import { useSnackBars } from '@ev/eva-container-api';
import { zodResolver } from '@hookform/resolvers/zod';
import { CurrencyEnum } from 'api/graphql/generated/graphql';
import { useCreateContact } from 'api/rest/hooks/useContacts';
import { ContactDetailsForm } from 'components/contact/ContactDetailsForm';
import { EVDrawerContent } from 'components/general/EVDrawer/EVDrawerContent';
import { CreateNewLead } from 'components/leads/forms/CreateNewLead';
import { useActiveShop } from 'components/state/ActiveShopProvider';
import { useConfirmation } from 'components/state/ConfirmationContext';
import { ContactEditSection } from 'page-components/contact/LayoutContact/EditContact/EditContact';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitErrorHandler, useForm, useWatch } from 'react-hook-form';
import { newContactDefaultValues } from 'util/defaultValues/newContactDefaultValues';
import { logSentryEntryIfAddressIsTruncated } from 'util/errorUtils';
import { useTranslation } from 'util/i18next';
import { createContactMapper } from 'util/mappers/createContactMapper';
import { useDestination } from 'util/navigation/useDestination';
import { useNavigation } from 'util/navigation/useNavigation';
import { captureCurrentUrl } from 'util/queryParams/captureUrl';
import { ContactDetailsFormData, useContactDetailsSchema } from 'util/schemas/contactDetailsSchema';
import { useErrorSnackBar } from 'util/useErrorSnackBar';
import { z } from 'zod';

export interface CreateNewContactProps {
  onClose: () => void;
  redirectToContactOnSuccess?: boolean;
  onSuccess?: (contactId: string, person?: ContactDetailsFormData) => void;
  prefillPhoneNumber?: string;
  withoutRelationships?: boolean;
  isServiceContact?: boolean;
  defaultValues?: Partial<ContactDetailsFormData>;
}

function getDefaultValues(
  shopCurrency: CurrencyEnum,
  phoneNumber?: string,
  isServiceContact?: boolean,
  redirectToContactOnSuccess?: boolean,
  defaultValues?: Partial<ContactDetailsFormData>,
) {
  const phoneNumberDefaultValues = phoneNumber
    ? {
        phoneNumbers: [
          {
            number: phoneNumber,
            phoneType: '',
            preferred: true,
          },
        ],
      }
    : {};
  const serviceContactDefaultValues = {
    ...defaultValues,
    _internals: {
      /** Disable switching to lead view when creating contact without redirection (cases like relationships, mails)*/
      isContactLeadToggleDisabled: !redirectToContactOnSuccess,
      ...defaultValues?._internals,
      contact: {
        preferredAddress: 0,
        preferredEmail: 0,
        preferredPhone: 0,
        isServiceContact,
        isOnlyContact: !redirectToContactOnSuccess,
        ...defaultValues?._internals?.contact,
      },
    },
  };

  return {
    ...newContactDefaultValues(shopCurrency),
    ...phoneNumberDefaultValues,
    ...serviceContactDefaultValues,
  };
}

export function CreateNewContact({
  onClose,
  redirectToContactOnSuccess = true,
  onSuccess,
  prefillPhoneNumber,
  withoutRelationships,
  defaultValues,
  isServiceContact,
}: CreateNewContactProps) {
  const { openSnackBar } = useSnackBars();
  const { openErrorSnackBar } = useErrorSnackBar();
  const { t } = useTranslation(['contact']);

  const { push } = useNavigation();
  const { activeShop, activeShopSettings, shopCurrency } = useActiveShop();
  const [activeSection, setActiveSection] = useState<ContactEditSection | undefined>('contact-form');

  const contactDetailsSchema = useContactDetailsSchema();
  const contactDefaultValues = getDefaultValues(
    shopCurrency,
    prefillPhoneNumber,
    isServiceContact,
    redirectToContactOnSuccess,
    defaultValues,
  );
  const methods = useForm<z.infer<typeof contactDetailsSchema>>({
    mode: 'onTouched',
    resolver: zodResolver(contactDetailsSchema),
    defaultValues: contactDefaultValues,
  });
  const { formState, setValue, control, handleSubmit } = methods;

  const watchedContactQualified = useWatch({
    control,
    name: '_internals.contact.contactQualified',
  });

  const watchedIsOnlyContact = useWatch({
    control,
    name: '_internals.contact.isOnlyContact',
  });

  const watchedIsServiceContact = useWatch({
    control,
    name: '_internals.contact.isServiceContact',
  });

  useConfirmation({
    title: t('contact:createNewContactDrawer.title'),
    active: formState.isDirty,
  });

  useEffect(() => {
    if (activeShopSettings?.applyAutomatedContactSettings) {
      setValue('addresses', [
        {
          preferred: true,
          address: {
            city: activeShopSettings?.defaultCity ?? undefined,
            countryCode: activeShopSettings?.defaultCountryCode ?? undefined,
          },
        },
      ]);
      setValue('preferredLanguage', activeShopSettings?.defaultPreferredLanguage ?? undefined);
    }
  }, [setValue, activeShopSettings]);

  const { createContact, isLoading } = useCreateContact();

  const { toContactDetails } = useDestination();

  if (!watchedIsOnlyContact && !watchedIsServiceContact) {
    return <CreateNewLead onClose={onClose} defaultContactValues={methods.getValues()} />;
  }

  const onSave = (data: z.infer<typeof contactDetailsSchema>): void => {
    if (!activeShop.id) {
      return;
    }
    logSentryEntryIfAddressIsTruncated('addressSelect', data.addresses);

    createContact(
      { requestBody: createContactMapper(data, activeShop.id) },
      {
        onSuccess: (id) => {
          if (redirectToContactOnSuccess) {
            push(toContactDetails({ contactId: id!.id! }), {
              contacts: captureCurrentUrl(),
            });
            openSnackBar(t('contact:createContact.success'), 'success');
          }
          if (onSuccess) {
            onSuccess(id!.id!, data);
          }
          onClose();
        },
        onError: (error) => openErrorSnackBar(t('contact:createContact.error'), error),
      },
    );
  };

  const handleValidationError: SubmitErrorHandler<z.infer<typeof contactDetailsSchema>> = (errors) => {
    setActiveSection(errors ? 'contact-form' : 'comment-tag-form');
  };

  return (
    <EVDrawerContent
      title={t(`contact:createNewContactDrawer.${isServiceContact ? 'serviceContactTitle' : 'title'}`)}
      onClose={onClose}
      primaryAction={{
        primaryButtonLabel: t('contact:createContact.saveButton'),
        disabled: !watchedContactQualified,
        callback: handleSubmit(onSave, handleValidationError),
      }}
      isLoading={isLoading}
    >
      <FormProvider {...methods}>
        <ContactDetailsForm activeSection={activeSection} withoutRelationships={withoutRelationships} />
      </FormProvider>
    </EVDrawerContent>
  );
}
