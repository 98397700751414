/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO-4217 compliant Currency Scalar */
  PropertyEngineCurrency: string;
  /** An RFC-3339 compliant Full Date Scalar */
  PropertyEngineDate: string;
  /** A slightly refined version of RFC-3339 compliant DateTime Scalar */
  PropertyEngineDateTime: string;
  /** The scalar counter-part of the Identifier class */
  PropertyEngineIdentifier: string;
  /** A JSON scalar */
  PropertyEngineJSON: any;
  /** A date-time without a time-zone in the ISO-8601 calendar system, formatted as 2007-12-03 10:15:30 */
  PropertyEngineLocalDateTime: any;
  /** A IETF BCP 47 language tag */
  PropertyEngineLocaleScalar: any;
  /** A 64-bit signed integer */
  PropertyEngineLong: number;
  /** A custom scalar that handles MultiPolygon objects in GeoJSON format */
  PropertyEngineMultiPolygon: any;
  /** A universally unique identifier compliant UUID Scalar */
  PropertyEngineUUID: string;
  /** A custom scalar that represents files */
  PropertyEngineUpload: any;
  /** A Url scalar */
  PropertyEngineUrl: string;
  /** The scalar for a 'void' return type e.g. represented as null */
  PropertyEngineVoid: any;
  bigint: any;
  bpchar: any;
  date: any;
  float8: any;
  geography: any;
  geometry: any;
  jsonb: any;
  numeric: any;
  timestamp: string;
  timestamptz: any;
  uuid: string;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type BigintComparisonExp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "bpchar". All fields are combined with logical 'AND'. */
export type BpcharComparisonExp = {
  _eq?: InputMaybe<Scalars['bpchar']>;
  _gt?: InputMaybe<Scalars['bpchar']>;
  _gte?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['bpchar']>;
  _in?: InputMaybe<Array<Scalars['bpchar']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['bpchar']>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['bpchar']>;
  _lt?: InputMaybe<Scalars['bpchar']>;
  _lte?: InputMaybe<Scalars['bpchar']>;
  _neq?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['bpchar']>;
  _nin?: InputMaybe<Array<Scalars['bpchar']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['bpchar']>;
};

/** ordering argument of a cursor */
export type CursorOrdering =
  /** ascending ordering of the cursor */
  | 'ASC'
  /** descending ordering of the cursor */
  | 'DESC';

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type DateComparisonExp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "db.activity_type_grouping" */
export type DbActivityTypeGrouping = {
  __typename?: 'DbActivityTypeGrouping';
  activityCategory: BaseActivityCategoryEnum;
  activityType: ActivityTypeEnum;
  typeLocalizeKey: Scalars['String'];
};

/** Boolean expression to filter rows from the table "db.activity_type_grouping". All fields are combined with a logical 'AND'. */
export type DbActivityTypeGroupingBoolExp = {
  _and?: InputMaybe<Array<DbActivityTypeGroupingBoolExp>>;
  _not?: InputMaybe<DbActivityTypeGroupingBoolExp>;
  _or?: InputMaybe<Array<DbActivityTypeGroupingBoolExp>>;
  activityCategory?: InputMaybe<EnumsBaseActivityCategoryEnumComparisonExp>;
  activityType?: InputMaybe<EnumsActivityTypeEnumComparisonExp>;
  typeLocalizeKey?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "db.activity_type_grouping". */
export type DbActivityTypeGroupingOrderBy = {
  activityCategory?: InputMaybe<OrderBy>;
  activityType?: InputMaybe<OrderBy>;
  typeLocalizeKey?: InputMaybe<OrderBy>;
};

/** select columns of table "db.activity_type_grouping" */
export type DbActivityTypeGroupingSelectColumn =
  /** column name */
  | 'activityCategory'
  /** column name */
  | 'activityType'
  /** column name */
  | 'typeLocalizeKey';

/** Streaming cursor of the table "db_activity_type_grouping" */
export type DbActivityTypeGroupingStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbActivityTypeGroupingStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbActivityTypeGroupingStreamCursorValueInput = {
  activityCategory?: InputMaybe<BaseActivityCategoryEnum>;
  activityType?: InputMaybe<ActivityTypeEnum>;
  typeLocalizeKey?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "db.address" */
export type DbAddress = {
  __typename?: 'DbAddress';
  /** An object relationship */
  addressFormat: EnumsAddressFormat;
  city?: Maybe<Scalars['String']>;
  /** An array relationship */
  contacts: Array<DbContactAddress>;
  /** An aggregate relationship */
  contactsAggregate: DbContactAddressAggregate;
  /** renamed */
  coordinateLatitude: Scalars['float8'];
  /** renamed */
  coordinateLongitude: Scalars['float8'];
  /** An object relationship */
  country: EnumsCountry;
  countryCode: CountryEnum;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  format: AddressFormatEnum;
  fullAddress?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  num: Scalars['bigint'];
  placeId?: Maybe<Scalars['String']>;
  postBox?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  secondAddressLine?: Maybe<Scalars['String']>;
  /** An array relationship */
  sellerRentOutSpecifications: Array<DbSellerRentOutSpecification>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** columns and relationships of "db.address" */
export type DbAddressContactsArgs = {
  distinctOn?: InputMaybe<Array<DbContactAddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactAddressOrderBy>>;
  where?: InputMaybe<DbContactAddressBoolExp>;
};

/** columns and relationships of "db.address" */
export type DbAddressContactsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbContactAddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactAddressOrderBy>>;
  where?: InputMaybe<DbContactAddressBoolExp>;
};

/** columns and relationships of "db.address" */
export type DbAddressSellerRentOutSpecificationsArgs = {
  distinctOn?: InputMaybe<Array<DbSellerRentOutSpecificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSellerRentOutSpecificationOrderBy>>;
  where?: InputMaybe<DbSellerRentOutSpecificationBoolExp>;
};

/** Boolean expression to filter rows from the table "db.address". All fields are combined with a logical 'AND'. */
export type DbAddressBoolExp = {
  _and?: InputMaybe<Array<DbAddressBoolExp>>;
  _not?: InputMaybe<DbAddressBoolExp>;
  _or?: InputMaybe<Array<DbAddressBoolExp>>;
  addressFormat?: InputMaybe<EnumsAddressFormatBoolExp>;
  city?: InputMaybe<StringComparisonExp>;
  contacts?: InputMaybe<DbContactAddressBoolExp>;
  contactsAggregate?: InputMaybe<DbContactAddressAggregateBoolExp>;
  coordinateLatitude?: InputMaybe<Float8ComparisonExp>;
  coordinateLongitude?: InputMaybe<Float8ComparisonExp>;
  country?: InputMaybe<EnumsCountryBoolExp>;
  countryCode?: InputMaybe<EnumsCountryEnumComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  format?: InputMaybe<EnumsAddressFormatEnumComparisonExp>;
  fullAddress?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  placeId?: InputMaybe<StringComparisonExp>;
  postBox?: InputMaybe<StringComparisonExp>;
  postalCode?: InputMaybe<StringComparisonExp>;
  secondAddressLine?: InputMaybe<StringComparisonExp>;
  sellerRentOutSpecifications?: InputMaybe<DbSellerRentOutSpecificationBoolExp>;
  streetName?: InputMaybe<StringComparisonExp>;
  streetNumber?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "db.address". */
export type DbAddressOrderBy = {
  addressFormat?: InputMaybe<EnumsAddressFormatOrderBy>;
  city?: InputMaybe<OrderBy>;
  contactsAggregate?: InputMaybe<DbContactAddressAggregateOrderBy>;
  coordinateLatitude?: InputMaybe<OrderBy>;
  coordinateLongitude?: InputMaybe<OrderBy>;
  country?: InputMaybe<EnumsCountryOrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  format?: InputMaybe<OrderBy>;
  fullAddress?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  placeId?: InputMaybe<OrderBy>;
  postBox?: InputMaybe<OrderBy>;
  postalCode?: InputMaybe<OrderBy>;
  secondAddressLine?: InputMaybe<OrderBy>;
  sellerRentOutSpecificationsAggregate?: InputMaybe<DbSellerRentOutSpecificationAggregateOrderBy>;
  streetName?: InputMaybe<OrderBy>;
  streetNumber?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.agent" */
export type DbAgent = {
  __typename?: 'DbAgent';
  activeSince: Scalars['timestamptz'];
  /** An array relationship */
  appointmentAgents: Array<DbAppointmentAgent>;
  /** An array relationship */
  appointments: Array<DbAppointment>;
  betaTester: Scalars['Boolean'];
  /** An array relationship */
  calls: Array<DbCall>;
  /** An aggregate relationship */
  callsAggregate: DbCallAggregate;
  /** An array relationship */
  contactAssignments: Array<DbContactAssignment>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  /** An array relationship */
  defaultEmailSignatures: Array<DbAgentDefaultEmailSignature>;
  /** An aggregate relationship */
  defaultEmailSignaturesAggregate: DbAgentDefaultEmailSignatureAggregate;
  /** An array relationship */
  defaultNylasEmailIntegrations: Array<DbAgentDefaultNylasEmailIntegration>;
  /** An aggregate relationship */
  defaultNylasEmailIntegrationsAggregate: DbAgentDefaultNylasEmailIntegrationAggregate;
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  /** An array relationship */
  emailSignatures: Array<DbEmailSignature>;
  /** An aggregate relationship */
  emailSignaturesAggregate: DbEmailSignatureAggregate;
  /** An array relationship */
  emailTemplates: Array<DbEmailTemplate>;
  /** An aggregate relationship */
  emailTemplatesAggregate: DbEmailTemplateAggregate;
  energyAdvisoryEnabled: Scalars['Boolean'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  ldapId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  leadhubGo3ContactLinksByFirstAgentId: Array<DbLeadhubGo3ContactLink>;
  /** An aggregate relationship */
  leadhubGo3ContactLinksByFirstAgentIdAggregate: DbLeadhubGo3ContactLinkAggregate;
  /** An array relationship */
  leadhubGo3ContactLinksBySecondAgentId: Array<DbLeadhubGo3ContactLink>;
  /** An aggregate relationship */
  leadhubGo3ContactLinksBySecondAgentIdAggregate: DbLeadhubGo3ContactLinkAggregate;
  /** An array relationship */
  leadhubGo3ContactLinksByThirdAgentId: Array<DbLeadhubGo3ContactLink>;
  /** An aggregate relationship */
  leadhubGo3ContactLinksByThirdAgentIdAggregate: DbLeadhubGo3ContactLinkAggregate;
  /** An array relationship */
  leads: Array<DbLead>;
  /** An aggregate relationship */
  leadsAggregate: DbLeadAggregate;
  mobilePhoneNumber?: Maybe<Scalars['String']>;
  normalizedFirstName?: Maybe<Scalars['String']>;
  normalizedLastName?: Maybe<Scalars['String']>;
  normalizedUserName?: Maybe<Scalars['String']>;
  num: Scalars['bigint'];
  /** An array relationship */
  nylasEmailIntegrations: Array<DbNylasEmailIntegration>;
  /** An aggregate relationship */
  nylasEmailIntegrationsAggregate: DbNylasEmailIntegrationAggregate;
  officePhoneNumber?: Maybe<Scalars['String']>;
  /** An object relationship */
  primaryShop?: Maybe<DbShop>;
  primaryShopId?: Maybe<Scalars['String']>;
  /** An array relationship */
  profiles: Array<DbAgentProfile>;
  /** An aggregate relationship */
  profilesAggregate: DbAgentProfileAggregate;
  /** An array relationship */
  referrals: Array<DbReferral>;
  /** An aggregate relationship */
  referralsAggregate: DbReferralAggregate;
  /** An array relationship */
  referralsByReceivingAgentId: Array<DbReferral>;
  /** An aggregate relationship */
  referralsByReceivingAgentIdAggregate: DbReferralAggregate;
  salutation?: Maybe<SalutationEnum>;
  /** An object relationship */
  salutationBySalutation?: Maybe<EnumsSalutation>;
  /** An array relationship */
  sessions: Array<DbSession>;
  /** An aggregate relationship */
  sessionsAggregate: DbSessionAggregate;
  /** An array relationship */
  shopProfileFeaturedAgents: Array<DbShopProfileFeaturedAgent>;
  status: AgentStatusEnum;
  /** An array relationship */
  taskAgents: Array<DbTaskAgent>;
  /** An array relationship */
  tasks: Array<DbTask>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  /** An array relationship */
  userAdminAssignments: Array<DbUserAdminAssignment>;
  /** An aggregate relationship */
  userAdminAssignmentsAggregate: DbUserAdminAssignmentAggregate;
  userName: Scalars['String'];
  /** An array relationship */
  userShopAssignments: Array<DbUserShopAssignment>;
  /** An aggregate relationship */
  userShopAssignmentsAggregate: DbUserShopAssignmentAggregate;
  /** An array relationship */
  userTeamAssignments: Array<DbUserTeamAssignment>;
  /** An aggregate relationship */
  userTeamAssignmentsAggregate: DbUserTeamAssignmentAggregate;
};

/** columns and relationships of "db.agent" */
export type DbAgentAppointmentAgentsArgs = {
  distinctOn?: InputMaybe<Array<DbAppointmentAgentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAppointmentAgentOrderBy>>;
  where?: InputMaybe<DbAppointmentAgentBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentAppointmentsArgs = {
  distinctOn?: InputMaybe<Array<DbAppointmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAppointmentOrderBy>>;
  where?: InputMaybe<DbAppointmentBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentCallsArgs = {
  distinctOn?: InputMaybe<Array<DbCallSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCallOrderBy>>;
  where?: InputMaybe<DbCallBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentCallsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbCallSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCallOrderBy>>;
  where?: InputMaybe<DbCallBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentContactAssignmentsArgs = {
  distinctOn?: InputMaybe<Array<DbContactAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactAssignmentOrderBy>>;
  where?: InputMaybe<DbContactAssignmentBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentDefaultEmailSignaturesArgs = {
  distinctOn?: InputMaybe<Array<DbAgentDefaultEmailSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentDefaultEmailSignatureOrderBy>>;
  where?: InputMaybe<DbAgentDefaultEmailSignatureBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentDefaultEmailSignaturesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentDefaultEmailSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentDefaultEmailSignatureOrderBy>>;
  where?: InputMaybe<DbAgentDefaultEmailSignatureBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentDefaultNylasEmailIntegrationsArgs = {
  distinctOn?: InputMaybe<Array<DbAgentDefaultNylasEmailIntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentDefaultNylasEmailIntegrationOrderBy>>;
  where?: InputMaybe<DbAgentDefaultNylasEmailIntegrationBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentDefaultNylasEmailIntegrationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentDefaultNylasEmailIntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentDefaultNylasEmailIntegrationOrderBy>>;
  where?: InputMaybe<DbAgentDefaultNylasEmailIntegrationBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentEmailSignaturesArgs = {
  distinctOn?: InputMaybe<Array<DbEmailSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailSignatureOrderBy>>;
  where?: InputMaybe<DbEmailSignatureBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentEmailSignaturesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailSignatureOrderBy>>;
  where?: InputMaybe<DbEmailSignatureBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentEmailTemplatesArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTemplateOrderBy>>;
  where?: InputMaybe<DbEmailTemplateBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentEmailTemplatesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTemplateOrderBy>>;
  where?: InputMaybe<DbEmailTemplateBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentLeadhubGo3ContactLinksByFirstAgentIdArgs = {
  distinctOn?: InputMaybe<Array<DbLeadhubGo3ContactLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadhubGo3ContactLinkOrderBy>>;
  where?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentLeadhubGo3ContactLinksByFirstAgentIdAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLeadhubGo3ContactLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadhubGo3ContactLinkOrderBy>>;
  where?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentLeadhubGo3ContactLinksBySecondAgentIdArgs = {
  distinctOn?: InputMaybe<Array<DbLeadhubGo3ContactLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadhubGo3ContactLinkOrderBy>>;
  where?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentLeadhubGo3ContactLinksBySecondAgentIdAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLeadhubGo3ContactLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadhubGo3ContactLinkOrderBy>>;
  where?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentLeadhubGo3ContactLinksByThirdAgentIdArgs = {
  distinctOn?: InputMaybe<Array<DbLeadhubGo3ContactLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadhubGo3ContactLinkOrderBy>>;
  where?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentLeadhubGo3ContactLinksByThirdAgentIdAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLeadhubGo3ContactLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadhubGo3ContactLinkOrderBy>>;
  where?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentLeadsArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentLeadsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentNylasEmailIntegrationsArgs = {
  distinctOn?: InputMaybe<Array<DbNylasEmailIntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbNylasEmailIntegrationOrderBy>>;
  where?: InputMaybe<DbNylasEmailIntegrationBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentNylasEmailIntegrationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbNylasEmailIntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbNylasEmailIntegrationOrderBy>>;
  where?: InputMaybe<DbNylasEmailIntegrationBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentProfilesArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileOrderBy>>;
  where?: InputMaybe<DbAgentProfileBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentProfilesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileOrderBy>>;
  where?: InputMaybe<DbAgentProfileBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentReferralsArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentReferralsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentReferralsByReceivingAgentIdArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentReferralsByReceivingAgentIdAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentSessionsArgs = {
  distinctOn?: InputMaybe<Array<DbSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSessionOrderBy>>;
  where?: InputMaybe<DbSessionBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentSessionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSessionOrderBy>>;
  where?: InputMaybe<DbSessionBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentShopProfileFeaturedAgentsArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileFeaturedAgentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileFeaturedAgentOrderBy>>;
  where?: InputMaybe<DbShopProfileFeaturedAgentBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentTaskAgentsArgs = {
  distinctOn?: InputMaybe<Array<DbTaskAgentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTaskAgentOrderBy>>;
  where?: InputMaybe<DbTaskAgentBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentTasksArgs = {
  distinctOn?: InputMaybe<Array<DbTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTaskOrderBy>>;
  where?: InputMaybe<DbTaskBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentUserAdminAssignmentsArgs = {
  distinctOn?: InputMaybe<Array<DbUserAdminAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserAdminAssignmentOrderBy>>;
  where?: InputMaybe<DbUserAdminAssignmentBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentUserAdminAssignmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbUserAdminAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserAdminAssignmentOrderBy>>;
  where?: InputMaybe<DbUserAdminAssignmentBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentUserShopAssignmentsArgs = {
  distinctOn?: InputMaybe<Array<DbUserShopAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserShopAssignmentOrderBy>>;
  where?: InputMaybe<DbUserShopAssignmentBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentUserShopAssignmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbUserShopAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserShopAssignmentOrderBy>>;
  where?: InputMaybe<DbUserShopAssignmentBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentUserTeamAssignmentsArgs = {
  distinctOn?: InputMaybe<Array<DbUserTeamAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserTeamAssignmentOrderBy>>;
  where?: InputMaybe<DbUserTeamAssignmentBoolExp>;
};

/** columns and relationships of "db.agent" */
export type DbAgentUserTeamAssignmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbUserTeamAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserTeamAssignmentOrderBy>>;
  where?: InputMaybe<DbUserTeamAssignmentBoolExp>;
};

/** aggregated selection of "db.agent" */
export type DbAgentAggregate = {
  __typename?: 'DbAgentAggregate';
  aggregate?: Maybe<DbAgentAggregateFields>;
  nodes: Array<DbAgent>;
};

export type DbAgentAggregateBoolExp = {
  bool_and?: InputMaybe<DbAgentAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbAgentAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbAgentAggregateBoolExpCount>;
};

/** aggregate fields of "db.agent" */
export type DbAgentAggregateFields = {
  __typename?: 'DbAgentAggregateFields';
  avg?: Maybe<DbAgentAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbAgentMaxFields>;
  min?: Maybe<DbAgentMinFields>;
  stddev?: Maybe<DbAgentStddevFields>;
  stddevPop?: Maybe<DbAgentStddevPopFields>;
  stddevSamp?: Maybe<DbAgentStddevSampFields>;
  sum?: Maybe<DbAgentSumFields>;
  varPop?: Maybe<DbAgentVarPopFields>;
  varSamp?: Maybe<DbAgentVarSampFields>;
  variance?: Maybe<DbAgentVarianceFields>;
};

/** aggregate fields of "db.agent" */
export type DbAgentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbAgentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.agent" */
export type DbAgentAggregateOrderBy = {
  avg?: InputMaybe<DbAgentAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbAgentMaxOrderBy>;
  min?: InputMaybe<DbAgentMinOrderBy>;
  stddev?: InputMaybe<DbAgentStddevOrderBy>;
  stddevPop?: InputMaybe<DbAgentStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbAgentStddevSampOrderBy>;
  sum?: InputMaybe<DbAgentSumOrderBy>;
  varPop?: InputMaybe<DbAgentVarPopOrderBy>;
  varSamp?: InputMaybe<DbAgentVarSampOrderBy>;
  variance?: InputMaybe<DbAgentVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbAgentAvgFields = {
  __typename?: 'DbAgentAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.agent" */
export type DbAgentAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.agent". All fields are combined with a logical 'AND'. */
export type DbAgentBoolExp = {
  _and?: InputMaybe<Array<DbAgentBoolExp>>;
  _not?: InputMaybe<DbAgentBoolExp>;
  _or?: InputMaybe<Array<DbAgentBoolExp>>;
  activeSince?: InputMaybe<TimestamptzComparisonExp>;
  appointmentAgents?: InputMaybe<DbAppointmentAgentBoolExp>;
  appointments?: InputMaybe<DbAppointmentBoolExp>;
  betaTester?: InputMaybe<BooleanComparisonExp>;
  calls?: InputMaybe<DbCallBoolExp>;
  callsAggregate?: InputMaybe<DbCallAggregateBoolExp>;
  contactAssignments?: InputMaybe<DbContactAssignmentBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  defaultEmailSignatures?: InputMaybe<DbAgentDefaultEmailSignatureBoolExp>;
  defaultEmailSignaturesAggregate?: InputMaybe<DbAgentDefaultEmailSignatureAggregateBoolExp>;
  defaultNylasEmailIntegrations?: InputMaybe<DbAgentDefaultNylasEmailIntegrationBoolExp>;
  defaultNylasEmailIntegrationsAggregate?: InputMaybe<DbAgentDefaultNylasEmailIntegrationAggregateBoolExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  emailSignatures?: InputMaybe<DbEmailSignatureBoolExp>;
  emailSignaturesAggregate?: InputMaybe<DbEmailSignatureAggregateBoolExp>;
  emailTemplates?: InputMaybe<DbEmailTemplateBoolExp>;
  emailTemplatesAggregate?: InputMaybe<DbEmailTemplateAggregateBoolExp>;
  energyAdvisoryEnabled?: InputMaybe<BooleanComparisonExp>;
  firstName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lastName?: InputMaybe<StringComparisonExp>;
  ldapId?: InputMaybe<UuidComparisonExp>;
  leadhubGo3ContactLinksByFirstAgentId?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
  leadhubGo3ContactLinksByFirstAgentIdAggregate?: InputMaybe<DbLeadhubGo3ContactLinkAggregateBoolExp>;
  leadhubGo3ContactLinksBySecondAgentId?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
  leadhubGo3ContactLinksBySecondAgentIdAggregate?: InputMaybe<DbLeadhubGo3ContactLinkAggregateBoolExp>;
  leadhubGo3ContactLinksByThirdAgentId?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
  leadhubGo3ContactLinksByThirdAgentIdAggregate?: InputMaybe<DbLeadhubGo3ContactLinkAggregateBoolExp>;
  leads?: InputMaybe<DbLeadBoolExp>;
  leadsAggregate?: InputMaybe<DbLeadAggregateBoolExp>;
  mobilePhoneNumber?: InputMaybe<StringComparisonExp>;
  normalizedFirstName?: InputMaybe<StringComparisonExp>;
  normalizedLastName?: InputMaybe<StringComparisonExp>;
  normalizedUserName?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  nylasEmailIntegrations?: InputMaybe<DbNylasEmailIntegrationBoolExp>;
  nylasEmailIntegrationsAggregate?: InputMaybe<DbNylasEmailIntegrationAggregateBoolExp>;
  officePhoneNumber?: InputMaybe<StringComparisonExp>;
  primaryShop?: InputMaybe<DbShopBoolExp>;
  primaryShopId?: InputMaybe<StringComparisonExp>;
  profiles?: InputMaybe<DbAgentProfileBoolExp>;
  profilesAggregate?: InputMaybe<DbAgentProfileAggregateBoolExp>;
  referrals?: InputMaybe<DbReferralBoolExp>;
  referralsAggregate?: InputMaybe<DbReferralAggregateBoolExp>;
  referralsByReceivingAgentId?: InputMaybe<DbReferralBoolExp>;
  referralsByReceivingAgentIdAggregate?: InputMaybe<DbReferralAggregateBoolExp>;
  salutation?: InputMaybe<EnumsSalutationEnumComparisonExp>;
  salutationBySalutation?: InputMaybe<EnumsSalutationBoolExp>;
  sessions?: InputMaybe<DbSessionBoolExp>;
  sessionsAggregate?: InputMaybe<DbSessionAggregateBoolExp>;
  shopProfileFeaturedAgents?: InputMaybe<DbShopProfileFeaturedAgentBoolExp>;
  status?: InputMaybe<EnumsAgentStatusEnumComparisonExp>;
  taskAgents?: InputMaybe<DbTaskAgentBoolExp>;
  tasks?: InputMaybe<DbTaskBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  userAdminAssignments?: InputMaybe<DbUserAdminAssignmentBoolExp>;
  userAdminAssignmentsAggregate?: InputMaybe<DbUserAdminAssignmentAggregateBoolExp>;
  userName?: InputMaybe<StringComparisonExp>;
  userShopAssignments?: InputMaybe<DbUserShopAssignmentBoolExp>;
  userShopAssignmentsAggregate?: InputMaybe<DbUserShopAssignmentAggregateBoolExp>;
  userTeamAssignments?: InputMaybe<DbUserTeamAssignmentBoolExp>;
  userTeamAssignmentsAggregate?: InputMaybe<DbUserTeamAssignmentAggregateBoolExp>;
};

/** columns and relationships of "db.agent_default_email_signature" */
export type DbAgentDefaultEmailSignature = {
  __typename?: 'DbAgentDefaultEmailSignature';
  /** An object relationship */
  agent: DbAgent;
  agentId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  emailSignature: DbEmailSignature;
  id: Scalars['String'];
  num: Scalars['bigint'];
  /** An object relationship */
  shop: DbShop;
  shopId: Scalars['String'];
  signatureId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.agent_default_email_signature" */
export type DbAgentDefaultEmailSignatureAggregate = {
  __typename?: 'DbAgentDefaultEmailSignatureAggregate';
  aggregate?: Maybe<DbAgentDefaultEmailSignatureAggregateFields>;
  nodes: Array<DbAgentDefaultEmailSignature>;
};

export type DbAgentDefaultEmailSignatureAggregateBoolExp = {
  bool_and?: InputMaybe<DbAgentDefaultEmailSignatureAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbAgentDefaultEmailSignatureAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbAgentDefaultEmailSignatureAggregateBoolExpCount>;
};

/** aggregate fields of "db.agent_default_email_signature" */
export type DbAgentDefaultEmailSignatureAggregateFields = {
  __typename?: 'DbAgentDefaultEmailSignatureAggregateFields';
  avg?: Maybe<DbAgentDefaultEmailSignatureAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbAgentDefaultEmailSignatureMaxFields>;
  min?: Maybe<DbAgentDefaultEmailSignatureMinFields>;
  stddev?: Maybe<DbAgentDefaultEmailSignatureStddevFields>;
  stddevPop?: Maybe<DbAgentDefaultEmailSignatureStddevPopFields>;
  stddevSamp?: Maybe<DbAgentDefaultEmailSignatureStddevSampFields>;
  sum?: Maybe<DbAgentDefaultEmailSignatureSumFields>;
  varPop?: Maybe<DbAgentDefaultEmailSignatureVarPopFields>;
  varSamp?: Maybe<DbAgentDefaultEmailSignatureVarSampFields>;
  variance?: Maybe<DbAgentDefaultEmailSignatureVarianceFields>;
};

/** aggregate fields of "db.agent_default_email_signature" */
export type DbAgentDefaultEmailSignatureAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbAgentDefaultEmailSignatureSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.agent_default_email_signature" */
export type DbAgentDefaultEmailSignatureAggregateOrderBy = {
  avg?: InputMaybe<DbAgentDefaultEmailSignatureAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbAgentDefaultEmailSignatureMaxOrderBy>;
  min?: InputMaybe<DbAgentDefaultEmailSignatureMinOrderBy>;
  stddev?: InputMaybe<DbAgentDefaultEmailSignatureStddevOrderBy>;
  stddevPop?: InputMaybe<DbAgentDefaultEmailSignatureStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbAgentDefaultEmailSignatureStddevSampOrderBy>;
  sum?: InputMaybe<DbAgentDefaultEmailSignatureSumOrderBy>;
  varPop?: InputMaybe<DbAgentDefaultEmailSignatureVarPopOrderBy>;
  varSamp?: InputMaybe<DbAgentDefaultEmailSignatureVarSampOrderBy>;
  variance?: InputMaybe<DbAgentDefaultEmailSignatureVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbAgentDefaultEmailSignatureAvgFields = {
  __typename?: 'DbAgentDefaultEmailSignatureAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.agent_default_email_signature" */
export type DbAgentDefaultEmailSignatureAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.agent_default_email_signature". All fields are combined with a logical 'AND'. */
export type DbAgentDefaultEmailSignatureBoolExp = {
  _and?: InputMaybe<Array<DbAgentDefaultEmailSignatureBoolExp>>;
  _not?: InputMaybe<DbAgentDefaultEmailSignatureBoolExp>;
  _or?: InputMaybe<Array<DbAgentDefaultEmailSignatureBoolExp>>;
  agent?: InputMaybe<DbAgentBoolExp>;
  agentId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  emailSignature?: InputMaybe<DbEmailSignatureBoolExp>;
  id?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  signatureId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbAgentDefaultEmailSignatureMaxFields = {
  __typename?: 'DbAgentDefaultEmailSignatureMaxFields';
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  shopId?: Maybe<Scalars['String']>;
  signatureId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.agent_default_email_signature" */
export type DbAgentDefaultEmailSignatureMaxOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  signatureId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbAgentDefaultEmailSignatureMinFields = {
  __typename?: 'DbAgentDefaultEmailSignatureMinFields';
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  shopId?: Maybe<Scalars['String']>;
  signatureId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.agent_default_email_signature" */
export type DbAgentDefaultEmailSignatureMinOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  signatureId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.agent_default_email_signature". */
export type DbAgentDefaultEmailSignatureOrderBy = {
  agent?: InputMaybe<DbAgentOrderBy>;
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  emailSignature?: InputMaybe<DbEmailSignatureOrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  signatureId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.agent_default_email_signature" */
export type DbAgentDefaultEmailSignatureSelectColumn =
  /** column name */
  | 'agentId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'num'
  /** column name */
  | 'shopId'
  /** column name */
  | 'signatureId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbAgentDefaultEmailSignatureAggregateBoolExpBool_andArgumentsColumns" columns of table "db.agent_default_email_signature" */
export type DbAgentDefaultEmailSignatureSelectColumnDbAgentDefaultEmailSignatureAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbAgentDefaultEmailSignatureAggregateBoolExpBool_orArgumentsColumns" columns of table "db.agent_default_email_signature" */
export type DbAgentDefaultEmailSignatureSelectColumnDbAgentDefaultEmailSignatureAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbAgentDefaultEmailSignatureStddevFields = {
  __typename?: 'DbAgentDefaultEmailSignatureStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.agent_default_email_signature" */
export type DbAgentDefaultEmailSignatureStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbAgentDefaultEmailSignatureStddevPopFields = {
  __typename?: 'DbAgentDefaultEmailSignatureStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.agent_default_email_signature" */
export type DbAgentDefaultEmailSignatureStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbAgentDefaultEmailSignatureStddevSampFields = {
  __typename?: 'DbAgentDefaultEmailSignatureStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.agent_default_email_signature" */
export type DbAgentDefaultEmailSignatureStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_agent_default_email_signature" */
export type DbAgentDefaultEmailSignatureStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbAgentDefaultEmailSignatureStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbAgentDefaultEmailSignatureStreamCursorValueInput = {
  agentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  shopId?: InputMaybe<Scalars['String']>;
  signatureId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbAgentDefaultEmailSignatureSumFields = {
  __typename?: 'DbAgentDefaultEmailSignatureSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.agent_default_email_signature" */
export type DbAgentDefaultEmailSignatureSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbAgentDefaultEmailSignatureVarPopFields = {
  __typename?: 'DbAgentDefaultEmailSignatureVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.agent_default_email_signature" */
export type DbAgentDefaultEmailSignatureVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbAgentDefaultEmailSignatureVarSampFields = {
  __typename?: 'DbAgentDefaultEmailSignatureVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.agent_default_email_signature" */
export type DbAgentDefaultEmailSignatureVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbAgentDefaultEmailSignatureVarianceFields = {
  __typename?: 'DbAgentDefaultEmailSignatureVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.agent_default_email_signature" */
export type DbAgentDefaultEmailSignatureVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.agent_default_nylas_email_integration" */
export type DbAgentDefaultNylasEmailIntegration = {
  __typename?: 'DbAgentDefaultNylasEmailIntegration';
  /** An object relationship */
  agent: DbAgent;
  agentId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  integrationId: Scalars['String'];
  num: Scalars['bigint'];
  /** An object relationship */
  nylasEmailIntegration: DbNylasEmailIntegration;
  /** An object relationship */
  shop: DbShop;
  shopId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.agent_default_nylas_email_integration" */
export type DbAgentDefaultNylasEmailIntegrationAggregate = {
  __typename?: 'DbAgentDefaultNylasEmailIntegrationAggregate';
  aggregate?: Maybe<DbAgentDefaultNylasEmailIntegrationAggregateFields>;
  nodes: Array<DbAgentDefaultNylasEmailIntegration>;
};

export type DbAgentDefaultNylasEmailIntegrationAggregateBoolExp = {
  bool_and?: InputMaybe<DbAgentDefaultNylasEmailIntegrationAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbAgentDefaultNylasEmailIntegrationAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbAgentDefaultNylasEmailIntegrationAggregateBoolExpCount>;
};

/** aggregate fields of "db.agent_default_nylas_email_integration" */
export type DbAgentDefaultNylasEmailIntegrationAggregateFields = {
  __typename?: 'DbAgentDefaultNylasEmailIntegrationAggregateFields';
  avg?: Maybe<DbAgentDefaultNylasEmailIntegrationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbAgentDefaultNylasEmailIntegrationMaxFields>;
  min?: Maybe<DbAgentDefaultNylasEmailIntegrationMinFields>;
  stddev?: Maybe<DbAgentDefaultNylasEmailIntegrationStddevFields>;
  stddevPop?: Maybe<DbAgentDefaultNylasEmailIntegrationStddevPopFields>;
  stddevSamp?: Maybe<DbAgentDefaultNylasEmailIntegrationStddevSampFields>;
  sum?: Maybe<DbAgentDefaultNylasEmailIntegrationSumFields>;
  varPop?: Maybe<DbAgentDefaultNylasEmailIntegrationVarPopFields>;
  varSamp?: Maybe<DbAgentDefaultNylasEmailIntegrationVarSampFields>;
  variance?: Maybe<DbAgentDefaultNylasEmailIntegrationVarianceFields>;
};

/** aggregate fields of "db.agent_default_nylas_email_integration" */
export type DbAgentDefaultNylasEmailIntegrationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbAgentDefaultNylasEmailIntegrationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.agent_default_nylas_email_integration" */
export type DbAgentDefaultNylasEmailIntegrationAggregateOrderBy = {
  avg?: InputMaybe<DbAgentDefaultNylasEmailIntegrationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbAgentDefaultNylasEmailIntegrationMaxOrderBy>;
  min?: InputMaybe<DbAgentDefaultNylasEmailIntegrationMinOrderBy>;
  stddev?: InputMaybe<DbAgentDefaultNylasEmailIntegrationStddevOrderBy>;
  stddevPop?: InputMaybe<DbAgentDefaultNylasEmailIntegrationStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbAgentDefaultNylasEmailIntegrationStddevSampOrderBy>;
  sum?: InputMaybe<DbAgentDefaultNylasEmailIntegrationSumOrderBy>;
  varPop?: InputMaybe<DbAgentDefaultNylasEmailIntegrationVarPopOrderBy>;
  varSamp?: InputMaybe<DbAgentDefaultNylasEmailIntegrationVarSampOrderBy>;
  variance?: InputMaybe<DbAgentDefaultNylasEmailIntegrationVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbAgentDefaultNylasEmailIntegrationAvgFields = {
  __typename?: 'DbAgentDefaultNylasEmailIntegrationAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.agent_default_nylas_email_integration" */
export type DbAgentDefaultNylasEmailIntegrationAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.agent_default_nylas_email_integration". All fields are combined with a logical 'AND'. */
export type DbAgentDefaultNylasEmailIntegrationBoolExp = {
  _and?: InputMaybe<Array<DbAgentDefaultNylasEmailIntegrationBoolExp>>;
  _not?: InputMaybe<DbAgentDefaultNylasEmailIntegrationBoolExp>;
  _or?: InputMaybe<Array<DbAgentDefaultNylasEmailIntegrationBoolExp>>;
  agent?: InputMaybe<DbAgentBoolExp>;
  agentId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  integrationId?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  nylasEmailIntegration?: InputMaybe<DbNylasEmailIntegrationBoolExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbAgentDefaultNylasEmailIntegrationMaxFields = {
  __typename?: 'DbAgentDefaultNylasEmailIntegrationMaxFields';
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  shopId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.agent_default_nylas_email_integration" */
export type DbAgentDefaultNylasEmailIntegrationMaxOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  integrationId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbAgentDefaultNylasEmailIntegrationMinFields = {
  __typename?: 'DbAgentDefaultNylasEmailIntegrationMinFields';
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  shopId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.agent_default_nylas_email_integration" */
export type DbAgentDefaultNylasEmailIntegrationMinOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  integrationId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.agent_default_nylas_email_integration". */
export type DbAgentDefaultNylasEmailIntegrationOrderBy = {
  agent?: InputMaybe<DbAgentOrderBy>;
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  integrationId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  nylasEmailIntegration?: InputMaybe<DbNylasEmailIntegrationOrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.agent_default_nylas_email_integration" */
export type DbAgentDefaultNylasEmailIntegrationSelectColumn =
  /** column name */
  | 'agentId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'integrationId'
  /** column name */
  | 'num'
  /** column name */
  | 'shopId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbAgentDefaultNylasEmailIntegrationAggregateBoolExpBool_andArgumentsColumns" columns of table "db.agent_default_nylas_email_integration" */
export type DbAgentDefaultNylasEmailIntegrationSelectColumnDbAgentDefaultNylasEmailIntegrationAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbAgentDefaultNylasEmailIntegrationAggregateBoolExpBool_orArgumentsColumns" columns of table "db.agent_default_nylas_email_integration" */
export type DbAgentDefaultNylasEmailIntegrationSelectColumnDbAgentDefaultNylasEmailIntegrationAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbAgentDefaultNylasEmailIntegrationStddevFields = {
  __typename?: 'DbAgentDefaultNylasEmailIntegrationStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.agent_default_nylas_email_integration" */
export type DbAgentDefaultNylasEmailIntegrationStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbAgentDefaultNylasEmailIntegrationStddevPopFields = {
  __typename?: 'DbAgentDefaultNylasEmailIntegrationStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.agent_default_nylas_email_integration" */
export type DbAgentDefaultNylasEmailIntegrationStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbAgentDefaultNylasEmailIntegrationStddevSampFields = {
  __typename?: 'DbAgentDefaultNylasEmailIntegrationStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.agent_default_nylas_email_integration" */
export type DbAgentDefaultNylasEmailIntegrationStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_agent_default_nylas_email_integration" */
export type DbAgentDefaultNylasEmailIntegrationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbAgentDefaultNylasEmailIntegrationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbAgentDefaultNylasEmailIntegrationStreamCursorValueInput = {
  agentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  integrationId?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  shopId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbAgentDefaultNylasEmailIntegrationSumFields = {
  __typename?: 'DbAgentDefaultNylasEmailIntegrationSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.agent_default_nylas_email_integration" */
export type DbAgentDefaultNylasEmailIntegrationSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbAgentDefaultNylasEmailIntegrationVarPopFields = {
  __typename?: 'DbAgentDefaultNylasEmailIntegrationVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.agent_default_nylas_email_integration" */
export type DbAgentDefaultNylasEmailIntegrationVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbAgentDefaultNylasEmailIntegrationVarSampFields = {
  __typename?: 'DbAgentDefaultNylasEmailIntegrationVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.agent_default_nylas_email_integration" */
export type DbAgentDefaultNylasEmailIntegrationVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbAgentDefaultNylasEmailIntegrationVarianceFields = {
  __typename?: 'DbAgentDefaultNylasEmailIntegrationVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.agent_default_nylas_email_integration" */
export type DbAgentDefaultNylasEmailIntegrationVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate max on columns */
export type DbAgentMaxFields = {
  __typename?: 'DbAgentMaxFields';
  activeSince?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  ldapId?: Maybe<Scalars['uuid']>;
  mobilePhoneNumber?: Maybe<Scalars['String']>;
  normalizedFirstName?: Maybe<Scalars['String']>;
  normalizedLastName?: Maybe<Scalars['String']>;
  normalizedUserName?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  officePhoneNumber?: Maybe<Scalars['String']>;
  primaryShopId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.agent" */
export type DbAgentMaxOrderBy = {
  activeSince?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  ldapId?: InputMaybe<OrderBy>;
  mobilePhoneNumber?: InputMaybe<OrderBy>;
  normalizedFirstName?: InputMaybe<OrderBy>;
  normalizedLastName?: InputMaybe<OrderBy>;
  normalizedUserName?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  officePhoneNumber?: InputMaybe<OrderBy>;
  primaryShopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  userName?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbAgentMinFields = {
  __typename?: 'DbAgentMinFields';
  activeSince?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  ldapId?: Maybe<Scalars['uuid']>;
  mobilePhoneNumber?: Maybe<Scalars['String']>;
  normalizedFirstName?: Maybe<Scalars['String']>;
  normalizedLastName?: Maybe<Scalars['String']>;
  normalizedUserName?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  officePhoneNumber?: Maybe<Scalars['String']>;
  primaryShopId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.agent" */
export type DbAgentMinOrderBy = {
  activeSince?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  ldapId?: InputMaybe<OrderBy>;
  mobilePhoneNumber?: InputMaybe<OrderBy>;
  normalizedFirstName?: InputMaybe<OrderBy>;
  normalizedLastName?: InputMaybe<OrderBy>;
  normalizedUserName?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  officePhoneNumber?: InputMaybe<OrderBy>;
  primaryShopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  userName?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.agent". */
export type DbAgentOrderBy = {
  activeSince?: InputMaybe<OrderBy>;
  appointmentAgentsAggregate?: InputMaybe<DbAppointmentAgentAggregateOrderBy>;
  appointmentsAggregate?: InputMaybe<DbAppointmentAggregateOrderBy>;
  betaTester?: InputMaybe<OrderBy>;
  callsAggregate?: InputMaybe<DbCallAggregateOrderBy>;
  contactAssignmentsAggregate?: InputMaybe<DbContactAssignmentAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  defaultEmailSignaturesAggregate?: InputMaybe<DbAgentDefaultEmailSignatureAggregateOrderBy>;
  defaultNylasEmailIntegrationsAggregate?: InputMaybe<DbAgentDefaultNylasEmailIntegrationAggregateOrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  emailSignaturesAggregate?: InputMaybe<DbEmailSignatureAggregateOrderBy>;
  emailTemplatesAggregate?: InputMaybe<DbEmailTemplateAggregateOrderBy>;
  energyAdvisoryEnabled?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  ldapId?: InputMaybe<OrderBy>;
  leadhubGo3ContactLinksByFirstAgentIdAggregate?: InputMaybe<DbLeadhubGo3ContactLinkAggregateOrderBy>;
  leadhubGo3ContactLinksBySecondAgentIdAggregate?: InputMaybe<DbLeadhubGo3ContactLinkAggregateOrderBy>;
  leadhubGo3ContactLinksByThirdAgentIdAggregate?: InputMaybe<DbLeadhubGo3ContactLinkAggregateOrderBy>;
  leadsAggregate?: InputMaybe<DbLeadAggregateOrderBy>;
  mobilePhoneNumber?: InputMaybe<OrderBy>;
  normalizedFirstName?: InputMaybe<OrderBy>;
  normalizedLastName?: InputMaybe<OrderBy>;
  normalizedUserName?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  nylasEmailIntegrationsAggregate?: InputMaybe<DbNylasEmailIntegrationAggregateOrderBy>;
  officePhoneNumber?: InputMaybe<OrderBy>;
  primaryShop?: InputMaybe<DbShopOrderBy>;
  primaryShopId?: InputMaybe<OrderBy>;
  profilesAggregate?: InputMaybe<DbAgentProfileAggregateOrderBy>;
  referralsAggregate?: InputMaybe<DbReferralAggregateOrderBy>;
  referralsByReceivingAgentIdAggregate?: InputMaybe<DbReferralAggregateOrderBy>;
  salutation?: InputMaybe<OrderBy>;
  salutationBySalutation?: InputMaybe<EnumsSalutationOrderBy>;
  sessionsAggregate?: InputMaybe<DbSessionAggregateOrderBy>;
  shopProfileFeaturedAgentsAggregate?: InputMaybe<DbShopProfileFeaturedAgentAggregateOrderBy>;
  status?: InputMaybe<OrderBy>;
  taskAgentsAggregate?: InputMaybe<DbTaskAgentAggregateOrderBy>;
  tasksAggregate?: InputMaybe<DbTaskAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  userAdminAssignmentsAggregate?: InputMaybe<DbUserAdminAssignmentAggregateOrderBy>;
  userName?: InputMaybe<OrderBy>;
  userShopAssignmentsAggregate?: InputMaybe<DbUserShopAssignmentAggregateOrderBy>;
  userTeamAssignmentsAggregate?: InputMaybe<DbUserTeamAssignmentAggregateOrderBy>;
};

/** columns and relationships of "db.agent_profile" */
export type DbAgentProfile = {
  __typename?: 'DbAgentProfile';
  /** An object relationship */
  agent: DbAgent;
  agentId: Scalars['String'];
  /** An object relationship */
  agentProfileSocialMediaProfile?: Maybe<DbAgentProfileSocialMediaProfiles>;
  /** An array relationship */
  cityDistricts: Array<DbAgentProfileCityDistrict>;
  /** An aggregate relationship */
  cityDistrictsAggregate: DbAgentProfileCityDistrictAggregate;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  descriptions: Array<DbAgentProfileDescription>;
  /** An aggregate relationship */
  descriptionsAggregate: DbAgentProfileDescriptionAggregate;
  /** An array relationship */
  expertises: Array<DbAgentProfileExpertise>;
  /** An aggregate relationship */
  expertisesAggregate: DbAgentProfileExpertiseAggregate;
  id: Scalars['String'];
  /** An array relationship */
  images: Array<DbAgentProfileImage>;
  num: Scalars['bigint'];
  published?: Maybe<Scalars['timestamp']>;
  /** An array relationship */
  spokenLanguages: Array<DbAgentProfileSpokenLanguage>;
  /** An aggregate relationship */
  spokenLanguagesAggregate: DbAgentProfileSpokenLanguageAggregate;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** columns and relationships of "db.agent_profile" */
export type DbAgentProfileCityDistrictsArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileCityDistrictSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileCityDistrictOrderBy>>;
  where?: InputMaybe<DbAgentProfileCityDistrictBoolExp>;
};

/** columns and relationships of "db.agent_profile" */
export type DbAgentProfileCityDistrictsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileCityDistrictSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileCityDistrictOrderBy>>;
  where?: InputMaybe<DbAgentProfileCityDistrictBoolExp>;
};

/** columns and relationships of "db.agent_profile" */
export type DbAgentProfileDescriptionsArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileDescriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileDescriptionOrderBy>>;
  where?: InputMaybe<DbAgentProfileDescriptionBoolExp>;
};

/** columns and relationships of "db.agent_profile" */
export type DbAgentProfileDescriptionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileDescriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileDescriptionOrderBy>>;
  where?: InputMaybe<DbAgentProfileDescriptionBoolExp>;
};

/** columns and relationships of "db.agent_profile" */
export type DbAgentProfileExpertisesArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileExpertiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileExpertiseOrderBy>>;
  where?: InputMaybe<DbAgentProfileExpertiseBoolExp>;
};

/** columns and relationships of "db.agent_profile" */
export type DbAgentProfileExpertisesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileExpertiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileExpertiseOrderBy>>;
  where?: InputMaybe<DbAgentProfileExpertiseBoolExp>;
};

/** columns and relationships of "db.agent_profile" */
export type DbAgentProfileImagesArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileImageOrderBy>>;
  where?: InputMaybe<DbAgentProfileImageBoolExp>;
};

/** columns and relationships of "db.agent_profile" */
export type DbAgentProfileSpokenLanguagesArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileSpokenLanguageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileSpokenLanguageOrderBy>>;
  where?: InputMaybe<DbAgentProfileSpokenLanguageBoolExp>;
};

/** columns and relationships of "db.agent_profile" */
export type DbAgentProfileSpokenLanguagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileSpokenLanguageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileSpokenLanguageOrderBy>>;
  where?: InputMaybe<DbAgentProfileSpokenLanguageBoolExp>;
};

/** aggregated selection of "db.agent_profile" */
export type DbAgentProfileAggregate = {
  __typename?: 'DbAgentProfileAggregate';
  aggregate?: Maybe<DbAgentProfileAggregateFields>;
  nodes: Array<DbAgentProfile>;
};

export type DbAgentProfileAggregateBoolExp = {
  bool_and?: InputMaybe<DbAgentProfileAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbAgentProfileAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbAgentProfileAggregateBoolExpCount>;
};

/** aggregate fields of "db.agent_profile" */
export type DbAgentProfileAggregateFields = {
  __typename?: 'DbAgentProfileAggregateFields';
  avg?: Maybe<DbAgentProfileAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbAgentProfileMaxFields>;
  min?: Maybe<DbAgentProfileMinFields>;
  stddev?: Maybe<DbAgentProfileStddevFields>;
  stddevPop?: Maybe<DbAgentProfileStddevPopFields>;
  stddevSamp?: Maybe<DbAgentProfileStddevSampFields>;
  sum?: Maybe<DbAgentProfileSumFields>;
  varPop?: Maybe<DbAgentProfileVarPopFields>;
  varSamp?: Maybe<DbAgentProfileVarSampFields>;
  variance?: Maybe<DbAgentProfileVarianceFields>;
};

/** aggregate fields of "db.agent_profile" */
export type DbAgentProfileAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbAgentProfileSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.agent_profile" */
export type DbAgentProfileAggregateOrderBy = {
  avg?: InputMaybe<DbAgentProfileAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbAgentProfileMaxOrderBy>;
  min?: InputMaybe<DbAgentProfileMinOrderBy>;
  stddev?: InputMaybe<DbAgentProfileStddevOrderBy>;
  stddevPop?: InputMaybe<DbAgentProfileStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbAgentProfileStddevSampOrderBy>;
  sum?: InputMaybe<DbAgentProfileSumOrderBy>;
  varPop?: InputMaybe<DbAgentProfileVarPopOrderBy>;
  varSamp?: InputMaybe<DbAgentProfileVarSampOrderBy>;
  variance?: InputMaybe<DbAgentProfileVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbAgentProfileAvgFields = {
  __typename?: 'DbAgentProfileAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.agent_profile" */
export type DbAgentProfileAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.agent_profile". All fields are combined with a logical 'AND'. */
export type DbAgentProfileBoolExp = {
  _and?: InputMaybe<Array<DbAgentProfileBoolExp>>;
  _not?: InputMaybe<DbAgentProfileBoolExp>;
  _or?: InputMaybe<Array<DbAgentProfileBoolExp>>;
  agent?: InputMaybe<DbAgentBoolExp>;
  agentId?: InputMaybe<StringComparisonExp>;
  agentProfileSocialMediaProfile?: InputMaybe<DbAgentProfileSocialMediaProfilesBoolExp>;
  cityDistricts?: InputMaybe<DbAgentProfileCityDistrictBoolExp>;
  cityDistrictsAggregate?: InputMaybe<DbAgentProfileCityDistrictAggregateBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  descriptions?: InputMaybe<DbAgentProfileDescriptionBoolExp>;
  descriptionsAggregate?: InputMaybe<DbAgentProfileDescriptionAggregateBoolExp>;
  expertises?: InputMaybe<DbAgentProfileExpertiseBoolExp>;
  expertisesAggregate?: InputMaybe<DbAgentProfileExpertiseAggregateBoolExp>;
  id?: InputMaybe<StringComparisonExp>;
  images?: InputMaybe<DbAgentProfileImageBoolExp>;
  num?: InputMaybe<BigintComparisonExp>;
  published?: InputMaybe<TimestampComparisonExp>;
  spokenLanguages?: InputMaybe<DbAgentProfileSpokenLanguageBoolExp>;
  spokenLanguagesAggregate?: InputMaybe<DbAgentProfileSpokenLanguageAggregateBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** columns and relationships of "db.agent_profile_city_district" */
export type DbAgentProfileCityDistrict = {
  __typename?: 'DbAgentProfileCityDistrict';
  /** An object relationship */
  agentProfile: DbAgentProfile;
  agentProfileId: Scalars['String'];
  city: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  district?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  num: Scalars['bigint'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.agent_profile_city_district" */
export type DbAgentProfileCityDistrictAggregate = {
  __typename?: 'DbAgentProfileCityDistrictAggregate';
  aggregate?: Maybe<DbAgentProfileCityDistrictAggregateFields>;
  nodes: Array<DbAgentProfileCityDistrict>;
};

export type DbAgentProfileCityDistrictAggregateBoolExp = {
  bool_and?: InputMaybe<DbAgentProfileCityDistrictAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbAgentProfileCityDistrictAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbAgentProfileCityDistrictAggregateBoolExpCount>;
};

/** aggregate fields of "db.agent_profile_city_district" */
export type DbAgentProfileCityDistrictAggregateFields = {
  __typename?: 'DbAgentProfileCityDistrictAggregateFields';
  avg?: Maybe<DbAgentProfileCityDistrictAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbAgentProfileCityDistrictMaxFields>;
  min?: Maybe<DbAgentProfileCityDistrictMinFields>;
  stddev?: Maybe<DbAgentProfileCityDistrictStddevFields>;
  stddevPop?: Maybe<DbAgentProfileCityDistrictStddevPopFields>;
  stddevSamp?: Maybe<DbAgentProfileCityDistrictStddevSampFields>;
  sum?: Maybe<DbAgentProfileCityDistrictSumFields>;
  varPop?: Maybe<DbAgentProfileCityDistrictVarPopFields>;
  varSamp?: Maybe<DbAgentProfileCityDistrictVarSampFields>;
  variance?: Maybe<DbAgentProfileCityDistrictVarianceFields>;
};

/** aggregate fields of "db.agent_profile_city_district" */
export type DbAgentProfileCityDistrictAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbAgentProfileCityDistrictSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.agent_profile_city_district" */
export type DbAgentProfileCityDistrictAggregateOrderBy = {
  avg?: InputMaybe<DbAgentProfileCityDistrictAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbAgentProfileCityDistrictMaxOrderBy>;
  min?: InputMaybe<DbAgentProfileCityDistrictMinOrderBy>;
  stddev?: InputMaybe<DbAgentProfileCityDistrictStddevOrderBy>;
  stddevPop?: InputMaybe<DbAgentProfileCityDistrictStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbAgentProfileCityDistrictStddevSampOrderBy>;
  sum?: InputMaybe<DbAgentProfileCityDistrictSumOrderBy>;
  varPop?: InputMaybe<DbAgentProfileCityDistrictVarPopOrderBy>;
  varSamp?: InputMaybe<DbAgentProfileCityDistrictVarSampOrderBy>;
  variance?: InputMaybe<DbAgentProfileCityDistrictVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbAgentProfileCityDistrictAvgFields = {
  __typename?: 'DbAgentProfileCityDistrictAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.agent_profile_city_district" */
export type DbAgentProfileCityDistrictAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.agent_profile_city_district". All fields are combined with a logical 'AND'. */
export type DbAgentProfileCityDistrictBoolExp = {
  _and?: InputMaybe<Array<DbAgentProfileCityDistrictBoolExp>>;
  _not?: InputMaybe<DbAgentProfileCityDistrictBoolExp>;
  _or?: InputMaybe<Array<DbAgentProfileCityDistrictBoolExp>>;
  agentProfile?: InputMaybe<DbAgentProfileBoolExp>;
  agentProfileId?: InputMaybe<StringComparisonExp>;
  city?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  district?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbAgentProfileCityDistrictMaxFields = {
  __typename?: 'DbAgentProfileCityDistrictMaxFields';
  agentProfileId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  district?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.agent_profile_city_district" */
export type DbAgentProfileCityDistrictMaxOrderBy = {
  agentProfileId?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  district?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbAgentProfileCityDistrictMinFields = {
  __typename?: 'DbAgentProfileCityDistrictMinFields';
  agentProfileId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  district?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.agent_profile_city_district" */
export type DbAgentProfileCityDistrictMinOrderBy = {
  agentProfileId?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  district?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.agent_profile_city_district". */
export type DbAgentProfileCityDistrictOrderBy = {
  agentProfile?: InputMaybe<DbAgentProfileOrderBy>;
  agentProfileId?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  district?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.agent_profile_city_district" */
export type DbAgentProfileCityDistrictSelectColumn =
  /** column name */
  | 'agentProfileId'
  /** column name */
  | 'city'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'district'
  /** column name */
  | 'id'
  /** column name */
  | 'num'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbAgentProfileCityDistrictAggregateBoolExpBool_andArgumentsColumns" columns of table "db.agent_profile_city_district" */
export type DbAgentProfileCityDistrictSelectColumnDbAgentProfileCityDistrictAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbAgentProfileCityDistrictAggregateBoolExpBool_orArgumentsColumns" columns of table "db.agent_profile_city_district" */
export type DbAgentProfileCityDistrictSelectColumnDbAgentProfileCityDistrictAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbAgentProfileCityDistrictStddevFields = {
  __typename?: 'DbAgentProfileCityDistrictStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.agent_profile_city_district" */
export type DbAgentProfileCityDistrictStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbAgentProfileCityDistrictStddevPopFields = {
  __typename?: 'DbAgentProfileCityDistrictStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.agent_profile_city_district" */
export type DbAgentProfileCityDistrictStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbAgentProfileCityDistrictStddevSampFields = {
  __typename?: 'DbAgentProfileCityDistrictStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.agent_profile_city_district" */
export type DbAgentProfileCityDistrictStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_agent_profile_city_district" */
export type DbAgentProfileCityDistrictStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbAgentProfileCityDistrictStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbAgentProfileCityDistrictStreamCursorValueInput = {
  agentProfileId?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  district?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbAgentProfileCityDistrictSumFields = {
  __typename?: 'DbAgentProfileCityDistrictSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.agent_profile_city_district" */
export type DbAgentProfileCityDistrictSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbAgentProfileCityDistrictVarPopFields = {
  __typename?: 'DbAgentProfileCityDistrictVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.agent_profile_city_district" */
export type DbAgentProfileCityDistrictVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbAgentProfileCityDistrictVarSampFields = {
  __typename?: 'DbAgentProfileCityDistrictVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.agent_profile_city_district" */
export type DbAgentProfileCityDistrictVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbAgentProfileCityDistrictVarianceFields = {
  __typename?: 'DbAgentProfileCityDistrictVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.agent_profile_city_district" */
export type DbAgentProfileCityDistrictVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.agent_profile_description" */
export type DbAgentProfileDescription = {
  __typename?: 'DbAgentProfileDescription';
  academicTitle?: Maybe<Scalars['String']>;
  /** An object relationship */
  agentProfile: DbAgentProfile;
  agentProfileId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  isAiTranslated?: Maybe<Scalars['Boolean']>;
  jobTitle?: Maybe<Scalars['String']>;
  lang: LanguageEnum;
  /** An object relationship */
  language: EnumsLanguage;
  num: Scalars['bigint'];
  text?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

/** aggregated selection of "db.agent_profile_description" */
export type DbAgentProfileDescriptionAggregate = {
  __typename?: 'DbAgentProfileDescriptionAggregate';
  aggregate?: Maybe<DbAgentProfileDescriptionAggregateFields>;
  nodes: Array<DbAgentProfileDescription>;
};

export type DbAgentProfileDescriptionAggregateBoolExp = {
  bool_and?: InputMaybe<DbAgentProfileDescriptionAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbAgentProfileDescriptionAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbAgentProfileDescriptionAggregateBoolExpCount>;
};

/** aggregate fields of "db.agent_profile_description" */
export type DbAgentProfileDescriptionAggregateFields = {
  __typename?: 'DbAgentProfileDescriptionAggregateFields';
  avg?: Maybe<DbAgentProfileDescriptionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbAgentProfileDescriptionMaxFields>;
  min?: Maybe<DbAgentProfileDescriptionMinFields>;
  stddev?: Maybe<DbAgentProfileDescriptionStddevFields>;
  stddevPop?: Maybe<DbAgentProfileDescriptionStddevPopFields>;
  stddevSamp?: Maybe<DbAgentProfileDescriptionStddevSampFields>;
  sum?: Maybe<DbAgentProfileDescriptionSumFields>;
  varPop?: Maybe<DbAgentProfileDescriptionVarPopFields>;
  varSamp?: Maybe<DbAgentProfileDescriptionVarSampFields>;
  variance?: Maybe<DbAgentProfileDescriptionVarianceFields>;
};

/** aggregate fields of "db.agent_profile_description" */
export type DbAgentProfileDescriptionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbAgentProfileDescriptionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.agent_profile_description" */
export type DbAgentProfileDescriptionAggregateOrderBy = {
  avg?: InputMaybe<DbAgentProfileDescriptionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbAgentProfileDescriptionMaxOrderBy>;
  min?: InputMaybe<DbAgentProfileDescriptionMinOrderBy>;
  stddev?: InputMaybe<DbAgentProfileDescriptionStddevOrderBy>;
  stddevPop?: InputMaybe<DbAgentProfileDescriptionStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbAgentProfileDescriptionStddevSampOrderBy>;
  sum?: InputMaybe<DbAgentProfileDescriptionSumOrderBy>;
  varPop?: InputMaybe<DbAgentProfileDescriptionVarPopOrderBy>;
  varSamp?: InputMaybe<DbAgentProfileDescriptionVarSampOrderBy>;
  variance?: InputMaybe<DbAgentProfileDescriptionVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbAgentProfileDescriptionAvgFields = {
  __typename?: 'DbAgentProfileDescriptionAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.agent_profile_description" */
export type DbAgentProfileDescriptionAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.agent_profile_description". All fields are combined with a logical 'AND'. */
export type DbAgentProfileDescriptionBoolExp = {
  _and?: InputMaybe<Array<DbAgentProfileDescriptionBoolExp>>;
  _not?: InputMaybe<DbAgentProfileDescriptionBoolExp>;
  _or?: InputMaybe<Array<DbAgentProfileDescriptionBoolExp>>;
  academicTitle?: InputMaybe<StringComparisonExp>;
  agentProfile?: InputMaybe<DbAgentProfileBoolExp>;
  agentProfileId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  isAiTranslated?: InputMaybe<BooleanComparisonExp>;
  jobTitle?: InputMaybe<StringComparisonExp>;
  lang?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  language?: InputMaybe<EnumsLanguageBoolExp>;
  num?: InputMaybe<BigintComparisonExp>;
  text?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbAgentProfileDescriptionMaxFields = {
  __typename?: 'DbAgentProfileDescriptionMaxFields';
  academicTitle?: Maybe<Scalars['String']>;
  agentProfileId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.agent_profile_description" */
export type DbAgentProfileDescriptionMaxOrderBy = {
  academicTitle?: InputMaybe<OrderBy>;
  agentProfileId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  jobTitle?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbAgentProfileDescriptionMinFields = {
  __typename?: 'DbAgentProfileDescriptionMinFields';
  academicTitle?: Maybe<Scalars['String']>;
  agentProfileId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.agent_profile_description" */
export type DbAgentProfileDescriptionMinOrderBy = {
  academicTitle?: InputMaybe<OrderBy>;
  agentProfileId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  jobTitle?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.agent_profile_description". */
export type DbAgentProfileDescriptionOrderBy = {
  academicTitle?: InputMaybe<OrderBy>;
  agentProfile?: InputMaybe<DbAgentProfileOrderBy>;
  agentProfileId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isAiTranslated?: InputMaybe<OrderBy>;
  jobTitle?: InputMaybe<OrderBy>;
  lang?: InputMaybe<OrderBy>;
  language?: InputMaybe<EnumsLanguageOrderBy>;
  num?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "db.agent_profile_description" */
export type DbAgentProfileDescriptionSelectColumn =
  /** column name */
  | 'academicTitle'
  /** column name */
  | 'agentProfileId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'isAiTranslated'
  /** column name */
  | 'jobTitle'
  /** column name */
  | 'lang'
  /** column name */
  | 'num'
  /** column name */
  | 'text'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy'
  /** column name */
  | 'url';

/** select "dbAgentProfileDescriptionAggregateBoolExpBool_andArgumentsColumns" columns of table "db.agent_profile_description" */
export type DbAgentProfileDescriptionSelectColumnDbAgentProfileDescriptionAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  | 'deleted'
  /** column name */
  | 'isAiTranslated';

/** select "dbAgentProfileDescriptionAggregateBoolExpBool_orArgumentsColumns" columns of table "db.agent_profile_description" */
export type DbAgentProfileDescriptionSelectColumnDbAgentProfileDescriptionAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  | 'deleted'
  /** column name */
  | 'isAiTranslated';

/** aggregate stddev on columns */
export type DbAgentProfileDescriptionStddevFields = {
  __typename?: 'DbAgentProfileDescriptionStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.agent_profile_description" */
export type DbAgentProfileDescriptionStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbAgentProfileDescriptionStddevPopFields = {
  __typename?: 'DbAgentProfileDescriptionStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.agent_profile_description" */
export type DbAgentProfileDescriptionStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbAgentProfileDescriptionStddevSampFields = {
  __typename?: 'DbAgentProfileDescriptionStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.agent_profile_description" */
export type DbAgentProfileDescriptionStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_agent_profile_description" */
export type DbAgentProfileDescriptionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbAgentProfileDescriptionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbAgentProfileDescriptionStreamCursorValueInput = {
  academicTitle?: InputMaybe<Scalars['String']>;
  agentProfileId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  isAiTranslated?: InputMaybe<Scalars['Boolean']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<LanguageEnum>;
  num?: InputMaybe<Scalars['bigint']>;
  text?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbAgentProfileDescriptionSumFields = {
  __typename?: 'DbAgentProfileDescriptionSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.agent_profile_description" */
export type DbAgentProfileDescriptionSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbAgentProfileDescriptionVarPopFields = {
  __typename?: 'DbAgentProfileDescriptionVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.agent_profile_description" */
export type DbAgentProfileDescriptionVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbAgentProfileDescriptionVarSampFields = {
  __typename?: 'DbAgentProfileDescriptionVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.agent_profile_description" */
export type DbAgentProfileDescriptionVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbAgentProfileDescriptionVarianceFields = {
  __typename?: 'DbAgentProfileDescriptionVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.agent_profile_description" */
export type DbAgentProfileDescriptionVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.agent_profile_expertise" */
export type DbAgentProfileExpertise = {
  __typename?: 'DbAgentProfileExpertise';
  /** An object relationship */
  agentExpertise: EnumsShopExpertise;
  /** An object relationship */
  agentProfile: DbAgentProfile;
  agentProfileId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  expertise: ShopExpertiseEnum;
  id: Scalars['String'];
  num: Scalars['bigint'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.agent_profile_expertise" */
export type DbAgentProfileExpertiseAggregate = {
  __typename?: 'DbAgentProfileExpertiseAggregate';
  aggregate?: Maybe<DbAgentProfileExpertiseAggregateFields>;
  nodes: Array<DbAgentProfileExpertise>;
};

export type DbAgentProfileExpertiseAggregateBoolExp = {
  bool_and?: InputMaybe<DbAgentProfileExpertiseAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbAgentProfileExpertiseAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbAgentProfileExpertiseAggregateBoolExpCount>;
};

/** aggregate fields of "db.agent_profile_expertise" */
export type DbAgentProfileExpertiseAggregateFields = {
  __typename?: 'DbAgentProfileExpertiseAggregateFields';
  avg?: Maybe<DbAgentProfileExpertiseAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbAgentProfileExpertiseMaxFields>;
  min?: Maybe<DbAgentProfileExpertiseMinFields>;
  stddev?: Maybe<DbAgentProfileExpertiseStddevFields>;
  stddevPop?: Maybe<DbAgentProfileExpertiseStddevPopFields>;
  stddevSamp?: Maybe<DbAgentProfileExpertiseStddevSampFields>;
  sum?: Maybe<DbAgentProfileExpertiseSumFields>;
  varPop?: Maybe<DbAgentProfileExpertiseVarPopFields>;
  varSamp?: Maybe<DbAgentProfileExpertiseVarSampFields>;
  variance?: Maybe<DbAgentProfileExpertiseVarianceFields>;
};

/** aggregate fields of "db.agent_profile_expertise" */
export type DbAgentProfileExpertiseAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbAgentProfileExpertiseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.agent_profile_expertise" */
export type DbAgentProfileExpertiseAggregateOrderBy = {
  avg?: InputMaybe<DbAgentProfileExpertiseAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbAgentProfileExpertiseMaxOrderBy>;
  min?: InputMaybe<DbAgentProfileExpertiseMinOrderBy>;
  stddev?: InputMaybe<DbAgentProfileExpertiseStddevOrderBy>;
  stddevPop?: InputMaybe<DbAgentProfileExpertiseStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbAgentProfileExpertiseStddevSampOrderBy>;
  sum?: InputMaybe<DbAgentProfileExpertiseSumOrderBy>;
  varPop?: InputMaybe<DbAgentProfileExpertiseVarPopOrderBy>;
  varSamp?: InputMaybe<DbAgentProfileExpertiseVarSampOrderBy>;
  variance?: InputMaybe<DbAgentProfileExpertiseVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbAgentProfileExpertiseAvgFields = {
  __typename?: 'DbAgentProfileExpertiseAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.agent_profile_expertise" */
export type DbAgentProfileExpertiseAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.agent_profile_expertise". All fields are combined with a logical 'AND'. */
export type DbAgentProfileExpertiseBoolExp = {
  _and?: InputMaybe<Array<DbAgentProfileExpertiseBoolExp>>;
  _not?: InputMaybe<DbAgentProfileExpertiseBoolExp>;
  _or?: InputMaybe<Array<DbAgentProfileExpertiseBoolExp>>;
  agentExpertise?: InputMaybe<EnumsShopExpertiseBoolExp>;
  agentProfile?: InputMaybe<DbAgentProfileBoolExp>;
  agentProfileId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  expertise?: InputMaybe<EnumsShopExpertiseEnumComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbAgentProfileExpertiseMaxFields = {
  __typename?: 'DbAgentProfileExpertiseMaxFields';
  agentProfileId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.agent_profile_expertise" */
export type DbAgentProfileExpertiseMaxOrderBy = {
  agentProfileId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbAgentProfileExpertiseMinFields = {
  __typename?: 'DbAgentProfileExpertiseMinFields';
  agentProfileId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.agent_profile_expertise" */
export type DbAgentProfileExpertiseMinOrderBy = {
  agentProfileId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.agent_profile_expertise". */
export type DbAgentProfileExpertiseOrderBy = {
  agentExpertise?: InputMaybe<EnumsShopExpertiseOrderBy>;
  agentProfile?: InputMaybe<DbAgentProfileOrderBy>;
  agentProfileId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  expertise?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.agent_profile_expertise" */
export type DbAgentProfileExpertiseSelectColumn =
  /** column name */
  | 'agentProfileId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'expertise'
  /** column name */
  | 'id'
  /** column name */
  | 'num'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbAgentProfileExpertiseAggregateBoolExpBool_andArgumentsColumns" columns of table "db.agent_profile_expertise" */
export type DbAgentProfileExpertiseSelectColumnDbAgentProfileExpertiseAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbAgentProfileExpertiseAggregateBoolExpBool_orArgumentsColumns" columns of table "db.agent_profile_expertise" */
export type DbAgentProfileExpertiseSelectColumnDbAgentProfileExpertiseAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbAgentProfileExpertiseStddevFields = {
  __typename?: 'DbAgentProfileExpertiseStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.agent_profile_expertise" */
export type DbAgentProfileExpertiseStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbAgentProfileExpertiseStddevPopFields = {
  __typename?: 'DbAgentProfileExpertiseStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.agent_profile_expertise" */
export type DbAgentProfileExpertiseStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbAgentProfileExpertiseStddevSampFields = {
  __typename?: 'DbAgentProfileExpertiseStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.agent_profile_expertise" */
export type DbAgentProfileExpertiseStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_agent_profile_expertise" */
export type DbAgentProfileExpertiseStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbAgentProfileExpertiseStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbAgentProfileExpertiseStreamCursorValueInput = {
  agentProfileId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  expertise?: InputMaybe<ShopExpertiseEnum>;
  id?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbAgentProfileExpertiseSumFields = {
  __typename?: 'DbAgentProfileExpertiseSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.agent_profile_expertise" */
export type DbAgentProfileExpertiseSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbAgentProfileExpertiseVarPopFields = {
  __typename?: 'DbAgentProfileExpertiseVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.agent_profile_expertise" */
export type DbAgentProfileExpertiseVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbAgentProfileExpertiseVarSampFields = {
  __typename?: 'DbAgentProfileExpertiseVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.agent_profile_expertise" */
export type DbAgentProfileExpertiseVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbAgentProfileExpertiseVarianceFields = {
  __typename?: 'DbAgentProfileExpertiseVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.agent_profile_expertise" */
export type DbAgentProfileExpertiseVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.agent_profile_former_url" */
export type DbAgentProfileFormerUrl = {
  __typename?: 'DbAgentProfileFormerUrl';
  agentId: Scalars['String'];
  /** An object relationship */
  agent_profile_former_url_agent_created_by: DbAgent;
  /** An object relationship */
  agent_profile_former_url_agent_id: DbAgent;
  /** An object relationship */
  agent_profile_former_url_agent_updated_by: DbAgent;
  /** An object relationship */
  agent_profile_former_url_language: EnumsLanguage;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  formerUrl: Scalars['String'];
  id: Scalars['String'];
  lang: LanguageEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.agent_profile_former_url" */
export type DbAgentProfileFormerUrlAggregate = {
  __typename?: 'DbAgentProfileFormerUrlAggregate';
  aggregate?: Maybe<DbAgentProfileFormerUrlAggregateFields>;
  nodes: Array<DbAgentProfileFormerUrl>;
};

/** aggregate fields of "db.agent_profile_former_url" */
export type DbAgentProfileFormerUrlAggregateFields = {
  __typename?: 'DbAgentProfileFormerUrlAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<DbAgentProfileFormerUrlMaxFields>;
  min?: Maybe<DbAgentProfileFormerUrlMinFields>;
};

/** aggregate fields of "db.agent_profile_former_url" */
export type DbAgentProfileFormerUrlAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbAgentProfileFormerUrlSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "db.agent_profile_former_url". All fields are combined with a logical 'AND'. */
export type DbAgentProfileFormerUrlBoolExp = {
  _and?: InputMaybe<Array<DbAgentProfileFormerUrlBoolExp>>;
  _not?: InputMaybe<DbAgentProfileFormerUrlBoolExp>;
  _or?: InputMaybe<Array<DbAgentProfileFormerUrlBoolExp>>;
  agentId?: InputMaybe<StringComparisonExp>;
  agent_profile_former_url_agent_created_by?: InputMaybe<DbAgentBoolExp>;
  agent_profile_former_url_agent_id?: InputMaybe<DbAgentBoolExp>;
  agent_profile_former_url_agent_updated_by?: InputMaybe<DbAgentBoolExp>;
  agent_profile_former_url_language?: InputMaybe<EnumsLanguageBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  formerUrl?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lang?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbAgentProfileFormerUrlMaxFields = {
  __typename?: 'DbAgentProfileFormerUrlMaxFields';
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  formerUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type DbAgentProfileFormerUrlMinFields = {
  __typename?: 'DbAgentProfileFormerUrlMinFields';
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  formerUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "db.agent_profile_former_url". */
export type DbAgentProfileFormerUrlOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  agent_profile_former_url_agent_created_by?: InputMaybe<DbAgentOrderBy>;
  agent_profile_former_url_agent_id?: InputMaybe<DbAgentOrderBy>;
  agent_profile_former_url_agent_updated_by?: InputMaybe<DbAgentOrderBy>;
  agent_profile_former_url_language?: InputMaybe<EnumsLanguageOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  formerUrl?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lang?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.agent_profile_former_url" */
export type DbAgentProfileFormerUrlSelectColumn =
  /** column name */
  | 'agentId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'formerUrl'
  /** column name */
  | 'id'
  /** column name */
  | 'lang'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** Streaming cursor of the table "db_agent_profile_former_url" */
export type DbAgentProfileFormerUrlStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbAgentProfileFormerUrlStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbAgentProfileFormerUrlStreamCursorValueInput = {
  agentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  formerUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<LanguageEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "db.agent_profile_image" */
export type DbAgentProfileImage = {
  __typename?: 'DbAgentProfileImage';
  /** An object relationship */
  agentProfile: DbAgentProfile;
  agentProfileId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  gcpFileName: Scalars['String'];
  height: Scalars['Int'];
  id: Scalars['String'];
  index: Scalars['Int'];
  title: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  width: Scalars['Int'];
};

/** order by aggregate values of table "db.agent_profile_image" */
export type DbAgentProfileImageAggregateOrderBy = {
  avg?: InputMaybe<DbAgentProfileImageAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbAgentProfileImageMaxOrderBy>;
  min?: InputMaybe<DbAgentProfileImageMinOrderBy>;
  stddev?: InputMaybe<DbAgentProfileImageStddevOrderBy>;
  stddevPop?: InputMaybe<DbAgentProfileImageStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbAgentProfileImageStddevSampOrderBy>;
  sum?: InputMaybe<DbAgentProfileImageSumOrderBy>;
  varPop?: InputMaybe<DbAgentProfileImageVarPopOrderBy>;
  varSamp?: InputMaybe<DbAgentProfileImageVarSampOrderBy>;
  variance?: InputMaybe<DbAgentProfileImageVarianceOrderBy>;
};

/** order by avg() on columns of table "db.agent_profile_image" */
export type DbAgentProfileImageAvgOrderBy = {
  height?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.agent_profile_image". All fields are combined with a logical 'AND'. */
export type DbAgentProfileImageBoolExp = {
  _and?: InputMaybe<Array<DbAgentProfileImageBoolExp>>;
  _not?: InputMaybe<DbAgentProfileImageBoolExp>;
  _or?: InputMaybe<Array<DbAgentProfileImageBoolExp>>;
  agentProfile?: InputMaybe<DbAgentProfileBoolExp>;
  agentProfileId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  gcpFileName?: InputMaybe<StringComparisonExp>;
  height?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  index?: InputMaybe<IntComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  width?: InputMaybe<IntComparisonExp>;
};

/** order by max() on columns of table "db.agent_profile_image" */
export type DbAgentProfileImageMaxOrderBy = {
  agentProfileId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  gcpFileName?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.agent_profile_image" */
export type DbAgentProfileImageMinOrderBy = {
  agentProfileId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  gcpFileName?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.agent_profile_image". */
export type DbAgentProfileImageOrderBy = {
  agentProfile?: InputMaybe<DbAgentProfileOrderBy>;
  agentProfileId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  gcpFileName?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** select columns of table "db.agent_profile_image" */
export type DbAgentProfileImageSelectColumn =
  /** column name */
  | 'agentProfileId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'gcpFileName'
  /** column name */
  | 'height'
  /** column name */
  | 'id'
  /** column name */
  | 'index'
  /** column name */
  | 'title'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy'
  /** column name */
  | 'width';

/** order by stddev() on columns of table "db.agent_profile_image" */
export type DbAgentProfileImageStddevOrderBy = {
  height?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.agent_profile_image" */
export type DbAgentProfileImageStddevPopOrderBy = {
  height?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.agent_profile_image" */
export type DbAgentProfileImageStddevSampOrderBy = {
  height?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_agent_profile_image" */
export type DbAgentProfileImageStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbAgentProfileImageStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbAgentProfileImageStreamCursorValueInput = {
  agentProfileId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  gcpFileName?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  index?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "db.agent_profile_image" */
export type DbAgentProfileImageSumOrderBy = {
  height?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.agent_profile_image" */
export type DbAgentProfileImageVarPopOrderBy = {
  height?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.agent_profile_image" */
export type DbAgentProfileImageVarSampOrderBy = {
  height?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.agent_profile_image" */
export type DbAgentProfileImageVarianceOrderBy = {
  height?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate max on columns */
export type DbAgentProfileMaxFields = {
  __typename?: 'DbAgentProfileMaxFields';
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  published?: Maybe<Scalars['timestamp']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.agent_profile" */
export type DbAgentProfileMaxOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  published?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbAgentProfileMinFields = {
  __typename?: 'DbAgentProfileMinFields';
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  published?: Maybe<Scalars['timestamp']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.agent_profile" */
export type DbAgentProfileMinOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  published?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.agent_profile". */
export type DbAgentProfileOrderBy = {
  agent?: InputMaybe<DbAgentOrderBy>;
  agentId?: InputMaybe<OrderBy>;
  agentProfileSocialMediaProfile?: InputMaybe<DbAgentProfileSocialMediaProfilesOrderBy>;
  cityDistrictsAggregate?: InputMaybe<DbAgentProfileCityDistrictAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  descriptionsAggregate?: InputMaybe<DbAgentProfileDescriptionAggregateOrderBy>;
  expertisesAggregate?: InputMaybe<DbAgentProfileExpertiseAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  imagesAggregate?: InputMaybe<DbAgentProfileImageAggregateOrderBy>;
  num?: InputMaybe<OrderBy>;
  published?: InputMaybe<OrderBy>;
  spokenLanguagesAggregate?: InputMaybe<DbAgentProfileSpokenLanguageAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.agent_profile" */
export type DbAgentProfileSelectColumn =
  /** column name */
  | 'agentId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'num'
  /** column name */
  | 'published'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbAgentProfileAggregateBoolExpBool_andArgumentsColumns" columns of table "db.agent_profile" */
export type DbAgentProfileSelectColumnDbAgentProfileAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbAgentProfileAggregateBoolExpBool_orArgumentsColumns" columns of table "db.agent_profile" */
export type DbAgentProfileSelectColumnDbAgentProfileAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** columns and relationships of "db.agent_profile_social_media_profiles" */
export type DbAgentProfileSocialMediaProfiles = {
  __typename?: 'DbAgentProfileSocialMediaProfiles';
  /** An object relationship */
  agentProfile: DbAgentProfile;
  agentProfileId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  facebookUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  instagramUrl?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  num: Scalars['bigint'];
  tiktokUrl?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  xingUrl?: Maybe<Scalars['String']>;
};

/** aggregated selection of "db.agent_profile_social_media_profiles" */
export type DbAgentProfileSocialMediaProfilesAggregate = {
  __typename?: 'DbAgentProfileSocialMediaProfilesAggregate';
  aggregate?: Maybe<DbAgentProfileSocialMediaProfilesAggregateFields>;
  nodes: Array<DbAgentProfileSocialMediaProfiles>;
};

/** aggregate fields of "db.agent_profile_social_media_profiles" */
export type DbAgentProfileSocialMediaProfilesAggregateFields = {
  __typename?: 'DbAgentProfileSocialMediaProfilesAggregateFields';
  avg?: Maybe<DbAgentProfileSocialMediaProfilesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbAgentProfileSocialMediaProfilesMaxFields>;
  min?: Maybe<DbAgentProfileSocialMediaProfilesMinFields>;
  stddev?: Maybe<DbAgentProfileSocialMediaProfilesStddevFields>;
  stddevPop?: Maybe<DbAgentProfileSocialMediaProfilesStddevPopFields>;
  stddevSamp?: Maybe<DbAgentProfileSocialMediaProfilesStddevSampFields>;
  sum?: Maybe<DbAgentProfileSocialMediaProfilesSumFields>;
  varPop?: Maybe<DbAgentProfileSocialMediaProfilesVarPopFields>;
  varSamp?: Maybe<DbAgentProfileSocialMediaProfilesVarSampFields>;
  variance?: Maybe<DbAgentProfileSocialMediaProfilesVarianceFields>;
};

/** aggregate fields of "db.agent_profile_social_media_profiles" */
export type DbAgentProfileSocialMediaProfilesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbAgentProfileSocialMediaProfilesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type DbAgentProfileSocialMediaProfilesAvgFields = {
  __typename?: 'DbAgentProfileSocialMediaProfilesAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "db.agent_profile_social_media_profiles". All fields are combined with a logical 'AND'. */
export type DbAgentProfileSocialMediaProfilesBoolExp = {
  _and?: InputMaybe<Array<DbAgentProfileSocialMediaProfilesBoolExp>>;
  _not?: InputMaybe<DbAgentProfileSocialMediaProfilesBoolExp>;
  _or?: InputMaybe<Array<DbAgentProfileSocialMediaProfilesBoolExp>>;
  agentProfile?: InputMaybe<DbAgentProfileBoolExp>;
  agentProfileId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  facebookUrl?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  instagramUrl?: InputMaybe<StringComparisonExp>;
  linkedInUrl?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  tiktokUrl?: InputMaybe<StringComparisonExp>;
  twitterUrl?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  xingUrl?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbAgentProfileSocialMediaProfilesMaxFields = {
  __typename?: 'DbAgentProfileSocialMediaProfilesMaxFields';
  agentProfileId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  facebookUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instagramUrl?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  tiktokUrl?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  xingUrl?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type DbAgentProfileSocialMediaProfilesMinFields = {
  __typename?: 'DbAgentProfileSocialMediaProfilesMinFields';
  agentProfileId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  facebookUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instagramUrl?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  tiktokUrl?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  xingUrl?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "db.agent_profile_social_media_profiles". */
export type DbAgentProfileSocialMediaProfilesOrderBy = {
  agentProfile?: InputMaybe<DbAgentProfileOrderBy>;
  agentProfileId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  facebookUrl?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  instagramUrl?: InputMaybe<OrderBy>;
  linkedInUrl?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  tiktokUrl?: InputMaybe<OrderBy>;
  twitterUrl?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  xingUrl?: InputMaybe<OrderBy>;
};

/** select columns of table "db.agent_profile_social_media_profiles" */
export type DbAgentProfileSocialMediaProfilesSelectColumn =
  /** column name */
  | 'agentProfileId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'facebookUrl'
  /** column name */
  | 'id'
  /** column name */
  | 'instagramUrl'
  /** column name */
  | 'linkedInUrl'
  /** column name */
  | 'num'
  /** column name */
  | 'tiktokUrl'
  /** column name */
  | 'twitterUrl'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy'
  /** column name */
  | 'xingUrl';

/** aggregate stddev on columns */
export type DbAgentProfileSocialMediaProfilesStddevFields = {
  __typename?: 'DbAgentProfileSocialMediaProfilesStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type DbAgentProfileSocialMediaProfilesStddevPopFields = {
  __typename?: 'DbAgentProfileSocialMediaProfilesStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type DbAgentProfileSocialMediaProfilesStddevSampFields = {
  __typename?: 'DbAgentProfileSocialMediaProfilesStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "db_agent_profile_social_media_profiles" */
export type DbAgentProfileSocialMediaProfilesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbAgentProfileSocialMediaProfilesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbAgentProfileSocialMediaProfilesStreamCursorValueInput = {
  agentProfileId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  facebookUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  instagramUrl?: InputMaybe<Scalars['String']>;
  linkedInUrl?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  tiktokUrl?: InputMaybe<Scalars['String']>;
  twitterUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  xingUrl?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbAgentProfileSocialMediaProfilesSumFields = {
  __typename?: 'DbAgentProfileSocialMediaProfilesSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** aggregate varPop on columns */
export type DbAgentProfileSocialMediaProfilesVarPopFields = {
  __typename?: 'DbAgentProfileSocialMediaProfilesVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type DbAgentProfileSocialMediaProfilesVarSampFields = {
  __typename?: 'DbAgentProfileSocialMediaProfilesVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type DbAgentProfileSocialMediaProfilesVarianceFields = {
  __typename?: 'DbAgentProfileSocialMediaProfilesVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "db.agent_profile_spoken_language" */
export type DbAgentProfileSpokenLanguage = {
  __typename?: 'DbAgentProfileSpokenLanguage';
  /** An object relationship */
  agentProfile: DbAgentProfile;
  agentProfileId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  /** An object relationship */
  language: EnumsSpokenLanguage;
  num: Scalars['bigint'];
  spokenLanguage: SpokenLanguageEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.agent_profile_spoken_language" */
export type DbAgentProfileSpokenLanguageAggregate = {
  __typename?: 'DbAgentProfileSpokenLanguageAggregate';
  aggregate?: Maybe<DbAgentProfileSpokenLanguageAggregateFields>;
  nodes: Array<DbAgentProfileSpokenLanguage>;
};

export type DbAgentProfileSpokenLanguageAggregateBoolExp = {
  bool_and?: InputMaybe<DbAgentProfileSpokenLanguageAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbAgentProfileSpokenLanguageAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbAgentProfileSpokenLanguageAggregateBoolExpCount>;
};

/** aggregate fields of "db.agent_profile_spoken_language" */
export type DbAgentProfileSpokenLanguageAggregateFields = {
  __typename?: 'DbAgentProfileSpokenLanguageAggregateFields';
  avg?: Maybe<DbAgentProfileSpokenLanguageAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbAgentProfileSpokenLanguageMaxFields>;
  min?: Maybe<DbAgentProfileSpokenLanguageMinFields>;
  stddev?: Maybe<DbAgentProfileSpokenLanguageStddevFields>;
  stddevPop?: Maybe<DbAgentProfileSpokenLanguageStddevPopFields>;
  stddevSamp?: Maybe<DbAgentProfileSpokenLanguageStddevSampFields>;
  sum?: Maybe<DbAgentProfileSpokenLanguageSumFields>;
  varPop?: Maybe<DbAgentProfileSpokenLanguageVarPopFields>;
  varSamp?: Maybe<DbAgentProfileSpokenLanguageVarSampFields>;
  variance?: Maybe<DbAgentProfileSpokenLanguageVarianceFields>;
};

/** aggregate fields of "db.agent_profile_spoken_language" */
export type DbAgentProfileSpokenLanguageAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbAgentProfileSpokenLanguageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.agent_profile_spoken_language" */
export type DbAgentProfileSpokenLanguageAggregateOrderBy = {
  avg?: InputMaybe<DbAgentProfileSpokenLanguageAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbAgentProfileSpokenLanguageMaxOrderBy>;
  min?: InputMaybe<DbAgentProfileSpokenLanguageMinOrderBy>;
  stddev?: InputMaybe<DbAgentProfileSpokenLanguageStddevOrderBy>;
  stddevPop?: InputMaybe<DbAgentProfileSpokenLanguageStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbAgentProfileSpokenLanguageStddevSampOrderBy>;
  sum?: InputMaybe<DbAgentProfileSpokenLanguageSumOrderBy>;
  varPop?: InputMaybe<DbAgentProfileSpokenLanguageVarPopOrderBy>;
  varSamp?: InputMaybe<DbAgentProfileSpokenLanguageVarSampOrderBy>;
  variance?: InputMaybe<DbAgentProfileSpokenLanguageVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbAgentProfileSpokenLanguageAvgFields = {
  __typename?: 'DbAgentProfileSpokenLanguageAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.agent_profile_spoken_language" */
export type DbAgentProfileSpokenLanguageAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.agent_profile_spoken_language". All fields are combined with a logical 'AND'. */
export type DbAgentProfileSpokenLanguageBoolExp = {
  _and?: InputMaybe<Array<DbAgentProfileSpokenLanguageBoolExp>>;
  _not?: InputMaybe<DbAgentProfileSpokenLanguageBoolExp>;
  _or?: InputMaybe<Array<DbAgentProfileSpokenLanguageBoolExp>>;
  agentProfile?: InputMaybe<DbAgentProfileBoolExp>;
  agentProfileId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  language?: InputMaybe<EnumsSpokenLanguageBoolExp>;
  num?: InputMaybe<BigintComparisonExp>;
  spokenLanguage?: InputMaybe<EnumsSpokenLanguageEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbAgentProfileSpokenLanguageMaxFields = {
  __typename?: 'DbAgentProfileSpokenLanguageMaxFields';
  agentProfileId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.agent_profile_spoken_language" */
export type DbAgentProfileSpokenLanguageMaxOrderBy = {
  agentProfileId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbAgentProfileSpokenLanguageMinFields = {
  __typename?: 'DbAgentProfileSpokenLanguageMinFields';
  agentProfileId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.agent_profile_spoken_language" */
export type DbAgentProfileSpokenLanguageMinOrderBy = {
  agentProfileId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.agent_profile_spoken_language". */
export type DbAgentProfileSpokenLanguageOrderBy = {
  agentProfile?: InputMaybe<DbAgentProfileOrderBy>;
  agentProfileId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  language?: InputMaybe<EnumsSpokenLanguageOrderBy>;
  num?: InputMaybe<OrderBy>;
  spokenLanguage?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.agent_profile_spoken_language" */
export type DbAgentProfileSpokenLanguageSelectColumn =
  /** column name */
  | 'agentProfileId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'num'
  /** column name */
  | 'spokenLanguage'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbAgentProfileSpokenLanguageAggregateBoolExpBool_andArgumentsColumns" columns of table "db.agent_profile_spoken_language" */
export type DbAgentProfileSpokenLanguageSelectColumnDbAgentProfileSpokenLanguageAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbAgentProfileSpokenLanguageAggregateBoolExpBool_orArgumentsColumns" columns of table "db.agent_profile_spoken_language" */
export type DbAgentProfileSpokenLanguageSelectColumnDbAgentProfileSpokenLanguageAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbAgentProfileSpokenLanguageStddevFields = {
  __typename?: 'DbAgentProfileSpokenLanguageStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.agent_profile_spoken_language" */
export type DbAgentProfileSpokenLanguageStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbAgentProfileSpokenLanguageStddevPopFields = {
  __typename?: 'DbAgentProfileSpokenLanguageStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.agent_profile_spoken_language" */
export type DbAgentProfileSpokenLanguageStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbAgentProfileSpokenLanguageStddevSampFields = {
  __typename?: 'DbAgentProfileSpokenLanguageStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.agent_profile_spoken_language" */
export type DbAgentProfileSpokenLanguageStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_agent_profile_spoken_language" */
export type DbAgentProfileSpokenLanguageStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbAgentProfileSpokenLanguageStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbAgentProfileSpokenLanguageStreamCursorValueInput = {
  agentProfileId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  spokenLanguage?: InputMaybe<SpokenLanguageEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbAgentProfileSpokenLanguageSumFields = {
  __typename?: 'DbAgentProfileSpokenLanguageSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.agent_profile_spoken_language" */
export type DbAgentProfileSpokenLanguageSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbAgentProfileSpokenLanguageVarPopFields = {
  __typename?: 'DbAgentProfileSpokenLanguageVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.agent_profile_spoken_language" */
export type DbAgentProfileSpokenLanguageVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbAgentProfileSpokenLanguageVarSampFields = {
  __typename?: 'DbAgentProfileSpokenLanguageVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.agent_profile_spoken_language" */
export type DbAgentProfileSpokenLanguageVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbAgentProfileSpokenLanguageVarianceFields = {
  __typename?: 'DbAgentProfileSpokenLanguageVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.agent_profile_spoken_language" */
export type DbAgentProfileSpokenLanguageVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddev on columns */
export type DbAgentProfileStddevFields = {
  __typename?: 'DbAgentProfileStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.agent_profile" */
export type DbAgentProfileStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbAgentProfileStddevPopFields = {
  __typename?: 'DbAgentProfileStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.agent_profile" */
export type DbAgentProfileStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbAgentProfileStddevSampFields = {
  __typename?: 'DbAgentProfileStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.agent_profile" */
export type DbAgentProfileStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_agent_profile" */
export type DbAgentProfileStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbAgentProfileStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbAgentProfileStreamCursorValueInput = {
  agentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  published?: InputMaybe<Scalars['timestamp']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbAgentProfileSumFields = {
  __typename?: 'DbAgentProfileSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.agent_profile" */
export type DbAgentProfileSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbAgentProfileVarPopFields = {
  __typename?: 'DbAgentProfileVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.agent_profile" */
export type DbAgentProfileVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbAgentProfileVarSampFields = {
  __typename?: 'DbAgentProfileVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.agent_profile" */
export type DbAgentProfileVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbAgentProfileVarianceFields = {
  __typename?: 'DbAgentProfileVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.agent_profile" */
export type DbAgentProfileVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** select columns of table "db.agent" */
export type DbAgentSelectColumn =
  /** column name */
  | 'activeSince'
  /** column name */
  | 'betaTester'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'email'
  /** column name */
  | 'energyAdvisoryEnabled'
  /** column name */
  | 'firstName'
  /** column name */
  | 'id'
  /** column name */
  | 'lastName'
  /** column name */
  | 'ldapId'
  /** column name */
  | 'mobilePhoneNumber'
  /** column name */
  | 'normalizedFirstName'
  /** column name */
  | 'normalizedLastName'
  /** column name */
  | 'normalizedUserName'
  /** column name */
  | 'num'
  /** column name */
  | 'officePhoneNumber'
  /** column name */
  | 'primaryShopId'
  /** column name */
  | 'salutation'
  /** column name */
  | 'status'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy'
  /** column name */
  | 'userName';

/** select "dbAgentAggregateBoolExpBool_andArgumentsColumns" columns of table "db.agent" */
export type DbAgentSelectColumnDbAgentAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  | 'betaTester'
  /** column name */
  | 'deleted'
  /** column name */
  | 'energyAdvisoryEnabled';

/** select "dbAgentAggregateBoolExpBool_orArgumentsColumns" columns of table "db.agent" */
export type DbAgentSelectColumnDbAgentAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  | 'betaTester'
  /** column name */
  | 'deleted'
  /** column name */
  | 'energyAdvisoryEnabled';

/** aggregate stddev on columns */
export type DbAgentStddevFields = {
  __typename?: 'DbAgentStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.agent" */
export type DbAgentStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbAgentStddevPopFields = {
  __typename?: 'DbAgentStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.agent" */
export type DbAgentStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbAgentStddevSampFields = {
  __typename?: 'DbAgentStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.agent" */
export type DbAgentStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_agent" */
export type DbAgentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbAgentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbAgentStreamCursorValueInput = {
  activeSince?: InputMaybe<Scalars['timestamptz']>;
  betaTester?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  energyAdvisoryEnabled?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  ldapId?: InputMaybe<Scalars['uuid']>;
  mobilePhoneNumber?: InputMaybe<Scalars['String']>;
  normalizedFirstName?: InputMaybe<Scalars['String']>;
  normalizedLastName?: InputMaybe<Scalars['String']>;
  normalizedUserName?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  officePhoneNumber?: InputMaybe<Scalars['String']>;
  primaryShopId?: InputMaybe<Scalars['String']>;
  salutation?: InputMaybe<SalutationEnum>;
  status?: InputMaybe<AgentStatusEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  userName?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbAgentSumFields = {
  __typename?: 'DbAgentSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.agent" */
export type DbAgentSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbAgentVarPopFields = {
  __typename?: 'DbAgentVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.agent" */
export type DbAgentVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbAgentVarSampFields = {
  __typename?: 'DbAgentVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.agent" */
export type DbAgentVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbAgentVarianceFields = {
  __typename?: 'DbAgentVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.agent" */
export type DbAgentVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.agents_assigned_to_leads_of_contact" */
export type DbAgentsAssignedToLeadsOfContact = {
  __typename?: 'DbAgentsAssignedToLeadsOfContact';
  /** An object relationship */
  agent?: Maybe<DbAgent>;
  agentId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  intentType?: Maybe<Scalars['String']>;
  /** An object relationship */
  intentTypeByIntentType?: Maybe<EnumsIntentType>;
};

/** order by aggregate values of table "db.agents_assigned_to_leads_of_contact" */
export type DbAgentsAssignedToLeadsOfContactAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbAgentsAssignedToLeadsOfContactMaxOrderBy>;
  min?: InputMaybe<DbAgentsAssignedToLeadsOfContactMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.agents_assigned_to_leads_of_contact". All fields are combined with a logical 'AND'. */
export type DbAgentsAssignedToLeadsOfContactBoolExp = {
  _and?: InputMaybe<Array<DbAgentsAssignedToLeadsOfContactBoolExp>>;
  _not?: InputMaybe<DbAgentsAssignedToLeadsOfContactBoolExp>;
  _or?: InputMaybe<Array<DbAgentsAssignedToLeadsOfContactBoolExp>>;
  agent?: InputMaybe<DbAgentBoolExp>;
  agentId?: InputMaybe<StringComparisonExp>;
  contactId?: InputMaybe<StringComparisonExp>;
  intentType?: InputMaybe<StringComparisonExp>;
  intentTypeByIntentType?: InputMaybe<EnumsIntentTypeBoolExp>;
};

/** order by max() on columns of table "db.agents_assigned_to_leads_of_contact" */
export type DbAgentsAssignedToLeadsOfContactMaxOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  contactId?: InputMaybe<OrderBy>;
  intentType?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.agents_assigned_to_leads_of_contact" */
export type DbAgentsAssignedToLeadsOfContactMinOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  contactId?: InputMaybe<OrderBy>;
  intentType?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.agents_assigned_to_leads_of_contact". */
export type DbAgentsAssignedToLeadsOfContactOrderBy = {
  agent?: InputMaybe<DbAgentOrderBy>;
  agentId?: InputMaybe<OrderBy>;
  contactId?: InputMaybe<OrderBy>;
  intentType?: InputMaybe<OrderBy>;
  intentTypeByIntentType?: InputMaybe<EnumsIntentTypeOrderBy>;
};

/** select columns of table "db.agents_assigned_to_leads_of_contact" */
export type DbAgentsAssignedToLeadsOfContactSelectColumn =
  /** column name */
  | 'agentId'
  /** column name */
  | 'contactId'
  /** column name */
  | 'intentType';

/** columns and relationships of "db.appointment" */
export type DbAppointment = {
  __typename?: 'DbAppointment';
  /** An object relationship */
  agent: DbAgent;
  agentId: Scalars['String'];
  /** An array relationship */
  appointmentActivities: Array<DbAppointmentActivity>;
  /** An array relationship */
  appointmentAgents: Array<DbAppointmentAgent>;
  /** An array relationship */
  appointmentContacts: Array<DbAppointmentContact>;
  /** An array relationship */
  appointmentLeads: Array<DbAppointmentLead>;
  /** An object relationship */
  appointmentTeam?: Maybe<DbTeam>;
  /** An object relationship */
  appointmentType: EnumsAppointmentType;
  comment?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['numeric']>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  endTime?: Maybe<Scalars['timestamptz']>;
  evaEventId?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
  go3AppointmentKey?: Maybe<Scalars['Int']>;
  googleEventId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lck: Scalars['Int'];
  location?: Maybe<Scalars['String']>;
  num: Scalars['bigint'];
  propertyIds: Array<Scalars['String']>;
  startTime: Scalars['timestamptz'];
  status?: Maybe<TaskAppointmentStatusEnum>;
  subject?: Maybe<Scalars['String']>;
  /** An object relationship */
  taskAppointmentStatus?: Maybe<EnumsTaskAppointmentStatus>;
  /** An object relationship */
  team?: Maybe<DbTeam>;
  teamId?: Maybe<Scalars['String']>;
  type: AppointmentTypeEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** columns and relationships of "db.appointment" */
export type DbAppointmentAppointmentActivitiesArgs = {
  distinctOn?: InputMaybe<Array<DbAppointmentActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAppointmentActivityOrderBy>>;
  where?: InputMaybe<DbAppointmentActivityBoolExp>;
};

/** columns and relationships of "db.appointment" */
export type DbAppointmentAppointmentAgentsArgs = {
  distinctOn?: InputMaybe<Array<DbAppointmentAgentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAppointmentAgentOrderBy>>;
  where?: InputMaybe<DbAppointmentAgentBoolExp>;
};

/** columns and relationships of "db.appointment" */
export type DbAppointmentAppointmentContactsArgs = {
  distinctOn?: InputMaybe<Array<DbAppointmentContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAppointmentContactOrderBy>>;
  where?: InputMaybe<DbAppointmentContactBoolExp>;
};

/** columns and relationships of "db.appointment" */
export type DbAppointmentAppointmentLeadsArgs = {
  distinctOn?: InputMaybe<Array<DbAppointmentLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAppointmentLeadOrderBy>>;
  where?: InputMaybe<DbAppointmentLeadBoolExp>;
};

/** columns and relationships of "db.appointment_activity" */
export type DbAppointmentActivity = {
  __typename?: 'DbAppointmentActivity';
  /** An object relationship */
  appointment: DbAppointment;
  appointmentId: Scalars['String'];
  /** An object relationship */
  baseActivity: DbBaseActivity;
  content?: Maybe<Scalars['jsonb']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "db.appointment_activity" */
export type DbAppointmentActivityContentArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** order by aggregate values of table "db.appointment_activity" */
export type DbAppointmentActivityAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbAppointmentActivityMaxOrderBy>;
  min?: InputMaybe<DbAppointmentActivityMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.appointment_activity". All fields are combined with a logical 'AND'. */
export type DbAppointmentActivityBoolExp = {
  _and?: InputMaybe<Array<DbAppointmentActivityBoolExp>>;
  _not?: InputMaybe<DbAppointmentActivityBoolExp>;
  _or?: InputMaybe<Array<DbAppointmentActivityBoolExp>>;
  appointment?: InputMaybe<DbAppointmentBoolExp>;
  appointmentId?: InputMaybe<StringComparisonExp>;
  baseActivity?: InputMaybe<DbBaseActivityBoolExp>;
  content?: InputMaybe<JsonbComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** order by max() on columns of table "db.appointment_activity" */
export type DbAppointmentActivityMaxOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.appointment_activity" */
export type DbAppointmentActivityMinOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.appointment_activity". */
export type DbAppointmentActivityOrderBy = {
  appointment?: InputMaybe<DbAppointmentOrderBy>;
  appointmentId?: InputMaybe<OrderBy>;
  baseActivity?: InputMaybe<DbBaseActivityOrderBy>;
  content?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** select columns of table "db.appointment_activity" */
export type DbAppointmentActivitySelectColumn =
  /** column name */
  | 'appointmentId'
  /** column name */
  | 'content'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'updatedAt';

/** Streaming cursor of the table "db_appointment_activity" */
export type DbAppointmentActivityStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbAppointmentActivityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbAppointmentActivityStreamCursorValueInput = {
  appointmentId?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "db.appointment_agent" */
export type DbAppointmentAgent = {
  __typename?: 'DbAppointmentAgent';
  /** An object relationship */
  agent: DbAgent;
  agentId: Scalars['String'];
  /** An object relationship */
  appointment: DbAppointment;
  appointmentId: Scalars['String'];
};

/** order by aggregate values of table "db.appointment_agent" */
export type DbAppointmentAgentAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbAppointmentAgentMaxOrderBy>;
  min?: InputMaybe<DbAppointmentAgentMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.appointment_agent". All fields are combined with a logical 'AND'. */
export type DbAppointmentAgentBoolExp = {
  _and?: InputMaybe<Array<DbAppointmentAgentBoolExp>>;
  _not?: InputMaybe<DbAppointmentAgentBoolExp>;
  _or?: InputMaybe<Array<DbAppointmentAgentBoolExp>>;
  agent?: InputMaybe<DbAgentBoolExp>;
  agentId?: InputMaybe<StringComparisonExp>;
  appointment?: InputMaybe<DbAppointmentBoolExp>;
  appointmentId?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.appointment_agent" */
export type DbAppointmentAgentMaxOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  appointmentId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.appointment_agent" */
export type DbAppointmentAgentMinOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  appointmentId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.appointment_agent". */
export type DbAppointmentAgentOrderBy = {
  agent?: InputMaybe<DbAgentOrderBy>;
  agentId?: InputMaybe<OrderBy>;
  appointment?: InputMaybe<DbAppointmentOrderBy>;
  appointmentId?: InputMaybe<OrderBy>;
};

/** select columns of table "db.appointment_agent" */
export type DbAppointmentAgentSelectColumn =
  /** column name */
  | 'agentId'
  /** column name */
  | 'appointmentId';

/** Streaming cursor of the table "db_appointment_agent" */
export type DbAppointmentAgentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbAppointmentAgentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbAppointmentAgentStreamCursorValueInput = {
  agentId?: InputMaybe<Scalars['String']>;
  appointmentId?: InputMaybe<Scalars['String']>;
};

/** order by aggregate values of table "db.appointment" */
export type DbAppointmentAggregateOrderBy = {
  avg?: InputMaybe<DbAppointmentAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbAppointmentMaxOrderBy>;
  min?: InputMaybe<DbAppointmentMinOrderBy>;
  stddev?: InputMaybe<DbAppointmentStddevOrderBy>;
  stddevPop?: InputMaybe<DbAppointmentStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbAppointmentStddevSampOrderBy>;
  sum?: InputMaybe<DbAppointmentSumOrderBy>;
  varPop?: InputMaybe<DbAppointmentVarPopOrderBy>;
  varSamp?: InputMaybe<DbAppointmentVarSampOrderBy>;
  variance?: InputMaybe<DbAppointmentVarianceOrderBy>;
};

/** order by avg() on columns of table "db.appointment" */
export type DbAppointmentAvgOrderBy = {
  cost?: InputMaybe<OrderBy>;
  go3AppointmentKey?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.appointment". All fields are combined with a logical 'AND'. */
export type DbAppointmentBoolExp = {
  _and?: InputMaybe<Array<DbAppointmentBoolExp>>;
  _not?: InputMaybe<DbAppointmentBoolExp>;
  _or?: InputMaybe<Array<DbAppointmentBoolExp>>;
  agent?: InputMaybe<DbAgentBoolExp>;
  agentId?: InputMaybe<StringComparisonExp>;
  appointmentActivities?: InputMaybe<DbAppointmentActivityBoolExp>;
  appointmentAgents?: InputMaybe<DbAppointmentAgentBoolExp>;
  appointmentContacts?: InputMaybe<DbAppointmentContactBoolExp>;
  appointmentLeads?: InputMaybe<DbAppointmentLeadBoolExp>;
  appointmentTeam?: InputMaybe<DbTeamBoolExp>;
  appointmentType?: InputMaybe<EnumsAppointmentTypeBoolExp>;
  comment?: InputMaybe<StringComparisonExp>;
  cost?: InputMaybe<NumericComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  endTime?: InputMaybe<TimestamptzComparisonExp>;
  evaEventId?: InputMaybe<StringComparisonExp>;
  feedback?: InputMaybe<StringComparisonExp>;
  go3AppointmentKey?: InputMaybe<IntComparisonExp>;
  googleEventId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lck?: InputMaybe<IntComparisonExp>;
  location?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  propertyIds?: InputMaybe<StringArrayComparisonExp>;
  startTime?: InputMaybe<TimestamptzComparisonExp>;
  status?: InputMaybe<EnumsTaskAppointmentStatusEnumComparisonExp>;
  subject?: InputMaybe<StringComparisonExp>;
  taskAppointmentStatus?: InputMaybe<EnumsTaskAppointmentStatusBoolExp>;
  team?: InputMaybe<DbTeamBoolExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<EnumsAppointmentTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** columns and relationships of "db.appointment_contact" */
export type DbAppointmentContact = {
  __typename?: 'DbAppointmentContact';
  /** An object relationship */
  appointment: DbAppointment;
  appointmentId: Scalars['String'];
  /** An object relationship */
  contact: DbContact;
  contactId: Scalars['String'];
};

/** order by aggregate values of table "db.appointment_contact" */
export type DbAppointmentContactAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbAppointmentContactMaxOrderBy>;
  min?: InputMaybe<DbAppointmentContactMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.appointment_contact". All fields are combined with a logical 'AND'. */
export type DbAppointmentContactBoolExp = {
  _and?: InputMaybe<Array<DbAppointmentContactBoolExp>>;
  _not?: InputMaybe<DbAppointmentContactBoolExp>;
  _or?: InputMaybe<Array<DbAppointmentContactBoolExp>>;
  appointment?: InputMaybe<DbAppointmentBoolExp>;
  appointmentId?: InputMaybe<StringComparisonExp>;
  contact?: InputMaybe<DbContactBoolExp>;
  contactId?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.appointment_contact" */
export type DbAppointmentContactMaxOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  contactId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.appointment_contact" */
export type DbAppointmentContactMinOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  contactId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.appointment_contact". */
export type DbAppointmentContactOrderBy = {
  appointment?: InputMaybe<DbAppointmentOrderBy>;
  appointmentId?: InputMaybe<OrderBy>;
  contact?: InputMaybe<DbContactOrderBy>;
  contactId?: InputMaybe<OrderBy>;
};

/** select columns of table "db.appointment_contact" */
export type DbAppointmentContactSelectColumn =
  /** column name */
  | 'appointmentId'
  /** column name */
  | 'contactId';

/** Streaming cursor of the table "db_appointment_contact" */
export type DbAppointmentContactStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbAppointmentContactStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbAppointmentContactStreamCursorValueInput = {
  appointmentId?: InputMaybe<Scalars['String']>;
  contactId?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "db.appointment_lead" */
export type DbAppointmentLead = {
  __typename?: 'DbAppointmentLead';
  /** An object relationship */
  appointment: DbAppointment;
  appointmentId: Scalars['String'];
  /** An object relationship */
  lead: DbLead;
  leadId: Scalars['String'];
};

/** order by aggregate values of table "db.appointment_lead" */
export type DbAppointmentLeadAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbAppointmentLeadMaxOrderBy>;
  min?: InputMaybe<DbAppointmentLeadMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.appointment_lead". All fields are combined with a logical 'AND'. */
export type DbAppointmentLeadBoolExp = {
  _and?: InputMaybe<Array<DbAppointmentLeadBoolExp>>;
  _not?: InputMaybe<DbAppointmentLeadBoolExp>;
  _or?: InputMaybe<Array<DbAppointmentLeadBoolExp>>;
  appointment?: InputMaybe<DbAppointmentBoolExp>;
  appointmentId?: InputMaybe<StringComparisonExp>;
  lead?: InputMaybe<DbLeadBoolExp>;
  leadId?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.appointment_lead" */
export type DbAppointmentLeadMaxOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  leadId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.appointment_lead" */
export type DbAppointmentLeadMinOrderBy = {
  appointmentId?: InputMaybe<OrderBy>;
  leadId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.appointment_lead". */
export type DbAppointmentLeadOrderBy = {
  appointment?: InputMaybe<DbAppointmentOrderBy>;
  appointmentId?: InputMaybe<OrderBy>;
  lead?: InputMaybe<DbLeadOrderBy>;
  leadId?: InputMaybe<OrderBy>;
};

/** select columns of table "db.appointment_lead" */
export type DbAppointmentLeadSelectColumn =
  /** column name */
  | 'appointmentId'
  /** column name */
  | 'leadId';

/** Streaming cursor of the table "db_appointment_lead" */
export type DbAppointmentLeadStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbAppointmentLeadStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbAppointmentLeadStreamCursorValueInput = {
  appointmentId?: InputMaybe<Scalars['String']>;
  leadId?: InputMaybe<Scalars['String']>;
};

/** order by max() on columns of table "db.appointment" */
export type DbAppointmentMaxOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  comment?: InputMaybe<OrderBy>;
  cost?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  endTime?: InputMaybe<OrderBy>;
  evaEventId?: InputMaybe<OrderBy>;
  feedback?: InputMaybe<OrderBy>;
  go3AppointmentKey?: InputMaybe<OrderBy>;
  googleEventId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  propertyIds?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
  subject?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.appointment" */
export type DbAppointmentMinOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  comment?: InputMaybe<OrderBy>;
  cost?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  endTime?: InputMaybe<OrderBy>;
  evaEventId?: InputMaybe<OrderBy>;
  feedback?: InputMaybe<OrderBy>;
  go3AppointmentKey?: InputMaybe<OrderBy>;
  googleEventId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  propertyIds?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
  subject?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.appointment". */
export type DbAppointmentOrderBy = {
  agent?: InputMaybe<DbAgentOrderBy>;
  agentId?: InputMaybe<OrderBy>;
  appointmentActivitiesAggregate?: InputMaybe<DbAppointmentActivityAggregateOrderBy>;
  appointmentAgentsAggregate?: InputMaybe<DbAppointmentAgentAggregateOrderBy>;
  appointmentContactsAggregate?: InputMaybe<DbAppointmentContactAggregateOrderBy>;
  appointmentLeadsAggregate?: InputMaybe<DbAppointmentLeadAggregateOrderBy>;
  appointmentTeam?: InputMaybe<DbTeamOrderBy>;
  appointmentType?: InputMaybe<EnumsAppointmentTypeOrderBy>;
  comment?: InputMaybe<OrderBy>;
  cost?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  endTime?: InputMaybe<OrderBy>;
  evaEventId?: InputMaybe<OrderBy>;
  feedback?: InputMaybe<OrderBy>;
  go3AppointmentKey?: InputMaybe<OrderBy>;
  googleEventId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  propertyIds?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  subject?: InputMaybe<OrderBy>;
  taskAppointmentStatus?: InputMaybe<EnumsTaskAppointmentStatusOrderBy>;
  team?: InputMaybe<DbTeamOrderBy>;
  teamId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.appointment" */
export type DbAppointmentSelectColumn =
  /** column name */
  | 'agentId'
  /** column name */
  | 'comment'
  /** column name */
  | 'cost'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'endTime'
  /** column name */
  | 'evaEventId'
  /** column name */
  | 'feedback'
  /** column name */
  | 'go3AppointmentKey'
  /** column name */
  | 'googleEventId'
  /** column name */
  | 'id'
  /** column name */
  | 'lck'
  /** column name */
  | 'location'
  /** column name */
  | 'num'
  /** column name */
  | 'propertyIds'
  /** column name */
  | 'startTime'
  /** column name */
  | 'status'
  /** column name */
  | 'subject'
  /** column name */
  | 'teamId'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.appointment" */
export type DbAppointmentStddevOrderBy = {
  cost?: InputMaybe<OrderBy>;
  go3AppointmentKey?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.appointment" */
export type DbAppointmentStddevPopOrderBy = {
  cost?: InputMaybe<OrderBy>;
  go3AppointmentKey?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.appointment" */
export type DbAppointmentStddevSampOrderBy = {
  cost?: InputMaybe<OrderBy>;
  go3AppointmentKey?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "db.appointment" */
export type DbAppointmentSumOrderBy = {
  cost?: InputMaybe<OrderBy>;
  go3AppointmentKey?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.appointment" */
export type DbAppointmentVarPopOrderBy = {
  cost?: InputMaybe<OrderBy>;
  go3AppointmentKey?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.appointment" */
export type DbAppointmentVarSampOrderBy = {
  cost?: InputMaybe<OrderBy>;
  go3AppointmentKey?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.appointment" */
export type DbAppointmentVarianceOrderBy = {
  cost?: InputMaybe<OrderBy>;
  go3AppointmentKey?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.audit_activity" */
export type DbAuditActivity = {
  __typename?: 'DbAuditActivity';
  /** An object relationship */
  baseActivity: DbBaseActivity;
  content?: Maybe<Scalars['jsonb']>;
  id: Scalars['String'];
};

/** columns and relationships of "db.audit_activity" */
export type DbAuditActivityContentArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "db.audit_activity". All fields are combined with a logical 'AND'. */
export type DbAuditActivityBoolExp = {
  _and?: InputMaybe<Array<DbAuditActivityBoolExp>>;
  _not?: InputMaybe<DbAuditActivityBoolExp>;
  _or?: InputMaybe<Array<DbAuditActivityBoolExp>>;
  baseActivity?: InputMaybe<DbBaseActivityBoolExp>;
  content?: InputMaybe<JsonbComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "db.audit_activity". */
export type DbAuditActivityOrderBy = {
  baseActivity?: InputMaybe<DbBaseActivityOrderBy>;
  content?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** select columns of table "db.audit_activity" */
export type DbAuditActivitySelectColumn =
  /** column name */
  | 'content'
  /** column name */
  | 'id';

/** Streaming cursor of the table "db_audit_activity" */
export type DbAuditActivityStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbAuditActivityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbAuditActivityStreamCursorValueInput = {
  content?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "db.base_activity" */
export type DbBaseActivity = {
  __typename?: 'DbBaseActivity';
  /** An object relationship */
  appointmentActivity?: Maybe<DbAppointmentActivity>;
  appointmentType?: Maybe<AppointmentTypeEnum>;
  /** An object relationship */
  auditActivity?: Maybe<DbAuditActivity>;
  /** An object relationship */
  baseActivityCategory: EnumsBaseActivityCategory;
  /** An object relationship */
  baseActivityType: EnumsActivityType;
  /** An array relationship */
  base_activity_legacy_go3_change_logs: Array<DbLegacyGo3ChangeLog>;
  /** An object relationship */
  callActivity?: Maybe<DbCallActivity>;
  category: BaseActivityCategoryEnum;
  /** An object relationship */
  consentActivity?: Maybe<DbConsentActivity>;
  /** An object relationship */
  contact?: Maybe<DbContact>;
  /** An array relationship */
  contactChangeLogs: Array<DbContactChangeLog>;
  contactId?: Maybe<Scalars['String']>;
  /** An object relationship */
  contactLastInteractionActivity?: Maybe<DbContact>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  /** An object relationship */
  creator?: Maybe<DbAgent>;
  /** An object relationship */
  customerAccount?: Maybe<DbCustomerAccount>;
  customerAccountId?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  documentActivity?: Maybe<DbDocumentActivity>;
  /** An object relationship */
  emailActivity?: Maybe<DbEmailActivity>;
  /** An object relationship */
  exposeSharedActivity?: Maybe<DbExposeeSharedActivity>;
  id: Scalars['String'];
  lck: Scalars['Int'];
  /** An object relationship */
  lead?: Maybe<DbLead>;
  leadId?: Maybe<Scalars['String']>;
  /** An object relationship */
  leadLastInteractionActivity?: Maybe<DbLead>;
  licenseId?: Maybe<Scalars['String']>;
  /** An object relationship */
  licensePartner?: Maybe<DbLicensePartner>;
  num: Scalars['bigint'];
  orderDate: Scalars['timestamptz'];
  /** An object relationship */
  printedDocumentActivity?: Maybe<DbPrintedDocumentActivity>;
  /** An object relationship */
  referralActivity?: Maybe<DbReferralActivity>;
  /** An object relationship */
  relatedAppointmentType?: Maybe<EnumsAppointmentType>;
  /** An object relationship */
  relatedTaskAppointmentStatus?: Maybe<EnumsTaskAppointmentStatus>;
  /** An object relationship */
  relatedTaskType?: Maybe<EnumsTaskType>;
  /** An object relationship */
  shop?: Maybe<DbShop>;
  shopId?: Maybe<Scalars['String']>;
  /** An object relationship */
  taskActivity?: Maybe<DbTaskActivity>;
  taskAppointmentStatus?: Maybe<TaskAppointmentStatusEnum>;
  taskType?: Maybe<TaskTypeEnum>;
  /** An object relationship */
  time?: Maybe<DbBaseActivityCreation>;
  type: ActivityTypeEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** columns and relationships of "db.base_activity" */
export type DbBaseActivityBaseActivityLegacyGo3ChangeLogsArgs = {
  distinctOn?: InputMaybe<Array<DbLegacyGo3ChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLegacyGo3ChangeLogOrderBy>>;
  where?: InputMaybe<DbLegacyGo3ChangeLogBoolExp>;
};

/** columns and relationships of "db.base_activity" */
export type DbBaseActivityContactChangeLogsArgs = {
  distinctOn?: InputMaybe<Array<DbContactChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactChangeLogOrderBy>>;
  where?: InputMaybe<DbContactChangeLogBoolExp>;
};

/** aggregated selection of "db.base_activity" */
export type DbBaseActivityAggregate = {
  __typename?: 'DbBaseActivityAggregate';
  aggregate?: Maybe<DbBaseActivityAggregateFields>;
  nodes: Array<DbBaseActivity>;
};

export type DbBaseActivityAggregateBoolExp = {
  bool_and?: InputMaybe<DbBaseActivityAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbBaseActivityAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbBaseActivityAggregateBoolExpCount>;
};

/** aggregate fields of "db.base_activity" */
export type DbBaseActivityAggregateFields = {
  __typename?: 'DbBaseActivityAggregateFields';
  avg?: Maybe<DbBaseActivityAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbBaseActivityMaxFields>;
  min?: Maybe<DbBaseActivityMinFields>;
  stddev?: Maybe<DbBaseActivityStddevFields>;
  stddevPop?: Maybe<DbBaseActivityStddevPopFields>;
  stddevSamp?: Maybe<DbBaseActivityStddevSampFields>;
  sum?: Maybe<DbBaseActivitySumFields>;
  varPop?: Maybe<DbBaseActivityVarPopFields>;
  varSamp?: Maybe<DbBaseActivityVarSampFields>;
  variance?: Maybe<DbBaseActivityVarianceFields>;
};

/** aggregate fields of "db.base_activity" */
export type DbBaseActivityAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbBaseActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.base_activity" */
export type DbBaseActivityAggregateOrderBy = {
  avg?: InputMaybe<DbBaseActivityAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbBaseActivityMaxOrderBy>;
  min?: InputMaybe<DbBaseActivityMinOrderBy>;
  stddev?: InputMaybe<DbBaseActivityStddevOrderBy>;
  stddevPop?: InputMaybe<DbBaseActivityStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbBaseActivityStddevSampOrderBy>;
  sum?: InputMaybe<DbBaseActivitySumOrderBy>;
  varPop?: InputMaybe<DbBaseActivityVarPopOrderBy>;
  varSamp?: InputMaybe<DbBaseActivityVarSampOrderBy>;
  variance?: InputMaybe<DbBaseActivityVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbBaseActivityAvgFields = {
  __typename?: 'DbBaseActivityAvgFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.base_activity" */
export type DbBaseActivityAvgOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.base_activity". All fields are combined with a logical 'AND'. */
export type DbBaseActivityBoolExp = {
  _and?: InputMaybe<Array<DbBaseActivityBoolExp>>;
  _not?: InputMaybe<DbBaseActivityBoolExp>;
  _or?: InputMaybe<Array<DbBaseActivityBoolExp>>;
  appointmentActivity?: InputMaybe<DbAppointmentActivityBoolExp>;
  appointmentType?: InputMaybe<EnumsAppointmentTypeEnumComparisonExp>;
  auditActivity?: InputMaybe<DbAuditActivityBoolExp>;
  baseActivityCategory?: InputMaybe<EnumsBaseActivityCategoryBoolExp>;
  baseActivityType?: InputMaybe<EnumsActivityTypeBoolExp>;
  base_activity_legacy_go3_change_logs?: InputMaybe<DbLegacyGo3ChangeLogBoolExp>;
  callActivity?: InputMaybe<DbCallActivityBoolExp>;
  category?: InputMaybe<EnumsBaseActivityCategoryEnumComparisonExp>;
  consentActivity?: InputMaybe<DbConsentActivityBoolExp>;
  contact?: InputMaybe<DbContactBoolExp>;
  contactChangeLogs?: InputMaybe<DbContactChangeLogBoolExp>;
  contactId?: InputMaybe<StringComparisonExp>;
  contactLastInteractionActivity?: InputMaybe<DbContactBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  creator?: InputMaybe<DbAgentBoolExp>;
  customerAccount?: InputMaybe<DbCustomerAccountBoolExp>;
  customerAccountId?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  documentActivity?: InputMaybe<DbDocumentActivityBoolExp>;
  emailActivity?: InputMaybe<DbEmailActivityBoolExp>;
  exposeSharedActivity?: InputMaybe<DbExposeeSharedActivityBoolExp>;
  id?: InputMaybe<StringComparisonExp>;
  lck?: InputMaybe<IntComparisonExp>;
  lead?: InputMaybe<DbLeadBoolExp>;
  leadId?: InputMaybe<StringComparisonExp>;
  leadLastInteractionActivity?: InputMaybe<DbLeadBoolExp>;
  licenseId?: InputMaybe<StringComparisonExp>;
  licensePartner?: InputMaybe<DbLicensePartnerBoolExp>;
  num?: InputMaybe<BigintComparisonExp>;
  orderDate?: InputMaybe<TimestamptzComparisonExp>;
  printedDocumentActivity?: InputMaybe<DbPrintedDocumentActivityBoolExp>;
  referralActivity?: InputMaybe<DbReferralActivityBoolExp>;
  relatedAppointmentType?: InputMaybe<EnumsAppointmentTypeBoolExp>;
  relatedTaskAppointmentStatus?: InputMaybe<EnumsTaskAppointmentStatusBoolExp>;
  relatedTaskType?: InputMaybe<EnumsTaskTypeBoolExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  taskActivity?: InputMaybe<DbTaskActivityBoolExp>;
  taskAppointmentStatus?: InputMaybe<EnumsTaskAppointmentStatusEnumComparisonExp>;
  taskType?: InputMaybe<EnumsTaskTypeEnumComparisonExp>;
  time?: InputMaybe<DbBaseActivityCreationBoolExp>;
  type?: InputMaybe<EnumsActivityTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** columns and relationships of "db.base_activity_creation" */
export type DbBaseActivityCreation = {
  __typename?: 'DbBaseActivityCreation';
  appointmentAgentId?: Maybe<Scalars['String']>;
  /** An object relationship */
  baseActivity?: Maybe<DbBaseActivity>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  orderDate?: Maybe<Scalars['timestamptz']>;
  taskAgentId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "db.base_activity_creation" */
export type DbBaseActivityCreationAggregate = {
  __typename?: 'DbBaseActivityCreationAggregate';
  aggregate?: Maybe<DbBaseActivityCreationAggregateFields>;
  nodes: Array<DbBaseActivityCreation>;
};

/** aggregate fields of "db.base_activity_creation" */
export type DbBaseActivityCreationAggregateFields = {
  __typename?: 'DbBaseActivityCreationAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<DbBaseActivityCreationMaxFields>;
  min?: Maybe<DbBaseActivityCreationMinFields>;
};

/** aggregate fields of "db.base_activity_creation" */
export type DbBaseActivityCreationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbBaseActivityCreationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "db.base_activity_creation". All fields are combined with a logical 'AND'. */
export type DbBaseActivityCreationBoolExp = {
  _and?: InputMaybe<Array<DbBaseActivityCreationBoolExp>>;
  _not?: InputMaybe<DbBaseActivityCreationBoolExp>;
  _or?: InputMaybe<Array<DbBaseActivityCreationBoolExp>>;
  appointmentAgentId?: InputMaybe<StringComparisonExp>;
  baseActivity?: InputMaybe<DbBaseActivityBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  orderDate?: InputMaybe<TimestamptzComparisonExp>;
  taskAgentId?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbBaseActivityCreationMaxFields = {
  __typename?: 'DbBaseActivityCreationMaxFields';
  appointmentAgentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  orderDate?: Maybe<Scalars['timestamptz']>;
  taskAgentId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type DbBaseActivityCreationMinFields = {
  __typename?: 'DbBaseActivityCreationMinFields';
  appointmentAgentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  orderDate?: Maybe<Scalars['timestamptz']>;
  taskAgentId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "db.base_activity_creation". */
export type DbBaseActivityCreationOrderBy = {
  appointmentAgentId?: InputMaybe<OrderBy>;
  baseActivity?: InputMaybe<DbBaseActivityOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  orderDate?: InputMaybe<OrderBy>;
  taskAgentId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** select columns of table "db.base_activity_creation" */
export type DbBaseActivityCreationSelectColumn =
  /** column name */
  | 'appointmentAgentId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'orderDate'
  /** column name */
  | 'taskAgentId'
  /** column name */
  | 'type';

/** Streaming cursor of the table "db_base_activity_creation" */
export type DbBaseActivityCreationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbBaseActivityCreationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbBaseActivityCreationStreamCursorValueInput = {
  appointmentAgentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  orderDate?: InputMaybe<Scalars['timestamptz']>;
  taskAgentId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type DbBaseActivityMaxFields = {
  __typename?: 'DbBaseActivityMaxFields';
  contactId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  customerAccountId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lck?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['String']>;
  licenseId?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  orderDate?: Maybe<Scalars['timestamptz']>;
  shopId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.base_activity" */
export type DbBaseActivityMaxOrderBy = {
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  customerAccountId?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  leadId?: InputMaybe<OrderBy>;
  licenseId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  orderDate?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbBaseActivityMinFields = {
  __typename?: 'DbBaseActivityMinFields';
  contactId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  customerAccountId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lck?: Maybe<Scalars['Int']>;
  leadId?: Maybe<Scalars['String']>;
  licenseId?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  orderDate?: Maybe<Scalars['timestamptz']>;
  shopId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.base_activity" */
export type DbBaseActivityMinOrderBy = {
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  customerAccountId?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  leadId?: InputMaybe<OrderBy>;
  licenseId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  orderDate?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.base_activity". */
export type DbBaseActivityOrderBy = {
  appointmentActivity?: InputMaybe<DbAppointmentActivityOrderBy>;
  appointmentType?: InputMaybe<OrderBy>;
  auditActivity?: InputMaybe<DbAuditActivityOrderBy>;
  baseActivityCategory?: InputMaybe<EnumsBaseActivityCategoryOrderBy>;
  baseActivityType?: InputMaybe<EnumsActivityTypeOrderBy>;
  base_activity_legacy_go3_change_logsAggregate?: InputMaybe<DbLegacyGo3ChangeLogAggregateOrderBy>;
  callActivity?: InputMaybe<DbCallActivityOrderBy>;
  category?: InputMaybe<OrderBy>;
  consentActivity?: InputMaybe<DbConsentActivityOrderBy>;
  contact?: InputMaybe<DbContactOrderBy>;
  contactChangeLogsAggregate?: InputMaybe<DbContactChangeLogAggregateOrderBy>;
  contactId?: InputMaybe<OrderBy>;
  contactLastInteractionActivity?: InputMaybe<DbContactOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  creator?: InputMaybe<DbAgentOrderBy>;
  customerAccount?: InputMaybe<DbCustomerAccountOrderBy>;
  customerAccountId?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  documentActivity?: InputMaybe<DbDocumentActivityOrderBy>;
  emailActivity?: InputMaybe<DbEmailActivityOrderBy>;
  exposeSharedActivity?: InputMaybe<DbExposeeSharedActivityOrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  lead?: InputMaybe<DbLeadOrderBy>;
  leadId?: InputMaybe<OrderBy>;
  leadLastInteractionActivity?: InputMaybe<DbLeadOrderBy>;
  licenseId?: InputMaybe<OrderBy>;
  licensePartner?: InputMaybe<DbLicensePartnerOrderBy>;
  num?: InputMaybe<OrderBy>;
  orderDate?: InputMaybe<OrderBy>;
  printedDocumentActivity?: InputMaybe<DbPrintedDocumentActivityOrderBy>;
  referralActivity?: InputMaybe<DbReferralActivityOrderBy>;
  relatedAppointmentType?: InputMaybe<EnumsAppointmentTypeOrderBy>;
  relatedTaskAppointmentStatus?: InputMaybe<EnumsTaskAppointmentStatusOrderBy>;
  relatedTaskType?: InputMaybe<EnumsTaskTypeOrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  taskActivity?: InputMaybe<DbTaskActivityOrderBy>;
  taskAppointmentStatus?: InputMaybe<OrderBy>;
  taskType?: InputMaybe<OrderBy>;
  time?: InputMaybe<DbBaseActivityCreationOrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.base_activity" */
export type DbBaseActivitySelectColumn =
  /** column name */
  | 'appointmentType'
  /** column name */
  | 'category'
  /** column name */
  | 'contactId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'customerAccountId'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'lck'
  /** column name */
  | 'leadId'
  /** column name */
  | 'licenseId'
  /** column name */
  | 'num'
  /** column name */
  | 'orderDate'
  /** column name */
  | 'shopId'
  /** column name */
  | 'taskAppointmentStatus'
  /** column name */
  | 'taskType'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbBaseActivityAggregateBoolExpBool_andArgumentsColumns" columns of table "db.base_activity" */
export type DbBaseActivitySelectColumnDbBaseActivityAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbBaseActivityAggregateBoolExpBool_orArgumentsColumns" columns of table "db.base_activity" */
export type DbBaseActivitySelectColumnDbBaseActivityAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbBaseActivityStddevFields = {
  __typename?: 'DbBaseActivityStddevFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.base_activity" */
export type DbBaseActivityStddevOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbBaseActivityStddevPopFields = {
  __typename?: 'DbBaseActivityStddevPopFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.base_activity" */
export type DbBaseActivityStddevPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbBaseActivityStddevSampFields = {
  __typename?: 'DbBaseActivityStddevSampFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.base_activity" */
export type DbBaseActivityStddevSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_base_activity" */
export type DbBaseActivityStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbBaseActivityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbBaseActivityStreamCursorValueInput = {
  appointmentType?: InputMaybe<AppointmentTypeEnum>;
  category?: InputMaybe<BaseActivityCategoryEnum>;
  contactId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  customerAccountId?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  lck?: InputMaybe<Scalars['Int']>;
  leadId?: InputMaybe<Scalars['String']>;
  licenseId?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  orderDate?: InputMaybe<Scalars['timestamptz']>;
  shopId?: InputMaybe<Scalars['String']>;
  taskAppointmentStatus?: InputMaybe<TaskAppointmentStatusEnum>;
  taskType?: InputMaybe<TaskTypeEnum>;
  type?: InputMaybe<ActivityTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbBaseActivitySumFields = {
  __typename?: 'DbBaseActivitySumFields';
  lck?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.base_activity" */
export type DbBaseActivitySumOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbBaseActivityVarPopFields = {
  __typename?: 'DbBaseActivityVarPopFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.base_activity" */
export type DbBaseActivityVarPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbBaseActivityVarSampFields = {
  __typename?: 'DbBaseActivityVarSampFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.base_activity" */
export type DbBaseActivityVarSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbBaseActivityVarianceFields = {
  __typename?: 'DbBaseActivityVarianceFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.base_activity" */
export type DbBaseActivityVarianceOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.batch_job_execution" */
export type DbBatchJobExecution = {
  __typename?: 'DbBatchJobExecution';
  /** An object relationship */
  batchJobExecutionContext?: Maybe<DbBatchJobExecutionContext>;
  /** An array relationship */
  batchJobExecutionParams: Array<DbBatchJobExecutionParams>;
  /** An object relationship */
  batchJobInstance: DbBatchJobInstance;
  /** An array relationship */
  batchStepExecutions: Array<DbBatchStepExecution>;
  createTime: Scalars['timestamp'];
  endTime?: Maybe<Scalars['timestamp']>;
  exitCode?: Maybe<Scalars['String']>;
  exitMessage?: Maybe<Scalars['String']>;
  jobExecutionId: Scalars['bigint'];
  jobInstanceId: Scalars['bigint'];
  lastUpdated?: Maybe<Scalars['timestamp']>;
  startTime?: Maybe<Scalars['timestamp']>;
  status?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['bigint']>;
};

/** columns and relationships of "db.batch_job_execution" */
export type DbBatchJobExecutionBatchJobExecutionParamsArgs = {
  distinctOn?: InputMaybe<Array<DbBatchJobExecutionParamsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBatchJobExecutionParamsOrderBy>>;
  where?: InputMaybe<DbBatchJobExecutionParamsBoolExp>;
};

/** columns and relationships of "db.batch_job_execution" */
export type DbBatchJobExecutionBatchStepExecutionsArgs = {
  distinctOn?: InputMaybe<Array<DbBatchStepExecutionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBatchStepExecutionOrderBy>>;
  where?: InputMaybe<DbBatchStepExecutionBoolExp>;
};

/** order by aggregate values of table "db.batch_job_execution" */
export type DbBatchJobExecutionAggregateOrderBy = {
  avg?: InputMaybe<DbBatchJobExecutionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbBatchJobExecutionMaxOrderBy>;
  min?: InputMaybe<DbBatchJobExecutionMinOrderBy>;
  stddev?: InputMaybe<DbBatchJobExecutionStddevOrderBy>;
  stddevPop?: InputMaybe<DbBatchJobExecutionStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbBatchJobExecutionStddevSampOrderBy>;
  sum?: InputMaybe<DbBatchJobExecutionSumOrderBy>;
  varPop?: InputMaybe<DbBatchJobExecutionVarPopOrderBy>;
  varSamp?: InputMaybe<DbBatchJobExecutionVarSampOrderBy>;
  variance?: InputMaybe<DbBatchJobExecutionVarianceOrderBy>;
};

/** order by avg() on columns of table "db.batch_job_execution" */
export type DbBatchJobExecutionAvgOrderBy = {
  jobExecutionId?: InputMaybe<OrderBy>;
  jobInstanceId?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.batch_job_execution". All fields are combined with a logical 'AND'. */
export type DbBatchJobExecutionBoolExp = {
  _and?: InputMaybe<Array<DbBatchJobExecutionBoolExp>>;
  _not?: InputMaybe<DbBatchJobExecutionBoolExp>;
  _or?: InputMaybe<Array<DbBatchJobExecutionBoolExp>>;
  batchJobExecutionContext?: InputMaybe<DbBatchJobExecutionContextBoolExp>;
  batchJobExecutionParams?: InputMaybe<DbBatchJobExecutionParamsBoolExp>;
  batchJobInstance?: InputMaybe<DbBatchJobInstanceBoolExp>;
  batchStepExecutions?: InputMaybe<DbBatchStepExecutionBoolExp>;
  createTime?: InputMaybe<TimestampComparisonExp>;
  endTime?: InputMaybe<TimestampComparisonExp>;
  exitCode?: InputMaybe<StringComparisonExp>;
  exitMessage?: InputMaybe<StringComparisonExp>;
  jobExecutionId?: InputMaybe<BigintComparisonExp>;
  jobInstanceId?: InputMaybe<BigintComparisonExp>;
  lastUpdated?: InputMaybe<TimestampComparisonExp>;
  startTime?: InputMaybe<TimestampComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  version?: InputMaybe<BigintComparisonExp>;
};

/** columns and relationships of "db.batch_job_execution_context" */
export type DbBatchJobExecutionContext = {
  __typename?: 'DbBatchJobExecutionContext';
  /** An object relationship */
  batchJobExecution: DbBatchJobExecution;
  jobExecutionId: Scalars['bigint'];
  serializedContext?: Maybe<Scalars['String']>;
  shortContext: Scalars['String'];
};

/** Boolean expression to filter rows from the table "db.batch_job_execution_context". All fields are combined with a logical 'AND'. */
export type DbBatchJobExecutionContextBoolExp = {
  _and?: InputMaybe<Array<DbBatchJobExecutionContextBoolExp>>;
  _not?: InputMaybe<DbBatchJobExecutionContextBoolExp>;
  _or?: InputMaybe<Array<DbBatchJobExecutionContextBoolExp>>;
  batchJobExecution?: InputMaybe<DbBatchJobExecutionBoolExp>;
  jobExecutionId?: InputMaybe<BigintComparisonExp>;
  serializedContext?: InputMaybe<StringComparisonExp>;
  shortContext?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "db.batch_job_execution_context". */
export type DbBatchJobExecutionContextOrderBy = {
  batchJobExecution?: InputMaybe<DbBatchJobExecutionOrderBy>;
  jobExecutionId?: InputMaybe<OrderBy>;
  serializedContext?: InputMaybe<OrderBy>;
  shortContext?: InputMaybe<OrderBy>;
};

/** select columns of table "db.batch_job_execution_context" */
export type DbBatchJobExecutionContextSelectColumn =
  /** column name */
  | 'jobExecutionId'
  /** column name */
  | 'serializedContext'
  /** column name */
  | 'shortContext';

/** Streaming cursor of the table "db_batch_job_execution_context" */
export type DbBatchJobExecutionContextStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbBatchJobExecutionContextStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbBatchJobExecutionContextStreamCursorValueInput = {
  jobExecutionId?: InputMaybe<Scalars['bigint']>;
  serializedContext?: InputMaybe<Scalars['String']>;
  shortContext?: InputMaybe<Scalars['String']>;
};

/** order by max() on columns of table "db.batch_job_execution" */
export type DbBatchJobExecutionMaxOrderBy = {
  createTime?: InputMaybe<OrderBy>;
  endTime?: InputMaybe<OrderBy>;
  exitCode?: InputMaybe<OrderBy>;
  exitMessage?: InputMaybe<OrderBy>;
  jobExecutionId?: InputMaybe<OrderBy>;
  jobInstanceId?: InputMaybe<OrderBy>;
  lastUpdated?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.batch_job_execution" */
export type DbBatchJobExecutionMinOrderBy = {
  createTime?: InputMaybe<OrderBy>;
  endTime?: InputMaybe<OrderBy>;
  exitCode?: InputMaybe<OrderBy>;
  exitMessage?: InputMaybe<OrderBy>;
  jobExecutionId?: InputMaybe<OrderBy>;
  jobInstanceId?: InputMaybe<OrderBy>;
  lastUpdated?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.batch_job_execution". */
export type DbBatchJobExecutionOrderBy = {
  batchJobExecutionContext?: InputMaybe<DbBatchJobExecutionContextOrderBy>;
  batchJobExecutionParamsAggregate?: InputMaybe<DbBatchJobExecutionParamsAggregateOrderBy>;
  batchJobInstance?: InputMaybe<DbBatchJobInstanceOrderBy>;
  batchStepExecutionsAggregate?: InputMaybe<DbBatchStepExecutionAggregateOrderBy>;
  createTime?: InputMaybe<OrderBy>;
  endTime?: InputMaybe<OrderBy>;
  exitCode?: InputMaybe<OrderBy>;
  exitMessage?: InputMaybe<OrderBy>;
  jobExecutionId?: InputMaybe<OrderBy>;
  jobInstanceId?: InputMaybe<OrderBy>;
  lastUpdated?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.batch_job_execution_params" */
export type DbBatchJobExecutionParams = {
  __typename?: 'DbBatchJobExecutionParams';
  /** An object relationship */
  batchJobExecution: DbBatchJobExecution;
  identifying: Scalars['bpchar'];
  jobExecutionId: Scalars['bigint'];
  parameterName: Scalars['String'];
  parameterType: Scalars['String'];
  parameterValue?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "db.batch_job_execution_params" */
export type DbBatchJobExecutionParamsAggregateOrderBy = {
  avg?: InputMaybe<DbBatchJobExecutionParamsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbBatchJobExecutionParamsMaxOrderBy>;
  min?: InputMaybe<DbBatchJobExecutionParamsMinOrderBy>;
  stddev?: InputMaybe<DbBatchJobExecutionParamsStddevOrderBy>;
  stddevPop?: InputMaybe<DbBatchJobExecutionParamsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbBatchJobExecutionParamsStddevSampOrderBy>;
  sum?: InputMaybe<DbBatchJobExecutionParamsSumOrderBy>;
  varPop?: InputMaybe<DbBatchJobExecutionParamsVarPopOrderBy>;
  varSamp?: InputMaybe<DbBatchJobExecutionParamsVarSampOrderBy>;
  variance?: InputMaybe<DbBatchJobExecutionParamsVarianceOrderBy>;
};

/** order by avg() on columns of table "db.batch_job_execution_params" */
export type DbBatchJobExecutionParamsAvgOrderBy = {
  jobExecutionId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.batch_job_execution_params". All fields are combined with a logical 'AND'. */
export type DbBatchJobExecutionParamsBoolExp = {
  _and?: InputMaybe<Array<DbBatchJobExecutionParamsBoolExp>>;
  _not?: InputMaybe<DbBatchJobExecutionParamsBoolExp>;
  _or?: InputMaybe<Array<DbBatchJobExecutionParamsBoolExp>>;
  batchJobExecution?: InputMaybe<DbBatchJobExecutionBoolExp>;
  identifying?: InputMaybe<BpcharComparisonExp>;
  jobExecutionId?: InputMaybe<BigintComparisonExp>;
  parameterName?: InputMaybe<StringComparisonExp>;
  parameterType?: InputMaybe<StringComparisonExp>;
  parameterValue?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.batch_job_execution_params" */
export type DbBatchJobExecutionParamsMaxOrderBy = {
  identifying?: InputMaybe<OrderBy>;
  jobExecutionId?: InputMaybe<OrderBy>;
  parameterName?: InputMaybe<OrderBy>;
  parameterType?: InputMaybe<OrderBy>;
  parameterValue?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.batch_job_execution_params" */
export type DbBatchJobExecutionParamsMinOrderBy = {
  identifying?: InputMaybe<OrderBy>;
  jobExecutionId?: InputMaybe<OrderBy>;
  parameterName?: InputMaybe<OrderBy>;
  parameterType?: InputMaybe<OrderBy>;
  parameterValue?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.batch_job_execution_params". */
export type DbBatchJobExecutionParamsOrderBy = {
  batchJobExecution?: InputMaybe<DbBatchJobExecutionOrderBy>;
  identifying?: InputMaybe<OrderBy>;
  jobExecutionId?: InputMaybe<OrderBy>;
  parameterName?: InputMaybe<OrderBy>;
  parameterType?: InputMaybe<OrderBy>;
  parameterValue?: InputMaybe<OrderBy>;
};

/** select columns of table "db.batch_job_execution_params" */
export type DbBatchJobExecutionParamsSelectColumn =
  /** column name */
  | 'identifying'
  /** column name */
  | 'jobExecutionId'
  /** column name */
  | 'parameterName'
  /** column name */
  | 'parameterType'
  /** column name */
  | 'parameterValue';

/** order by stddev() on columns of table "db.batch_job_execution_params" */
export type DbBatchJobExecutionParamsStddevOrderBy = {
  jobExecutionId?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.batch_job_execution_params" */
export type DbBatchJobExecutionParamsStddevPopOrderBy = {
  jobExecutionId?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.batch_job_execution_params" */
export type DbBatchJobExecutionParamsStddevSampOrderBy = {
  jobExecutionId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_batch_job_execution_params" */
export type DbBatchJobExecutionParamsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbBatchJobExecutionParamsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbBatchJobExecutionParamsStreamCursorValueInput = {
  identifying?: InputMaybe<Scalars['bpchar']>;
  jobExecutionId?: InputMaybe<Scalars['bigint']>;
  parameterName?: InputMaybe<Scalars['String']>;
  parameterType?: InputMaybe<Scalars['String']>;
  parameterValue?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.batch_job_execution_params" */
export type DbBatchJobExecutionParamsSumOrderBy = {
  jobExecutionId?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.batch_job_execution_params" */
export type DbBatchJobExecutionParamsVarPopOrderBy = {
  jobExecutionId?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.batch_job_execution_params" */
export type DbBatchJobExecutionParamsVarSampOrderBy = {
  jobExecutionId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.batch_job_execution_params" */
export type DbBatchJobExecutionParamsVarianceOrderBy = {
  jobExecutionId?: InputMaybe<OrderBy>;
};

/** select columns of table "db.batch_job_execution" */
export type DbBatchJobExecutionSelectColumn =
  /** column name */
  | 'createTime'
  /** column name */
  | 'endTime'
  /** column name */
  | 'exitCode'
  /** column name */
  | 'exitMessage'
  /** column name */
  | 'jobExecutionId'
  /** column name */
  | 'jobInstanceId'
  /** column name */
  | 'lastUpdated'
  /** column name */
  | 'startTime'
  /** column name */
  | 'status'
  /** column name */
  | 'version';

/** order by stddev() on columns of table "db.batch_job_execution" */
export type DbBatchJobExecutionStddevOrderBy = {
  jobExecutionId?: InputMaybe<OrderBy>;
  jobInstanceId?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.batch_job_execution" */
export type DbBatchJobExecutionStddevPopOrderBy = {
  jobExecutionId?: InputMaybe<OrderBy>;
  jobInstanceId?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.batch_job_execution" */
export type DbBatchJobExecutionStddevSampOrderBy = {
  jobExecutionId?: InputMaybe<OrderBy>;
  jobInstanceId?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_batch_job_execution" */
export type DbBatchJobExecutionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbBatchJobExecutionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbBatchJobExecutionStreamCursorValueInput = {
  createTime?: InputMaybe<Scalars['timestamp']>;
  endTime?: InputMaybe<Scalars['timestamp']>;
  exitCode?: InputMaybe<Scalars['String']>;
  exitMessage?: InputMaybe<Scalars['String']>;
  jobExecutionId?: InputMaybe<Scalars['bigint']>;
  jobInstanceId?: InputMaybe<Scalars['bigint']>;
  lastUpdated?: InputMaybe<Scalars['timestamp']>;
  startTime?: InputMaybe<Scalars['timestamp']>;
  status?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.batch_job_execution" */
export type DbBatchJobExecutionSumOrderBy = {
  jobExecutionId?: InputMaybe<OrderBy>;
  jobInstanceId?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.batch_job_execution" */
export type DbBatchJobExecutionVarPopOrderBy = {
  jobExecutionId?: InputMaybe<OrderBy>;
  jobInstanceId?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.batch_job_execution" */
export type DbBatchJobExecutionVarSampOrderBy = {
  jobExecutionId?: InputMaybe<OrderBy>;
  jobInstanceId?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.batch_job_execution" */
export type DbBatchJobExecutionVarianceOrderBy = {
  jobExecutionId?: InputMaybe<OrderBy>;
  jobInstanceId?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.batch_job_instance" */
export type DbBatchJobInstance = {
  __typename?: 'DbBatchJobInstance';
  /** An array relationship */
  batchJobExecutions: Array<DbBatchJobExecution>;
  jobInstanceId: Scalars['bigint'];
  jobKey: Scalars['String'];
  jobName: Scalars['String'];
  version?: Maybe<Scalars['bigint']>;
};

/** columns and relationships of "db.batch_job_instance" */
export type DbBatchJobInstanceBatchJobExecutionsArgs = {
  distinctOn?: InputMaybe<Array<DbBatchJobExecutionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBatchJobExecutionOrderBy>>;
  where?: InputMaybe<DbBatchJobExecutionBoolExp>;
};

/** Boolean expression to filter rows from the table "db.batch_job_instance". All fields are combined with a logical 'AND'. */
export type DbBatchJobInstanceBoolExp = {
  _and?: InputMaybe<Array<DbBatchJobInstanceBoolExp>>;
  _not?: InputMaybe<DbBatchJobInstanceBoolExp>;
  _or?: InputMaybe<Array<DbBatchJobInstanceBoolExp>>;
  batchJobExecutions?: InputMaybe<DbBatchJobExecutionBoolExp>;
  jobInstanceId?: InputMaybe<BigintComparisonExp>;
  jobKey?: InputMaybe<StringComparisonExp>;
  jobName?: InputMaybe<StringComparisonExp>;
  version?: InputMaybe<BigintComparisonExp>;
};

/** Ordering options when selecting data from "db.batch_job_instance". */
export type DbBatchJobInstanceOrderBy = {
  batchJobExecutionsAggregate?: InputMaybe<DbBatchJobExecutionAggregateOrderBy>;
  jobInstanceId?: InputMaybe<OrderBy>;
  jobKey?: InputMaybe<OrderBy>;
  jobName?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
};

/** select columns of table "db.batch_job_instance" */
export type DbBatchJobInstanceSelectColumn =
  /** column name */
  | 'jobInstanceId'
  /** column name */
  | 'jobKey'
  /** column name */
  | 'jobName'
  /** column name */
  | 'version';

/** Streaming cursor of the table "db_batch_job_instance" */
export type DbBatchJobInstanceStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbBatchJobInstanceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbBatchJobInstanceStreamCursorValueInput = {
  jobInstanceId?: InputMaybe<Scalars['bigint']>;
  jobKey?: InputMaybe<Scalars['String']>;
  jobName?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "db.batch_step_execution" */
export type DbBatchStepExecution = {
  __typename?: 'DbBatchStepExecution';
  /** An object relationship */
  batchJobExecution: DbBatchJobExecution;
  /** An object relationship */
  batchStepExecutionContext?: Maybe<DbBatchStepExecutionContext>;
  commitCount?: Maybe<Scalars['bigint']>;
  createTime: Scalars['timestamp'];
  endTime?: Maybe<Scalars['timestamp']>;
  exitCode?: Maybe<Scalars['String']>;
  exitMessage?: Maybe<Scalars['String']>;
  filterCount?: Maybe<Scalars['bigint']>;
  jobExecutionId: Scalars['bigint'];
  lastUpdated?: Maybe<Scalars['timestamp']>;
  processSkipCount?: Maybe<Scalars['bigint']>;
  readCount?: Maybe<Scalars['bigint']>;
  readSkipCount?: Maybe<Scalars['bigint']>;
  rollbackCount?: Maybe<Scalars['bigint']>;
  startTime?: Maybe<Scalars['timestamp']>;
  status?: Maybe<Scalars['String']>;
  stepExecutionId: Scalars['bigint'];
  stepName: Scalars['String'];
  version: Scalars['bigint'];
  writeCount?: Maybe<Scalars['bigint']>;
  writeSkipCount?: Maybe<Scalars['bigint']>;
};

/** order by aggregate values of table "db.batch_step_execution" */
export type DbBatchStepExecutionAggregateOrderBy = {
  avg?: InputMaybe<DbBatchStepExecutionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbBatchStepExecutionMaxOrderBy>;
  min?: InputMaybe<DbBatchStepExecutionMinOrderBy>;
  stddev?: InputMaybe<DbBatchStepExecutionStddevOrderBy>;
  stddevPop?: InputMaybe<DbBatchStepExecutionStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbBatchStepExecutionStddevSampOrderBy>;
  sum?: InputMaybe<DbBatchStepExecutionSumOrderBy>;
  varPop?: InputMaybe<DbBatchStepExecutionVarPopOrderBy>;
  varSamp?: InputMaybe<DbBatchStepExecutionVarSampOrderBy>;
  variance?: InputMaybe<DbBatchStepExecutionVarianceOrderBy>;
};

/** order by avg() on columns of table "db.batch_step_execution" */
export type DbBatchStepExecutionAvgOrderBy = {
  commitCount?: InputMaybe<OrderBy>;
  filterCount?: InputMaybe<OrderBy>;
  jobExecutionId?: InputMaybe<OrderBy>;
  processSkipCount?: InputMaybe<OrderBy>;
  readCount?: InputMaybe<OrderBy>;
  readSkipCount?: InputMaybe<OrderBy>;
  rollbackCount?: InputMaybe<OrderBy>;
  stepExecutionId?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  writeCount?: InputMaybe<OrderBy>;
  writeSkipCount?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.batch_step_execution". All fields are combined with a logical 'AND'. */
export type DbBatchStepExecutionBoolExp = {
  _and?: InputMaybe<Array<DbBatchStepExecutionBoolExp>>;
  _not?: InputMaybe<DbBatchStepExecutionBoolExp>;
  _or?: InputMaybe<Array<DbBatchStepExecutionBoolExp>>;
  batchJobExecution?: InputMaybe<DbBatchJobExecutionBoolExp>;
  batchStepExecutionContext?: InputMaybe<DbBatchStepExecutionContextBoolExp>;
  commitCount?: InputMaybe<BigintComparisonExp>;
  createTime?: InputMaybe<TimestampComparisonExp>;
  endTime?: InputMaybe<TimestampComparisonExp>;
  exitCode?: InputMaybe<StringComparisonExp>;
  exitMessage?: InputMaybe<StringComparisonExp>;
  filterCount?: InputMaybe<BigintComparisonExp>;
  jobExecutionId?: InputMaybe<BigintComparisonExp>;
  lastUpdated?: InputMaybe<TimestampComparisonExp>;
  processSkipCount?: InputMaybe<BigintComparisonExp>;
  readCount?: InputMaybe<BigintComparisonExp>;
  readSkipCount?: InputMaybe<BigintComparisonExp>;
  rollbackCount?: InputMaybe<BigintComparisonExp>;
  startTime?: InputMaybe<TimestampComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  stepExecutionId?: InputMaybe<BigintComparisonExp>;
  stepName?: InputMaybe<StringComparisonExp>;
  version?: InputMaybe<BigintComparisonExp>;
  writeCount?: InputMaybe<BigintComparisonExp>;
  writeSkipCount?: InputMaybe<BigintComparisonExp>;
};

/** columns and relationships of "db.batch_step_execution_context" */
export type DbBatchStepExecutionContext = {
  __typename?: 'DbBatchStepExecutionContext';
  /** An object relationship */
  batchStepExecution: DbBatchStepExecution;
  serializedContext?: Maybe<Scalars['String']>;
  shortContext: Scalars['String'];
  stepExecutionId: Scalars['bigint'];
};

/** Boolean expression to filter rows from the table "db.batch_step_execution_context". All fields are combined with a logical 'AND'. */
export type DbBatchStepExecutionContextBoolExp = {
  _and?: InputMaybe<Array<DbBatchStepExecutionContextBoolExp>>;
  _not?: InputMaybe<DbBatchStepExecutionContextBoolExp>;
  _or?: InputMaybe<Array<DbBatchStepExecutionContextBoolExp>>;
  batchStepExecution?: InputMaybe<DbBatchStepExecutionBoolExp>;
  serializedContext?: InputMaybe<StringComparisonExp>;
  shortContext?: InputMaybe<StringComparisonExp>;
  stepExecutionId?: InputMaybe<BigintComparisonExp>;
};

/** Ordering options when selecting data from "db.batch_step_execution_context". */
export type DbBatchStepExecutionContextOrderBy = {
  batchStepExecution?: InputMaybe<DbBatchStepExecutionOrderBy>;
  serializedContext?: InputMaybe<OrderBy>;
  shortContext?: InputMaybe<OrderBy>;
  stepExecutionId?: InputMaybe<OrderBy>;
};

/** select columns of table "db.batch_step_execution_context" */
export type DbBatchStepExecutionContextSelectColumn =
  /** column name */
  | 'serializedContext'
  /** column name */
  | 'shortContext'
  /** column name */
  | 'stepExecutionId';

/** Streaming cursor of the table "db_batch_step_execution_context" */
export type DbBatchStepExecutionContextStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbBatchStepExecutionContextStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbBatchStepExecutionContextStreamCursorValueInput = {
  serializedContext?: InputMaybe<Scalars['String']>;
  shortContext?: InputMaybe<Scalars['String']>;
  stepExecutionId?: InputMaybe<Scalars['bigint']>;
};

/** order by max() on columns of table "db.batch_step_execution" */
export type DbBatchStepExecutionMaxOrderBy = {
  commitCount?: InputMaybe<OrderBy>;
  createTime?: InputMaybe<OrderBy>;
  endTime?: InputMaybe<OrderBy>;
  exitCode?: InputMaybe<OrderBy>;
  exitMessage?: InputMaybe<OrderBy>;
  filterCount?: InputMaybe<OrderBy>;
  jobExecutionId?: InputMaybe<OrderBy>;
  lastUpdated?: InputMaybe<OrderBy>;
  processSkipCount?: InputMaybe<OrderBy>;
  readCount?: InputMaybe<OrderBy>;
  readSkipCount?: InputMaybe<OrderBy>;
  rollbackCount?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stepExecutionId?: InputMaybe<OrderBy>;
  stepName?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  writeCount?: InputMaybe<OrderBy>;
  writeSkipCount?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.batch_step_execution" */
export type DbBatchStepExecutionMinOrderBy = {
  commitCount?: InputMaybe<OrderBy>;
  createTime?: InputMaybe<OrderBy>;
  endTime?: InputMaybe<OrderBy>;
  exitCode?: InputMaybe<OrderBy>;
  exitMessage?: InputMaybe<OrderBy>;
  filterCount?: InputMaybe<OrderBy>;
  jobExecutionId?: InputMaybe<OrderBy>;
  lastUpdated?: InputMaybe<OrderBy>;
  processSkipCount?: InputMaybe<OrderBy>;
  readCount?: InputMaybe<OrderBy>;
  readSkipCount?: InputMaybe<OrderBy>;
  rollbackCount?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stepExecutionId?: InputMaybe<OrderBy>;
  stepName?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  writeCount?: InputMaybe<OrderBy>;
  writeSkipCount?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.batch_step_execution". */
export type DbBatchStepExecutionOrderBy = {
  batchJobExecution?: InputMaybe<DbBatchJobExecutionOrderBy>;
  batchStepExecutionContext?: InputMaybe<DbBatchStepExecutionContextOrderBy>;
  commitCount?: InputMaybe<OrderBy>;
  createTime?: InputMaybe<OrderBy>;
  endTime?: InputMaybe<OrderBy>;
  exitCode?: InputMaybe<OrderBy>;
  exitMessage?: InputMaybe<OrderBy>;
  filterCount?: InputMaybe<OrderBy>;
  jobExecutionId?: InputMaybe<OrderBy>;
  lastUpdated?: InputMaybe<OrderBy>;
  processSkipCount?: InputMaybe<OrderBy>;
  readCount?: InputMaybe<OrderBy>;
  readSkipCount?: InputMaybe<OrderBy>;
  rollbackCount?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  stepExecutionId?: InputMaybe<OrderBy>;
  stepName?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  writeCount?: InputMaybe<OrderBy>;
  writeSkipCount?: InputMaybe<OrderBy>;
};

/** select columns of table "db.batch_step_execution" */
export type DbBatchStepExecutionSelectColumn =
  /** column name */
  | 'commitCount'
  /** column name */
  | 'createTime'
  /** column name */
  | 'endTime'
  /** column name */
  | 'exitCode'
  /** column name */
  | 'exitMessage'
  /** column name */
  | 'filterCount'
  /** column name */
  | 'jobExecutionId'
  /** column name */
  | 'lastUpdated'
  /** column name */
  | 'processSkipCount'
  /** column name */
  | 'readCount'
  /** column name */
  | 'readSkipCount'
  /** column name */
  | 'rollbackCount'
  /** column name */
  | 'startTime'
  /** column name */
  | 'status'
  /** column name */
  | 'stepExecutionId'
  /** column name */
  | 'stepName'
  /** column name */
  | 'version'
  /** column name */
  | 'writeCount'
  /** column name */
  | 'writeSkipCount';

/** order by stddev() on columns of table "db.batch_step_execution" */
export type DbBatchStepExecutionStddevOrderBy = {
  commitCount?: InputMaybe<OrderBy>;
  filterCount?: InputMaybe<OrderBy>;
  jobExecutionId?: InputMaybe<OrderBy>;
  processSkipCount?: InputMaybe<OrderBy>;
  readCount?: InputMaybe<OrderBy>;
  readSkipCount?: InputMaybe<OrderBy>;
  rollbackCount?: InputMaybe<OrderBy>;
  stepExecutionId?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  writeCount?: InputMaybe<OrderBy>;
  writeSkipCount?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.batch_step_execution" */
export type DbBatchStepExecutionStddevPopOrderBy = {
  commitCount?: InputMaybe<OrderBy>;
  filterCount?: InputMaybe<OrderBy>;
  jobExecutionId?: InputMaybe<OrderBy>;
  processSkipCount?: InputMaybe<OrderBy>;
  readCount?: InputMaybe<OrderBy>;
  readSkipCount?: InputMaybe<OrderBy>;
  rollbackCount?: InputMaybe<OrderBy>;
  stepExecutionId?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  writeCount?: InputMaybe<OrderBy>;
  writeSkipCount?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.batch_step_execution" */
export type DbBatchStepExecutionStddevSampOrderBy = {
  commitCount?: InputMaybe<OrderBy>;
  filterCount?: InputMaybe<OrderBy>;
  jobExecutionId?: InputMaybe<OrderBy>;
  processSkipCount?: InputMaybe<OrderBy>;
  readCount?: InputMaybe<OrderBy>;
  readSkipCount?: InputMaybe<OrderBy>;
  rollbackCount?: InputMaybe<OrderBy>;
  stepExecutionId?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  writeCount?: InputMaybe<OrderBy>;
  writeSkipCount?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_batch_step_execution" */
export type DbBatchStepExecutionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbBatchStepExecutionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbBatchStepExecutionStreamCursorValueInput = {
  commitCount?: InputMaybe<Scalars['bigint']>;
  createTime?: InputMaybe<Scalars['timestamp']>;
  endTime?: InputMaybe<Scalars['timestamp']>;
  exitCode?: InputMaybe<Scalars['String']>;
  exitMessage?: InputMaybe<Scalars['String']>;
  filterCount?: InputMaybe<Scalars['bigint']>;
  jobExecutionId?: InputMaybe<Scalars['bigint']>;
  lastUpdated?: InputMaybe<Scalars['timestamp']>;
  processSkipCount?: InputMaybe<Scalars['bigint']>;
  readCount?: InputMaybe<Scalars['bigint']>;
  readSkipCount?: InputMaybe<Scalars['bigint']>;
  rollbackCount?: InputMaybe<Scalars['bigint']>;
  startTime?: InputMaybe<Scalars['timestamp']>;
  status?: InputMaybe<Scalars['String']>;
  stepExecutionId?: InputMaybe<Scalars['bigint']>;
  stepName?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['bigint']>;
  writeCount?: InputMaybe<Scalars['bigint']>;
  writeSkipCount?: InputMaybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.batch_step_execution" */
export type DbBatchStepExecutionSumOrderBy = {
  commitCount?: InputMaybe<OrderBy>;
  filterCount?: InputMaybe<OrderBy>;
  jobExecutionId?: InputMaybe<OrderBy>;
  processSkipCount?: InputMaybe<OrderBy>;
  readCount?: InputMaybe<OrderBy>;
  readSkipCount?: InputMaybe<OrderBy>;
  rollbackCount?: InputMaybe<OrderBy>;
  stepExecutionId?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  writeCount?: InputMaybe<OrderBy>;
  writeSkipCount?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.batch_step_execution" */
export type DbBatchStepExecutionVarPopOrderBy = {
  commitCount?: InputMaybe<OrderBy>;
  filterCount?: InputMaybe<OrderBy>;
  jobExecutionId?: InputMaybe<OrderBy>;
  processSkipCount?: InputMaybe<OrderBy>;
  readCount?: InputMaybe<OrderBy>;
  readSkipCount?: InputMaybe<OrderBy>;
  rollbackCount?: InputMaybe<OrderBy>;
  stepExecutionId?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  writeCount?: InputMaybe<OrderBy>;
  writeSkipCount?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.batch_step_execution" */
export type DbBatchStepExecutionVarSampOrderBy = {
  commitCount?: InputMaybe<OrderBy>;
  filterCount?: InputMaybe<OrderBy>;
  jobExecutionId?: InputMaybe<OrderBy>;
  processSkipCount?: InputMaybe<OrderBy>;
  readCount?: InputMaybe<OrderBy>;
  readSkipCount?: InputMaybe<OrderBy>;
  rollbackCount?: InputMaybe<OrderBy>;
  stepExecutionId?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  writeCount?: InputMaybe<OrderBy>;
  writeSkipCount?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.batch_step_execution" */
export type DbBatchStepExecutionVarianceOrderBy = {
  commitCount?: InputMaybe<OrderBy>;
  filterCount?: InputMaybe<OrderBy>;
  jobExecutionId?: InputMaybe<OrderBy>;
  processSkipCount?: InputMaybe<OrderBy>;
  readCount?: InputMaybe<OrderBy>;
  readSkipCount?: InputMaybe<OrderBy>;
  rollbackCount?: InputMaybe<OrderBy>;
  stepExecutionId?: InputMaybe<OrderBy>;
  version?: InputMaybe<OrderBy>;
  writeCount?: InputMaybe<OrderBy>;
  writeSkipCount?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.bulk_email" */
export type DbBulkEmail = {
  __typename?: 'DbBulkEmail';
  /** An array relationship */
  attachments: Array<DbEmailAttachment>;
  /** An aggregate relationship */
  attachmentsAggregate: DbEmailAttachmentAggregate;
  bcc?: Maybe<Array<Scalars['String']>>;
  body?: Maybe<Scalars['String']>;
  cc?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  /** An array relationship */
  documentReferences: Array<DbDocumentReference>;
  /** An aggregate relationship */
  documentReferencesAggregate: DbDocumentReferenceAggregate;
  /** An array relationship */
  emailActivities: Array<DbEmailActivity>;
  /** An object relationship */
  emailStatus?: Maybe<EnumsEmailStatus>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  /** An object relationship */
  template?: Maybe<DbEmailTemplate>;
  templateId?: Maybe<Scalars['String']>;
  to?: Maybe<Array<Scalars['String']>>;
  /** An array relationship */
  trackings: Array<DbEmailTracking>;
  /** An aggregate relationship */
  trackingsAggregate: DbEmailTrackingAggregate;
};

/** columns and relationships of "db.bulk_email" */
export type DbBulkEmailAttachmentsArgs = {
  distinctOn?: InputMaybe<Array<DbEmailAttachmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailAttachmentOrderBy>>;
  where?: InputMaybe<DbEmailAttachmentBoolExp>;
};

/** columns and relationships of "db.bulk_email" */
export type DbBulkEmailAttachmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailAttachmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailAttachmentOrderBy>>;
  where?: InputMaybe<DbEmailAttachmentBoolExp>;
};

/** columns and relationships of "db.bulk_email" */
export type DbBulkEmailDocumentReferencesArgs = {
  distinctOn?: InputMaybe<Array<DbDocumentReferenceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbDocumentReferenceOrderBy>>;
  where?: InputMaybe<DbDocumentReferenceBoolExp>;
};

/** columns and relationships of "db.bulk_email" */
export type DbBulkEmailDocumentReferencesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbDocumentReferenceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbDocumentReferenceOrderBy>>;
  where?: InputMaybe<DbDocumentReferenceBoolExp>;
};

/** columns and relationships of "db.bulk_email" */
export type DbBulkEmailEmailActivitiesArgs = {
  distinctOn?: InputMaybe<Array<DbEmailActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailActivityOrderBy>>;
  where?: InputMaybe<DbEmailActivityBoolExp>;
};

/** columns and relationships of "db.bulk_email" */
export type DbBulkEmailTrackingsArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTrackingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTrackingOrderBy>>;
  where?: InputMaybe<DbEmailTrackingBoolExp>;
};

/** columns and relationships of "db.bulk_email" */
export type DbBulkEmailTrackingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTrackingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTrackingOrderBy>>;
  where?: InputMaybe<DbEmailTrackingBoolExp>;
};

/** aggregated selection of "db.bulk_email" */
export type DbBulkEmailAggregate = {
  __typename?: 'DbBulkEmailAggregate';
  aggregate?: Maybe<DbBulkEmailAggregateFields>;
  nodes: Array<DbBulkEmail>;
};

/** aggregate fields of "db.bulk_email" */
export type DbBulkEmailAggregateFields = {
  __typename?: 'DbBulkEmailAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<DbBulkEmailMaxFields>;
  min?: Maybe<DbBulkEmailMinFields>;
};

/** aggregate fields of "db.bulk_email" */
export type DbBulkEmailAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbBulkEmailSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "db.bulk_email". All fields are combined with a logical 'AND'. */
export type DbBulkEmailBoolExp = {
  _and?: InputMaybe<Array<DbBulkEmailBoolExp>>;
  _not?: InputMaybe<DbBulkEmailBoolExp>;
  _or?: InputMaybe<Array<DbBulkEmailBoolExp>>;
  attachments?: InputMaybe<DbEmailAttachmentBoolExp>;
  attachmentsAggregate?: InputMaybe<DbEmailAttachmentAggregateBoolExp>;
  bcc?: InputMaybe<StringArrayComparisonExp>;
  body?: InputMaybe<StringComparisonExp>;
  cc?: InputMaybe<StringArrayComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  documentReferences?: InputMaybe<DbDocumentReferenceBoolExp>;
  documentReferencesAggregate?: InputMaybe<DbDocumentReferenceAggregateBoolExp>;
  emailActivities?: InputMaybe<DbEmailActivityBoolExp>;
  emailStatus?: InputMaybe<EnumsEmailStatusBoolExp>;
  from?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  subject?: InputMaybe<StringComparisonExp>;
  template?: InputMaybe<DbEmailTemplateBoolExp>;
  templateId?: InputMaybe<StringComparisonExp>;
  to?: InputMaybe<StringArrayComparisonExp>;
  trackings?: InputMaybe<DbEmailTrackingBoolExp>;
  trackingsAggregate?: InputMaybe<DbEmailTrackingAggregateBoolExp>;
};

/** aggregate max on columns */
export type DbBulkEmailMaxFields = {
  __typename?: 'DbBulkEmailMaxFields';
  bcc?: Maybe<Array<Scalars['String']>>;
  body?: Maybe<Scalars['String']>;
  cc?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['String']>;
  to?: Maybe<Array<Scalars['String']>>;
};

/** aggregate min on columns */
export type DbBulkEmailMinFields = {
  __typename?: 'DbBulkEmailMinFields';
  bcc?: Maybe<Array<Scalars['String']>>;
  body?: Maybe<Scalars['String']>;
  cc?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['String']>;
  to?: Maybe<Array<Scalars['String']>>;
};

/** Ordering options when selecting data from "db.bulk_email". */
export type DbBulkEmailOrderBy = {
  attachmentsAggregate?: InputMaybe<DbEmailAttachmentAggregateOrderBy>;
  bcc?: InputMaybe<OrderBy>;
  body?: InputMaybe<OrderBy>;
  cc?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  documentReferencesAggregate?: InputMaybe<DbDocumentReferenceAggregateOrderBy>;
  emailActivitiesAggregate?: InputMaybe<DbEmailActivityAggregateOrderBy>;
  emailStatus?: InputMaybe<EnumsEmailStatusOrderBy>;
  from?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  subject?: InputMaybe<OrderBy>;
  template?: InputMaybe<DbEmailTemplateOrderBy>;
  templateId?: InputMaybe<OrderBy>;
  to?: InputMaybe<OrderBy>;
  trackingsAggregate?: InputMaybe<DbEmailTrackingAggregateOrderBy>;
};

/** select columns of table "db.bulk_email" */
export type DbBulkEmailSelectColumn =
  /** column name */
  | 'bcc'
  /** column name */
  | 'body'
  /** column name */
  | 'cc'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'from'
  /** column name */
  | 'id'
  /** column name */
  | 'status'
  /** column name */
  | 'subject'
  /** column name */
  | 'templateId'
  /** column name */
  | 'to';

/** Streaming cursor of the table "db_bulk_email" */
export type DbBulkEmailStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbBulkEmailStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbBulkEmailStreamCursorValueInput = {
  bcc?: InputMaybe<Array<Scalars['String']>>;
  body?: InputMaybe<Scalars['String']>;
  cc?: InputMaybe<Array<Scalars['String']>>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  templateId?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Array<Scalars['String']>>;
};

/** columns and relationships of "db.buyer_to_rent_specification" */
export type DbBuyerToRentSpecification = {
  __typename?: 'DbBuyerToRentSpecification';
  area?: Maybe<Scalars['String']>;
  /** An array relationship */
  assetSubTypes: Array<EnumsAssetSubType>;
  /** An object relationship */
  assetType?: Maybe<EnumsAssetType>;
  /** An array relationship */
  assetVariations: Array<EnumsAssetVariation>;
  bedroomsMax?: Maybe<Scalars['Int']>;
  bedroomsMin?: Maybe<Scalars['Int']>;
  /** An array relationship */
  buyerToRentSpecificationCdfs: Array<DbBuyerToRentSpecificationCdf>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  currency: CurrencyEnum;
  /** An object relationship */
  currencyByCurrency: EnumsCurrency;
  /** An array relationship */
  customSearchDemandPolygons: Array<DbCustomSearchDemandPolygon>;
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  district?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lck: Scalars['Int'];
  /** An object relationship */
  lead?: Maybe<DbLead>;
  /** An array relationship */
  leads: Array<DbLead>;
  /** An aggregate relationship */
  leadsAggregate: DbLeadAggregate;
  legacyAreaIds: Array<Scalars['Int']>;
  legacyAreaNames: Array<Scalars['String']>;
  legacyDistrictIds: Array<Scalars['Int']>;
  legacyDistrictNames: Array<Scalars['String']>;
  listingShortTermRental?: Maybe<Scalars['Boolean']>;
  livingAreaMax?: Maybe<Scalars['Int']>;
  livingAreaMin?: Maybe<Scalars['Int']>;
  monthlyNetRentMax?: Maybe<Scalars['Int']>;
  monthlyNetRentMin?: Maybe<Scalars['Int']>;
  monthlyTotalRentMax?: Maybe<Scalars['Int']>;
  monthlyTotalRentMin?: Maybe<Scalars['Int']>;
  num: Scalars['bigint'];
  placeIds: Array<Scalars['String']>;
  plotSurfaceMax?: Maybe<Scalars['Int']>;
  plotSurfaceMin?: Maybe<Scalars['Int']>;
  priceMax?: Maybe<Scalars['Int']>;
  priceMin?: Maybe<Scalars['Int']>;
  roomsMax?: Maybe<Scalars['Int']>;
  roomsMin?: Maybe<Scalars['Int']>;
  searchDemandId?: Maybe<Scalars['String']>;
  subTypes?: Maybe<Array<Scalars['String']>>;
  totalSurfaceMax?: Maybe<Scalars['Int']>;
  totalSurfaceMin?: Maybe<Scalars['Int']>;
  type?: Maybe<AssetTypeEnum>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  variations?: Maybe<Array<Scalars['String']>>;
};

/** columns and relationships of "db.buyer_to_rent_specification" */
export type DbBuyerToRentSpecificationAssetSubTypesArgs = {
  distinctOn?: InputMaybe<Array<EnumsAssetSubTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsAssetSubTypeOrderBy>>;
  where?: InputMaybe<EnumsAssetSubTypeBoolExp>;
};

/** columns and relationships of "db.buyer_to_rent_specification" */
export type DbBuyerToRentSpecificationAssetVariationsArgs = {
  distinctOn?: InputMaybe<Array<EnumsAssetVariationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsAssetVariationOrderBy>>;
  where?: InputMaybe<EnumsAssetVariationBoolExp>;
};

/** columns and relationships of "db.buyer_to_rent_specification" */
export type DbBuyerToRentSpecificationBuyerToRentSpecificationCdfsArgs = {
  distinctOn?: InputMaybe<Array<DbBuyerToRentSpecificationCdfSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBuyerToRentSpecificationCdfOrderBy>>;
  where?: InputMaybe<DbBuyerToRentSpecificationCdfBoolExp>;
};

/** columns and relationships of "db.buyer_to_rent_specification" */
export type DbBuyerToRentSpecificationCustomSearchDemandPolygonsArgs = {
  distinctOn?: InputMaybe<Array<DbCustomSearchDemandPolygonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCustomSearchDemandPolygonOrderBy>>;
  where?: InputMaybe<DbCustomSearchDemandPolygonBoolExp>;
};

/** columns and relationships of "db.buyer_to_rent_specification" */
export type DbBuyerToRentSpecificationLeadsArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

/** columns and relationships of "db.buyer_to_rent_specification" */
export type DbBuyerToRentSpecificationLeadsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

/** Boolean expression to filter rows from the table "db.buyer_to_rent_specification". All fields are combined with a logical 'AND'. */
export type DbBuyerToRentSpecificationBoolExp = {
  _and?: InputMaybe<Array<DbBuyerToRentSpecificationBoolExp>>;
  _not?: InputMaybe<DbBuyerToRentSpecificationBoolExp>;
  _or?: InputMaybe<Array<DbBuyerToRentSpecificationBoolExp>>;
  area?: InputMaybe<StringComparisonExp>;
  assetSubTypes?: InputMaybe<EnumsAssetSubTypeBoolExp>;
  assetType?: InputMaybe<EnumsAssetTypeBoolExp>;
  assetVariations?: InputMaybe<EnumsAssetVariationBoolExp>;
  bedroomsMax?: InputMaybe<IntComparisonExp>;
  bedroomsMin?: InputMaybe<IntComparisonExp>;
  buyerToRentSpecificationCdfs?: InputMaybe<DbBuyerToRentSpecificationCdfBoolExp>;
  city?: InputMaybe<StringComparisonExp>;
  country?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  currency?: InputMaybe<EnumsCurrencyEnumComparisonExp>;
  currencyByCurrency?: InputMaybe<EnumsCurrencyBoolExp>;
  customSearchDemandPolygons?: InputMaybe<DbCustomSearchDemandPolygonBoolExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  district?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lck?: InputMaybe<IntComparisonExp>;
  lead?: InputMaybe<DbLeadBoolExp>;
  leads?: InputMaybe<DbLeadBoolExp>;
  leadsAggregate?: InputMaybe<DbLeadAggregateBoolExp>;
  legacyAreaIds?: InputMaybe<IntArrayComparisonExp>;
  legacyAreaNames?: InputMaybe<StringArrayComparisonExp>;
  legacyDistrictIds?: InputMaybe<IntArrayComparisonExp>;
  legacyDistrictNames?: InputMaybe<StringArrayComparisonExp>;
  listingShortTermRental?: InputMaybe<BooleanComparisonExp>;
  livingAreaMax?: InputMaybe<IntComparisonExp>;
  livingAreaMin?: InputMaybe<IntComparisonExp>;
  monthlyNetRentMax?: InputMaybe<IntComparisonExp>;
  monthlyNetRentMin?: InputMaybe<IntComparisonExp>;
  monthlyTotalRentMax?: InputMaybe<IntComparisonExp>;
  monthlyTotalRentMin?: InputMaybe<IntComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  placeIds?: InputMaybe<StringArrayComparisonExp>;
  plotSurfaceMax?: InputMaybe<IntComparisonExp>;
  plotSurfaceMin?: InputMaybe<IntComparisonExp>;
  priceMax?: InputMaybe<IntComparisonExp>;
  priceMin?: InputMaybe<IntComparisonExp>;
  roomsMax?: InputMaybe<IntComparisonExp>;
  roomsMin?: InputMaybe<IntComparisonExp>;
  searchDemandId?: InputMaybe<StringComparisonExp>;
  subTypes?: InputMaybe<StringArrayComparisonExp>;
  totalSurfaceMax?: InputMaybe<IntComparisonExp>;
  totalSurfaceMin?: InputMaybe<IntComparisonExp>;
  type?: InputMaybe<EnumsAssetTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  variations?: InputMaybe<StringArrayComparisonExp>;
};

/** columns and relationships of "db.buyer_to_rent_specification_cdf" */
export type DbBuyerToRentSpecificationCdf = {
  __typename?: 'DbBuyerToRentSpecificationCdf';
  brsId: Scalars['String'];
  /** An object relationship */
  cdf: DbCdf;
  cdfId: Scalars['String'];
};

/** order by aggregate values of table "db.buyer_to_rent_specification_cdf" */
export type DbBuyerToRentSpecificationCdfAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbBuyerToRentSpecificationCdfMaxOrderBy>;
  min?: InputMaybe<DbBuyerToRentSpecificationCdfMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.buyer_to_rent_specification_cdf". All fields are combined with a logical 'AND'. */
export type DbBuyerToRentSpecificationCdfBoolExp = {
  _and?: InputMaybe<Array<DbBuyerToRentSpecificationCdfBoolExp>>;
  _not?: InputMaybe<DbBuyerToRentSpecificationCdfBoolExp>;
  _or?: InputMaybe<Array<DbBuyerToRentSpecificationCdfBoolExp>>;
  brsId?: InputMaybe<StringComparisonExp>;
  cdf?: InputMaybe<DbCdfBoolExp>;
  cdfId?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.buyer_to_rent_specification_cdf" */
export type DbBuyerToRentSpecificationCdfMaxOrderBy = {
  brsId?: InputMaybe<OrderBy>;
  cdfId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.buyer_to_rent_specification_cdf" */
export type DbBuyerToRentSpecificationCdfMinOrderBy = {
  brsId?: InputMaybe<OrderBy>;
  cdfId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.buyer_to_rent_specification_cdf". */
export type DbBuyerToRentSpecificationCdfOrderBy = {
  brsId?: InputMaybe<OrderBy>;
  cdf?: InputMaybe<DbCdfOrderBy>;
  cdfId?: InputMaybe<OrderBy>;
};

/** select columns of table "db.buyer_to_rent_specification_cdf" */
export type DbBuyerToRentSpecificationCdfSelectColumn =
  /** column name */
  | 'brsId'
  /** column name */
  | 'cdfId';

/** Streaming cursor of the table "db_buyer_to_rent_specification_cdf" */
export type DbBuyerToRentSpecificationCdfStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbBuyerToRentSpecificationCdfStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbBuyerToRentSpecificationCdfStreamCursorValueInput = {
  brsId?: InputMaybe<Scalars['String']>;
  cdfId?: InputMaybe<Scalars['String']>;
};

/** Ordering options when selecting data from "db.buyer_to_rent_specification". */
export type DbBuyerToRentSpecificationOrderBy = {
  area?: InputMaybe<OrderBy>;
  assetSubTypesAggregate?: InputMaybe<EnumsAssetSubTypeAggregateOrderBy>;
  assetType?: InputMaybe<EnumsAssetTypeOrderBy>;
  assetVariationsAggregate?: InputMaybe<EnumsAssetVariationAggregateOrderBy>;
  bedroomsMax?: InputMaybe<OrderBy>;
  bedroomsMin?: InputMaybe<OrderBy>;
  buyerToRentSpecificationCdfsAggregate?: InputMaybe<DbBuyerToRentSpecificationCdfAggregateOrderBy>;
  city?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  currency?: InputMaybe<OrderBy>;
  currencyByCurrency?: InputMaybe<EnumsCurrencyOrderBy>;
  customSearchDemandPolygonsAggregate?: InputMaybe<DbCustomSearchDemandPolygonAggregateOrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  district?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  lead?: InputMaybe<DbLeadOrderBy>;
  leadsAggregate?: InputMaybe<DbLeadAggregateOrderBy>;
  legacyAreaIds?: InputMaybe<OrderBy>;
  legacyAreaNames?: InputMaybe<OrderBy>;
  legacyDistrictIds?: InputMaybe<OrderBy>;
  legacyDistrictNames?: InputMaybe<OrderBy>;
  listingShortTermRental?: InputMaybe<OrderBy>;
  livingAreaMax?: InputMaybe<OrderBy>;
  livingAreaMin?: InputMaybe<OrderBy>;
  monthlyNetRentMax?: InputMaybe<OrderBy>;
  monthlyNetRentMin?: InputMaybe<OrderBy>;
  monthlyTotalRentMax?: InputMaybe<OrderBy>;
  monthlyTotalRentMin?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  placeIds?: InputMaybe<OrderBy>;
  plotSurfaceMax?: InputMaybe<OrderBy>;
  plotSurfaceMin?: InputMaybe<OrderBy>;
  priceMax?: InputMaybe<OrderBy>;
  priceMin?: InputMaybe<OrderBy>;
  roomsMax?: InputMaybe<OrderBy>;
  roomsMin?: InputMaybe<OrderBy>;
  searchDemandId?: InputMaybe<OrderBy>;
  subTypes?: InputMaybe<OrderBy>;
  totalSurfaceMax?: InputMaybe<OrderBy>;
  totalSurfaceMin?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  variations?: InputMaybe<OrderBy>;
};

/** select columns of table "db.buyer_to_rent_specification" */
export type DbBuyerToRentSpecificationSelectColumn =
  /** column name */
  | 'area'
  /** column name */
  | 'bedroomsMax'
  /** column name */
  | 'bedroomsMin'
  /** column name */
  | 'city'
  /** column name */
  | 'country'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'currency'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'district'
  /** column name */
  | 'id'
  /** column name */
  | 'lck'
  /** column name */
  | 'legacyAreaIds'
  /** column name */
  | 'legacyAreaNames'
  /** column name */
  | 'legacyDistrictIds'
  /** column name */
  | 'legacyDistrictNames'
  /** column name */
  | 'listingShortTermRental'
  /** column name */
  | 'livingAreaMax'
  /** column name */
  | 'livingAreaMin'
  /** column name */
  | 'monthlyNetRentMax'
  /** column name */
  | 'monthlyNetRentMin'
  /** column name */
  | 'monthlyTotalRentMax'
  /** column name */
  | 'monthlyTotalRentMin'
  /** column name */
  | 'num'
  /** column name */
  | 'placeIds'
  /** column name */
  | 'plotSurfaceMax'
  /** column name */
  | 'plotSurfaceMin'
  /** column name */
  | 'priceMax'
  /** column name */
  | 'priceMin'
  /** column name */
  | 'roomsMax'
  /** column name */
  | 'roomsMin'
  /** column name */
  | 'searchDemandId'
  /** column name */
  | 'subTypes'
  /** column name */
  | 'totalSurfaceMax'
  /** column name */
  | 'totalSurfaceMin'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy'
  /** column name */
  | 'variations';

/** Streaming cursor of the table "db_buyer_to_rent_specification" */
export type DbBuyerToRentSpecificationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbBuyerToRentSpecificationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbBuyerToRentSpecificationStreamCursorValueInput = {
  area?: InputMaybe<Scalars['String']>;
  bedroomsMax?: InputMaybe<Scalars['Int']>;
  bedroomsMin?: InputMaybe<Scalars['Int']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<CurrencyEnum>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  district?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lck?: InputMaybe<Scalars['Int']>;
  legacyAreaIds?: InputMaybe<Array<Scalars['Int']>>;
  legacyAreaNames?: InputMaybe<Array<Scalars['String']>>;
  legacyDistrictIds?: InputMaybe<Array<Scalars['Int']>>;
  legacyDistrictNames?: InputMaybe<Array<Scalars['String']>>;
  listingShortTermRental?: InputMaybe<Scalars['Boolean']>;
  livingAreaMax?: InputMaybe<Scalars['Int']>;
  livingAreaMin?: InputMaybe<Scalars['Int']>;
  monthlyNetRentMax?: InputMaybe<Scalars['Int']>;
  monthlyNetRentMin?: InputMaybe<Scalars['Int']>;
  monthlyTotalRentMax?: InputMaybe<Scalars['Int']>;
  monthlyTotalRentMin?: InputMaybe<Scalars['Int']>;
  num?: InputMaybe<Scalars['bigint']>;
  placeIds?: InputMaybe<Array<Scalars['String']>>;
  plotSurfaceMax?: InputMaybe<Scalars['Int']>;
  plotSurfaceMin?: InputMaybe<Scalars['Int']>;
  priceMax?: InputMaybe<Scalars['Int']>;
  priceMin?: InputMaybe<Scalars['Int']>;
  roomsMax?: InputMaybe<Scalars['Int']>;
  roomsMin?: InputMaybe<Scalars['Int']>;
  searchDemandId?: InputMaybe<Scalars['String']>;
  subTypes?: InputMaybe<Array<Scalars['String']>>;
  totalSurfaceMax?: InputMaybe<Scalars['Int']>;
  totalSurfaceMin?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<AssetTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  variations?: InputMaybe<Array<Scalars['String']>>;
};

/** columns and relationships of "db.call" */
export type DbCall = {
  __typename?: 'DbCall';
  /** An object relationship */
  agent?: Maybe<DbAgent>;
  agentId?: Maybe<Scalars['String']>;
  /** An object relationship */
  callDirection: EnumsCallDirection;
  /** An object relationship */
  callOutcome?: Maybe<EnumsCallOutcome>;
  callSid: Scalars['String'];
  /** An object relationship */
  callStatus: EnumsCallStatus;
  /** An array relationship */
  call_activities: Array<DbCallActivity>;
  /** An object relationship */
  call_shop: DbShop;
  caller: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  direction: CallDirectionEnum;
  duration?: Maybe<Scalars['bigint']>;
  id: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  num: Scalars['bigint'];
  outcome?: Maybe<CallOutcomeEnum>;
  recipient: Scalars['String'];
  shopId: Scalars['String'];
  status: CallStatusEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** columns and relationships of "db.call" */
export type DbCallCallActivitiesArgs = {
  distinctOn?: InputMaybe<Array<DbCallActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCallActivityOrderBy>>;
  where?: InputMaybe<DbCallActivityBoolExp>;
};

/** columns and relationships of "db.call_activity" */
export type DbCallActivity = {
  __typename?: 'DbCallActivity';
  /** An object relationship */
  baseActivity: DbBaseActivity;
  /** An object relationship */
  call?: Maybe<DbCall>;
  callId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

/** order by aggregate values of table "db.call_activity" */
export type DbCallActivityAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbCallActivityMaxOrderBy>;
  min?: InputMaybe<DbCallActivityMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.call_activity". All fields are combined with a logical 'AND'. */
export type DbCallActivityBoolExp = {
  _and?: InputMaybe<Array<DbCallActivityBoolExp>>;
  _not?: InputMaybe<DbCallActivityBoolExp>;
  _or?: InputMaybe<Array<DbCallActivityBoolExp>>;
  baseActivity?: InputMaybe<DbBaseActivityBoolExp>;
  call?: InputMaybe<DbCallBoolExp>;
  callId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.call_activity" */
export type DbCallActivityMaxOrderBy = {
  callId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.call_activity" */
export type DbCallActivityMinOrderBy = {
  callId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.call_activity". */
export type DbCallActivityOrderBy = {
  baseActivity?: InputMaybe<DbBaseActivityOrderBy>;
  call?: InputMaybe<DbCallOrderBy>;
  callId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** select columns of table "db.call_activity" */
export type DbCallActivitySelectColumn =
  /** column name */
  | 'callId'
  /** column name */
  | 'id';

/** Streaming cursor of the table "db_call_activity" */
export type DbCallActivityStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbCallActivityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbCallActivityStreamCursorValueInput = {
  callId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "db.call" */
export type DbCallAggregate = {
  __typename?: 'DbCallAggregate';
  aggregate?: Maybe<DbCallAggregateFields>;
  nodes: Array<DbCall>;
};

export type DbCallAggregateBoolExp = {
  bool_and?: InputMaybe<DbCallAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbCallAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbCallAggregateBoolExpCount>;
};

/** aggregate fields of "db.call" */
export type DbCallAggregateFields = {
  __typename?: 'DbCallAggregateFields';
  avg?: Maybe<DbCallAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbCallMaxFields>;
  min?: Maybe<DbCallMinFields>;
  stddev?: Maybe<DbCallStddevFields>;
  stddevPop?: Maybe<DbCallStddevPopFields>;
  stddevSamp?: Maybe<DbCallStddevSampFields>;
  sum?: Maybe<DbCallSumFields>;
  varPop?: Maybe<DbCallVarPopFields>;
  varSamp?: Maybe<DbCallVarSampFields>;
  variance?: Maybe<DbCallVarianceFields>;
};

/** aggregate fields of "db.call" */
export type DbCallAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbCallSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.call" */
export type DbCallAggregateOrderBy = {
  avg?: InputMaybe<DbCallAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbCallMaxOrderBy>;
  min?: InputMaybe<DbCallMinOrderBy>;
  stddev?: InputMaybe<DbCallStddevOrderBy>;
  stddevPop?: InputMaybe<DbCallStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbCallStddevSampOrderBy>;
  sum?: InputMaybe<DbCallSumOrderBy>;
  varPop?: InputMaybe<DbCallVarPopOrderBy>;
  varSamp?: InputMaybe<DbCallVarSampOrderBy>;
  variance?: InputMaybe<DbCallVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbCallAvgFields = {
  __typename?: 'DbCallAvgFields';
  duration?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.call" */
export type DbCallAvgOrderBy = {
  duration?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.call". All fields are combined with a logical 'AND'. */
export type DbCallBoolExp = {
  _and?: InputMaybe<Array<DbCallBoolExp>>;
  _not?: InputMaybe<DbCallBoolExp>;
  _or?: InputMaybe<Array<DbCallBoolExp>>;
  agent?: InputMaybe<DbAgentBoolExp>;
  agentId?: InputMaybe<StringComparisonExp>;
  callDirection?: InputMaybe<EnumsCallDirectionBoolExp>;
  callOutcome?: InputMaybe<EnumsCallOutcomeBoolExp>;
  callSid?: InputMaybe<StringComparisonExp>;
  callStatus?: InputMaybe<EnumsCallStatusBoolExp>;
  call_activities?: InputMaybe<DbCallActivityBoolExp>;
  call_shop?: InputMaybe<DbShopBoolExp>;
  caller?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  direction?: InputMaybe<EnumsCallDirectionEnumComparisonExp>;
  duration?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  note?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  outcome?: InputMaybe<EnumsCallOutcomeEnumComparisonExp>;
  recipient?: InputMaybe<StringComparisonExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<EnumsCallStatusEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbCallMaxFields = {
  __typename?: 'DbCallMaxFields';
  agentId?: Maybe<Scalars['String']>;
  callSid?: Maybe<Scalars['String']>;
  caller?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  duration?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  recipient?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.call" */
export type DbCallMaxOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  callSid?: InputMaybe<OrderBy>;
  caller?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  recipient?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbCallMinFields = {
  __typename?: 'DbCallMinFields';
  agentId?: Maybe<Scalars['String']>;
  callSid?: Maybe<Scalars['String']>;
  caller?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  duration?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  recipient?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.call" */
export type DbCallMinOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  callSid?: InputMaybe<OrderBy>;
  caller?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  recipient?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.call". */
export type DbCallOrderBy = {
  agent?: InputMaybe<DbAgentOrderBy>;
  agentId?: InputMaybe<OrderBy>;
  callDirection?: InputMaybe<EnumsCallDirectionOrderBy>;
  callOutcome?: InputMaybe<EnumsCallOutcomeOrderBy>;
  callSid?: InputMaybe<OrderBy>;
  callStatus?: InputMaybe<EnumsCallStatusOrderBy>;
  call_activitiesAggregate?: InputMaybe<DbCallActivityAggregateOrderBy>;
  call_shop?: InputMaybe<DbShopOrderBy>;
  caller?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  direction?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  outcome?: InputMaybe<OrderBy>;
  recipient?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.call" */
export type DbCallSelectColumn =
  /** column name */
  | 'agentId'
  /** column name */
  | 'callSid'
  /** column name */
  | 'caller'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'direction'
  /** column name */
  | 'duration'
  /** column name */
  | 'id'
  /** column name */
  | 'note'
  /** column name */
  | 'num'
  /** column name */
  | 'outcome'
  /** column name */
  | 'recipient'
  /** column name */
  | 'shopId'
  /** column name */
  | 'status'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbCallAggregateBoolExpBool_andArgumentsColumns" columns of table "db.call" */
export type DbCallSelectColumnDbCallAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbCallAggregateBoolExpBool_orArgumentsColumns" columns of table "db.call" */
export type DbCallSelectColumnDbCallAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbCallStddevFields = {
  __typename?: 'DbCallStddevFields';
  duration?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.call" */
export type DbCallStddevOrderBy = {
  duration?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbCallStddevPopFields = {
  __typename?: 'DbCallStddevPopFields';
  duration?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.call" */
export type DbCallStddevPopOrderBy = {
  duration?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbCallStddevSampFields = {
  __typename?: 'DbCallStddevSampFields';
  duration?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.call" */
export type DbCallStddevSampOrderBy = {
  duration?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_call" */
export type DbCallStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbCallStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbCallStreamCursorValueInput = {
  agentId?: InputMaybe<Scalars['String']>;
  callSid?: InputMaybe<Scalars['String']>;
  caller?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  direction?: InputMaybe<CallDirectionEnum>;
  duration?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  outcome?: InputMaybe<CallOutcomeEnum>;
  recipient?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CallStatusEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbCallSumFields = {
  __typename?: 'DbCallSumFields';
  duration?: Maybe<Scalars['bigint']>;
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.call" */
export type DbCallSumOrderBy = {
  duration?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbCallVarPopFields = {
  __typename?: 'DbCallVarPopFields';
  duration?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.call" */
export type DbCallVarPopOrderBy = {
  duration?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbCallVarSampFields = {
  __typename?: 'DbCallVarSampFields';
  duration?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.call" */
export type DbCallVarSampOrderBy = {
  duration?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbCallVarianceFields = {
  __typename?: 'DbCallVarianceFields';
  duration?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.call" */
export type DbCallVarianceOrderBy = {
  duration?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.cdf" */
export type DbCdf = {
  __typename?: 'DbCdf';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  label: Scalars['String'];
  lck: Scalars['Int'];
  num: Scalars['bigint'];
  shopId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.cdf" */
export type DbCdfAggregateOrderBy = {
  avg?: InputMaybe<DbCdfAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbCdfMaxOrderBy>;
  min?: InputMaybe<DbCdfMinOrderBy>;
  stddev?: InputMaybe<DbCdfStddevOrderBy>;
  stddevPop?: InputMaybe<DbCdfStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbCdfStddevSampOrderBy>;
  sum?: InputMaybe<DbCdfSumOrderBy>;
  varPop?: InputMaybe<DbCdfVarPopOrderBy>;
  varSamp?: InputMaybe<DbCdfVarSampOrderBy>;
  variance?: InputMaybe<DbCdfVarianceOrderBy>;
};

/** order by avg() on columns of table "db.cdf" */
export type DbCdfAvgOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.cdf". All fields are combined with a logical 'AND'. */
export type DbCdfBoolExp = {
  _and?: InputMaybe<Array<DbCdfBoolExp>>;
  _not?: InputMaybe<DbCdfBoolExp>;
  _or?: InputMaybe<Array<DbCdfBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  label?: InputMaybe<StringComparisonExp>;
  lck?: InputMaybe<IntComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.cdf" */
export type DbCdfMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.cdf" */
export type DbCdfMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.cdf". */
export type DbCdfOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.cdf" */
export type DbCdfSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'label'
  /** column name */
  | 'lck'
  /** column name */
  | 'num'
  /** column name */
  | 'shopId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.cdf" */
export type DbCdfStddevOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.cdf" */
export type DbCdfStddevPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.cdf" */
export type DbCdfStddevSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_cdf" */
export type DbCdfStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbCdfStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbCdfStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  lck?: InputMaybe<Scalars['Int']>;
  num?: InputMaybe<Scalars['bigint']>;
  shopId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.cdf" */
export type DbCdfSumOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.cdf" */
export type DbCdfVarPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.cdf" */
export type DbCdfVarSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.cdf" */
export type DbCdfVarianceOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.consent" */
export type DbConsent = {
  __typename?: 'DbConsent';
  /** An array relationship */
  consentActivities: Array<DbConsentActivity>;
  consentSource: ConsentSourceEnum;
  /** An object relationship */
  consentSourceByConsentSource: EnumsConsentSource;
  /** An object relationship */
  consentStatus: EnumsConsentStatus;
  consentText?: Maybe<Scalars['String']>;
  /** An object relationship */
  consentTextByConsentText?: Maybe<DbConsentText>;
  consentType: ConsentTypeEnum;
  /** An object relationship */
  consentTypeByConsentType: EnumsConsentType;
  consentedOn?: Maybe<Scalars['timestamptz']>;
  contactForm: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  /** An object relationship */
  creator: DbAgent;
  /** An object relationship */
  customerAccount: DbCustomerAccount;
  customerAccountId: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  document?: Maybe<DbDocumentReference>;
  documentReferenceId?: Maybe<Scalars['String']>;
  emailId?: Maybe<Scalars['String']>;
  /** An array relationship */
  events: Array<DbConsentEvent>;
  /** An aggregate relationship */
  eventsAggregate: DbConsentEventAggregate;
  expiredAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  ipAddress?: Maybe<Scalars['String']>;
  language?: Maybe<LanguageEnum>;
  /** An object relationship */
  languageByLanguage?: Maybe<EnumsLanguage>;
  licenseId?: Maybe<Scalars['String']>;
  /** An object relationship */
  licensePartner?: Maybe<DbLicensePartner>;
  num: Scalars['bigint'];
  revokedAt?: Maybe<Scalars['timestamptz']>;
  status: ConsentStatusEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

/** columns and relationships of "db.consent" */
export type DbConsentConsentActivitiesArgs = {
  distinctOn?: InputMaybe<Array<DbConsentActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentActivityOrderBy>>;
  where?: InputMaybe<DbConsentActivityBoolExp>;
};

/** columns and relationships of "db.consent" */
export type DbConsentEventsArgs = {
  distinctOn?: InputMaybe<Array<DbConsentEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentEventOrderBy>>;
  where?: InputMaybe<DbConsentEventBoolExp>;
};

/** columns and relationships of "db.consent" */
export type DbConsentEventsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbConsentEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentEventOrderBy>>;
  where?: InputMaybe<DbConsentEventBoolExp>;
};

/** columns and relationships of "db.consent_activity" */
export type DbConsentActivity = {
  __typename?: 'DbConsentActivity';
  /** An object relationship */
  baseActivity: DbBaseActivity;
  /** An object relationship */
  consent: DbConsent;
  consentId: Scalars['String'];
  content?: Maybe<Scalars['jsonb']>;
  id: Scalars['String'];
};

/** columns and relationships of "db.consent_activity" */
export type DbConsentActivityContentArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** order by aggregate values of table "db.consent_activity" */
export type DbConsentActivityAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbConsentActivityMaxOrderBy>;
  min?: InputMaybe<DbConsentActivityMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.consent_activity". All fields are combined with a logical 'AND'. */
export type DbConsentActivityBoolExp = {
  _and?: InputMaybe<Array<DbConsentActivityBoolExp>>;
  _not?: InputMaybe<DbConsentActivityBoolExp>;
  _or?: InputMaybe<Array<DbConsentActivityBoolExp>>;
  baseActivity?: InputMaybe<DbBaseActivityBoolExp>;
  consent?: InputMaybe<DbConsentBoolExp>;
  consentId?: InputMaybe<StringComparisonExp>;
  content?: InputMaybe<JsonbComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.consent_activity" */
export type DbConsentActivityMaxOrderBy = {
  consentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.consent_activity" */
export type DbConsentActivityMinOrderBy = {
  consentId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.consent_activity". */
export type DbConsentActivityOrderBy = {
  baseActivity?: InputMaybe<DbBaseActivityOrderBy>;
  consent?: InputMaybe<DbConsentOrderBy>;
  consentId?: InputMaybe<OrderBy>;
  content?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** select columns of table "db.consent_activity" */
export type DbConsentActivitySelectColumn =
  /** column name */
  | 'consentId'
  /** column name */
  | 'content'
  /** column name */
  | 'id';

/** Streaming cursor of the table "db_consent_activity" */
export type DbConsentActivityStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbConsentActivityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbConsentActivityStreamCursorValueInput = {
  consentId?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "db.consent" */
export type DbConsentAggregate = {
  __typename?: 'DbConsentAggregate';
  aggregate?: Maybe<DbConsentAggregateFields>;
  nodes: Array<DbConsent>;
};

export type DbConsentAggregateBoolExp = {
  bool_and?: InputMaybe<DbConsentAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbConsentAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbConsentAggregateBoolExpCount>;
};

/** aggregate fields of "db.consent" */
export type DbConsentAggregateFields = {
  __typename?: 'DbConsentAggregateFields';
  avg?: Maybe<DbConsentAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbConsentMaxFields>;
  min?: Maybe<DbConsentMinFields>;
  stddev?: Maybe<DbConsentStddevFields>;
  stddevPop?: Maybe<DbConsentStddevPopFields>;
  stddevSamp?: Maybe<DbConsentStddevSampFields>;
  sum?: Maybe<DbConsentSumFields>;
  varPop?: Maybe<DbConsentVarPopFields>;
  varSamp?: Maybe<DbConsentVarSampFields>;
  variance?: Maybe<DbConsentVarianceFields>;
};

/** aggregate fields of "db.consent" */
export type DbConsentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbConsentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.consent" */
export type DbConsentAggregateOrderBy = {
  avg?: InputMaybe<DbConsentAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbConsentMaxOrderBy>;
  min?: InputMaybe<DbConsentMinOrderBy>;
  stddev?: InputMaybe<DbConsentStddevOrderBy>;
  stddevPop?: InputMaybe<DbConsentStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbConsentStddevSampOrderBy>;
  sum?: InputMaybe<DbConsentSumOrderBy>;
  varPop?: InputMaybe<DbConsentVarPopOrderBy>;
  varSamp?: InputMaybe<DbConsentVarSampOrderBy>;
  variance?: InputMaybe<DbConsentVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbConsentAvgFields = {
  __typename?: 'DbConsentAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.consent" */
export type DbConsentAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.consent". All fields are combined with a logical 'AND'. */
export type DbConsentBoolExp = {
  _and?: InputMaybe<Array<DbConsentBoolExp>>;
  _not?: InputMaybe<DbConsentBoolExp>;
  _or?: InputMaybe<Array<DbConsentBoolExp>>;
  consentActivities?: InputMaybe<DbConsentActivityBoolExp>;
  consentSource?: InputMaybe<EnumsConsentSourceEnumComparisonExp>;
  consentSourceByConsentSource?: InputMaybe<EnumsConsentSourceBoolExp>;
  consentStatus?: InputMaybe<EnumsConsentStatusBoolExp>;
  consentText?: InputMaybe<StringComparisonExp>;
  consentTextByConsentText?: InputMaybe<DbConsentTextBoolExp>;
  consentType?: InputMaybe<EnumsConsentTypeEnumComparisonExp>;
  consentTypeByConsentType?: InputMaybe<EnumsConsentTypeBoolExp>;
  consentedOn?: InputMaybe<TimestamptzComparisonExp>;
  contactForm?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  creator?: InputMaybe<DbAgentBoolExp>;
  customerAccount?: InputMaybe<DbCustomerAccountBoolExp>;
  customerAccountId?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  document?: InputMaybe<DbDocumentReferenceBoolExp>;
  documentReferenceId?: InputMaybe<StringComparisonExp>;
  emailId?: InputMaybe<StringComparisonExp>;
  events?: InputMaybe<DbConsentEventBoolExp>;
  eventsAggregate?: InputMaybe<DbConsentEventAggregateBoolExp>;
  expiredAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  ipAddress?: InputMaybe<StringComparisonExp>;
  language?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  languageByLanguage?: InputMaybe<EnumsLanguageBoolExp>;
  licenseId?: InputMaybe<StringComparisonExp>;
  licensePartner?: InputMaybe<DbLicensePartnerBoolExp>;
  num?: InputMaybe<BigintComparisonExp>;
  revokedAt?: InputMaybe<TimestamptzComparisonExp>;
  status?: InputMaybe<EnumsConsentStatusEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** columns and relationships of "db.consent_event" */
export type DbConsentEvent = {
  __typename?: 'DbConsentEvent';
  clickedAt: Scalars['timestamptz'];
  /** An object relationship */
  consent: DbConsent;
  /** An object relationship */
  consentEventStatus: EnumsConsentEventStatus;
  /** An object relationship */
  consentEventType: EnumsConsentEventType;
  consentId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  num: Scalars['bigint'];
  retryCount: Scalars['Int'];
  status: ConsentEventStatusEnum;
  type: ConsentEventTypeEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.consent_event" */
export type DbConsentEventAggregate = {
  __typename?: 'DbConsentEventAggregate';
  aggregate?: Maybe<DbConsentEventAggregateFields>;
  nodes: Array<DbConsentEvent>;
};

export type DbConsentEventAggregateBoolExp = {
  bool_and?: InputMaybe<DbConsentEventAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbConsentEventAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbConsentEventAggregateBoolExpCount>;
};

/** aggregate fields of "db.consent_event" */
export type DbConsentEventAggregateFields = {
  __typename?: 'DbConsentEventAggregateFields';
  avg?: Maybe<DbConsentEventAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbConsentEventMaxFields>;
  min?: Maybe<DbConsentEventMinFields>;
  stddev?: Maybe<DbConsentEventStddevFields>;
  stddevPop?: Maybe<DbConsentEventStddevPopFields>;
  stddevSamp?: Maybe<DbConsentEventStddevSampFields>;
  sum?: Maybe<DbConsentEventSumFields>;
  varPop?: Maybe<DbConsentEventVarPopFields>;
  varSamp?: Maybe<DbConsentEventVarSampFields>;
  variance?: Maybe<DbConsentEventVarianceFields>;
};

/** aggregate fields of "db.consent_event" */
export type DbConsentEventAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbConsentEventSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.consent_event" */
export type DbConsentEventAggregateOrderBy = {
  avg?: InputMaybe<DbConsentEventAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbConsentEventMaxOrderBy>;
  min?: InputMaybe<DbConsentEventMinOrderBy>;
  stddev?: InputMaybe<DbConsentEventStddevOrderBy>;
  stddevPop?: InputMaybe<DbConsentEventStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbConsentEventStddevSampOrderBy>;
  sum?: InputMaybe<DbConsentEventSumOrderBy>;
  varPop?: InputMaybe<DbConsentEventVarPopOrderBy>;
  varSamp?: InputMaybe<DbConsentEventVarSampOrderBy>;
  variance?: InputMaybe<DbConsentEventVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbConsentEventAvgFields = {
  __typename?: 'DbConsentEventAvgFields';
  num?: Maybe<Scalars['Float']>;
  retryCount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.consent_event" */
export type DbConsentEventAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
  retryCount?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.consent_event". All fields are combined with a logical 'AND'. */
export type DbConsentEventBoolExp = {
  _and?: InputMaybe<Array<DbConsentEventBoolExp>>;
  _not?: InputMaybe<DbConsentEventBoolExp>;
  _or?: InputMaybe<Array<DbConsentEventBoolExp>>;
  clickedAt?: InputMaybe<TimestamptzComparisonExp>;
  consent?: InputMaybe<DbConsentBoolExp>;
  consentEventStatus?: InputMaybe<EnumsConsentEventStatusBoolExp>;
  consentEventType?: InputMaybe<EnumsConsentEventTypeBoolExp>;
  consentId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  retryCount?: InputMaybe<IntComparisonExp>;
  status?: InputMaybe<EnumsConsentEventStatusEnumComparisonExp>;
  type?: InputMaybe<EnumsConsentEventTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbConsentEventMaxFields = {
  __typename?: 'DbConsentEventMaxFields';
  clickedAt?: Maybe<Scalars['timestamptz']>;
  consentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  retryCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.consent_event" */
export type DbConsentEventMaxOrderBy = {
  clickedAt?: InputMaybe<OrderBy>;
  consentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  retryCount?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbConsentEventMinFields = {
  __typename?: 'DbConsentEventMinFields';
  clickedAt?: Maybe<Scalars['timestamptz']>;
  consentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  retryCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.consent_event" */
export type DbConsentEventMinOrderBy = {
  clickedAt?: InputMaybe<OrderBy>;
  consentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  retryCount?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.consent_event". */
export type DbConsentEventOrderBy = {
  clickedAt?: InputMaybe<OrderBy>;
  consent?: InputMaybe<DbConsentOrderBy>;
  consentEventStatus?: InputMaybe<EnumsConsentEventStatusOrderBy>;
  consentEventType?: InputMaybe<EnumsConsentEventTypeOrderBy>;
  consentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  retryCount?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.consent_event" */
export type DbConsentEventSelectColumn =
  /** column name */
  | 'clickedAt'
  /** column name */
  | 'consentId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'num'
  /** column name */
  | 'retryCount'
  /** column name */
  | 'status'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbConsentEventAggregateBoolExpBool_andArgumentsColumns" columns of table "db.consent_event" */
export type DbConsentEventSelectColumnDbConsentEventAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbConsentEventAggregateBoolExpBool_orArgumentsColumns" columns of table "db.consent_event" */
export type DbConsentEventSelectColumnDbConsentEventAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbConsentEventStddevFields = {
  __typename?: 'DbConsentEventStddevFields';
  num?: Maybe<Scalars['Float']>;
  retryCount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.consent_event" */
export type DbConsentEventStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
  retryCount?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbConsentEventStddevPopFields = {
  __typename?: 'DbConsentEventStddevPopFields';
  num?: Maybe<Scalars['Float']>;
  retryCount?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.consent_event" */
export type DbConsentEventStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
  retryCount?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbConsentEventStddevSampFields = {
  __typename?: 'DbConsentEventStddevSampFields';
  num?: Maybe<Scalars['Float']>;
  retryCount?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.consent_event" */
export type DbConsentEventStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
  retryCount?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_consent_event" */
export type DbConsentEventStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbConsentEventStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbConsentEventStreamCursorValueInput = {
  clickedAt?: InputMaybe<Scalars['timestamptz']>;
  consentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  retryCount?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<ConsentEventStatusEnum>;
  type?: InputMaybe<ConsentEventTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbConsentEventSumFields = {
  __typename?: 'DbConsentEventSumFields';
  num?: Maybe<Scalars['bigint']>;
  retryCount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "db.consent_event" */
export type DbConsentEventSumOrderBy = {
  num?: InputMaybe<OrderBy>;
  retryCount?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbConsentEventVarPopFields = {
  __typename?: 'DbConsentEventVarPopFields';
  num?: Maybe<Scalars['Float']>;
  retryCount?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.consent_event" */
export type DbConsentEventVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
  retryCount?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbConsentEventVarSampFields = {
  __typename?: 'DbConsentEventVarSampFields';
  num?: Maybe<Scalars['Float']>;
  retryCount?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.consent_event" */
export type DbConsentEventVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
  retryCount?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbConsentEventVarianceFields = {
  __typename?: 'DbConsentEventVarianceFields';
  num?: Maybe<Scalars['Float']>;
  retryCount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.consent_event" */
export type DbConsentEventVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
  retryCount?: InputMaybe<OrderBy>;
};

/** aggregate max on columns */
export type DbConsentMaxFields = {
  __typename?: 'DbConsentMaxFields';
  consentText?: Maybe<Scalars['String']>;
  consentedOn?: Maybe<Scalars['timestamptz']>;
  contactForm?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  customerAccountId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  documentReferenceId?: Maybe<Scalars['String']>;
  emailId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  licenseId?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  revokedAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.consent" */
export type DbConsentMaxOrderBy = {
  consentText?: InputMaybe<OrderBy>;
  consentedOn?: InputMaybe<OrderBy>;
  contactForm?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  customerAccountId?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  documentReferenceId?: InputMaybe<OrderBy>;
  emailId?: InputMaybe<OrderBy>;
  expiredAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ipAddress?: InputMaybe<OrderBy>;
  licenseId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  revokedAt?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbConsentMinFields = {
  __typename?: 'DbConsentMinFields';
  consentText?: Maybe<Scalars['String']>;
  consentedOn?: Maybe<Scalars['timestamptz']>;
  contactForm?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  customerAccountId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  documentReferenceId?: Maybe<Scalars['String']>;
  emailId?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  licenseId?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  revokedAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.consent" */
export type DbConsentMinOrderBy = {
  consentText?: InputMaybe<OrderBy>;
  consentedOn?: InputMaybe<OrderBy>;
  contactForm?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  customerAccountId?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  documentReferenceId?: InputMaybe<OrderBy>;
  emailId?: InputMaybe<OrderBy>;
  expiredAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ipAddress?: InputMaybe<OrderBy>;
  licenseId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  revokedAt?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.consent". */
export type DbConsentOrderBy = {
  consentActivitiesAggregate?: InputMaybe<DbConsentActivityAggregateOrderBy>;
  consentSource?: InputMaybe<OrderBy>;
  consentSourceByConsentSource?: InputMaybe<EnumsConsentSourceOrderBy>;
  consentStatus?: InputMaybe<EnumsConsentStatusOrderBy>;
  consentText?: InputMaybe<OrderBy>;
  consentTextByConsentText?: InputMaybe<DbConsentTextOrderBy>;
  consentType?: InputMaybe<OrderBy>;
  consentTypeByConsentType?: InputMaybe<EnumsConsentTypeOrderBy>;
  consentedOn?: InputMaybe<OrderBy>;
  contactForm?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  creator?: InputMaybe<DbAgentOrderBy>;
  customerAccount?: InputMaybe<DbCustomerAccountOrderBy>;
  customerAccountId?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  document?: InputMaybe<DbDocumentReferenceOrderBy>;
  documentReferenceId?: InputMaybe<OrderBy>;
  emailId?: InputMaybe<OrderBy>;
  eventsAggregate?: InputMaybe<DbConsentEventAggregateOrderBy>;
  expiredAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ipAddress?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  languageByLanguage?: InputMaybe<EnumsLanguageOrderBy>;
  licenseId?: InputMaybe<OrderBy>;
  licensePartner?: InputMaybe<DbLicensePartnerOrderBy>;
  num?: InputMaybe<OrderBy>;
  revokedAt?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "db.consent" */
export type DbConsentSelectColumn =
  /** column name */
  | 'consentSource'
  /** column name */
  | 'consentText'
  /** column name */
  | 'consentType'
  /** column name */
  | 'consentedOn'
  /** column name */
  | 'contactForm'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'customerAccountId'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'documentReferenceId'
  /** column name */
  | 'emailId'
  /** column name */
  | 'expiredAt'
  /** column name */
  | 'id'
  /** column name */
  | 'ipAddress'
  /** column name */
  | 'language'
  /** column name */
  | 'licenseId'
  /** column name */
  | 'num'
  /** column name */
  | 'revokedAt'
  /** column name */
  | 'status'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy'
  /** column name */
  | 'url';

/** select "dbConsentAggregateBoolExpBool_andArgumentsColumns" columns of table "db.consent" */
export type DbConsentSelectColumnDbConsentAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbConsentAggregateBoolExpBool_orArgumentsColumns" columns of table "db.consent" */
export type DbConsentSelectColumnDbConsentAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbConsentStddevFields = {
  __typename?: 'DbConsentStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.consent" */
export type DbConsentStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbConsentStddevPopFields = {
  __typename?: 'DbConsentStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.consent" */
export type DbConsentStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbConsentStddevSampFields = {
  __typename?: 'DbConsentStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.consent" */
export type DbConsentStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_consent" */
export type DbConsentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbConsentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbConsentStreamCursorValueInput = {
  consentSource?: InputMaybe<ConsentSourceEnum>;
  consentText?: InputMaybe<Scalars['String']>;
  consentType?: InputMaybe<ConsentTypeEnum>;
  consentedOn?: InputMaybe<Scalars['timestamptz']>;
  contactForm?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  customerAccountId?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  documentReferenceId?: InputMaybe<Scalars['String']>;
  emailId?: InputMaybe<Scalars['String']>;
  expiredAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageEnum>;
  licenseId?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  revokedAt?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<ConsentStatusEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbConsentSumFields = {
  __typename?: 'DbConsentSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.consent" */
export type DbConsentSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.consent_template" */
export type DbConsentTemplate = {
  __typename?: 'DbConsentTemplate';
  consentType: ConsentTypeEnum;
  /** An object relationship */
  consent_template_type: EnumsConsentTemplateType;
  contentLanguage: LanguageEnum;
  contentText: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  /** An object relationship */
  language: EnumsLanguage;
  /** An object relationship */
  shop?: Maybe<DbShop>;
  shopId?: Maybe<Scalars['String']>;
  templateUsageType: ConsentTemplateTypeEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.consent_template" */
export type DbConsentTemplateAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbConsentTemplateMaxOrderBy>;
  min?: InputMaybe<DbConsentTemplateMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.consent_template". All fields are combined with a logical 'AND'. */
export type DbConsentTemplateBoolExp = {
  _and?: InputMaybe<Array<DbConsentTemplateBoolExp>>;
  _not?: InputMaybe<DbConsentTemplateBoolExp>;
  _or?: InputMaybe<Array<DbConsentTemplateBoolExp>>;
  consentType?: InputMaybe<EnumsConsentTypeEnumComparisonExp>;
  consent_template_type?: InputMaybe<EnumsConsentTemplateTypeBoolExp>;
  contentLanguage?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  contentText?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  language?: InputMaybe<EnumsLanguageBoolExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  templateUsageType?: InputMaybe<EnumsConsentTemplateTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.consent_template" */
export type DbConsentTemplateMaxOrderBy = {
  contentText?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.consent_template" */
export type DbConsentTemplateMinOrderBy = {
  contentText?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.consent_template". */
export type DbConsentTemplateOrderBy = {
  consentType?: InputMaybe<OrderBy>;
  consent_template_type?: InputMaybe<EnumsConsentTemplateTypeOrderBy>;
  contentLanguage?: InputMaybe<OrderBy>;
  contentText?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  language?: InputMaybe<EnumsLanguageOrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  templateUsageType?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.consent_template" */
export type DbConsentTemplateSelectColumn =
  /** column name */
  | 'consentType'
  /** column name */
  | 'contentLanguage'
  /** column name */
  | 'contentText'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'shopId'
  /** column name */
  | 'templateUsageType'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** Streaming cursor of the table "db_consent_template" */
export type DbConsentTemplateStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbConsentTemplateStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbConsentTemplateStreamCursorValueInput = {
  consentType?: InputMaybe<ConsentTypeEnum>;
  contentLanguage?: InputMaybe<LanguageEnum>;
  contentText?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['String']>;
  templateUsageType?: InputMaybe<ConsentTemplateTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "db.consent_text" */
export type DbConsentText = {
  __typename?: 'DbConsentText';
  /** An array relationship */
  consents: Array<DbConsent>;
  /** An aggregate relationship */
  consentsAggregate: DbConsentAggregate;
  content: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  hash: Scalars['String'];
  id: Scalars['String'];
  num: Scalars['bigint'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** columns and relationships of "db.consent_text" */
export type DbConsentTextConsentsArgs = {
  distinctOn?: InputMaybe<Array<DbConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentOrderBy>>;
  where?: InputMaybe<DbConsentBoolExp>;
};

/** columns and relationships of "db.consent_text" */
export type DbConsentTextConsentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentOrderBy>>;
  where?: InputMaybe<DbConsentBoolExp>;
};

/** Boolean expression to filter rows from the table "db.consent_text". All fields are combined with a logical 'AND'. */
export type DbConsentTextBoolExp = {
  _and?: InputMaybe<Array<DbConsentTextBoolExp>>;
  _not?: InputMaybe<DbConsentTextBoolExp>;
  _or?: InputMaybe<Array<DbConsentTextBoolExp>>;
  consents?: InputMaybe<DbConsentBoolExp>;
  consentsAggregate?: InputMaybe<DbConsentAggregateBoolExp>;
  content?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  hash?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "db.consent_text". */
export type DbConsentTextOrderBy = {
  consentsAggregate?: InputMaybe<DbConsentAggregateOrderBy>;
  content?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  hash?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.consent_text" */
export type DbConsentTextSelectColumn =
  /** column name */
  | 'content'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'hash'
  /** column name */
  | 'id'
  /** column name */
  | 'num'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** Streaming cursor of the table "db_consent_text" */
export type DbConsentTextStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbConsentTextStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbConsentTextStreamCursorValueInput = {
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate varPop on columns */
export type DbConsentVarPopFields = {
  __typename?: 'DbConsentVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.consent" */
export type DbConsentVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbConsentVarSampFields = {
  __typename?: 'DbConsentVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.consent" */
export type DbConsentVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbConsentVarianceFields = {
  __typename?: 'DbConsentVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.consent" */
export type DbConsentVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.contact" */
export type DbContact = {
  __typename?: 'DbContact';
  /** An array relationship */
  addresses: Array<DbContactAddress>;
  /** An aggregate relationship */
  addressesAggregate: DbContactAddressAggregate;
  /** An array relationship */
  agentsAssignedToLeadsOfContact: Array<DbAgentsAssignedToLeadsOfContact>;
  /** An array relationship */
  appointmentContacts: Array<DbAppointmentContact>;
  autoCc?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  baseActivities: Array<DbBaseActivity>;
  /** An aggregate relationship */
  baseActivitiesAggregate: DbBaseActivityAggregate;
  birthdate?: Maybe<Scalars['date']>;
  blocked: Scalars['Boolean'];
  /** An object relationship */
  contactContactStatus: EnumsContactStatus;
  /** An object relationship */
  contactContactType: EnumsContactType;
  /** An object relationship */
  contactGdpr?: Maybe<DbContactGdpr>;
  /** An array relationship */
  contactRelationships: Array<DbContactRelationship>;
  /** An array relationship */
  contactRenterInformations: Array<DbContactRenterInformation>;
  contactStatus: ContactStatusEnum;
  /** An array relationship */
  contact_contact_assignments: Array<DbContactAssignment>;
  /** An object relationship */
  country: EnumsCountry;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  /** An object relationship */
  creator?: Maybe<DbAgent>;
  /** An object relationship */
  customerAccount?: Maybe<DbCustomerAccount>;
  customerAccountId?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  documentReferences: Array<DbDocumentReference>;
  /** An aggregate relationship */
  documentReferencesAggregate: DbDocumentReferenceAggregate;
  /** An array relationship */
  emailAddresses: Array<DbContactEmailAddress>;
  /** An aggregate relationship */
  emailAddressesAggregate: DbContactEmailAddressAggregate;
  /** An array relationship */
  emailLinks: Array<DbEmailLink>;
  /** An aggregate relationship */
  emailLinksAggregate: DbEmailLinkAggregate;
  firstName?: Maybe<Scalars['String']>;
  formattedSalutation?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  gdprId?: Maybe<Scalars['String']>;
  go3ContactKey?: Maybe<Scalars['Int']>;
  go3Utag?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  idNumber?: Maybe<Scalars['String']>;
  kycSearchId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  language?: Maybe<EnumsLanguage>;
  /** An object relationship */
  lastInteractionActivity?: Maybe<DbBaseActivity>;
  lastInteractionActivityId?: Maybe<Scalars['String']>;
  lastInteractionActivityOrderDate?: Maybe<Scalars['timestamptz']>;
  lastName?: Maybe<Scalars['String']>;
  lck: Scalars['Int'];
  /** An array relationship */
  leadhubGo3ContactLinks: Array<DbLeadhubGo3ContactLink>;
  /** An aggregate relationship */
  leadhubGo3ContactLinksAggregate: DbLeadhubGo3ContactLinkAggregate;
  /** An array relationship */
  leads: Array<DbLead>;
  /** An aggregate relationship */
  leadsAggregate: DbLeadAggregate;
  legacyGo3NewsletterType?: Maybe<LegacyGo3NewsletterTypeEnum>;
  /** An object relationship */
  legalEntity?: Maybe<DbLegalEntity>;
  legalEntityId?: Maybe<Scalars['String']>;
  lowerFirstName?: Maybe<Scalars['String']>;
  lowerLastName?: Maybe<Scalars['String']>;
  mostRecentActivityCreatedAt?: Maybe<Scalars['timestamptz']>;
  nationality: CountryEnum;
  noMail?: Maybe<Scalars['Boolean']>;
  normalizedLowerFirstName?: Maybe<Scalars['String']>;
  normalizedLowerLastName?: Maybe<Scalars['String']>;
  num: Scalars['bigint'];
  partnerFirstName?: Maybe<Scalars['String']>;
  partnerLastName?: Maybe<Scalars['String']>;
  personalNote?: Maybe<Scalars['String']>;
  /** An array relationship */
  phoneNumbers: Array<DbContactPhoneNumber>;
  /** An aggregate relationship */
  phoneNumbersAggregate: DbContactPhoneNumberAggregate;
  placeOfBirth?: Maybe<Scalars['String']>;
  preferredLanguage?: Maybe<LanguageEnum>;
  /** An array relationship */
  preferredTimes: Array<DbContactPreferredTime>;
  /** An aggregate relationship */
  preferredTimesAggregate: DbContactPreferredTimeAggregate;
  /** An array relationship */
  preferredWays: Array<DbContactPreferredWay>;
  /** An aggregate relationship */
  preferredWaysAggregate: DbContactPreferredWayAggregate;
  /** An array relationship */
  qualificationFormLinks: Array<DbQualificationFormLink>;
  /** An aggregate relationship */
  qualificationFormLinksAggregate: DbQualificationFormLinkAggregate;
  /** An array relationship */
  referrals: Array<DbReferral>;
  /** An aggregate relationship */
  referralsAggregate: DbReferralAggregate;
  /** An array relationship */
  relationships: Array<DbContactRelationship>;
  salutation?: Maybe<SalutationEnum>;
  /** An object relationship */
  salutationBySalutation?: Maybe<EnumsSalutation>;
  /** An array relationship */
  sellerRentOutSpecifications: Array<DbSellerRentOutSpecification>;
  /** An object relationship */
  shop: DbShop;
  shopId: Scalars['String'];
  /** An array relationship */
  snapshotSellerRentOutSpecifications: Array<DbSnapshotSellerRentOutSpecification>;
  /** An aggregate relationship */
  snapshotSellerRentOutSpecificationsAggregate: DbSnapshotSellerRentOutSpecificationAggregate;
  source?: Maybe<Scalars['String']>;
  /** An array relationship */
  tags: Array<DbContactTag>;
  /** An array relationship */
  taskContacts: Array<DbTaskContact>;
  title?: Maybe<Scalars['String']>;
  type: ContactTypeEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** columns and relationships of "db.contact" */
export type DbContactAddressesArgs = {
  distinctOn?: InputMaybe<Array<DbContactAddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactAddressOrderBy>>;
  where?: InputMaybe<DbContactAddressBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactAddressesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbContactAddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactAddressOrderBy>>;
  where?: InputMaybe<DbContactAddressBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactAgentsAssignedToLeadsOfContactArgs = {
  distinctOn?: InputMaybe<Array<DbAgentsAssignedToLeadsOfContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentsAssignedToLeadsOfContactOrderBy>>;
  where?: InputMaybe<DbAgentsAssignedToLeadsOfContactBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactAppointmentContactsArgs = {
  distinctOn?: InputMaybe<Array<DbAppointmentContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAppointmentContactOrderBy>>;
  where?: InputMaybe<DbAppointmentContactBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactBaseActivitiesArgs = {
  distinctOn?: InputMaybe<Array<DbBaseActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBaseActivityOrderBy>>;
  where?: InputMaybe<DbBaseActivityBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactBaseActivitiesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbBaseActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBaseActivityOrderBy>>;
  where?: InputMaybe<DbBaseActivityBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactContactRelationshipsArgs = {
  distinctOn?: InputMaybe<Array<DbContactRelationshipSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactRelationshipOrderBy>>;
  where?: InputMaybe<DbContactRelationshipBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactContactRenterInformationsArgs = {
  distinctOn?: InputMaybe<Array<DbContactRenterInformationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactRenterInformationOrderBy>>;
  where?: InputMaybe<DbContactRenterInformationBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactContactContactAssignmentsArgs = {
  distinctOn?: InputMaybe<Array<DbContactAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactAssignmentOrderBy>>;
  where?: InputMaybe<DbContactAssignmentBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactDocumentReferencesArgs = {
  distinctOn?: InputMaybe<Array<DbDocumentReferenceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbDocumentReferenceOrderBy>>;
  where?: InputMaybe<DbDocumentReferenceBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactDocumentReferencesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbDocumentReferenceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbDocumentReferenceOrderBy>>;
  where?: InputMaybe<DbDocumentReferenceBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactEmailAddressesArgs = {
  distinctOn?: InputMaybe<Array<DbContactEmailAddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactEmailAddressOrderBy>>;
  where?: InputMaybe<DbContactEmailAddressBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactEmailAddressesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbContactEmailAddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactEmailAddressOrderBy>>;
  where?: InputMaybe<DbContactEmailAddressBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactEmailLinksArgs = {
  distinctOn?: InputMaybe<Array<DbEmailLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailLinkOrderBy>>;
  where?: InputMaybe<DbEmailLinkBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactEmailLinksAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailLinkOrderBy>>;
  where?: InputMaybe<DbEmailLinkBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactLeadhubGo3ContactLinksArgs = {
  distinctOn?: InputMaybe<Array<DbLeadhubGo3ContactLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadhubGo3ContactLinkOrderBy>>;
  where?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactLeadhubGo3ContactLinksAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLeadhubGo3ContactLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadhubGo3ContactLinkOrderBy>>;
  where?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactLeadsArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactLeadsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactPhoneNumbersArgs = {
  distinctOn?: InputMaybe<Array<DbContactPhoneNumberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactPhoneNumberOrderBy>>;
  where?: InputMaybe<DbContactPhoneNumberBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactPhoneNumbersAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbContactPhoneNumberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactPhoneNumberOrderBy>>;
  where?: InputMaybe<DbContactPhoneNumberBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactPreferredTimesArgs = {
  distinctOn?: InputMaybe<Array<DbContactPreferredTimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactPreferredTimeOrderBy>>;
  where?: InputMaybe<DbContactPreferredTimeBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactPreferredTimesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbContactPreferredTimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactPreferredTimeOrderBy>>;
  where?: InputMaybe<DbContactPreferredTimeBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactPreferredWaysArgs = {
  distinctOn?: InputMaybe<Array<DbContactPreferredWaySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactPreferredWayOrderBy>>;
  where?: InputMaybe<DbContactPreferredWayBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactPreferredWaysAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbContactPreferredWaySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactPreferredWayOrderBy>>;
  where?: InputMaybe<DbContactPreferredWayBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactQualificationFormLinksArgs = {
  distinctOn?: InputMaybe<Array<DbQualificationFormLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbQualificationFormLinkOrderBy>>;
  where?: InputMaybe<DbQualificationFormLinkBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactQualificationFormLinksAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbQualificationFormLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbQualificationFormLinkOrderBy>>;
  where?: InputMaybe<DbQualificationFormLinkBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactReferralsArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactReferralsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactRelationshipsArgs = {
  distinctOn?: InputMaybe<Array<DbContactRelationshipSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactRelationshipOrderBy>>;
  where?: InputMaybe<DbContactRelationshipBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactSellerRentOutSpecificationsArgs = {
  distinctOn?: InputMaybe<Array<DbSellerRentOutSpecificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSellerRentOutSpecificationOrderBy>>;
  where?: InputMaybe<DbSellerRentOutSpecificationBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactSnapshotSellerRentOutSpecificationsArgs = {
  distinctOn?: InputMaybe<Array<DbSnapshotSellerRentOutSpecificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSnapshotSellerRentOutSpecificationOrderBy>>;
  where?: InputMaybe<DbSnapshotSellerRentOutSpecificationBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactSnapshotSellerRentOutSpecificationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbSnapshotSellerRentOutSpecificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSnapshotSellerRentOutSpecificationOrderBy>>;
  where?: InputMaybe<DbSnapshotSellerRentOutSpecificationBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactTagsArgs = {
  distinctOn?: InputMaybe<Array<DbContactTagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactTagOrderBy>>;
  where?: InputMaybe<DbContactTagBoolExp>;
};

/** columns and relationships of "db.contact" */
export type DbContactTaskContactsArgs = {
  distinctOn?: InputMaybe<Array<DbTaskContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTaskContactOrderBy>>;
  where?: InputMaybe<DbTaskContactBoolExp>;
};

/** columns and relationships of "db.contact_address" */
export type DbContactAddress = {
  __typename?: 'DbContactAddress';
  /** An object relationship */
  address: DbAddress;
  addressId: Scalars['String'];
  /** An object relationship */
  addressType: EnumsAddressType;
  /** An object relationship */
  contact: DbContact;
  contactId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  num: Scalars['bigint'];
  preferred: Scalars['Boolean'];
  type: AddressTypeEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.contact_address" */
export type DbContactAddressAggregate = {
  __typename?: 'DbContactAddressAggregate';
  aggregate?: Maybe<DbContactAddressAggregateFields>;
  nodes: Array<DbContactAddress>;
};

export type DbContactAddressAggregateBoolExp = {
  bool_and?: InputMaybe<DbContactAddressAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbContactAddressAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbContactAddressAggregateBoolExpCount>;
};

/** aggregate fields of "db.contact_address" */
export type DbContactAddressAggregateFields = {
  __typename?: 'DbContactAddressAggregateFields';
  avg?: Maybe<DbContactAddressAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbContactAddressMaxFields>;
  min?: Maybe<DbContactAddressMinFields>;
  stddev?: Maybe<DbContactAddressStddevFields>;
  stddevPop?: Maybe<DbContactAddressStddevPopFields>;
  stddevSamp?: Maybe<DbContactAddressStddevSampFields>;
  sum?: Maybe<DbContactAddressSumFields>;
  varPop?: Maybe<DbContactAddressVarPopFields>;
  varSamp?: Maybe<DbContactAddressVarSampFields>;
  variance?: Maybe<DbContactAddressVarianceFields>;
};

/** aggregate fields of "db.contact_address" */
export type DbContactAddressAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbContactAddressSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.contact_address" */
export type DbContactAddressAggregateOrderBy = {
  avg?: InputMaybe<DbContactAddressAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbContactAddressMaxOrderBy>;
  min?: InputMaybe<DbContactAddressMinOrderBy>;
  stddev?: InputMaybe<DbContactAddressStddevOrderBy>;
  stddevPop?: InputMaybe<DbContactAddressStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbContactAddressStddevSampOrderBy>;
  sum?: InputMaybe<DbContactAddressSumOrderBy>;
  varPop?: InputMaybe<DbContactAddressVarPopOrderBy>;
  varSamp?: InputMaybe<DbContactAddressVarSampOrderBy>;
  variance?: InputMaybe<DbContactAddressVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbContactAddressAvgFields = {
  __typename?: 'DbContactAddressAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.contact_address" */
export type DbContactAddressAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.contact_address". All fields are combined with a logical 'AND'. */
export type DbContactAddressBoolExp = {
  _and?: InputMaybe<Array<DbContactAddressBoolExp>>;
  _not?: InputMaybe<DbContactAddressBoolExp>;
  _or?: InputMaybe<Array<DbContactAddressBoolExp>>;
  address?: InputMaybe<DbAddressBoolExp>;
  addressId?: InputMaybe<StringComparisonExp>;
  addressType?: InputMaybe<EnumsAddressTypeBoolExp>;
  contact?: InputMaybe<DbContactBoolExp>;
  contactId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  preferred?: InputMaybe<BooleanComparisonExp>;
  type?: InputMaybe<EnumsAddressTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbContactAddressMaxFields = {
  __typename?: 'DbContactAddressMaxFields';
  addressId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.contact_address" */
export type DbContactAddressMaxOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbContactAddressMinFields = {
  __typename?: 'DbContactAddressMinFields';
  addressId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.contact_address" */
export type DbContactAddressMinOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.contact_address". */
export type DbContactAddressOrderBy = {
  address?: InputMaybe<DbAddressOrderBy>;
  addressId?: InputMaybe<OrderBy>;
  addressType?: InputMaybe<EnumsAddressTypeOrderBy>;
  contact?: InputMaybe<DbContactOrderBy>;
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  preferred?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.contact_address" */
export type DbContactAddressSelectColumn =
  /** column name */
  | 'addressId'
  /** column name */
  | 'contactId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'num'
  /** column name */
  | 'preferred'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbContactAddressAggregateBoolExpBool_andArgumentsColumns" columns of table "db.contact_address" */
export type DbContactAddressSelectColumnDbContactAddressAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  | 'deleted'
  /** column name */
  | 'preferred';

/** select "dbContactAddressAggregateBoolExpBool_orArgumentsColumns" columns of table "db.contact_address" */
export type DbContactAddressSelectColumnDbContactAddressAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  | 'deleted'
  /** column name */
  | 'preferred';

/** aggregate stddev on columns */
export type DbContactAddressStddevFields = {
  __typename?: 'DbContactAddressStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.contact_address" */
export type DbContactAddressStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbContactAddressStddevPopFields = {
  __typename?: 'DbContactAddressStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.contact_address" */
export type DbContactAddressStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbContactAddressStddevSampFields = {
  __typename?: 'DbContactAddressStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.contact_address" */
export type DbContactAddressStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type DbContactAddressSumFields = {
  __typename?: 'DbContactAddressSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.contact_address" */
export type DbContactAddressSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbContactAddressVarPopFields = {
  __typename?: 'DbContactAddressVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.contact_address" */
export type DbContactAddressVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbContactAddressVarSampFields = {
  __typename?: 'DbContactAddressVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.contact_address" */
export type DbContactAddressVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbContactAddressVarianceFields = {
  __typename?: 'DbContactAddressVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.contact_address" */
export type DbContactAddressVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregated selection of "db.contact" */
export type DbContactAggregate = {
  __typename?: 'DbContactAggregate';
  aggregate?: Maybe<DbContactAggregateFields>;
  nodes: Array<DbContact>;
};

export type DbContactAggregateBoolExp = {
  bool_and?: InputMaybe<DbContactAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbContactAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbContactAggregateBoolExpCount>;
};

/** aggregate fields of "db.contact" */
export type DbContactAggregateFields = {
  __typename?: 'DbContactAggregateFields';
  avg?: Maybe<DbContactAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbContactMaxFields>;
  min?: Maybe<DbContactMinFields>;
  stddev?: Maybe<DbContactStddevFields>;
  stddevPop?: Maybe<DbContactStddevPopFields>;
  stddevSamp?: Maybe<DbContactStddevSampFields>;
  sum?: Maybe<DbContactSumFields>;
  varPop?: Maybe<DbContactVarPopFields>;
  varSamp?: Maybe<DbContactVarSampFields>;
  variance?: Maybe<DbContactVarianceFields>;
};

/** aggregate fields of "db.contact" */
export type DbContactAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbContactSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.contact" */
export type DbContactAggregateOrderBy = {
  avg?: InputMaybe<DbContactAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbContactMaxOrderBy>;
  min?: InputMaybe<DbContactMinOrderBy>;
  stddev?: InputMaybe<DbContactStddevOrderBy>;
  stddevPop?: InputMaybe<DbContactStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbContactStddevSampOrderBy>;
  sum?: InputMaybe<DbContactSumOrderBy>;
  varPop?: InputMaybe<DbContactVarPopOrderBy>;
  varSamp?: InputMaybe<DbContactVarSampOrderBy>;
  variance?: InputMaybe<DbContactVarianceOrderBy>;
};

/** columns and relationships of "db.contact_assignment" */
export type DbContactAssignment = {
  __typename?: 'DbContactAssignment';
  agentId?: Maybe<Scalars['String']>;
  /** An object relationship */
  contact: DbContact;
  contactId: Scalars['String'];
};

/** order by aggregate values of table "db.contact_assignment" */
export type DbContactAssignmentAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbContactAssignmentMaxOrderBy>;
  min?: InputMaybe<DbContactAssignmentMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.contact_assignment". All fields are combined with a logical 'AND'. */
export type DbContactAssignmentBoolExp = {
  _and?: InputMaybe<Array<DbContactAssignmentBoolExp>>;
  _not?: InputMaybe<DbContactAssignmentBoolExp>;
  _or?: InputMaybe<Array<DbContactAssignmentBoolExp>>;
  agentId?: InputMaybe<StringComparisonExp>;
  contact?: InputMaybe<DbContactBoolExp>;
  contactId?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.contact_assignment" */
export type DbContactAssignmentMaxOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  contactId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.contact_assignment" */
export type DbContactAssignmentMinOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  contactId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.contact_assignment". */
export type DbContactAssignmentOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  contact?: InputMaybe<DbContactOrderBy>;
  contactId?: InputMaybe<OrderBy>;
};

/** select columns of table "db.contact_assignment" */
export type DbContactAssignmentSelectColumn =
  /** column name */
  | 'agentId'
  /** column name */
  | 'contactId';

/** Streaming cursor of the table "db_contact_assignment" */
export type DbContactAssignmentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbContactAssignmentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbContactAssignmentStreamCursorValueInput = {
  agentId?: InputMaybe<Scalars['String']>;
  contactId?: InputMaybe<Scalars['String']>;
};

/** aggregate avg on columns */
export type DbContactAvgFields = {
  __typename?: 'DbContactAvgFields';
  go3ContactKey?: Maybe<Scalars['Float']>;
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.contact" */
export type DbContactAvgOrderBy = {
  go3ContactKey?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.contact". All fields are combined with a logical 'AND'. */
export type DbContactBoolExp = {
  _and?: InputMaybe<Array<DbContactBoolExp>>;
  _not?: InputMaybe<DbContactBoolExp>;
  _or?: InputMaybe<Array<DbContactBoolExp>>;
  addresses?: InputMaybe<DbContactAddressBoolExp>;
  addressesAggregate?: InputMaybe<DbContactAddressAggregateBoolExp>;
  agentsAssignedToLeadsOfContact?: InputMaybe<DbAgentsAssignedToLeadsOfContactBoolExp>;
  appointmentContacts?: InputMaybe<DbAppointmentContactBoolExp>;
  autoCc?: InputMaybe<BooleanComparisonExp>;
  baseActivities?: InputMaybe<DbBaseActivityBoolExp>;
  baseActivitiesAggregate?: InputMaybe<DbBaseActivityAggregateBoolExp>;
  birthdate?: InputMaybe<DateComparisonExp>;
  blocked?: InputMaybe<BooleanComparisonExp>;
  contactContactStatus?: InputMaybe<EnumsContactStatusBoolExp>;
  contactContactType?: InputMaybe<EnumsContactTypeBoolExp>;
  contactGdpr?: InputMaybe<DbContactGdprBoolExp>;
  contactRelationships?: InputMaybe<DbContactRelationshipBoolExp>;
  contactRenterInformations?: InputMaybe<DbContactRenterInformationBoolExp>;
  contactStatus?: InputMaybe<EnumsContactStatusEnumComparisonExp>;
  contact_contact_assignments?: InputMaybe<DbContactAssignmentBoolExp>;
  country?: InputMaybe<EnumsCountryBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  creator?: InputMaybe<DbAgentBoolExp>;
  customerAccount?: InputMaybe<DbCustomerAccountBoolExp>;
  customerAccountId?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  documentReferences?: InputMaybe<DbDocumentReferenceBoolExp>;
  documentReferencesAggregate?: InputMaybe<DbDocumentReferenceAggregateBoolExp>;
  emailAddresses?: InputMaybe<DbContactEmailAddressBoolExp>;
  emailAddressesAggregate?: InputMaybe<DbContactEmailAddressAggregateBoolExp>;
  emailLinks?: InputMaybe<DbEmailLinkBoolExp>;
  emailLinksAggregate?: InputMaybe<DbEmailLinkAggregateBoolExp>;
  firstName?: InputMaybe<StringComparisonExp>;
  formattedSalutation?: InputMaybe<StringComparisonExp>;
  fullName?: InputMaybe<StringComparisonExp>;
  gdprId?: InputMaybe<StringComparisonExp>;
  go3ContactKey?: InputMaybe<IntComparisonExp>;
  go3Utag?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  idNumber?: InputMaybe<StringComparisonExp>;
  kycSearchId?: InputMaybe<UuidComparisonExp>;
  language?: InputMaybe<EnumsLanguageBoolExp>;
  lastInteractionActivity?: InputMaybe<DbBaseActivityBoolExp>;
  lastInteractionActivityId?: InputMaybe<StringComparisonExp>;
  lastInteractionActivityOrderDate?: InputMaybe<TimestamptzComparisonExp>;
  lastName?: InputMaybe<StringComparisonExp>;
  lck?: InputMaybe<IntComparisonExp>;
  leadhubGo3ContactLinks?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
  leadhubGo3ContactLinksAggregate?: InputMaybe<DbLeadhubGo3ContactLinkAggregateBoolExp>;
  leads?: InputMaybe<DbLeadBoolExp>;
  leadsAggregate?: InputMaybe<DbLeadAggregateBoolExp>;
  legacyGo3NewsletterType?: InputMaybe<EnumsLegacyGo3NewsletterTypeEnumComparisonExp>;
  legalEntity?: InputMaybe<DbLegalEntityBoolExp>;
  legalEntityId?: InputMaybe<StringComparisonExp>;
  lowerFirstName?: InputMaybe<StringComparisonExp>;
  lowerLastName?: InputMaybe<StringComparisonExp>;
  mostRecentActivityCreatedAt?: InputMaybe<TimestamptzComparisonExp>;
  nationality?: InputMaybe<EnumsCountryEnumComparisonExp>;
  noMail?: InputMaybe<BooleanComparisonExp>;
  normalizedLowerFirstName?: InputMaybe<StringComparisonExp>;
  normalizedLowerLastName?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  partnerFirstName?: InputMaybe<StringComparisonExp>;
  partnerLastName?: InputMaybe<StringComparisonExp>;
  personalNote?: InputMaybe<StringComparisonExp>;
  phoneNumbers?: InputMaybe<DbContactPhoneNumberBoolExp>;
  phoneNumbersAggregate?: InputMaybe<DbContactPhoneNumberAggregateBoolExp>;
  placeOfBirth?: InputMaybe<StringComparisonExp>;
  preferredLanguage?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  preferredTimes?: InputMaybe<DbContactPreferredTimeBoolExp>;
  preferredTimesAggregate?: InputMaybe<DbContactPreferredTimeAggregateBoolExp>;
  preferredWays?: InputMaybe<DbContactPreferredWayBoolExp>;
  preferredWaysAggregate?: InputMaybe<DbContactPreferredWayAggregateBoolExp>;
  qualificationFormLinks?: InputMaybe<DbQualificationFormLinkBoolExp>;
  qualificationFormLinksAggregate?: InputMaybe<DbQualificationFormLinkAggregateBoolExp>;
  referrals?: InputMaybe<DbReferralBoolExp>;
  referralsAggregate?: InputMaybe<DbReferralAggregateBoolExp>;
  relationships?: InputMaybe<DbContactRelationshipBoolExp>;
  salutation?: InputMaybe<EnumsSalutationEnumComparisonExp>;
  salutationBySalutation?: InputMaybe<EnumsSalutationBoolExp>;
  sellerRentOutSpecifications?: InputMaybe<DbSellerRentOutSpecificationBoolExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  snapshotSellerRentOutSpecifications?: InputMaybe<DbSnapshotSellerRentOutSpecificationBoolExp>;
  snapshotSellerRentOutSpecificationsAggregate?: InputMaybe<DbSnapshotSellerRentOutSpecificationAggregateBoolExp>;
  source?: InputMaybe<StringComparisonExp>;
  tags?: InputMaybe<DbContactTagBoolExp>;
  taskContacts?: InputMaybe<DbTaskContactBoolExp>;
  title?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<EnumsContactTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** columns and relationships of "db.contact_change_activity" */
export type DbContactChangeActivity = {
  __typename?: 'DbContactChangeActivity';
  /** An object relationship */
  baseActivity: DbBaseActivity;
  id: Scalars['String'];
};

/** Boolean expression to filter rows from the table "db.contact_change_activity". All fields are combined with a logical 'AND'. */
export type DbContactChangeActivityBoolExp = {
  _and?: InputMaybe<Array<DbContactChangeActivityBoolExp>>;
  _not?: InputMaybe<DbContactChangeActivityBoolExp>;
  _or?: InputMaybe<Array<DbContactChangeActivityBoolExp>>;
  baseActivity?: InputMaybe<DbBaseActivityBoolExp>;
  id?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "db.contact_change_activity". */
export type DbContactChangeActivityOrderBy = {
  baseActivity?: InputMaybe<DbBaseActivityOrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** select columns of table "db.contact_change_activity" */
export type DbContactChangeActivitySelectColumn =
  /** column name */
  'id';

/** Streaming cursor of the table "db_contact_change_activity" */
export type DbContactChangeActivityStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbContactChangeActivityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbContactChangeActivityStreamCursorValueInput = {
  id?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "db.contact_change_log" */
export type DbContactChangeLog = {
  __typename?: 'DbContactChangeLog';
  /** An object relationship */
  baseActivity?: Maybe<DbBaseActivity>;
  changeLogActId?: Maybe<Scalars['String']>;
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  fieldName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  newValue?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.contact_change_log" */
export type DbContactChangeLogAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbContactChangeLogMaxOrderBy>;
  min?: InputMaybe<DbContactChangeLogMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.contact_change_log". All fields are combined with a logical 'AND'. */
export type DbContactChangeLogBoolExp = {
  _and?: InputMaybe<Array<DbContactChangeLogBoolExp>>;
  _not?: InputMaybe<DbContactChangeLogBoolExp>;
  _or?: InputMaybe<Array<DbContactChangeLogBoolExp>>;
  baseActivity?: InputMaybe<DbBaseActivityBoolExp>;
  changeLogActId?: InputMaybe<StringComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  fieldName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  newValue?: InputMaybe<StringComparisonExp>;
  oldValue?: InputMaybe<StringComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.contact_change_log" */
export type DbContactChangeLogMaxOrderBy = {
  changeLogActId?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  fieldName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  newValue?: InputMaybe<OrderBy>;
  oldValue?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.contact_change_log" */
export type DbContactChangeLogMinOrderBy = {
  changeLogActId?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  fieldName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  newValue?: InputMaybe<OrderBy>;
  oldValue?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.contact_change_log". */
export type DbContactChangeLogOrderBy = {
  baseActivity?: InputMaybe<DbBaseActivityOrderBy>;
  changeLogActId?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  fieldName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  newValue?: InputMaybe<OrderBy>;
  oldValue?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.contact_change_log" */
export type DbContactChangeLogSelectColumn =
  /** column name */
  | 'changeLogActId'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'fieldName'
  /** column name */
  | 'id'
  /** column name */
  | 'newValue'
  /** column name */
  | 'oldValue'
  /** column name */
  | 'updatedBy';

/** Streaming cursor of the table "db_contact_change_log" */
export type DbContactChangeLogStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbContactChangeLogStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbContactChangeLogStreamCursorValueInput = {
  changeLogActId?: InputMaybe<Scalars['String']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  fieldName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  newValue?: InputMaybe<Scalars['String']>;
  oldValue?: InputMaybe<Scalars['String']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "db.contact_details" */
export type DbContactDetails = {
  __typename?: 'DbContactDetails';
  /** An object relationship */
  contact?: Maybe<DbContact>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  /** An object relationship */
  shop?: Maybe<DbShop>;
  shopId?: Maybe<Scalars['String']>;
};

/** aggregated selection of "db.contact_details" */
export type DbContactDetailsAggregate = {
  __typename?: 'DbContactDetailsAggregate';
  aggregate?: Maybe<DbContactDetailsAggregateFields>;
  nodes: Array<DbContactDetails>;
};

/** aggregate fields of "db.contact_details" */
export type DbContactDetailsAggregateFields = {
  __typename?: 'DbContactDetailsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<DbContactDetailsMaxFields>;
  min?: Maybe<DbContactDetailsMinFields>;
};

/** aggregate fields of "db.contact_details" */
export type DbContactDetailsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbContactDetailsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "db.contact_details". All fields are combined with a logical 'AND'. */
export type DbContactDetailsBoolExp = {
  _and?: InputMaybe<Array<DbContactDetailsBoolExp>>;
  _not?: InputMaybe<DbContactDetailsBoolExp>;
  _or?: InputMaybe<Array<DbContactDetailsBoolExp>>;
  contact?: InputMaybe<DbContactBoolExp>;
  firstName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lastName?: InputMaybe<StringComparisonExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbContactDetailsMaxFields = {
  __typename?: 'DbContactDetailsMaxFields';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type DbContactDetailsMinFields = {
  __typename?: 'DbContactDetailsMinFields';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "db.contact_details". */
export type DbContactDetailsOrderBy = {
  contact?: InputMaybe<DbContactOrderBy>;
  firstName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** select columns of table "db.contact_details" */
export type DbContactDetailsSelectColumn =
  /** column name */
  | 'firstName'
  /** column name */
  | 'id'
  /** column name */
  | 'lastName'
  /** column name */
  | 'shopId';

/** Streaming cursor of the table "db_contact_details" */
export type DbContactDetailsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbContactDetailsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbContactDetailsStreamCursorValueInput = {
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "db.contact_email_address" */
export type DbContactEmailAddress = {
  __typename?: 'DbContactEmailAddress';
  address: Scalars['String'];
  /** An object relationship */
  contact: DbContact;
  contactId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  emailAddressType: EnumsEmailAddressType;
  id: Scalars['String'];
  normalizedAddress?: Maybe<Scalars['String']>;
  num: Scalars['bigint'];
  preferred: Scalars['Boolean'];
  type: EmailAddressTypeEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.contact_email_address" */
export type DbContactEmailAddressAggregate = {
  __typename?: 'DbContactEmailAddressAggregate';
  aggregate?: Maybe<DbContactEmailAddressAggregateFields>;
  nodes: Array<DbContactEmailAddress>;
};

export type DbContactEmailAddressAggregateBoolExp = {
  bool_and?: InputMaybe<DbContactEmailAddressAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbContactEmailAddressAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbContactEmailAddressAggregateBoolExpCount>;
};

/** aggregate fields of "db.contact_email_address" */
export type DbContactEmailAddressAggregateFields = {
  __typename?: 'DbContactEmailAddressAggregateFields';
  avg?: Maybe<DbContactEmailAddressAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbContactEmailAddressMaxFields>;
  min?: Maybe<DbContactEmailAddressMinFields>;
  stddev?: Maybe<DbContactEmailAddressStddevFields>;
  stddevPop?: Maybe<DbContactEmailAddressStddevPopFields>;
  stddevSamp?: Maybe<DbContactEmailAddressStddevSampFields>;
  sum?: Maybe<DbContactEmailAddressSumFields>;
  varPop?: Maybe<DbContactEmailAddressVarPopFields>;
  varSamp?: Maybe<DbContactEmailAddressVarSampFields>;
  variance?: Maybe<DbContactEmailAddressVarianceFields>;
};

/** aggregate fields of "db.contact_email_address" */
export type DbContactEmailAddressAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbContactEmailAddressSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.contact_email_address" */
export type DbContactEmailAddressAggregateOrderBy = {
  avg?: InputMaybe<DbContactEmailAddressAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbContactEmailAddressMaxOrderBy>;
  min?: InputMaybe<DbContactEmailAddressMinOrderBy>;
  stddev?: InputMaybe<DbContactEmailAddressStddevOrderBy>;
  stddevPop?: InputMaybe<DbContactEmailAddressStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbContactEmailAddressStddevSampOrderBy>;
  sum?: InputMaybe<DbContactEmailAddressSumOrderBy>;
  varPop?: InputMaybe<DbContactEmailAddressVarPopOrderBy>;
  varSamp?: InputMaybe<DbContactEmailAddressVarSampOrderBy>;
  variance?: InputMaybe<DbContactEmailAddressVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbContactEmailAddressAvgFields = {
  __typename?: 'DbContactEmailAddressAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.contact_email_address" */
export type DbContactEmailAddressAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.contact_email_address". All fields are combined with a logical 'AND'. */
export type DbContactEmailAddressBoolExp = {
  _and?: InputMaybe<Array<DbContactEmailAddressBoolExp>>;
  _not?: InputMaybe<DbContactEmailAddressBoolExp>;
  _or?: InputMaybe<Array<DbContactEmailAddressBoolExp>>;
  address?: InputMaybe<StringComparisonExp>;
  contact?: InputMaybe<DbContactBoolExp>;
  contactId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  emailAddressType?: InputMaybe<EnumsEmailAddressTypeBoolExp>;
  id?: InputMaybe<StringComparisonExp>;
  normalizedAddress?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  preferred?: InputMaybe<BooleanComparisonExp>;
  type?: InputMaybe<EnumsEmailAddressTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbContactEmailAddressMaxFields = {
  __typename?: 'DbContactEmailAddressMaxFields';
  address?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  normalizedAddress?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.contact_email_address" */
export type DbContactEmailAddressMaxOrderBy = {
  address?: InputMaybe<OrderBy>;
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  normalizedAddress?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbContactEmailAddressMinFields = {
  __typename?: 'DbContactEmailAddressMinFields';
  address?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  normalizedAddress?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.contact_email_address" */
export type DbContactEmailAddressMinOrderBy = {
  address?: InputMaybe<OrderBy>;
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  normalizedAddress?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.contact_email_address". */
export type DbContactEmailAddressOrderBy = {
  address?: InputMaybe<OrderBy>;
  contact?: InputMaybe<DbContactOrderBy>;
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  emailAddressType?: InputMaybe<EnumsEmailAddressTypeOrderBy>;
  id?: InputMaybe<OrderBy>;
  normalizedAddress?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  preferred?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.contact_email_address" */
export type DbContactEmailAddressSelectColumn =
  /** column name */
  | 'address'
  /** column name */
  | 'contactId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'normalizedAddress'
  /** column name */
  | 'num'
  /** column name */
  | 'preferred'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbContactEmailAddressAggregateBoolExpBool_andArgumentsColumns" columns of table "db.contact_email_address" */
export type DbContactEmailAddressSelectColumnDbContactEmailAddressAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  | 'deleted'
  /** column name */
  | 'preferred';

/** select "dbContactEmailAddressAggregateBoolExpBool_orArgumentsColumns" columns of table "db.contact_email_address" */
export type DbContactEmailAddressSelectColumnDbContactEmailAddressAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  | 'deleted'
  /** column name */
  | 'preferred';

/** aggregate stddev on columns */
export type DbContactEmailAddressStddevFields = {
  __typename?: 'DbContactEmailAddressStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.contact_email_address" */
export type DbContactEmailAddressStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbContactEmailAddressStddevPopFields = {
  __typename?: 'DbContactEmailAddressStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.contact_email_address" */
export type DbContactEmailAddressStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbContactEmailAddressStddevSampFields = {
  __typename?: 'DbContactEmailAddressStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.contact_email_address" */
export type DbContactEmailAddressStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type DbContactEmailAddressSumFields = {
  __typename?: 'DbContactEmailAddressSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.contact_email_address" */
export type DbContactEmailAddressSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbContactEmailAddressVarPopFields = {
  __typename?: 'DbContactEmailAddressVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.contact_email_address" */
export type DbContactEmailAddressVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbContactEmailAddressVarSampFields = {
  __typename?: 'DbContactEmailAddressVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.contact_email_address" */
export type DbContactEmailAddressVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbContactEmailAddressVarianceFields = {
  __typename?: 'DbContactEmailAddressVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.contact_email_address" */
export type DbContactEmailAddressVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.contact_gdpr" */
export type DbContactGdpr = {
  __typename?: 'DbContactGdpr';
  agreedToGdpr?: Maybe<Scalars['Boolean']>;
  blockedDueToGdpr?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  contacts: Array<DbContact>;
  /** An aggregate relationship */
  contactsAggregate: DbContactAggregate;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  gdprBlockingDate?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  num: Scalars['bigint'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** columns and relationships of "db.contact_gdpr" */
export type DbContactGdprContactsArgs = {
  distinctOn?: InputMaybe<Array<DbContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactOrderBy>>;
  where?: InputMaybe<DbContactBoolExp>;
};

/** columns and relationships of "db.contact_gdpr" */
export type DbContactGdprContactsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactOrderBy>>;
  where?: InputMaybe<DbContactBoolExp>;
};

/** Boolean expression to filter rows from the table "db.contact_gdpr". All fields are combined with a logical 'AND'. */
export type DbContactGdprBoolExp = {
  _and?: InputMaybe<Array<DbContactGdprBoolExp>>;
  _not?: InputMaybe<DbContactGdprBoolExp>;
  _or?: InputMaybe<Array<DbContactGdprBoolExp>>;
  agreedToGdpr?: InputMaybe<BooleanComparisonExp>;
  blockedDueToGdpr?: InputMaybe<BooleanComparisonExp>;
  contacts?: InputMaybe<DbContactBoolExp>;
  contactsAggregate?: InputMaybe<DbContactAggregateBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  gdprBlockingDate?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "db.contact_gdpr". */
export type DbContactGdprOrderBy = {
  agreedToGdpr?: InputMaybe<OrderBy>;
  blockedDueToGdpr?: InputMaybe<OrderBy>;
  contactsAggregate?: InputMaybe<DbContactAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  gdprBlockingDate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.contact_gdpr" */
export type DbContactGdprSelectColumn =
  /** column name */
  | 'agreedToGdpr'
  /** column name */
  | 'blockedDueToGdpr'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'gdprBlockingDate'
  /** column name */
  | 'id'
  /** column name */
  | 'num'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** Streaming cursor of the table "db_contact_gdpr" */
export type DbContactGdprStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbContactGdprStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbContactGdprStreamCursorValueInput = {
  agreedToGdpr?: InputMaybe<Scalars['Boolean']>;
  blockedDueToGdpr?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  gdprBlockingDate?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type DbContactMaxFields = {
  __typename?: 'DbContactMaxFields';
  birthdate?: Maybe<Scalars['date']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  customerAccountId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  firstName?: Maybe<Scalars['String']>;
  formattedSalutation?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  gdprId?: Maybe<Scalars['String']>;
  go3ContactKey?: Maybe<Scalars['Int']>;
  go3Utag?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  kycSearchId?: Maybe<Scalars['uuid']>;
  lastInteractionActivityId?: Maybe<Scalars['String']>;
  lastInteractionActivityOrderDate?: Maybe<Scalars['timestamptz']>;
  lastName?: Maybe<Scalars['String']>;
  lck?: Maybe<Scalars['Int']>;
  legalEntityId?: Maybe<Scalars['String']>;
  lowerFirstName?: Maybe<Scalars['String']>;
  lowerLastName?: Maybe<Scalars['String']>;
  mostRecentActivityCreatedAt?: Maybe<Scalars['timestamptz']>;
  normalizedLowerFirstName?: Maybe<Scalars['String']>;
  normalizedLowerLastName?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  partnerFirstName?: Maybe<Scalars['String']>;
  partnerLastName?: Maybe<Scalars['String']>;
  personalNote?: Maybe<Scalars['String']>;
  placeOfBirth?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.contact" */
export type DbContactMaxOrderBy = {
  birthdate?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  customerAccountId?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  formattedSalutation?: InputMaybe<OrderBy>;
  fullName?: InputMaybe<OrderBy>;
  gdprId?: InputMaybe<OrderBy>;
  go3ContactKey?: InputMaybe<OrderBy>;
  go3Utag?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  idNumber?: InputMaybe<OrderBy>;
  kycSearchId?: InputMaybe<OrderBy>;
  lastInteractionActivityId?: InputMaybe<OrderBy>;
  lastInteractionActivityOrderDate?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  legalEntityId?: InputMaybe<OrderBy>;
  lowerFirstName?: InputMaybe<OrderBy>;
  lowerLastName?: InputMaybe<OrderBy>;
  mostRecentActivityCreatedAt?: InputMaybe<OrderBy>;
  normalizedLowerFirstName?: InputMaybe<OrderBy>;
  normalizedLowerLastName?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  partnerFirstName?: InputMaybe<OrderBy>;
  partnerLastName?: InputMaybe<OrderBy>;
  personalNote?: InputMaybe<OrderBy>;
  placeOfBirth?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  source?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbContactMinFields = {
  __typename?: 'DbContactMinFields';
  birthdate?: Maybe<Scalars['date']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  customerAccountId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  firstName?: Maybe<Scalars['String']>;
  formattedSalutation?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  gdprId?: Maybe<Scalars['String']>;
  go3ContactKey?: Maybe<Scalars['Int']>;
  go3Utag?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  idNumber?: Maybe<Scalars['String']>;
  kycSearchId?: Maybe<Scalars['uuid']>;
  lastInteractionActivityId?: Maybe<Scalars['String']>;
  lastInteractionActivityOrderDate?: Maybe<Scalars['timestamptz']>;
  lastName?: Maybe<Scalars['String']>;
  lck?: Maybe<Scalars['Int']>;
  legalEntityId?: Maybe<Scalars['String']>;
  lowerFirstName?: Maybe<Scalars['String']>;
  lowerLastName?: Maybe<Scalars['String']>;
  mostRecentActivityCreatedAt?: Maybe<Scalars['timestamptz']>;
  normalizedLowerFirstName?: Maybe<Scalars['String']>;
  normalizedLowerLastName?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  partnerFirstName?: Maybe<Scalars['String']>;
  partnerLastName?: Maybe<Scalars['String']>;
  personalNote?: Maybe<Scalars['String']>;
  placeOfBirth?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.contact" */
export type DbContactMinOrderBy = {
  birthdate?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  customerAccountId?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  formattedSalutation?: InputMaybe<OrderBy>;
  fullName?: InputMaybe<OrderBy>;
  gdprId?: InputMaybe<OrderBy>;
  go3ContactKey?: InputMaybe<OrderBy>;
  go3Utag?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  idNumber?: InputMaybe<OrderBy>;
  kycSearchId?: InputMaybe<OrderBy>;
  lastInteractionActivityId?: InputMaybe<OrderBy>;
  lastInteractionActivityOrderDate?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  legalEntityId?: InputMaybe<OrderBy>;
  lowerFirstName?: InputMaybe<OrderBy>;
  lowerLastName?: InputMaybe<OrderBy>;
  mostRecentActivityCreatedAt?: InputMaybe<OrderBy>;
  normalizedLowerFirstName?: InputMaybe<OrderBy>;
  normalizedLowerLastName?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  partnerFirstName?: InputMaybe<OrderBy>;
  partnerLastName?: InputMaybe<OrderBy>;
  personalNote?: InputMaybe<OrderBy>;
  placeOfBirth?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  source?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.contact". */
export type DbContactOrderBy = {
  addressesAggregate?: InputMaybe<DbContactAddressAggregateOrderBy>;
  agentsAssignedToLeadsOfContactAggregate?: InputMaybe<DbAgentsAssignedToLeadsOfContactAggregateOrderBy>;
  appointmentContactsAggregate?: InputMaybe<DbAppointmentContactAggregateOrderBy>;
  autoCc?: InputMaybe<OrderBy>;
  baseActivitiesAggregate?: InputMaybe<DbBaseActivityAggregateOrderBy>;
  birthdate?: InputMaybe<OrderBy>;
  blocked?: InputMaybe<OrderBy>;
  contactContactStatus?: InputMaybe<EnumsContactStatusOrderBy>;
  contactContactType?: InputMaybe<EnumsContactTypeOrderBy>;
  contactGdpr?: InputMaybe<DbContactGdprOrderBy>;
  contactRelationshipsAggregate?: InputMaybe<DbContactRelationshipAggregateOrderBy>;
  contactRenterInformationsAggregate?: InputMaybe<DbContactRenterInformationAggregateOrderBy>;
  contactStatus?: InputMaybe<OrderBy>;
  contact_contact_assignmentsAggregate?: InputMaybe<DbContactAssignmentAggregateOrderBy>;
  country?: InputMaybe<EnumsCountryOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  creator?: InputMaybe<DbAgentOrderBy>;
  customerAccount?: InputMaybe<DbCustomerAccountOrderBy>;
  customerAccountId?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  documentReferencesAggregate?: InputMaybe<DbDocumentReferenceAggregateOrderBy>;
  emailAddressesAggregate?: InputMaybe<DbContactEmailAddressAggregateOrderBy>;
  emailLinksAggregate?: InputMaybe<DbEmailLinkAggregateOrderBy>;
  firstName?: InputMaybe<OrderBy>;
  formattedSalutation?: InputMaybe<OrderBy>;
  fullName?: InputMaybe<OrderBy>;
  gdprId?: InputMaybe<OrderBy>;
  go3ContactKey?: InputMaybe<OrderBy>;
  go3Utag?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  idNumber?: InputMaybe<OrderBy>;
  kycSearchId?: InputMaybe<OrderBy>;
  language?: InputMaybe<EnumsLanguageOrderBy>;
  lastInteractionActivity?: InputMaybe<DbBaseActivityOrderBy>;
  lastInteractionActivityId?: InputMaybe<OrderBy>;
  lastInteractionActivityOrderDate?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  leadhubGo3ContactLinksAggregate?: InputMaybe<DbLeadhubGo3ContactLinkAggregateOrderBy>;
  leadsAggregate?: InputMaybe<DbLeadAggregateOrderBy>;
  legacyGo3NewsletterType?: InputMaybe<OrderBy>;
  legalEntity?: InputMaybe<DbLegalEntityOrderBy>;
  legalEntityId?: InputMaybe<OrderBy>;
  lowerFirstName?: InputMaybe<OrderBy>;
  lowerLastName?: InputMaybe<OrderBy>;
  mostRecentActivityCreatedAt?: InputMaybe<OrderBy>;
  nationality?: InputMaybe<OrderBy>;
  noMail?: InputMaybe<OrderBy>;
  normalizedLowerFirstName?: InputMaybe<OrderBy>;
  normalizedLowerLastName?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  partnerFirstName?: InputMaybe<OrderBy>;
  partnerLastName?: InputMaybe<OrderBy>;
  personalNote?: InputMaybe<OrderBy>;
  phoneNumbersAggregate?: InputMaybe<DbContactPhoneNumberAggregateOrderBy>;
  placeOfBirth?: InputMaybe<OrderBy>;
  preferredLanguage?: InputMaybe<OrderBy>;
  preferredTimesAggregate?: InputMaybe<DbContactPreferredTimeAggregateOrderBy>;
  preferredWaysAggregate?: InputMaybe<DbContactPreferredWayAggregateOrderBy>;
  qualificationFormLinksAggregate?: InputMaybe<DbQualificationFormLinkAggregateOrderBy>;
  referralsAggregate?: InputMaybe<DbReferralAggregateOrderBy>;
  relationshipsAggregate?: InputMaybe<DbContactRelationshipAggregateOrderBy>;
  salutation?: InputMaybe<OrderBy>;
  salutationBySalutation?: InputMaybe<EnumsSalutationOrderBy>;
  sellerRentOutSpecificationsAggregate?: InputMaybe<DbSellerRentOutSpecificationAggregateOrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  snapshotSellerRentOutSpecificationsAggregate?: InputMaybe<DbSnapshotSellerRentOutSpecificationAggregateOrderBy>;
  source?: InputMaybe<OrderBy>;
  tagsAggregate?: InputMaybe<DbContactTagAggregateOrderBy>;
  taskContactsAggregate?: InputMaybe<DbTaskContactAggregateOrderBy>;
  title?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.contact_phone_number" */
export type DbContactPhoneNumber = {
  __typename?: 'DbContactPhoneNumber';
  /** An object relationship */
  contact: DbContact;
  contactId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  /** international number in E164 format */
  internationalNumber?: Maybe<Scalars['String']>;
  normalizedLocalNumber?: Maybe<Scalars['String']>;
  num: Scalars['bigint'];
  /** raw number from input */
  number: Scalars['String'];
  /** An object relationship */
  phoneNumberType: EnumsPhoneNumberType;
  preferred: Scalars['Boolean'];
  type: PhoneNumberTypeEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.contact_phone_number" */
export type DbContactPhoneNumberAggregate = {
  __typename?: 'DbContactPhoneNumberAggregate';
  aggregate?: Maybe<DbContactPhoneNumberAggregateFields>;
  nodes: Array<DbContactPhoneNumber>;
};

export type DbContactPhoneNumberAggregateBoolExp = {
  bool_and?: InputMaybe<DbContactPhoneNumberAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbContactPhoneNumberAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbContactPhoneNumberAggregateBoolExpCount>;
};

/** aggregate fields of "db.contact_phone_number" */
export type DbContactPhoneNumberAggregateFields = {
  __typename?: 'DbContactPhoneNumberAggregateFields';
  avg?: Maybe<DbContactPhoneNumberAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbContactPhoneNumberMaxFields>;
  min?: Maybe<DbContactPhoneNumberMinFields>;
  stddev?: Maybe<DbContactPhoneNumberStddevFields>;
  stddevPop?: Maybe<DbContactPhoneNumberStddevPopFields>;
  stddevSamp?: Maybe<DbContactPhoneNumberStddevSampFields>;
  sum?: Maybe<DbContactPhoneNumberSumFields>;
  varPop?: Maybe<DbContactPhoneNumberVarPopFields>;
  varSamp?: Maybe<DbContactPhoneNumberVarSampFields>;
  variance?: Maybe<DbContactPhoneNumberVarianceFields>;
};

/** aggregate fields of "db.contact_phone_number" */
export type DbContactPhoneNumberAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbContactPhoneNumberSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.contact_phone_number" */
export type DbContactPhoneNumberAggregateOrderBy = {
  avg?: InputMaybe<DbContactPhoneNumberAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbContactPhoneNumberMaxOrderBy>;
  min?: InputMaybe<DbContactPhoneNumberMinOrderBy>;
  stddev?: InputMaybe<DbContactPhoneNumberStddevOrderBy>;
  stddevPop?: InputMaybe<DbContactPhoneNumberStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbContactPhoneNumberStddevSampOrderBy>;
  sum?: InputMaybe<DbContactPhoneNumberSumOrderBy>;
  varPop?: InputMaybe<DbContactPhoneNumberVarPopOrderBy>;
  varSamp?: InputMaybe<DbContactPhoneNumberVarSampOrderBy>;
  variance?: InputMaybe<DbContactPhoneNumberVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbContactPhoneNumberAvgFields = {
  __typename?: 'DbContactPhoneNumberAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.contact_phone_number" */
export type DbContactPhoneNumberAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.contact_phone_number". All fields are combined with a logical 'AND'. */
export type DbContactPhoneNumberBoolExp = {
  _and?: InputMaybe<Array<DbContactPhoneNumberBoolExp>>;
  _not?: InputMaybe<DbContactPhoneNumberBoolExp>;
  _or?: InputMaybe<Array<DbContactPhoneNumberBoolExp>>;
  contact?: InputMaybe<DbContactBoolExp>;
  contactId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  internationalNumber?: InputMaybe<StringComparisonExp>;
  normalizedLocalNumber?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  number?: InputMaybe<StringComparisonExp>;
  phoneNumberType?: InputMaybe<EnumsPhoneNumberTypeBoolExp>;
  preferred?: InputMaybe<BooleanComparisonExp>;
  type?: InputMaybe<EnumsPhoneNumberTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbContactPhoneNumberMaxFields = {
  __typename?: 'DbContactPhoneNumberMaxFields';
  contactId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  /** international number in E164 format */
  internationalNumber?: Maybe<Scalars['String']>;
  normalizedLocalNumber?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  /** raw number from input */
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.contact_phone_number" */
export type DbContactPhoneNumberMaxOrderBy = {
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** international number in E164 format */
  internationalNumber?: InputMaybe<OrderBy>;
  normalizedLocalNumber?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  /** raw number from input */
  number?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbContactPhoneNumberMinFields = {
  __typename?: 'DbContactPhoneNumberMinFields';
  contactId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  /** international number in E164 format */
  internationalNumber?: Maybe<Scalars['String']>;
  normalizedLocalNumber?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  /** raw number from input */
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.contact_phone_number" */
export type DbContactPhoneNumberMinOrderBy = {
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  /** international number in E164 format */
  internationalNumber?: InputMaybe<OrderBy>;
  normalizedLocalNumber?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  /** raw number from input */
  number?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.contact_phone_number". */
export type DbContactPhoneNumberOrderBy = {
  contact?: InputMaybe<DbContactOrderBy>;
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  internationalNumber?: InputMaybe<OrderBy>;
  normalizedLocalNumber?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
  phoneNumberType?: InputMaybe<EnumsPhoneNumberTypeOrderBy>;
  preferred?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.contact_phone_number" */
export type DbContactPhoneNumberSelectColumn =
  /** column name */
  | 'contactId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'internationalNumber'
  /** column name */
  | 'normalizedLocalNumber'
  /** column name */
  | 'num'
  /** column name */
  | 'number'
  /** column name */
  | 'preferred'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbContactPhoneNumberAggregateBoolExpBool_andArgumentsColumns" columns of table "db.contact_phone_number" */
export type DbContactPhoneNumberSelectColumnDbContactPhoneNumberAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  | 'deleted'
  /** column name */
  | 'preferred';

/** select "dbContactPhoneNumberAggregateBoolExpBool_orArgumentsColumns" columns of table "db.contact_phone_number" */
export type DbContactPhoneNumberSelectColumnDbContactPhoneNumberAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  | 'deleted'
  /** column name */
  | 'preferred';

/** aggregate stddev on columns */
export type DbContactPhoneNumberStddevFields = {
  __typename?: 'DbContactPhoneNumberStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.contact_phone_number" */
export type DbContactPhoneNumberStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbContactPhoneNumberStddevPopFields = {
  __typename?: 'DbContactPhoneNumberStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.contact_phone_number" */
export type DbContactPhoneNumberStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbContactPhoneNumberStddevSampFields = {
  __typename?: 'DbContactPhoneNumberStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.contact_phone_number" */
export type DbContactPhoneNumberStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate sum on columns */
export type DbContactPhoneNumberSumFields = {
  __typename?: 'DbContactPhoneNumberSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.contact_phone_number" */
export type DbContactPhoneNumberSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbContactPhoneNumberVarPopFields = {
  __typename?: 'DbContactPhoneNumberVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.contact_phone_number" */
export type DbContactPhoneNumberVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbContactPhoneNumberVarSampFields = {
  __typename?: 'DbContactPhoneNumberVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.contact_phone_number" */
export type DbContactPhoneNumberVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbContactPhoneNumberVarianceFields = {
  __typename?: 'DbContactPhoneNumberVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.contact_phone_number" */
export type DbContactPhoneNumberVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.contact_preferred_time" */
export type DbContactPreferredTime = {
  __typename?: 'DbContactPreferredTime';
  /** An object relationship */
  contact: DbContact;
  contactId: Scalars['String'];
  /** An object relationship */
  contactTime: EnumsContactTime;
  preferredContactTime: ContactTimeEnum;
};

/** aggregated selection of "db.contact_preferred_time" */
export type DbContactPreferredTimeAggregate = {
  __typename?: 'DbContactPreferredTimeAggregate';
  aggregate?: Maybe<DbContactPreferredTimeAggregateFields>;
  nodes: Array<DbContactPreferredTime>;
};

export type DbContactPreferredTimeAggregateBoolExp = {
  count?: InputMaybe<DbContactPreferredTimeAggregateBoolExpCount>;
};

/** aggregate fields of "db.contact_preferred_time" */
export type DbContactPreferredTimeAggregateFields = {
  __typename?: 'DbContactPreferredTimeAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<DbContactPreferredTimeMaxFields>;
  min?: Maybe<DbContactPreferredTimeMinFields>;
};

/** aggregate fields of "db.contact_preferred_time" */
export type DbContactPreferredTimeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbContactPreferredTimeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.contact_preferred_time" */
export type DbContactPreferredTimeAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbContactPreferredTimeMaxOrderBy>;
  min?: InputMaybe<DbContactPreferredTimeMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.contact_preferred_time". All fields are combined with a logical 'AND'. */
export type DbContactPreferredTimeBoolExp = {
  _and?: InputMaybe<Array<DbContactPreferredTimeBoolExp>>;
  _not?: InputMaybe<DbContactPreferredTimeBoolExp>;
  _or?: InputMaybe<Array<DbContactPreferredTimeBoolExp>>;
  contact?: InputMaybe<DbContactBoolExp>;
  contactId?: InputMaybe<StringComparisonExp>;
  contactTime?: InputMaybe<EnumsContactTimeBoolExp>;
  preferredContactTime?: InputMaybe<EnumsContactTimeEnumComparisonExp>;
};

/** aggregate max on columns */
export type DbContactPreferredTimeMaxFields = {
  __typename?: 'DbContactPreferredTimeMaxFields';
  contactId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.contact_preferred_time" */
export type DbContactPreferredTimeMaxOrderBy = {
  contactId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbContactPreferredTimeMinFields = {
  __typename?: 'DbContactPreferredTimeMinFields';
  contactId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.contact_preferred_time" */
export type DbContactPreferredTimeMinOrderBy = {
  contactId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.contact_preferred_time". */
export type DbContactPreferredTimeOrderBy = {
  contact?: InputMaybe<DbContactOrderBy>;
  contactId?: InputMaybe<OrderBy>;
  contactTime?: InputMaybe<EnumsContactTimeOrderBy>;
  preferredContactTime?: InputMaybe<OrderBy>;
};

/** select columns of table "db.contact_preferred_time" */
export type DbContactPreferredTimeSelectColumn =
  /** column name */
  | 'contactId'
  /** column name */
  | 'preferredContactTime';

/** Streaming cursor of the table "db_contact_preferred_time" */
export type DbContactPreferredTimeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbContactPreferredTimeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbContactPreferredTimeStreamCursorValueInput = {
  contactId?: InputMaybe<Scalars['String']>;
  preferredContactTime?: InputMaybe<ContactTimeEnum>;
};

/** columns and relationships of "db.contact_preferred_way" */
export type DbContactPreferredWay = {
  __typename?: 'DbContactPreferredWay';
  /** An object relationship */
  contact: DbContact;
  contactId: Scalars['String'];
  /** An object relationship */
  contactWay: EnumsContactWay;
  preferredContactWay: ContactWayEnum;
};

/** aggregated selection of "db.contact_preferred_way" */
export type DbContactPreferredWayAggregate = {
  __typename?: 'DbContactPreferredWayAggregate';
  aggregate?: Maybe<DbContactPreferredWayAggregateFields>;
  nodes: Array<DbContactPreferredWay>;
};

export type DbContactPreferredWayAggregateBoolExp = {
  count?: InputMaybe<DbContactPreferredWayAggregateBoolExpCount>;
};

/** aggregate fields of "db.contact_preferred_way" */
export type DbContactPreferredWayAggregateFields = {
  __typename?: 'DbContactPreferredWayAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<DbContactPreferredWayMaxFields>;
  min?: Maybe<DbContactPreferredWayMinFields>;
};

/** aggregate fields of "db.contact_preferred_way" */
export type DbContactPreferredWayAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbContactPreferredWaySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.contact_preferred_way" */
export type DbContactPreferredWayAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbContactPreferredWayMaxOrderBy>;
  min?: InputMaybe<DbContactPreferredWayMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.contact_preferred_way". All fields are combined with a logical 'AND'. */
export type DbContactPreferredWayBoolExp = {
  _and?: InputMaybe<Array<DbContactPreferredWayBoolExp>>;
  _not?: InputMaybe<DbContactPreferredWayBoolExp>;
  _or?: InputMaybe<Array<DbContactPreferredWayBoolExp>>;
  contact?: InputMaybe<DbContactBoolExp>;
  contactId?: InputMaybe<StringComparisonExp>;
  contactWay?: InputMaybe<EnumsContactWayBoolExp>;
  preferredContactWay?: InputMaybe<EnumsContactWayEnumComparisonExp>;
};

/** aggregate max on columns */
export type DbContactPreferredWayMaxFields = {
  __typename?: 'DbContactPreferredWayMaxFields';
  contactId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.contact_preferred_way" */
export type DbContactPreferredWayMaxOrderBy = {
  contactId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbContactPreferredWayMinFields = {
  __typename?: 'DbContactPreferredWayMinFields';
  contactId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.contact_preferred_way" */
export type DbContactPreferredWayMinOrderBy = {
  contactId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.contact_preferred_way". */
export type DbContactPreferredWayOrderBy = {
  contact?: InputMaybe<DbContactOrderBy>;
  contactId?: InputMaybe<OrderBy>;
  contactWay?: InputMaybe<EnumsContactWayOrderBy>;
  preferredContactWay?: InputMaybe<OrderBy>;
};

/** select columns of table "db.contact_preferred_way" */
export type DbContactPreferredWaySelectColumn =
  /** column name */
  | 'contactId'
  /** column name */
  | 'preferredContactWay';

/** Streaming cursor of the table "db_contact_preferred_way" */
export type DbContactPreferredWayStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbContactPreferredWayStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbContactPreferredWayStreamCursorValueInput = {
  contactId?: InputMaybe<Scalars['String']>;
  preferredContactWay?: InputMaybe<ContactWayEnum>;
};

/** columns and relationships of "db.contact_relationship" */
export type DbContactRelationship = {
  __typename?: 'DbContactRelationship';
  autoCc?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  contact: DbContact;
  contactId: Scalars['String'];
  /** An object relationship */
  contactRelationType: EnumsContactRelationType;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  lck: Scalars['Int'];
  num: Scalars['bigint'];
  relatedContactId: Scalars['String'];
  /** An object relationship */
  related_contact: DbContact;
  type: ContactRelationTypeEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.contact_relationship" */
export type DbContactRelationshipAggregateOrderBy = {
  avg?: InputMaybe<DbContactRelationshipAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbContactRelationshipMaxOrderBy>;
  min?: InputMaybe<DbContactRelationshipMinOrderBy>;
  stddev?: InputMaybe<DbContactRelationshipStddevOrderBy>;
  stddevPop?: InputMaybe<DbContactRelationshipStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbContactRelationshipStddevSampOrderBy>;
  sum?: InputMaybe<DbContactRelationshipSumOrderBy>;
  varPop?: InputMaybe<DbContactRelationshipVarPopOrderBy>;
  varSamp?: InputMaybe<DbContactRelationshipVarSampOrderBy>;
  variance?: InputMaybe<DbContactRelationshipVarianceOrderBy>;
};

/** order by avg() on columns of table "db.contact_relationship" */
export type DbContactRelationshipAvgOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.contact_relationship". All fields are combined with a logical 'AND'. */
export type DbContactRelationshipBoolExp = {
  _and?: InputMaybe<Array<DbContactRelationshipBoolExp>>;
  _not?: InputMaybe<DbContactRelationshipBoolExp>;
  _or?: InputMaybe<Array<DbContactRelationshipBoolExp>>;
  autoCc?: InputMaybe<BooleanComparisonExp>;
  contact?: InputMaybe<DbContactBoolExp>;
  contactId?: InputMaybe<StringComparisonExp>;
  contactRelationType?: InputMaybe<EnumsContactRelationTypeBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lck?: InputMaybe<IntComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  relatedContactId?: InputMaybe<StringComparisonExp>;
  related_contact?: InputMaybe<DbContactBoolExp>;
  type?: InputMaybe<EnumsContactRelationTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.contact_relationship" */
export type DbContactRelationshipMaxOrderBy = {
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  relatedContactId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.contact_relationship" */
export type DbContactRelationshipMinOrderBy = {
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  relatedContactId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.contact_relationship". */
export type DbContactRelationshipOrderBy = {
  autoCc?: InputMaybe<OrderBy>;
  contact?: InputMaybe<DbContactOrderBy>;
  contactId?: InputMaybe<OrderBy>;
  contactRelationType?: InputMaybe<EnumsContactRelationTypeOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  relatedContactId?: InputMaybe<OrderBy>;
  related_contact?: InputMaybe<DbContactOrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.contact_relationship" */
export type DbContactRelationshipSelectColumn =
  /** column name */
  | 'autoCc'
  /** column name */
  | 'contactId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'lck'
  /** column name */
  | 'num'
  /** column name */
  | 'relatedContactId'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.contact_relationship" */
export type DbContactRelationshipStddevOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.contact_relationship" */
export type DbContactRelationshipStddevPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.contact_relationship" */
export type DbContactRelationshipStddevSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_contact_relationship" */
export type DbContactRelationshipStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbContactRelationshipStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbContactRelationshipStreamCursorValueInput = {
  autoCc?: InputMaybe<Scalars['Boolean']>;
  contactId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  lck?: InputMaybe<Scalars['Int']>;
  num?: InputMaybe<Scalars['bigint']>;
  relatedContactId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ContactRelationTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.contact_relationship" */
export type DbContactRelationshipSumOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.contact_relationship" */
export type DbContactRelationshipVarPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.contact_relationship" */
export type DbContactRelationshipVarSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.contact_relationship" */
export type DbContactRelationshipVarianceOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.contact_renter_information" */
export type DbContactRenterInformation = {
  __typename?: 'DbContactRenterInformation';
  contactId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  currency: CurrencyEnum;
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  householdType?: Maybe<HouseholdTypeEnum>;
  id: Scalars['String'];
  lck: Scalars['Int'];
  monthlyIncomeMax?: Maybe<Scalars['Int']>;
  monthlyIncomeMin?: Maybe<Scalars['Int']>;
  num: Scalars['bigint'];
  petOwner?: Maybe<Scalars['Boolean']>;
  professionType?: Maybe<ProfessionTypeEnum>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.contact_renter_information" */
export type DbContactRenterInformationAggregateOrderBy = {
  avg?: InputMaybe<DbContactRenterInformationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbContactRenterInformationMaxOrderBy>;
  min?: InputMaybe<DbContactRenterInformationMinOrderBy>;
  stddev?: InputMaybe<DbContactRenterInformationStddevOrderBy>;
  stddevPop?: InputMaybe<DbContactRenterInformationStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbContactRenterInformationStddevSampOrderBy>;
  sum?: InputMaybe<DbContactRenterInformationSumOrderBy>;
  varPop?: InputMaybe<DbContactRenterInformationVarPopOrderBy>;
  varSamp?: InputMaybe<DbContactRenterInformationVarSampOrderBy>;
  variance?: InputMaybe<DbContactRenterInformationVarianceOrderBy>;
};

/** order by avg() on columns of table "db.contact_renter_information" */
export type DbContactRenterInformationAvgOrderBy = {
  lck?: InputMaybe<OrderBy>;
  monthlyIncomeMax?: InputMaybe<OrderBy>;
  monthlyIncomeMin?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.contact_renter_information". All fields are combined with a logical 'AND'. */
export type DbContactRenterInformationBoolExp = {
  _and?: InputMaybe<Array<DbContactRenterInformationBoolExp>>;
  _not?: InputMaybe<DbContactRenterInformationBoolExp>;
  _or?: InputMaybe<Array<DbContactRenterInformationBoolExp>>;
  contactId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  currency?: InputMaybe<EnumsCurrencyEnumComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  householdType?: InputMaybe<EnumsHouseholdTypeEnumComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lck?: InputMaybe<IntComparisonExp>;
  monthlyIncomeMax?: InputMaybe<IntComparisonExp>;
  monthlyIncomeMin?: InputMaybe<IntComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  petOwner?: InputMaybe<BooleanComparisonExp>;
  professionType?: InputMaybe<EnumsProfessionTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.contact_renter_information" */
export type DbContactRenterInformationMaxOrderBy = {
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  monthlyIncomeMax?: InputMaybe<OrderBy>;
  monthlyIncomeMin?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.contact_renter_information" */
export type DbContactRenterInformationMinOrderBy = {
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  monthlyIncomeMax?: InputMaybe<OrderBy>;
  monthlyIncomeMin?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.contact_renter_information". */
export type DbContactRenterInformationOrderBy = {
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  currency?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  householdType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  monthlyIncomeMax?: InputMaybe<OrderBy>;
  monthlyIncomeMin?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  petOwner?: InputMaybe<OrderBy>;
  professionType?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.contact_renter_information" */
export type DbContactRenterInformationSelectColumn =
  /** column name */
  | 'contactId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'currency'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'householdType'
  /** column name */
  | 'id'
  /** column name */
  | 'lck'
  /** column name */
  | 'monthlyIncomeMax'
  /** column name */
  | 'monthlyIncomeMin'
  /** column name */
  | 'num'
  /** column name */
  | 'petOwner'
  /** column name */
  | 'professionType'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.contact_renter_information" */
export type DbContactRenterInformationStddevOrderBy = {
  lck?: InputMaybe<OrderBy>;
  monthlyIncomeMax?: InputMaybe<OrderBy>;
  monthlyIncomeMin?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.contact_renter_information" */
export type DbContactRenterInformationStddevPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  monthlyIncomeMax?: InputMaybe<OrderBy>;
  monthlyIncomeMin?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.contact_renter_information" */
export type DbContactRenterInformationStddevSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  monthlyIncomeMax?: InputMaybe<OrderBy>;
  monthlyIncomeMin?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_contact_renter_information" */
export type DbContactRenterInformationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbContactRenterInformationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbContactRenterInformationStreamCursorValueInput = {
  contactId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<CurrencyEnum>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  householdType?: InputMaybe<HouseholdTypeEnum>;
  id?: InputMaybe<Scalars['String']>;
  lck?: InputMaybe<Scalars['Int']>;
  monthlyIncomeMax?: InputMaybe<Scalars['Int']>;
  monthlyIncomeMin?: InputMaybe<Scalars['Int']>;
  num?: InputMaybe<Scalars['bigint']>;
  petOwner?: InputMaybe<Scalars['Boolean']>;
  professionType?: InputMaybe<ProfessionTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.contact_renter_information" */
export type DbContactRenterInformationSumOrderBy = {
  lck?: InputMaybe<OrderBy>;
  monthlyIncomeMax?: InputMaybe<OrderBy>;
  monthlyIncomeMin?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.contact_renter_information" */
export type DbContactRenterInformationVarPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  monthlyIncomeMax?: InputMaybe<OrderBy>;
  monthlyIncomeMin?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.contact_renter_information" */
export type DbContactRenterInformationVarSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  monthlyIncomeMax?: InputMaybe<OrderBy>;
  monthlyIncomeMin?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.contact_renter_information" */
export type DbContactRenterInformationVarianceOrderBy = {
  lck?: InputMaybe<OrderBy>;
  monthlyIncomeMax?: InputMaybe<OrderBy>;
  monthlyIncomeMin?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** select columns of table "db.contact" */
export type DbContactSelectColumn =
  /** column name */
  | 'autoCc'
  /** column name */
  | 'birthdate'
  /** column name */
  | 'blocked'
  /** column name */
  | 'contactStatus'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'customerAccountId'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'firstName'
  /** column name */
  | 'formattedSalutation'
  /** column name */
  | 'fullName'
  /** column name */
  | 'gdprId'
  /** column name */
  | 'go3ContactKey'
  /** column name */
  | 'go3Utag'
  /** column name */
  | 'id'
  /** column name */
  | 'idNumber'
  /** column name */
  | 'kycSearchId'
  /** column name */
  | 'lastInteractionActivityId'
  /** column name */
  | 'lastInteractionActivityOrderDate'
  /** column name */
  | 'lastName'
  /** column name */
  | 'lck'
  /** column name */
  | 'legacyGo3NewsletterType'
  /** column name */
  | 'legalEntityId'
  /** column name */
  | 'lowerFirstName'
  /** column name */
  | 'lowerLastName'
  /** column name */
  | 'mostRecentActivityCreatedAt'
  /** column name */
  | 'nationality'
  /** column name */
  | 'noMail'
  /** column name */
  | 'normalizedLowerFirstName'
  /** column name */
  | 'normalizedLowerLastName'
  /** column name */
  | 'num'
  /** column name */
  | 'partnerFirstName'
  /** column name */
  | 'partnerLastName'
  /** column name */
  | 'personalNote'
  /** column name */
  | 'placeOfBirth'
  /** column name */
  | 'preferredLanguage'
  /** column name */
  | 'salutation'
  /** column name */
  | 'shopId'
  /** column name */
  | 'source'
  /** column name */
  | 'title'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbContactAggregateBoolExpBool_andArgumentsColumns" columns of table "db.contact" */
export type DbContactSelectColumnDbContactAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  | 'autoCc'
  /** column name */
  | 'blocked'
  /** column name */
  | 'deleted'
  /** column name */
  | 'noMail';

/** select "dbContactAggregateBoolExpBool_orArgumentsColumns" columns of table "db.contact" */
export type DbContactSelectColumnDbContactAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  | 'autoCc'
  /** column name */
  | 'blocked'
  /** column name */
  | 'deleted'
  /** column name */
  | 'noMail';

/** columns and relationships of "db.contact_status_info" */
export type DbContactStatusInfo = {
  __typename?: 'DbContactStatusInfo';
  blocked?: Maybe<Scalars['Boolean']>;
  contactStatus?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['String']>;
};

/** aggregated selection of "db.contact_status_info" */
export type DbContactStatusInfoAggregate = {
  __typename?: 'DbContactStatusInfoAggregate';
  aggregate?: Maybe<DbContactStatusInfoAggregateFields>;
  nodes: Array<DbContactStatusInfo>;
};

/** aggregate fields of "db.contact_status_info" */
export type DbContactStatusInfoAggregateFields = {
  __typename?: 'DbContactStatusInfoAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<DbContactStatusInfoMaxFields>;
  min?: Maybe<DbContactStatusInfoMinFields>;
};

/** aggregate fields of "db.contact_status_info" */
export type DbContactStatusInfoAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbContactStatusInfoSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "db.contact_status_info". All fields are combined with a logical 'AND'. */
export type DbContactStatusInfoBoolExp = {
  _and?: InputMaybe<Array<DbContactStatusInfoBoolExp>>;
  _not?: InputMaybe<DbContactStatusInfoBoolExp>;
  _or?: InputMaybe<Array<DbContactStatusInfoBoolExp>>;
  blocked?: InputMaybe<BooleanComparisonExp>;
  contactStatus?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  shopId?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbContactStatusInfoMaxFields = {
  __typename?: 'DbContactStatusInfoMaxFields';
  contactStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type DbContactStatusInfoMinFields = {
  __typename?: 'DbContactStatusInfoMinFields';
  contactStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "db.contact_status_info". */
export type DbContactStatusInfoOrderBy = {
  blocked?: InputMaybe<OrderBy>;
  contactStatus?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** select columns of table "db.contact_status_info" */
export type DbContactStatusInfoSelectColumn =
  /** column name */
  | 'blocked'
  /** column name */
  | 'contactStatus'
  /** column name */
  | 'deleted'
  /** column name */
  | 'id'
  /** column name */
  | 'shopId';

/** Streaming cursor of the table "db_contact_status_info" */
export type DbContactStatusInfoStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbContactStatusInfoStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbContactStatusInfoStreamCursorValueInput = {
  blocked?: InputMaybe<Scalars['Boolean']>;
  contactStatus?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type DbContactStddevFields = {
  __typename?: 'DbContactStddevFields';
  go3ContactKey?: Maybe<Scalars['Float']>;
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.contact" */
export type DbContactStddevOrderBy = {
  go3ContactKey?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbContactStddevPopFields = {
  __typename?: 'DbContactStddevPopFields';
  go3ContactKey?: Maybe<Scalars['Float']>;
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.contact" */
export type DbContactStddevPopOrderBy = {
  go3ContactKey?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbContactStddevSampFields = {
  __typename?: 'DbContactStddevSampFields';
  go3ContactKey?: Maybe<Scalars['Float']>;
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.contact" */
export type DbContactStddevSampOrderBy = {
  go3ContactKey?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_contact" */
export type DbContactStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbContactStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbContactStreamCursorValueInput = {
  autoCc?: InputMaybe<Scalars['Boolean']>;
  birthdate?: InputMaybe<Scalars['date']>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  contactStatus?: InputMaybe<ContactStatusEnum>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  customerAccountId?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  firstName?: InputMaybe<Scalars['String']>;
  formattedSalutation?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  gdprId?: InputMaybe<Scalars['String']>;
  go3ContactKey?: InputMaybe<Scalars['Int']>;
  go3Utag?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  idNumber?: InputMaybe<Scalars['String']>;
  kycSearchId?: InputMaybe<Scalars['uuid']>;
  lastInteractionActivityId?: InputMaybe<Scalars['String']>;
  lastInteractionActivityOrderDate?: InputMaybe<Scalars['timestamptz']>;
  lastName?: InputMaybe<Scalars['String']>;
  lck?: InputMaybe<Scalars['Int']>;
  legacyGo3NewsletterType?: InputMaybe<LegacyGo3NewsletterTypeEnum>;
  legalEntityId?: InputMaybe<Scalars['String']>;
  lowerFirstName?: InputMaybe<Scalars['String']>;
  lowerLastName?: InputMaybe<Scalars['String']>;
  mostRecentActivityCreatedAt?: InputMaybe<Scalars['timestamptz']>;
  nationality?: InputMaybe<CountryEnum>;
  noMail?: InputMaybe<Scalars['Boolean']>;
  normalizedLowerFirstName?: InputMaybe<Scalars['String']>;
  normalizedLowerLastName?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  partnerFirstName?: InputMaybe<Scalars['String']>;
  partnerLastName?: InputMaybe<Scalars['String']>;
  personalNote?: InputMaybe<Scalars['String']>;
  placeOfBirth?: InputMaybe<Scalars['String']>;
  preferredLanguage?: InputMaybe<LanguageEnum>;
  salutation?: InputMaybe<SalutationEnum>;
  shopId?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ContactTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbContactSumFields = {
  __typename?: 'DbContactSumFields';
  go3ContactKey?: Maybe<Scalars['Int']>;
  lck?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.contact" */
export type DbContactSumOrderBy = {
  go3ContactKey?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.contact_tag" */
export type DbContactTag = {
  __typename?: 'DbContactTag';
  /** An object relationship */
  contact: DbContact;
  contactId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  num: Scalars['bigint'];
  /** An object relationship */
  tag: DbTag;
  tagId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.contact_tag" */
export type DbContactTagAggregateOrderBy = {
  avg?: InputMaybe<DbContactTagAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbContactTagMaxOrderBy>;
  min?: InputMaybe<DbContactTagMinOrderBy>;
  stddev?: InputMaybe<DbContactTagStddevOrderBy>;
  stddevPop?: InputMaybe<DbContactTagStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbContactTagStddevSampOrderBy>;
  sum?: InputMaybe<DbContactTagSumOrderBy>;
  varPop?: InputMaybe<DbContactTagVarPopOrderBy>;
  varSamp?: InputMaybe<DbContactTagVarSampOrderBy>;
  variance?: InputMaybe<DbContactTagVarianceOrderBy>;
};

/** order by avg() on columns of table "db.contact_tag" */
export type DbContactTagAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.contact_tag". All fields are combined with a logical 'AND'. */
export type DbContactTagBoolExp = {
  _and?: InputMaybe<Array<DbContactTagBoolExp>>;
  _not?: InputMaybe<DbContactTagBoolExp>;
  _or?: InputMaybe<Array<DbContactTagBoolExp>>;
  contact?: InputMaybe<DbContactBoolExp>;
  contactId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  tag?: InputMaybe<DbTagBoolExp>;
  tagId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.contact_tag" */
export type DbContactTagMaxOrderBy = {
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  tagId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.contact_tag" */
export type DbContactTagMinOrderBy = {
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  tagId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.contact_tag". */
export type DbContactTagOrderBy = {
  contact?: InputMaybe<DbContactOrderBy>;
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  tag?: InputMaybe<DbTagOrderBy>;
  tagId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.contact_tag" */
export type DbContactTagSelectColumn =
  /** column name */
  | 'contactId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'num'
  /** column name */
  | 'tagId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.contact_tag" */
export type DbContactTagStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.contact_tag" */
export type DbContactTagStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.contact_tag" */
export type DbContactTagStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_contact_tag" */
export type DbContactTagStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbContactTagStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbContactTagStreamCursorValueInput = {
  contactId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  tagId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.contact_tag" */
export type DbContactTagSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.contact_tag" */
export type DbContactTagVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.contact_tag" */
export type DbContactTagVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.contact_tag" */
export type DbContactTagVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbContactVarPopFields = {
  __typename?: 'DbContactVarPopFields';
  go3ContactKey?: Maybe<Scalars['Float']>;
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.contact" */
export type DbContactVarPopOrderBy = {
  go3ContactKey?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbContactVarSampFields = {
  __typename?: 'DbContactVarSampFields';
  go3ContactKey?: Maybe<Scalars['Float']>;
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.contact" */
export type DbContactVarSampOrderBy = {
  go3ContactKey?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbContactVarianceFields = {
  __typename?: 'DbContactVarianceFields';
  go3ContactKey?: Maybe<Scalars['Float']>;
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.contact" */
export type DbContactVarianceOrderBy = {
  go3ContactKey?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.conversion_data" */
export type DbConversionData = {
  __typename?: 'DbConversionData';
  campaignOwnerShopId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  landingPageOwnerShopId?: Maybe<Scalars['uuid']>;
  landingPageUrlWithUtm?: Maybe<Scalars['String']>;
  /** An object relationship */
  lead?: Maybe<DbLead>;
  leadId?: Maybe<Scalars['String']>;
  num: Scalars['bigint'];
  referrer?: Maybe<Scalars['String']>;
  snowplowSessionId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.conversion_data" */
export type DbConversionDataAggregateOrderBy = {
  avg?: InputMaybe<DbConversionDataAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbConversionDataMaxOrderBy>;
  min?: InputMaybe<DbConversionDataMinOrderBy>;
  stddev?: InputMaybe<DbConversionDataStddevOrderBy>;
  stddevPop?: InputMaybe<DbConversionDataStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbConversionDataStddevSampOrderBy>;
  sum?: InputMaybe<DbConversionDataSumOrderBy>;
  varPop?: InputMaybe<DbConversionDataVarPopOrderBy>;
  varSamp?: InputMaybe<DbConversionDataVarSampOrderBy>;
  variance?: InputMaybe<DbConversionDataVarianceOrderBy>;
};

/** order by avg() on columns of table "db.conversion_data" */
export type DbConversionDataAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.conversion_data". All fields are combined with a logical 'AND'. */
export type DbConversionDataBoolExp = {
  _and?: InputMaybe<Array<DbConversionDataBoolExp>>;
  _not?: InputMaybe<DbConversionDataBoolExp>;
  _or?: InputMaybe<Array<DbConversionDataBoolExp>>;
  campaignOwnerShopId?: InputMaybe<UuidComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  landingPageOwnerShopId?: InputMaybe<UuidComparisonExp>;
  landingPageUrlWithUtm?: InputMaybe<StringComparisonExp>;
  lead?: InputMaybe<DbLeadBoolExp>;
  leadId?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  referrer?: InputMaybe<StringComparisonExp>;
  snowplowSessionId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.conversion_data" */
export type DbConversionDataMaxOrderBy = {
  campaignOwnerShopId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  landingPageOwnerShopId?: InputMaybe<OrderBy>;
  landingPageUrlWithUtm?: InputMaybe<OrderBy>;
  leadId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  referrer?: InputMaybe<OrderBy>;
  snowplowSessionId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.conversion_data" */
export type DbConversionDataMinOrderBy = {
  campaignOwnerShopId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  landingPageOwnerShopId?: InputMaybe<OrderBy>;
  landingPageUrlWithUtm?: InputMaybe<OrderBy>;
  leadId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  referrer?: InputMaybe<OrderBy>;
  snowplowSessionId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.conversion_data". */
export type DbConversionDataOrderBy = {
  campaignOwnerShopId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  landingPageOwnerShopId?: InputMaybe<OrderBy>;
  landingPageUrlWithUtm?: InputMaybe<OrderBy>;
  lead?: InputMaybe<DbLeadOrderBy>;
  leadId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  referrer?: InputMaybe<OrderBy>;
  snowplowSessionId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.conversion_data" */
export type DbConversionDataSelectColumn =
  /** column name */
  | 'campaignOwnerShopId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'landingPageOwnerShopId'
  /** column name */
  | 'landingPageUrlWithUtm'
  /** column name */
  | 'leadId'
  /** column name */
  | 'num'
  /** column name */
  | 'referrer'
  /** column name */
  | 'snowplowSessionId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.conversion_data" */
export type DbConversionDataStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.conversion_data" */
export type DbConversionDataStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.conversion_data" */
export type DbConversionDataStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_conversion_data" */
export type DbConversionDataStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbConversionDataStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbConversionDataStreamCursorValueInput = {
  campaignOwnerShopId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  landingPageOwnerShopId?: InputMaybe<Scalars['uuid']>;
  landingPageUrlWithUtm?: InputMaybe<Scalars['String']>;
  leadId?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  referrer?: InputMaybe<Scalars['String']>;
  snowplowSessionId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.conversion_data" */
export type DbConversionDataSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.conversion_data" */
export type DbConversionDataVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.conversion_data" */
export type DbConversionDataVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.conversion_data" */
export type DbConversionDataVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.country_mandatory_language" */
export type DbCountryMandatoryLanguage = {
  __typename?: 'DbCountryMandatoryLanguage';
  country?: Maybe<Scalars['String']>;
  countryCode: CountryEnum;
  /** An object relationship */
  country_mandatory_language_country: EnumsCountry;
  /** An object relationship */
  country_mandatory_language_language: EnumsLanguage;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  lang: LanguageEnum;
  num: Scalars['bigint'];
  /** An object relationship */
  shop?: Maybe<DbShop>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** Boolean expression to filter rows from the table "db.country_mandatory_language". All fields are combined with a logical 'AND'. */
export type DbCountryMandatoryLanguageBoolExp = {
  _and?: InputMaybe<Array<DbCountryMandatoryLanguageBoolExp>>;
  _not?: InputMaybe<DbCountryMandatoryLanguageBoolExp>;
  _or?: InputMaybe<Array<DbCountryMandatoryLanguageBoolExp>>;
  country?: InputMaybe<StringComparisonExp>;
  countryCode?: InputMaybe<EnumsCountryEnumComparisonExp>;
  country_mandatory_language_country?: InputMaybe<EnumsCountryBoolExp>;
  country_mandatory_language_language?: InputMaybe<EnumsLanguageBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lang?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "db.country_mandatory_language". */
export type DbCountryMandatoryLanguageOrderBy = {
  country?: InputMaybe<OrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  country_mandatory_language_country?: InputMaybe<EnumsCountryOrderBy>;
  country_mandatory_language_language?: InputMaybe<EnumsLanguageOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lang?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.country_mandatory_language" */
export type DbCountryMandatoryLanguageSelectColumn =
  /** column name */
  | 'country'
  /** column name */
  | 'countryCode'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'lang'
  /** column name */
  | 'num'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** Streaming cursor of the table "db_country_mandatory_language" */
export type DbCountryMandatoryLanguageStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbCountryMandatoryLanguageStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbCountryMandatoryLanguageStreamCursorValueInput = {
  country?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<CountryEnum>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<LanguageEnum>;
  num?: InputMaybe<Scalars['bigint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "db.custom_list_view" */
export type DbCustomListView = {
  __typename?: 'DbCustomListView';
  agentId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  /** An object relationship */
  custom_list_view_agent: DbAgent;
  /** An object relationship */
  custom_list_view_custom_view_type: EnumsCustomViewType;
  id: Scalars['String'];
  name: Scalars['String'];
  shopId: Scalars['String'];
  shownByDefault?: Maybe<Scalars['Boolean']>;
  type: CustomViewTypeEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  viewData: Scalars['jsonb'];
  viewIndex: Scalars['Int'];
};

/** columns and relationships of "db.custom_list_view" */
export type DbCustomListViewViewDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "db.custom_list_view". All fields are combined with a logical 'AND'. */
export type DbCustomListViewBoolExp = {
  _and?: InputMaybe<Array<DbCustomListViewBoolExp>>;
  _not?: InputMaybe<DbCustomListViewBoolExp>;
  _or?: InputMaybe<Array<DbCustomListViewBoolExp>>;
  agentId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  custom_list_view_agent?: InputMaybe<DbAgentBoolExp>;
  custom_list_view_custom_view_type?: InputMaybe<EnumsCustomViewTypeBoolExp>;
  id?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  shownByDefault?: InputMaybe<BooleanComparisonExp>;
  type?: InputMaybe<EnumsCustomViewTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  viewData?: InputMaybe<JsonbComparisonExp>;
  viewIndex?: InputMaybe<IntComparisonExp>;
};

/** Ordering options when selecting data from "db.custom_list_view". */
export type DbCustomListViewOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  custom_list_view_agent?: InputMaybe<DbAgentOrderBy>;
  custom_list_view_custom_view_type?: InputMaybe<EnumsCustomViewTypeOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  shownByDefault?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  viewData?: InputMaybe<OrderBy>;
  viewIndex?: InputMaybe<OrderBy>;
};

/** select columns of table "db.custom_list_view" */
export type DbCustomListViewSelectColumn =
  /** column name */
  | 'agentId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'shopId'
  /** column name */
  | 'shownByDefault'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy'
  /** column name */
  | 'viewData'
  /** column name */
  | 'viewIndex';

/** Streaming cursor of the table "db_custom_list_view" */
export type DbCustomListViewStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbCustomListViewStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbCustomListViewStreamCursorValueInput = {
  agentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['String']>;
  shownByDefault?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<CustomViewTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  viewData?: InputMaybe<Scalars['jsonb']>;
  viewIndex?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "db.custom_search_demand_polygon" */
export type DbCustomSearchDemandPolygon = {
  __typename?: 'DbCustomSearchDemandPolygon';
  buyerToRentSpecificationId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  num: Scalars['bigint'];
  polygon: Scalars['geometry'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.custom_search_demand_polygon" */
export type DbCustomSearchDemandPolygonAggregateOrderBy = {
  avg?: InputMaybe<DbCustomSearchDemandPolygonAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbCustomSearchDemandPolygonMaxOrderBy>;
  min?: InputMaybe<DbCustomSearchDemandPolygonMinOrderBy>;
  stddev?: InputMaybe<DbCustomSearchDemandPolygonStddevOrderBy>;
  stddevPop?: InputMaybe<DbCustomSearchDemandPolygonStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbCustomSearchDemandPolygonStddevSampOrderBy>;
  sum?: InputMaybe<DbCustomSearchDemandPolygonSumOrderBy>;
  varPop?: InputMaybe<DbCustomSearchDemandPolygonVarPopOrderBy>;
  varSamp?: InputMaybe<DbCustomSearchDemandPolygonVarSampOrderBy>;
  variance?: InputMaybe<DbCustomSearchDemandPolygonVarianceOrderBy>;
};

/** order by avg() on columns of table "db.custom_search_demand_polygon" */
export type DbCustomSearchDemandPolygonAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.custom_search_demand_polygon". All fields are combined with a logical 'AND'. */
export type DbCustomSearchDemandPolygonBoolExp = {
  _and?: InputMaybe<Array<DbCustomSearchDemandPolygonBoolExp>>;
  _not?: InputMaybe<DbCustomSearchDemandPolygonBoolExp>;
  _or?: InputMaybe<Array<DbCustomSearchDemandPolygonBoolExp>>;
  buyerToRentSpecificationId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  polygon?: InputMaybe<GeometryComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.custom_search_demand_polygon" */
export type DbCustomSearchDemandPolygonMaxOrderBy = {
  buyerToRentSpecificationId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.custom_search_demand_polygon" */
export type DbCustomSearchDemandPolygonMinOrderBy = {
  buyerToRentSpecificationId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.custom_search_demand_polygon". */
export type DbCustomSearchDemandPolygonOrderBy = {
  buyerToRentSpecificationId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  polygon?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.custom_search_demand_polygon" */
export type DbCustomSearchDemandPolygonSelectColumn =
  /** column name */
  | 'buyerToRentSpecificationId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'num'
  /** column name */
  | 'polygon'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.custom_search_demand_polygon" */
export type DbCustomSearchDemandPolygonStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.custom_search_demand_polygon" */
export type DbCustomSearchDemandPolygonStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.custom_search_demand_polygon" */
export type DbCustomSearchDemandPolygonStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_custom_search_demand_polygon" */
export type DbCustomSearchDemandPolygonStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbCustomSearchDemandPolygonStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbCustomSearchDemandPolygonStreamCursorValueInput = {
  buyerToRentSpecificationId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  polygon?: InputMaybe<Scalars['geometry']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.custom_search_demand_polygon" */
export type DbCustomSearchDemandPolygonSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.custom_search_demand_polygon" */
export type DbCustomSearchDemandPolygonVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.custom_search_demand_polygon" */
export type DbCustomSearchDemandPolygonVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.custom_search_demand_polygon" */
export type DbCustomSearchDemandPolygonVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.customer_account" */
export type DbCustomerAccount = {
  __typename?: 'DbCustomerAccount';
  /** An array relationship */
  baseActivities: Array<DbBaseActivity>;
  /** An aggregate relationship */
  baseActivitiesAggregate: DbBaseActivityAggregate;
  city?: Maybe<Scalars['String']>;
  /** An array relationship */
  consents: Array<DbConsent>;
  /** An aggregate relationship */
  consentsAggregate: DbConsentAggregate;
  country: CountryEnum;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  emailAddress: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  licensePartnerId?: Maybe<Scalars['String']>;
  num: Scalars['bigint'];
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  preferredLanguage?: Maybe<LanguageEnum>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  verified: Scalars['Boolean'];
};

/** columns and relationships of "db.customer_account" */
export type DbCustomerAccountBaseActivitiesArgs = {
  distinctOn?: InputMaybe<Array<DbBaseActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBaseActivityOrderBy>>;
  where?: InputMaybe<DbBaseActivityBoolExp>;
};

/** columns and relationships of "db.customer_account" */
export type DbCustomerAccountBaseActivitiesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbBaseActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBaseActivityOrderBy>>;
  where?: InputMaybe<DbBaseActivityBoolExp>;
};

/** columns and relationships of "db.customer_account" */
export type DbCustomerAccountConsentsArgs = {
  distinctOn?: InputMaybe<Array<DbConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentOrderBy>>;
  where?: InputMaybe<DbConsentBoolExp>;
};

/** columns and relationships of "db.customer_account" */
export type DbCustomerAccountConsentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentOrderBy>>;
  where?: InputMaybe<DbConsentBoolExp>;
};

/** Boolean expression to filter rows from the table "db.customer_account". All fields are combined with a logical 'AND'. */
export type DbCustomerAccountBoolExp = {
  _and?: InputMaybe<Array<DbCustomerAccountBoolExp>>;
  _not?: InputMaybe<DbCustomerAccountBoolExp>;
  _or?: InputMaybe<Array<DbCustomerAccountBoolExp>>;
  baseActivities?: InputMaybe<DbBaseActivityBoolExp>;
  baseActivitiesAggregate?: InputMaybe<DbBaseActivityAggregateBoolExp>;
  city?: InputMaybe<StringComparisonExp>;
  consents?: InputMaybe<DbConsentBoolExp>;
  consentsAggregate?: InputMaybe<DbConsentAggregateBoolExp>;
  country?: InputMaybe<EnumsCountryEnumComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  emailAddress?: InputMaybe<StringComparisonExp>;
  firstName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lastName?: InputMaybe<StringComparisonExp>;
  licensePartnerId?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  phoneNumber?: InputMaybe<StringComparisonExp>;
  postalCode?: InputMaybe<StringComparisonExp>;
  preferredLanguage?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  streetName?: InputMaybe<StringComparisonExp>;
  streetNumber?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  verified?: InputMaybe<BooleanComparisonExp>;
};

/** Ordering options when selecting data from "db.customer_account". */
export type DbCustomerAccountOrderBy = {
  baseActivitiesAggregate?: InputMaybe<DbBaseActivityAggregateOrderBy>;
  city?: InputMaybe<OrderBy>;
  consentsAggregate?: InputMaybe<DbConsentAggregateOrderBy>;
  country?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  emailAddress?: InputMaybe<OrderBy>;
  firstName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastName?: InputMaybe<OrderBy>;
  licensePartnerId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  phoneNumber?: InputMaybe<OrderBy>;
  postalCode?: InputMaybe<OrderBy>;
  preferredLanguage?: InputMaybe<OrderBy>;
  streetName?: InputMaybe<OrderBy>;
  streetNumber?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  verified?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.document" */
export type DbDocument = {
  __typename?: 'DbDocument';
  contentType: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  emailAttachments: Array<DbEmailAttachment>;
  /** An aggregate relationship */
  emailAttachmentsAggregate: DbEmailAttachmentAggregate;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  name: Scalars['String'];
  num: Scalars['bigint'];
  targetDirectory?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

/** columns and relationships of "db.document" */
export type DbDocumentEmailAttachmentsArgs = {
  distinctOn?: InputMaybe<Array<DbEmailAttachmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailAttachmentOrderBy>>;
  where?: InputMaybe<DbEmailAttachmentBoolExp>;
};

/** columns and relationships of "db.document" */
export type DbDocumentEmailAttachmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailAttachmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailAttachmentOrderBy>>;
  where?: InputMaybe<DbEmailAttachmentBoolExp>;
};

/** columns and relationships of "db.document_activity" */
export type DbDocumentActivity = {
  __typename?: 'DbDocumentActivity';
  /** An object relationship */
  baseActivity: DbBaseActivity;
  content?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  documentReference?: Maybe<DbDocumentReference>;
  documentReferenceId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

/** columns and relationships of "db.document_activity" */
export type DbDocumentActivityContentArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "db.document_activity". All fields are combined with a logical 'AND'. */
export type DbDocumentActivityBoolExp = {
  _and?: InputMaybe<Array<DbDocumentActivityBoolExp>>;
  _not?: InputMaybe<DbDocumentActivityBoolExp>;
  _or?: InputMaybe<Array<DbDocumentActivityBoolExp>>;
  baseActivity?: InputMaybe<DbBaseActivityBoolExp>;
  content?: InputMaybe<JsonbComparisonExp>;
  documentReference?: InputMaybe<DbDocumentReferenceBoolExp>;
  documentReferenceId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "db.document_activity". */
export type DbDocumentActivityOrderBy = {
  baseActivity?: InputMaybe<DbBaseActivityOrderBy>;
  content?: InputMaybe<OrderBy>;
  documentReference?: InputMaybe<DbDocumentReferenceOrderBy>;
  documentReferenceId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** select columns of table "db.document_activity" */
export type DbDocumentActivitySelectColumn =
  /** column name */
  | 'content'
  /** column name */
  | 'documentReferenceId'
  /** column name */
  | 'id';

/** Streaming cursor of the table "db_document_activity" */
export type DbDocumentActivityStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbDocumentActivityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbDocumentActivityStreamCursorValueInput = {
  content?: InputMaybe<Scalars['jsonb']>;
  documentReferenceId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "db.document" */
export type DbDocumentAggregate = {
  __typename?: 'DbDocumentAggregate';
  aggregate?: Maybe<DbDocumentAggregateFields>;
  nodes: Array<DbDocument>;
};

/** aggregate fields of "db.document" */
export type DbDocumentAggregateFields = {
  __typename?: 'DbDocumentAggregateFields';
  avg?: Maybe<DbDocumentAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbDocumentMaxFields>;
  min?: Maybe<DbDocumentMinFields>;
  stddev?: Maybe<DbDocumentStddevFields>;
  stddevPop?: Maybe<DbDocumentStddevPopFields>;
  stddevSamp?: Maybe<DbDocumentStddevSampFields>;
  sum?: Maybe<DbDocumentSumFields>;
  varPop?: Maybe<DbDocumentVarPopFields>;
  varSamp?: Maybe<DbDocumentVarSampFields>;
  variance?: Maybe<DbDocumentVarianceFields>;
};

/** aggregate fields of "db.document" */
export type DbDocumentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbDocumentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type DbDocumentAvgFields = {
  __typename?: 'DbDocumentAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "db.document". All fields are combined with a logical 'AND'. */
export type DbDocumentBoolExp = {
  _and?: InputMaybe<Array<DbDocumentBoolExp>>;
  _not?: InputMaybe<DbDocumentBoolExp>;
  _or?: InputMaybe<Array<DbDocumentBoolExp>>;
  contentType?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  emailAttachments?: InputMaybe<DbEmailAttachmentBoolExp>;
  emailAttachmentsAggregate?: InputMaybe<DbEmailAttachmentAggregateBoolExp>;
  expiresAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  targetDirectory?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbDocumentMaxFields = {
  __typename?: 'DbDocumentMaxFields';
  contentType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  targetDirectory?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type DbDocumentMinFields = {
  __typename?: 'DbDocumentMinFields';
  contentType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  targetDirectory?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "db.document". */
export type DbDocumentOrderBy = {
  contentType?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  emailAttachmentsAggregate?: InputMaybe<DbEmailAttachmentAggregateOrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  targetDirectory?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.document_reference" */
export type DbDocumentReference = {
  __typename?: 'DbDocumentReference';
  /** An object relationship */
  contact?: Maybe<DbContact>;
  contactId?: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  /** An object relationship */
  createdByAgent?: Maybe<DbAgent>;
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  /** An object relationship */
  entityType: EnumsEntityType;
  fileName: Scalars['String'];
  id: Scalars['String'];
  lck: Scalars['Int'];
  /** An object relationship */
  lead?: Maybe<DbLead>;
  licensePartnerId?: Maybe<Scalars['String']>;
  /** An aggregate relationship */
  licensePartnerShopsAggregate: DbShopAggregate;
  /** An array relationship */
  license_partner_shops: Array<DbShop>;
  num: Scalars['bigint'];
  path: Scalars['String'];
  privacyLevel: PrivacyLevelEnum;
  /** An object relationship */
  privacyLevelByPrivacyLevel: EnumsPrivacyLevel;
  referenceId: Scalars['String'];
  referenceType: EntityTypeEnum;
  shopId?: Maybe<Scalars['String']>;
  sizeKb?: Maybe<Scalars['Int']>;
  /** An object relationship */
  team?: Maybe<DbTeam>;
  teamId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  /** An object relationship */
  updatedByAgent?: Maybe<DbAgent>;
};

/** columns and relationships of "db.document_reference" */
export type DbDocumentReferenceLicensePartnerShopsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopOrderBy>>;
  where?: InputMaybe<DbShopBoolExp>;
};

/** columns and relationships of "db.document_reference" */
export type DbDocumentReferenceLicensePartnerShopsArgs = {
  distinctOn?: InputMaybe<Array<DbShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopOrderBy>>;
  where?: InputMaybe<DbShopBoolExp>;
};

/** aggregated selection of "db.document_reference" */
export type DbDocumentReferenceAggregate = {
  __typename?: 'DbDocumentReferenceAggregate';
  aggregate?: Maybe<DbDocumentReferenceAggregateFields>;
  nodes: Array<DbDocumentReference>;
};

export type DbDocumentReferenceAggregateBoolExp = {
  bool_and?: InputMaybe<DbDocumentReferenceAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbDocumentReferenceAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbDocumentReferenceAggregateBoolExpCount>;
};

/** aggregate fields of "db.document_reference" */
export type DbDocumentReferenceAggregateFields = {
  __typename?: 'DbDocumentReferenceAggregateFields';
  avg?: Maybe<DbDocumentReferenceAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbDocumentReferenceMaxFields>;
  min?: Maybe<DbDocumentReferenceMinFields>;
  stddev?: Maybe<DbDocumentReferenceStddevFields>;
  stddevPop?: Maybe<DbDocumentReferenceStddevPopFields>;
  stddevSamp?: Maybe<DbDocumentReferenceStddevSampFields>;
  sum?: Maybe<DbDocumentReferenceSumFields>;
  varPop?: Maybe<DbDocumentReferenceVarPopFields>;
  varSamp?: Maybe<DbDocumentReferenceVarSampFields>;
  variance?: Maybe<DbDocumentReferenceVarianceFields>;
};

/** aggregate fields of "db.document_reference" */
export type DbDocumentReferenceAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbDocumentReferenceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.document_reference" */
export type DbDocumentReferenceAggregateOrderBy = {
  avg?: InputMaybe<DbDocumentReferenceAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbDocumentReferenceMaxOrderBy>;
  min?: InputMaybe<DbDocumentReferenceMinOrderBy>;
  stddev?: InputMaybe<DbDocumentReferenceStddevOrderBy>;
  stddevPop?: InputMaybe<DbDocumentReferenceStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbDocumentReferenceStddevSampOrderBy>;
  sum?: InputMaybe<DbDocumentReferenceSumOrderBy>;
  varPop?: InputMaybe<DbDocumentReferenceVarPopOrderBy>;
  varSamp?: InputMaybe<DbDocumentReferenceVarSampOrderBy>;
  variance?: InputMaybe<DbDocumentReferenceVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbDocumentReferenceAvgFields = {
  __typename?: 'DbDocumentReferenceAvgFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  sizeKb?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.document_reference" */
export type DbDocumentReferenceAvgOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  sizeKb?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.document_reference". All fields are combined with a logical 'AND'. */
export type DbDocumentReferenceBoolExp = {
  _and?: InputMaybe<Array<DbDocumentReferenceBoolExp>>;
  _not?: InputMaybe<DbDocumentReferenceBoolExp>;
  _or?: InputMaybe<Array<DbDocumentReferenceBoolExp>>;
  contact?: InputMaybe<DbContactBoolExp>;
  contactId?: InputMaybe<StringComparisonExp>;
  contentType?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  createdByAgent?: InputMaybe<DbAgentBoolExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  displayName?: InputMaybe<StringComparisonExp>;
  entityType?: InputMaybe<EnumsEntityTypeBoolExp>;
  fileName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lck?: InputMaybe<IntComparisonExp>;
  lead?: InputMaybe<DbLeadBoolExp>;
  licensePartnerId?: InputMaybe<StringComparisonExp>;
  license_partner_shops?: InputMaybe<DbShopBoolExp>;
  license_partner_shopsAggregate?: InputMaybe<DbShopAggregateBoolExp>;
  num?: InputMaybe<BigintComparisonExp>;
  path?: InputMaybe<StringComparisonExp>;
  privacyLevel?: InputMaybe<EnumsPrivacyLevelEnumComparisonExp>;
  privacyLevelByPrivacyLevel?: InputMaybe<EnumsPrivacyLevelBoolExp>;
  referenceId?: InputMaybe<StringComparisonExp>;
  referenceType?: InputMaybe<EnumsEntityTypeEnumComparisonExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  sizeKb?: InputMaybe<IntComparisonExp>;
  team?: InputMaybe<DbTeamBoolExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  updatedByAgent?: InputMaybe<DbAgentBoolExp>;
};

/** aggregate max on columns */
export type DbDocumentReferenceMaxFields = {
  __typename?: 'DbDocumentReferenceMaxFields';
  contactId?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lck?: Maybe<Scalars['Int']>;
  licensePartnerId?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  path?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['String']>;
  sizeKb?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.document_reference" */
export type DbDocumentReferenceMaxOrderBy = {
  contactId?: InputMaybe<OrderBy>;
  contentType?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  displayName?: InputMaybe<OrderBy>;
  fileName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  licensePartnerId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  path?: InputMaybe<OrderBy>;
  referenceId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  sizeKb?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbDocumentReferenceMinFields = {
  __typename?: 'DbDocumentReferenceMinFields';
  contactId?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lck?: Maybe<Scalars['Int']>;
  licensePartnerId?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  path?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['String']>;
  sizeKb?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.document_reference" */
export type DbDocumentReferenceMinOrderBy = {
  contactId?: InputMaybe<OrderBy>;
  contentType?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  displayName?: InputMaybe<OrderBy>;
  fileName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  licensePartnerId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  path?: InputMaybe<OrderBy>;
  referenceId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  sizeKb?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.document_reference". */
export type DbDocumentReferenceOrderBy = {
  contact?: InputMaybe<DbContactOrderBy>;
  contactId?: InputMaybe<OrderBy>;
  contentType?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  createdByAgent?: InputMaybe<DbAgentOrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  displayName?: InputMaybe<OrderBy>;
  entityType?: InputMaybe<EnumsEntityTypeOrderBy>;
  fileName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  lead?: InputMaybe<DbLeadOrderBy>;
  licensePartnerId?: InputMaybe<OrderBy>;
  license_partner_shopsAggregate?: InputMaybe<DbShopAggregateOrderBy>;
  num?: InputMaybe<OrderBy>;
  path?: InputMaybe<OrderBy>;
  privacyLevel?: InputMaybe<OrderBy>;
  privacyLevelByPrivacyLevel?: InputMaybe<EnumsPrivacyLevelOrderBy>;
  referenceId?: InputMaybe<OrderBy>;
  referenceType?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  sizeKb?: InputMaybe<OrderBy>;
  team?: InputMaybe<DbTeamOrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  updatedByAgent?: InputMaybe<DbAgentOrderBy>;
};

/** select columns of table "db.document_reference" */
export type DbDocumentReferenceSelectColumn =
  /** column name */
  | 'contactId'
  /** column name */
  | 'contentType'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'description'
  /** column name */
  | 'displayName'
  /** column name */
  | 'fileName'
  /** column name */
  | 'id'
  /** column name */
  | 'lck'
  /** column name */
  | 'licensePartnerId'
  /** column name */
  | 'num'
  /** column name */
  | 'path'
  /** column name */
  | 'privacyLevel'
  /** column name */
  | 'referenceId'
  /** column name */
  | 'referenceType'
  /** column name */
  | 'shopId'
  /** column name */
  | 'sizeKb'
  /** column name */
  | 'teamId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbDocumentReferenceAggregateBoolExpBool_andArgumentsColumns" columns of table "db.document_reference" */
export type DbDocumentReferenceSelectColumnDbDocumentReferenceAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbDocumentReferenceAggregateBoolExpBool_orArgumentsColumns" columns of table "db.document_reference" */
export type DbDocumentReferenceSelectColumnDbDocumentReferenceAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbDocumentReferenceStddevFields = {
  __typename?: 'DbDocumentReferenceStddevFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  sizeKb?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.document_reference" */
export type DbDocumentReferenceStddevOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  sizeKb?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbDocumentReferenceStddevPopFields = {
  __typename?: 'DbDocumentReferenceStddevPopFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  sizeKb?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.document_reference" */
export type DbDocumentReferenceStddevPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  sizeKb?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbDocumentReferenceStddevSampFields = {
  __typename?: 'DbDocumentReferenceStddevSampFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  sizeKb?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.document_reference" */
export type DbDocumentReferenceStddevSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  sizeKb?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_document_reference" */
export type DbDocumentReferenceStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbDocumentReferenceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbDocumentReferenceStreamCursorValueInput = {
  contactId?: InputMaybe<Scalars['String']>;
  contentType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lck?: InputMaybe<Scalars['Int']>;
  licensePartnerId?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  path?: InputMaybe<Scalars['String']>;
  privacyLevel?: InputMaybe<PrivacyLevelEnum>;
  referenceId?: InputMaybe<Scalars['String']>;
  referenceType?: InputMaybe<EntityTypeEnum>;
  shopId?: InputMaybe<Scalars['String']>;
  sizeKb?: InputMaybe<Scalars['Int']>;
  teamId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbDocumentReferenceSumFields = {
  __typename?: 'DbDocumentReferenceSumFields';
  lck?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['bigint']>;
  sizeKb?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "db.document_reference" */
export type DbDocumentReferenceSumOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  sizeKb?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbDocumentReferenceVarPopFields = {
  __typename?: 'DbDocumentReferenceVarPopFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  sizeKb?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.document_reference" */
export type DbDocumentReferenceVarPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  sizeKb?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbDocumentReferenceVarSampFields = {
  __typename?: 'DbDocumentReferenceVarSampFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  sizeKb?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.document_reference" */
export type DbDocumentReferenceVarSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  sizeKb?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbDocumentReferenceVarianceFields = {
  __typename?: 'DbDocumentReferenceVarianceFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  sizeKb?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.document_reference" */
export type DbDocumentReferenceVarianceOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  sizeKb?: InputMaybe<OrderBy>;
};

/** select columns of table "db.document" */
export type DbDocumentSelectColumn =
  /** column name */
  | 'contentType'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'expiresAt'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'num'
  /** column name */
  | 'targetDirectory'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy'
  /** column name */
  | 'url';

/** aggregate stddev on columns */
export type DbDocumentStddevFields = {
  __typename?: 'DbDocumentStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type DbDocumentStddevPopFields = {
  __typename?: 'DbDocumentStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type DbDocumentStddevSampFields = {
  __typename?: 'DbDocumentStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "db_document" */
export type DbDocumentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbDocumentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbDocumentStreamCursorValueInput = {
  contentType?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  targetDirectory?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbDocumentSumFields = {
  __typename?: 'DbDocumentSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** aggregate varPop on columns */
export type DbDocumentVarPopFields = {
  __typename?: 'DbDocumentVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type DbDocumentVarSampFields = {
  __typename?: 'DbDocumentVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type DbDocumentVarianceFields = {
  __typename?: 'DbDocumentVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "db.email" */
export type DbEmail = {
  __typename?: 'DbEmail';
  /** An array relationship */
  attachments: Array<DbEmailAttachment>;
  /** An aggregate relationship */
  attachmentsAggregate: DbEmailAttachmentAggregate;
  bcc?: Maybe<Array<Scalars['String']>>;
  body: Scalars['String'];
  cc?: Maybe<Array<Scalars['String']>>;
  /** An array relationship */
  consents: Array<DbConsent>;
  /** An aggregate relationship */
  consentsAggregate: DbConsentAggregate;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  date?: Maybe<Scalars['timestamp']>;
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  direction?: Maybe<EmailDirectionEnum>;
  /** An array relationship */
  documentReferences: Array<DbDocumentReference>;
  /** An aggregate relationship */
  documentReferencesAggregate: DbDocumentReferenceAggregate;
  /** An array relationship */
  emailActivities: Array<DbEmailActivity>;
  /** An object relationship */
  emailDirection?: Maybe<EnumsEmailDirection>;
  /** An object relationship */
  emailStatus?: Maybe<EnumsEmailStatus>;
  failureReason?: Maybe<Scalars['String']>;
  from: Scalars['String'];
  id: Scalars['String'];
  messageId?: Maybe<Scalars['String']>;
  num: Scalars['bigint'];
  /** An object relationship */
  nylasIntegrationFrom?: Maybe<DbNylasEmailIntegration>;
  /** An object relationship */
  nylasIntegrationTo?: Maybe<DbNylasEmailIntegration>;
  priority?: Maybe<PriorityEnum>;
  /** An object relationship */
  priorityByPriority?: Maybe<EnumsPriority>;
  propertyIds: Array<Scalars['String']>;
  retryCount?: Maybe<Scalars['Int']>;
  status?: Maybe<EmailStatusEnum>;
  subject: Scalars['String'];
  threadId?: Maybe<Scalars['String']>;
  to: Array<Scalars['String']>;
  /** An array relationship */
  trackings: Array<DbEmailTracking>;
  /** An aggregate relationship */
  trackingsAggregate: DbEmailTrackingAggregate;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** columns and relationships of "db.email" */
export type DbEmailAttachmentsArgs = {
  distinctOn?: InputMaybe<Array<DbEmailAttachmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailAttachmentOrderBy>>;
  where?: InputMaybe<DbEmailAttachmentBoolExp>;
};

/** columns and relationships of "db.email" */
export type DbEmailAttachmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailAttachmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailAttachmentOrderBy>>;
  where?: InputMaybe<DbEmailAttachmentBoolExp>;
};

/** columns and relationships of "db.email" */
export type DbEmailConsentsArgs = {
  distinctOn?: InputMaybe<Array<DbConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentOrderBy>>;
  where?: InputMaybe<DbConsentBoolExp>;
};

/** columns and relationships of "db.email" */
export type DbEmailConsentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentOrderBy>>;
  where?: InputMaybe<DbConsentBoolExp>;
};

/** columns and relationships of "db.email" */
export type DbEmailDocumentReferencesArgs = {
  distinctOn?: InputMaybe<Array<DbDocumentReferenceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbDocumentReferenceOrderBy>>;
  where?: InputMaybe<DbDocumentReferenceBoolExp>;
};

/** columns and relationships of "db.email" */
export type DbEmailDocumentReferencesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbDocumentReferenceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbDocumentReferenceOrderBy>>;
  where?: InputMaybe<DbDocumentReferenceBoolExp>;
};

/** columns and relationships of "db.email" */
export type DbEmailEmailActivitiesArgs = {
  distinctOn?: InputMaybe<Array<DbEmailActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailActivityOrderBy>>;
  where?: InputMaybe<DbEmailActivityBoolExp>;
};

/** columns and relationships of "db.email" */
export type DbEmailTrackingsArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTrackingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTrackingOrderBy>>;
  where?: InputMaybe<DbEmailTrackingBoolExp>;
};

/** columns and relationships of "db.email" */
export type DbEmailTrackingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTrackingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTrackingOrderBy>>;
  where?: InputMaybe<DbEmailTrackingBoolExp>;
};

/** columns and relationships of "db.email_activity" */
export type DbEmailActivity = {
  __typename?: 'DbEmailActivity';
  /** An object relationship */
  baseActivity: DbBaseActivity;
  /** An object relationship */
  email: DbEmail;
  emailId: Scalars['String'];
  id: Scalars['String'];
};

/** order by aggregate values of table "db.email_activity" */
export type DbEmailActivityAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbEmailActivityMaxOrderBy>;
  min?: InputMaybe<DbEmailActivityMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.email_activity". All fields are combined with a logical 'AND'. */
export type DbEmailActivityBoolExp = {
  _and?: InputMaybe<Array<DbEmailActivityBoolExp>>;
  _not?: InputMaybe<DbEmailActivityBoolExp>;
  _or?: InputMaybe<Array<DbEmailActivityBoolExp>>;
  baseActivity?: InputMaybe<DbBaseActivityBoolExp>;
  email?: InputMaybe<DbEmailBoolExp>;
  emailId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.email_activity" */
export type DbEmailActivityMaxOrderBy = {
  emailId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.email_activity" */
export type DbEmailActivityMinOrderBy = {
  emailId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.email_activity". */
export type DbEmailActivityOrderBy = {
  baseActivity?: InputMaybe<DbBaseActivityOrderBy>;
  email?: InputMaybe<DbEmailOrderBy>;
  emailId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** select columns of table "db.email_activity" */
export type DbEmailActivitySelectColumn =
  /** column name */
  | 'emailId'
  /** column name */
  | 'id';

/** columns and relationships of "db.email_attachment" */
export type DbEmailAttachment = {
  __typename?: 'DbEmailAttachment';
  attachmentId: Scalars['String'];
  /** An object relationship */
  document: DbDocument;
  emailId: Scalars['String'];
};

/** aggregated selection of "db.email_attachment" */
export type DbEmailAttachmentAggregate = {
  __typename?: 'DbEmailAttachmentAggregate';
  aggregate?: Maybe<DbEmailAttachmentAggregateFields>;
  nodes: Array<DbEmailAttachment>;
};

export type DbEmailAttachmentAggregateBoolExp = {
  count?: InputMaybe<DbEmailAttachmentAggregateBoolExpCount>;
};

/** aggregate fields of "db.email_attachment" */
export type DbEmailAttachmentAggregateFields = {
  __typename?: 'DbEmailAttachmentAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<DbEmailAttachmentMaxFields>;
  min?: Maybe<DbEmailAttachmentMinFields>;
};

/** aggregate fields of "db.email_attachment" */
export type DbEmailAttachmentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbEmailAttachmentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.email_attachment" */
export type DbEmailAttachmentAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbEmailAttachmentMaxOrderBy>;
  min?: InputMaybe<DbEmailAttachmentMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.email_attachment". All fields are combined with a logical 'AND'. */
export type DbEmailAttachmentBoolExp = {
  _and?: InputMaybe<Array<DbEmailAttachmentBoolExp>>;
  _not?: InputMaybe<DbEmailAttachmentBoolExp>;
  _or?: InputMaybe<Array<DbEmailAttachmentBoolExp>>;
  attachmentId?: InputMaybe<StringComparisonExp>;
  document?: InputMaybe<DbDocumentBoolExp>;
  emailId?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbEmailAttachmentMaxFields = {
  __typename?: 'DbEmailAttachmentMaxFields';
  attachmentId?: Maybe<Scalars['String']>;
  emailId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.email_attachment" */
export type DbEmailAttachmentMaxOrderBy = {
  attachmentId?: InputMaybe<OrderBy>;
  emailId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbEmailAttachmentMinFields = {
  __typename?: 'DbEmailAttachmentMinFields';
  attachmentId?: Maybe<Scalars['String']>;
  emailId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.email_attachment" */
export type DbEmailAttachmentMinOrderBy = {
  attachmentId?: InputMaybe<OrderBy>;
  emailId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.email_attachment". */
export type DbEmailAttachmentOrderBy = {
  attachmentId?: InputMaybe<OrderBy>;
  document?: InputMaybe<DbDocumentOrderBy>;
  emailId?: InputMaybe<OrderBy>;
};

/** select columns of table "db.email_attachment" */
export type DbEmailAttachmentSelectColumn =
  /** column name */
  | 'attachmentId'
  /** column name */
  | 'emailId';

/** Streaming cursor of the table "db_email_attachment" */
export type DbEmailAttachmentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbEmailAttachmentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbEmailAttachmentStreamCursorValueInput = {
  attachmentId?: InputMaybe<Scalars['String']>;
  emailId?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "db.email". All fields are combined with a logical 'AND'. */
export type DbEmailBoolExp = {
  _and?: InputMaybe<Array<DbEmailBoolExp>>;
  _not?: InputMaybe<DbEmailBoolExp>;
  _or?: InputMaybe<Array<DbEmailBoolExp>>;
  attachments?: InputMaybe<DbEmailAttachmentBoolExp>;
  attachmentsAggregate?: InputMaybe<DbEmailAttachmentAggregateBoolExp>;
  bcc?: InputMaybe<StringArrayComparisonExp>;
  body?: InputMaybe<StringComparisonExp>;
  cc?: InputMaybe<StringArrayComparisonExp>;
  consents?: InputMaybe<DbConsentBoolExp>;
  consentsAggregate?: InputMaybe<DbConsentAggregateBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  date?: InputMaybe<TimestampComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  direction?: InputMaybe<EnumsEmailDirectionEnumComparisonExp>;
  documentReferences?: InputMaybe<DbDocumentReferenceBoolExp>;
  documentReferencesAggregate?: InputMaybe<DbDocumentReferenceAggregateBoolExp>;
  emailActivities?: InputMaybe<DbEmailActivityBoolExp>;
  emailDirection?: InputMaybe<EnumsEmailDirectionBoolExp>;
  emailStatus?: InputMaybe<EnumsEmailStatusBoolExp>;
  failureReason?: InputMaybe<StringComparisonExp>;
  from?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  messageId?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  nylasIntegrationFrom?: InputMaybe<DbNylasEmailIntegrationBoolExp>;
  nylasIntegrationTo?: InputMaybe<DbNylasEmailIntegrationBoolExp>;
  priority?: InputMaybe<EnumsPriorityEnumComparisonExp>;
  priorityByPriority?: InputMaybe<EnumsPriorityBoolExp>;
  propertyIds?: InputMaybe<StringArrayComparisonExp>;
  retryCount?: InputMaybe<IntComparisonExp>;
  status?: InputMaybe<EnumsEmailStatusEnumComparisonExp>;
  subject?: InputMaybe<StringComparisonExp>;
  threadId?: InputMaybe<StringComparisonExp>;
  to?: InputMaybe<StringArrayComparisonExp>;
  trackings?: InputMaybe<DbEmailTrackingBoolExp>;
  trackingsAggregate?: InputMaybe<DbEmailTrackingAggregateBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** columns and relationships of "db.email_callback_events" */
export type DbEmailCallbackEvents = {
  __typename?: 'DbEmailCallbackEvents';
  content?: Maybe<Scalars['jsonb']>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  emailCallbackEventSource: EnumsEmailCallbackEventSource;
  /** An object relationship */
  emailCallbackEventStatus: EnumsEventStatus;
  id: Scalars['String'];
  num: Scalars['bigint'];
  source: EmailCallbackEventSourceEnum;
  status: EventStatusEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** columns and relationships of "db.email_callback_events" */
export type DbEmailCallbackEventsContentArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "db.email_callback_events" */
export type DbEmailCallbackEventsAggregate = {
  __typename?: 'DbEmailCallbackEventsAggregate';
  aggregate?: Maybe<DbEmailCallbackEventsAggregateFields>;
  nodes: Array<DbEmailCallbackEvents>;
};

export type DbEmailCallbackEventsAggregateBoolExp = {
  bool_and?: InputMaybe<DbEmailCallbackEventsAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbEmailCallbackEventsAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbEmailCallbackEventsAggregateBoolExpCount>;
};

/** aggregate fields of "db.email_callback_events" */
export type DbEmailCallbackEventsAggregateFields = {
  __typename?: 'DbEmailCallbackEventsAggregateFields';
  avg?: Maybe<DbEmailCallbackEventsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbEmailCallbackEventsMaxFields>;
  min?: Maybe<DbEmailCallbackEventsMinFields>;
  stddev?: Maybe<DbEmailCallbackEventsStddevFields>;
  stddevPop?: Maybe<DbEmailCallbackEventsStddevPopFields>;
  stddevSamp?: Maybe<DbEmailCallbackEventsStddevSampFields>;
  sum?: Maybe<DbEmailCallbackEventsSumFields>;
  varPop?: Maybe<DbEmailCallbackEventsVarPopFields>;
  varSamp?: Maybe<DbEmailCallbackEventsVarSampFields>;
  variance?: Maybe<DbEmailCallbackEventsVarianceFields>;
};

/** aggregate fields of "db.email_callback_events" */
export type DbEmailCallbackEventsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbEmailCallbackEventsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.email_callback_events" */
export type DbEmailCallbackEventsAggregateOrderBy = {
  avg?: InputMaybe<DbEmailCallbackEventsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbEmailCallbackEventsMaxOrderBy>;
  min?: InputMaybe<DbEmailCallbackEventsMinOrderBy>;
  stddev?: InputMaybe<DbEmailCallbackEventsStddevOrderBy>;
  stddevPop?: InputMaybe<DbEmailCallbackEventsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbEmailCallbackEventsStddevSampOrderBy>;
  sum?: InputMaybe<DbEmailCallbackEventsSumOrderBy>;
  varPop?: InputMaybe<DbEmailCallbackEventsVarPopOrderBy>;
  varSamp?: InputMaybe<DbEmailCallbackEventsVarSampOrderBy>;
  variance?: InputMaybe<DbEmailCallbackEventsVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbEmailCallbackEventsAvgFields = {
  __typename?: 'DbEmailCallbackEventsAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.email_callback_events" */
export type DbEmailCallbackEventsAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.email_callback_events". All fields are combined with a logical 'AND'. */
export type DbEmailCallbackEventsBoolExp = {
  _and?: InputMaybe<Array<DbEmailCallbackEventsBoolExp>>;
  _not?: InputMaybe<DbEmailCallbackEventsBoolExp>;
  _or?: InputMaybe<Array<DbEmailCallbackEventsBoolExp>>;
  content?: InputMaybe<JsonbComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  emailCallbackEventSource?: InputMaybe<EnumsEmailCallbackEventSourceBoolExp>;
  emailCallbackEventStatus?: InputMaybe<EnumsEventStatusBoolExp>;
  id?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  source?: InputMaybe<EnumsEmailCallbackEventSourceEnumComparisonExp>;
  status?: InputMaybe<EnumsEventStatusEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbEmailCallbackEventsMaxFields = {
  __typename?: 'DbEmailCallbackEventsMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.email_callback_events" */
export type DbEmailCallbackEventsMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbEmailCallbackEventsMinFields = {
  __typename?: 'DbEmailCallbackEventsMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.email_callback_events" */
export type DbEmailCallbackEventsMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.email_callback_events". */
export type DbEmailCallbackEventsOrderBy = {
  content?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  emailCallbackEventSource?: InputMaybe<EnumsEmailCallbackEventSourceOrderBy>;
  emailCallbackEventStatus?: InputMaybe<EnumsEventStatusOrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  source?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.email_callback_events" */
export type DbEmailCallbackEventsSelectColumn =
  /** column name */
  | 'content'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'num'
  /** column name */
  | 'source'
  /** column name */
  | 'status'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbEmailCallbackEventsAggregateBoolExpBool_andArgumentsColumns" columns of table "db.email_callback_events" */
export type DbEmailCallbackEventsSelectColumnDbEmailCallbackEventsAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbEmailCallbackEventsAggregateBoolExpBool_orArgumentsColumns" columns of table "db.email_callback_events" */
export type DbEmailCallbackEventsSelectColumnDbEmailCallbackEventsAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbEmailCallbackEventsStddevFields = {
  __typename?: 'DbEmailCallbackEventsStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.email_callback_events" */
export type DbEmailCallbackEventsStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbEmailCallbackEventsStddevPopFields = {
  __typename?: 'DbEmailCallbackEventsStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.email_callback_events" */
export type DbEmailCallbackEventsStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbEmailCallbackEventsStddevSampFields = {
  __typename?: 'DbEmailCallbackEventsStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.email_callback_events" */
export type DbEmailCallbackEventsStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_email_callback_events" */
export type DbEmailCallbackEventsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbEmailCallbackEventsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbEmailCallbackEventsStreamCursorValueInput = {
  content?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  source?: InputMaybe<EmailCallbackEventSourceEnum>;
  status?: InputMaybe<EventStatusEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbEmailCallbackEventsSumFields = {
  __typename?: 'DbEmailCallbackEventsSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.email_callback_events" */
export type DbEmailCallbackEventsSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbEmailCallbackEventsVarPopFields = {
  __typename?: 'DbEmailCallbackEventsVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.email_callback_events" */
export type DbEmailCallbackEventsVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbEmailCallbackEventsVarSampFields = {
  __typename?: 'DbEmailCallbackEventsVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.email_callback_events" */
export type DbEmailCallbackEventsVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbEmailCallbackEventsVarianceFields = {
  __typename?: 'DbEmailCallbackEventsVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.email_callback_events" */
export type DbEmailCallbackEventsVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.email_link" */
export type DbEmailLink = {
  __typename?: 'DbEmailLink';
  /** An object relationship */
  contact: DbContact;
  contactId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  /** An object relationship */
  lead?: Maybe<DbLead>;
  leadId?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
  num: Scalars['bigint'];
  threadId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.email_link" */
export type DbEmailLinkAggregate = {
  __typename?: 'DbEmailLinkAggregate';
  aggregate?: Maybe<DbEmailLinkAggregateFields>;
  nodes: Array<DbEmailLink>;
};

export type DbEmailLinkAggregateBoolExp = {
  bool_and?: InputMaybe<DbEmailLinkAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbEmailLinkAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbEmailLinkAggregateBoolExpCount>;
};

/** aggregate fields of "db.email_link" */
export type DbEmailLinkAggregateFields = {
  __typename?: 'DbEmailLinkAggregateFields';
  avg?: Maybe<DbEmailLinkAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbEmailLinkMaxFields>;
  min?: Maybe<DbEmailLinkMinFields>;
  stddev?: Maybe<DbEmailLinkStddevFields>;
  stddevPop?: Maybe<DbEmailLinkStddevPopFields>;
  stddevSamp?: Maybe<DbEmailLinkStddevSampFields>;
  sum?: Maybe<DbEmailLinkSumFields>;
  varPop?: Maybe<DbEmailLinkVarPopFields>;
  varSamp?: Maybe<DbEmailLinkVarSampFields>;
  variance?: Maybe<DbEmailLinkVarianceFields>;
};

/** aggregate fields of "db.email_link" */
export type DbEmailLinkAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbEmailLinkSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.email_link" */
export type DbEmailLinkAggregateOrderBy = {
  avg?: InputMaybe<DbEmailLinkAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbEmailLinkMaxOrderBy>;
  min?: InputMaybe<DbEmailLinkMinOrderBy>;
  stddev?: InputMaybe<DbEmailLinkStddevOrderBy>;
  stddevPop?: InputMaybe<DbEmailLinkStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbEmailLinkStddevSampOrderBy>;
  sum?: InputMaybe<DbEmailLinkSumOrderBy>;
  varPop?: InputMaybe<DbEmailLinkVarPopOrderBy>;
  varSamp?: InputMaybe<DbEmailLinkVarSampOrderBy>;
  variance?: InputMaybe<DbEmailLinkVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbEmailLinkAvgFields = {
  __typename?: 'DbEmailLinkAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.email_link" */
export type DbEmailLinkAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.email_link". All fields are combined with a logical 'AND'. */
export type DbEmailLinkBoolExp = {
  _and?: InputMaybe<Array<DbEmailLinkBoolExp>>;
  _not?: InputMaybe<DbEmailLinkBoolExp>;
  _or?: InputMaybe<Array<DbEmailLinkBoolExp>>;
  contact?: InputMaybe<DbContactBoolExp>;
  contactId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lead?: InputMaybe<DbLeadBoolExp>;
  leadId?: InputMaybe<StringComparisonExp>;
  messageId?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  threadId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbEmailLinkMaxFields = {
  __typename?: 'DbEmailLinkMaxFields';
  contactId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  leadId?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  threadId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.email_link" */
export type DbEmailLinkMaxOrderBy = {
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  leadId?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  threadId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbEmailLinkMinFields = {
  __typename?: 'DbEmailLinkMinFields';
  contactId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  leadId?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  threadId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.email_link" */
export type DbEmailLinkMinOrderBy = {
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  leadId?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  threadId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.email_link". */
export type DbEmailLinkOrderBy = {
  contact?: InputMaybe<DbContactOrderBy>;
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lead?: InputMaybe<DbLeadOrderBy>;
  leadId?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  threadId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.email_link" */
export type DbEmailLinkSelectColumn =
  /** column name */
  | 'contactId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'leadId'
  /** column name */
  | 'messageId'
  /** column name */
  | 'num'
  /** column name */
  | 'threadId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbEmailLinkAggregateBoolExpBool_andArgumentsColumns" columns of table "db.email_link" */
export type DbEmailLinkSelectColumnDbEmailLinkAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbEmailLinkAggregateBoolExpBool_orArgumentsColumns" columns of table "db.email_link" */
export type DbEmailLinkSelectColumnDbEmailLinkAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbEmailLinkStddevFields = {
  __typename?: 'DbEmailLinkStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.email_link" */
export type DbEmailLinkStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbEmailLinkStddevPopFields = {
  __typename?: 'DbEmailLinkStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.email_link" */
export type DbEmailLinkStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbEmailLinkStddevSampFields = {
  __typename?: 'DbEmailLinkStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.email_link" */
export type DbEmailLinkStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_email_link" */
export type DbEmailLinkStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbEmailLinkStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbEmailLinkStreamCursorValueInput = {
  contactId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  leadId?: InputMaybe<Scalars['String']>;
  messageId?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  threadId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbEmailLinkSumFields = {
  __typename?: 'DbEmailLinkSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.email_link" */
export type DbEmailLinkSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbEmailLinkVarPopFields = {
  __typename?: 'DbEmailLinkVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.email_link" */
export type DbEmailLinkVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbEmailLinkVarSampFields = {
  __typename?: 'DbEmailLinkVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.email_link" */
export type DbEmailLinkVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbEmailLinkVarianceFields = {
  __typename?: 'DbEmailLinkVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.email_link" */
export type DbEmailLinkVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.email_message_map" */
export type DbEmailMessageMap = {
  __typename?: 'DbEmailMessageMap';
  emailId?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "db.email_message_map". All fields are combined with a logical 'AND'. */
export type DbEmailMessageMapBoolExp = {
  _and?: InputMaybe<Array<DbEmailMessageMapBoolExp>>;
  _not?: InputMaybe<DbEmailMessageMapBoolExp>;
  _or?: InputMaybe<Array<DbEmailMessageMapBoolExp>>;
  emailId?: InputMaybe<StringComparisonExp>;
  messageId?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "db.email_message_map". */
export type DbEmailMessageMapOrderBy = {
  emailId?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
};

/** select columns of table "db.email_message_map" */
export type DbEmailMessageMapSelectColumn =
  /** column name */
  | 'emailId'
  /** column name */
  | 'messageId';

/** Streaming cursor of the table "db_email_message_map" */
export type DbEmailMessageMapStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbEmailMessageMapStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbEmailMessageMapStreamCursorValueInput = {
  emailId?: InputMaybe<Scalars['String']>;
  messageId?: InputMaybe<Scalars['String']>;
};

/** Ordering options when selecting data from "db.email". */
export type DbEmailOrderBy = {
  attachmentsAggregate?: InputMaybe<DbEmailAttachmentAggregateOrderBy>;
  bcc?: InputMaybe<OrderBy>;
  body?: InputMaybe<OrderBy>;
  cc?: InputMaybe<OrderBy>;
  consentsAggregate?: InputMaybe<DbConsentAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  date?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  direction?: InputMaybe<OrderBy>;
  documentReferencesAggregate?: InputMaybe<DbDocumentReferenceAggregateOrderBy>;
  emailActivitiesAggregate?: InputMaybe<DbEmailActivityAggregateOrderBy>;
  emailDirection?: InputMaybe<EnumsEmailDirectionOrderBy>;
  emailStatus?: InputMaybe<EnumsEmailStatusOrderBy>;
  failureReason?: InputMaybe<OrderBy>;
  from?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  messageId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  nylasIntegrationFrom?: InputMaybe<DbNylasEmailIntegrationOrderBy>;
  nylasIntegrationTo?: InputMaybe<DbNylasEmailIntegrationOrderBy>;
  priority?: InputMaybe<OrderBy>;
  priorityByPriority?: InputMaybe<EnumsPriorityOrderBy>;
  propertyIds?: InputMaybe<OrderBy>;
  retryCount?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  subject?: InputMaybe<OrderBy>;
  threadId?: InputMaybe<OrderBy>;
  to?: InputMaybe<OrderBy>;
  trackingsAggregate?: InputMaybe<DbEmailTrackingAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.email_placeholder" */
export type DbEmailPlaceholder = {
  __typename?: 'DbEmailPlaceholder';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  emailPlaceholderScope: EnumsEmailPlaceholderScope;
  /** An object relationship */
  emailPlaceholderType: EnumsEmailPlaceholderType;
  id: Scalars['String'];
  mapping: Scalars['String'];
  name: Scalars['String'];
  num: Scalars['bigint'];
  scope: EmailPlaceholderScopeEnum;
  type: EmailPlaceholderTypeEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.email_placeholder" */
export type DbEmailPlaceholderAggregate = {
  __typename?: 'DbEmailPlaceholderAggregate';
  aggregate?: Maybe<DbEmailPlaceholderAggregateFields>;
  nodes: Array<DbEmailPlaceholder>;
};

export type DbEmailPlaceholderAggregateBoolExp = {
  bool_and?: InputMaybe<DbEmailPlaceholderAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbEmailPlaceholderAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbEmailPlaceholderAggregateBoolExpCount>;
};

/** aggregate fields of "db.email_placeholder" */
export type DbEmailPlaceholderAggregateFields = {
  __typename?: 'DbEmailPlaceholderAggregateFields';
  avg?: Maybe<DbEmailPlaceholderAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbEmailPlaceholderMaxFields>;
  min?: Maybe<DbEmailPlaceholderMinFields>;
  stddev?: Maybe<DbEmailPlaceholderStddevFields>;
  stddevPop?: Maybe<DbEmailPlaceholderStddevPopFields>;
  stddevSamp?: Maybe<DbEmailPlaceholderStddevSampFields>;
  sum?: Maybe<DbEmailPlaceholderSumFields>;
  varPop?: Maybe<DbEmailPlaceholderVarPopFields>;
  varSamp?: Maybe<DbEmailPlaceholderVarSampFields>;
  variance?: Maybe<DbEmailPlaceholderVarianceFields>;
};

/** aggregate fields of "db.email_placeholder" */
export type DbEmailPlaceholderAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbEmailPlaceholderSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.email_placeholder" */
export type DbEmailPlaceholderAggregateOrderBy = {
  avg?: InputMaybe<DbEmailPlaceholderAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbEmailPlaceholderMaxOrderBy>;
  min?: InputMaybe<DbEmailPlaceholderMinOrderBy>;
  stddev?: InputMaybe<DbEmailPlaceholderStddevOrderBy>;
  stddevPop?: InputMaybe<DbEmailPlaceholderStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbEmailPlaceholderStddevSampOrderBy>;
  sum?: InputMaybe<DbEmailPlaceholderSumOrderBy>;
  varPop?: InputMaybe<DbEmailPlaceholderVarPopOrderBy>;
  varSamp?: InputMaybe<DbEmailPlaceholderVarSampOrderBy>;
  variance?: InputMaybe<DbEmailPlaceholderVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbEmailPlaceholderAvgFields = {
  __typename?: 'DbEmailPlaceholderAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.email_placeholder" */
export type DbEmailPlaceholderAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.email_placeholder". All fields are combined with a logical 'AND'. */
export type DbEmailPlaceholderBoolExp = {
  _and?: InputMaybe<Array<DbEmailPlaceholderBoolExp>>;
  _not?: InputMaybe<DbEmailPlaceholderBoolExp>;
  _or?: InputMaybe<Array<DbEmailPlaceholderBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  emailPlaceholderScope?: InputMaybe<EnumsEmailPlaceholderScopeBoolExp>;
  emailPlaceholderType?: InputMaybe<EnumsEmailPlaceholderTypeBoolExp>;
  id?: InputMaybe<StringComparisonExp>;
  mapping?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  scope?: InputMaybe<EnumsEmailPlaceholderScopeEnumComparisonExp>;
  type?: InputMaybe<EnumsEmailPlaceholderTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbEmailPlaceholderMaxFields = {
  __typename?: 'DbEmailPlaceholderMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  mapping?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.email_placeholder" */
export type DbEmailPlaceholderMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  mapping?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbEmailPlaceholderMinFields = {
  __typename?: 'DbEmailPlaceholderMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  mapping?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.email_placeholder" */
export type DbEmailPlaceholderMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  mapping?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.email_placeholder". */
export type DbEmailPlaceholderOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  emailPlaceholderScope?: InputMaybe<EnumsEmailPlaceholderScopeOrderBy>;
  emailPlaceholderType?: InputMaybe<EnumsEmailPlaceholderTypeOrderBy>;
  id?: InputMaybe<OrderBy>;
  mapping?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  scope?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.email_placeholder" */
export type DbEmailPlaceholderSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'mapping'
  /** column name */
  | 'name'
  /** column name */
  | 'num'
  /** column name */
  | 'scope'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbEmailPlaceholderAggregateBoolExpBool_andArgumentsColumns" columns of table "db.email_placeholder" */
export type DbEmailPlaceholderSelectColumnDbEmailPlaceholderAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbEmailPlaceholderAggregateBoolExpBool_orArgumentsColumns" columns of table "db.email_placeholder" */
export type DbEmailPlaceholderSelectColumnDbEmailPlaceholderAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbEmailPlaceholderStddevFields = {
  __typename?: 'DbEmailPlaceholderStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.email_placeholder" */
export type DbEmailPlaceholderStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbEmailPlaceholderStddevPopFields = {
  __typename?: 'DbEmailPlaceholderStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.email_placeholder" */
export type DbEmailPlaceholderStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbEmailPlaceholderStddevSampFields = {
  __typename?: 'DbEmailPlaceholderStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.email_placeholder" */
export type DbEmailPlaceholderStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_email_placeholder" */
export type DbEmailPlaceholderStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbEmailPlaceholderStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbEmailPlaceholderStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  mapping?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  scope?: InputMaybe<EmailPlaceholderScopeEnum>;
  type?: InputMaybe<EmailPlaceholderTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbEmailPlaceholderSumFields = {
  __typename?: 'DbEmailPlaceholderSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.email_placeholder" */
export type DbEmailPlaceholderSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbEmailPlaceholderVarPopFields = {
  __typename?: 'DbEmailPlaceholderVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.email_placeholder" */
export type DbEmailPlaceholderVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbEmailPlaceholderVarSampFields = {
  __typename?: 'DbEmailPlaceholderVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.email_placeholder" */
export type DbEmailPlaceholderVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbEmailPlaceholderVarianceFields = {
  __typename?: 'DbEmailPlaceholderVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.email_placeholder" */
export type DbEmailPlaceholderVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.email_signature" */
export type DbEmailSignature = {
  __typename?: 'DbEmailSignature';
  /** An object relationship */
  agent?: Maybe<DbAgent>;
  /** An array relationship */
  agentDefaults: Array<DbAgentDefaultEmailSignature>;
  /** An aggregate relationship */
  agentDefaultsAggregate: DbAgentDefaultEmailSignatureAggregate;
  agentId?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  emailSignatureScope: EnumsEmailSignatureScope;
  id: Scalars['String'];
  name: Scalars['String'];
  num: Scalars['bigint'];
  scope: EmailSignatureScopeEnum;
  /** An object relationship */
  shop?: Maybe<DbShop>;
  shopId?: Maybe<Scalars['String']>;
  signature: Scalars['String'];
  /** An object relationship */
  team?: Maybe<DbTeam>;
  teamId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** columns and relationships of "db.email_signature" */
export type DbEmailSignatureAgentDefaultsArgs = {
  distinctOn?: InputMaybe<Array<DbAgentDefaultEmailSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentDefaultEmailSignatureOrderBy>>;
  where?: InputMaybe<DbAgentDefaultEmailSignatureBoolExp>;
};

/** columns and relationships of "db.email_signature" */
export type DbEmailSignatureAgentDefaultsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentDefaultEmailSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentDefaultEmailSignatureOrderBy>>;
  where?: InputMaybe<DbAgentDefaultEmailSignatureBoolExp>;
};

/** aggregated selection of "db.email_signature" */
export type DbEmailSignatureAggregate = {
  __typename?: 'DbEmailSignatureAggregate';
  aggregate?: Maybe<DbEmailSignatureAggregateFields>;
  nodes: Array<DbEmailSignature>;
};

export type DbEmailSignatureAggregateBoolExp = {
  bool_and?: InputMaybe<DbEmailSignatureAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbEmailSignatureAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbEmailSignatureAggregateBoolExpCount>;
};

/** aggregate fields of "db.email_signature" */
export type DbEmailSignatureAggregateFields = {
  __typename?: 'DbEmailSignatureAggregateFields';
  avg?: Maybe<DbEmailSignatureAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbEmailSignatureMaxFields>;
  min?: Maybe<DbEmailSignatureMinFields>;
  stddev?: Maybe<DbEmailSignatureStddevFields>;
  stddevPop?: Maybe<DbEmailSignatureStddevPopFields>;
  stddevSamp?: Maybe<DbEmailSignatureStddevSampFields>;
  sum?: Maybe<DbEmailSignatureSumFields>;
  varPop?: Maybe<DbEmailSignatureVarPopFields>;
  varSamp?: Maybe<DbEmailSignatureVarSampFields>;
  variance?: Maybe<DbEmailSignatureVarianceFields>;
};

/** aggregate fields of "db.email_signature" */
export type DbEmailSignatureAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbEmailSignatureSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.email_signature" */
export type DbEmailSignatureAggregateOrderBy = {
  avg?: InputMaybe<DbEmailSignatureAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbEmailSignatureMaxOrderBy>;
  min?: InputMaybe<DbEmailSignatureMinOrderBy>;
  stddev?: InputMaybe<DbEmailSignatureStddevOrderBy>;
  stddevPop?: InputMaybe<DbEmailSignatureStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbEmailSignatureStddevSampOrderBy>;
  sum?: InputMaybe<DbEmailSignatureSumOrderBy>;
  varPop?: InputMaybe<DbEmailSignatureVarPopOrderBy>;
  varSamp?: InputMaybe<DbEmailSignatureVarSampOrderBy>;
  variance?: InputMaybe<DbEmailSignatureVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbEmailSignatureAvgFields = {
  __typename?: 'DbEmailSignatureAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.email_signature" */
export type DbEmailSignatureAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.email_signature". All fields are combined with a logical 'AND'. */
export type DbEmailSignatureBoolExp = {
  _and?: InputMaybe<Array<DbEmailSignatureBoolExp>>;
  _not?: InputMaybe<DbEmailSignatureBoolExp>;
  _or?: InputMaybe<Array<DbEmailSignatureBoolExp>>;
  agent?: InputMaybe<DbAgentBoolExp>;
  agentDefaults?: InputMaybe<DbAgentDefaultEmailSignatureBoolExp>;
  agentDefaultsAggregate?: InputMaybe<DbAgentDefaultEmailSignatureAggregateBoolExp>;
  agentId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  emailSignatureScope?: InputMaybe<EnumsEmailSignatureScopeBoolExp>;
  id?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  scope?: InputMaybe<EnumsEmailSignatureScopeEnumComparisonExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  signature?: InputMaybe<StringComparisonExp>;
  team?: InputMaybe<DbTeamBoolExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbEmailSignatureMaxFields = {
  __typename?: 'DbEmailSignatureMaxFields';
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  shopId?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.email_signature" */
export type DbEmailSignatureMaxOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  signature?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbEmailSignatureMinFields = {
  __typename?: 'DbEmailSignatureMinFields';
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  shopId?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.email_signature" */
export type DbEmailSignatureMinOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  signature?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.email_signature". */
export type DbEmailSignatureOrderBy = {
  agent?: InputMaybe<DbAgentOrderBy>;
  agentDefaultsAggregate?: InputMaybe<DbAgentDefaultEmailSignatureAggregateOrderBy>;
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  emailSignatureScope?: InputMaybe<EnumsEmailSignatureScopeOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  scope?: InputMaybe<OrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  signature?: InputMaybe<OrderBy>;
  team?: InputMaybe<DbTeamOrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.email_signature" */
export type DbEmailSignatureSelectColumn =
  /** column name */
  | 'agentId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'num'
  /** column name */
  | 'scope'
  /** column name */
  | 'shopId'
  /** column name */
  | 'signature'
  /** column name */
  | 'teamId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbEmailSignatureAggregateBoolExpBool_andArgumentsColumns" columns of table "db.email_signature" */
export type DbEmailSignatureSelectColumnDbEmailSignatureAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbEmailSignatureAggregateBoolExpBool_orArgumentsColumns" columns of table "db.email_signature" */
export type DbEmailSignatureSelectColumnDbEmailSignatureAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbEmailSignatureStddevFields = {
  __typename?: 'DbEmailSignatureStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.email_signature" */
export type DbEmailSignatureStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbEmailSignatureStddevPopFields = {
  __typename?: 'DbEmailSignatureStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.email_signature" */
export type DbEmailSignatureStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbEmailSignatureStddevSampFields = {
  __typename?: 'DbEmailSignatureStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.email_signature" */
export type DbEmailSignatureStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_email_signature" */
export type DbEmailSignatureStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbEmailSignatureStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbEmailSignatureStreamCursorValueInput = {
  agentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  scope?: InputMaybe<EmailSignatureScopeEnum>;
  shopId?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbEmailSignatureSumFields = {
  __typename?: 'DbEmailSignatureSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.email_signature" */
export type DbEmailSignatureSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbEmailSignatureVarPopFields = {
  __typename?: 'DbEmailSignatureVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.email_signature" */
export type DbEmailSignatureVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbEmailSignatureVarSampFields = {
  __typename?: 'DbEmailSignatureVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.email_signature" */
export type DbEmailSignatureVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbEmailSignatureVarianceFields = {
  __typename?: 'DbEmailSignatureVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.email_signature" */
export type DbEmailSignatureVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.email_template" */
export type DbEmailTemplate = {
  __typename?: 'DbEmailTemplate';
  /** An object relationship */
  agent?: Maybe<DbAgent>;
  agentId?: Maybe<Scalars['String']>;
  /** An array relationship */
  contents: Array<DbEmailTemplateContent>;
  /** An aggregate relationship */
  contentsAggregate: DbEmailTemplateContentAggregate;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  emailTemplateScope: EnumsEmailTemplateScope;
  /** An object relationship */
  emailTemplateType: EnumsEmailTemplateType;
  id: Scalars['String'];
  name: Scalars['String'];
  num: Scalars['bigint'];
  scope: EmailTemplateScopeEnum;
  /** An object relationship */
  shop?: Maybe<DbShop>;
  shopId?: Maybe<Scalars['String']>;
  /** An object relationship */
  team?: Maybe<DbTeam>;
  teamId?: Maybe<Scalars['String']>;
  type: EmailTemplateTypeEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** columns and relationships of "db.email_template" */
export type DbEmailTemplateContentsArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTemplateContentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTemplateContentOrderBy>>;
  where?: InputMaybe<DbEmailTemplateContentBoolExp>;
};

/** columns and relationships of "db.email_template" */
export type DbEmailTemplateContentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTemplateContentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTemplateContentOrderBy>>;
  where?: InputMaybe<DbEmailTemplateContentBoolExp>;
};

/** aggregated selection of "db.email_template" */
export type DbEmailTemplateAggregate = {
  __typename?: 'DbEmailTemplateAggregate';
  aggregate?: Maybe<DbEmailTemplateAggregateFields>;
  nodes: Array<DbEmailTemplate>;
};

export type DbEmailTemplateAggregateBoolExp = {
  bool_and?: InputMaybe<DbEmailTemplateAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbEmailTemplateAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbEmailTemplateAggregateBoolExpCount>;
};

/** aggregate fields of "db.email_template" */
export type DbEmailTemplateAggregateFields = {
  __typename?: 'DbEmailTemplateAggregateFields';
  avg?: Maybe<DbEmailTemplateAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbEmailTemplateMaxFields>;
  min?: Maybe<DbEmailTemplateMinFields>;
  stddev?: Maybe<DbEmailTemplateStddevFields>;
  stddevPop?: Maybe<DbEmailTemplateStddevPopFields>;
  stddevSamp?: Maybe<DbEmailTemplateStddevSampFields>;
  sum?: Maybe<DbEmailTemplateSumFields>;
  varPop?: Maybe<DbEmailTemplateVarPopFields>;
  varSamp?: Maybe<DbEmailTemplateVarSampFields>;
  variance?: Maybe<DbEmailTemplateVarianceFields>;
};

/** aggregate fields of "db.email_template" */
export type DbEmailTemplateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbEmailTemplateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.email_template" */
export type DbEmailTemplateAggregateOrderBy = {
  avg?: InputMaybe<DbEmailTemplateAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbEmailTemplateMaxOrderBy>;
  min?: InputMaybe<DbEmailTemplateMinOrderBy>;
  stddev?: InputMaybe<DbEmailTemplateStddevOrderBy>;
  stddevPop?: InputMaybe<DbEmailTemplateStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbEmailTemplateStddevSampOrderBy>;
  sum?: InputMaybe<DbEmailTemplateSumOrderBy>;
  varPop?: InputMaybe<DbEmailTemplateVarPopOrderBy>;
  varSamp?: InputMaybe<DbEmailTemplateVarSampOrderBy>;
  variance?: InputMaybe<DbEmailTemplateVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbEmailTemplateAvgFields = {
  __typename?: 'DbEmailTemplateAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.email_template" */
export type DbEmailTemplateAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.email_template". All fields are combined with a logical 'AND'. */
export type DbEmailTemplateBoolExp = {
  _and?: InputMaybe<Array<DbEmailTemplateBoolExp>>;
  _not?: InputMaybe<DbEmailTemplateBoolExp>;
  _or?: InputMaybe<Array<DbEmailTemplateBoolExp>>;
  agent?: InputMaybe<DbAgentBoolExp>;
  agentId?: InputMaybe<StringComparisonExp>;
  contents?: InputMaybe<DbEmailTemplateContentBoolExp>;
  contentsAggregate?: InputMaybe<DbEmailTemplateContentAggregateBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  emailTemplateScope?: InputMaybe<EnumsEmailTemplateScopeBoolExp>;
  emailTemplateType?: InputMaybe<EnumsEmailTemplateTypeBoolExp>;
  id?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  scope?: InputMaybe<EnumsEmailTemplateScopeEnumComparisonExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  team?: InputMaybe<DbTeamBoolExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<EnumsEmailTemplateTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** columns and relationships of "db.email_template_content" */
export type DbEmailTemplateContent = {
  __typename?: 'DbEmailTemplateContent';
  body: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  emailTemplate: DbEmailTemplate;
  id: Scalars['String'];
  language: LanguageEnum;
  /** An object relationship */
  languageByLanguage: EnumsLanguage;
  lck: Scalars['Int'];
  num: Scalars['bigint'];
  subject: Scalars['String'];
  templateId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.email_template_content" */
export type DbEmailTemplateContentAggregate = {
  __typename?: 'DbEmailTemplateContentAggregate';
  aggregate?: Maybe<DbEmailTemplateContentAggregateFields>;
  nodes: Array<DbEmailTemplateContent>;
};

export type DbEmailTemplateContentAggregateBoolExp = {
  bool_and?: InputMaybe<DbEmailTemplateContentAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbEmailTemplateContentAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbEmailTemplateContentAggregateBoolExpCount>;
};

/** aggregate fields of "db.email_template_content" */
export type DbEmailTemplateContentAggregateFields = {
  __typename?: 'DbEmailTemplateContentAggregateFields';
  avg?: Maybe<DbEmailTemplateContentAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbEmailTemplateContentMaxFields>;
  min?: Maybe<DbEmailTemplateContentMinFields>;
  stddev?: Maybe<DbEmailTemplateContentStddevFields>;
  stddevPop?: Maybe<DbEmailTemplateContentStddevPopFields>;
  stddevSamp?: Maybe<DbEmailTemplateContentStddevSampFields>;
  sum?: Maybe<DbEmailTemplateContentSumFields>;
  varPop?: Maybe<DbEmailTemplateContentVarPopFields>;
  varSamp?: Maybe<DbEmailTemplateContentVarSampFields>;
  variance?: Maybe<DbEmailTemplateContentVarianceFields>;
};

/** aggregate fields of "db.email_template_content" */
export type DbEmailTemplateContentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbEmailTemplateContentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.email_template_content" */
export type DbEmailTemplateContentAggregateOrderBy = {
  avg?: InputMaybe<DbEmailTemplateContentAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbEmailTemplateContentMaxOrderBy>;
  min?: InputMaybe<DbEmailTemplateContentMinOrderBy>;
  stddev?: InputMaybe<DbEmailTemplateContentStddevOrderBy>;
  stddevPop?: InputMaybe<DbEmailTemplateContentStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbEmailTemplateContentStddevSampOrderBy>;
  sum?: InputMaybe<DbEmailTemplateContentSumOrderBy>;
  varPop?: InputMaybe<DbEmailTemplateContentVarPopOrderBy>;
  varSamp?: InputMaybe<DbEmailTemplateContentVarSampOrderBy>;
  variance?: InputMaybe<DbEmailTemplateContentVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbEmailTemplateContentAvgFields = {
  __typename?: 'DbEmailTemplateContentAvgFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.email_template_content" */
export type DbEmailTemplateContentAvgOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.email_template_content". All fields are combined with a logical 'AND'. */
export type DbEmailTemplateContentBoolExp = {
  _and?: InputMaybe<Array<DbEmailTemplateContentBoolExp>>;
  _not?: InputMaybe<DbEmailTemplateContentBoolExp>;
  _or?: InputMaybe<Array<DbEmailTemplateContentBoolExp>>;
  body?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  emailTemplate?: InputMaybe<DbEmailTemplateBoolExp>;
  id?: InputMaybe<StringComparisonExp>;
  language?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  languageByLanguage?: InputMaybe<EnumsLanguageBoolExp>;
  lck?: InputMaybe<IntComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  subject?: InputMaybe<StringComparisonExp>;
  templateId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbEmailTemplateContentMaxFields = {
  __typename?: 'DbEmailTemplateContentMaxFields';
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lck?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['bigint']>;
  subject?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.email_template_content" */
export type DbEmailTemplateContentMaxOrderBy = {
  body?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  subject?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbEmailTemplateContentMinFields = {
  __typename?: 'DbEmailTemplateContentMinFields';
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lck?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['bigint']>;
  subject?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.email_template_content" */
export type DbEmailTemplateContentMinOrderBy = {
  body?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  subject?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.email_template_content". */
export type DbEmailTemplateContentOrderBy = {
  body?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  emailTemplate?: InputMaybe<DbEmailTemplateOrderBy>;
  id?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  languageByLanguage?: InputMaybe<EnumsLanguageOrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  subject?: InputMaybe<OrderBy>;
  templateId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.email_template_content" */
export type DbEmailTemplateContentSelectColumn =
  /** column name */
  | 'body'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'language'
  /** column name */
  | 'lck'
  /** column name */
  | 'num'
  /** column name */
  | 'subject'
  /** column name */
  | 'templateId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbEmailTemplateContentAggregateBoolExpBool_andArgumentsColumns" columns of table "db.email_template_content" */
export type DbEmailTemplateContentSelectColumnDbEmailTemplateContentAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbEmailTemplateContentAggregateBoolExpBool_orArgumentsColumns" columns of table "db.email_template_content" */
export type DbEmailTemplateContentSelectColumnDbEmailTemplateContentAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbEmailTemplateContentStddevFields = {
  __typename?: 'DbEmailTemplateContentStddevFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.email_template_content" */
export type DbEmailTemplateContentStddevOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbEmailTemplateContentStddevPopFields = {
  __typename?: 'DbEmailTemplateContentStddevPopFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.email_template_content" */
export type DbEmailTemplateContentStddevPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbEmailTemplateContentStddevSampFields = {
  __typename?: 'DbEmailTemplateContentStddevSampFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.email_template_content" */
export type DbEmailTemplateContentStddevSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_email_template_content" */
export type DbEmailTemplateContentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbEmailTemplateContentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbEmailTemplateContentStreamCursorValueInput = {
  body?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageEnum>;
  lck?: InputMaybe<Scalars['Int']>;
  num?: InputMaybe<Scalars['bigint']>;
  subject?: InputMaybe<Scalars['String']>;
  templateId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbEmailTemplateContentSumFields = {
  __typename?: 'DbEmailTemplateContentSumFields';
  lck?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.email_template_content" */
export type DbEmailTemplateContentSumOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbEmailTemplateContentVarPopFields = {
  __typename?: 'DbEmailTemplateContentVarPopFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.email_template_content" */
export type DbEmailTemplateContentVarPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbEmailTemplateContentVarSampFields = {
  __typename?: 'DbEmailTemplateContentVarSampFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.email_template_content" */
export type DbEmailTemplateContentVarSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbEmailTemplateContentVarianceFields = {
  __typename?: 'DbEmailTemplateContentVarianceFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.email_template_content" */
export type DbEmailTemplateContentVarianceOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate max on columns */
export type DbEmailTemplateMaxFields = {
  __typename?: 'DbEmailTemplateMaxFields';
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  shopId?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.email_template" */
export type DbEmailTemplateMaxOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbEmailTemplateMinFields = {
  __typename?: 'DbEmailTemplateMinFields';
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  shopId?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.email_template" */
export type DbEmailTemplateMinOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.email_template". */
export type DbEmailTemplateOrderBy = {
  agent?: InputMaybe<DbAgentOrderBy>;
  agentId?: InputMaybe<OrderBy>;
  contentsAggregate?: InputMaybe<DbEmailTemplateContentAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  emailTemplateScope?: InputMaybe<EnumsEmailTemplateScopeOrderBy>;
  emailTemplateType?: InputMaybe<EnumsEmailTemplateTypeOrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  scope?: InputMaybe<OrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  team?: InputMaybe<DbTeamOrderBy>;
  teamId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.email_template" */
export type DbEmailTemplateSelectColumn =
  /** column name */
  | 'agentId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'num'
  /** column name */
  | 'scope'
  /** column name */
  | 'shopId'
  /** column name */
  | 'teamId'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbEmailTemplateAggregateBoolExpBool_andArgumentsColumns" columns of table "db.email_template" */
export type DbEmailTemplateSelectColumnDbEmailTemplateAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbEmailTemplateAggregateBoolExpBool_orArgumentsColumns" columns of table "db.email_template" */
export type DbEmailTemplateSelectColumnDbEmailTemplateAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbEmailTemplateStddevFields = {
  __typename?: 'DbEmailTemplateStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.email_template" */
export type DbEmailTemplateStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbEmailTemplateStddevPopFields = {
  __typename?: 'DbEmailTemplateStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.email_template" */
export type DbEmailTemplateStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbEmailTemplateStddevSampFields = {
  __typename?: 'DbEmailTemplateStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.email_template" */
export type DbEmailTemplateStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_email_template" */
export type DbEmailTemplateStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbEmailTemplateStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbEmailTemplateStreamCursorValueInput = {
  agentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  scope?: InputMaybe<EmailTemplateScopeEnum>;
  shopId?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EmailTemplateTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbEmailTemplateSumFields = {
  __typename?: 'DbEmailTemplateSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.email_template" */
export type DbEmailTemplateSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbEmailTemplateVarPopFields = {
  __typename?: 'DbEmailTemplateVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.email_template" */
export type DbEmailTemplateVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbEmailTemplateVarSampFields = {
  __typename?: 'DbEmailTemplateVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.email_template" */
export type DbEmailTemplateVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbEmailTemplateVarianceFields = {
  __typename?: 'DbEmailTemplateVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.email_template" */
export type DbEmailTemplateVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.email_tracking" */
export type DbEmailTracking = {
  __typename?: 'DbEmailTracking';
  count: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  email?: Maybe<DbEmailMessageMap>;
  emailId: Scalars['String'];
  /** An object relationship */
  emailTrackingType: EnumsEmailTrackingType;
  failureReason?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  linkUrl?: Maybe<Scalars['String']>;
  num: Scalars['bigint'];
  processedAt: Scalars['timestamp'];
  recipient?: Maybe<Scalars['String']>;
  type: EmailTrackingTypeEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.email_tracking" */
export type DbEmailTrackingAggregate = {
  __typename?: 'DbEmailTrackingAggregate';
  aggregate?: Maybe<DbEmailTrackingAggregateFields>;
  nodes: Array<DbEmailTracking>;
};

export type DbEmailTrackingAggregateBoolExp = {
  bool_and?: InputMaybe<DbEmailTrackingAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbEmailTrackingAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbEmailTrackingAggregateBoolExpCount>;
};

/** aggregate fields of "db.email_tracking" */
export type DbEmailTrackingAggregateFields = {
  __typename?: 'DbEmailTrackingAggregateFields';
  avg?: Maybe<DbEmailTrackingAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbEmailTrackingMaxFields>;
  min?: Maybe<DbEmailTrackingMinFields>;
  stddev?: Maybe<DbEmailTrackingStddevFields>;
  stddevPop?: Maybe<DbEmailTrackingStddevPopFields>;
  stddevSamp?: Maybe<DbEmailTrackingStddevSampFields>;
  sum?: Maybe<DbEmailTrackingSumFields>;
  varPop?: Maybe<DbEmailTrackingVarPopFields>;
  varSamp?: Maybe<DbEmailTrackingVarSampFields>;
  variance?: Maybe<DbEmailTrackingVarianceFields>;
};

/** aggregate fields of "db.email_tracking" */
export type DbEmailTrackingAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbEmailTrackingSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.email_tracking" */
export type DbEmailTrackingAggregateOrderBy = {
  avg?: InputMaybe<DbEmailTrackingAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbEmailTrackingMaxOrderBy>;
  min?: InputMaybe<DbEmailTrackingMinOrderBy>;
  stddev?: InputMaybe<DbEmailTrackingStddevOrderBy>;
  stddevPop?: InputMaybe<DbEmailTrackingStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbEmailTrackingStddevSampOrderBy>;
  sum?: InputMaybe<DbEmailTrackingSumOrderBy>;
  varPop?: InputMaybe<DbEmailTrackingVarPopOrderBy>;
  varSamp?: InputMaybe<DbEmailTrackingVarSampOrderBy>;
  variance?: InputMaybe<DbEmailTrackingVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbEmailTrackingAvgFields = {
  __typename?: 'DbEmailTrackingAvgFields';
  count?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.email_tracking" */
export type DbEmailTrackingAvgOrderBy = {
  count?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.email_tracking". All fields are combined with a logical 'AND'. */
export type DbEmailTrackingBoolExp = {
  _and?: InputMaybe<Array<DbEmailTrackingBoolExp>>;
  _not?: InputMaybe<DbEmailTrackingBoolExp>;
  _or?: InputMaybe<Array<DbEmailTrackingBoolExp>>;
  count?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  email?: InputMaybe<DbEmailMessageMapBoolExp>;
  emailId?: InputMaybe<StringComparisonExp>;
  emailTrackingType?: InputMaybe<EnumsEmailTrackingTypeBoolExp>;
  failureReason?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  linkUrl?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  processedAt?: InputMaybe<TimestampComparisonExp>;
  recipient?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<EnumsEmailTrackingTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbEmailTrackingMaxFields = {
  __typename?: 'DbEmailTrackingMaxFields';
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  emailId?: Maybe<Scalars['String']>;
  failureReason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  linkUrl?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  processedAt?: Maybe<Scalars['timestamp']>;
  recipient?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.email_tracking" */
export type DbEmailTrackingMaxOrderBy = {
  count?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  emailId?: InputMaybe<OrderBy>;
  failureReason?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  linkUrl?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  processedAt?: InputMaybe<OrderBy>;
  recipient?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbEmailTrackingMinFields = {
  __typename?: 'DbEmailTrackingMinFields';
  count?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  emailId?: Maybe<Scalars['String']>;
  failureReason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  linkUrl?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  processedAt?: Maybe<Scalars['timestamp']>;
  recipient?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.email_tracking" */
export type DbEmailTrackingMinOrderBy = {
  count?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  emailId?: InputMaybe<OrderBy>;
  failureReason?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  linkUrl?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  processedAt?: InputMaybe<OrderBy>;
  recipient?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.email_tracking". */
export type DbEmailTrackingOrderBy = {
  count?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<DbEmailMessageMapOrderBy>;
  emailId?: InputMaybe<OrderBy>;
  emailTrackingType?: InputMaybe<EnumsEmailTrackingTypeOrderBy>;
  failureReason?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  linkUrl?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  processedAt?: InputMaybe<OrderBy>;
  recipient?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.email_tracking" */
export type DbEmailTrackingSelectColumn =
  /** column name */
  | 'count'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'emailId'
  /** column name */
  | 'failureReason'
  /** column name */
  | 'id'
  /** column name */
  | 'linkUrl'
  /** column name */
  | 'num'
  /** column name */
  | 'processedAt'
  /** column name */
  | 'recipient'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbEmailTrackingAggregateBoolExpBool_andArgumentsColumns" columns of table "db.email_tracking" */
export type DbEmailTrackingSelectColumnDbEmailTrackingAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbEmailTrackingAggregateBoolExpBool_orArgumentsColumns" columns of table "db.email_tracking" */
export type DbEmailTrackingSelectColumnDbEmailTrackingAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbEmailTrackingStddevFields = {
  __typename?: 'DbEmailTrackingStddevFields';
  count?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.email_tracking" */
export type DbEmailTrackingStddevOrderBy = {
  count?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbEmailTrackingStddevPopFields = {
  __typename?: 'DbEmailTrackingStddevPopFields';
  count?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.email_tracking" */
export type DbEmailTrackingStddevPopOrderBy = {
  count?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbEmailTrackingStddevSampFields = {
  __typename?: 'DbEmailTrackingStddevSampFields';
  count?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.email_tracking" */
export type DbEmailTrackingStddevSampOrderBy = {
  count?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_email_tracking" */
export type DbEmailTrackingStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbEmailTrackingStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbEmailTrackingStreamCursorValueInput = {
  count?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  emailId?: InputMaybe<Scalars['String']>;
  failureReason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  linkUrl?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  processedAt?: InputMaybe<Scalars['timestamp']>;
  recipient?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EmailTrackingTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbEmailTrackingSumFields = {
  __typename?: 'DbEmailTrackingSumFields';
  count?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.email_tracking" */
export type DbEmailTrackingSumOrderBy = {
  count?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbEmailTrackingVarPopFields = {
  __typename?: 'DbEmailTrackingVarPopFields';
  count?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.email_tracking" */
export type DbEmailTrackingVarPopOrderBy = {
  count?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbEmailTrackingVarSampFields = {
  __typename?: 'DbEmailTrackingVarSampFields';
  count?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.email_tracking" */
export type DbEmailTrackingVarSampOrderBy = {
  count?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbEmailTrackingVarianceFields = {
  __typename?: 'DbEmailTrackingVarianceFields';
  count?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.email_tracking" */
export type DbEmailTrackingVarianceOrderBy = {
  count?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.enabled_feature_flags" */
export type DbEnabledFeatureFlags = {
  __typename?: 'DbEnabledFeatureFlags';
  enabledFeature: FeatureFlagsEnum;
};

/** Boolean expression to filter rows from the table "db.enabled_feature_flags". All fields are combined with a logical 'AND'. */
export type DbEnabledFeatureFlagsBoolExp = {
  _and?: InputMaybe<Array<DbEnabledFeatureFlagsBoolExp>>;
  _not?: InputMaybe<DbEnabledFeatureFlagsBoolExp>;
  _or?: InputMaybe<Array<DbEnabledFeatureFlagsBoolExp>>;
  enabledFeature?: InputMaybe<EnumsFeatureFlagsEnumComparisonExp>;
};

/** Ordering options when selecting data from "db.enabled_feature_flags". */
export type DbEnabledFeatureFlagsOrderBy = {
  enabledFeature?: InputMaybe<OrderBy>;
};

/** select columns of table "db.enabled_feature_flags" */
export type DbEnabledFeatureFlagsSelectColumn =
  /** column name */
  'enabledFeature';

/** Streaming cursor of the table "db_enabled_feature_flags" */
export type DbEnabledFeatureFlagsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbEnabledFeatureFlagsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbEnabledFeatureFlagsStreamCursorValueInput = {
  enabledFeature?: InputMaybe<FeatureFlagsEnum>;
};

/** columns and relationships of "db.exposee_shared_activity" */
export type DbExposeeSharedActivity = {
  __typename?: 'DbExposeeSharedActivity';
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['String'];
  propertyIds: Array<Scalars['String']>;
  status: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "db.exposee_shared_activity" */
export type DbExposeeSharedActivityAggregate = {
  __typename?: 'DbExposeeSharedActivityAggregate';
  aggregate?: Maybe<DbExposeeSharedActivityAggregateFields>;
  nodes: Array<DbExposeeSharedActivity>;
};

/** aggregate fields of "db.exposee_shared_activity" */
export type DbExposeeSharedActivityAggregateFields = {
  __typename?: 'DbExposeeSharedActivityAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<DbExposeeSharedActivityMaxFields>;
  min?: Maybe<DbExposeeSharedActivityMinFields>;
};

/** aggregate fields of "db.exposee_shared_activity" */
export type DbExposeeSharedActivityAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbExposeeSharedActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "db.exposee_shared_activity". All fields are combined with a logical 'AND'. */
export type DbExposeeSharedActivityBoolExp = {
  _and?: InputMaybe<Array<DbExposeeSharedActivityBoolExp>>;
  _not?: InputMaybe<DbExposeeSharedActivityBoolExp>;
  _or?: InputMaybe<Array<DbExposeeSharedActivityBoolExp>>;
  comment?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  propertyIds?: InputMaybe<StringArrayComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** aggregate max on columns */
export type DbExposeeSharedActivityMaxFields = {
  __typename?: 'DbExposeeSharedActivityMaxFields';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  propertyIds?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type DbExposeeSharedActivityMinFields = {
  __typename?: 'DbExposeeSharedActivityMinFields';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  propertyIds?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** Ordering options when selecting data from "db.exposee_shared_activity". */
export type DbExposeeSharedActivityOrderBy = {
  comment?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  propertyIds?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** select columns of table "db.exposee_shared_activity" */
export type DbExposeeSharedActivitySelectColumn =
  /** column name */
  | 'comment'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'propertyIds'
  /** column name */
  | 'status'
  /** column name */
  | 'title'
  /** column name */
  | 'updatedAt';

/** Streaming cursor of the table "db_exposee_shared_activity" */
export type DbExposeeSharedActivityStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbExposeeSharedActivityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbExposeeSharedActivityStreamCursorValueInput = {
  comment?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  propertyIds?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "db.import_lead_request" */
export type DbImportLeadRequest = {
  __typename?: 'DbImportLeadRequest';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  errorMessage?: Maybe<Scalars['String']>;
  errorOccurredAt?: Maybe<Scalars['timestamp']>;
  id: Scalars['String'];
  importLeadStatus: ImportLeadStatusEnum;
  /** An object relationship */
  importLeadStatusByImportLeadStatus: EnumsImportLeadStatus;
  importedLeadId?: Maybe<Scalars['String']>;
  num: Scalars['bigint'];
  payloadType: Scalars['String'];
  requestPayload: Scalars['String'];
  stackTrace?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.import_lead_request" */
export type DbImportLeadRequestAggregate = {
  __typename?: 'DbImportLeadRequestAggregate';
  aggregate?: Maybe<DbImportLeadRequestAggregateFields>;
  nodes: Array<DbImportLeadRequest>;
};

export type DbImportLeadRequestAggregateBoolExp = {
  bool_and?: InputMaybe<DbImportLeadRequestAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbImportLeadRequestAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbImportLeadRequestAggregateBoolExpCount>;
};

/** aggregate fields of "db.import_lead_request" */
export type DbImportLeadRequestAggregateFields = {
  __typename?: 'DbImportLeadRequestAggregateFields';
  avg?: Maybe<DbImportLeadRequestAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbImportLeadRequestMaxFields>;
  min?: Maybe<DbImportLeadRequestMinFields>;
  stddev?: Maybe<DbImportLeadRequestStddevFields>;
  stddevPop?: Maybe<DbImportLeadRequestStddevPopFields>;
  stddevSamp?: Maybe<DbImportLeadRequestStddevSampFields>;
  sum?: Maybe<DbImportLeadRequestSumFields>;
  varPop?: Maybe<DbImportLeadRequestVarPopFields>;
  varSamp?: Maybe<DbImportLeadRequestVarSampFields>;
  variance?: Maybe<DbImportLeadRequestVarianceFields>;
};

/** aggregate fields of "db.import_lead_request" */
export type DbImportLeadRequestAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbImportLeadRequestSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.import_lead_request" */
export type DbImportLeadRequestAggregateOrderBy = {
  avg?: InputMaybe<DbImportLeadRequestAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbImportLeadRequestMaxOrderBy>;
  min?: InputMaybe<DbImportLeadRequestMinOrderBy>;
  stddev?: InputMaybe<DbImportLeadRequestStddevOrderBy>;
  stddevPop?: InputMaybe<DbImportLeadRequestStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbImportLeadRequestStddevSampOrderBy>;
  sum?: InputMaybe<DbImportLeadRequestSumOrderBy>;
  varPop?: InputMaybe<DbImportLeadRequestVarPopOrderBy>;
  varSamp?: InputMaybe<DbImportLeadRequestVarSampOrderBy>;
  variance?: InputMaybe<DbImportLeadRequestVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbImportLeadRequestAvgFields = {
  __typename?: 'DbImportLeadRequestAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.import_lead_request" */
export type DbImportLeadRequestAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.import_lead_request". All fields are combined with a logical 'AND'. */
export type DbImportLeadRequestBoolExp = {
  _and?: InputMaybe<Array<DbImportLeadRequestBoolExp>>;
  _not?: InputMaybe<DbImportLeadRequestBoolExp>;
  _or?: InputMaybe<Array<DbImportLeadRequestBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  errorMessage?: InputMaybe<StringComparisonExp>;
  errorOccurredAt?: InputMaybe<TimestampComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  importLeadStatus?: InputMaybe<EnumsImportLeadStatusEnumComparisonExp>;
  importLeadStatusByImportLeadStatus?: InputMaybe<EnumsImportLeadStatusBoolExp>;
  importedLeadId?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  payloadType?: InputMaybe<StringComparisonExp>;
  requestPayload?: InputMaybe<StringComparisonExp>;
  stackTrace?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbImportLeadRequestMaxFields = {
  __typename?: 'DbImportLeadRequestMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  errorMessage?: Maybe<Scalars['String']>;
  errorOccurredAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['String']>;
  importedLeadId?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  payloadType?: Maybe<Scalars['String']>;
  requestPayload?: Maybe<Scalars['String']>;
  stackTrace?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.import_lead_request" */
export type DbImportLeadRequestMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  errorMessage?: InputMaybe<OrderBy>;
  errorOccurredAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  importedLeadId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  payloadType?: InputMaybe<OrderBy>;
  requestPayload?: InputMaybe<OrderBy>;
  stackTrace?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbImportLeadRequestMinFields = {
  __typename?: 'DbImportLeadRequestMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  errorMessage?: Maybe<Scalars['String']>;
  errorOccurredAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['String']>;
  importedLeadId?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  payloadType?: Maybe<Scalars['String']>;
  requestPayload?: Maybe<Scalars['String']>;
  stackTrace?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.import_lead_request" */
export type DbImportLeadRequestMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  errorMessage?: InputMaybe<OrderBy>;
  errorOccurredAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  importedLeadId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  payloadType?: InputMaybe<OrderBy>;
  requestPayload?: InputMaybe<OrderBy>;
  stackTrace?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.import_lead_request". */
export type DbImportLeadRequestOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  errorMessage?: InputMaybe<OrderBy>;
  errorOccurredAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  importLeadStatus?: InputMaybe<OrderBy>;
  importLeadStatusByImportLeadStatus?: InputMaybe<EnumsImportLeadStatusOrderBy>;
  importedLeadId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  payloadType?: InputMaybe<OrderBy>;
  requestPayload?: InputMaybe<OrderBy>;
  stackTrace?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.import_lead_request" */
export type DbImportLeadRequestSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'errorMessage'
  /** column name */
  | 'errorOccurredAt'
  /** column name */
  | 'id'
  /** column name */
  | 'importLeadStatus'
  /** column name */
  | 'importedLeadId'
  /** column name */
  | 'num'
  /** column name */
  | 'payloadType'
  /** column name */
  | 'requestPayload'
  /** column name */
  | 'stackTrace'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbImportLeadRequestAggregateBoolExpBool_andArgumentsColumns" columns of table "db.import_lead_request" */
export type DbImportLeadRequestSelectColumnDbImportLeadRequestAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbImportLeadRequestAggregateBoolExpBool_orArgumentsColumns" columns of table "db.import_lead_request" */
export type DbImportLeadRequestSelectColumnDbImportLeadRequestAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbImportLeadRequestStddevFields = {
  __typename?: 'DbImportLeadRequestStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.import_lead_request" */
export type DbImportLeadRequestStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbImportLeadRequestStddevPopFields = {
  __typename?: 'DbImportLeadRequestStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.import_lead_request" */
export type DbImportLeadRequestStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbImportLeadRequestStddevSampFields = {
  __typename?: 'DbImportLeadRequestStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.import_lead_request" */
export type DbImportLeadRequestStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_import_lead_request" */
export type DbImportLeadRequestStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbImportLeadRequestStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbImportLeadRequestStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  errorOccurredAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['String']>;
  importLeadStatus?: InputMaybe<ImportLeadStatusEnum>;
  importedLeadId?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  payloadType?: InputMaybe<Scalars['String']>;
  requestPayload?: InputMaybe<Scalars['String']>;
  stackTrace?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbImportLeadRequestSumFields = {
  __typename?: 'DbImportLeadRequestSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.import_lead_request" */
export type DbImportLeadRequestSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbImportLeadRequestVarPopFields = {
  __typename?: 'DbImportLeadRequestVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.import_lead_request" */
export type DbImportLeadRequestVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbImportLeadRequestVarSampFields = {
  __typename?: 'DbImportLeadRequestVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.import_lead_request" */
export type DbImportLeadRequestVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbImportLeadRequestVarianceFields = {
  __typename?: 'DbImportLeadRequestVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.import_lead_request" */
export type DbImportLeadRequestVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.lead" */
export type DbLead = {
  __typename?: 'DbLead';
  /** An object relationship */
  agent?: Maybe<DbAgent>;
  agentId?: Maybe<Scalars['String']>;
  /** An array relationship */
  appointmentLeads: Array<DbAppointmentLead>;
  /** An array relationship */
  baseActivities: Array<DbBaseActivity>;
  /** An aggregate relationship */
  baseActivitiesAggregate: DbBaseActivityAggregate;
  /** An object relationship */
  buyerToRentSpecification?: Maybe<DbBuyerToRentSpecification>;
  buyerToRentSpecificationId?: Maybe<Scalars['String']>;
  /** An object relationship */
  contact: DbContact;
  /** An array relationship */
  contactAddresses: Array<DbContactAddress>;
  /** An aggregate relationship */
  contactAddressesAggregate: DbContactAddressAggregate;
  /** An array relationship */
  contactEmailAddresses: Array<DbContactEmailAddress>;
  /** An aggregate relationship */
  contactEmailAddressesAggregate: DbContactEmailAddressAggregate;
  contactFirstName?: Maybe<Scalars['String']>;
  contactId: Scalars['String'];
  contactLastName?: Maybe<Scalars['String']>;
  /** An object relationship */
  contactStatusInfo?: Maybe<DbContactStatusInfo>;
  /** An array relationship */
  conversionData: Array<DbConversionData>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  customerMessage?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  emailLinks: Array<DbEmailLink>;
  /** An aggregate relationship */
  emailLinksAggregate: DbEmailLinkAggregate;
  expiredAt?: Maybe<Scalars['timestamptz']>;
  go3PropertyId?: Maybe<Scalars['String']>;
  go3SearchDemandKey?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  intentType: IntentTypeEnum;
  /** An object relationship */
  intentTypeByIntentType: EnumsIntentType;
  /** An object relationship */
  lastInteractionActivity?: Maybe<DbBaseActivity>;
  lastInteractionActivityId?: Maybe<Scalars['String']>;
  lastInteractionActivityOrderDate?: Maybe<Scalars['timestamptz']>;
  lck: Scalars['Int'];
  /** An object relationship */
  leadBundlingByLeadWithPropertyId?: Maybe<DbLeadBundling>;
  /** An array relationship */
  leadBundlingsByLeadWithSearchDemandId: Array<DbLeadBundling>;
  /** An object relationship */
  leadExpiryByLead?: Maybe<DbLeadExpiry>;
  /** An object relationship */
  leadSource: EnumsLeadSource;
  /** An object relationship */
  leadStatus: EnumsLeadStatus;
  /** An object relationship */
  leadStatusReason?: Maybe<EnumsLeadStatusReason>;
  mostRecentActivityCreatedAt?: Maybe<Scalars['timestamptz']>;
  num: Scalars['bigint'];
  peLinkedPropertyId?: Maybe<Scalars['String']>;
  personalNote?: Maybe<Scalars['String']>;
  priority?: Maybe<PriorityEnum>;
  /** An object relationship */
  priorityByPriority?: Maybe<EnumsPriority>;
  /** An array relationship */
  qualificationFormLinks: Array<DbQualificationFormLink>;
  /** An aggregate relationship */
  qualificationFormLinksAggregate: DbQualificationFormLinkAggregate;
  referralInitiated?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  referrals: Array<DbReferral>;
  /** An aggregate relationship */
  referralsAggregate: DbReferralAggregate;
  /** An array relationship */
  referralsByReceivingLeadId: Array<DbReferral>;
  /** An aggregate relationship */
  referralsByReceivingLeadIdAggregate: DbReferralAggregate;
  /** An object relationship */
  sellerRentOutSpecification?: Maybe<DbSellerRentOutSpecification>;
  sellerRentOutSpecificationId?: Maybe<Scalars['String']>;
  /** An object relationship */
  shop: DbShop;
  shopId: Scalars['String'];
  source: LeadSourceEnum;
  status: LeadStatusEnum;
  statusNote?: Maybe<Scalars['String']>;
  statusReason?: Maybe<LeadStatusReasonEnum>;
  /** An array relationship */
  taskLeads: Array<DbTaskLead>;
  /** An object relationship */
  team?: Maybe<DbTeam>;
  teamId?: Maybe<Scalars['String']>;
  timeFrame?: Maybe<TimeFrameEnum>;
  /** An object relationship */
  timeFrameByTimeFrame?: Maybe<EnumsTimeFrame>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  /** An object relationship */
  utmParams?: Maybe<DbUtmParam>;
};

/** columns and relationships of "db.lead" */
export type DbLeadAppointmentLeadsArgs = {
  distinctOn?: InputMaybe<Array<DbAppointmentLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAppointmentLeadOrderBy>>;
  where?: InputMaybe<DbAppointmentLeadBoolExp>;
};

/** columns and relationships of "db.lead" */
export type DbLeadBaseActivitiesArgs = {
  distinctOn?: InputMaybe<Array<DbBaseActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBaseActivityOrderBy>>;
  where?: InputMaybe<DbBaseActivityBoolExp>;
};

/** columns and relationships of "db.lead" */
export type DbLeadBaseActivitiesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbBaseActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBaseActivityOrderBy>>;
  where?: InputMaybe<DbBaseActivityBoolExp>;
};

/** columns and relationships of "db.lead" */
export type DbLeadContactAddressesArgs = {
  distinctOn?: InputMaybe<Array<DbContactAddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactAddressOrderBy>>;
  where?: InputMaybe<DbContactAddressBoolExp>;
};

/** columns and relationships of "db.lead" */
export type DbLeadContactAddressesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbContactAddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactAddressOrderBy>>;
  where?: InputMaybe<DbContactAddressBoolExp>;
};

/** columns and relationships of "db.lead" */
export type DbLeadContactEmailAddressesArgs = {
  distinctOn?: InputMaybe<Array<DbContactEmailAddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactEmailAddressOrderBy>>;
  where?: InputMaybe<DbContactEmailAddressBoolExp>;
};

/** columns and relationships of "db.lead" */
export type DbLeadContactEmailAddressesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbContactEmailAddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactEmailAddressOrderBy>>;
  where?: InputMaybe<DbContactEmailAddressBoolExp>;
};

/** columns and relationships of "db.lead" */
export type DbLeadConversionDataArgs = {
  distinctOn?: InputMaybe<Array<DbConversionDataSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConversionDataOrderBy>>;
  where?: InputMaybe<DbConversionDataBoolExp>;
};

/** columns and relationships of "db.lead" */
export type DbLeadEmailLinksArgs = {
  distinctOn?: InputMaybe<Array<DbEmailLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailLinkOrderBy>>;
  where?: InputMaybe<DbEmailLinkBoolExp>;
};

/** columns and relationships of "db.lead" */
export type DbLeadEmailLinksAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailLinkOrderBy>>;
  where?: InputMaybe<DbEmailLinkBoolExp>;
};

/** columns and relationships of "db.lead" */
export type DbLeadLeadBundlingsByLeadWithSearchDemandIdArgs = {
  distinctOn?: InputMaybe<Array<DbLeadBundlingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadBundlingOrderBy>>;
  where?: InputMaybe<DbLeadBundlingBoolExp>;
};

/** columns and relationships of "db.lead" */
export type DbLeadQualificationFormLinksArgs = {
  distinctOn?: InputMaybe<Array<DbQualificationFormLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbQualificationFormLinkOrderBy>>;
  where?: InputMaybe<DbQualificationFormLinkBoolExp>;
};

/** columns and relationships of "db.lead" */
export type DbLeadQualificationFormLinksAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbQualificationFormLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbQualificationFormLinkOrderBy>>;
  where?: InputMaybe<DbQualificationFormLinkBoolExp>;
};

/** columns and relationships of "db.lead" */
export type DbLeadReferralsArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

/** columns and relationships of "db.lead" */
export type DbLeadReferralsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

/** columns and relationships of "db.lead" */
export type DbLeadReferralsByReceivingLeadIdArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

/** columns and relationships of "db.lead" */
export type DbLeadReferralsByReceivingLeadIdAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

/** columns and relationships of "db.lead" */
export type DbLeadTaskLeadsArgs = {
  distinctOn?: InputMaybe<Array<DbTaskLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTaskLeadOrderBy>>;
  where?: InputMaybe<DbTaskLeadBoolExp>;
};

/** aggregated selection of "db.lead" */
export type DbLeadAggregate = {
  __typename?: 'DbLeadAggregate';
  aggregate?: Maybe<DbLeadAggregateFields>;
  nodes: Array<DbLead>;
};

export type DbLeadAggregateBoolExp = {
  bool_and?: InputMaybe<DbLeadAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbLeadAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbLeadAggregateBoolExpCount>;
};

/** aggregate fields of "db.lead" */
export type DbLeadAggregateFields = {
  __typename?: 'DbLeadAggregateFields';
  avg?: Maybe<DbLeadAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbLeadMaxFields>;
  min?: Maybe<DbLeadMinFields>;
  stddev?: Maybe<DbLeadStddevFields>;
  stddevPop?: Maybe<DbLeadStddevPopFields>;
  stddevSamp?: Maybe<DbLeadStddevSampFields>;
  sum?: Maybe<DbLeadSumFields>;
  varPop?: Maybe<DbLeadVarPopFields>;
  varSamp?: Maybe<DbLeadVarSampFields>;
  variance?: Maybe<DbLeadVarianceFields>;
};

/** aggregate fields of "db.lead" */
export type DbLeadAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbLeadSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.lead" */
export type DbLeadAggregateOrderBy = {
  avg?: InputMaybe<DbLeadAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbLeadMaxOrderBy>;
  min?: InputMaybe<DbLeadMinOrderBy>;
  stddev?: InputMaybe<DbLeadStddevOrderBy>;
  stddevPop?: InputMaybe<DbLeadStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbLeadStddevSampOrderBy>;
  sum?: InputMaybe<DbLeadSumOrderBy>;
  varPop?: InputMaybe<DbLeadVarPopOrderBy>;
  varSamp?: InputMaybe<DbLeadVarSampOrderBy>;
  variance?: InputMaybe<DbLeadVarianceOrderBy>;
};

/** columns and relationships of "db.lead_auto_assignment_intent" */
export type DbLeadAutoAssignmentIntent = {
  __typename?: 'DbLeadAutoAssignmentIntent';
  intentType: IntentTypeEnum;
  /** An object relationship */
  intentTypeByIntentType: EnumsIntentType;
  /** An object relationship */
  shop: DbShop;
  shopId: Scalars['String'];
};

/** order by aggregate values of table "db.lead_auto_assignment_intent" */
export type DbLeadAutoAssignmentIntentAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbLeadAutoAssignmentIntentMaxOrderBy>;
  min?: InputMaybe<DbLeadAutoAssignmentIntentMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.lead_auto_assignment_intent". All fields are combined with a logical 'AND'. */
export type DbLeadAutoAssignmentIntentBoolExp = {
  _and?: InputMaybe<Array<DbLeadAutoAssignmentIntentBoolExp>>;
  _not?: InputMaybe<DbLeadAutoAssignmentIntentBoolExp>;
  _or?: InputMaybe<Array<DbLeadAutoAssignmentIntentBoolExp>>;
  intentType?: InputMaybe<EnumsIntentTypeEnumComparisonExp>;
  intentTypeByIntentType?: InputMaybe<EnumsIntentTypeBoolExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.lead_auto_assignment_intent" */
export type DbLeadAutoAssignmentIntentMaxOrderBy = {
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.lead_auto_assignment_intent" */
export type DbLeadAutoAssignmentIntentMinOrderBy = {
  shopId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.lead_auto_assignment_intent". */
export type DbLeadAutoAssignmentIntentOrderBy = {
  intentType?: InputMaybe<OrderBy>;
  intentTypeByIntentType?: InputMaybe<EnumsIntentTypeOrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** select columns of table "db.lead_auto_assignment_intent" */
export type DbLeadAutoAssignmentIntentSelectColumn =
  /** column name */
  | 'intentType'
  /** column name */
  | 'shopId';

/** Streaming cursor of the table "db_lead_auto_assignment_intent" */
export type DbLeadAutoAssignmentIntentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbLeadAutoAssignmentIntentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbLeadAutoAssignmentIntentStreamCursorValueInput = {
  intentType?: InputMaybe<IntentTypeEnum>;
  shopId?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "db.lead_auto_assignment_rules" */
export type DbLeadAutoAssignmentRules = {
  __typename?: 'DbLeadAutoAssignmentRules';
  leadAssignmentRule: LeadAutoAssignmentRulesEnum;
  /** An object relationship */
  leadAutoAssignmentRule: EnumsLeadAutoAssignmentRules;
  /** An object relationship */
  shop: DbShop;
  shopId: Scalars['String'];
};

/** order by aggregate values of table "db.lead_auto_assignment_rules" */
export type DbLeadAutoAssignmentRulesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbLeadAutoAssignmentRulesMaxOrderBy>;
  min?: InputMaybe<DbLeadAutoAssignmentRulesMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.lead_auto_assignment_rules". All fields are combined with a logical 'AND'. */
export type DbLeadAutoAssignmentRulesBoolExp = {
  _and?: InputMaybe<Array<DbLeadAutoAssignmentRulesBoolExp>>;
  _not?: InputMaybe<DbLeadAutoAssignmentRulesBoolExp>;
  _or?: InputMaybe<Array<DbLeadAutoAssignmentRulesBoolExp>>;
  leadAssignmentRule?: InputMaybe<EnumsLeadAutoAssignmentRulesEnumComparisonExp>;
  leadAutoAssignmentRule?: InputMaybe<EnumsLeadAutoAssignmentRulesBoolExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.lead_auto_assignment_rules" */
export type DbLeadAutoAssignmentRulesMaxOrderBy = {
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.lead_auto_assignment_rules" */
export type DbLeadAutoAssignmentRulesMinOrderBy = {
  shopId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.lead_auto_assignment_rules". */
export type DbLeadAutoAssignmentRulesOrderBy = {
  leadAssignmentRule?: InputMaybe<OrderBy>;
  leadAutoAssignmentRule?: InputMaybe<EnumsLeadAutoAssignmentRulesOrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** select columns of table "db.lead_auto_assignment_rules" */
export type DbLeadAutoAssignmentRulesSelectColumn =
  /** column name */
  | 'leadAssignmentRule'
  /** column name */
  | 'shopId';

/** Streaming cursor of the table "db_lead_auto_assignment_rules" */
export type DbLeadAutoAssignmentRulesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbLeadAutoAssignmentRulesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbLeadAutoAssignmentRulesStreamCursorValueInput = {
  leadAssignmentRule?: InputMaybe<LeadAutoAssignmentRulesEnum>;
  shopId?: InputMaybe<Scalars['String']>;
};

/** aggregate avg on columns */
export type DbLeadAvgFields = {
  __typename?: 'DbLeadAvgFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.lead" */
export type DbLeadAvgOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.lead". All fields are combined with a logical 'AND'. */
export type DbLeadBoolExp = {
  _and?: InputMaybe<Array<DbLeadBoolExp>>;
  _not?: InputMaybe<DbLeadBoolExp>;
  _or?: InputMaybe<Array<DbLeadBoolExp>>;
  agent?: InputMaybe<DbAgentBoolExp>;
  agentId?: InputMaybe<StringComparisonExp>;
  appointmentLeads?: InputMaybe<DbAppointmentLeadBoolExp>;
  baseActivities?: InputMaybe<DbBaseActivityBoolExp>;
  baseActivitiesAggregate?: InputMaybe<DbBaseActivityAggregateBoolExp>;
  buyerToRentSpecification?: InputMaybe<DbBuyerToRentSpecificationBoolExp>;
  buyerToRentSpecificationId?: InputMaybe<StringComparisonExp>;
  contact?: InputMaybe<DbContactBoolExp>;
  contactAddresses?: InputMaybe<DbContactAddressBoolExp>;
  contactAddressesAggregate?: InputMaybe<DbContactAddressAggregateBoolExp>;
  contactEmailAddresses?: InputMaybe<DbContactEmailAddressBoolExp>;
  contactEmailAddressesAggregate?: InputMaybe<DbContactEmailAddressAggregateBoolExp>;
  contactFirstName?: InputMaybe<StringComparisonExp>;
  contactId?: InputMaybe<StringComparisonExp>;
  contactLastName?: InputMaybe<StringComparisonExp>;
  contactStatusInfo?: InputMaybe<DbContactStatusInfoBoolExp>;
  conversionData?: InputMaybe<DbConversionDataBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  customerMessage?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  emailLinks?: InputMaybe<DbEmailLinkBoolExp>;
  emailLinksAggregate?: InputMaybe<DbEmailLinkAggregateBoolExp>;
  expiredAt?: InputMaybe<TimestamptzComparisonExp>;
  go3PropertyId?: InputMaybe<StringComparisonExp>;
  go3SearchDemandKey?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  intentType?: InputMaybe<EnumsIntentTypeEnumComparisonExp>;
  intentTypeByIntentType?: InputMaybe<EnumsIntentTypeBoolExp>;
  lastInteractionActivity?: InputMaybe<DbBaseActivityBoolExp>;
  lastInteractionActivityId?: InputMaybe<StringComparisonExp>;
  lastInteractionActivityOrderDate?: InputMaybe<TimestamptzComparisonExp>;
  lck?: InputMaybe<IntComparisonExp>;
  leadBundlingByLeadWithPropertyId?: InputMaybe<DbLeadBundlingBoolExp>;
  leadBundlingsByLeadWithSearchDemandId?: InputMaybe<DbLeadBundlingBoolExp>;
  leadExpiryByLead?: InputMaybe<DbLeadExpiryBoolExp>;
  leadSource?: InputMaybe<EnumsLeadSourceBoolExp>;
  leadStatus?: InputMaybe<EnumsLeadStatusBoolExp>;
  leadStatusReason?: InputMaybe<EnumsLeadStatusReasonBoolExp>;
  mostRecentActivityCreatedAt?: InputMaybe<TimestamptzComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  peLinkedPropertyId?: InputMaybe<StringComparisonExp>;
  personalNote?: InputMaybe<StringComparisonExp>;
  priority?: InputMaybe<EnumsPriorityEnumComparisonExp>;
  priorityByPriority?: InputMaybe<EnumsPriorityBoolExp>;
  qualificationFormLinks?: InputMaybe<DbQualificationFormLinkBoolExp>;
  qualificationFormLinksAggregate?: InputMaybe<DbQualificationFormLinkAggregateBoolExp>;
  referralInitiated?: InputMaybe<BooleanComparisonExp>;
  referrals?: InputMaybe<DbReferralBoolExp>;
  referralsAggregate?: InputMaybe<DbReferralAggregateBoolExp>;
  referralsByReceivingLeadId?: InputMaybe<DbReferralBoolExp>;
  referralsByReceivingLeadIdAggregate?: InputMaybe<DbReferralAggregateBoolExp>;
  sellerRentOutSpecification?: InputMaybe<DbSellerRentOutSpecificationBoolExp>;
  sellerRentOutSpecificationId?: InputMaybe<StringComparisonExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  source?: InputMaybe<EnumsLeadSourceEnumComparisonExp>;
  status?: InputMaybe<EnumsLeadStatusEnumComparisonExp>;
  statusNote?: InputMaybe<StringComparisonExp>;
  statusReason?: InputMaybe<EnumsLeadStatusReasonEnumComparisonExp>;
  taskLeads?: InputMaybe<DbTaskLeadBoolExp>;
  team?: InputMaybe<DbTeamBoolExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  timeFrame?: InputMaybe<EnumsTimeFrameEnumComparisonExp>;
  timeFrameByTimeFrame?: InputMaybe<EnumsTimeFrameBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  utmParams?: InputMaybe<DbUtmParamBoolExp>;
};

/** columns and relationships of "db.lead_bundling" */
export type DbLeadBundling = {
  __typename?: 'DbLeadBundling';
  id: Scalars['String'];
  /** An object relationship */
  leadWithProperty: DbLead;
  /** An object relationship */
  leadWithSearchDemand: DbLead;
};

/** order by aggregate values of table "db.lead_bundling" */
export type DbLeadBundlingAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbLeadBundlingMaxOrderBy>;
  min?: InputMaybe<DbLeadBundlingMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.lead_bundling". All fields are combined with a logical 'AND'. */
export type DbLeadBundlingBoolExp = {
  _and?: InputMaybe<Array<DbLeadBundlingBoolExp>>;
  _not?: InputMaybe<DbLeadBundlingBoolExp>;
  _or?: InputMaybe<Array<DbLeadBundlingBoolExp>>;
  id?: InputMaybe<StringComparisonExp>;
  leadWithProperty?: InputMaybe<DbLeadBoolExp>;
  leadWithSearchDemand?: InputMaybe<DbLeadBoolExp>;
};

/** order by max() on columns of table "db.lead_bundling" */
export type DbLeadBundlingMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.lead_bundling" */
export type DbLeadBundlingMinOrderBy = {
  id?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.lead_bundling". */
export type DbLeadBundlingOrderBy = {
  id?: InputMaybe<OrderBy>;
  leadWithProperty?: InputMaybe<DbLeadOrderBy>;
  leadWithSearchDemand?: InputMaybe<DbLeadOrderBy>;
};

/** select columns of table "db.lead_bundling" */
export type DbLeadBundlingSelectColumn =
  /** column name */
  'id';

/** columns and relationships of "db.lead_expiry" */
export type DbLeadExpiry = {
  __typename?: 'DbLeadExpiry';
  expirationDate?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "db.lead_expiry". All fields are combined with a logical 'AND'. */
export type DbLeadExpiryBoolExp = {
  _and?: InputMaybe<Array<DbLeadExpiryBoolExp>>;
  _not?: InputMaybe<DbLeadExpiryBoolExp>;
  _or?: InputMaybe<Array<DbLeadExpiryBoolExp>>;
  expirationDate?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "db.lead_expiry". */
export type DbLeadExpiryOrderBy = {
  expirationDate?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
};

/** select columns of table "db.lead_expiry" */
export type DbLeadExpirySelectColumn =
  /** column name */
  | 'expirationDate'
  /** column name */
  | 'id'
  /** column name */
  | 'status';

/** Streaming cursor of the table "db_lead_expiry" */
export type DbLeadExpiryStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbLeadExpiryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbLeadExpiryStreamCursorValueInput = {
  expirationDate?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "db.lead_extending_activities" */
export type DbLeadExtendingActivities = {
  __typename?: 'DbLeadExtendingActivities';
  /** An object relationship */
  activity: EnumsActivityType;
  activityType: ActivityTypeEnum;
  /** An object relationship */
  shop: DbShop;
  shopId: Scalars['String'];
};

/** order by aggregate values of table "db.lead_extending_activities" */
export type DbLeadExtendingActivitiesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbLeadExtendingActivitiesMaxOrderBy>;
  min?: InputMaybe<DbLeadExtendingActivitiesMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.lead_extending_activities". All fields are combined with a logical 'AND'. */
export type DbLeadExtendingActivitiesBoolExp = {
  _and?: InputMaybe<Array<DbLeadExtendingActivitiesBoolExp>>;
  _not?: InputMaybe<DbLeadExtendingActivitiesBoolExp>;
  _or?: InputMaybe<Array<DbLeadExtendingActivitiesBoolExp>>;
  activity?: InputMaybe<EnumsActivityTypeBoolExp>;
  activityType?: InputMaybe<EnumsActivityTypeEnumComparisonExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.lead_extending_activities" */
export type DbLeadExtendingActivitiesMaxOrderBy = {
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.lead_extending_activities" */
export type DbLeadExtendingActivitiesMinOrderBy = {
  shopId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.lead_extending_activities". */
export type DbLeadExtendingActivitiesOrderBy = {
  activity?: InputMaybe<EnumsActivityTypeOrderBy>;
  activityType?: InputMaybe<OrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** select columns of table "db.lead_extending_activities" */
export type DbLeadExtendingActivitiesSelectColumn =
  /** column name */
  | 'activityType'
  /** column name */
  | 'shopId';

/** Streaming cursor of the table "db_lead_extending_activities" */
export type DbLeadExtendingActivitiesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbLeadExtendingActivitiesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbLeadExtendingActivitiesStreamCursorValueInput = {
  activityType?: InputMaybe<ActivityTypeEnum>;
  shopId?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "db.lead_extending_appointment_types" */
export type DbLeadExtendingAppointmentTypes = {
  __typename?: 'DbLeadExtendingAppointmentTypes';
  /** An object relationship */
  appointment: EnumsAppointmentType;
  appointmentType: AppointmentTypeEnum;
  /** An object relationship */
  shop: DbShop;
  shopId: Scalars['String'];
};

/** order by aggregate values of table "db.lead_extending_appointment_types" */
export type DbLeadExtendingAppointmentTypesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbLeadExtendingAppointmentTypesMaxOrderBy>;
  min?: InputMaybe<DbLeadExtendingAppointmentTypesMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.lead_extending_appointment_types". All fields are combined with a logical 'AND'. */
export type DbLeadExtendingAppointmentTypesBoolExp = {
  _and?: InputMaybe<Array<DbLeadExtendingAppointmentTypesBoolExp>>;
  _not?: InputMaybe<DbLeadExtendingAppointmentTypesBoolExp>;
  _or?: InputMaybe<Array<DbLeadExtendingAppointmentTypesBoolExp>>;
  appointment?: InputMaybe<EnumsAppointmentTypeBoolExp>;
  appointmentType?: InputMaybe<EnumsAppointmentTypeEnumComparisonExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.lead_extending_appointment_types" */
export type DbLeadExtendingAppointmentTypesMaxOrderBy = {
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.lead_extending_appointment_types" */
export type DbLeadExtendingAppointmentTypesMinOrderBy = {
  shopId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.lead_extending_appointment_types". */
export type DbLeadExtendingAppointmentTypesOrderBy = {
  appointment?: InputMaybe<EnumsAppointmentTypeOrderBy>;
  appointmentType?: InputMaybe<OrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
};

/** select columns of table "db.lead_extending_appointment_types" */
export type DbLeadExtendingAppointmentTypesSelectColumn =
  /** column name */
  | 'appointmentType'
  /** column name */
  | 'shopId';

/** Streaming cursor of the table "db_lead_extending_appointment_types" */
export type DbLeadExtendingAppointmentTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbLeadExtendingAppointmentTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbLeadExtendingAppointmentTypesStreamCursorValueInput = {
  appointmentType?: InputMaybe<AppointmentTypeEnum>;
  shopId?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "db.lead_extending_task_types" */
export type DbLeadExtendingTaskTypes = {
  __typename?: 'DbLeadExtendingTaskTypes';
  /** An object relationship */
  shop: DbShop;
  shopId: Scalars['String'];
  /** An object relationship */
  task: EnumsTaskType;
  taskType: TaskTypeEnum;
};

/** order by aggregate values of table "db.lead_extending_task_types" */
export type DbLeadExtendingTaskTypesAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbLeadExtendingTaskTypesMaxOrderBy>;
  min?: InputMaybe<DbLeadExtendingTaskTypesMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.lead_extending_task_types". All fields are combined with a logical 'AND'. */
export type DbLeadExtendingTaskTypesBoolExp = {
  _and?: InputMaybe<Array<DbLeadExtendingTaskTypesBoolExp>>;
  _not?: InputMaybe<DbLeadExtendingTaskTypesBoolExp>;
  _or?: InputMaybe<Array<DbLeadExtendingTaskTypesBoolExp>>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  task?: InputMaybe<EnumsTaskTypeBoolExp>;
  taskType?: InputMaybe<EnumsTaskTypeEnumComparisonExp>;
};

/** order by max() on columns of table "db.lead_extending_task_types" */
export type DbLeadExtendingTaskTypesMaxOrderBy = {
  shopId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.lead_extending_task_types" */
export type DbLeadExtendingTaskTypesMinOrderBy = {
  shopId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.lead_extending_task_types". */
export type DbLeadExtendingTaskTypesOrderBy = {
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  task?: InputMaybe<EnumsTaskTypeOrderBy>;
  taskType?: InputMaybe<OrderBy>;
};

/** select columns of table "db.lead_extending_task_types" */
export type DbLeadExtendingTaskTypesSelectColumn =
  /** column name */
  | 'shopId'
  /** column name */
  | 'taskType';

/** Streaming cursor of the table "db_lead_extending_task_types" */
export type DbLeadExtendingTaskTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbLeadExtendingTaskTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbLeadExtendingTaskTypesStreamCursorValueInput = {
  shopId?: InputMaybe<Scalars['String']>;
  taskType?: InputMaybe<TaskTypeEnum>;
};

/** aggregate max on columns */
export type DbLeadMaxFields = {
  __typename?: 'DbLeadMaxFields';
  agentId?: Maybe<Scalars['String']>;
  buyerToRentSpecificationId?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  contactLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  customerMessage?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  expiredAt?: Maybe<Scalars['timestamptz']>;
  go3PropertyId?: Maybe<Scalars['String']>;
  go3SearchDemandKey?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastInteractionActivityId?: Maybe<Scalars['String']>;
  lastInteractionActivityOrderDate?: Maybe<Scalars['timestamptz']>;
  lck?: Maybe<Scalars['Int']>;
  mostRecentActivityCreatedAt?: Maybe<Scalars['timestamptz']>;
  num?: Maybe<Scalars['bigint']>;
  peLinkedPropertyId?: Maybe<Scalars['String']>;
  personalNote?: Maybe<Scalars['String']>;
  sellerRentOutSpecificationId?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['String']>;
  statusNote?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.lead" */
export type DbLeadMaxOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  buyerToRentSpecificationId?: InputMaybe<OrderBy>;
  contactFirstName?: InputMaybe<OrderBy>;
  contactId?: InputMaybe<OrderBy>;
  contactLastName?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  customerMessage?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  expiredAt?: InputMaybe<OrderBy>;
  go3PropertyId?: InputMaybe<OrderBy>;
  go3SearchDemandKey?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastInteractionActivityId?: InputMaybe<OrderBy>;
  lastInteractionActivityOrderDate?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  mostRecentActivityCreatedAt?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  peLinkedPropertyId?: InputMaybe<OrderBy>;
  personalNote?: InputMaybe<OrderBy>;
  sellerRentOutSpecificationId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  statusNote?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbLeadMinFields = {
  __typename?: 'DbLeadMinFields';
  agentId?: Maybe<Scalars['String']>;
  buyerToRentSpecificationId?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  contactLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  customerMessage?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  expiredAt?: Maybe<Scalars['timestamptz']>;
  go3PropertyId?: Maybe<Scalars['String']>;
  go3SearchDemandKey?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastInteractionActivityId?: Maybe<Scalars['String']>;
  lastInteractionActivityOrderDate?: Maybe<Scalars['timestamptz']>;
  lck?: Maybe<Scalars['Int']>;
  mostRecentActivityCreatedAt?: Maybe<Scalars['timestamptz']>;
  num?: Maybe<Scalars['bigint']>;
  peLinkedPropertyId?: Maybe<Scalars['String']>;
  personalNote?: Maybe<Scalars['String']>;
  sellerRentOutSpecificationId?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['String']>;
  statusNote?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.lead" */
export type DbLeadMinOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  buyerToRentSpecificationId?: InputMaybe<OrderBy>;
  contactFirstName?: InputMaybe<OrderBy>;
  contactId?: InputMaybe<OrderBy>;
  contactLastName?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  customerMessage?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  expiredAt?: InputMaybe<OrderBy>;
  go3PropertyId?: InputMaybe<OrderBy>;
  go3SearchDemandKey?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastInteractionActivityId?: InputMaybe<OrderBy>;
  lastInteractionActivityOrderDate?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  mostRecentActivityCreatedAt?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  peLinkedPropertyId?: InputMaybe<OrderBy>;
  personalNote?: InputMaybe<OrderBy>;
  sellerRentOutSpecificationId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  statusNote?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.lead". */
export type DbLeadOrderBy = {
  agent?: InputMaybe<DbAgentOrderBy>;
  agentId?: InputMaybe<OrderBy>;
  appointmentLeadsAggregate?: InputMaybe<DbAppointmentLeadAggregateOrderBy>;
  baseActivitiesAggregate?: InputMaybe<DbBaseActivityAggregateOrderBy>;
  buyerToRentSpecification?: InputMaybe<DbBuyerToRentSpecificationOrderBy>;
  buyerToRentSpecificationId?: InputMaybe<OrderBy>;
  contact?: InputMaybe<DbContactOrderBy>;
  contactAddressesAggregate?: InputMaybe<DbContactAddressAggregateOrderBy>;
  contactEmailAddressesAggregate?: InputMaybe<DbContactEmailAddressAggregateOrderBy>;
  contactFirstName?: InputMaybe<OrderBy>;
  contactId?: InputMaybe<OrderBy>;
  contactLastName?: InputMaybe<OrderBy>;
  contactStatusInfo?: InputMaybe<DbContactStatusInfoOrderBy>;
  conversionDataAggregate?: InputMaybe<DbConversionDataAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  customerMessage?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  emailLinksAggregate?: InputMaybe<DbEmailLinkAggregateOrderBy>;
  expiredAt?: InputMaybe<OrderBy>;
  go3PropertyId?: InputMaybe<OrderBy>;
  go3SearchDemandKey?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  intentType?: InputMaybe<OrderBy>;
  intentTypeByIntentType?: InputMaybe<EnumsIntentTypeOrderBy>;
  lastInteractionActivity?: InputMaybe<DbBaseActivityOrderBy>;
  lastInteractionActivityId?: InputMaybe<OrderBy>;
  lastInteractionActivityOrderDate?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  leadBundlingByLeadWithPropertyId?: InputMaybe<DbLeadBundlingOrderBy>;
  leadBundlingsByLeadWithSearchDemandIdAggregate?: InputMaybe<DbLeadBundlingAggregateOrderBy>;
  leadExpiryByLead?: InputMaybe<DbLeadExpiryOrderBy>;
  leadSource?: InputMaybe<EnumsLeadSourceOrderBy>;
  leadStatus?: InputMaybe<EnumsLeadStatusOrderBy>;
  leadStatusReason?: InputMaybe<EnumsLeadStatusReasonOrderBy>;
  mostRecentActivityCreatedAt?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  peLinkedPropertyId?: InputMaybe<OrderBy>;
  personalNote?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  priorityByPriority?: InputMaybe<EnumsPriorityOrderBy>;
  qualificationFormLinksAggregate?: InputMaybe<DbQualificationFormLinkAggregateOrderBy>;
  referralInitiated?: InputMaybe<OrderBy>;
  referralsAggregate?: InputMaybe<DbReferralAggregateOrderBy>;
  referralsByReceivingLeadIdAggregate?: InputMaybe<DbReferralAggregateOrderBy>;
  sellerRentOutSpecification?: InputMaybe<DbSellerRentOutSpecificationOrderBy>;
  sellerRentOutSpecificationId?: InputMaybe<OrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  source?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  statusNote?: InputMaybe<OrderBy>;
  statusReason?: InputMaybe<OrderBy>;
  taskLeadsAggregate?: InputMaybe<DbTaskLeadAggregateOrderBy>;
  team?: InputMaybe<DbTeamOrderBy>;
  teamId?: InputMaybe<OrderBy>;
  timeFrame?: InputMaybe<OrderBy>;
  timeFrameByTimeFrame?: InputMaybe<EnumsTimeFrameOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  utmParams?: InputMaybe<DbUtmParamOrderBy>;
};

/** select columns of table "db.lead" */
export type DbLeadSelectColumn =
  /** column name */
  | 'agentId'
  /** column name */
  | 'buyerToRentSpecificationId'
  /** column name */
  | 'contactFirstName'
  /** column name */
  | 'contactId'
  /** column name */
  | 'contactLastName'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'customerMessage'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'expiredAt'
  /** column name */
  | 'go3PropertyId'
  /** column name */
  | 'go3SearchDemandKey'
  /** column name */
  | 'id'
  /** column name */
  | 'intentType'
  /** column name */
  | 'lastInteractionActivityId'
  /** column name */
  | 'lastInteractionActivityOrderDate'
  /** column name */
  | 'lck'
  /** column name */
  | 'mostRecentActivityCreatedAt'
  /** column name */
  | 'num'
  /** column name */
  | 'peLinkedPropertyId'
  /** column name */
  | 'personalNote'
  /** column name */
  | 'priority'
  /** column name */
  | 'referralInitiated'
  /** column name */
  | 'sellerRentOutSpecificationId'
  /** column name */
  | 'shopId'
  /** column name */
  | 'source'
  /** column name */
  | 'status'
  /** column name */
  | 'statusNote'
  /** column name */
  | 'statusReason'
  /** column name */
  | 'teamId'
  /** column name */
  | 'timeFrame'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbLeadAggregateBoolExpBool_andArgumentsColumns" columns of table "db.lead" */
export type DbLeadSelectColumnDbLeadAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  | 'deleted'
  /** column name */
  | 'referralInitiated';

/** select "dbLeadAggregateBoolExpBool_orArgumentsColumns" columns of table "db.lead" */
export type DbLeadSelectColumnDbLeadAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  | 'deleted'
  /** column name */
  | 'referralInitiated';

/** columns and relationships of "db.lead_statistics" */
export type DbLeadStatistics = {
  __typename?: 'DbLeadStatistics';
  count?: Maybe<Scalars['bigint']>;
  createdDate?: Maybe<Scalars['date']>;
  originalLeadSource?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "db.lead_statistics". All fields are combined with a logical 'AND'. */
export type DbLeadStatisticsBoolExp = {
  _and?: InputMaybe<Array<DbLeadStatisticsBoolExp>>;
  _not?: InputMaybe<DbLeadStatisticsBoolExp>;
  _or?: InputMaybe<Array<DbLeadStatisticsBoolExp>>;
  count?: InputMaybe<BigintComparisonExp>;
  createdDate?: InputMaybe<DateComparisonExp>;
  originalLeadSource?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "db.lead_statistics". */
export type DbLeadStatisticsOrderBy = {
  count?: InputMaybe<OrderBy>;
  createdDate?: InputMaybe<OrderBy>;
  originalLeadSource?: InputMaybe<OrderBy>;
};

/** select columns of table "db.lead_statistics" */
export type DbLeadStatisticsSelectColumn =
  /** column name */
  | 'count'
  /** column name */
  | 'createdDate'
  /** column name */
  | 'originalLeadSource';

/** Streaming cursor of the table "db_lead_statistics" */
export type DbLeadStatisticsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbLeadStatisticsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbLeadStatisticsStreamCursorValueInput = {
  count?: InputMaybe<Scalars['bigint']>;
  createdDate?: InputMaybe<Scalars['date']>;
  originalLeadSource?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type DbLeadStddevFields = {
  __typename?: 'DbLeadStddevFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.lead" */
export type DbLeadStddevOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbLeadStddevPopFields = {
  __typename?: 'DbLeadStddevPopFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.lead" */
export type DbLeadStddevPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbLeadStddevSampFields = {
  __typename?: 'DbLeadStddevSampFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.lead" */
export type DbLeadStddevSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_lead" */
export type DbLeadStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbLeadStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbLeadStreamCursorValueInput = {
  agentId?: InputMaybe<Scalars['String']>;
  buyerToRentSpecificationId?: InputMaybe<Scalars['String']>;
  contactFirstName?: InputMaybe<Scalars['String']>;
  contactId?: InputMaybe<Scalars['String']>;
  contactLastName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  customerMessage?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  expiredAt?: InputMaybe<Scalars['timestamptz']>;
  go3PropertyId?: InputMaybe<Scalars['String']>;
  go3SearchDemandKey?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  intentType?: InputMaybe<IntentTypeEnum>;
  lastInteractionActivityId?: InputMaybe<Scalars['String']>;
  lastInteractionActivityOrderDate?: InputMaybe<Scalars['timestamptz']>;
  lck?: InputMaybe<Scalars['Int']>;
  mostRecentActivityCreatedAt?: InputMaybe<Scalars['timestamptz']>;
  num?: InputMaybe<Scalars['bigint']>;
  peLinkedPropertyId?: InputMaybe<Scalars['String']>;
  personalNote?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<PriorityEnum>;
  referralInitiated?: InputMaybe<Scalars['Boolean']>;
  sellerRentOutSpecificationId?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<LeadSourceEnum>;
  status?: InputMaybe<LeadStatusEnum>;
  statusNote?: InputMaybe<Scalars['String']>;
  statusReason?: InputMaybe<LeadStatusReasonEnum>;
  teamId?: InputMaybe<Scalars['String']>;
  timeFrame?: InputMaybe<TimeFrameEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbLeadSumFields = {
  __typename?: 'DbLeadSumFields';
  lck?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.lead" */
export type DbLeadSumOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbLeadVarPopFields = {
  __typename?: 'DbLeadVarPopFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.lead" */
export type DbLeadVarPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbLeadVarSampFields = {
  __typename?: 'DbLeadVarSampFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.lead" */
export type DbLeadVarSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbLeadVarianceFields = {
  __typename?: 'DbLeadVarianceFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.lead" */
export type DbLeadVarianceOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.leadhub_go3_contact_link" */
export type DbLeadhubGo3ContactLink = {
  __typename?: 'DbLeadhubGo3ContactLink';
  /** An object relationship */
  contact: DbContact;
  contactId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  firstAgent: DbAgent;
  firstAgentId: Scalars['String'];
  firstIntentCategory: IntentCategoryEnum;
  go3FirstAgentId: Scalars['Int'];
  go3SecondAgentId?: Maybe<Scalars['Int']>;
  go3ThirdAgentId?: Maybe<Scalars['Int']>;
  go3Utag?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** An object relationship */
  intentCategory: EnumsIntentCategory;
  /** An object relationship */
  intentCategoryBySecondIntentCategory?: Maybe<EnumsIntentCategory>;
  /** An object relationship */
  intentCategoryByThirdIntentCategory?: Maybe<EnumsIntentCategory>;
  num: Scalars['bigint'];
  /** An object relationship */
  secondAgent?: Maybe<DbAgent>;
  secondAgentId?: Maybe<Scalars['String']>;
  secondIntentCategory?: Maybe<IntentCategoryEnum>;
  /** An object relationship */
  thirdAgent?: Maybe<DbAgent>;
  thirdAgentId?: Maybe<Scalars['String']>;
  thirdIntentCategory?: Maybe<IntentCategoryEnum>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.leadhub_go3_contact_link" */
export type DbLeadhubGo3ContactLinkAggregate = {
  __typename?: 'DbLeadhubGo3ContactLinkAggregate';
  aggregate?: Maybe<DbLeadhubGo3ContactLinkAggregateFields>;
  nodes: Array<DbLeadhubGo3ContactLink>;
};

export type DbLeadhubGo3ContactLinkAggregateBoolExp = {
  bool_and?: InputMaybe<DbLeadhubGo3ContactLinkAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbLeadhubGo3ContactLinkAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbLeadhubGo3ContactLinkAggregateBoolExpCount>;
};

/** aggregate fields of "db.leadhub_go3_contact_link" */
export type DbLeadhubGo3ContactLinkAggregateFields = {
  __typename?: 'DbLeadhubGo3ContactLinkAggregateFields';
  avg?: Maybe<DbLeadhubGo3ContactLinkAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbLeadhubGo3ContactLinkMaxFields>;
  min?: Maybe<DbLeadhubGo3ContactLinkMinFields>;
  stddev?: Maybe<DbLeadhubGo3ContactLinkStddevFields>;
  stddevPop?: Maybe<DbLeadhubGo3ContactLinkStddevPopFields>;
  stddevSamp?: Maybe<DbLeadhubGo3ContactLinkStddevSampFields>;
  sum?: Maybe<DbLeadhubGo3ContactLinkSumFields>;
  varPop?: Maybe<DbLeadhubGo3ContactLinkVarPopFields>;
  varSamp?: Maybe<DbLeadhubGo3ContactLinkVarSampFields>;
  variance?: Maybe<DbLeadhubGo3ContactLinkVarianceFields>;
};

/** aggregate fields of "db.leadhub_go3_contact_link" */
export type DbLeadhubGo3ContactLinkAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbLeadhubGo3ContactLinkSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.leadhub_go3_contact_link" */
export type DbLeadhubGo3ContactLinkAggregateOrderBy = {
  avg?: InputMaybe<DbLeadhubGo3ContactLinkAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbLeadhubGo3ContactLinkMaxOrderBy>;
  min?: InputMaybe<DbLeadhubGo3ContactLinkMinOrderBy>;
  stddev?: InputMaybe<DbLeadhubGo3ContactLinkStddevOrderBy>;
  stddevPop?: InputMaybe<DbLeadhubGo3ContactLinkStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbLeadhubGo3ContactLinkStddevSampOrderBy>;
  sum?: InputMaybe<DbLeadhubGo3ContactLinkSumOrderBy>;
  varPop?: InputMaybe<DbLeadhubGo3ContactLinkVarPopOrderBy>;
  varSamp?: InputMaybe<DbLeadhubGo3ContactLinkVarSampOrderBy>;
  variance?: InputMaybe<DbLeadhubGo3ContactLinkVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbLeadhubGo3ContactLinkAvgFields = {
  __typename?: 'DbLeadhubGo3ContactLinkAvgFields';
  go3FirstAgentId?: Maybe<Scalars['Float']>;
  go3SecondAgentId?: Maybe<Scalars['Float']>;
  go3ThirdAgentId?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.leadhub_go3_contact_link" */
export type DbLeadhubGo3ContactLinkAvgOrderBy = {
  go3FirstAgentId?: InputMaybe<OrderBy>;
  go3SecondAgentId?: InputMaybe<OrderBy>;
  go3ThirdAgentId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.leadhub_go3_contact_link". All fields are combined with a logical 'AND'. */
export type DbLeadhubGo3ContactLinkBoolExp = {
  _and?: InputMaybe<Array<DbLeadhubGo3ContactLinkBoolExp>>;
  _not?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
  _or?: InputMaybe<Array<DbLeadhubGo3ContactLinkBoolExp>>;
  contact?: InputMaybe<DbContactBoolExp>;
  contactId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  firstAgent?: InputMaybe<DbAgentBoolExp>;
  firstAgentId?: InputMaybe<StringComparisonExp>;
  firstIntentCategory?: InputMaybe<EnumsIntentCategoryEnumComparisonExp>;
  go3FirstAgentId?: InputMaybe<IntComparisonExp>;
  go3SecondAgentId?: InputMaybe<IntComparisonExp>;
  go3ThirdAgentId?: InputMaybe<IntComparisonExp>;
  go3Utag?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  intentCategory?: InputMaybe<EnumsIntentCategoryBoolExp>;
  intentCategoryBySecondIntentCategory?: InputMaybe<EnumsIntentCategoryBoolExp>;
  intentCategoryByThirdIntentCategory?: InputMaybe<EnumsIntentCategoryBoolExp>;
  num?: InputMaybe<BigintComparisonExp>;
  secondAgent?: InputMaybe<DbAgentBoolExp>;
  secondAgentId?: InputMaybe<StringComparisonExp>;
  secondIntentCategory?: InputMaybe<EnumsIntentCategoryEnumComparisonExp>;
  thirdAgent?: InputMaybe<DbAgentBoolExp>;
  thirdAgentId?: InputMaybe<StringComparisonExp>;
  thirdIntentCategory?: InputMaybe<EnumsIntentCategoryEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbLeadhubGo3ContactLinkMaxFields = {
  __typename?: 'DbLeadhubGo3ContactLinkMaxFields';
  contactId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  firstAgentId?: Maybe<Scalars['String']>;
  go3FirstAgentId?: Maybe<Scalars['Int']>;
  go3SecondAgentId?: Maybe<Scalars['Int']>;
  go3ThirdAgentId?: Maybe<Scalars['Int']>;
  go3Utag?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  secondAgentId?: Maybe<Scalars['String']>;
  thirdAgentId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.leadhub_go3_contact_link" */
export type DbLeadhubGo3ContactLinkMaxOrderBy = {
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  firstAgentId?: InputMaybe<OrderBy>;
  go3FirstAgentId?: InputMaybe<OrderBy>;
  go3SecondAgentId?: InputMaybe<OrderBy>;
  go3ThirdAgentId?: InputMaybe<OrderBy>;
  go3Utag?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  secondAgentId?: InputMaybe<OrderBy>;
  thirdAgentId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbLeadhubGo3ContactLinkMinFields = {
  __typename?: 'DbLeadhubGo3ContactLinkMinFields';
  contactId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  firstAgentId?: Maybe<Scalars['String']>;
  go3FirstAgentId?: Maybe<Scalars['Int']>;
  go3SecondAgentId?: Maybe<Scalars['Int']>;
  go3ThirdAgentId?: Maybe<Scalars['Int']>;
  go3Utag?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  secondAgentId?: Maybe<Scalars['String']>;
  thirdAgentId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.leadhub_go3_contact_link" */
export type DbLeadhubGo3ContactLinkMinOrderBy = {
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  firstAgentId?: InputMaybe<OrderBy>;
  go3FirstAgentId?: InputMaybe<OrderBy>;
  go3SecondAgentId?: InputMaybe<OrderBy>;
  go3ThirdAgentId?: InputMaybe<OrderBy>;
  go3Utag?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  secondAgentId?: InputMaybe<OrderBy>;
  thirdAgentId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.leadhub_go3_contact_link". */
export type DbLeadhubGo3ContactLinkOrderBy = {
  contact?: InputMaybe<DbContactOrderBy>;
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  firstAgent?: InputMaybe<DbAgentOrderBy>;
  firstAgentId?: InputMaybe<OrderBy>;
  firstIntentCategory?: InputMaybe<OrderBy>;
  go3FirstAgentId?: InputMaybe<OrderBy>;
  go3SecondAgentId?: InputMaybe<OrderBy>;
  go3ThirdAgentId?: InputMaybe<OrderBy>;
  go3Utag?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  intentCategory?: InputMaybe<EnumsIntentCategoryOrderBy>;
  intentCategoryBySecondIntentCategory?: InputMaybe<EnumsIntentCategoryOrderBy>;
  intentCategoryByThirdIntentCategory?: InputMaybe<EnumsIntentCategoryOrderBy>;
  num?: InputMaybe<OrderBy>;
  secondAgent?: InputMaybe<DbAgentOrderBy>;
  secondAgentId?: InputMaybe<OrderBy>;
  secondIntentCategory?: InputMaybe<OrderBy>;
  thirdAgent?: InputMaybe<DbAgentOrderBy>;
  thirdAgentId?: InputMaybe<OrderBy>;
  thirdIntentCategory?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.leadhub_go3_contact_link" */
export type DbLeadhubGo3ContactLinkSelectColumn =
  /** column name */
  | 'contactId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'firstAgentId'
  /** column name */
  | 'firstIntentCategory'
  /** column name */
  | 'go3FirstAgentId'
  /** column name */
  | 'go3SecondAgentId'
  /** column name */
  | 'go3ThirdAgentId'
  /** column name */
  | 'go3Utag'
  /** column name */
  | 'id'
  /** column name */
  | 'num'
  /** column name */
  | 'secondAgentId'
  /** column name */
  | 'secondIntentCategory'
  /** column name */
  | 'thirdAgentId'
  /** column name */
  | 'thirdIntentCategory'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbLeadhubGo3ContactLinkAggregateBoolExpBool_andArgumentsColumns" columns of table "db.leadhub_go3_contact_link" */
export type DbLeadhubGo3ContactLinkSelectColumnDbLeadhubGo3ContactLinkAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbLeadhubGo3ContactLinkAggregateBoolExpBool_orArgumentsColumns" columns of table "db.leadhub_go3_contact_link" */
export type DbLeadhubGo3ContactLinkSelectColumnDbLeadhubGo3ContactLinkAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbLeadhubGo3ContactLinkStddevFields = {
  __typename?: 'DbLeadhubGo3ContactLinkStddevFields';
  go3FirstAgentId?: Maybe<Scalars['Float']>;
  go3SecondAgentId?: Maybe<Scalars['Float']>;
  go3ThirdAgentId?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.leadhub_go3_contact_link" */
export type DbLeadhubGo3ContactLinkStddevOrderBy = {
  go3FirstAgentId?: InputMaybe<OrderBy>;
  go3SecondAgentId?: InputMaybe<OrderBy>;
  go3ThirdAgentId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbLeadhubGo3ContactLinkStddevPopFields = {
  __typename?: 'DbLeadhubGo3ContactLinkStddevPopFields';
  go3FirstAgentId?: Maybe<Scalars['Float']>;
  go3SecondAgentId?: Maybe<Scalars['Float']>;
  go3ThirdAgentId?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.leadhub_go3_contact_link" */
export type DbLeadhubGo3ContactLinkStddevPopOrderBy = {
  go3FirstAgentId?: InputMaybe<OrderBy>;
  go3SecondAgentId?: InputMaybe<OrderBy>;
  go3ThirdAgentId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbLeadhubGo3ContactLinkStddevSampFields = {
  __typename?: 'DbLeadhubGo3ContactLinkStddevSampFields';
  go3FirstAgentId?: Maybe<Scalars['Float']>;
  go3SecondAgentId?: Maybe<Scalars['Float']>;
  go3ThirdAgentId?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.leadhub_go3_contact_link" */
export type DbLeadhubGo3ContactLinkStddevSampOrderBy = {
  go3FirstAgentId?: InputMaybe<OrderBy>;
  go3SecondAgentId?: InputMaybe<OrderBy>;
  go3ThirdAgentId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_leadhub_go3_contact_link" */
export type DbLeadhubGo3ContactLinkStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbLeadhubGo3ContactLinkStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbLeadhubGo3ContactLinkStreamCursorValueInput = {
  contactId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  firstAgentId?: InputMaybe<Scalars['String']>;
  firstIntentCategory?: InputMaybe<IntentCategoryEnum>;
  go3FirstAgentId?: InputMaybe<Scalars['Int']>;
  go3SecondAgentId?: InputMaybe<Scalars['Int']>;
  go3ThirdAgentId?: InputMaybe<Scalars['Int']>;
  go3Utag?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  secondAgentId?: InputMaybe<Scalars['String']>;
  secondIntentCategory?: InputMaybe<IntentCategoryEnum>;
  thirdAgentId?: InputMaybe<Scalars['String']>;
  thirdIntentCategory?: InputMaybe<IntentCategoryEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbLeadhubGo3ContactLinkSumFields = {
  __typename?: 'DbLeadhubGo3ContactLinkSumFields';
  go3FirstAgentId?: Maybe<Scalars['Int']>;
  go3SecondAgentId?: Maybe<Scalars['Int']>;
  go3ThirdAgentId?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.leadhub_go3_contact_link" */
export type DbLeadhubGo3ContactLinkSumOrderBy = {
  go3FirstAgentId?: InputMaybe<OrderBy>;
  go3SecondAgentId?: InputMaybe<OrderBy>;
  go3ThirdAgentId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbLeadhubGo3ContactLinkVarPopFields = {
  __typename?: 'DbLeadhubGo3ContactLinkVarPopFields';
  go3FirstAgentId?: Maybe<Scalars['Float']>;
  go3SecondAgentId?: Maybe<Scalars['Float']>;
  go3ThirdAgentId?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.leadhub_go3_contact_link" */
export type DbLeadhubGo3ContactLinkVarPopOrderBy = {
  go3FirstAgentId?: InputMaybe<OrderBy>;
  go3SecondAgentId?: InputMaybe<OrderBy>;
  go3ThirdAgentId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbLeadhubGo3ContactLinkVarSampFields = {
  __typename?: 'DbLeadhubGo3ContactLinkVarSampFields';
  go3FirstAgentId?: Maybe<Scalars['Float']>;
  go3SecondAgentId?: Maybe<Scalars['Float']>;
  go3ThirdAgentId?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.leadhub_go3_contact_link" */
export type DbLeadhubGo3ContactLinkVarSampOrderBy = {
  go3FirstAgentId?: InputMaybe<OrderBy>;
  go3SecondAgentId?: InputMaybe<OrderBy>;
  go3ThirdAgentId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbLeadhubGo3ContactLinkVarianceFields = {
  __typename?: 'DbLeadhubGo3ContactLinkVarianceFields';
  go3FirstAgentId?: Maybe<Scalars['Float']>;
  go3SecondAgentId?: Maybe<Scalars['Float']>;
  go3ThirdAgentId?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.leadhub_go3_contact_link" */
export type DbLeadhubGo3ContactLinkVarianceOrderBy = {
  go3FirstAgentId?: InputMaybe<OrderBy>;
  go3SecondAgentId?: InputMaybe<OrderBy>;
  go3ThirdAgentId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.legacy_go3_change_log" */
export type DbLegacyGo3ChangeLog = {
  __typename?: 'DbLegacyGo3ChangeLog';
  changeLogActId?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  fieldName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** An object relationship */
  legacy_go3_change_log_base_activity?: Maybe<DbBaseActivity>;
  newValue?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.legacy_go3_change_log" */
export type DbLegacyGo3ChangeLogAggregateOrderBy = {
  avg?: InputMaybe<DbLegacyGo3ChangeLogAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbLegacyGo3ChangeLogMaxOrderBy>;
  min?: InputMaybe<DbLegacyGo3ChangeLogMinOrderBy>;
  stddev?: InputMaybe<DbLegacyGo3ChangeLogStddevOrderBy>;
  stddevPop?: InputMaybe<DbLegacyGo3ChangeLogStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbLegacyGo3ChangeLogStddevSampOrderBy>;
  sum?: InputMaybe<DbLegacyGo3ChangeLogSumOrderBy>;
  varPop?: InputMaybe<DbLegacyGo3ChangeLogVarPopOrderBy>;
  varSamp?: InputMaybe<DbLegacyGo3ChangeLogVarSampOrderBy>;
  variance?: InputMaybe<DbLegacyGo3ChangeLogVarianceOrderBy>;
};

/** order by avg() on columns of table "db.legacy_go3_change_log" */
export type DbLegacyGo3ChangeLogAvgOrderBy = {
  revision?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.legacy_go3_change_log". All fields are combined with a logical 'AND'. */
export type DbLegacyGo3ChangeLogBoolExp = {
  _and?: InputMaybe<Array<DbLegacyGo3ChangeLogBoolExp>>;
  _not?: InputMaybe<DbLegacyGo3ChangeLogBoolExp>;
  _or?: InputMaybe<Array<DbLegacyGo3ChangeLogBoolExp>>;
  changeLogActId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  fieldName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  legacy_go3_change_log_base_activity?: InputMaybe<DbBaseActivityBoolExp>;
  newValue?: InputMaybe<StringComparisonExp>;
  oldValue?: InputMaybe<StringComparisonExp>;
  revision?: InputMaybe<IntComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.legacy_go3_change_log" */
export type DbLegacyGo3ChangeLogMaxOrderBy = {
  changeLogActId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  fieldName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  newValue?: InputMaybe<OrderBy>;
  oldValue?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.legacy_go3_change_log" */
export type DbLegacyGo3ChangeLogMinOrderBy = {
  changeLogActId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  fieldName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  newValue?: InputMaybe<OrderBy>;
  oldValue?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.legacy_go3_change_log". */
export type DbLegacyGo3ChangeLogOrderBy = {
  changeLogActId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  fieldName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  legacy_go3_change_log_base_activity?: InputMaybe<DbBaseActivityOrderBy>;
  newValue?: InputMaybe<OrderBy>;
  oldValue?: InputMaybe<OrderBy>;
  revision?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.legacy_go3_change_log" */
export type DbLegacyGo3ChangeLogSelectColumn =
  /** column name */
  | 'changeLogActId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'fieldName'
  /** column name */
  | 'id'
  /** column name */
  | 'newValue'
  /** column name */
  | 'oldValue'
  /** column name */
  | 'revision'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.legacy_go3_change_log" */
export type DbLegacyGo3ChangeLogStddevOrderBy = {
  revision?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.legacy_go3_change_log" */
export type DbLegacyGo3ChangeLogStddevPopOrderBy = {
  revision?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.legacy_go3_change_log" */
export type DbLegacyGo3ChangeLogStddevSampOrderBy = {
  revision?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_legacy_go3_change_log" */
export type DbLegacyGo3ChangeLogStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbLegacyGo3ChangeLogStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbLegacyGo3ChangeLogStreamCursorValueInput = {
  changeLogActId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  fieldName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  newValue?: InputMaybe<Scalars['String']>;
  oldValue?: InputMaybe<Scalars['String']>;
  revision?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.legacy_go3_change_log" */
export type DbLegacyGo3ChangeLogSumOrderBy = {
  revision?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.legacy_go3_change_log" */
export type DbLegacyGo3ChangeLogVarPopOrderBy = {
  revision?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.legacy_go3_change_log" */
export type DbLegacyGo3ChangeLogVarSampOrderBy = {
  revision?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.legacy_go3_change_log" */
export type DbLegacyGo3ChangeLogVarianceOrderBy = {
  revision?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.legal_entity" */
export type DbLegalEntity = {
  __typename?: 'DbLegalEntity';
  /** An array relationship */
  contacts: Array<DbContact>;
  /** An aggregate relationship */
  contactsAggregate: DbContactAggregate;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  department?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  num: Scalars['bigint'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** columns and relationships of "db.legal_entity" */
export type DbLegalEntityContactsArgs = {
  distinctOn?: InputMaybe<Array<DbContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactOrderBy>>;
  where?: InputMaybe<DbContactBoolExp>;
};

/** columns and relationships of "db.legal_entity" */
export type DbLegalEntityContactsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactOrderBy>>;
  where?: InputMaybe<DbContactBoolExp>;
};

/** aggregated selection of "db.legal_entity" */
export type DbLegalEntityAggregate = {
  __typename?: 'DbLegalEntityAggregate';
  aggregate?: Maybe<DbLegalEntityAggregateFields>;
  nodes: Array<DbLegalEntity>;
};

/** aggregate fields of "db.legal_entity" */
export type DbLegalEntityAggregateFields = {
  __typename?: 'DbLegalEntityAggregateFields';
  avg?: Maybe<DbLegalEntityAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbLegalEntityMaxFields>;
  min?: Maybe<DbLegalEntityMinFields>;
  stddev?: Maybe<DbLegalEntityStddevFields>;
  stddevPop?: Maybe<DbLegalEntityStddevPopFields>;
  stddevSamp?: Maybe<DbLegalEntityStddevSampFields>;
  sum?: Maybe<DbLegalEntitySumFields>;
  varPop?: Maybe<DbLegalEntityVarPopFields>;
  varSamp?: Maybe<DbLegalEntityVarSampFields>;
  variance?: Maybe<DbLegalEntityVarianceFields>;
};

/** aggregate fields of "db.legal_entity" */
export type DbLegalEntityAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbLegalEntitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type DbLegalEntityAvgFields = {
  __typename?: 'DbLegalEntityAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "db.legal_entity". All fields are combined with a logical 'AND'. */
export type DbLegalEntityBoolExp = {
  _and?: InputMaybe<Array<DbLegalEntityBoolExp>>;
  _not?: InputMaybe<DbLegalEntityBoolExp>;
  _or?: InputMaybe<Array<DbLegalEntityBoolExp>>;
  contacts?: InputMaybe<DbContactBoolExp>;
  contactsAggregate?: InputMaybe<DbContactAggregateBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  department?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbLegalEntityMaxFields = {
  __typename?: 'DbLegalEntityMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  department?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type DbLegalEntityMinFields = {
  __typename?: 'DbLegalEntityMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  department?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "db.legal_entity". */
export type DbLegalEntityOrderBy = {
  contactsAggregate?: InputMaybe<DbContactAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  department?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.legal_entity" */
export type DbLegalEntitySelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'department'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'num'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** aggregate stddev on columns */
export type DbLegalEntityStddevFields = {
  __typename?: 'DbLegalEntityStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type DbLegalEntityStddevPopFields = {
  __typename?: 'DbLegalEntityStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type DbLegalEntityStddevSampFields = {
  __typename?: 'DbLegalEntityStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "db_legal_entity" */
export type DbLegalEntityStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbLegalEntityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbLegalEntityStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  department?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbLegalEntitySumFields = {
  __typename?: 'DbLegalEntitySumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** aggregate varPop on columns */
export type DbLegalEntityVarPopFields = {
  __typename?: 'DbLegalEntityVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type DbLegalEntityVarSampFields = {
  __typename?: 'DbLegalEntityVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type DbLegalEntityVarianceFields = {
  __typename?: 'DbLegalEntityVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "db.license_partner" */
export type DbLicensePartner = {
  __typename?: 'DbLicensePartner';
  /** An array relationship */
  baseActivities: Array<DbBaseActivity>;
  /** An aggregate relationship */
  baseActivitiesAggregate: DbBaseActivityAggregate;
  /** An array relationship */
  consents: Array<DbConsent>;
  /** An aggregate relationship */
  consentsAggregate: DbConsentAggregate;
  countryCode: CountryEnum;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  debtorNumber?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  isCompanyOwnedOperation?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  licensePartnerStatus?: Maybe<EnumsLicensePartnerStatus>;
  name: Scalars['String'];
  num: Scalars['bigint'];
  /** An object relationship */
  primaryShop?: Maybe<DbShop>;
  primaryShopId?: Maybe<Scalars['String']>;
  /** An array relationship */
  profiles: Array<DbLicensePartnerProfile>;
  /** An aggregate relationship */
  profilesAggregate: DbLicensePartnerProfileAggregate;
  /** An array relationship */
  shops: Array<DbShop>;
  /** An aggregate relationship */
  shopsAggregate: DbShopAggregate;
  status?: Maybe<LicensePartnerStatusEnum>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** columns and relationships of "db.license_partner" */
export type DbLicensePartnerBaseActivitiesArgs = {
  distinctOn?: InputMaybe<Array<DbBaseActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBaseActivityOrderBy>>;
  where?: InputMaybe<DbBaseActivityBoolExp>;
};

/** columns and relationships of "db.license_partner" */
export type DbLicensePartnerBaseActivitiesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbBaseActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBaseActivityOrderBy>>;
  where?: InputMaybe<DbBaseActivityBoolExp>;
};

/** columns and relationships of "db.license_partner" */
export type DbLicensePartnerConsentsArgs = {
  distinctOn?: InputMaybe<Array<DbConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentOrderBy>>;
  where?: InputMaybe<DbConsentBoolExp>;
};

/** columns and relationships of "db.license_partner" */
export type DbLicensePartnerConsentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentOrderBy>>;
  where?: InputMaybe<DbConsentBoolExp>;
};

/** columns and relationships of "db.license_partner" */
export type DbLicensePartnerProfilesArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileBoolExp>;
};

/** columns and relationships of "db.license_partner" */
export type DbLicensePartnerProfilesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileBoolExp>;
};

/** columns and relationships of "db.license_partner" */
export type DbLicensePartnerShopsArgs = {
  distinctOn?: InputMaybe<Array<DbShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopOrderBy>>;
  where?: InputMaybe<DbShopBoolExp>;
};

/** columns and relationships of "db.license_partner" */
export type DbLicensePartnerShopsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopOrderBy>>;
  where?: InputMaybe<DbShopBoolExp>;
};

/** aggregated selection of "db.license_partner" */
export type DbLicensePartnerAggregate = {
  __typename?: 'DbLicensePartnerAggregate';
  aggregate?: Maybe<DbLicensePartnerAggregateFields>;
  nodes: Array<DbLicensePartner>;
};

/** aggregate fields of "db.license_partner" */
export type DbLicensePartnerAggregateFields = {
  __typename?: 'DbLicensePartnerAggregateFields';
  avg?: Maybe<DbLicensePartnerAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbLicensePartnerMaxFields>;
  min?: Maybe<DbLicensePartnerMinFields>;
  stddev?: Maybe<DbLicensePartnerStddevFields>;
  stddevPop?: Maybe<DbLicensePartnerStddevPopFields>;
  stddevSamp?: Maybe<DbLicensePartnerStddevSampFields>;
  sum?: Maybe<DbLicensePartnerSumFields>;
  varPop?: Maybe<DbLicensePartnerVarPopFields>;
  varSamp?: Maybe<DbLicensePartnerVarSampFields>;
  variance?: Maybe<DbLicensePartnerVarianceFields>;
};

/** aggregate fields of "db.license_partner" */
export type DbLicensePartnerAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbLicensePartnerSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type DbLicensePartnerAvgFields = {
  __typename?: 'DbLicensePartnerAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "db.license_partner". All fields are combined with a logical 'AND'. */
export type DbLicensePartnerBoolExp = {
  _and?: InputMaybe<Array<DbLicensePartnerBoolExp>>;
  _not?: InputMaybe<DbLicensePartnerBoolExp>;
  _or?: InputMaybe<Array<DbLicensePartnerBoolExp>>;
  baseActivities?: InputMaybe<DbBaseActivityBoolExp>;
  baseActivitiesAggregate?: InputMaybe<DbBaseActivityAggregateBoolExp>;
  consents?: InputMaybe<DbConsentBoolExp>;
  consentsAggregate?: InputMaybe<DbConsentAggregateBoolExp>;
  countryCode?: InputMaybe<EnumsCountryEnumComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  debtorNumber?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  isCompanyOwnedOperation?: InputMaybe<BooleanComparisonExp>;
  licensePartnerStatus?: InputMaybe<EnumsLicensePartnerStatusBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  primaryShop?: InputMaybe<DbShopBoolExp>;
  primaryShopId?: InputMaybe<StringComparisonExp>;
  profiles?: InputMaybe<DbLicensePartnerProfileBoolExp>;
  profilesAggregate?: InputMaybe<DbLicensePartnerProfileAggregateBoolExp>;
  shops?: InputMaybe<DbShopBoolExp>;
  shopsAggregate?: InputMaybe<DbShopAggregateBoolExp>;
  status?: InputMaybe<EnumsLicensePartnerStatusEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbLicensePartnerMaxFields = {
  __typename?: 'DbLicensePartnerMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  debtorNumber?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  primaryShopId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type DbLicensePartnerMinFields = {
  __typename?: 'DbLicensePartnerMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  debtorNumber?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  primaryShopId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "db.license_partner". */
export type DbLicensePartnerOrderBy = {
  baseActivitiesAggregate?: InputMaybe<DbBaseActivityAggregateOrderBy>;
  consentsAggregate?: InputMaybe<DbConsentAggregateOrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  debtorNumber?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isCompanyOwnedOperation?: InputMaybe<OrderBy>;
  licensePartnerStatus?: InputMaybe<EnumsLicensePartnerStatusOrderBy>;
  name?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  primaryShop?: InputMaybe<DbShopOrderBy>;
  primaryShopId?: InputMaybe<OrderBy>;
  profilesAggregate?: InputMaybe<DbLicensePartnerProfileAggregateOrderBy>;
  shopsAggregate?: InputMaybe<DbShopAggregateOrderBy>;
  status?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.license_partner_profile" */
export type DbLicensePartnerProfile = {
  __typename?: 'DbLicensePartnerProfile';
  activated?: Maybe<Scalars['Boolean']>;
  clientId?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  criteoId?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  emailOther?: Maybe<Scalars['String']>;
  emailRecruiting?: Maybe<Scalars['String']>;
  facebookId?: Maybe<Scalars['String']>;
  facebookUrl?: Maybe<Scalars['String']>;
  /** An array relationship */
  featuredAgents: Array<DbLicensePartnerProfileFeaturedAgent>;
  googleAdsId?: Maybe<Scalars['String']>;
  googleAnalyticsAccountId?: Maybe<Scalars['String']>;
  googlePlaceId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  imprintUrl?: Maybe<Scalars['String']>;
  instagramUrl?: Maybe<Scalars['String']>;
  lck: Scalars['Int'];
  /** An object relationship */
  licensePartner: DbLicensePartner;
  licensePartnerId: Scalars['String'];
  /** An array relationship */
  licensePartnerProfileImages: Array<DbLicensePartnerProfileImage>;
  /** An aggregate relationship */
  licensePartnerProfileImagesAggregate: DbLicensePartnerProfileImageAggregate;
  /** An array relationship */
  licensePartnerProfileLanguageVariants: Array<DbLicensePartnerProfileLanguageVariant>;
  /** An aggregate relationship */
  licensePartnerProfileLanguageVariantsAggregate: DbLicensePartnerProfileLanguageVariantAggregate;
  linkedInUrl?: Maybe<Scalars['String']>;
  linkedinId?: Maybe<Scalars['String']>;
  microsoftId?: Maybe<Scalars['String']>;
  num: Scalars['bigint'];
  optin?: Maybe<Scalars['String']>;
  privacyPolicyUrl?: Maybe<Scalars['String']>;
  /** An array relationship */
  propertyPreviews: Array<DbLicensePartnerProfilePropertyPreview>;
  published?: Maybe<Scalars['timestamp']>;
  publishedBy?: Maybe<Scalars['String']>;
  recipientId?: Maybe<Scalars['String']>;
  tiktokUrl?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  xingUrl?: Maybe<Scalars['String']>;
  youtubeUrl?: Maybe<Scalars['String']>;
};

/** columns and relationships of "db.license_partner_profile" */
export type DbLicensePartnerProfileFeaturedAgentsArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileFeaturedAgentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileFeaturedAgentOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileFeaturedAgentBoolExp>;
};

/** columns and relationships of "db.license_partner_profile" */
export type DbLicensePartnerProfileLicensePartnerProfileImagesArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileImageOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileImageBoolExp>;
};

/** columns and relationships of "db.license_partner_profile" */
export type DbLicensePartnerProfileLicensePartnerProfileImagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileImageOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileImageBoolExp>;
};

/** columns and relationships of "db.license_partner_profile" */
export type DbLicensePartnerProfileLicensePartnerProfileLanguageVariantsArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileLanguageVariantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileLanguageVariantOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileLanguageVariantBoolExp>;
};

/** columns and relationships of "db.license_partner_profile" */
export type DbLicensePartnerProfileLicensePartnerProfileLanguageVariantsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileLanguageVariantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileLanguageVariantOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileLanguageVariantBoolExp>;
};

/** columns and relationships of "db.license_partner_profile" */
export type DbLicensePartnerProfilePropertyPreviewsArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfilePropertyPreviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfilePropertyPreviewOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfilePropertyPreviewBoolExp>;
};

/** aggregated selection of "db.license_partner_profile" */
export type DbLicensePartnerProfileAggregate = {
  __typename?: 'DbLicensePartnerProfileAggregate';
  aggregate?: Maybe<DbLicensePartnerProfileAggregateFields>;
  nodes: Array<DbLicensePartnerProfile>;
};

export type DbLicensePartnerProfileAggregateBoolExp = {
  bool_and?: InputMaybe<DbLicensePartnerProfileAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbLicensePartnerProfileAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbLicensePartnerProfileAggregateBoolExpCount>;
};

/** aggregate fields of "db.license_partner_profile" */
export type DbLicensePartnerProfileAggregateFields = {
  __typename?: 'DbLicensePartnerProfileAggregateFields';
  avg?: Maybe<DbLicensePartnerProfileAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbLicensePartnerProfileMaxFields>;
  min?: Maybe<DbLicensePartnerProfileMinFields>;
  stddev?: Maybe<DbLicensePartnerProfileStddevFields>;
  stddevPop?: Maybe<DbLicensePartnerProfileStddevPopFields>;
  stddevSamp?: Maybe<DbLicensePartnerProfileStddevSampFields>;
  sum?: Maybe<DbLicensePartnerProfileSumFields>;
  varPop?: Maybe<DbLicensePartnerProfileVarPopFields>;
  varSamp?: Maybe<DbLicensePartnerProfileVarSampFields>;
  variance?: Maybe<DbLicensePartnerProfileVarianceFields>;
};

/** aggregate fields of "db.license_partner_profile" */
export type DbLicensePartnerProfileAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbLicensePartnerProfileSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.license_partner_profile" */
export type DbLicensePartnerProfileAggregateOrderBy = {
  avg?: InputMaybe<DbLicensePartnerProfileAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbLicensePartnerProfileMaxOrderBy>;
  min?: InputMaybe<DbLicensePartnerProfileMinOrderBy>;
  stddev?: InputMaybe<DbLicensePartnerProfileStddevOrderBy>;
  stddevPop?: InputMaybe<DbLicensePartnerProfileStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbLicensePartnerProfileStddevSampOrderBy>;
  sum?: InputMaybe<DbLicensePartnerProfileSumOrderBy>;
  varPop?: InputMaybe<DbLicensePartnerProfileVarPopOrderBy>;
  varSamp?: InputMaybe<DbLicensePartnerProfileVarSampOrderBy>;
  variance?: InputMaybe<DbLicensePartnerProfileVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbLicensePartnerProfileAvgFields = {
  __typename?: 'DbLicensePartnerProfileAvgFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.license_partner_profile" */
export type DbLicensePartnerProfileAvgOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.license_partner_profile". All fields are combined with a logical 'AND'. */
export type DbLicensePartnerProfileBoolExp = {
  _and?: InputMaybe<Array<DbLicensePartnerProfileBoolExp>>;
  _not?: InputMaybe<DbLicensePartnerProfileBoolExp>;
  _or?: InputMaybe<Array<DbLicensePartnerProfileBoolExp>>;
  activated?: InputMaybe<BooleanComparisonExp>;
  clientId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  criteoId?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  emailOther?: InputMaybe<StringComparisonExp>;
  emailRecruiting?: InputMaybe<StringComparisonExp>;
  facebookId?: InputMaybe<StringComparisonExp>;
  facebookUrl?: InputMaybe<StringComparisonExp>;
  featuredAgents?: InputMaybe<DbLicensePartnerProfileFeaturedAgentBoolExp>;
  googleAdsId?: InputMaybe<StringComparisonExp>;
  googleAnalyticsAccountId?: InputMaybe<StringComparisonExp>;
  googlePlaceId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  imprintUrl?: InputMaybe<StringComparisonExp>;
  instagramUrl?: InputMaybe<StringComparisonExp>;
  lck?: InputMaybe<IntComparisonExp>;
  licensePartner?: InputMaybe<DbLicensePartnerBoolExp>;
  licensePartnerId?: InputMaybe<StringComparisonExp>;
  licensePartnerProfileImages?: InputMaybe<DbLicensePartnerProfileImageBoolExp>;
  licensePartnerProfileImagesAggregate?: InputMaybe<DbLicensePartnerProfileImageAggregateBoolExp>;
  licensePartnerProfileLanguageVariants?: InputMaybe<DbLicensePartnerProfileLanguageVariantBoolExp>;
  licensePartnerProfileLanguageVariantsAggregate?: InputMaybe<DbLicensePartnerProfileLanguageVariantAggregateBoolExp>;
  linkedInUrl?: InputMaybe<StringComparisonExp>;
  linkedinId?: InputMaybe<StringComparisonExp>;
  microsoftId?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  optin?: InputMaybe<StringComparisonExp>;
  privacyPolicyUrl?: InputMaybe<StringComparisonExp>;
  propertyPreviews?: InputMaybe<DbLicensePartnerProfilePropertyPreviewBoolExp>;
  published?: InputMaybe<TimestampComparisonExp>;
  publishedBy?: InputMaybe<StringComparisonExp>;
  recipientId?: InputMaybe<StringComparisonExp>;
  tiktokUrl?: InputMaybe<StringComparisonExp>;
  twitterUrl?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  xingUrl?: InputMaybe<StringComparisonExp>;
  youtubeUrl?: InputMaybe<StringComparisonExp>;
};

/** columns and relationships of "db.license_partner_profile_featured_agent" */
export type DbLicensePartnerProfileFeaturedAgent = {
  __typename?: 'DbLicensePartnerProfileFeaturedAgent';
  /** An object relationship */
  agent: DbAgent;
  agentId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  index: Scalars['Int'];
  lck: Scalars['Int'];
  /** An object relationship */
  licensePartnerProfile: DbLicensePartnerProfile;
  licensePartnerProfileId: Scalars['String'];
  num: Scalars['bigint'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.license_partner_profile_featured_agent" */
export type DbLicensePartnerProfileFeaturedAgentAggregateOrderBy = {
  avg?: InputMaybe<DbLicensePartnerProfileFeaturedAgentAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbLicensePartnerProfileFeaturedAgentMaxOrderBy>;
  min?: InputMaybe<DbLicensePartnerProfileFeaturedAgentMinOrderBy>;
  stddev?: InputMaybe<DbLicensePartnerProfileFeaturedAgentStddevOrderBy>;
  stddevPop?: InputMaybe<DbLicensePartnerProfileFeaturedAgentStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbLicensePartnerProfileFeaturedAgentStddevSampOrderBy>;
  sum?: InputMaybe<DbLicensePartnerProfileFeaturedAgentSumOrderBy>;
  varPop?: InputMaybe<DbLicensePartnerProfileFeaturedAgentVarPopOrderBy>;
  varSamp?: InputMaybe<DbLicensePartnerProfileFeaturedAgentVarSampOrderBy>;
  variance?: InputMaybe<DbLicensePartnerProfileFeaturedAgentVarianceOrderBy>;
};

/** order by avg() on columns of table "db.license_partner_profile_featured_agent" */
export type DbLicensePartnerProfileFeaturedAgentAvgOrderBy = {
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.license_partner_profile_featured_agent". All fields are combined with a logical 'AND'. */
export type DbLicensePartnerProfileFeaturedAgentBoolExp = {
  _and?: InputMaybe<Array<DbLicensePartnerProfileFeaturedAgentBoolExp>>;
  _not?: InputMaybe<DbLicensePartnerProfileFeaturedAgentBoolExp>;
  _or?: InputMaybe<Array<DbLicensePartnerProfileFeaturedAgentBoolExp>>;
  agent?: InputMaybe<DbAgentBoolExp>;
  agentId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  index?: InputMaybe<IntComparisonExp>;
  lck?: InputMaybe<IntComparisonExp>;
  licensePartnerProfile?: InputMaybe<DbLicensePartnerProfileBoolExp>;
  licensePartnerProfileId?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.license_partner_profile_featured_agent" */
export type DbLicensePartnerProfileFeaturedAgentMaxOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  licensePartnerProfileId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.license_partner_profile_featured_agent" */
export type DbLicensePartnerProfileFeaturedAgentMinOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  licensePartnerProfileId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.license_partner_profile_featured_agent". */
export type DbLicensePartnerProfileFeaturedAgentOrderBy = {
  agent?: InputMaybe<DbAgentOrderBy>;
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  licensePartnerProfile?: InputMaybe<DbLicensePartnerProfileOrderBy>;
  licensePartnerProfileId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.license_partner_profile_featured_agent" */
export type DbLicensePartnerProfileFeaturedAgentSelectColumn =
  /** column name */
  | 'agentId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'index'
  /** column name */
  | 'lck'
  /** column name */
  | 'licensePartnerProfileId'
  /** column name */
  | 'num'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.license_partner_profile_featured_agent" */
export type DbLicensePartnerProfileFeaturedAgentStddevOrderBy = {
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.license_partner_profile_featured_agent" */
export type DbLicensePartnerProfileFeaturedAgentStddevPopOrderBy = {
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.license_partner_profile_featured_agent" */
export type DbLicensePartnerProfileFeaturedAgentStddevSampOrderBy = {
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_license_partner_profile_featured_agent" */
export type DbLicensePartnerProfileFeaturedAgentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbLicensePartnerProfileFeaturedAgentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbLicensePartnerProfileFeaturedAgentStreamCursorValueInput = {
  agentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  index?: InputMaybe<Scalars['Int']>;
  lck?: InputMaybe<Scalars['Int']>;
  licensePartnerProfileId?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.license_partner_profile_featured_agent" */
export type DbLicensePartnerProfileFeaturedAgentSumOrderBy = {
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.license_partner_profile_featured_agent" */
export type DbLicensePartnerProfileFeaturedAgentVarPopOrderBy = {
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.license_partner_profile_featured_agent" */
export type DbLicensePartnerProfileFeaturedAgentVarSampOrderBy = {
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.license_partner_profile_featured_agent" */
export type DbLicensePartnerProfileFeaturedAgentVarianceOrderBy = {
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.license_partner_profile_featured_content_teaser" */
export type DbLicensePartnerProfileFeaturedContentTeaser = {
  __typename?: 'DbLicensePartnerProfileFeaturedContentTeaser';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  index: Scalars['Int'];
  /** An object relationship */
  languageVariant?: Maybe<DbLicensePartnerProfileLanguageVariant>;
  lck: Scalars['Int'];
  licensePartnerProfileLangVariantId: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  num: Scalars['bigint'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.license_partner_profile_featured_content_teaser" */
export type DbLicensePartnerProfileFeaturedContentTeaserAggregateOrderBy = {
  avg?: InputMaybe<DbLicensePartnerProfileFeaturedContentTeaserAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbLicensePartnerProfileFeaturedContentTeaserMaxOrderBy>;
  min?: InputMaybe<DbLicensePartnerProfileFeaturedContentTeaserMinOrderBy>;
  stddev?: InputMaybe<DbLicensePartnerProfileFeaturedContentTeaserStddevOrderBy>;
  stddevPop?: InputMaybe<DbLicensePartnerProfileFeaturedContentTeaserStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbLicensePartnerProfileFeaturedContentTeaserStddevSampOrderBy>;
  sum?: InputMaybe<DbLicensePartnerProfileFeaturedContentTeaserSumOrderBy>;
  varPop?: InputMaybe<DbLicensePartnerProfileFeaturedContentTeaserVarPopOrderBy>;
  varSamp?: InputMaybe<DbLicensePartnerProfileFeaturedContentTeaserVarSampOrderBy>;
  variance?: InputMaybe<DbLicensePartnerProfileFeaturedContentTeaserVarianceOrderBy>;
};

/** order by avg() on columns of table "db.license_partner_profile_featured_content_teaser" */
export type DbLicensePartnerProfileFeaturedContentTeaserAvgOrderBy = {
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.license_partner_profile_featured_content_teaser". All fields are combined with a logical 'AND'. */
export type DbLicensePartnerProfileFeaturedContentTeaserBoolExp = {
  _and?: InputMaybe<Array<DbLicensePartnerProfileFeaturedContentTeaserBoolExp>>;
  _not?: InputMaybe<DbLicensePartnerProfileFeaturedContentTeaserBoolExp>;
  _or?: InputMaybe<Array<DbLicensePartnerProfileFeaturedContentTeaserBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  headline?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  index?: InputMaybe<IntComparisonExp>;
  languageVariant?: InputMaybe<DbLicensePartnerProfileLanguageVariantBoolExp>;
  lck?: InputMaybe<IntComparisonExp>;
  licensePartnerProfileLangVariantId?: InputMaybe<StringComparisonExp>;
  link?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.license_partner_profile_featured_content_teaser" */
export type DbLicensePartnerProfileFeaturedContentTeaserMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  headline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  licensePartnerProfileLangVariantId?: InputMaybe<OrderBy>;
  link?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.license_partner_profile_featured_content_teaser" */
export type DbLicensePartnerProfileFeaturedContentTeaserMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  headline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  licensePartnerProfileLangVariantId?: InputMaybe<OrderBy>;
  link?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.license_partner_profile_featured_content_teaser". */
export type DbLicensePartnerProfileFeaturedContentTeaserOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  headline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  languageVariant?: InputMaybe<DbLicensePartnerProfileLanguageVariantOrderBy>;
  lck?: InputMaybe<OrderBy>;
  licensePartnerProfileLangVariantId?: InputMaybe<OrderBy>;
  link?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.license_partner_profile_featured_content_teaser" */
export type DbLicensePartnerProfileFeaturedContentTeaserSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'description'
  /** column name */
  | 'headline'
  /** column name */
  | 'id'
  /** column name */
  | 'index'
  /** column name */
  | 'lck'
  /** column name */
  | 'licensePartnerProfileLangVariantId'
  /** column name */
  | 'link'
  /** column name */
  | 'num'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.license_partner_profile_featured_content_teaser" */
export type DbLicensePartnerProfileFeaturedContentTeaserStddevOrderBy = {
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.license_partner_profile_featured_content_teaser" */
export type DbLicensePartnerProfileFeaturedContentTeaserStddevPopOrderBy = {
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.license_partner_profile_featured_content_teaser" */
export type DbLicensePartnerProfileFeaturedContentTeaserStddevSampOrderBy = {
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_license_partner_profile_featured_content_teaser" */
export type DbLicensePartnerProfileFeaturedContentTeaserStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbLicensePartnerProfileFeaturedContentTeaserStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbLicensePartnerProfileFeaturedContentTeaserStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  index?: InputMaybe<Scalars['Int']>;
  lck?: InputMaybe<Scalars['Int']>;
  licensePartnerProfileLangVariantId?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.license_partner_profile_featured_content_teaser" */
export type DbLicensePartnerProfileFeaturedContentTeaserSumOrderBy = {
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.license_partner_profile_featured_content_teaser" */
export type DbLicensePartnerProfileFeaturedContentTeaserVarPopOrderBy = {
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.license_partner_profile_featured_content_teaser" */
export type DbLicensePartnerProfileFeaturedContentTeaserVarSampOrderBy = {
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.license_partner_profile_featured_content_teaser" */
export type DbLicensePartnerProfileFeaturedContentTeaserVarianceOrderBy = {
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.license_partner_profile_former_url" */
export type DbLicensePartnerProfileFormerUrl = {
  __typename?: 'DbLicensePartnerProfileFormerUrl';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  formerUrl: Scalars['String'];
  id: Scalars['String'];
  lang: LanguageEnum;
  licensePartnerId: Scalars['String'];
  /** An object relationship */
  license_partner_profile_former_url_agent: DbAgent;
  /** An object relationship */
  license_partner_profile_former_url_agent_created_by: DbAgent;
  /** An object relationship */
  license_partner_profile_former_url_language: EnumsLanguage;
  /** An object relationship */
  license_partner_profile_former_url_license_partner: DbLicensePartner;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.license_partner_profile_former_url" */
export type DbLicensePartnerProfileFormerUrlAggregate = {
  __typename?: 'DbLicensePartnerProfileFormerUrlAggregate';
  aggregate?: Maybe<DbLicensePartnerProfileFormerUrlAggregateFields>;
  nodes: Array<DbLicensePartnerProfileFormerUrl>;
};

/** aggregate fields of "db.license_partner_profile_former_url" */
export type DbLicensePartnerProfileFormerUrlAggregateFields = {
  __typename?: 'DbLicensePartnerProfileFormerUrlAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<DbLicensePartnerProfileFormerUrlMaxFields>;
  min?: Maybe<DbLicensePartnerProfileFormerUrlMinFields>;
};

/** aggregate fields of "db.license_partner_profile_former_url" */
export type DbLicensePartnerProfileFormerUrlAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbLicensePartnerProfileFormerUrlSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "db.license_partner_profile_former_url". All fields are combined with a logical 'AND'. */
export type DbLicensePartnerProfileFormerUrlBoolExp = {
  _and?: InputMaybe<Array<DbLicensePartnerProfileFormerUrlBoolExp>>;
  _not?: InputMaybe<DbLicensePartnerProfileFormerUrlBoolExp>;
  _or?: InputMaybe<Array<DbLicensePartnerProfileFormerUrlBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  formerUrl?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lang?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  licensePartnerId?: InputMaybe<StringComparisonExp>;
  license_partner_profile_former_url_agent?: InputMaybe<DbAgentBoolExp>;
  license_partner_profile_former_url_agent_created_by?: InputMaybe<DbAgentBoolExp>;
  license_partner_profile_former_url_language?: InputMaybe<EnumsLanguageBoolExp>;
  license_partner_profile_former_url_license_partner?: InputMaybe<DbLicensePartnerBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbLicensePartnerProfileFormerUrlMaxFields = {
  __typename?: 'DbLicensePartnerProfileFormerUrlMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  formerUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  licensePartnerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type DbLicensePartnerProfileFormerUrlMinFields = {
  __typename?: 'DbLicensePartnerProfileFormerUrlMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  formerUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  licensePartnerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "db.license_partner_profile_former_url". */
export type DbLicensePartnerProfileFormerUrlOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  formerUrl?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lang?: InputMaybe<OrderBy>;
  licensePartnerId?: InputMaybe<OrderBy>;
  license_partner_profile_former_url_agent?: InputMaybe<DbAgentOrderBy>;
  license_partner_profile_former_url_agent_created_by?: InputMaybe<DbAgentOrderBy>;
  license_partner_profile_former_url_language?: InputMaybe<EnumsLanguageOrderBy>;
  license_partner_profile_former_url_license_partner?: InputMaybe<DbLicensePartnerOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.license_partner_profile_former_url" */
export type DbLicensePartnerProfileFormerUrlSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'formerUrl'
  /** column name */
  | 'id'
  /** column name */
  | 'lang'
  /** column name */
  | 'licensePartnerId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** Streaming cursor of the table "db_license_partner_profile_former_url" */
export type DbLicensePartnerProfileFormerUrlStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbLicensePartnerProfileFormerUrlStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbLicensePartnerProfileFormerUrlStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  formerUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<LanguageEnum>;
  licensePartnerId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "db.license_partner_profile_image" */
export type DbLicensePartnerProfileImage = {
  __typename?: 'DbLicensePartnerProfileImage';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  gcpFileName: Scalars['String'];
  height: Scalars['Int'];
  id: Scalars['String'];
  index: Scalars['Int'];
  /** An object relationship */
  licensePartnerProfile: DbLicensePartnerProfile;
  licensePartnerProfileId: Scalars['String'];
  num: Scalars['bigint'];
  title: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  width: Scalars['Int'];
};

/** aggregated selection of "db.license_partner_profile_image" */
export type DbLicensePartnerProfileImageAggregate = {
  __typename?: 'DbLicensePartnerProfileImageAggregate';
  aggregate?: Maybe<DbLicensePartnerProfileImageAggregateFields>;
  nodes: Array<DbLicensePartnerProfileImage>;
};

export type DbLicensePartnerProfileImageAggregateBoolExp = {
  bool_and?: InputMaybe<DbLicensePartnerProfileImageAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbLicensePartnerProfileImageAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbLicensePartnerProfileImageAggregateBoolExpCount>;
};

/** aggregate fields of "db.license_partner_profile_image" */
export type DbLicensePartnerProfileImageAggregateFields = {
  __typename?: 'DbLicensePartnerProfileImageAggregateFields';
  avg?: Maybe<DbLicensePartnerProfileImageAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbLicensePartnerProfileImageMaxFields>;
  min?: Maybe<DbLicensePartnerProfileImageMinFields>;
  stddev?: Maybe<DbLicensePartnerProfileImageStddevFields>;
  stddevPop?: Maybe<DbLicensePartnerProfileImageStddevPopFields>;
  stddevSamp?: Maybe<DbLicensePartnerProfileImageStddevSampFields>;
  sum?: Maybe<DbLicensePartnerProfileImageSumFields>;
  varPop?: Maybe<DbLicensePartnerProfileImageVarPopFields>;
  varSamp?: Maybe<DbLicensePartnerProfileImageVarSampFields>;
  variance?: Maybe<DbLicensePartnerProfileImageVarianceFields>;
};

/** aggregate fields of "db.license_partner_profile_image" */
export type DbLicensePartnerProfileImageAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbLicensePartnerProfileImageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.license_partner_profile_image" */
export type DbLicensePartnerProfileImageAggregateOrderBy = {
  avg?: InputMaybe<DbLicensePartnerProfileImageAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbLicensePartnerProfileImageMaxOrderBy>;
  min?: InputMaybe<DbLicensePartnerProfileImageMinOrderBy>;
  stddev?: InputMaybe<DbLicensePartnerProfileImageStddevOrderBy>;
  stddevPop?: InputMaybe<DbLicensePartnerProfileImageStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbLicensePartnerProfileImageStddevSampOrderBy>;
  sum?: InputMaybe<DbLicensePartnerProfileImageSumOrderBy>;
  varPop?: InputMaybe<DbLicensePartnerProfileImageVarPopOrderBy>;
  varSamp?: InputMaybe<DbLicensePartnerProfileImageVarSampOrderBy>;
  variance?: InputMaybe<DbLicensePartnerProfileImageVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbLicensePartnerProfileImageAvgFields = {
  __typename?: 'DbLicensePartnerProfileImageAvgFields';
  height?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.license_partner_profile_image" */
export type DbLicensePartnerProfileImageAvgOrderBy = {
  height?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.license_partner_profile_image". All fields are combined with a logical 'AND'. */
export type DbLicensePartnerProfileImageBoolExp = {
  _and?: InputMaybe<Array<DbLicensePartnerProfileImageBoolExp>>;
  _not?: InputMaybe<DbLicensePartnerProfileImageBoolExp>;
  _or?: InputMaybe<Array<DbLicensePartnerProfileImageBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  gcpFileName?: InputMaybe<StringComparisonExp>;
  height?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  index?: InputMaybe<IntComparisonExp>;
  licensePartnerProfile?: InputMaybe<DbLicensePartnerProfileBoolExp>;
  licensePartnerProfileId?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  width?: InputMaybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type DbLicensePartnerProfileImageMaxFields = {
  __typename?: 'DbLicensePartnerProfileImageMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  gcpFileName?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  licensePartnerProfileId?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "db.license_partner_profile_image" */
export type DbLicensePartnerProfileImageMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  gcpFileName?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  licensePartnerProfileId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbLicensePartnerProfileImageMinFields = {
  __typename?: 'DbLicensePartnerProfileImageMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  gcpFileName?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  licensePartnerProfileId?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "db.license_partner_profile_image" */
export type DbLicensePartnerProfileImageMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  gcpFileName?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  licensePartnerProfileId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.license_partner_profile_image". */
export type DbLicensePartnerProfileImageOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  gcpFileName?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  licensePartnerProfile?: InputMaybe<DbLicensePartnerProfileOrderBy>;
  licensePartnerProfileId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** select columns of table "db.license_partner_profile_image" */
export type DbLicensePartnerProfileImageSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'gcpFileName'
  /** column name */
  | 'height'
  /** column name */
  | 'id'
  /** column name */
  | 'index'
  /** column name */
  | 'licensePartnerProfileId'
  /** column name */
  | 'num'
  /** column name */
  | 'title'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy'
  /** column name */
  | 'width';

/** select "dbLicensePartnerProfileImageAggregateBoolExpBool_andArgumentsColumns" columns of table "db.license_partner_profile_image" */
export type DbLicensePartnerProfileImageSelectColumnDbLicensePartnerProfileImageAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbLicensePartnerProfileImageAggregateBoolExpBool_orArgumentsColumns" columns of table "db.license_partner_profile_image" */
export type DbLicensePartnerProfileImageSelectColumnDbLicensePartnerProfileImageAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbLicensePartnerProfileImageStddevFields = {
  __typename?: 'DbLicensePartnerProfileImageStddevFields';
  height?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.license_partner_profile_image" */
export type DbLicensePartnerProfileImageStddevOrderBy = {
  height?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbLicensePartnerProfileImageStddevPopFields = {
  __typename?: 'DbLicensePartnerProfileImageStddevPopFields';
  height?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.license_partner_profile_image" */
export type DbLicensePartnerProfileImageStddevPopOrderBy = {
  height?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbLicensePartnerProfileImageStddevSampFields = {
  __typename?: 'DbLicensePartnerProfileImageStddevSampFields';
  height?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.license_partner_profile_image" */
export type DbLicensePartnerProfileImageStddevSampOrderBy = {
  height?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_license_partner_profile_image" */
export type DbLicensePartnerProfileImageStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbLicensePartnerProfileImageStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbLicensePartnerProfileImageStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  gcpFileName?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  index?: InputMaybe<Scalars['Int']>;
  licensePartnerProfileId?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type DbLicensePartnerProfileImageSumFields = {
  __typename?: 'DbLicensePartnerProfileImageSumFields';
  height?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['bigint']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "db.license_partner_profile_image" */
export type DbLicensePartnerProfileImageSumOrderBy = {
  height?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbLicensePartnerProfileImageVarPopFields = {
  __typename?: 'DbLicensePartnerProfileImageVarPopFields';
  height?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.license_partner_profile_image" */
export type DbLicensePartnerProfileImageVarPopOrderBy = {
  height?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbLicensePartnerProfileImageVarSampFields = {
  __typename?: 'DbLicensePartnerProfileImageVarSampFields';
  height?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.license_partner_profile_image" */
export type DbLicensePartnerProfileImageVarSampOrderBy = {
  height?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbLicensePartnerProfileImageVarianceFields = {
  __typename?: 'DbLicensePartnerProfileImageVarianceFields';
  height?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.license_partner_profile_image" */
export type DbLicensePartnerProfileImageVarianceOrderBy = {
  height?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.license_partner_profile_language_variant" */
export type DbLicensePartnerProfileLanguageVariant = {
  __typename?: 'DbLicensePartnerProfileLanguageVariant';
  careerDesc?: Maybe<Scalars['String']>;
  careerHeadline?: Maybe<Scalars['String']>;
  careerIsAiTranslated?: Maybe<Scalars['Boolean']>;
  careerLink?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  featuredContentDescription?: Maybe<Scalars['String']>;
  featuredContentHeadline?: Maybe<Scalars['String']>;
  featuredContentIsAiTranslated?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  featuredContentTeasers: Array<DbLicensePartnerProfileFeaturedContentTeaser>;
  heroDesc?: Maybe<Scalars['String']>;
  heroHeadline?: Maybe<Scalars['String']>;
  heroIsAiTranslated?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  imprint?: Maybe<Scalars['String']>;
  imprintIsAiTranslated?: Maybe<Scalars['Boolean']>;
  introDescription?: Maybe<Scalars['String']>;
  introHeadline?: Maybe<Scalars['String']>;
  introIsAiTranslated?: Maybe<Scalars['Boolean']>;
  lang: LanguageEnum;
  /** An object relationship */
  language: EnumsLanguage;
  /** An object relationship */
  licensePartnerProfile: DbLicensePartnerProfile;
  licensePartnerProfileId: Scalars['String'];
  num: Scalars['bigint'];
  privacyPolicy?: Maybe<Scalars['String']>;
  privacyPolicyIsAiTranslated?: Maybe<Scalars['Boolean']>;
  shopOverviewDescription?: Maybe<Scalars['String']>;
  shopOverviewHeadline?: Maybe<Scalars['String']>;
  shopOverviewIsAiTranslated?: Maybe<Scalars['Boolean']>;
  slugOverride?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

/** columns and relationships of "db.license_partner_profile_language_variant" */
export type DbLicensePartnerProfileLanguageVariantFeaturedContentTeasersArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileFeaturedContentTeaserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileFeaturedContentTeaserOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileFeaturedContentTeaserBoolExp>;
};

/** aggregated selection of "db.license_partner_profile_language_variant" */
export type DbLicensePartnerProfileLanguageVariantAggregate = {
  __typename?: 'DbLicensePartnerProfileLanguageVariantAggregate';
  aggregate?: Maybe<DbLicensePartnerProfileLanguageVariantAggregateFields>;
  nodes: Array<DbLicensePartnerProfileLanguageVariant>;
};

export type DbLicensePartnerProfileLanguageVariantAggregateBoolExp = {
  bool_and?: InputMaybe<DbLicensePartnerProfileLanguageVariantAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbLicensePartnerProfileLanguageVariantAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbLicensePartnerProfileLanguageVariantAggregateBoolExpCount>;
};

/** aggregate fields of "db.license_partner_profile_language_variant" */
export type DbLicensePartnerProfileLanguageVariantAggregateFields = {
  __typename?: 'DbLicensePartnerProfileLanguageVariantAggregateFields';
  avg?: Maybe<DbLicensePartnerProfileLanguageVariantAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbLicensePartnerProfileLanguageVariantMaxFields>;
  min?: Maybe<DbLicensePartnerProfileLanguageVariantMinFields>;
  stddev?: Maybe<DbLicensePartnerProfileLanguageVariantStddevFields>;
  stddevPop?: Maybe<DbLicensePartnerProfileLanguageVariantStddevPopFields>;
  stddevSamp?: Maybe<DbLicensePartnerProfileLanguageVariantStddevSampFields>;
  sum?: Maybe<DbLicensePartnerProfileLanguageVariantSumFields>;
  varPop?: Maybe<DbLicensePartnerProfileLanguageVariantVarPopFields>;
  varSamp?: Maybe<DbLicensePartnerProfileLanguageVariantVarSampFields>;
  variance?: Maybe<DbLicensePartnerProfileLanguageVariantVarianceFields>;
};

/** aggregate fields of "db.license_partner_profile_language_variant" */
export type DbLicensePartnerProfileLanguageVariantAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbLicensePartnerProfileLanguageVariantSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.license_partner_profile_language_variant" */
export type DbLicensePartnerProfileLanguageVariantAggregateOrderBy = {
  avg?: InputMaybe<DbLicensePartnerProfileLanguageVariantAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbLicensePartnerProfileLanguageVariantMaxOrderBy>;
  min?: InputMaybe<DbLicensePartnerProfileLanguageVariantMinOrderBy>;
  stddev?: InputMaybe<DbLicensePartnerProfileLanguageVariantStddevOrderBy>;
  stddevPop?: InputMaybe<DbLicensePartnerProfileLanguageVariantStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbLicensePartnerProfileLanguageVariantStddevSampOrderBy>;
  sum?: InputMaybe<DbLicensePartnerProfileLanguageVariantSumOrderBy>;
  varPop?: InputMaybe<DbLicensePartnerProfileLanguageVariantVarPopOrderBy>;
  varSamp?: InputMaybe<DbLicensePartnerProfileLanguageVariantVarSampOrderBy>;
  variance?: InputMaybe<DbLicensePartnerProfileLanguageVariantVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbLicensePartnerProfileLanguageVariantAvgFields = {
  __typename?: 'DbLicensePartnerProfileLanguageVariantAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.license_partner_profile_language_variant" */
export type DbLicensePartnerProfileLanguageVariantAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.license_partner_profile_language_variant". All fields are combined with a logical 'AND'. */
export type DbLicensePartnerProfileLanguageVariantBoolExp = {
  _and?: InputMaybe<Array<DbLicensePartnerProfileLanguageVariantBoolExp>>;
  _not?: InputMaybe<DbLicensePartnerProfileLanguageVariantBoolExp>;
  _or?: InputMaybe<Array<DbLicensePartnerProfileLanguageVariantBoolExp>>;
  careerDesc?: InputMaybe<StringComparisonExp>;
  careerHeadline?: InputMaybe<StringComparisonExp>;
  careerIsAiTranslated?: InputMaybe<BooleanComparisonExp>;
  careerLink?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  displayName?: InputMaybe<StringComparisonExp>;
  featuredContentDescription?: InputMaybe<StringComparisonExp>;
  featuredContentHeadline?: InputMaybe<StringComparisonExp>;
  featuredContentIsAiTranslated?: InputMaybe<BooleanComparisonExp>;
  featuredContentTeasers?: InputMaybe<DbLicensePartnerProfileFeaturedContentTeaserBoolExp>;
  heroDesc?: InputMaybe<StringComparisonExp>;
  heroHeadline?: InputMaybe<StringComparisonExp>;
  heroIsAiTranslated?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  imprint?: InputMaybe<StringComparisonExp>;
  imprintIsAiTranslated?: InputMaybe<BooleanComparisonExp>;
  introDescription?: InputMaybe<StringComparisonExp>;
  introHeadline?: InputMaybe<StringComparisonExp>;
  introIsAiTranslated?: InputMaybe<BooleanComparisonExp>;
  lang?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  language?: InputMaybe<EnumsLanguageBoolExp>;
  licensePartnerProfile?: InputMaybe<DbLicensePartnerProfileBoolExp>;
  licensePartnerProfileId?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  privacyPolicy?: InputMaybe<StringComparisonExp>;
  privacyPolicyIsAiTranslated?: InputMaybe<BooleanComparisonExp>;
  shopOverviewDescription?: InputMaybe<StringComparisonExp>;
  shopOverviewHeadline?: InputMaybe<StringComparisonExp>;
  shopOverviewIsAiTranslated?: InputMaybe<BooleanComparisonExp>;
  slugOverride?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbLicensePartnerProfileLanguageVariantMaxFields = {
  __typename?: 'DbLicensePartnerProfileLanguageVariantMaxFields';
  careerDesc?: Maybe<Scalars['String']>;
  careerHeadline?: Maybe<Scalars['String']>;
  careerLink?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  featuredContentDescription?: Maybe<Scalars['String']>;
  featuredContentHeadline?: Maybe<Scalars['String']>;
  heroDesc?: Maybe<Scalars['String']>;
  heroHeadline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imprint?: Maybe<Scalars['String']>;
  introDescription?: Maybe<Scalars['String']>;
  introHeadline?: Maybe<Scalars['String']>;
  licensePartnerProfileId?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  privacyPolicy?: Maybe<Scalars['String']>;
  shopOverviewDescription?: Maybe<Scalars['String']>;
  shopOverviewHeadline?: Maybe<Scalars['String']>;
  slugOverride?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.license_partner_profile_language_variant" */
export type DbLicensePartnerProfileLanguageVariantMaxOrderBy = {
  careerDesc?: InputMaybe<OrderBy>;
  careerHeadline?: InputMaybe<OrderBy>;
  careerLink?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  displayName?: InputMaybe<OrderBy>;
  featuredContentDescription?: InputMaybe<OrderBy>;
  featuredContentHeadline?: InputMaybe<OrderBy>;
  heroDesc?: InputMaybe<OrderBy>;
  heroHeadline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imprint?: InputMaybe<OrderBy>;
  introDescription?: InputMaybe<OrderBy>;
  introHeadline?: InputMaybe<OrderBy>;
  licensePartnerProfileId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  privacyPolicy?: InputMaybe<OrderBy>;
  shopOverviewDescription?: InputMaybe<OrderBy>;
  shopOverviewHeadline?: InputMaybe<OrderBy>;
  slugOverride?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbLicensePartnerProfileLanguageVariantMinFields = {
  __typename?: 'DbLicensePartnerProfileLanguageVariantMinFields';
  careerDesc?: Maybe<Scalars['String']>;
  careerHeadline?: Maybe<Scalars['String']>;
  careerLink?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  featuredContentDescription?: Maybe<Scalars['String']>;
  featuredContentHeadline?: Maybe<Scalars['String']>;
  heroDesc?: Maybe<Scalars['String']>;
  heroHeadline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imprint?: Maybe<Scalars['String']>;
  introDescription?: Maybe<Scalars['String']>;
  introHeadline?: Maybe<Scalars['String']>;
  licensePartnerProfileId?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  privacyPolicy?: Maybe<Scalars['String']>;
  shopOverviewDescription?: Maybe<Scalars['String']>;
  shopOverviewHeadline?: Maybe<Scalars['String']>;
  slugOverride?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.license_partner_profile_language_variant" */
export type DbLicensePartnerProfileLanguageVariantMinOrderBy = {
  careerDesc?: InputMaybe<OrderBy>;
  careerHeadline?: InputMaybe<OrderBy>;
  careerLink?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  displayName?: InputMaybe<OrderBy>;
  featuredContentDescription?: InputMaybe<OrderBy>;
  featuredContentHeadline?: InputMaybe<OrderBy>;
  heroDesc?: InputMaybe<OrderBy>;
  heroHeadline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imprint?: InputMaybe<OrderBy>;
  introDescription?: InputMaybe<OrderBy>;
  introHeadline?: InputMaybe<OrderBy>;
  licensePartnerProfileId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  privacyPolicy?: InputMaybe<OrderBy>;
  shopOverviewDescription?: InputMaybe<OrderBy>;
  shopOverviewHeadline?: InputMaybe<OrderBy>;
  slugOverride?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.license_partner_profile_language_variant". */
export type DbLicensePartnerProfileLanguageVariantOrderBy = {
  careerDesc?: InputMaybe<OrderBy>;
  careerHeadline?: InputMaybe<OrderBy>;
  careerIsAiTranslated?: InputMaybe<OrderBy>;
  careerLink?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  displayName?: InputMaybe<OrderBy>;
  featuredContentDescription?: InputMaybe<OrderBy>;
  featuredContentHeadline?: InputMaybe<OrderBy>;
  featuredContentIsAiTranslated?: InputMaybe<OrderBy>;
  featuredContentTeasersAggregate?: InputMaybe<DbLicensePartnerProfileFeaturedContentTeaserAggregateOrderBy>;
  heroDesc?: InputMaybe<OrderBy>;
  heroHeadline?: InputMaybe<OrderBy>;
  heroIsAiTranslated?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imprint?: InputMaybe<OrderBy>;
  imprintIsAiTranslated?: InputMaybe<OrderBy>;
  introDescription?: InputMaybe<OrderBy>;
  introHeadline?: InputMaybe<OrderBy>;
  introIsAiTranslated?: InputMaybe<OrderBy>;
  lang?: InputMaybe<OrderBy>;
  language?: InputMaybe<EnumsLanguageOrderBy>;
  licensePartnerProfile?: InputMaybe<DbLicensePartnerProfileOrderBy>;
  licensePartnerProfileId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  privacyPolicy?: InputMaybe<OrderBy>;
  privacyPolicyIsAiTranslated?: InputMaybe<OrderBy>;
  shopOverviewDescription?: InputMaybe<OrderBy>;
  shopOverviewHeadline?: InputMaybe<OrderBy>;
  shopOverviewIsAiTranslated?: InputMaybe<OrderBy>;
  slugOverride?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "db.license_partner_profile_language_variant" */
export type DbLicensePartnerProfileLanguageVariantSelectColumn =
  /** column name */
  | 'careerDesc'
  /** column name */
  | 'careerHeadline'
  /** column name */
  | 'careerIsAiTranslated'
  /** column name */
  | 'careerLink'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'displayName'
  /** column name */
  | 'featuredContentDescription'
  /** column name */
  | 'featuredContentHeadline'
  /** column name */
  | 'featuredContentIsAiTranslated'
  /** column name */
  | 'heroDesc'
  /** column name */
  | 'heroHeadline'
  /** column name */
  | 'heroIsAiTranslated'
  /** column name */
  | 'id'
  /** column name */
  | 'imprint'
  /** column name */
  | 'imprintIsAiTranslated'
  /** column name */
  | 'introDescription'
  /** column name */
  | 'introHeadline'
  /** column name */
  | 'introIsAiTranslated'
  /** column name */
  | 'lang'
  /** column name */
  | 'licensePartnerProfileId'
  /** column name */
  | 'num'
  /** column name */
  | 'privacyPolicy'
  /** column name */
  | 'privacyPolicyIsAiTranslated'
  /** column name */
  | 'shopOverviewDescription'
  /** column name */
  | 'shopOverviewHeadline'
  /** column name */
  | 'shopOverviewIsAiTranslated'
  /** column name */
  | 'slugOverride'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy'
  /** column name */
  | 'url';

/** select "dbLicensePartnerProfileLanguageVariantAggregateBoolExpBool_andArgumentsColumns" columns of table "db.license_partner_profile_language_variant" */
export type DbLicensePartnerProfileLanguageVariantSelectColumnDbLicensePartnerProfileLanguageVariantAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  | 'careerIsAiTranslated'
  /** column name */
  | 'deleted'
  /** column name */
  | 'featuredContentIsAiTranslated'
  /** column name */
  | 'heroIsAiTranslated'
  /** column name */
  | 'imprintIsAiTranslated'
  /** column name */
  | 'introIsAiTranslated'
  /** column name */
  | 'privacyPolicyIsAiTranslated'
  /** column name */
  | 'shopOverviewIsAiTranslated';

/** select "dbLicensePartnerProfileLanguageVariantAggregateBoolExpBool_orArgumentsColumns" columns of table "db.license_partner_profile_language_variant" */
export type DbLicensePartnerProfileLanguageVariantSelectColumnDbLicensePartnerProfileLanguageVariantAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  | 'careerIsAiTranslated'
  /** column name */
  | 'deleted'
  /** column name */
  | 'featuredContentIsAiTranslated'
  /** column name */
  | 'heroIsAiTranslated'
  /** column name */
  | 'imprintIsAiTranslated'
  /** column name */
  | 'introIsAiTranslated'
  /** column name */
  | 'privacyPolicyIsAiTranslated'
  /** column name */
  | 'shopOverviewIsAiTranslated';

/** aggregate stddev on columns */
export type DbLicensePartnerProfileLanguageVariantStddevFields = {
  __typename?: 'DbLicensePartnerProfileLanguageVariantStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.license_partner_profile_language_variant" */
export type DbLicensePartnerProfileLanguageVariantStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbLicensePartnerProfileLanguageVariantStddevPopFields = {
  __typename?: 'DbLicensePartnerProfileLanguageVariantStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.license_partner_profile_language_variant" */
export type DbLicensePartnerProfileLanguageVariantStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbLicensePartnerProfileLanguageVariantStddevSampFields = {
  __typename?: 'DbLicensePartnerProfileLanguageVariantStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.license_partner_profile_language_variant" */
export type DbLicensePartnerProfileLanguageVariantStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_license_partner_profile_language_variant" */
export type DbLicensePartnerProfileLanguageVariantStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbLicensePartnerProfileLanguageVariantStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbLicensePartnerProfileLanguageVariantStreamCursorValueInput = {
  careerDesc?: InputMaybe<Scalars['String']>;
  careerHeadline?: InputMaybe<Scalars['String']>;
  careerIsAiTranslated?: InputMaybe<Scalars['Boolean']>;
  careerLink?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  displayName?: InputMaybe<Scalars['String']>;
  featuredContentDescription?: InputMaybe<Scalars['String']>;
  featuredContentHeadline?: InputMaybe<Scalars['String']>;
  featuredContentIsAiTranslated?: InputMaybe<Scalars['Boolean']>;
  heroDesc?: InputMaybe<Scalars['String']>;
  heroHeadline?: InputMaybe<Scalars['String']>;
  heroIsAiTranslated?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  imprint?: InputMaybe<Scalars['String']>;
  imprintIsAiTranslated?: InputMaybe<Scalars['Boolean']>;
  introDescription?: InputMaybe<Scalars['String']>;
  introHeadline?: InputMaybe<Scalars['String']>;
  introIsAiTranslated?: InputMaybe<Scalars['Boolean']>;
  lang?: InputMaybe<LanguageEnum>;
  licensePartnerProfileId?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  privacyPolicy?: InputMaybe<Scalars['String']>;
  privacyPolicyIsAiTranslated?: InputMaybe<Scalars['Boolean']>;
  shopOverviewDescription?: InputMaybe<Scalars['String']>;
  shopOverviewHeadline?: InputMaybe<Scalars['String']>;
  shopOverviewIsAiTranslated?: InputMaybe<Scalars['Boolean']>;
  slugOverride?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbLicensePartnerProfileLanguageVariantSumFields = {
  __typename?: 'DbLicensePartnerProfileLanguageVariantSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.license_partner_profile_language_variant" */
export type DbLicensePartnerProfileLanguageVariantSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbLicensePartnerProfileLanguageVariantVarPopFields = {
  __typename?: 'DbLicensePartnerProfileLanguageVariantVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.license_partner_profile_language_variant" */
export type DbLicensePartnerProfileLanguageVariantVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbLicensePartnerProfileLanguageVariantVarSampFields = {
  __typename?: 'DbLicensePartnerProfileLanguageVariantVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.license_partner_profile_language_variant" */
export type DbLicensePartnerProfileLanguageVariantVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbLicensePartnerProfileLanguageVariantVarianceFields = {
  __typename?: 'DbLicensePartnerProfileLanguageVariantVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.license_partner_profile_language_variant" */
export type DbLicensePartnerProfileLanguageVariantVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate max on columns */
export type DbLicensePartnerProfileMaxFields = {
  __typename?: 'DbLicensePartnerProfileMaxFields';
  clientId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  criteoId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  emailOther?: Maybe<Scalars['String']>;
  emailRecruiting?: Maybe<Scalars['String']>;
  facebookId?: Maybe<Scalars['String']>;
  facebookUrl?: Maybe<Scalars['String']>;
  googleAdsId?: Maybe<Scalars['String']>;
  googleAnalyticsAccountId?: Maybe<Scalars['String']>;
  googlePlaceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imprintUrl?: Maybe<Scalars['String']>;
  instagramUrl?: Maybe<Scalars['String']>;
  lck?: Maybe<Scalars['Int']>;
  licensePartnerId?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  linkedinId?: Maybe<Scalars['String']>;
  microsoftId?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  optin?: Maybe<Scalars['String']>;
  privacyPolicyUrl?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['timestamp']>;
  publishedBy?: Maybe<Scalars['String']>;
  recipientId?: Maybe<Scalars['String']>;
  tiktokUrl?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  xingUrl?: Maybe<Scalars['String']>;
  youtubeUrl?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.license_partner_profile" */
export type DbLicensePartnerProfileMaxOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  criteoId?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  emailOther?: InputMaybe<OrderBy>;
  emailRecruiting?: InputMaybe<OrderBy>;
  facebookId?: InputMaybe<OrderBy>;
  facebookUrl?: InputMaybe<OrderBy>;
  googleAdsId?: InputMaybe<OrderBy>;
  googleAnalyticsAccountId?: InputMaybe<OrderBy>;
  googlePlaceId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imprintUrl?: InputMaybe<OrderBy>;
  instagramUrl?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  licensePartnerId?: InputMaybe<OrderBy>;
  linkedInUrl?: InputMaybe<OrderBy>;
  linkedinId?: InputMaybe<OrderBy>;
  microsoftId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  optin?: InputMaybe<OrderBy>;
  privacyPolicyUrl?: InputMaybe<OrderBy>;
  published?: InputMaybe<OrderBy>;
  publishedBy?: InputMaybe<OrderBy>;
  recipientId?: InputMaybe<OrderBy>;
  tiktokUrl?: InputMaybe<OrderBy>;
  twitterUrl?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  xingUrl?: InputMaybe<OrderBy>;
  youtubeUrl?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbLicensePartnerProfileMinFields = {
  __typename?: 'DbLicensePartnerProfileMinFields';
  clientId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  criteoId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  emailOther?: Maybe<Scalars['String']>;
  emailRecruiting?: Maybe<Scalars['String']>;
  facebookId?: Maybe<Scalars['String']>;
  facebookUrl?: Maybe<Scalars['String']>;
  googleAdsId?: Maybe<Scalars['String']>;
  googleAnalyticsAccountId?: Maybe<Scalars['String']>;
  googlePlaceId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imprintUrl?: Maybe<Scalars['String']>;
  instagramUrl?: Maybe<Scalars['String']>;
  lck?: Maybe<Scalars['Int']>;
  licensePartnerId?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  linkedinId?: Maybe<Scalars['String']>;
  microsoftId?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  optin?: Maybe<Scalars['String']>;
  privacyPolicyUrl?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['timestamp']>;
  publishedBy?: Maybe<Scalars['String']>;
  recipientId?: Maybe<Scalars['String']>;
  tiktokUrl?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  xingUrl?: Maybe<Scalars['String']>;
  youtubeUrl?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.license_partner_profile" */
export type DbLicensePartnerProfileMinOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  criteoId?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  emailOther?: InputMaybe<OrderBy>;
  emailRecruiting?: InputMaybe<OrderBy>;
  facebookId?: InputMaybe<OrderBy>;
  facebookUrl?: InputMaybe<OrderBy>;
  googleAdsId?: InputMaybe<OrderBy>;
  googleAnalyticsAccountId?: InputMaybe<OrderBy>;
  googlePlaceId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imprintUrl?: InputMaybe<OrderBy>;
  instagramUrl?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  licensePartnerId?: InputMaybe<OrderBy>;
  linkedInUrl?: InputMaybe<OrderBy>;
  linkedinId?: InputMaybe<OrderBy>;
  microsoftId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  optin?: InputMaybe<OrderBy>;
  privacyPolicyUrl?: InputMaybe<OrderBy>;
  published?: InputMaybe<OrderBy>;
  publishedBy?: InputMaybe<OrderBy>;
  recipientId?: InputMaybe<OrderBy>;
  tiktokUrl?: InputMaybe<OrderBy>;
  twitterUrl?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  xingUrl?: InputMaybe<OrderBy>;
  youtubeUrl?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.license_partner_profile". */
export type DbLicensePartnerProfileOrderBy = {
  activated?: InputMaybe<OrderBy>;
  clientId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  criteoId?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  emailOther?: InputMaybe<OrderBy>;
  emailRecruiting?: InputMaybe<OrderBy>;
  facebookId?: InputMaybe<OrderBy>;
  facebookUrl?: InputMaybe<OrderBy>;
  featuredAgentsAggregate?: InputMaybe<DbLicensePartnerProfileFeaturedAgentAggregateOrderBy>;
  googleAdsId?: InputMaybe<OrderBy>;
  googleAnalyticsAccountId?: InputMaybe<OrderBy>;
  googlePlaceId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imprintUrl?: InputMaybe<OrderBy>;
  instagramUrl?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  licensePartner?: InputMaybe<DbLicensePartnerOrderBy>;
  licensePartnerId?: InputMaybe<OrderBy>;
  licensePartnerProfileImagesAggregate?: InputMaybe<DbLicensePartnerProfileImageAggregateOrderBy>;
  licensePartnerProfileLanguageVariantsAggregate?: InputMaybe<DbLicensePartnerProfileLanguageVariantAggregateOrderBy>;
  linkedInUrl?: InputMaybe<OrderBy>;
  linkedinId?: InputMaybe<OrderBy>;
  microsoftId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  optin?: InputMaybe<OrderBy>;
  privacyPolicyUrl?: InputMaybe<OrderBy>;
  propertyPreviewsAggregate?: InputMaybe<DbLicensePartnerProfilePropertyPreviewAggregateOrderBy>;
  published?: InputMaybe<OrderBy>;
  publishedBy?: InputMaybe<OrderBy>;
  recipientId?: InputMaybe<OrderBy>;
  tiktokUrl?: InputMaybe<OrderBy>;
  twitterUrl?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  xingUrl?: InputMaybe<OrderBy>;
  youtubeUrl?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.license_partner_profile_property_preview" */
export type DbLicensePartnerProfilePropertyPreview = {
  __typename?: 'DbLicensePartnerProfilePropertyPreview';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  index: Scalars['Int'];
  /** An object relationship */
  licensePartnerProfile: DbLicensePartnerProfile;
  licensePartnerProfileId: Scalars['String'];
  num: Scalars['bigint'];
  type: MarketingTypeEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  utag: Scalars['String'];
};

/** order by aggregate values of table "db.license_partner_profile_property_preview" */
export type DbLicensePartnerProfilePropertyPreviewAggregateOrderBy = {
  avg?: InputMaybe<DbLicensePartnerProfilePropertyPreviewAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbLicensePartnerProfilePropertyPreviewMaxOrderBy>;
  min?: InputMaybe<DbLicensePartnerProfilePropertyPreviewMinOrderBy>;
  stddev?: InputMaybe<DbLicensePartnerProfilePropertyPreviewStddevOrderBy>;
  stddevPop?: InputMaybe<DbLicensePartnerProfilePropertyPreviewStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbLicensePartnerProfilePropertyPreviewStddevSampOrderBy>;
  sum?: InputMaybe<DbLicensePartnerProfilePropertyPreviewSumOrderBy>;
  varPop?: InputMaybe<DbLicensePartnerProfilePropertyPreviewVarPopOrderBy>;
  varSamp?: InputMaybe<DbLicensePartnerProfilePropertyPreviewVarSampOrderBy>;
  variance?: InputMaybe<DbLicensePartnerProfilePropertyPreviewVarianceOrderBy>;
};

/** order by avg() on columns of table "db.license_partner_profile_property_preview" */
export type DbLicensePartnerProfilePropertyPreviewAvgOrderBy = {
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.license_partner_profile_property_preview". All fields are combined with a logical 'AND'. */
export type DbLicensePartnerProfilePropertyPreviewBoolExp = {
  _and?: InputMaybe<Array<DbLicensePartnerProfilePropertyPreviewBoolExp>>;
  _not?: InputMaybe<DbLicensePartnerProfilePropertyPreviewBoolExp>;
  _or?: InputMaybe<Array<DbLicensePartnerProfilePropertyPreviewBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  index?: InputMaybe<IntComparisonExp>;
  licensePartnerProfile?: InputMaybe<DbLicensePartnerProfileBoolExp>;
  licensePartnerProfileId?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  type?: InputMaybe<EnumsMarketingTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  utag?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.license_partner_profile_property_preview" */
export type DbLicensePartnerProfilePropertyPreviewMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  licensePartnerProfileId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  utag?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.license_partner_profile_property_preview" */
export type DbLicensePartnerProfilePropertyPreviewMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  licensePartnerProfileId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  utag?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.license_partner_profile_property_preview". */
export type DbLicensePartnerProfilePropertyPreviewOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  licensePartnerProfile?: InputMaybe<DbLicensePartnerProfileOrderBy>;
  licensePartnerProfileId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  utag?: InputMaybe<OrderBy>;
};

/** select columns of table "db.license_partner_profile_property_preview" */
export type DbLicensePartnerProfilePropertyPreviewSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'index'
  /** column name */
  | 'licensePartnerProfileId'
  /** column name */
  | 'num'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy'
  /** column name */
  | 'utag';

/** order by stddev() on columns of table "db.license_partner_profile_property_preview" */
export type DbLicensePartnerProfilePropertyPreviewStddevOrderBy = {
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.license_partner_profile_property_preview" */
export type DbLicensePartnerProfilePropertyPreviewStddevPopOrderBy = {
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.license_partner_profile_property_preview" */
export type DbLicensePartnerProfilePropertyPreviewStddevSampOrderBy = {
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_license_partner_profile_property_preview" */
export type DbLicensePartnerProfilePropertyPreviewStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbLicensePartnerProfilePropertyPreviewStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbLicensePartnerProfilePropertyPreviewStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  index?: InputMaybe<Scalars['Int']>;
  licensePartnerProfileId?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  type?: InputMaybe<MarketingTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  utag?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.license_partner_profile_property_preview" */
export type DbLicensePartnerProfilePropertyPreviewSumOrderBy = {
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.license_partner_profile_property_preview" */
export type DbLicensePartnerProfilePropertyPreviewVarPopOrderBy = {
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.license_partner_profile_property_preview" */
export type DbLicensePartnerProfilePropertyPreviewVarSampOrderBy = {
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.license_partner_profile_property_preview" */
export type DbLicensePartnerProfilePropertyPreviewVarianceOrderBy = {
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** select columns of table "db.license_partner_profile" */
export type DbLicensePartnerProfileSelectColumn =
  /** column name */
  | 'activated'
  /** column name */
  | 'clientId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'criteoId'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'emailOther'
  /** column name */
  | 'emailRecruiting'
  /** column name */
  | 'facebookId'
  /** column name */
  | 'facebookUrl'
  /** column name */
  | 'googleAdsId'
  /** column name */
  | 'googleAnalyticsAccountId'
  /** column name */
  | 'googlePlaceId'
  /** column name */
  | 'id'
  /** column name */
  | 'imprintUrl'
  /** column name */
  | 'instagramUrl'
  /** column name */
  | 'lck'
  /** column name */
  | 'licensePartnerId'
  /** column name */
  | 'linkedInUrl'
  /** column name */
  | 'linkedinId'
  /** column name */
  | 'microsoftId'
  /** column name */
  | 'num'
  /** column name */
  | 'optin'
  /** column name */
  | 'privacyPolicyUrl'
  /** column name */
  | 'published'
  /** column name */
  | 'publishedBy'
  /** column name */
  | 'recipientId'
  /** column name */
  | 'tiktokUrl'
  /** column name */
  | 'twitterUrl'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy'
  /** column name */
  | 'xingUrl'
  /** column name */
  | 'youtubeUrl';

/** select "dbLicensePartnerProfileAggregateBoolExpBool_andArgumentsColumns" columns of table "db.license_partner_profile" */
export type DbLicensePartnerProfileSelectColumnDbLicensePartnerProfileAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  | 'activated'
  /** column name */
  | 'deleted';

/** select "dbLicensePartnerProfileAggregateBoolExpBool_orArgumentsColumns" columns of table "db.license_partner_profile" */
export type DbLicensePartnerProfileSelectColumnDbLicensePartnerProfileAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  | 'activated'
  /** column name */
  | 'deleted';

/** aggregate stddev on columns */
export type DbLicensePartnerProfileStddevFields = {
  __typename?: 'DbLicensePartnerProfileStddevFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.license_partner_profile" */
export type DbLicensePartnerProfileStddevOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbLicensePartnerProfileStddevPopFields = {
  __typename?: 'DbLicensePartnerProfileStddevPopFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.license_partner_profile" */
export type DbLicensePartnerProfileStddevPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbLicensePartnerProfileStddevSampFields = {
  __typename?: 'DbLicensePartnerProfileStddevSampFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.license_partner_profile" */
export type DbLicensePartnerProfileStddevSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_license_partner_profile" */
export type DbLicensePartnerProfileStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbLicensePartnerProfileStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbLicensePartnerProfileStreamCursorValueInput = {
  activated?: InputMaybe<Scalars['Boolean']>;
  clientId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  criteoId?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  emailOther?: InputMaybe<Scalars['String']>;
  emailRecruiting?: InputMaybe<Scalars['String']>;
  facebookId?: InputMaybe<Scalars['String']>;
  facebookUrl?: InputMaybe<Scalars['String']>;
  googleAdsId?: InputMaybe<Scalars['String']>;
  googleAnalyticsAccountId?: InputMaybe<Scalars['String']>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  imprintUrl?: InputMaybe<Scalars['String']>;
  instagramUrl?: InputMaybe<Scalars['String']>;
  lck?: InputMaybe<Scalars['Int']>;
  licensePartnerId?: InputMaybe<Scalars['String']>;
  linkedInUrl?: InputMaybe<Scalars['String']>;
  linkedinId?: InputMaybe<Scalars['String']>;
  microsoftId?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  optin?: InputMaybe<Scalars['String']>;
  privacyPolicyUrl?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['timestamp']>;
  publishedBy?: InputMaybe<Scalars['String']>;
  recipientId?: InputMaybe<Scalars['String']>;
  tiktokUrl?: InputMaybe<Scalars['String']>;
  twitterUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  xingUrl?: InputMaybe<Scalars['String']>;
  youtubeUrl?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbLicensePartnerProfileSumFields = {
  __typename?: 'DbLicensePartnerProfileSumFields';
  lck?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.license_partner_profile" */
export type DbLicensePartnerProfileSumOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbLicensePartnerProfileVarPopFields = {
  __typename?: 'DbLicensePartnerProfileVarPopFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.license_partner_profile" */
export type DbLicensePartnerProfileVarPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbLicensePartnerProfileVarSampFields = {
  __typename?: 'DbLicensePartnerProfileVarSampFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.license_partner_profile" */
export type DbLicensePartnerProfileVarSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbLicensePartnerProfileVarianceFields = {
  __typename?: 'DbLicensePartnerProfileVarianceFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.license_partner_profile" */
export type DbLicensePartnerProfileVarianceOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** select columns of table "db.license_partner" */
export type DbLicensePartnerSelectColumn =
  /** column name */
  | 'countryCode'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'debtorNumber'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'isCompanyOwnedOperation'
  /** column name */
  | 'name'
  /** column name */
  | 'num'
  /** column name */
  | 'primaryShopId'
  /** column name */
  | 'status'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** aggregate stddev on columns */
export type DbLicensePartnerStddevFields = {
  __typename?: 'DbLicensePartnerStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type DbLicensePartnerStddevPopFields = {
  __typename?: 'DbLicensePartnerStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type DbLicensePartnerStddevSampFields = {
  __typename?: 'DbLicensePartnerStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "db_license_partner" */
export type DbLicensePartnerStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbLicensePartnerStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbLicensePartnerStreamCursorValueInput = {
  countryCode?: InputMaybe<CountryEnum>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  debtorNumber?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  isCompanyOwnedOperation?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  primaryShopId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<LicensePartnerStatusEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbLicensePartnerSumFields = {
  __typename?: 'DbLicensePartnerSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** aggregate varPop on columns */
export type DbLicensePartnerVarPopFields = {
  __typename?: 'DbLicensePartnerVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type DbLicensePartnerVarSampFields = {
  __typename?: 'DbLicensePartnerVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type DbLicensePartnerVarianceFields = {
  __typename?: 'DbLicensePartnerVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "db.migration_source" */
export type DbMigrationSource = {
  __typename?: 'DbMigrationSource';
  agentKey?: Maybe<Scalars['Int']>;
  agentUserName?: Maybe<Scalars['String']>;
  appointmentKey?: Maybe<Scalars['Int']>;
  contactKey?: Maybe<Scalars['Int']>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  departmentKey?: Maybe<Scalars['Int']>;
  documentKey?: Maybe<Scalars['Int']>;
  emailKey?: Maybe<Scalars['Int']>;
  entityType: Scalars['String'];
  guid?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lck: Scalars['Int'];
  migrationId: Scalars['String'];
  /** An array relationship */
  migrationTargets: Array<DbMigrationTarget>;
  /** An aggregate relationship */
  migrationTargetsAggregate: DbMigrationTargetAggregate;
  num: Scalars['bigint'];
  payload: Scalars['jsonb'];
  sourceId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** columns and relationships of "db.migration_source" */
export type DbMigrationSourceMigrationTargetsArgs = {
  distinctOn?: InputMaybe<Array<DbMigrationTargetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbMigrationTargetOrderBy>>;
  where?: InputMaybe<DbMigrationTargetBoolExp>;
};

/** columns and relationships of "db.migration_source" */
export type DbMigrationSourceMigrationTargetsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbMigrationTargetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbMigrationTargetOrderBy>>;
  where?: InputMaybe<DbMigrationTargetBoolExp>;
};

/** columns and relationships of "db.migration_source" */
export type DbMigrationSourcePayloadArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "db.migration_source" */
export type DbMigrationSourceAggregate = {
  __typename?: 'DbMigrationSourceAggregate';
  aggregate?: Maybe<DbMigrationSourceAggregateFields>;
  nodes: Array<DbMigrationSource>;
};

/** aggregate fields of "db.migration_source" */
export type DbMigrationSourceAggregateFields = {
  __typename?: 'DbMigrationSourceAggregateFields';
  avg?: Maybe<DbMigrationSourceAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbMigrationSourceMaxFields>;
  min?: Maybe<DbMigrationSourceMinFields>;
  stddev?: Maybe<DbMigrationSourceStddevFields>;
  stddevPop?: Maybe<DbMigrationSourceStddevPopFields>;
  stddevSamp?: Maybe<DbMigrationSourceStddevSampFields>;
  sum?: Maybe<DbMigrationSourceSumFields>;
  varPop?: Maybe<DbMigrationSourceVarPopFields>;
  varSamp?: Maybe<DbMigrationSourceVarSampFields>;
  variance?: Maybe<DbMigrationSourceVarianceFields>;
};

/** aggregate fields of "db.migration_source" */
export type DbMigrationSourceAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbMigrationSourceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type DbMigrationSourceAvgFields = {
  __typename?: 'DbMigrationSourceAvgFields';
  agentKey?: Maybe<Scalars['Float']>;
  appointmentKey?: Maybe<Scalars['Float']>;
  contactKey?: Maybe<Scalars['Float']>;
  departmentKey?: Maybe<Scalars['Float']>;
  documentKey?: Maybe<Scalars['Float']>;
  emailKey?: Maybe<Scalars['Float']>;
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "db.migration_source". All fields are combined with a logical 'AND'. */
export type DbMigrationSourceBoolExp = {
  _and?: InputMaybe<Array<DbMigrationSourceBoolExp>>;
  _not?: InputMaybe<DbMigrationSourceBoolExp>;
  _or?: InputMaybe<Array<DbMigrationSourceBoolExp>>;
  agentKey?: InputMaybe<IntComparisonExp>;
  agentUserName?: InputMaybe<StringComparisonExp>;
  appointmentKey?: InputMaybe<IntComparisonExp>;
  contactKey?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  departmentKey?: InputMaybe<IntComparisonExp>;
  documentKey?: InputMaybe<IntComparisonExp>;
  emailKey?: InputMaybe<IntComparisonExp>;
  entityType?: InputMaybe<StringComparisonExp>;
  guid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lck?: InputMaybe<IntComparisonExp>;
  migrationId?: InputMaybe<StringComparisonExp>;
  migrationTargets?: InputMaybe<DbMigrationTargetBoolExp>;
  migrationTargetsAggregate?: InputMaybe<DbMigrationTargetAggregateBoolExp>;
  num?: InputMaybe<BigintComparisonExp>;
  payload?: InputMaybe<JsonbComparisonExp>;
  sourceId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbMigrationSourceMaxFields = {
  __typename?: 'DbMigrationSourceMaxFields';
  agentKey?: Maybe<Scalars['Int']>;
  agentUserName?: Maybe<Scalars['String']>;
  appointmentKey?: Maybe<Scalars['Int']>;
  contactKey?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  departmentKey?: Maybe<Scalars['Int']>;
  documentKey?: Maybe<Scalars['Int']>;
  emailKey?: Maybe<Scalars['Int']>;
  entityType?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lck?: Maybe<Scalars['Int']>;
  migrationId?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  sourceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type DbMigrationSourceMinFields = {
  __typename?: 'DbMigrationSourceMinFields';
  agentKey?: Maybe<Scalars['Int']>;
  agentUserName?: Maybe<Scalars['String']>;
  appointmentKey?: Maybe<Scalars['Int']>;
  contactKey?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  departmentKey?: Maybe<Scalars['Int']>;
  documentKey?: Maybe<Scalars['Int']>;
  emailKey?: Maybe<Scalars['Int']>;
  entityType?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lck?: Maybe<Scalars['Int']>;
  migrationId?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  sourceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "db.migration_source". */
export type DbMigrationSourceOrderBy = {
  agentKey?: InputMaybe<OrderBy>;
  agentUserName?: InputMaybe<OrderBy>;
  appointmentKey?: InputMaybe<OrderBy>;
  contactKey?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  departmentKey?: InputMaybe<OrderBy>;
  documentKey?: InputMaybe<OrderBy>;
  emailKey?: InputMaybe<OrderBy>;
  entityType?: InputMaybe<OrderBy>;
  guid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  migrationId?: InputMaybe<OrderBy>;
  migrationTargetsAggregate?: InputMaybe<DbMigrationTargetAggregateOrderBy>;
  num?: InputMaybe<OrderBy>;
  payload?: InputMaybe<OrderBy>;
  sourceId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.migration_source_pk_list" */
export type DbMigrationSourcePkList = {
  __typename?: 'DbMigrationSourcePkList';
  pkId: Scalars['bigint'];
  sequenceNo: Scalars['Int'];
  type: Scalars['String'];
};

/** aggregated selection of "db.migration_source_pk_list" */
export type DbMigrationSourcePkListAggregate = {
  __typename?: 'DbMigrationSourcePkListAggregate';
  aggregate?: Maybe<DbMigrationSourcePkListAggregateFields>;
  nodes: Array<DbMigrationSourcePkList>;
};

/** aggregate fields of "db.migration_source_pk_list" */
export type DbMigrationSourcePkListAggregateFields = {
  __typename?: 'DbMigrationSourcePkListAggregateFields';
  avg?: Maybe<DbMigrationSourcePkListAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbMigrationSourcePkListMaxFields>;
  min?: Maybe<DbMigrationSourcePkListMinFields>;
  stddev?: Maybe<DbMigrationSourcePkListStddevFields>;
  stddevPop?: Maybe<DbMigrationSourcePkListStddevPopFields>;
  stddevSamp?: Maybe<DbMigrationSourcePkListStddevSampFields>;
  sum?: Maybe<DbMigrationSourcePkListSumFields>;
  varPop?: Maybe<DbMigrationSourcePkListVarPopFields>;
  varSamp?: Maybe<DbMigrationSourcePkListVarSampFields>;
  variance?: Maybe<DbMigrationSourcePkListVarianceFields>;
};

/** aggregate fields of "db.migration_source_pk_list" */
export type DbMigrationSourcePkListAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbMigrationSourcePkListSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type DbMigrationSourcePkListAvgFields = {
  __typename?: 'DbMigrationSourcePkListAvgFields';
  pkId?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "db.migration_source_pk_list". All fields are combined with a logical 'AND'. */
export type DbMigrationSourcePkListBoolExp = {
  _and?: InputMaybe<Array<DbMigrationSourcePkListBoolExp>>;
  _not?: InputMaybe<DbMigrationSourcePkListBoolExp>;
  _or?: InputMaybe<Array<DbMigrationSourcePkListBoolExp>>;
  pkId?: InputMaybe<BigintComparisonExp>;
  sequenceNo?: InputMaybe<IntComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbMigrationSourcePkListMaxFields = {
  __typename?: 'DbMigrationSourcePkListMaxFields';
  pkId?: Maybe<Scalars['bigint']>;
  sequenceNo?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type DbMigrationSourcePkListMinFields = {
  __typename?: 'DbMigrationSourcePkListMinFields';
  pkId?: Maybe<Scalars['bigint']>;
  sequenceNo?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "db.migration_source_pk_list". */
export type DbMigrationSourcePkListOrderBy = {
  pkId?: InputMaybe<OrderBy>;
  sequenceNo?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** select columns of table "db.migration_source_pk_list" */
export type DbMigrationSourcePkListSelectColumn =
  /** column name */
  | 'pkId'
  /** column name */
  | 'sequenceNo'
  /** column name */
  | 'type';

/** aggregate stddev on columns */
export type DbMigrationSourcePkListStddevFields = {
  __typename?: 'DbMigrationSourcePkListStddevFields';
  pkId?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type DbMigrationSourcePkListStddevPopFields = {
  __typename?: 'DbMigrationSourcePkListStddevPopFields';
  pkId?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type DbMigrationSourcePkListStddevSampFields = {
  __typename?: 'DbMigrationSourcePkListStddevSampFields';
  pkId?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "db_migration_source_pk_list" */
export type DbMigrationSourcePkListStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbMigrationSourcePkListStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbMigrationSourcePkListStreamCursorValueInput = {
  pkId?: InputMaybe<Scalars['bigint']>;
  sequenceNo?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbMigrationSourcePkListSumFields = {
  __typename?: 'DbMigrationSourcePkListSumFields';
  pkId?: Maybe<Scalars['bigint']>;
  sequenceNo?: Maybe<Scalars['Int']>;
};

/** aggregate varPop on columns */
export type DbMigrationSourcePkListVarPopFields = {
  __typename?: 'DbMigrationSourcePkListVarPopFields';
  pkId?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type DbMigrationSourcePkListVarSampFields = {
  __typename?: 'DbMigrationSourcePkListVarSampFields';
  pkId?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type DbMigrationSourcePkListVarianceFields = {
  __typename?: 'DbMigrationSourcePkListVarianceFields';
  pkId?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
};

/** select columns of table "db.migration_source" */
export type DbMigrationSourceSelectColumn =
  /** column name */
  | 'agentKey'
  /** column name */
  | 'agentUserName'
  /** column name */
  | 'appointmentKey'
  /** column name */
  | 'contactKey'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'departmentKey'
  /** column name */
  | 'documentKey'
  /** column name */
  | 'emailKey'
  /** column name */
  | 'entityType'
  /** column name */
  | 'guid'
  /** column name */
  | 'id'
  /** column name */
  | 'lck'
  /** column name */
  | 'migrationId'
  /** column name */
  | 'num'
  /** column name */
  | 'payload'
  /** column name */
  | 'sourceId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** aggregate stddev on columns */
export type DbMigrationSourceStddevFields = {
  __typename?: 'DbMigrationSourceStddevFields';
  agentKey?: Maybe<Scalars['Float']>;
  appointmentKey?: Maybe<Scalars['Float']>;
  contactKey?: Maybe<Scalars['Float']>;
  departmentKey?: Maybe<Scalars['Float']>;
  documentKey?: Maybe<Scalars['Float']>;
  emailKey?: Maybe<Scalars['Float']>;
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type DbMigrationSourceStddevPopFields = {
  __typename?: 'DbMigrationSourceStddevPopFields';
  agentKey?: Maybe<Scalars['Float']>;
  appointmentKey?: Maybe<Scalars['Float']>;
  contactKey?: Maybe<Scalars['Float']>;
  departmentKey?: Maybe<Scalars['Float']>;
  documentKey?: Maybe<Scalars['Float']>;
  emailKey?: Maybe<Scalars['Float']>;
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type DbMigrationSourceStddevSampFields = {
  __typename?: 'DbMigrationSourceStddevSampFields';
  agentKey?: Maybe<Scalars['Float']>;
  appointmentKey?: Maybe<Scalars['Float']>;
  contactKey?: Maybe<Scalars['Float']>;
  departmentKey?: Maybe<Scalars['Float']>;
  documentKey?: Maybe<Scalars['Float']>;
  emailKey?: Maybe<Scalars['Float']>;
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "db_migration_source" */
export type DbMigrationSourceStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbMigrationSourceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbMigrationSourceStreamCursorValueInput = {
  agentKey?: InputMaybe<Scalars['Int']>;
  agentUserName?: InputMaybe<Scalars['String']>;
  appointmentKey?: InputMaybe<Scalars['Int']>;
  contactKey?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  departmentKey?: InputMaybe<Scalars['Int']>;
  documentKey?: InputMaybe<Scalars['Int']>;
  emailKey?: InputMaybe<Scalars['Int']>;
  entityType?: InputMaybe<Scalars['String']>;
  guid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lck?: InputMaybe<Scalars['Int']>;
  migrationId?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  sourceId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbMigrationSourceSumFields = {
  __typename?: 'DbMigrationSourceSumFields';
  agentKey?: Maybe<Scalars['Int']>;
  appointmentKey?: Maybe<Scalars['Int']>;
  contactKey?: Maybe<Scalars['Int']>;
  departmentKey?: Maybe<Scalars['Int']>;
  documentKey?: Maybe<Scalars['Int']>;
  emailKey?: Maybe<Scalars['Int']>;
  lck?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['bigint']>;
};

/** aggregate varPop on columns */
export type DbMigrationSourceVarPopFields = {
  __typename?: 'DbMigrationSourceVarPopFields';
  agentKey?: Maybe<Scalars['Float']>;
  appointmentKey?: Maybe<Scalars['Float']>;
  contactKey?: Maybe<Scalars['Float']>;
  departmentKey?: Maybe<Scalars['Float']>;
  documentKey?: Maybe<Scalars['Float']>;
  emailKey?: Maybe<Scalars['Float']>;
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type DbMigrationSourceVarSampFields = {
  __typename?: 'DbMigrationSourceVarSampFields';
  agentKey?: Maybe<Scalars['Float']>;
  appointmentKey?: Maybe<Scalars['Float']>;
  contactKey?: Maybe<Scalars['Float']>;
  departmentKey?: Maybe<Scalars['Float']>;
  documentKey?: Maybe<Scalars['Float']>;
  emailKey?: Maybe<Scalars['Float']>;
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type DbMigrationSourceVarianceFields = {
  __typename?: 'DbMigrationSourceVarianceFields';
  agentKey?: Maybe<Scalars['Float']>;
  appointmentKey?: Maybe<Scalars['Float']>;
  contactKey?: Maybe<Scalars['Float']>;
  departmentKey?: Maybe<Scalars['Float']>;
  documentKey?: Maybe<Scalars['Float']>;
  emailKey?: Maybe<Scalars['Float']>;
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "db.migration_target" */
export type DbMigrationTarget = {
  __typename?: 'DbMigrationTarget';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  entityType: Scalars['String'];
  id: Scalars['String'];
  lck: Scalars['Int'];
  /** An object relationship */
  migrationSource: DbMigrationSource;
  migrationSourceId: Scalars['String'];
  num: Scalars['bigint'];
  targetId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.migration_target" */
export type DbMigrationTargetAggregate = {
  __typename?: 'DbMigrationTargetAggregate';
  aggregate?: Maybe<DbMigrationTargetAggregateFields>;
  nodes: Array<DbMigrationTarget>;
};

export type DbMigrationTargetAggregateBoolExp = {
  bool_and?: InputMaybe<DbMigrationTargetAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbMigrationTargetAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbMigrationTargetAggregateBoolExpCount>;
};

/** aggregate fields of "db.migration_target" */
export type DbMigrationTargetAggregateFields = {
  __typename?: 'DbMigrationTargetAggregateFields';
  avg?: Maybe<DbMigrationTargetAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbMigrationTargetMaxFields>;
  min?: Maybe<DbMigrationTargetMinFields>;
  stddev?: Maybe<DbMigrationTargetStddevFields>;
  stddevPop?: Maybe<DbMigrationTargetStddevPopFields>;
  stddevSamp?: Maybe<DbMigrationTargetStddevSampFields>;
  sum?: Maybe<DbMigrationTargetSumFields>;
  varPop?: Maybe<DbMigrationTargetVarPopFields>;
  varSamp?: Maybe<DbMigrationTargetVarSampFields>;
  variance?: Maybe<DbMigrationTargetVarianceFields>;
};

/** aggregate fields of "db.migration_target" */
export type DbMigrationTargetAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbMigrationTargetSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.migration_target" */
export type DbMigrationTargetAggregateOrderBy = {
  avg?: InputMaybe<DbMigrationTargetAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbMigrationTargetMaxOrderBy>;
  min?: InputMaybe<DbMigrationTargetMinOrderBy>;
  stddev?: InputMaybe<DbMigrationTargetStddevOrderBy>;
  stddevPop?: InputMaybe<DbMigrationTargetStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbMigrationTargetStddevSampOrderBy>;
  sum?: InputMaybe<DbMigrationTargetSumOrderBy>;
  varPop?: InputMaybe<DbMigrationTargetVarPopOrderBy>;
  varSamp?: InputMaybe<DbMigrationTargetVarSampOrderBy>;
  variance?: InputMaybe<DbMigrationTargetVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbMigrationTargetAvgFields = {
  __typename?: 'DbMigrationTargetAvgFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.migration_target" */
export type DbMigrationTargetAvgOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.migration_target". All fields are combined with a logical 'AND'. */
export type DbMigrationTargetBoolExp = {
  _and?: InputMaybe<Array<DbMigrationTargetBoolExp>>;
  _not?: InputMaybe<DbMigrationTargetBoolExp>;
  _or?: InputMaybe<Array<DbMigrationTargetBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  entityType?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lck?: InputMaybe<IntComparisonExp>;
  migrationSource?: InputMaybe<DbMigrationSourceBoolExp>;
  migrationSourceId?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  targetId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbMigrationTargetMaxFields = {
  __typename?: 'DbMigrationTargetMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  entityType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lck?: Maybe<Scalars['Int']>;
  migrationSourceId?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  targetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.migration_target" */
export type DbMigrationTargetMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  entityType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  migrationSourceId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  targetId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbMigrationTargetMinFields = {
  __typename?: 'DbMigrationTargetMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  entityType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lck?: Maybe<Scalars['Int']>;
  migrationSourceId?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  targetId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.migration_target" */
export type DbMigrationTargetMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  entityType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  migrationSourceId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  targetId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.migration_target". */
export type DbMigrationTargetOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  entityType?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  migrationSource?: InputMaybe<DbMigrationSourceOrderBy>;
  migrationSourceId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  targetId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.migration_target" */
export type DbMigrationTargetSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'entityType'
  /** column name */
  | 'id'
  /** column name */
  | 'lck'
  /** column name */
  | 'migrationSourceId'
  /** column name */
  | 'num'
  /** column name */
  | 'targetId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbMigrationTargetAggregateBoolExpBool_andArgumentsColumns" columns of table "db.migration_target" */
export type DbMigrationTargetSelectColumnDbMigrationTargetAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbMigrationTargetAggregateBoolExpBool_orArgumentsColumns" columns of table "db.migration_target" */
export type DbMigrationTargetSelectColumnDbMigrationTargetAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbMigrationTargetStddevFields = {
  __typename?: 'DbMigrationTargetStddevFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.migration_target" */
export type DbMigrationTargetStddevOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbMigrationTargetStddevPopFields = {
  __typename?: 'DbMigrationTargetStddevPopFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.migration_target" */
export type DbMigrationTargetStddevPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbMigrationTargetStddevSampFields = {
  __typename?: 'DbMigrationTargetStddevSampFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.migration_target" */
export type DbMigrationTargetStddevSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_migration_target" */
export type DbMigrationTargetStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbMigrationTargetStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbMigrationTargetStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  entityType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lck?: InputMaybe<Scalars['Int']>;
  migrationSourceId?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  targetId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbMigrationTargetSumFields = {
  __typename?: 'DbMigrationTargetSumFields';
  lck?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.migration_target" */
export type DbMigrationTargetSumOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbMigrationTargetVarPopFields = {
  __typename?: 'DbMigrationTargetVarPopFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.migration_target" */
export type DbMigrationTargetVarPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbMigrationTargetVarSampFields = {
  __typename?: 'DbMigrationTargetVarSampFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.migration_target" */
export type DbMigrationTargetVarSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbMigrationTargetVarianceFields = {
  __typename?: 'DbMigrationTargetVarianceFields';
  lck?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.migration_target" */
export type DbMigrationTargetVarianceOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.nylas_email_integration" */
export type DbNylasEmailIntegration = {
  __typename?: 'DbNylasEmailIntegration';
  active: Scalars['Boolean'];
  /** An object relationship */
  agent?: Maybe<DbAgent>;
  /** An array relationship */
  agentDefaults: Array<DbAgentDefaultNylasEmailIntegration>;
  /** An aggregate relationship */
  agentDefaultsAggregate: DbAgentDefaultNylasEmailIntegrationAggregate;
  agentId?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  /** An object relationship */
  emailIntegrationScope: EnumsEmailIntegrationScope;
  id: Scalars['String'];
  num: Scalars['bigint'];
  scope: EmailIntegrationScopeEnum;
  /** An object relationship */
  shop?: Maybe<DbShop>;
  shopId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** columns and relationships of "db.nylas_email_integration" */
export type DbNylasEmailIntegrationAgentDefaultsArgs = {
  distinctOn?: InputMaybe<Array<DbAgentDefaultNylasEmailIntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentDefaultNylasEmailIntegrationOrderBy>>;
  where?: InputMaybe<DbAgentDefaultNylasEmailIntegrationBoolExp>;
};

/** columns and relationships of "db.nylas_email_integration" */
export type DbNylasEmailIntegrationAgentDefaultsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentDefaultNylasEmailIntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentDefaultNylasEmailIntegrationOrderBy>>;
  where?: InputMaybe<DbAgentDefaultNylasEmailIntegrationBoolExp>;
};

/** aggregated selection of "db.nylas_email_integration" */
export type DbNylasEmailIntegrationAggregate = {
  __typename?: 'DbNylasEmailIntegrationAggregate';
  aggregate?: Maybe<DbNylasEmailIntegrationAggregateFields>;
  nodes: Array<DbNylasEmailIntegration>;
};

export type DbNylasEmailIntegrationAggregateBoolExp = {
  bool_and?: InputMaybe<DbNylasEmailIntegrationAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbNylasEmailIntegrationAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbNylasEmailIntegrationAggregateBoolExpCount>;
};

/** aggregate fields of "db.nylas_email_integration" */
export type DbNylasEmailIntegrationAggregateFields = {
  __typename?: 'DbNylasEmailIntegrationAggregateFields';
  avg?: Maybe<DbNylasEmailIntegrationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbNylasEmailIntegrationMaxFields>;
  min?: Maybe<DbNylasEmailIntegrationMinFields>;
  stddev?: Maybe<DbNylasEmailIntegrationStddevFields>;
  stddevPop?: Maybe<DbNylasEmailIntegrationStddevPopFields>;
  stddevSamp?: Maybe<DbNylasEmailIntegrationStddevSampFields>;
  sum?: Maybe<DbNylasEmailIntegrationSumFields>;
  varPop?: Maybe<DbNylasEmailIntegrationVarPopFields>;
  varSamp?: Maybe<DbNylasEmailIntegrationVarSampFields>;
  variance?: Maybe<DbNylasEmailIntegrationVarianceFields>;
};

/** aggregate fields of "db.nylas_email_integration" */
export type DbNylasEmailIntegrationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbNylasEmailIntegrationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.nylas_email_integration" */
export type DbNylasEmailIntegrationAggregateOrderBy = {
  avg?: InputMaybe<DbNylasEmailIntegrationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbNylasEmailIntegrationMaxOrderBy>;
  min?: InputMaybe<DbNylasEmailIntegrationMinOrderBy>;
  stddev?: InputMaybe<DbNylasEmailIntegrationStddevOrderBy>;
  stddevPop?: InputMaybe<DbNylasEmailIntegrationStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbNylasEmailIntegrationStddevSampOrderBy>;
  sum?: InputMaybe<DbNylasEmailIntegrationSumOrderBy>;
  varPop?: InputMaybe<DbNylasEmailIntegrationVarPopOrderBy>;
  varSamp?: InputMaybe<DbNylasEmailIntegrationVarSampOrderBy>;
  variance?: InputMaybe<DbNylasEmailIntegrationVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbNylasEmailIntegrationAvgFields = {
  __typename?: 'DbNylasEmailIntegrationAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.nylas_email_integration" */
export type DbNylasEmailIntegrationAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.nylas_email_integration". All fields are combined with a logical 'AND'. */
export type DbNylasEmailIntegrationBoolExp = {
  _and?: InputMaybe<Array<DbNylasEmailIntegrationBoolExp>>;
  _not?: InputMaybe<DbNylasEmailIntegrationBoolExp>;
  _or?: InputMaybe<Array<DbNylasEmailIntegrationBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  agent?: InputMaybe<DbAgentBoolExp>;
  agentDefaults?: InputMaybe<DbAgentDefaultNylasEmailIntegrationBoolExp>;
  agentDefaultsAggregate?: InputMaybe<DbAgentDefaultNylasEmailIntegrationAggregateBoolExp>;
  agentId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  emailIntegrationScope?: InputMaybe<EnumsEmailIntegrationScopeBoolExp>;
  id?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  scope?: InputMaybe<EnumsEmailIntegrationScopeEnumComparisonExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbNylasEmailIntegrationMaxFields = {
  __typename?: 'DbNylasEmailIntegrationMaxFields';
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  shopId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.nylas_email_integration" */
export type DbNylasEmailIntegrationMaxOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbNylasEmailIntegrationMinFields = {
  __typename?: 'DbNylasEmailIntegrationMinFields';
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  shopId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.nylas_email_integration" */
export type DbNylasEmailIntegrationMinOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.nylas_email_integration". */
export type DbNylasEmailIntegrationOrderBy = {
  active?: InputMaybe<OrderBy>;
  agent?: InputMaybe<DbAgentOrderBy>;
  agentDefaultsAggregate?: InputMaybe<DbAgentDefaultNylasEmailIntegrationAggregateOrderBy>;
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  emailIntegrationScope?: InputMaybe<EnumsEmailIntegrationScopeOrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  scope?: InputMaybe<OrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.nylas_email_integration" */
export type DbNylasEmailIntegrationSelectColumn =
  /** column name */
  | 'active'
  /** column name */
  | 'agentId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'email'
  /** column name */
  | 'id'
  /** column name */
  | 'num'
  /** column name */
  | 'scope'
  /** column name */
  | 'shopId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbNylasEmailIntegrationAggregateBoolExpBool_andArgumentsColumns" columns of table "db.nylas_email_integration" */
export type DbNylasEmailIntegrationSelectColumnDbNylasEmailIntegrationAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  | 'active'
  /** column name */
  | 'deleted';

/** select "dbNylasEmailIntegrationAggregateBoolExpBool_orArgumentsColumns" columns of table "db.nylas_email_integration" */
export type DbNylasEmailIntegrationSelectColumnDbNylasEmailIntegrationAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  | 'active'
  /** column name */
  | 'deleted';

/** aggregate stddev on columns */
export type DbNylasEmailIntegrationStddevFields = {
  __typename?: 'DbNylasEmailIntegrationStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.nylas_email_integration" */
export type DbNylasEmailIntegrationStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbNylasEmailIntegrationStddevPopFields = {
  __typename?: 'DbNylasEmailIntegrationStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.nylas_email_integration" */
export type DbNylasEmailIntegrationStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbNylasEmailIntegrationStddevSampFields = {
  __typename?: 'DbNylasEmailIntegrationStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.nylas_email_integration" */
export type DbNylasEmailIntegrationStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_nylas_email_integration" */
export type DbNylasEmailIntegrationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbNylasEmailIntegrationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbNylasEmailIntegrationStreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  agentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  scope?: InputMaybe<EmailIntegrationScopeEnum>;
  shopId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbNylasEmailIntegrationSumFields = {
  __typename?: 'DbNylasEmailIntegrationSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.nylas_email_integration" */
export type DbNylasEmailIntegrationSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbNylasEmailIntegrationVarPopFields = {
  __typename?: 'DbNylasEmailIntegrationVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.nylas_email_integration" */
export type DbNylasEmailIntegrationVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbNylasEmailIntegrationVarSampFields = {
  __typename?: 'DbNylasEmailIntegrationVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.nylas_email_integration" */
export type DbNylasEmailIntegrationVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbNylasEmailIntegrationVarianceFields = {
  __typename?: 'DbNylasEmailIntegrationVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.nylas_email_integration" */
export type DbNylasEmailIntegrationVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.one_way_mapping_from_lead_id_to_contact_id" */
export type DbOneWayMappingFromLeadIdToContactId = {
  __typename?: 'DbOneWayMappingFromLeadIdToContactId';
  /** An object relationship */
  contact?: Maybe<DbContact>;
  contactId?: Maybe<Scalars['String']>;
  leadId?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "db.one_way_mapping_from_lead_id_to_contact_id". All fields are combined with a logical 'AND'. */
export type DbOneWayMappingFromLeadIdToContactIdBoolExp = {
  _and?: InputMaybe<Array<DbOneWayMappingFromLeadIdToContactIdBoolExp>>;
  _not?: InputMaybe<DbOneWayMappingFromLeadIdToContactIdBoolExp>;
  _or?: InputMaybe<Array<DbOneWayMappingFromLeadIdToContactIdBoolExp>>;
  contact?: InputMaybe<DbContactBoolExp>;
  contactId?: InputMaybe<StringComparisonExp>;
  leadId?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "db.one_way_mapping_from_lead_id_to_contact_id". */
export type DbOneWayMappingFromLeadIdToContactIdOrderBy = {
  contact?: InputMaybe<DbContactOrderBy>;
  contactId?: InputMaybe<OrderBy>;
  leadId?: InputMaybe<OrderBy>;
};

/** select columns of table "db.one_way_mapping_from_lead_id_to_contact_id" */
export type DbOneWayMappingFromLeadIdToContactIdSelectColumn =
  /** column name */
  | 'contactId'
  /** column name */
  | 'leadId';

/** Streaming cursor of the table "db_one_way_mapping_from_lead_id_to_contact_id" */
export type DbOneWayMappingFromLeadIdToContactIdStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbOneWayMappingFromLeadIdToContactIdStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbOneWayMappingFromLeadIdToContactIdStreamCursorValueInput = {
  contactId?: InputMaybe<Scalars['String']>;
  leadId?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "db.optimizely_client" */
export type DbOptimizelyClient = {
  __typename?: 'DbOptimizelyClient';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  defaultLanguage: LanguageEnum;
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  /** An object relationship */
  language: EnumsLanguage;
  optimizelyClient: Scalars['String'];
  optimizelyClientId: Scalars['numeric'];
  /** An object relationship */
  optimizelyClientShop: DbShop;
  recipientListIds: Array<Scalars['String']>;
  shopId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.optimizely_client" */
export type DbOptimizelyClientAggregateOrderBy = {
  avg?: InputMaybe<DbOptimizelyClientAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbOptimizelyClientMaxOrderBy>;
  min?: InputMaybe<DbOptimizelyClientMinOrderBy>;
  stddev?: InputMaybe<DbOptimizelyClientStddevOrderBy>;
  stddevPop?: InputMaybe<DbOptimizelyClientStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbOptimizelyClientStddevSampOrderBy>;
  sum?: InputMaybe<DbOptimizelyClientSumOrderBy>;
  varPop?: InputMaybe<DbOptimizelyClientVarPopOrderBy>;
  varSamp?: InputMaybe<DbOptimizelyClientVarSampOrderBy>;
  variance?: InputMaybe<DbOptimizelyClientVarianceOrderBy>;
};

/** order by avg() on columns of table "db.optimizely_client" */
export type DbOptimizelyClientAvgOrderBy = {
  optimizelyClientId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.optimizely_client". All fields are combined with a logical 'AND'. */
export type DbOptimizelyClientBoolExp = {
  _and?: InputMaybe<Array<DbOptimizelyClientBoolExp>>;
  _not?: InputMaybe<DbOptimizelyClientBoolExp>;
  _or?: InputMaybe<Array<DbOptimizelyClientBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  defaultLanguage?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  language?: InputMaybe<EnumsLanguageBoolExp>;
  optimizelyClient?: InputMaybe<StringComparisonExp>;
  optimizelyClientId?: InputMaybe<NumericComparisonExp>;
  optimizelyClientShop?: InputMaybe<DbShopBoolExp>;
  recipientListIds?: InputMaybe<StringArrayComparisonExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.optimizely_client" */
export type DbOptimizelyClientMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  optimizelyClient?: InputMaybe<OrderBy>;
  optimizelyClientId?: InputMaybe<OrderBy>;
  recipientListIds?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.optimizely_client" */
export type DbOptimizelyClientMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  optimizelyClient?: InputMaybe<OrderBy>;
  optimizelyClientId?: InputMaybe<OrderBy>;
  recipientListIds?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.optimizely_client". */
export type DbOptimizelyClientOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  defaultLanguage?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  language?: InputMaybe<EnumsLanguageOrderBy>;
  optimizelyClient?: InputMaybe<OrderBy>;
  optimizelyClientId?: InputMaybe<OrderBy>;
  optimizelyClientShop?: InputMaybe<DbShopOrderBy>;
  recipientListIds?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.optimizely_client" */
export type DbOptimizelyClientSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'defaultLanguage'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'optimizelyClient'
  /** column name */
  | 'optimizelyClientId'
  /** column name */
  | 'recipientListIds'
  /** column name */
  | 'shopId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.optimizely_client" */
export type DbOptimizelyClientStddevOrderBy = {
  optimizelyClientId?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.optimizely_client" */
export type DbOptimizelyClientStddevPopOrderBy = {
  optimizelyClientId?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.optimizely_client" */
export type DbOptimizelyClientStddevSampOrderBy = {
  optimizelyClientId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_optimizely_client" */
export type DbOptimizelyClientStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbOptimizelyClientStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbOptimizelyClientStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  defaultLanguage?: InputMaybe<LanguageEnum>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  optimizelyClient?: InputMaybe<Scalars['String']>;
  optimizelyClientId?: InputMaybe<Scalars['numeric']>;
  recipientListIds?: InputMaybe<Array<Scalars['String']>>;
  shopId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.optimizely_client" */
export type DbOptimizelyClientSumOrderBy = {
  optimizelyClientId?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.optimizely_client" */
export type DbOptimizelyClientVarPopOrderBy = {
  optimizelyClientId?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.optimizely_client" */
export type DbOptimizelyClientVarSampOrderBy = {
  optimizelyClientId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.optimizely_client" */
export type DbOptimizelyClientVarianceOrderBy = {
  optimizelyClientId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.potential_duplicate_contact" */
export type DbPotentialDuplicateContact = {
  __typename?: 'DbPotentialDuplicateContact';
  /** An object relationship */
  contact: DbContact;
  contactId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  groupId: Scalars['uuid'];
  id: Scalars['String'];
  matchedEmail?: Maybe<Array<Scalars['String']>>;
  matchedName?: Maybe<Scalars['String']>;
  matchedPhone?: Maybe<Array<Scalars['String']>>;
  num: Scalars['bigint'];
  shopId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.potential_duplicate_contact" */
export type DbPotentialDuplicateContactAggregate = {
  __typename?: 'DbPotentialDuplicateContactAggregate';
  aggregate?: Maybe<DbPotentialDuplicateContactAggregateFields>;
  nodes: Array<DbPotentialDuplicateContact>;
};

/** aggregate fields of "db.potential_duplicate_contact" */
export type DbPotentialDuplicateContactAggregateFields = {
  __typename?: 'DbPotentialDuplicateContactAggregateFields';
  avg?: Maybe<DbPotentialDuplicateContactAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbPotentialDuplicateContactMaxFields>;
  min?: Maybe<DbPotentialDuplicateContactMinFields>;
  stddev?: Maybe<DbPotentialDuplicateContactStddevFields>;
  stddevPop?: Maybe<DbPotentialDuplicateContactStddevPopFields>;
  stddevSamp?: Maybe<DbPotentialDuplicateContactStddevSampFields>;
  sum?: Maybe<DbPotentialDuplicateContactSumFields>;
  varPop?: Maybe<DbPotentialDuplicateContactVarPopFields>;
  varSamp?: Maybe<DbPotentialDuplicateContactVarSampFields>;
  variance?: Maybe<DbPotentialDuplicateContactVarianceFields>;
};

/** aggregate fields of "db.potential_duplicate_contact" */
export type DbPotentialDuplicateContactAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbPotentialDuplicateContactSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type DbPotentialDuplicateContactAvgFields = {
  __typename?: 'DbPotentialDuplicateContactAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "db.potential_duplicate_contact". All fields are combined with a logical 'AND'. */
export type DbPotentialDuplicateContactBoolExp = {
  _and?: InputMaybe<Array<DbPotentialDuplicateContactBoolExp>>;
  _not?: InputMaybe<DbPotentialDuplicateContactBoolExp>;
  _or?: InputMaybe<Array<DbPotentialDuplicateContactBoolExp>>;
  contact?: InputMaybe<DbContactBoolExp>;
  contactId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  groupId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  matchedEmail?: InputMaybe<StringArrayComparisonExp>;
  matchedName?: InputMaybe<StringComparisonExp>;
  matchedPhone?: InputMaybe<StringArrayComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbPotentialDuplicateContactMaxFields = {
  __typename?: 'DbPotentialDuplicateContactMaxFields';
  contactId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  groupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['String']>;
  matchedEmail?: Maybe<Array<Scalars['String']>>;
  matchedName?: Maybe<Scalars['String']>;
  matchedPhone?: Maybe<Array<Scalars['String']>>;
  num?: Maybe<Scalars['bigint']>;
  shopId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type DbPotentialDuplicateContactMinFields = {
  __typename?: 'DbPotentialDuplicateContactMinFields';
  contactId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  groupId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['String']>;
  matchedEmail?: Maybe<Array<Scalars['String']>>;
  matchedName?: Maybe<Scalars['String']>;
  matchedPhone?: Maybe<Array<Scalars['String']>>;
  num?: Maybe<Scalars['bigint']>;
  shopId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "db.potential_duplicate_contact". */
export type DbPotentialDuplicateContactOrderBy = {
  contact?: InputMaybe<DbContactOrderBy>;
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  groupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  matchedEmail?: InputMaybe<OrderBy>;
  matchedName?: InputMaybe<OrderBy>;
  matchedPhone?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.potential_duplicate_contact" */
export type DbPotentialDuplicateContactSelectColumn =
  /** column name */
  | 'contactId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'groupId'
  /** column name */
  | 'id'
  /** column name */
  | 'matchedEmail'
  /** column name */
  | 'matchedName'
  /** column name */
  | 'matchedPhone'
  /** column name */
  | 'num'
  /** column name */
  | 'shopId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** aggregate stddev on columns */
export type DbPotentialDuplicateContactStddevFields = {
  __typename?: 'DbPotentialDuplicateContactStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type DbPotentialDuplicateContactStddevPopFields = {
  __typename?: 'DbPotentialDuplicateContactStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type DbPotentialDuplicateContactStddevSampFields = {
  __typename?: 'DbPotentialDuplicateContactStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "db_potential_duplicate_contact" */
export type DbPotentialDuplicateContactStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbPotentialDuplicateContactStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbPotentialDuplicateContactStreamCursorValueInput = {
  contactId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  groupId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['String']>;
  matchedEmail?: InputMaybe<Array<Scalars['String']>>;
  matchedName?: InputMaybe<Scalars['String']>;
  matchedPhone?: InputMaybe<Array<Scalars['String']>>;
  num?: InputMaybe<Scalars['bigint']>;
  shopId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbPotentialDuplicateContactSumFields = {
  __typename?: 'DbPotentialDuplicateContactSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** aggregate varPop on columns */
export type DbPotentialDuplicateContactVarPopFields = {
  __typename?: 'DbPotentialDuplicateContactVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type DbPotentialDuplicateContactVarSampFields = {
  __typename?: 'DbPotentialDuplicateContactVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type DbPotentialDuplicateContactVarianceFields = {
  __typename?: 'DbPotentialDuplicateContactVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "db.printed_document_activity" */
export type DbPrintedDocumentActivity = {
  __typename?: 'DbPrintedDocumentActivity';
  id: Scalars['String'];
  printedDocumentId?: Maybe<Scalars['String']>;
  /** An object relationship */
  printed_document_activity_base_activity: DbBaseActivity;
};

/** Boolean expression to filter rows from the table "db.printed_document_activity". All fields are combined with a logical 'AND'. */
export type DbPrintedDocumentActivityBoolExp = {
  _and?: InputMaybe<Array<DbPrintedDocumentActivityBoolExp>>;
  _not?: InputMaybe<DbPrintedDocumentActivityBoolExp>;
  _or?: InputMaybe<Array<DbPrintedDocumentActivityBoolExp>>;
  id?: InputMaybe<StringComparisonExp>;
  printedDocumentId?: InputMaybe<StringComparisonExp>;
  printed_document_activity_base_activity?: InputMaybe<DbBaseActivityBoolExp>;
};

/** Ordering options when selecting data from "db.printed_document_activity". */
export type DbPrintedDocumentActivityOrderBy = {
  id?: InputMaybe<OrderBy>;
  printedDocumentId?: InputMaybe<OrderBy>;
  printed_document_activity_base_activity?: InputMaybe<DbBaseActivityOrderBy>;
};

/** select columns of table "db.printed_document_activity" */
export type DbPrintedDocumentActivitySelectColumn =
  /** column name */
  | 'id'
  /** column name */
  | 'printedDocumentId';

/** Streaming cursor of the table "db_printed_document_activity" */
export type DbPrintedDocumentActivityStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbPrintedDocumentActivityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbPrintedDocumentActivityStreamCursorValueInput = {
  id?: InputMaybe<Scalars['String']>;
  printedDocumentId?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "db.property_lounge" */
export type DbPropertyLounge = {
  __typename?: 'DbPropertyLounge';
  city?: Maybe<Scalars['String']>;
  /** An object relationship */
  country?: Maybe<EnumsCountry>;
  countryCode?: Maybe<CountryEnum>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  name: Scalars['String'];
  num: Scalars['bigint'];
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  /** An object relationship */
  shop: DbShop;
  shopId: Scalars['String'];
  /** An array relationship */
  shopProfiles: Array<DbShopProfile>;
  /** An aggregate relationship */
  shopProfilesAggregate: DbShopProfileAggregate;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** columns and relationships of "db.property_lounge" */
export type DbPropertyLoungeShopProfilesArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileOrderBy>>;
  where?: InputMaybe<DbShopProfileBoolExp>;
};

/** columns and relationships of "db.property_lounge" */
export type DbPropertyLoungeShopProfilesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileOrderBy>>;
  where?: InputMaybe<DbShopProfileBoolExp>;
};

/** order by aggregate values of table "db.property_lounge" */
export type DbPropertyLoungeAggregateOrderBy = {
  avg?: InputMaybe<DbPropertyLoungeAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbPropertyLoungeMaxOrderBy>;
  min?: InputMaybe<DbPropertyLoungeMinOrderBy>;
  stddev?: InputMaybe<DbPropertyLoungeStddevOrderBy>;
  stddevPop?: InputMaybe<DbPropertyLoungeStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbPropertyLoungeStddevSampOrderBy>;
  sum?: InputMaybe<DbPropertyLoungeSumOrderBy>;
  varPop?: InputMaybe<DbPropertyLoungeVarPopOrderBy>;
  varSamp?: InputMaybe<DbPropertyLoungeVarSampOrderBy>;
  variance?: InputMaybe<DbPropertyLoungeVarianceOrderBy>;
};

/** order by avg() on columns of table "db.property_lounge" */
export type DbPropertyLoungeAvgOrderBy = {
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.property_lounge". All fields are combined with a logical 'AND'. */
export type DbPropertyLoungeBoolExp = {
  _and?: InputMaybe<Array<DbPropertyLoungeBoolExp>>;
  _not?: InputMaybe<DbPropertyLoungeBoolExp>;
  _or?: InputMaybe<Array<DbPropertyLoungeBoolExp>>;
  city?: InputMaybe<StringComparisonExp>;
  country?: InputMaybe<EnumsCountryBoolExp>;
  countryCode?: InputMaybe<EnumsCountryEnumComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  latitude?: InputMaybe<Float8ComparisonExp>;
  longitude?: InputMaybe<Float8ComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  phone?: InputMaybe<StringComparisonExp>;
  postalCode?: InputMaybe<StringComparisonExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  shopProfiles?: InputMaybe<DbShopProfileBoolExp>;
  shopProfilesAggregate?: InputMaybe<DbShopProfileAggregateBoolExp>;
  state?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  streetName?: InputMaybe<StringComparisonExp>;
  streetNumber?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.property_lounge" */
export type DbPropertyLoungeMaxOrderBy = {
  city?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  postalCode?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  streetName?: InputMaybe<OrderBy>;
  streetNumber?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.property_lounge" */
export type DbPropertyLoungeMinOrderBy = {
  city?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  postalCode?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  state?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  streetName?: InputMaybe<OrderBy>;
  streetNumber?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.property_lounge". */
export type DbPropertyLoungeOrderBy = {
  city?: InputMaybe<OrderBy>;
  country?: InputMaybe<EnumsCountryOrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  postalCode?: InputMaybe<OrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  shopProfilesAggregate?: InputMaybe<DbShopProfileAggregateOrderBy>;
  state?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  streetName?: InputMaybe<OrderBy>;
  streetNumber?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.property_lounge" */
export type DbPropertyLoungeSelectColumn =
  /** column name */
  | 'city'
  /** column name */
  | 'countryCode'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'email'
  /** column name */
  | 'id'
  /** column name */
  | 'latitude'
  /** column name */
  | 'longitude'
  /** column name */
  | 'name'
  /** column name */
  | 'num'
  /** column name */
  | 'phone'
  /** column name */
  | 'postalCode'
  /** column name */
  | 'shopId'
  /** column name */
  | 'state'
  /** column name */
  | 'status'
  /** column name */
  | 'streetName'
  /** column name */
  | 'streetNumber'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.property_lounge" */
export type DbPropertyLoungeStddevOrderBy = {
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.property_lounge" */
export type DbPropertyLoungeStddevPopOrderBy = {
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.property_lounge" */
export type DbPropertyLoungeStddevSampOrderBy = {
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_property_lounge" */
export type DbPropertyLoungeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbPropertyLoungeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbPropertyLoungeStreamCursorValueInput = {
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<CountryEnum>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  name?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  phone?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  streetName?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.property_lounge" */
export type DbPropertyLoungeSumOrderBy = {
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.property_lounge" */
export type DbPropertyLoungeVarPopOrderBy = {
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.property_lounge" */
export type DbPropertyLoungeVarSampOrderBy = {
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.property_lounge" */
export type DbPropertyLoungeVarianceOrderBy = {
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.qualification_form_link" */
export type DbQualificationFormLink = {
  __typename?: 'DbQualificationFormLink';
  /** An object relationship */
  contact: DbContact;
  contactId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  go3PropertyUtag?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** An object relationship */
  lead?: Maybe<DbLead>;
  leadId?: Maybe<Scalars['String']>;
  num: Scalars['bigint'];
  /** An object relationship */
  qualificationFormStatus: EnumsQualificationFormStatus;
  status: QualificationFormStatusEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.qualification_form_link" */
export type DbQualificationFormLinkAggregate = {
  __typename?: 'DbQualificationFormLinkAggregate';
  aggregate?: Maybe<DbQualificationFormLinkAggregateFields>;
  nodes: Array<DbQualificationFormLink>;
};

export type DbQualificationFormLinkAggregateBoolExp = {
  bool_and?: InputMaybe<DbQualificationFormLinkAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbQualificationFormLinkAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbQualificationFormLinkAggregateBoolExpCount>;
};

/** aggregate fields of "db.qualification_form_link" */
export type DbQualificationFormLinkAggregateFields = {
  __typename?: 'DbQualificationFormLinkAggregateFields';
  avg?: Maybe<DbQualificationFormLinkAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbQualificationFormLinkMaxFields>;
  min?: Maybe<DbQualificationFormLinkMinFields>;
  stddev?: Maybe<DbQualificationFormLinkStddevFields>;
  stddevPop?: Maybe<DbQualificationFormLinkStddevPopFields>;
  stddevSamp?: Maybe<DbQualificationFormLinkStddevSampFields>;
  sum?: Maybe<DbQualificationFormLinkSumFields>;
  varPop?: Maybe<DbQualificationFormLinkVarPopFields>;
  varSamp?: Maybe<DbQualificationFormLinkVarSampFields>;
  variance?: Maybe<DbQualificationFormLinkVarianceFields>;
};

/** aggregate fields of "db.qualification_form_link" */
export type DbQualificationFormLinkAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbQualificationFormLinkSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.qualification_form_link" */
export type DbQualificationFormLinkAggregateOrderBy = {
  avg?: InputMaybe<DbQualificationFormLinkAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbQualificationFormLinkMaxOrderBy>;
  min?: InputMaybe<DbQualificationFormLinkMinOrderBy>;
  stddev?: InputMaybe<DbQualificationFormLinkStddevOrderBy>;
  stddevPop?: InputMaybe<DbQualificationFormLinkStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbQualificationFormLinkStddevSampOrderBy>;
  sum?: InputMaybe<DbQualificationFormLinkSumOrderBy>;
  varPop?: InputMaybe<DbQualificationFormLinkVarPopOrderBy>;
  varSamp?: InputMaybe<DbQualificationFormLinkVarSampOrderBy>;
  variance?: InputMaybe<DbQualificationFormLinkVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbQualificationFormLinkAvgFields = {
  __typename?: 'DbQualificationFormLinkAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.qualification_form_link" */
export type DbQualificationFormLinkAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.qualification_form_link". All fields are combined with a logical 'AND'. */
export type DbQualificationFormLinkBoolExp = {
  _and?: InputMaybe<Array<DbQualificationFormLinkBoolExp>>;
  _not?: InputMaybe<DbQualificationFormLinkBoolExp>;
  _or?: InputMaybe<Array<DbQualificationFormLinkBoolExp>>;
  contact?: InputMaybe<DbContactBoolExp>;
  contactId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  go3PropertyUtag?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lead?: InputMaybe<DbLeadBoolExp>;
  leadId?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  qualificationFormStatus?: InputMaybe<EnumsQualificationFormStatusBoolExp>;
  status?: InputMaybe<EnumsQualificationFormStatusEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbQualificationFormLinkMaxFields = {
  __typename?: 'DbQualificationFormLinkMaxFields';
  contactId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  go3PropertyUtag?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  leadId?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.qualification_form_link" */
export type DbQualificationFormLinkMaxOrderBy = {
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  go3PropertyUtag?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  leadId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbQualificationFormLinkMinFields = {
  __typename?: 'DbQualificationFormLinkMinFields';
  contactId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  go3PropertyUtag?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  leadId?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.qualification_form_link" */
export type DbQualificationFormLinkMinOrderBy = {
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  go3PropertyUtag?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  leadId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.qualification_form_link". */
export type DbQualificationFormLinkOrderBy = {
  contact?: InputMaybe<DbContactOrderBy>;
  contactId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  go3PropertyUtag?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lead?: InputMaybe<DbLeadOrderBy>;
  leadId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  qualificationFormStatus?: InputMaybe<EnumsQualificationFormStatusOrderBy>;
  status?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.qualification_form_link" */
export type DbQualificationFormLinkSelectColumn =
  /** column name */
  | 'contactId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'go3PropertyUtag'
  /** column name */
  | 'id'
  /** column name */
  | 'leadId'
  /** column name */
  | 'num'
  /** column name */
  | 'status'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbQualificationFormLinkAggregateBoolExpBool_andArgumentsColumns" columns of table "db.qualification_form_link" */
export type DbQualificationFormLinkSelectColumnDbQualificationFormLinkAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbQualificationFormLinkAggregateBoolExpBool_orArgumentsColumns" columns of table "db.qualification_form_link" */
export type DbQualificationFormLinkSelectColumnDbQualificationFormLinkAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbQualificationFormLinkStddevFields = {
  __typename?: 'DbQualificationFormLinkStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.qualification_form_link" */
export type DbQualificationFormLinkStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbQualificationFormLinkStddevPopFields = {
  __typename?: 'DbQualificationFormLinkStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.qualification_form_link" */
export type DbQualificationFormLinkStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbQualificationFormLinkStddevSampFields = {
  __typename?: 'DbQualificationFormLinkStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.qualification_form_link" */
export type DbQualificationFormLinkStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_qualification_form_link" */
export type DbQualificationFormLinkStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbQualificationFormLinkStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbQualificationFormLinkStreamCursorValueInput = {
  contactId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  go3PropertyUtag?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  leadId?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<QualificationFormStatusEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbQualificationFormLinkSumFields = {
  __typename?: 'DbQualificationFormLinkSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.qualification_form_link" */
export type DbQualificationFormLinkSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbQualificationFormLinkVarPopFields = {
  __typename?: 'DbQualificationFormLinkVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.qualification_form_link" */
export type DbQualificationFormLinkVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbQualificationFormLinkVarSampFields = {
  __typename?: 'DbQualificationFormLinkVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.qualification_form_link" */
export type DbQualificationFormLinkVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbQualificationFormLinkVarianceFields = {
  __typename?: 'DbQualificationFormLinkVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.qualification_form_link" */
export type DbQualificationFormLinkVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.qualiform_lead_intent" */
export type DbQualiformLeadIntent = {
  __typename?: 'DbQualiformLeadIntent';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  intentType: IntentTypeEnum;
  /** An object relationship */
  intentTypeByIntentType: EnumsIntentType;
  lck: Scalars['Int'];
  num: Scalars['bigint'];
  /** An object relationship */
  shop: DbShop;
  shopId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.qualiform_lead_intent" */
export type DbQualiformLeadIntentAggregateOrderBy = {
  avg?: InputMaybe<DbQualiformLeadIntentAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbQualiformLeadIntentMaxOrderBy>;
  min?: InputMaybe<DbQualiformLeadIntentMinOrderBy>;
  stddev?: InputMaybe<DbQualiformLeadIntentStddevOrderBy>;
  stddevPop?: InputMaybe<DbQualiformLeadIntentStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbQualiformLeadIntentStddevSampOrderBy>;
  sum?: InputMaybe<DbQualiformLeadIntentSumOrderBy>;
  varPop?: InputMaybe<DbQualiformLeadIntentVarPopOrderBy>;
  varSamp?: InputMaybe<DbQualiformLeadIntentVarSampOrderBy>;
  variance?: InputMaybe<DbQualiformLeadIntentVarianceOrderBy>;
};

/** order by avg() on columns of table "db.qualiform_lead_intent" */
export type DbQualiformLeadIntentAvgOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.qualiform_lead_intent". All fields are combined with a logical 'AND'. */
export type DbQualiformLeadIntentBoolExp = {
  _and?: InputMaybe<Array<DbQualiformLeadIntentBoolExp>>;
  _not?: InputMaybe<DbQualiformLeadIntentBoolExp>;
  _or?: InputMaybe<Array<DbQualiformLeadIntentBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  intentType?: InputMaybe<EnumsIntentTypeEnumComparisonExp>;
  intentTypeByIntentType?: InputMaybe<EnumsIntentTypeBoolExp>;
  lck?: InputMaybe<IntComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.qualiform_lead_intent" */
export type DbQualiformLeadIntentMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.qualiform_lead_intent" */
export type DbQualiformLeadIntentMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.qualiform_lead_intent". */
export type DbQualiformLeadIntentOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  intentType?: InputMaybe<OrderBy>;
  intentTypeByIntentType?: InputMaybe<EnumsIntentTypeOrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.qualiform_lead_intent" */
export type DbQualiformLeadIntentSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'intentType'
  /** column name */
  | 'lck'
  /** column name */
  | 'num'
  /** column name */
  | 'shopId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.qualiform_lead_intent" */
export type DbQualiformLeadIntentStddevOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.qualiform_lead_intent" */
export type DbQualiformLeadIntentStddevPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.qualiform_lead_intent" */
export type DbQualiformLeadIntentStddevSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_qualiform_lead_intent" */
export type DbQualiformLeadIntentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbQualiformLeadIntentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbQualiformLeadIntentStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  intentType?: InputMaybe<IntentTypeEnum>;
  lck?: InputMaybe<Scalars['Int']>;
  num?: InputMaybe<Scalars['bigint']>;
  shopId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.qualiform_lead_intent" */
export type DbQualiformLeadIntentSumOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.qualiform_lead_intent" */
export type DbQualiformLeadIntentVarPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.qualiform_lead_intent" */
export type DbQualiformLeadIntentVarSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.qualiform_lead_intent" */
export type DbQualiformLeadIntentVarianceOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.qualiform_lead_source" */
export type DbQualiformLeadSource = {
  __typename?: 'DbQualiformLeadSource';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  lck: Scalars['Int'];
  leadSource: LeadSourceEnum;
  /** An object relationship */
  leadSourceByLeadSource: EnumsLeadSource;
  num: Scalars['bigint'];
  /** An object relationship */
  shop: DbShop;
  shopId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.qualiform_lead_source" */
export type DbQualiformLeadSourceAggregateOrderBy = {
  avg?: InputMaybe<DbQualiformLeadSourceAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbQualiformLeadSourceMaxOrderBy>;
  min?: InputMaybe<DbQualiformLeadSourceMinOrderBy>;
  stddev?: InputMaybe<DbQualiformLeadSourceStddevOrderBy>;
  stddevPop?: InputMaybe<DbQualiformLeadSourceStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbQualiformLeadSourceStddevSampOrderBy>;
  sum?: InputMaybe<DbQualiformLeadSourceSumOrderBy>;
  varPop?: InputMaybe<DbQualiformLeadSourceVarPopOrderBy>;
  varSamp?: InputMaybe<DbQualiformLeadSourceVarSampOrderBy>;
  variance?: InputMaybe<DbQualiformLeadSourceVarianceOrderBy>;
};

/** order by avg() on columns of table "db.qualiform_lead_source" */
export type DbQualiformLeadSourceAvgOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.qualiform_lead_source". All fields are combined with a logical 'AND'. */
export type DbQualiformLeadSourceBoolExp = {
  _and?: InputMaybe<Array<DbQualiformLeadSourceBoolExp>>;
  _not?: InputMaybe<DbQualiformLeadSourceBoolExp>;
  _or?: InputMaybe<Array<DbQualiformLeadSourceBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lck?: InputMaybe<IntComparisonExp>;
  leadSource?: InputMaybe<EnumsLeadSourceEnumComparisonExp>;
  leadSourceByLeadSource?: InputMaybe<EnumsLeadSourceBoolExp>;
  num?: InputMaybe<BigintComparisonExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.qualiform_lead_source" */
export type DbQualiformLeadSourceMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.qualiform_lead_source" */
export type DbQualiformLeadSourceMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.qualiform_lead_source". */
export type DbQualiformLeadSourceOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  leadSource?: InputMaybe<OrderBy>;
  leadSourceByLeadSource?: InputMaybe<EnumsLeadSourceOrderBy>;
  num?: InputMaybe<OrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.qualiform_lead_source" */
export type DbQualiformLeadSourceSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'lck'
  /** column name */
  | 'leadSource'
  /** column name */
  | 'num'
  /** column name */
  | 'shopId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.qualiform_lead_source" */
export type DbQualiformLeadSourceStddevOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.qualiform_lead_source" */
export type DbQualiformLeadSourceStddevPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.qualiform_lead_source" */
export type DbQualiformLeadSourceStddevSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_qualiform_lead_source" */
export type DbQualiformLeadSourceStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbQualiformLeadSourceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbQualiformLeadSourceStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  lck?: InputMaybe<Scalars['Int']>;
  leadSource?: InputMaybe<LeadSourceEnum>;
  num?: InputMaybe<Scalars['bigint']>;
  shopId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.qualiform_lead_source" */
export type DbQualiformLeadSourceSumOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.qualiform_lead_source" */
export type DbQualiformLeadSourceVarPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.qualiform_lead_source" */
export type DbQualiformLeadSourceVarSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.qualiform_lead_source" */
export type DbQualiformLeadSourceVarianceOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.qualiform_mandatory_field" */
export type DbQualiformMandatoryField = {
  __typename?: 'DbQualiformMandatoryField';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  fieldName: QualiformFieldsEnum;
  id: Scalars['String'];
  lck: Scalars['Int'];
  num: Scalars['bigint'];
  /** An object relationship */
  qualiformField: EnumsQualiformFields;
  /** An object relationship */
  shop: DbShop;
  shopId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.qualiform_mandatory_field" */
export type DbQualiformMandatoryFieldAggregateOrderBy = {
  avg?: InputMaybe<DbQualiformMandatoryFieldAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbQualiformMandatoryFieldMaxOrderBy>;
  min?: InputMaybe<DbQualiformMandatoryFieldMinOrderBy>;
  stddev?: InputMaybe<DbQualiformMandatoryFieldStddevOrderBy>;
  stddevPop?: InputMaybe<DbQualiformMandatoryFieldStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbQualiformMandatoryFieldStddevSampOrderBy>;
  sum?: InputMaybe<DbQualiformMandatoryFieldSumOrderBy>;
  varPop?: InputMaybe<DbQualiformMandatoryFieldVarPopOrderBy>;
  varSamp?: InputMaybe<DbQualiformMandatoryFieldVarSampOrderBy>;
  variance?: InputMaybe<DbQualiformMandatoryFieldVarianceOrderBy>;
};

/** order by avg() on columns of table "db.qualiform_mandatory_field" */
export type DbQualiformMandatoryFieldAvgOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.qualiform_mandatory_field". All fields are combined with a logical 'AND'. */
export type DbQualiformMandatoryFieldBoolExp = {
  _and?: InputMaybe<Array<DbQualiformMandatoryFieldBoolExp>>;
  _not?: InputMaybe<DbQualiformMandatoryFieldBoolExp>;
  _or?: InputMaybe<Array<DbQualiformMandatoryFieldBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  fieldName?: InputMaybe<EnumsQualiformFieldsEnumComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lck?: InputMaybe<IntComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  qualiformField?: InputMaybe<EnumsQualiformFieldsBoolExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.qualiform_mandatory_field" */
export type DbQualiformMandatoryFieldMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.qualiform_mandatory_field" */
export type DbQualiformMandatoryFieldMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.qualiform_mandatory_field". */
export type DbQualiformMandatoryFieldOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  fieldName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  qualiformField?: InputMaybe<EnumsQualiformFieldsOrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.qualiform_mandatory_field" */
export type DbQualiformMandatoryFieldSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'fieldName'
  /** column name */
  | 'id'
  /** column name */
  | 'lck'
  /** column name */
  | 'num'
  /** column name */
  | 'shopId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.qualiform_mandatory_field" */
export type DbQualiformMandatoryFieldStddevOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.qualiform_mandatory_field" */
export type DbQualiformMandatoryFieldStddevPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.qualiform_mandatory_field" */
export type DbQualiformMandatoryFieldStddevSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_qualiform_mandatory_field" */
export type DbQualiformMandatoryFieldStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbQualiformMandatoryFieldStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbQualiformMandatoryFieldStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  fieldName?: InputMaybe<QualiformFieldsEnum>;
  id?: InputMaybe<Scalars['String']>;
  lck?: InputMaybe<Scalars['Int']>;
  num?: InputMaybe<Scalars['bigint']>;
  shopId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.qualiform_mandatory_field" */
export type DbQualiformMandatoryFieldSumOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.qualiform_mandatory_field" */
export type DbQualiformMandatoryFieldVarPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.qualiform_mandatory_field" */
export type DbQualiformMandatoryFieldVarSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.qualiform_mandatory_field" */
export type DbQualiformMandatoryFieldVarianceOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.qualiform_shop_setting" */
export type DbQualiformShopSetting = {
  __typename?: 'DbQualiformShopSetting';
  /** An array relationship */
  commissionContracts: Array<DbShopCommissionContract>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  lck: Scalars['Int'];
  num: Scalars['bigint'];
  /** An array relationship */
  rightOfWithdrawals: Array<DbShopRightOfWithdrawal>;
  secondaryLanguageEnglishEnabled: Scalars['Boolean'];
  /** An object relationship */
  shop: DbShop;
  shopId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** columns and relationships of "db.qualiform_shop_setting" */
export type DbQualiformShopSettingCommissionContractsArgs = {
  distinctOn?: InputMaybe<Array<DbShopCommissionContractSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopCommissionContractOrderBy>>;
  where?: InputMaybe<DbShopCommissionContractBoolExp>;
};

/** columns and relationships of "db.qualiform_shop_setting" */
export type DbQualiformShopSettingRightOfWithdrawalsArgs = {
  distinctOn?: InputMaybe<Array<DbShopRightOfWithdrawalSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopRightOfWithdrawalOrderBy>>;
  where?: InputMaybe<DbShopRightOfWithdrawalBoolExp>;
};

/** order by aggregate values of table "db.qualiform_shop_setting" */
export type DbQualiformShopSettingAggregateOrderBy = {
  avg?: InputMaybe<DbQualiformShopSettingAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbQualiformShopSettingMaxOrderBy>;
  min?: InputMaybe<DbQualiformShopSettingMinOrderBy>;
  stddev?: InputMaybe<DbQualiformShopSettingStddevOrderBy>;
  stddevPop?: InputMaybe<DbQualiformShopSettingStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbQualiformShopSettingStddevSampOrderBy>;
  sum?: InputMaybe<DbQualiformShopSettingSumOrderBy>;
  varPop?: InputMaybe<DbQualiformShopSettingVarPopOrderBy>;
  varSamp?: InputMaybe<DbQualiformShopSettingVarSampOrderBy>;
  variance?: InputMaybe<DbQualiformShopSettingVarianceOrderBy>;
};

/** order by avg() on columns of table "db.qualiform_shop_setting" */
export type DbQualiformShopSettingAvgOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.qualiform_shop_setting". All fields are combined with a logical 'AND'. */
export type DbQualiformShopSettingBoolExp = {
  _and?: InputMaybe<Array<DbQualiformShopSettingBoolExp>>;
  _not?: InputMaybe<DbQualiformShopSettingBoolExp>;
  _or?: InputMaybe<Array<DbQualiformShopSettingBoolExp>>;
  commissionContracts?: InputMaybe<DbShopCommissionContractBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lck?: InputMaybe<IntComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  rightOfWithdrawals?: InputMaybe<DbShopRightOfWithdrawalBoolExp>;
  secondaryLanguageEnglishEnabled?: InputMaybe<BooleanComparisonExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.qualiform_shop_setting" */
export type DbQualiformShopSettingMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.qualiform_shop_setting" */
export type DbQualiformShopSettingMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.qualiform_shop_setting". */
export type DbQualiformShopSettingOrderBy = {
  commissionContractsAggregate?: InputMaybe<DbShopCommissionContractAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  rightOfWithdrawalsAggregate?: InputMaybe<DbShopRightOfWithdrawalAggregateOrderBy>;
  secondaryLanguageEnglishEnabled?: InputMaybe<OrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.qualiform_shop_setting" */
export type DbQualiformShopSettingSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'lck'
  /** column name */
  | 'num'
  /** column name */
  | 'secondaryLanguageEnglishEnabled'
  /** column name */
  | 'shopId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.qualiform_shop_setting" */
export type DbQualiformShopSettingStddevOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.qualiform_shop_setting" */
export type DbQualiformShopSettingStddevPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.qualiform_shop_setting" */
export type DbQualiformShopSettingStddevSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_qualiform_shop_setting" */
export type DbQualiformShopSettingStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbQualiformShopSettingStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbQualiformShopSettingStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  lck?: InputMaybe<Scalars['Int']>;
  num?: InputMaybe<Scalars['bigint']>;
  secondaryLanguageEnglishEnabled?: InputMaybe<Scalars['Boolean']>;
  shopId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.qualiform_shop_setting" */
export type DbQualiformShopSettingSumOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.qualiform_shop_setting" */
export type DbQualiformShopSettingVarPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.qualiform_shop_setting" */
export type DbQualiformShopSettingVarSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.qualiform_shop_setting" */
export type DbQualiformShopSettingVarianceOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.referral" */
export type DbReferral = {
  __typename?: 'DbReferral';
  acceptedRefusedDate?: Maybe<Scalars['timestamptz']>;
  acceptedRefusedUser?: Maybe<Scalars['String']>;
  /** An object relationship */
  agent: DbAgent;
  /** An object relationship */
  agentByReceivingAgentId?: Maybe<DbAgent>;
  agentId: Scalars['String'];
  commission: Scalars['float8'];
  /** An object relationship */
  contact?: Maybe<DbContact>;
  /** An object relationship */
  contactByReceivingLeadIdWrapper?: Maybe<DbOneWayMappingFromLeadIdToContactId>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  go3ReferralId?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  lastGoUpdateAttempt?: Maybe<Scalars['timestamptz']>;
  lastGoUpdateStatus: Scalars['String'];
  /** An object relationship */
  lead?: Maybe<DbLead>;
  /** An object relationship */
  leadByReceivingLeadId?: Maybe<DbLead>;
  leadId?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  num: Scalars['bigint'];
  receivingAgentId?: Maybe<Scalars['String']>;
  receivingGo3ContactUtag?: Maybe<Scalars['String']>;
  receivingLeadId?: Maybe<Scalars['String']>;
  /** An object relationship */
  receivingShop?: Maybe<DbShop>;
  receivingShopId?: Maybe<Scalars['String']>;
  receivingTeamId?: Maybe<Scalars['String']>;
  /** An array relationship */
  referralActivities: Array<DbReferralActivity>;
  referralAgentNote?: Maybe<Scalars['String']>;
  referralNotPossibleReason?: Maybe<ReferralNotPossibleReasonEnum>;
  /** An object relationship */
  referralNotPossibleReasonByReferralNotPossibleReason?: Maybe<EnumsReferralNotPossibleReason>;
  referralStatus: ReferralStatusEnum;
  sendingContactId?: Maybe<Scalars['String']>;
  sendingGo3ContactUtag?: Maybe<Scalars['String']>;
  /** An object relationship */
  sendingShop?: Maybe<DbShop>;
  sendingShopId: Scalars['String'];
  sendingTeamId?: Maybe<Scalars['String']>;
  snapshotId: Scalars['String'];
  /** An object relationship */
  snapshotLead: DbSnapshotLead;
  /** An object relationship */
  teamByReceivingTeamId?: Maybe<DbTeam>;
  /** An object relationship */
  teamBySendingTeamId?: Maybe<DbTeam>;
  type: ReferralTypeEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  zeroCommissionReason?: Maybe<ZeroCommissionReasonEnum>;
  /** An object relationship */
  zeroCommissionReasonByZeroCommissionReason?: Maybe<EnumsZeroCommissionReason>;
};

/** columns and relationships of "db.referral" */
export type DbReferralReferralActivitiesArgs = {
  distinctOn?: InputMaybe<Array<DbReferralActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralActivityOrderBy>>;
  where?: InputMaybe<DbReferralActivityBoolExp>;
};

/** columns and relationships of "db.referral_activity" */
export type DbReferralActivity = {
  __typename?: 'DbReferralActivity';
  /** An object relationship */
  baseActivity: DbBaseActivity;
  id: Scalars['String'];
  /** An object relationship */
  referral: DbReferral;
  referralId: Scalars['String'];
};

/** order by aggregate values of table "db.referral_activity" */
export type DbReferralActivityAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbReferralActivityMaxOrderBy>;
  min?: InputMaybe<DbReferralActivityMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.referral_activity". All fields are combined with a logical 'AND'. */
export type DbReferralActivityBoolExp = {
  _and?: InputMaybe<Array<DbReferralActivityBoolExp>>;
  _not?: InputMaybe<DbReferralActivityBoolExp>;
  _or?: InputMaybe<Array<DbReferralActivityBoolExp>>;
  baseActivity?: InputMaybe<DbBaseActivityBoolExp>;
  id?: InputMaybe<StringComparisonExp>;
  referral?: InputMaybe<DbReferralBoolExp>;
  referralId?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.referral_activity" */
export type DbReferralActivityMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.referral_activity" */
export type DbReferralActivityMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  referralId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.referral_activity". */
export type DbReferralActivityOrderBy = {
  baseActivity?: InputMaybe<DbBaseActivityOrderBy>;
  id?: InputMaybe<OrderBy>;
  referral?: InputMaybe<DbReferralOrderBy>;
  referralId?: InputMaybe<OrderBy>;
};

/** select columns of table "db.referral_activity" */
export type DbReferralActivitySelectColumn =
  /** column name */
  | 'id'
  /** column name */
  | 'referralId';

/** Streaming cursor of the table "db_referral_activity" */
export type DbReferralActivityStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbReferralActivityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbReferralActivityStreamCursorValueInput = {
  id?: InputMaybe<Scalars['String']>;
  referralId?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "db.referral" */
export type DbReferralAggregate = {
  __typename?: 'DbReferralAggregate';
  aggregate?: Maybe<DbReferralAggregateFields>;
  nodes: Array<DbReferral>;
};

export type DbReferralAggregateBoolExp = {
  avg?: InputMaybe<DbReferralAggregateBoolExpAvg>;
  bool_and?: InputMaybe<DbReferralAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbReferralAggregateBoolExpBoolOr>;
  corr?: InputMaybe<DbReferralAggregateBoolExpCorr>;
  count?: InputMaybe<DbReferralAggregateBoolExpCount>;
  covar_samp?: InputMaybe<DbReferralAggregateBoolExpCovarSamp>;
  max?: InputMaybe<DbReferralAggregateBoolExpMax>;
  min?: InputMaybe<DbReferralAggregateBoolExpMin>;
  stddev_samp?: InputMaybe<DbReferralAggregateBoolExpStddevSamp>;
  sum?: InputMaybe<DbReferralAggregateBoolExpSum>;
  var_samp?: InputMaybe<DbReferralAggregateBoolExpVarSamp>;
};

/** aggregate fields of "db.referral" */
export type DbReferralAggregateFields = {
  __typename?: 'DbReferralAggregateFields';
  avg?: Maybe<DbReferralAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbReferralMaxFields>;
  min?: Maybe<DbReferralMinFields>;
  stddev?: Maybe<DbReferralStddevFields>;
  stddevPop?: Maybe<DbReferralStddevPopFields>;
  stddevSamp?: Maybe<DbReferralStddevSampFields>;
  sum?: Maybe<DbReferralSumFields>;
  varPop?: Maybe<DbReferralVarPopFields>;
  varSamp?: Maybe<DbReferralVarSampFields>;
  variance?: Maybe<DbReferralVarianceFields>;
};

/** aggregate fields of "db.referral" */
export type DbReferralAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbReferralSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.referral" */
export type DbReferralAggregateOrderBy = {
  avg?: InputMaybe<DbReferralAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbReferralMaxOrderBy>;
  min?: InputMaybe<DbReferralMinOrderBy>;
  stddev?: InputMaybe<DbReferralStddevOrderBy>;
  stddevPop?: InputMaybe<DbReferralStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbReferralStddevSampOrderBy>;
  sum?: InputMaybe<DbReferralSumOrderBy>;
  varPop?: InputMaybe<DbReferralVarPopOrderBy>;
  varSamp?: InputMaybe<DbReferralVarSampOrderBy>;
  variance?: InputMaybe<DbReferralVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbReferralAvgFields = {
  __typename?: 'DbReferralAvgFields';
  commission?: Maybe<Scalars['Float']>;
  go3ReferralId?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.referral" */
export type DbReferralAvgOrderBy = {
  commission?: InputMaybe<OrderBy>;
  go3ReferralId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.referral". All fields are combined with a logical 'AND'. */
export type DbReferralBoolExp = {
  _and?: InputMaybe<Array<DbReferralBoolExp>>;
  _not?: InputMaybe<DbReferralBoolExp>;
  _or?: InputMaybe<Array<DbReferralBoolExp>>;
  acceptedRefusedDate?: InputMaybe<TimestamptzComparisonExp>;
  acceptedRefusedUser?: InputMaybe<StringComparisonExp>;
  agent?: InputMaybe<DbAgentBoolExp>;
  agentByReceivingAgentId?: InputMaybe<DbAgentBoolExp>;
  agentId?: InputMaybe<StringComparisonExp>;
  commission?: InputMaybe<Float8ComparisonExp>;
  contact?: InputMaybe<DbContactBoolExp>;
  contactByReceivingLeadIdWrapper?: InputMaybe<DbOneWayMappingFromLeadIdToContactIdBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  go3ReferralId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lastGoUpdateAttempt?: InputMaybe<TimestamptzComparisonExp>;
  lastGoUpdateStatus?: InputMaybe<StringComparisonExp>;
  lead?: InputMaybe<DbLeadBoolExp>;
  leadByReceivingLeadId?: InputMaybe<DbLeadBoolExp>;
  leadId?: InputMaybe<StringComparisonExp>;
  note?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  receivingAgentId?: InputMaybe<StringComparisonExp>;
  receivingGo3ContactUtag?: InputMaybe<StringComparisonExp>;
  receivingLeadId?: InputMaybe<StringComparisonExp>;
  receivingShop?: InputMaybe<DbShopBoolExp>;
  receivingShopId?: InputMaybe<StringComparisonExp>;
  receivingTeamId?: InputMaybe<StringComparisonExp>;
  referralActivities?: InputMaybe<DbReferralActivityBoolExp>;
  referralAgentNote?: InputMaybe<StringComparisonExp>;
  referralNotPossibleReason?: InputMaybe<EnumsReferralNotPossibleReasonEnumComparisonExp>;
  referralNotPossibleReasonByReferralNotPossibleReason?: InputMaybe<EnumsReferralNotPossibleReasonBoolExp>;
  referralStatus?: InputMaybe<EnumsReferralStatusEnumComparisonExp>;
  sendingContactId?: InputMaybe<StringComparisonExp>;
  sendingGo3ContactUtag?: InputMaybe<StringComparisonExp>;
  sendingShop?: InputMaybe<DbShopBoolExp>;
  sendingShopId?: InputMaybe<StringComparisonExp>;
  sendingTeamId?: InputMaybe<StringComparisonExp>;
  snapshotId?: InputMaybe<StringComparisonExp>;
  snapshotLead?: InputMaybe<DbSnapshotLeadBoolExp>;
  teamByReceivingTeamId?: InputMaybe<DbTeamBoolExp>;
  teamBySendingTeamId?: InputMaybe<DbTeamBoolExp>;
  type?: InputMaybe<EnumsReferralTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  zeroCommissionReason?: InputMaybe<EnumsZeroCommissionReasonEnumComparisonExp>;
  zeroCommissionReasonByZeroCommissionReason?: InputMaybe<EnumsZeroCommissionReasonBoolExp>;
};

/** aggregate max on columns */
export type DbReferralMaxFields = {
  __typename?: 'DbReferralMaxFields';
  acceptedRefusedDate?: Maybe<Scalars['timestamptz']>;
  acceptedRefusedUser?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['String']>;
  commission?: Maybe<Scalars['float8']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  go3ReferralId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lastGoUpdateAttempt?: Maybe<Scalars['timestamptz']>;
  lastGoUpdateStatus?: Maybe<Scalars['String']>;
  leadId?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  receivingAgentId?: Maybe<Scalars['String']>;
  receivingGo3ContactUtag?: Maybe<Scalars['String']>;
  receivingLeadId?: Maybe<Scalars['String']>;
  receivingShopId?: Maybe<Scalars['String']>;
  receivingTeamId?: Maybe<Scalars['String']>;
  referralAgentNote?: Maybe<Scalars['String']>;
  sendingContactId?: Maybe<Scalars['String']>;
  sendingGo3ContactUtag?: Maybe<Scalars['String']>;
  sendingShopId?: Maybe<Scalars['String']>;
  sendingTeamId?: Maybe<Scalars['String']>;
  snapshotId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.referral" */
export type DbReferralMaxOrderBy = {
  acceptedRefusedDate?: InputMaybe<OrderBy>;
  acceptedRefusedUser?: InputMaybe<OrderBy>;
  agentId?: InputMaybe<OrderBy>;
  commission?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  go3ReferralId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastGoUpdateAttempt?: InputMaybe<OrderBy>;
  lastGoUpdateStatus?: InputMaybe<OrderBy>;
  leadId?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  receivingAgentId?: InputMaybe<OrderBy>;
  receivingGo3ContactUtag?: InputMaybe<OrderBy>;
  receivingLeadId?: InputMaybe<OrderBy>;
  receivingShopId?: InputMaybe<OrderBy>;
  receivingTeamId?: InputMaybe<OrderBy>;
  referralAgentNote?: InputMaybe<OrderBy>;
  sendingContactId?: InputMaybe<OrderBy>;
  sendingGo3ContactUtag?: InputMaybe<OrderBy>;
  sendingShopId?: InputMaybe<OrderBy>;
  sendingTeamId?: InputMaybe<OrderBy>;
  snapshotId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbReferralMinFields = {
  __typename?: 'DbReferralMinFields';
  acceptedRefusedDate?: Maybe<Scalars['timestamptz']>;
  acceptedRefusedUser?: Maybe<Scalars['String']>;
  agentId?: Maybe<Scalars['String']>;
  commission?: Maybe<Scalars['float8']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  go3ReferralId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  lastGoUpdateAttempt?: Maybe<Scalars['timestamptz']>;
  lastGoUpdateStatus?: Maybe<Scalars['String']>;
  leadId?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  receivingAgentId?: Maybe<Scalars['String']>;
  receivingGo3ContactUtag?: Maybe<Scalars['String']>;
  receivingLeadId?: Maybe<Scalars['String']>;
  receivingShopId?: Maybe<Scalars['String']>;
  receivingTeamId?: Maybe<Scalars['String']>;
  referralAgentNote?: Maybe<Scalars['String']>;
  sendingContactId?: Maybe<Scalars['String']>;
  sendingGo3ContactUtag?: Maybe<Scalars['String']>;
  sendingShopId?: Maybe<Scalars['String']>;
  sendingTeamId?: Maybe<Scalars['String']>;
  snapshotId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.referral" */
export type DbReferralMinOrderBy = {
  acceptedRefusedDate?: InputMaybe<OrderBy>;
  acceptedRefusedUser?: InputMaybe<OrderBy>;
  agentId?: InputMaybe<OrderBy>;
  commission?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  go3ReferralId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastGoUpdateAttempt?: InputMaybe<OrderBy>;
  lastGoUpdateStatus?: InputMaybe<OrderBy>;
  leadId?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  receivingAgentId?: InputMaybe<OrderBy>;
  receivingGo3ContactUtag?: InputMaybe<OrderBy>;
  receivingLeadId?: InputMaybe<OrderBy>;
  receivingShopId?: InputMaybe<OrderBy>;
  receivingTeamId?: InputMaybe<OrderBy>;
  referralAgentNote?: InputMaybe<OrderBy>;
  sendingContactId?: InputMaybe<OrderBy>;
  sendingGo3ContactUtag?: InputMaybe<OrderBy>;
  sendingShopId?: InputMaybe<OrderBy>;
  sendingTeamId?: InputMaybe<OrderBy>;
  snapshotId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.referral". */
export type DbReferralOrderBy = {
  acceptedRefusedDate?: InputMaybe<OrderBy>;
  acceptedRefusedUser?: InputMaybe<OrderBy>;
  agent?: InputMaybe<DbAgentOrderBy>;
  agentByReceivingAgentId?: InputMaybe<DbAgentOrderBy>;
  agentId?: InputMaybe<OrderBy>;
  commission?: InputMaybe<OrderBy>;
  contact?: InputMaybe<DbContactOrderBy>;
  contactByReceivingLeadIdWrapper?: InputMaybe<DbOneWayMappingFromLeadIdToContactIdOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  go3ReferralId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastGoUpdateAttempt?: InputMaybe<OrderBy>;
  lastGoUpdateStatus?: InputMaybe<OrderBy>;
  lead?: InputMaybe<DbLeadOrderBy>;
  leadByReceivingLeadId?: InputMaybe<DbLeadOrderBy>;
  leadId?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  receivingAgentId?: InputMaybe<OrderBy>;
  receivingGo3ContactUtag?: InputMaybe<OrderBy>;
  receivingLeadId?: InputMaybe<OrderBy>;
  receivingShop?: InputMaybe<DbShopOrderBy>;
  receivingShopId?: InputMaybe<OrderBy>;
  receivingTeamId?: InputMaybe<OrderBy>;
  referralActivitiesAggregate?: InputMaybe<DbReferralActivityAggregateOrderBy>;
  referralAgentNote?: InputMaybe<OrderBy>;
  referralNotPossibleReason?: InputMaybe<OrderBy>;
  referralNotPossibleReasonByReferralNotPossibleReason?: InputMaybe<EnumsReferralNotPossibleReasonOrderBy>;
  referralStatus?: InputMaybe<OrderBy>;
  sendingContactId?: InputMaybe<OrderBy>;
  sendingGo3ContactUtag?: InputMaybe<OrderBy>;
  sendingShop?: InputMaybe<DbShopOrderBy>;
  sendingShopId?: InputMaybe<OrderBy>;
  sendingTeamId?: InputMaybe<OrderBy>;
  snapshotId?: InputMaybe<OrderBy>;
  snapshotLead?: InputMaybe<DbSnapshotLeadOrderBy>;
  teamByReceivingTeamId?: InputMaybe<DbTeamOrderBy>;
  teamBySendingTeamId?: InputMaybe<DbTeamOrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  zeroCommissionReason?: InputMaybe<OrderBy>;
  zeroCommissionReasonByZeroCommissionReason?: InputMaybe<EnumsZeroCommissionReasonOrderBy>;
};

/** select columns of table "db.referral" */
export type DbReferralSelectColumn =
  /** column name */
  | 'acceptedRefusedDate'
  /** column name */
  | 'acceptedRefusedUser'
  /** column name */
  | 'agentId'
  /** column name */
  | 'commission'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'go3ReferralId'
  /** column name */
  | 'id'
  /** column name */
  | 'lastGoUpdateAttempt'
  /** column name */
  | 'lastGoUpdateStatus'
  /** column name */
  | 'leadId'
  /** column name */
  | 'note'
  /** column name */
  | 'num'
  /** column name */
  | 'receivingAgentId'
  /** column name */
  | 'receivingGo3ContactUtag'
  /** column name */
  | 'receivingLeadId'
  /** column name */
  | 'receivingShopId'
  /** column name */
  | 'receivingTeamId'
  /** column name */
  | 'referralAgentNote'
  /** column name */
  | 'referralNotPossibleReason'
  /** column name */
  | 'referralStatus'
  /** column name */
  | 'sendingContactId'
  /** column name */
  | 'sendingGo3ContactUtag'
  /** column name */
  | 'sendingShopId'
  /** column name */
  | 'sendingTeamId'
  /** column name */
  | 'snapshotId'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy'
  /** column name */
  | 'zeroCommissionReason';

/** select "dbReferralAggregateBoolExpAvgArgumentsColumns" columns of table "db.referral" */
export type DbReferralSelectColumnDbReferralAggregateBoolExpAvgArgumentsColumns =
  /** column name */
  'commission';

/** select "dbReferralAggregateBoolExpBool_andArgumentsColumns" columns of table "db.referral" */
export type DbReferralSelectColumnDbReferralAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbReferralAggregateBoolExpBool_orArgumentsColumns" columns of table "db.referral" */
export type DbReferralSelectColumnDbReferralAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbReferralAggregateBoolExpCorrArgumentsColumns" columns of table "db.referral" */
export type DbReferralSelectColumnDbReferralAggregateBoolExpCorrArgumentsColumns =
  /** column name */
  'commission';

/** select "dbReferralAggregateBoolExpCovar_sampArgumentsColumns" columns of table "db.referral" */
export type DbReferralSelectColumnDbReferralAggregateBoolExpCovarSampArgumentsColumns =
  /** column name */
  'commission';

/** select "dbReferralAggregateBoolExpMaxArgumentsColumns" columns of table "db.referral" */
export type DbReferralSelectColumnDbReferralAggregateBoolExpMaxArgumentsColumns =
  /** column name */
  'commission';

/** select "dbReferralAggregateBoolExpMinArgumentsColumns" columns of table "db.referral" */
export type DbReferralSelectColumnDbReferralAggregateBoolExpMinArgumentsColumns =
  /** column name */
  'commission';

/** select "dbReferralAggregateBoolExpStddev_sampArgumentsColumns" columns of table "db.referral" */
export type DbReferralSelectColumnDbReferralAggregateBoolExpStddevSampArgumentsColumns =
  /** column name */
  'commission';

/** select "dbReferralAggregateBoolExpSumArgumentsColumns" columns of table "db.referral" */
export type DbReferralSelectColumnDbReferralAggregateBoolExpSumArgumentsColumns =
  /** column name */
  'commission';

/** select "dbReferralAggregateBoolExpVar_sampArgumentsColumns" columns of table "db.referral" */
export type DbReferralSelectColumnDbReferralAggregateBoolExpVarSampArgumentsColumns =
  /** column name */
  'commission';

/** aggregate stddev on columns */
export type DbReferralStddevFields = {
  __typename?: 'DbReferralStddevFields';
  commission?: Maybe<Scalars['Float']>;
  go3ReferralId?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.referral" */
export type DbReferralStddevOrderBy = {
  commission?: InputMaybe<OrderBy>;
  go3ReferralId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbReferralStddevPopFields = {
  __typename?: 'DbReferralStddevPopFields';
  commission?: Maybe<Scalars['Float']>;
  go3ReferralId?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.referral" */
export type DbReferralStddevPopOrderBy = {
  commission?: InputMaybe<OrderBy>;
  go3ReferralId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbReferralStddevSampFields = {
  __typename?: 'DbReferralStddevSampFields';
  commission?: Maybe<Scalars['Float']>;
  go3ReferralId?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.referral" */
export type DbReferralStddevSampOrderBy = {
  commission?: InputMaybe<OrderBy>;
  go3ReferralId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_referral" */
export type DbReferralStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbReferralStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbReferralStreamCursorValueInput = {
  acceptedRefusedDate?: InputMaybe<Scalars['timestamptz']>;
  acceptedRefusedUser?: InputMaybe<Scalars['String']>;
  agentId?: InputMaybe<Scalars['String']>;
  commission?: InputMaybe<Scalars['float8']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  go3ReferralId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  lastGoUpdateAttempt?: InputMaybe<Scalars['timestamptz']>;
  lastGoUpdateStatus?: InputMaybe<Scalars['String']>;
  leadId?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  receivingAgentId?: InputMaybe<Scalars['String']>;
  receivingGo3ContactUtag?: InputMaybe<Scalars['String']>;
  receivingLeadId?: InputMaybe<Scalars['String']>;
  receivingShopId?: InputMaybe<Scalars['String']>;
  receivingTeamId?: InputMaybe<Scalars['String']>;
  referralAgentNote?: InputMaybe<Scalars['String']>;
  referralNotPossibleReason?: InputMaybe<ReferralNotPossibleReasonEnum>;
  referralStatus?: InputMaybe<ReferralStatusEnum>;
  sendingContactId?: InputMaybe<Scalars['String']>;
  sendingGo3ContactUtag?: InputMaybe<Scalars['String']>;
  sendingShopId?: InputMaybe<Scalars['String']>;
  sendingTeamId?: InputMaybe<Scalars['String']>;
  snapshotId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ReferralTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  zeroCommissionReason?: InputMaybe<ZeroCommissionReasonEnum>;
};

/** aggregate sum on columns */
export type DbReferralSumFields = {
  __typename?: 'DbReferralSumFields';
  commission?: Maybe<Scalars['float8']>;
  go3ReferralId?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.referral" */
export type DbReferralSumOrderBy = {
  commission?: InputMaybe<OrderBy>;
  go3ReferralId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbReferralVarPopFields = {
  __typename?: 'DbReferralVarPopFields';
  commission?: Maybe<Scalars['Float']>;
  go3ReferralId?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.referral" */
export type DbReferralVarPopOrderBy = {
  commission?: InputMaybe<OrderBy>;
  go3ReferralId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbReferralVarSampFields = {
  __typename?: 'DbReferralVarSampFields';
  commission?: Maybe<Scalars['Float']>;
  go3ReferralId?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.referral" */
export type DbReferralVarSampOrderBy = {
  commission?: InputMaybe<OrderBy>;
  go3ReferralId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbReferralVarianceFields = {
  __typename?: 'DbReferralVarianceFields';
  commission?: Maybe<Scalars['Float']>;
  go3ReferralId?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.referral" */
export type DbReferralVarianceOrderBy = {
  commission?: InputMaybe<OrderBy>;
  go3ReferralId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.seller_rent_out_specification" */
export type DbSellerRentOutSpecification = {
  __typename?: 'DbSellerRentOutSpecification';
  /** An object relationship */
  address?: Maybe<DbAddress>;
  addressId?: Maybe<Scalars['String']>;
  /** An object relationship */
  assetCondition?: Maybe<EnumsAssetCondition>;
  /** An object relationship */
  assetSubType?: Maybe<EnumsAssetSubType>;
  /** An object relationship */
  assetType?: Maybe<EnumsAssetType>;
  balcony?: Maybe<Scalars['Boolean']>;
  condition?: Maybe<AssetConditionEnum>;
  constructionYear?: Maybe<Scalars['Int']>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  /** An object relationship */
  currency: EnumsCurrency;
  /** An object relationship */
  currencyByRentPriceUnit: EnumsCurrency;
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  elevator?: Maybe<Scalars['Boolean']>;
  floor?: Maybe<Scalars['Int']>;
  garage?: Maybe<Scalars['Boolean']>;
  garden?: Maybe<Scalars['Boolean']>;
  guestToilet?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isOccupiedByOwner: Scalars['Boolean'];
  lck: Scalars['Int'];
  /** An object relationship */
  lead?: Maybe<DbLead>;
  /** An array relationship */
  leads: Array<DbLead>;
  /** An aggregate relationship */
  leadsAggregate: DbLeadAggregate;
  livingArea?: Maybe<Scalars['float8']>;
  num: Scalars['bigint'];
  numberOfBathrooms?: Maybe<Scalars['Float']>;
  numberOfBedrooms?: Maybe<Scalars['Float']>;
  numberOfMasterBathrooms?: Maybe<Scalars['Float']>;
  numberOfRooms?: Maybe<Scalars['Float']>;
  occupationStatus?: Maybe<PropertyOccupationStatusEnum>;
  /** An object relationship */
  owner?: Maybe<DbContact>;
  ownerDescription?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  ownershipStatus?: Maybe<PropertyOwnershipStatusEnum>;
  parking?: Maybe<Scalars['Boolean']>;
  plotArea?: Maybe<Scalars['float8']>;
  priceRangeFrom?: Maybe<Scalars['numeric']>;
  priceRangeTo?: Maybe<Scalars['numeric']>;
  priceRangeUnit: CurrencyEnum;
  /** An object relationship */
  propertyOccupationStatus?: Maybe<EnumsPropertyOccupationStatus>;
  /** An object relationship */
  propertyOwnershipStatus?: Maybe<EnumsPropertyOwnershipStatus>;
  rentPriceUnit: CurrencyEnum;
  rentRangeFrom?: Maybe<Scalars['numeric']>;
  rentRangeTo?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  sellerRentOutSpecificationCdfs: Array<DbSellerRentOutSpecificationCdf>;
  subType?: Maybe<AssetSubTypeEnum>;
  surfaceTotal?: Maybe<Scalars['float8']>;
  surfaceUsable?: Maybe<Scalars['float8']>;
  swimmingPool?: Maybe<Scalars['Boolean']>;
  totalFloors?: Maybe<Scalars['Int']>;
  type?: Maybe<AssetTypeEnum>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  variation?: Maybe<AssetVariationEnum>;
};

/** columns and relationships of "db.seller_rent_out_specification" */
export type DbSellerRentOutSpecificationLeadsArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

/** columns and relationships of "db.seller_rent_out_specification" */
export type DbSellerRentOutSpecificationLeadsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

/** columns and relationships of "db.seller_rent_out_specification" */
export type DbSellerRentOutSpecificationSellerRentOutSpecificationCdfsArgs = {
  distinctOn?: InputMaybe<Array<DbSellerRentOutSpecificationCdfSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSellerRentOutSpecificationCdfOrderBy>>;
  where?: InputMaybe<DbSellerRentOutSpecificationCdfBoolExp>;
};

/** order by aggregate values of table "db.seller_rent_out_specification" */
export type DbSellerRentOutSpecificationAggregateOrderBy = {
  avg?: InputMaybe<DbSellerRentOutSpecificationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbSellerRentOutSpecificationMaxOrderBy>;
  min?: InputMaybe<DbSellerRentOutSpecificationMinOrderBy>;
  stddev?: InputMaybe<DbSellerRentOutSpecificationStddevOrderBy>;
  stddevPop?: InputMaybe<DbSellerRentOutSpecificationStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbSellerRentOutSpecificationStddevSampOrderBy>;
  sum?: InputMaybe<DbSellerRentOutSpecificationSumOrderBy>;
  varPop?: InputMaybe<DbSellerRentOutSpecificationVarPopOrderBy>;
  varSamp?: InputMaybe<DbSellerRentOutSpecificationVarSampOrderBy>;
  variance?: InputMaybe<DbSellerRentOutSpecificationVarianceOrderBy>;
};

/** order by avg() on columns of table "db.seller_rent_out_specification" */
export type DbSellerRentOutSpecificationAvgOrderBy = {
  constructionYear?: InputMaybe<OrderBy>;
  floor?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  livingArea?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  numberOfBathrooms?: InputMaybe<OrderBy>;
  numberOfBedrooms?: InputMaybe<OrderBy>;
  numberOfMasterBathrooms?: InputMaybe<OrderBy>;
  numberOfRooms?: InputMaybe<OrderBy>;
  plotArea?: InputMaybe<OrderBy>;
  priceRangeFrom?: InputMaybe<OrderBy>;
  priceRangeTo?: InputMaybe<OrderBy>;
  rentRangeFrom?: InputMaybe<OrderBy>;
  rentRangeTo?: InputMaybe<OrderBy>;
  surfaceTotal?: InputMaybe<OrderBy>;
  surfaceUsable?: InputMaybe<OrderBy>;
  totalFloors?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.seller_rent_out_specification". All fields are combined with a logical 'AND'. */
export type DbSellerRentOutSpecificationBoolExp = {
  _and?: InputMaybe<Array<DbSellerRentOutSpecificationBoolExp>>;
  _not?: InputMaybe<DbSellerRentOutSpecificationBoolExp>;
  _or?: InputMaybe<Array<DbSellerRentOutSpecificationBoolExp>>;
  address?: InputMaybe<DbAddressBoolExp>;
  addressId?: InputMaybe<StringComparisonExp>;
  assetCondition?: InputMaybe<EnumsAssetConditionBoolExp>;
  assetSubType?: InputMaybe<EnumsAssetSubTypeBoolExp>;
  assetType?: InputMaybe<EnumsAssetTypeBoolExp>;
  balcony?: InputMaybe<BooleanComparisonExp>;
  condition?: InputMaybe<EnumsAssetConditionEnumComparisonExp>;
  constructionYear?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  currency?: InputMaybe<EnumsCurrencyBoolExp>;
  currencyByRentPriceUnit?: InputMaybe<EnumsCurrencyBoolExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  elevator?: InputMaybe<BooleanComparisonExp>;
  floor?: InputMaybe<IntComparisonExp>;
  garage?: InputMaybe<BooleanComparisonExp>;
  garden?: InputMaybe<BooleanComparisonExp>;
  guestToilet?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  isOccupiedByOwner?: InputMaybe<BooleanComparisonExp>;
  lck?: InputMaybe<IntComparisonExp>;
  lead?: InputMaybe<DbLeadBoolExp>;
  leads?: InputMaybe<DbLeadBoolExp>;
  leadsAggregate?: InputMaybe<DbLeadAggregateBoolExp>;
  livingArea?: InputMaybe<Float8ComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  numberOfBathrooms?: InputMaybe<FloatComparisonExp>;
  numberOfBedrooms?: InputMaybe<FloatComparisonExp>;
  numberOfMasterBathrooms?: InputMaybe<FloatComparisonExp>;
  numberOfRooms?: InputMaybe<FloatComparisonExp>;
  occupationStatus?: InputMaybe<EnumsPropertyOccupationStatusEnumComparisonExp>;
  owner?: InputMaybe<DbContactBoolExp>;
  ownerDescription?: InputMaybe<StringComparisonExp>;
  ownerId?: InputMaybe<StringComparisonExp>;
  ownershipStatus?: InputMaybe<EnumsPropertyOwnershipStatusEnumComparisonExp>;
  parking?: InputMaybe<BooleanComparisonExp>;
  plotArea?: InputMaybe<Float8ComparisonExp>;
  priceRangeFrom?: InputMaybe<NumericComparisonExp>;
  priceRangeTo?: InputMaybe<NumericComparisonExp>;
  priceRangeUnit?: InputMaybe<EnumsCurrencyEnumComparisonExp>;
  propertyOccupationStatus?: InputMaybe<EnumsPropertyOccupationStatusBoolExp>;
  propertyOwnershipStatus?: InputMaybe<EnumsPropertyOwnershipStatusBoolExp>;
  rentPriceUnit?: InputMaybe<EnumsCurrencyEnumComparisonExp>;
  rentRangeFrom?: InputMaybe<NumericComparisonExp>;
  rentRangeTo?: InputMaybe<NumericComparisonExp>;
  sellerRentOutSpecificationCdfs?: InputMaybe<DbSellerRentOutSpecificationCdfBoolExp>;
  subType?: InputMaybe<EnumsAssetSubTypeEnumComparisonExp>;
  surfaceTotal?: InputMaybe<Float8ComparisonExp>;
  surfaceUsable?: InputMaybe<Float8ComparisonExp>;
  swimmingPool?: InputMaybe<BooleanComparisonExp>;
  totalFloors?: InputMaybe<IntComparisonExp>;
  type?: InputMaybe<EnumsAssetTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  variation?: InputMaybe<EnumsAssetVariationEnumComparisonExp>;
};

/** columns and relationships of "db.seller_rent_out_specification_cdf" */
export type DbSellerRentOutSpecificationCdf = {
  __typename?: 'DbSellerRentOutSpecificationCdf';
  /** An object relationship */
  cdf: DbCdf;
  cdfId: Scalars['String'];
  srsId: Scalars['String'];
};

/** order by aggregate values of table "db.seller_rent_out_specification_cdf" */
export type DbSellerRentOutSpecificationCdfAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbSellerRentOutSpecificationCdfMaxOrderBy>;
  min?: InputMaybe<DbSellerRentOutSpecificationCdfMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.seller_rent_out_specification_cdf". All fields are combined with a logical 'AND'. */
export type DbSellerRentOutSpecificationCdfBoolExp = {
  _and?: InputMaybe<Array<DbSellerRentOutSpecificationCdfBoolExp>>;
  _not?: InputMaybe<DbSellerRentOutSpecificationCdfBoolExp>;
  _or?: InputMaybe<Array<DbSellerRentOutSpecificationCdfBoolExp>>;
  cdf?: InputMaybe<DbCdfBoolExp>;
  cdfId?: InputMaybe<StringComparisonExp>;
  srsId?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.seller_rent_out_specification_cdf" */
export type DbSellerRentOutSpecificationCdfMaxOrderBy = {
  cdfId?: InputMaybe<OrderBy>;
  srsId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.seller_rent_out_specification_cdf" */
export type DbSellerRentOutSpecificationCdfMinOrderBy = {
  cdfId?: InputMaybe<OrderBy>;
  srsId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.seller_rent_out_specification_cdf". */
export type DbSellerRentOutSpecificationCdfOrderBy = {
  cdf?: InputMaybe<DbCdfOrderBy>;
  cdfId?: InputMaybe<OrderBy>;
  srsId?: InputMaybe<OrderBy>;
};

/** select columns of table "db.seller_rent_out_specification_cdf" */
export type DbSellerRentOutSpecificationCdfSelectColumn =
  /** column name */
  | 'cdfId'
  /** column name */
  | 'srsId';

/** Streaming cursor of the table "db_seller_rent_out_specification_cdf" */
export type DbSellerRentOutSpecificationCdfStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbSellerRentOutSpecificationCdfStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbSellerRentOutSpecificationCdfStreamCursorValueInput = {
  cdfId?: InputMaybe<Scalars['String']>;
  srsId?: InputMaybe<Scalars['String']>;
};

/** order by max() on columns of table "db.seller_rent_out_specification" */
export type DbSellerRentOutSpecificationMaxOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  constructionYear?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  floor?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  livingArea?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  numberOfBathrooms?: InputMaybe<OrderBy>;
  numberOfBedrooms?: InputMaybe<OrderBy>;
  numberOfMasterBathrooms?: InputMaybe<OrderBy>;
  numberOfRooms?: InputMaybe<OrderBy>;
  ownerDescription?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
  plotArea?: InputMaybe<OrderBy>;
  priceRangeFrom?: InputMaybe<OrderBy>;
  priceRangeTo?: InputMaybe<OrderBy>;
  rentRangeFrom?: InputMaybe<OrderBy>;
  rentRangeTo?: InputMaybe<OrderBy>;
  surfaceTotal?: InputMaybe<OrderBy>;
  surfaceUsable?: InputMaybe<OrderBy>;
  totalFloors?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.seller_rent_out_specification" */
export type DbSellerRentOutSpecificationMinOrderBy = {
  addressId?: InputMaybe<OrderBy>;
  constructionYear?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  floor?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  livingArea?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  numberOfBathrooms?: InputMaybe<OrderBy>;
  numberOfBedrooms?: InputMaybe<OrderBy>;
  numberOfMasterBathrooms?: InputMaybe<OrderBy>;
  numberOfRooms?: InputMaybe<OrderBy>;
  ownerDescription?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
  plotArea?: InputMaybe<OrderBy>;
  priceRangeFrom?: InputMaybe<OrderBy>;
  priceRangeTo?: InputMaybe<OrderBy>;
  rentRangeFrom?: InputMaybe<OrderBy>;
  rentRangeTo?: InputMaybe<OrderBy>;
  surfaceTotal?: InputMaybe<OrderBy>;
  surfaceUsable?: InputMaybe<OrderBy>;
  totalFloors?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.seller_rent_out_specification". */
export type DbSellerRentOutSpecificationOrderBy = {
  address?: InputMaybe<DbAddressOrderBy>;
  addressId?: InputMaybe<OrderBy>;
  assetCondition?: InputMaybe<EnumsAssetConditionOrderBy>;
  assetSubType?: InputMaybe<EnumsAssetSubTypeOrderBy>;
  assetType?: InputMaybe<EnumsAssetTypeOrderBy>;
  balcony?: InputMaybe<OrderBy>;
  condition?: InputMaybe<OrderBy>;
  constructionYear?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  currency?: InputMaybe<EnumsCurrencyOrderBy>;
  currencyByRentPriceUnit?: InputMaybe<EnumsCurrencyOrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  elevator?: InputMaybe<OrderBy>;
  floor?: InputMaybe<OrderBy>;
  garage?: InputMaybe<OrderBy>;
  garden?: InputMaybe<OrderBy>;
  guestToilet?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isOccupiedByOwner?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  lead?: InputMaybe<DbLeadOrderBy>;
  leadsAggregate?: InputMaybe<DbLeadAggregateOrderBy>;
  livingArea?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  numberOfBathrooms?: InputMaybe<OrderBy>;
  numberOfBedrooms?: InputMaybe<OrderBy>;
  numberOfMasterBathrooms?: InputMaybe<OrderBy>;
  numberOfRooms?: InputMaybe<OrderBy>;
  occupationStatus?: InputMaybe<OrderBy>;
  owner?: InputMaybe<DbContactOrderBy>;
  ownerDescription?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
  ownershipStatus?: InputMaybe<OrderBy>;
  parking?: InputMaybe<OrderBy>;
  plotArea?: InputMaybe<OrderBy>;
  priceRangeFrom?: InputMaybe<OrderBy>;
  priceRangeTo?: InputMaybe<OrderBy>;
  priceRangeUnit?: InputMaybe<OrderBy>;
  propertyOccupationStatus?: InputMaybe<EnumsPropertyOccupationStatusOrderBy>;
  propertyOwnershipStatus?: InputMaybe<EnumsPropertyOwnershipStatusOrderBy>;
  rentPriceUnit?: InputMaybe<OrderBy>;
  rentRangeFrom?: InputMaybe<OrderBy>;
  rentRangeTo?: InputMaybe<OrderBy>;
  sellerRentOutSpecificationCdfsAggregate?: InputMaybe<DbSellerRentOutSpecificationCdfAggregateOrderBy>;
  subType?: InputMaybe<OrderBy>;
  surfaceTotal?: InputMaybe<OrderBy>;
  surfaceUsable?: InputMaybe<OrderBy>;
  swimmingPool?: InputMaybe<OrderBy>;
  totalFloors?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  variation?: InputMaybe<OrderBy>;
};

/** select columns of table "db.seller_rent_out_specification" */
export type DbSellerRentOutSpecificationSelectColumn =
  /** column name */
  | 'addressId'
  /** column name */
  | 'balcony'
  /** column name */
  | 'condition'
  /** column name */
  | 'constructionYear'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'elevator'
  /** column name */
  | 'floor'
  /** column name */
  | 'garage'
  /** column name */
  | 'garden'
  /** column name */
  | 'guestToilet'
  /** column name */
  | 'id'
  /** column name */
  | 'isOccupiedByOwner'
  /** column name */
  | 'lck'
  /** column name */
  | 'livingArea'
  /** column name */
  | 'num'
  /** column name */
  | 'numberOfBathrooms'
  /** column name */
  | 'numberOfBedrooms'
  /** column name */
  | 'numberOfMasterBathrooms'
  /** column name */
  | 'numberOfRooms'
  /** column name */
  | 'occupationStatus'
  /** column name */
  | 'ownerDescription'
  /** column name */
  | 'ownerId'
  /** column name */
  | 'ownershipStatus'
  /** column name */
  | 'parking'
  /** column name */
  | 'plotArea'
  /** column name */
  | 'priceRangeFrom'
  /** column name */
  | 'priceRangeTo'
  /** column name */
  | 'priceRangeUnit'
  /** column name */
  | 'rentPriceUnit'
  /** column name */
  | 'rentRangeFrom'
  /** column name */
  | 'rentRangeTo'
  /** column name */
  | 'subType'
  /** column name */
  | 'surfaceTotal'
  /** column name */
  | 'surfaceUsable'
  /** column name */
  | 'swimmingPool'
  /** column name */
  | 'totalFloors'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy'
  /** column name */
  | 'variation';

/** order by stddev() on columns of table "db.seller_rent_out_specification" */
export type DbSellerRentOutSpecificationStddevOrderBy = {
  constructionYear?: InputMaybe<OrderBy>;
  floor?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  livingArea?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  numberOfBathrooms?: InputMaybe<OrderBy>;
  numberOfBedrooms?: InputMaybe<OrderBy>;
  numberOfMasterBathrooms?: InputMaybe<OrderBy>;
  numberOfRooms?: InputMaybe<OrderBy>;
  plotArea?: InputMaybe<OrderBy>;
  priceRangeFrom?: InputMaybe<OrderBy>;
  priceRangeTo?: InputMaybe<OrderBy>;
  rentRangeFrom?: InputMaybe<OrderBy>;
  rentRangeTo?: InputMaybe<OrderBy>;
  surfaceTotal?: InputMaybe<OrderBy>;
  surfaceUsable?: InputMaybe<OrderBy>;
  totalFloors?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.seller_rent_out_specification" */
export type DbSellerRentOutSpecificationStddevPopOrderBy = {
  constructionYear?: InputMaybe<OrderBy>;
  floor?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  livingArea?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  numberOfBathrooms?: InputMaybe<OrderBy>;
  numberOfBedrooms?: InputMaybe<OrderBy>;
  numberOfMasterBathrooms?: InputMaybe<OrderBy>;
  numberOfRooms?: InputMaybe<OrderBy>;
  plotArea?: InputMaybe<OrderBy>;
  priceRangeFrom?: InputMaybe<OrderBy>;
  priceRangeTo?: InputMaybe<OrderBy>;
  rentRangeFrom?: InputMaybe<OrderBy>;
  rentRangeTo?: InputMaybe<OrderBy>;
  surfaceTotal?: InputMaybe<OrderBy>;
  surfaceUsable?: InputMaybe<OrderBy>;
  totalFloors?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.seller_rent_out_specification" */
export type DbSellerRentOutSpecificationStddevSampOrderBy = {
  constructionYear?: InputMaybe<OrderBy>;
  floor?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  livingArea?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  numberOfBathrooms?: InputMaybe<OrderBy>;
  numberOfBedrooms?: InputMaybe<OrderBy>;
  numberOfMasterBathrooms?: InputMaybe<OrderBy>;
  numberOfRooms?: InputMaybe<OrderBy>;
  plotArea?: InputMaybe<OrderBy>;
  priceRangeFrom?: InputMaybe<OrderBy>;
  priceRangeTo?: InputMaybe<OrderBy>;
  rentRangeFrom?: InputMaybe<OrderBy>;
  rentRangeTo?: InputMaybe<OrderBy>;
  surfaceTotal?: InputMaybe<OrderBy>;
  surfaceUsable?: InputMaybe<OrderBy>;
  totalFloors?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_seller_rent_out_specification" */
export type DbSellerRentOutSpecificationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbSellerRentOutSpecificationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbSellerRentOutSpecificationStreamCursorValueInput = {
  addressId?: InputMaybe<Scalars['String']>;
  balcony?: InputMaybe<Scalars['Boolean']>;
  condition?: InputMaybe<AssetConditionEnum>;
  constructionYear?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  elevator?: InputMaybe<Scalars['Boolean']>;
  floor?: InputMaybe<Scalars['Int']>;
  garage?: InputMaybe<Scalars['Boolean']>;
  garden?: InputMaybe<Scalars['Boolean']>;
  guestToilet?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  isOccupiedByOwner?: InputMaybe<Scalars['Boolean']>;
  lck?: InputMaybe<Scalars['Int']>;
  livingArea?: InputMaybe<Scalars['float8']>;
  num?: InputMaybe<Scalars['bigint']>;
  numberOfBathrooms?: InputMaybe<Scalars['Float']>;
  numberOfBedrooms?: InputMaybe<Scalars['Float']>;
  numberOfMasterBathrooms?: InputMaybe<Scalars['Float']>;
  numberOfRooms?: InputMaybe<Scalars['Float']>;
  occupationStatus?: InputMaybe<PropertyOccupationStatusEnum>;
  ownerDescription?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  ownershipStatus?: InputMaybe<PropertyOwnershipStatusEnum>;
  parking?: InputMaybe<Scalars['Boolean']>;
  plotArea?: InputMaybe<Scalars['float8']>;
  priceRangeFrom?: InputMaybe<Scalars['numeric']>;
  priceRangeTo?: InputMaybe<Scalars['numeric']>;
  priceRangeUnit?: InputMaybe<CurrencyEnum>;
  rentPriceUnit?: InputMaybe<CurrencyEnum>;
  rentRangeFrom?: InputMaybe<Scalars['numeric']>;
  rentRangeTo?: InputMaybe<Scalars['numeric']>;
  subType?: InputMaybe<AssetSubTypeEnum>;
  surfaceTotal?: InputMaybe<Scalars['float8']>;
  surfaceUsable?: InputMaybe<Scalars['float8']>;
  swimmingPool?: InputMaybe<Scalars['Boolean']>;
  totalFloors?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<AssetTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  variation?: InputMaybe<AssetVariationEnum>;
};

/** order by sum() on columns of table "db.seller_rent_out_specification" */
export type DbSellerRentOutSpecificationSumOrderBy = {
  constructionYear?: InputMaybe<OrderBy>;
  floor?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  livingArea?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  numberOfBathrooms?: InputMaybe<OrderBy>;
  numberOfBedrooms?: InputMaybe<OrderBy>;
  numberOfMasterBathrooms?: InputMaybe<OrderBy>;
  numberOfRooms?: InputMaybe<OrderBy>;
  plotArea?: InputMaybe<OrderBy>;
  priceRangeFrom?: InputMaybe<OrderBy>;
  priceRangeTo?: InputMaybe<OrderBy>;
  rentRangeFrom?: InputMaybe<OrderBy>;
  rentRangeTo?: InputMaybe<OrderBy>;
  surfaceTotal?: InputMaybe<OrderBy>;
  surfaceUsable?: InputMaybe<OrderBy>;
  totalFloors?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.seller_rent_out_specification" */
export type DbSellerRentOutSpecificationVarPopOrderBy = {
  constructionYear?: InputMaybe<OrderBy>;
  floor?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  livingArea?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  numberOfBathrooms?: InputMaybe<OrderBy>;
  numberOfBedrooms?: InputMaybe<OrderBy>;
  numberOfMasterBathrooms?: InputMaybe<OrderBy>;
  numberOfRooms?: InputMaybe<OrderBy>;
  plotArea?: InputMaybe<OrderBy>;
  priceRangeFrom?: InputMaybe<OrderBy>;
  priceRangeTo?: InputMaybe<OrderBy>;
  rentRangeFrom?: InputMaybe<OrderBy>;
  rentRangeTo?: InputMaybe<OrderBy>;
  surfaceTotal?: InputMaybe<OrderBy>;
  surfaceUsable?: InputMaybe<OrderBy>;
  totalFloors?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.seller_rent_out_specification" */
export type DbSellerRentOutSpecificationVarSampOrderBy = {
  constructionYear?: InputMaybe<OrderBy>;
  floor?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  livingArea?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  numberOfBathrooms?: InputMaybe<OrderBy>;
  numberOfBedrooms?: InputMaybe<OrderBy>;
  numberOfMasterBathrooms?: InputMaybe<OrderBy>;
  numberOfRooms?: InputMaybe<OrderBy>;
  plotArea?: InputMaybe<OrderBy>;
  priceRangeFrom?: InputMaybe<OrderBy>;
  priceRangeTo?: InputMaybe<OrderBy>;
  rentRangeFrom?: InputMaybe<OrderBy>;
  rentRangeTo?: InputMaybe<OrderBy>;
  surfaceTotal?: InputMaybe<OrderBy>;
  surfaceUsable?: InputMaybe<OrderBy>;
  totalFloors?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.seller_rent_out_specification" */
export type DbSellerRentOutSpecificationVarianceOrderBy = {
  constructionYear?: InputMaybe<OrderBy>;
  floor?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  livingArea?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  numberOfBathrooms?: InputMaybe<OrderBy>;
  numberOfBedrooms?: InputMaybe<OrderBy>;
  numberOfMasterBathrooms?: InputMaybe<OrderBy>;
  numberOfRooms?: InputMaybe<OrderBy>;
  plotArea?: InputMaybe<OrderBy>;
  priceRangeFrom?: InputMaybe<OrderBy>;
  priceRangeTo?: InputMaybe<OrderBy>;
  rentRangeFrom?: InputMaybe<OrderBy>;
  rentRangeTo?: InputMaybe<OrderBy>;
  surfaceTotal?: InputMaybe<OrderBy>;
  surfaceUsable?: InputMaybe<OrderBy>;
  totalFloors?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.session" */
export type DbSession = {
  __typename?: 'DbSession';
  /** An object relationship */
  agent: DbAgent;
  agentId: Scalars['String'];
  callAttendedAt?: Maybe<Scalars['timestamp']>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  expiresAt: Scalars['timestamptz'];
  id: Scalars['String'];
  num: Scalars['bigint'];
  preflightCheck: Scalars['Boolean'];
  /** An object relationship */
  sessionStatus: EnumsSessionStatus;
  status: SessionStatusEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  variables: Scalars['jsonb'];
};

/** columns and relationships of "db.session" */
export type DbSessionVariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "db.session" */
export type DbSessionAggregate = {
  __typename?: 'DbSessionAggregate';
  aggregate?: Maybe<DbSessionAggregateFields>;
  nodes: Array<DbSession>;
};

export type DbSessionAggregateBoolExp = {
  bool_and?: InputMaybe<DbSessionAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbSessionAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbSessionAggregateBoolExpCount>;
};

/** aggregate fields of "db.session" */
export type DbSessionAggregateFields = {
  __typename?: 'DbSessionAggregateFields';
  avg?: Maybe<DbSessionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbSessionMaxFields>;
  min?: Maybe<DbSessionMinFields>;
  stddev?: Maybe<DbSessionStddevFields>;
  stddevPop?: Maybe<DbSessionStddevPopFields>;
  stddevSamp?: Maybe<DbSessionStddevSampFields>;
  sum?: Maybe<DbSessionSumFields>;
  varPop?: Maybe<DbSessionVarPopFields>;
  varSamp?: Maybe<DbSessionVarSampFields>;
  variance?: Maybe<DbSessionVarianceFields>;
};

/** aggregate fields of "db.session" */
export type DbSessionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbSessionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.session" */
export type DbSessionAggregateOrderBy = {
  avg?: InputMaybe<DbSessionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbSessionMaxOrderBy>;
  min?: InputMaybe<DbSessionMinOrderBy>;
  stddev?: InputMaybe<DbSessionStddevOrderBy>;
  stddevPop?: InputMaybe<DbSessionStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbSessionStddevSampOrderBy>;
  sum?: InputMaybe<DbSessionSumOrderBy>;
  varPop?: InputMaybe<DbSessionVarPopOrderBy>;
  varSamp?: InputMaybe<DbSessionVarSampOrderBy>;
  variance?: InputMaybe<DbSessionVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbSessionAvgFields = {
  __typename?: 'DbSessionAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.session" */
export type DbSessionAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.session". All fields are combined with a logical 'AND'. */
export type DbSessionBoolExp = {
  _and?: InputMaybe<Array<DbSessionBoolExp>>;
  _not?: InputMaybe<DbSessionBoolExp>;
  _or?: InputMaybe<Array<DbSessionBoolExp>>;
  agent?: InputMaybe<DbAgentBoolExp>;
  agentId?: InputMaybe<StringComparisonExp>;
  callAttendedAt?: InputMaybe<TimestampComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  expiresAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  preflightCheck?: InputMaybe<BooleanComparisonExp>;
  sessionStatus?: InputMaybe<EnumsSessionStatusBoolExp>;
  status?: InputMaybe<EnumsSessionStatusEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  variables?: InputMaybe<JsonbComparisonExp>;
};

/** aggregate max on columns */
export type DbSessionMaxFields = {
  __typename?: 'DbSessionMaxFields';
  agentId?: Maybe<Scalars['String']>;
  callAttendedAt?: Maybe<Scalars['timestamp']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.session" */
export type DbSessionMaxOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  callAttendedAt?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbSessionMinFields = {
  __typename?: 'DbSessionMinFields';
  agentId?: Maybe<Scalars['String']>;
  callAttendedAt?: Maybe<Scalars['timestamp']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.session" */
export type DbSessionMinOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  callAttendedAt?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.session". */
export type DbSessionOrderBy = {
  agent?: InputMaybe<DbAgentOrderBy>;
  agentId?: InputMaybe<OrderBy>;
  callAttendedAt?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  expiresAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  preflightCheck?: InputMaybe<OrderBy>;
  sessionStatus?: InputMaybe<EnumsSessionStatusOrderBy>;
  status?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  variables?: InputMaybe<OrderBy>;
};

/** select columns of table "db.session" */
export type DbSessionSelectColumn =
  /** column name */
  | 'agentId'
  /** column name */
  | 'callAttendedAt'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'expiresAt'
  /** column name */
  | 'id'
  /** column name */
  | 'num'
  /** column name */
  | 'preflightCheck'
  /** column name */
  | 'status'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy'
  /** column name */
  | 'variables';

/** select "dbSessionAggregateBoolExpBool_andArgumentsColumns" columns of table "db.session" */
export type DbSessionSelectColumnDbSessionAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  | 'deleted'
  /** column name */
  | 'preflightCheck';

/** select "dbSessionAggregateBoolExpBool_orArgumentsColumns" columns of table "db.session" */
export type DbSessionSelectColumnDbSessionAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  | 'deleted'
  /** column name */
  | 'preflightCheck';

/** aggregate stddev on columns */
export type DbSessionStddevFields = {
  __typename?: 'DbSessionStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.session" */
export type DbSessionStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbSessionStddevPopFields = {
  __typename?: 'DbSessionStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.session" */
export type DbSessionStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbSessionStddevSampFields = {
  __typename?: 'DbSessionStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.session" */
export type DbSessionStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_session" */
export type DbSessionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbSessionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbSessionStreamCursorValueInput = {
  agentId?: InputMaybe<Scalars['String']>;
  callAttendedAt?: InputMaybe<Scalars['timestamp']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  preflightCheck?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<SessionStatusEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  variables?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate sum on columns */
export type DbSessionSumFields = {
  __typename?: 'DbSessionSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.session" */
export type DbSessionSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbSessionVarPopFields = {
  __typename?: 'DbSessionVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.session" */
export type DbSessionVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbSessionVarSampFields = {
  __typename?: 'DbSessionVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.session" */
export type DbSessionVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbSessionVarianceFields = {
  __typename?: 'DbSessionVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.session" */
export type DbSessionVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.shop" */
export type DbShop = {
  __typename?: 'DbShop';
  /** An array relationship */
  agentDefaultEmailSignatures: Array<DbAgentDefaultEmailSignature>;
  /** An aggregate relationship */
  agentDefaultEmailSignaturesAggregate: DbAgentDefaultEmailSignatureAggregate;
  /** An array relationship */
  agentDefaultNylasEmailIntegrations: Array<DbAgentDefaultNylasEmailIntegration>;
  /** An aggregate relationship */
  agentDefaultNylasEmailIntegrationsAggregate: DbAgentDefaultNylasEmailIntegrationAggregate;
  /** An array relationship */
  baseActivities: Array<DbBaseActivity>;
  /** An aggregate relationship */
  baseActivitiesAggregate: DbBaseActivityAggregate;
  businessUnit?: Maybe<Scalars['String']>;
  /** An array relationship */
  calls: Array<DbCall>;
  /** An aggregate relationship */
  callsAggregate: DbCallAggregate;
  city?: Maybe<Scalars['String']>;
  /** An array relationship */
  contacts: Array<DbContact>;
  /** An aggregate relationship */
  contactsAggregate: DbContactAggregate;
  /** An object relationship */
  country: EnumsCountry;
  countryCode: CountryEnum;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  currency: CurrencyEnum;
  /** An object relationship */
  currencyCode: EnumsCurrency;
  /** An array relationship */
  customDemandFields: Array<DbCdf>;
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  /** An array relationship */
  emailSignatures: Array<DbEmailSignature>;
  /** An aggregate relationship */
  emailSignaturesAggregate: DbEmailSignatureAggregate;
  /** An array relationship */
  emailTemplates: Array<DbEmailTemplate>;
  /** An aggregate relationship */
  emailTemplatesAggregate: DbEmailTemplateAggregate;
  energyAdvisoryEnabled: Scalars['Boolean'];
  go3ShopStatus?: Maybe<ShopStatusEnum>;
  /** An object relationship */
  goShopStatus?: Maybe<EnumsShopStatus>;
  groupId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastShopLvlChangeDate?: Maybe<Scalars['timestamp']>;
  latitude?: Maybe<Scalars['float8']>;
  /** An array relationship */
  leadAutoAssignmentIntents: Array<DbLeadAutoAssignmentIntent>;
  /** An array relationship */
  leadAutoAssignmentRules: Array<DbLeadAutoAssignmentRules>;
  /** An array relationship */
  lead_extending_activities: Array<DbLeadExtendingActivities>;
  /** An array relationship */
  lead_extending_appointments: Array<DbLeadExtendingAppointmentTypes>;
  /** An array relationship */
  lead_extending_tasks: Array<DbLeadExtendingTaskTypes>;
  /** An array relationship */
  leads: Array<DbLead>;
  /** An aggregate relationship */
  leadsAggregate: DbLeadAggregate;
  /** An object relationship */
  licensePartner: DbLicensePartner;
  licensePartnerId: Scalars['String'];
  longitude?: Maybe<Scalars['float8']>;
  normalizedShopName?: Maybe<Scalars['String']>;
  num: Scalars['bigint'];
  /** An array relationship */
  nylasEmailIntegrations: Array<DbNylasEmailIntegration>;
  /** An aggregate relationship */
  nylasEmailIntegrationsAggregate: DbNylasEmailIntegrationAggregate;
  onboardingLevel?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  /** An array relationship */
  profiles: Array<DbShopProfile>;
  /** An aggregate relationship */
  profilesAggregate: DbShopProfileAggregate;
  /** An array relationship */
  propertyLounges: Array<DbPropertyLounge>;
  /** An array relationship */
  qualiformLeadIntents: Array<DbQualiformLeadIntent>;
  /** An array relationship */
  qualiformLeadSources: Array<DbQualiformLeadSource>;
  /** An array relationship */
  qualiformMandatoryFields: Array<DbQualiformMandatoryField>;
  /** An array relationship */
  qualiformShopSettings: Array<DbQualiformShopSetting>;
  /** An array relationship */
  referrals: Array<DbReferral>;
  /** An aggregate relationship */
  referralsAggregate: DbReferralAggregate;
  /** An array relationship */
  referralsBySendingShopId: Array<DbReferral>;
  /** An aggregate relationship */
  referralsBySendingShopIdAggregate: DbReferralAggregate;
  /** An array relationship */
  settings: Array<DbShopSetting>;
  /** An aggregate relationship */
  settingsAggregate: DbShopSettingAggregate;
  /** An object relationship */
  shopGroup?: Maybe<DbShopGroup>;
  shopName: Scalars['String'];
  /** An array relationship */
  shopOptimizelyClients: Array<DbOptimizelyClient>;
  /** An object relationship */
  shopStatus?: Maybe<EnumsShopStatus>;
  /** An array relationship */
  shop_managed_consents: Array<DbShopManagedConsent>;
  /** An array relationship */
  shop_specific_consent_templates: Array<DbConsentTemplate>;
  status?: Maybe<ShopStatusEnum>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  /** An array relationship */
  tags: Array<DbTag>;
  /** An array relationship */
  teams: Array<DbTeam>;
  /** An aggregate relationship */
  teamsAggregate: DbTeamAggregate;
  /** An array relationship */
  twilioConfigurations: Array<DbTwilioConfiguration>;
  /** An aggregate relationship */
  twilioConfigurationsAggregate: DbTwilioConfigurationAggregate;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  /** An array relationship */
  userShopAssignments: Array<DbUserShopAssignment>;
  /** An aggregate relationship */
  userShopAssignmentsAggregate: DbUserShopAssignmentAggregate;
  website?: Maybe<Scalars['String']>;
};

/** columns and relationships of "db.shop" */
export type DbShopAgentDefaultEmailSignaturesArgs = {
  distinctOn?: InputMaybe<Array<DbAgentDefaultEmailSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentDefaultEmailSignatureOrderBy>>;
  where?: InputMaybe<DbAgentDefaultEmailSignatureBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopAgentDefaultEmailSignaturesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentDefaultEmailSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentDefaultEmailSignatureOrderBy>>;
  where?: InputMaybe<DbAgentDefaultEmailSignatureBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopAgentDefaultNylasEmailIntegrationsArgs = {
  distinctOn?: InputMaybe<Array<DbAgentDefaultNylasEmailIntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentDefaultNylasEmailIntegrationOrderBy>>;
  where?: InputMaybe<DbAgentDefaultNylasEmailIntegrationBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopAgentDefaultNylasEmailIntegrationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentDefaultNylasEmailIntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentDefaultNylasEmailIntegrationOrderBy>>;
  where?: InputMaybe<DbAgentDefaultNylasEmailIntegrationBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopBaseActivitiesArgs = {
  distinctOn?: InputMaybe<Array<DbBaseActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBaseActivityOrderBy>>;
  where?: InputMaybe<DbBaseActivityBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopBaseActivitiesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbBaseActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBaseActivityOrderBy>>;
  where?: InputMaybe<DbBaseActivityBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopCallsArgs = {
  distinctOn?: InputMaybe<Array<DbCallSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCallOrderBy>>;
  where?: InputMaybe<DbCallBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopCallsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbCallSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCallOrderBy>>;
  where?: InputMaybe<DbCallBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopContactsArgs = {
  distinctOn?: InputMaybe<Array<DbContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactOrderBy>>;
  where?: InputMaybe<DbContactBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopContactsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactOrderBy>>;
  where?: InputMaybe<DbContactBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopCustomDemandFieldsArgs = {
  distinctOn?: InputMaybe<Array<DbCdfSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCdfOrderBy>>;
  where?: InputMaybe<DbCdfBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopEmailSignaturesArgs = {
  distinctOn?: InputMaybe<Array<DbEmailSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailSignatureOrderBy>>;
  where?: InputMaybe<DbEmailSignatureBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopEmailSignaturesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailSignatureOrderBy>>;
  where?: InputMaybe<DbEmailSignatureBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopEmailTemplatesArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTemplateOrderBy>>;
  where?: InputMaybe<DbEmailTemplateBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopEmailTemplatesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTemplateOrderBy>>;
  where?: InputMaybe<DbEmailTemplateBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopLeadAutoAssignmentIntentsArgs = {
  distinctOn?: InputMaybe<Array<DbLeadAutoAssignmentIntentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadAutoAssignmentIntentOrderBy>>;
  where?: InputMaybe<DbLeadAutoAssignmentIntentBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopLeadAutoAssignmentRulesArgs = {
  distinctOn?: InputMaybe<Array<DbLeadAutoAssignmentRulesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadAutoAssignmentRulesOrderBy>>;
  where?: InputMaybe<DbLeadAutoAssignmentRulesBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopLeadExtendingActivitiesArgs = {
  distinctOn?: InputMaybe<Array<DbLeadExtendingActivitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadExtendingActivitiesOrderBy>>;
  where?: InputMaybe<DbLeadExtendingActivitiesBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopLeadExtendingAppointmentsArgs = {
  distinctOn?: InputMaybe<Array<DbLeadExtendingAppointmentTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadExtendingAppointmentTypesOrderBy>>;
  where?: InputMaybe<DbLeadExtendingAppointmentTypesBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopLeadExtendingTasksArgs = {
  distinctOn?: InputMaybe<Array<DbLeadExtendingTaskTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadExtendingTaskTypesOrderBy>>;
  where?: InputMaybe<DbLeadExtendingTaskTypesBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopLeadsArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopLeadsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopNylasEmailIntegrationsArgs = {
  distinctOn?: InputMaybe<Array<DbNylasEmailIntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbNylasEmailIntegrationOrderBy>>;
  where?: InputMaybe<DbNylasEmailIntegrationBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopNylasEmailIntegrationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbNylasEmailIntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbNylasEmailIntegrationOrderBy>>;
  where?: InputMaybe<DbNylasEmailIntegrationBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopProfilesArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileOrderBy>>;
  where?: InputMaybe<DbShopProfileBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopProfilesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileOrderBy>>;
  where?: InputMaybe<DbShopProfileBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopPropertyLoungesArgs = {
  distinctOn?: InputMaybe<Array<DbPropertyLoungeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbPropertyLoungeOrderBy>>;
  where?: InputMaybe<DbPropertyLoungeBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopQualiformLeadIntentsArgs = {
  distinctOn?: InputMaybe<Array<DbQualiformLeadIntentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbQualiformLeadIntentOrderBy>>;
  where?: InputMaybe<DbQualiformLeadIntentBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopQualiformLeadSourcesArgs = {
  distinctOn?: InputMaybe<Array<DbQualiformLeadSourceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbQualiformLeadSourceOrderBy>>;
  where?: InputMaybe<DbQualiformLeadSourceBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopQualiformMandatoryFieldsArgs = {
  distinctOn?: InputMaybe<Array<DbQualiformMandatoryFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbQualiformMandatoryFieldOrderBy>>;
  where?: InputMaybe<DbQualiformMandatoryFieldBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopQualiformShopSettingsArgs = {
  distinctOn?: InputMaybe<Array<DbQualiformShopSettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbQualiformShopSettingOrderBy>>;
  where?: InputMaybe<DbQualiformShopSettingBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopReferralsArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopReferralsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopReferralsBySendingShopIdArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopReferralsBySendingShopIdAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopSettingsArgs = {
  distinctOn?: InputMaybe<Array<DbShopSettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopSettingOrderBy>>;
  where?: InputMaybe<DbShopSettingBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopSettingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopSettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopSettingOrderBy>>;
  where?: InputMaybe<DbShopSettingBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopShopOptimizelyClientsArgs = {
  distinctOn?: InputMaybe<Array<DbOptimizelyClientSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbOptimizelyClientOrderBy>>;
  where?: InputMaybe<DbOptimizelyClientBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopShopManagedConsentsArgs = {
  distinctOn?: InputMaybe<Array<DbShopManagedConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopManagedConsentOrderBy>>;
  where?: InputMaybe<DbShopManagedConsentBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopShopSpecificConsentTemplatesArgs = {
  distinctOn?: InputMaybe<Array<DbConsentTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentTemplateOrderBy>>;
  where?: InputMaybe<DbConsentTemplateBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopTagsArgs = {
  distinctOn?: InputMaybe<Array<DbTagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTagOrderBy>>;
  where?: InputMaybe<DbTagBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopTeamsArgs = {
  distinctOn?: InputMaybe<Array<DbTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTeamOrderBy>>;
  where?: InputMaybe<DbTeamBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopTeamsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTeamOrderBy>>;
  where?: InputMaybe<DbTeamBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopTwilioConfigurationsArgs = {
  distinctOn?: InputMaybe<Array<DbTwilioConfigurationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTwilioConfigurationOrderBy>>;
  where?: InputMaybe<DbTwilioConfigurationBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopTwilioConfigurationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbTwilioConfigurationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTwilioConfigurationOrderBy>>;
  where?: InputMaybe<DbTwilioConfigurationBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopUserShopAssignmentsArgs = {
  distinctOn?: InputMaybe<Array<DbUserShopAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserShopAssignmentOrderBy>>;
  where?: InputMaybe<DbUserShopAssignmentBoolExp>;
};

/** columns and relationships of "db.shop" */
export type DbShopUserShopAssignmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbUserShopAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserShopAssignmentOrderBy>>;
  where?: InputMaybe<DbUserShopAssignmentBoolExp>;
};

/** aggregated selection of "db.shop" */
export type DbShopAggregate = {
  __typename?: 'DbShopAggregate';
  aggregate?: Maybe<DbShopAggregateFields>;
  nodes: Array<DbShop>;
};

export type DbShopAggregateBoolExp = {
  avg?: InputMaybe<DbShopAggregateBoolExpAvg>;
  bool_and?: InputMaybe<DbShopAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbShopAggregateBoolExpBoolOr>;
  corr?: InputMaybe<DbShopAggregateBoolExpCorr>;
  count?: InputMaybe<DbShopAggregateBoolExpCount>;
  covar_samp?: InputMaybe<DbShopAggregateBoolExpCovarSamp>;
  max?: InputMaybe<DbShopAggregateBoolExpMax>;
  min?: InputMaybe<DbShopAggregateBoolExpMin>;
  stddev_samp?: InputMaybe<DbShopAggregateBoolExpStddevSamp>;
  sum?: InputMaybe<DbShopAggregateBoolExpSum>;
  var_samp?: InputMaybe<DbShopAggregateBoolExpVarSamp>;
};

/** aggregate fields of "db.shop" */
export type DbShopAggregateFields = {
  __typename?: 'DbShopAggregateFields';
  avg?: Maybe<DbShopAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbShopMaxFields>;
  min?: Maybe<DbShopMinFields>;
  stddev?: Maybe<DbShopStddevFields>;
  stddevPop?: Maybe<DbShopStddevPopFields>;
  stddevSamp?: Maybe<DbShopStddevSampFields>;
  sum?: Maybe<DbShopSumFields>;
  varPop?: Maybe<DbShopVarPopFields>;
  varSamp?: Maybe<DbShopVarSampFields>;
  variance?: Maybe<DbShopVarianceFields>;
};

/** aggregate fields of "db.shop" */
export type DbShopAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbShopSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.shop" */
export type DbShopAggregateOrderBy = {
  avg?: InputMaybe<DbShopAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbShopMaxOrderBy>;
  min?: InputMaybe<DbShopMinOrderBy>;
  stddev?: InputMaybe<DbShopStddevOrderBy>;
  stddevPop?: InputMaybe<DbShopStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbShopStddevSampOrderBy>;
  sum?: InputMaybe<DbShopSumOrderBy>;
  varPop?: InputMaybe<DbShopVarPopOrderBy>;
  varSamp?: InputMaybe<DbShopVarSampOrderBy>;
  variance?: InputMaybe<DbShopVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbShopAvgFields = {
  __typename?: 'DbShopAvgFields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  onboardingLevel?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.shop" */
export type DbShopAvgOrderBy = {
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  onboardingLevel?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.shop". All fields are combined with a logical 'AND'. */
export type DbShopBoolExp = {
  _and?: InputMaybe<Array<DbShopBoolExp>>;
  _not?: InputMaybe<DbShopBoolExp>;
  _or?: InputMaybe<Array<DbShopBoolExp>>;
  agentDefaultEmailSignatures?: InputMaybe<DbAgentDefaultEmailSignatureBoolExp>;
  agentDefaultEmailSignaturesAggregate?: InputMaybe<DbAgentDefaultEmailSignatureAggregateBoolExp>;
  agentDefaultNylasEmailIntegrations?: InputMaybe<DbAgentDefaultNylasEmailIntegrationBoolExp>;
  agentDefaultNylasEmailIntegrationsAggregate?: InputMaybe<DbAgentDefaultNylasEmailIntegrationAggregateBoolExp>;
  baseActivities?: InputMaybe<DbBaseActivityBoolExp>;
  baseActivitiesAggregate?: InputMaybe<DbBaseActivityAggregateBoolExp>;
  businessUnit?: InputMaybe<StringComparisonExp>;
  calls?: InputMaybe<DbCallBoolExp>;
  callsAggregate?: InputMaybe<DbCallAggregateBoolExp>;
  city?: InputMaybe<StringComparisonExp>;
  contacts?: InputMaybe<DbContactBoolExp>;
  contactsAggregate?: InputMaybe<DbContactAggregateBoolExp>;
  country?: InputMaybe<EnumsCountryBoolExp>;
  countryCode?: InputMaybe<EnumsCountryEnumComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  currency?: InputMaybe<EnumsCurrencyEnumComparisonExp>;
  currencyCode?: InputMaybe<EnumsCurrencyBoolExp>;
  customDemandFields?: InputMaybe<DbCdfBoolExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  emailSignatures?: InputMaybe<DbEmailSignatureBoolExp>;
  emailSignaturesAggregate?: InputMaybe<DbEmailSignatureAggregateBoolExp>;
  emailTemplates?: InputMaybe<DbEmailTemplateBoolExp>;
  emailTemplatesAggregate?: InputMaybe<DbEmailTemplateAggregateBoolExp>;
  energyAdvisoryEnabled?: InputMaybe<BooleanComparisonExp>;
  go3ShopStatus?: InputMaybe<EnumsShopStatusEnumComparisonExp>;
  goShopStatus?: InputMaybe<EnumsShopStatusBoolExp>;
  groupId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lastShopLvlChangeDate?: InputMaybe<TimestampComparisonExp>;
  latitude?: InputMaybe<Float8ComparisonExp>;
  leadAutoAssignmentIntents?: InputMaybe<DbLeadAutoAssignmentIntentBoolExp>;
  leadAutoAssignmentRules?: InputMaybe<DbLeadAutoAssignmentRulesBoolExp>;
  lead_extending_activities?: InputMaybe<DbLeadExtendingActivitiesBoolExp>;
  lead_extending_appointments?: InputMaybe<DbLeadExtendingAppointmentTypesBoolExp>;
  lead_extending_tasks?: InputMaybe<DbLeadExtendingTaskTypesBoolExp>;
  leads?: InputMaybe<DbLeadBoolExp>;
  leadsAggregate?: InputMaybe<DbLeadAggregateBoolExp>;
  licensePartner?: InputMaybe<DbLicensePartnerBoolExp>;
  licensePartnerId?: InputMaybe<StringComparisonExp>;
  longitude?: InputMaybe<Float8ComparisonExp>;
  normalizedShopName?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  nylasEmailIntegrations?: InputMaybe<DbNylasEmailIntegrationBoolExp>;
  nylasEmailIntegrationsAggregate?: InputMaybe<DbNylasEmailIntegrationAggregateBoolExp>;
  onboardingLevel?: InputMaybe<IntComparisonExp>;
  phone?: InputMaybe<StringComparisonExp>;
  postalCode?: InputMaybe<StringComparisonExp>;
  profiles?: InputMaybe<DbShopProfileBoolExp>;
  profilesAggregate?: InputMaybe<DbShopProfileAggregateBoolExp>;
  propertyLounges?: InputMaybe<DbPropertyLoungeBoolExp>;
  qualiformLeadIntents?: InputMaybe<DbQualiformLeadIntentBoolExp>;
  qualiformLeadSources?: InputMaybe<DbQualiformLeadSourceBoolExp>;
  qualiformMandatoryFields?: InputMaybe<DbQualiformMandatoryFieldBoolExp>;
  qualiformShopSettings?: InputMaybe<DbQualiformShopSettingBoolExp>;
  referrals?: InputMaybe<DbReferralBoolExp>;
  referralsAggregate?: InputMaybe<DbReferralAggregateBoolExp>;
  referralsBySendingShopId?: InputMaybe<DbReferralBoolExp>;
  referralsBySendingShopIdAggregate?: InputMaybe<DbReferralAggregateBoolExp>;
  settings?: InputMaybe<DbShopSettingBoolExp>;
  settingsAggregate?: InputMaybe<DbShopSettingAggregateBoolExp>;
  shopGroup?: InputMaybe<DbShopGroupBoolExp>;
  shopName?: InputMaybe<StringComparisonExp>;
  shopOptimizelyClients?: InputMaybe<DbOptimizelyClientBoolExp>;
  shopStatus?: InputMaybe<EnumsShopStatusBoolExp>;
  shop_managed_consents?: InputMaybe<DbShopManagedConsentBoolExp>;
  shop_specific_consent_templates?: InputMaybe<DbConsentTemplateBoolExp>;
  status?: InputMaybe<EnumsShopStatusEnumComparisonExp>;
  streetName?: InputMaybe<StringComparisonExp>;
  streetNumber?: InputMaybe<StringComparisonExp>;
  tags?: InputMaybe<DbTagBoolExp>;
  teams?: InputMaybe<DbTeamBoolExp>;
  teamsAggregate?: InputMaybe<DbTeamAggregateBoolExp>;
  twilioConfigurations?: InputMaybe<DbTwilioConfigurationBoolExp>;
  twilioConfigurationsAggregate?: InputMaybe<DbTwilioConfigurationAggregateBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  userShopAssignments?: InputMaybe<DbUserShopAssignmentBoolExp>;
  userShopAssignmentsAggregate?: InputMaybe<DbUserShopAssignmentAggregateBoolExp>;
  website?: InputMaybe<StringComparisonExp>;
};

/** columns and relationships of "db.shop_commission_contract" */
export type DbShopCommissionContract = {
  __typename?: 'DbShopCommissionContract';
  commissionContract: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  language: LanguageEnum;
  lck: Scalars['Int'];
  num: Scalars['bigint'];
  /** An object relationship */
  qualiformShopSetting: DbQualiformShopSetting;
  qualiformShopSettingId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.shop_commission_contract" */
export type DbShopCommissionContractAggregateOrderBy = {
  avg?: InputMaybe<DbShopCommissionContractAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbShopCommissionContractMaxOrderBy>;
  min?: InputMaybe<DbShopCommissionContractMinOrderBy>;
  stddev?: InputMaybe<DbShopCommissionContractStddevOrderBy>;
  stddevPop?: InputMaybe<DbShopCommissionContractStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbShopCommissionContractStddevSampOrderBy>;
  sum?: InputMaybe<DbShopCommissionContractSumOrderBy>;
  varPop?: InputMaybe<DbShopCommissionContractVarPopOrderBy>;
  varSamp?: InputMaybe<DbShopCommissionContractVarSampOrderBy>;
  variance?: InputMaybe<DbShopCommissionContractVarianceOrderBy>;
};

/** order by avg() on columns of table "db.shop_commission_contract" */
export type DbShopCommissionContractAvgOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.shop_commission_contract". All fields are combined with a logical 'AND'. */
export type DbShopCommissionContractBoolExp = {
  _and?: InputMaybe<Array<DbShopCommissionContractBoolExp>>;
  _not?: InputMaybe<DbShopCommissionContractBoolExp>;
  _or?: InputMaybe<Array<DbShopCommissionContractBoolExp>>;
  commissionContract?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  language?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  lck?: InputMaybe<IntComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  qualiformShopSetting?: InputMaybe<DbQualiformShopSettingBoolExp>;
  qualiformShopSettingId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.shop_commission_contract" */
export type DbShopCommissionContractMaxOrderBy = {
  commissionContract?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  qualiformShopSettingId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.shop_commission_contract" */
export type DbShopCommissionContractMinOrderBy = {
  commissionContract?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  qualiformShopSettingId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.shop_commission_contract". */
export type DbShopCommissionContractOrderBy = {
  commissionContract?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  qualiformShopSetting?: InputMaybe<DbQualiformShopSettingOrderBy>;
  qualiformShopSettingId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_commission_contract" */
export type DbShopCommissionContractSelectColumn =
  /** column name */
  | 'commissionContract'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'language'
  /** column name */
  | 'lck'
  /** column name */
  | 'num'
  /** column name */
  | 'qualiformShopSettingId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.shop_commission_contract" */
export type DbShopCommissionContractStddevOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.shop_commission_contract" */
export type DbShopCommissionContractStddevPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.shop_commission_contract" */
export type DbShopCommissionContractStddevSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_shop_commission_contract" */
export type DbShopCommissionContractStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopCommissionContractStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopCommissionContractStreamCursorValueInput = {
  commissionContract?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageEnum>;
  lck?: InputMaybe<Scalars['Int']>;
  num?: InputMaybe<Scalars['bigint']>;
  qualiformShopSettingId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.shop_commission_contract" */
export type DbShopCommissionContractSumOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.shop_commission_contract" */
export type DbShopCommissionContractVarPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.shop_commission_contract" */
export type DbShopCommissionContractVarSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.shop_commission_contract" */
export type DbShopCommissionContractVarianceOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.shop_group" */
export type DbShopGroup = {
  __typename?: 'DbShopGroup';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  num: Scalars['bigint'];
  /** An array relationship */
  shops: Array<DbShop>;
  /** An aggregate relationship */
  shopsAggregate: DbShopAggregate;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** columns and relationships of "db.shop_group" */
export type DbShopGroupShopsArgs = {
  distinctOn?: InputMaybe<Array<DbShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopOrderBy>>;
  where?: InputMaybe<DbShopBoolExp>;
};

/** columns and relationships of "db.shop_group" */
export type DbShopGroupShopsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopOrderBy>>;
  where?: InputMaybe<DbShopBoolExp>;
};

/** aggregated selection of "db.shop_group" */
export type DbShopGroupAggregate = {
  __typename?: 'DbShopGroupAggregate';
  aggregate?: Maybe<DbShopGroupAggregateFields>;
  nodes: Array<DbShopGroup>;
};

/** aggregate fields of "db.shop_group" */
export type DbShopGroupAggregateFields = {
  __typename?: 'DbShopGroupAggregateFields';
  avg?: Maybe<DbShopGroupAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbShopGroupMaxFields>;
  min?: Maybe<DbShopGroupMinFields>;
  stddev?: Maybe<DbShopGroupStddevFields>;
  stddevPop?: Maybe<DbShopGroupStddevPopFields>;
  stddevSamp?: Maybe<DbShopGroupStddevSampFields>;
  sum?: Maybe<DbShopGroupSumFields>;
  varPop?: Maybe<DbShopGroupVarPopFields>;
  varSamp?: Maybe<DbShopGroupVarSampFields>;
  variance?: Maybe<DbShopGroupVarianceFields>;
};

/** aggregate fields of "db.shop_group" */
export type DbShopGroupAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbShopGroupSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type DbShopGroupAvgFields = {
  __typename?: 'DbShopGroupAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "db.shop_group". All fields are combined with a logical 'AND'. */
export type DbShopGroupBoolExp = {
  _and?: InputMaybe<Array<DbShopGroupBoolExp>>;
  _not?: InputMaybe<DbShopGroupBoolExp>;
  _or?: InputMaybe<Array<DbShopGroupBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  shops?: InputMaybe<DbShopBoolExp>;
  shopsAggregate?: InputMaybe<DbShopAggregateBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbShopGroupMaxFields = {
  __typename?: 'DbShopGroupMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type DbShopGroupMinFields = {
  __typename?: 'DbShopGroupMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "db.shop_group". */
export type DbShopGroupOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopsAggregate?: InputMaybe<DbShopAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_group" */
export type DbShopGroupSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'description'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'num'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** aggregate stddev on columns */
export type DbShopGroupStddevFields = {
  __typename?: 'DbShopGroupStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type DbShopGroupStddevPopFields = {
  __typename?: 'DbShopGroupStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type DbShopGroupStddevSampFields = {
  __typename?: 'DbShopGroupStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "db_shop_group" */
export type DbShopGroupStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopGroupStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopGroupStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbShopGroupSumFields = {
  __typename?: 'DbShopGroupSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** aggregate varPop on columns */
export type DbShopGroupVarPopFields = {
  __typename?: 'DbShopGroupVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type DbShopGroupVarSampFields = {
  __typename?: 'DbShopGroupVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type DbShopGroupVarianceFields = {
  __typename?: 'DbShopGroupVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "db.shop_managed_consent" */
export type DbShopManagedConsent = {
  __typename?: 'DbShopManagedConsent';
  consentType: ConsentTypeEnum;
  /** An object relationship */
  consent_template_type: EnumsConsentTemplateType;
  /** An object relationship */
  consent_type_name: EnumsConsentType;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  /** An object relationship */
  shop: DbShop;
  shopId: Scalars['String'];
  templateUsageType: ConsentTemplateTypeEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.shop_managed_consent" */
export type DbShopManagedConsentAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbShopManagedConsentMaxOrderBy>;
  min?: InputMaybe<DbShopManagedConsentMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.shop_managed_consent". All fields are combined with a logical 'AND'. */
export type DbShopManagedConsentBoolExp = {
  _and?: InputMaybe<Array<DbShopManagedConsentBoolExp>>;
  _not?: InputMaybe<DbShopManagedConsentBoolExp>;
  _or?: InputMaybe<Array<DbShopManagedConsentBoolExp>>;
  consentType?: InputMaybe<EnumsConsentTypeEnumComparisonExp>;
  consent_template_type?: InputMaybe<EnumsConsentTemplateTypeBoolExp>;
  consent_type_name?: InputMaybe<EnumsConsentTypeBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  templateUsageType?: InputMaybe<EnumsConsentTemplateTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.shop_managed_consent" */
export type DbShopManagedConsentMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.shop_managed_consent" */
export type DbShopManagedConsentMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.shop_managed_consent". */
export type DbShopManagedConsentOrderBy = {
  consentType?: InputMaybe<OrderBy>;
  consent_template_type?: InputMaybe<EnumsConsentTemplateTypeOrderBy>;
  consent_type_name?: InputMaybe<EnumsConsentTypeOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  templateUsageType?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_managed_consent" */
export type DbShopManagedConsentSelectColumn =
  /** column name */
  | 'consentType'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'shopId'
  /** column name */
  | 'templateUsageType'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** Streaming cursor of the table "db_shop_managed_consent" */
export type DbShopManagedConsentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopManagedConsentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopManagedConsentStreamCursorValueInput = {
  consentType?: InputMaybe<ConsentTypeEnum>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['String']>;
  templateUsageType?: InputMaybe<ConsentTemplateTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type DbShopMaxFields = {
  __typename?: 'DbShopMaxFields';
  businessUnit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastShopLvlChangeDate?: Maybe<Scalars['timestamp']>;
  latitude?: Maybe<Scalars['float8']>;
  licensePartnerId?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['float8']>;
  normalizedShopName?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  onboardingLevel?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  shopName?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.shop" */
export type DbShopMaxOrderBy = {
  businessUnit?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  groupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastShopLvlChangeDate?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  licensePartnerId?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  normalizedShopName?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  onboardingLevel?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  postalCode?: InputMaybe<OrderBy>;
  shopName?: InputMaybe<OrderBy>;
  streetName?: InputMaybe<OrderBy>;
  streetNumber?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  website?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbShopMinFields = {
  __typename?: 'DbShopMinFields';
  businessUnit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastShopLvlChangeDate?: Maybe<Scalars['timestamp']>;
  latitude?: Maybe<Scalars['float8']>;
  licensePartnerId?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['float8']>;
  normalizedShopName?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  onboardingLevel?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  shopName?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.shop" */
export type DbShopMinOrderBy = {
  businessUnit?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  groupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastShopLvlChangeDate?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  licensePartnerId?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  normalizedShopName?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  onboardingLevel?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  postalCode?: InputMaybe<OrderBy>;
  shopName?: InputMaybe<OrderBy>;
  streetName?: InputMaybe<OrderBy>;
  streetNumber?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  website?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.shop". */
export type DbShopOrderBy = {
  agentDefaultEmailSignaturesAggregate?: InputMaybe<DbAgentDefaultEmailSignatureAggregateOrderBy>;
  agentDefaultNylasEmailIntegrationsAggregate?: InputMaybe<DbAgentDefaultNylasEmailIntegrationAggregateOrderBy>;
  baseActivitiesAggregate?: InputMaybe<DbBaseActivityAggregateOrderBy>;
  businessUnit?: InputMaybe<OrderBy>;
  callsAggregate?: InputMaybe<DbCallAggregateOrderBy>;
  city?: InputMaybe<OrderBy>;
  contactsAggregate?: InputMaybe<DbContactAggregateOrderBy>;
  country?: InputMaybe<EnumsCountryOrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  currency?: InputMaybe<OrderBy>;
  currencyCode?: InputMaybe<EnumsCurrencyOrderBy>;
  customDemandFieldsAggregate?: InputMaybe<DbCdfAggregateOrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  emailSignaturesAggregate?: InputMaybe<DbEmailSignatureAggregateOrderBy>;
  emailTemplatesAggregate?: InputMaybe<DbEmailTemplateAggregateOrderBy>;
  energyAdvisoryEnabled?: InputMaybe<OrderBy>;
  go3ShopStatus?: InputMaybe<OrderBy>;
  goShopStatus?: InputMaybe<EnumsShopStatusOrderBy>;
  groupId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastShopLvlChangeDate?: InputMaybe<OrderBy>;
  latitude?: InputMaybe<OrderBy>;
  leadAutoAssignmentIntentsAggregate?: InputMaybe<DbLeadAutoAssignmentIntentAggregateOrderBy>;
  leadAutoAssignmentRulesAggregate?: InputMaybe<DbLeadAutoAssignmentRulesAggregateOrderBy>;
  lead_extending_activitiesAggregate?: InputMaybe<DbLeadExtendingActivitiesAggregateOrderBy>;
  lead_extending_appointmentsAggregate?: InputMaybe<DbLeadExtendingAppointmentTypesAggregateOrderBy>;
  lead_extending_tasksAggregate?: InputMaybe<DbLeadExtendingTaskTypesAggregateOrderBy>;
  leadsAggregate?: InputMaybe<DbLeadAggregateOrderBy>;
  licensePartner?: InputMaybe<DbLicensePartnerOrderBy>;
  licensePartnerId?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  normalizedShopName?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  nylasEmailIntegrationsAggregate?: InputMaybe<DbNylasEmailIntegrationAggregateOrderBy>;
  onboardingLevel?: InputMaybe<OrderBy>;
  phone?: InputMaybe<OrderBy>;
  postalCode?: InputMaybe<OrderBy>;
  profilesAggregate?: InputMaybe<DbShopProfileAggregateOrderBy>;
  propertyLoungesAggregate?: InputMaybe<DbPropertyLoungeAggregateOrderBy>;
  qualiformLeadIntentsAggregate?: InputMaybe<DbQualiformLeadIntentAggregateOrderBy>;
  qualiformLeadSourcesAggregate?: InputMaybe<DbQualiformLeadSourceAggregateOrderBy>;
  qualiformMandatoryFieldsAggregate?: InputMaybe<DbQualiformMandatoryFieldAggregateOrderBy>;
  qualiformShopSettingsAggregate?: InputMaybe<DbQualiformShopSettingAggregateOrderBy>;
  referralsAggregate?: InputMaybe<DbReferralAggregateOrderBy>;
  referralsBySendingShopIdAggregate?: InputMaybe<DbReferralAggregateOrderBy>;
  settingsAggregate?: InputMaybe<DbShopSettingAggregateOrderBy>;
  shopGroup?: InputMaybe<DbShopGroupOrderBy>;
  shopName?: InputMaybe<OrderBy>;
  shopOptimizelyClientsAggregate?: InputMaybe<DbOptimizelyClientAggregateOrderBy>;
  shopStatus?: InputMaybe<EnumsShopStatusOrderBy>;
  shop_managed_consentsAggregate?: InputMaybe<DbShopManagedConsentAggregateOrderBy>;
  shop_specific_consent_templatesAggregate?: InputMaybe<DbConsentTemplateAggregateOrderBy>;
  status?: InputMaybe<OrderBy>;
  streetName?: InputMaybe<OrderBy>;
  streetNumber?: InputMaybe<OrderBy>;
  tagsAggregate?: InputMaybe<DbTagAggregateOrderBy>;
  teamsAggregate?: InputMaybe<DbTeamAggregateOrderBy>;
  twilioConfigurationsAggregate?: InputMaybe<DbTwilioConfigurationAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  userShopAssignmentsAggregate?: InputMaybe<DbUserShopAssignmentAggregateOrderBy>;
  website?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfile = {
  __typename?: 'DbShopProfile';
  activated?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  careers: Array<DbShopProfileCareer>;
  /** An aggregate relationship */
  careersAggregate: DbShopProfileCareerAggregate;
  /** An array relationship */
  cityDistricts: Array<DbShopProfileCityDistrict>;
  /** An aggregate relationship */
  cityDistrictsAggregate: DbShopProfileCityDistrictAggregate;
  clientId?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  /** An array relationship */
  customerMails: Array<DbShopProfileCustomerMails>;
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  descriptions: Array<DbShopProfileDescription>;
  /** An aggregate relationship */
  descriptionsAggregate: DbShopProfileDescriptionAggregate;
  /** An array relationship */
  expertises: Array<DbShopProfileExpertise>;
  /** An aggregate relationship */
  expertisesAggregate: DbShopProfileExpertiseAggregate;
  id: Scalars['String'];
  /** An array relationship */
  images: Array<DbShopProfileImage>;
  /** An aggregate relationship */
  imagesAggregate: DbShopProfileImageAggregate;
  /** An array relationship */
  imprints: Array<DbShopProfileImprint>;
  /** An aggregate relationship */
  imprintsAggregate: DbShopProfileImprintAggregate;
  num: Scalars['bigint'];
  optin?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  /** An array relationship */
  privacyPolicies: Array<DbShopProfilePrivacyPolicy>;
  /** An aggregate relationship */
  privacyPoliciesAggregate: DbShopProfilePrivacyPolicyAggregate;
  /** An object relationship */
  propertyLounge?: Maybe<DbPropertyLounge>;
  propertyLoungeId?: Maybe<Scalars['String']>;
  /** An array relationship */
  propertyPreviews: Array<DbShopProfilePropertyPreview>;
  /** An aggregate relationship */
  propertyPreviewsAggregate: DbShopProfilePropertyPreviewAggregate;
  published?: Maybe<Scalars['timestamp']>;
  publishedBy?: Maybe<Scalars['String']>;
  /** An array relationship */
  quotes: Array<DbShopProfileQuote>;
  /** An aggregate relationship */
  quotesAggregate: DbShopProfileQuoteAggregate;
  recipientId?: Maybe<Scalars['String']>;
  /** An array relationship */
  seoTrackings: Array<DbShopProfileSeoTracking>;
  /** An object relationship */
  shop: DbShop;
  shopId: Scalars['String'];
  /** An array relationship */
  shopProfileFeaturedAgents: Array<DbShopProfileFeaturedAgent>;
  /** An array relationship */
  shopProfileFeaturedContents: Array<DbShopProfileFeaturedContent>;
  /** An object relationship */
  socialMediaProfiles?: Maybe<DbShopProfileSocialMediaProfiles>;
  /** An array relationship */
  spokenLanguages: Array<DbShopProfileSpokenLanguage>;
  /** An aggregate relationship */
  spokenLanguagesAggregate: DbShopProfileSpokenLanguageAggregate;
  /** An array relationship */
  teams: Array<DbShopProfileTeam>;
  /** An array relationship */
  termsAndConditions: Array<DbShopProfileTermsAndConditions>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  /** An array relationship */
  withdrawalPolicies: Array<DbShopProfileWithdrawalPolicy>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfileCareersArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileCareerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileCareerOrderBy>>;
  where?: InputMaybe<DbShopProfileCareerBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfileCareersAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileCareerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileCareerOrderBy>>;
  where?: InputMaybe<DbShopProfileCareerBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfileCityDistrictsArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileCityDistrictSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileCityDistrictOrderBy>>;
  where?: InputMaybe<DbShopProfileCityDistrictBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfileCityDistrictsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileCityDistrictSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileCityDistrictOrderBy>>;
  where?: InputMaybe<DbShopProfileCityDistrictBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfileCustomerMailsArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileCustomerMailsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileCustomerMailsOrderBy>>;
  where?: InputMaybe<DbShopProfileCustomerMailsBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfileDescriptionsArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileDescriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileDescriptionOrderBy>>;
  where?: InputMaybe<DbShopProfileDescriptionBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfileDescriptionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileDescriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileDescriptionOrderBy>>;
  where?: InputMaybe<DbShopProfileDescriptionBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfileExpertisesArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileExpertiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileExpertiseOrderBy>>;
  where?: InputMaybe<DbShopProfileExpertiseBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfileExpertisesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileExpertiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileExpertiseOrderBy>>;
  where?: InputMaybe<DbShopProfileExpertiseBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfileImagesArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileImageOrderBy>>;
  where?: InputMaybe<DbShopProfileImageBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfileImagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileImageOrderBy>>;
  where?: InputMaybe<DbShopProfileImageBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfileImprintsArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileImprintSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileImprintOrderBy>>;
  where?: InputMaybe<DbShopProfileImprintBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfileImprintsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileImprintSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileImprintOrderBy>>;
  where?: InputMaybe<DbShopProfileImprintBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfilePrivacyPoliciesArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfilePrivacyPolicySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfilePrivacyPolicyOrderBy>>;
  where?: InputMaybe<DbShopProfilePrivacyPolicyBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfilePrivacyPoliciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfilePrivacyPolicySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfilePrivacyPolicyOrderBy>>;
  where?: InputMaybe<DbShopProfilePrivacyPolicyBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfilePropertyPreviewsArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfilePropertyPreviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfilePropertyPreviewOrderBy>>;
  where?: InputMaybe<DbShopProfilePropertyPreviewBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfilePropertyPreviewsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfilePropertyPreviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfilePropertyPreviewOrderBy>>;
  where?: InputMaybe<DbShopProfilePropertyPreviewBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfileQuotesArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileQuoteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileQuoteOrderBy>>;
  where?: InputMaybe<DbShopProfileQuoteBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfileQuotesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileQuoteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileQuoteOrderBy>>;
  where?: InputMaybe<DbShopProfileQuoteBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfileSeoTrackingsArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileSeoTrackingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileSeoTrackingOrderBy>>;
  where?: InputMaybe<DbShopProfileSeoTrackingBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfileShopProfileFeaturedAgentsArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileFeaturedAgentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileFeaturedAgentOrderBy>>;
  where?: InputMaybe<DbShopProfileFeaturedAgentBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfileShopProfileFeaturedContentsArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileFeaturedContentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileFeaturedContentOrderBy>>;
  where?: InputMaybe<DbShopProfileFeaturedContentBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfileSpokenLanguagesArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileSpokenLanguageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileSpokenLanguageOrderBy>>;
  where?: InputMaybe<DbShopProfileSpokenLanguageBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfileSpokenLanguagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileSpokenLanguageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileSpokenLanguageOrderBy>>;
  where?: InputMaybe<DbShopProfileSpokenLanguageBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfileTeamsArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileTeamOrderBy>>;
  where?: InputMaybe<DbShopProfileTeamBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfileTermsAndConditionsArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileTermsAndConditionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileTermsAndConditionsOrderBy>>;
  where?: InputMaybe<DbShopProfileTermsAndConditionsBoolExp>;
};

/** columns and relationships of "db.shop_profile" */
export type DbShopProfileWithdrawalPoliciesArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileWithdrawalPolicySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileWithdrawalPolicyOrderBy>>;
  where?: InputMaybe<DbShopProfileWithdrawalPolicyBoolExp>;
};

/** aggregated selection of "db.shop_profile" */
export type DbShopProfileAggregate = {
  __typename?: 'DbShopProfileAggregate';
  aggregate?: Maybe<DbShopProfileAggregateFields>;
  nodes: Array<DbShopProfile>;
};

export type DbShopProfileAggregateBoolExp = {
  bool_and?: InputMaybe<DbShopProfileAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbShopProfileAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbShopProfileAggregateBoolExpCount>;
};

/** aggregate fields of "db.shop_profile" */
export type DbShopProfileAggregateFields = {
  __typename?: 'DbShopProfileAggregateFields';
  avg?: Maybe<DbShopProfileAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbShopProfileMaxFields>;
  min?: Maybe<DbShopProfileMinFields>;
  stddev?: Maybe<DbShopProfileStddevFields>;
  stddevPop?: Maybe<DbShopProfileStddevPopFields>;
  stddevSamp?: Maybe<DbShopProfileStddevSampFields>;
  sum?: Maybe<DbShopProfileSumFields>;
  varPop?: Maybe<DbShopProfileVarPopFields>;
  varSamp?: Maybe<DbShopProfileVarSampFields>;
  variance?: Maybe<DbShopProfileVarianceFields>;
};

/** aggregate fields of "db.shop_profile" */
export type DbShopProfileAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbShopProfileSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.shop_profile" */
export type DbShopProfileAggregateOrderBy = {
  avg?: InputMaybe<DbShopProfileAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbShopProfileMaxOrderBy>;
  min?: InputMaybe<DbShopProfileMinOrderBy>;
  stddev?: InputMaybe<DbShopProfileStddevOrderBy>;
  stddevPop?: InputMaybe<DbShopProfileStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbShopProfileStddevSampOrderBy>;
  sum?: InputMaybe<DbShopProfileSumOrderBy>;
  varPop?: InputMaybe<DbShopProfileVarPopOrderBy>;
  varSamp?: InputMaybe<DbShopProfileVarSampOrderBy>;
  variance?: InputMaybe<DbShopProfileVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbShopProfileAvgFields = {
  __typename?: 'DbShopProfileAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.shop_profile" */
export type DbShopProfileAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.shop_profile". All fields are combined with a logical 'AND'. */
export type DbShopProfileBoolExp = {
  _and?: InputMaybe<Array<DbShopProfileBoolExp>>;
  _not?: InputMaybe<DbShopProfileBoolExp>;
  _or?: InputMaybe<Array<DbShopProfileBoolExp>>;
  activated?: InputMaybe<BooleanComparisonExp>;
  careers?: InputMaybe<DbShopProfileCareerBoolExp>;
  careersAggregate?: InputMaybe<DbShopProfileCareerAggregateBoolExp>;
  cityDistricts?: InputMaybe<DbShopProfileCityDistrictBoolExp>;
  cityDistrictsAggregate?: InputMaybe<DbShopProfileCityDistrictAggregateBoolExp>;
  clientId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  customerMails?: InputMaybe<DbShopProfileCustomerMailsBoolExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  descriptions?: InputMaybe<DbShopProfileDescriptionBoolExp>;
  descriptionsAggregate?: InputMaybe<DbShopProfileDescriptionAggregateBoolExp>;
  expertises?: InputMaybe<DbShopProfileExpertiseBoolExp>;
  expertisesAggregate?: InputMaybe<DbShopProfileExpertiseAggregateBoolExp>;
  id?: InputMaybe<StringComparisonExp>;
  images?: InputMaybe<DbShopProfileImageBoolExp>;
  imagesAggregate?: InputMaybe<DbShopProfileImageAggregateBoolExp>;
  imprints?: InputMaybe<DbShopProfileImprintBoolExp>;
  imprintsAggregate?: InputMaybe<DbShopProfileImprintAggregateBoolExp>;
  num?: InputMaybe<BigintComparisonExp>;
  optin?: InputMaybe<StringComparisonExp>;
  placeId?: InputMaybe<StringComparisonExp>;
  privacyPolicies?: InputMaybe<DbShopProfilePrivacyPolicyBoolExp>;
  privacyPoliciesAggregate?: InputMaybe<DbShopProfilePrivacyPolicyAggregateBoolExp>;
  propertyLounge?: InputMaybe<DbPropertyLoungeBoolExp>;
  propertyLoungeId?: InputMaybe<StringComparisonExp>;
  propertyPreviews?: InputMaybe<DbShopProfilePropertyPreviewBoolExp>;
  propertyPreviewsAggregate?: InputMaybe<DbShopProfilePropertyPreviewAggregateBoolExp>;
  published?: InputMaybe<TimestampComparisonExp>;
  publishedBy?: InputMaybe<StringComparisonExp>;
  quotes?: InputMaybe<DbShopProfileQuoteBoolExp>;
  quotesAggregate?: InputMaybe<DbShopProfileQuoteAggregateBoolExp>;
  recipientId?: InputMaybe<StringComparisonExp>;
  seoTrackings?: InputMaybe<DbShopProfileSeoTrackingBoolExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  shopProfileFeaturedAgents?: InputMaybe<DbShopProfileFeaturedAgentBoolExp>;
  shopProfileFeaturedContents?: InputMaybe<DbShopProfileFeaturedContentBoolExp>;
  socialMediaProfiles?: InputMaybe<DbShopProfileSocialMediaProfilesBoolExp>;
  spokenLanguages?: InputMaybe<DbShopProfileSpokenLanguageBoolExp>;
  spokenLanguagesAggregate?: InputMaybe<DbShopProfileSpokenLanguageAggregateBoolExp>;
  teams?: InputMaybe<DbShopProfileTeamBoolExp>;
  termsAndConditions?: InputMaybe<DbShopProfileTermsAndConditionsBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  withdrawalPolicies?: InputMaybe<DbShopProfileWithdrawalPolicyBoolExp>;
};

/** columns and relationships of "db.shop_profile_career" */
export type DbShopProfileCareer = {
  __typename?: 'DbShopProfileCareer';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isAiTranslated?: Maybe<Scalars['Boolean']>;
  lang: LanguageEnum;
  link?: Maybe<Scalars['String']>;
  shopProfileId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.shop_profile_career" */
export type DbShopProfileCareerAggregate = {
  __typename?: 'DbShopProfileCareerAggregate';
  aggregate?: Maybe<DbShopProfileCareerAggregateFields>;
  nodes: Array<DbShopProfileCareer>;
};

export type DbShopProfileCareerAggregateBoolExp = {
  bool_and?: InputMaybe<DbShopProfileCareerAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbShopProfileCareerAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbShopProfileCareerAggregateBoolExpCount>;
};

/** aggregate fields of "db.shop_profile_career" */
export type DbShopProfileCareerAggregateFields = {
  __typename?: 'DbShopProfileCareerAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<DbShopProfileCareerMaxFields>;
  min?: Maybe<DbShopProfileCareerMinFields>;
};

/** aggregate fields of "db.shop_profile_career" */
export type DbShopProfileCareerAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbShopProfileCareerSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.shop_profile_career" */
export type DbShopProfileCareerAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbShopProfileCareerMaxOrderBy>;
  min?: InputMaybe<DbShopProfileCareerMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.shop_profile_career". All fields are combined with a logical 'AND'. */
export type DbShopProfileCareerBoolExp = {
  _and?: InputMaybe<Array<DbShopProfileCareerBoolExp>>;
  _not?: InputMaybe<DbShopProfileCareerBoolExp>;
  _or?: InputMaybe<Array<DbShopProfileCareerBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  headline?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  isAiTranslated?: InputMaybe<BooleanComparisonExp>;
  lang?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  link?: InputMaybe<StringComparisonExp>;
  shopProfileId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbShopProfileCareerMaxFields = {
  __typename?: 'DbShopProfileCareerMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  shopProfileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.shop_profile_career" */
export type DbShopProfileCareerMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  headline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  link?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbShopProfileCareerMinFields = {
  __typename?: 'DbShopProfileCareerMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  shopProfileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.shop_profile_career" */
export type DbShopProfileCareerMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  headline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  link?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.shop_profile_career". */
export type DbShopProfileCareerOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  headline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isAiTranslated?: InputMaybe<OrderBy>;
  lang?: InputMaybe<OrderBy>;
  link?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_profile_career" */
export type DbShopProfileCareerSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'description'
  /** column name */
  | 'headline'
  /** column name */
  | 'id'
  /** column name */
  | 'isAiTranslated'
  /** column name */
  | 'lang'
  /** column name */
  | 'link'
  /** column name */
  | 'shopProfileId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbShopProfileCareerAggregateBoolExpBool_andArgumentsColumns" columns of table "db.shop_profile_career" */
export type DbShopProfileCareerSelectColumnDbShopProfileCareerAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  | 'deleted'
  /** column name */
  | 'isAiTranslated';

/** select "dbShopProfileCareerAggregateBoolExpBool_orArgumentsColumns" columns of table "db.shop_profile_career" */
export type DbShopProfileCareerSelectColumnDbShopProfileCareerAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  | 'deleted'
  /** column name */
  | 'isAiTranslated';

/** Streaming cursor of the table "db_shop_profile_career" */
export type DbShopProfileCareerStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopProfileCareerStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopProfileCareerStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isAiTranslated?: InputMaybe<Scalars['Boolean']>;
  lang?: InputMaybe<LanguageEnum>;
  link?: InputMaybe<Scalars['String']>;
  shopProfileId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "db.shop_profile_city_district" */
export type DbShopProfileCityDistrict = {
  __typename?: 'DbShopProfileCityDistrict';
  city: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  district?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  language: LanguageEnum;
  num: Scalars['bigint'];
  /** An object relationship */
  shopProfile: DbShopProfile;
  shopProfileId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.shop_profile_city_district" */
export type DbShopProfileCityDistrictAggregate = {
  __typename?: 'DbShopProfileCityDistrictAggregate';
  aggregate?: Maybe<DbShopProfileCityDistrictAggregateFields>;
  nodes: Array<DbShopProfileCityDistrict>;
};

export type DbShopProfileCityDistrictAggregateBoolExp = {
  bool_and?: InputMaybe<DbShopProfileCityDistrictAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbShopProfileCityDistrictAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbShopProfileCityDistrictAggregateBoolExpCount>;
};

/** aggregate fields of "db.shop_profile_city_district" */
export type DbShopProfileCityDistrictAggregateFields = {
  __typename?: 'DbShopProfileCityDistrictAggregateFields';
  avg?: Maybe<DbShopProfileCityDistrictAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbShopProfileCityDistrictMaxFields>;
  min?: Maybe<DbShopProfileCityDistrictMinFields>;
  stddev?: Maybe<DbShopProfileCityDistrictStddevFields>;
  stddevPop?: Maybe<DbShopProfileCityDistrictStddevPopFields>;
  stddevSamp?: Maybe<DbShopProfileCityDistrictStddevSampFields>;
  sum?: Maybe<DbShopProfileCityDistrictSumFields>;
  varPop?: Maybe<DbShopProfileCityDistrictVarPopFields>;
  varSamp?: Maybe<DbShopProfileCityDistrictVarSampFields>;
  variance?: Maybe<DbShopProfileCityDistrictVarianceFields>;
};

/** aggregate fields of "db.shop_profile_city_district" */
export type DbShopProfileCityDistrictAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbShopProfileCityDistrictSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.shop_profile_city_district" */
export type DbShopProfileCityDistrictAggregateOrderBy = {
  avg?: InputMaybe<DbShopProfileCityDistrictAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbShopProfileCityDistrictMaxOrderBy>;
  min?: InputMaybe<DbShopProfileCityDistrictMinOrderBy>;
  stddev?: InputMaybe<DbShopProfileCityDistrictStddevOrderBy>;
  stddevPop?: InputMaybe<DbShopProfileCityDistrictStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbShopProfileCityDistrictStddevSampOrderBy>;
  sum?: InputMaybe<DbShopProfileCityDistrictSumOrderBy>;
  varPop?: InputMaybe<DbShopProfileCityDistrictVarPopOrderBy>;
  varSamp?: InputMaybe<DbShopProfileCityDistrictVarSampOrderBy>;
  variance?: InputMaybe<DbShopProfileCityDistrictVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbShopProfileCityDistrictAvgFields = {
  __typename?: 'DbShopProfileCityDistrictAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.shop_profile_city_district" */
export type DbShopProfileCityDistrictAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.shop_profile_city_district". All fields are combined with a logical 'AND'. */
export type DbShopProfileCityDistrictBoolExp = {
  _and?: InputMaybe<Array<DbShopProfileCityDistrictBoolExp>>;
  _not?: InputMaybe<DbShopProfileCityDistrictBoolExp>;
  _or?: InputMaybe<Array<DbShopProfileCityDistrictBoolExp>>;
  city?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  district?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  language?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  shopProfile?: InputMaybe<DbShopProfileBoolExp>;
  shopProfileId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbShopProfileCityDistrictMaxFields = {
  __typename?: 'DbShopProfileCityDistrictMaxFields';
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  district?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  shopProfileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.shop_profile_city_district" */
export type DbShopProfileCityDistrictMaxOrderBy = {
  city?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  district?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbShopProfileCityDistrictMinFields = {
  __typename?: 'DbShopProfileCityDistrictMinFields';
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  district?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  shopProfileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.shop_profile_city_district" */
export type DbShopProfileCityDistrictMinOrderBy = {
  city?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  district?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.shop_profile_city_district". */
export type DbShopProfileCityDistrictOrderBy = {
  city?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  district?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfile?: InputMaybe<DbShopProfileOrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_profile_city_district" */
export type DbShopProfileCityDistrictSelectColumn =
  /** column name */
  | 'city'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'district'
  /** column name */
  | 'id'
  /** column name */
  | 'language'
  /** column name */
  | 'num'
  /** column name */
  | 'shopProfileId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbShopProfileCityDistrictAggregateBoolExpBool_andArgumentsColumns" columns of table "db.shop_profile_city_district" */
export type DbShopProfileCityDistrictSelectColumnDbShopProfileCityDistrictAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbShopProfileCityDistrictAggregateBoolExpBool_orArgumentsColumns" columns of table "db.shop_profile_city_district" */
export type DbShopProfileCityDistrictSelectColumnDbShopProfileCityDistrictAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbShopProfileCityDistrictStddevFields = {
  __typename?: 'DbShopProfileCityDistrictStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.shop_profile_city_district" */
export type DbShopProfileCityDistrictStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbShopProfileCityDistrictStddevPopFields = {
  __typename?: 'DbShopProfileCityDistrictStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.shop_profile_city_district" */
export type DbShopProfileCityDistrictStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbShopProfileCityDistrictStddevSampFields = {
  __typename?: 'DbShopProfileCityDistrictStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.shop_profile_city_district" */
export type DbShopProfileCityDistrictStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_shop_profile_city_district" */
export type DbShopProfileCityDistrictStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopProfileCityDistrictStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopProfileCityDistrictStreamCursorValueInput = {
  city?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  district?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageEnum>;
  num?: InputMaybe<Scalars['bigint']>;
  shopProfileId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbShopProfileCityDistrictSumFields = {
  __typename?: 'DbShopProfileCityDistrictSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.shop_profile_city_district" */
export type DbShopProfileCityDistrictSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbShopProfileCityDistrictVarPopFields = {
  __typename?: 'DbShopProfileCityDistrictVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.shop_profile_city_district" */
export type DbShopProfileCityDistrictVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbShopProfileCityDistrictVarSampFields = {
  __typename?: 'DbShopProfileCityDistrictVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.shop_profile_city_district" */
export type DbShopProfileCityDistrictVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbShopProfileCityDistrictVarianceFields = {
  __typename?: 'DbShopProfileCityDistrictVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.shop_profile_city_district" */
export type DbShopProfileCityDistrictVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.shop_profile_customer_mails" */
export type DbShopProfileCustomerMails = {
  __typename?: 'DbShopProfileCustomerMails';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  footer: Scalars['String'];
  id: Scalars['String'];
  lang: LanguageEnum;
  /** An object relationship */
  language: EnumsLanguage;
  num: Scalars['bigint'];
  shopProfileId: Scalars['String'];
  /** An object relationship */
  shop_profile: DbShopProfile;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.shop_profile_customer_mails" */
export type DbShopProfileCustomerMailsAggregateOrderBy = {
  avg?: InputMaybe<DbShopProfileCustomerMailsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbShopProfileCustomerMailsMaxOrderBy>;
  min?: InputMaybe<DbShopProfileCustomerMailsMinOrderBy>;
  stddev?: InputMaybe<DbShopProfileCustomerMailsStddevOrderBy>;
  stddevPop?: InputMaybe<DbShopProfileCustomerMailsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbShopProfileCustomerMailsStddevSampOrderBy>;
  sum?: InputMaybe<DbShopProfileCustomerMailsSumOrderBy>;
  varPop?: InputMaybe<DbShopProfileCustomerMailsVarPopOrderBy>;
  varSamp?: InputMaybe<DbShopProfileCustomerMailsVarSampOrderBy>;
  variance?: InputMaybe<DbShopProfileCustomerMailsVarianceOrderBy>;
};

/** order by avg() on columns of table "db.shop_profile_customer_mails" */
export type DbShopProfileCustomerMailsAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.shop_profile_customer_mails". All fields are combined with a logical 'AND'. */
export type DbShopProfileCustomerMailsBoolExp = {
  _and?: InputMaybe<Array<DbShopProfileCustomerMailsBoolExp>>;
  _not?: InputMaybe<DbShopProfileCustomerMailsBoolExp>;
  _or?: InputMaybe<Array<DbShopProfileCustomerMailsBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  footer?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lang?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  language?: InputMaybe<EnumsLanguageBoolExp>;
  num?: InputMaybe<BigintComparisonExp>;
  shopProfileId?: InputMaybe<StringComparisonExp>;
  shop_profile?: InputMaybe<DbShopProfileBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.shop_profile_customer_mails" */
export type DbShopProfileCustomerMailsMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  footer?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.shop_profile_customer_mails" */
export type DbShopProfileCustomerMailsMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  footer?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.shop_profile_customer_mails". */
export type DbShopProfileCustomerMailsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  footer?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lang?: InputMaybe<OrderBy>;
  language?: InputMaybe<EnumsLanguageOrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  shop_profile?: InputMaybe<DbShopProfileOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_profile_customer_mails" */
export type DbShopProfileCustomerMailsSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'footer'
  /** column name */
  | 'id'
  /** column name */
  | 'lang'
  /** column name */
  | 'num'
  /** column name */
  | 'shopProfileId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.shop_profile_customer_mails" */
export type DbShopProfileCustomerMailsStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.shop_profile_customer_mails" */
export type DbShopProfileCustomerMailsStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.shop_profile_customer_mails" */
export type DbShopProfileCustomerMailsStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_shop_profile_customer_mails" */
export type DbShopProfileCustomerMailsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopProfileCustomerMailsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopProfileCustomerMailsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  footer?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<LanguageEnum>;
  num?: InputMaybe<Scalars['bigint']>;
  shopProfileId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.shop_profile_customer_mails" */
export type DbShopProfileCustomerMailsSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.shop_profile_customer_mails" */
export type DbShopProfileCustomerMailsVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.shop_profile_customer_mails" */
export type DbShopProfileCustomerMailsVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.shop_profile_customer_mails" */
export type DbShopProfileCustomerMailsVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.shop_profile_description" */
export type DbShopProfileDescription = {
  __typename?: 'DbShopProfileDescription';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isAiTranslated?: Maybe<Scalars['Boolean']>;
  lang: LanguageEnum;
  /** An object relationship */
  language: EnumsLanguage;
  num: Scalars['bigint'];
  /** An object relationship */
  shopProfile: DbShopProfile;
  shopProfileId: Scalars['String'];
  slugOverride?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

/** aggregated selection of "db.shop_profile_description" */
export type DbShopProfileDescriptionAggregate = {
  __typename?: 'DbShopProfileDescriptionAggregate';
  aggregate?: Maybe<DbShopProfileDescriptionAggregateFields>;
  nodes: Array<DbShopProfileDescription>;
};

export type DbShopProfileDescriptionAggregateBoolExp = {
  bool_and?: InputMaybe<DbShopProfileDescriptionAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbShopProfileDescriptionAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbShopProfileDescriptionAggregateBoolExpCount>;
};

/** aggregate fields of "db.shop_profile_description" */
export type DbShopProfileDescriptionAggregateFields = {
  __typename?: 'DbShopProfileDescriptionAggregateFields';
  avg?: Maybe<DbShopProfileDescriptionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbShopProfileDescriptionMaxFields>;
  min?: Maybe<DbShopProfileDescriptionMinFields>;
  stddev?: Maybe<DbShopProfileDescriptionStddevFields>;
  stddevPop?: Maybe<DbShopProfileDescriptionStddevPopFields>;
  stddevSamp?: Maybe<DbShopProfileDescriptionStddevSampFields>;
  sum?: Maybe<DbShopProfileDescriptionSumFields>;
  varPop?: Maybe<DbShopProfileDescriptionVarPopFields>;
  varSamp?: Maybe<DbShopProfileDescriptionVarSampFields>;
  variance?: Maybe<DbShopProfileDescriptionVarianceFields>;
};

/** aggregate fields of "db.shop_profile_description" */
export type DbShopProfileDescriptionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbShopProfileDescriptionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.shop_profile_description" */
export type DbShopProfileDescriptionAggregateOrderBy = {
  avg?: InputMaybe<DbShopProfileDescriptionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbShopProfileDescriptionMaxOrderBy>;
  min?: InputMaybe<DbShopProfileDescriptionMinOrderBy>;
  stddev?: InputMaybe<DbShopProfileDescriptionStddevOrderBy>;
  stddevPop?: InputMaybe<DbShopProfileDescriptionStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbShopProfileDescriptionStddevSampOrderBy>;
  sum?: InputMaybe<DbShopProfileDescriptionSumOrderBy>;
  varPop?: InputMaybe<DbShopProfileDescriptionVarPopOrderBy>;
  varSamp?: InputMaybe<DbShopProfileDescriptionVarSampOrderBy>;
  variance?: InputMaybe<DbShopProfileDescriptionVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbShopProfileDescriptionAvgFields = {
  __typename?: 'DbShopProfileDescriptionAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.shop_profile_description" */
export type DbShopProfileDescriptionAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.shop_profile_description". All fields are combined with a logical 'AND'. */
export type DbShopProfileDescriptionBoolExp = {
  _and?: InputMaybe<Array<DbShopProfileDescriptionBoolExp>>;
  _not?: InputMaybe<DbShopProfileDescriptionBoolExp>;
  _or?: InputMaybe<Array<DbShopProfileDescriptionBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  displayName?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  isAiTranslated?: InputMaybe<BooleanComparisonExp>;
  lang?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  language?: InputMaybe<EnumsLanguageBoolExp>;
  num?: InputMaybe<BigintComparisonExp>;
  shopProfile?: InputMaybe<DbShopProfileBoolExp>;
  shopProfileId?: InputMaybe<StringComparisonExp>;
  slugOverride?: InputMaybe<StringComparisonExp>;
  text?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbShopProfileDescriptionMaxFields = {
  __typename?: 'DbShopProfileDescriptionMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  shopProfileId?: Maybe<Scalars['String']>;
  slugOverride?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.shop_profile_description" */
export type DbShopProfileDescriptionMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  displayName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  slugOverride?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbShopProfileDescriptionMinFields = {
  __typename?: 'DbShopProfileDescriptionMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  shopProfileId?: Maybe<Scalars['String']>;
  slugOverride?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.shop_profile_description" */
export type DbShopProfileDescriptionMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  displayName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  slugOverride?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.shop_profile_description". */
export type DbShopProfileDescriptionOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  displayName?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isAiTranslated?: InputMaybe<OrderBy>;
  lang?: InputMaybe<OrderBy>;
  language?: InputMaybe<EnumsLanguageOrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfile?: InputMaybe<DbShopProfileOrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  slugOverride?: InputMaybe<OrderBy>;
  text?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_profile_description" */
export type DbShopProfileDescriptionSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'displayName'
  /** column name */
  | 'id'
  /** column name */
  | 'isAiTranslated'
  /** column name */
  | 'lang'
  /** column name */
  | 'num'
  /** column name */
  | 'shopProfileId'
  /** column name */
  | 'slugOverride'
  /** column name */
  | 'text'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy'
  /** column name */
  | 'url';

/** select "dbShopProfileDescriptionAggregateBoolExpBool_andArgumentsColumns" columns of table "db.shop_profile_description" */
export type DbShopProfileDescriptionSelectColumnDbShopProfileDescriptionAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  | 'deleted'
  /** column name */
  | 'isAiTranslated';

/** select "dbShopProfileDescriptionAggregateBoolExpBool_orArgumentsColumns" columns of table "db.shop_profile_description" */
export type DbShopProfileDescriptionSelectColumnDbShopProfileDescriptionAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  | 'deleted'
  /** column name */
  | 'isAiTranslated';

/** aggregate stddev on columns */
export type DbShopProfileDescriptionStddevFields = {
  __typename?: 'DbShopProfileDescriptionStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.shop_profile_description" */
export type DbShopProfileDescriptionStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbShopProfileDescriptionStddevPopFields = {
  __typename?: 'DbShopProfileDescriptionStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.shop_profile_description" */
export type DbShopProfileDescriptionStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbShopProfileDescriptionStddevSampFields = {
  __typename?: 'DbShopProfileDescriptionStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.shop_profile_description" */
export type DbShopProfileDescriptionStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_shop_profile_description" */
export type DbShopProfileDescriptionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopProfileDescriptionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopProfileDescriptionStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  displayName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isAiTranslated?: InputMaybe<Scalars['Boolean']>;
  lang?: InputMaybe<LanguageEnum>;
  num?: InputMaybe<Scalars['bigint']>;
  shopProfileId?: InputMaybe<Scalars['String']>;
  slugOverride?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbShopProfileDescriptionSumFields = {
  __typename?: 'DbShopProfileDescriptionSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.shop_profile_description" */
export type DbShopProfileDescriptionSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbShopProfileDescriptionVarPopFields = {
  __typename?: 'DbShopProfileDescriptionVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.shop_profile_description" */
export type DbShopProfileDescriptionVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbShopProfileDescriptionVarSampFields = {
  __typename?: 'DbShopProfileDescriptionVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.shop_profile_description" */
export type DbShopProfileDescriptionVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbShopProfileDescriptionVarianceFields = {
  __typename?: 'DbShopProfileDescriptionVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.shop_profile_description" */
export type DbShopProfileDescriptionVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.shop_profile_expertise" */
export type DbShopProfileExpertise = {
  __typename?: 'DbShopProfileExpertise';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  expertise: ShopExpertiseEnum;
  id: Scalars['String'];
  num: Scalars['bigint'];
  /** An object relationship */
  shopExpertise: EnumsShopExpertise;
  /** An object relationship */
  shopProfile: DbShopProfile;
  shopProfileId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.shop_profile_expertise" */
export type DbShopProfileExpertiseAggregate = {
  __typename?: 'DbShopProfileExpertiseAggregate';
  aggregate?: Maybe<DbShopProfileExpertiseAggregateFields>;
  nodes: Array<DbShopProfileExpertise>;
};

export type DbShopProfileExpertiseAggregateBoolExp = {
  bool_and?: InputMaybe<DbShopProfileExpertiseAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbShopProfileExpertiseAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbShopProfileExpertiseAggregateBoolExpCount>;
};

/** aggregate fields of "db.shop_profile_expertise" */
export type DbShopProfileExpertiseAggregateFields = {
  __typename?: 'DbShopProfileExpertiseAggregateFields';
  avg?: Maybe<DbShopProfileExpertiseAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbShopProfileExpertiseMaxFields>;
  min?: Maybe<DbShopProfileExpertiseMinFields>;
  stddev?: Maybe<DbShopProfileExpertiseStddevFields>;
  stddevPop?: Maybe<DbShopProfileExpertiseStddevPopFields>;
  stddevSamp?: Maybe<DbShopProfileExpertiseStddevSampFields>;
  sum?: Maybe<DbShopProfileExpertiseSumFields>;
  varPop?: Maybe<DbShopProfileExpertiseVarPopFields>;
  varSamp?: Maybe<DbShopProfileExpertiseVarSampFields>;
  variance?: Maybe<DbShopProfileExpertiseVarianceFields>;
};

/** aggregate fields of "db.shop_profile_expertise" */
export type DbShopProfileExpertiseAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbShopProfileExpertiseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.shop_profile_expertise" */
export type DbShopProfileExpertiseAggregateOrderBy = {
  avg?: InputMaybe<DbShopProfileExpertiseAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbShopProfileExpertiseMaxOrderBy>;
  min?: InputMaybe<DbShopProfileExpertiseMinOrderBy>;
  stddev?: InputMaybe<DbShopProfileExpertiseStddevOrderBy>;
  stddevPop?: InputMaybe<DbShopProfileExpertiseStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbShopProfileExpertiseStddevSampOrderBy>;
  sum?: InputMaybe<DbShopProfileExpertiseSumOrderBy>;
  varPop?: InputMaybe<DbShopProfileExpertiseVarPopOrderBy>;
  varSamp?: InputMaybe<DbShopProfileExpertiseVarSampOrderBy>;
  variance?: InputMaybe<DbShopProfileExpertiseVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbShopProfileExpertiseAvgFields = {
  __typename?: 'DbShopProfileExpertiseAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.shop_profile_expertise" */
export type DbShopProfileExpertiseAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.shop_profile_expertise". All fields are combined with a logical 'AND'. */
export type DbShopProfileExpertiseBoolExp = {
  _and?: InputMaybe<Array<DbShopProfileExpertiseBoolExp>>;
  _not?: InputMaybe<DbShopProfileExpertiseBoolExp>;
  _or?: InputMaybe<Array<DbShopProfileExpertiseBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  expertise?: InputMaybe<EnumsShopExpertiseEnumComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  shopExpertise?: InputMaybe<EnumsShopExpertiseBoolExp>;
  shopProfile?: InputMaybe<DbShopProfileBoolExp>;
  shopProfileId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbShopProfileExpertiseMaxFields = {
  __typename?: 'DbShopProfileExpertiseMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  shopProfileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.shop_profile_expertise" */
export type DbShopProfileExpertiseMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbShopProfileExpertiseMinFields = {
  __typename?: 'DbShopProfileExpertiseMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  shopProfileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.shop_profile_expertise" */
export type DbShopProfileExpertiseMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.shop_profile_expertise". */
export type DbShopProfileExpertiseOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  expertise?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopExpertise?: InputMaybe<EnumsShopExpertiseOrderBy>;
  shopProfile?: InputMaybe<DbShopProfileOrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_profile_expertise" */
export type DbShopProfileExpertiseSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'expertise'
  /** column name */
  | 'id'
  /** column name */
  | 'num'
  /** column name */
  | 'shopProfileId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbShopProfileExpertiseAggregateBoolExpBool_andArgumentsColumns" columns of table "db.shop_profile_expertise" */
export type DbShopProfileExpertiseSelectColumnDbShopProfileExpertiseAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbShopProfileExpertiseAggregateBoolExpBool_orArgumentsColumns" columns of table "db.shop_profile_expertise" */
export type DbShopProfileExpertiseSelectColumnDbShopProfileExpertiseAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbShopProfileExpertiseStddevFields = {
  __typename?: 'DbShopProfileExpertiseStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.shop_profile_expertise" */
export type DbShopProfileExpertiseStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbShopProfileExpertiseStddevPopFields = {
  __typename?: 'DbShopProfileExpertiseStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.shop_profile_expertise" */
export type DbShopProfileExpertiseStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbShopProfileExpertiseStddevSampFields = {
  __typename?: 'DbShopProfileExpertiseStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.shop_profile_expertise" */
export type DbShopProfileExpertiseStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_shop_profile_expertise" */
export type DbShopProfileExpertiseStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopProfileExpertiseStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopProfileExpertiseStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  expertise?: InputMaybe<ShopExpertiseEnum>;
  id?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  shopProfileId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbShopProfileExpertiseSumFields = {
  __typename?: 'DbShopProfileExpertiseSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.shop_profile_expertise" */
export type DbShopProfileExpertiseSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbShopProfileExpertiseVarPopFields = {
  __typename?: 'DbShopProfileExpertiseVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.shop_profile_expertise" */
export type DbShopProfileExpertiseVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbShopProfileExpertiseVarSampFields = {
  __typename?: 'DbShopProfileExpertiseVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.shop_profile_expertise" */
export type DbShopProfileExpertiseVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbShopProfileExpertiseVarianceFields = {
  __typename?: 'DbShopProfileExpertiseVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.shop_profile_expertise" */
export type DbShopProfileExpertiseVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.shop_profile_featured_agent" */
export type DbShopProfileFeaturedAgent = {
  __typename?: 'DbShopProfileFeaturedAgent';
  /** An object relationship */
  agent: DbAgent;
  agentId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  index: Scalars['Int'];
  lck: Scalars['Int'];
  num: Scalars['bigint'];
  /** An object relationship */
  shopProfile: DbShopProfile;
  shopProfileId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.shop_profile_featured_agent" */
export type DbShopProfileFeaturedAgentAggregateOrderBy = {
  avg?: InputMaybe<DbShopProfileFeaturedAgentAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbShopProfileFeaturedAgentMaxOrderBy>;
  min?: InputMaybe<DbShopProfileFeaturedAgentMinOrderBy>;
  stddev?: InputMaybe<DbShopProfileFeaturedAgentStddevOrderBy>;
  stddevPop?: InputMaybe<DbShopProfileFeaturedAgentStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbShopProfileFeaturedAgentStddevSampOrderBy>;
  sum?: InputMaybe<DbShopProfileFeaturedAgentSumOrderBy>;
  varPop?: InputMaybe<DbShopProfileFeaturedAgentVarPopOrderBy>;
  varSamp?: InputMaybe<DbShopProfileFeaturedAgentVarSampOrderBy>;
  variance?: InputMaybe<DbShopProfileFeaturedAgentVarianceOrderBy>;
};

/** order by avg() on columns of table "db.shop_profile_featured_agent" */
export type DbShopProfileFeaturedAgentAvgOrderBy = {
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.shop_profile_featured_agent". All fields are combined with a logical 'AND'. */
export type DbShopProfileFeaturedAgentBoolExp = {
  _and?: InputMaybe<Array<DbShopProfileFeaturedAgentBoolExp>>;
  _not?: InputMaybe<DbShopProfileFeaturedAgentBoolExp>;
  _or?: InputMaybe<Array<DbShopProfileFeaturedAgentBoolExp>>;
  agent?: InputMaybe<DbAgentBoolExp>;
  agentId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  index?: InputMaybe<IntComparisonExp>;
  lck?: InputMaybe<IntComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  shopProfile?: InputMaybe<DbShopProfileBoolExp>;
  shopProfileId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.shop_profile_featured_agent" */
export type DbShopProfileFeaturedAgentMaxOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.shop_profile_featured_agent" */
export type DbShopProfileFeaturedAgentMinOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.shop_profile_featured_agent". */
export type DbShopProfileFeaturedAgentOrderBy = {
  agent?: InputMaybe<DbAgentOrderBy>;
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfile?: InputMaybe<DbShopProfileOrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_profile_featured_agent" */
export type DbShopProfileFeaturedAgentSelectColumn =
  /** column name */
  | 'agentId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'index'
  /** column name */
  | 'lck'
  /** column name */
  | 'num'
  /** column name */
  | 'shopProfileId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.shop_profile_featured_agent" */
export type DbShopProfileFeaturedAgentStddevOrderBy = {
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.shop_profile_featured_agent" */
export type DbShopProfileFeaturedAgentStddevPopOrderBy = {
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.shop_profile_featured_agent" */
export type DbShopProfileFeaturedAgentStddevSampOrderBy = {
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_shop_profile_featured_agent" */
export type DbShopProfileFeaturedAgentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopProfileFeaturedAgentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopProfileFeaturedAgentStreamCursorValueInput = {
  agentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  index?: InputMaybe<Scalars['Int']>;
  lck?: InputMaybe<Scalars['Int']>;
  num?: InputMaybe<Scalars['bigint']>;
  shopProfileId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.shop_profile_featured_agent" */
export type DbShopProfileFeaturedAgentSumOrderBy = {
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.shop_profile_featured_agent" */
export type DbShopProfileFeaturedAgentVarPopOrderBy = {
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.shop_profile_featured_agent" */
export type DbShopProfileFeaturedAgentVarSampOrderBy = {
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.shop_profile_featured_agent" */
export type DbShopProfileFeaturedAgentVarianceOrderBy = {
  index?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.shop_profile_featured_content" */
export type DbShopProfileFeaturedContent = {
  __typename?: 'DbShopProfileFeaturedContent';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isAiTranslated?: Maybe<Scalars['Boolean']>;
  lang: LanguageEnum;
  num: Scalars['bigint'];
  /** An array relationship */
  shopProfileFeaturedContentsTeasers: Array<DbShopProfileFeaturedContentTeaser>;
  shopProfileId: Scalars['String'];
  /** An object relationship */
  shop_profile_featured_content_shop_profile: DbShopProfile;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** columns and relationships of "db.shop_profile_featured_content" */
export type DbShopProfileFeaturedContentShopProfileFeaturedContentsTeasersArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileFeaturedContentTeaserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileFeaturedContentTeaserOrderBy>>;
  where?: InputMaybe<DbShopProfileFeaturedContentTeaserBoolExp>;
};

/** order by aggregate values of table "db.shop_profile_featured_content" */
export type DbShopProfileFeaturedContentAggregateOrderBy = {
  avg?: InputMaybe<DbShopProfileFeaturedContentAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbShopProfileFeaturedContentMaxOrderBy>;
  min?: InputMaybe<DbShopProfileFeaturedContentMinOrderBy>;
  stddev?: InputMaybe<DbShopProfileFeaturedContentStddevOrderBy>;
  stddevPop?: InputMaybe<DbShopProfileFeaturedContentStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbShopProfileFeaturedContentStddevSampOrderBy>;
  sum?: InputMaybe<DbShopProfileFeaturedContentSumOrderBy>;
  varPop?: InputMaybe<DbShopProfileFeaturedContentVarPopOrderBy>;
  varSamp?: InputMaybe<DbShopProfileFeaturedContentVarSampOrderBy>;
  variance?: InputMaybe<DbShopProfileFeaturedContentVarianceOrderBy>;
};

/** order by avg() on columns of table "db.shop_profile_featured_content" */
export type DbShopProfileFeaturedContentAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.shop_profile_featured_content". All fields are combined with a logical 'AND'. */
export type DbShopProfileFeaturedContentBoolExp = {
  _and?: InputMaybe<Array<DbShopProfileFeaturedContentBoolExp>>;
  _not?: InputMaybe<DbShopProfileFeaturedContentBoolExp>;
  _or?: InputMaybe<Array<DbShopProfileFeaturedContentBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  headline?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  isAiTranslated?: InputMaybe<BooleanComparisonExp>;
  lang?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  shopProfileFeaturedContentsTeasers?: InputMaybe<DbShopProfileFeaturedContentTeaserBoolExp>;
  shopProfileId?: InputMaybe<StringComparisonExp>;
  shop_profile_featured_content_shop_profile?: InputMaybe<DbShopProfileBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.shop_profile_featured_content" */
export type DbShopProfileFeaturedContentMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  headline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.shop_profile_featured_content" */
export type DbShopProfileFeaturedContentMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  headline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.shop_profile_featured_content". */
export type DbShopProfileFeaturedContentOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  headline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isAiTranslated?: InputMaybe<OrderBy>;
  lang?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileFeaturedContentsTeasersAggregate?: InputMaybe<DbShopProfileFeaturedContentTeaserAggregateOrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  shop_profile_featured_content_shop_profile?: InputMaybe<DbShopProfileOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_profile_featured_content" */
export type DbShopProfileFeaturedContentSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'description'
  /** column name */
  | 'headline'
  /** column name */
  | 'id'
  /** column name */
  | 'isAiTranslated'
  /** column name */
  | 'lang'
  /** column name */
  | 'num'
  /** column name */
  | 'shopProfileId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.shop_profile_featured_content" */
export type DbShopProfileFeaturedContentStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.shop_profile_featured_content" */
export type DbShopProfileFeaturedContentStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.shop_profile_featured_content" */
export type DbShopProfileFeaturedContentStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_shop_profile_featured_content" */
export type DbShopProfileFeaturedContentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopProfileFeaturedContentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopProfileFeaturedContentStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isAiTranslated?: InputMaybe<Scalars['Boolean']>;
  lang?: InputMaybe<LanguageEnum>;
  num?: InputMaybe<Scalars['bigint']>;
  shopProfileId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.shop_profile_featured_content" */
export type DbShopProfileFeaturedContentSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.shop_profile_featured_content_teaser" */
export type DbShopProfileFeaturedContentTeaser = {
  __typename?: 'DbShopProfileFeaturedContentTeaser';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  index: Scalars['Int'];
  link?: Maybe<Scalars['String']>;
  num: Scalars['bigint'];
  /** An object relationship */
  shopProfileFeaturedContent: DbShopProfileFeaturedContent;
  shopProfileFeaturedContentId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.shop_profile_featured_content_teaser" */
export type DbShopProfileFeaturedContentTeaserAggregateOrderBy = {
  avg?: InputMaybe<DbShopProfileFeaturedContentTeaserAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbShopProfileFeaturedContentTeaserMaxOrderBy>;
  min?: InputMaybe<DbShopProfileFeaturedContentTeaserMinOrderBy>;
  stddev?: InputMaybe<DbShopProfileFeaturedContentTeaserStddevOrderBy>;
  stddevPop?: InputMaybe<DbShopProfileFeaturedContentTeaserStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbShopProfileFeaturedContentTeaserStddevSampOrderBy>;
  sum?: InputMaybe<DbShopProfileFeaturedContentTeaserSumOrderBy>;
  varPop?: InputMaybe<DbShopProfileFeaturedContentTeaserVarPopOrderBy>;
  varSamp?: InputMaybe<DbShopProfileFeaturedContentTeaserVarSampOrderBy>;
  variance?: InputMaybe<DbShopProfileFeaturedContentTeaserVarianceOrderBy>;
};

/** order by avg() on columns of table "db.shop_profile_featured_content_teaser" */
export type DbShopProfileFeaturedContentTeaserAvgOrderBy = {
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.shop_profile_featured_content_teaser". All fields are combined with a logical 'AND'. */
export type DbShopProfileFeaturedContentTeaserBoolExp = {
  _and?: InputMaybe<Array<DbShopProfileFeaturedContentTeaserBoolExp>>;
  _not?: InputMaybe<DbShopProfileFeaturedContentTeaserBoolExp>;
  _or?: InputMaybe<Array<DbShopProfileFeaturedContentTeaserBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  headline?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  index?: InputMaybe<IntComparisonExp>;
  link?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  shopProfileFeaturedContent?: InputMaybe<DbShopProfileFeaturedContentBoolExp>;
  shopProfileFeaturedContentId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.shop_profile_featured_content_teaser" */
export type DbShopProfileFeaturedContentTeaserMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  headline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  link?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileFeaturedContentId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.shop_profile_featured_content_teaser" */
export type DbShopProfileFeaturedContentTeaserMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  headline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  link?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileFeaturedContentId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.shop_profile_featured_content_teaser". */
export type DbShopProfileFeaturedContentTeaserOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  headline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  link?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileFeaturedContent?: InputMaybe<DbShopProfileFeaturedContentOrderBy>;
  shopProfileFeaturedContentId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_profile_featured_content_teaser" */
export type DbShopProfileFeaturedContentTeaserSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'description'
  /** column name */
  | 'headline'
  /** column name */
  | 'id'
  /** column name */
  | 'index'
  /** column name */
  | 'link'
  /** column name */
  | 'num'
  /** column name */
  | 'shopProfileFeaturedContentId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.shop_profile_featured_content_teaser" */
export type DbShopProfileFeaturedContentTeaserStddevOrderBy = {
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.shop_profile_featured_content_teaser" */
export type DbShopProfileFeaturedContentTeaserStddevPopOrderBy = {
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.shop_profile_featured_content_teaser" */
export type DbShopProfileFeaturedContentTeaserStddevSampOrderBy = {
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_shop_profile_featured_content_teaser" */
export type DbShopProfileFeaturedContentTeaserStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopProfileFeaturedContentTeaserStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopProfileFeaturedContentTeaserStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  index?: InputMaybe<Scalars['Int']>;
  link?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  shopProfileFeaturedContentId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.shop_profile_featured_content_teaser" */
export type DbShopProfileFeaturedContentTeaserSumOrderBy = {
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.shop_profile_featured_content_teaser" */
export type DbShopProfileFeaturedContentTeaserVarPopOrderBy = {
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.shop_profile_featured_content_teaser" */
export type DbShopProfileFeaturedContentTeaserVarSampOrderBy = {
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.shop_profile_featured_content_teaser" */
export type DbShopProfileFeaturedContentTeaserVarianceOrderBy = {
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.shop_profile_featured_content" */
export type DbShopProfileFeaturedContentVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.shop_profile_featured_content" */
export type DbShopProfileFeaturedContentVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.shop_profile_featured_content" */
export type DbShopProfileFeaturedContentVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.shop_profile_former_url" */
export type DbShopProfileFormerUrl = {
  __typename?: 'DbShopProfileFormerUrl';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  formerUrl: Scalars['String'];
  id: Scalars['String'];
  lang: LanguageEnum;
  propertyLoungeId?: Maybe<Scalars['String']>;
  shopId: Scalars['String'];
  /** An object relationship */
  shop_profile_former_url_agent: DbAgent;
  /** An object relationship */
  shop_profile_former_url_agent_created_by: DbAgent;
  /** An object relationship */
  shop_profile_former_url_language: EnumsLanguage;
  /** An object relationship */
  shop_profile_former_url_property_lounge?: Maybe<DbPropertyLounge>;
  /** An object relationship */
  shop_profile_former_url_shop: DbShop;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.shop_profile_former_url" */
export type DbShopProfileFormerUrlAggregate = {
  __typename?: 'DbShopProfileFormerUrlAggregate';
  aggregate?: Maybe<DbShopProfileFormerUrlAggregateFields>;
  nodes: Array<DbShopProfileFormerUrl>;
};

/** aggregate fields of "db.shop_profile_former_url" */
export type DbShopProfileFormerUrlAggregateFields = {
  __typename?: 'DbShopProfileFormerUrlAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<DbShopProfileFormerUrlMaxFields>;
  min?: Maybe<DbShopProfileFormerUrlMinFields>;
};

/** aggregate fields of "db.shop_profile_former_url" */
export type DbShopProfileFormerUrlAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbShopProfileFormerUrlSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "db.shop_profile_former_url". All fields are combined with a logical 'AND'. */
export type DbShopProfileFormerUrlBoolExp = {
  _and?: InputMaybe<Array<DbShopProfileFormerUrlBoolExp>>;
  _not?: InputMaybe<DbShopProfileFormerUrlBoolExp>;
  _or?: InputMaybe<Array<DbShopProfileFormerUrlBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  formerUrl?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lang?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  propertyLoungeId?: InputMaybe<StringComparisonExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  shop_profile_former_url_agent?: InputMaybe<DbAgentBoolExp>;
  shop_profile_former_url_agent_created_by?: InputMaybe<DbAgentBoolExp>;
  shop_profile_former_url_language?: InputMaybe<EnumsLanguageBoolExp>;
  shop_profile_former_url_property_lounge?: InputMaybe<DbPropertyLoungeBoolExp>;
  shop_profile_former_url_shop?: InputMaybe<DbShopBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbShopProfileFormerUrlMaxFields = {
  __typename?: 'DbShopProfileFormerUrlMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  formerUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  propertyLoungeId?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type DbShopProfileFormerUrlMinFields = {
  __typename?: 'DbShopProfileFormerUrlMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  formerUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  propertyLoungeId?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "db.shop_profile_former_url". */
export type DbShopProfileFormerUrlOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  formerUrl?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lang?: InputMaybe<OrderBy>;
  propertyLoungeId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  shop_profile_former_url_agent?: InputMaybe<DbAgentOrderBy>;
  shop_profile_former_url_agent_created_by?: InputMaybe<DbAgentOrderBy>;
  shop_profile_former_url_language?: InputMaybe<EnumsLanguageOrderBy>;
  shop_profile_former_url_property_lounge?: InputMaybe<DbPropertyLoungeOrderBy>;
  shop_profile_former_url_shop?: InputMaybe<DbShopOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_profile_former_url" */
export type DbShopProfileFormerUrlSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'formerUrl'
  /** column name */
  | 'id'
  /** column name */
  | 'lang'
  /** column name */
  | 'propertyLoungeId'
  /** column name */
  | 'shopId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** Streaming cursor of the table "db_shop_profile_former_url" */
export type DbShopProfileFormerUrlStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopProfileFormerUrlStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopProfileFormerUrlStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  formerUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<LanguageEnum>;
  propertyLoungeId?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "db.shop_profile_image" */
export type DbShopProfileImage = {
  __typename?: 'DbShopProfileImage';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  gcpFileName: Scalars['String'];
  height: Scalars['Int'];
  id: Scalars['String'];
  index: Scalars['Int'];
  num: Scalars['bigint'];
  /** An object relationship */
  shopProfile: DbShopProfile;
  shopProfileId: Scalars['String'];
  title: Scalars['String'];
  type: ShopProfileImageTypeEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  width: Scalars['Int'];
};

/** aggregated selection of "db.shop_profile_image" */
export type DbShopProfileImageAggregate = {
  __typename?: 'DbShopProfileImageAggregate';
  aggregate?: Maybe<DbShopProfileImageAggregateFields>;
  nodes: Array<DbShopProfileImage>;
};

export type DbShopProfileImageAggregateBoolExp = {
  bool_and?: InputMaybe<DbShopProfileImageAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbShopProfileImageAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbShopProfileImageAggregateBoolExpCount>;
};

/** aggregate fields of "db.shop_profile_image" */
export type DbShopProfileImageAggregateFields = {
  __typename?: 'DbShopProfileImageAggregateFields';
  avg?: Maybe<DbShopProfileImageAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbShopProfileImageMaxFields>;
  min?: Maybe<DbShopProfileImageMinFields>;
  stddev?: Maybe<DbShopProfileImageStddevFields>;
  stddevPop?: Maybe<DbShopProfileImageStddevPopFields>;
  stddevSamp?: Maybe<DbShopProfileImageStddevSampFields>;
  sum?: Maybe<DbShopProfileImageSumFields>;
  varPop?: Maybe<DbShopProfileImageVarPopFields>;
  varSamp?: Maybe<DbShopProfileImageVarSampFields>;
  variance?: Maybe<DbShopProfileImageVarianceFields>;
};

/** aggregate fields of "db.shop_profile_image" */
export type DbShopProfileImageAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbShopProfileImageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.shop_profile_image" */
export type DbShopProfileImageAggregateOrderBy = {
  avg?: InputMaybe<DbShopProfileImageAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbShopProfileImageMaxOrderBy>;
  min?: InputMaybe<DbShopProfileImageMinOrderBy>;
  stddev?: InputMaybe<DbShopProfileImageStddevOrderBy>;
  stddevPop?: InputMaybe<DbShopProfileImageStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbShopProfileImageStddevSampOrderBy>;
  sum?: InputMaybe<DbShopProfileImageSumOrderBy>;
  varPop?: InputMaybe<DbShopProfileImageVarPopOrderBy>;
  varSamp?: InputMaybe<DbShopProfileImageVarSampOrderBy>;
  variance?: InputMaybe<DbShopProfileImageVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbShopProfileImageAvgFields = {
  __typename?: 'DbShopProfileImageAvgFields';
  height?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.shop_profile_image" */
export type DbShopProfileImageAvgOrderBy = {
  height?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.shop_profile_image". All fields are combined with a logical 'AND'. */
export type DbShopProfileImageBoolExp = {
  _and?: InputMaybe<Array<DbShopProfileImageBoolExp>>;
  _not?: InputMaybe<DbShopProfileImageBoolExp>;
  _or?: InputMaybe<Array<DbShopProfileImageBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  gcpFileName?: InputMaybe<StringComparisonExp>;
  height?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  index?: InputMaybe<IntComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  shopProfile?: InputMaybe<DbShopProfileBoolExp>;
  shopProfileId?: InputMaybe<StringComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<EnumsShopProfileImageTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  width?: InputMaybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type DbShopProfileImageMaxFields = {
  __typename?: 'DbShopProfileImageMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  gcpFileName?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['bigint']>;
  shopProfileId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "db.shop_profile_image" */
export type DbShopProfileImageMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  gcpFileName?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbShopProfileImageMinFields = {
  __typename?: 'DbShopProfileImageMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  gcpFileName?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['bigint']>;
  shopProfileId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "db.shop_profile_image" */
export type DbShopProfileImageMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  gcpFileName?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.shop_profile_image". */
export type DbShopProfileImageOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  gcpFileName?: InputMaybe<OrderBy>;
  height?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfile?: InputMaybe<DbShopProfileOrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_profile_image" */
export type DbShopProfileImageSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'gcpFileName'
  /** column name */
  | 'height'
  /** column name */
  | 'id'
  /** column name */
  | 'index'
  /** column name */
  | 'num'
  /** column name */
  | 'shopProfileId'
  /** column name */
  | 'title'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy'
  /** column name */
  | 'width';

/** select "dbShopProfileImageAggregateBoolExpBool_andArgumentsColumns" columns of table "db.shop_profile_image" */
export type DbShopProfileImageSelectColumnDbShopProfileImageAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbShopProfileImageAggregateBoolExpBool_orArgumentsColumns" columns of table "db.shop_profile_image" */
export type DbShopProfileImageSelectColumnDbShopProfileImageAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbShopProfileImageStddevFields = {
  __typename?: 'DbShopProfileImageStddevFields';
  height?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.shop_profile_image" */
export type DbShopProfileImageStddevOrderBy = {
  height?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbShopProfileImageStddevPopFields = {
  __typename?: 'DbShopProfileImageStddevPopFields';
  height?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.shop_profile_image" */
export type DbShopProfileImageStddevPopOrderBy = {
  height?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbShopProfileImageStddevSampFields = {
  __typename?: 'DbShopProfileImageStddevSampFields';
  height?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.shop_profile_image" */
export type DbShopProfileImageStddevSampOrderBy = {
  height?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_shop_profile_image" */
export type DbShopProfileImageStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopProfileImageStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopProfileImageStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  gcpFileName?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  index?: InputMaybe<Scalars['Int']>;
  num?: InputMaybe<Scalars['bigint']>;
  shopProfileId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ShopProfileImageTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type DbShopProfileImageSumFields = {
  __typename?: 'DbShopProfileImageSumFields';
  height?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['bigint']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "db.shop_profile_image" */
export type DbShopProfileImageSumOrderBy = {
  height?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbShopProfileImageVarPopFields = {
  __typename?: 'DbShopProfileImageVarPopFields';
  height?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.shop_profile_image" */
export type DbShopProfileImageVarPopOrderBy = {
  height?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbShopProfileImageVarSampFields = {
  __typename?: 'DbShopProfileImageVarSampFields';
  height?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.shop_profile_image" */
export type DbShopProfileImageVarSampOrderBy = {
  height?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbShopProfileImageVarianceFields = {
  __typename?: 'DbShopProfileImageVarianceFields';
  height?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.shop_profile_image" */
export type DbShopProfileImageVarianceOrderBy = {
  height?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  width?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.shop_profile_imprint" */
export type DbShopProfileImprint = {
  __typename?: 'DbShopProfileImprint';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  imprint: Scalars['String'];
  isAiTranslated?: Maybe<Scalars['Boolean']>;
  lang: LanguageEnum;
  /** An object relationship */
  language: EnumsLanguage;
  num: Scalars['bigint'];
  /** An object relationship */
  shopProfile: DbShopProfile;
  shopProfileId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.shop_profile_imprint" */
export type DbShopProfileImprintAggregate = {
  __typename?: 'DbShopProfileImprintAggregate';
  aggregate?: Maybe<DbShopProfileImprintAggregateFields>;
  nodes: Array<DbShopProfileImprint>;
};

export type DbShopProfileImprintAggregateBoolExp = {
  bool_and?: InputMaybe<DbShopProfileImprintAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbShopProfileImprintAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbShopProfileImprintAggregateBoolExpCount>;
};

/** aggregate fields of "db.shop_profile_imprint" */
export type DbShopProfileImprintAggregateFields = {
  __typename?: 'DbShopProfileImprintAggregateFields';
  avg?: Maybe<DbShopProfileImprintAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbShopProfileImprintMaxFields>;
  min?: Maybe<DbShopProfileImprintMinFields>;
  stddev?: Maybe<DbShopProfileImprintStddevFields>;
  stddevPop?: Maybe<DbShopProfileImprintStddevPopFields>;
  stddevSamp?: Maybe<DbShopProfileImprintStddevSampFields>;
  sum?: Maybe<DbShopProfileImprintSumFields>;
  varPop?: Maybe<DbShopProfileImprintVarPopFields>;
  varSamp?: Maybe<DbShopProfileImprintVarSampFields>;
  variance?: Maybe<DbShopProfileImprintVarianceFields>;
};

/** aggregate fields of "db.shop_profile_imprint" */
export type DbShopProfileImprintAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbShopProfileImprintSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.shop_profile_imprint" */
export type DbShopProfileImprintAggregateOrderBy = {
  avg?: InputMaybe<DbShopProfileImprintAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbShopProfileImprintMaxOrderBy>;
  min?: InputMaybe<DbShopProfileImprintMinOrderBy>;
  stddev?: InputMaybe<DbShopProfileImprintStddevOrderBy>;
  stddevPop?: InputMaybe<DbShopProfileImprintStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbShopProfileImprintStddevSampOrderBy>;
  sum?: InputMaybe<DbShopProfileImprintSumOrderBy>;
  varPop?: InputMaybe<DbShopProfileImprintVarPopOrderBy>;
  varSamp?: InputMaybe<DbShopProfileImprintVarSampOrderBy>;
  variance?: InputMaybe<DbShopProfileImprintVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbShopProfileImprintAvgFields = {
  __typename?: 'DbShopProfileImprintAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.shop_profile_imprint" */
export type DbShopProfileImprintAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.shop_profile_imprint". All fields are combined with a logical 'AND'. */
export type DbShopProfileImprintBoolExp = {
  _and?: InputMaybe<Array<DbShopProfileImprintBoolExp>>;
  _not?: InputMaybe<DbShopProfileImprintBoolExp>;
  _or?: InputMaybe<Array<DbShopProfileImprintBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  imprint?: InputMaybe<StringComparisonExp>;
  isAiTranslated?: InputMaybe<BooleanComparisonExp>;
  lang?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  language?: InputMaybe<EnumsLanguageBoolExp>;
  num?: InputMaybe<BigintComparisonExp>;
  shopProfile?: InputMaybe<DbShopProfileBoolExp>;
  shopProfileId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbShopProfileImprintMaxFields = {
  __typename?: 'DbShopProfileImprintMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  imprint?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  shopProfileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.shop_profile_imprint" */
export type DbShopProfileImprintMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imprint?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbShopProfileImprintMinFields = {
  __typename?: 'DbShopProfileImprintMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  imprint?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  shopProfileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.shop_profile_imprint" */
export type DbShopProfileImprintMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imprint?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.shop_profile_imprint". */
export type DbShopProfileImprintOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imprint?: InputMaybe<OrderBy>;
  isAiTranslated?: InputMaybe<OrderBy>;
  lang?: InputMaybe<OrderBy>;
  language?: InputMaybe<EnumsLanguageOrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfile?: InputMaybe<DbShopProfileOrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_profile_imprint" */
export type DbShopProfileImprintSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'imprint'
  /** column name */
  | 'isAiTranslated'
  /** column name */
  | 'lang'
  /** column name */
  | 'num'
  /** column name */
  | 'shopProfileId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbShopProfileImprintAggregateBoolExpBool_andArgumentsColumns" columns of table "db.shop_profile_imprint" */
export type DbShopProfileImprintSelectColumnDbShopProfileImprintAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  | 'deleted'
  /** column name */
  | 'isAiTranslated';

/** select "dbShopProfileImprintAggregateBoolExpBool_orArgumentsColumns" columns of table "db.shop_profile_imprint" */
export type DbShopProfileImprintSelectColumnDbShopProfileImprintAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  | 'deleted'
  /** column name */
  | 'isAiTranslated';

/** aggregate stddev on columns */
export type DbShopProfileImprintStddevFields = {
  __typename?: 'DbShopProfileImprintStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.shop_profile_imprint" */
export type DbShopProfileImprintStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbShopProfileImprintStddevPopFields = {
  __typename?: 'DbShopProfileImprintStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.shop_profile_imprint" */
export type DbShopProfileImprintStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbShopProfileImprintStddevSampFields = {
  __typename?: 'DbShopProfileImprintStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.shop_profile_imprint" */
export type DbShopProfileImprintStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_shop_profile_imprint" */
export type DbShopProfileImprintStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopProfileImprintStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopProfileImprintStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  imprint?: InputMaybe<Scalars['String']>;
  isAiTranslated?: InputMaybe<Scalars['Boolean']>;
  lang?: InputMaybe<LanguageEnum>;
  num?: InputMaybe<Scalars['bigint']>;
  shopProfileId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbShopProfileImprintSumFields = {
  __typename?: 'DbShopProfileImprintSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.shop_profile_imprint" */
export type DbShopProfileImprintSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbShopProfileImprintVarPopFields = {
  __typename?: 'DbShopProfileImprintVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.shop_profile_imprint" */
export type DbShopProfileImprintVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbShopProfileImprintVarSampFields = {
  __typename?: 'DbShopProfileImprintVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.shop_profile_imprint" */
export type DbShopProfileImprintVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbShopProfileImprintVarianceFields = {
  __typename?: 'DbShopProfileImprintVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.shop_profile_imprint" */
export type DbShopProfileImprintVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate max on columns */
export type DbShopProfileMaxFields = {
  __typename?: 'DbShopProfileMaxFields';
  clientId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  optin?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  propertyLoungeId?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['timestamp']>;
  publishedBy?: Maybe<Scalars['String']>;
  recipientId?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.shop_profile" */
export type DbShopProfileMaxOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  optin?: InputMaybe<OrderBy>;
  placeId?: InputMaybe<OrderBy>;
  propertyLoungeId?: InputMaybe<OrderBy>;
  published?: InputMaybe<OrderBy>;
  publishedBy?: InputMaybe<OrderBy>;
  recipientId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbShopProfileMinFields = {
  __typename?: 'DbShopProfileMinFields';
  clientId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  optin?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  propertyLoungeId?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['timestamp']>;
  publishedBy?: Maybe<Scalars['String']>;
  recipientId?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.shop_profile" */
export type DbShopProfileMinOrderBy = {
  clientId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  optin?: InputMaybe<OrderBy>;
  placeId?: InputMaybe<OrderBy>;
  propertyLoungeId?: InputMaybe<OrderBy>;
  published?: InputMaybe<OrderBy>;
  publishedBy?: InputMaybe<OrderBy>;
  recipientId?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.shop_profile". */
export type DbShopProfileOrderBy = {
  activated?: InputMaybe<OrderBy>;
  careersAggregate?: InputMaybe<DbShopProfileCareerAggregateOrderBy>;
  cityDistrictsAggregate?: InputMaybe<DbShopProfileCityDistrictAggregateOrderBy>;
  clientId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  customerMailsAggregate?: InputMaybe<DbShopProfileCustomerMailsAggregateOrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  descriptionsAggregate?: InputMaybe<DbShopProfileDescriptionAggregateOrderBy>;
  expertisesAggregate?: InputMaybe<DbShopProfileExpertiseAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  imagesAggregate?: InputMaybe<DbShopProfileImageAggregateOrderBy>;
  imprintsAggregate?: InputMaybe<DbShopProfileImprintAggregateOrderBy>;
  num?: InputMaybe<OrderBy>;
  optin?: InputMaybe<OrderBy>;
  placeId?: InputMaybe<OrderBy>;
  privacyPoliciesAggregate?: InputMaybe<DbShopProfilePrivacyPolicyAggregateOrderBy>;
  propertyLounge?: InputMaybe<DbPropertyLoungeOrderBy>;
  propertyLoungeId?: InputMaybe<OrderBy>;
  propertyPreviewsAggregate?: InputMaybe<DbShopProfilePropertyPreviewAggregateOrderBy>;
  published?: InputMaybe<OrderBy>;
  publishedBy?: InputMaybe<OrderBy>;
  quotesAggregate?: InputMaybe<DbShopProfileQuoteAggregateOrderBy>;
  recipientId?: InputMaybe<OrderBy>;
  seoTrackingsAggregate?: InputMaybe<DbShopProfileSeoTrackingAggregateOrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  shopProfileFeaturedAgentsAggregate?: InputMaybe<DbShopProfileFeaturedAgentAggregateOrderBy>;
  shopProfileFeaturedContentsAggregate?: InputMaybe<DbShopProfileFeaturedContentAggregateOrderBy>;
  socialMediaProfiles?: InputMaybe<DbShopProfileSocialMediaProfilesOrderBy>;
  spokenLanguagesAggregate?: InputMaybe<DbShopProfileSpokenLanguageAggregateOrderBy>;
  teamsAggregate?: InputMaybe<DbShopProfileTeamAggregateOrderBy>;
  termsAndConditionsAggregate?: InputMaybe<DbShopProfileTermsAndConditionsAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  withdrawalPoliciesAggregate?: InputMaybe<DbShopProfileWithdrawalPolicyAggregateOrderBy>;
};

/** columns and relationships of "db.shop_profile_privacy_policy" */
export type DbShopProfilePrivacyPolicy = {
  __typename?: 'DbShopProfilePrivacyPolicy';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  isAiTranslated?: Maybe<Scalars['Boolean']>;
  lang: LanguageEnum;
  /** An object relationship */
  language: EnumsLanguage;
  num: Scalars['bigint'];
  privacyPolicy: Scalars['String'];
  /** An object relationship */
  shopProfile: DbShopProfile;
  shopProfileId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.shop_profile_privacy_policy" */
export type DbShopProfilePrivacyPolicyAggregate = {
  __typename?: 'DbShopProfilePrivacyPolicyAggregate';
  aggregate?: Maybe<DbShopProfilePrivacyPolicyAggregateFields>;
  nodes: Array<DbShopProfilePrivacyPolicy>;
};

export type DbShopProfilePrivacyPolicyAggregateBoolExp = {
  bool_and?: InputMaybe<DbShopProfilePrivacyPolicyAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbShopProfilePrivacyPolicyAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbShopProfilePrivacyPolicyAggregateBoolExpCount>;
};

/** aggregate fields of "db.shop_profile_privacy_policy" */
export type DbShopProfilePrivacyPolicyAggregateFields = {
  __typename?: 'DbShopProfilePrivacyPolicyAggregateFields';
  avg?: Maybe<DbShopProfilePrivacyPolicyAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbShopProfilePrivacyPolicyMaxFields>;
  min?: Maybe<DbShopProfilePrivacyPolicyMinFields>;
  stddev?: Maybe<DbShopProfilePrivacyPolicyStddevFields>;
  stddevPop?: Maybe<DbShopProfilePrivacyPolicyStddevPopFields>;
  stddevSamp?: Maybe<DbShopProfilePrivacyPolicyStddevSampFields>;
  sum?: Maybe<DbShopProfilePrivacyPolicySumFields>;
  varPop?: Maybe<DbShopProfilePrivacyPolicyVarPopFields>;
  varSamp?: Maybe<DbShopProfilePrivacyPolicyVarSampFields>;
  variance?: Maybe<DbShopProfilePrivacyPolicyVarianceFields>;
};

/** aggregate fields of "db.shop_profile_privacy_policy" */
export type DbShopProfilePrivacyPolicyAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbShopProfilePrivacyPolicySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.shop_profile_privacy_policy" */
export type DbShopProfilePrivacyPolicyAggregateOrderBy = {
  avg?: InputMaybe<DbShopProfilePrivacyPolicyAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbShopProfilePrivacyPolicyMaxOrderBy>;
  min?: InputMaybe<DbShopProfilePrivacyPolicyMinOrderBy>;
  stddev?: InputMaybe<DbShopProfilePrivacyPolicyStddevOrderBy>;
  stddevPop?: InputMaybe<DbShopProfilePrivacyPolicyStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbShopProfilePrivacyPolicyStddevSampOrderBy>;
  sum?: InputMaybe<DbShopProfilePrivacyPolicySumOrderBy>;
  varPop?: InputMaybe<DbShopProfilePrivacyPolicyVarPopOrderBy>;
  varSamp?: InputMaybe<DbShopProfilePrivacyPolicyVarSampOrderBy>;
  variance?: InputMaybe<DbShopProfilePrivacyPolicyVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbShopProfilePrivacyPolicyAvgFields = {
  __typename?: 'DbShopProfilePrivacyPolicyAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.shop_profile_privacy_policy" */
export type DbShopProfilePrivacyPolicyAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.shop_profile_privacy_policy". All fields are combined with a logical 'AND'. */
export type DbShopProfilePrivacyPolicyBoolExp = {
  _and?: InputMaybe<Array<DbShopProfilePrivacyPolicyBoolExp>>;
  _not?: InputMaybe<DbShopProfilePrivacyPolicyBoolExp>;
  _or?: InputMaybe<Array<DbShopProfilePrivacyPolicyBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  isAiTranslated?: InputMaybe<BooleanComparisonExp>;
  lang?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  language?: InputMaybe<EnumsLanguageBoolExp>;
  num?: InputMaybe<BigintComparisonExp>;
  privacyPolicy?: InputMaybe<StringComparisonExp>;
  shopProfile?: InputMaybe<DbShopProfileBoolExp>;
  shopProfileId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbShopProfilePrivacyPolicyMaxFields = {
  __typename?: 'DbShopProfilePrivacyPolicyMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  privacyPolicy?: Maybe<Scalars['String']>;
  shopProfileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.shop_profile_privacy_policy" */
export type DbShopProfilePrivacyPolicyMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  privacyPolicy?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbShopProfilePrivacyPolicyMinFields = {
  __typename?: 'DbShopProfilePrivacyPolicyMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  privacyPolicy?: Maybe<Scalars['String']>;
  shopProfileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.shop_profile_privacy_policy" */
export type DbShopProfilePrivacyPolicyMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  privacyPolicy?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.shop_profile_privacy_policy". */
export type DbShopProfilePrivacyPolicyOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isAiTranslated?: InputMaybe<OrderBy>;
  lang?: InputMaybe<OrderBy>;
  language?: InputMaybe<EnumsLanguageOrderBy>;
  num?: InputMaybe<OrderBy>;
  privacyPolicy?: InputMaybe<OrderBy>;
  shopProfile?: InputMaybe<DbShopProfileOrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_profile_privacy_policy" */
export type DbShopProfilePrivacyPolicySelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'isAiTranslated'
  /** column name */
  | 'lang'
  /** column name */
  | 'num'
  /** column name */
  | 'privacyPolicy'
  /** column name */
  | 'shopProfileId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbShopProfilePrivacyPolicyAggregateBoolExpBool_andArgumentsColumns" columns of table "db.shop_profile_privacy_policy" */
export type DbShopProfilePrivacyPolicySelectColumnDbShopProfilePrivacyPolicyAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  | 'deleted'
  /** column name */
  | 'isAiTranslated';

/** select "dbShopProfilePrivacyPolicyAggregateBoolExpBool_orArgumentsColumns" columns of table "db.shop_profile_privacy_policy" */
export type DbShopProfilePrivacyPolicySelectColumnDbShopProfilePrivacyPolicyAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  | 'deleted'
  /** column name */
  | 'isAiTranslated';

/** aggregate stddev on columns */
export type DbShopProfilePrivacyPolicyStddevFields = {
  __typename?: 'DbShopProfilePrivacyPolicyStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.shop_profile_privacy_policy" */
export type DbShopProfilePrivacyPolicyStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbShopProfilePrivacyPolicyStddevPopFields = {
  __typename?: 'DbShopProfilePrivacyPolicyStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.shop_profile_privacy_policy" */
export type DbShopProfilePrivacyPolicyStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbShopProfilePrivacyPolicyStddevSampFields = {
  __typename?: 'DbShopProfilePrivacyPolicyStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.shop_profile_privacy_policy" */
export type DbShopProfilePrivacyPolicyStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_shop_profile_privacy_policy" */
export type DbShopProfilePrivacyPolicyStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopProfilePrivacyPolicyStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopProfilePrivacyPolicyStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  isAiTranslated?: InputMaybe<Scalars['Boolean']>;
  lang?: InputMaybe<LanguageEnum>;
  num?: InputMaybe<Scalars['bigint']>;
  privacyPolicy?: InputMaybe<Scalars['String']>;
  shopProfileId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbShopProfilePrivacyPolicySumFields = {
  __typename?: 'DbShopProfilePrivacyPolicySumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.shop_profile_privacy_policy" */
export type DbShopProfilePrivacyPolicySumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbShopProfilePrivacyPolicyVarPopFields = {
  __typename?: 'DbShopProfilePrivacyPolicyVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.shop_profile_privacy_policy" */
export type DbShopProfilePrivacyPolicyVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbShopProfilePrivacyPolicyVarSampFields = {
  __typename?: 'DbShopProfilePrivacyPolicyVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.shop_profile_privacy_policy" */
export type DbShopProfilePrivacyPolicyVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbShopProfilePrivacyPolicyVarianceFields = {
  __typename?: 'DbShopProfilePrivacyPolicyVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.shop_profile_privacy_policy" */
export type DbShopProfilePrivacyPolicyVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.shop_profile_property_preview" */
export type DbShopProfilePropertyPreview = {
  __typename?: 'DbShopProfilePropertyPreview';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  index: Scalars['Int'];
  shopProfileId: Scalars['String'];
  type: MarketingTypeEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  utag: Scalars['String'];
};

/** aggregated selection of "db.shop_profile_property_preview" */
export type DbShopProfilePropertyPreviewAggregate = {
  __typename?: 'DbShopProfilePropertyPreviewAggregate';
  aggregate?: Maybe<DbShopProfilePropertyPreviewAggregateFields>;
  nodes: Array<DbShopProfilePropertyPreview>;
};

export type DbShopProfilePropertyPreviewAggregateBoolExp = {
  bool_and?: InputMaybe<DbShopProfilePropertyPreviewAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbShopProfilePropertyPreviewAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbShopProfilePropertyPreviewAggregateBoolExpCount>;
};

/** aggregate fields of "db.shop_profile_property_preview" */
export type DbShopProfilePropertyPreviewAggregateFields = {
  __typename?: 'DbShopProfilePropertyPreviewAggregateFields';
  avg?: Maybe<DbShopProfilePropertyPreviewAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbShopProfilePropertyPreviewMaxFields>;
  min?: Maybe<DbShopProfilePropertyPreviewMinFields>;
  stddev?: Maybe<DbShopProfilePropertyPreviewStddevFields>;
  stddevPop?: Maybe<DbShopProfilePropertyPreviewStddevPopFields>;
  stddevSamp?: Maybe<DbShopProfilePropertyPreviewStddevSampFields>;
  sum?: Maybe<DbShopProfilePropertyPreviewSumFields>;
  varPop?: Maybe<DbShopProfilePropertyPreviewVarPopFields>;
  varSamp?: Maybe<DbShopProfilePropertyPreviewVarSampFields>;
  variance?: Maybe<DbShopProfilePropertyPreviewVarianceFields>;
};

/** aggregate fields of "db.shop_profile_property_preview" */
export type DbShopProfilePropertyPreviewAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbShopProfilePropertyPreviewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.shop_profile_property_preview" */
export type DbShopProfilePropertyPreviewAggregateOrderBy = {
  avg?: InputMaybe<DbShopProfilePropertyPreviewAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbShopProfilePropertyPreviewMaxOrderBy>;
  min?: InputMaybe<DbShopProfilePropertyPreviewMinOrderBy>;
  stddev?: InputMaybe<DbShopProfilePropertyPreviewStddevOrderBy>;
  stddevPop?: InputMaybe<DbShopProfilePropertyPreviewStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbShopProfilePropertyPreviewStddevSampOrderBy>;
  sum?: InputMaybe<DbShopProfilePropertyPreviewSumOrderBy>;
  varPop?: InputMaybe<DbShopProfilePropertyPreviewVarPopOrderBy>;
  varSamp?: InputMaybe<DbShopProfilePropertyPreviewVarSampOrderBy>;
  variance?: InputMaybe<DbShopProfilePropertyPreviewVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbShopProfilePropertyPreviewAvgFields = {
  __typename?: 'DbShopProfilePropertyPreviewAvgFields';
  index?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.shop_profile_property_preview" */
export type DbShopProfilePropertyPreviewAvgOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.shop_profile_property_preview". All fields are combined with a logical 'AND'. */
export type DbShopProfilePropertyPreviewBoolExp = {
  _and?: InputMaybe<Array<DbShopProfilePropertyPreviewBoolExp>>;
  _not?: InputMaybe<DbShopProfilePropertyPreviewBoolExp>;
  _or?: InputMaybe<Array<DbShopProfilePropertyPreviewBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  index?: InputMaybe<IntComparisonExp>;
  shopProfileId?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<EnumsMarketingTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  utag?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbShopProfilePropertyPreviewMaxFields = {
  __typename?: 'DbShopProfilePropertyPreviewMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  shopProfileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  utag?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.shop_profile_property_preview" */
export type DbShopProfilePropertyPreviewMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  utag?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbShopProfilePropertyPreviewMinFields = {
  __typename?: 'DbShopProfilePropertyPreviewMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  shopProfileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  utag?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.shop_profile_property_preview" */
export type DbShopProfilePropertyPreviewMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  utag?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.shop_profile_property_preview". */
export type DbShopProfilePropertyPreviewOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  index?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  utag?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_profile_property_preview" */
export type DbShopProfilePropertyPreviewSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'index'
  /** column name */
  | 'shopProfileId'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy'
  /** column name */
  | 'utag';

/** select "dbShopProfilePropertyPreviewAggregateBoolExpBool_andArgumentsColumns" columns of table "db.shop_profile_property_preview" */
export type DbShopProfilePropertyPreviewSelectColumnDbShopProfilePropertyPreviewAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbShopProfilePropertyPreviewAggregateBoolExpBool_orArgumentsColumns" columns of table "db.shop_profile_property_preview" */
export type DbShopProfilePropertyPreviewSelectColumnDbShopProfilePropertyPreviewAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbShopProfilePropertyPreviewStddevFields = {
  __typename?: 'DbShopProfilePropertyPreviewStddevFields';
  index?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.shop_profile_property_preview" */
export type DbShopProfilePropertyPreviewStddevOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbShopProfilePropertyPreviewStddevPopFields = {
  __typename?: 'DbShopProfilePropertyPreviewStddevPopFields';
  index?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.shop_profile_property_preview" */
export type DbShopProfilePropertyPreviewStddevPopOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbShopProfilePropertyPreviewStddevSampFields = {
  __typename?: 'DbShopProfilePropertyPreviewStddevSampFields';
  index?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.shop_profile_property_preview" */
export type DbShopProfilePropertyPreviewStddevSampOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_shop_profile_property_preview" */
export type DbShopProfilePropertyPreviewStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopProfilePropertyPreviewStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopProfilePropertyPreviewStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  index?: InputMaybe<Scalars['Int']>;
  shopProfileId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<MarketingTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  utag?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbShopProfilePropertyPreviewSumFields = {
  __typename?: 'DbShopProfilePropertyPreviewSumFields';
  index?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "db.shop_profile_property_preview" */
export type DbShopProfilePropertyPreviewSumOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbShopProfilePropertyPreviewVarPopFields = {
  __typename?: 'DbShopProfilePropertyPreviewVarPopFields';
  index?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.shop_profile_property_preview" */
export type DbShopProfilePropertyPreviewVarPopOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbShopProfilePropertyPreviewVarSampFields = {
  __typename?: 'DbShopProfilePropertyPreviewVarSampFields';
  index?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.shop_profile_property_preview" */
export type DbShopProfilePropertyPreviewVarSampOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbShopProfilePropertyPreviewVarianceFields = {
  __typename?: 'DbShopProfilePropertyPreviewVarianceFields';
  index?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.shop_profile_property_preview" */
export type DbShopProfilePropertyPreviewVarianceOrderBy = {
  index?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.shop_profile_quote" */
export type DbShopProfileQuote = {
  __typename?: 'DbShopProfileQuote';
  authorName: Scalars['String'];
  authorTitle?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  isAiTranslated?: Maybe<Scalars['Boolean']>;
  lang: LanguageEnum;
  /** An object relationship */
  language: EnumsLanguage;
  num: Scalars['bigint'];
  quote: Scalars['String'];
  /** An object relationship */
  shopProfile: DbShopProfile;
  shopProfileId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.shop_profile_quote" */
export type DbShopProfileQuoteAggregate = {
  __typename?: 'DbShopProfileQuoteAggregate';
  aggregate?: Maybe<DbShopProfileQuoteAggregateFields>;
  nodes: Array<DbShopProfileQuote>;
};

export type DbShopProfileQuoteAggregateBoolExp = {
  bool_and?: InputMaybe<DbShopProfileQuoteAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbShopProfileQuoteAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbShopProfileQuoteAggregateBoolExpCount>;
};

/** aggregate fields of "db.shop_profile_quote" */
export type DbShopProfileQuoteAggregateFields = {
  __typename?: 'DbShopProfileQuoteAggregateFields';
  avg?: Maybe<DbShopProfileQuoteAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbShopProfileQuoteMaxFields>;
  min?: Maybe<DbShopProfileQuoteMinFields>;
  stddev?: Maybe<DbShopProfileQuoteStddevFields>;
  stddevPop?: Maybe<DbShopProfileQuoteStddevPopFields>;
  stddevSamp?: Maybe<DbShopProfileQuoteStddevSampFields>;
  sum?: Maybe<DbShopProfileQuoteSumFields>;
  varPop?: Maybe<DbShopProfileQuoteVarPopFields>;
  varSamp?: Maybe<DbShopProfileQuoteVarSampFields>;
  variance?: Maybe<DbShopProfileQuoteVarianceFields>;
};

/** aggregate fields of "db.shop_profile_quote" */
export type DbShopProfileQuoteAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbShopProfileQuoteSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.shop_profile_quote" */
export type DbShopProfileQuoteAggregateOrderBy = {
  avg?: InputMaybe<DbShopProfileQuoteAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbShopProfileQuoteMaxOrderBy>;
  min?: InputMaybe<DbShopProfileQuoteMinOrderBy>;
  stddev?: InputMaybe<DbShopProfileQuoteStddevOrderBy>;
  stddevPop?: InputMaybe<DbShopProfileQuoteStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbShopProfileQuoteStddevSampOrderBy>;
  sum?: InputMaybe<DbShopProfileQuoteSumOrderBy>;
  varPop?: InputMaybe<DbShopProfileQuoteVarPopOrderBy>;
  varSamp?: InputMaybe<DbShopProfileQuoteVarSampOrderBy>;
  variance?: InputMaybe<DbShopProfileQuoteVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbShopProfileQuoteAvgFields = {
  __typename?: 'DbShopProfileQuoteAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.shop_profile_quote" */
export type DbShopProfileQuoteAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.shop_profile_quote". All fields are combined with a logical 'AND'. */
export type DbShopProfileQuoteBoolExp = {
  _and?: InputMaybe<Array<DbShopProfileQuoteBoolExp>>;
  _not?: InputMaybe<DbShopProfileQuoteBoolExp>;
  _or?: InputMaybe<Array<DbShopProfileQuoteBoolExp>>;
  authorName?: InputMaybe<StringComparisonExp>;
  authorTitle?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  isAiTranslated?: InputMaybe<BooleanComparisonExp>;
  lang?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  language?: InputMaybe<EnumsLanguageBoolExp>;
  num?: InputMaybe<BigintComparisonExp>;
  quote?: InputMaybe<StringComparisonExp>;
  shopProfile?: InputMaybe<DbShopProfileBoolExp>;
  shopProfileId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbShopProfileQuoteMaxFields = {
  __typename?: 'DbShopProfileQuoteMaxFields';
  authorName?: Maybe<Scalars['String']>;
  authorTitle?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  quote?: Maybe<Scalars['String']>;
  shopProfileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.shop_profile_quote" */
export type DbShopProfileQuoteMaxOrderBy = {
  authorName?: InputMaybe<OrderBy>;
  authorTitle?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  quote?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbShopProfileQuoteMinFields = {
  __typename?: 'DbShopProfileQuoteMinFields';
  authorName?: Maybe<Scalars['String']>;
  authorTitle?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  quote?: Maybe<Scalars['String']>;
  shopProfileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.shop_profile_quote" */
export type DbShopProfileQuoteMinOrderBy = {
  authorName?: InputMaybe<OrderBy>;
  authorTitle?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  quote?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.shop_profile_quote". */
export type DbShopProfileQuoteOrderBy = {
  authorName?: InputMaybe<OrderBy>;
  authorTitle?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isAiTranslated?: InputMaybe<OrderBy>;
  lang?: InputMaybe<OrderBy>;
  language?: InputMaybe<EnumsLanguageOrderBy>;
  num?: InputMaybe<OrderBy>;
  quote?: InputMaybe<OrderBy>;
  shopProfile?: InputMaybe<DbShopProfileOrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_profile_quote" */
export type DbShopProfileQuoteSelectColumn =
  /** column name */
  | 'authorName'
  /** column name */
  | 'authorTitle'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'isAiTranslated'
  /** column name */
  | 'lang'
  /** column name */
  | 'num'
  /** column name */
  | 'quote'
  /** column name */
  | 'shopProfileId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbShopProfileQuoteAggregateBoolExpBool_andArgumentsColumns" columns of table "db.shop_profile_quote" */
export type DbShopProfileQuoteSelectColumnDbShopProfileQuoteAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  | 'deleted'
  /** column name */
  | 'isAiTranslated';

/** select "dbShopProfileQuoteAggregateBoolExpBool_orArgumentsColumns" columns of table "db.shop_profile_quote" */
export type DbShopProfileQuoteSelectColumnDbShopProfileQuoteAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  | 'deleted'
  /** column name */
  | 'isAiTranslated';

/** aggregate stddev on columns */
export type DbShopProfileQuoteStddevFields = {
  __typename?: 'DbShopProfileQuoteStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.shop_profile_quote" */
export type DbShopProfileQuoteStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbShopProfileQuoteStddevPopFields = {
  __typename?: 'DbShopProfileQuoteStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.shop_profile_quote" */
export type DbShopProfileQuoteStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbShopProfileQuoteStddevSampFields = {
  __typename?: 'DbShopProfileQuoteStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.shop_profile_quote" */
export type DbShopProfileQuoteStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_shop_profile_quote" */
export type DbShopProfileQuoteStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopProfileQuoteStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopProfileQuoteStreamCursorValueInput = {
  authorName?: InputMaybe<Scalars['String']>;
  authorTitle?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  isAiTranslated?: InputMaybe<Scalars['Boolean']>;
  lang?: InputMaybe<LanguageEnum>;
  num?: InputMaybe<Scalars['bigint']>;
  quote?: InputMaybe<Scalars['String']>;
  shopProfileId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbShopProfileQuoteSumFields = {
  __typename?: 'DbShopProfileQuoteSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.shop_profile_quote" */
export type DbShopProfileQuoteSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbShopProfileQuoteVarPopFields = {
  __typename?: 'DbShopProfileQuoteVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.shop_profile_quote" */
export type DbShopProfileQuoteVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbShopProfileQuoteVarSampFields = {
  __typename?: 'DbShopProfileQuoteVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.shop_profile_quote" */
export type DbShopProfileQuoteVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbShopProfileQuoteVarianceFields = {
  __typename?: 'DbShopProfileQuoteVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.shop_profile_quote" */
export type DbShopProfileQuoteVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_profile" */
export type DbShopProfileSelectColumn =
  /** column name */
  | 'activated'
  /** column name */
  | 'clientId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'num'
  /** column name */
  | 'optin'
  /** column name */
  | 'placeId'
  /** column name */
  | 'propertyLoungeId'
  /** column name */
  | 'published'
  /** column name */
  | 'publishedBy'
  /** column name */
  | 'recipientId'
  /** column name */
  | 'shopId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbShopProfileAggregateBoolExpBool_andArgumentsColumns" columns of table "db.shop_profile" */
export type DbShopProfileSelectColumnDbShopProfileAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  | 'activated'
  /** column name */
  | 'deleted';

/** select "dbShopProfileAggregateBoolExpBool_orArgumentsColumns" columns of table "db.shop_profile" */
export type DbShopProfileSelectColumnDbShopProfileAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  | 'activated'
  /** column name */
  | 'deleted';

/** columns and relationships of "db.shop_profile_seo_tracking" */
export type DbShopProfileSeoTracking = {
  __typename?: 'DbShopProfileSeoTracking';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  criteoId?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  facebookId?: Maybe<Scalars['String']>;
  googleAdsId?: Maybe<Scalars['String']>;
  googleAnalyticsAccountId?: Maybe<Scalars['String']>;
  googlePlaceId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  imprintUrl?: Maybe<Scalars['String']>;
  linkedinId?: Maybe<Scalars['String']>;
  microsoftId?: Maybe<Scalars['String']>;
  num: Scalars['bigint'];
  privacyPolicyUrl?: Maybe<Scalars['String']>;
  /** An object relationship */
  shopProfile: DbShopProfile;
  shopProfileId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.shop_profile_seo_tracking" */
export type DbShopProfileSeoTrackingAggregateOrderBy = {
  avg?: InputMaybe<DbShopProfileSeoTrackingAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbShopProfileSeoTrackingMaxOrderBy>;
  min?: InputMaybe<DbShopProfileSeoTrackingMinOrderBy>;
  stddev?: InputMaybe<DbShopProfileSeoTrackingStddevOrderBy>;
  stddevPop?: InputMaybe<DbShopProfileSeoTrackingStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbShopProfileSeoTrackingStddevSampOrderBy>;
  sum?: InputMaybe<DbShopProfileSeoTrackingSumOrderBy>;
  varPop?: InputMaybe<DbShopProfileSeoTrackingVarPopOrderBy>;
  varSamp?: InputMaybe<DbShopProfileSeoTrackingVarSampOrderBy>;
  variance?: InputMaybe<DbShopProfileSeoTrackingVarianceOrderBy>;
};

/** order by avg() on columns of table "db.shop_profile_seo_tracking" */
export type DbShopProfileSeoTrackingAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.shop_profile_seo_tracking". All fields are combined with a logical 'AND'. */
export type DbShopProfileSeoTrackingBoolExp = {
  _and?: InputMaybe<Array<DbShopProfileSeoTrackingBoolExp>>;
  _not?: InputMaybe<DbShopProfileSeoTrackingBoolExp>;
  _or?: InputMaybe<Array<DbShopProfileSeoTrackingBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  criteoId?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  facebookId?: InputMaybe<StringComparisonExp>;
  googleAdsId?: InputMaybe<StringComparisonExp>;
  googleAnalyticsAccountId?: InputMaybe<StringComparisonExp>;
  googlePlaceId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  imprintUrl?: InputMaybe<StringComparisonExp>;
  linkedinId?: InputMaybe<StringComparisonExp>;
  microsoftId?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  privacyPolicyUrl?: InputMaybe<StringComparisonExp>;
  shopProfile?: InputMaybe<DbShopProfileBoolExp>;
  shopProfileId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.shop_profile_seo_tracking" */
export type DbShopProfileSeoTrackingMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  criteoId?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  facebookId?: InputMaybe<OrderBy>;
  googleAdsId?: InputMaybe<OrderBy>;
  googleAnalyticsAccountId?: InputMaybe<OrderBy>;
  googlePlaceId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imprintUrl?: InputMaybe<OrderBy>;
  linkedinId?: InputMaybe<OrderBy>;
  microsoftId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  privacyPolicyUrl?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.shop_profile_seo_tracking" */
export type DbShopProfileSeoTrackingMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  criteoId?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  facebookId?: InputMaybe<OrderBy>;
  googleAdsId?: InputMaybe<OrderBy>;
  googleAnalyticsAccountId?: InputMaybe<OrderBy>;
  googlePlaceId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imprintUrl?: InputMaybe<OrderBy>;
  linkedinId?: InputMaybe<OrderBy>;
  microsoftId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  privacyPolicyUrl?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.shop_profile_seo_tracking". */
export type DbShopProfileSeoTrackingOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  criteoId?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  facebookId?: InputMaybe<OrderBy>;
  googleAdsId?: InputMaybe<OrderBy>;
  googleAnalyticsAccountId?: InputMaybe<OrderBy>;
  googlePlaceId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  imprintUrl?: InputMaybe<OrderBy>;
  linkedinId?: InputMaybe<OrderBy>;
  microsoftId?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  privacyPolicyUrl?: InputMaybe<OrderBy>;
  shopProfile?: InputMaybe<DbShopProfileOrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_profile_seo_tracking" */
export type DbShopProfileSeoTrackingSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'criteoId'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'facebookId'
  /** column name */
  | 'googleAdsId'
  /** column name */
  | 'googleAnalyticsAccountId'
  /** column name */
  | 'googlePlaceId'
  /** column name */
  | 'id'
  /** column name */
  | 'imprintUrl'
  /** column name */
  | 'linkedinId'
  /** column name */
  | 'microsoftId'
  /** column name */
  | 'num'
  /** column name */
  | 'privacyPolicyUrl'
  /** column name */
  | 'shopProfileId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.shop_profile_seo_tracking" */
export type DbShopProfileSeoTrackingStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.shop_profile_seo_tracking" */
export type DbShopProfileSeoTrackingStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.shop_profile_seo_tracking" */
export type DbShopProfileSeoTrackingStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_shop_profile_seo_tracking" */
export type DbShopProfileSeoTrackingStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopProfileSeoTrackingStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopProfileSeoTrackingStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  criteoId?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  facebookId?: InputMaybe<Scalars['String']>;
  googleAdsId?: InputMaybe<Scalars['String']>;
  googleAnalyticsAccountId?: InputMaybe<Scalars['String']>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  imprintUrl?: InputMaybe<Scalars['String']>;
  linkedinId?: InputMaybe<Scalars['String']>;
  microsoftId?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  privacyPolicyUrl?: InputMaybe<Scalars['String']>;
  shopProfileId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.shop_profile_seo_tracking" */
export type DbShopProfileSeoTrackingSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.shop_profile_seo_tracking" */
export type DbShopProfileSeoTrackingVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.shop_profile_seo_tracking" */
export type DbShopProfileSeoTrackingVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.shop_profile_seo_tracking" */
export type DbShopProfileSeoTrackingVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.shop_profile_social_media_profiles" */
export type DbShopProfileSocialMediaProfiles = {
  __typename?: 'DbShopProfileSocialMediaProfiles';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  facebookUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  instagramUrl?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  num: Scalars['bigint'];
  /** An object relationship */
  shopProfile: DbShopProfile;
  shopProfileId: Scalars['String'];
  tiktokUrl?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  xingUrl?: Maybe<Scalars['String']>;
  youtubeUrl?: Maybe<Scalars['String']>;
};

/** aggregated selection of "db.shop_profile_social_media_profiles" */
export type DbShopProfileSocialMediaProfilesAggregate = {
  __typename?: 'DbShopProfileSocialMediaProfilesAggregate';
  aggregate?: Maybe<DbShopProfileSocialMediaProfilesAggregateFields>;
  nodes: Array<DbShopProfileSocialMediaProfiles>;
};

/** aggregate fields of "db.shop_profile_social_media_profiles" */
export type DbShopProfileSocialMediaProfilesAggregateFields = {
  __typename?: 'DbShopProfileSocialMediaProfilesAggregateFields';
  avg?: Maybe<DbShopProfileSocialMediaProfilesAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbShopProfileSocialMediaProfilesMaxFields>;
  min?: Maybe<DbShopProfileSocialMediaProfilesMinFields>;
  stddev?: Maybe<DbShopProfileSocialMediaProfilesStddevFields>;
  stddevPop?: Maybe<DbShopProfileSocialMediaProfilesStddevPopFields>;
  stddevSamp?: Maybe<DbShopProfileSocialMediaProfilesStddevSampFields>;
  sum?: Maybe<DbShopProfileSocialMediaProfilesSumFields>;
  varPop?: Maybe<DbShopProfileSocialMediaProfilesVarPopFields>;
  varSamp?: Maybe<DbShopProfileSocialMediaProfilesVarSampFields>;
  variance?: Maybe<DbShopProfileSocialMediaProfilesVarianceFields>;
};

/** aggregate fields of "db.shop_profile_social_media_profiles" */
export type DbShopProfileSocialMediaProfilesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbShopProfileSocialMediaProfilesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type DbShopProfileSocialMediaProfilesAvgFields = {
  __typename?: 'DbShopProfileSocialMediaProfilesAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "db.shop_profile_social_media_profiles". All fields are combined with a logical 'AND'. */
export type DbShopProfileSocialMediaProfilesBoolExp = {
  _and?: InputMaybe<Array<DbShopProfileSocialMediaProfilesBoolExp>>;
  _not?: InputMaybe<DbShopProfileSocialMediaProfilesBoolExp>;
  _or?: InputMaybe<Array<DbShopProfileSocialMediaProfilesBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  facebookUrl?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  instagramUrl?: InputMaybe<StringComparisonExp>;
  linkedInUrl?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  shopProfile?: InputMaybe<DbShopProfileBoolExp>;
  shopProfileId?: InputMaybe<StringComparisonExp>;
  tiktokUrl?: InputMaybe<StringComparisonExp>;
  twitterUrl?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  xingUrl?: InputMaybe<StringComparisonExp>;
  youtubeUrl?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbShopProfileSocialMediaProfilesMaxFields = {
  __typename?: 'DbShopProfileSocialMediaProfilesMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  facebookUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instagramUrl?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  shopProfileId?: Maybe<Scalars['String']>;
  tiktokUrl?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  xingUrl?: Maybe<Scalars['String']>;
  youtubeUrl?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type DbShopProfileSocialMediaProfilesMinFields = {
  __typename?: 'DbShopProfileSocialMediaProfilesMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  facebookUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instagramUrl?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  shopProfileId?: Maybe<Scalars['String']>;
  tiktokUrl?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  xingUrl?: Maybe<Scalars['String']>;
  youtubeUrl?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "db.shop_profile_social_media_profiles". */
export type DbShopProfileSocialMediaProfilesOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  facebookUrl?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  instagramUrl?: InputMaybe<OrderBy>;
  linkedInUrl?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfile?: InputMaybe<DbShopProfileOrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  tiktokUrl?: InputMaybe<OrderBy>;
  twitterUrl?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  xingUrl?: InputMaybe<OrderBy>;
  youtubeUrl?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_profile_social_media_profiles" */
export type DbShopProfileSocialMediaProfilesSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'facebookUrl'
  /** column name */
  | 'id'
  /** column name */
  | 'instagramUrl'
  /** column name */
  | 'linkedInUrl'
  /** column name */
  | 'num'
  /** column name */
  | 'shopProfileId'
  /** column name */
  | 'tiktokUrl'
  /** column name */
  | 'twitterUrl'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy'
  /** column name */
  | 'xingUrl'
  /** column name */
  | 'youtubeUrl';

/** aggregate stddev on columns */
export type DbShopProfileSocialMediaProfilesStddevFields = {
  __typename?: 'DbShopProfileSocialMediaProfilesStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type DbShopProfileSocialMediaProfilesStddevPopFields = {
  __typename?: 'DbShopProfileSocialMediaProfilesStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type DbShopProfileSocialMediaProfilesStddevSampFields = {
  __typename?: 'DbShopProfileSocialMediaProfilesStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "db_shop_profile_social_media_profiles" */
export type DbShopProfileSocialMediaProfilesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopProfileSocialMediaProfilesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopProfileSocialMediaProfilesStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  facebookUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  instagramUrl?: InputMaybe<Scalars['String']>;
  linkedInUrl?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  shopProfileId?: InputMaybe<Scalars['String']>;
  tiktokUrl?: InputMaybe<Scalars['String']>;
  twitterUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  xingUrl?: InputMaybe<Scalars['String']>;
  youtubeUrl?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbShopProfileSocialMediaProfilesSumFields = {
  __typename?: 'DbShopProfileSocialMediaProfilesSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** aggregate varPop on columns */
export type DbShopProfileSocialMediaProfilesVarPopFields = {
  __typename?: 'DbShopProfileSocialMediaProfilesVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type DbShopProfileSocialMediaProfilesVarSampFields = {
  __typename?: 'DbShopProfileSocialMediaProfilesVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type DbShopProfileSocialMediaProfilesVarianceFields = {
  __typename?: 'DbShopProfileSocialMediaProfilesVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "db.shop_profile_spoken_language" */
export type DbShopProfileSpokenLanguage = {
  __typename?: 'DbShopProfileSpokenLanguage';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  /** An object relationship */
  language: EnumsSpokenLanguage;
  num: Scalars['bigint'];
  /** An object relationship */
  shopProfile: DbShopProfile;
  shopProfileId: Scalars['String'];
  spokenLanguage: SpokenLanguageEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.shop_profile_spoken_language" */
export type DbShopProfileSpokenLanguageAggregate = {
  __typename?: 'DbShopProfileSpokenLanguageAggregate';
  aggregate?: Maybe<DbShopProfileSpokenLanguageAggregateFields>;
  nodes: Array<DbShopProfileSpokenLanguage>;
};

export type DbShopProfileSpokenLanguageAggregateBoolExp = {
  bool_and?: InputMaybe<DbShopProfileSpokenLanguageAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbShopProfileSpokenLanguageAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbShopProfileSpokenLanguageAggregateBoolExpCount>;
};

/** aggregate fields of "db.shop_profile_spoken_language" */
export type DbShopProfileSpokenLanguageAggregateFields = {
  __typename?: 'DbShopProfileSpokenLanguageAggregateFields';
  avg?: Maybe<DbShopProfileSpokenLanguageAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbShopProfileSpokenLanguageMaxFields>;
  min?: Maybe<DbShopProfileSpokenLanguageMinFields>;
  stddev?: Maybe<DbShopProfileSpokenLanguageStddevFields>;
  stddevPop?: Maybe<DbShopProfileSpokenLanguageStddevPopFields>;
  stddevSamp?: Maybe<DbShopProfileSpokenLanguageStddevSampFields>;
  sum?: Maybe<DbShopProfileSpokenLanguageSumFields>;
  varPop?: Maybe<DbShopProfileSpokenLanguageVarPopFields>;
  varSamp?: Maybe<DbShopProfileSpokenLanguageVarSampFields>;
  variance?: Maybe<DbShopProfileSpokenLanguageVarianceFields>;
};

/** aggregate fields of "db.shop_profile_spoken_language" */
export type DbShopProfileSpokenLanguageAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbShopProfileSpokenLanguageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.shop_profile_spoken_language" */
export type DbShopProfileSpokenLanguageAggregateOrderBy = {
  avg?: InputMaybe<DbShopProfileSpokenLanguageAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbShopProfileSpokenLanguageMaxOrderBy>;
  min?: InputMaybe<DbShopProfileSpokenLanguageMinOrderBy>;
  stddev?: InputMaybe<DbShopProfileSpokenLanguageStddevOrderBy>;
  stddevPop?: InputMaybe<DbShopProfileSpokenLanguageStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbShopProfileSpokenLanguageStddevSampOrderBy>;
  sum?: InputMaybe<DbShopProfileSpokenLanguageSumOrderBy>;
  varPop?: InputMaybe<DbShopProfileSpokenLanguageVarPopOrderBy>;
  varSamp?: InputMaybe<DbShopProfileSpokenLanguageVarSampOrderBy>;
  variance?: InputMaybe<DbShopProfileSpokenLanguageVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbShopProfileSpokenLanguageAvgFields = {
  __typename?: 'DbShopProfileSpokenLanguageAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.shop_profile_spoken_language" */
export type DbShopProfileSpokenLanguageAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.shop_profile_spoken_language". All fields are combined with a logical 'AND'. */
export type DbShopProfileSpokenLanguageBoolExp = {
  _and?: InputMaybe<Array<DbShopProfileSpokenLanguageBoolExp>>;
  _not?: InputMaybe<DbShopProfileSpokenLanguageBoolExp>;
  _or?: InputMaybe<Array<DbShopProfileSpokenLanguageBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  language?: InputMaybe<EnumsSpokenLanguageBoolExp>;
  num?: InputMaybe<BigintComparisonExp>;
  shopProfile?: InputMaybe<DbShopProfileBoolExp>;
  shopProfileId?: InputMaybe<StringComparisonExp>;
  spokenLanguage?: InputMaybe<EnumsSpokenLanguageEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbShopProfileSpokenLanguageMaxFields = {
  __typename?: 'DbShopProfileSpokenLanguageMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  shopProfileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.shop_profile_spoken_language" */
export type DbShopProfileSpokenLanguageMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbShopProfileSpokenLanguageMinFields = {
  __typename?: 'DbShopProfileSpokenLanguageMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  shopProfileId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.shop_profile_spoken_language" */
export type DbShopProfileSpokenLanguageMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.shop_profile_spoken_language". */
export type DbShopProfileSpokenLanguageOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  language?: InputMaybe<EnumsSpokenLanguageOrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfile?: InputMaybe<DbShopProfileOrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  spokenLanguage?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_profile_spoken_language" */
export type DbShopProfileSpokenLanguageSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'num'
  /** column name */
  | 'shopProfileId'
  /** column name */
  | 'spokenLanguage'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbShopProfileSpokenLanguageAggregateBoolExpBool_andArgumentsColumns" columns of table "db.shop_profile_spoken_language" */
export type DbShopProfileSpokenLanguageSelectColumnDbShopProfileSpokenLanguageAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbShopProfileSpokenLanguageAggregateBoolExpBool_orArgumentsColumns" columns of table "db.shop_profile_spoken_language" */
export type DbShopProfileSpokenLanguageSelectColumnDbShopProfileSpokenLanguageAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbShopProfileSpokenLanguageStddevFields = {
  __typename?: 'DbShopProfileSpokenLanguageStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.shop_profile_spoken_language" */
export type DbShopProfileSpokenLanguageStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbShopProfileSpokenLanguageStddevPopFields = {
  __typename?: 'DbShopProfileSpokenLanguageStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.shop_profile_spoken_language" */
export type DbShopProfileSpokenLanguageStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbShopProfileSpokenLanguageStddevSampFields = {
  __typename?: 'DbShopProfileSpokenLanguageStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.shop_profile_spoken_language" */
export type DbShopProfileSpokenLanguageStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_shop_profile_spoken_language" */
export type DbShopProfileSpokenLanguageStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopProfileSpokenLanguageStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopProfileSpokenLanguageStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  shopProfileId?: InputMaybe<Scalars['String']>;
  spokenLanguage?: InputMaybe<SpokenLanguageEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbShopProfileSpokenLanguageSumFields = {
  __typename?: 'DbShopProfileSpokenLanguageSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.shop_profile_spoken_language" */
export type DbShopProfileSpokenLanguageSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbShopProfileSpokenLanguageVarPopFields = {
  __typename?: 'DbShopProfileSpokenLanguageVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.shop_profile_spoken_language" */
export type DbShopProfileSpokenLanguageVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbShopProfileSpokenLanguageVarSampFields = {
  __typename?: 'DbShopProfileSpokenLanguageVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.shop_profile_spoken_language" */
export type DbShopProfileSpokenLanguageVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbShopProfileSpokenLanguageVarianceFields = {
  __typename?: 'DbShopProfileSpokenLanguageVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.shop_profile_spoken_language" */
export type DbShopProfileSpokenLanguageVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddev on columns */
export type DbShopProfileStddevFields = {
  __typename?: 'DbShopProfileStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.shop_profile" */
export type DbShopProfileStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbShopProfileStddevPopFields = {
  __typename?: 'DbShopProfileStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.shop_profile" */
export type DbShopProfileStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbShopProfileStddevSampFields = {
  __typename?: 'DbShopProfileStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.shop_profile" */
export type DbShopProfileStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_shop_profile" */
export type DbShopProfileStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopProfileStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopProfileStreamCursorValueInput = {
  activated?: InputMaybe<Scalars['Boolean']>;
  clientId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  optin?: InputMaybe<Scalars['String']>;
  placeId?: InputMaybe<Scalars['String']>;
  propertyLoungeId?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['timestamp']>;
  publishedBy?: InputMaybe<Scalars['String']>;
  recipientId?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbShopProfileSumFields = {
  __typename?: 'DbShopProfileSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.shop_profile" */
export type DbShopProfileSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.shop_profile_team" */
export type DbShopProfileTeam = {
  __typename?: 'DbShopProfileTeam';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isAiTranslated: Scalars['Boolean'];
  lang: LanguageEnum;
  lck: Scalars['Int'];
  num: Scalars['bigint'];
  shopProfileId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.shop_profile_team" */
export type DbShopProfileTeamAggregateOrderBy = {
  avg?: InputMaybe<DbShopProfileTeamAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbShopProfileTeamMaxOrderBy>;
  min?: InputMaybe<DbShopProfileTeamMinOrderBy>;
  stddev?: InputMaybe<DbShopProfileTeamStddevOrderBy>;
  stddevPop?: InputMaybe<DbShopProfileTeamStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbShopProfileTeamStddevSampOrderBy>;
  sum?: InputMaybe<DbShopProfileTeamSumOrderBy>;
  varPop?: InputMaybe<DbShopProfileTeamVarPopOrderBy>;
  varSamp?: InputMaybe<DbShopProfileTeamVarSampOrderBy>;
  variance?: InputMaybe<DbShopProfileTeamVarianceOrderBy>;
};

/** order by avg() on columns of table "db.shop_profile_team" */
export type DbShopProfileTeamAvgOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.shop_profile_team". All fields are combined with a logical 'AND'. */
export type DbShopProfileTeamBoolExp = {
  _and?: InputMaybe<Array<DbShopProfileTeamBoolExp>>;
  _not?: InputMaybe<DbShopProfileTeamBoolExp>;
  _or?: InputMaybe<Array<DbShopProfileTeamBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  headline?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  isAiTranslated?: InputMaybe<BooleanComparisonExp>;
  lang?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  lck?: InputMaybe<IntComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  shopProfileId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.shop_profile_team" */
export type DbShopProfileTeamMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  headline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.shop_profile_team" */
export type DbShopProfileTeamMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  headline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.shop_profile_team". */
export type DbShopProfileTeamOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  headline?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isAiTranslated?: InputMaybe<OrderBy>;
  lang?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_profile_team" */
export type DbShopProfileTeamSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'description'
  /** column name */
  | 'headline'
  /** column name */
  | 'id'
  /** column name */
  | 'isAiTranslated'
  /** column name */
  | 'lang'
  /** column name */
  | 'lck'
  /** column name */
  | 'num'
  /** column name */
  | 'shopProfileId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.shop_profile_team" */
export type DbShopProfileTeamStddevOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.shop_profile_team" */
export type DbShopProfileTeamStddevPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.shop_profile_team" */
export type DbShopProfileTeamStddevSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_shop_profile_team" */
export type DbShopProfileTeamStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopProfileTeamStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopProfileTeamStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isAiTranslated?: InputMaybe<Scalars['Boolean']>;
  lang?: InputMaybe<LanguageEnum>;
  lck?: InputMaybe<Scalars['Int']>;
  num?: InputMaybe<Scalars['bigint']>;
  shopProfileId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.shop_profile_team" */
export type DbShopProfileTeamSumOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.shop_profile_team" */
export type DbShopProfileTeamVarPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.shop_profile_team" */
export type DbShopProfileTeamVarSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.shop_profile_team" */
export type DbShopProfileTeamVarianceOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.shop_profile_terms_and_conditions" */
export type DbShopProfileTermsAndConditions = {
  __typename?: 'DbShopProfileTermsAndConditions';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  lang: LanguageEnum;
  lck: Scalars['Int'];
  num: Scalars['bigint'];
  shopProfileId: Scalars['String'];
  termsAndConditions: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.shop_profile_terms_and_conditions" */
export type DbShopProfileTermsAndConditionsAggregateOrderBy = {
  avg?: InputMaybe<DbShopProfileTermsAndConditionsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbShopProfileTermsAndConditionsMaxOrderBy>;
  min?: InputMaybe<DbShopProfileTermsAndConditionsMinOrderBy>;
  stddev?: InputMaybe<DbShopProfileTermsAndConditionsStddevOrderBy>;
  stddevPop?: InputMaybe<DbShopProfileTermsAndConditionsStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbShopProfileTermsAndConditionsStddevSampOrderBy>;
  sum?: InputMaybe<DbShopProfileTermsAndConditionsSumOrderBy>;
  varPop?: InputMaybe<DbShopProfileTermsAndConditionsVarPopOrderBy>;
  varSamp?: InputMaybe<DbShopProfileTermsAndConditionsVarSampOrderBy>;
  variance?: InputMaybe<DbShopProfileTermsAndConditionsVarianceOrderBy>;
};

/** order by avg() on columns of table "db.shop_profile_terms_and_conditions" */
export type DbShopProfileTermsAndConditionsAvgOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.shop_profile_terms_and_conditions". All fields are combined with a logical 'AND'. */
export type DbShopProfileTermsAndConditionsBoolExp = {
  _and?: InputMaybe<Array<DbShopProfileTermsAndConditionsBoolExp>>;
  _not?: InputMaybe<DbShopProfileTermsAndConditionsBoolExp>;
  _or?: InputMaybe<Array<DbShopProfileTermsAndConditionsBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lang?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  lck?: InputMaybe<IntComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  shopProfileId?: InputMaybe<StringComparisonExp>;
  termsAndConditions?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.shop_profile_terms_and_conditions" */
export type DbShopProfileTermsAndConditionsMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  termsAndConditions?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.shop_profile_terms_and_conditions" */
export type DbShopProfileTermsAndConditionsMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  termsAndConditions?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.shop_profile_terms_and_conditions". */
export type DbShopProfileTermsAndConditionsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lang?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  termsAndConditions?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_profile_terms_and_conditions" */
export type DbShopProfileTermsAndConditionsSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'lang'
  /** column name */
  | 'lck'
  /** column name */
  | 'num'
  /** column name */
  | 'shopProfileId'
  /** column name */
  | 'termsAndConditions'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.shop_profile_terms_and_conditions" */
export type DbShopProfileTermsAndConditionsStddevOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.shop_profile_terms_and_conditions" */
export type DbShopProfileTermsAndConditionsStddevPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.shop_profile_terms_and_conditions" */
export type DbShopProfileTermsAndConditionsStddevSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_shop_profile_terms_and_conditions" */
export type DbShopProfileTermsAndConditionsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopProfileTermsAndConditionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopProfileTermsAndConditionsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<LanguageEnum>;
  lck?: InputMaybe<Scalars['Int']>;
  num?: InputMaybe<Scalars['bigint']>;
  shopProfileId?: InputMaybe<Scalars['String']>;
  termsAndConditions?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.shop_profile_terms_and_conditions" */
export type DbShopProfileTermsAndConditionsSumOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.shop_profile_terms_and_conditions" */
export type DbShopProfileTermsAndConditionsVarPopOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.shop_profile_terms_and_conditions" */
export type DbShopProfileTermsAndConditionsVarSampOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.shop_profile_terms_and_conditions" */
export type DbShopProfileTermsAndConditionsVarianceOrderBy = {
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbShopProfileVarPopFields = {
  __typename?: 'DbShopProfileVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.shop_profile" */
export type DbShopProfileVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbShopProfileVarSampFields = {
  __typename?: 'DbShopProfileVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.shop_profile" */
export type DbShopProfileVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbShopProfileVarianceFields = {
  __typename?: 'DbShopProfileVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.shop_profile" */
export type DbShopProfileVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.shop_profile_withdrawal_policy" */
export type DbShopProfileWithdrawalPolicy = {
  __typename?: 'DbShopProfileWithdrawalPolicy';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  lang: LanguageEnum;
  /** An object relationship */
  shopProfile: DbShopProfile;
  shopProfileId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  withdrawalPolicy: Scalars['String'];
};

/** order by aggregate values of table "db.shop_profile_withdrawal_policy" */
export type DbShopProfileWithdrawalPolicyAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbShopProfileWithdrawalPolicyMaxOrderBy>;
  min?: InputMaybe<DbShopProfileWithdrawalPolicyMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.shop_profile_withdrawal_policy". All fields are combined with a logical 'AND'. */
export type DbShopProfileWithdrawalPolicyBoolExp = {
  _and?: InputMaybe<Array<DbShopProfileWithdrawalPolicyBoolExp>>;
  _not?: InputMaybe<DbShopProfileWithdrawalPolicyBoolExp>;
  _or?: InputMaybe<Array<DbShopProfileWithdrawalPolicyBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lang?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  shopProfile?: InputMaybe<DbShopProfileBoolExp>;
  shopProfileId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  withdrawalPolicy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.shop_profile_withdrawal_policy" */
export type DbShopProfileWithdrawalPolicyMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  withdrawalPolicy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.shop_profile_withdrawal_policy" */
export type DbShopProfileWithdrawalPolicyMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  withdrawalPolicy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.shop_profile_withdrawal_policy". */
export type DbShopProfileWithdrawalPolicyOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lang?: InputMaybe<OrderBy>;
  shopProfile?: InputMaybe<DbShopProfileOrderBy>;
  shopProfileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  withdrawalPolicy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_profile_withdrawal_policy" */
export type DbShopProfileWithdrawalPolicySelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'lang'
  /** column name */
  | 'shopProfileId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy'
  /** column name */
  | 'withdrawalPolicy';

/** Streaming cursor of the table "db_shop_profile_withdrawal_policy" */
export type DbShopProfileWithdrawalPolicyStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopProfileWithdrawalPolicyStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopProfileWithdrawalPolicyStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  lang?: InputMaybe<LanguageEnum>;
  shopProfileId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  withdrawalPolicy?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "db.shop_right_of_withdrawal" */
export type DbShopRightOfWithdrawal = {
  __typename?: 'DbShopRightOfWithdrawal';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  language: LanguageEnum;
  /** An object relationship */
  languageByLanguage: EnumsLanguage;
  /** An object relationship */
  qualiformShopSetting: DbQualiformShopSetting;
  qualiformShopSettingId: Scalars['String'];
  rightOfWithdrawal: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.shop_right_of_withdrawal" */
export type DbShopRightOfWithdrawalAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbShopRightOfWithdrawalMaxOrderBy>;
  min?: InputMaybe<DbShopRightOfWithdrawalMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.shop_right_of_withdrawal". All fields are combined with a logical 'AND'. */
export type DbShopRightOfWithdrawalBoolExp = {
  _and?: InputMaybe<Array<DbShopRightOfWithdrawalBoolExp>>;
  _not?: InputMaybe<DbShopRightOfWithdrawalBoolExp>;
  _or?: InputMaybe<Array<DbShopRightOfWithdrawalBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  language?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  languageByLanguage?: InputMaybe<EnumsLanguageBoolExp>;
  qualiformShopSetting?: InputMaybe<DbQualiformShopSettingBoolExp>;
  qualiformShopSettingId?: InputMaybe<StringComparisonExp>;
  rightOfWithdrawal?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.shop_right_of_withdrawal" */
export type DbShopRightOfWithdrawalMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  qualiformShopSettingId?: InputMaybe<OrderBy>;
  rightOfWithdrawal?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.shop_right_of_withdrawal" */
export type DbShopRightOfWithdrawalMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  qualiformShopSettingId?: InputMaybe<OrderBy>;
  rightOfWithdrawal?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.shop_right_of_withdrawal". */
export type DbShopRightOfWithdrawalOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  languageByLanguage?: InputMaybe<EnumsLanguageOrderBy>;
  qualiformShopSetting?: InputMaybe<DbQualiformShopSettingOrderBy>;
  qualiformShopSettingId?: InputMaybe<OrderBy>;
  rightOfWithdrawal?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_right_of_withdrawal" */
export type DbShopRightOfWithdrawalSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'language'
  /** column name */
  | 'qualiformShopSettingId'
  /** column name */
  | 'rightOfWithdrawal'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** Streaming cursor of the table "db_shop_right_of_withdrawal" */
export type DbShopRightOfWithdrawalStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopRightOfWithdrawalStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopRightOfWithdrawalStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageEnum>;
  qualiformShopSettingId?: InputMaybe<Scalars['String']>;
  rightOfWithdrawal?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** select columns of table "db.shop" */
export type DbShopSelectColumn =
  /** column name */
  | 'businessUnit'
  /** column name */
  | 'city'
  /** column name */
  | 'countryCode'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'currency'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'email'
  /** column name */
  | 'energyAdvisoryEnabled'
  /** column name */
  | 'go3ShopStatus'
  /** column name */
  | 'groupId'
  /** column name */
  | 'id'
  /** column name */
  | 'lastShopLvlChangeDate'
  /** column name */
  | 'latitude'
  /** column name */
  | 'licensePartnerId'
  /** column name */
  | 'longitude'
  /** column name */
  | 'normalizedShopName'
  /** column name */
  | 'num'
  /** column name */
  | 'onboardingLevel'
  /** column name */
  | 'phone'
  /** column name */
  | 'postalCode'
  /** column name */
  | 'shopName'
  /** column name */
  | 'status'
  /** column name */
  | 'streetName'
  /** column name */
  | 'streetNumber'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy'
  /** column name */
  | 'website';

/** select "dbShopAggregateBoolExpAvgArgumentsColumns" columns of table "db.shop" */
export type DbShopSelectColumnDbShopAggregateBoolExpAvgArgumentsColumns =
  /** column name */
  | 'latitude'
  /** column name */
  | 'longitude';

/** select "dbShopAggregateBoolExpBool_andArgumentsColumns" columns of table "db.shop" */
export type DbShopSelectColumnDbShopAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  | 'deleted'
  /** column name */
  | 'energyAdvisoryEnabled';

/** select "dbShopAggregateBoolExpBool_orArgumentsColumns" columns of table "db.shop" */
export type DbShopSelectColumnDbShopAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  | 'deleted'
  /** column name */
  | 'energyAdvisoryEnabled';

/** select "dbShopAggregateBoolExpCorrArgumentsColumns" columns of table "db.shop" */
export type DbShopSelectColumnDbShopAggregateBoolExpCorrArgumentsColumns =
  /** column name */
  | 'latitude'
  /** column name */
  | 'longitude';

/** select "dbShopAggregateBoolExpCovar_sampArgumentsColumns" columns of table "db.shop" */
export type DbShopSelectColumnDbShopAggregateBoolExpCovarSampArgumentsColumns =
  /** column name */
  | 'latitude'
  /** column name */
  | 'longitude';

/** select "dbShopAggregateBoolExpMaxArgumentsColumns" columns of table "db.shop" */
export type DbShopSelectColumnDbShopAggregateBoolExpMaxArgumentsColumns =
  /** column name */
  | 'latitude'
  /** column name */
  | 'longitude';

/** select "dbShopAggregateBoolExpMinArgumentsColumns" columns of table "db.shop" */
export type DbShopSelectColumnDbShopAggregateBoolExpMinArgumentsColumns =
  /** column name */
  | 'latitude'
  /** column name */
  | 'longitude';

/** select "dbShopAggregateBoolExpStddev_sampArgumentsColumns" columns of table "db.shop" */
export type DbShopSelectColumnDbShopAggregateBoolExpStddevSampArgumentsColumns =
  /** column name */
  | 'latitude'
  /** column name */
  | 'longitude';

/** select "dbShopAggregateBoolExpSumArgumentsColumns" columns of table "db.shop" */
export type DbShopSelectColumnDbShopAggregateBoolExpSumArgumentsColumns =
  /** column name */
  | 'latitude'
  /** column name */
  | 'longitude';

/** select "dbShopAggregateBoolExpVar_sampArgumentsColumns" columns of table "db.shop" */
export type DbShopSelectColumnDbShopAggregateBoolExpVarSampArgumentsColumns =
  /** column name */
  | 'latitude'
  /** column name */
  | 'longitude';

/** columns and relationships of "db.shop_setting" */
export type DbShopSetting = {
  __typename?: 'DbShopSetting';
  activeToRecalculateLastInteraction?: Maybe<Scalars['Boolean']>;
  applyAutomatedContactSettings: Scalars['Boolean'];
  ccUsersInAssignmentEmails: Scalars['Boolean'];
  consentManagementEnabled: Scalars['Boolean'];
  /** An object relationship */
  country: EnumsCountry;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  defaultCity?: Maybe<Scalars['String']>;
  defaultCountryCode: CountryEnum;
  defaultPreferredLanguage: LanguageEnum;
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  importLeadFromEvSearch: Scalars['Boolean'];
  /** An object relationship */
  language: EnumsLanguage;
  lastInteractionRecalculatedAt: Scalars['timestamptz'];
  leadAutoAssignmentEnabled: Scalars['Boolean'];
  leadExpiryDaysBuyer?: Maybe<Scalars['Int']>;
  leadExpiryDaysRentOut?: Maybe<Scalars['Int']>;
  leadExpiryDaysSeller?: Maybe<Scalars['Int']>;
  leadExpiryDaysToRent?: Maybe<Scalars['Int']>;
  leadExpiryEnabled: Scalars['Boolean'];
  leadExtendingInteractionsEnabled: Scalars['Boolean'];
  num: Scalars['bigint'];
  optimizelyIntegrationEnabled: Scalars['Boolean'];
  sendQualificationLink: Scalars['Boolean'];
  /** An object relationship */
  shop: DbShop;
  shopId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.shop_setting" */
export type DbShopSettingAggregate = {
  __typename?: 'DbShopSettingAggregate';
  aggregate?: Maybe<DbShopSettingAggregateFields>;
  nodes: Array<DbShopSetting>;
};

export type DbShopSettingAggregateBoolExp = {
  bool_and?: InputMaybe<DbShopSettingAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbShopSettingAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbShopSettingAggregateBoolExpCount>;
};

/** aggregate fields of "db.shop_setting" */
export type DbShopSettingAggregateFields = {
  __typename?: 'DbShopSettingAggregateFields';
  avg?: Maybe<DbShopSettingAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbShopSettingMaxFields>;
  min?: Maybe<DbShopSettingMinFields>;
  stddev?: Maybe<DbShopSettingStddevFields>;
  stddevPop?: Maybe<DbShopSettingStddevPopFields>;
  stddevSamp?: Maybe<DbShopSettingStddevSampFields>;
  sum?: Maybe<DbShopSettingSumFields>;
  varPop?: Maybe<DbShopSettingVarPopFields>;
  varSamp?: Maybe<DbShopSettingVarSampFields>;
  variance?: Maybe<DbShopSettingVarianceFields>;
};

/** aggregate fields of "db.shop_setting" */
export type DbShopSettingAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbShopSettingSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.shop_setting" */
export type DbShopSettingAggregateOrderBy = {
  avg?: InputMaybe<DbShopSettingAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbShopSettingMaxOrderBy>;
  min?: InputMaybe<DbShopSettingMinOrderBy>;
  stddev?: InputMaybe<DbShopSettingStddevOrderBy>;
  stddevPop?: InputMaybe<DbShopSettingStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbShopSettingStddevSampOrderBy>;
  sum?: InputMaybe<DbShopSettingSumOrderBy>;
  varPop?: InputMaybe<DbShopSettingVarPopOrderBy>;
  varSamp?: InputMaybe<DbShopSettingVarSampOrderBy>;
  variance?: InputMaybe<DbShopSettingVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbShopSettingAvgFields = {
  __typename?: 'DbShopSettingAvgFields';
  leadExpiryDaysBuyer?: Maybe<Scalars['Float']>;
  leadExpiryDaysRentOut?: Maybe<Scalars['Float']>;
  leadExpiryDaysSeller?: Maybe<Scalars['Float']>;
  leadExpiryDaysToRent?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.shop_setting" */
export type DbShopSettingAvgOrderBy = {
  leadExpiryDaysBuyer?: InputMaybe<OrderBy>;
  leadExpiryDaysRentOut?: InputMaybe<OrderBy>;
  leadExpiryDaysSeller?: InputMaybe<OrderBy>;
  leadExpiryDaysToRent?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.shop_setting". All fields are combined with a logical 'AND'. */
export type DbShopSettingBoolExp = {
  _and?: InputMaybe<Array<DbShopSettingBoolExp>>;
  _not?: InputMaybe<DbShopSettingBoolExp>;
  _or?: InputMaybe<Array<DbShopSettingBoolExp>>;
  activeToRecalculateLastInteraction?: InputMaybe<BooleanComparisonExp>;
  applyAutomatedContactSettings?: InputMaybe<BooleanComparisonExp>;
  ccUsersInAssignmentEmails?: InputMaybe<BooleanComparisonExp>;
  consentManagementEnabled?: InputMaybe<BooleanComparisonExp>;
  country?: InputMaybe<EnumsCountryBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  defaultCity?: InputMaybe<StringComparisonExp>;
  defaultCountryCode?: InputMaybe<EnumsCountryEnumComparisonExp>;
  defaultPreferredLanguage?: InputMaybe<EnumsLanguageEnumComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  importLeadFromEvSearch?: InputMaybe<BooleanComparisonExp>;
  language?: InputMaybe<EnumsLanguageBoolExp>;
  lastInteractionRecalculatedAt?: InputMaybe<TimestamptzComparisonExp>;
  leadAutoAssignmentEnabled?: InputMaybe<BooleanComparisonExp>;
  leadExpiryDaysBuyer?: InputMaybe<IntComparisonExp>;
  leadExpiryDaysRentOut?: InputMaybe<IntComparisonExp>;
  leadExpiryDaysSeller?: InputMaybe<IntComparisonExp>;
  leadExpiryDaysToRent?: InputMaybe<IntComparisonExp>;
  leadExpiryEnabled?: InputMaybe<BooleanComparisonExp>;
  leadExtendingInteractionsEnabled?: InputMaybe<BooleanComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  optimizelyIntegrationEnabled?: InputMaybe<BooleanComparisonExp>;
  sendQualificationLink?: InputMaybe<BooleanComparisonExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbShopSettingMaxFields = {
  __typename?: 'DbShopSettingMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  defaultCity?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lastInteractionRecalculatedAt?: Maybe<Scalars['timestamptz']>;
  leadExpiryDaysBuyer?: Maybe<Scalars['Int']>;
  leadExpiryDaysRentOut?: Maybe<Scalars['Int']>;
  leadExpiryDaysSeller?: Maybe<Scalars['Int']>;
  leadExpiryDaysToRent?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['bigint']>;
  shopId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.shop_setting" */
export type DbShopSettingMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  defaultCity?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastInteractionRecalculatedAt?: InputMaybe<OrderBy>;
  leadExpiryDaysBuyer?: InputMaybe<OrderBy>;
  leadExpiryDaysRentOut?: InputMaybe<OrderBy>;
  leadExpiryDaysSeller?: InputMaybe<OrderBy>;
  leadExpiryDaysToRent?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbShopSettingMinFields = {
  __typename?: 'DbShopSettingMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  defaultCity?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  lastInteractionRecalculatedAt?: Maybe<Scalars['timestamptz']>;
  leadExpiryDaysBuyer?: Maybe<Scalars['Int']>;
  leadExpiryDaysRentOut?: Maybe<Scalars['Int']>;
  leadExpiryDaysSeller?: Maybe<Scalars['Int']>;
  leadExpiryDaysToRent?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['bigint']>;
  shopId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.shop_setting" */
export type DbShopSettingMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  defaultCity?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lastInteractionRecalculatedAt?: InputMaybe<OrderBy>;
  leadExpiryDaysBuyer?: InputMaybe<OrderBy>;
  leadExpiryDaysRentOut?: InputMaybe<OrderBy>;
  leadExpiryDaysSeller?: InputMaybe<OrderBy>;
  leadExpiryDaysToRent?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.shop_setting". */
export type DbShopSettingOrderBy = {
  activeToRecalculateLastInteraction?: InputMaybe<OrderBy>;
  applyAutomatedContactSettings?: InputMaybe<OrderBy>;
  ccUsersInAssignmentEmails?: InputMaybe<OrderBy>;
  consentManagementEnabled?: InputMaybe<OrderBy>;
  country?: InputMaybe<EnumsCountryOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  defaultCity?: InputMaybe<OrderBy>;
  defaultCountryCode?: InputMaybe<OrderBy>;
  defaultPreferredLanguage?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  importLeadFromEvSearch?: InputMaybe<OrderBy>;
  language?: InputMaybe<EnumsLanguageOrderBy>;
  lastInteractionRecalculatedAt?: InputMaybe<OrderBy>;
  leadAutoAssignmentEnabled?: InputMaybe<OrderBy>;
  leadExpiryDaysBuyer?: InputMaybe<OrderBy>;
  leadExpiryDaysRentOut?: InputMaybe<OrderBy>;
  leadExpiryDaysSeller?: InputMaybe<OrderBy>;
  leadExpiryDaysToRent?: InputMaybe<OrderBy>;
  leadExpiryEnabled?: InputMaybe<OrderBy>;
  leadExtendingInteractionsEnabled?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  optimizelyIntegrationEnabled?: InputMaybe<OrderBy>;
  sendQualificationLink?: InputMaybe<OrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.shop_setting" */
export type DbShopSettingSelectColumn =
  /** column name */
  | 'activeToRecalculateLastInteraction'
  /** column name */
  | 'applyAutomatedContactSettings'
  /** column name */
  | 'ccUsersInAssignmentEmails'
  /** column name */
  | 'consentManagementEnabled'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'defaultCity'
  /** column name */
  | 'defaultCountryCode'
  /** column name */
  | 'defaultPreferredLanguage'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'importLeadFromEvSearch'
  /** column name */
  | 'lastInteractionRecalculatedAt'
  /** column name */
  | 'leadAutoAssignmentEnabled'
  /** column name */
  | 'leadExpiryDaysBuyer'
  /** column name */
  | 'leadExpiryDaysRentOut'
  /** column name */
  | 'leadExpiryDaysSeller'
  /** column name */
  | 'leadExpiryDaysToRent'
  /** column name */
  | 'leadExpiryEnabled'
  /** column name */
  | 'leadExtendingInteractionsEnabled'
  /** column name */
  | 'num'
  /** column name */
  | 'optimizelyIntegrationEnabled'
  /** column name */
  | 'sendQualificationLink'
  /** column name */
  | 'shopId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbShopSettingAggregateBoolExpBool_andArgumentsColumns" columns of table "db.shop_setting" */
export type DbShopSettingSelectColumnDbShopSettingAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  | 'activeToRecalculateLastInteraction'
  /** column name */
  | 'applyAutomatedContactSettings'
  /** column name */
  | 'ccUsersInAssignmentEmails'
  /** column name */
  | 'consentManagementEnabled'
  /** column name */
  | 'deleted'
  /** column name */
  | 'importLeadFromEvSearch'
  /** column name */
  | 'leadAutoAssignmentEnabled'
  /** column name */
  | 'leadExpiryEnabled'
  /** column name */
  | 'leadExtendingInteractionsEnabled'
  /** column name */
  | 'optimizelyIntegrationEnabled'
  /** column name */
  | 'sendQualificationLink';

/** select "dbShopSettingAggregateBoolExpBool_orArgumentsColumns" columns of table "db.shop_setting" */
export type DbShopSettingSelectColumnDbShopSettingAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  | 'activeToRecalculateLastInteraction'
  /** column name */
  | 'applyAutomatedContactSettings'
  /** column name */
  | 'ccUsersInAssignmentEmails'
  /** column name */
  | 'consentManagementEnabled'
  /** column name */
  | 'deleted'
  /** column name */
  | 'importLeadFromEvSearch'
  /** column name */
  | 'leadAutoAssignmentEnabled'
  /** column name */
  | 'leadExpiryEnabled'
  /** column name */
  | 'leadExtendingInteractionsEnabled'
  /** column name */
  | 'optimizelyIntegrationEnabled'
  /** column name */
  | 'sendQualificationLink';

/** aggregate stddev on columns */
export type DbShopSettingStddevFields = {
  __typename?: 'DbShopSettingStddevFields';
  leadExpiryDaysBuyer?: Maybe<Scalars['Float']>;
  leadExpiryDaysRentOut?: Maybe<Scalars['Float']>;
  leadExpiryDaysSeller?: Maybe<Scalars['Float']>;
  leadExpiryDaysToRent?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.shop_setting" */
export type DbShopSettingStddevOrderBy = {
  leadExpiryDaysBuyer?: InputMaybe<OrderBy>;
  leadExpiryDaysRentOut?: InputMaybe<OrderBy>;
  leadExpiryDaysSeller?: InputMaybe<OrderBy>;
  leadExpiryDaysToRent?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbShopSettingStddevPopFields = {
  __typename?: 'DbShopSettingStddevPopFields';
  leadExpiryDaysBuyer?: Maybe<Scalars['Float']>;
  leadExpiryDaysRentOut?: Maybe<Scalars['Float']>;
  leadExpiryDaysSeller?: Maybe<Scalars['Float']>;
  leadExpiryDaysToRent?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.shop_setting" */
export type DbShopSettingStddevPopOrderBy = {
  leadExpiryDaysBuyer?: InputMaybe<OrderBy>;
  leadExpiryDaysRentOut?: InputMaybe<OrderBy>;
  leadExpiryDaysSeller?: InputMaybe<OrderBy>;
  leadExpiryDaysToRent?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbShopSettingStddevSampFields = {
  __typename?: 'DbShopSettingStddevSampFields';
  leadExpiryDaysBuyer?: Maybe<Scalars['Float']>;
  leadExpiryDaysRentOut?: Maybe<Scalars['Float']>;
  leadExpiryDaysSeller?: Maybe<Scalars['Float']>;
  leadExpiryDaysToRent?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.shop_setting" */
export type DbShopSettingStddevSampOrderBy = {
  leadExpiryDaysBuyer?: InputMaybe<OrderBy>;
  leadExpiryDaysRentOut?: InputMaybe<OrderBy>;
  leadExpiryDaysSeller?: InputMaybe<OrderBy>;
  leadExpiryDaysToRent?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_shop_setting" */
export type DbShopSettingStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopSettingStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopSettingStreamCursorValueInput = {
  activeToRecalculateLastInteraction?: InputMaybe<Scalars['Boolean']>;
  applyAutomatedContactSettings?: InputMaybe<Scalars['Boolean']>;
  ccUsersInAssignmentEmails?: InputMaybe<Scalars['Boolean']>;
  consentManagementEnabled?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  defaultCity?: InputMaybe<Scalars['String']>;
  defaultCountryCode?: InputMaybe<CountryEnum>;
  defaultPreferredLanguage?: InputMaybe<LanguageEnum>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  importLeadFromEvSearch?: InputMaybe<Scalars['Boolean']>;
  lastInteractionRecalculatedAt?: InputMaybe<Scalars['timestamptz']>;
  leadAutoAssignmentEnabled?: InputMaybe<Scalars['Boolean']>;
  leadExpiryDaysBuyer?: InputMaybe<Scalars['Int']>;
  leadExpiryDaysRentOut?: InputMaybe<Scalars['Int']>;
  leadExpiryDaysSeller?: InputMaybe<Scalars['Int']>;
  leadExpiryDaysToRent?: InputMaybe<Scalars['Int']>;
  leadExpiryEnabled?: InputMaybe<Scalars['Boolean']>;
  leadExtendingInteractionsEnabled?: InputMaybe<Scalars['Boolean']>;
  num?: InputMaybe<Scalars['bigint']>;
  optimizelyIntegrationEnabled?: InputMaybe<Scalars['Boolean']>;
  sendQualificationLink?: InputMaybe<Scalars['Boolean']>;
  shopId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbShopSettingSumFields = {
  __typename?: 'DbShopSettingSumFields';
  leadExpiryDaysBuyer?: Maybe<Scalars['Int']>;
  leadExpiryDaysRentOut?: Maybe<Scalars['Int']>;
  leadExpiryDaysSeller?: Maybe<Scalars['Int']>;
  leadExpiryDaysToRent?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.shop_setting" */
export type DbShopSettingSumOrderBy = {
  leadExpiryDaysBuyer?: InputMaybe<OrderBy>;
  leadExpiryDaysRentOut?: InputMaybe<OrderBy>;
  leadExpiryDaysSeller?: InputMaybe<OrderBy>;
  leadExpiryDaysToRent?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbShopSettingVarPopFields = {
  __typename?: 'DbShopSettingVarPopFields';
  leadExpiryDaysBuyer?: Maybe<Scalars['Float']>;
  leadExpiryDaysRentOut?: Maybe<Scalars['Float']>;
  leadExpiryDaysSeller?: Maybe<Scalars['Float']>;
  leadExpiryDaysToRent?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.shop_setting" */
export type DbShopSettingVarPopOrderBy = {
  leadExpiryDaysBuyer?: InputMaybe<OrderBy>;
  leadExpiryDaysRentOut?: InputMaybe<OrderBy>;
  leadExpiryDaysSeller?: InputMaybe<OrderBy>;
  leadExpiryDaysToRent?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbShopSettingVarSampFields = {
  __typename?: 'DbShopSettingVarSampFields';
  leadExpiryDaysBuyer?: Maybe<Scalars['Float']>;
  leadExpiryDaysRentOut?: Maybe<Scalars['Float']>;
  leadExpiryDaysSeller?: Maybe<Scalars['Float']>;
  leadExpiryDaysToRent?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.shop_setting" */
export type DbShopSettingVarSampOrderBy = {
  leadExpiryDaysBuyer?: InputMaybe<OrderBy>;
  leadExpiryDaysRentOut?: InputMaybe<OrderBy>;
  leadExpiryDaysSeller?: InputMaybe<OrderBy>;
  leadExpiryDaysToRent?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbShopSettingVarianceFields = {
  __typename?: 'DbShopSettingVarianceFields';
  leadExpiryDaysBuyer?: Maybe<Scalars['Float']>;
  leadExpiryDaysRentOut?: Maybe<Scalars['Float']>;
  leadExpiryDaysSeller?: Maybe<Scalars['Float']>;
  leadExpiryDaysToRent?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.shop_setting" */
export type DbShopSettingVarianceOrderBy = {
  leadExpiryDaysBuyer?: InputMaybe<OrderBy>;
  leadExpiryDaysRentOut?: InputMaybe<OrderBy>;
  leadExpiryDaysSeller?: InputMaybe<OrderBy>;
  leadExpiryDaysToRent?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddev on columns */
export type DbShopStddevFields = {
  __typename?: 'DbShopStddevFields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  onboardingLevel?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.shop" */
export type DbShopStddevOrderBy = {
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  onboardingLevel?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbShopStddevPopFields = {
  __typename?: 'DbShopStddevPopFields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  onboardingLevel?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.shop" */
export type DbShopStddevPopOrderBy = {
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  onboardingLevel?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbShopStddevSampFields = {
  __typename?: 'DbShopStddevSampFields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  onboardingLevel?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.shop" */
export type DbShopStddevSampOrderBy = {
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  onboardingLevel?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_shop" */
export type DbShopStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbShopStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbShopStreamCursorValueInput = {
  businessUnit?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<CountryEnum>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<CurrencyEnum>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  energyAdvisoryEnabled?: InputMaybe<Scalars['Boolean']>;
  go3ShopStatus?: InputMaybe<ShopStatusEnum>;
  groupId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastShopLvlChangeDate?: InputMaybe<Scalars['timestamp']>;
  latitude?: InputMaybe<Scalars['float8']>;
  licensePartnerId?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['float8']>;
  normalizedShopName?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  onboardingLevel?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  shopName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ShopStatusEnum>;
  streetName?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbShopSumFields = {
  __typename?: 'DbShopSumFields';
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  num?: Maybe<Scalars['bigint']>;
  onboardingLevel?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "db.shop" */
export type DbShopSumOrderBy = {
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  onboardingLevel?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbShopVarPopFields = {
  __typename?: 'DbShopVarPopFields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  onboardingLevel?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.shop" */
export type DbShopVarPopOrderBy = {
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  onboardingLevel?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbShopVarSampFields = {
  __typename?: 'DbShopVarSampFields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  onboardingLevel?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.shop" */
export type DbShopVarSampOrderBy = {
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  onboardingLevel?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbShopVarianceFields = {
  __typename?: 'DbShopVarianceFields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  onboardingLevel?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.shop" */
export type DbShopVarianceOrderBy = {
  latitude?: InputMaybe<OrderBy>;
  longitude?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  onboardingLevel?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.snapshot_buyer_to_rent_specification" */
export type DbSnapshotBuyerToRentSpecification = {
  __typename?: 'DbSnapshotBuyerToRentSpecification';
  area?: Maybe<Scalars['String']>;
  /** An object relationship */
  assetType?: Maybe<EnumsAssetType>;
  bedroomsMax?: Maybe<Scalars['Int']>;
  bedroomsMin?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  currency: CurrencyEnum;
  /** An object relationship */
  currencyByCurrency: EnumsCurrency;
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  district?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  legacyAreaIds: Array<Scalars['Int']>;
  legacyAreaNames: Array<Scalars['String']>;
  legacyDistrictIds: Array<Scalars['Int']>;
  legacyDistrictNames: Array<Scalars['String']>;
  listingShortTermRental?: Maybe<Scalars['Boolean']>;
  livingAreaMax?: Maybe<Scalars['Int']>;
  livingAreaMin?: Maybe<Scalars['Int']>;
  monthlyNetRentMax?: Maybe<Scalars['Int']>;
  monthlyNetRentMin?: Maybe<Scalars['Int']>;
  monthlyTotalRentMax?: Maybe<Scalars['Int']>;
  monthlyTotalRentMin?: Maybe<Scalars['Int']>;
  placeIds: Array<Scalars['String']>;
  plotSurfaceMax?: Maybe<Scalars['Int']>;
  plotSurfaceMin?: Maybe<Scalars['Int']>;
  priceMax?: Maybe<Scalars['Int']>;
  priceMin?: Maybe<Scalars['Int']>;
  roomsMax?: Maybe<Scalars['Int']>;
  roomsMin?: Maybe<Scalars['Int']>;
  /** An array relationship */
  snapshotCustomSearchDemandPolygons: Array<DbSnapshotCustomSearchDemandPolygon>;
  /** An array relationship */
  snapshotLeads: Array<DbSnapshotLead>;
  /** An aggregate relationship */
  snapshotLeadsAggregate: DbSnapshotLeadAggregate;
  subTypes?: Maybe<Array<Scalars['String']>>;
  totalSurfaceMax?: Maybe<Scalars['Int']>;
  totalSurfaceMin?: Maybe<Scalars['Int']>;
  type?: Maybe<AssetTypeEnum>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  variations?: Maybe<Array<Scalars['String']>>;
};

/** columns and relationships of "db.snapshot_buyer_to_rent_specification" */
export type DbSnapshotBuyerToRentSpecificationSnapshotCustomSearchDemandPolygonsArgs = {
  distinctOn?: InputMaybe<Array<DbSnapshotCustomSearchDemandPolygonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSnapshotCustomSearchDemandPolygonOrderBy>>;
  where?: InputMaybe<DbSnapshotCustomSearchDemandPolygonBoolExp>;
};

/** columns and relationships of "db.snapshot_buyer_to_rent_specification" */
export type DbSnapshotBuyerToRentSpecificationSnapshotLeadsArgs = {
  distinctOn?: InputMaybe<Array<DbSnapshotLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSnapshotLeadOrderBy>>;
  where?: InputMaybe<DbSnapshotLeadBoolExp>;
};

/** columns and relationships of "db.snapshot_buyer_to_rent_specification" */
export type DbSnapshotBuyerToRentSpecificationSnapshotLeadsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbSnapshotLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSnapshotLeadOrderBy>>;
  where?: InputMaybe<DbSnapshotLeadBoolExp>;
};

/** aggregated selection of "db.snapshot_buyer_to_rent_specification" */
export type DbSnapshotBuyerToRentSpecificationAggregate = {
  __typename?: 'DbSnapshotBuyerToRentSpecificationAggregate';
  aggregate?: Maybe<DbSnapshotBuyerToRentSpecificationAggregateFields>;
  nodes: Array<DbSnapshotBuyerToRentSpecification>;
};

/** aggregate fields of "db.snapshot_buyer_to_rent_specification" */
export type DbSnapshotBuyerToRentSpecificationAggregateFields = {
  __typename?: 'DbSnapshotBuyerToRentSpecificationAggregateFields';
  avg?: Maybe<DbSnapshotBuyerToRentSpecificationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbSnapshotBuyerToRentSpecificationMaxFields>;
  min?: Maybe<DbSnapshotBuyerToRentSpecificationMinFields>;
  stddev?: Maybe<DbSnapshotBuyerToRentSpecificationStddevFields>;
  stddevPop?: Maybe<DbSnapshotBuyerToRentSpecificationStddevPopFields>;
  stddevSamp?: Maybe<DbSnapshotBuyerToRentSpecificationStddevSampFields>;
  sum?: Maybe<DbSnapshotBuyerToRentSpecificationSumFields>;
  varPop?: Maybe<DbSnapshotBuyerToRentSpecificationVarPopFields>;
  varSamp?: Maybe<DbSnapshotBuyerToRentSpecificationVarSampFields>;
  variance?: Maybe<DbSnapshotBuyerToRentSpecificationVarianceFields>;
};

/** aggregate fields of "db.snapshot_buyer_to_rent_specification" */
export type DbSnapshotBuyerToRentSpecificationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbSnapshotBuyerToRentSpecificationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type DbSnapshotBuyerToRentSpecificationAvgFields = {
  __typename?: 'DbSnapshotBuyerToRentSpecificationAvgFields';
  bedroomsMax?: Maybe<Scalars['Float']>;
  bedroomsMin?: Maybe<Scalars['Float']>;
  livingAreaMax?: Maybe<Scalars['Float']>;
  livingAreaMin?: Maybe<Scalars['Float']>;
  monthlyNetRentMax?: Maybe<Scalars['Float']>;
  monthlyNetRentMin?: Maybe<Scalars['Float']>;
  monthlyTotalRentMax?: Maybe<Scalars['Float']>;
  monthlyTotalRentMin?: Maybe<Scalars['Float']>;
  plotSurfaceMax?: Maybe<Scalars['Float']>;
  plotSurfaceMin?: Maybe<Scalars['Float']>;
  priceMax?: Maybe<Scalars['Float']>;
  priceMin?: Maybe<Scalars['Float']>;
  roomsMax?: Maybe<Scalars['Float']>;
  roomsMin?: Maybe<Scalars['Float']>;
  totalSurfaceMax?: Maybe<Scalars['Float']>;
  totalSurfaceMin?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "db.snapshot_buyer_to_rent_specification". All fields are combined with a logical 'AND'. */
export type DbSnapshotBuyerToRentSpecificationBoolExp = {
  _and?: InputMaybe<Array<DbSnapshotBuyerToRentSpecificationBoolExp>>;
  _not?: InputMaybe<DbSnapshotBuyerToRentSpecificationBoolExp>;
  _or?: InputMaybe<Array<DbSnapshotBuyerToRentSpecificationBoolExp>>;
  area?: InputMaybe<StringComparisonExp>;
  assetType?: InputMaybe<EnumsAssetTypeBoolExp>;
  bedroomsMax?: InputMaybe<IntComparisonExp>;
  bedroomsMin?: InputMaybe<IntComparisonExp>;
  city?: InputMaybe<StringComparisonExp>;
  country?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  currency?: InputMaybe<EnumsCurrencyEnumComparisonExp>;
  currencyByCurrency?: InputMaybe<EnumsCurrencyBoolExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  district?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  legacyAreaIds?: InputMaybe<IntArrayComparisonExp>;
  legacyAreaNames?: InputMaybe<StringArrayComparisonExp>;
  legacyDistrictIds?: InputMaybe<IntArrayComparisonExp>;
  legacyDistrictNames?: InputMaybe<StringArrayComparisonExp>;
  listingShortTermRental?: InputMaybe<BooleanComparisonExp>;
  livingAreaMax?: InputMaybe<IntComparisonExp>;
  livingAreaMin?: InputMaybe<IntComparisonExp>;
  monthlyNetRentMax?: InputMaybe<IntComparisonExp>;
  monthlyNetRentMin?: InputMaybe<IntComparisonExp>;
  monthlyTotalRentMax?: InputMaybe<IntComparisonExp>;
  monthlyTotalRentMin?: InputMaybe<IntComparisonExp>;
  placeIds?: InputMaybe<StringArrayComparisonExp>;
  plotSurfaceMax?: InputMaybe<IntComparisonExp>;
  plotSurfaceMin?: InputMaybe<IntComparisonExp>;
  priceMax?: InputMaybe<IntComparisonExp>;
  priceMin?: InputMaybe<IntComparisonExp>;
  roomsMax?: InputMaybe<IntComparisonExp>;
  roomsMin?: InputMaybe<IntComparisonExp>;
  snapshotCustomSearchDemandPolygons?: InputMaybe<DbSnapshotCustomSearchDemandPolygonBoolExp>;
  snapshotLeads?: InputMaybe<DbSnapshotLeadBoolExp>;
  snapshotLeadsAggregate?: InputMaybe<DbSnapshotLeadAggregateBoolExp>;
  subTypes?: InputMaybe<StringArrayComparisonExp>;
  totalSurfaceMax?: InputMaybe<IntComparisonExp>;
  totalSurfaceMin?: InputMaybe<IntComparisonExp>;
  type?: InputMaybe<EnumsAssetTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  variations?: InputMaybe<StringArrayComparisonExp>;
};

/** aggregate max on columns */
export type DbSnapshotBuyerToRentSpecificationMaxFields = {
  __typename?: 'DbSnapshotBuyerToRentSpecificationMaxFields';
  area?: Maybe<Scalars['String']>;
  bedroomsMax?: Maybe<Scalars['Int']>;
  bedroomsMin?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  district?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  legacyAreaIds?: Maybe<Array<Scalars['Int']>>;
  legacyAreaNames?: Maybe<Array<Scalars['String']>>;
  legacyDistrictIds?: Maybe<Array<Scalars['Int']>>;
  legacyDistrictNames?: Maybe<Array<Scalars['String']>>;
  livingAreaMax?: Maybe<Scalars['Int']>;
  livingAreaMin?: Maybe<Scalars['Int']>;
  monthlyNetRentMax?: Maybe<Scalars['Int']>;
  monthlyNetRentMin?: Maybe<Scalars['Int']>;
  monthlyTotalRentMax?: Maybe<Scalars['Int']>;
  monthlyTotalRentMin?: Maybe<Scalars['Int']>;
  placeIds?: Maybe<Array<Scalars['String']>>;
  plotSurfaceMax?: Maybe<Scalars['Int']>;
  plotSurfaceMin?: Maybe<Scalars['Int']>;
  priceMax?: Maybe<Scalars['Int']>;
  priceMin?: Maybe<Scalars['Int']>;
  roomsMax?: Maybe<Scalars['Int']>;
  roomsMin?: Maybe<Scalars['Int']>;
  subTypes?: Maybe<Array<Scalars['String']>>;
  totalSurfaceMax?: Maybe<Scalars['Int']>;
  totalSurfaceMin?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  variations?: Maybe<Array<Scalars['String']>>;
};

/** aggregate min on columns */
export type DbSnapshotBuyerToRentSpecificationMinFields = {
  __typename?: 'DbSnapshotBuyerToRentSpecificationMinFields';
  area?: Maybe<Scalars['String']>;
  bedroomsMax?: Maybe<Scalars['Int']>;
  bedroomsMin?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  district?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  legacyAreaIds?: Maybe<Array<Scalars['Int']>>;
  legacyAreaNames?: Maybe<Array<Scalars['String']>>;
  legacyDistrictIds?: Maybe<Array<Scalars['Int']>>;
  legacyDistrictNames?: Maybe<Array<Scalars['String']>>;
  livingAreaMax?: Maybe<Scalars['Int']>;
  livingAreaMin?: Maybe<Scalars['Int']>;
  monthlyNetRentMax?: Maybe<Scalars['Int']>;
  monthlyNetRentMin?: Maybe<Scalars['Int']>;
  monthlyTotalRentMax?: Maybe<Scalars['Int']>;
  monthlyTotalRentMin?: Maybe<Scalars['Int']>;
  placeIds?: Maybe<Array<Scalars['String']>>;
  plotSurfaceMax?: Maybe<Scalars['Int']>;
  plotSurfaceMin?: Maybe<Scalars['Int']>;
  priceMax?: Maybe<Scalars['Int']>;
  priceMin?: Maybe<Scalars['Int']>;
  roomsMax?: Maybe<Scalars['Int']>;
  roomsMin?: Maybe<Scalars['Int']>;
  subTypes?: Maybe<Array<Scalars['String']>>;
  totalSurfaceMax?: Maybe<Scalars['Int']>;
  totalSurfaceMin?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
  variations?: Maybe<Array<Scalars['String']>>;
};

/** Ordering options when selecting data from "db.snapshot_buyer_to_rent_specification". */
export type DbSnapshotBuyerToRentSpecificationOrderBy = {
  area?: InputMaybe<OrderBy>;
  assetType?: InputMaybe<EnumsAssetTypeOrderBy>;
  bedroomsMax?: InputMaybe<OrderBy>;
  bedroomsMin?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  currency?: InputMaybe<OrderBy>;
  currencyByCurrency?: InputMaybe<EnumsCurrencyOrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  district?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  legacyAreaIds?: InputMaybe<OrderBy>;
  legacyAreaNames?: InputMaybe<OrderBy>;
  legacyDistrictIds?: InputMaybe<OrderBy>;
  legacyDistrictNames?: InputMaybe<OrderBy>;
  listingShortTermRental?: InputMaybe<OrderBy>;
  livingAreaMax?: InputMaybe<OrderBy>;
  livingAreaMin?: InputMaybe<OrderBy>;
  monthlyNetRentMax?: InputMaybe<OrderBy>;
  monthlyNetRentMin?: InputMaybe<OrderBy>;
  monthlyTotalRentMax?: InputMaybe<OrderBy>;
  monthlyTotalRentMin?: InputMaybe<OrderBy>;
  placeIds?: InputMaybe<OrderBy>;
  plotSurfaceMax?: InputMaybe<OrderBy>;
  plotSurfaceMin?: InputMaybe<OrderBy>;
  priceMax?: InputMaybe<OrderBy>;
  priceMin?: InputMaybe<OrderBy>;
  roomsMax?: InputMaybe<OrderBy>;
  roomsMin?: InputMaybe<OrderBy>;
  snapshotCustomSearchDemandPolygonsAggregate?: InputMaybe<DbSnapshotCustomSearchDemandPolygonAggregateOrderBy>;
  snapshotLeadsAggregate?: InputMaybe<DbSnapshotLeadAggregateOrderBy>;
  subTypes?: InputMaybe<OrderBy>;
  totalSurfaceMax?: InputMaybe<OrderBy>;
  totalSurfaceMin?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  variations?: InputMaybe<OrderBy>;
};

/** select columns of table "db.snapshot_buyer_to_rent_specification" */
export type DbSnapshotBuyerToRentSpecificationSelectColumn =
  /** column name */
  | 'area'
  /** column name */
  | 'bedroomsMax'
  /** column name */
  | 'bedroomsMin'
  /** column name */
  | 'city'
  /** column name */
  | 'country'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'currency'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'district'
  /** column name */
  | 'id'
  /** column name */
  | 'legacyAreaIds'
  /** column name */
  | 'legacyAreaNames'
  /** column name */
  | 'legacyDistrictIds'
  /** column name */
  | 'legacyDistrictNames'
  /** column name */
  | 'listingShortTermRental'
  /** column name */
  | 'livingAreaMax'
  /** column name */
  | 'livingAreaMin'
  /** column name */
  | 'monthlyNetRentMax'
  /** column name */
  | 'monthlyNetRentMin'
  /** column name */
  | 'monthlyTotalRentMax'
  /** column name */
  | 'monthlyTotalRentMin'
  /** column name */
  | 'placeIds'
  /** column name */
  | 'plotSurfaceMax'
  /** column name */
  | 'plotSurfaceMin'
  /** column name */
  | 'priceMax'
  /** column name */
  | 'priceMin'
  /** column name */
  | 'roomsMax'
  /** column name */
  | 'roomsMin'
  /** column name */
  | 'subTypes'
  /** column name */
  | 'totalSurfaceMax'
  /** column name */
  | 'totalSurfaceMin'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy'
  /** column name */
  | 'variations';

/** aggregate stddev on columns */
export type DbSnapshotBuyerToRentSpecificationStddevFields = {
  __typename?: 'DbSnapshotBuyerToRentSpecificationStddevFields';
  bedroomsMax?: Maybe<Scalars['Float']>;
  bedroomsMin?: Maybe<Scalars['Float']>;
  livingAreaMax?: Maybe<Scalars['Float']>;
  livingAreaMin?: Maybe<Scalars['Float']>;
  monthlyNetRentMax?: Maybe<Scalars['Float']>;
  monthlyNetRentMin?: Maybe<Scalars['Float']>;
  monthlyTotalRentMax?: Maybe<Scalars['Float']>;
  monthlyTotalRentMin?: Maybe<Scalars['Float']>;
  plotSurfaceMax?: Maybe<Scalars['Float']>;
  plotSurfaceMin?: Maybe<Scalars['Float']>;
  priceMax?: Maybe<Scalars['Float']>;
  priceMin?: Maybe<Scalars['Float']>;
  roomsMax?: Maybe<Scalars['Float']>;
  roomsMin?: Maybe<Scalars['Float']>;
  totalSurfaceMax?: Maybe<Scalars['Float']>;
  totalSurfaceMin?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type DbSnapshotBuyerToRentSpecificationStddevPopFields = {
  __typename?: 'DbSnapshotBuyerToRentSpecificationStddevPopFields';
  bedroomsMax?: Maybe<Scalars['Float']>;
  bedroomsMin?: Maybe<Scalars['Float']>;
  livingAreaMax?: Maybe<Scalars['Float']>;
  livingAreaMin?: Maybe<Scalars['Float']>;
  monthlyNetRentMax?: Maybe<Scalars['Float']>;
  monthlyNetRentMin?: Maybe<Scalars['Float']>;
  monthlyTotalRentMax?: Maybe<Scalars['Float']>;
  monthlyTotalRentMin?: Maybe<Scalars['Float']>;
  plotSurfaceMax?: Maybe<Scalars['Float']>;
  plotSurfaceMin?: Maybe<Scalars['Float']>;
  priceMax?: Maybe<Scalars['Float']>;
  priceMin?: Maybe<Scalars['Float']>;
  roomsMax?: Maybe<Scalars['Float']>;
  roomsMin?: Maybe<Scalars['Float']>;
  totalSurfaceMax?: Maybe<Scalars['Float']>;
  totalSurfaceMin?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type DbSnapshotBuyerToRentSpecificationStddevSampFields = {
  __typename?: 'DbSnapshotBuyerToRentSpecificationStddevSampFields';
  bedroomsMax?: Maybe<Scalars['Float']>;
  bedroomsMin?: Maybe<Scalars['Float']>;
  livingAreaMax?: Maybe<Scalars['Float']>;
  livingAreaMin?: Maybe<Scalars['Float']>;
  monthlyNetRentMax?: Maybe<Scalars['Float']>;
  monthlyNetRentMin?: Maybe<Scalars['Float']>;
  monthlyTotalRentMax?: Maybe<Scalars['Float']>;
  monthlyTotalRentMin?: Maybe<Scalars['Float']>;
  plotSurfaceMax?: Maybe<Scalars['Float']>;
  plotSurfaceMin?: Maybe<Scalars['Float']>;
  priceMax?: Maybe<Scalars['Float']>;
  priceMin?: Maybe<Scalars['Float']>;
  roomsMax?: Maybe<Scalars['Float']>;
  roomsMin?: Maybe<Scalars['Float']>;
  totalSurfaceMax?: Maybe<Scalars['Float']>;
  totalSurfaceMin?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "db_snapshot_buyer_to_rent_specification" */
export type DbSnapshotBuyerToRentSpecificationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbSnapshotBuyerToRentSpecificationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbSnapshotBuyerToRentSpecificationStreamCursorValueInput = {
  area?: InputMaybe<Scalars['String']>;
  bedroomsMax?: InputMaybe<Scalars['Int']>;
  bedroomsMin?: InputMaybe<Scalars['Int']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<CurrencyEnum>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  district?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  legacyAreaIds?: InputMaybe<Array<Scalars['Int']>>;
  legacyAreaNames?: InputMaybe<Array<Scalars['String']>>;
  legacyDistrictIds?: InputMaybe<Array<Scalars['Int']>>;
  legacyDistrictNames?: InputMaybe<Array<Scalars['String']>>;
  listingShortTermRental?: InputMaybe<Scalars['Boolean']>;
  livingAreaMax?: InputMaybe<Scalars['Int']>;
  livingAreaMin?: InputMaybe<Scalars['Int']>;
  monthlyNetRentMax?: InputMaybe<Scalars['Int']>;
  monthlyNetRentMin?: InputMaybe<Scalars['Int']>;
  monthlyTotalRentMax?: InputMaybe<Scalars['Int']>;
  monthlyTotalRentMin?: InputMaybe<Scalars['Int']>;
  placeIds?: InputMaybe<Array<Scalars['String']>>;
  plotSurfaceMax?: InputMaybe<Scalars['Int']>;
  plotSurfaceMin?: InputMaybe<Scalars['Int']>;
  priceMax?: InputMaybe<Scalars['Int']>;
  priceMin?: InputMaybe<Scalars['Int']>;
  roomsMax?: InputMaybe<Scalars['Int']>;
  roomsMin?: InputMaybe<Scalars['Int']>;
  subTypes?: InputMaybe<Array<Scalars['String']>>;
  totalSurfaceMax?: InputMaybe<Scalars['Int']>;
  totalSurfaceMin?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<AssetTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  variations?: InputMaybe<Array<Scalars['String']>>;
};

/** aggregate sum on columns */
export type DbSnapshotBuyerToRentSpecificationSumFields = {
  __typename?: 'DbSnapshotBuyerToRentSpecificationSumFields';
  bedroomsMax?: Maybe<Scalars['Int']>;
  bedroomsMin?: Maybe<Scalars['Int']>;
  livingAreaMax?: Maybe<Scalars['Int']>;
  livingAreaMin?: Maybe<Scalars['Int']>;
  monthlyNetRentMax?: Maybe<Scalars['Int']>;
  monthlyNetRentMin?: Maybe<Scalars['Int']>;
  monthlyTotalRentMax?: Maybe<Scalars['Int']>;
  monthlyTotalRentMin?: Maybe<Scalars['Int']>;
  plotSurfaceMax?: Maybe<Scalars['Int']>;
  plotSurfaceMin?: Maybe<Scalars['Int']>;
  priceMax?: Maybe<Scalars['Int']>;
  priceMin?: Maybe<Scalars['Int']>;
  roomsMax?: Maybe<Scalars['Int']>;
  roomsMin?: Maybe<Scalars['Int']>;
  totalSurfaceMax?: Maybe<Scalars['Int']>;
  totalSurfaceMin?: Maybe<Scalars['Int']>;
};

/** aggregate varPop on columns */
export type DbSnapshotBuyerToRentSpecificationVarPopFields = {
  __typename?: 'DbSnapshotBuyerToRentSpecificationVarPopFields';
  bedroomsMax?: Maybe<Scalars['Float']>;
  bedroomsMin?: Maybe<Scalars['Float']>;
  livingAreaMax?: Maybe<Scalars['Float']>;
  livingAreaMin?: Maybe<Scalars['Float']>;
  monthlyNetRentMax?: Maybe<Scalars['Float']>;
  monthlyNetRentMin?: Maybe<Scalars['Float']>;
  monthlyTotalRentMax?: Maybe<Scalars['Float']>;
  monthlyTotalRentMin?: Maybe<Scalars['Float']>;
  plotSurfaceMax?: Maybe<Scalars['Float']>;
  plotSurfaceMin?: Maybe<Scalars['Float']>;
  priceMax?: Maybe<Scalars['Float']>;
  priceMin?: Maybe<Scalars['Float']>;
  roomsMax?: Maybe<Scalars['Float']>;
  roomsMin?: Maybe<Scalars['Float']>;
  totalSurfaceMax?: Maybe<Scalars['Float']>;
  totalSurfaceMin?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type DbSnapshotBuyerToRentSpecificationVarSampFields = {
  __typename?: 'DbSnapshotBuyerToRentSpecificationVarSampFields';
  bedroomsMax?: Maybe<Scalars['Float']>;
  bedroomsMin?: Maybe<Scalars['Float']>;
  livingAreaMax?: Maybe<Scalars['Float']>;
  livingAreaMin?: Maybe<Scalars['Float']>;
  monthlyNetRentMax?: Maybe<Scalars['Float']>;
  monthlyNetRentMin?: Maybe<Scalars['Float']>;
  monthlyTotalRentMax?: Maybe<Scalars['Float']>;
  monthlyTotalRentMin?: Maybe<Scalars['Float']>;
  plotSurfaceMax?: Maybe<Scalars['Float']>;
  plotSurfaceMin?: Maybe<Scalars['Float']>;
  priceMax?: Maybe<Scalars['Float']>;
  priceMin?: Maybe<Scalars['Float']>;
  roomsMax?: Maybe<Scalars['Float']>;
  roomsMin?: Maybe<Scalars['Float']>;
  totalSurfaceMax?: Maybe<Scalars['Float']>;
  totalSurfaceMin?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type DbSnapshotBuyerToRentSpecificationVarianceFields = {
  __typename?: 'DbSnapshotBuyerToRentSpecificationVarianceFields';
  bedroomsMax?: Maybe<Scalars['Float']>;
  bedroomsMin?: Maybe<Scalars['Float']>;
  livingAreaMax?: Maybe<Scalars['Float']>;
  livingAreaMin?: Maybe<Scalars['Float']>;
  monthlyNetRentMax?: Maybe<Scalars['Float']>;
  monthlyNetRentMin?: Maybe<Scalars['Float']>;
  monthlyTotalRentMax?: Maybe<Scalars['Float']>;
  monthlyTotalRentMin?: Maybe<Scalars['Float']>;
  plotSurfaceMax?: Maybe<Scalars['Float']>;
  plotSurfaceMin?: Maybe<Scalars['Float']>;
  priceMax?: Maybe<Scalars['Float']>;
  priceMin?: Maybe<Scalars['Float']>;
  roomsMax?: Maybe<Scalars['Float']>;
  roomsMin?: Maybe<Scalars['Float']>;
  totalSurfaceMax?: Maybe<Scalars['Float']>;
  totalSurfaceMin?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "db.snapshot_custom_search_demand_polygon" */
export type DbSnapshotCustomSearchDemandPolygon = {
  __typename?: 'DbSnapshotCustomSearchDemandPolygon';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  num: Scalars['bigint'];
  polygon: Scalars['geometry'];
  snapshotBuyerToRentSpecificationId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.snapshot_custom_search_demand_polygon" */
export type DbSnapshotCustomSearchDemandPolygonAggregateOrderBy = {
  avg?: InputMaybe<DbSnapshotCustomSearchDemandPolygonAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbSnapshotCustomSearchDemandPolygonMaxOrderBy>;
  min?: InputMaybe<DbSnapshotCustomSearchDemandPolygonMinOrderBy>;
  stddev?: InputMaybe<DbSnapshotCustomSearchDemandPolygonStddevOrderBy>;
  stddevPop?: InputMaybe<DbSnapshotCustomSearchDemandPolygonStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbSnapshotCustomSearchDemandPolygonStddevSampOrderBy>;
  sum?: InputMaybe<DbSnapshotCustomSearchDemandPolygonSumOrderBy>;
  varPop?: InputMaybe<DbSnapshotCustomSearchDemandPolygonVarPopOrderBy>;
  varSamp?: InputMaybe<DbSnapshotCustomSearchDemandPolygonVarSampOrderBy>;
  variance?: InputMaybe<DbSnapshotCustomSearchDemandPolygonVarianceOrderBy>;
};

/** order by avg() on columns of table "db.snapshot_custom_search_demand_polygon" */
export type DbSnapshotCustomSearchDemandPolygonAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.snapshot_custom_search_demand_polygon". All fields are combined with a logical 'AND'. */
export type DbSnapshotCustomSearchDemandPolygonBoolExp = {
  _and?: InputMaybe<Array<DbSnapshotCustomSearchDemandPolygonBoolExp>>;
  _not?: InputMaybe<DbSnapshotCustomSearchDemandPolygonBoolExp>;
  _or?: InputMaybe<Array<DbSnapshotCustomSearchDemandPolygonBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  polygon?: InputMaybe<GeometryComparisonExp>;
  snapshotBuyerToRentSpecificationId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.snapshot_custom_search_demand_polygon" */
export type DbSnapshotCustomSearchDemandPolygonMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  snapshotBuyerToRentSpecificationId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.snapshot_custom_search_demand_polygon" */
export type DbSnapshotCustomSearchDemandPolygonMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  snapshotBuyerToRentSpecificationId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.snapshot_custom_search_demand_polygon". */
export type DbSnapshotCustomSearchDemandPolygonOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  polygon?: InputMaybe<OrderBy>;
  snapshotBuyerToRentSpecificationId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.snapshot_custom_search_demand_polygon" */
export type DbSnapshotCustomSearchDemandPolygonSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'num'
  /** column name */
  | 'polygon'
  /** column name */
  | 'snapshotBuyerToRentSpecificationId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.snapshot_custom_search_demand_polygon" */
export type DbSnapshotCustomSearchDemandPolygonStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.snapshot_custom_search_demand_polygon" */
export type DbSnapshotCustomSearchDemandPolygonStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.snapshot_custom_search_demand_polygon" */
export type DbSnapshotCustomSearchDemandPolygonStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_snapshot_custom_search_demand_polygon" */
export type DbSnapshotCustomSearchDemandPolygonStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbSnapshotCustomSearchDemandPolygonStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbSnapshotCustomSearchDemandPolygonStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  polygon?: InputMaybe<Scalars['geometry']>;
  snapshotBuyerToRentSpecificationId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.snapshot_custom_search_demand_polygon" */
export type DbSnapshotCustomSearchDemandPolygonSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.snapshot_custom_search_demand_polygon" */
export type DbSnapshotCustomSearchDemandPolygonVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.snapshot_custom_search_demand_polygon" */
export type DbSnapshotCustomSearchDemandPolygonVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.snapshot_custom_search_demand_polygon" */
export type DbSnapshotCustomSearchDemandPolygonVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.snapshot_email_address" */
export type DbSnapshotEmailAddress = {
  __typename?: 'DbSnapshotEmailAddress';
  address: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  snapshotLeadId: Scalars['String'];
  type: EmailAddressTypeEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.snapshot_email_address" */
export type DbSnapshotEmailAddressAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbSnapshotEmailAddressMaxOrderBy>;
  min?: InputMaybe<DbSnapshotEmailAddressMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.snapshot_email_address". All fields are combined with a logical 'AND'. */
export type DbSnapshotEmailAddressBoolExp = {
  _and?: InputMaybe<Array<DbSnapshotEmailAddressBoolExp>>;
  _not?: InputMaybe<DbSnapshotEmailAddressBoolExp>;
  _or?: InputMaybe<Array<DbSnapshotEmailAddressBoolExp>>;
  address?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  snapshotLeadId?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<EnumsEmailAddressTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.snapshot_email_address" */
export type DbSnapshotEmailAddressMaxOrderBy = {
  address?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  snapshotLeadId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.snapshot_email_address" */
export type DbSnapshotEmailAddressMinOrderBy = {
  address?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  snapshotLeadId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.snapshot_email_address". */
export type DbSnapshotEmailAddressOrderBy = {
  address?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  snapshotLeadId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.snapshot_email_address" */
export type DbSnapshotEmailAddressSelectColumn =
  /** column name */
  | 'address'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'snapshotLeadId'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** columns and relationships of "db.snapshot_lead" */
export type DbSnapshotLead = {
  __typename?: 'DbSnapshotLead';
  contactFirstName?: Maybe<Scalars['String']>;
  contactLastName?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  customerMessage?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  emailAddresses: Array<DbSnapshotEmailAddress>;
  id: Scalars['String'];
  intentType: IntentTypeEnum;
  /** An object relationship */
  intentTypeByIntentType: EnumsIntentType;
  normalizedLowerContactFirstName?: Maybe<Scalars['String']>;
  normalizedLowerContactLastName?: Maybe<Scalars['String']>;
  personalNote?: Maybe<Scalars['String']>;
  /** An array relationship */
  phoneNumbers: Array<DbSnapshotPhoneNumber>;
  /** An object relationship */
  preferredEmailAddress?: Maybe<DbSnapshotEmailAddress>;
  preferredEmailAddressId?: Maybe<Scalars['String']>;
  /** An object relationship */
  preferredPhoneNumber?: Maybe<DbSnapshotPhoneNumber>;
  preferredPhoneNumberId?: Maybe<Scalars['String']>;
  priority?: Maybe<PriorityEnum>;
  /** An object relationship */
  priorityByPriority?: Maybe<EnumsPriority>;
  propertyId?: Maybe<Scalars['String']>;
  /** An array relationship */
  referrals: Array<DbReferral>;
  /** An aggregate relationship */
  referralsAggregate: DbReferralAggregate;
  /** An object relationship */
  snapshotBuyerToRentSpecification?: Maybe<DbSnapshotBuyerToRentSpecification>;
  snapshotBuyerToRentSpecificationId?: Maybe<Scalars['String']>;
  /** An object relationship */
  snapshotSellerRentOutSpecification?: Maybe<DbSnapshotSellerRentOutSpecification>;
  snapshotSellerRentOutSpecificationId?: Maybe<Scalars['String']>;
  timeFrame?: Maybe<TimeFrameEnum>;
  /** An object relationship */
  timeFrameByTimeFrame?: Maybe<EnumsTimeFrame>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** columns and relationships of "db.snapshot_lead" */
export type DbSnapshotLeadEmailAddressesArgs = {
  distinctOn?: InputMaybe<Array<DbSnapshotEmailAddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSnapshotEmailAddressOrderBy>>;
  where?: InputMaybe<DbSnapshotEmailAddressBoolExp>;
};

/** columns and relationships of "db.snapshot_lead" */
export type DbSnapshotLeadPhoneNumbersArgs = {
  distinctOn?: InputMaybe<Array<DbSnapshotPhoneNumberSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSnapshotPhoneNumberOrderBy>>;
  where?: InputMaybe<DbSnapshotPhoneNumberBoolExp>;
};

/** columns and relationships of "db.snapshot_lead" */
export type DbSnapshotLeadReferralsArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

/** columns and relationships of "db.snapshot_lead" */
export type DbSnapshotLeadReferralsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

/** aggregated selection of "db.snapshot_lead" */
export type DbSnapshotLeadAggregate = {
  __typename?: 'DbSnapshotLeadAggregate';
  aggregate?: Maybe<DbSnapshotLeadAggregateFields>;
  nodes: Array<DbSnapshotLead>;
};

export type DbSnapshotLeadAggregateBoolExp = {
  bool_and?: InputMaybe<DbSnapshotLeadAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbSnapshotLeadAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbSnapshotLeadAggregateBoolExpCount>;
};

/** aggregate fields of "db.snapshot_lead" */
export type DbSnapshotLeadAggregateFields = {
  __typename?: 'DbSnapshotLeadAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<DbSnapshotLeadMaxFields>;
  min?: Maybe<DbSnapshotLeadMinFields>;
};

/** aggregate fields of "db.snapshot_lead" */
export type DbSnapshotLeadAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbSnapshotLeadSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.snapshot_lead" */
export type DbSnapshotLeadAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbSnapshotLeadMaxOrderBy>;
  min?: InputMaybe<DbSnapshotLeadMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.snapshot_lead". All fields are combined with a logical 'AND'. */
export type DbSnapshotLeadBoolExp = {
  _and?: InputMaybe<Array<DbSnapshotLeadBoolExp>>;
  _not?: InputMaybe<DbSnapshotLeadBoolExp>;
  _or?: InputMaybe<Array<DbSnapshotLeadBoolExp>>;
  contactFirstName?: InputMaybe<StringComparisonExp>;
  contactLastName?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  customerMessage?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  emailAddresses?: InputMaybe<DbSnapshotEmailAddressBoolExp>;
  id?: InputMaybe<StringComparisonExp>;
  intentType?: InputMaybe<EnumsIntentTypeEnumComparisonExp>;
  intentTypeByIntentType?: InputMaybe<EnumsIntentTypeBoolExp>;
  normalizedLowerContactFirstName?: InputMaybe<StringComparisonExp>;
  normalizedLowerContactLastName?: InputMaybe<StringComparisonExp>;
  personalNote?: InputMaybe<StringComparisonExp>;
  phoneNumbers?: InputMaybe<DbSnapshotPhoneNumberBoolExp>;
  preferredEmailAddress?: InputMaybe<DbSnapshotEmailAddressBoolExp>;
  preferredEmailAddressId?: InputMaybe<StringComparisonExp>;
  preferredPhoneNumber?: InputMaybe<DbSnapshotPhoneNumberBoolExp>;
  preferredPhoneNumberId?: InputMaybe<StringComparisonExp>;
  priority?: InputMaybe<EnumsPriorityEnumComparisonExp>;
  priorityByPriority?: InputMaybe<EnumsPriorityBoolExp>;
  propertyId?: InputMaybe<StringComparisonExp>;
  referrals?: InputMaybe<DbReferralBoolExp>;
  referralsAggregate?: InputMaybe<DbReferralAggregateBoolExp>;
  snapshotBuyerToRentSpecification?: InputMaybe<DbSnapshotBuyerToRentSpecificationBoolExp>;
  snapshotBuyerToRentSpecificationId?: InputMaybe<StringComparisonExp>;
  snapshotSellerRentOutSpecification?: InputMaybe<DbSnapshotSellerRentOutSpecificationBoolExp>;
  snapshotSellerRentOutSpecificationId?: InputMaybe<StringComparisonExp>;
  timeFrame?: InputMaybe<EnumsTimeFrameEnumComparisonExp>;
  timeFrameByTimeFrame?: InputMaybe<EnumsTimeFrameBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbSnapshotLeadMaxFields = {
  __typename?: 'DbSnapshotLeadMaxFields';
  contactFirstName?: Maybe<Scalars['String']>;
  contactLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  customerMessage?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  normalizedLowerContactFirstName?: Maybe<Scalars['String']>;
  normalizedLowerContactLastName?: Maybe<Scalars['String']>;
  personalNote?: Maybe<Scalars['String']>;
  preferredEmailAddressId?: Maybe<Scalars['String']>;
  preferredPhoneNumberId?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['String']>;
  snapshotBuyerToRentSpecificationId?: Maybe<Scalars['String']>;
  snapshotSellerRentOutSpecificationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.snapshot_lead" */
export type DbSnapshotLeadMaxOrderBy = {
  contactFirstName?: InputMaybe<OrderBy>;
  contactLastName?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  customerMessage?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  normalizedLowerContactFirstName?: InputMaybe<OrderBy>;
  normalizedLowerContactLastName?: InputMaybe<OrderBy>;
  personalNote?: InputMaybe<OrderBy>;
  preferredEmailAddressId?: InputMaybe<OrderBy>;
  preferredPhoneNumberId?: InputMaybe<OrderBy>;
  propertyId?: InputMaybe<OrderBy>;
  snapshotBuyerToRentSpecificationId?: InputMaybe<OrderBy>;
  snapshotSellerRentOutSpecificationId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbSnapshotLeadMinFields = {
  __typename?: 'DbSnapshotLeadMinFields';
  contactFirstName?: Maybe<Scalars['String']>;
  contactLastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  customerMessage?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  normalizedLowerContactFirstName?: Maybe<Scalars['String']>;
  normalizedLowerContactLastName?: Maybe<Scalars['String']>;
  personalNote?: Maybe<Scalars['String']>;
  preferredEmailAddressId?: Maybe<Scalars['String']>;
  preferredPhoneNumberId?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['String']>;
  snapshotBuyerToRentSpecificationId?: Maybe<Scalars['String']>;
  snapshotSellerRentOutSpecificationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.snapshot_lead" */
export type DbSnapshotLeadMinOrderBy = {
  contactFirstName?: InputMaybe<OrderBy>;
  contactLastName?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  customerMessage?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  normalizedLowerContactFirstName?: InputMaybe<OrderBy>;
  normalizedLowerContactLastName?: InputMaybe<OrderBy>;
  personalNote?: InputMaybe<OrderBy>;
  preferredEmailAddressId?: InputMaybe<OrderBy>;
  preferredPhoneNumberId?: InputMaybe<OrderBy>;
  propertyId?: InputMaybe<OrderBy>;
  snapshotBuyerToRentSpecificationId?: InputMaybe<OrderBy>;
  snapshotSellerRentOutSpecificationId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.snapshot_lead". */
export type DbSnapshotLeadOrderBy = {
  contactFirstName?: InputMaybe<OrderBy>;
  contactLastName?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  customerMessage?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  emailAddressesAggregate?: InputMaybe<DbSnapshotEmailAddressAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  intentType?: InputMaybe<OrderBy>;
  intentTypeByIntentType?: InputMaybe<EnumsIntentTypeOrderBy>;
  normalizedLowerContactFirstName?: InputMaybe<OrderBy>;
  normalizedLowerContactLastName?: InputMaybe<OrderBy>;
  personalNote?: InputMaybe<OrderBy>;
  phoneNumbersAggregate?: InputMaybe<DbSnapshotPhoneNumberAggregateOrderBy>;
  preferredEmailAddress?: InputMaybe<DbSnapshotEmailAddressOrderBy>;
  preferredEmailAddressId?: InputMaybe<OrderBy>;
  preferredPhoneNumber?: InputMaybe<DbSnapshotPhoneNumberOrderBy>;
  preferredPhoneNumberId?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  priorityByPriority?: InputMaybe<EnumsPriorityOrderBy>;
  propertyId?: InputMaybe<OrderBy>;
  referralsAggregate?: InputMaybe<DbReferralAggregateOrderBy>;
  snapshotBuyerToRentSpecification?: InputMaybe<DbSnapshotBuyerToRentSpecificationOrderBy>;
  snapshotBuyerToRentSpecificationId?: InputMaybe<OrderBy>;
  snapshotSellerRentOutSpecification?: InputMaybe<DbSnapshotSellerRentOutSpecificationOrderBy>;
  snapshotSellerRentOutSpecificationId?: InputMaybe<OrderBy>;
  timeFrame?: InputMaybe<OrderBy>;
  timeFrameByTimeFrame?: InputMaybe<EnumsTimeFrameOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.snapshot_lead" */
export type DbSnapshotLeadSelectColumn =
  /** column name */
  | 'contactFirstName'
  /** column name */
  | 'contactLastName'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'customerMessage'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'intentType'
  /** column name */
  | 'normalizedLowerContactFirstName'
  /** column name */
  | 'normalizedLowerContactLastName'
  /** column name */
  | 'personalNote'
  /** column name */
  | 'preferredEmailAddressId'
  /** column name */
  | 'preferredPhoneNumberId'
  /** column name */
  | 'priority'
  /** column name */
  | 'propertyId'
  /** column name */
  | 'snapshotBuyerToRentSpecificationId'
  /** column name */
  | 'snapshotSellerRentOutSpecificationId'
  /** column name */
  | 'timeFrame'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbSnapshotLeadAggregateBoolExpBool_andArgumentsColumns" columns of table "db.snapshot_lead" */
export type DbSnapshotLeadSelectColumnDbSnapshotLeadAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbSnapshotLeadAggregateBoolExpBool_orArgumentsColumns" columns of table "db.snapshot_lead" */
export type DbSnapshotLeadSelectColumnDbSnapshotLeadAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** columns and relationships of "db.snapshot_phone_number" */
export type DbSnapshotPhoneNumber = {
  __typename?: 'DbSnapshotPhoneNumber';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  internationalNumber?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  snapshotLeadId: Scalars['String'];
  type: PhoneNumberTypeEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** order by aggregate values of table "db.snapshot_phone_number" */
export type DbSnapshotPhoneNumberAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbSnapshotPhoneNumberMaxOrderBy>;
  min?: InputMaybe<DbSnapshotPhoneNumberMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.snapshot_phone_number". All fields are combined with a logical 'AND'. */
export type DbSnapshotPhoneNumberBoolExp = {
  _and?: InputMaybe<Array<DbSnapshotPhoneNumberBoolExp>>;
  _not?: InputMaybe<DbSnapshotPhoneNumberBoolExp>;
  _or?: InputMaybe<Array<DbSnapshotPhoneNumberBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  internationalNumber?: InputMaybe<StringComparisonExp>;
  number?: InputMaybe<StringComparisonExp>;
  snapshotLeadId?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<EnumsPhoneNumberTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.snapshot_phone_number" */
export type DbSnapshotPhoneNumberMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  internationalNumber?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
  snapshotLeadId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.snapshot_phone_number" */
export type DbSnapshotPhoneNumberMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  internationalNumber?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
  snapshotLeadId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.snapshot_phone_number". */
export type DbSnapshotPhoneNumberOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  internationalNumber?: InputMaybe<OrderBy>;
  number?: InputMaybe<OrderBy>;
  snapshotLeadId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.snapshot_phone_number" */
export type DbSnapshotPhoneNumberSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'internationalNumber'
  /** column name */
  | 'number'
  /** column name */
  | 'snapshotLeadId'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** columns and relationships of "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecification = {
  __typename?: 'DbSnapshotSellerRentOutSpecification';
  /** An object relationship */
  assetCondition?: Maybe<EnumsAssetCondition>;
  /** An object relationship */
  assetSubType?: Maybe<EnumsAssetSubType>;
  /** An object relationship */
  assetType?: Maybe<EnumsAssetType>;
  balcony?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  condition?: Maybe<AssetConditionEnum>;
  constructionYear?: Maybe<Scalars['Int']>;
  /** An object relationship */
  contact?: Maybe<DbContact>;
  coordinateLat?: Maybe<Scalars['float8']>;
  coordinateLon?: Maybe<Scalars['float8']>;
  /** An object relationship */
  country?: Maybe<EnumsCountry>;
  countryCode?: Maybe<CountryEnum>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  /** An object relationship */
  currency: EnumsCurrency;
  /** An object relationship */
  currencyByRentPriceUnit: EnumsCurrency;
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  elevator?: Maybe<Scalars['Boolean']>;
  floor?: Maybe<Scalars['Int']>;
  garage?: Maybe<Scalars['Boolean']>;
  garden?: Maybe<Scalars['Boolean']>;
  guestToilet?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isOccupiedByOwner: Scalars['Boolean'];
  livingArea?: Maybe<Scalars['float8']>;
  numberOfBathrooms?: Maybe<Scalars['Float']>;
  numberOfBedrooms?: Maybe<Scalars['Float']>;
  numberOfMasterBathrooms?: Maybe<Scalars['Float']>;
  numberOfRooms?: Maybe<Scalars['Float']>;
  occupationStatus?: Maybe<PropertyOccupationStatusEnum>;
  ownerDescription?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  ownershipStatus?: Maybe<PropertyOwnershipStatusEnum>;
  parking?: Maybe<Scalars['Boolean']>;
  plotArea?: Maybe<Scalars['float8']>;
  postalCode?: Maybe<Scalars['String']>;
  priceRangeFrom?: Maybe<Scalars['numeric']>;
  priceRangeTo?: Maybe<Scalars['numeric']>;
  priceRangeUnit: CurrencyEnum;
  /** An object relationship */
  propertyOccupationStatus?: Maybe<EnumsPropertyOccupationStatus>;
  /** An object relationship */
  propertyOwnershipStatus?: Maybe<EnumsPropertyOwnershipStatus>;
  rentPriceUnit: CurrencyEnum;
  rentRangeFrom?: Maybe<Scalars['numeric']>;
  rentRangeTo?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  snapshotLeads: Array<DbSnapshotLead>;
  /** An aggregate relationship */
  snapshotLeadsAggregate: DbSnapshotLeadAggregate;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  subType?: Maybe<AssetSubTypeEnum>;
  surfaceTotal?: Maybe<Scalars['float8']>;
  surfaceUsable?: Maybe<Scalars['float8']>;
  swimmingPool?: Maybe<Scalars['Boolean']>;
  totalFloors?: Maybe<Scalars['Int']>;
  type?: Maybe<AssetTypeEnum>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  variation?: Maybe<AssetVariationEnum>;
};

/** columns and relationships of "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationSnapshotLeadsArgs = {
  distinctOn?: InputMaybe<Array<DbSnapshotLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSnapshotLeadOrderBy>>;
  where?: InputMaybe<DbSnapshotLeadBoolExp>;
};

/** columns and relationships of "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationSnapshotLeadsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbSnapshotLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSnapshotLeadOrderBy>>;
  where?: InputMaybe<DbSnapshotLeadBoolExp>;
};

/** aggregated selection of "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationAggregate = {
  __typename?: 'DbSnapshotSellerRentOutSpecificationAggregate';
  aggregate?: Maybe<DbSnapshotSellerRentOutSpecificationAggregateFields>;
  nodes: Array<DbSnapshotSellerRentOutSpecification>;
};

export type DbSnapshotSellerRentOutSpecificationAggregateBoolExp = {
  avg?: InputMaybe<DbSnapshotSellerRentOutSpecificationAggregateBoolExpAvg>;
  bool_and?: InputMaybe<DbSnapshotSellerRentOutSpecificationAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbSnapshotSellerRentOutSpecificationAggregateBoolExpBoolOr>;
  corr?: InputMaybe<DbSnapshotSellerRentOutSpecificationAggregateBoolExpCorr>;
  count?: InputMaybe<DbSnapshotSellerRentOutSpecificationAggregateBoolExpCount>;
  covar_samp?: InputMaybe<DbSnapshotSellerRentOutSpecificationAggregateBoolExpCovarSamp>;
  max?: InputMaybe<DbSnapshotSellerRentOutSpecificationAggregateBoolExpMax>;
  min?: InputMaybe<DbSnapshotSellerRentOutSpecificationAggregateBoolExpMin>;
  stddev_samp?: InputMaybe<DbSnapshotSellerRentOutSpecificationAggregateBoolExpStddevSamp>;
  sum?: InputMaybe<DbSnapshotSellerRentOutSpecificationAggregateBoolExpSum>;
  var_samp?: InputMaybe<DbSnapshotSellerRentOutSpecificationAggregateBoolExpVarSamp>;
};

/** aggregate fields of "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationAggregateFields = {
  __typename?: 'DbSnapshotSellerRentOutSpecificationAggregateFields';
  avg?: Maybe<DbSnapshotSellerRentOutSpecificationAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbSnapshotSellerRentOutSpecificationMaxFields>;
  min?: Maybe<DbSnapshotSellerRentOutSpecificationMinFields>;
  stddev?: Maybe<DbSnapshotSellerRentOutSpecificationStddevFields>;
  stddevPop?: Maybe<DbSnapshotSellerRentOutSpecificationStddevPopFields>;
  stddevSamp?: Maybe<DbSnapshotSellerRentOutSpecificationStddevSampFields>;
  sum?: Maybe<DbSnapshotSellerRentOutSpecificationSumFields>;
  varPop?: Maybe<DbSnapshotSellerRentOutSpecificationVarPopFields>;
  varSamp?: Maybe<DbSnapshotSellerRentOutSpecificationVarSampFields>;
  variance?: Maybe<DbSnapshotSellerRentOutSpecificationVarianceFields>;
};

/** aggregate fields of "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbSnapshotSellerRentOutSpecificationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationAggregateOrderBy = {
  avg?: InputMaybe<DbSnapshotSellerRentOutSpecificationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbSnapshotSellerRentOutSpecificationMaxOrderBy>;
  min?: InputMaybe<DbSnapshotSellerRentOutSpecificationMinOrderBy>;
  stddev?: InputMaybe<DbSnapshotSellerRentOutSpecificationStddevOrderBy>;
  stddevPop?: InputMaybe<DbSnapshotSellerRentOutSpecificationStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbSnapshotSellerRentOutSpecificationStddevSampOrderBy>;
  sum?: InputMaybe<DbSnapshotSellerRentOutSpecificationSumOrderBy>;
  varPop?: InputMaybe<DbSnapshotSellerRentOutSpecificationVarPopOrderBy>;
  varSamp?: InputMaybe<DbSnapshotSellerRentOutSpecificationVarSampOrderBy>;
  variance?: InputMaybe<DbSnapshotSellerRentOutSpecificationVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbSnapshotSellerRentOutSpecificationAvgFields = {
  __typename?: 'DbSnapshotSellerRentOutSpecificationAvgFields';
  constructionYear?: Maybe<Scalars['Float']>;
  coordinateLat?: Maybe<Scalars['Float']>;
  coordinateLon?: Maybe<Scalars['Float']>;
  floor?: Maybe<Scalars['Float']>;
  livingArea?: Maybe<Scalars['Float']>;
  numberOfBathrooms?: Maybe<Scalars['Float']>;
  numberOfBedrooms?: Maybe<Scalars['Float']>;
  numberOfMasterBathrooms?: Maybe<Scalars['Float']>;
  numberOfRooms?: Maybe<Scalars['Float']>;
  plotArea?: Maybe<Scalars['Float']>;
  priceRangeFrom?: Maybe<Scalars['Float']>;
  priceRangeTo?: Maybe<Scalars['Float']>;
  rentRangeFrom?: Maybe<Scalars['Float']>;
  rentRangeTo?: Maybe<Scalars['Float']>;
  surfaceTotal?: Maybe<Scalars['Float']>;
  surfaceUsable?: Maybe<Scalars['Float']>;
  totalFloors?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationAvgOrderBy = {
  constructionYear?: InputMaybe<OrderBy>;
  coordinateLat?: InputMaybe<OrderBy>;
  coordinateLon?: InputMaybe<OrderBy>;
  floor?: InputMaybe<OrderBy>;
  livingArea?: InputMaybe<OrderBy>;
  numberOfBathrooms?: InputMaybe<OrderBy>;
  numberOfBedrooms?: InputMaybe<OrderBy>;
  numberOfMasterBathrooms?: InputMaybe<OrderBy>;
  numberOfRooms?: InputMaybe<OrderBy>;
  plotArea?: InputMaybe<OrderBy>;
  priceRangeFrom?: InputMaybe<OrderBy>;
  priceRangeTo?: InputMaybe<OrderBy>;
  rentRangeFrom?: InputMaybe<OrderBy>;
  rentRangeTo?: InputMaybe<OrderBy>;
  surfaceTotal?: InputMaybe<OrderBy>;
  surfaceUsable?: InputMaybe<OrderBy>;
  totalFloors?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.snapshot_seller_rent_out_specification". All fields are combined with a logical 'AND'. */
export type DbSnapshotSellerRentOutSpecificationBoolExp = {
  _and?: InputMaybe<Array<DbSnapshotSellerRentOutSpecificationBoolExp>>;
  _not?: InputMaybe<DbSnapshotSellerRentOutSpecificationBoolExp>;
  _or?: InputMaybe<Array<DbSnapshotSellerRentOutSpecificationBoolExp>>;
  assetCondition?: InputMaybe<EnumsAssetConditionBoolExp>;
  assetSubType?: InputMaybe<EnumsAssetSubTypeBoolExp>;
  assetType?: InputMaybe<EnumsAssetTypeBoolExp>;
  balcony?: InputMaybe<BooleanComparisonExp>;
  city?: InputMaybe<StringComparisonExp>;
  condition?: InputMaybe<EnumsAssetConditionEnumComparisonExp>;
  constructionYear?: InputMaybe<IntComparisonExp>;
  contact?: InputMaybe<DbContactBoolExp>;
  coordinateLat?: InputMaybe<Float8ComparisonExp>;
  coordinateLon?: InputMaybe<Float8ComparisonExp>;
  country?: InputMaybe<EnumsCountryBoolExp>;
  countryCode?: InputMaybe<EnumsCountryEnumComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  currency?: InputMaybe<EnumsCurrencyBoolExp>;
  currencyByRentPriceUnit?: InputMaybe<EnumsCurrencyBoolExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  elevator?: InputMaybe<BooleanComparisonExp>;
  floor?: InputMaybe<IntComparisonExp>;
  garage?: InputMaybe<BooleanComparisonExp>;
  garden?: InputMaybe<BooleanComparisonExp>;
  guestToilet?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  isOccupiedByOwner?: InputMaybe<BooleanComparisonExp>;
  livingArea?: InputMaybe<Float8ComparisonExp>;
  numberOfBathrooms?: InputMaybe<FloatComparisonExp>;
  numberOfBedrooms?: InputMaybe<FloatComparisonExp>;
  numberOfMasterBathrooms?: InputMaybe<FloatComparisonExp>;
  numberOfRooms?: InputMaybe<FloatComparisonExp>;
  occupationStatus?: InputMaybe<EnumsPropertyOccupationStatusEnumComparisonExp>;
  ownerDescription?: InputMaybe<StringComparisonExp>;
  ownerId?: InputMaybe<StringComparisonExp>;
  ownershipStatus?: InputMaybe<EnumsPropertyOwnershipStatusEnumComparisonExp>;
  parking?: InputMaybe<BooleanComparisonExp>;
  plotArea?: InputMaybe<Float8ComparisonExp>;
  postalCode?: InputMaybe<StringComparisonExp>;
  priceRangeFrom?: InputMaybe<NumericComparisonExp>;
  priceRangeTo?: InputMaybe<NumericComparisonExp>;
  priceRangeUnit?: InputMaybe<EnumsCurrencyEnumComparisonExp>;
  propertyOccupationStatus?: InputMaybe<EnumsPropertyOccupationStatusBoolExp>;
  propertyOwnershipStatus?: InputMaybe<EnumsPropertyOwnershipStatusBoolExp>;
  rentPriceUnit?: InputMaybe<EnumsCurrencyEnumComparisonExp>;
  rentRangeFrom?: InputMaybe<NumericComparisonExp>;
  rentRangeTo?: InputMaybe<NumericComparisonExp>;
  snapshotLeads?: InputMaybe<DbSnapshotLeadBoolExp>;
  snapshotLeadsAggregate?: InputMaybe<DbSnapshotLeadAggregateBoolExp>;
  streetName?: InputMaybe<StringComparisonExp>;
  streetNumber?: InputMaybe<StringComparisonExp>;
  subType?: InputMaybe<EnumsAssetSubTypeEnumComparisonExp>;
  surfaceTotal?: InputMaybe<Float8ComparisonExp>;
  surfaceUsable?: InputMaybe<Float8ComparisonExp>;
  swimmingPool?: InputMaybe<BooleanComparisonExp>;
  totalFloors?: InputMaybe<IntComparisonExp>;
  type?: InputMaybe<EnumsAssetTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  variation?: InputMaybe<EnumsAssetVariationEnumComparisonExp>;
};

/** aggregate max on columns */
export type DbSnapshotSellerRentOutSpecificationMaxFields = {
  __typename?: 'DbSnapshotSellerRentOutSpecificationMaxFields';
  city?: Maybe<Scalars['String']>;
  constructionYear?: Maybe<Scalars['Int']>;
  coordinateLat?: Maybe<Scalars['float8']>;
  coordinateLon?: Maybe<Scalars['float8']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  floor?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  livingArea?: Maybe<Scalars['float8']>;
  numberOfBathrooms?: Maybe<Scalars['Float']>;
  numberOfBedrooms?: Maybe<Scalars['Float']>;
  numberOfMasterBathrooms?: Maybe<Scalars['Float']>;
  numberOfRooms?: Maybe<Scalars['Float']>;
  ownerDescription?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  plotArea?: Maybe<Scalars['float8']>;
  postalCode?: Maybe<Scalars['String']>;
  priceRangeFrom?: Maybe<Scalars['numeric']>;
  priceRangeTo?: Maybe<Scalars['numeric']>;
  rentRangeFrom?: Maybe<Scalars['numeric']>;
  rentRangeTo?: Maybe<Scalars['numeric']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  surfaceTotal?: Maybe<Scalars['float8']>;
  surfaceUsable?: Maybe<Scalars['float8']>;
  totalFloors?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationMaxOrderBy = {
  city?: InputMaybe<OrderBy>;
  constructionYear?: InputMaybe<OrderBy>;
  coordinateLat?: InputMaybe<OrderBy>;
  coordinateLon?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  floor?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  livingArea?: InputMaybe<OrderBy>;
  numberOfBathrooms?: InputMaybe<OrderBy>;
  numberOfBedrooms?: InputMaybe<OrderBy>;
  numberOfMasterBathrooms?: InputMaybe<OrderBy>;
  numberOfRooms?: InputMaybe<OrderBy>;
  ownerDescription?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
  plotArea?: InputMaybe<OrderBy>;
  postalCode?: InputMaybe<OrderBy>;
  priceRangeFrom?: InputMaybe<OrderBy>;
  priceRangeTo?: InputMaybe<OrderBy>;
  rentRangeFrom?: InputMaybe<OrderBy>;
  rentRangeTo?: InputMaybe<OrderBy>;
  streetName?: InputMaybe<OrderBy>;
  streetNumber?: InputMaybe<OrderBy>;
  surfaceTotal?: InputMaybe<OrderBy>;
  surfaceUsable?: InputMaybe<OrderBy>;
  totalFloors?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbSnapshotSellerRentOutSpecificationMinFields = {
  __typename?: 'DbSnapshotSellerRentOutSpecificationMinFields';
  city?: Maybe<Scalars['String']>;
  constructionYear?: Maybe<Scalars['Int']>;
  coordinateLat?: Maybe<Scalars['float8']>;
  coordinateLon?: Maybe<Scalars['float8']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  floor?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  livingArea?: Maybe<Scalars['float8']>;
  numberOfBathrooms?: Maybe<Scalars['Float']>;
  numberOfBedrooms?: Maybe<Scalars['Float']>;
  numberOfMasterBathrooms?: Maybe<Scalars['Float']>;
  numberOfRooms?: Maybe<Scalars['Float']>;
  ownerDescription?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  plotArea?: Maybe<Scalars['float8']>;
  postalCode?: Maybe<Scalars['String']>;
  priceRangeFrom?: Maybe<Scalars['numeric']>;
  priceRangeTo?: Maybe<Scalars['numeric']>;
  rentRangeFrom?: Maybe<Scalars['numeric']>;
  rentRangeTo?: Maybe<Scalars['numeric']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  surfaceTotal?: Maybe<Scalars['float8']>;
  surfaceUsable?: Maybe<Scalars['float8']>;
  totalFloors?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationMinOrderBy = {
  city?: InputMaybe<OrderBy>;
  constructionYear?: InputMaybe<OrderBy>;
  coordinateLat?: InputMaybe<OrderBy>;
  coordinateLon?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  floor?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  livingArea?: InputMaybe<OrderBy>;
  numberOfBathrooms?: InputMaybe<OrderBy>;
  numberOfBedrooms?: InputMaybe<OrderBy>;
  numberOfMasterBathrooms?: InputMaybe<OrderBy>;
  numberOfRooms?: InputMaybe<OrderBy>;
  ownerDescription?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
  plotArea?: InputMaybe<OrderBy>;
  postalCode?: InputMaybe<OrderBy>;
  priceRangeFrom?: InputMaybe<OrderBy>;
  priceRangeTo?: InputMaybe<OrderBy>;
  rentRangeFrom?: InputMaybe<OrderBy>;
  rentRangeTo?: InputMaybe<OrderBy>;
  streetName?: InputMaybe<OrderBy>;
  streetNumber?: InputMaybe<OrderBy>;
  surfaceTotal?: InputMaybe<OrderBy>;
  surfaceUsable?: InputMaybe<OrderBy>;
  totalFloors?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.snapshot_seller_rent_out_specification". */
export type DbSnapshotSellerRentOutSpecificationOrderBy = {
  assetCondition?: InputMaybe<EnumsAssetConditionOrderBy>;
  assetSubType?: InputMaybe<EnumsAssetSubTypeOrderBy>;
  assetType?: InputMaybe<EnumsAssetTypeOrderBy>;
  balcony?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  condition?: InputMaybe<OrderBy>;
  constructionYear?: InputMaybe<OrderBy>;
  contact?: InputMaybe<DbContactOrderBy>;
  coordinateLat?: InputMaybe<OrderBy>;
  coordinateLon?: InputMaybe<OrderBy>;
  country?: InputMaybe<EnumsCountryOrderBy>;
  countryCode?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  currency?: InputMaybe<EnumsCurrencyOrderBy>;
  currencyByRentPriceUnit?: InputMaybe<EnumsCurrencyOrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  elevator?: InputMaybe<OrderBy>;
  floor?: InputMaybe<OrderBy>;
  garage?: InputMaybe<OrderBy>;
  garden?: InputMaybe<OrderBy>;
  guestToilet?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  isOccupiedByOwner?: InputMaybe<OrderBy>;
  livingArea?: InputMaybe<OrderBy>;
  numberOfBathrooms?: InputMaybe<OrderBy>;
  numberOfBedrooms?: InputMaybe<OrderBy>;
  numberOfMasterBathrooms?: InputMaybe<OrderBy>;
  numberOfRooms?: InputMaybe<OrderBy>;
  occupationStatus?: InputMaybe<OrderBy>;
  ownerDescription?: InputMaybe<OrderBy>;
  ownerId?: InputMaybe<OrderBy>;
  ownershipStatus?: InputMaybe<OrderBy>;
  parking?: InputMaybe<OrderBy>;
  plotArea?: InputMaybe<OrderBy>;
  postalCode?: InputMaybe<OrderBy>;
  priceRangeFrom?: InputMaybe<OrderBy>;
  priceRangeTo?: InputMaybe<OrderBy>;
  priceRangeUnit?: InputMaybe<OrderBy>;
  propertyOccupationStatus?: InputMaybe<EnumsPropertyOccupationStatusOrderBy>;
  propertyOwnershipStatus?: InputMaybe<EnumsPropertyOwnershipStatusOrderBy>;
  rentPriceUnit?: InputMaybe<OrderBy>;
  rentRangeFrom?: InputMaybe<OrderBy>;
  rentRangeTo?: InputMaybe<OrderBy>;
  snapshotLeadsAggregate?: InputMaybe<DbSnapshotLeadAggregateOrderBy>;
  streetName?: InputMaybe<OrderBy>;
  streetNumber?: InputMaybe<OrderBy>;
  subType?: InputMaybe<OrderBy>;
  surfaceTotal?: InputMaybe<OrderBy>;
  surfaceUsable?: InputMaybe<OrderBy>;
  swimmingPool?: InputMaybe<OrderBy>;
  totalFloors?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  variation?: InputMaybe<OrderBy>;
};

/** select columns of table "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationSelectColumn =
  /** column name */
  | 'balcony'
  /** column name */
  | 'city'
  /** column name */
  | 'condition'
  /** column name */
  | 'constructionYear'
  /** column name */
  | 'coordinateLat'
  /** column name */
  | 'coordinateLon'
  /** column name */
  | 'countryCode'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'elevator'
  /** column name */
  | 'floor'
  /** column name */
  | 'garage'
  /** column name */
  | 'garden'
  /** column name */
  | 'guestToilet'
  /** column name */
  | 'id'
  /** column name */
  | 'isOccupiedByOwner'
  /** column name */
  | 'livingArea'
  /** column name */
  | 'numberOfBathrooms'
  /** column name */
  | 'numberOfBedrooms'
  /** column name */
  | 'numberOfMasterBathrooms'
  /** column name */
  | 'numberOfRooms'
  /** column name */
  | 'occupationStatus'
  /** column name */
  | 'ownerDescription'
  /** column name */
  | 'ownerId'
  /** column name */
  | 'ownershipStatus'
  /** column name */
  | 'parking'
  /** column name */
  | 'plotArea'
  /** column name */
  | 'postalCode'
  /** column name */
  | 'priceRangeFrom'
  /** column name */
  | 'priceRangeTo'
  /** column name */
  | 'priceRangeUnit'
  /** column name */
  | 'rentPriceUnit'
  /** column name */
  | 'rentRangeFrom'
  /** column name */
  | 'rentRangeTo'
  /** column name */
  | 'streetName'
  /** column name */
  | 'streetNumber'
  /** column name */
  | 'subType'
  /** column name */
  | 'surfaceTotal'
  /** column name */
  | 'surfaceUsable'
  /** column name */
  | 'swimmingPool'
  /** column name */
  | 'totalFloors'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy'
  /** column name */
  | 'variation';

/** select "dbSnapshotSellerRentOutSpecificationAggregateBoolExpAvgArgumentsColumns" columns of table "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationSelectColumnDbSnapshotSellerRentOutSpecificationAggregateBoolExpAvgArgumentsColumns =
  /** column name */
  | 'coordinateLat'
  /** column name */
  | 'coordinateLon'
  /** column name */
  | 'livingArea'
  /** column name */
  | 'plotArea'
  /** column name */
  | 'surfaceTotal'
  /** column name */
  | 'surfaceUsable';

/** select "dbSnapshotSellerRentOutSpecificationAggregateBoolExpBool_andArgumentsColumns" columns of table "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationSelectColumnDbSnapshotSellerRentOutSpecificationAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  | 'balcony'
  /** column name */
  | 'deleted'
  /** column name */
  | 'elevator'
  /** column name */
  | 'garage'
  /** column name */
  | 'garden'
  /** column name */
  | 'guestToilet'
  /** column name */
  | 'isOccupiedByOwner'
  /** column name */
  | 'parking'
  /** column name */
  | 'swimmingPool';

/** select "dbSnapshotSellerRentOutSpecificationAggregateBoolExpBool_orArgumentsColumns" columns of table "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationSelectColumnDbSnapshotSellerRentOutSpecificationAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  | 'balcony'
  /** column name */
  | 'deleted'
  /** column name */
  | 'elevator'
  /** column name */
  | 'garage'
  /** column name */
  | 'garden'
  /** column name */
  | 'guestToilet'
  /** column name */
  | 'isOccupiedByOwner'
  /** column name */
  | 'parking'
  /** column name */
  | 'swimmingPool';

/** select "dbSnapshotSellerRentOutSpecificationAggregateBoolExpCorrArgumentsColumns" columns of table "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationSelectColumnDbSnapshotSellerRentOutSpecificationAggregateBoolExpCorrArgumentsColumns =
  /** column name */
  | 'coordinateLat'
  /** column name */
  | 'coordinateLon'
  /** column name */
  | 'livingArea'
  /** column name */
  | 'plotArea'
  /** column name */
  | 'surfaceTotal'
  /** column name */
  | 'surfaceUsable';

/** select "dbSnapshotSellerRentOutSpecificationAggregateBoolExpCovar_sampArgumentsColumns" columns of table "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationSelectColumnDbSnapshotSellerRentOutSpecificationAggregateBoolExpCovarSampArgumentsColumns =
  /** column name */
  | 'coordinateLat'
  /** column name */
  | 'coordinateLon'
  /** column name */
  | 'livingArea'
  /** column name */
  | 'plotArea'
  /** column name */
  | 'surfaceTotal'
  /** column name */
  | 'surfaceUsable';

/** select "dbSnapshotSellerRentOutSpecificationAggregateBoolExpMaxArgumentsColumns" columns of table "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationSelectColumnDbSnapshotSellerRentOutSpecificationAggregateBoolExpMaxArgumentsColumns =
  /** column name */
  | 'coordinateLat'
  /** column name */
  | 'coordinateLon'
  /** column name */
  | 'livingArea'
  /** column name */
  | 'plotArea'
  /** column name */
  | 'surfaceTotal'
  /** column name */
  | 'surfaceUsable';

/** select "dbSnapshotSellerRentOutSpecificationAggregateBoolExpMinArgumentsColumns" columns of table "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationSelectColumnDbSnapshotSellerRentOutSpecificationAggregateBoolExpMinArgumentsColumns =
  /** column name */
  | 'coordinateLat'
  /** column name */
  | 'coordinateLon'
  /** column name */
  | 'livingArea'
  /** column name */
  | 'plotArea'
  /** column name */
  | 'surfaceTotal'
  /** column name */
  | 'surfaceUsable';

/** select "dbSnapshotSellerRentOutSpecificationAggregateBoolExpStddev_sampArgumentsColumns" columns of table "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationSelectColumnDbSnapshotSellerRentOutSpecificationAggregateBoolExpStddevSampArgumentsColumns =
  /** column name */
  | 'coordinateLat'
  /** column name */
  | 'coordinateLon'
  /** column name */
  | 'livingArea'
  /** column name */
  | 'plotArea'
  /** column name */
  | 'surfaceTotal'
  /** column name */
  | 'surfaceUsable';

/** select "dbSnapshotSellerRentOutSpecificationAggregateBoolExpSumArgumentsColumns" columns of table "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationSelectColumnDbSnapshotSellerRentOutSpecificationAggregateBoolExpSumArgumentsColumns =
  /** column name */
  | 'coordinateLat'
  /** column name */
  | 'coordinateLon'
  /** column name */
  | 'livingArea'
  /** column name */
  | 'plotArea'
  /** column name */
  | 'surfaceTotal'
  /** column name */
  | 'surfaceUsable';

/** select "dbSnapshotSellerRentOutSpecificationAggregateBoolExpVar_sampArgumentsColumns" columns of table "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationSelectColumnDbSnapshotSellerRentOutSpecificationAggregateBoolExpVarSampArgumentsColumns =
  /** column name */
  | 'coordinateLat'
  /** column name */
  | 'coordinateLon'
  /** column name */
  | 'livingArea'
  /** column name */
  | 'plotArea'
  /** column name */
  | 'surfaceTotal'
  /** column name */
  | 'surfaceUsable';

/** aggregate stddev on columns */
export type DbSnapshotSellerRentOutSpecificationStddevFields = {
  __typename?: 'DbSnapshotSellerRentOutSpecificationStddevFields';
  constructionYear?: Maybe<Scalars['Float']>;
  coordinateLat?: Maybe<Scalars['Float']>;
  coordinateLon?: Maybe<Scalars['Float']>;
  floor?: Maybe<Scalars['Float']>;
  livingArea?: Maybe<Scalars['Float']>;
  numberOfBathrooms?: Maybe<Scalars['Float']>;
  numberOfBedrooms?: Maybe<Scalars['Float']>;
  numberOfMasterBathrooms?: Maybe<Scalars['Float']>;
  numberOfRooms?: Maybe<Scalars['Float']>;
  plotArea?: Maybe<Scalars['Float']>;
  priceRangeFrom?: Maybe<Scalars['Float']>;
  priceRangeTo?: Maybe<Scalars['Float']>;
  rentRangeFrom?: Maybe<Scalars['Float']>;
  rentRangeTo?: Maybe<Scalars['Float']>;
  surfaceTotal?: Maybe<Scalars['Float']>;
  surfaceUsable?: Maybe<Scalars['Float']>;
  totalFloors?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationStddevOrderBy = {
  constructionYear?: InputMaybe<OrderBy>;
  coordinateLat?: InputMaybe<OrderBy>;
  coordinateLon?: InputMaybe<OrderBy>;
  floor?: InputMaybe<OrderBy>;
  livingArea?: InputMaybe<OrderBy>;
  numberOfBathrooms?: InputMaybe<OrderBy>;
  numberOfBedrooms?: InputMaybe<OrderBy>;
  numberOfMasterBathrooms?: InputMaybe<OrderBy>;
  numberOfRooms?: InputMaybe<OrderBy>;
  plotArea?: InputMaybe<OrderBy>;
  priceRangeFrom?: InputMaybe<OrderBy>;
  priceRangeTo?: InputMaybe<OrderBy>;
  rentRangeFrom?: InputMaybe<OrderBy>;
  rentRangeTo?: InputMaybe<OrderBy>;
  surfaceTotal?: InputMaybe<OrderBy>;
  surfaceUsable?: InputMaybe<OrderBy>;
  totalFloors?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbSnapshotSellerRentOutSpecificationStddevPopFields = {
  __typename?: 'DbSnapshotSellerRentOutSpecificationStddevPopFields';
  constructionYear?: Maybe<Scalars['Float']>;
  coordinateLat?: Maybe<Scalars['Float']>;
  coordinateLon?: Maybe<Scalars['Float']>;
  floor?: Maybe<Scalars['Float']>;
  livingArea?: Maybe<Scalars['Float']>;
  numberOfBathrooms?: Maybe<Scalars['Float']>;
  numberOfBedrooms?: Maybe<Scalars['Float']>;
  numberOfMasterBathrooms?: Maybe<Scalars['Float']>;
  numberOfRooms?: Maybe<Scalars['Float']>;
  plotArea?: Maybe<Scalars['Float']>;
  priceRangeFrom?: Maybe<Scalars['Float']>;
  priceRangeTo?: Maybe<Scalars['Float']>;
  rentRangeFrom?: Maybe<Scalars['Float']>;
  rentRangeTo?: Maybe<Scalars['Float']>;
  surfaceTotal?: Maybe<Scalars['Float']>;
  surfaceUsable?: Maybe<Scalars['Float']>;
  totalFloors?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationStddevPopOrderBy = {
  constructionYear?: InputMaybe<OrderBy>;
  coordinateLat?: InputMaybe<OrderBy>;
  coordinateLon?: InputMaybe<OrderBy>;
  floor?: InputMaybe<OrderBy>;
  livingArea?: InputMaybe<OrderBy>;
  numberOfBathrooms?: InputMaybe<OrderBy>;
  numberOfBedrooms?: InputMaybe<OrderBy>;
  numberOfMasterBathrooms?: InputMaybe<OrderBy>;
  numberOfRooms?: InputMaybe<OrderBy>;
  plotArea?: InputMaybe<OrderBy>;
  priceRangeFrom?: InputMaybe<OrderBy>;
  priceRangeTo?: InputMaybe<OrderBy>;
  rentRangeFrom?: InputMaybe<OrderBy>;
  rentRangeTo?: InputMaybe<OrderBy>;
  surfaceTotal?: InputMaybe<OrderBy>;
  surfaceUsable?: InputMaybe<OrderBy>;
  totalFloors?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbSnapshotSellerRentOutSpecificationStddevSampFields = {
  __typename?: 'DbSnapshotSellerRentOutSpecificationStddevSampFields';
  constructionYear?: Maybe<Scalars['Float']>;
  coordinateLat?: Maybe<Scalars['Float']>;
  coordinateLon?: Maybe<Scalars['Float']>;
  floor?: Maybe<Scalars['Float']>;
  livingArea?: Maybe<Scalars['Float']>;
  numberOfBathrooms?: Maybe<Scalars['Float']>;
  numberOfBedrooms?: Maybe<Scalars['Float']>;
  numberOfMasterBathrooms?: Maybe<Scalars['Float']>;
  numberOfRooms?: Maybe<Scalars['Float']>;
  plotArea?: Maybe<Scalars['Float']>;
  priceRangeFrom?: Maybe<Scalars['Float']>;
  priceRangeTo?: Maybe<Scalars['Float']>;
  rentRangeFrom?: Maybe<Scalars['Float']>;
  rentRangeTo?: Maybe<Scalars['Float']>;
  surfaceTotal?: Maybe<Scalars['Float']>;
  surfaceUsable?: Maybe<Scalars['Float']>;
  totalFloors?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationStddevSampOrderBy = {
  constructionYear?: InputMaybe<OrderBy>;
  coordinateLat?: InputMaybe<OrderBy>;
  coordinateLon?: InputMaybe<OrderBy>;
  floor?: InputMaybe<OrderBy>;
  livingArea?: InputMaybe<OrderBy>;
  numberOfBathrooms?: InputMaybe<OrderBy>;
  numberOfBedrooms?: InputMaybe<OrderBy>;
  numberOfMasterBathrooms?: InputMaybe<OrderBy>;
  numberOfRooms?: InputMaybe<OrderBy>;
  plotArea?: InputMaybe<OrderBy>;
  priceRangeFrom?: InputMaybe<OrderBy>;
  priceRangeTo?: InputMaybe<OrderBy>;
  rentRangeFrom?: InputMaybe<OrderBy>;
  rentRangeTo?: InputMaybe<OrderBy>;
  surfaceTotal?: InputMaybe<OrderBy>;
  surfaceUsable?: InputMaybe<OrderBy>;
  totalFloors?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbSnapshotSellerRentOutSpecificationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbSnapshotSellerRentOutSpecificationStreamCursorValueInput = {
  balcony?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  condition?: InputMaybe<AssetConditionEnum>;
  constructionYear?: InputMaybe<Scalars['Int']>;
  coordinateLat?: InputMaybe<Scalars['float8']>;
  coordinateLon?: InputMaybe<Scalars['float8']>;
  countryCode?: InputMaybe<CountryEnum>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  elevator?: InputMaybe<Scalars['Boolean']>;
  floor?: InputMaybe<Scalars['Int']>;
  garage?: InputMaybe<Scalars['Boolean']>;
  garden?: InputMaybe<Scalars['Boolean']>;
  guestToilet?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  isOccupiedByOwner?: InputMaybe<Scalars['Boolean']>;
  livingArea?: InputMaybe<Scalars['float8']>;
  numberOfBathrooms?: InputMaybe<Scalars['Float']>;
  numberOfBedrooms?: InputMaybe<Scalars['Float']>;
  numberOfMasterBathrooms?: InputMaybe<Scalars['Float']>;
  numberOfRooms?: InputMaybe<Scalars['Float']>;
  occupationStatus?: InputMaybe<PropertyOccupationStatusEnum>;
  ownerDescription?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  ownershipStatus?: InputMaybe<PropertyOwnershipStatusEnum>;
  parking?: InputMaybe<Scalars['Boolean']>;
  plotArea?: InputMaybe<Scalars['float8']>;
  postalCode?: InputMaybe<Scalars['String']>;
  priceRangeFrom?: InputMaybe<Scalars['numeric']>;
  priceRangeTo?: InputMaybe<Scalars['numeric']>;
  priceRangeUnit?: InputMaybe<CurrencyEnum>;
  rentPriceUnit?: InputMaybe<CurrencyEnum>;
  rentRangeFrom?: InputMaybe<Scalars['numeric']>;
  rentRangeTo?: InputMaybe<Scalars['numeric']>;
  streetName?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  subType?: InputMaybe<AssetSubTypeEnum>;
  surfaceTotal?: InputMaybe<Scalars['float8']>;
  surfaceUsable?: InputMaybe<Scalars['float8']>;
  swimmingPool?: InputMaybe<Scalars['Boolean']>;
  totalFloors?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<AssetTypeEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  variation?: InputMaybe<AssetVariationEnum>;
};

/** aggregate sum on columns */
export type DbSnapshotSellerRentOutSpecificationSumFields = {
  __typename?: 'DbSnapshotSellerRentOutSpecificationSumFields';
  constructionYear?: Maybe<Scalars['Int']>;
  coordinateLat?: Maybe<Scalars['float8']>;
  coordinateLon?: Maybe<Scalars['float8']>;
  floor?: Maybe<Scalars['Int']>;
  livingArea?: Maybe<Scalars['float8']>;
  numberOfBathrooms?: Maybe<Scalars['Float']>;
  numberOfBedrooms?: Maybe<Scalars['Float']>;
  numberOfMasterBathrooms?: Maybe<Scalars['Float']>;
  numberOfRooms?: Maybe<Scalars['Float']>;
  plotArea?: Maybe<Scalars['float8']>;
  priceRangeFrom?: Maybe<Scalars['numeric']>;
  priceRangeTo?: Maybe<Scalars['numeric']>;
  rentRangeFrom?: Maybe<Scalars['numeric']>;
  rentRangeTo?: Maybe<Scalars['numeric']>;
  surfaceTotal?: Maybe<Scalars['float8']>;
  surfaceUsable?: Maybe<Scalars['float8']>;
  totalFloors?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationSumOrderBy = {
  constructionYear?: InputMaybe<OrderBy>;
  coordinateLat?: InputMaybe<OrderBy>;
  coordinateLon?: InputMaybe<OrderBy>;
  floor?: InputMaybe<OrderBy>;
  livingArea?: InputMaybe<OrderBy>;
  numberOfBathrooms?: InputMaybe<OrderBy>;
  numberOfBedrooms?: InputMaybe<OrderBy>;
  numberOfMasterBathrooms?: InputMaybe<OrderBy>;
  numberOfRooms?: InputMaybe<OrderBy>;
  plotArea?: InputMaybe<OrderBy>;
  priceRangeFrom?: InputMaybe<OrderBy>;
  priceRangeTo?: InputMaybe<OrderBy>;
  rentRangeFrom?: InputMaybe<OrderBy>;
  rentRangeTo?: InputMaybe<OrderBy>;
  surfaceTotal?: InputMaybe<OrderBy>;
  surfaceUsable?: InputMaybe<OrderBy>;
  totalFloors?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbSnapshotSellerRentOutSpecificationVarPopFields = {
  __typename?: 'DbSnapshotSellerRentOutSpecificationVarPopFields';
  constructionYear?: Maybe<Scalars['Float']>;
  coordinateLat?: Maybe<Scalars['Float']>;
  coordinateLon?: Maybe<Scalars['Float']>;
  floor?: Maybe<Scalars['Float']>;
  livingArea?: Maybe<Scalars['Float']>;
  numberOfBathrooms?: Maybe<Scalars['Float']>;
  numberOfBedrooms?: Maybe<Scalars['Float']>;
  numberOfMasterBathrooms?: Maybe<Scalars['Float']>;
  numberOfRooms?: Maybe<Scalars['Float']>;
  plotArea?: Maybe<Scalars['Float']>;
  priceRangeFrom?: Maybe<Scalars['Float']>;
  priceRangeTo?: Maybe<Scalars['Float']>;
  rentRangeFrom?: Maybe<Scalars['Float']>;
  rentRangeTo?: Maybe<Scalars['Float']>;
  surfaceTotal?: Maybe<Scalars['Float']>;
  surfaceUsable?: Maybe<Scalars['Float']>;
  totalFloors?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationVarPopOrderBy = {
  constructionYear?: InputMaybe<OrderBy>;
  coordinateLat?: InputMaybe<OrderBy>;
  coordinateLon?: InputMaybe<OrderBy>;
  floor?: InputMaybe<OrderBy>;
  livingArea?: InputMaybe<OrderBy>;
  numberOfBathrooms?: InputMaybe<OrderBy>;
  numberOfBedrooms?: InputMaybe<OrderBy>;
  numberOfMasterBathrooms?: InputMaybe<OrderBy>;
  numberOfRooms?: InputMaybe<OrderBy>;
  plotArea?: InputMaybe<OrderBy>;
  priceRangeFrom?: InputMaybe<OrderBy>;
  priceRangeTo?: InputMaybe<OrderBy>;
  rentRangeFrom?: InputMaybe<OrderBy>;
  rentRangeTo?: InputMaybe<OrderBy>;
  surfaceTotal?: InputMaybe<OrderBy>;
  surfaceUsable?: InputMaybe<OrderBy>;
  totalFloors?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbSnapshotSellerRentOutSpecificationVarSampFields = {
  __typename?: 'DbSnapshotSellerRentOutSpecificationVarSampFields';
  constructionYear?: Maybe<Scalars['Float']>;
  coordinateLat?: Maybe<Scalars['Float']>;
  coordinateLon?: Maybe<Scalars['Float']>;
  floor?: Maybe<Scalars['Float']>;
  livingArea?: Maybe<Scalars['Float']>;
  numberOfBathrooms?: Maybe<Scalars['Float']>;
  numberOfBedrooms?: Maybe<Scalars['Float']>;
  numberOfMasterBathrooms?: Maybe<Scalars['Float']>;
  numberOfRooms?: Maybe<Scalars['Float']>;
  plotArea?: Maybe<Scalars['Float']>;
  priceRangeFrom?: Maybe<Scalars['Float']>;
  priceRangeTo?: Maybe<Scalars['Float']>;
  rentRangeFrom?: Maybe<Scalars['Float']>;
  rentRangeTo?: Maybe<Scalars['Float']>;
  surfaceTotal?: Maybe<Scalars['Float']>;
  surfaceUsable?: Maybe<Scalars['Float']>;
  totalFloors?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationVarSampOrderBy = {
  constructionYear?: InputMaybe<OrderBy>;
  coordinateLat?: InputMaybe<OrderBy>;
  coordinateLon?: InputMaybe<OrderBy>;
  floor?: InputMaybe<OrderBy>;
  livingArea?: InputMaybe<OrderBy>;
  numberOfBathrooms?: InputMaybe<OrderBy>;
  numberOfBedrooms?: InputMaybe<OrderBy>;
  numberOfMasterBathrooms?: InputMaybe<OrderBy>;
  numberOfRooms?: InputMaybe<OrderBy>;
  plotArea?: InputMaybe<OrderBy>;
  priceRangeFrom?: InputMaybe<OrderBy>;
  priceRangeTo?: InputMaybe<OrderBy>;
  rentRangeFrom?: InputMaybe<OrderBy>;
  rentRangeTo?: InputMaybe<OrderBy>;
  surfaceTotal?: InputMaybe<OrderBy>;
  surfaceUsable?: InputMaybe<OrderBy>;
  totalFloors?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbSnapshotSellerRentOutSpecificationVarianceFields = {
  __typename?: 'DbSnapshotSellerRentOutSpecificationVarianceFields';
  constructionYear?: Maybe<Scalars['Float']>;
  coordinateLat?: Maybe<Scalars['Float']>;
  coordinateLon?: Maybe<Scalars['Float']>;
  floor?: Maybe<Scalars['Float']>;
  livingArea?: Maybe<Scalars['Float']>;
  numberOfBathrooms?: Maybe<Scalars['Float']>;
  numberOfBedrooms?: Maybe<Scalars['Float']>;
  numberOfMasterBathrooms?: Maybe<Scalars['Float']>;
  numberOfRooms?: Maybe<Scalars['Float']>;
  plotArea?: Maybe<Scalars['Float']>;
  priceRangeFrom?: Maybe<Scalars['Float']>;
  priceRangeTo?: Maybe<Scalars['Float']>;
  rentRangeFrom?: Maybe<Scalars['Float']>;
  rentRangeTo?: Maybe<Scalars['Float']>;
  surfaceTotal?: Maybe<Scalars['Float']>;
  surfaceUsable?: Maybe<Scalars['Float']>;
  totalFloors?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.snapshot_seller_rent_out_specification" */
export type DbSnapshotSellerRentOutSpecificationVarianceOrderBy = {
  constructionYear?: InputMaybe<OrderBy>;
  coordinateLat?: InputMaybe<OrderBy>;
  coordinateLon?: InputMaybe<OrderBy>;
  floor?: InputMaybe<OrderBy>;
  livingArea?: InputMaybe<OrderBy>;
  numberOfBathrooms?: InputMaybe<OrderBy>;
  numberOfBedrooms?: InputMaybe<OrderBy>;
  numberOfMasterBathrooms?: InputMaybe<OrderBy>;
  numberOfRooms?: InputMaybe<OrderBy>;
  plotArea?: InputMaybe<OrderBy>;
  priceRangeFrom?: InputMaybe<OrderBy>;
  priceRangeTo?: InputMaybe<OrderBy>;
  rentRangeFrom?: InputMaybe<OrderBy>;
  rentRangeTo?: InputMaybe<OrderBy>;
  surfaceTotal?: InputMaybe<OrderBy>;
  surfaceUsable?: InputMaybe<OrderBy>;
  totalFloors?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.tag" */
export type DbTag = {
  __typename?: 'DbTag';
  /** An array relationship */
  contact_tags: Array<DbContactTag>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  num: Scalars['bigint'];
  /** An object relationship */
  shop: DbShop;
  shopId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** columns and relationships of "db.tag" */
export type DbTagContactTagsArgs = {
  distinctOn?: InputMaybe<Array<DbContactTagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactTagOrderBy>>;
  where?: InputMaybe<DbContactTagBoolExp>;
};

/** order by aggregate values of table "db.tag" */
export type DbTagAggregateOrderBy = {
  avg?: InputMaybe<DbTagAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbTagMaxOrderBy>;
  min?: InputMaybe<DbTagMinOrderBy>;
  stddev?: InputMaybe<DbTagStddevOrderBy>;
  stddevPop?: InputMaybe<DbTagStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbTagStddevSampOrderBy>;
  sum?: InputMaybe<DbTagSumOrderBy>;
  varPop?: InputMaybe<DbTagVarPopOrderBy>;
  varSamp?: InputMaybe<DbTagVarSampOrderBy>;
  variance?: InputMaybe<DbTagVarianceOrderBy>;
};

/** order by avg() on columns of table "db.tag" */
export type DbTagAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.tag". All fields are combined with a logical 'AND'. */
export type DbTagBoolExp = {
  _and?: InputMaybe<Array<DbTagBoolExp>>;
  _not?: InputMaybe<DbTagBoolExp>;
  _or?: InputMaybe<Array<DbTagBoolExp>>;
  contact_tags?: InputMaybe<DbContactTagBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.tag" */
export type DbTagMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.tag" */
export type DbTagMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.tag". */
export type DbTagOrderBy = {
  contact_tagsAggregate?: InputMaybe<DbContactTagAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.tag" */
export type DbTagSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'description'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'num'
  /** column name */
  | 'shopId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.tag" */
export type DbTagStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.tag" */
export type DbTagStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.tag" */
export type DbTagStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_tag" */
export type DbTagStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbTagStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbTagStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  shopId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "db.tag" */
export type DbTagSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.tag" */
export type DbTagVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.tag" */
export type DbTagVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.tag" */
export type DbTagVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.task" */
export type DbTask = {
  __typename?: 'DbTask';
  /** An object relationship */
  agent: DbAgent;
  agentId: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['numeric']>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  dueDatetime?: Maybe<Scalars['timestamptz']>;
  evaEventId?: Maybe<Scalars['String']>;
  go3TaskKey?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  lck: Scalars['Int'];
  num: Scalars['bigint'];
  priority?: Maybe<PriorityEnum>;
  /** An object relationship */
  priorityByPriority?: Maybe<EnumsPriority>;
  propertyIds: Array<Scalars['String']>;
  status?: Maybe<TaskAppointmentStatusEnum>;
  /** An array relationship */
  taskActivities: Array<DbTaskActivity>;
  /** An array relationship */
  taskAgents: Array<DbTaskAgent>;
  /** An object relationship */
  taskAppointmentStatus?: Maybe<EnumsTaskAppointmentStatus>;
  /** An array relationship */
  taskContacts: Array<DbTaskContact>;
  /** An array relationship */
  taskLeads: Array<DbTaskLead>;
  /** An object relationship */
  taskTeam?: Maybe<DbTeam>;
  /** An object relationship */
  taskType: EnumsTaskType;
  /** An object relationship */
  team?: Maybe<DbTeam>;
  teamId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type: TaskTypeEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** columns and relationships of "db.task" */
export type DbTaskTaskActivitiesArgs = {
  distinctOn?: InputMaybe<Array<DbTaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTaskActivityOrderBy>>;
  where?: InputMaybe<DbTaskActivityBoolExp>;
};

/** columns and relationships of "db.task" */
export type DbTaskTaskAgentsArgs = {
  distinctOn?: InputMaybe<Array<DbTaskAgentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTaskAgentOrderBy>>;
  where?: InputMaybe<DbTaskAgentBoolExp>;
};

/** columns and relationships of "db.task" */
export type DbTaskTaskContactsArgs = {
  distinctOn?: InputMaybe<Array<DbTaskContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTaskContactOrderBy>>;
  where?: InputMaybe<DbTaskContactBoolExp>;
};

/** columns and relationships of "db.task" */
export type DbTaskTaskLeadsArgs = {
  distinctOn?: InputMaybe<Array<DbTaskLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTaskLeadOrderBy>>;
  where?: InputMaybe<DbTaskLeadBoolExp>;
};

/** columns and relationships of "db.task_activity" */
export type DbTaskActivity = {
  __typename?: 'DbTaskActivity';
  /** An object relationship */
  baseActivity: DbBaseActivity;
  content?: Maybe<Scalars['jsonb']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['String'];
  /** An object relationship */
  task: DbTask;
  taskId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "db.task_activity" */
export type DbTaskActivityContentArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** order by aggregate values of table "db.task_activity" */
export type DbTaskActivityAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbTaskActivityMaxOrderBy>;
  min?: InputMaybe<DbTaskActivityMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.task_activity". All fields are combined with a logical 'AND'. */
export type DbTaskActivityBoolExp = {
  _and?: InputMaybe<Array<DbTaskActivityBoolExp>>;
  _not?: InputMaybe<DbTaskActivityBoolExp>;
  _or?: InputMaybe<Array<DbTaskActivityBoolExp>>;
  baseActivity?: InputMaybe<DbBaseActivityBoolExp>;
  content?: InputMaybe<JsonbComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  task?: InputMaybe<DbTaskBoolExp>;
  taskId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** order by max() on columns of table "db.task_activity" */
export type DbTaskActivityMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  taskId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.task_activity" */
export type DbTaskActivityMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  taskId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.task_activity". */
export type DbTaskActivityOrderBy = {
  baseActivity?: InputMaybe<DbBaseActivityOrderBy>;
  content?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  task?: InputMaybe<DbTaskOrderBy>;
  taskId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** select columns of table "db.task_activity" */
export type DbTaskActivitySelectColumn =
  /** column name */
  | 'content'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'id'
  /** column name */
  | 'taskId'
  /** column name */
  | 'updatedAt';

/** Streaming cursor of the table "db_task_activity" */
export type DbTaskActivityStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbTaskActivityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbTaskActivityStreamCursorValueInput = {
  content?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  taskId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "db.task_agent" */
export type DbTaskAgent = {
  __typename?: 'DbTaskAgent';
  /** An object relationship */
  agent: DbAgent;
  agentId: Scalars['String'];
  /** An object relationship */
  task: DbTask;
  taskId: Scalars['String'];
};

/** order by aggregate values of table "db.task_agent" */
export type DbTaskAgentAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbTaskAgentMaxOrderBy>;
  min?: InputMaybe<DbTaskAgentMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.task_agent". All fields are combined with a logical 'AND'. */
export type DbTaskAgentBoolExp = {
  _and?: InputMaybe<Array<DbTaskAgentBoolExp>>;
  _not?: InputMaybe<DbTaskAgentBoolExp>;
  _or?: InputMaybe<Array<DbTaskAgentBoolExp>>;
  agent?: InputMaybe<DbAgentBoolExp>;
  agentId?: InputMaybe<StringComparisonExp>;
  task?: InputMaybe<DbTaskBoolExp>;
  taskId?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.task_agent" */
export type DbTaskAgentMaxOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  taskId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.task_agent" */
export type DbTaskAgentMinOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  taskId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.task_agent". */
export type DbTaskAgentOrderBy = {
  agent?: InputMaybe<DbAgentOrderBy>;
  agentId?: InputMaybe<OrderBy>;
  task?: InputMaybe<DbTaskOrderBy>;
  taskId?: InputMaybe<OrderBy>;
};

/** select columns of table "db.task_agent" */
export type DbTaskAgentSelectColumn =
  /** column name */
  | 'agentId'
  /** column name */
  | 'taskId';

/** Streaming cursor of the table "db_task_agent" */
export type DbTaskAgentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbTaskAgentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbTaskAgentStreamCursorValueInput = {
  agentId?: InputMaybe<Scalars['String']>;
  taskId?: InputMaybe<Scalars['String']>;
};

/** order by aggregate values of table "db.task" */
export type DbTaskAggregateOrderBy = {
  avg?: InputMaybe<DbTaskAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbTaskMaxOrderBy>;
  min?: InputMaybe<DbTaskMinOrderBy>;
  stddev?: InputMaybe<DbTaskStddevOrderBy>;
  stddevPop?: InputMaybe<DbTaskStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbTaskStddevSampOrderBy>;
  sum?: InputMaybe<DbTaskSumOrderBy>;
  varPop?: InputMaybe<DbTaskVarPopOrderBy>;
  varSamp?: InputMaybe<DbTaskVarSampOrderBy>;
  variance?: InputMaybe<DbTaskVarianceOrderBy>;
};

/** order by avg() on columns of table "db.task" */
export type DbTaskAvgOrderBy = {
  cost?: InputMaybe<OrderBy>;
  go3TaskKey?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.task". All fields are combined with a logical 'AND'. */
export type DbTaskBoolExp = {
  _and?: InputMaybe<Array<DbTaskBoolExp>>;
  _not?: InputMaybe<DbTaskBoolExp>;
  _or?: InputMaybe<Array<DbTaskBoolExp>>;
  agent?: InputMaybe<DbAgentBoolExp>;
  agentId?: InputMaybe<StringComparisonExp>;
  comment?: InputMaybe<StringComparisonExp>;
  cost?: InputMaybe<NumericComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  dueDatetime?: InputMaybe<TimestamptzComparisonExp>;
  evaEventId?: InputMaybe<StringComparisonExp>;
  go3TaskKey?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lck?: InputMaybe<IntComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  priority?: InputMaybe<EnumsPriorityEnumComparisonExp>;
  priorityByPriority?: InputMaybe<EnumsPriorityBoolExp>;
  propertyIds?: InputMaybe<StringArrayComparisonExp>;
  status?: InputMaybe<EnumsTaskAppointmentStatusEnumComparisonExp>;
  taskActivities?: InputMaybe<DbTaskActivityBoolExp>;
  taskAgents?: InputMaybe<DbTaskAgentBoolExp>;
  taskAppointmentStatus?: InputMaybe<EnumsTaskAppointmentStatusBoolExp>;
  taskContacts?: InputMaybe<DbTaskContactBoolExp>;
  taskLeads?: InputMaybe<DbTaskLeadBoolExp>;
  taskTeam?: InputMaybe<DbTeamBoolExp>;
  taskType?: InputMaybe<EnumsTaskTypeBoolExp>;
  team?: InputMaybe<DbTeamBoolExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  title?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<EnumsTaskTypeEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** columns and relationships of "db.task_contact" */
export type DbTaskContact = {
  __typename?: 'DbTaskContact';
  /** An object relationship */
  contact: DbContact;
  contactId: Scalars['String'];
  /** An object relationship */
  task: DbTask;
  taskId: Scalars['String'];
};

/** order by aggregate values of table "db.task_contact" */
export type DbTaskContactAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbTaskContactMaxOrderBy>;
  min?: InputMaybe<DbTaskContactMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.task_contact". All fields are combined with a logical 'AND'. */
export type DbTaskContactBoolExp = {
  _and?: InputMaybe<Array<DbTaskContactBoolExp>>;
  _not?: InputMaybe<DbTaskContactBoolExp>;
  _or?: InputMaybe<Array<DbTaskContactBoolExp>>;
  contact?: InputMaybe<DbContactBoolExp>;
  contactId?: InputMaybe<StringComparisonExp>;
  task?: InputMaybe<DbTaskBoolExp>;
  taskId?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.task_contact" */
export type DbTaskContactMaxOrderBy = {
  contactId?: InputMaybe<OrderBy>;
  taskId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.task_contact" */
export type DbTaskContactMinOrderBy = {
  contactId?: InputMaybe<OrderBy>;
  taskId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.task_contact". */
export type DbTaskContactOrderBy = {
  contact?: InputMaybe<DbContactOrderBy>;
  contactId?: InputMaybe<OrderBy>;
  task?: InputMaybe<DbTaskOrderBy>;
  taskId?: InputMaybe<OrderBy>;
};

/** select columns of table "db.task_contact" */
export type DbTaskContactSelectColumn =
  /** column name */
  | 'contactId'
  /** column name */
  | 'taskId';

/** Streaming cursor of the table "db_task_contact" */
export type DbTaskContactStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbTaskContactStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbTaskContactStreamCursorValueInput = {
  contactId?: InputMaybe<Scalars['String']>;
  taskId?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "db.task_lead" */
export type DbTaskLead = {
  __typename?: 'DbTaskLead';
  /** An object relationship */
  appointment: DbTask;
  /** An object relationship */
  lead: DbLead;
  leadId: Scalars['String'];
  taskId: Scalars['String'];
};

/** order by aggregate values of table "db.task_lead" */
export type DbTaskLeadAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbTaskLeadMaxOrderBy>;
  min?: InputMaybe<DbTaskLeadMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.task_lead". All fields are combined with a logical 'AND'. */
export type DbTaskLeadBoolExp = {
  _and?: InputMaybe<Array<DbTaskLeadBoolExp>>;
  _not?: InputMaybe<DbTaskLeadBoolExp>;
  _or?: InputMaybe<Array<DbTaskLeadBoolExp>>;
  appointment?: InputMaybe<DbTaskBoolExp>;
  lead?: InputMaybe<DbLeadBoolExp>;
  leadId?: InputMaybe<StringComparisonExp>;
  taskId?: InputMaybe<StringComparisonExp>;
};

/** order by max() on columns of table "db.task_lead" */
export type DbTaskLeadMaxOrderBy = {
  leadId?: InputMaybe<OrderBy>;
  taskId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.task_lead" */
export type DbTaskLeadMinOrderBy = {
  leadId?: InputMaybe<OrderBy>;
  taskId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.task_lead". */
export type DbTaskLeadOrderBy = {
  appointment?: InputMaybe<DbTaskOrderBy>;
  lead?: InputMaybe<DbLeadOrderBy>;
  leadId?: InputMaybe<OrderBy>;
  taskId?: InputMaybe<OrderBy>;
};

/** select columns of table "db.task_lead" */
export type DbTaskLeadSelectColumn =
  /** column name */
  | 'leadId'
  /** column name */
  | 'taskId';

/** Streaming cursor of the table "db_task_lead" */
export type DbTaskLeadStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbTaskLeadStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbTaskLeadStreamCursorValueInput = {
  leadId?: InputMaybe<Scalars['String']>;
  taskId?: InputMaybe<Scalars['String']>;
};

/** order by max() on columns of table "db.task" */
export type DbTaskMaxOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  comment?: InputMaybe<OrderBy>;
  cost?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  dueDatetime?: InputMaybe<OrderBy>;
  evaEventId?: InputMaybe<OrderBy>;
  go3TaskKey?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  propertyIds?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "db.task" */
export type DbTaskMinOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  comment?: InputMaybe<OrderBy>;
  cost?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  dueDatetime?: InputMaybe<OrderBy>;
  evaEventId?: InputMaybe<OrderBy>;
  go3TaskKey?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  propertyIds?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.task". */
export type DbTaskOrderBy = {
  agent?: InputMaybe<DbAgentOrderBy>;
  agentId?: InputMaybe<OrderBy>;
  comment?: InputMaybe<OrderBy>;
  cost?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  dueDatetime?: InputMaybe<OrderBy>;
  evaEventId?: InputMaybe<OrderBy>;
  go3TaskKey?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  priority?: InputMaybe<OrderBy>;
  priorityByPriority?: InputMaybe<EnumsPriorityOrderBy>;
  propertyIds?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  taskActivitiesAggregate?: InputMaybe<DbTaskActivityAggregateOrderBy>;
  taskAgentsAggregate?: InputMaybe<DbTaskAgentAggregateOrderBy>;
  taskAppointmentStatus?: InputMaybe<EnumsTaskAppointmentStatusOrderBy>;
  taskContactsAggregate?: InputMaybe<DbTaskContactAggregateOrderBy>;
  taskLeadsAggregate?: InputMaybe<DbTaskLeadAggregateOrderBy>;
  taskTeam?: InputMaybe<DbTeamOrderBy>;
  taskType?: InputMaybe<EnumsTaskTypeOrderBy>;
  team?: InputMaybe<DbTeamOrderBy>;
  teamId?: InputMaybe<OrderBy>;
  title?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.task" */
export type DbTaskSelectColumn =
  /** column name */
  | 'agentId'
  /** column name */
  | 'comment'
  /** column name */
  | 'cost'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'dueDatetime'
  /** column name */
  | 'evaEventId'
  /** column name */
  | 'go3TaskKey'
  /** column name */
  | 'id'
  /** column name */
  | 'lck'
  /** column name */
  | 'num'
  /** column name */
  | 'priority'
  /** column name */
  | 'propertyIds'
  /** column name */
  | 'status'
  /** column name */
  | 'teamId'
  /** column name */
  | 'title'
  /** column name */
  | 'type'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** order by stddev() on columns of table "db.task" */
export type DbTaskStddevOrderBy = {
  cost?: InputMaybe<OrderBy>;
  go3TaskKey?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevPop() on columns of table "db.task" */
export type DbTaskStddevPopOrderBy = {
  cost?: InputMaybe<OrderBy>;
  go3TaskKey?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by stddevSamp() on columns of table "db.task" */
export type DbTaskStddevSampOrderBy = {
  cost?: InputMaybe<OrderBy>;
  go3TaskKey?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "db.task" */
export type DbTaskSumOrderBy = {
  cost?: InputMaybe<OrderBy>;
  go3TaskKey?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varPop() on columns of table "db.task" */
export type DbTaskVarPopOrderBy = {
  cost?: InputMaybe<OrderBy>;
  go3TaskKey?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by varSamp() on columns of table "db.task" */
export type DbTaskVarSampOrderBy = {
  cost?: InputMaybe<OrderBy>;
  go3TaskKey?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "db.task" */
export type DbTaskVarianceOrderBy = {
  cost?: InputMaybe<OrderBy>;
  go3TaskKey?: InputMaybe<OrderBy>;
  lck?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.team" */
export type DbTeam = {
  __typename?: 'DbTeam';
  /** An array relationship */
  appointments: Array<DbAppointment>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  documentReferences: Array<DbDocumentReference>;
  /** An aggregate relationship */
  documentReferencesAggregate: DbDocumentReferenceAggregate;
  email?: Maybe<Scalars['String']>;
  /** An array relationship */
  emailSignatures: Array<DbEmailSignature>;
  /** An aggregate relationship */
  emailSignaturesAggregate: DbEmailSignatureAggregate;
  /** An array relationship */
  emailTemplates: Array<DbEmailTemplate>;
  /** An aggregate relationship */
  emailTemplatesAggregate: DbEmailTemplateAggregate;
  go3DepartmentKey?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  /** An array relationship */
  leads: Array<DbLead>;
  /** An aggregate relationship */
  leadsAggregate: DbLeadAggregate;
  name: Scalars['String'];
  normalizedName?: Maybe<Scalars['String']>;
  num: Scalars['bigint'];
  /** An array relationship */
  referrals: Array<DbReferral>;
  /** An aggregate relationship */
  referralsAggregate: DbReferralAggregate;
  /** An array relationship */
  referralsBySendingTeamId: Array<DbReferral>;
  /** An aggregate relationship */
  referralsBySendingTeamIdAggregate: DbReferralAggregate;
  /** An object relationship */
  shop: DbShop;
  shopId: Scalars['String'];
  status?: Maybe<TeamStatusEnum>;
  /** An array relationship */
  tasks: Array<DbTask>;
  /** An object relationship */
  teamStatus?: Maybe<EnumsTeamStatus>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  /** An array relationship */
  userTeamAssignments: Array<DbUserTeamAssignment>;
  /** An aggregate relationship */
  userTeamAssignmentsAggregate: DbUserTeamAssignmentAggregate;
};

/** columns and relationships of "db.team" */
export type DbTeamAppointmentsArgs = {
  distinctOn?: InputMaybe<Array<DbAppointmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAppointmentOrderBy>>;
  where?: InputMaybe<DbAppointmentBoolExp>;
};

/** columns and relationships of "db.team" */
export type DbTeamDocumentReferencesArgs = {
  distinctOn?: InputMaybe<Array<DbDocumentReferenceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbDocumentReferenceOrderBy>>;
  where?: InputMaybe<DbDocumentReferenceBoolExp>;
};

/** columns and relationships of "db.team" */
export type DbTeamDocumentReferencesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbDocumentReferenceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbDocumentReferenceOrderBy>>;
  where?: InputMaybe<DbDocumentReferenceBoolExp>;
};

/** columns and relationships of "db.team" */
export type DbTeamEmailSignaturesArgs = {
  distinctOn?: InputMaybe<Array<DbEmailSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailSignatureOrderBy>>;
  where?: InputMaybe<DbEmailSignatureBoolExp>;
};

/** columns and relationships of "db.team" */
export type DbTeamEmailSignaturesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailSignatureOrderBy>>;
  where?: InputMaybe<DbEmailSignatureBoolExp>;
};

/** columns and relationships of "db.team" */
export type DbTeamEmailTemplatesArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTemplateOrderBy>>;
  where?: InputMaybe<DbEmailTemplateBoolExp>;
};

/** columns and relationships of "db.team" */
export type DbTeamEmailTemplatesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTemplateOrderBy>>;
  where?: InputMaybe<DbEmailTemplateBoolExp>;
};

/** columns and relationships of "db.team" */
export type DbTeamLeadsArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

/** columns and relationships of "db.team" */
export type DbTeamLeadsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

/** columns and relationships of "db.team" */
export type DbTeamReferralsArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

/** columns and relationships of "db.team" */
export type DbTeamReferralsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

/** columns and relationships of "db.team" */
export type DbTeamReferralsBySendingTeamIdArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

/** columns and relationships of "db.team" */
export type DbTeamReferralsBySendingTeamIdAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

/** columns and relationships of "db.team" */
export type DbTeamTasksArgs = {
  distinctOn?: InputMaybe<Array<DbTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTaskOrderBy>>;
  where?: InputMaybe<DbTaskBoolExp>;
};

/** columns and relationships of "db.team" */
export type DbTeamUserTeamAssignmentsArgs = {
  distinctOn?: InputMaybe<Array<DbUserTeamAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserTeamAssignmentOrderBy>>;
  where?: InputMaybe<DbUserTeamAssignmentBoolExp>;
};

/** columns and relationships of "db.team" */
export type DbTeamUserTeamAssignmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbUserTeamAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserTeamAssignmentOrderBy>>;
  where?: InputMaybe<DbUserTeamAssignmentBoolExp>;
};

/** aggregated selection of "db.team" */
export type DbTeamAggregate = {
  __typename?: 'DbTeamAggregate';
  aggregate?: Maybe<DbTeamAggregateFields>;
  nodes: Array<DbTeam>;
};

export type DbTeamAggregateBoolExp = {
  bool_and?: InputMaybe<DbTeamAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbTeamAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbTeamAggregateBoolExpCount>;
};

/** aggregate fields of "db.team" */
export type DbTeamAggregateFields = {
  __typename?: 'DbTeamAggregateFields';
  avg?: Maybe<DbTeamAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbTeamMaxFields>;
  min?: Maybe<DbTeamMinFields>;
  stddev?: Maybe<DbTeamStddevFields>;
  stddevPop?: Maybe<DbTeamStddevPopFields>;
  stddevSamp?: Maybe<DbTeamStddevSampFields>;
  sum?: Maybe<DbTeamSumFields>;
  varPop?: Maybe<DbTeamVarPopFields>;
  varSamp?: Maybe<DbTeamVarSampFields>;
  variance?: Maybe<DbTeamVarianceFields>;
};

/** aggregate fields of "db.team" */
export type DbTeamAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbTeamSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.team" */
export type DbTeamAggregateOrderBy = {
  avg?: InputMaybe<DbTeamAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbTeamMaxOrderBy>;
  min?: InputMaybe<DbTeamMinOrderBy>;
  stddev?: InputMaybe<DbTeamStddevOrderBy>;
  stddevPop?: InputMaybe<DbTeamStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbTeamStddevSampOrderBy>;
  sum?: InputMaybe<DbTeamSumOrderBy>;
  varPop?: InputMaybe<DbTeamVarPopOrderBy>;
  varSamp?: InputMaybe<DbTeamVarSampOrderBy>;
  variance?: InputMaybe<DbTeamVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbTeamAvgFields = {
  __typename?: 'DbTeamAvgFields';
  go3DepartmentKey?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.team" */
export type DbTeamAvgOrderBy = {
  go3DepartmentKey?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.team". All fields are combined with a logical 'AND'. */
export type DbTeamBoolExp = {
  _and?: InputMaybe<Array<DbTeamBoolExp>>;
  _not?: InputMaybe<DbTeamBoolExp>;
  _or?: InputMaybe<Array<DbTeamBoolExp>>;
  appointments?: InputMaybe<DbAppointmentBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  documentReferences?: InputMaybe<DbDocumentReferenceBoolExp>;
  documentReferencesAggregate?: InputMaybe<DbDocumentReferenceAggregateBoolExp>;
  email?: InputMaybe<StringComparisonExp>;
  emailSignatures?: InputMaybe<DbEmailSignatureBoolExp>;
  emailSignaturesAggregate?: InputMaybe<DbEmailSignatureAggregateBoolExp>;
  emailTemplates?: InputMaybe<DbEmailTemplateBoolExp>;
  emailTemplatesAggregate?: InputMaybe<DbEmailTemplateAggregateBoolExp>;
  go3DepartmentKey?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  leads?: InputMaybe<DbLeadBoolExp>;
  leadsAggregate?: InputMaybe<DbLeadAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  normalizedName?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  referrals?: InputMaybe<DbReferralBoolExp>;
  referralsAggregate?: InputMaybe<DbReferralAggregateBoolExp>;
  referralsBySendingTeamId?: InputMaybe<DbReferralBoolExp>;
  referralsBySendingTeamIdAggregate?: InputMaybe<DbReferralAggregateBoolExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<EnumsTeamStatusEnumComparisonExp>;
  tasks?: InputMaybe<DbTaskBoolExp>;
  teamStatus?: InputMaybe<EnumsTeamStatusBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  userTeamAssignments?: InputMaybe<DbUserTeamAssignmentBoolExp>;
  userTeamAssignmentsAggregate?: InputMaybe<DbUserTeamAssignmentAggregateBoolExp>;
};

/** aggregate max on columns */
export type DbTeamMaxFields = {
  __typename?: 'DbTeamMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  go3DepartmentKey?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  normalizedName?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  shopId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.team" */
export type DbTeamMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  go3DepartmentKey?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  normalizedName?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbTeamMinFields = {
  __typename?: 'DbTeamMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  go3DepartmentKey?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  normalizedName?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  shopId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.team" */
export type DbTeamMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  go3DepartmentKey?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  normalizedName?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.team". */
export type DbTeamOrderBy = {
  appointmentsAggregate?: InputMaybe<DbAppointmentAggregateOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  documentReferencesAggregate?: InputMaybe<DbDocumentReferenceAggregateOrderBy>;
  email?: InputMaybe<OrderBy>;
  emailSignaturesAggregate?: InputMaybe<DbEmailSignatureAggregateOrderBy>;
  emailTemplatesAggregate?: InputMaybe<DbEmailTemplateAggregateOrderBy>;
  go3DepartmentKey?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  leadsAggregate?: InputMaybe<DbLeadAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  normalizedName?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  referralsAggregate?: InputMaybe<DbReferralAggregateOrderBy>;
  referralsBySendingTeamIdAggregate?: InputMaybe<DbReferralAggregateOrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  tasksAggregate?: InputMaybe<DbTaskAggregateOrderBy>;
  teamStatus?: InputMaybe<EnumsTeamStatusOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  userTeamAssignmentsAggregate?: InputMaybe<DbUserTeamAssignmentAggregateOrderBy>;
};

/** select columns of table "db.team" */
export type DbTeamSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'description'
  /** column name */
  | 'email'
  /** column name */
  | 'go3DepartmentKey'
  /** column name */
  | 'id'
  /** column name */
  | 'name'
  /** column name */
  | 'normalizedName'
  /** column name */
  | 'num'
  /** column name */
  | 'shopId'
  /** column name */
  | 'status'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbTeamAggregateBoolExpBool_andArgumentsColumns" columns of table "db.team" */
export type DbTeamSelectColumnDbTeamAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbTeamAggregateBoolExpBool_orArgumentsColumns" columns of table "db.team" */
export type DbTeamSelectColumnDbTeamAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbTeamStddevFields = {
  __typename?: 'DbTeamStddevFields';
  go3DepartmentKey?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.team" */
export type DbTeamStddevOrderBy = {
  go3DepartmentKey?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbTeamStddevPopFields = {
  __typename?: 'DbTeamStddevPopFields';
  go3DepartmentKey?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.team" */
export type DbTeamStddevPopOrderBy = {
  go3DepartmentKey?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbTeamStddevSampFields = {
  __typename?: 'DbTeamStddevSampFields';
  go3DepartmentKey?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.team" */
export type DbTeamStddevSampOrderBy = {
  go3DepartmentKey?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_team" */
export type DbTeamStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbTeamStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbTeamStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  go3DepartmentKey?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  normalizedName?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  shopId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TeamStatusEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbTeamSumFields = {
  __typename?: 'DbTeamSumFields';
  go3DepartmentKey?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.team" */
export type DbTeamSumOrderBy = {
  go3DepartmentKey?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbTeamVarPopFields = {
  __typename?: 'DbTeamVarPopFields';
  go3DepartmentKey?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.team" */
export type DbTeamVarPopOrderBy = {
  go3DepartmentKey?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbTeamVarSampFields = {
  __typename?: 'DbTeamVarSampFields';
  go3DepartmentKey?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.team" */
export type DbTeamVarSampOrderBy = {
  go3DepartmentKey?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbTeamVarianceFields = {
  __typename?: 'DbTeamVarianceFields';
  go3DepartmentKey?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.team" */
export type DbTeamVarianceOrderBy = {
  go3DepartmentKey?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.tracking_lead" */
export type DbTrackingLead = {
  __typename?: 'DbTrackingLead';
  content?: Maybe<Scalars['jsonb']>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  leadSource: Scalars['String'];
  leadType: Scalars['String'];
  num: Scalars['bigint'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** columns and relationships of "db.tracking_lead" */
export type DbTrackingLeadContentArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "db.tracking_lead" */
export type DbTrackingLeadAggregate = {
  __typename?: 'DbTrackingLeadAggregate';
  aggregate?: Maybe<DbTrackingLeadAggregateFields>;
  nodes: Array<DbTrackingLead>;
};

/** aggregate fields of "db.tracking_lead" */
export type DbTrackingLeadAggregateFields = {
  __typename?: 'DbTrackingLeadAggregateFields';
  avg?: Maybe<DbTrackingLeadAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbTrackingLeadMaxFields>;
  min?: Maybe<DbTrackingLeadMinFields>;
  stddev?: Maybe<DbTrackingLeadStddevFields>;
  stddevPop?: Maybe<DbTrackingLeadStddevPopFields>;
  stddevSamp?: Maybe<DbTrackingLeadStddevSampFields>;
  sum?: Maybe<DbTrackingLeadSumFields>;
  varPop?: Maybe<DbTrackingLeadVarPopFields>;
  varSamp?: Maybe<DbTrackingLeadVarSampFields>;
  variance?: Maybe<DbTrackingLeadVarianceFields>;
};

/** aggregate fields of "db.tracking_lead" */
export type DbTrackingLeadAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbTrackingLeadSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type DbTrackingLeadAvgFields = {
  __typename?: 'DbTrackingLeadAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "db.tracking_lead". All fields are combined with a logical 'AND'. */
export type DbTrackingLeadBoolExp = {
  _and?: InputMaybe<Array<DbTrackingLeadBoolExp>>;
  _not?: InputMaybe<DbTrackingLeadBoolExp>;
  _or?: InputMaybe<Array<DbTrackingLeadBoolExp>>;
  content?: InputMaybe<JsonbComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  leadSource?: InputMaybe<StringComparisonExp>;
  leadType?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbTrackingLeadMaxFields = {
  __typename?: 'DbTrackingLeadMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  leadSource?: Maybe<Scalars['String']>;
  leadType?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type DbTrackingLeadMinFields = {
  __typename?: 'DbTrackingLeadMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  leadSource?: Maybe<Scalars['String']>;
  leadType?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "db.tracking_lead". */
export type DbTrackingLeadOrderBy = {
  content?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  leadSource?: InputMaybe<OrderBy>;
  leadType?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.tracking_lead" */
export type DbTrackingLeadSelectColumn =
  /** column name */
  | 'content'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'leadSource'
  /** column name */
  | 'leadType'
  /** column name */
  | 'num'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** aggregate stddev on columns */
export type DbTrackingLeadStddevFields = {
  __typename?: 'DbTrackingLeadStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate stddevPop on columns */
export type DbTrackingLeadStddevPopFields = {
  __typename?: 'DbTrackingLeadStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate stddevSamp on columns */
export type DbTrackingLeadStddevSampFields = {
  __typename?: 'DbTrackingLeadStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "db_tracking_lead" */
export type DbTrackingLeadStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbTrackingLeadStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbTrackingLeadStreamCursorValueInput = {
  content?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  leadSource?: InputMaybe<Scalars['String']>;
  leadType?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbTrackingLeadSumFields = {
  __typename?: 'DbTrackingLeadSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** aggregate varPop on columns */
export type DbTrackingLeadVarPopFields = {
  __typename?: 'DbTrackingLeadVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate varSamp on columns */
export type DbTrackingLeadVarSampFields = {
  __typename?: 'DbTrackingLeadVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type DbTrackingLeadVarianceFields = {
  __typename?: 'DbTrackingLeadVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "db.twilio_configuration" */
export type DbTwilioConfiguration = {
  __typename?: 'DbTwilioConfiguration';
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  displayNumber: Scalars['String'];
  id: Scalars['String'];
  /** An object relationship */
  shop: DbShop;
  shopId: Scalars['String'];
  twilioNumber: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
};

/** aggregated selection of "db.twilio_configuration" */
export type DbTwilioConfigurationAggregate = {
  __typename?: 'DbTwilioConfigurationAggregate';
  aggregate?: Maybe<DbTwilioConfigurationAggregateFields>;
  nodes: Array<DbTwilioConfiguration>;
};

export type DbTwilioConfigurationAggregateBoolExp = {
  bool_and?: InputMaybe<DbTwilioConfigurationAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbTwilioConfigurationAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbTwilioConfigurationAggregateBoolExpCount>;
};

/** aggregate fields of "db.twilio_configuration" */
export type DbTwilioConfigurationAggregateFields = {
  __typename?: 'DbTwilioConfigurationAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<DbTwilioConfigurationMaxFields>;
  min?: Maybe<DbTwilioConfigurationMinFields>;
};

/** aggregate fields of "db.twilio_configuration" */
export type DbTwilioConfigurationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbTwilioConfigurationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.twilio_configuration" */
export type DbTwilioConfigurationAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbTwilioConfigurationMaxOrderBy>;
  min?: InputMaybe<DbTwilioConfigurationMinOrderBy>;
};

/** Boolean expression to filter rows from the table "db.twilio_configuration". All fields are combined with a logical 'AND'. */
export type DbTwilioConfigurationBoolExp = {
  _and?: InputMaybe<Array<DbTwilioConfigurationBoolExp>>;
  _not?: InputMaybe<DbTwilioConfigurationBoolExp>;
  _or?: InputMaybe<Array<DbTwilioConfigurationBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  displayNumber?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  twilioNumber?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type DbTwilioConfigurationMaxFields = {
  __typename?: 'DbTwilioConfigurationMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  displayNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['String']>;
  twilioNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.twilio_configuration" */
export type DbTwilioConfigurationMaxOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  displayNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  twilioNumber?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbTwilioConfigurationMinFields = {
  __typename?: 'DbTwilioConfigurationMinFields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  displayNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['String']>;
  twilioNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.twilio_configuration" */
export type DbTwilioConfigurationMinOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  displayNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  twilioNumber?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.twilio_configuration". */
export type DbTwilioConfigurationOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  displayNumber?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  twilioNumber?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** select columns of table "db.twilio_configuration" */
export type DbTwilioConfigurationSelectColumn =
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'displayNumber'
  /** column name */
  | 'id'
  /** column name */
  | 'shopId'
  /** column name */
  | 'twilioNumber'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbTwilioConfigurationAggregateBoolExpBool_andArgumentsColumns" columns of table "db.twilio_configuration" */
export type DbTwilioConfigurationSelectColumnDbTwilioConfigurationAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbTwilioConfigurationAggregateBoolExpBool_orArgumentsColumns" columns of table "db.twilio_configuration" */
export type DbTwilioConfigurationSelectColumnDbTwilioConfigurationAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** Streaming cursor of the table "db_twilio_configuration" */
export type DbTwilioConfigurationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbTwilioConfigurationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbTwilioConfigurationStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  displayNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  shopId?: InputMaybe<Scalars['String']>;
  twilioNumber?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "db.user_admin_assignment" */
export type DbUserAdminAssignment = {
  __typename?: 'DbUserAdminAssignment';
  /** An object relationship */
  agent: DbAgent;
  agentId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  keycloakEventTime: Scalars['timestamp'];
  num: Scalars['bigint'];
  role: UserRoleEnum;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  /** An object relationship */
  userRole: EnumsUserRole;
};

/** aggregated selection of "db.user_admin_assignment" */
export type DbUserAdminAssignmentAggregate = {
  __typename?: 'DbUserAdminAssignmentAggregate';
  aggregate?: Maybe<DbUserAdminAssignmentAggregateFields>;
  nodes: Array<DbUserAdminAssignment>;
};

export type DbUserAdminAssignmentAggregateBoolExp = {
  bool_and?: InputMaybe<DbUserAdminAssignmentAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbUserAdminAssignmentAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbUserAdminAssignmentAggregateBoolExpCount>;
};

/** aggregate fields of "db.user_admin_assignment" */
export type DbUserAdminAssignmentAggregateFields = {
  __typename?: 'DbUserAdminAssignmentAggregateFields';
  avg?: Maybe<DbUserAdminAssignmentAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbUserAdminAssignmentMaxFields>;
  min?: Maybe<DbUserAdminAssignmentMinFields>;
  stddev?: Maybe<DbUserAdminAssignmentStddevFields>;
  stddevPop?: Maybe<DbUserAdminAssignmentStddevPopFields>;
  stddevSamp?: Maybe<DbUserAdminAssignmentStddevSampFields>;
  sum?: Maybe<DbUserAdminAssignmentSumFields>;
  varPop?: Maybe<DbUserAdminAssignmentVarPopFields>;
  varSamp?: Maybe<DbUserAdminAssignmentVarSampFields>;
  variance?: Maybe<DbUserAdminAssignmentVarianceFields>;
};

/** aggregate fields of "db.user_admin_assignment" */
export type DbUserAdminAssignmentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbUserAdminAssignmentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.user_admin_assignment" */
export type DbUserAdminAssignmentAggregateOrderBy = {
  avg?: InputMaybe<DbUserAdminAssignmentAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbUserAdminAssignmentMaxOrderBy>;
  min?: InputMaybe<DbUserAdminAssignmentMinOrderBy>;
  stddev?: InputMaybe<DbUserAdminAssignmentStddevOrderBy>;
  stddevPop?: InputMaybe<DbUserAdminAssignmentStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbUserAdminAssignmentStddevSampOrderBy>;
  sum?: InputMaybe<DbUserAdminAssignmentSumOrderBy>;
  varPop?: InputMaybe<DbUserAdminAssignmentVarPopOrderBy>;
  varSamp?: InputMaybe<DbUserAdminAssignmentVarSampOrderBy>;
  variance?: InputMaybe<DbUserAdminAssignmentVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbUserAdminAssignmentAvgFields = {
  __typename?: 'DbUserAdminAssignmentAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.user_admin_assignment" */
export type DbUserAdminAssignmentAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.user_admin_assignment". All fields are combined with a logical 'AND'. */
export type DbUserAdminAssignmentBoolExp = {
  _and?: InputMaybe<Array<DbUserAdminAssignmentBoolExp>>;
  _not?: InputMaybe<DbUserAdminAssignmentBoolExp>;
  _or?: InputMaybe<Array<DbUserAdminAssignmentBoolExp>>;
  agent?: InputMaybe<DbAgentBoolExp>;
  agentId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  keycloakEventTime?: InputMaybe<TimestampComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  role?: InputMaybe<EnumsUserRoleEnumComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  userRole?: InputMaybe<EnumsUserRoleBoolExp>;
};

/** aggregate max on columns */
export type DbUserAdminAssignmentMaxFields = {
  __typename?: 'DbUserAdminAssignmentMaxFields';
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  keycloakEventTime?: Maybe<Scalars['timestamp']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.user_admin_assignment" */
export type DbUserAdminAssignmentMaxOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  keycloakEventTime?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbUserAdminAssignmentMinFields = {
  __typename?: 'DbUserAdminAssignmentMinFields';
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  keycloakEventTime?: Maybe<Scalars['timestamp']>;
  num?: Maybe<Scalars['bigint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.user_admin_assignment" */
export type DbUserAdminAssignmentMinOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  keycloakEventTime?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.user_admin_assignment". */
export type DbUserAdminAssignmentOrderBy = {
  agent?: InputMaybe<DbAgentOrderBy>;
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  keycloakEventTime?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  userRole?: InputMaybe<EnumsUserRoleOrderBy>;
};

/** select columns of table "db.user_admin_assignment" */
export type DbUserAdminAssignmentSelectColumn =
  /** column name */
  | 'agentId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'keycloakEventTime'
  /** column name */
  | 'num'
  /** column name */
  | 'role'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbUserAdminAssignmentAggregateBoolExpBool_andArgumentsColumns" columns of table "db.user_admin_assignment" */
export type DbUserAdminAssignmentSelectColumnDbUserAdminAssignmentAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbUserAdminAssignmentAggregateBoolExpBool_orArgumentsColumns" columns of table "db.user_admin_assignment" */
export type DbUserAdminAssignmentSelectColumnDbUserAdminAssignmentAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbUserAdminAssignmentStddevFields = {
  __typename?: 'DbUserAdminAssignmentStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.user_admin_assignment" */
export type DbUserAdminAssignmentStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbUserAdminAssignmentStddevPopFields = {
  __typename?: 'DbUserAdminAssignmentStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.user_admin_assignment" */
export type DbUserAdminAssignmentStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbUserAdminAssignmentStddevSampFields = {
  __typename?: 'DbUserAdminAssignmentStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.user_admin_assignment" */
export type DbUserAdminAssignmentStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_user_admin_assignment" */
export type DbUserAdminAssignmentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbUserAdminAssignmentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbUserAdminAssignmentStreamCursorValueInput = {
  agentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  keycloakEventTime?: InputMaybe<Scalars['timestamp']>;
  num?: InputMaybe<Scalars['bigint']>;
  role?: InputMaybe<UserRoleEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbUserAdminAssignmentSumFields = {
  __typename?: 'DbUserAdminAssignmentSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.user_admin_assignment" */
export type DbUserAdminAssignmentSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbUserAdminAssignmentVarPopFields = {
  __typename?: 'DbUserAdminAssignmentVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.user_admin_assignment" */
export type DbUserAdminAssignmentVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbUserAdminAssignmentVarSampFields = {
  __typename?: 'DbUserAdminAssignmentVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.user_admin_assignment" */
export type DbUserAdminAssignmentVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbUserAdminAssignmentVarianceFields = {
  __typename?: 'DbUserAdminAssignmentVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.user_admin_assignment" */
export type DbUserAdminAssignmentVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.user_shop_assignment" */
export type DbUserShopAssignment = {
  __typename?: 'DbUserShopAssignment';
  /** An object relationship */
  agent: DbAgent;
  agentId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  keycloakEventTime: Scalars['timestamp'];
  num: Scalars['bigint'];
  role: UserRoleEnum;
  /** An object relationship */
  shop: DbShop;
  shopId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  /** An object relationship */
  userRole: EnumsUserRole;
};

/** aggregated selection of "db.user_shop_assignment" */
export type DbUserShopAssignmentAggregate = {
  __typename?: 'DbUserShopAssignmentAggregate';
  aggregate?: Maybe<DbUserShopAssignmentAggregateFields>;
  nodes: Array<DbUserShopAssignment>;
};

export type DbUserShopAssignmentAggregateBoolExp = {
  bool_and?: InputMaybe<DbUserShopAssignmentAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbUserShopAssignmentAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbUserShopAssignmentAggregateBoolExpCount>;
};

/** aggregate fields of "db.user_shop_assignment" */
export type DbUserShopAssignmentAggregateFields = {
  __typename?: 'DbUserShopAssignmentAggregateFields';
  avg?: Maybe<DbUserShopAssignmentAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbUserShopAssignmentMaxFields>;
  min?: Maybe<DbUserShopAssignmentMinFields>;
  stddev?: Maybe<DbUserShopAssignmentStddevFields>;
  stddevPop?: Maybe<DbUserShopAssignmentStddevPopFields>;
  stddevSamp?: Maybe<DbUserShopAssignmentStddevSampFields>;
  sum?: Maybe<DbUserShopAssignmentSumFields>;
  varPop?: Maybe<DbUserShopAssignmentVarPopFields>;
  varSamp?: Maybe<DbUserShopAssignmentVarSampFields>;
  variance?: Maybe<DbUserShopAssignmentVarianceFields>;
};

/** aggregate fields of "db.user_shop_assignment" */
export type DbUserShopAssignmentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbUserShopAssignmentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.user_shop_assignment" */
export type DbUserShopAssignmentAggregateOrderBy = {
  avg?: InputMaybe<DbUserShopAssignmentAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbUserShopAssignmentMaxOrderBy>;
  min?: InputMaybe<DbUserShopAssignmentMinOrderBy>;
  stddev?: InputMaybe<DbUserShopAssignmentStddevOrderBy>;
  stddevPop?: InputMaybe<DbUserShopAssignmentStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbUserShopAssignmentStddevSampOrderBy>;
  sum?: InputMaybe<DbUserShopAssignmentSumOrderBy>;
  varPop?: InputMaybe<DbUserShopAssignmentVarPopOrderBy>;
  varSamp?: InputMaybe<DbUserShopAssignmentVarSampOrderBy>;
  variance?: InputMaybe<DbUserShopAssignmentVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbUserShopAssignmentAvgFields = {
  __typename?: 'DbUserShopAssignmentAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.user_shop_assignment" */
export type DbUserShopAssignmentAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.user_shop_assignment". All fields are combined with a logical 'AND'. */
export type DbUserShopAssignmentBoolExp = {
  _and?: InputMaybe<Array<DbUserShopAssignmentBoolExp>>;
  _not?: InputMaybe<DbUserShopAssignmentBoolExp>;
  _or?: InputMaybe<Array<DbUserShopAssignmentBoolExp>>;
  agent?: InputMaybe<DbAgentBoolExp>;
  agentId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  keycloakEventTime?: InputMaybe<TimestampComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  role?: InputMaybe<EnumsUserRoleEnumComparisonExp>;
  shop?: InputMaybe<DbShopBoolExp>;
  shopId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  userRole?: InputMaybe<EnumsUserRoleBoolExp>;
};

/** aggregate max on columns */
export type DbUserShopAssignmentMaxFields = {
  __typename?: 'DbUserShopAssignmentMaxFields';
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  keycloakEventTime?: Maybe<Scalars['timestamp']>;
  num?: Maybe<Scalars['bigint']>;
  shopId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.user_shop_assignment" */
export type DbUserShopAssignmentMaxOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  keycloakEventTime?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbUserShopAssignmentMinFields = {
  __typename?: 'DbUserShopAssignmentMinFields';
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  keycloakEventTime?: Maybe<Scalars['timestamp']>;
  num?: Maybe<Scalars['bigint']>;
  shopId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.user_shop_assignment" */
export type DbUserShopAssignmentMinOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  keycloakEventTime?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.user_shop_assignment". */
export type DbUserShopAssignmentOrderBy = {
  agent?: InputMaybe<DbAgentOrderBy>;
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  keycloakEventTime?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  shop?: InputMaybe<DbShopOrderBy>;
  shopId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  userRole?: InputMaybe<EnumsUserRoleOrderBy>;
};

/** select columns of table "db.user_shop_assignment" */
export type DbUserShopAssignmentSelectColumn =
  /** column name */
  | 'agentId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'keycloakEventTime'
  /** column name */
  | 'num'
  /** column name */
  | 'role'
  /** column name */
  | 'shopId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbUserShopAssignmentAggregateBoolExpBool_andArgumentsColumns" columns of table "db.user_shop_assignment" */
export type DbUserShopAssignmentSelectColumnDbUserShopAssignmentAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbUserShopAssignmentAggregateBoolExpBool_orArgumentsColumns" columns of table "db.user_shop_assignment" */
export type DbUserShopAssignmentSelectColumnDbUserShopAssignmentAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbUserShopAssignmentStddevFields = {
  __typename?: 'DbUserShopAssignmentStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.user_shop_assignment" */
export type DbUserShopAssignmentStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbUserShopAssignmentStddevPopFields = {
  __typename?: 'DbUserShopAssignmentStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.user_shop_assignment" */
export type DbUserShopAssignmentStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbUserShopAssignmentStddevSampFields = {
  __typename?: 'DbUserShopAssignmentStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.user_shop_assignment" */
export type DbUserShopAssignmentStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_user_shop_assignment" */
export type DbUserShopAssignmentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbUserShopAssignmentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbUserShopAssignmentStreamCursorValueInput = {
  agentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  keycloakEventTime?: InputMaybe<Scalars['timestamp']>;
  num?: InputMaybe<Scalars['bigint']>;
  role?: InputMaybe<UserRoleEnum>;
  shopId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbUserShopAssignmentSumFields = {
  __typename?: 'DbUserShopAssignmentSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.user_shop_assignment" */
export type DbUserShopAssignmentSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbUserShopAssignmentVarPopFields = {
  __typename?: 'DbUserShopAssignmentVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.user_shop_assignment" */
export type DbUserShopAssignmentVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbUserShopAssignmentVarSampFields = {
  __typename?: 'DbUserShopAssignmentVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.user_shop_assignment" */
export type DbUserShopAssignmentVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbUserShopAssignmentVarianceFields = {
  __typename?: 'DbUserShopAssignmentVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.user_shop_assignment" */
export type DbUserShopAssignmentVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.user_team_assignment" */
export type DbUserTeamAssignment = {
  __typename?: 'DbUserTeamAssignment';
  /** An object relationship */
  agent: DbAgent;
  agentId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  keycloakEventTime: Scalars['timestamp'];
  num: Scalars['bigint'];
  role: UserRoleEnum;
  /** An object relationship */
  team: DbTeam;
  teamId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  /** An object relationship */
  userRole: EnumsUserRole;
};

/** aggregated selection of "db.user_team_assignment" */
export type DbUserTeamAssignmentAggregate = {
  __typename?: 'DbUserTeamAssignmentAggregate';
  aggregate?: Maybe<DbUserTeamAssignmentAggregateFields>;
  nodes: Array<DbUserTeamAssignment>;
};

export type DbUserTeamAssignmentAggregateBoolExp = {
  bool_and?: InputMaybe<DbUserTeamAssignmentAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<DbUserTeamAssignmentAggregateBoolExpBoolOr>;
  count?: InputMaybe<DbUserTeamAssignmentAggregateBoolExpCount>;
};

/** aggregate fields of "db.user_team_assignment" */
export type DbUserTeamAssignmentAggregateFields = {
  __typename?: 'DbUserTeamAssignmentAggregateFields';
  avg?: Maybe<DbUserTeamAssignmentAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<DbUserTeamAssignmentMaxFields>;
  min?: Maybe<DbUserTeamAssignmentMinFields>;
  stddev?: Maybe<DbUserTeamAssignmentStddevFields>;
  stddevPop?: Maybe<DbUserTeamAssignmentStddevPopFields>;
  stddevSamp?: Maybe<DbUserTeamAssignmentStddevSampFields>;
  sum?: Maybe<DbUserTeamAssignmentSumFields>;
  varPop?: Maybe<DbUserTeamAssignmentVarPopFields>;
  varSamp?: Maybe<DbUserTeamAssignmentVarSampFields>;
  variance?: Maybe<DbUserTeamAssignmentVarianceFields>;
};

/** aggregate fields of "db.user_team_assignment" */
export type DbUserTeamAssignmentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DbUserTeamAssignmentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "db.user_team_assignment" */
export type DbUserTeamAssignmentAggregateOrderBy = {
  avg?: InputMaybe<DbUserTeamAssignmentAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DbUserTeamAssignmentMaxOrderBy>;
  min?: InputMaybe<DbUserTeamAssignmentMinOrderBy>;
  stddev?: InputMaybe<DbUserTeamAssignmentStddevOrderBy>;
  stddevPop?: InputMaybe<DbUserTeamAssignmentStddevPopOrderBy>;
  stddevSamp?: InputMaybe<DbUserTeamAssignmentStddevSampOrderBy>;
  sum?: InputMaybe<DbUserTeamAssignmentSumOrderBy>;
  varPop?: InputMaybe<DbUserTeamAssignmentVarPopOrderBy>;
  varSamp?: InputMaybe<DbUserTeamAssignmentVarSampOrderBy>;
  variance?: InputMaybe<DbUserTeamAssignmentVarianceOrderBy>;
};

/** aggregate avg on columns */
export type DbUserTeamAssignmentAvgFields = {
  __typename?: 'DbUserTeamAssignmentAvgFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "db.user_team_assignment" */
export type DbUserTeamAssignmentAvgOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "db.user_team_assignment". All fields are combined with a logical 'AND'. */
export type DbUserTeamAssignmentBoolExp = {
  _and?: InputMaybe<Array<DbUserTeamAssignmentBoolExp>>;
  _not?: InputMaybe<DbUserTeamAssignmentBoolExp>;
  _or?: InputMaybe<Array<DbUserTeamAssignmentBoolExp>>;
  agent?: InputMaybe<DbAgentBoolExp>;
  agentId?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  keycloakEventTime?: InputMaybe<TimestampComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  role?: InputMaybe<EnumsUserRoleEnumComparisonExp>;
  team?: InputMaybe<DbTeamBoolExp>;
  teamId?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  userRole?: InputMaybe<EnumsUserRoleBoolExp>;
};

/** aggregate max on columns */
export type DbUserTeamAssignmentMaxFields = {
  __typename?: 'DbUserTeamAssignmentMaxFields';
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  keycloakEventTime?: Maybe<Scalars['timestamp']>;
  num?: Maybe<Scalars['bigint']>;
  teamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "db.user_team_assignment" */
export type DbUserTeamAssignmentMaxOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  keycloakEventTime?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DbUserTeamAssignmentMinFields = {
  __typename?: 'DbUserTeamAssignmentMinFields';
  agentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  keycloakEventTime?: Maybe<Scalars['timestamp']>;
  num?: Maybe<Scalars['bigint']>;
  teamId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  updatedBy?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "db.user_team_assignment" */
export type DbUserTeamAssignmentMinOrderBy = {
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  keycloakEventTime?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "db.user_team_assignment". */
export type DbUserTeamAssignmentOrderBy = {
  agent?: InputMaybe<DbAgentOrderBy>;
  agentId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  keycloakEventTime?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  role?: InputMaybe<OrderBy>;
  team?: InputMaybe<DbTeamOrderBy>;
  teamId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  userRole?: InputMaybe<EnumsUserRoleOrderBy>;
};

/** select columns of table "db.user_team_assignment" */
export type DbUserTeamAssignmentSelectColumn =
  /** column name */
  | 'agentId'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'id'
  /** column name */
  | 'keycloakEventTime'
  /** column name */
  | 'num'
  /** column name */
  | 'role'
  /** column name */
  | 'teamId'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy';

/** select "dbUserTeamAssignmentAggregateBoolExpBool_andArgumentsColumns" columns of table "db.user_team_assignment" */
export type DbUserTeamAssignmentSelectColumnDbUserTeamAssignmentAggregateBoolExpBoolAndArgumentsColumns =
  /** column name */
  'deleted';

/** select "dbUserTeamAssignmentAggregateBoolExpBool_orArgumentsColumns" columns of table "db.user_team_assignment" */
export type DbUserTeamAssignmentSelectColumnDbUserTeamAssignmentAggregateBoolExpBoolOrArgumentsColumns =
  /** column name */
  'deleted';

/** aggregate stddev on columns */
export type DbUserTeamAssignmentStddevFields = {
  __typename?: 'DbUserTeamAssignmentStddevFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "db.user_team_assignment" */
export type DbUserTeamAssignmentStddevOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevPop on columns */
export type DbUserTeamAssignmentStddevPopFields = {
  __typename?: 'DbUserTeamAssignmentStddevPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevPop() on columns of table "db.user_team_assignment" */
export type DbUserTeamAssignmentStddevPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate stddevSamp on columns */
export type DbUserTeamAssignmentStddevSampFields = {
  __typename?: 'DbUserTeamAssignmentStddevSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by stddevSamp() on columns of table "db.user_team_assignment" */
export type DbUserTeamAssignmentStddevSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "db_user_team_assignment" */
export type DbUserTeamAssignmentStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbUserTeamAssignmentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbUserTeamAssignmentStreamCursorValueInput = {
  agentId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['String']>;
  keycloakEventTime?: InputMaybe<Scalars['timestamp']>;
  num?: InputMaybe<Scalars['bigint']>;
  role?: InputMaybe<UserRoleEnum>;
  teamId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type DbUserTeamAssignmentSumFields = {
  __typename?: 'DbUserTeamAssignmentSumFields';
  num?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "db.user_team_assignment" */
export type DbUserTeamAssignmentSumOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varPop on columns */
export type DbUserTeamAssignmentVarPopFields = {
  __typename?: 'DbUserTeamAssignmentVarPopFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varPop() on columns of table "db.user_team_assignment" */
export type DbUserTeamAssignmentVarPopOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate varSamp on columns */
export type DbUserTeamAssignmentVarSampFields = {
  __typename?: 'DbUserTeamAssignmentVarSampFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by varSamp() on columns of table "db.user_team_assignment" */
export type DbUserTeamAssignmentVarSampOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DbUserTeamAssignmentVarianceFields = {
  __typename?: 'DbUserTeamAssignmentVarianceFields';
  num?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "db.user_team_assignment" */
export type DbUserTeamAssignmentVarianceOrderBy = {
  num?: InputMaybe<OrderBy>;
};

/** columns and relationships of "db.utm_param" */
export type DbUtmParam = {
  __typename?: 'DbUtmParam';
  autaclid?: Maybe<Scalars['String']>;
  campaign?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  fbclid?: Maybe<Scalars['String']>;
  gbraid?: Maybe<Scalars['String']>;
  gclid?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** An object relationship */
  lead?: Maybe<DbLead>;
  leadId?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  msclkid?: Maybe<Scalars['String']>;
  num: Scalars['bigint'];
  pmclid?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  updatedBy: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  wbraid?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "db.utm_param". All fields are combined with a logical 'AND'. */
export type DbUtmParamBoolExp = {
  _and?: InputMaybe<Array<DbUtmParamBoolExp>>;
  _not?: InputMaybe<DbUtmParamBoolExp>;
  _or?: InputMaybe<Array<DbUtmParamBoolExp>>;
  autaclid?: InputMaybe<StringComparisonExp>;
  campaign?: InputMaybe<StringComparisonExp>;
  content?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<StringComparisonExp>;
  deleted?: InputMaybe<BooleanComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  fbclid?: InputMaybe<StringComparisonExp>;
  gbraid?: InputMaybe<StringComparisonExp>;
  gclid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  lead?: InputMaybe<DbLeadBoolExp>;
  leadId?: InputMaybe<StringComparisonExp>;
  medium?: InputMaybe<StringComparisonExp>;
  msclkid?: InputMaybe<StringComparisonExp>;
  num?: InputMaybe<BigintComparisonExp>;
  pmclid?: InputMaybe<StringComparisonExp>;
  source?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<StringComparisonExp>;
  url?: InputMaybe<StringComparisonExp>;
  wbraid?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "db.utm_param". */
export type DbUtmParamOrderBy = {
  autaclid?: InputMaybe<OrderBy>;
  campaign?: InputMaybe<OrderBy>;
  content?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  deleted?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  fbclid?: InputMaybe<OrderBy>;
  gbraid?: InputMaybe<OrderBy>;
  gclid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lead?: InputMaybe<DbLeadOrderBy>;
  leadId?: InputMaybe<OrderBy>;
  medium?: InputMaybe<OrderBy>;
  msclkid?: InputMaybe<OrderBy>;
  num?: InputMaybe<OrderBy>;
  pmclid?: InputMaybe<OrderBy>;
  source?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  url?: InputMaybe<OrderBy>;
  wbraid?: InputMaybe<OrderBy>;
};

/** select columns of table "db.utm_param" */
export type DbUtmParamSelectColumn =
  /** column name */
  | 'autaclid'
  /** column name */
  | 'campaign'
  /** column name */
  | 'content'
  /** column name */
  | 'createdAt'
  /** column name */
  | 'createdBy'
  /** column name */
  | 'deleted'
  /** column name */
  | 'deletedAt'
  /** column name */
  | 'fbclid'
  /** column name */
  | 'gbraid'
  /** column name */
  | 'gclid'
  /** column name */
  | 'id'
  /** column name */
  | 'leadId'
  /** column name */
  | 'medium'
  /** column name */
  | 'msclkid'
  /** column name */
  | 'num'
  /** column name */
  | 'pmclid'
  /** column name */
  | 'source'
  /** column name */
  | 'updatedAt'
  /** column name */
  | 'updatedBy'
  /** column name */
  | 'url'
  /** column name */
  | 'wbraid';

/** Streaming cursor of the table "db_utm_param" */
export type DbUtmParamStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: DbUtmParamStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DbUtmParamStreamCursorValueInput = {
  autaclid?: InputMaybe<Scalars['String']>;
  campaign?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']>;
  fbclid?: InputMaybe<Scalars['String']>;
  gbraid?: InputMaybe<Scalars['String']>;
  gclid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  leadId?: InputMaybe<Scalars['String']>;
  medium?: InputMaybe<Scalars['String']>;
  msclkid?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['bigint']>;
  pmclid?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  wbraid?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.activity_type" */
export type EnumsActivityType = {
  __typename?: 'EnumsActivityType';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.activity_type". All fields are combined with a logical 'AND'. */
export type EnumsActivityTypeBoolExp = {
  _and?: InputMaybe<Array<EnumsActivityTypeBoolExp>>;
  _not?: InputMaybe<EnumsActivityTypeBoolExp>;
  _or?: InputMaybe<Array<EnumsActivityTypeBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type ActivityTypeEnum =
  | 'ACTIVITIES_BY_CONTACT_EXPORTED'
  | 'ACTIVITIES_BY_PROPERTY_EXPORTED'
  | 'AGENT_ASSIGNED_TO_CONTACT'
  | 'AGENT_REASSIGNED_TO_CONTACT'
  | 'APPOINTMENT_CREATED'
  | 'APPOINTMENT_UPDATED'
  | 'CALL'
  | 'CONSENT_CONFIRMATION_MAIL_SENT'
  | 'CONSENT_CONFIRMED'
  | 'CONSENT_EXPIRED'
  /** Consent is not given. */
  | 'CONSENT_REJECTED'
  /** CONSENT_REQUESTED */
  | 'CONSENT_REQUESTED'
  | 'CONSENT_REVOKED'
  | 'CONSENT_UPDATED'
  | 'CONTACTS_BY_AGENT_EXPORTED'
  | 'CONTACT_ACTIVATED'
  | 'CONTACT_BLOCKED'
  | 'CONTACT_CREATED'
  | 'CONTACT_LINKED'
  | 'CONTACT_MERGED'
  | 'CONTACT_UNBLOCKED'
  | 'CONTACT_UPDATED'
  | 'CUSTOMER_ACCOUNT_UPDATED'
  | 'DOCUMENT_CREATED'
  | 'DOCUMENT_DELETED'
  | 'DOCUMENT_MODIFIED'
  | 'DOCUMENT_PRINTED'
  | 'EMAIL_DELINKING_TO_CONTACT'
  | 'EMAIL_DELINKING_TO_LEAD'
  | 'EMAIL_LINKING_TO_CONTACT'
  | 'EMAIL_LINKING_TO_LEAD'
  | 'EXPOSE'
  | 'EXPOSE_SENT'
  | 'EXPOSE_SENT_CC'
  | 'IMS_EMAIL_SENT'
  | 'INCOMING_CALL'
  | 'INCOMING_EMAIL'
  | 'LEAD_ASSIGNED_TO_AGENT'
  | 'LEAD_CREATED'
  | 'LEAD_QUALIFICATION_COMMISSION_CONTRACT_EMAIL_SENT'
  | 'LEAD_QUALIFICATION_FORM_RESENT'
  | 'LEAD_QUALIFICATION_FORM_SENT'
  | 'LEAD_QUALIFICATION_FORM_SUBMITTED'
  | 'LEAD_QUALIFICATION_PROPERTY_NOT_AVAILABLE_EMAIL_SENT'
  | 'LEAD_QUALIFICATION_REMINDER_SENT'
  | 'LEAD_QUALIFICATION_WITH_MISSING_WITHDRAWAL_CONSENT_EMAIL_SENT'
  | 'LEAD_QUALIFIED'
  | 'LEAD_REFERRAL_CUSTOMER_OUTGOING_EMAIL'
  | 'LEAD_REFERRAL_SHOP_OUTGOING_EMAIL'
  | 'LEAD_UPDATED'
  | 'LEGACY_GO3_CHANGE'
  | 'LETTER'
  | 'LINKED_TO_PROPERTY'
  | 'LINKED_TO_SEARCH_DEMAND'
  | 'MIGRATION_PATCHING'
  | 'MISSED_CALL'
  | 'NEWSLETTER_RECIPIENTS_EXPORTED'
  | 'OTHERS'
  | 'OUTGOING_CALL'
  | 'OUTGOING_CALL_API'
  | 'OUTGOING_CC'
  | 'OUTGOING_EMAIL'
  | 'POSTAL_CONTACTS_EXPORTED'
  | 'REFERRAL_SENT'
  | 'SHARED_LEAD'
  | 'SHOP_OUTGOING_CALL'
  | 'TASK_CREATED'
  | 'TASK_UPDATED'
  | 'UNLINKED_FROM_PROPERTY'
  | 'UNLINKED_FROM_SEARCH_DEMAND'
  | 'VIEWING_REPORT'
  | 'VIEWING_REPORT_CC'
  | 'VISIT_CONFIRMATION_SENT';

/** Boolean expression to compare columns of type "EnumsActivityTypeEnum". All fields are combined with logical 'AND'. */
export type EnumsActivityTypeEnumComparisonExp = {
  _eq?: InputMaybe<ActivityTypeEnum>;
  _in?: InputMaybe<Array<ActivityTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ActivityTypeEnum>;
  _nin?: InputMaybe<Array<ActivityTypeEnum>>;
};

/** Ordering options when selecting data from "enums.activity_type". */
export type EnumsActivityTypeOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.activity_type" */
export type EnumsActivityTypeSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_activity_type" */
export type EnumsActivityTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsActivityTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsActivityTypeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.address_format" */
export type EnumsAddressFormat = {
  __typename?: 'EnumsAddressFormat';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.address_format". All fields are combined with a logical 'AND'. */
export type EnumsAddressFormatBoolExp = {
  _and?: InputMaybe<Array<EnumsAddressFormatBoolExp>>;
  _not?: InputMaybe<EnumsAddressFormatBoolExp>;
  _or?: InputMaybe<Array<EnumsAddressFormatBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type AddressFormatEnum =
  /** {{streetName}} {{streetNumber}}\n{{secondAddressLine}}\n{{postalCode}} {{city}}\n{{country.name}} */
  | 'FULL'
  /** {{streetNumber}} {{streetName}}\n{{secondAddressLine}}\n{{postalCode}} {{city}}\n{{country.name}} */
  | 'FULL_WITH_SWAPPED_STREET'
  /** {{streetNumber}} {{streetName}}\n{{secondAddressLine}}\n{{city}} {{postalCode}}\n{{country.name}} */
  | 'FULL_WITH_SWAPPED_STREET_AND_CITY'
  /** {{streetNumber}} {{streetName}}\n{{secondAddressLine}}\n{{city}} {{country.name}} {{postalCode}}\”{{country.name}} */
  | 'FULL_WITH_SWAPPED_STREET_AND_CITY_EXTRA_COUNTRY'
  /** {{streetNumber}} {{streetName}}\n{{secondAddressLine}}\n{{city}}\n{{country.name}}\n{{postalCode}}\n{{country.name}} */
  | 'FULL_WITH_SWAPPED_STREET_AND_EXPLICIT'
  /** {{postalCode}} {{city}}\n{{postBox}}\n{{country.name}} */
  | 'POSTBOX';

/** Boolean expression to compare columns of type "EnumsAddressFormatEnum". All fields are combined with logical 'AND'. */
export type EnumsAddressFormatEnumComparisonExp = {
  _eq?: InputMaybe<AddressFormatEnum>;
  _in?: InputMaybe<Array<AddressFormatEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<AddressFormatEnum>;
  _nin?: InputMaybe<Array<AddressFormatEnum>>;
};

/** Ordering options when selecting data from "enums.address_format". */
export type EnumsAddressFormatOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.address_format" */
export type EnumsAddressFormatSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_address_format" */
export type EnumsAddressFormatStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsAddressFormatStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsAddressFormatStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.address_type" */
export type EnumsAddressType = {
  __typename?: 'EnumsAddressType';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.address_type". All fields are combined with a logical 'AND'. */
export type EnumsAddressTypeBoolExp = {
  _and?: InputMaybe<Array<EnumsAddressTypeBoolExp>>;
  _not?: InputMaybe<EnumsAddressTypeBoolExp>;
  _or?: InputMaybe<Array<EnumsAddressTypeBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type AddressTypeEnum =
  | 'BUSINESS'
  | 'HOLIDAY'
  | 'PRIVATE'
  | 'SECOND_IN_FOREIGN_COUNTRY'
  | 'SECOND_IN_HOME_COUNTRY'
  | 'SECRET'
  | 'UNDEFINED'
  | 'WEEKEND';

/** Boolean expression to compare columns of type "EnumsAddressTypeEnum". All fields are combined with logical 'AND'. */
export type EnumsAddressTypeEnumComparisonExp = {
  _eq?: InputMaybe<AddressTypeEnum>;
  _in?: InputMaybe<Array<AddressTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<AddressTypeEnum>;
  _nin?: InputMaybe<Array<AddressTypeEnum>>;
};

/** Ordering options when selecting data from "enums.address_type". */
export type EnumsAddressTypeOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.address_type" */
export type EnumsAddressTypeSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_address_type" */
export type EnumsAddressTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsAddressTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsAddressTypeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type AgentStatusEnum =
  /** Active */
  | 'ACTIVE'
  /** Inactive */
  | 'INACTIVE';

/** Boolean expression to compare columns of type "EnumsAgentStatusEnum". All fields are combined with logical 'AND'. */
export type EnumsAgentStatusEnumComparisonExp = {
  _eq?: InputMaybe<AgentStatusEnum>;
  _in?: InputMaybe<Array<AgentStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<AgentStatusEnum>;
  _nin?: InputMaybe<Array<AgentStatusEnum>>;
};

/** columns and relationships of "enums.appointment_type" */
export type EnumsAppointmentType = {
  __typename?: 'EnumsAppointmentType';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.appointment_type". All fields are combined with a logical 'AND'. */
export type EnumsAppointmentTypeBoolExp = {
  _and?: InputMaybe<Array<EnumsAppointmentTypeBoolExp>>;
  _not?: InputMaybe<EnumsAppointmentTypeBoolExp>;
  _or?: InputMaybe<Array<EnumsAppointmentTypeBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type AppointmentTypeEnum =
  | 'HANDOVER'
  | 'LISTING_PRESENTATION'
  | 'MISCELLANEOUS'
  | 'NOTARY'
  | 'OPEN_HOUSE'
  | 'PRICE_MODIFICATION'
  | 'PRICE_REDUCTION'
  | 'SEATRIAL'
  | 'VALUATION'
  | 'VIEWING';

/** Boolean expression to compare columns of type "EnumsAppointmentTypeEnum". All fields are combined with logical 'AND'. */
export type EnumsAppointmentTypeEnumComparisonExp = {
  _eq?: InputMaybe<AppointmentTypeEnum>;
  _in?: InputMaybe<Array<AppointmentTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<AppointmentTypeEnum>;
  _nin?: InputMaybe<Array<AppointmentTypeEnum>>;
};

/** Ordering options when selecting data from "enums.appointment_type". */
export type EnumsAppointmentTypeOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.appointment_type" */
export type EnumsAppointmentTypeSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_appointment_type" */
export type EnumsAppointmentTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsAppointmentTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsAppointmentTypeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.asset_condition" */
export type EnumsAssetCondition = {
  __typename?: 'EnumsAssetCondition';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.asset_condition". All fields are combined with a logical 'AND'. */
export type EnumsAssetConditionBoolExp = {
  _and?: InputMaybe<Array<EnumsAssetConditionBoolExp>>;
  _not?: InputMaybe<EnumsAssetConditionBoolExp>;
  _or?: InputMaybe<Array<EnumsAssetConditionBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type AssetConditionEnum =
  | 'AVERAGE'
  | 'GOOD'
  | 'NEEDS_REHABILITATION'
  | 'NEEDS_RENOVATION'
  | 'OTHER'
  | 'PARTLY_REHABILITATED'
  | 'REHABILITATION_DONE'
  | 'TOP'
  | 'VERY_GOOD';

/** Boolean expression to compare columns of type "EnumsAssetConditionEnum". All fields are combined with logical 'AND'. */
export type EnumsAssetConditionEnumComparisonExp = {
  _eq?: InputMaybe<AssetConditionEnum>;
  _in?: InputMaybe<Array<AssetConditionEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<AssetConditionEnum>;
  _nin?: InputMaybe<Array<AssetConditionEnum>>;
};

/** Ordering options when selecting data from "enums.asset_condition". */
export type EnumsAssetConditionOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.asset_condition" */
export type EnumsAssetConditionSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_asset_condition" */
export type EnumsAssetConditionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsAssetConditionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsAssetConditionStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.asset_sub_type" */
export type EnumsAssetSubType = {
  __typename?: 'EnumsAssetSubType';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** order by aggregate values of table "enums.asset_sub_type" */
export type EnumsAssetSubTypeAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EnumsAssetSubTypeMaxOrderBy>;
  min?: InputMaybe<EnumsAssetSubTypeMinOrderBy>;
};

/** Boolean expression to filter rows from the table "enums.asset_sub_type". All fields are combined with a logical 'AND'. */
export type EnumsAssetSubTypeBoolExp = {
  _and?: InputMaybe<Array<EnumsAssetSubTypeBoolExp>>;
  _not?: InputMaybe<EnumsAssetSubTypeBoolExp>;
  _or?: InputMaybe<Array<EnumsAssetSubTypeBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type AssetSubTypeEnum =
  /** agriculture land */
  | 'AGRICULTURE_LAND'
  /** attic apartment */
  | 'ATTIC_APARTMENT'
  /** building land */
  | 'BUILDING_LAND'
  /** bungalow */
  | 'BUNGALOW'
  /** country house */
  | 'COUNTRY_HOUSE'
  /** detached house */
  | 'DETACHED_HOUSE'
  /** developed land */
  | 'DEVELOPED_LAND'
  /** duplex */
  | 'DUPLEX'
  /** flat */
  | 'FLAT'
  /** forest */
  | 'FOREST'
  /** ground floor apartment */
  | 'GROUND_FLOOR_APARTMENT'
  /** hunting ground */
  | 'HUNTING_GROUND'
  /** investment */
  | 'INVESTMENT_HOUSE'
  /** lakeside land */
  | 'LAKESIDE_LAND'
  /** land in building lease */
  | 'LAND_IN_BUILDING_LEASE'
  /** loft */
  | 'LOFT'
  /** mooring */
  | 'MOORING'
  /** multi-family house */
  | 'MULTI_FAMILY_HOUSE'
  /** old building apartment */
  | 'OLD_BUILDING_APARTMENT'
  /** other apartment */
  | 'OTHER_APARTMENT'
  /** other house */
  | 'OTHER_HOUSE'
  /** other land */
  | 'OTHER_LAND'
  /** paddock */
  | 'PADDOCK'
  /** parking */
  | 'PARKING'
  /** pasture */
  | 'PASTURE'
  | 'PENTHOUSE'
  /** row house */
  | 'ROW_HOUSE'
  /** seaside land */
  | 'SEASIDE_LAND'
  /** semi-detached house */
  | 'SEMI_DETACHED_HOUSE'
  /** special property */
  | 'SPECIAL_PROPERTY'
  /** studio */
  | 'STUDIO'
  /** villa */
  | 'VILLA'
  /** wine land */
  | 'WINE_LAND';

/** Boolean expression to compare columns of type "EnumsAssetSubTypeEnum". All fields are combined with logical 'AND'. */
export type EnumsAssetSubTypeEnumComparisonExp = {
  _eq?: InputMaybe<AssetSubTypeEnum>;
  _in?: InputMaybe<Array<AssetSubTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<AssetSubTypeEnum>;
  _nin?: InputMaybe<Array<AssetSubTypeEnum>>;
};

/** order by max() on columns of table "enums.asset_sub_type" */
export type EnumsAssetSubTypeMaxOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "enums.asset_sub_type" */
export type EnumsAssetSubTypeMinOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "enums.asset_sub_type". */
export type EnumsAssetSubTypeOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.asset_sub_type" */
export type EnumsAssetSubTypeSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_asset_sub_type" */
export type EnumsAssetSubTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsAssetSubTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsAssetSubTypeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.asset_type" */
export type EnumsAssetType = {
  __typename?: 'EnumsAssetType';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.asset_type". All fields are combined with a logical 'AND'. */
export type EnumsAssetTypeBoolExp = {
  _and?: InputMaybe<Array<EnumsAssetTypeBoolExp>>;
  _not?: InputMaybe<EnumsAssetTypeBoolExp>;
  _or?: InputMaybe<Array<EnumsAssetTypeBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type AssetTypeEnum = 'APARTMENT' | 'HOUSE' | 'PLOT';

/** Boolean expression to compare columns of type "EnumsAssetTypeEnum". All fields are combined with logical 'AND'. */
export type EnumsAssetTypeEnumComparisonExp = {
  _eq?: InputMaybe<AssetTypeEnum>;
  _in?: InputMaybe<Array<AssetTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<AssetTypeEnum>;
  _nin?: InputMaybe<Array<AssetTypeEnum>>;
};

/** Ordering options when selecting data from "enums.asset_type". */
export type EnumsAssetTypeOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.asset_type" */
export type EnumsAssetTypeSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_asset_type" */
export type EnumsAssetTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsAssetTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsAssetTypeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.asset_variation" */
export type EnumsAssetVariation = {
  __typename?: 'EnumsAssetVariation';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** order by aggregate values of table "enums.asset_variation" */
export type EnumsAssetVariationAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EnumsAssetVariationMaxOrderBy>;
  min?: InputMaybe<EnumsAssetVariationMinOrderBy>;
};

/** Boolean expression to filter rows from the table "enums.asset_variation". All fields are combined with a logical 'AND'. */
export type EnumsAssetVariationBoolExp = {
  _and?: InputMaybe<Array<EnumsAssetVariationBoolExp>>;
  _not?: InputMaybe<EnumsAssetVariationBoolExp>;
  _or?: InputMaybe<Array<EnumsAssetVariationBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type AssetVariationEnum =
  /** castle */
  | 'CASTLE'
  /** chalet */
  | 'CHALET'
  /** cottage */
  | 'COTTAGE'
  /** end-of-row house */
  | 'END_OF_ROW_HOUSE'
  /** farm */
  | 'FARM'
  /** farmhouse */
  | 'FARMHOUSE'
  /** finca */
  | 'FINCA'
  /** houseboat */
  | 'HOUSEBOAT'
  /** manor */
  | 'MANOR'
  /** mid-row house */
  | 'MID_ROW_HOUSE';

/** Boolean expression to compare columns of type "EnumsAssetVariationEnum". All fields are combined with logical 'AND'. */
export type EnumsAssetVariationEnumComparisonExp = {
  _eq?: InputMaybe<AssetVariationEnum>;
  _in?: InputMaybe<Array<AssetVariationEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<AssetVariationEnum>;
  _nin?: InputMaybe<Array<AssetVariationEnum>>;
};

/** order by max() on columns of table "enums.asset_variation" */
export type EnumsAssetVariationMaxOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "enums.asset_variation" */
export type EnumsAssetVariationMinOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "enums.asset_variation". */
export type EnumsAssetVariationOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.asset_variation" */
export type EnumsAssetVariationSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_asset_variation" */
export type EnumsAssetVariationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsAssetVariationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsAssetVariationStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.base_activity_category" */
export type EnumsBaseActivityCategory = {
  __typename?: 'EnumsBaseActivityCategory';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.base_activity_category". All fields are combined with a logical 'AND'. */
export type EnumsBaseActivityCategoryBoolExp = {
  _and?: InputMaybe<Array<EnumsBaseActivityCategoryBoolExp>>;
  _not?: InputMaybe<EnumsBaseActivityCategoryBoolExp>;
  _or?: InputMaybe<Array<EnumsBaseActivityCategoryBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type BaseActivityCategoryEnum =
  | 'APPOINTMENT'
  | 'CALL'
  | 'CONSENT'
  | 'CONTACT'
  | 'DOCUMENT'
  | 'EMAIL'
  | 'EVA_EXPORT'
  | 'LEAD'
  | 'SHARED'
  | 'TASK';

/** Boolean expression to compare columns of type "EnumsBaseActivityCategoryEnum". All fields are combined with logical 'AND'. */
export type EnumsBaseActivityCategoryEnumComparisonExp = {
  _eq?: InputMaybe<BaseActivityCategoryEnum>;
  _in?: InputMaybe<Array<BaseActivityCategoryEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<BaseActivityCategoryEnum>;
  _nin?: InputMaybe<Array<BaseActivityCategoryEnum>>;
};

/** Ordering options when selecting data from "enums.base_activity_category". */
export type EnumsBaseActivityCategoryOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.base_activity_category" */
export type EnumsBaseActivityCategorySelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_base_activity_category" */
export type EnumsBaseActivityCategoryStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsBaseActivityCategoryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsBaseActivityCategoryStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.call_direction" */
export type EnumsCallDirection = {
  __typename?: 'EnumsCallDirection';
  /** An aggregate relationship */
  callDirectionCallsAggregate: DbCallAggregate;
  /** An array relationship */
  call_direction_calls: Array<DbCall>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** columns and relationships of "enums.call_direction" */
export type EnumsCallDirectionCallDirectionCallsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbCallSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCallOrderBy>>;
  where?: InputMaybe<DbCallBoolExp>;
};

/** columns and relationships of "enums.call_direction" */
export type EnumsCallDirectionCallDirectionCallsArgs = {
  distinctOn?: InputMaybe<Array<DbCallSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCallOrderBy>>;
  where?: InputMaybe<DbCallBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.call_direction". All fields are combined with a logical 'AND'. */
export type EnumsCallDirectionBoolExp = {
  _and?: InputMaybe<Array<EnumsCallDirectionBoolExp>>;
  _not?: InputMaybe<EnumsCallDirectionBoolExp>;
  _or?: InputMaybe<Array<EnumsCallDirectionBoolExp>>;
  call_direction_calls?: InputMaybe<DbCallBoolExp>;
  call_direction_callsAggregate?: InputMaybe<DbCallAggregateBoolExp>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type CallDirectionEnum =
  /** Incoming call */
  | 'INCOMING'
  /** Outgoing_dial or Outbound_api call */
  | 'OUTGOING';

/** Boolean expression to compare columns of type "EnumsCallDirectionEnum". All fields are combined with logical 'AND'. */
export type EnumsCallDirectionEnumComparisonExp = {
  _eq?: InputMaybe<CallDirectionEnum>;
  _in?: InputMaybe<Array<CallDirectionEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CallDirectionEnum>;
  _nin?: InputMaybe<Array<CallDirectionEnum>>;
};

/** Ordering options when selecting data from "enums.call_direction". */
export type EnumsCallDirectionOrderBy = {
  call_direction_callsAggregate?: InputMaybe<DbCallAggregateOrderBy>;
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.call_direction" */
export type EnumsCallDirectionSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_call_direction" */
export type EnumsCallDirectionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsCallDirectionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsCallDirectionStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.call_outcome" */
export type EnumsCallOutcome = {
  __typename?: 'EnumsCallOutcome';
  /** An aggregate relationship */
  callOutcomeCallsAggregate: DbCallAggregate;
  /** An array relationship */
  call_outcome_calls: Array<DbCall>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** columns and relationships of "enums.call_outcome" */
export type EnumsCallOutcomeCallOutcomeCallsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbCallSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCallOrderBy>>;
  where?: InputMaybe<DbCallBoolExp>;
};

/** columns and relationships of "enums.call_outcome" */
export type EnumsCallOutcomeCallOutcomeCallsArgs = {
  distinctOn?: InputMaybe<Array<DbCallSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCallOrderBy>>;
  where?: InputMaybe<DbCallBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.call_outcome". All fields are combined with a logical 'AND'. */
export type EnumsCallOutcomeBoolExp = {
  _and?: InputMaybe<Array<EnumsCallOutcomeBoolExp>>;
  _not?: InputMaybe<EnumsCallOutcomeBoolExp>;
  _or?: InputMaybe<Array<EnumsCallOutcomeBoolExp>>;
  call_outcome_calls?: InputMaybe<DbCallBoolExp>;
  call_outcome_callsAggregate?: InputMaybe<DbCallAggregateBoolExp>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type CallOutcomeEnum = 'BUSY' | 'FAILED' | 'LEFT_MESSAGE' | 'NO_ANSWER' | 'TALKED' | 'WRONG_NUMBER';

/** Boolean expression to compare columns of type "EnumsCallOutcomeEnum". All fields are combined with logical 'AND'. */
export type EnumsCallOutcomeEnumComparisonExp = {
  _eq?: InputMaybe<CallOutcomeEnum>;
  _in?: InputMaybe<Array<CallOutcomeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CallOutcomeEnum>;
  _nin?: InputMaybe<Array<CallOutcomeEnum>>;
};

/** Ordering options when selecting data from "enums.call_outcome". */
export type EnumsCallOutcomeOrderBy = {
  call_outcome_callsAggregate?: InputMaybe<DbCallAggregateOrderBy>;
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.call_outcome" */
export type EnumsCallOutcomeSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_call_outcome" */
export type EnumsCallOutcomeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsCallOutcomeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsCallOutcomeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.call_status" */
export type EnumsCallStatus = {
  __typename?: 'EnumsCallStatus';
  /** An aggregate relationship */
  callStatusCallsAggregate: DbCallAggregate;
  /** An array relationship */
  call_status_calls: Array<DbCall>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** columns and relationships of "enums.call_status" */
export type EnumsCallStatusCallStatusCallsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbCallSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCallOrderBy>>;
  where?: InputMaybe<DbCallBoolExp>;
};

/** columns and relationships of "enums.call_status" */
export type EnumsCallStatusCallStatusCallsArgs = {
  distinctOn?: InputMaybe<Array<DbCallSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCallOrderBy>>;
  where?: InputMaybe<DbCallBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.call_status". All fields are combined with a logical 'AND'. */
export type EnumsCallStatusBoolExp = {
  _and?: InputMaybe<Array<EnumsCallStatusBoolExp>>;
  _not?: InputMaybe<EnumsCallStatusBoolExp>;
  _or?: InputMaybe<Array<EnumsCallStatusBoolExp>>;
  call_status_calls?: InputMaybe<DbCallBoolExp>;
  call_status_callsAggregate?: InputMaybe<DbCallAggregateBoolExp>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type CallStatusEnum = 'CANCELLED' | 'COMPLETED' | 'FAILED' | 'NO_ANSWER' | 'ONGOING' | 'PENDING' | 'QUEUED';

/** Boolean expression to compare columns of type "EnumsCallStatusEnum". All fields are combined with logical 'AND'. */
export type EnumsCallStatusEnumComparisonExp = {
  _eq?: InputMaybe<CallStatusEnum>;
  _in?: InputMaybe<Array<CallStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CallStatusEnum>;
  _nin?: InputMaybe<Array<CallStatusEnum>>;
};

/** Ordering options when selecting data from "enums.call_status". */
export type EnumsCallStatusOrderBy = {
  call_status_callsAggregate?: InputMaybe<DbCallAggregateOrderBy>;
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.call_status" */
export type EnumsCallStatusSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_call_status" */
export type EnumsCallStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsCallStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsCallStatusStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.consent_event_status" */
export type EnumsConsentEventStatus = {
  __typename?: 'EnumsConsentEventStatus';
  /** An aggregate relationship */
  consentEventsAggregate: DbConsentEventAggregate;
  /** An array relationship */
  consent_events: Array<DbConsentEvent>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** columns and relationships of "enums.consent_event_status" */
export type EnumsConsentEventStatusConsentEventsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbConsentEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentEventOrderBy>>;
  where?: InputMaybe<DbConsentEventBoolExp>;
};

/** columns and relationships of "enums.consent_event_status" */
export type EnumsConsentEventStatusConsentEventsArgs = {
  distinctOn?: InputMaybe<Array<DbConsentEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentEventOrderBy>>;
  where?: InputMaybe<DbConsentEventBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.consent_event_status". All fields are combined with a logical 'AND'. */
export type EnumsConsentEventStatusBoolExp = {
  _and?: InputMaybe<Array<EnumsConsentEventStatusBoolExp>>;
  _not?: InputMaybe<EnumsConsentEventStatusBoolExp>;
  _or?: InputMaybe<Array<EnumsConsentEventStatusBoolExp>>;
  consent_events?: InputMaybe<DbConsentEventBoolExp>;
  consent_eventsAggregate?: InputMaybe<DbConsentEventAggregateBoolExp>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type ConsentEventStatusEnum = 'FAILED' | 'PROCESSED' | 'UNPROCESSED';

/** Boolean expression to compare columns of type "EnumsConsentEventStatusEnum". All fields are combined with logical 'AND'. */
export type EnumsConsentEventStatusEnumComparisonExp = {
  _eq?: InputMaybe<ConsentEventStatusEnum>;
  _in?: InputMaybe<Array<ConsentEventStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ConsentEventStatusEnum>;
  _nin?: InputMaybe<Array<ConsentEventStatusEnum>>;
};

/** Ordering options when selecting data from "enums.consent_event_status". */
export type EnumsConsentEventStatusOrderBy = {
  consent_eventsAggregate?: InputMaybe<DbConsentEventAggregateOrderBy>;
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.consent_event_status" */
export type EnumsConsentEventStatusSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_consent_event_status" */
export type EnumsConsentEventStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsConsentEventStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsConsentEventStatusStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.consent_event_type" */
export type EnumsConsentEventType = {
  __typename?: 'EnumsConsentEventType';
  /** An aggregate relationship */
  consentEventsAggregate: DbConsentEventAggregate;
  /** An array relationship */
  consent_events: Array<DbConsentEvent>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** columns and relationships of "enums.consent_event_type" */
export type EnumsConsentEventTypeConsentEventsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbConsentEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentEventOrderBy>>;
  where?: InputMaybe<DbConsentEventBoolExp>;
};

/** columns and relationships of "enums.consent_event_type" */
export type EnumsConsentEventTypeConsentEventsArgs = {
  distinctOn?: InputMaybe<Array<DbConsentEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentEventOrderBy>>;
  where?: InputMaybe<DbConsentEventBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.consent_event_type". All fields are combined with a logical 'AND'. */
export type EnumsConsentEventTypeBoolExp = {
  _and?: InputMaybe<Array<EnumsConsentEventTypeBoolExp>>;
  _not?: InputMaybe<EnumsConsentEventTypeBoolExp>;
  _or?: InputMaybe<Array<EnumsConsentEventTypeBoolExp>>;
  consent_events?: InputMaybe<DbConsentEventBoolExp>;
  consent_eventsAggregate?: InputMaybe<DbConsentEventAggregateBoolExp>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type ConsentEventTypeEnum = 'CONFIRM' | 'UNSUBSCRIBE';

/** Boolean expression to compare columns of type "EnumsConsentEventTypeEnum". All fields are combined with logical 'AND'. */
export type EnumsConsentEventTypeEnumComparisonExp = {
  _eq?: InputMaybe<ConsentEventTypeEnum>;
  _in?: InputMaybe<Array<ConsentEventTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ConsentEventTypeEnum>;
  _nin?: InputMaybe<Array<ConsentEventTypeEnum>>;
};

/** Ordering options when selecting data from "enums.consent_event_type". */
export type EnumsConsentEventTypeOrderBy = {
  consent_eventsAggregate?: InputMaybe<DbConsentEventAggregateOrderBy>;
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.consent_event_type" */
export type EnumsConsentEventTypeSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_consent_event_type" */
export type EnumsConsentEventTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsConsentEventTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsConsentEventTypeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.consent_source" */
export type EnumsConsentSource = {
  __typename?: 'EnumsConsentSource';
  /** An array relationship */
  consents: Array<DbConsent>;
  /** An aggregate relationship */
  consentsAggregate: DbConsentAggregate;
  name: Scalars['String'];
};

/** columns and relationships of "enums.consent_source" */
export type EnumsConsentSourceConsentsArgs = {
  distinctOn?: InputMaybe<Array<DbConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentOrderBy>>;
  where?: InputMaybe<DbConsentBoolExp>;
};

/** columns and relationships of "enums.consent_source" */
export type EnumsConsentSourceConsentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentOrderBy>>;
  where?: InputMaybe<DbConsentBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.consent_source". All fields are combined with a logical 'AND'. */
export type EnumsConsentSourceBoolExp = {
  _and?: InputMaybe<Array<EnumsConsentSourceBoolExp>>;
  _not?: InputMaybe<EnumsConsentSourceBoolExp>;
  _or?: InputMaybe<Array<EnumsConsentSourceBoolExp>>;
  consents?: InputMaybe<DbConsentBoolExp>;
  consentsAggregate?: InputMaybe<DbConsentAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type ConsentSourceEnum = 'CONSENTED_OFFLINE' | 'CONSENTED_ONLINE' | 'EXPLICITLY_CONSENTED_TO';

/** Boolean expression to compare columns of type "EnumsConsentSourceEnum". All fields are combined with logical 'AND'. */
export type EnumsConsentSourceEnumComparisonExp = {
  _eq?: InputMaybe<ConsentSourceEnum>;
  _in?: InputMaybe<Array<ConsentSourceEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ConsentSourceEnum>;
  _nin?: InputMaybe<Array<ConsentSourceEnum>>;
};

/** Ordering options when selecting data from "enums.consent_source". */
export type EnumsConsentSourceOrderBy = {
  consentsAggregate?: InputMaybe<DbConsentAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.consent_source" */
export type EnumsConsentSourceSelectColumn =
  /** column name */
  'name';

/** Streaming cursor of the table "enums_consent_source" */
export type EnumsConsentSourceStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsConsentSourceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsConsentSourceStreamCursorValueInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.consent_status" */
export type EnumsConsentStatus = {
  __typename?: 'EnumsConsentStatus';
  /** An array relationship */
  consents: Array<DbConsent>;
  /** An aggregate relationship */
  consentsAggregate: DbConsentAggregate;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** columns and relationships of "enums.consent_status" */
export type EnumsConsentStatusConsentsArgs = {
  distinctOn?: InputMaybe<Array<DbConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentOrderBy>>;
  where?: InputMaybe<DbConsentBoolExp>;
};

/** columns and relationships of "enums.consent_status" */
export type EnumsConsentStatusConsentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentOrderBy>>;
  where?: InputMaybe<DbConsentBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.consent_status". All fields are combined with a logical 'AND'. */
export type EnumsConsentStatusBoolExp = {
  _and?: InputMaybe<Array<EnumsConsentStatusBoolExp>>;
  _not?: InputMaybe<EnumsConsentStatusBoolExp>;
  _or?: InputMaybe<Array<EnumsConsentStatusBoolExp>>;
  consents?: InputMaybe<DbConsentBoolExp>;
  consentsAggregate?: InputMaybe<DbConsentAggregateBoolExp>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type ConsentStatusEnum = 'CONSENTED' | 'REJECTED' | 'REVOKED' | 'WAITING';

/** Boolean expression to compare columns of type "EnumsConsentStatusEnum". All fields are combined with logical 'AND'. */
export type EnumsConsentStatusEnumComparisonExp = {
  _eq?: InputMaybe<ConsentStatusEnum>;
  _in?: InputMaybe<Array<ConsentStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ConsentStatusEnum>;
  _nin?: InputMaybe<Array<ConsentStatusEnum>>;
};

/** Ordering options when selecting data from "enums.consent_status". */
export type EnumsConsentStatusOrderBy = {
  consentsAggregate?: InputMaybe<DbConsentAggregateOrderBy>;
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.consent_status" */
export type EnumsConsentStatusSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_consent_status" */
export type EnumsConsentStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsConsentStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsConsentStatusStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.consent_template_type" */
export type EnumsConsentTemplateType = {
  __typename?: 'EnumsConsentTemplateType';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.consent_template_type". All fields are combined with a logical 'AND'. */
export type EnumsConsentTemplateTypeBoolExp = {
  _and?: InputMaybe<Array<EnumsConsentTemplateTypeBoolExp>>;
  _not?: InputMaybe<EnumsConsentTemplateTypeBoolExp>;
  _or?: InputMaybe<Array<EnumsConsentTemplateTypeBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type ConsentTemplateTypeEnum = 'DEFAULT' | 'SHOP';

/** Boolean expression to compare columns of type "EnumsConsentTemplateTypeEnum". All fields are combined with logical 'AND'. */
export type EnumsConsentTemplateTypeEnumComparisonExp = {
  _eq?: InputMaybe<ConsentTemplateTypeEnum>;
  _in?: InputMaybe<Array<ConsentTemplateTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ConsentTemplateTypeEnum>;
  _nin?: InputMaybe<Array<ConsentTemplateTypeEnum>>;
};

/** Ordering options when selecting data from "enums.consent_template_type". */
export type EnumsConsentTemplateTypeOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.consent_template_type" */
export type EnumsConsentTemplateTypeSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_consent_template_type" */
export type EnumsConsentTemplateTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsConsentTemplateTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsConsentTemplateTypeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.consent_type" */
export type EnumsConsentType = {
  __typename?: 'EnumsConsentType';
  /** An array relationship */
  consents: Array<DbConsent>;
  /** An aggregate relationship */
  consentsAggregate: DbConsentAggregate;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** columns and relationships of "enums.consent_type" */
export type EnumsConsentTypeConsentsArgs = {
  distinctOn?: InputMaybe<Array<DbConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentOrderBy>>;
  where?: InputMaybe<DbConsentBoolExp>;
};

/** columns and relationships of "enums.consent_type" */
export type EnumsConsentTypeConsentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentOrderBy>>;
  where?: InputMaybe<DbConsentBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.consent_type". All fields are combined with a logical 'AND'. */
export type EnumsConsentTypeBoolExp = {
  _and?: InputMaybe<Array<EnumsConsentTypeBoolExp>>;
  _not?: InputMaybe<EnumsConsentTypeBoolExp>;
  _or?: InputMaybe<Array<EnumsConsentTypeBoolExp>>;
  consents?: InputMaybe<DbConsentBoolExp>;
  consentsAggregate?: InputMaybe<DbConsentAggregateBoolExp>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type ConsentTypeEnum =
  | 'CONTACTING'
  | 'FINANCE_DATA_PROCESSING'
  | 'MARKETING'
  | 'MATCHING_PROPERTY_RECOMMENDATION'
  | 'REFERRALS'
  | 'VISIT_CONFIRMATION';

/** Boolean expression to compare columns of type "EnumsConsentTypeEnum". All fields are combined with logical 'AND'. */
export type EnumsConsentTypeEnumComparisonExp = {
  _eq?: InputMaybe<ConsentTypeEnum>;
  _in?: InputMaybe<Array<ConsentTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ConsentTypeEnum>;
  _nin?: InputMaybe<Array<ConsentTypeEnum>>;
};

/** Ordering options when selecting data from "enums.consent_type". */
export type EnumsConsentTypeOrderBy = {
  consentsAggregate?: InputMaybe<DbConsentAggregateOrderBy>;
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.consent_type" */
export type EnumsConsentTypeSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_consent_type" */
export type EnumsConsentTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsConsentTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsConsentTypeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.contact_relation_type" */
export type EnumsContactRelationType = {
  __typename?: 'EnumsContactRelationType';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.contact_relation_type". All fields are combined with a logical 'AND'. */
export type EnumsContactRelationTypeBoolExp = {
  _and?: InputMaybe<Array<EnumsContactRelationTypeBoolExp>>;
  _not?: InputMaybe<EnumsContactRelationTypeBoolExp>;
  _or?: InputMaybe<Array<EnumsContactRelationTypeBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type ContactRelationTypeEnum =
  | 'HAS_DISTURBED_RELATION_TO'
  | 'IS_AUTHORIZED_BY'
  | 'IS_AUTHORIZING_OF'
  | 'IS_BUSINESS_CONTACT_FROM'
  | 'IS_CO_OWNED_BY'
  | 'IS_CO_OWNER_OF'
  | 'IS_EMPLOYED_BY'
  | 'IS_EMPLOYER_OF'
  | 'IS_RELATIVE_FROM'
  | 'IS_THE_SAME_CONTACT_AS'
  | 'KNOWS'
  | 'LIVES_TOGETHER_WITH'
  | 'PARTNER_OR_SPOUSE'
  | 'RECOMMENDED_BY'
  | 'RECOMMEND_TO';

/** Boolean expression to compare columns of type "EnumsContactRelationTypeEnum". All fields are combined with logical 'AND'. */
export type EnumsContactRelationTypeEnumComparisonExp = {
  _eq?: InputMaybe<ContactRelationTypeEnum>;
  _in?: InputMaybe<Array<ContactRelationTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ContactRelationTypeEnum>;
  _nin?: InputMaybe<Array<ContactRelationTypeEnum>>;
};

/** Ordering options when selecting data from "enums.contact_relation_type". */
export type EnumsContactRelationTypeOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.contact_relation_type" */
export type EnumsContactRelationTypeSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_contact_relation_type" */
export type EnumsContactRelationTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsContactRelationTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsContactRelationTypeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.contact_status" */
export type EnumsContactStatus = {
  __typename?: 'EnumsContactStatus';
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.contact_status". All fields are combined with a logical 'AND'. */
export type EnumsContactStatusBoolExp = {
  _and?: InputMaybe<Array<EnumsContactStatusBoolExp>>;
  _not?: InputMaybe<EnumsContactStatusBoolExp>;
  _or?: InputMaybe<Array<EnumsContactStatusBoolExp>>;
  name?: InputMaybe<StringComparisonExp>;
};

export type ContactStatusEnum = 'ACTIVE' | 'FLAGGED';

/** Boolean expression to compare columns of type "EnumsContactStatusEnum". All fields are combined with logical 'AND'. */
export type EnumsContactStatusEnumComparisonExp = {
  _eq?: InputMaybe<ContactStatusEnum>;
  _in?: InputMaybe<Array<ContactStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ContactStatusEnum>;
  _nin?: InputMaybe<Array<ContactStatusEnum>>;
};

/** Ordering options when selecting data from "enums.contact_status". */
export type EnumsContactStatusOrderBy = {
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.contact_status" */
export type EnumsContactStatusSelectColumn =
  /** column name */
  'name';

/** Streaming cursor of the table "enums_contact_status" */
export type EnumsContactStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsContactStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsContactStatusStreamCursorValueInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.contact_time" */
export type EnumsContactTime = {
  __typename?: 'EnumsContactTime';
  /** An aggregate relationship */
  contactPreferredTimesAggregate: DbContactPreferredTimeAggregate;
  /** An array relationship */
  contact_preferred_times: Array<DbContactPreferredTime>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** columns and relationships of "enums.contact_time" */
export type EnumsContactTimeContactPreferredTimesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbContactPreferredTimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactPreferredTimeOrderBy>>;
  where?: InputMaybe<DbContactPreferredTimeBoolExp>;
};

/** columns and relationships of "enums.contact_time" */
export type EnumsContactTimeContactPreferredTimesArgs = {
  distinctOn?: InputMaybe<Array<DbContactPreferredTimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactPreferredTimeOrderBy>>;
  where?: InputMaybe<DbContactPreferredTimeBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.contact_time". All fields are combined with a logical 'AND'. */
export type EnumsContactTimeBoolExp = {
  _and?: InputMaybe<Array<EnumsContactTimeBoolExp>>;
  _not?: InputMaybe<EnumsContactTimeBoolExp>;
  _or?: InputMaybe<Array<EnumsContactTimeBoolExp>>;
  contact_preferred_times?: InputMaybe<DbContactPreferredTimeBoolExp>;
  contact_preferred_timesAggregate?: InputMaybe<DbContactPreferredTimeAggregateBoolExp>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type ContactTimeEnum = 'AFTERNOON' | 'ANYTIME' | 'EVENING' | 'MORNING' | 'NOON' | 'UNDEFINED';

/** Boolean expression to compare columns of type "EnumsContactTimeEnum". All fields are combined with logical 'AND'. */
export type EnumsContactTimeEnumComparisonExp = {
  _eq?: InputMaybe<ContactTimeEnum>;
  _in?: InputMaybe<Array<ContactTimeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ContactTimeEnum>;
  _nin?: InputMaybe<Array<ContactTimeEnum>>;
};

/** Ordering options when selecting data from "enums.contact_time". */
export type EnumsContactTimeOrderBy = {
  contact_preferred_timesAggregate?: InputMaybe<DbContactPreferredTimeAggregateOrderBy>;
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.contact_time" */
export type EnumsContactTimeSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_contact_time" */
export type EnumsContactTimeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsContactTimeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsContactTimeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.contact_type" */
export type EnumsContactType = {
  __typename?: 'EnumsContactType';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.contact_type". All fields are combined with a logical 'AND'. */
export type EnumsContactTypeBoolExp = {
  _and?: InputMaybe<Array<EnumsContactTypeBoolExp>>;
  _not?: InputMaybe<EnumsContactTypeBoolExp>;
  _or?: InputMaybe<Array<EnumsContactTypeBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type ContactTypeEnum = 'CONTACT_PERSON' | 'SERVICE_CONTACT' | 'TENANT' | 'UNDEFINED';

/** Boolean expression to compare columns of type "EnumsContactTypeEnum". All fields are combined with logical 'AND'. */
export type EnumsContactTypeEnumComparisonExp = {
  _eq?: InputMaybe<ContactTypeEnum>;
  _in?: InputMaybe<Array<ContactTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ContactTypeEnum>;
  _nin?: InputMaybe<Array<ContactTypeEnum>>;
};

/** Ordering options when selecting data from "enums.contact_type". */
export type EnumsContactTypeOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.contact_type" */
export type EnumsContactTypeSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_contact_type" */
export type EnumsContactTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsContactTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsContactTypeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.contact_way" */
export type EnumsContactWay = {
  __typename?: 'EnumsContactWay';
  /** An aggregate relationship */
  contactPreferredWaysAggregate: DbContactPreferredWayAggregate;
  /** An array relationship */
  contact_preferred_ways: Array<DbContactPreferredWay>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** columns and relationships of "enums.contact_way" */
export type EnumsContactWayContactPreferredWaysAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbContactPreferredWaySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactPreferredWayOrderBy>>;
  where?: InputMaybe<DbContactPreferredWayBoolExp>;
};

/** columns and relationships of "enums.contact_way" */
export type EnumsContactWayContactPreferredWaysArgs = {
  distinctOn?: InputMaybe<Array<DbContactPreferredWaySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactPreferredWayOrderBy>>;
  where?: InputMaybe<DbContactPreferredWayBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.contact_way". All fields are combined with a logical 'AND'. */
export type EnumsContactWayBoolExp = {
  _and?: InputMaybe<Array<EnumsContactWayBoolExp>>;
  _not?: InputMaybe<EnumsContactWayBoolExp>;
  _or?: InputMaybe<Array<EnumsContactWayBoolExp>>;
  contact_preferred_ways?: InputMaybe<DbContactPreferredWayBoolExp>;
  contact_preferred_waysAggregate?: InputMaybe<DbContactPreferredWayAggregateBoolExp>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type ContactWayEnum =
  | 'EMAIL'
  | 'FAX'
  | 'MOBILEPHONE'
  | 'PHONE'
  | 'PHONEBUSINESS'
  | 'PHONEPRIVATE'
  | 'POSTAL'
  | 'UNDEFINED';

/** Boolean expression to compare columns of type "EnumsContactWayEnum". All fields are combined with logical 'AND'. */
export type EnumsContactWayEnumComparisonExp = {
  _eq?: InputMaybe<ContactWayEnum>;
  _in?: InputMaybe<Array<ContactWayEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ContactWayEnum>;
  _nin?: InputMaybe<Array<ContactWayEnum>>;
};

/** Ordering options when selecting data from "enums.contact_way". */
export type EnumsContactWayOrderBy = {
  contact_preferred_waysAggregate?: InputMaybe<DbContactPreferredWayAggregateOrderBy>;
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.contact_way" */
export type EnumsContactWaySelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_contact_way" */
export type EnumsContactWayStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsContactWayStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsContactWayStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.country" */
export type EnumsCountry = {
  __typename?: 'EnumsCountry';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An array relationship */
  shopSettings: Array<DbShopSetting>;
  /** An aggregate relationship */
  shopSettingsAggregate: DbShopSettingAggregate;
  /** An array relationship */
  shops: Array<DbShop>;
  /** An aggregate relationship */
  shopsAggregate: DbShopAggregate;
};

/** columns and relationships of "enums.country" */
export type EnumsCountryShopSettingsArgs = {
  distinctOn?: InputMaybe<Array<DbShopSettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopSettingOrderBy>>;
  where?: InputMaybe<DbShopSettingBoolExp>;
};

/** columns and relationships of "enums.country" */
export type EnumsCountryShopSettingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopSettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopSettingOrderBy>>;
  where?: InputMaybe<DbShopSettingBoolExp>;
};

/** columns and relationships of "enums.country" */
export type EnumsCountryShopsArgs = {
  distinctOn?: InputMaybe<Array<DbShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopOrderBy>>;
  where?: InputMaybe<DbShopBoolExp>;
};

/** columns and relationships of "enums.country" */
export type EnumsCountryShopsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopOrderBy>>;
  where?: InputMaybe<DbShopBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.country". All fields are combined with a logical 'AND'. */
export type EnumsCountryBoolExp = {
  _and?: InputMaybe<Array<EnumsCountryBoolExp>>;
  _not?: InputMaybe<EnumsCountryBoolExp>;
  _or?: InputMaybe<Array<EnumsCountryBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  shopSettings?: InputMaybe<DbShopSettingBoolExp>;
  shopSettingsAggregate?: InputMaybe<DbShopSettingAggregateBoolExp>;
  shops?: InputMaybe<DbShopBoolExp>;
  shopsAggregate?: InputMaybe<DbShopAggregateBoolExp>;
};

export type CountryEnum =
  /** AND: Andorra */
  | 'AD'
  /** ARE: United Arab Emirates */
  | 'AE'
  /** AFG: Afghanistan */
  | 'AF'
  /** ATG: Antigua and Barbuda */
  | 'AG'
  /** AIA: Anguilla */
  | 'AI'
  /** ALB: Albania */
  | 'AL'
  /** ARM: Armenia */
  | 'AM'
  /** ANT: Netherlands Antilles */
  | 'AN'
  /** AGO: Angola */
  | 'AO'
  /** ATA: Antarctica */
  | 'AQ'
  /** ARG: Argentina */
  | 'AR'
  /** ASM: American Samoa */
  | 'AS'
  /** AUT: Austria */
  | 'AT'
  /** AUS: Australia */
  | 'AU'
  /** ABW: Aruba */
  | 'AW'
  /** ALA: Åland Islands */
  | 'AX'
  /** AZE: Azerbaijan */
  | 'AZ'
  /** BIH: Bosnia and Herzegovina */
  | 'BA'
  /** BRB: Barbados */
  | 'BB'
  /** BGD: Bangladesh */
  | 'BD'
  /** BEL: Belgium */
  | 'BE'
  /** BFA: Burkina Faso */
  | 'BF'
  /** BGR: Bulgaria */
  | 'BG'
  /** BHR: Bahrain */
  | 'BH'
  /** BDI: Burundi */
  | 'BI'
  /** BEN: Benin */
  | 'BJ'
  /** BLM: Saint Barthélemy */
  | 'BL'
  /** BMU: Bermuda */
  | 'BM'
  /** BRN: Brunei Darussalam */
  | 'BN'
  /** BOL: Plurinational State of Bolivia */
  | 'BO'
  /** BES: Bonaire, Sint Eustatius and Saba */
  | 'BQ'
  /** BRA: Brazil */
  | 'BR'
  /** BHS: Bahamas */
  | 'BS'
  /** BTN: Bhutan */
  | 'BT'
  /** BVT: Bouvet Island */
  | 'BV'
  /** BWA: Botswana */
  | 'BW'
  /** BLR: Belarus */
  | 'BY'
  /** BLZ: Belize */
  | 'BZ'
  /** CAN: Canada */
  | 'CA'
  /** CCK: Cocos Islands */
  | 'CC'
  /** COD: The Democratic Republic of the Congo */
  | 'CD'
  /** CAF: Central African Republic */
  | 'CF'
  /** COG: Congo */
  | 'CG'
  /** CHE: Switzerland */
  | 'CH'
  /** CIV: Côte d'Ivoire */
  | 'CI'
  /** COK: Cook Islands */
  | 'CK'
  /** CHL: Chile */
  | 'CL'
  /** CMR: Cameroon */
  | 'CM'
  /** CHN: China */
  | 'CN'
  /** COL: Colombia */
  | 'CO'
  /** CRI: Costa Rica */
  | 'CR'
  /** CUB: Cuba */
  | 'CU'
  /** CPV: Cape Verde */
  | 'CV'
  /** CUW: Cura/u00E7ao */
  | 'CW'
  /** CXR: Christmas Island */
  | 'CX'
  /** CYP: Cyprus */
  | 'CY'
  /** CZE: Czech Republic */
  | 'CZ'
  /** DEU: Germany */
  | 'DE'
  /** DJI: Djibouti */
  | 'DJ'
  /** DNK: Denmark */
  | 'DK'
  /** DMA: Dominica */
  | 'DM'
  /** DOM: Dominican Republic */
  | 'DO'
  /** DZA: Algeria */
  | 'DZ'
  /** ECU: Ecuador */
  | 'EC'
  /** EST: Estonia */
  | 'EE'
  /** EGY: Egypt */
  | 'EG'
  /** ESH: Western Sahara */
  | 'EH'
  /** ERI: Eritrea */
  | 'ER'
  /** ESP: Spain */
  | 'ES'
  /** ETH: Ethiopia */
  | 'ET'
  /** FIN: Finland */
  | 'FI'
  /** FJI: Fiji */
  | 'FJ'
  /** FLK: Falkland Islands */
  | 'FK'
  /** FSM: Federated States of Micronesia */
  | 'FM'
  /** FRO: Faroe Islands */
  | 'FO'
  /** FRA: France */
  | 'FR'
  /** GAB: Gabon */
  | 'GA'
  /** GBR: United Kingdom */
  | 'GB'
  /** GRD: Grenada */
  | 'GD'
  /** GEO: Georgia */
  | 'GE'
  /** GUF: French Guiana */
  | 'GF'
  /** GGY: Guemsey */
  | 'GG'
  /** GHA: Ghana */
  | 'GH'
  /** GIB: Gibraltar */
  | 'GI'
  /** GRL: Greenland */
  | 'GL'
  /** GMB: Gambia */
  | 'GM'
  /** GIN: Guinea */
  | 'GN'
  /** GLP: Guadeloupe */
  | 'GP'
  /** GNQ: Equatorial Guinea */
  | 'GQ'
  /** GRC: Greece */
  | 'GR'
  /** SGS: South Georgia and the South Sandwich Islands */
  | 'GS'
  /** GTM: Guatemala */
  | 'GT'
  /** GUM: Guam */
  | 'GU'
  /** GNB: Guinea-Bissau */
  | 'GW'
  /** GUY: Guyana */
  | 'GY'
  /** HKG: Hong Kong */
  | 'HK'
  /** HMD: Heard Island and McDonald Islands */
  | 'HM'
  /** HND: Honduras */
  | 'HN'
  /** HRV: Croatia */
  | 'HR'
  /** HTI: Haiti */
  | 'HT'
  /** HUN: Hungary */
  | 'HU'
  /** IDN: Indonesia */
  | 'ID'
  /** IRL: Ireland */
  | 'IE'
  /** ISR: Israel */
  | 'IL'
  /** IMN: Isle of Man */
  | 'IM'
  /** IND: India */
  | 'IN'
  /** IOT: British Indian Ocean Territory */
  | 'IO'
  /** IRQ: Iraq */
  | 'IQ'
  /** IRN: Islamic Republic of Iran */
  | 'IR'
  /** ISL: Iceland */
  | 'IS'
  /** ITA: Italy */
  | 'IT'
  /** JEY: Jersey */
  | 'JE'
  /** JAM: Jamaica */
  | 'JM'
  /** JOR: Jordan */
  | 'JO'
  /** JPN: Japan */
  | 'JP'
  /** KEN: Kenya */
  | 'KE'
  /** KGZ: Kyrgyzstan */
  | 'KG'
  /** KHM: Cambodia */
  | 'KH'
  /** KIR: Kiribati */
  | 'KI'
  /** COM: Comoros */
  | 'KM'
  /** KNA: Saint Kitts and Nevis */
  | 'KN'
  /** PRK: Democratic People's Republic of Korea */
  | 'KP'
  /** KOR: Republic of Korea */
  | 'KR'
  /** KWT: Kuwait */
  | 'KW'
  /** CYM: Cayman Islands */
  | 'KY'
  /** KAZ: Kazakhstan */
  | 'KZ'
  /** LAO: Lao People's Democratic Republic */
  | 'LA'
  /** LBN: Lebanon */
  | 'LB'
  /** LCA: Saint Lucia */
  | 'LC'
  /** LIE: Liechtenstein */
  | 'LI'
  /** LKA: Sri Lanka */
  | 'LK'
  /** LBR: Liberia */
  | 'LR'
  /** LSO: Lesotho */
  | 'LS'
  /** LTU: Lithuania */
  | 'LT'
  /** LUX: Luxembourg */
  | 'LU'
  /** LVA: Latvia */
  | 'LV'
  /** LBY: Libya */
  | 'LY'
  /** MAR: Morocco */
  | 'MA'
  /** MCO: Monaco */
  | 'MC'
  /** MDA: Republic of Moldova */
  | 'MD'
  /** MNE: Montenegro */
  | 'ME'
  /** MAF: Saint Martin */
  | 'MF'
  /** MDG: Madagascar */
  | 'MG'
  /** MHL: Marshall Islands */
  | 'MH'
  /** MKD: The former Yugoslav Republic of Macedonia */
  | 'MK'
  /** MLI: Mali */
  | 'ML'
  /** MMR: Myanmar */
  | 'MM'
  /** MNG: Mongolia */
  | 'MN'
  /** MAC: Macao */
  | 'MO'
  /** MNP: Northern Mariana Islands */
  | 'MP'
  /** MTQ: Martinique */
  | 'MQ'
  /** MRT: Mauritania */
  | 'MR'
  /** MSR: Montserrat */
  | 'MS'
  /** MLT: Malta */
  | 'MT'
  /** MUS: Mauritius */
  | 'MU'
  /** MDV: Maldives */
  | 'MV'
  /** MWI: Malawi */
  | 'MW'
  /** MEX: Mexico */
  | 'MX'
  /** MYS: Malaysia */
  | 'MY'
  /** MOZ: Mozambique */
  | 'MZ'
  /** NAM: Namibia */
  | 'NA'
  /** NCL: New Caledonia */
  | 'NC'
  /** NER: Niger */
  | 'NE'
  /** NFK: Norfolk Island */
  | 'NF'
  /** NGA: Nigeria */
  | 'NG'
  /** NIC: Nicaragua */
  | 'NI'
  /** NLD: Netherlands */
  | 'NL'
  /** NOR: Norway */
  | 'NO'
  /** NPL: Nepal */
  | 'NP'
  /** NRU: Nauru */
  | 'NR'
  /** NIU: Niue */
  | 'NU'
  /** NZL: New Zealand */
  | 'NZ'
  /** OMN: Oman */
  | 'OM'
  /** PAN: Panama */
  | 'PA'
  /** PER: Peru */
  | 'PE'
  /** PYF: French Polynesia */
  | 'PF'
  /** PNG: Papua New Guinea */
  | 'PG'
  /** PHL: Philippines */
  | 'PH'
  /** PAK: Pakistan */
  | 'PK'
  /** POL: Poland */
  | 'PL'
  /** SPM: Saint Pierre and Miquelon */
  | 'PM'
  /** PCN: Pitcairn */
  | 'PN'
  /** PRI: Puerto Rico */
  | 'PR'
  /** PSE: Occupied Palestinian Territory */
  | 'PS'
  /** PRT: Portugal */
  | 'PT'
  /** PLW: Palau */
  | 'PW'
  /** PRY: Paraguay */
  | 'PY'
  /** QAT: Qatar */
  | 'QA'
  /** REU: Réunion */
  | 'RE'
  /** ROU: Romania */
  | 'RO'
  /** SRB: Serbia */
  | 'RS'
  /** RUS: Russian Federation */
  | 'RU'
  /** RWA: Rwanda */
  | 'RW'
  /** SAU: Saudi Arabia */
  | 'SA'
  /** SLB: Solomon Islands */
  | 'SB'
  /** SYC: Seychelles */
  | 'SC'
  /** SDN: Sudan */
  | 'SD'
  /** SWE: Sweden */
  | 'SE'
  /** SGP: Singapore */
  | 'SG'
  /** SHN: Saint Helena, Ascension and Tristan da Cunha */
  | 'SH'
  /** SVN: Slovenia */
  | 'SI'
  /** SJM: Svalbard and Jan Mayen */
  | 'SJ'
  /** SVK: Slovakia */
  | 'SK'
  /** SLE: Sierra Leone */
  | 'SL'
  /** SMR: San Marino */
  | 'SM'
  /** SEN: Senegal */
  | 'SN'
  /** SOM: Somalia */
  | 'SO'
  /** SUR: Suriname */
  | 'SR'
  /** SSD: South Sudan */
  | 'SS'
  /** STP: Sao Tome and Principe */
  | 'ST'
  /** SLV: El Salvador */
  | 'SV'
  /** SXM: Sint Maarten */
  | 'SX'
  /** SYR: Syrian Arab Republic */
  | 'SY'
  /** SWZ: Swaziland */
  | 'SZ'
  /** TCA: Turks and Caicos Islands */
  | 'TC'
  /** TCD: Chad */
  | 'TD'
  /** ATF: French Southern Territories */
  | 'TF'
  /** TGO: Togo */
  | 'TG'
  /** THA: Thailand */
  | 'TH'
  /** TJK: Tajikistan */
  | 'TJ'
  /** TKL: Tokelau */
  | 'TK'
  /** TLS: Timor-Leste */
  | 'TL'
  /** TKM: Turkmenistan */
  | 'TM'
  /** TUN: Tunisia */
  | 'TN'
  /** TON: Tonga */
  | 'TO'
  /** TUR: Turkey */
  | 'TR'
  /** TTO: Trinidad and Tobago */
  | 'TT'
  /** TUV: Tuvalu */
  | 'TV'
  /** TWN: Taiwan, Province of China */
  | 'TW'
  /** TZA: United Republic of Tanzania */
  | 'TZ'
  /** UKR: Ukraine */
  | 'UA'
  /** UGA: Uganda */
  | 'UG'
  /** UMI: United States Minor Outlying Islands */
  | 'UM'
  /** Undefined */
  | 'UNDEFINED'
  /** USA: United States */
  | 'US'
  /** URY: Uruguay */
  | 'UY'
  /** UZB: Uzbekistan */
  | 'UZ'
  /** VAT: Holy See */
  | 'VA'
  /** VCT: Saint Vincent and the Grenadines */
  | 'VC'
  /** VEN: Bolivarian Republic of Venezuela */
  | 'VE'
  /** VGB: British Virgin Islands */
  | 'VG'
  /** VIR: Virgin Islands, U.S. */
  | 'VI'
  /** VNM: Viet Nam */
  | 'VN'
  /** VUT: Vanuatu */
  | 'VU'
  /** WLF: Wallis and Futuna */
  | 'WF'
  /** WSM: Samoa */
  | 'WS'
  /** YEM: Yemen */
  | 'YE'
  /** MYT: Mayotte */
  | 'YT'
  /** ZAF: South Africa */
  | 'ZA'
  /** ZMB: Zambia */
  | 'ZM'
  /** ZWE: Zimbabwe */
  | 'ZW';

/** Boolean expression to compare columns of type "EnumsCountryEnum". All fields are combined with logical 'AND'. */
export type EnumsCountryEnumComparisonExp = {
  _eq?: InputMaybe<CountryEnum>;
  _in?: InputMaybe<Array<CountryEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CountryEnum>;
  _nin?: InputMaybe<Array<CountryEnum>>;
};

/** Ordering options when selecting data from "enums.country". */
export type EnumsCountryOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  shopSettingsAggregate?: InputMaybe<DbShopSettingAggregateOrderBy>;
  shopsAggregate?: InputMaybe<DbShopAggregateOrderBy>;
};

/** select columns of table "enums.country" */
export type EnumsCountrySelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_country" */
export type EnumsCountryStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsCountryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsCountryStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.currency" */
export type EnumsCurrency = {
  __typename?: 'EnumsCurrency';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.currency". All fields are combined with a logical 'AND'. */
export type EnumsCurrencyBoolExp = {
  _and?: InputMaybe<Array<EnumsCurrencyBoolExp>>;
  _not?: InputMaybe<EnumsCurrencyBoolExp>;
  _or?: InputMaybe<Array<EnumsCurrencyBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type CurrencyEnum =
  /** UAE Dirham */
  | 'AED'
  /** Afghani */
  | 'AFN'
  /** Lek */
  | 'ALL'
  /** Armenia Dram */
  | 'AMD'
  /** Netherlands Antilles Guilder */
  | 'ANG'
  /** Kwanza */
  | 'AOA'
  /** Argentina Peso */
  | 'ARS'
  /** Australia Dollar */
  | 'AUD'
  /** Aruba Guilder */
  | 'AWG'
  /** Azerbaijan Manat */
  | 'AZN'
  /** Convertible Mark */
  | 'BAM'
  /** Barbados Dollar */
  | 'BBD'
  /** Taka */
  | 'BDT'
  /** Bulgaria Lev */
  | 'BGN'
  /** Bahrain Dinar */
  | 'BHD'
  /** Burundi Franc */
  | 'BIF'
  /** Bermuda Dollar */
  | 'BMD'
  /** Brunei Dollar */
  | 'BND'
  /** Boliviano */
  | 'BOB'
  /** Mvdol */
  | 'BOV'
  /** Brazil Real */
  | 'BRL'
  /** Bahamas Dollar */
  | 'BSD'
  /** Ngultrum */
  | 'BTN'
  /** Pula */
  | 'BWP'
  /** Belarusian Ruble */
  | 'BYN'
  /** Belize Dollar */
  | 'BZD'
  /** Canada Dollar */
  | 'CAD'
  /** Congolese Franc */
  | 'CDF'
  /** WIR Euro */
  | 'CHE'
  /** Swiss Franc */
  | 'CHF'
  /** WIR Franc */
  | 'CHW'
  /** Unidad de Fomento */
  | 'CLF'
  /** Chile Peso */
  | 'CLP'
  /** Yuan Renminbi */
  | 'CNY'
  /** Colombian Peso */
  | 'COP'
  /** Costa Rica Colon */
  | 'CRC'
  /** Peso Convertible */
  | 'CUC'
  /** Cuban Peso */
  | 'CUP'
  /** Cape Verde Escudo */
  | 'CVE'
  /** Czech Koruna */
  | 'CZK'
  /** Djibouti Franc */
  | 'DJF'
  /** Denmark Krone */
  | 'DKK'
  /** Dominican Peso */
  | 'DOP'
  /** Algerian Dinar */
  | 'DZD'
  /** Egyptian Pound */
  | 'EGP'
  /** Nakfa */
  | 'ERN'
  /** Ethiopian Birr */
  | 'ETB'
  /** Euro */
  | 'EUR'
  /** Fiji Dollar */
  | 'FJD'
  /** Falkland Islands Pound */
  | 'FKP'
  /** Pound Sterling */
  | 'GBP'
  /** Lari */
  | 'GEL'
  /** Ghana Cedi */
  | 'GHS'
  /** Gibraltar Pound */
  | 'GIP'
  /** Dalasi */
  | 'GMD'
  /** Guinea Franc */
  | 'GNF'
  /** Quetzal */
  | 'GTQ'
  /** Guyana Dollar */
  | 'GYD'
  /** Hong Kong Dollar */
  | 'HKD'
  /** Lempira */
  | 'HNL'
  /** Kuna */
  | 'HRK'
  /** Haiti Gourde */
  | 'HTG'
  /** Forint */
  | 'HUF'
  /** Rupiah */
  | 'IDR'
  /** New Israeli Sheqel */
  | 'ILS'
  /** Isle of Man Pound */
  | 'IMP'
  /** India Rupee */
  | 'INR'
  /** Iraqi Dinar */
  | 'IQD'
  /** Iranian Rial */
  | 'IRR'
  /** Iceland Krona */
  | 'ISK'
  /** Jersey Pound */
  | 'JEP'
  /** Jamaican Dollar */
  | 'JMD'
  /** Jordanian Dinar */
  | 'JOD'
  /** Yen */
  | 'JPY'
  /** Kenyan Shilling */
  | 'KES'
  /** Som */
  | 'KGS'
  /** Riel */
  | 'KHR'
  /** Comorian Franc */
  | 'KMF'
  /** North Korean Won */
  | 'KPW'
  /** Won */
  | 'KRW'
  /** Kuwaiti Dinar */
  | 'KWD'
  /** Cayman Islands Dollar */
  | 'KYD'
  /** Tenge */
  | 'KZT'
  /** Lao Kip */
  | 'LAK'
  /** Lebanese Pound */
  | 'LBP'
  /** Sri Lanka Rupee */
  | 'LKR'
  /** Liberia Dollar */
  | 'LRD'
  /** Loti */
  | 'LSL'
  /** Libyan Dinar */
  | 'LYD'
  /** Moroccan Dirham */
  | 'MAD'
  /** Moldovan Leu */
  | 'MDL'
  /** Malagasy Ariary */
  | 'MGA'
  /** Denar */
  | 'MKD'
  /** Kyat */
  | 'MMK'
  /** Tughrik */
  | 'MNT'
  /** Pataca */
  | 'MOP'
  /** Ouguiya */
  | 'MRU'
  /** Mauritius Rupee */
  | 'MUR'
  /** Rufiyaa */
  | 'MVR'
  /** Malawi Kwacha */
  | 'MWK'
  /** Mexico Peso */
  | 'MXN'
  /** Mexican Unidad de Inversion (UDI) */
  | 'MXV'
  /** Malaysian Ringgit */
  | 'MYR'
  /** Mozambique Metical */
  | 'MZN'
  /** Namibia Dollar */
  | 'NAD'
  /** Naira */
  | 'NGN'
  /** Cordoba Oro */
  | 'NIO'
  /** Norway Krone */
  | 'NOK'
  /** Nepalese Rupee */
  | 'NPR'
  /** New Zealand Dollar */
  | 'NZD'
  /** Rial Omani */
  | 'OMR'
  /** Balboa */
  | 'PAB'
  /** Sol */
  | 'PEN'
  /** Kina */
  | 'PGK'
  /** Philippine Peso */
  | 'PHP'
  /** Pakistan Rupee */
  | 'PKR'
  /** Zloty */
  | 'PLN'
  /** Guarani */
  | 'PYG'
  /** Qatar Riyal */
  | 'QAR'
  /** Romanian Leu */
  | 'RON'
  /** Serbian Dinar */
  | 'RSD'
  /** Russian Ruble */
  | 'RUB'
  /** Rwanda Franc */
  | 'RWF'
  /** Saudi Riyal */
  | 'SAR'
  /** Solomon Islands Dollar */
  | 'SBD'
  /** Seychelles Rupee */
  | 'SCR'
  /** Sudan Pound */
  | 'SDG'
  /** Swedish Krona */
  | 'SEK'
  /** Singapore Dollar */
  | 'SGD'
  /** Saint Helena Pound */
  | 'SHP'
  /** Leone */
  | 'SLL'
  /** Somali Shilling */
  | 'SOS'
  /** Seborga Luigino */
  | 'SPL'
  /** Suriname Dollar */
  | 'SRD'
  /** South Sudanese Pound */
  | 'SSP'
  /** Dobra */
  | 'STN'
  /** El Salvador Colon */
  | 'SVC'
  /** Syria Pound */
  | 'SYP'
  /** Lilangeni */
  | 'SZL'
  /** Baht */
  | 'THB'
  /** Somoni */
  | 'TJS'
  /** Turkmenistan New Manat */
  | 'TMT'
  /** Tunisia Dinar */
  | 'TND'
  /** Pa'anga */
  | 'TOP'
  /** Turkey Lira */
  | 'TRY'
  /** Trinidad and Tobago Dollar */
  | 'TTD'
  /** Taiwan New Dollar */
  | 'TWD'
  /** Tanzania Shilling */
  | 'TZS'
  /** Ukraine Hryvnia */
  | 'UAH'
  /** Uganda Shilling */
  | 'UGX'
  /** US Dollar */
  | 'USD'
  /** US Dollar (Next day) */
  | 'USN'
  /** Uruguay Peso en Unidades */
  | 'UYI'
  /** Peso Uruguayo */
  | 'UYU'
  /** Uzbekistan Som */
  | 'UZS'
  /** Venezuela Bolivar */
  | 'VEF'
  /** Dong */
  | 'VND'
  /** Vatu */
  | 'VUV'
  /** Tala */
  | 'WST'
  /** CFA Franc BEAC */
  | 'XAF'
  /** Silver */
  | 'XAG'
  /** Gold */
  | 'XAU'
  /** Bond Markets Unit European */
  | 'XBA'
  /** Bond Markets Unit European Monetary Unit (E.M.U.-6) */
  | 'XBB'
  /** Bond Markets Unit European Unit of Account 9 (E.U.A.-9) */
  | 'XBC'
  /** Bond Markets Unit European Unit of Account 17 (E.U.A.-17) */
  | 'XBD'
  /** East Caribbean Dollar */
  | 'XCD'
  /** SDR (Special Drawing Right) */
  | 'XDR'
  /** CFA Franc BCEAO */
  | 'XOF'
  /** Palladium */
  | 'XPD'
  /** CFP Franc */
  | 'XPF'
  /** Platinum */
  | 'XPT'
  /** Sucre */
  | 'XSU'
  /** Codes specifically reserved for testing purposes */
  | 'XTS'
  /** The codes assigned for transactions where no currency is involved */
  | 'XXX'
  /** Yemen Rial */
  | 'YER'
  /** Rand */
  | 'ZAR'
  /** Zambia Kwacha */
  | 'ZMW'
  /** Zimbabwe Dollar */
  | 'ZWL';

/** Boolean expression to compare columns of type "EnumsCurrencyEnum". All fields are combined with logical 'AND'. */
export type EnumsCurrencyEnumComparisonExp = {
  _eq?: InputMaybe<CurrencyEnum>;
  _in?: InputMaybe<Array<CurrencyEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CurrencyEnum>;
  _nin?: InputMaybe<Array<CurrencyEnum>>;
};

/** Ordering options when selecting data from "enums.currency". */
export type EnumsCurrencyOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.currency" */
export type EnumsCurrencySelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_currency" */
export type EnumsCurrencyStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsCurrencyStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsCurrencyStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.custom_view_type" */
export type EnumsCustomViewType = {
  __typename?: 'EnumsCustomViewType';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.custom_view_type". All fields are combined with a logical 'AND'. */
export type EnumsCustomViewTypeBoolExp = {
  _and?: InputMaybe<Array<EnumsCustomViewTypeBoolExp>>;
  _not?: InputMaybe<EnumsCustomViewTypeBoolExp>;
  _or?: InputMaybe<Array<EnumsCustomViewTypeBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type CustomViewTypeEnum = 'CONTACT' | 'LEAD';

/** Boolean expression to compare columns of type "EnumsCustomViewTypeEnum". All fields are combined with logical 'AND'. */
export type EnumsCustomViewTypeEnumComparisonExp = {
  _eq?: InputMaybe<CustomViewTypeEnum>;
  _in?: InputMaybe<Array<CustomViewTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CustomViewTypeEnum>;
  _nin?: InputMaybe<Array<CustomViewTypeEnum>>;
};

/** Ordering options when selecting data from "enums.custom_view_type". */
export type EnumsCustomViewTypeOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.custom_view_type" */
export type EnumsCustomViewTypeSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_custom_view_type" */
export type EnumsCustomViewTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsCustomViewTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsCustomViewTypeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.email_address_type" */
export type EnumsEmailAddressType = {
  __typename?: 'EnumsEmailAddressType';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.email_address_type". All fields are combined with a logical 'AND'. */
export type EnumsEmailAddressTypeBoolExp = {
  _and?: InputMaybe<Array<EnumsEmailAddressTypeBoolExp>>;
  _not?: InputMaybe<EnumsEmailAddressTypeBoolExp>;
  _or?: InputMaybe<Array<EnumsEmailAddressTypeBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type EmailAddressTypeEnum = 'PRIVATE' | 'PUBLIC' | 'UNDEFINED' | 'WORK';

/** Boolean expression to compare columns of type "EnumsEmailAddressTypeEnum". All fields are combined with logical 'AND'. */
export type EnumsEmailAddressTypeEnumComparisonExp = {
  _eq?: InputMaybe<EmailAddressTypeEnum>;
  _in?: InputMaybe<Array<EmailAddressTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EmailAddressTypeEnum>;
  _nin?: InputMaybe<Array<EmailAddressTypeEnum>>;
};

/** Ordering options when selecting data from "enums.email_address_type". */
export type EnumsEmailAddressTypeOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.email_address_type" */
export type EnumsEmailAddressTypeSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_email_address_type" */
export type EnumsEmailAddressTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsEmailAddressTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsEmailAddressTypeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.email_callback_event_source" */
export type EnumsEmailCallbackEventSource = {
  __typename?: 'EnumsEmailCallbackEventSource';
  description?: Maybe<Scalars['String']>;
  /** An aggregate relationship */
  emailCallbackEventsAggregate: DbEmailCallbackEventsAggregate;
  /** An array relationship */
  email_callback_events: Array<DbEmailCallbackEvents>;
  name: Scalars['String'];
};

/** columns and relationships of "enums.email_callback_event_source" */
export type EnumsEmailCallbackEventSourceEmailCallbackEventsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailCallbackEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailCallbackEventsOrderBy>>;
  where?: InputMaybe<DbEmailCallbackEventsBoolExp>;
};

/** columns and relationships of "enums.email_callback_event_source" */
export type EnumsEmailCallbackEventSourceEmailCallbackEventsArgs = {
  distinctOn?: InputMaybe<Array<DbEmailCallbackEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailCallbackEventsOrderBy>>;
  where?: InputMaybe<DbEmailCallbackEventsBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.email_callback_event_source". All fields are combined with a logical 'AND'. */
export type EnumsEmailCallbackEventSourceBoolExp = {
  _and?: InputMaybe<Array<EnumsEmailCallbackEventSourceBoolExp>>;
  _not?: InputMaybe<EnumsEmailCallbackEventSourceBoolExp>;
  _or?: InputMaybe<Array<EnumsEmailCallbackEventSourceBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  email_callback_events?: InputMaybe<DbEmailCallbackEventsBoolExp>;
  email_callback_eventsAggregate?: InputMaybe<DbEmailCallbackEventsAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type EmailCallbackEventSourceEnum = 'MAILGUN' | 'NYLAS_V3';

/** Boolean expression to compare columns of type "EnumsEmailCallbackEventSourceEnum". All fields are combined with logical 'AND'. */
export type EnumsEmailCallbackEventSourceEnumComparisonExp = {
  _eq?: InputMaybe<EmailCallbackEventSourceEnum>;
  _in?: InputMaybe<Array<EmailCallbackEventSourceEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EmailCallbackEventSourceEnum>;
  _nin?: InputMaybe<Array<EmailCallbackEventSourceEnum>>;
};

/** Ordering options when selecting data from "enums.email_callback_event_source". */
export type EnumsEmailCallbackEventSourceOrderBy = {
  description?: InputMaybe<OrderBy>;
  email_callback_eventsAggregate?: InputMaybe<DbEmailCallbackEventsAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.email_callback_event_source" */
export type EnumsEmailCallbackEventSourceSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_email_callback_event_source" */
export type EnumsEmailCallbackEventSourceStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsEmailCallbackEventSourceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsEmailCallbackEventSourceStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.email_direction" */
export type EnumsEmailDirection = {
  __typename?: 'EnumsEmailDirection';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.email_direction". All fields are combined with a logical 'AND'. */
export type EnumsEmailDirectionBoolExp = {
  _and?: InputMaybe<Array<EnumsEmailDirectionBoolExp>>;
  _not?: InputMaybe<EnumsEmailDirectionBoolExp>;
  _or?: InputMaybe<Array<EnumsEmailDirectionBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type EmailDirectionEnum = 'INCOMING' | 'OUTGOING';

/** Boolean expression to compare columns of type "EnumsEmailDirectionEnum". All fields are combined with logical 'AND'. */
export type EnumsEmailDirectionEnumComparisonExp = {
  _eq?: InputMaybe<EmailDirectionEnum>;
  _in?: InputMaybe<Array<EmailDirectionEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EmailDirectionEnum>;
  _nin?: InputMaybe<Array<EmailDirectionEnum>>;
};

/** Ordering options when selecting data from "enums.email_direction". */
export type EnumsEmailDirectionOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.email_direction" */
export type EnumsEmailDirectionSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_email_direction" */
export type EnumsEmailDirectionStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsEmailDirectionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsEmailDirectionStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.email_integration_scope" */
export type EnumsEmailIntegrationScope = {
  __typename?: 'EnumsEmailIntegrationScope';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An aggregate relationship */
  nylasEmailIntegrationsAggregate: DbNylasEmailIntegrationAggregate;
  /** An array relationship */
  nylas_email_integrations: Array<DbNylasEmailIntegration>;
};

/** columns and relationships of "enums.email_integration_scope" */
export type EnumsEmailIntegrationScopeNylasEmailIntegrationsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbNylasEmailIntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbNylasEmailIntegrationOrderBy>>;
  where?: InputMaybe<DbNylasEmailIntegrationBoolExp>;
};

/** columns and relationships of "enums.email_integration_scope" */
export type EnumsEmailIntegrationScopeNylasEmailIntegrationsArgs = {
  distinctOn?: InputMaybe<Array<DbNylasEmailIntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbNylasEmailIntegrationOrderBy>>;
  where?: InputMaybe<DbNylasEmailIntegrationBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.email_integration_scope". All fields are combined with a logical 'AND'. */
export type EnumsEmailIntegrationScopeBoolExp = {
  _and?: InputMaybe<Array<EnumsEmailIntegrationScopeBoolExp>>;
  _not?: InputMaybe<EnumsEmailIntegrationScopeBoolExp>;
  _or?: InputMaybe<Array<EnumsEmailIntegrationScopeBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  nylas_email_integrations?: InputMaybe<DbNylasEmailIntegrationBoolExp>;
  nylas_email_integrationsAggregate?: InputMaybe<DbNylasEmailIntegrationAggregateBoolExp>;
};

export type EmailIntegrationScopeEnum = 'PERSONAL' | 'SHOP' | 'TEAM';

/** Boolean expression to compare columns of type "EnumsEmailIntegrationScopeEnum". All fields are combined with logical 'AND'. */
export type EnumsEmailIntegrationScopeEnumComparisonExp = {
  _eq?: InputMaybe<EmailIntegrationScopeEnum>;
  _in?: InputMaybe<Array<EmailIntegrationScopeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EmailIntegrationScopeEnum>;
  _nin?: InputMaybe<Array<EmailIntegrationScopeEnum>>;
};

/** Ordering options when selecting data from "enums.email_integration_scope". */
export type EnumsEmailIntegrationScopeOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nylas_email_integrationsAggregate?: InputMaybe<DbNylasEmailIntegrationAggregateOrderBy>;
};

/** select columns of table "enums.email_integration_scope" */
export type EnumsEmailIntegrationScopeSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_email_integration_scope" */
export type EnumsEmailIntegrationScopeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsEmailIntegrationScopeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsEmailIntegrationScopeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.email_placeholder_scope" */
export type EnumsEmailPlaceholderScope = {
  __typename?: 'EnumsEmailPlaceholderScope';
  description?: Maybe<Scalars['String']>;
  /** An aggregate relationship */
  emailPlaceholdersAggregate: DbEmailPlaceholderAggregate;
  /** An array relationship */
  email_placeholders: Array<DbEmailPlaceholder>;
  name: Scalars['String'];
};

/** columns and relationships of "enums.email_placeholder_scope" */
export type EnumsEmailPlaceholderScopeEmailPlaceholdersAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailPlaceholderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailPlaceholderOrderBy>>;
  where?: InputMaybe<DbEmailPlaceholderBoolExp>;
};

/** columns and relationships of "enums.email_placeholder_scope" */
export type EnumsEmailPlaceholderScopeEmailPlaceholdersArgs = {
  distinctOn?: InputMaybe<Array<DbEmailPlaceholderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailPlaceholderOrderBy>>;
  where?: InputMaybe<DbEmailPlaceholderBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.email_placeholder_scope". All fields are combined with a logical 'AND'. */
export type EnumsEmailPlaceholderScopeBoolExp = {
  _and?: InputMaybe<Array<EnumsEmailPlaceholderScopeBoolExp>>;
  _not?: InputMaybe<EnumsEmailPlaceholderScopeBoolExp>;
  _or?: InputMaybe<Array<EnumsEmailPlaceholderScopeBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  email_placeholders?: InputMaybe<DbEmailPlaceholderBoolExp>;
  email_placeholdersAggregate?: InputMaybe<DbEmailPlaceholderAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type EmailPlaceholderScopeEnum = 'GLOBAL' | 'SIGNATURE' | 'TEMPLATE';

/** Boolean expression to compare columns of type "EnumsEmailPlaceholderScopeEnum". All fields are combined with logical 'AND'. */
export type EnumsEmailPlaceholderScopeEnumComparisonExp = {
  _eq?: InputMaybe<EmailPlaceholderScopeEnum>;
  _in?: InputMaybe<Array<EmailPlaceholderScopeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EmailPlaceholderScopeEnum>;
  _nin?: InputMaybe<Array<EmailPlaceholderScopeEnum>>;
};

/** Ordering options when selecting data from "enums.email_placeholder_scope". */
export type EnumsEmailPlaceholderScopeOrderBy = {
  description?: InputMaybe<OrderBy>;
  email_placeholdersAggregate?: InputMaybe<DbEmailPlaceholderAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.email_placeholder_scope" */
export type EnumsEmailPlaceholderScopeSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_email_placeholder_scope" */
export type EnumsEmailPlaceholderScopeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsEmailPlaceholderScopeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsEmailPlaceholderScopeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.email_placeholder_type" */
export type EnumsEmailPlaceholderType = {
  __typename?: 'EnumsEmailPlaceholderType';
  description?: Maybe<Scalars['String']>;
  /** An aggregate relationship */
  emailPlaceholdersAggregate: DbEmailPlaceholderAggregate;
  /** An array relationship */
  email_placeholders: Array<DbEmailPlaceholder>;
  name: Scalars['String'];
};

/** columns and relationships of "enums.email_placeholder_type" */
export type EnumsEmailPlaceholderTypeEmailPlaceholdersAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailPlaceholderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailPlaceholderOrderBy>>;
  where?: InputMaybe<DbEmailPlaceholderBoolExp>;
};

/** columns and relationships of "enums.email_placeholder_type" */
export type EnumsEmailPlaceholderTypeEmailPlaceholdersArgs = {
  distinctOn?: InputMaybe<Array<DbEmailPlaceholderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailPlaceholderOrderBy>>;
  where?: InputMaybe<DbEmailPlaceholderBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.email_placeholder_type". All fields are combined with a logical 'AND'. */
export type EnumsEmailPlaceholderTypeBoolExp = {
  _and?: InputMaybe<Array<EnumsEmailPlaceholderTypeBoolExp>>;
  _not?: InputMaybe<EnumsEmailPlaceholderTypeBoolExp>;
  _or?: InputMaybe<Array<EnumsEmailPlaceholderTypeBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  email_placeholders?: InputMaybe<DbEmailPlaceholderBoolExp>;
  email_placeholdersAggregate?: InputMaybe<DbEmailPlaceholderAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type EmailPlaceholderTypeEnum =
  | 'ACTIVITY_VIEWING_DATE'
  | 'ACTIVITY_VIEWING_FEEDBACK'
  | 'ADDITIONAL_GO3_PROPERTY_IDS'
  | 'AGENT_EMAIL'
  | 'AGENT_FIRST_NAME'
  | 'AGENT_JOB_TITLE'
  | 'AGENT_LAST_NAME'
  | 'AGENT_MOBILE_PHONE_NUMBER'
  | 'AGENT_OFFICE_PHONE_NUMBER'
  | 'AGENT_SALUTATION'
  | 'CONTACT_ADDRESS_CITY'
  | 'CONTACT_ADDRESS_COUNTRY'
  | 'CONTACT_ADDRESS_POSTAL_CODE'
  | 'CONTACT_ADDRESS_STREET_NAME'
  | 'CONTACT_ADDRESS_STREET_NUMBER'
  | 'CONTACT_EMAIL_ADDRESSES'
  | 'CONTACT_FIRST_NAME'
  | 'CONTACT_FORMATTED_SALUTATION'
  | 'CONTACT_FULL_ADDRESS'
  | 'CONTACT_GENERAL_COMMENT'
  | 'CONTACT_ID'
  | 'CONTACT_LAST_NAME'
  | 'CONTACT_PHONE_NUMBERS'
  | 'CONTACT_PREFERRED_LANGUAGE'
  | 'CONTACT_PREF_EMAIL_ADDRESS'
  | 'CONTACT_PREF_PHONE_NUMBER'
  | 'CONTACT_SALUTATION'
  | 'CONTACT_TITLE'
  | 'CURRENT_DATE'
  | 'LEAD_ASSIGNED_AGENT_EMAIL'
  | 'LEAD_ASSIGNED_AGENT_FIRST_NAME'
  | 'LEAD_ASSIGNED_AGENT_LAST_NAME'
  | 'LEAD_ASSIGNED_AGENT_MOBILE_PHONE_NUMBER'
  | 'LEAD_ASSIGNED_AGENT_OFFICE_PHONE_NUMBER'
  | 'LEAD_CREATED_DT'
  | 'LEAD_CUSTOMER_MESSAGE'
  | 'LEAD_INTENT_TYPE'
  | 'LEAD_LINK'
  | 'LEAD_PERSONAL_NOTE'
  | 'LEAD_PROPERTY_CITY'
  | 'LEAD_PROPERTY_COUNTRY'
  | 'LEAD_PROPERTY_FULL_ADDRESS'
  | 'LEAD_PROPERTY_LIVING_AREA'
  | 'LEAD_PROPERTY_POSTAL_CODE'
  | 'LEAD_PROPERTY_PRICE'
  | 'LEAD_PROPERTY_STREET_NAME'
  | 'LEAD_PROPERTY_STREET_NUMBER'
  | 'LEAD_PROPERTY_TYPE'
  | 'LEAD_SOURCE'
  | 'OWN_SHOP_ADDRESS_CITY'
  | 'OWN_SHOP_ADDRESS_COUNTRY'
  | 'OWN_SHOP_ADDRESS_POSTAL_CODE'
  | 'OWN_SHOP_ADDRESS_STREET_NAME'
  | 'OWN_SHOP_ADDRESS_STREET_NUMBER'
  | 'OWN_SHOP_EMAIL_ADDRESS'
  | 'OWN_SHOP_FULL_ADDRESS'
  | 'OWN_SHOP_IMPRINT_URL'
  | 'OWN_SHOP_LEGAL_ENTITY'
  | 'OWN_SHOP_NAME'
  | 'OWN_SHOP_PHONE_NUMBER'
  | 'OWN_SHOP_PRIVACY_POLICY_URL'
  | 'OWN_SHOP_WEBSITE_URL'
  | 'PREMIUM_EXPOSE'
  | 'PROPERTY_EXPOSE_LIST'
  | 'PROPERTY_FIRST_CITY'
  | 'PROPERTY_FIRST_CONDITIONAL'
  | 'PROPERTY_FIRST_COUNTRY'
  | 'PROPERTY_FIRST_EXPOSEE_URL'
  | 'PROPERTY_FIRST_FULL_ADDRESS'
  | 'PROPERTY_FIRST_GOME_LINK'
  | 'PROPERTY_FIRST_ID'
  | 'PROPERTY_FIRST_IMAGE'
  | 'PROPERTY_FIRST_POSTAL_CODE'
  | 'PROPERTY_FIRST_PRICE'
  | 'PROPERTY_FIRST_SECOND_ADDRESS_LINE'
  | 'PROPERTY_FIRST_STREET_NAME'
  | 'PROPERTY_FIRST_STREET_NUMBER'
  | 'PROPERTY_FIRST_TITLE'
  | 'PROPERTY_SECOND_CITY'
  | 'PROPERTY_SECOND_CONDITIONAL'
  | 'PROPERTY_SECOND_COUNTRY'
  | 'PROPERTY_SECOND_EXPOSEE_URL'
  | 'PROPERTY_SECOND_FULL_ADDRESS'
  | 'PROPERTY_SECOND_GOME_LINK'
  | 'PROPERTY_SECOND_ID'
  | 'PROPERTY_SECOND_IMAGE'
  | 'PROPERTY_SECOND_POSTAL_CODE'
  | 'PROPERTY_SECOND_PRICE'
  | 'PROPERTY_SECOND_SECOND_ADDRESS_LINE'
  | 'PROPERTY_SECOND_STREET_NAME'
  | 'PROPERTY_SECOND_STREET_NUMBER'
  | 'PROPERTY_SECOND_TITLE'
  | 'PROPERTY_THIRD_CITY'
  | 'PROPERTY_THIRD_CONDITIONAL'
  | 'PROPERTY_THIRD_COUNTRY'
  | 'PROPERTY_THIRD_EXPOSEE_URL'
  | 'PROPERTY_THIRD_FULL_ADDRESS'
  | 'PROPERTY_THIRD_GOME_LINK'
  | 'PROPERTY_THIRD_ID'
  | 'PROPERTY_THIRD_IMAGE'
  | 'PROPERTY_THIRD_POSTAL_CODE'
  | 'PROPERTY_THIRD_PRICE'
  | 'PROPERTY_THIRD_SECOND_ADDRESS_LINE'
  | 'PROPERTY_THIRD_STREET_NAME'
  | 'PROPERTY_THIRD_STREET_NUMBER'
  | 'PROPERTY_THIRD_TITLE'
  | 'RECIPIENT_FIRST_NAME'
  | 'RECIPIENT_LAST_NAME'
  | 'REFERRAL_AGENT_NOTE'
  | 'REFERRAL_COMMISSION'
  | 'REFERRAL_FIRST_CONDITIONAL'
  | 'REFERRAL_FIRST_RECEIVING_SHOP_LEGAL_ENTITY'
  | 'REFERRAL_FIRST_RECEIVING_SHOP_NAME'
  | 'REFERRAL_LEAD_LINK'
  | 'REFERRAL_RECEIVING_AGENT_EMAIL'
  | 'REFERRAL_RECEIVING_AGENT_FIRST_NAME'
  | 'REFERRAL_RECEIVING_AGENT_LAST_NAME'
  | 'REFERRAL_RECEIVING_AGENT_MOBILE_PHONE_NUMBER'
  | 'REFERRAL_RECEIVING_SHOP_LEGAL_ENTITY'
  | 'REFERRAL_RECEIVING_SHOP_NAME'
  | 'REFERRAL_REJECTION_REASON'
  | 'REFERRAL_SECOND_CONDITIONAL'
  | 'REFERRAL_SECOND_RECEIVING_SHOP_LEGAL_ENTITY'
  | 'REFERRAL_SECOND_RECEIVING_SHOP_NAME'
  | 'REFERRAL_SENDING_SHOP_NAME'
  | 'REFERRAL_THIRD_CONDITIONAL'
  | 'REFERRAL_THIRD_RECEIVING_SHOP_LEGAL_ENTITY'
  | 'REFERRAL_THIRD_RECEIVING_SHOP_NAME';

/** Boolean expression to compare columns of type "EnumsEmailPlaceholderTypeEnum". All fields are combined with logical 'AND'. */
export type EnumsEmailPlaceholderTypeEnumComparisonExp = {
  _eq?: InputMaybe<EmailPlaceholderTypeEnum>;
  _in?: InputMaybe<Array<EmailPlaceholderTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EmailPlaceholderTypeEnum>;
  _nin?: InputMaybe<Array<EmailPlaceholderTypeEnum>>;
};

/** Ordering options when selecting data from "enums.email_placeholder_type". */
export type EnumsEmailPlaceholderTypeOrderBy = {
  description?: InputMaybe<OrderBy>;
  email_placeholdersAggregate?: InputMaybe<DbEmailPlaceholderAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.email_placeholder_type" */
export type EnumsEmailPlaceholderTypeSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_email_placeholder_type" */
export type EnumsEmailPlaceholderTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsEmailPlaceholderTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsEmailPlaceholderTypeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.email_signature_scope" */
export type EnumsEmailSignatureScope = {
  __typename?: 'EnumsEmailSignatureScope';
  description?: Maybe<Scalars['String']>;
  /** An aggregate relationship */
  emailSignaturesAggregate: DbEmailSignatureAggregate;
  /** An array relationship */
  email_signatures: Array<DbEmailSignature>;
  name: Scalars['String'];
};

/** columns and relationships of "enums.email_signature_scope" */
export type EnumsEmailSignatureScopeEmailSignaturesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailSignatureOrderBy>>;
  where?: InputMaybe<DbEmailSignatureBoolExp>;
};

/** columns and relationships of "enums.email_signature_scope" */
export type EnumsEmailSignatureScopeEmailSignaturesArgs = {
  distinctOn?: InputMaybe<Array<DbEmailSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailSignatureOrderBy>>;
  where?: InputMaybe<DbEmailSignatureBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.email_signature_scope". All fields are combined with a logical 'AND'. */
export type EnumsEmailSignatureScopeBoolExp = {
  _and?: InputMaybe<Array<EnumsEmailSignatureScopeBoolExp>>;
  _not?: InputMaybe<EnumsEmailSignatureScopeBoolExp>;
  _or?: InputMaybe<Array<EnumsEmailSignatureScopeBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  email_signatures?: InputMaybe<DbEmailSignatureBoolExp>;
  email_signaturesAggregate?: InputMaybe<DbEmailSignatureAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type EmailSignatureScopeEnum = 'GLOBAL' | 'PERSONAL' | 'SHOP' | 'TEAM';

/** Boolean expression to compare columns of type "EnumsEmailSignatureScopeEnum". All fields are combined with logical 'AND'. */
export type EnumsEmailSignatureScopeEnumComparisonExp = {
  _eq?: InputMaybe<EmailSignatureScopeEnum>;
  _in?: InputMaybe<Array<EmailSignatureScopeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EmailSignatureScopeEnum>;
  _nin?: InputMaybe<Array<EmailSignatureScopeEnum>>;
};

/** Ordering options when selecting data from "enums.email_signature_scope". */
export type EnumsEmailSignatureScopeOrderBy = {
  description?: InputMaybe<OrderBy>;
  email_signaturesAggregate?: InputMaybe<DbEmailSignatureAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.email_signature_scope" */
export type EnumsEmailSignatureScopeSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_email_signature_scope" */
export type EnumsEmailSignatureScopeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsEmailSignatureScopeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsEmailSignatureScopeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.email_status" */
export type EnumsEmailStatus = {
  __typename?: 'EnumsEmailStatus';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.email_status". All fields are combined with a logical 'AND'. */
export type EnumsEmailStatusBoolExp = {
  _and?: InputMaybe<Array<EnumsEmailStatusBoolExp>>;
  _not?: InputMaybe<EnumsEmailStatusBoolExp>;
  _or?: InputMaybe<Array<EnumsEmailStatusBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type EmailStatusEnum = 'FAILED' | 'PENDING' | 'PERMANENTLY_FAILED' | 'PROCESSED' | 'RECEIVED' | 'SENT';

/** Boolean expression to compare columns of type "EnumsEmailStatusEnum". All fields are combined with logical 'AND'. */
export type EnumsEmailStatusEnumComparisonExp = {
  _eq?: InputMaybe<EmailStatusEnum>;
  _in?: InputMaybe<Array<EmailStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EmailStatusEnum>;
  _nin?: InputMaybe<Array<EmailStatusEnum>>;
};

/** Ordering options when selecting data from "enums.email_status". */
export type EnumsEmailStatusOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.email_status" */
export type EnumsEmailStatusSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_email_status" */
export type EnumsEmailStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsEmailStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsEmailStatusStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.email_template_scope" */
export type EnumsEmailTemplateScope = {
  __typename?: 'EnumsEmailTemplateScope';
  description?: Maybe<Scalars['String']>;
  /** An aggregate relationship */
  emailTemplatesAggregate: DbEmailTemplateAggregate;
  /** An array relationship */
  email_templates: Array<DbEmailTemplate>;
  name: Scalars['String'];
};

/** columns and relationships of "enums.email_template_scope" */
export type EnumsEmailTemplateScopeEmailTemplatesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTemplateOrderBy>>;
  where?: InputMaybe<DbEmailTemplateBoolExp>;
};

/** columns and relationships of "enums.email_template_scope" */
export type EnumsEmailTemplateScopeEmailTemplatesArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTemplateOrderBy>>;
  where?: InputMaybe<DbEmailTemplateBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.email_template_scope". All fields are combined with a logical 'AND'. */
export type EnumsEmailTemplateScopeBoolExp = {
  _and?: InputMaybe<Array<EnumsEmailTemplateScopeBoolExp>>;
  _not?: InputMaybe<EnumsEmailTemplateScopeBoolExp>;
  _or?: InputMaybe<Array<EnumsEmailTemplateScopeBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  email_templates?: InputMaybe<DbEmailTemplateBoolExp>;
  email_templatesAggregate?: InputMaybe<DbEmailTemplateAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type EmailTemplateScopeEnum = 'GLOBAL' | 'PERSONAL' | 'SHOP' | 'TEAM';

/** Boolean expression to compare columns of type "EnumsEmailTemplateScopeEnum". All fields are combined with logical 'AND'. */
export type EnumsEmailTemplateScopeEnumComparisonExp = {
  _eq?: InputMaybe<EmailTemplateScopeEnum>;
  _in?: InputMaybe<Array<EmailTemplateScopeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EmailTemplateScopeEnum>;
  _nin?: InputMaybe<Array<EmailTemplateScopeEnum>>;
};

/** Ordering options when selecting data from "enums.email_template_scope". */
export type EnumsEmailTemplateScopeOrderBy = {
  description?: InputMaybe<OrderBy>;
  email_templatesAggregate?: InputMaybe<DbEmailTemplateAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.email_template_scope" */
export type EnumsEmailTemplateScopeSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_email_template_scope" */
export type EnumsEmailTemplateScopeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsEmailTemplateScopeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsEmailTemplateScopeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.email_template_type" */
export type EnumsEmailTemplateType = {
  __typename?: 'EnumsEmailTemplateType';
  description?: Maybe<Scalars['String']>;
  /** An aggregate relationship */
  emailTemplatesAggregate: DbEmailTemplateAggregate;
  /** An array relationship */
  email_templates: Array<DbEmailTemplate>;
  name: Scalars['String'];
};

/** columns and relationships of "enums.email_template_type" */
export type EnumsEmailTemplateTypeEmailTemplatesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTemplateOrderBy>>;
  where?: InputMaybe<DbEmailTemplateBoolExp>;
};

/** columns and relationships of "enums.email_template_type" */
export type EnumsEmailTemplateTypeEmailTemplatesArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTemplateOrderBy>>;
  where?: InputMaybe<DbEmailTemplateBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.email_template_type". All fields are combined with a logical 'AND'. */
export type EnumsEmailTemplateTypeBoolExp = {
  _and?: InputMaybe<Array<EnumsEmailTemplateTypeBoolExp>>;
  _not?: InputMaybe<EnumsEmailTemplateTypeBoolExp>;
  _or?: InputMaybe<Array<EnumsEmailTemplateTypeBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  email_templates?: InputMaybe<DbEmailTemplateBoolExp>;
  email_templatesAggregate?: InputMaybe<DbEmailTemplateAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type EmailTemplateTypeEnum =
  | 'AUTO'
  | 'EXPOSE'
  | 'MANUAL'
  | 'PREMIUM_EXPOSE_MAIL'
  | 'PRICE_REDUCTION'
  | 'VIEWING_REPORT';

/** Boolean expression to compare columns of type "EnumsEmailTemplateTypeEnum". All fields are combined with logical 'AND'. */
export type EnumsEmailTemplateTypeEnumComparisonExp = {
  _eq?: InputMaybe<EmailTemplateTypeEnum>;
  _in?: InputMaybe<Array<EmailTemplateTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EmailTemplateTypeEnum>;
  _nin?: InputMaybe<Array<EmailTemplateTypeEnum>>;
};

/** Ordering options when selecting data from "enums.email_template_type". */
export type EnumsEmailTemplateTypeOrderBy = {
  description?: InputMaybe<OrderBy>;
  email_templatesAggregate?: InputMaybe<DbEmailTemplateAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.email_template_type" */
export type EnumsEmailTemplateTypeSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_email_template_type" */
export type EnumsEmailTemplateTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsEmailTemplateTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsEmailTemplateTypeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.email_tracking_type" */
export type EnumsEmailTrackingType = {
  __typename?: 'EnumsEmailTrackingType';
  description?: Maybe<Scalars['String']>;
  /** An aggregate relationship */
  emailTrackingsAggregate: DbEmailTrackingAggregate;
  /** An array relationship */
  email_trackings: Array<DbEmailTracking>;
  name: Scalars['String'];
};

/** columns and relationships of "enums.email_tracking_type" */
export type EnumsEmailTrackingTypeEmailTrackingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTrackingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTrackingOrderBy>>;
  where?: InputMaybe<DbEmailTrackingBoolExp>;
};

/** columns and relationships of "enums.email_tracking_type" */
export type EnumsEmailTrackingTypeEmailTrackingsArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTrackingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTrackingOrderBy>>;
  where?: InputMaybe<DbEmailTrackingBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.email_tracking_type". All fields are combined with a logical 'AND'. */
export type EnumsEmailTrackingTypeBoolExp = {
  _and?: InputMaybe<Array<EnumsEmailTrackingTypeBoolExp>>;
  _not?: InputMaybe<EnumsEmailTrackingTypeBoolExp>;
  _or?: InputMaybe<Array<EnumsEmailTrackingTypeBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  email_trackings?: InputMaybe<DbEmailTrackingBoolExp>;
  email_trackingsAggregate?: InputMaybe<DbEmailTrackingAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type EmailTrackingTypeEnum =
  | 'ACCEPTED'
  | 'COMPLAINED'
  | 'DELIVERED'
  | 'EMAIL_OPENED'
  | 'HARD_BOUNCED'
  | 'LINK_CLICKED'
  | 'SOFT_BOUNCED'
  | 'UNSUBSCRIBED';

/** Boolean expression to compare columns of type "EnumsEmailTrackingTypeEnum". All fields are combined with logical 'AND'. */
export type EnumsEmailTrackingTypeEnumComparisonExp = {
  _eq?: InputMaybe<EmailTrackingTypeEnum>;
  _in?: InputMaybe<Array<EmailTrackingTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EmailTrackingTypeEnum>;
  _nin?: InputMaybe<Array<EmailTrackingTypeEnum>>;
};

/** Ordering options when selecting data from "enums.email_tracking_type". */
export type EnumsEmailTrackingTypeOrderBy = {
  description?: InputMaybe<OrderBy>;
  email_trackingsAggregate?: InputMaybe<DbEmailTrackingAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.email_tracking_type" */
export type EnumsEmailTrackingTypeSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_email_tracking_type" */
export type EnumsEmailTrackingTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsEmailTrackingTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsEmailTrackingTypeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.entity_type" */
export type EnumsEntityType = {
  __typename?: 'EnumsEntityType';
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.entity_type". All fields are combined with a logical 'AND'. */
export type EnumsEntityTypeBoolExp = {
  _and?: InputMaybe<Array<EnumsEntityTypeBoolExp>>;
  _not?: InputMaybe<EnumsEntityTypeBoolExp>;
  _or?: InputMaybe<Array<EnumsEntityTypeBoolExp>>;
  name?: InputMaybe<StringComparisonExp>;
};

export type EntityTypeEnum =
  | 'AGENT'
  | 'APPOINTMENT'
  | 'APPOINTMENT_ACTIVITY'
  | 'CHANGE_HISTORY'
  | 'CONSENT'
  | 'CONTACT'
  | 'CONTACT_RELATIONSHIP'
  | 'CONTACT_TAG'
  | 'DOCUMENT_REFERENCE'
  | 'EMAIL'
  | 'EMAIL_ACTIVITY'
  | 'LEAD'
  | 'PRINTED_DOCUMENT'
  | 'TAG'
  | 'TASK_ACTIVITY'
  | 'VIEWING_APPOINTMENT_ACTIVITY';

/** Boolean expression to compare columns of type "EnumsEntityTypeEnum". All fields are combined with logical 'AND'. */
export type EnumsEntityTypeEnumComparisonExp = {
  _eq?: InputMaybe<EntityTypeEnum>;
  _in?: InputMaybe<Array<EntityTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EntityTypeEnum>;
  _nin?: InputMaybe<Array<EntityTypeEnum>>;
};

/** Ordering options when selecting data from "enums.entity_type". */
export type EnumsEntityTypeOrderBy = {
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.entity_type" */
export type EnumsEntityTypeSelectColumn =
  /** column name */
  'name';

/** Streaming cursor of the table "enums_entity_type" */
export type EnumsEntityTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsEntityTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsEntityTypeStreamCursorValueInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.event_status" */
export type EnumsEventStatus = {
  __typename?: 'EnumsEventStatus';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.event_status". All fields are combined with a logical 'AND'. */
export type EnumsEventStatusBoolExp = {
  _and?: InputMaybe<Array<EnumsEventStatusBoolExp>>;
  _not?: InputMaybe<EnumsEventStatusBoolExp>;
  _or?: InputMaybe<Array<EnumsEventStatusBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type EventStatusEnum = 'FAILED' | 'PROCESSED' | 'SUSPENDED' | 'UNPROCESSED';

/** Boolean expression to compare columns of type "EnumsEventStatusEnum". All fields are combined with logical 'AND'. */
export type EnumsEventStatusEnumComparisonExp = {
  _eq?: InputMaybe<EventStatusEnum>;
  _in?: InputMaybe<Array<EventStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EventStatusEnum>;
  _nin?: InputMaybe<Array<EventStatusEnum>>;
};

/** Ordering options when selecting data from "enums.event_status". */
export type EnumsEventStatusOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.event_status" */
export type EnumsEventStatusSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_event_status" */
export type EnumsEventStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsEventStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsEventStatusStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.feature_flags" */
export type EnumsFeatureFlags = {
  __typename?: 'EnumsFeatureFlags';
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.feature_flags". All fields are combined with a logical 'AND'. */
export type EnumsFeatureFlagsBoolExp = {
  _and?: InputMaybe<Array<EnumsFeatureFlagsBoolExp>>;
  _not?: InputMaybe<EnumsFeatureFlagsBoolExp>;
  _or?: InputMaybe<Array<EnumsFeatureFlagsBoolExp>>;
  name?: InputMaybe<StringComparisonExp>;
};

export type FeatureFlagsEnum =
  | 'AI_TRANSLATION_PROFILES'
  | 'GOME_MODULE_FEDERATION'
  | 'KYC_INTEGRATION'
  | 'LEAD_BUNDLING'
  | 'MANUAL_CONSENT'
  | 'MERGE_CONTACTS'
  | 'PROFILE_INTEGRATION'
  | 'REPLY_TO_OR_FORWARD_MAIL'
  | 'SHOP_EXPORT_PAGE'
  | 'TWILIO_PREFLIGHT_CHECK';

/** Boolean expression to compare columns of type "EnumsFeatureFlagsEnum". All fields are combined with logical 'AND'. */
export type EnumsFeatureFlagsEnumComparisonExp = {
  _eq?: InputMaybe<FeatureFlagsEnum>;
  _in?: InputMaybe<Array<FeatureFlagsEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<FeatureFlagsEnum>;
  _nin?: InputMaybe<Array<FeatureFlagsEnum>>;
};

/** Ordering options when selecting data from "enums.feature_flags". */
export type EnumsFeatureFlagsOrderBy = {
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.feature_flags" */
export type EnumsFeatureFlagsSelectColumn =
  /** column name */
  'name';

/** Streaming cursor of the table "enums_feature_flags" */
export type EnumsFeatureFlagsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsFeatureFlagsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsFeatureFlagsStreamCursorValueInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.gender" */
export type EnumsGender = {
  __typename?: 'EnumsGender';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.gender". All fields are combined with a logical 'AND'. */
export type EnumsGenderBoolExp = {
  _and?: InputMaybe<Array<EnumsGenderBoolExp>>;
  _not?: InputMaybe<EnumsGenderBoolExp>;
  _or?: InputMaybe<Array<EnumsGenderBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "enums.gender". */
export type EnumsGenderOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.gender" */
export type EnumsGenderSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_gender" */
export type EnumsGenderStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsGenderStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsGenderStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.household_type" */
export type EnumsHouseholdType = {
  __typename?: 'EnumsHouseholdType';
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.household_type". All fields are combined with a logical 'AND'. */
export type EnumsHouseholdTypeBoolExp = {
  _and?: InputMaybe<Array<EnumsHouseholdTypeBoolExp>>;
  _not?: InputMaybe<EnumsHouseholdTypeBoolExp>;
  _or?: InputMaybe<Array<EnumsHouseholdTypeBoolExp>>;
  name?: InputMaybe<StringComparisonExp>;
};

export type HouseholdTypeEnum = 'FAMILY' | 'FLATSHARE' | 'ONE_PERSON' | 'TWO_ADULTS';

/** Boolean expression to compare columns of type "EnumsHouseholdTypeEnum". All fields are combined with logical 'AND'. */
export type EnumsHouseholdTypeEnumComparisonExp = {
  _eq?: InputMaybe<HouseholdTypeEnum>;
  _in?: InputMaybe<Array<HouseholdTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<HouseholdTypeEnum>;
  _nin?: InputMaybe<Array<HouseholdTypeEnum>>;
};

/** Ordering options when selecting data from "enums.household_type". */
export type EnumsHouseholdTypeOrderBy = {
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.household_type" */
export type EnumsHouseholdTypeSelectColumn =
  /** column name */
  'name';

/** Streaming cursor of the table "enums_household_type" */
export type EnumsHouseholdTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsHouseholdTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsHouseholdTypeStreamCursorValueInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.import_lead_status" */
export type EnumsImportLeadStatus = {
  __typename?: 'EnumsImportLeadStatus';
  description?: Maybe<Scalars['String']>;
  /** An aggregate relationship */
  importLeadRequestsAggregate: DbImportLeadRequestAggregate;
  /** An array relationship */
  import_lead_requests: Array<DbImportLeadRequest>;
  name: Scalars['String'];
};

/** columns and relationships of "enums.import_lead_status" */
export type EnumsImportLeadStatusImportLeadRequestsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbImportLeadRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbImportLeadRequestOrderBy>>;
  where?: InputMaybe<DbImportLeadRequestBoolExp>;
};

/** columns and relationships of "enums.import_lead_status" */
export type EnumsImportLeadStatusImportLeadRequestsArgs = {
  distinctOn?: InputMaybe<Array<DbImportLeadRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbImportLeadRequestOrderBy>>;
  where?: InputMaybe<DbImportLeadRequestBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.import_lead_status". All fields are combined with a logical 'AND'. */
export type EnumsImportLeadStatusBoolExp = {
  _and?: InputMaybe<Array<EnumsImportLeadStatusBoolExp>>;
  _not?: InputMaybe<EnumsImportLeadStatusBoolExp>;
  _or?: InputMaybe<Array<EnumsImportLeadStatusBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  import_lead_requests?: InputMaybe<DbImportLeadRequestBoolExp>;
  import_lead_requestsAggregate?: InputMaybe<DbImportLeadRequestAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type ImportLeadStatusEnum = 'DELETED' | 'FAILED' | 'IMPORTING' | 'REIMPORT' | 'SUCCESSFUL';

/** Boolean expression to compare columns of type "EnumsImportLeadStatusEnum". All fields are combined with logical 'AND'. */
export type EnumsImportLeadStatusEnumComparisonExp = {
  _eq?: InputMaybe<ImportLeadStatusEnum>;
  _in?: InputMaybe<Array<ImportLeadStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ImportLeadStatusEnum>;
  _nin?: InputMaybe<Array<ImportLeadStatusEnum>>;
};

/** Ordering options when selecting data from "enums.import_lead_status". */
export type EnumsImportLeadStatusOrderBy = {
  description?: InputMaybe<OrderBy>;
  import_lead_requestsAggregate?: InputMaybe<DbImportLeadRequestAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.import_lead_status" */
export type EnumsImportLeadStatusSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_import_lead_status" */
export type EnumsImportLeadStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsImportLeadStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsImportLeadStatusStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.intent_category" */
export type EnumsIntentCategory = {
  __typename?: 'EnumsIntentCategory';
  description?: Maybe<Scalars['String']>;
  /** An aggregate relationship */
  leadhubGo3ContactLinksAggregate: DbLeadhubGo3ContactLinkAggregate;
  /** An array relationship */
  leadhubGo3ContactLinksBySecondIntentCategory: Array<DbLeadhubGo3ContactLink>;
  /** An aggregate relationship */
  leadhubGo3ContactLinksBySecondIntentCategoryAggregate: DbLeadhubGo3ContactLinkAggregate;
  /** An array relationship */
  leadhubGo3ContactLinksByThirdIntentCategory: Array<DbLeadhubGo3ContactLink>;
  /** An aggregate relationship */
  leadhubGo3ContactLinksByThirdIntentCategoryAggregate: DbLeadhubGo3ContactLinkAggregate;
  /** An array relationship */
  leadhub_go3_contact_links: Array<DbLeadhubGo3ContactLink>;
  name: Scalars['String'];
};

/** columns and relationships of "enums.intent_category" */
export type EnumsIntentCategoryLeadhubGo3ContactLinksAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLeadhubGo3ContactLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadhubGo3ContactLinkOrderBy>>;
  where?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
};

/** columns and relationships of "enums.intent_category" */
export type EnumsIntentCategoryLeadhubGo3ContactLinksBySecondIntentCategoryArgs = {
  distinctOn?: InputMaybe<Array<DbLeadhubGo3ContactLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadhubGo3ContactLinkOrderBy>>;
  where?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
};

/** columns and relationships of "enums.intent_category" */
export type EnumsIntentCategoryLeadhubGo3ContactLinksBySecondIntentCategoryAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLeadhubGo3ContactLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadhubGo3ContactLinkOrderBy>>;
  where?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
};

/** columns and relationships of "enums.intent_category" */
export type EnumsIntentCategoryLeadhubGo3ContactLinksByThirdIntentCategoryArgs = {
  distinctOn?: InputMaybe<Array<DbLeadhubGo3ContactLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadhubGo3ContactLinkOrderBy>>;
  where?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
};

/** columns and relationships of "enums.intent_category" */
export type EnumsIntentCategoryLeadhubGo3ContactLinksByThirdIntentCategoryAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLeadhubGo3ContactLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadhubGo3ContactLinkOrderBy>>;
  where?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
};

/** columns and relationships of "enums.intent_category" */
export type EnumsIntentCategoryLeadhubGo3ContactLinksArgs = {
  distinctOn?: InputMaybe<Array<DbLeadhubGo3ContactLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadhubGo3ContactLinkOrderBy>>;
  where?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.intent_category". All fields are combined with a logical 'AND'. */
export type EnumsIntentCategoryBoolExp = {
  _and?: InputMaybe<Array<EnumsIntentCategoryBoolExp>>;
  _not?: InputMaybe<EnumsIntentCategoryBoolExp>;
  _or?: InputMaybe<Array<EnumsIntentCategoryBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  leadhubGo3ContactLinksBySecondIntentCategory?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
  leadhubGo3ContactLinksBySecondIntentCategoryAggregate?: InputMaybe<DbLeadhubGo3ContactLinkAggregateBoolExp>;
  leadhubGo3ContactLinksByThirdIntentCategory?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
  leadhubGo3ContactLinksByThirdIntentCategoryAggregate?: InputMaybe<DbLeadhubGo3ContactLinkAggregateBoolExp>;
  leadhub_go3_contact_links?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
  leadhub_go3_contact_linksAggregate?: InputMaybe<DbLeadhubGo3ContactLinkAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type IntentCategoryEnum = 'BUYER' | 'RENTAL' | 'SELLER';

/** Boolean expression to compare columns of type "EnumsIntentCategoryEnum". All fields are combined with logical 'AND'. */
export type EnumsIntentCategoryEnumComparisonExp = {
  _eq?: InputMaybe<IntentCategoryEnum>;
  _in?: InputMaybe<Array<IntentCategoryEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<IntentCategoryEnum>;
  _nin?: InputMaybe<Array<IntentCategoryEnum>>;
};

/** Ordering options when selecting data from "enums.intent_category". */
export type EnumsIntentCategoryOrderBy = {
  description?: InputMaybe<OrderBy>;
  leadhubGo3ContactLinksBySecondIntentCategoryAggregate?: InputMaybe<DbLeadhubGo3ContactLinkAggregateOrderBy>;
  leadhubGo3ContactLinksByThirdIntentCategoryAggregate?: InputMaybe<DbLeadhubGo3ContactLinkAggregateOrderBy>;
  leadhub_go3_contact_linksAggregate?: InputMaybe<DbLeadhubGo3ContactLinkAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.intent_category" */
export type EnumsIntentCategorySelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_intent_category" */
export type EnumsIntentCategoryStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsIntentCategoryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsIntentCategoryStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.intent_type" */
export type EnumsIntentType = {
  __typename?: 'EnumsIntentType';
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  leadAutoAssignmentIntents: Array<DbLeadAutoAssignmentIntent>;
  /** An array relationship */
  leads: Array<DbLead>;
  /** An aggregate relationship */
  leadsAggregate: DbLeadAggregate;
  name: Scalars['String'];
  /** An array relationship */
  qualiformLeadIntents: Array<DbQualiformLeadIntent>;
};

/** columns and relationships of "enums.intent_type" */
export type EnumsIntentTypeLeadAutoAssignmentIntentsArgs = {
  distinctOn?: InputMaybe<Array<DbLeadAutoAssignmentIntentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadAutoAssignmentIntentOrderBy>>;
  where?: InputMaybe<DbLeadAutoAssignmentIntentBoolExp>;
};

/** columns and relationships of "enums.intent_type" */
export type EnumsIntentTypeLeadsArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

/** columns and relationships of "enums.intent_type" */
export type EnumsIntentTypeLeadsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

/** columns and relationships of "enums.intent_type" */
export type EnumsIntentTypeQualiformLeadIntentsArgs = {
  distinctOn?: InputMaybe<Array<DbQualiformLeadIntentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbQualiformLeadIntentOrderBy>>;
  where?: InputMaybe<DbQualiformLeadIntentBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.intent_type". All fields are combined with a logical 'AND'. */
export type EnumsIntentTypeBoolExp = {
  _and?: InputMaybe<Array<EnumsIntentTypeBoolExp>>;
  _not?: InputMaybe<EnumsIntentTypeBoolExp>;
  _or?: InputMaybe<Array<EnumsIntentTypeBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  leadAutoAssignmentIntents?: InputMaybe<DbLeadAutoAssignmentIntentBoolExp>;
  leads?: InputMaybe<DbLeadBoolExp>;
  leadsAggregate?: InputMaybe<DbLeadAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  qualiformLeadIntents?: InputMaybe<DbQualiformLeadIntentBoolExp>;
};

export type IntentTypeEnum = 'BUY' | 'RENT_OUT' | 'SELL' | 'TO_RENT' | 'UNDEFINED' | 'VALUATION';

/** Boolean expression to compare columns of type "EnumsIntentTypeEnum". All fields are combined with logical 'AND'. */
export type EnumsIntentTypeEnumComparisonExp = {
  _eq?: InputMaybe<IntentTypeEnum>;
  _in?: InputMaybe<Array<IntentTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<IntentTypeEnum>;
  _nin?: InputMaybe<Array<IntentTypeEnum>>;
};

/** Ordering options when selecting data from "enums.intent_type". */
export type EnumsIntentTypeOrderBy = {
  description?: InputMaybe<OrderBy>;
  leadAutoAssignmentIntentsAggregate?: InputMaybe<DbLeadAutoAssignmentIntentAggregateOrderBy>;
  leadsAggregate?: InputMaybe<DbLeadAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  qualiformLeadIntentsAggregate?: InputMaybe<DbQualiformLeadIntentAggregateOrderBy>;
};

/** select columns of table "enums.intent_type" */
export type EnumsIntentTypeSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_intent_type" */
export type EnumsIntentTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsIntentTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsIntentTypeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.language" */
export type EnumsLanguage = {
  __typename?: 'EnumsLanguage';
  /** An array relationship */
  agentProfileDescriptions: Array<DbAgentProfileDescription>;
  /** An aggregate relationship */
  agentProfileDescriptionsAggregate: DbAgentProfileDescriptionAggregate;
  /** An array relationship */
  consents: Array<DbConsent>;
  /** An aggregate relationship */
  consentsAggregate: DbConsentAggregate;
  /** An array relationship */
  contacts: Array<DbContact>;
  /** An aggregate relationship */
  contactsAggregate: DbContactAggregate;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An array relationship */
  optimizelyClients: Array<DbOptimizelyClient>;
  /** An aggregate relationship */
  shopProfileDescriptionsAggregate: DbShopProfileDescriptionAggregate;
  /** An array relationship */
  shopProfileImprints: Array<DbShopProfileImprint>;
  /** An aggregate relationship */
  shopProfileImprintsAggregate: DbShopProfileImprintAggregate;
  /** An array relationship */
  shopProfilePrivacyPolicies: Array<DbShopProfilePrivacyPolicy>;
  /** An aggregate relationship */
  shopProfilePrivacyPoliciesAggregate: DbShopProfilePrivacyPolicyAggregate;
  /** An array relationship */
  shopProfileQuotes: Array<DbShopProfileQuote>;
  /** An aggregate relationship */
  shopProfileQuotesAggregate: DbShopProfileQuoteAggregate;
  /** An array relationship */
  shopSettings: Array<DbShopSetting>;
  /** An aggregate relationship */
  shopSettingsAggregate: DbShopSettingAggregate;
  /** An array relationship */
  shop_profile_descriptions: Array<DbShopProfileDescription>;
};

/** columns and relationships of "enums.language" */
export type EnumsLanguageAgentProfileDescriptionsArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileDescriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileDescriptionOrderBy>>;
  where?: InputMaybe<DbAgentProfileDescriptionBoolExp>;
};

/** columns and relationships of "enums.language" */
export type EnumsLanguageAgentProfileDescriptionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileDescriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileDescriptionOrderBy>>;
  where?: InputMaybe<DbAgentProfileDescriptionBoolExp>;
};

/** columns and relationships of "enums.language" */
export type EnumsLanguageConsentsArgs = {
  distinctOn?: InputMaybe<Array<DbConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentOrderBy>>;
  where?: InputMaybe<DbConsentBoolExp>;
};

/** columns and relationships of "enums.language" */
export type EnumsLanguageConsentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentOrderBy>>;
  where?: InputMaybe<DbConsentBoolExp>;
};

/** columns and relationships of "enums.language" */
export type EnumsLanguageContactsArgs = {
  distinctOn?: InputMaybe<Array<DbContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactOrderBy>>;
  where?: InputMaybe<DbContactBoolExp>;
};

/** columns and relationships of "enums.language" */
export type EnumsLanguageContactsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactOrderBy>>;
  where?: InputMaybe<DbContactBoolExp>;
};

/** columns and relationships of "enums.language" */
export type EnumsLanguageOptimizelyClientsArgs = {
  distinctOn?: InputMaybe<Array<DbOptimizelyClientSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbOptimizelyClientOrderBy>>;
  where?: InputMaybe<DbOptimizelyClientBoolExp>;
};

/** columns and relationships of "enums.language" */
export type EnumsLanguageShopProfileDescriptionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileDescriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileDescriptionOrderBy>>;
  where?: InputMaybe<DbShopProfileDescriptionBoolExp>;
};

/** columns and relationships of "enums.language" */
export type EnumsLanguageShopProfileImprintsArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileImprintSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileImprintOrderBy>>;
  where?: InputMaybe<DbShopProfileImprintBoolExp>;
};

/** columns and relationships of "enums.language" */
export type EnumsLanguageShopProfileImprintsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileImprintSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileImprintOrderBy>>;
  where?: InputMaybe<DbShopProfileImprintBoolExp>;
};

/** columns and relationships of "enums.language" */
export type EnumsLanguageShopProfilePrivacyPoliciesArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfilePrivacyPolicySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfilePrivacyPolicyOrderBy>>;
  where?: InputMaybe<DbShopProfilePrivacyPolicyBoolExp>;
};

/** columns and relationships of "enums.language" */
export type EnumsLanguageShopProfilePrivacyPoliciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfilePrivacyPolicySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfilePrivacyPolicyOrderBy>>;
  where?: InputMaybe<DbShopProfilePrivacyPolicyBoolExp>;
};

/** columns and relationships of "enums.language" */
export type EnumsLanguageShopProfileQuotesArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileQuoteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileQuoteOrderBy>>;
  where?: InputMaybe<DbShopProfileQuoteBoolExp>;
};

/** columns and relationships of "enums.language" */
export type EnumsLanguageShopProfileQuotesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileQuoteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileQuoteOrderBy>>;
  where?: InputMaybe<DbShopProfileQuoteBoolExp>;
};

/** columns and relationships of "enums.language" */
export type EnumsLanguageShopSettingsArgs = {
  distinctOn?: InputMaybe<Array<DbShopSettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopSettingOrderBy>>;
  where?: InputMaybe<DbShopSettingBoolExp>;
};

/** columns and relationships of "enums.language" */
export type EnumsLanguageShopSettingsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopSettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopSettingOrderBy>>;
  where?: InputMaybe<DbShopSettingBoolExp>;
};

/** columns and relationships of "enums.language" */
export type EnumsLanguageShopProfileDescriptionsArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileDescriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileDescriptionOrderBy>>;
  where?: InputMaybe<DbShopProfileDescriptionBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.language". All fields are combined with a logical 'AND'. */
export type EnumsLanguageBoolExp = {
  _and?: InputMaybe<Array<EnumsLanguageBoolExp>>;
  _not?: InputMaybe<EnumsLanguageBoolExp>;
  _or?: InputMaybe<Array<EnumsLanguageBoolExp>>;
  agentProfileDescriptions?: InputMaybe<DbAgentProfileDescriptionBoolExp>;
  agentProfileDescriptionsAggregate?: InputMaybe<DbAgentProfileDescriptionAggregateBoolExp>;
  consents?: InputMaybe<DbConsentBoolExp>;
  consentsAggregate?: InputMaybe<DbConsentAggregateBoolExp>;
  contacts?: InputMaybe<DbContactBoolExp>;
  contactsAggregate?: InputMaybe<DbContactAggregateBoolExp>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  optimizelyClients?: InputMaybe<DbOptimizelyClientBoolExp>;
  shopProfileImprints?: InputMaybe<DbShopProfileImprintBoolExp>;
  shopProfileImprintsAggregate?: InputMaybe<DbShopProfileImprintAggregateBoolExp>;
  shopProfilePrivacyPolicies?: InputMaybe<DbShopProfilePrivacyPolicyBoolExp>;
  shopProfilePrivacyPoliciesAggregate?: InputMaybe<DbShopProfilePrivacyPolicyAggregateBoolExp>;
  shopProfileQuotes?: InputMaybe<DbShopProfileQuoteBoolExp>;
  shopProfileQuotesAggregate?: InputMaybe<DbShopProfileQuoteAggregateBoolExp>;
  shopSettings?: InputMaybe<DbShopSettingBoolExp>;
  shopSettingsAggregate?: InputMaybe<DbShopSettingAggregateBoolExp>;
  shop_profile_descriptions?: InputMaybe<DbShopProfileDescriptionBoolExp>;
  shop_profile_descriptionsAggregate?: InputMaybe<DbShopProfileDescriptionAggregateBoolExp>;
};

export type LanguageEnum =
  /** Afrikaans */
  | 'AF'
  /** Arabic */
  | 'AR'
  /** Bulgarian */
  | 'BG'
  /** Catalan */
  | 'CA'
  /** Czech */
  | 'CS'
  /** Denmark */
  | 'DA'
  /** German (Germany) */
  | 'DE'
  /** German (Austrian) */
  | 'DE_AT'
  /** German (Switzerland) */
  | 'DE_CH'
  /** Greek */
  | 'EL'
  /** English */
  | 'EN'
  /** English (Australia) */
  | 'EN_AU'
  /** English (Hong Kong) */
  | 'EN_HK'
  /** Spanish (Spain) */
  | 'ES'
  /** Spanish (South America) */
  | 'ES_AR'
  /** Finnish */
  | 'FI'
  /** French (France) */
  | 'FR'
  /** French (Belgium) */
  | 'FR_BE'
  /** Hebrew */
  | 'HE'
  /** Croatian */
  | 'HR'
  /** Hungarian */
  | 'HU'
  /** Italian */
  | 'IT'
  /** Japanese */
  | 'JA'
  /** Korean */
  | 'KO'
  /** Montenegrin */
  | 'ME'
  /** Dutch */
  | 'NL'
  /** Norwegian */
  | 'NO'
  /** Polish */
  | 'PL'
  /** Portuguese */
  | 'PT'
  /** Romanian */
  | 'RO'
  /** Russian */
  | 'RU'
  /** Swedish */
  | 'SV'
  /** Turkish */
  | 'TR'
  /** Xhosa */
  | 'XH'
  /** Chinese */
  | 'ZH_CN'
  /** Chinese */
  | 'ZH_HANS'
  /** Chinese */
  | 'ZH_HANT'
  /** Chinese (Taiwan) */
  | 'ZH_TW'
  /** Zulu */
  | 'ZU';

/** Boolean expression to compare columns of type "EnumsLanguageEnum". All fields are combined with logical 'AND'. */
export type EnumsLanguageEnumComparisonExp = {
  _eq?: InputMaybe<LanguageEnum>;
  _in?: InputMaybe<Array<LanguageEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<LanguageEnum>;
  _nin?: InputMaybe<Array<LanguageEnum>>;
};

/** Ordering options when selecting data from "enums.language". */
export type EnumsLanguageOrderBy = {
  agentProfileDescriptionsAggregate?: InputMaybe<DbAgentProfileDescriptionAggregateOrderBy>;
  consentsAggregate?: InputMaybe<DbConsentAggregateOrderBy>;
  contactsAggregate?: InputMaybe<DbContactAggregateOrderBy>;
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  optimizelyClientsAggregate?: InputMaybe<DbOptimizelyClientAggregateOrderBy>;
  shopProfileImprintsAggregate?: InputMaybe<DbShopProfileImprintAggregateOrderBy>;
  shopProfilePrivacyPoliciesAggregate?: InputMaybe<DbShopProfilePrivacyPolicyAggregateOrderBy>;
  shopProfileQuotesAggregate?: InputMaybe<DbShopProfileQuoteAggregateOrderBy>;
  shopSettingsAggregate?: InputMaybe<DbShopSettingAggregateOrderBy>;
  shop_profile_descriptionsAggregate?: InputMaybe<DbShopProfileDescriptionAggregateOrderBy>;
};

/** select columns of table "enums.language" */
export type EnumsLanguageSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_language" */
export type EnumsLanguageStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsLanguageStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsLanguageStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.lead_auto_assignment_rules" */
export type EnumsLeadAutoAssignmentRules = {
  __typename?: 'EnumsLeadAutoAssignmentRules';
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  leadAutoAssignmentRules: Array<DbLeadAutoAssignmentRules>;
  name: Scalars['String'];
};

/** columns and relationships of "enums.lead_auto_assignment_rules" */
export type EnumsLeadAutoAssignmentRulesLeadAutoAssignmentRulesArgs = {
  distinctOn?: InputMaybe<Array<DbLeadAutoAssignmentRulesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadAutoAssignmentRulesOrderBy>>;
  where?: InputMaybe<DbLeadAutoAssignmentRulesBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.lead_auto_assignment_rules". All fields are combined with a logical 'AND'. */
export type EnumsLeadAutoAssignmentRulesBoolExp = {
  _and?: InputMaybe<Array<EnumsLeadAutoAssignmentRulesBoolExp>>;
  _not?: InputMaybe<EnumsLeadAutoAssignmentRulesBoolExp>;
  _or?: InputMaybe<Array<EnumsLeadAutoAssignmentRulesBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  leadAutoAssignmentRules?: InputMaybe<DbLeadAutoAssignmentRulesBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type LeadAutoAssignmentRulesEnum =
  /** Assign lead to the owner agent of the property. */
  'ASSIGN_LEAD_TO_LISTING_AGENT';

/** Boolean expression to compare columns of type "EnumsLeadAutoAssignmentRulesEnum". All fields are combined with logical 'AND'. */
export type EnumsLeadAutoAssignmentRulesEnumComparisonExp = {
  _eq?: InputMaybe<LeadAutoAssignmentRulesEnum>;
  _in?: InputMaybe<Array<LeadAutoAssignmentRulesEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<LeadAutoAssignmentRulesEnum>;
  _nin?: InputMaybe<Array<LeadAutoAssignmentRulesEnum>>;
};

/** Ordering options when selecting data from "enums.lead_auto_assignment_rules". */
export type EnumsLeadAutoAssignmentRulesOrderBy = {
  description?: InputMaybe<OrderBy>;
  leadAutoAssignmentRulesAggregate?: InputMaybe<DbLeadAutoAssignmentRulesAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.lead_auto_assignment_rules" */
export type EnumsLeadAutoAssignmentRulesSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_lead_auto_assignment_rules" */
export type EnumsLeadAutoAssignmentRulesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsLeadAutoAssignmentRulesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsLeadAutoAssignmentRulesStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.lead_source" */
export type EnumsLeadSource = {
  __typename?: 'EnumsLeadSource';
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  leads: Array<DbLead>;
  /** An aggregate relationship */
  leadsAggregate: DbLeadAggregate;
  name: Scalars['String'];
  /** An array relationship */
  qualiformLeadSources: Array<DbQualiformLeadSource>;
};

/** columns and relationships of "enums.lead_source" */
export type EnumsLeadSourceLeadsArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

/** columns and relationships of "enums.lead_source" */
export type EnumsLeadSourceLeadsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

/** columns and relationships of "enums.lead_source" */
export type EnumsLeadSourceQualiformLeadSourcesArgs = {
  distinctOn?: InputMaybe<Array<DbQualiformLeadSourceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbQualiformLeadSourceOrderBy>>;
  where?: InputMaybe<DbQualiformLeadSourceBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.lead_source". All fields are combined with a logical 'AND'. */
export type EnumsLeadSourceBoolExp = {
  _and?: InputMaybe<Array<EnumsLeadSourceBoolExp>>;
  _not?: InputMaybe<EnumsLeadSourceBoolExp>;
  _or?: InputMaybe<Array<EnumsLeadSourceBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  leads?: InputMaybe<DbLeadBoolExp>;
  leadsAggregate?: InputMaybe<DbLeadAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
  qualiformLeadSources?: InputMaybe<DbQualiformLeadSourceBoolExp>;
};

export type LeadSourceEnum =
  | 'ATHOME'
  | 'BELLESD_LUXR'
  | 'CASASAPO'
  | 'CHAT_WIDGET'
  | 'COLD_CALLING'
  | 'COLLABORATION'
  | 'CUSTOMER_INITIATIVE'
  | 'ENALQUILER'
  | 'ENTER'
  | 'EVENT'
  | 'EV_SEARCH'
  | 'EXHIBITION'
  | 'EXISTING_CONTACT'
  | 'FARMING'
  | 'FIGAROIMMO'
  | 'FLYER'
  | 'FOTOCASA'
  | 'GG'
  | 'GLOBALIZA'
  | 'GO3_MIGRATION'
  | 'GO3_MIGRATION_OWNER'
  | 'GO3_MIGRATION_SEARCH_CLIENT'
  | 'HABITACLIA'
  | 'HAUSFRAGE'
  | 'HINTFROM'
  | 'HOLIDAY_MARKET_REPORT'
  | 'HOMEGATE'
  | 'IDEALISTA'
  | 'IMMOBILIARE'
  | 'IMMOBILIER_CH'
  | 'IMMOSCOUT24'
  | 'IMMOSCOUT_2_0'
  | 'IMMOWELT'
  | 'INDOMIO'
  | 'INMOBILIARIAS_ES'
  | 'JAMESEDITION'
  | 'KYERO'
  | 'LISTGLOBALLY'
  | 'LUXURYESTATE'
  | 'MAILING'
  | 'MAISONS_APPARTEMENTS'
  | 'MEILLEURSAGENTS'
  | 'MERETDEMEURES'
  | 'MIETE_AKTUELL'
  | 'MSW'
  | 'NEIGHBOURHOOD_FLYER'
  | 'NEWHOME'
  | 'OTHERS'
  | 'PAID_SEARCH'
  | 'PISOS'
  | 'PRIVATE_CONTACT'
  | 'PROPERTY_PRICES'
  | 'PROPERTY_VALUATION'
  | 'PROPRIETES_FIGARO'
  | 'PUBLICATION'
  | 'RECOMMENDATION'
  | 'REFERRAL'
  | 'REFERRAL_COMMERCIAL'
  | 'RESEARCH_MARKET_REPORT'
  | 'RESIDENCES_IMMOBILIER'
  | 'RIGHTMOVE'
  | 'SELL_PROPERTY'
  | 'SELOGER_LOGICIMMO'
  | 'SIGNBOARD'
  | 'SPITOGATOS'
  | 'TIPSTER'
  | 'UNDEFINED'
  | 'VENTURE'
  | 'WALK_IN'
  | 'WATTFOX'
  | 'WILLHABEN'
  | 'XE'
  | 'YAECONTRE';

/** Boolean expression to compare columns of type "EnumsLeadSourceEnum". All fields are combined with logical 'AND'. */
export type EnumsLeadSourceEnumComparisonExp = {
  _eq?: InputMaybe<LeadSourceEnum>;
  _in?: InputMaybe<Array<LeadSourceEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<LeadSourceEnum>;
  _nin?: InputMaybe<Array<LeadSourceEnum>>;
};

/** Ordering options when selecting data from "enums.lead_source". */
export type EnumsLeadSourceOrderBy = {
  description?: InputMaybe<OrderBy>;
  leadsAggregate?: InputMaybe<DbLeadAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
  qualiformLeadSourcesAggregate?: InputMaybe<DbQualiformLeadSourceAggregateOrderBy>;
};

/** select columns of table "enums.lead_source" */
export type EnumsLeadSourceSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_lead_source" */
export type EnumsLeadSourceStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsLeadSourceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsLeadSourceStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.lead_status" */
export type EnumsLeadStatus = {
  __typename?: 'EnumsLeadStatus';
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  leads: Array<DbLead>;
  /** An aggregate relationship */
  leadsAggregate: DbLeadAggregate;
  name: Scalars['String'];
};

/** columns and relationships of "enums.lead_status" */
export type EnumsLeadStatusLeadsArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

/** columns and relationships of "enums.lead_status" */
export type EnumsLeadStatusLeadsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.lead_status". All fields are combined with a logical 'AND'. */
export type EnumsLeadStatusBoolExp = {
  _and?: InputMaybe<Array<EnumsLeadStatusBoolExp>>;
  _not?: InputMaybe<EnumsLeadStatusBoolExp>;
  _or?: InputMaybe<Array<EnumsLeadStatusBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  leads?: InputMaybe<DbLeadBoolExp>;
  leadsAggregate?: InputMaybe<DbLeadAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type LeadStatusEnum = 'ACTIVE' | 'CLOSED' | 'EXPIRED' | 'INACTIVE' | 'INVALID';

/** Boolean expression to compare columns of type "EnumsLeadStatusEnum". All fields are combined with logical 'AND'. */
export type EnumsLeadStatusEnumComparisonExp = {
  _eq?: InputMaybe<LeadStatusEnum>;
  _in?: InputMaybe<Array<LeadStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<LeadStatusEnum>;
  _nin?: InputMaybe<Array<LeadStatusEnum>>;
};

/** Ordering options when selecting data from "enums.lead_status". */
export type EnumsLeadStatusOrderBy = {
  description?: InputMaybe<OrderBy>;
  leadsAggregate?: InputMaybe<DbLeadAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** columns and relationships of "enums.lead_status_reason" */
export type EnumsLeadStatusReason = {
  __typename?: 'EnumsLeadStatusReason';
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  leads: Array<DbLead>;
  /** An aggregate relationship */
  leadsAggregate: DbLeadAggregate;
  name: Scalars['String'];
};

/** columns and relationships of "enums.lead_status_reason" */
export type EnumsLeadStatusReasonLeadsArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

/** columns and relationships of "enums.lead_status_reason" */
export type EnumsLeadStatusReasonLeadsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.lead_status_reason". All fields are combined with a logical 'AND'. */
export type EnumsLeadStatusReasonBoolExp = {
  _and?: InputMaybe<Array<EnumsLeadStatusReasonBoolExp>>;
  _not?: InputMaybe<EnumsLeadStatusReasonBoolExp>;
  _or?: InputMaybe<Array<EnumsLeadStatusReasonBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  leads?: InputMaybe<DbLeadBoolExp>;
  leadsAggregate?: InputMaybe<DbLeadAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type LeadStatusReasonEnum =
  /** Collaborator */
  | 'COLLABORATOR'
  /** Commercial */
  | 'COMMERCIAL'
  | 'CUSTOMER_BLOCKED'
  /** Customer revoked request */
  | 'CUSTOMER_REVOKED_REQUEST'
  | 'CUSTOMER_UNBLOCKED'
  /** Duplicate lead */
  | 'DUPLICATE_LEAD'
  /** Fake lead */
  | 'FAKE_LEAD'
  /** No contact information */
  | 'NO_CONTACT_INFORMATION'
  /** No customer response */
  | 'NO_CUSTOMER_RESPONSE'
  /** No shop in area */
  | 'NO_SHOP_IN_AREA'
  /** Various unknown reasons */
  | 'OTHERS';

/** Boolean expression to compare columns of type "EnumsLeadStatusReasonEnum". All fields are combined with logical 'AND'. */
export type EnumsLeadStatusReasonEnumComparisonExp = {
  _eq?: InputMaybe<LeadStatusReasonEnum>;
  _in?: InputMaybe<Array<LeadStatusReasonEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<LeadStatusReasonEnum>;
  _nin?: InputMaybe<Array<LeadStatusReasonEnum>>;
};

/** Ordering options when selecting data from "enums.lead_status_reason". */
export type EnumsLeadStatusReasonOrderBy = {
  description?: InputMaybe<OrderBy>;
  leadsAggregate?: InputMaybe<DbLeadAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.lead_status_reason" */
export type EnumsLeadStatusReasonSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_lead_status_reason" */
export type EnumsLeadStatusReasonStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsLeadStatusReasonStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsLeadStatusReasonStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** select columns of table "enums.lead_status" */
export type EnumsLeadStatusSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_lead_status" */
export type EnumsLeadStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsLeadStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsLeadStatusStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.legacy_go3_newsletter_type" */
export type EnumsLegacyGo3NewsletterType = {
  __typename?: 'EnumsLegacyGo3NewsletterType';
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.legacy_go3_newsletter_type". All fields are combined with a logical 'AND'. */
export type EnumsLegacyGo3NewsletterTypeBoolExp = {
  _and?: InputMaybe<Array<EnumsLegacyGo3NewsletterTypeBoolExp>>;
  _not?: InputMaybe<EnumsLegacyGo3NewsletterTypeBoolExp>;
  _or?: InputMaybe<Array<EnumsLegacyGo3NewsletterTypeBoolExp>>;
  name?: InputMaybe<StringComparisonExp>;
};

export type LegacyGo3NewsletterTypeEnum = 'NO' | 'REQUESTED' | 'YES';

/** Boolean expression to compare columns of type "EnumsLegacyGo3NewsletterTypeEnum". All fields are combined with logical 'AND'. */
export type EnumsLegacyGo3NewsletterTypeEnumComparisonExp = {
  _eq?: InputMaybe<LegacyGo3NewsletterTypeEnum>;
  _in?: InputMaybe<Array<LegacyGo3NewsletterTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<LegacyGo3NewsletterTypeEnum>;
  _nin?: InputMaybe<Array<LegacyGo3NewsletterTypeEnum>>;
};

/** Ordering options when selecting data from "enums.legacy_go3_newsletter_type". */
export type EnumsLegacyGo3NewsletterTypeOrderBy = {
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.legacy_go3_newsletter_type" */
export type EnumsLegacyGo3NewsletterTypeSelectColumn =
  /** column name */
  'name';

/** Streaming cursor of the table "enums_legacy_go3_newsletter_type" */
export type EnumsLegacyGo3NewsletterTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsLegacyGo3NewsletterTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsLegacyGo3NewsletterTypeStreamCursorValueInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.license_partner_status" */
export type EnumsLicensePartnerStatus = {
  __typename?: 'EnumsLicensePartnerStatus';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** aggregated selection of "enums.license_partner_status" */
export type EnumsLicensePartnerStatusAggregate = {
  __typename?: 'EnumsLicensePartnerStatusAggregate';
  aggregate?: Maybe<EnumsLicensePartnerStatusAggregateFields>;
  nodes: Array<EnumsLicensePartnerStatus>;
};

/** aggregate fields of "enums.license_partner_status" */
export type EnumsLicensePartnerStatusAggregateFields = {
  __typename?: 'EnumsLicensePartnerStatusAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<EnumsLicensePartnerStatusMaxFields>;
  min?: Maybe<EnumsLicensePartnerStatusMinFields>;
};

/** aggregate fields of "enums.license_partner_status" */
export type EnumsLicensePartnerStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EnumsLicensePartnerStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enums.license_partner_status". All fields are combined with a logical 'AND'. */
export type EnumsLicensePartnerStatusBoolExp = {
  _and?: InputMaybe<Array<EnumsLicensePartnerStatusBoolExp>>;
  _not?: InputMaybe<EnumsLicensePartnerStatusBoolExp>;
  _or?: InputMaybe<Array<EnumsLicensePartnerStatusBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type LicensePartnerStatusEnum =
  /** Active */
  | 'ACTIVE'
  /** Inactive */
  | 'INACTIVE';

/** Boolean expression to compare columns of type "EnumsLicensePartnerStatusEnum". All fields are combined with logical 'AND'. */
export type EnumsLicensePartnerStatusEnumComparisonExp = {
  _eq?: InputMaybe<LicensePartnerStatusEnum>;
  _in?: InputMaybe<Array<LicensePartnerStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<LicensePartnerStatusEnum>;
  _nin?: InputMaybe<Array<LicensePartnerStatusEnum>>;
};

/** aggregate max on columns */
export type EnumsLicensePartnerStatusMaxFields = {
  __typename?: 'EnumsLicensePartnerStatusMaxFields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type EnumsLicensePartnerStatusMinFields = {
  __typename?: 'EnumsLicensePartnerStatusMinFields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "enums.license_partner_status". */
export type EnumsLicensePartnerStatusOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.license_partner_status" */
export type EnumsLicensePartnerStatusSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_license_partner_status" */
export type EnumsLicensePartnerStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsLicensePartnerStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsLicensePartnerStatusStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.mandatory_agent_profile_field" */
export type EnumsMandatoryAgentProfileField = {
  __typename?: 'EnumsMandatoryAgentProfileField';
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.mandatory_agent_profile_field". All fields are combined with a logical 'AND'. */
export type EnumsMandatoryAgentProfileFieldBoolExp = {
  _and?: InputMaybe<Array<EnumsMandatoryAgentProfileFieldBoolExp>>;
  _not?: InputMaybe<EnumsMandatoryAgentProfileFieldBoolExp>;
  _or?: InputMaybe<Array<EnumsMandatoryAgentProfileFieldBoolExp>>;
  name?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "enums.mandatory_agent_profile_field". */
export type EnumsMandatoryAgentProfileFieldOrderBy = {
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.mandatory_agent_profile_field" */
export type EnumsMandatoryAgentProfileFieldSelectColumn =
  /** column name */
  'name';

/** Streaming cursor of the table "enums_mandatory_agent_profile_field" */
export type EnumsMandatoryAgentProfileFieldStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsMandatoryAgentProfileFieldStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsMandatoryAgentProfileFieldStreamCursorValueInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.mandatory_shop_profile_field" */
export type EnumsMandatoryShopProfileField = {
  __typename?: 'EnumsMandatoryShopProfileField';
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.mandatory_shop_profile_field". All fields are combined with a logical 'AND'. */
export type EnumsMandatoryShopProfileFieldBoolExp = {
  _and?: InputMaybe<Array<EnumsMandatoryShopProfileFieldBoolExp>>;
  _not?: InputMaybe<EnumsMandatoryShopProfileFieldBoolExp>;
  _or?: InputMaybe<Array<EnumsMandatoryShopProfileFieldBoolExp>>;
  name?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "enums.mandatory_shop_profile_field". */
export type EnumsMandatoryShopProfileFieldOrderBy = {
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.mandatory_shop_profile_field" */
export type EnumsMandatoryShopProfileFieldSelectColumn =
  /** column name */
  'name';

/** Streaming cursor of the table "enums_mandatory_shop_profile_field" */
export type EnumsMandatoryShopProfileFieldStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsMandatoryShopProfileFieldStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsMandatoryShopProfileFieldStreamCursorValueInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.marketing_type" */
export type EnumsMarketingType = {
  __typename?: 'EnumsMarketingType';
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.marketing_type". All fields are combined with a logical 'AND'. */
export type EnumsMarketingTypeBoolExp = {
  _and?: InputMaybe<Array<EnumsMarketingTypeBoolExp>>;
  _not?: InputMaybe<EnumsMarketingTypeBoolExp>;
  _or?: InputMaybe<Array<EnumsMarketingTypeBoolExp>>;
  name?: InputMaybe<StringComparisonExp>;
};

export type MarketingTypeEnum = 'DEVELOPMENT' | 'RENT' | 'SALE';

/** Boolean expression to compare columns of type "EnumsMarketingTypeEnum". All fields are combined with logical 'AND'. */
export type EnumsMarketingTypeEnumComparisonExp = {
  _eq?: InputMaybe<MarketingTypeEnum>;
  _in?: InputMaybe<Array<MarketingTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<MarketingTypeEnum>;
  _nin?: InputMaybe<Array<MarketingTypeEnum>>;
};

/** Ordering options when selecting data from "enums.marketing_type". */
export type EnumsMarketingTypeOrderBy = {
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.marketing_type" */
export type EnumsMarketingTypeSelectColumn =
  /** column name */
  'name';

/** Streaming cursor of the table "enums_marketing_type" */
export type EnumsMarketingTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsMarketingTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsMarketingTypeStreamCursorValueInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.phone_number_type" */
export type EnumsPhoneNumberType = {
  __typename?: 'EnumsPhoneNumberType';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.phone_number_type". All fields are combined with a logical 'AND'. */
export type EnumsPhoneNumberTypeBoolExp = {
  _and?: InputMaybe<Array<EnumsPhoneNumberTypeBoolExp>>;
  _not?: InputMaybe<EnumsPhoneNumberTypeBoolExp>;
  _or?: InputMaybe<Array<EnumsPhoneNumberTypeBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type PhoneNumberTypeEnum =
  | 'FAX'
  | 'MOBILE_PRIVATE'
  | 'MOBILE_WORK'
  | 'PHONE_PRIVATE'
  | 'PHONE_WORK'
  | 'UNDEFINED';

/** Boolean expression to compare columns of type "EnumsPhoneNumberTypeEnum". All fields are combined with logical 'AND'. */
export type EnumsPhoneNumberTypeEnumComparisonExp = {
  _eq?: InputMaybe<PhoneNumberTypeEnum>;
  _in?: InputMaybe<Array<PhoneNumberTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<PhoneNumberTypeEnum>;
  _nin?: InputMaybe<Array<PhoneNumberTypeEnum>>;
};

/** Ordering options when selecting data from "enums.phone_number_type". */
export type EnumsPhoneNumberTypeOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.phone_number_type" */
export type EnumsPhoneNumberTypeSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_phone_number_type" */
export type EnumsPhoneNumberTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsPhoneNumberTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsPhoneNumberTypeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.priority" */
export type EnumsPriority = {
  __typename?: 'EnumsPriority';
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  leads: Array<DbLead>;
  /** An aggregate relationship */
  leadsAggregate: DbLeadAggregate;
  name: Scalars['String'];
};

/** columns and relationships of "enums.priority" */
export type EnumsPriorityLeadsArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

/** columns and relationships of "enums.priority" */
export type EnumsPriorityLeadsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.priority". All fields are combined with a logical 'AND'. */
export type EnumsPriorityBoolExp = {
  _and?: InputMaybe<Array<EnumsPriorityBoolExp>>;
  _not?: InputMaybe<EnumsPriorityBoolExp>;
  _or?: InputMaybe<Array<EnumsPriorityBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  leads?: InputMaybe<DbLeadBoolExp>;
  leadsAggregate?: InputMaybe<DbLeadAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type PriorityEnum = 'HIGH' | 'LOW' | 'MEDIUM';

/** Boolean expression to compare columns of type "EnumsPriorityEnum". All fields are combined with logical 'AND'. */
export type EnumsPriorityEnumComparisonExp = {
  _eq?: InputMaybe<PriorityEnum>;
  _in?: InputMaybe<Array<PriorityEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<PriorityEnum>;
  _nin?: InputMaybe<Array<PriorityEnum>>;
};

/** Ordering options when selecting data from "enums.priority". */
export type EnumsPriorityOrderBy = {
  description?: InputMaybe<OrderBy>;
  leadsAggregate?: InputMaybe<DbLeadAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.priority" */
export type EnumsPrioritySelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_priority" */
export type EnumsPriorityStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsPriorityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsPriorityStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.privacy_level" */
export type EnumsPrivacyLevel = {
  __typename?: 'EnumsPrivacyLevel';
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.privacy_level". All fields are combined with a logical 'AND'. */
export type EnumsPrivacyLevelBoolExp = {
  _and?: InputMaybe<Array<EnumsPrivacyLevelBoolExp>>;
  _not?: InputMaybe<EnumsPrivacyLevelBoolExp>;
  _or?: InputMaybe<Array<EnumsPrivacyLevelBoolExp>>;
  name?: InputMaybe<StringComparisonExp>;
};

export type PrivacyLevelEnum = 'LICENSE_PARTNER' | 'PERSONAL' | 'SHOP' | 'TEAM';

/** Boolean expression to compare columns of type "EnumsPrivacyLevelEnum". All fields are combined with logical 'AND'. */
export type EnumsPrivacyLevelEnumComparisonExp = {
  _eq?: InputMaybe<PrivacyLevelEnum>;
  _in?: InputMaybe<Array<PrivacyLevelEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<PrivacyLevelEnum>;
  _nin?: InputMaybe<Array<PrivacyLevelEnum>>;
};

/** Ordering options when selecting data from "enums.privacy_level". */
export type EnumsPrivacyLevelOrderBy = {
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.privacy_level" */
export type EnumsPrivacyLevelSelectColumn =
  /** column name */
  'name';

/** Streaming cursor of the table "enums_privacy_level" */
export type EnumsPrivacyLevelStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsPrivacyLevelStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsPrivacyLevelStreamCursorValueInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.profession_type" */
export type EnumsProfessionType = {
  __typename?: 'EnumsProfessionType';
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.profession_type". All fields are combined with a logical 'AND'. */
export type EnumsProfessionTypeBoolExp = {
  _and?: InputMaybe<Array<EnumsProfessionTypeBoolExp>>;
  _not?: InputMaybe<EnumsProfessionTypeBoolExp>;
  _or?: InputMaybe<Array<EnumsProfessionTypeBoolExp>>;
  name?: InputMaybe<StringComparisonExp>;
};

export type ProfessionTypeEnum =
  | 'DOCTORAL_STUDENT'
  | 'EMPLOYEE'
  | 'HOMEMAKER'
  | 'JOB_SEEKER'
  | 'OFFICIAL'
  | 'OTHER'
  | 'RETIREE'
  | 'SELF_EMPLOYED'
  | 'STUDENT'
  | 'TRAINEE'
  | 'WORKER';

/** Boolean expression to compare columns of type "EnumsProfessionTypeEnum". All fields are combined with logical 'AND'. */
export type EnumsProfessionTypeEnumComparisonExp = {
  _eq?: InputMaybe<ProfessionTypeEnum>;
  _in?: InputMaybe<Array<ProfessionTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ProfessionTypeEnum>;
  _nin?: InputMaybe<Array<ProfessionTypeEnum>>;
};

/** Ordering options when selecting data from "enums.profession_type". */
export type EnumsProfessionTypeOrderBy = {
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.profession_type" */
export type EnumsProfessionTypeSelectColumn =
  /** column name */
  'name';

/** Streaming cursor of the table "enums_profession_type" */
export type EnumsProfessionTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsProfessionTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsProfessionTypeStreamCursorValueInput = {
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.property_occupation_status" */
export type EnumsPropertyOccupationStatus = {
  __typename?: 'EnumsPropertyOccupationStatus';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.property_occupation_status". All fields are combined with a logical 'AND'. */
export type EnumsPropertyOccupationStatusBoolExp = {
  _and?: InputMaybe<Array<EnumsPropertyOccupationStatusBoolExp>>;
  _not?: InputMaybe<EnumsPropertyOccupationStatusBoolExp>;
  _or?: InputMaybe<Array<EnumsPropertyOccupationStatusBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type PropertyOccupationStatusEnum = 'EMPTY' | 'OWNER' | 'RENTED_OUT';

/** Boolean expression to compare columns of type "EnumsPropertyOccupationStatusEnum". All fields are combined with logical 'AND'. */
export type EnumsPropertyOccupationStatusEnumComparisonExp = {
  _eq?: InputMaybe<PropertyOccupationStatusEnum>;
  _in?: InputMaybe<Array<PropertyOccupationStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<PropertyOccupationStatusEnum>;
  _nin?: InputMaybe<Array<PropertyOccupationStatusEnum>>;
};

/** Ordering options when selecting data from "enums.property_occupation_status". */
export type EnumsPropertyOccupationStatusOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.property_occupation_status" */
export type EnumsPropertyOccupationStatusSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_property_occupation_status" */
export type EnumsPropertyOccupationStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsPropertyOccupationStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsPropertyOccupationStatusStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.property_ownership_status" */
export type EnumsPropertyOwnershipStatus = {
  __typename?: 'EnumsPropertyOwnershipStatus';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.property_ownership_status". All fields are combined with a logical 'AND'. */
export type EnumsPropertyOwnershipStatusBoolExp = {
  _and?: InputMaybe<Array<EnumsPropertyOwnershipStatusBoolExp>>;
  _not?: InputMaybe<EnumsPropertyOwnershipStatusBoolExp>;
  _or?: InputMaybe<Array<EnumsPropertyOwnershipStatusBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type PropertyOwnershipStatusEnum = 'LH_CONTACT' | 'OTHER' | 'OWNER';

/** Boolean expression to compare columns of type "EnumsPropertyOwnershipStatusEnum". All fields are combined with logical 'AND'. */
export type EnumsPropertyOwnershipStatusEnumComparisonExp = {
  _eq?: InputMaybe<PropertyOwnershipStatusEnum>;
  _in?: InputMaybe<Array<PropertyOwnershipStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<PropertyOwnershipStatusEnum>;
  _nin?: InputMaybe<Array<PropertyOwnershipStatusEnum>>;
};

/** Ordering options when selecting data from "enums.property_ownership_status". */
export type EnumsPropertyOwnershipStatusOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.property_ownership_status" */
export type EnumsPropertyOwnershipStatusSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_property_ownership_status" */
export type EnumsPropertyOwnershipStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsPropertyOwnershipStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsPropertyOwnershipStatusStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.qualification_form_status" */
export type EnumsQualificationFormStatus = {
  __typename?: 'EnumsQualificationFormStatus';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An array relationship */
  qualificationFormLinks: Array<DbQualificationFormLink>;
  /** An aggregate relationship */
  qualificationFormLinksAggregate: DbQualificationFormLinkAggregate;
};

/** columns and relationships of "enums.qualification_form_status" */
export type EnumsQualificationFormStatusQualificationFormLinksArgs = {
  distinctOn?: InputMaybe<Array<DbQualificationFormLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbQualificationFormLinkOrderBy>>;
  where?: InputMaybe<DbQualificationFormLinkBoolExp>;
};

/** columns and relationships of "enums.qualification_form_status" */
export type EnumsQualificationFormStatusQualificationFormLinksAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbQualificationFormLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbQualificationFormLinkOrderBy>>;
  where?: InputMaybe<DbQualificationFormLinkBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.qualification_form_status". All fields are combined with a logical 'AND'. */
export type EnumsQualificationFormStatusBoolExp = {
  _and?: InputMaybe<Array<EnumsQualificationFormStatusBoolExp>>;
  _not?: InputMaybe<EnumsQualificationFormStatusBoolExp>;
  _or?: InputMaybe<Array<EnumsQualificationFormStatusBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  qualificationFormLinks?: InputMaybe<DbQualificationFormLinkBoolExp>;
  qualificationFormLinksAggregate?: InputMaybe<DbQualificationFormLinkAggregateBoolExp>;
};

export type QualificationFormStatusEnum =
  | 'COMPLETED_BY_FORM'
  | 'COMPLETED_BY_LH'
  | 'DIRECT_EXPOSEE_SENT'
  | 'EXPIRED'
  | 'EXPOSEE_WITHOUT_COMMISSION_DETAILS_SENT'
  | 'FORM_SENT'
  | 'INVALID'
  | 'REMINDER_SENT';

/** Boolean expression to compare columns of type "EnumsQualificationFormStatusEnum". All fields are combined with logical 'AND'. */
export type EnumsQualificationFormStatusEnumComparisonExp = {
  _eq?: InputMaybe<QualificationFormStatusEnum>;
  _in?: InputMaybe<Array<QualificationFormStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<QualificationFormStatusEnum>;
  _nin?: InputMaybe<Array<QualificationFormStatusEnum>>;
};

/** Ordering options when selecting data from "enums.qualification_form_status". */
export type EnumsQualificationFormStatusOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  qualificationFormLinksAggregate?: InputMaybe<DbQualificationFormLinkAggregateOrderBy>;
};

/** select columns of table "enums.qualification_form_status" */
export type EnumsQualificationFormStatusSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_qualification_form_status" */
export type EnumsQualificationFormStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsQualificationFormStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsQualificationFormStatusStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.qualiform_fields" */
export type EnumsQualiformFields = {
  __typename?: 'EnumsQualiformFields';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An array relationship */
  qualiformMandatoryFields: Array<DbQualiformMandatoryField>;
};

/** columns and relationships of "enums.qualiform_fields" */
export type EnumsQualiformFieldsQualiformMandatoryFieldsArgs = {
  distinctOn?: InputMaybe<Array<DbQualiformMandatoryFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbQualiformMandatoryFieldOrderBy>>;
  where?: InputMaybe<DbQualiformMandatoryFieldBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.qualiform_fields". All fields are combined with a logical 'AND'. */
export type EnumsQualiformFieldsBoolExp = {
  _and?: InputMaybe<Array<EnumsQualiformFieldsBoolExp>>;
  _not?: InputMaybe<EnumsQualiformFieldsBoolExp>;
  _or?: InputMaybe<Array<EnumsQualiformFieldsBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  qualiformMandatoryFields?: InputMaybe<DbQualiformMandatoryFieldBoolExp>;
};

export type QualiformFieldsEnum =
  | 'CITY'
  | 'COUNTRY'
  | 'EMAIL'
  | 'FIRSTNAME'
  | 'LASTNAME'
  | 'PHONE'
  | 'POSTAL_CODE'
  | 'PREFERRED_LANGUAGE'
  | 'SELLER_LEAD_CITY'
  | 'SELLER_LEAD_COUNTRY'
  | 'SELLER_LEAD_OCCUPATION_STATUS'
  | 'SELLER_LEAD_OWNER'
  | 'SELLER_LEAD_POSTAL_CODE'
  | 'SELLER_LEAD_PURPOSE_OF_REQUEST'
  | 'SELLER_LEAD_STREET_NAME'
  | 'SELLER_LEAD_STREET_NUMBER'
  | 'STREET_NAME'
  | 'STREET_NUMBER';

/** Boolean expression to compare columns of type "EnumsQualiformFieldsEnum". All fields are combined with logical 'AND'. */
export type EnumsQualiformFieldsEnumComparisonExp = {
  _eq?: InputMaybe<QualiformFieldsEnum>;
  _in?: InputMaybe<Array<QualiformFieldsEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<QualiformFieldsEnum>;
  _nin?: InputMaybe<Array<QualiformFieldsEnum>>;
};

/** Ordering options when selecting data from "enums.qualiform_fields". */
export type EnumsQualiformFieldsOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  qualiformMandatoryFieldsAggregate?: InputMaybe<DbQualiformMandatoryFieldAggregateOrderBy>;
};

/** select columns of table "enums.qualiform_fields" */
export type EnumsQualiformFieldsSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_qualiform_fields" */
export type EnumsQualiformFieldsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsQualiformFieldsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsQualiformFieldsStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.referral_not_possible_reason" */
export type EnumsReferralNotPossibleReason = {
  __typename?: 'EnumsReferralNotPossibleReason';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An array relationship */
  referrals: Array<DbReferral>;
  /** An aggregate relationship */
  referralsAggregate: DbReferralAggregate;
};

/** columns and relationships of "enums.referral_not_possible_reason" */
export type EnumsReferralNotPossibleReasonReferralsArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

/** columns and relationships of "enums.referral_not_possible_reason" */
export type EnumsReferralNotPossibleReasonReferralsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.referral_not_possible_reason". All fields are combined with a logical 'AND'. */
export type EnumsReferralNotPossibleReasonBoolExp = {
  _and?: InputMaybe<Array<EnumsReferralNotPossibleReasonBoolExp>>;
  _not?: InputMaybe<EnumsReferralNotPossibleReasonBoolExp>;
  _or?: InputMaybe<Array<EnumsReferralNotPossibleReasonBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  referrals?: InputMaybe<DbReferralBoolExp>;
  referralsAggregate?: InputMaybe<DbReferralAggregateBoolExp>;
};

export type ReferralNotPossibleReasonEnum =
  | 'BLACKLIST'
  | 'CUSTOMER_DOES_NOT_RESPOND'
  | 'CUSTOMER_IN_CONTACT_WITH_SHOP'
  | 'CUSTOMER_REVOKED_REQUEST'
  | 'FICTITIOUS_REQUEST'
  | 'INVALID_CONTACT_INFO'
  | 'NO_SHOP_IN_THIS_AREA'
  | 'REFERRAL_REJECTED';

/** Boolean expression to compare columns of type "EnumsReferralNotPossibleReasonEnum". All fields are combined with logical 'AND'. */
export type EnumsReferralNotPossibleReasonEnumComparisonExp = {
  _eq?: InputMaybe<ReferralNotPossibleReasonEnum>;
  _in?: InputMaybe<Array<ReferralNotPossibleReasonEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ReferralNotPossibleReasonEnum>;
  _nin?: InputMaybe<Array<ReferralNotPossibleReasonEnum>>;
};

/** Ordering options when selecting data from "enums.referral_not_possible_reason". */
export type EnumsReferralNotPossibleReasonOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  referralsAggregate?: InputMaybe<DbReferralAggregateOrderBy>;
};

/** select columns of table "enums.referral_not_possible_reason" */
export type EnumsReferralNotPossibleReasonSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_referral_not_possible_reason" */
export type EnumsReferralNotPossibleReasonStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsReferralNotPossibleReasonStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsReferralNotPossibleReasonStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ReferralStatusEnum = 'ACCEPTED' | 'IN_PROGRESS' | 'REJECTED';

/** Boolean expression to compare columns of type "EnumsReferralStatusEnum". All fields are combined with logical 'AND'. */
export type EnumsReferralStatusEnumComparisonExp = {
  _eq?: InputMaybe<ReferralStatusEnum>;
  _in?: InputMaybe<Array<ReferralStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ReferralStatusEnum>;
  _nin?: InputMaybe<Array<ReferralStatusEnum>>;
};

export type ReferralTypeEnum = 'GO_TO_LH' | 'LH_TO_GO' | 'LH_TO_LH';

/** Boolean expression to compare columns of type "EnumsReferralTypeEnum". All fields are combined with logical 'AND'. */
export type EnumsReferralTypeEnumComparisonExp = {
  _eq?: InputMaybe<ReferralTypeEnum>;
  _in?: InputMaybe<Array<ReferralTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ReferralTypeEnum>;
  _nin?: InputMaybe<Array<ReferralTypeEnum>>;
};

/** columns and relationships of "enums.salutation" */
export type EnumsSalutation = {
  __typename?: 'EnumsSalutation';
  /** An array relationship */
  agents: Array<DbAgent>;
  /** An aggregate relationship */
  agentsAggregate: DbAgentAggregate;
  /** An array relationship */
  contacts: Array<DbContact>;
  /** An aggregate relationship */
  contactsAggregate: DbContactAggregate;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** columns and relationships of "enums.salutation" */
export type EnumsSalutationAgentsArgs = {
  distinctOn?: InputMaybe<Array<DbAgentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentOrderBy>>;
  where?: InputMaybe<DbAgentBoolExp>;
};

/** columns and relationships of "enums.salutation" */
export type EnumsSalutationAgentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentOrderBy>>;
  where?: InputMaybe<DbAgentBoolExp>;
};

/** columns and relationships of "enums.salutation" */
export type EnumsSalutationContactsArgs = {
  distinctOn?: InputMaybe<Array<DbContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactOrderBy>>;
  where?: InputMaybe<DbContactBoolExp>;
};

/** columns and relationships of "enums.salutation" */
export type EnumsSalutationContactsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactOrderBy>>;
  where?: InputMaybe<DbContactBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.salutation". All fields are combined with a logical 'AND'. */
export type EnumsSalutationBoolExp = {
  _and?: InputMaybe<Array<EnumsSalutationBoolExp>>;
  _not?: InputMaybe<EnumsSalutationBoolExp>;
  _or?: InputMaybe<Array<EnumsSalutationBoolExp>>;
  agents?: InputMaybe<DbAgentBoolExp>;
  agentsAggregate?: InputMaybe<DbAgentAggregateBoolExp>;
  contacts?: InputMaybe<DbContactBoolExp>;
  contactsAggregate?: InputMaybe<DbContactAggregateBoolExp>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type SalutationEnum = 'MR' | 'MRS' | 'OTHER';

/** Boolean expression to compare columns of type "EnumsSalutationEnum". All fields are combined with logical 'AND'. */
export type EnumsSalutationEnumComparisonExp = {
  _eq?: InputMaybe<SalutationEnum>;
  _in?: InputMaybe<Array<SalutationEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<SalutationEnum>;
  _nin?: InputMaybe<Array<SalutationEnum>>;
};

/** Ordering options when selecting data from "enums.salutation". */
export type EnumsSalutationOrderBy = {
  agentsAggregate?: InputMaybe<DbAgentAggregateOrderBy>;
  contactsAggregate?: InputMaybe<DbContactAggregateOrderBy>;
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.salutation" */
export type EnumsSalutationSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_salutation" */
export type EnumsSalutationStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsSalutationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsSalutationStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.session_status" */
export type EnumsSessionStatus = {
  __typename?: 'EnumsSessionStatus';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An array relationship */
  sessions: Array<DbSession>;
  /** An aggregate relationship */
  sessionsAggregate: DbSessionAggregate;
};

/** columns and relationships of "enums.session_status" */
export type EnumsSessionStatusSessionsArgs = {
  distinctOn?: InputMaybe<Array<DbSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSessionOrderBy>>;
  where?: InputMaybe<DbSessionBoolExp>;
};

/** columns and relationships of "enums.session_status" */
export type EnumsSessionStatusSessionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSessionOrderBy>>;
  where?: InputMaybe<DbSessionBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.session_status". All fields are combined with a logical 'AND'. */
export type EnumsSessionStatusBoolExp = {
  _and?: InputMaybe<Array<EnumsSessionStatusBoolExp>>;
  _not?: InputMaybe<EnumsSessionStatusBoolExp>;
  _or?: InputMaybe<Array<EnumsSessionStatusBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  sessions?: InputMaybe<DbSessionBoolExp>;
  sessionsAggregate?: InputMaybe<DbSessionAggregateBoolExp>;
};

export type SessionStatusEnum = 'ACTIVE' | 'BUSY' | 'CALL_ENDED' | 'INACTIVE' | 'LOGOUT' | 'OFFLINE';

/** Boolean expression to compare columns of type "EnumsSessionStatusEnum". All fields are combined with logical 'AND'. */
export type EnumsSessionStatusEnumComparisonExp = {
  _eq?: InputMaybe<SessionStatusEnum>;
  _in?: InputMaybe<Array<SessionStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<SessionStatusEnum>;
  _nin?: InputMaybe<Array<SessionStatusEnum>>;
};

/** Ordering options when selecting data from "enums.session_status". */
export type EnumsSessionStatusOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  sessionsAggregate?: InputMaybe<DbSessionAggregateOrderBy>;
};

/** select columns of table "enums.session_status" */
export type EnumsSessionStatusSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_session_status" */
export type EnumsSessionStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsSessionStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsSessionStatusStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.shared_type" */
export type EnumsSharedType = {
  __typename?: 'EnumsSharedType';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.shared_type". All fields are combined with a logical 'AND'. */
export type EnumsSharedTypeBoolExp = {
  _and?: InputMaybe<Array<EnumsSharedTypeBoolExp>>;
  _not?: InputMaybe<EnumsSharedTypeBoolExp>;
  _or?: InputMaybe<Array<EnumsSharedTypeBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** Ordering options when selecting data from "enums.shared_type". */
export type EnumsSharedTypeOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.shared_type" */
export type EnumsSharedTypeSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_shared_type" */
export type EnumsSharedTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsSharedTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsSharedTypeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.shop_expertise" */
export type EnumsShopExpertise = {
  __typename?: 'EnumsShopExpertise';
  /** An array relationship */
  agentProfileExpertises: Array<DbAgentProfileExpertise>;
  /** An aggregate relationship */
  agentProfileExpertisesAggregate: DbAgentProfileExpertiseAggregate;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An aggregate relationship */
  shopProfileExpertisesAggregate: DbShopProfileExpertiseAggregate;
  /** An array relationship */
  shop_profile_expertises: Array<DbShopProfileExpertise>;
};

/** columns and relationships of "enums.shop_expertise" */
export type EnumsShopExpertiseAgentProfileExpertisesArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileExpertiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileExpertiseOrderBy>>;
  where?: InputMaybe<DbAgentProfileExpertiseBoolExp>;
};

/** columns and relationships of "enums.shop_expertise" */
export type EnumsShopExpertiseAgentProfileExpertisesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileExpertiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileExpertiseOrderBy>>;
  where?: InputMaybe<DbAgentProfileExpertiseBoolExp>;
};

/** columns and relationships of "enums.shop_expertise" */
export type EnumsShopExpertiseShopProfileExpertisesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileExpertiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileExpertiseOrderBy>>;
  where?: InputMaybe<DbShopProfileExpertiseBoolExp>;
};

/** columns and relationships of "enums.shop_expertise" */
export type EnumsShopExpertiseShopProfileExpertisesArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileExpertiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileExpertiseOrderBy>>;
  where?: InputMaybe<DbShopProfileExpertiseBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.shop_expertise". All fields are combined with a logical 'AND'. */
export type EnumsShopExpertiseBoolExp = {
  _and?: InputMaybe<Array<EnumsShopExpertiseBoolExp>>;
  _not?: InputMaybe<EnumsShopExpertiseBoolExp>;
  _or?: InputMaybe<Array<EnumsShopExpertiseBoolExp>>;
  agentProfileExpertises?: InputMaybe<DbAgentProfileExpertiseBoolExp>;
  agentProfileExpertisesAggregate?: InputMaybe<DbAgentProfileExpertiseAggregateBoolExp>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  shop_profile_expertises?: InputMaybe<DbShopProfileExpertiseBoolExp>;
  shop_profile_expertisesAggregate?: InputMaybe<DbShopProfileExpertiseAggregateBoolExp>;
};

export type ShopExpertiseEnum =
  | 'COMMERCIAL_SPACE'
  | 'GASTRONOMY'
  | 'INDUSTRY'
  | 'INVESTMENT_PROPERTIES'
  | 'LUXURY_HOMES'
  | 'OFFICE'
  | 'RESIDENTIAL_SALES'
  | 'VILLAS';

/** Boolean expression to compare columns of type "EnumsShopExpertiseEnum". All fields are combined with logical 'AND'. */
export type EnumsShopExpertiseEnumComparisonExp = {
  _eq?: InputMaybe<ShopExpertiseEnum>;
  _in?: InputMaybe<Array<ShopExpertiseEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ShopExpertiseEnum>;
  _nin?: InputMaybe<Array<ShopExpertiseEnum>>;
};

/** Ordering options when selecting data from "enums.shop_expertise". */
export type EnumsShopExpertiseOrderBy = {
  agentProfileExpertisesAggregate?: InputMaybe<DbAgentProfileExpertiseAggregateOrderBy>;
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  shop_profile_expertisesAggregate?: InputMaybe<DbShopProfileExpertiseAggregateOrderBy>;
};

/** select columns of table "enums.shop_expertise" */
export type EnumsShopExpertiseSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_shop_expertise" */
export type EnumsShopExpertiseStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsShopExpertiseStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsShopExpertiseStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ShopProfileImageTypeEnum = 'FEATURED_CONTENT_TEASER' | 'MANAGER' | 'SHOP' | 'TEAM';

/** Boolean expression to compare columns of type "EnumsShopProfileImageTypeEnum". All fields are combined with logical 'AND'. */
export type EnumsShopProfileImageTypeEnumComparisonExp = {
  _eq?: InputMaybe<ShopProfileImageTypeEnum>;
  _in?: InputMaybe<Array<ShopProfileImageTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ShopProfileImageTypeEnum>;
  _nin?: InputMaybe<Array<ShopProfileImageTypeEnum>>;
};

/** columns and relationships of "enums.shop_status" */
export type EnumsShopStatus = {
  __typename?: 'EnumsShopStatus';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An array relationship */
  shops: Array<DbShop>;
  /** An aggregate relationship */
  shopsAggregate: DbShopAggregate;
};

/** columns and relationships of "enums.shop_status" */
export type EnumsShopStatusShopsArgs = {
  distinctOn?: InputMaybe<Array<DbShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopOrderBy>>;
  where?: InputMaybe<DbShopBoolExp>;
};

/** columns and relationships of "enums.shop_status" */
export type EnumsShopStatusShopsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopOrderBy>>;
  where?: InputMaybe<DbShopBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.shop_status". All fields are combined with a logical 'AND'. */
export type EnumsShopStatusBoolExp = {
  _and?: InputMaybe<Array<EnumsShopStatusBoolExp>>;
  _not?: InputMaybe<EnumsShopStatusBoolExp>;
  _or?: InputMaybe<Array<EnumsShopStatusBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  shops?: InputMaybe<DbShopBoolExp>;
  shopsAggregate?: InputMaybe<DbShopAggregateBoolExp>;
};

export type ShopStatusEnum =
  /** Blacklisted */
  | 'BLACKLISTED'
  /** Blocked */
  | 'BLOCKED'
  /** Closed */
  | 'CLOSED'
  /** Opened */
  | 'OPENED'
  /** Planned */
  | 'PLANNED';

/** Boolean expression to compare columns of type "EnumsShopStatusEnum". All fields are combined with logical 'AND'. */
export type EnumsShopStatusEnumComparisonExp = {
  _eq?: InputMaybe<ShopStatusEnum>;
  _in?: InputMaybe<Array<ShopStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ShopStatusEnum>;
  _nin?: InputMaybe<Array<ShopStatusEnum>>;
};

/** Ordering options when selecting data from "enums.shop_status". */
export type EnumsShopStatusOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  shopsAggregate?: InputMaybe<DbShopAggregateOrderBy>;
};

/** select columns of table "enums.shop_status" */
export type EnumsShopStatusSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_shop_status" */
export type EnumsShopStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsShopStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsShopStatusStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.spoken_language" */
export type EnumsSpokenLanguage = {
  __typename?: 'EnumsSpokenLanguage';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.spoken_language". All fields are combined with a logical 'AND'. */
export type EnumsSpokenLanguageBoolExp = {
  _and?: InputMaybe<Array<EnumsSpokenLanguageBoolExp>>;
  _not?: InputMaybe<EnumsSpokenLanguageBoolExp>;
  _or?: InputMaybe<Array<EnumsSpokenLanguageBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type SpokenLanguageEnum =
  /** Afrikaans */
  | 'AF'
  /** Arabic */
  | 'AR'
  /** Bulgarian */
  | 'BG'
  /** Catalan */
  | 'CA'
  /** Czech */
  | 'CS'
  /** Denmark */
  | 'DA'
  /** German (Germany) */
  | 'DE'
  /** German (Austrian) */
  | 'DE_AT'
  /** German (Switzerland) */
  | 'DE_CH'
  /** Greek */
  | 'EL'
  /** English */
  | 'EN'
  /** English (Australia) */
  | 'EN_AU'
  /** English (Hong Kong) */
  | 'EN_HK'
  /** Spanish (Spain) */
  | 'ES'
  /** Spanish (South America) */
  | 'ES_AR'
  /** Finnish */
  | 'FI'
  /** French (France) */
  | 'FR'
  /** French (Belgium) */
  | 'FR_BE'
  /** Hebrew */
  | 'HE'
  /** Croatian */
  | 'HR'
  /** Hungarian */
  | 'HU'
  /** Italian */
  | 'IT'
  /** Japanese */
  | 'JA'
  /** Korean */
  | 'KO'
  /** Montenegrin */
  | 'ME'
  /** Dutch */
  | 'NL'
  /** Norwegian */
  | 'NO'
  /** Polish */
  | 'PL'
  /** Portuguese */
  | 'PT'
  /** Romanian */
  | 'RO'
  /** Russian */
  | 'RU'
  /** German Sign Language */
  | 'SGN_DE'
  /** Slovak */
  | 'SK'
  /** Slovene */
  | 'SL'
  /** Swedish */
  | 'SV'
  /** Turkish */
  | 'TR'
  /** Xhosa */
  | 'XH'
  /** Chinese */
  | 'ZH_CN'
  /** Chinese */
  | 'ZH_HANS'
  /** Chinese */
  | 'ZH_HANT'
  /** Chinese (Taiwan) */
  | 'ZH_TW'
  /** Zulu */
  | 'ZU';

/** Boolean expression to compare columns of type "EnumsSpokenLanguageEnum". All fields are combined with logical 'AND'. */
export type EnumsSpokenLanguageEnumComparisonExp = {
  _eq?: InputMaybe<SpokenLanguageEnum>;
  _in?: InputMaybe<Array<SpokenLanguageEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<SpokenLanguageEnum>;
  _nin?: InputMaybe<Array<SpokenLanguageEnum>>;
};

/** Ordering options when selecting data from "enums.spoken_language". */
export type EnumsSpokenLanguageOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.spoken_language" */
export type EnumsSpokenLanguageSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_spoken_language" */
export type EnumsSpokenLanguageStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsSpokenLanguageStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsSpokenLanguageStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.task_appointment_status" */
export type EnumsTaskAppointmentStatus = {
  __typename?: 'EnumsTaskAppointmentStatus';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.task_appointment_status". All fields are combined with a logical 'AND'. */
export type EnumsTaskAppointmentStatusBoolExp = {
  _and?: InputMaybe<Array<EnumsTaskAppointmentStatusBoolExp>>;
  _not?: InputMaybe<EnumsTaskAppointmentStatusBoolExp>;
  _or?: InputMaybe<Array<EnumsTaskAppointmentStatusBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type TaskAppointmentStatusEnum = 'CANCELLED' | 'COMPLETED' | 'OPEN';

/** Boolean expression to compare columns of type "EnumsTaskAppointmentStatusEnum". All fields are combined with logical 'AND'. */
export type EnumsTaskAppointmentStatusEnumComparisonExp = {
  _eq?: InputMaybe<TaskAppointmentStatusEnum>;
  _in?: InputMaybe<Array<TaskAppointmentStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<TaskAppointmentStatusEnum>;
  _nin?: InputMaybe<Array<TaskAppointmentStatusEnum>>;
};

/** Ordering options when selecting data from "enums.task_appointment_status". */
export type EnumsTaskAppointmentStatusOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.task_appointment_status" */
export type EnumsTaskAppointmentStatusSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_task_appointment_status" */
export type EnumsTaskAppointmentStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsTaskAppointmentStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsTaskAppointmentStatusStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.task_type" */
export type EnumsTaskType = {
  __typename?: 'EnumsTaskType';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "enums.task_type". All fields are combined with a logical 'AND'. */
export type EnumsTaskTypeBoolExp = {
  _and?: InputMaybe<Array<EnumsTaskTypeBoolExp>>;
  _not?: InputMaybe<EnumsTaskTypeBoolExp>;
  _or?: InputMaybe<Array<EnumsTaskTypeBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type TaskTypeEnum =
  | 'ACQUISITION'
  | 'ACTIVE_BUYER_CONTACT'
  | 'ACTIVE_SELLER_CONTACT'
  | 'ACTIVITY_REPORT'
  | 'FOLLOW_UP'
  | 'INCOMING_BUYER_SELLER_CONTACT'
  | 'INCOMING_CALL'
  | 'MAKE_AN_APPOINTMENT'
  | 'MARKET_REPORT'
  | 'MISCELLANEOUS'
  | 'NEWSLETTER'
  | 'NOTICE'
  | 'OFFER'
  | 'OUTGOING_CALL'
  | 'OWNERSHIP_ANNIVERSARY'
  | 'PLACE_ADVERTISEMENT'
  | 'PRICE_REDUCTION'
  | 'TODO';

/** Boolean expression to compare columns of type "EnumsTaskTypeEnum". All fields are combined with logical 'AND'. */
export type EnumsTaskTypeEnumComparisonExp = {
  _eq?: InputMaybe<TaskTypeEnum>;
  _in?: InputMaybe<Array<TaskTypeEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<TaskTypeEnum>;
  _nin?: InputMaybe<Array<TaskTypeEnum>>;
};

/** Ordering options when selecting data from "enums.task_type". */
export type EnumsTaskTypeOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.task_type" */
export type EnumsTaskTypeSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_task_type" */
export type EnumsTaskTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsTaskTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsTaskTypeStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.team_status" */
export type EnumsTeamStatus = {
  __typename?: 'EnumsTeamStatus';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An array relationship */
  teams: Array<DbTeam>;
  /** An aggregate relationship */
  teamsAggregate: DbTeamAggregate;
};

/** columns and relationships of "enums.team_status" */
export type EnumsTeamStatusTeamsArgs = {
  distinctOn?: InputMaybe<Array<DbTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTeamOrderBy>>;
  where?: InputMaybe<DbTeamBoolExp>;
};

/** columns and relationships of "enums.team_status" */
export type EnumsTeamStatusTeamsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTeamOrderBy>>;
  where?: InputMaybe<DbTeamBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.team_status". All fields are combined with a logical 'AND'. */
export type EnumsTeamStatusBoolExp = {
  _and?: InputMaybe<Array<EnumsTeamStatusBoolExp>>;
  _not?: InputMaybe<EnumsTeamStatusBoolExp>;
  _or?: InputMaybe<Array<EnumsTeamStatusBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  teams?: InputMaybe<DbTeamBoolExp>;
  teamsAggregate?: InputMaybe<DbTeamAggregateBoolExp>;
};

export type TeamStatusEnum =
  /** Active */
  | 'ACTIVE'
  /** Inactive */
  | 'INACTIVE';

/** Boolean expression to compare columns of type "EnumsTeamStatusEnum". All fields are combined with logical 'AND'. */
export type EnumsTeamStatusEnumComparisonExp = {
  _eq?: InputMaybe<TeamStatusEnum>;
  _in?: InputMaybe<Array<TeamStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<TeamStatusEnum>;
  _nin?: InputMaybe<Array<TeamStatusEnum>>;
};

/** Ordering options when selecting data from "enums.team_status". */
export type EnumsTeamStatusOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  teamsAggregate?: InputMaybe<DbTeamAggregateOrderBy>;
};

/** select columns of table "enums.team_status" */
export type EnumsTeamStatusSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_team_status" */
export type EnumsTeamStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsTeamStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsTeamStatusStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.time_frame" */
export type EnumsTimeFrame = {
  __typename?: 'EnumsTimeFrame';
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  leads: Array<DbLead>;
  /** An aggregate relationship */
  leadsAggregate: DbLeadAggregate;
  name: Scalars['String'];
};

/** columns and relationships of "enums.time_frame" */
export type EnumsTimeFrameLeadsArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

/** columns and relationships of "enums.time_frame" */
export type EnumsTimeFrameLeadsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.time_frame". All fields are combined with a logical 'AND'. */
export type EnumsTimeFrameBoolExp = {
  _and?: InputMaybe<Array<EnumsTimeFrameBoolExp>>;
  _not?: InputMaybe<EnumsTimeFrameBoolExp>;
  _or?: InputMaybe<Array<EnumsTimeFrameBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  leads?: InputMaybe<DbLeadBoolExp>;
  leadsAggregate?: InputMaybe<DbLeadAggregateBoolExp>;
  name?: InputMaybe<StringComparisonExp>;
};

export type TimeFrameEnum = 'ONE_MONTH' | 'SIX_MONTHS' | 'THREE_MONTHS' | 'TWELVE_MONTHS' | 'TWO_MONTHS';

/** Boolean expression to compare columns of type "EnumsTimeFrameEnum". All fields are combined with logical 'AND'. */
export type EnumsTimeFrameEnumComparisonExp = {
  _eq?: InputMaybe<TimeFrameEnum>;
  _in?: InputMaybe<Array<TimeFrameEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<TimeFrameEnum>;
  _nin?: InputMaybe<Array<TimeFrameEnum>>;
};

/** Ordering options when selecting data from "enums.time_frame". */
export type EnumsTimeFrameOrderBy = {
  description?: InputMaybe<OrderBy>;
  leadsAggregate?: InputMaybe<DbLeadAggregateOrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** select columns of table "enums.time_frame" */
export type EnumsTimeFrameSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_time_frame" */
export type EnumsTimeFrameStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsTimeFrameStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsTimeFrameStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.user_role" */
export type EnumsUserRole = {
  __typename?: 'EnumsUserRole';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An aggregate relationship */
  userAdminAssignmentsAggregate: DbUserAdminAssignmentAggregate;
  /** An aggregate relationship */
  userShopAssignmentsAggregate: DbUserShopAssignmentAggregate;
  /** An aggregate relationship */
  userTeamAssignmentsAggregate: DbUserTeamAssignmentAggregate;
  /** An array relationship */
  user_admin_assignments: Array<DbUserAdminAssignment>;
  /** An array relationship */
  user_shop_assignments: Array<DbUserShopAssignment>;
  /** An array relationship */
  user_team_assignments: Array<DbUserTeamAssignment>;
};

/** columns and relationships of "enums.user_role" */
export type EnumsUserRoleUserAdminAssignmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbUserAdminAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserAdminAssignmentOrderBy>>;
  where?: InputMaybe<DbUserAdminAssignmentBoolExp>;
};

/** columns and relationships of "enums.user_role" */
export type EnumsUserRoleUserShopAssignmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbUserShopAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserShopAssignmentOrderBy>>;
  where?: InputMaybe<DbUserShopAssignmentBoolExp>;
};

/** columns and relationships of "enums.user_role" */
export type EnumsUserRoleUserTeamAssignmentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbUserTeamAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserTeamAssignmentOrderBy>>;
  where?: InputMaybe<DbUserTeamAssignmentBoolExp>;
};

/** columns and relationships of "enums.user_role" */
export type EnumsUserRoleUserAdminAssignmentsArgs = {
  distinctOn?: InputMaybe<Array<DbUserAdminAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserAdminAssignmentOrderBy>>;
  where?: InputMaybe<DbUserAdminAssignmentBoolExp>;
};

/** columns and relationships of "enums.user_role" */
export type EnumsUserRoleUserShopAssignmentsArgs = {
  distinctOn?: InputMaybe<Array<DbUserShopAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserShopAssignmentOrderBy>>;
  where?: InputMaybe<DbUserShopAssignmentBoolExp>;
};

/** columns and relationships of "enums.user_role" */
export type EnumsUserRoleUserTeamAssignmentsArgs = {
  distinctOn?: InputMaybe<Array<DbUserTeamAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserTeamAssignmentOrderBy>>;
  where?: InputMaybe<DbUserTeamAssignmentBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.user_role". All fields are combined with a logical 'AND'. */
export type EnumsUserRoleBoolExp = {
  _and?: InputMaybe<Array<EnumsUserRoleBoolExp>>;
  _not?: InputMaybe<EnumsUserRoleBoolExp>;
  _or?: InputMaybe<Array<EnumsUserRoleBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  user_admin_assignments?: InputMaybe<DbUserAdminAssignmentBoolExp>;
  user_admin_assignmentsAggregate?: InputMaybe<DbUserAdminAssignmentAggregateBoolExp>;
  user_shop_assignments?: InputMaybe<DbUserShopAssignmentBoolExp>;
  user_shop_assignmentsAggregate?: InputMaybe<DbUserShopAssignmentAggregateBoolExp>;
  user_team_assignments?: InputMaybe<DbUserTeamAssignmentBoolExp>;
  user_team_assignmentsAggregate?: InputMaybe<DbUserTeamAssignmentAggregateBoolExp>;
};

export type UserRoleEnum =
  | 'ADMIN'
  | 'AGENT'
  | 'AGENT_LIGHT'
  | 'CALL_CENTER_AGENT'
  | 'COMPLIANCE_MANAGER'
  | 'CONTENT_MANAGER'
  | 'LEAD_MANAGER'
  | 'READONLY_ADMIN'
  | 'SHOP_MANAGER'
  | 'TEAM_ASSISTANT'
  | 'TEAM_ASSISTANT_LIGHT'
  | 'TEAM_LEAD'
  | 'TEAM_LEAD_LIGHT';

/** Boolean expression to compare columns of type "EnumsUserRoleEnum". All fields are combined with logical 'AND'. */
export type EnumsUserRoleEnumComparisonExp = {
  _eq?: InputMaybe<UserRoleEnum>;
  _in?: InputMaybe<Array<UserRoleEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<UserRoleEnum>;
  _nin?: InputMaybe<Array<UserRoleEnum>>;
};

/** Ordering options when selecting data from "enums.user_role". */
export type EnumsUserRoleOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  user_admin_assignmentsAggregate?: InputMaybe<DbUserAdminAssignmentAggregateOrderBy>;
  user_shop_assignmentsAggregate?: InputMaybe<DbUserShopAssignmentAggregateOrderBy>;
  user_team_assignmentsAggregate?: InputMaybe<DbUserTeamAssignmentAggregateOrderBy>;
};

/** select columns of table "enums.user_role" */
export type EnumsUserRoleSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_user_role" */
export type EnumsUserRoleStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsUserRoleStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsUserRoleStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "enums.zero_commission_reason" */
export type EnumsZeroCommissionReason = {
  __typename?: 'EnumsZeroCommissionReason';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An array relationship */
  referrals: Array<DbReferral>;
  /** An aggregate relationship */
  referralsAggregate: DbReferralAggregate;
};

/** columns and relationships of "enums.zero_commission_reason" */
export type EnumsZeroCommissionReasonReferralsArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

/** columns and relationships of "enums.zero_commission_reason" */
export type EnumsZeroCommissionReasonReferralsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

/** Boolean expression to filter rows from the table "enums.zero_commission_reason". All fields are combined with a logical 'AND'. */
export type EnumsZeroCommissionReasonBoolExp = {
  _and?: InputMaybe<Array<EnumsZeroCommissionReasonBoolExp>>;
  _not?: InputMaybe<EnumsZeroCommissionReasonBoolExp>;
  _or?: InputMaybe<Array<EnumsZeroCommissionReasonBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  referrals?: InputMaybe<DbReferralBoolExp>;
  referralsAggregate?: InputMaybe<DbReferralAggregateBoolExp>;
};

export type ZeroCommissionReasonEnum =
  | 'DUPLICATE'
  | 'FARMING'
  | 'HQ_PROMOTION'
  | 'OTHERS'
  | 'OUTSIDE_LICENSE_AREA'
  | 'RECOMMENDATION';

/** Boolean expression to compare columns of type "EnumsZeroCommissionReasonEnum". All fields are combined with logical 'AND'. */
export type EnumsZeroCommissionReasonEnumComparisonExp = {
  _eq?: InputMaybe<ZeroCommissionReasonEnum>;
  _in?: InputMaybe<Array<ZeroCommissionReasonEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<ZeroCommissionReasonEnum>;
  _nin?: InputMaybe<Array<ZeroCommissionReasonEnum>>;
};

/** Ordering options when selecting data from "enums.zero_commission_reason". */
export type EnumsZeroCommissionReasonOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  referralsAggregate?: InputMaybe<DbReferralAggregateOrderBy>;
};

/** select columns of table "enums.zero_commission_reason" */
export type EnumsZeroCommissionReasonSelectColumn =
  /** column name */
  | 'description'
  /** column name */
  | 'name';

/** Streaming cursor of the table "enums_zero_commission_reason" */
export type EnumsZeroCommissionReasonStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EnumsZeroCommissionReasonStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumsZeroCommissionReasonStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8ComparisonExp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type FloatComparisonExp = {
  _eq?: InputMaybe<Scalars['Float']>;
  _gt?: InputMaybe<Scalars['Float']>;
  _gte?: InputMaybe<Scalars['Float']>;
  _in?: InputMaybe<Array<Scalars['Float']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Float']>;
  _lte?: InputMaybe<Scalars['Float']>;
  _neq?: InputMaybe<Scalars['Float']>;
  _nin?: InputMaybe<Array<Scalars['Float']>>;
};

export type GeographyCastExp = {
  geometry?: InputMaybe<GeometryComparisonExp>;
};

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type GeographyComparisonExp = {
  _cast?: InputMaybe<GeographyCastExp>;
  _eq?: InputMaybe<Scalars['geography']>;
  _gt?: InputMaybe<Scalars['geography']>;
  _gte?: InputMaybe<Scalars['geography']>;
  _in?: InputMaybe<Array<Scalars['geography']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['geography']>;
  _lte?: InputMaybe<Scalars['geography']>;
  _neq?: InputMaybe<Scalars['geography']>;
  _nin?: InputMaybe<Array<Scalars['geography']>>;
  /** is the column within a given distance from the given geography value */
  _stDWithin?: InputMaybe<StDWithinGeographyInput>;
  /** does the column spatially intersect the given geography value */
  _stIntersects?: InputMaybe<Scalars['geography']>;
};

export type GeometryCastExp = {
  geography?: InputMaybe<GeographyComparisonExp>;
};

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type GeometryComparisonExp = {
  _cast?: InputMaybe<GeometryCastExp>;
  _eq?: InputMaybe<Scalars['geometry']>;
  _gt?: InputMaybe<Scalars['geometry']>;
  _gte?: InputMaybe<Scalars['geometry']>;
  _in?: InputMaybe<Array<Scalars['geometry']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['geometry']>;
  _lte?: InputMaybe<Scalars['geometry']>;
  _neq?: InputMaybe<Scalars['geometry']>;
  _nin?: InputMaybe<Array<Scalars['geometry']>>;
  /** is the column within a given 3D distance from the given geometry value */
  _st3dDWithin?: InputMaybe<StDWithinInput>;
  /** does the column spatially intersect the given geometry value in 3D */
  _st3dIntersects?: InputMaybe<Scalars['geometry']>;
  /** does the column contain the given geometry value */
  _stContains?: InputMaybe<Scalars['geometry']>;
  /** does the column cross the given geometry value */
  _stCrosses?: InputMaybe<Scalars['geometry']>;
  /** is the column within a given distance from the given geometry value */
  _stDWithin?: InputMaybe<StDWithinInput>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _stEquals?: InputMaybe<Scalars['geometry']>;
  /** does the column spatially intersect the given geometry value */
  _stIntersects?: InputMaybe<Scalars['geometry']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _stOverlaps?: InputMaybe<Scalars['geometry']>;
  /** does the column have atleast one point in common with the given geometry value */
  _stTouches?: InputMaybe<Scalars['geometry']>;
  /** is the column contained in the given geometry value */
  _stWithin?: InputMaybe<Scalars['geometry']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntArrayComparisonExp = {
  /** is the array contained in the given array value */
  _containedIn?: InputMaybe<Array<Scalars['Int']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['Int']>>;
  _eq?: InputMaybe<Array<Scalars['Int']>>;
  _gt?: InputMaybe<Array<Scalars['Int']>>;
  _gte?: InputMaybe<Array<Scalars['Int']>>;
  _in?: InputMaybe<Array<Array<Scalars['Int']>>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Array<Scalars['Int']>>;
  _lte?: InputMaybe<Array<Scalars['Int']>>;
  _neq?: InputMaybe<Array<Scalars['Int']>>;
  _nin?: InputMaybe<Array<Array<Scalars['Int']>>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type JsonbCastExp = {
  String?: InputMaybe<StringComparisonExp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  _cast?: InputMaybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _containedIn?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _hasKey?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _hasKeysAll?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _hasKeysAny?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

export type KeycloakUser = {
  __typename?: 'KeycloakUser';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  ldapId?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export type OrderBy =
  /** in ascending order, nulls last */
  | 'ASC'
  /** in ascending order, nulls first */
  | 'ASC_NULLS_FIRST'
  /** in ascending order, nulls last */
  | 'ASC_NULLS_LAST'
  /** in descending order, nulls first */
  | 'DESC'
  /** in descending order, nulls first */
  | 'DESC_NULLS_FIRST'
  /** in descending order, nulls last */
  | 'DESC_NULLS_LAST';

export type PropertyEngineActivityAgent = {
  __typename?: 'PropertyEngineActivityAgent';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type PropertyEngineActivityAppointment = {
  __typename?: 'PropertyEngineActivityAppointment';
  comment?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  endDateTime?: Maybe<Scalars['PropertyEngineDateTime']>;
  feedback?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  startDateTime?: Maybe<Scalars['PropertyEngineDateTime']>;
  subject?: Maybe<Scalars['String']>;
  type?: Maybe<PropertyEngineActivityAppointmentType>;
};

export type PropertyEngineActivityAppointmentType =
  | 'HANDOVER'
  | 'LISTING_PRESENTATION'
  | 'MISCELLANEOUS'
  | 'NOTARY'
  | 'OPEN_HOUSE'
  | 'PRICE_MODIFICATION'
  | 'PRICE_REDUCTION'
  /**  inactive types */
  | 'SEATRIAL'
  | 'VALUATION'
  | 'VIEWING';

export type PropertyEngineActivityContact = {
  __typename?: 'PropertyEngineActivityContact';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type PropertyEngineActivityDocument = {
  __typename?: 'PropertyEngineActivityDocument';
  comment?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type: PropertyEngineActivityDocumentType;
};

export type PropertyEngineActivityDocumentType = 'EXPOSE' | 'LETTER' | 'VIEWING_REPORT';

export type PropertyEngineActivityEmail = {
  __typename?: 'PropertyEngineActivityEmail';
  bcc?: Maybe<Array<Maybe<Scalars['String']>>>;
  body?: Maybe<Scalars['String']>;
  cc?: Maybe<Array<Maybe<Scalars['String']>>>;
  direction?: Maybe<PropertyEngineActivityEmailDirection>;
  from?: Maybe<Scalars['String']>;
  priority?: Maybe<PropertyEngineActivityPriority>;
  status?: Maybe<PropertyEngineActivityEmailStatus>;
  subject?: Maybe<Scalars['String']>;
  to?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<PropertyEngineActivityEmailType>;
};

export type PropertyEngineActivityEmailDirection = 'INCOMING' | 'OUTGOING';

export type PropertyEngineActivityEmailStatus =
  | 'FAILED'
  | 'PENDING'
  | 'PERMANENTLY_FAILED'
  | 'PROCESSED'
  | 'RECEIVED'
  | 'SENT';

export type PropertyEngineActivityEmailType = 'EXPOSE_SENT' | 'LETTER' | 'OTHERS' | 'VIEWING_REPORT';

/**  --------------------------------------------------------------------------------------------------------------------- */
export type PropertyEngineActivityOrderByInput = {
  orderByDate?: InputMaybe<PropertyEngineOrderBy>;
  orderByStatus?: InputMaybe<PropertyEngineOrderBy>;
  orderByType?: InputMaybe<PropertyEngineOrderBy>;
};

export type PropertyEngineActivityPriority = 'HIGH' | 'LOW' | 'MEDIUM';

export type PropertyEngineActivityProperty = {
  __typename?: 'PropertyEngineActivityProperty';
  key?: Maybe<Scalars['Int']>;
  listingType?: Maybe<PropertyEngineListingType>;
  ownerIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  utag?: Maybe<Scalars['String']>;
};

export type PropertyEngineActivityResponse = {
  __typename?: 'PropertyEngineActivityResponse';
  activityCategory?: Maybe<PropertyEngineActivityTypeEnum>;
  activityStatus?: Maybe<PropertyEngineActivityStatus>;
  activityType?: Maybe<PropertyEngineActivityTypeEnum>;
  agent?: Maybe<PropertyEngineActivityAgent>;
  appointment?: Maybe<PropertyEngineActivityAppointment>;
  createdAt?: Maybe<Scalars['PropertyEngineDateTime']>;
  document?: Maybe<PropertyEngineActivityDocument>;
  email?: Maybe<PropertyEngineActivityEmail>;
  id: Scalars['String'];
  linkedContacts?: Maybe<Array<Maybe<PropertyEngineActivityContact>>>;
  linkedProperties?: Maybe<Array<Maybe<PropertyEngineActivityProperty>>>;
  linkedPropertiesUtags?: Maybe<Array<Maybe<Scalars['String']>>>;
  task?: Maybe<PropertyEngineActivityTask>;
  updatedAt?: Maybe<Scalars['PropertyEngineDateTime']>;
};

export type PropertyEngineActivityResponseDetails = {
  __typename?: 'PropertyEngineActivityResponseDetails';
  activities?: Maybe<Array<Maybe<PropertyEngineActivityResponse>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PropertyEngineActivityStatus = 'CANCELLED' | 'COMPLETED' | 'OPEN';

export type PropertyEngineActivityTask = {
  __typename?: 'PropertyEngineActivityTask';
  comment?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  dueDateTime?: Maybe<Scalars['PropertyEngineDateTime']>;
  priority?: Maybe<PropertyEngineActivityPriority>;
  title?: Maybe<Scalars['String']>;
  type: PropertyEngineActivityTaskType;
};

export type PropertyEngineActivityTaskType =
  | 'ACQUISITION'
  | 'ACTIVE_BUYER_CONTACT'
  | 'ACTIVE_SELLER_CONTACT'
  | 'ACTIVITY_REPORT'
  | 'FOLLOW_UP'
  | 'INCOMING_BUYER_SELLER_CONTACT'
  | 'INCOMING_CALL'
  /**  inactive types */
  | 'MAKE_AN_APPOINTMENT'
  | 'MARKET_REPORT'
  | 'MISCELLANEOUS'
  | 'NEWSLETTER'
  | 'NOTICE'
  | 'OFFER'
  | 'OUTGOING_CALL'
  | 'OWNERSHIP_ANNIVERSARY'
  | 'PLACE_ADVERTISEMENT'
  | 'PRICE_REDUCTION'
  | 'TODO';

export type PropertyEngineActivityTypeEnum = 'APPOINTMENT' | 'DOCUMENT' | 'EMAIL' | 'SHARED' | 'TASK';

export type PropertyEngineAddress = {
  __typename?: 'PropertyEngineAddress';
  apartmentNumber?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<PropertyEngineCountryCode>;
  coverLetterAddress?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  postalCode?: Maybe<Scalars['String']>;
  propertyName?: Maybe<Scalars['String']>;
  secondAddressLine?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  thirdAddressLine?: Maybe<Scalars['String']>;
  towerName?: Maybe<Scalars['String']>;
};

export type PropertyEngineAgentDetail = {
  __typename?: 'PropertyEngineAgentDetail';
  agentId?: Maybe<Scalars['PropertyEngineUUID']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  image?: Maybe<PropertyEngineAgentImageDetail>;
  lastName?: Maybe<Scalars['String']>;
  mobilePhoneNumber?: Maybe<Scalars['String']>;
  officePhoneNumber?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type PropertyEngineAgentImageDetail = {
  __typename?: 'PropertyEngineAgentImageDetail';
  fullPath?: Maybe<Scalars['String']>;
  gcpFileName?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<PropertyEngineAgentImageType>;
};

export type PropertyEngineAgentImageType = 'MANAGER' | 'SHOP';

export type PropertyEngineAiAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  legacyAreaName?: InputMaybe<Scalars['String']>;
  legacyDistrictName?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['Float']>;
  postalCode?: InputMaybe<Scalars['String']>;
  streetName?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
};

export type PropertyEngineAiAnswerType = 'BOOLEAN' | 'INTEGER' | 'STRING';

export type PropertyEngineAiAssetInput = {
  atticType?: InputMaybe<Array<PropertyEngineAtticType>>;
  balconyPresent?: InputMaybe<Scalars['Boolean']>;
  basementPresent?: InputMaybe<Scalars['Boolean']>;
  basementType?: InputMaybe<Array<PropertyEngineBasementType>>;
  condition?: InputMaybe<PropertyEngineAssetCondition>;
  elevatorType?: InputMaybe<Array<PropertyEngineElevatorType>>;
  fireplacePresent?: InputMaybe<Scalars['Boolean']>;
  flooringType?: InputMaybe<Array<PropertyEngineFlooringType>>;
  gardenPresent?: InputMaybe<Scalars['Boolean']>;
  hasUndergroundGarage?: InputMaybe<Scalars['Boolean']>;
  heatingType?: InputMaybe<Array<PropertyEngineHeatingType>>;
  indoorFacilities?: InputMaybe<Array<PropertyEngineInteriorOption>>;
  lastModernizationRestaurationYear?: InputMaybe<Scalars['Int']>;
  laundryRoom?: InputMaybe<PropertyEngineLaundryRoomLocation>;
  numberGarages?: InputMaybe<Scalars['Int']>;
  numberOfBathrooms?: InputMaybe<Scalars['Int']>;
  numberOfBedrooms?: InputMaybe<Scalars['Int']>;
  numberOfMasterBathrooms?: InputMaybe<Scalars['Int']>;
  numberOfRooms?: InputMaybe<Scalars['Float']>;
  orientationsOfBalcony?: InputMaybe<Array<PropertyEngineOrientation>>;
  patioPresent?: InputMaybe<Scalars['Boolean']>;
  plotSurface?: InputMaybe<Scalars['Float']>;
  priceGarage?: InputMaybe<Scalars['Float']>;
  securityFeatures?: InputMaybe<Array<PropertyEngineSecurityOption>>;
  surfaceLivingArea?: InputMaybe<Scalars['Float']>;
  swimmingPoolPresent?: InputMaybe<Scalars['Boolean']>;
  terracePresent?: InputMaybe<Scalars['Boolean']>;
  terraceSize?: InputMaybe<Scalars['Float']>;
  totalSurface?: InputMaybe<Scalars['Float']>;
  type: PropertyEngineAssetType;
  views?: InputMaybe<Array<PropertyEngineViewOption>>;
  waterfrontLocations?: InputMaybe<Array<PropertyEngineWaterLocationType>>;
  wellnessEquipment?: InputMaybe<Array<PropertyEngineWellnessOption>>;
  yearOfConstruction?: InputMaybe<Scalars['Int']>;
};

export type PropertyEngineAiFeedbackInput = {
  agentId?: InputMaybe<Scalars['PropertyEngineUUID']>;
  answer: Scalars['String'];
  answerType: PropertyEngineAiAnswerType;
  question: Scalars['String'];
  requestId?: InputMaybe<Scalars['PropertyEngineUUID']>;
  responseId?: InputMaybe<Scalars['PropertyEngineUUID']>;
};

export type PropertyEngineAiGenerateMarketingTextInput = {
  listing: PropertyEngineAiListingInput;
  maxOutputTextLength?: InputMaybe<Scalars['Int']>;
  targetLanguage: Scalars['PropertyEngineLocaleScalar'];
  toneOfVoice?: InputMaybe<PropertyEngineAiToneOfVoice>;
  type: PropertyEngineAiMarketingTextType;
};

export type PropertyEngineAiListingInput = {
  address?: InputMaybe<PropertyEngineAiAddressInput>;
  askingPrice?: InputMaybe<Scalars['Float']>;
  asset?: InputMaybe<PropertyEngineAiAssetInput>;
  currency?: InputMaybe<Scalars['PropertyEngineCurrency']>;
  marketingDetails?: InputMaybe<Array<PropertyEngineAiMarketingDetailInput>>;
  measurementSystem?: InputMaybe<PropertyEngineMeasurementSystem>;
  utag: Scalars['String'];
};

export type PropertyEngineAiMarketingDetailInput = {
  assetDescription?: InputMaybe<Scalars['String']>;
  commissionText?: InputMaybe<Scalars['String']>;
  language: Scalars['PropertyEngineLocaleScalar'];
  locationDescription?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type PropertyEngineAiMarketingTextDetail = {
  __typename?: 'PropertyEngineAiMarketingTextDetail';
  body: Scalars['String'];
  requestId: Scalars['PropertyEngineUUID'];
  responseId: Scalars['PropertyEngineUUID'];
  targetLanguage: Scalars['PropertyEngineLocaleScalar'];
  type: PropertyEngineAiMarketingTextType;
};

export type PropertyEngineAiMarketingTextType =
  | 'PROPERTY_EXPOSE_DESCRIPTION'
  | 'PROPERTY_EXPOSE_HEADER'
  | 'PROPERTY_EXPOSE_LOCATION_DESCRIPTION';

export type PropertyEngineAiTextDetail = {
  __typename?: 'PropertyEngineAiTextDetail';
  body: Scalars['String'];
  requestId: Scalars['PropertyEngineUUID'];
  responseId: Scalars['PropertyEngineUUID'];
  targetLanguage: Scalars['PropertyEngineLocaleScalar'];
};

export type PropertyEngineAiToneOfVoice = 'CREATIVE' | 'DEFAULT' | 'FACTUAL' | 'FRIENDLY' | 'PROFESSIONAL';

export type PropertyEngineAiTranslateTextInput = {
  body: Scalars['String'];
  maxLength?: InputMaybe<Scalars['Int']>;
  sourceLanguage: Scalars['PropertyEngineLocaleScalar'];
  targetLanguage: Scalars['PropertyEngineLocaleScalar'];
};

export type PropertyEngineAirConditionType =
  /** Ceiling cooling */
  | 'CEILING_COOLING'
  /** Central air-conditioned */
  | 'CENTRAL_AIR_CONDITIONED'
  /** Ducted air-conditioned */
  | 'DUCTED_AIR_CONDITIONED'
  /** Fully air-conditioned */
  | 'FULLY_AIR_CONDITIONED'
  /** Partly air-conditioned */
  | 'PARTLY_AIR_CONDITIONED'
  /** Split air-conditioned */
  | 'SPLIT_AIR_CONDITIONED'
  /** Window-type air-conditioned */
  | 'WINDOW_TYPE_AIR_CONDITIONED';

export type PropertyEngineApartment = {
  __typename?: 'PropertyEngineApartment';
  airConditionTypes?: Maybe<Array<PropertyEngineAirConditionType>>;
  amountOfBalconies?: Maybe<Scalars['Int']>;
  asBuiltPlan?: Maybe<Scalars['Boolean']>;
  atticTypes?: Maybe<Array<PropertyEngineAtticType>>;
  basementTypes?: Maybe<Array<PropertyEngineBasementType>>;
  buildingInsuranceValue?: Maybe<Scalars['Float']>;
  buildingRestrictions?: Maybe<Array<PropertyEngineBuildingRestriction>>;
  buildingRightComments?: Maybe<Scalars['String']>;
  buildingRightYear?: Maybe<Scalars['Int']>;
  certificationFuelTanks?: Maybe<PropertyEngineThreeAnswers>;
  co2Emission?: Maybe<Scalars['Float']>;
  co2EmissionRating?: Maybe<PropertyEngineCo2EmissionRating>;
  condition?: Maybe<PropertyEngineAssetCondition>;
  conditionBelgium?: Maybe<PropertyEngineConditionBelgium>;
  doubleGlazing?: Maybe<Scalars['Boolean']>;
  elevatorTypes?: Maybe<Array<PropertyEngineElevatorType>>;
  energyCertificateDate?: Maybe<Scalars['PropertyEngineDate']>;
  energyCertificateExpirationDate?: Maybe<Scalars['PropertyEngineDate']>;
  energyCertificateNumberFlanders?: Maybe<Scalars['String']>;
  energyCertificateType?: Maybe<PropertyEngineQcEnergyCertificateType>;
  energyConsumption?: Maybe<Scalars['Float']>;
  energyEfficiencyRating?: Maybe<PropertyEngineEnergyEfficiencyRating>;
  energyLabelPt?: Maybe<PropertyEngineEnergyLabelPt>;
  energyRatingSummary?: Maybe<Scalars['String']>;
  energyRatingSummaryLocked: Scalars['Boolean'];
  epcCode?: Maybe<Scalars['String']>;
  finalEnergyConsumption?: Maybe<Scalars['Float']>;
  finalEnergyDemand?: Maybe<Scalars['Float']>;
  finalEnergyEfficiencyFactorFgee?: Maybe<Scalars['Float']>;
  fireInsuranceValue?: Maybe<Scalars['Float']>;
  floodZones?: Maybe<PropertyEngineFloodZones>;
  floorDesc?: Maybe<Scalars['String']>;
  flooringTypes?: Maybe<Array<PropertyEngineFlooringType>>;
  gardenSurface?: Maybe<Scalars['Float']>;
  hasBalcony?: Maybe<Scalars['Boolean']>;
  hasBasement?: Maybe<Scalars['Boolean']>;
  hasEnergyCertificate?: Maybe<Scalars['Boolean']>;
  hasFireplace?: Maybe<Scalars['Boolean']>;
  hasGarden?: Maybe<Scalars['Boolean']>;
  hasPatio?: Maybe<Scalars['Boolean']>;
  hasRooftopTerrace?: Maybe<Scalars['Boolean']>;
  hasSecondarySuite?: Maybe<Scalars['Boolean']>;
  hasServantsQuarter?: Maybe<Scalars['Boolean']>;
  hasSwimmingPool?: Maybe<Scalars['Boolean']>;
  hasTerrace?: Maybe<Scalars['Boolean']>;
  hasUndergroundGarage?: Maybe<Scalars['Boolean']>;
  heatProtectionClass?: Maybe<PropertyEngineEnergyEfficiencyRating>;
  heatProtectionValue?: Maybe<Scalars['Float']>;
  heatingEnergyDemandHwb?: Maybe<Scalars['Float']>;
  heatingTransferType?: Maybe<Array<Maybe<PropertyEngineHeatingTransferType>>>;
  heatingTypes?: Maybe<Array<PropertyEngineHeatingType>>;
  hotWaterIncludedInEnergyConsumption?: Maybe<Scalars['Boolean']>;
  housingBenefit?: Maybe<Scalars['Float']>;
  indoorFacilities?: Maybe<Array<PropertyEngineInteriorOption>>;
  ipeKwh?: Maybe<Scalars['Float']>;
  land?: Maybe<PropertyEngineLand>;
  lastModernizationRestaurationYear?: Maybe<Scalars['Int']>;
  latestLandUseDesignation?: Maybe<PropertyEngineUrbanInformationLatestLandUseDesignation>;
  laundryRoom?: Maybe<PropertyEngineLaundryRoomLocation>;
  laundryRoomUsageType?: Maybe<PropertyEngineUsageType>;
  leasehold?: Maybe<Scalars['Boolean']>;
  leaseholdComments?: Maybe<Scalars['String']>;
  leaseholdYear?: Maybe<Scalars['Int']>;
  monthlyRentPerGarage?: Maybe<Scalars['Float']>;
  monthlyRentPerParkingBay?: Maybe<Scalars['Float']>;
  numberOfBathrooms?: Maybe<Scalars['Int']>;
  numberOfBedrooms?: Maybe<Scalars['Int']>;
  numberOfFacades?: Maybe<Scalars['Float']>;
  numberOfGarages?: Maybe<Scalars['Int']>;
  numberOfMasterBathrooms?: Maybe<Scalars['Int']>;
  numberOfParkingBays?: Maybe<Scalars['Int']>;
  numberOfRooms?: Maybe<Scalars['Float']>;
  numberOfStories?: Maybe<Scalars['Float']>;
  orientationsOfBalcony?: Maybe<Array<PropertyEngineOrientation>>;
  outdoorFacilities?: Maybe<Array<PropertyEngineOutsideOption>>;
  ownership?: Maybe<PropertyEngineAssetOwnership>;
  preemptiveRightOption?: Maybe<PropertyEngineUrbanInformationPreemptiveRightOption>;
  pricePerGarage?: Maybe<Scalars['Float']>;
  pricePerParkingBay?: Maybe<Scalars['Float']>;
  proceedingsPlanningRegulationsBreach?: Maybe<PropertyEngineUrbanInformationProceedingsPlanningRegulationsBreach>;
  rooftopTerraceSurface?: Maybe<Scalars['Float']>;
  securityFeatures?: Maybe<Array<PropertyEngineSecurityOption>>;
  subType?: Maybe<PropertyEngineAssetSubType>;
  subdivisionPermit?: Maybe<PropertyEngineUrbanInformationSubdivisionPermit>;
  surfaceLivingArea?: Maybe<Scalars['Float']>;
  swimmingPoolTypes?: Maybe<Array<PropertyEngineSwimmingPoolType>>;
  tenureConditions?: Maybe<Array<Maybe<PropertyEngineTenureCondition>>>;
  terraceSurface?: Maybe<Scalars['Float']>;
  totalSurface?: Maybe<Scalars['Float']>;
  type?: Maybe<PropertyEngineAssetType>;
  urbanInformationPlanningPermissionObtained?: Maybe<PropertyEngineUrbanInformationPlanningPermissionType>;
  usableSurface?: Maybe<Scalars['Float']>;
  variation?: Maybe<PropertyEngineAssetVariation>;
  views?: Maybe<Array<PropertyEngineViewOption>>;
  waterfrontLocations?: Maybe<Array<PropertyEngineWaterLocationType>>;
  wellnessEquipment?: Maybe<Array<PropertyEngineWellnessOption>>;
  widthOfFacadeToStreet?: Maybe<Scalars['Float']>;
  yearOfConstruction?: Maybe<Scalars['Int']>;
};

export type PropertyEngineAsset = PropertyEngineApartment | PropertyEngineHouse | PropertyEngineLand;

export type PropertyEngineAssetCondition =
  /** Average */
  | 'AVERAGE'
  /** Good */
  | 'GOOD'
  /** Needs rehabilitation */
  | 'NEEDS_REHABILITATION'
  /** Needs renovation */
  | 'NEEDS_RENOVATION'
  /** Others */
  | 'OTHERS'
  /** Partly rehabilitated */
  | 'PARTLY_REHABILITATED'
  /** Rehabilitation done */
  | 'REHABILITATION_DONE'
  /** Top */
  | 'TOP'
  /** Undefined */
  | 'UNDEFINED'
  /** Very good */
  | 'VERY_GOOD';

export type PropertyEngineAssetOwnership =
  /** Freehold */
  | 'FREEHOLD'
  /** Leasehold */
  | 'LEASEHOLD'
  /** Asset ownership is undefined */
  | 'UNDEFINED';

export type PropertyEngineAssetSubType =
  /** Agriculture land */
  | 'AGRICULTURE_LAND'
  /** Attic apartment */
  | 'ATTIC_APARTMENT'
  /** Building land */
  | 'BUILDING_LAND'
  /** Bungalow house type */
  | 'BUNGALOW'
  /** Country house type */
  | 'COUNTRY_HOUSE'
  /** Detached house type */
  | 'DETACHED_HOUSE'
  /** Developed land */
  | 'DEVELOPED_LAND'
  /** Duplex apartment type */
  | 'DUPLEX'
  /** Flat apartment type */
  | 'FLAT'
  /** Forest */
  | 'FOREST'
  /** Ground floor apartment */
  | 'GROUND_FLOOR_APARTMENT'
  /** Hunting ground */
  | 'HUNTING_GROUND'
  /** Investment house type */
  | 'INVESTMENT'
  /** Lakeside land */
  | 'LAKESIDE_LAND'
  /** Land in building lease */
  | 'LAND_IN_BUILDING_LEASE'
  /** Loft apartment type */
  | 'LOFT'
  /** Mooring */
  | 'MOORING'
  /** Multi-family house type */
  | 'MULTI_FAMILY_HOUSE'
  /** Old building apartment */
  | 'OLD_BUILDING_APARTMENT'
  /** Other house types */
  | 'OTHER'
  /** Paddock */
  | 'PADDOCK'
  /** Parking */
  | 'PARKING'
  /** Pasture */
  | 'PASTURE'
  /** Penthouse */
  | 'PENTHOUSE'
  /** Row house type */
  | 'ROW_HOUSE'
  /** Seaside land */
  | 'SEASIDE_LAND'
  /** Semi-detached house type */
  | 'SEMI_DETACHED_HOUSE'
  /** Special property */
  | 'SPECIAL_PROPERTY'
  /** Studio apartment type */
  | 'STUDIO'
  /** Villa house type */
  | 'VILLA'
  /** Wine land */
  | 'WINE_LAND';

export type PropertyEngineAssetType =
  /** Apartment asset */
  | 'APARTMENT'
  /** House asset */
  | 'HOUSE'
  /** Land asset */
  | 'LAND';

export type PropertyEngineAssetVariation =
  /** Castle variation */
  | 'CASTLE'
  /** Chalet variation */
  | 'CHALET'
  /** Cottage variation */
  | 'COTTAGE'
  /** End-of-row house variation */
  | 'END_OF_ROW_HOUSE'
  /** Farm variation */
  | 'FARM'
  /** Farmhouse variation */
  | 'FARMHOUSE'
  /** Finca variation */
  | 'FINCA'
  /** Houseboat variation */
  | 'HOUSEBOAT'
  /** Manor variation */
  | 'MANOR'
  /** Mid-row house variation */
  | 'MID_ROW_HOUSE';

export type PropertyEngineAtticType =
  /** Complete attic */
  | 'COMPLETE_ATTIC'
  /** Construction reserve */
  | 'CONSTRUCTION_RESERVE'
  /** Full attic */
  | 'FULL_ATTIC'
  /** Part attic */
  | 'PART_ATTIC';

export type PropertyEngineBasementType =
  /** Full basement */
  | 'FULL'
  /** Part basement */
  | 'PART';

export type PropertyEngineBoundingBox = {
  lowerLeftX: Scalars['Float'];
  lowerLeftY: Scalars['Float'];
  upperRightX: Scalars['Float'];
  upperRightY: Scalars['Float'];
};

/** Copy of BoundingBox but as output type. Both types should be kept in sync. */
export type PropertyEngineBoundingBoxDetail = {
  __typename?: 'PropertyEngineBoundingBoxDetail';
  lowerLeftX: Scalars['Float'];
  lowerLeftY: Scalars['Float'];
  upperRightX: Scalars['Float'];
  upperRightY: Scalars['Float'];
};

export type PropertyEngineBuildingRestriction =
  /** Ensemble monument */
  | 'ENSEMBLE_MONUMENT'
  /** Front of building monument */
  | 'FRONT_OF_BUILDING_MONUMENT'
  /** Single monument building */
  | 'SINGLE_MONUMENT_BUILDING';

export type PropertyEngineCdfDefinition = {
  __typename?: 'PropertyEngineCdfDefinition';
  go3Key?: Maybe<Scalars['Int']>;
  id: Scalars['PropertyEngineUUID'];
  label: Scalars['String'];
};

export type PropertyEngineClosing = {
  __typename?: 'PropertyEngineClosing';
  closingComments?: Maybe<Scalars['String']>;
  commissionAmountPaid?: Maybe<Scalars['Float']>;
  commissionNet?: Maybe<Scalars['Float']>;
  commissionNetRentFactor?: Maybe<Scalars['Float']>;
  commissionPaidAt?: Maybe<Scalars['PropertyEngineDate']>;
  commissionShareAgents?: Maybe<Array<Maybe<PropertyEngineClosingAgent>>>;
  contractClosingDate?: Maybe<Scalars['PropertyEngineDate']>;
  endOfRental?: Maybe<Scalars['PropertyEngineDate']>;
  graduatedRent?: Maybe<Scalars['Float']>;
  handoverCondition?: Maybe<Scalars['String']>;
  handoverDate?: Maybe<Scalars['PropertyEngineDate']>;
  id: Scalars['PropertyEngineIdentifier'];
  letByShop?: Maybe<Scalars['PropertyEngineUUID']>;
  monthlyNetRent?: Maybe<Scalars['Float']>;
  monthlyTotalRent?: Maybe<Scalars['Float']>;
  newOwnersContactIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  newTenantsContactIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  noticePeriodMonths?: Maybe<Scalars['Float']>;
  premium?: Maybe<Scalars['Float']>;
  previousOwnersContactIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  referredByAgent?: Maybe<Scalars['PropertyEngineUUID']>;
  soldByShop?: Maybe<Scalars['PropertyEngineUUID']>;
  soldPrice?: Maybe<Scalars['Int']>;
  startOfRental?: Maybe<Scalars['PropertyEngineDate']>;
  subCommissionNet?: Maybe<Scalars['Float']>;
  subCommissionReferral?: Maybe<Scalars['Float']>;
  thirdPartyUsageContactId?: Maybe<Scalars['String']>;
  totalCommissionNet?: Maybe<Scalars['Float']>;
  totalCommissionPercent?: Maybe<Scalars['Float']>;
  totalDeposit?: Maybe<Scalars['Float']>;
  utilization?: Maybe<PropertyEngineUtilizationType>;
};

export type PropertyEngineClosingAgent = {
  __typename?: 'PropertyEngineClosingAgent';
  agentId: Scalars['String'];
  sharePercent: Scalars['Float'];
  type: PropertyEngineClosingAgentType;
};

export type PropertyEngineClosingAgentType =
  /** Closing's agent type is closing. */
  | 'CLOSING'
  /** Closing's agent type is listing. */
  | 'LISTING';

export type PropertyEngineCo2EmissionRating =
  /** Co2 emission rating is A */
  | 'A'
  /** Co2 emission rating is B */
  | 'B'
  /** Co2 emission rating is C */
  | 'C'
  /** Co2 emission rating is D */
  | 'D'
  /** Co2 emission rating is E */
  | 'E'
  /** Co2 emission rating is F */
  | 'F'
  /** Co2 emission rating is G */
  | 'G';

export type PropertyEngineColumnPinning = {
  __typename?: 'PropertyEngineColumnPinning';
  left?: Maybe<Array<Scalars['String']>>;
  right?: Maybe<Array<Scalars['String']>>;
};

export type PropertyEngineColumnPinningInput = {
  left?: InputMaybe<Array<Scalars['String']>>;
  right?: InputMaybe<Array<Scalars['String']>>;
};

export type PropertyEngineColumnSorting = {
  __typename?: 'PropertyEngineColumnSorting';
  desc?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
};

export type PropertyEngineColumnSortingInput = {
  desc?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};

export type PropertyEngineConditionBelgium =
  /** First time use */
  | 'FIRST_TIME_USE'
  /** Good (little works necessary) */
  | 'GOOD'
  /** Needs reconditioning */
  | 'NEEDS_RECONDITIONING'
  /** Needs renovation (do-it-yourself) */
  | 'NEEDS_RENOVATION_DO_IT_YOURSELF'
  /** Needs renovation (professional) */
  | 'NEEDS_RENOVATION_PROFESSIONAL'
  /** Planned */
  | 'PLANNED'
  /** Top */
  | 'TOP'
  /** To demolish */
  | 'TO_DEMOLISH';

export type PropertyEngineContactBasicDataResponse = {
  __typename?: 'PropertyEngineContactBasicDataResponse';
  emailAddresses?: Maybe<Array<PropertyEngineContactDetailsEmailAddress>>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type PropertyEngineContactDetailsAddress = {
  __typename?: 'PropertyEngineContactDetailsAddress';
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  postBox?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  preferred: Scalars['Boolean'];
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
};

export type PropertyEngineContactDetailsEmailAddress = {
  __typename?: 'PropertyEngineContactDetailsEmailAddress';
  email?: Maybe<Scalars['String']>;
  preferred?: Maybe<Scalars['Boolean']>;
};

export type PropertyEngineContactDetailsPhoneNumber = {
  __typename?: 'PropertyEngineContactDetailsPhoneNumber';
  number?: Maybe<Scalars['String']>;
  preferred?: Maybe<Scalars['Boolean']>;
};

export type PropertyEngineContactDetailsResult = {
  __typename?: 'PropertyEngineContactDetailsResult';
  addresses?: Maybe<Array<PropertyEngineContactDetailsAddress>>;
  contactKey?: Maybe<Scalars['Int']>;
  emailAddresses?: Maybe<Array<PropertyEngineContactDetailsEmailAddress>>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastActivityDate?: Maybe<Scalars['PropertyEngineDate']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<Array<PropertyEngineContactDetailsPhoneNumber>>;
  status?: Maybe<Scalars['String']>;
  utag?: Maybe<Scalars['String']>;
};

export type PropertyEngineContactWithId = {
  __typename?: 'PropertyEngineContactWithId';
  id?: Maybe<Scalars['String']>;
};

export type PropertyEngineCountryCode =
  /** Andorra */
  | 'AD'
  /** United Arab Emirates */
  | 'AE'
  /** Afghanistan */
  | 'AF'
  /** Antigua and Barbuda */
  | 'AG'
  /** Anguilla */
  | 'AI'
  /** Albania */
  | 'AL'
  /** Armenia */
  | 'AM'
  /** Netherlands Antilles */
  | 'AN'
  /** Angola */
  | 'AO'
  /** Antarctica */
  | 'AQ'
  /** Argentina */
  | 'AR'
  /** American Samoa */
  | 'AS'
  /** Austria */
  | 'AT'
  /** Australia */
  | 'AU'
  /** Aruba */
  | 'AW'
  /** Åland Islands */
  | 'AX'
  /** Azerbaijan */
  | 'AZ'
  /** Bosnia and Herzegovina */
  | 'BA'
  /** Barbados */
  | 'BB'
  /** Bangladesh */
  | 'BD'
  /** Belgium */
  | 'BE'
  /** Burkina Faso */
  | 'BF'
  /** Bulgaria */
  | 'BG'
  /** Bahrain */
  | 'BH'
  /** Burundi */
  | 'BI'
  /** Benin */
  | 'BJ'
  /** Saint Barthélemy */
  | 'BL'
  /** Bermuda */
  | 'BM'
  /** Brunei Darussalam */
  | 'BN'
  /** Plurinational State of Bolivia */
  | 'BO'
  /** Bonaire, Sint Eustatius and Saba */
  | 'BQ'
  /** Brazil */
  | 'BR'
  /** Bahamas */
  | 'BS'
  /** Bhutan */
  | 'BT'
  /** Bouvet Island */
  | 'BV'
  /** Botswana */
  | 'BW'
  /** Belarus */
  | 'BY'
  /** Belize */
  | 'BZ'
  /** Canada */
  | 'CA'
  /** Cocos Islands */
  | 'CC'
  /** The Democratic Republic of the Congo */
  | 'CD'
  /** Central African Republic */
  | 'CF'
  /** Congo */
  | 'CG'
  /** Switzerland */
  | 'CH'
  /** Côte d'Ivoire */
  | 'CI'
  /** Cook Islands */
  | 'CK'
  /** Chile */
  | 'CL'
  /** Cameroon */
  | 'CM'
  /** China */
  | 'CN'
  /** Colombia */
  | 'CO'
  /** Costa Rica */
  | 'CR'
  /** Cuba */
  | 'CU'
  /** Cape Verde */
  | 'CV'
  /** Cura/u00E7ao */
  | 'CW'
  /** Christmas Island */
  | 'CX'
  /** Cyprus */
  | 'CY'
  /** Czech Republic */
  | 'CZ'
  /** Germany */
  | 'DE'
  /** Djibouti */
  | 'DJ'
  /** Denmark */
  | 'DK'
  /** Dominica */
  | 'DM'
  /** Dominican Republic */
  | 'DO'
  /** Algeria */
  | 'DZ'
  /** Ecuador */
  | 'EC'
  /** Estonia */
  | 'EE'
  /** Egypt */
  | 'EG'
  /** Western Sahara */
  | 'EH'
  /** Eritrea */
  | 'ER'
  /** Spain */
  | 'ES'
  /** Ethiopia */
  | 'ET'
  /** Finland */
  | 'FI'
  /** Fiji */
  | 'FJ'
  /** Falkland Islands */
  | 'FK'
  /** Federated States of Micronesia */
  | 'FM'
  /** Faroe Islands */
  | 'FO'
  /** France */
  | 'FR'
  /** Gabon */
  | 'GA'
  /** United Kingdom */
  | 'GB'
  /** Grenada */
  | 'GD'
  /** Georgia */
  | 'GE'
  /** French Guiana */
  | 'GF'
  /** Guemsey */
  | 'GG'
  /** Ghana */
  | 'GH'
  /** Gibraltar */
  | 'GI'
  /** Greenland */
  | 'GL'
  /** Gambia */
  | 'GM'
  /** Guinea */
  | 'GN'
  /** Guadeloupe */
  | 'GP'
  /** Equatorial Guinea */
  | 'GQ'
  /** Greece */
  | 'GR'
  /** South Georgia and the South Sandwich Islands */
  | 'GS'
  /** Guatemala */
  | 'GT'
  /** Guam */
  | 'GU'
  /** Guinea-Bissau */
  | 'GW'
  /** Guyana */
  | 'GY'
  /** Hong Kong */
  | 'HK'
  /** Heard Island and McDonald Islands */
  | 'HM'
  /** Honduras */
  | 'HN'
  /** Croatia */
  | 'HR'
  /** Haiti */
  | 'HT'
  /** Hungary */
  | 'HU'
  /** Indonesia */
  | 'ID'
  /** Ireland */
  | 'IE'
  /** Israel */
  | 'IL'
  /** Isle of Man */
  | 'IM'
  /** India */
  | 'IN'
  /** British Indian Ocean Territory */
  | 'IO'
  /** Iraq */
  | 'IQ'
  /** Islamic Republic of Iran */
  | 'IR'
  /** Iceland */
  | 'IS'
  /** Italy */
  | 'IT'
  /** Jersey */
  | 'JE'
  /** Jamaica */
  | 'JM'
  /** Jordan */
  | 'JO'
  /** Japan */
  | 'JP'
  /** Kenya */
  | 'KE'
  /** Kyrgyzstan */
  | 'KG'
  /** Cambodia */
  | 'KH'
  /** Kiribati */
  | 'KI'
  /** Comoros */
  | 'KM'
  /** Saint Kitts and Nevis */
  | 'KN'
  /** Democratic People's Republic of Korea */
  | 'KP'
  /** Republic of Korea */
  | 'KR'
  /** Kuwait */
  | 'KW'
  /** Cayman Islands */
  | 'KY'
  /** Kazakhstan */
  | 'KZ'
  /** Lao People's Democratic Republic */
  | 'LA'
  /** Lebanon */
  | 'LB'
  /** Saint Lucia */
  | 'LC'
  /** Liechtenstein */
  | 'LI'
  /** Sri Lanka */
  | 'LK'
  /** Liberia */
  | 'LR'
  /** Lesotho */
  | 'LS'
  /** Lithuania */
  | 'LT'
  /** Luxembourg */
  | 'LU'
  /** Latvia */
  | 'LV'
  /** Libya */
  | 'LY'
  /** Morocco */
  | 'MA'
  /** Monaco */
  | 'MC'
  /** Republic of Moldova */
  | 'MD'
  /** Montenegro */
  | 'ME'
  /** Saint Martin */
  | 'MF'
  /** Madagascar */
  | 'MG'
  /** Marshall Islands */
  | 'MH'
  /** The former Yugoslav Republic of Macedonia */
  | 'MK'
  /** Mali */
  | 'ML'
  /** Myanmar */
  | 'MM'
  /** Mongolia */
  | 'MN'
  /** Macao */
  | 'MO'
  /** Northern Mariana Islands */
  | 'MP'
  /** Martinique */
  | 'MQ'
  /** Mauritania */
  | 'MR'
  /** Montserrat */
  | 'MS'
  /** Malta */
  | 'MT'
  /** Mauritius */
  | 'MU'
  /** Maldives */
  | 'MV'
  /** Malawi */
  | 'MW'
  /** Mexico */
  | 'MX'
  /** Malaysia */
  | 'MY'
  /** Mozambique */
  | 'MZ'
  /** Namibia */
  | 'NA'
  /** New Caledonia */
  | 'NC'
  /** Niger */
  | 'NE'
  /** Norfolk Island */
  | 'NF'
  /** Nigeria */
  | 'NG'
  /** Nicaragua */
  | 'NI'
  /** Netherlands */
  | 'NL'
  /** Norway */
  | 'NO'
  /** Nepal */
  | 'NP'
  /** Nauru */
  | 'NR'
  /** Niue */
  | 'NU'
  /** New Zealand */
  | 'NZ'
  /** Oman */
  | 'OM'
  /** Panama */
  | 'PA'
  /** Peru */
  | 'PE'
  /** French Polynesia */
  | 'PF'
  /** Papua New Guinea */
  | 'PG'
  /** Philippines */
  | 'PH'
  /** Pakistan */
  | 'PK'
  /** Poland */
  | 'PL'
  /** Saint Pierre and Miquelon */
  | 'PM'
  /** Pitcairn */
  | 'PN'
  /** Puerto Rico */
  | 'PR'
  /** Occupied Palestinian Territory */
  | 'PS'
  /** Portugal */
  | 'PT'
  /** Palau */
  | 'PW'
  /** Paraguay */
  | 'PY'
  /** Qatar */
  | 'QA'
  /** Réunion */
  | 'RE'
  /** Romania */
  | 'RO'
  /** Serbia */
  | 'RS'
  /** Russian Federation */
  | 'RU'
  /** Rwanda */
  | 'RW'
  /** Saudi Arabia */
  | 'SA'
  /** Solomon Islands */
  | 'SB'
  /** Seychelles */
  | 'SC'
  /** Sudan */
  | 'SD'
  /** Sweden */
  | 'SE'
  /** Singapore */
  | 'SG'
  /** Saint Helena, Ascension and Tristan da Cunha */
  | 'SH'
  /** Slovenia */
  | 'SI'
  /** Svalbard and Jan Mayen */
  | 'SJ'
  /** Slovakia */
  | 'SK'
  /** Sierra Leone */
  | 'SL'
  /** San Marino */
  | 'SM'
  /** Senegal */
  | 'SN'
  /** Somalia */
  | 'SO'
  /** Suriname */
  | 'SR'
  /** South Sudan */
  | 'SS'
  /** Sao Tome and Principe */
  | 'ST'
  /** El Salvador */
  | 'SV'
  /** Sint Maarten */
  | 'SX'
  /** Syrian Arab Republic */
  | 'SY'
  /** Swaziland */
  | 'SZ'
  /** Turks and Caicos Islands */
  | 'TC'
  /** Chad */
  | 'TD'
  /** French Southern Territories */
  | 'TF'
  /** Togo */
  | 'TG'
  /** Thailand */
  | 'TH'
  /** Tajikistan */
  | 'TJ'
  /** Tokelau */
  | 'TK'
  /** Timor-Leste */
  | 'TL'
  /** Turkmenistan */
  | 'TM'
  /** Tunisia */
  | 'TN'
  /** Tonga */
  | 'TO'
  /** Turkey */
  | 'TR'
  /** Trinidad and Tobago */
  | 'TT'
  /** Tuvalu */
  | 'TV'
  /** Taiwan, Province of China */
  | 'TW'
  /** United Republic of Tanzania */
  | 'TZ'
  /** Ukraine */
  | 'UA'
  /** Uganda */
  | 'UG'
  /** United States Minor Outlying Islands */
  | 'UM'
  /** Matches any country code */
  | 'UNDEFINED'
  /** United States of America */
  | 'US'
  /** Uruguay */
  | 'UY'
  /** Uzbekistan */
  | 'UZ'
  /** Holy See */
  | 'VA'
  /** Saint Vincent and the Grenadines */
  | 'VC'
  /** Bolivarian Republic of Venezuela */
  | 'VE'
  /** British Virgin Islands */
  | 'VG'
  /** Virgin Islands, U.S. */
  | 'VI'
  /** Viet Nam */
  | 'VN'
  /** Vanuatu */
  | 'VU'
  /** Wallis and Futuna */
  | 'WF'
  /** Samoa */
  | 'WS'
  /** Yemen */
  | 'YE'
  /** Mayotte */
  | 'YT'
  /** South Africa */
  | 'ZA'
  /** Zambia */
  | 'ZM'
  /** Zimbabwe */
  | 'ZW';

/**  --------------------------------------------------------------------------------------------------------------------- */
export type PropertyEngineCreateSearchDemandInput = {
  assignment: PropertyEngineSearchDemandAssignmentInput;
  contactFirstName?: InputMaybe<Scalars['String']>;
  contactId: Scalars['String'];
  contactLastName?: InputMaybe<Scalars['String']>;
  criteria: PropertyEngineSearchDemandCriteriaInput;
  leadId: Scalars['String'];
  priority?: PropertyEngineSearchDemandPriority;
  status?: PropertyEngineSearchDemandStatus;
};

export type PropertyEngineCreateSearchDemandsInput = {
  id: Scalars['PropertyEngineUUID'];
  input: PropertyEngineCreateSearchDemandInput;
};

export type PropertyEngineCreateSearchDemandsResponse = {
  __typename?: 'PropertyEngineCreateSearchDemandsResponse';
  leadId: Scalars['String'];
  searchDemandId?: Maybe<Scalars['PropertyEngineUUID']>;
  status?: Maybe<PropertyEngineCreateSearchDemandsStatus>;
};

export type PropertyEngineCreateSearchDemandsStatus = 'FAILED' | 'SUCCESS';

export type PropertyEngineCustomTable = {
  __typename?: 'PropertyEngineCustomTable';
  defaultView?: Maybe<Scalars['Boolean']>;
  filterConfiguration?: Maybe<PropertyEngineFilterConfiguration>;
  id: Scalars['PropertyEngineIdentifier'];
  orderConfiguration?: Maybe<PropertyEngineOrderConfiguration>;
  tableConfiguration: PropertyEngineTableConfiguration;
  viewName: Scalars['String'];
};

export type PropertyEngineCustomTableInput = {
  defaultView?: InputMaybe<Scalars['Boolean']>;
  filterConfiguration?: InputMaybe<PropertyEngineFilterConfigurationInput>;
  orderConfiguration?: InputMaybe<PropertyEngineOrderConfigurationInput>;
  tableConfiguration: PropertyEngineTableConfigurationInput;
  viewName: Scalars['String'];
};

export type PropertyEngineDashboardFeedListingsDimension =
  | 'LAST_ACTIVIATION'
  | 'LAST_ASSIGNED_AGENT_CHANGE'
  | 'LAST_PRICE_REDUCTION';

export type PropertyEngineDashboardFeedListingsResponse = {
  __typename?: 'PropertyEngineDashboardFeedListingsResponse';
  results?: Maybe<Array<PropertyEngineDashboardFeedResult>>;
  total?: Maybe<Scalars['Int']>;
};

export type PropertyEngineDashboardFeedResult = {
  __typename?: 'PropertyEngineDashboardFeedResult';
  dimensions?: Maybe<Array<PropertyEngineDashboardFeedListingsDimension>>;
  listing: PropertyEngineListing;
};

export type PropertyEngineDateRange = {
  __typename?: 'PropertyEngineDateRange';
  max?: Maybe<Scalars['PropertyEngineDate']>;
  min?: Maybe<Scalars['PropertyEngineDate']>;
};

export type PropertyEngineDateRangeInput = {
  max?: InputMaybe<Scalars['PropertyEngineDate']>;
  min?: InputMaybe<Scalars['PropertyEngineDate']>;
};

export type PropertyEngineDecimalRange = {
  __typename?: 'PropertyEngineDecimalRange';
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export type PropertyEngineDecimalRangeInput = {
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
};

export type PropertyEngineDevelopmentInfrastructure =
  /** Electricity on site */
  | 'ELECTRICITY_ON_SITE'
  /** Gas on site */
  | 'GAS_ON_SITE'
  /** Has driveway */
  | 'HAS_DRIVEWAY'
  /** Sewer on site */
  | 'SEWER_ON_SITE'
  /** Water on site */
  | 'WATER_ON_SITE';

export type PropertyEngineElevatorType =
  /** Cargo-lift */
  | 'CARGO_LIFT'
  /** Lift */
  | 'LIFT'
  /** Others */
  | 'OTHERS';

export type PropertyEngineEnergyEfficiencyRating =
  /** A */
  | 'A'
  /** A+ */
  | 'A_PLUS'
  /** A++ */
  | 'A_PLUS_PLUS'
  /** B */
  | 'B'
  /** C */
  | 'C'
  /** D */
  | 'D'
  /** E */
  | 'E'
  /** F */
  | 'F'
  /** G */
  | 'G'
  /** H */
  | 'H'
  /** I */
  | 'I'
  /** Energy efficiency is undefined */
  | 'UNDEFINED';

export type PropertyEngineEnergyLabelPt =
  /** A */
  | 'A'
  /** A B */
  | 'A_B'
  /** A B- */
  | 'A_B_MINUS'
  /** A C */
  | 'A_C'
  /** A D */
  | 'A_D'
  /** A E */
  | 'A_E'
  /** A F */
  | 'A_F'
  /** A G */
  | 'A_G'
  /** A */
  | 'A_PLUS'
  /** A  A */
  | 'A_PLUS_A'
  /** A  B */
  | 'A_PLUS_B'
  /** A  B- */
  | 'A_PLUS_B_MINUS'
  /** A  C */
  | 'A_PLUS_C'
  /** A  D */
  | 'A_PLUS_D'
  /** A  E */
  | 'A_PLUS_E'
  /** A  F */
  | 'A_PLUS_F'
  /** A  G */
  | 'A_PLUS_G'
  /** B */
  | 'B'
  /** B B- */
  | 'B_B_MINUS'
  /** B C */
  | 'B_C'
  /** B D */
  | 'B_D'
  /** B E */
  | 'B_E'
  /** B F */
  | 'B_F'
  /** B G */
  | 'B_G'
  /** B- */
  | 'B_MINUS'
  /** B- C */
  | 'B_MINUS_C'
  /** B- D */
  | 'B_MINUS_D'
  /** B- E */
  | 'B_MINUS_E'
  /** B- F */
  | 'B_MINUS_F'
  /** B- G */
  | 'B_MINUS_G'
  /** C */
  | 'C'
  /** C D */
  | 'C_D'
  /** C E */
  | 'C_E'
  /** C F */
  | 'C_F'
  /** C G */
  | 'C_G'
  /** D */
  | 'D'
  /** D E */
  | 'D_E'
  /** D F */
  | 'D_F'
  /** D G */
  | 'D_G'
  /** E */
  | 'E'
  /** E F */
  | 'E_F'
  /** E G */
  | 'E_G'
  /** F */
  | 'F'
  /** F G */
  | 'F_G'
  /** G */
  | 'G';

export type PropertyEngineEnterUploadInput = {
  description: Scalars['String'];
  file: Scalars['PropertyEngineUpload'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type PropertyEngineEvSourceType =
  /** mapped from GO3_COLDCALLING_7 */
  | 'COLD_CALLING'
  /** mapped from GO3_COLLABORATION_27 */
  | 'COLLABORATION'
  /** mapped from GO3_EVENT_13 */
  | 'EVENT'
  /** mapped from GO3_EXIBITION_23 */
  | 'EXIBITION'
  /** mapped from GO3_EXISTINGCONTACT_25 */
  | 'EXISTING_CONTACT'
  /** mapped from GO3_FARMINGLEAD_28 */
  | 'FARMINGLEAD'
  /** mapped from GO3_FLYER_6 */
  | 'FLYER'
  /** mapped from GO3_GG_5 */
  | 'GG'
  /** mapped from GO3_HINTFROM_12 */
  | 'HINT_FROM'
  /** mapped from GO3_INITIATIVEFROMTHECUSTOMERSSIDE_11 */
  | 'INITIATIVE_FROM_THE_CUSTOMERS_SIDE'
  /** mapped from GO3_INTERNETPORTALS_26 */
  | 'INTERNET_PORTALS'
  /** mapped from GO3_INTERNETPORTALSDIFFERENT_16 */
  | 'INTERNET_PORTALS_DIFFERENT'
  /** mapped from GO3_INTERNETPORTALEV_15 */
  | 'INTERNET_PORTAL_EV'
  /** mapped from GO3_INTERNETPORTALIMMONET_21 */
  | 'INTERNET_PORTAL_IMMONET'
  /** mapped from GO3_INTERNETPORTALIS24_20 */
  | 'INTERNET_PORTAL_IS_24'
  /** mapped from GO3_INTERNETPORTALPRIMELOCATION_22 */
  | 'INTERNET_PORTAL_PRIMELOCATION'
  /** mapped from GO3_LEADHUB_29 */
  | 'LEADHUB'
  /** mapped from GO3_MAILING_17 */
  | 'MAILING'
  /** mapped from GO3_NEIGHBOURHOODFLYER_8 */
  | 'NEIGHBOURHOOD_FLYER'
  /** mapped from GO3_OFFICE_2 */
  | 'OFFICE'
  /** mapped from GO3_OTHERSUNKNOWN_19 */
  | 'OTHERS_UNKNOWN'
  /** mapped from GO3_OUTDOORADVERTISING_3 */
  | 'OUTDOOR_ADVERTISING'
  /** mapped from GO3_PRIVATECONTACT_9 */
  | 'PRIVATE_CONTACT'
  /** mapped from GO3_PUBLICITY_1 */
  | 'PUBLICITY'
  /** mapped from GO3_RECOMMENDATION_4 */
  | 'RECOMMENDATION'
  /** mapped from GO3_REFERRAL_14 */
  | 'REFERRAL'
  /** mapped from GO3_SIGNBOARD_10 */
  | 'SIGNBOARD'
  /** mapped from GO3_SUBSIDIARIES_24 */
  | 'SUBSIDIARIES'
  /** mapped from GO3_TELEPHONEBOOK_18 */
  | 'TELEPHONEBOOK';

export type PropertyEngineExposeAttachmentDetail = {
  __typename?: 'PropertyEngineExposeAttachmentDetail';
  attachmentDownloadUrl: Scalars['PropertyEngineUrl'];
  metadata?: Maybe<Scalars['PropertyEngineJSON']>;
  name: Scalars['String'];
};

export type PropertyEngineExposeDetail = {
  __typename?: 'PropertyEngineExposeDetail';
  attachments?: Maybe<Array<PropertyEngineExposeAttachmentDetail>>;
  exposeDownloadUrl?: Maybe<Scalars['PropertyEngineUrl']>;
  id: Scalars['PropertyEngineIdentifier'];
  language: PropertyEngineLanguage;
  type: PropertyEngineExposeType;
};

export type PropertyEngineExposeType =
  /** Expose is automatically generated */
  | 'GENERATED'
  /** Expose is manually uploaded */
  | 'UPLOAD';

export type PropertyEngineFile = PropertyEngineNode & {
  __typename?: 'PropertyEngineFile';
  category?: Maybe<PropertyEngineFileCategory>;
  id: Scalars['PropertyEngineIdentifier'];
  metadata: Scalars['PropertyEngineJSON'];
  name: Scalars['String'];
  parent?: Maybe<PropertyEngineFolder>;
  path: Scalars['String'];
  type?: Maybe<PropertyEngineFileType>;
  url?: Maybe<Scalars['PropertyEngineUrl']>;
};

export type PropertyEngineFileCategory =
  /** File represents an automatically generated expose */
  | 'ATTACHMENT_EXPOSE'
  /** File represents an energy certificate */
  | 'ENERGY_CERTIFICATE'
  /** File represents a floor plan */
  | 'FLOOR_PLAN'
  /** File represents a land register map */
  | 'LAND_REGISTER_MAP'
  /** File represents a picture */
  | 'PICTURE'
  /** File represents a site plan */
  | 'SITE_PLAN'
  /** File represents a manually uploaded expose */
  | 'UPLOADED_EXPOSE';

export type PropertyEngineFileInput = {
  metadata: Scalars['PropertyEngineJSON'];
  mime?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  path: Scalars['String'];
  url?: InputMaybe<Scalars['PropertyEngineUrl']>;
};

export type PropertyEngineFileType =
  /** Type of the file is document */
  | 'DOCUMENT'
  /** Type of the file is picture */
  | 'PICTURE';

export type PropertyEngineFilterConfiguration = {
  __typename?: 'PropertyEngineFilterConfiguration';
  askingPrice?: Maybe<PropertyEngineDecimalRange>;
  assetSubTypes?: Maybe<Array<PropertyEngineAssetSubType>>;
  assetType?: Maybe<PropertyEngineAssetType>;
  assetVariations?: Maybe<Array<PropertyEngineAssetVariation>>;
  assignedAgentId?: Maybe<Scalars['PropertyEngineUUID']>;
  /**  SearchListingsFilterInput */
  assignedAgentKey?: Maybe<Scalars['PropertyEngineLong']>;
  assignedTeamIds?: Maybe<Array<Scalars['PropertyEngineUUID']>>;
  boundingBox?: Maybe<PropertyEngineBoundingBoxDetail>;
  cdfs?: Maybe<Array<Scalars['PropertyEngineUUID']>>;
  city?: Maybe<Scalars['String']>;
  closingDate?: Maybe<PropertyEngineDateRange>;
  contactNameQuery?: Maybe<Scalars['String']>;
  countryCode?: Maybe<PropertyEngineCountryCode>;
  fileAs?: Maybe<Scalars['String']>;
  keyInShop?: Maybe<Scalars['Boolean']>;
  lastActivationDate?: Maybe<PropertyEngineDateRange>;
  lastOwnerChange?: Maybe<PropertyEngineDateRange>;
  lastPriceChange?: Maybe<PropertyEngineDateRange>;
  lastPriceReduction?: Maybe<PropertyEngineDateRange>;
  listingStatus?: Maybe<PropertyEngineListingStatus>;
  listingType?: Maybe<PropertyEngineListingType>;
  matchingState?: Maybe<PropertyEngineMatchingState>;
  monthlyNetRent?: Maybe<PropertyEngineDecimalRange>;
  monthlyNetRentBySurfSys?: Maybe<PropertyEngineDecimalRange>;
  multiPolygon?: Maybe<Scalars['PropertyEngineMultiPolygon']>;
  numberOfBathrooms?: Maybe<PropertyEngineIntegerRange>;
  numberOfBedrooms?: Maybe<PropertyEngineIntegerRange>;
  numberOfRooms?: Maybe<PropertyEngineDecimalRange>;
  onlyOwnSearchDemands?: Maybe<Scalars['Boolean']>;
  ownerContactId?: Maybe<Scalars['String']>;
  ownerContactKey?: Maybe<Scalars['PropertyEngineLong']>;
  /** placeId is deprecated but FE cannot generate their models for deprecated fields... */
  placeId?: Maybe<Scalars['String']>;
  placeIds?: Maybe<Array<Scalars['String']>>;
  plotSurface?: Maybe<PropertyEngineIntegerRange>;
  postalCode?: Maybe<Scalars['String']>;
  propertyName?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  signboard?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<Array<Maybe<PropertyEngineSearchDemandStatus>>>;
  streetName?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  surfaceLivingArea?: Maybe<PropertyEngineDecimalRange>;
  towerName?: Maybe<Scalars['String']>;
  /**  MatchingForListingInput */
  utag?: Maybe<Scalars['String']>;
  yearOfConstruction?: Maybe<PropertyEngineIntegerRange>;
};

export type PropertyEngineFilterConfigurationInput = {
  askingPrice?: InputMaybe<PropertyEngineDecimalRangeInput>;
  assetSubTypes?: InputMaybe<Array<PropertyEngineAssetSubType>>;
  assetType?: InputMaybe<PropertyEngineAssetType>;
  assetVariations?: InputMaybe<Array<PropertyEngineAssetVariation>>;
  assignedAgentId?: InputMaybe<Scalars['PropertyEngineUUID']>;
  /**  SearchListingsFilterInput */
  assignedAgentKey?: InputMaybe<Scalars['PropertyEngineLong']>;
  assignedTeamIds?: InputMaybe<Array<Scalars['PropertyEngineUUID']>>;
  boundingBox?: InputMaybe<PropertyEngineBoundingBox>;
  cdfs?: InputMaybe<Array<Scalars['PropertyEngineUUID']>>;
  city?: InputMaybe<Scalars['String']>;
  closingDate?: InputMaybe<PropertyEngineDateRangeInput>;
  contactNameQuery?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<PropertyEngineCountryCode>;
  fileAs?: InputMaybe<Scalars['String']>;
  keyInShop?: InputMaybe<Scalars['Boolean']>;
  lastActivationDate?: InputMaybe<PropertyEngineDateRangeInput>;
  lastOwnerChange?: InputMaybe<PropertyEngineDateRangeInput>;
  lastPriceChange?: InputMaybe<PropertyEngineDateRangeInput>;
  lastPriceReduction?: InputMaybe<PropertyEngineDateRangeInput>;
  listingStatus?: InputMaybe<PropertyEngineListingStatus>;
  listingType?: InputMaybe<PropertyEngineListingType>;
  matchingState?: InputMaybe<PropertyEngineMatchingState>;
  monthlyNetRent?: InputMaybe<PropertyEngineDecimalRangeInput>;
  monthlyNetRentBySurfSys?: InputMaybe<PropertyEngineDecimalRangeInput>;
  multiPolygon?: InputMaybe<Scalars['PropertyEngineMultiPolygon']>;
  numberOfBathrooms?: InputMaybe<PropertyEngineIntegerRangeInput>;
  numberOfBedrooms?: InputMaybe<PropertyEngineIntegerRangeInput>;
  numberOfRooms?: InputMaybe<PropertyEngineDecimalRangeInput>;
  onlyOwnSearchDemands?: InputMaybe<Scalars['Boolean']>;
  ownerContactId?: InputMaybe<Scalars['String']>;
  ownerContactKey?: InputMaybe<Scalars['PropertyEngineLong']>;
  placeIds?: InputMaybe<Array<Scalars['String']>>;
  plotSurface?: InputMaybe<PropertyEngineIntegerRangeInput>;
  postalCode?: InputMaybe<Scalars['String']>;
  propertyName?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  signboard?: InputMaybe<Scalars['Boolean']>;
  statuses?: InputMaybe<Array<InputMaybe<PropertyEngineSearchDemandStatus>>>;
  streetName?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  surfaceLivingArea?: InputMaybe<PropertyEngineDecimalRangeInput>;
  towerName?: InputMaybe<Scalars['String']>;
  /**  MatchingForListingInput # copied from input parameters from findMatchingSearchDemandsForListing */
  utag?: InputMaybe<Scalars['String']>;
  yearOfConstruction?: InputMaybe<PropertyEngineIntegerRangeInput>;
};

export type PropertyEngineFloodZones =
  /** Circumscribed zone */
  | 'CIRCUMSCRIBED'
  /** Property partially or completely located in a circumscribed flood zone */
  | 'LOCATED_CIRCUMSCRIBED_FLOOD'
  /** Property partially or completely located in a circumscribed waterside zone */
  | 'LOCATED_CIRCUMSCRIBED_WATERSIDE'
  /** Property partially or completely located in a possible flood zone and located in a circumscribed waterside zone */
  | 'LOCATED_FLOOD_WATERSIDE'
  /** Property partially or completely located in a circumscribed and recognised flood zone */
  | 'LOCATED_RECOGNISED_FLOOD'
  /** Non flood zone */
  | 'NON_FLOOD'
  /** Possible circumscribed waterside zone */
  | 'POSSIBLE_CIRCUMSCRIBED_WATERSIDE'
  /** Possible flood zone */
  | 'POSSIBLE_FLOOD'
  /** Recognised flood zone */
  | 'RECOGNISED_FLOOD';

export type PropertyEngineFlooringType =
  /** Anti static carpeted floor */
  | 'ANTI_STATIC_CARPETED_FLOOR'
  /** Carpet */
  | 'CARPET'
  /** Carpet tiles */
  | 'CARPET_TILES'
  /** Concrete */
  | 'CONCRETE'
  /** Epoxy resin floor */
  | 'EPOXY_RESIN_FLOOR'
  /** Laminate */
  | 'LAMINATE'
  /** Marble */
  | 'MARBLE'
  /** On-demand */
  | 'ON_DEMAND'
  /** Parquet */
  | 'PARQUET'
  /** Pvc */
  | 'PVC'
  /** Stone */
  | 'STONE'
  /** Tiles */
  | 'TILES'
  /** Vinyl design */
  | 'VINYL_DESIGN'
  /** Wooden floor */
  | 'WOODEN_FLOOR';

export type PropertyEngineFolder = PropertyEngineNode & {
  __typename?: 'PropertyEngineFolder';
  children: Array<PropertyEngineNode>;
  id: Scalars['PropertyEngineIdentifier'];
  metadata: Scalars['PropertyEngineJSON'];
  name: Scalars['String'];
  parent?: Maybe<PropertyEngineFolder>;
  path: Scalars['String'];
};

/**
 *  -------------------------------------------------------------------------------------------------
 *  -------------------------------------------------------------------------------------------------
 *  -------------------------------------------------------------------------------------------------
 */
export type PropertyEngineFolderInput = {
  metadata?: InputMaybe<Scalars['PropertyEngineJSON']>;
  name: Scalars['String'];
  path: Scalars['String'];
};

export type PropertyEngineGo3MigrationFilter = {
  lastChangeTimeAfter?: InputMaybe<Scalars['PropertyEngineLocalDateTime']>;
  statuses?: InputMaybe<Array<Scalars['Int']>>;
};

export type PropertyEngineHeatingTransferType =
  /** Air */
  | 'AIR'
  /** Radiator */
  | 'RADIATOR'
  /** Underfloor */
  | 'UNDERFLOOR';

export type PropertyEngineHeatingType =
  /** Central heating */
  | 'CENTRAL_HEATING'
  /** Coal heating */
  | 'COAL_HEATING'
  /** Decentral heating */
  | 'DECENTRAL_HEATING'
  /** District heating */
  | 'DISTRICT_HEATING'
  /** Electric heating */
  | 'ELECTRIC_HEATING'
  /** Gas-heating */
  | 'GAS_HEATING'
  /** Heat_pump */
  | 'HEAT_PUMP'
  /** Long-distance heating */
  | 'LONG_DISTANCE_HEATING'
  /** Loop */
  | 'LOOP'
  /** Off-peak storage heating */
  | 'OFF_PEAK_STORAGE_HEATING'
  /** Oil heating */
  | 'OIL_HEATING'
  /** Pellet heating */
  | 'PELLET_HEATING'
  /** Self-contained central heating */
  | 'SELF_CONTAINED_CENTRAL_HEATING'
  /** Solar heating */
  | 'SOLAR_HEATING'
  /** Stove heating */
  | 'STOVE_HEATING';

export type PropertyEngineHouse = {
  __typename?: 'PropertyEngineHouse';
  airConditionTypes?: Maybe<Array<PropertyEngineAirConditionType>>;
  amountOfBalconies?: Maybe<Scalars['Int']>;
  asBuiltPlan?: Maybe<Scalars['Boolean']>;
  atticTypes?: Maybe<Array<PropertyEngineAtticType>>;
  basementTypes?: Maybe<Array<PropertyEngineBasementType>>;
  buildingInsuranceValue?: Maybe<Scalars['Float']>;
  buildingRestrictions?: Maybe<Array<PropertyEngineBuildingRestriction>>;
  buildingRightComments?: Maybe<Scalars['String']>;
  buildingRightYear?: Maybe<Scalars['Int']>;
  certificationFuelTanks?: Maybe<PropertyEngineThreeAnswers>;
  co2Emission?: Maybe<Scalars['Float']>;
  co2EmissionRating?: Maybe<PropertyEngineCo2EmissionRating>;
  condition?: Maybe<PropertyEngineAssetCondition>;
  conditionBelgium?: Maybe<PropertyEngineConditionBelgium>;
  doubleGlazing?: Maybe<Scalars['Boolean']>;
  elevatorTypes?: Maybe<Array<PropertyEngineElevatorType>>;
  energyCertificateDate?: Maybe<Scalars['PropertyEngineDate']>;
  energyCertificateExpirationDate?: Maybe<Scalars['PropertyEngineDate']>;
  energyCertificateNumberFlanders?: Maybe<Scalars['String']>;
  energyCertificateType?: Maybe<PropertyEngineQcEnergyCertificateType>;
  energyConsumption?: Maybe<Scalars['Float']>;
  energyEfficiencyRating?: Maybe<PropertyEngineEnergyEfficiencyRating>;
  energyLabelPt?: Maybe<PropertyEngineEnergyLabelPt>;
  energyRatingSummary?: Maybe<Scalars['String']>;
  energyRatingSummaryLocked: Scalars['Boolean'];
  epcCode?: Maybe<Scalars['String']>;
  finalEnergyConsumption?: Maybe<Scalars['Float']>;
  finalEnergyDemand?: Maybe<Scalars['Float']>;
  finalEnergyEfficiencyFactorFgee?: Maybe<Scalars['Float']>;
  fireInsuranceValue?: Maybe<Scalars['Float']>;
  floodZones?: Maybe<PropertyEngineFloodZones>;
  floorDesc?: Maybe<Scalars['String']>;
  flooringTypes?: Maybe<Array<PropertyEngineFlooringType>>;
  gardenSurface?: Maybe<Scalars['Float']>;
  hasBalcony?: Maybe<Scalars['Boolean']>;
  hasBasement?: Maybe<Scalars['Boolean']>;
  hasEnergyCertificate?: Maybe<Scalars['Boolean']>;
  hasFireplace?: Maybe<Scalars['Boolean']>;
  hasGarden?: Maybe<Scalars['Boolean']>;
  hasPatio?: Maybe<Scalars['Boolean']>;
  hasRooftopTerrace?: Maybe<Scalars['Boolean']>;
  hasSecondarySuite?: Maybe<Scalars['Boolean']>;
  hasServantsQuarter?: Maybe<Scalars['Boolean']>;
  hasSwimmingPool?: Maybe<Scalars['Boolean']>;
  hasTerrace?: Maybe<Scalars['Boolean']>;
  hasUndergroundGarage?: Maybe<Scalars['Boolean']>;
  heatProtectionClass?: Maybe<PropertyEngineEnergyEfficiencyRating>;
  heatProtectionValue?: Maybe<Scalars['Float']>;
  heatingEnergyDemandHwb?: Maybe<Scalars['Float']>;
  heatingTransferType?: Maybe<Array<Maybe<PropertyEngineHeatingTransferType>>>;
  heatingTypes?: Maybe<Array<PropertyEngineHeatingType>>;
  hotWaterIncludedInEnergyConsumption?: Maybe<Scalars['Boolean']>;
  indoorFacilities?: Maybe<Array<PropertyEngineInteriorOption>>;
  ipeKwh?: Maybe<Scalars['Float']>;
  land?: Maybe<PropertyEngineLand>;
  lastModernizationRestaurationYear?: Maybe<Scalars['Int']>;
  latestLandUseDesignation?: Maybe<PropertyEngineUrbanInformationLatestLandUseDesignation>;
  laundryRoom?: Maybe<PropertyEngineLaundryRoomLocation>;
  laundryRoomUsageType?: Maybe<PropertyEngineUsageType>;
  leasehold?: Maybe<Scalars['Boolean']>;
  leaseholdComments?: Maybe<Scalars['String']>;
  leaseholdYear?: Maybe<Scalars['Int']>;
  monthlyRentPerGarage?: Maybe<Scalars['Float']>;
  monthlyRentPerParkingBay?: Maybe<Scalars['Float']>;
  numberOfBathrooms?: Maybe<Scalars['Int']>;
  numberOfBedrooms?: Maybe<Scalars['Int']>;
  numberOfFacades?: Maybe<Scalars['Float']>;
  numberOfGarages?: Maybe<Scalars['Int']>;
  numberOfMasterBathrooms?: Maybe<Scalars['Int']>;
  numberOfParkingBays?: Maybe<Scalars['Int']>;
  numberOfRooms?: Maybe<Scalars['Float']>;
  numberOfStories?: Maybe<Scalars['Float']>;
  orientationsOfBalcony?: Maybe<Array<PropertyEngineOrientation>>;
  outdoorFacilities?: Maybe<Array<PropertyEngineOutsideOption>>;
  ownership?: Maybe<PropertyEngineAssetOwnership>;
  preemptiveRightOption?: Maybe<PropertyEngineUrbanInformationPreemptiveRightOption>;
  pricePerGarage?: Maybe<Scalars['Float']>;
  pricePerParkingBay?: Maybe<Scalars['Float']>;
  proceedingsPlanningRegulationsBreach?: Maybe<PropertyEngineUrbanInformationProceedingsPlanningRegulationsBreach>;
  rooftopTerraceSurface?: Maybe<Scalars['Float']>;
  securityFeatures?: Maybe<Array<PropertyEngineSecurityOption>>;
  subType?: Maybe<PropertyEngineAssetSubType>;
  subdivisionPermit?: Maybe<PropertyEngineUrbanInformationSubdivisionPermit>;
  surfaceLivingArea?: Maybe<Scalars['Float']>;
  swimmingPoolTypes?: Maybe<Array<PropertyEngineSwimmingPoolType>>;
  tenureConditions?: Maybe<Array<Maybe<PropertyEngineTenureCondition>>>;
  terraceSurface?: Maybe<Scalars['Float']>;
  totalSurface?: Maybe<Scalars['Float']>;
  type?: Maybe<PropertyEngineAssetType>;
  urbanInformationPlanningPermissionObtained?: Maybe<PropertyEngineUrbanInformationPlanningPermissionType>;
  usableSurface?: Maybe<Scalars['Float']>;
  variation?: Maybe<PropertyEngineAssetVariation>;
  views?: Maybe<Array<PropertyEngineViewOption>>;
  waterfrontLocations?: Maybe<Array<PropertyEngineWaterLocationType>>;
  wellnessEquipment?: Maybe<Array<PropertyEngineWellnessOption>>;
  widthOfFacadeToStreet?: Maybe<Scalars['Float']>;
  yearOfConstruction?: Maybe<Scalars['Int']>;
};

export type PropertyEngineImageDescription = {
  __typename?: 'PropertyEngineImageDescription';
  description: Scalars['String'];
  locale: Scalars['PropertyEngineLocaleScalar'];
};

export type PropertyEngineIntegerRange = {
  __typename?: 'PropertyEngineIntegerRange';
  max?: Maybe<Scalars['PropertyEngineLong']>;
  min?: Maybe<Scalars['PropertyEngineLong']>;
};

export type PropertyEngineIntegerRangeInput = {
  max?: InputMaybe<Scalars['PropertyEngineLong']>;
  min?: InputMaybe<Scalars['PropertyEngineLong']>;
};

export type PropertyEngineInteriorOption =
  /** Accessible for wheelchairs */
  | 'ACCESSIBLE_FOR_WHEELCHAIRS'
  /** Appliances */
  | 'APPLIANCES'
  /** Builtin cupboards */
  | 'BUILTIN_CUPBOARDS'
  /** Builtin wardrobe */
  | 'BUILTIN_WARDROBE'
  /** Doorman */
  | 'DOORMAN'
  /** Fixed furniture */
  | 'FIXED_FURNITURE'
  /** Furnished */
  | 'FURNISHED'
  /** Guest toilet */
  | 'GUEST_TOILET'
  /** Kitchen */
  | 'KITCHEN'
  /** Laundry/wash kitchen */
  | 'LAUNDRY_WASH_KITCHEN'
  /** Senior oriented */
  | 'SENIOR_ORIENTED'
  /** Separate sanitary facilities */
  | 'SEPARATE_SANITARY_FACILITIES';

export type PropertyEngineInternetPortal = {
  __typename?: 'PropertyEngineInternetPortal';
  availableForExport?: Maybe<Scalars['Boolean']>;
  availableSettings?: Maybe<Array<PropertyEngineInternetPortalSettingOption>>;
  includeInExport?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  portalId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineInternetPortalSettingOption =
  /** Okz */
  | 'OKZ'
  /** Show new flag */
  | 'SHOW_NEW_FLAG';

export type PropertyEngineInternetPortalSettings = {
  __typename?: 'PropertyEngineInternetPortalSettings';
  okz?: Maybe<Scalars['String']>;
  showNewFlag?: Maybe<Scalars['Boolean']>;
};

export type PropertyEngineInternetProfile = {
  __typename?: 'PropertyEngineInternetProfile';
  automaticExposeSendingEnabled: Scalars['Boolean'];
  displayActualLocation?: Maybe<Scalars['Boolean']>;
  isUpdatable: Scalars['Boolean'];
  linkVirtualTour?: Maybe<Scalars['String']>;
  pictures?: Maybe<Array<PropertyEngineProxyImageDetail>>;
  portals?: Maybe<Array<PropertyEngineListingInternetPortal>>;
  priceOnRequest?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['PropertyEngineDateTime'];
  useWatermark?: Maybe<Scalars['Boolean']>;
};

export type PropertyEngineLand = {
  __typename?: 'PropertyEngineLand';
  buildingDepth?: Maybe<Scalars['String']>;
  buildingLines?: Maybe<Scalars['String']>;
  developmentInfrastructures?: Maybe<Array<PropertyEngineDevelopmentInfrastructure>>;
  floorSpaceIndex?: Maybe<PropertyEngineDecimalRange>;
  grossFloorArea?: Maybe<Scalars['Int']>;
  hasGarden?: Maybe<Scalars['Boolean']>;
  isSubdividable?: Maybe<Scalars['Boolean']>;
  landGuidelines?: Maybe<Array<PropertyEngineLandGuideline>>;
  landOrientation?: Maybe<PropertyEngineOrientation>;
  landUsageType?: Maybe<PropertyEngineLandUsageType>;
  legallyBindingLandUsePlan?: Maybe<Scalars['String']>;
  maxAllowedBuildingHeight?: Maybe<Scalars['Float']>;
  maxAllowedFullFloors?: Maybe<Scalars['Int']>;
  ownership?: Maybe<PropertyEngineAssetOwnership>;
  plotSurface?: Maybe<Scalars['Float']>;
  roofGradient?: Maybe<Scalars['Float']>;
  siteOccupancyIndex?: Maybe<PropertyEngineDecimalRange>;
  soilCondition?: Maybe<Scalars['String']>;
  standardGroundValue?: Maybe<Scalars['Float']>;
  subType?: Maybe<PropertyEngineAssetSubType>;
  type?: Maybe<PropertyEngineAssetType>;
  typeOfRoof?: Maybe<Scalars['String']>;
  variation?: Maybe<PropertyEngineAssetVariation>;
  views?: Maybe<Array<PropertyEngineViewOption>>;
  waterfrontLocations?: Maybe<Array<PropertyEngineWaterLocationType>>;
  widthOfFacadeToStreet?: Maybe<Scalars['Float']>;
};

export type PropertyEngineLandGuideline =
  /** Contaminated sites */
  | 'CONTAMINATED_SITES'
  /** Demolition inventory */
  | 'DEMOLITION_INVENTORY'
  /** Developed */
  | 'DEVELOPED'
  /** Freestanding hall */
  | 'FREESTANDING_HALL'
  /** Freestanding house */
  | 'FREESTANDING_HOUSE'
  /** Hillside */
  | 'HILLSIDE'
  /** Saved hillside */
  | 'SAVED_HILLSIDE';

export type PropertyEngineLandUsageType =
  /** commercial */
  | 'COMMERCIAL'
  /** Residential */
  | 'RESIDENTIAL'
  /** Service */
  | 'SERVICE'
  | 'UNDEFINED';

export type PropertyEngineLanguage =
  /** Bulgarian */
  | 'BG'
  /** Catalan */
  | 'CA'
  /** Czech */
  | 'CS'
  /** Danish */
  | 'DA'
  /** German (Austrian) */
  | 'DE_AT'
  /** German (Switzerland) */
  | 'DE_CH'
  /** German (Germany) */
  | 'DE_DE'
  /** Greek */
  | 'EL'
  /** English (Australia) */
  | 'EN_AU'
  /** English (United Kingdom) */
  | 'EN_GB'
  /** English (Hong Kong) */
  | 'EN_HK'
  /** English (Middle East) */
  | 'EN_ME'
  /** English (Thailand) */
  | 'EN_TH'
  /** Spanish (Spain) */
  | 'ES_ES'
  /** Spanish (South America) */
  | 'ES_SA'
  /** Estonian */
  | 'ET'
  /** Finnish */
  | 'FI'
  /** French (Belgium) */
  | 'FR_BE'
  /** French (France) */
  | 'FR_FR'
  /** Croatian */
  | 'HR'
  /** Hungarian */
  | 'HU'
  /** Italian */
  | 'IT'
  /** Lithuanian */
  | 'LT'
  /** Latvian */
  | 'LV'
  /** Dutch */
  | 'NL'
  /** Flemish */
  | 'NL_BE'
  /** Norwegian */
  | 'NO'
  /** Polish */
  | 'PL'
  /** Portuguese */
  | 'PT'
  /** Romanian */
  | 'RO'
  /** Russian */
  | 'RU'
  /** Serbian */
  | 'SR'
  /** Montenegrin */
  | 'SR_ME'
  /** Swedish */
  | 'SV'
  /** Thai */
  | 'TH'
  /** Turkish */
  | 'TR'
  /** Ukrainian */
  | 'UK'
  /** Cantonese */
  | 'YUE'
  /** Chinese (Simplified) */
  | 'ZH_CN'
  /** Chinese (Traditional) */
  | 'ZH_TW';

export type PropertyEngineLaundryRoomLocation =
  /** Laundry-room is inside the object */
  | 'INSIDE'
  /** Laundry-room is outside the object */
  | 'OUTSIDE';

export type PropertyEngineLeadWithContactIdResponse = {
  __typename?: 'PropertyEngineLeadWithContactIdResponse';
  contact?: Maybe<PropertyEngineContactWithId>;
  id?: Maybe<Scalars['String']>;
};

export type PropertyEngineListing = {
  __typename?: 'PropertyEngineListing';
  additionalTax?: Maybe<Scalars['Float']>;
  additionalWaterCharges?: Maybe<Scalars['Boolean']>;
  address?: Maybe<PropertyEngineAddress>;
  annualNetRent?: Maybe<Scalars['Float']>;
  annualUtilities?: Maybe<Scalars['Float']>;
  appraisalValue?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  askingPrice?: Maybe<Scalars['Float']>;
  askingPricePerAreaUnit?: Maybe<Scalars['Float']>;
  asset: PropertyEngineAsset;
  assignedAgentId?: Maybe<Scalars['PropertyEngineUUID']>;
  assignedTeamId?: Maybe<Scalars['PropertyEngineUUID']>;
  availableForShortTermRental?: Maybe<Scalars['Boolean']>;
  cdfs?: Maybe<Array<Maybe<Scalars['PropertyEngineUUID']>>>;
  closing?: Maybe<PropertyEngineClosing>;
  createdAt: Scalars['PropertyEngineDateTime'];
  createdBy?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['PropertyEngineCurrency']>;
  depositMonthlyNetRentFactor?: Maybe<Scalars['Float']>;
  district?: Maybe<Scalars['String']>;
  exposes: Array<PropertyEngineExposeDetail>;
  fileAs?: Maybe<Scalars['String']>;
  firstActivation?: Maybe<Scalars['PropertyEngineDateTime']>;
  generalComments?: Maybe<Scalars['String']>;
  hasSignboard?: Maybe<Scalars['Boolean']>;
  heatingCostsIncludedInUtilities?: Maybe<Scalars['Boolean']>;
  id: Scalars['PropertyEngineIdentifier'];
  initialAskingPrice?: Maybe<Scalars['Float']>;
  internalAskingPrice?: Maybe<PropertyEngineDecimalRange>;
  internetProfile: PropertyEngineInternetProfile;
  isSynchronized: Scalars['Boolean'];
  isUpdatable: Scalars['Boolean'];
  keyInShop?: Maybe<Scalars['Boolean']>;
  keyLabel?: Maybe<Scalars['String']>;
  landTax?: Maybe<Scalars['Float']>;
  lastActivation?: Maybe<Scalars['PropertyEngineLocalDateTime']>;
  /** Will be changed, see PROP-1998 */
  lastAssignedAgentChange?: Maybe<Scalars['PropertyEngineLocalDateTime']>;
  lastPriceReduction?: Maybe<Scalars['PropertyEngineLocalDateTime']>;
  legacyEVSource?: Maybe<PropertyEngineEvSourceType>;
  legacyEVSourceInternetPortal?: Maybe<Scalars['String']>;
  legacyEVSourcePublication?: Maybe<Scalars['String']>;
  legacyKey?: Maybe<Scalars['Int']>;
  listingOwnerContactId?: Maybe<Scalars['String']>;
  maintenanceReserve?: Maybe<Scalars['Float']>;
  maintenanceReservePerAreaUnit?: Maybe<Scalars['Float']>;
  mandate?: Maybe<PropertyEngineMandate>;
  marketingDetails: Array<PropertyEngineMarketingDetail>;
  measurementSystem?: Maybe<PropertyEngineMeasurementSystem>;
  monthlyHeatingCosts?: Maybe<Scalars['Float']>;
  monthlyNetRent?: Maybe<Scalars['Float']>;
  monthlyRentPerAreaUnit?: Maybe<Scalars['Float']>;
  monthlyTotalRent?: Maybe<Scalars['Float']>;
  monthlyUtilities?: Maybe<Scalars['Float']>;
  petsAllowed?: Maybe<Scalars['Boolean']>;
  pictures?: Maybe<Array<PropertyEngineProxyImageDetail>>;
  premium?: Maybe<Scalars['Float']>;
  previewImage?: Maybe<PropertyEngineProxyImageDetail>;
  priceBySurface?: Maybe<Scalars['Float']>;
  priceIndex?: Maybe<Scalars['Float']>;
  propertyTax?: Maybe<Scalars['Float']>;
  qualifiedAsHolidayHome?: Maybe<Scalars['Boolean']>;
  rentPeriod?: Maybe<PropertyEngineRentPeriod>;
  rentedOut?: Maybe<Scalars['Boolean']>;
  serviceCharge?: Maybe<Scalars['Float']>;
  shopId: Scalars['PropertyEngineUUID'];
  signboardPutUpDate?: Maybe<Scalars['PropertyEngineDate']>;
  startOfRental?: Maybe<Scalars['PropertyEngineDate']>;
  status: PropertyEngineListingStatus;
  taxComments?: Maybe<Scalars['String']>;
  totalDeposit?: Maybe<Scalars['Float']>;
  totalRent?: Maybe<Scalars['Float']>;
  type: PropertyEngineListingType;
  updatedAt: Scalars['PropertyEngineDateTime'];
  utag: Scalars['String'];
  vatAmount?: Maybe<Scalars['Float']>;
  windowDisplay?: Maybe<PropertyEngineWindowDisplay>;
};

export type PropertyEngineListingExposesArgs = {
  agentId?: InputMaybe<Scalars['PropertyEngineUUID']>;
  language?: InputMaybe<PropertyEngineLanguage>;
  showAgentInExpose?: InputMaybe<Scalars['Boolean']>;
};

export type PropertyEngineListingMarketingDetailsArgs = {
  language?: InputMaybe<PropertyEngineLanguage>;
};

export type PropertyEngineListingInternetPortal = {
  __typename?: 'PropertyEngineListingInternetPortal';
  availableForExport?: Maybe<Scalars['Boolean']>;
  includeInExport?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  portalId: Scalars['PropertyEngineUUID'];
  settings?: Maybe<PropertyEngineInternetPortalSettings>;
};

/**  --------------------------------------------------------------------------------------------------------------------- */
export type PropertyEngineListingOrder = {
  askingPrice?: InputMaybe<PropertyEngineOrderBy>;
  lastActivation?: InputMaybe<PropertyEngineOrderBy>;
  monthlyNetRent?: InputMaybe<PropertyEngineOrderBy>;
  monthlyTotalRent?: InputMaybe<PropertyEngineOrderBy>;
  numberOfRooms?: InputMaybe<PropertyEngineOrderBy>;
  /**  deprecated for removal */
  price?: InputMaybe<PropertyEngineOrderBy>;
  surfaceLivingArea?: InputMaybe<PropertyEngineOrderBy>;
};

export type PropertyEngineListingRequiredField =
  | 'ADDRESS_CITY'
  | 'AREA'
  | 'ASKING_PRICE'
  | 'ASSET_SUB_TYPE'
  | 'LATITUDE'
  | 'LISTING_OWNER'
  | 'LONGITUDE'
  | 'MONTHLY_NET_RENT'
  | 'PICTURES'
  | 'SEARCH_BY';

export type PropertyEngineListingStatus =
  | 'ACTIVE'
  | 'CANCELLED'
  | 'CLOSED_BY_EV'
  | 'CLOSED_BY_OTHERS'
  | 'EXPIRED'
  | 'INACTIVE'
  | 'OFFER_NO_SHOW'
  | 'OFFER_SHOW'
  | 'PENDING'
  | 'STREET_INDEX'
  | 'UNDEFINED'
  | 'WITHDRAWN';

export type PropertyEngineListingType =
  /** Listing represents an asset to rent */
  | 'RENTAL'
  /** Listing represents an asset to sale */
  | 'SALE';

export type PropertyEngineMandate = {
  __typename?: 'PropertyEngineMandate';
  commissionAmount?: Maybe<Scalars['Float']>;
  commissionNetRentFactor?: Maybe<Scalars['Float']>;
  commissionPercentBuyer?: Maybe<Scalars['Float']>;
  commissionPercentOwner?: Maybe<Scalars['Float']>;
  commissionType?: Maybe<PropertyEngineMandateCommissionType>;
  coownersContactIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['PropertyEngineIdentifier'];
  listingOwnerContactId?: Maybe<Scalars['String']>;
  mandateType: PropertyEngineMandateType;
  preferredCommunicationContactType?: Maybe<PropertyEngineMandateContactType>;
  propertyAvailableFromDate?: Maybe<Scalars['PropertyEngineDate']>;
  propertyAvailableFromText?: Maybe<Scalars['String']>;
  representativeContactId?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['PropertyEngineDate']>;
  validTo?: Maybe<Scalars['PropertyEngineDate']>;
};

export type PropertyEngineMandateCommissionType = 'BUYER_TENANT_SIDE' | 'MIXED' | 'OWNER_SIDE' | 'UNDEFINED';

export type PropertyEngineMandateContactType =
  /** Contact is an additional asset owner */
  | 'ADDITIONAL_OWNER'
  /** Mapped from GO3_CARETAKER_220260, GO3_CARETAKER_230260, GO3_CARETAKERSWISS_220230, GO3_CARETAKERSWISS_230230 */
  | 'CARE_TAKER'
  /** Contact is a contact of the asset owner */
  | 'CONTACT'
  /** Contact is from a cooperation */
  | 'COOPERATION'
  /** Mapped from GO3_LANDLORD_230210 */
  | 'LAND_LORD'
  /** Contact is the asset owner */
  | 'LISTINGOWNER'
  /** Mapped from GO3_NEWOWNER_224120 */
  | 'NEW_OWNER'
  /** Mapped from GO3_NEWTENNANT_234140 */
  | 'NEW_TENANT'
  /** Mapped from GO3_EXOWNER_224130 */
  | 'PREVIOUS_OWNER'
  /** Contact is the representative of the asset owner */
  | 'REPRESENTATIVE'
  /** Mapped from GO3_TENNANT_220250, GO3_TENNANT_230250 */
  | 'TENANT'
  /** Mapped from GO3_FOREIGNCONTACT_224250, GO3_FOREIGNCONTACT_234250 */
  | 'THIRD_PARTY_USAGE_CONTACT'
  /** Contact type of the mandate is undefined */
  | 'UNDEFINED';

export type PropertyEngineMandateType =
  | 'BROKER_MANDATE'
  | 'EXCLUSIVE_MANDATE'
  | 'GENERAL_MANDATE'
  | 'JOINT_HANDLING'
  | 'JOINT_MANDATE'
  | 'NO_FORMAL_MANDATE'
  | 'QUALIFIED_EXCLUSIVE_MANDATE'
  | 'UNDEFINED';

export type PropertyEngineMarketingDetail = {
  __typename?: 'PropertyEngineMarketingDetail';
  assetDescription?: Maybe<Scalars['String']>;
  commissionText?: Maybe<Scalars['String']>;
  id: Scalars['PropertyEngineIdentifier'];
  language: PropertyEngineLanguage;
  locationDescription?: Maybe<Scalars['String']>;
  premiumExposeEmail?: Maybe<PropertyEnginePremiumExposeEmail>;
  title: Scalars['String'];
};

/**  --------------------------------------------------------------------------------------------------------------------- */
export type PropertyEngineMatchingListings = {
  __typename?: 'PropertyEngineMatchingListings';
  listings?: Maybe<Array<PropertyEngineListing>>;
  total?: Maybe<Scalars['Int']>;
};

export type PropertyEngineMatchingState =
  /** Listing has been proposed but did not interest the customer */
  | 'NOT_INTERESTED'
  /** Listing has not been proposed */
  | 'NOT_PROPOSED'
  /** Listing has been proposed */
  | 'PROPOSED';

/**  --------------------------------------------------------------------------------------------------------------------- */
export type PropertyEngineMatchingStatusDetail = {
  __typename?: 'PropertyEngineMatchingStatusDetail';
  buyerToRentSpecification?: Maybe<DbBuyerToRentSpecification>;
  contactId: Scalars['String'];
  createdAt: Scalars['PropertyEngineDateTime'];
  feedback?: Maybe<Scalars['String']>;
  id: Scalars['PropertyEngineIdentifier'];
  listingId: Scalars['PropertyEngineIdentifier'];
  listingUtag?: Maybe<Scalars['String']>;
  manuallyProposed?: Maybe<Scalars['Boolean']>;
  matchingState: PropertyEngineMatchingState;
  searchDemandId?: Maybe<Scalars['PropertyEngineUUID']>;
};

/**  --------------------------------------------------------------------------------------------------------------------- */
export type PropertyEngineMatchingStatusInput = {
  contactId: Scalars['String'];
  feedback?: InputMaybe<Scalars['String']>;
  listingUtag: Scalars['String'];
  matchingState: PropertyEngineMatchingState;
  searchDemandId?: InputMaybe<Scalars['PropertyEngineUUID']>;
};

export type PropertyEngineMatchingStatusOrder = {
  askingPrice?: InputMaybe<PropertyEngineOrderBy>;
  createdAt?: InputMaybe<PropertyEngineOrderBy>;
  matchingState?: InputMaybe<PropertyEngineOrderBy>;
  monthlyNetRent?: InputMaybe<PropertyEngineOrderBy>;
  monthlyTotalRent?: InputMaybe<PropertyEngineOrderBy>;
  numberOfRooms?: InputMaybe<PropertyEngineOrderBy>;
  /**  deprecated for removal */
  price?: InputMaybe<PropertyEngineOrderBy>;
  surfaceLivingArea?: InputMaybe<PropertyEngineOrderBy>;
};

export type PropertyEngineMeasurementSystem =
  /** Imperial measurement system */
  | 'IMPERIAL'
  /** Metric measurement system */
  | 'METRIC';

/**
 *  -------------------------------------------------------------------------------------------------
 *  -------------------------------------------------------------------------------------------------
 *  -------------------------------------------------------------------------------------------------
 */
export type PropertyEngineNode = {
  id: Scalars['PropertyEngineIdentifier'];
  metadata: Scalars['PropertyEngineJSON'];
  name: Scalars['String'];
  parent?: Maybe<PropertyEngineFolder>;
  path: Scalars['String'];
};

/**  --------------------------------------------------------------------------------------------------------------------- */
export type PropertyEngineOffer = {
  __typename?: 'PropertyEngineOffer';
  assignedAgentId?: Maybe<Scalars['PropertyEngineUUID']>;
  comment?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['PropertyEngineDateTime']>;
  createdById?: Maybe<Scalars['PropertyEngineUUID']>;
  id: Scalars['String'];
  isUpdatable: Scalars['Boolean'];
  lastStatusChangedAt?: Maybe<Scalars['PropertyEngineDateTime']>;
  offeredAt?: Maybe<Scalars['PropertyEngineDateTime']>;
  offeredAtLegacy?: Maybe<Scalars['PropertyEngineLocalDateTime']>;
  price: Scalars['Float'];
  status: PropertyEngineOfferStatus;
  updatedAt?: Maybe<Scalars['PropertyEngineDateTime']>;
  updatedById?: Maybe<Scalars['PropertyEngineUUID']>;
};

export type PropertyEngineOfferOrder = {
  lastStatusChangedAt?: InputMaybe<PropertyEngineOrderBy>;
  offeredAt?: InputMaybe<PropertyEngineOrderBy>;
  price?: InputMaybe<PropertyEngineOrderBy>;
  status?: InputMaybe<PropertyEngineOrderBy>;
};

export type PropertyEngineOfferStatus =
  /** Offer has been accepted */
  | 'ACCEPTED'
  /** Most recent offer */
  | 'LATEST'
  /** Offer has been rejected */
  | 'REJECTED';

export type PropertyEngineOffersResponse = {
  __typename?: 'PropertyEngineOffersResponse';
  offers: Array<PropertyEngineOffer>;
  total?: Maybe<Scalars['Int']>;
};

export type PropertyEngineOrderBy = 'ASC' | 'DESC';

export type PropertyEngineOrderConfiguration = {
  __typename?: 'PropertyEngineOrderConfiguration';
  askingPrice?: Maybe<PropertyEngineOrderBy>;
  city?: Maybe<PropertyEngineOrderBy>;
  /**  deprecated for removal */
  contactName?: Maybe<PropertyEngineOrderBy>;
  /**  SearchListingsOrder */
  lastActivation?: Maybe<PropertyEngineOrderBy>;
  maxAskingPrice?: Maybe<PropertyEngineOrderBy>;
  maxListingPrice?: Maybe<PropertyEngineOrderBy>;
  maxMonthlyNetRent?: Maybe<PropertyEngineOrderBy>;
  maxMonthlyTotalRent?: Maybe<PropertyEngineOrderBy>;
  /**  deprecated for removal */
  monthlyNetRent?: Maybe<PropertyEngineOrderBy>;
  monthlyTotalRent?: Maybe<PropertyEngineOrderBy>;
  numberOfBedrooms?: Maybe<PropertyEngineOrderBy>;
  numberOfRooms?: Maybe<PropertyEngineOrderBy>;
  plotSurface?: Maybe<PropertyEngineOrderBy>;
  postalCode?: Maybe<PropertyEngineOrderBy>;
  price?: Maybe<PropertyEngineOrderBy>;
  /**  SearchDemandOrder */
  priority?: Maybe<PropertyEngineOrderBy>;
  propertyName?: Maybe<PropertyEngineOrderBy>;
  streetName?: Maybe<PropertyEngineOrderBy>;
  streetNumber?: Maybe<PropertyEngineOrderBy>;
  surfaceLivingArea?: Maybe<PropertyEngineOrderBy>;
};

export type PropertyEngineOrderConfigurationInput = {
  askingPrice?: InputMaybe<PropertyEngineOrderBy>;
  city?: InputMaybe<PropertyEngineOrderBy>;
  /**  deprecated for removal */
  contactName?: InputMaybe<PropertyEngineOrderBy>;
  /**  SearchListingsOrder */
  lastActivation?: InputMaybe<PropertyEngineOrderBy>;
  maxAskingPrice?: InputMaybe<PropertyEngineOrderBy>;
  maxListingPrice?: InputMaybe<PropertyEngineOrderBy>;
  maxMonthlyNetRent?: InputMaybe<PropertyEngineOrderBy>;
  maxMonthlyTotalRent?: InputMaybe<PropertyEngineOrderBy>;
  /**  deprecated for removal */
  monthlyNetRent?: InputMaybe<PropertyEngineOrderBy>;
  monthlyTotalRent?: InputMaybe<PropertyEngineOrderBy>;
  numberOfBedrooms?: InputMaybe<PropertyEngineOrderBy>;
  numberOfRooms?: InputMaybe<PropertyEngineOrderBy>;
  plotSurface?: InputMaybe<PropertyEngineOrderBy>;
  postalCode?: InputMaybe<PropertyEngineOrderBy>;
  price?: InputMaybe<PropertyEngineOrderBy>;
  /**  SearchDemandOrder */
  priority?: InputMaybe<PropertyEngineOrderBy>;
  propertyName?: InputMaybe<PropertyEngineOrderBy>;
  streetName?: InputMaybe<PropertyEngineOrderBy>;
  streetNumber?: InputMaybe<PropertyEngineOrderBy>;
  surfaceLivingArea?: InputMaybe<PropertyEngineOrderBy>;
};

export type PropertyEngineOrientation =
  /** East */
  | 'EAST'
  /** North */
  | 'NORTH'
  /** Northeast */
  | 'NORTHEAST'
  /** Northwest */
  | 'NORTHWEST'
  /** South */
  | 'SOUTH'
  /** Southeast */
  | 'SOUTHEAST'
  /** Southwest */
  | 'SOUTHWEST'
  /** Undefined */
  | 'UNDEFINED'
  /** West */
  | 'WEST';

export type PropertyEngineOutsideOption =
  /** Children's playground */
  | 'CHILDRENS_PLAYGROUND'
  /** Clubhouse */
  | 'CLUBHOUSE'
  /** Garden irrigation */
  | 'GARDEN_IRRIGATION'
  /** Golf course */
  | 'GOLF_COURSE'
  /** Helicopter pad */
  | 'HELICOPTER_PAD'
  /** Landing strip */
  | 'LANDING_STRIP'
  /** Squash court */
  | 'SQUASH_COURT'
  /** Tennis court */
  | 'TENNIS_COURT';

export type PropertyEnginePagination = {
  __typename?: 'PropertyEnginePagination';
  pageSize: Scalars['Int'];
};

export type PropertyEnginePaginationInput = {
  pageSize: Scalars['Int'];
};

export type PropertyEnginePlaceMultiPolygon = {
  __typename?: 'PropertyEnginePlaceMultiPolygon';
  multiPolygon?: Maybe<Scalars['PropertyEngineMultiPolygon']>;
  placeId: Scalars['String'];
};

/**  --------------------------------------------------------------------------------------------------------------------- */
export type PropertyEnginePortalStatusDetail = {
  __typename?: 'PropertyEnginePortalStatusDetail';
  exportMessages?: Maybe<Array<Maybe<PropertyEnginePortalStatusMessage>>>;
  lastPortalExportId?: Maybe<Scalars['PropertyEngineUUID']>;
  lastSuccessfulUpdate?: Maybe<Scalars['PropertyEngineDateTime']>;
  lastUpdate?: Maybe<Scalars['PropertyEngineDateTime']>;
  portalId: Scalars['PropertyEngineUUID'];
  portalName?: Maybe<Scalars['String']>;
  portalPropertyUrl?: Maybe<Scalars['String']>;
  status: PropertyEnginePortalStatusState;
};

export type PropertyEnginePortalStatusMessage = {
  __typename?: 'PropertyEnginePortalStatusMessage';
  message?: Maybe<Scalars['String']>;
  messageCode?: Maybe<PropertyEnginePortalStatusMessageCode>;
  severity?: Maybe<PropertyEnginePortalStatusMessageSeverity>;
};

export type PropertyEnginePortalStatusMessageCode =
  | 'ANOTHER_OPERATION_ON_SAME_RESOURCE'
  | 'ASKING_PRICE_MISSING_OR_INVALID'
  | 'AVRO_ERROR'
  | 'AVRO_STRUCTURE_NOT_APPROPRIATE'
  | 'BUILDING_YEAR_INVALID'
  | 'CANNOT_CHANGE_PROPERTY_TYPE'
  | 'CO2_EMISSION_MISSING'
  | 'CONSUMPTION_VALUE_MISSING'
  | 'CONTINGENT_MISSING'
  | 'DESCRIPTION_TITLE_MISSING'
  | 'EFFICIENCY_RATING_MISSING'
  | 'EMISSION_RATING_MISSING'
  | 'ENERGY_CONSUMPTION_CONTAINS_WARM_WATER'
  | 'ENERGY_CONSUMPTION_INVALID'
  | 'ENERGY_URBAN_INFORMATION_DEVELOPMENT_PERMIT_MISSING'
  | 'ENERGY_URBAN_INFORMATION_OPTION_TO_BUY_MISSING'
  | 'ENERGY_URBAN_INFORMATION_PERMIT_MISSING'
  | 'ENERGY_URBAN_INFORMATION_PLANNING_MISSING'
  | 'ENERGY_URBAN_INFORMATION_SUMMONS_MISSING'
  | 'EPC_KWH_INVALID'
  | 'EPC_KWH_SQM_MISSING'
  | 'EPC_KWQ_SQM_MISSING'
  | 'FLOOR_STORIES_INTEGER_NEEDED'
  | 'GENERAL_MESSAGE_CODE'
  | 'GEOGRAPHY_ID_INCORRECT'
  | 'GEO_COORDINATES_INVALID'
  | 'GEO_COORDINATES_MISSING'
  | 'HOUSENUMBER_MISSING'
  | 'HOUSING_BENEFITS_INVALID'
  | 'INTERNATIONAL_ADDRESS_NOT_ALLOWED'
  | 'MINIMUM_4_PHOTOS'
  | 'MONTHLY_RENT_MISSING'
  | 'NUMBER_OF_ROOMS_MISSING'
  | 'PLOT_AREA_MISSING'
  | 'PROPERTY_ADDRESS_INVALID'
  | 'PROPERTY_DESCRIPTION_MISSING'
  | 'PROPERTY_SUBTYPE_MISSING'
  | 'REAL_ESTATE_ALREADY_EXISTS_FOR_EXTERNAL_ID'
  | 'RESOURCE_NOT_FOUND'
  | 'RESOURCE_WITH_ID_NOT_FOUND'
  | 'STREET_MISSING'
  | 'THERMAL_CHARACTERISTIC_EMPTY'
  | 'TOTAL_SURFACE_MISSING'
  | 'UNIQUE_CODE_MISSING'
  | 'WRONG_CREDENTIALS'
  | 'ZIPCODE_MISSING_OR_INVALID';

export type PropertyEnginePortalStatusMessageSeverity = 'ERROR' | 'INFO' | 'WARNING';

export type PropertyEnginePortalStatusScope = 'LISTING' | 'SHOP';

export type PropertyEnginePortalStatusState = 'ERROR' | 'NO_INFORMATION' | 'PENDING' | 'SUCCESS';

export type PropertyEnginePremiumExposeEmail = {
  __typename?: 'PropertyEnginePremiumExposeEmail';
  bulletPoints?: Maybe<Array<Maybe<Scalars['String']>>>;
  bulletPointsTitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

export type PropertyEnginePremiumExposeEmailInput = {
  bulletPoints?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bulletPointsTitle?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
};

export type PropertyEnginePropertySubType = {
  __typename?: 'PropertyEnginePropertySubType';
  id: Scalars['String'];
  key: Scalars['Int'];
  name: PropertyEngineAssetSubType;
  variations?: Maybe<Array<PropertyEnginePropertyVariation>>;
};

export type PropertyEnginePropertyType = {
  __typename?: 'PropertyEnginePropertyType';
  id: Scalars['String'];
  key: Scalars['Int'];
  name: PropertyEngineAssetType;
  subTypes: Array<PropertyEnginePropertySubType>;
};

/**  ----------------------------------------------------------------------------------- */
export type PropertyEnginePropertyTypeHierarchy = {
  __typename?: 'PropertyEnginePropertyTypeHierarchy';
  types: Array<PropertyEnginePropertyType>;
};

export type PropertyEnginePropertyVariation = {
  __typename?: 'PropertyEnginePropertyVariation';
  id: Scalars['String'];
  key: Scalars['Int'];
  name: PropertyEngineAssetVariation;
};

export type PropertyEngineProposedListing = {
  __typename?: 'PropertyEngineProposedListing';
  createdAt: Scalars['PropertyEngineDateTime'];
  feedback?: Maybe<Scalars['String']>;
  listing: PropertyEngineListing;
  manuallyProposed: Scalars['Boolean'];
  matchingState: PropertyEngineMatchingState;
};

export type PropertyEngineProposedListingsResponse = {
  __typename?: 'PropertyEngineProposedListingsResponse';
  proposedListings: Array<PropertyEngineProposedListing>;
  total: Scalars['Int'];
};

export type PropertyEngineProxyImageDetail = {
  __typename?: 'PropertyEngineProxyImageDetail';
  descriptions?: Maybe<Array<PropertyEngineImageDescription>>;
  file?: Maybe<PropertyEngineFile>;
  imageUrl: Scalars['String'];
  internalSrcPath: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  pictureType?: Maybe<PropertyEngineFileCategory>;
  proxyHostname: Scalars['String'];
  watermarkedUrl: Scalars['String'];
};

export type PropertyEngineQcEnergyCertificateType =
  /** Consumption Certificate */
  | 'CONSUMPTION_CERTIFICATE'
  /** End-Demand Certificate */
  | 'END_DEMAND_CERTIFICATE'
  /** Certificate is undefined */
  | 'UNDEFINED';

export type PropertyEngineRentPeriod =
  /** Rent must be paid daily */
  | 'DAILY'
  /** Rent must be paid monthly */
  | 'MONTHLY'
  /** Rent must be paid quarterly */
  | 'QUARTERLY'
  /** Rent period is not available */
  | 'UNDEFINED'
  /** Rent must be paid weekly */
  | 'WEEKLY'
  /** Rent must be paid yearly */
  | 'YEARLY';

export type PropertyEngineRequiredFields = {
  __typename?: 'PropertyEngineRequiredFields';
  transitions: Array<Maybe<PropertyEngineStatusTransition>>;
};

export type PropertyEngineSearchDemandAssignmentDetail = {
  __typename?: 'PropertyEngineSearchDemandAssignmentDetail';
  agentId?: Maybe<Scalars['PropertyEngineUUID']>;
  shopId: Scalars['PropertyEngineUUID'];
  teamId?: Maybe<Scalars['PropertyEngineUUID']>;
};

export type PropertyEngineSearchDemandAssignmentInput = {
  agentId?: InputMaybe<Scalars['PropertyEngineUUID']>;
  shopId: Scalars['PropertyEngineUUID'];
  teamId?: InputMaybe<Scalars['PropertyEngineUUID']>;
};

export type PropertyEngineSearchDemandCriteriaDetail = {
  __typename?: 'PropertyEngineSearchDemandCriteriaDetail';
  addressCity?: Maybe<Array<Scalars['String']>>;
  addressCountryCode?: Maybe<PropertyEngineCountryCode>;
  addressPostalCode?: Maybe<Array<Scalars['String']>>;
  askingPrice?: Maybe<PropertyEngineDecimalRange>;
  assetLivingArea?: Maybe<PropertyEngineDecimalRange>;
  assetNumberOfBedrooms?: Maybe<PropertyEngineIntegerRange>;
  assetNumberOfRooms?: Maybe<PropertyEngineDecimalRange>;
  assetPlotSurface?: Maybe<PropertyEngineIntegerRange>;
  assetSubTypes?: Maybe<Array<PropertyEngineAssetSubType>>;
  assetTotalSurface?: Maybe<PropertyEngineDecimalRange>;
  assetType?: Maybe<PropertyEngineAssetType>;
  assetVariations?: Maybe<Array<PropertyEngineAssetVariation>>;
  cdfs?: Maybe<Array<Scalars['PropertyEngineUUID']>>;
  currency: Scalars['PropertyEngineCurrency'];
  geoPlaceId?: Maybe<Scalars['String']>;
  geoPlaceIds?: Maybe<Array<Scalars['String']>>;
  legacyAreaId?: Maybe<Array<Scalars['Int']>>;
  legacyAreaName?: Maybe<Array<Scalars['String']>>;
  legacyDistrictId?: Maybe<Array<Scalars['Int']>>;
  legacyDistrictName?: Maybe<Array<Scalars['String']>>;
  listingId?: Maybe<Scalars['PropertyEngineIdentifier']>;
  listingPrice?: Maybe<PropertyEngineDecimalRange>;
  listingShortTermRental?: Maybe<Scalars['Boolean']>;
  listingType: PropertyEngineListingType;
  monthlyNetRent?: Maybe<PropertyEngineDecimalRange>;
  monthlyTotalRent?: Maybe<PropertyEngineDecimalRange>;
  multiPolygon?: Maybe<Scalars['PropertyEngineMultiPolygon']>;
};

export type PropertyEngineSearchDemandCriteriaInput = {
  /**  @deprecated(reason: "to be removed") */
  addressCity?: InputMaybe<Array<Scalars['String']>>;
  /**  @deprecated(reason: "to be removed") */
  addressCountryCode?: InputMaybe<PropertyEngineCountryCode>;
  /**  @deprecated(reason: "to be removed") */
  addressPostalCode?: InputMaybe<Array<Scalars['String']>>;
  askingPrice?: InputMaybe<PropertyEngineDecimalRangeInput>;
  assetLivingArea?: InputMaybe<PropertyEngineDecimalRangeInput>;
  assetNumberOfBedrooms?: InputMaybe<PropertyEngineIntegerRangeInput>;
  assetNumberOfRooms?: InputMaybe<PropertyEngineDecimalRangeInput>;
  assetPlotSurface?: InputMaybe<PropertyEngineIntegerRangeInput>;
  assetSubTypes?: InputMaybe<Array<PropertyEngineAssetSubType>>;
  assetTotalSurface?: InputMaybe<PropertyEngineDecimalRangeInput>;
  assetType?: InputMaybe<PropertyEngineAssetType>;
  assetVariations?: InputMaybe<Array<PropertyEngineAssetVariation>>;
  cdfs?: InputMaybe<Array<Scalars['PropertyEngineUUID']>>;
  /**  @deprecated(reason: "to be removed") */
  currency?: InputMaybe<Scalars['PropertyEngineCurrency']>;
  geoPlaceIds?: InputMaybe<Array<Scalars['String']>>;
  legacyAreaId?: InputMaybe<Array<Scalars['Int']>>;
  legacyAreaName?: InputMaybe<Array<Scalars['String']>>;
  legacyDistrictId?: InputMaybe<Array<Scalars['Int']>>;
  legacyDistrictName?: InputMaybe<Array<Scalars['String']>>;
  listingPrice?: InputMaybe<PropertyEngineDecimalRangeInput>;
  listingShortTermRental?: InputMaybe<Scalars['Boolean']>;
  listingType: PropertyEngineListingType;
  monthlyNetRent?: InputMaybe<PropertyEngineDecimalRangeInput>;
  monthlyTotalRent?: InputMaybe<PropertyEngineDecimalRangeInput>;
  multiPolygon?: InputMaybe<Scalars['PropertyEngineMultiPolygon']>;
};

export type PropertyEngineSearchDemandDetail = {
  __typename?: 'PropertyEngineSearchDemandDetail';
  agents: Array<PropertyEngineAgentDetail>;
  comment?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  contactLastName?: Maybe<Scalars['String']>;
  criteria: PropertyEngineSearchDemandCriteriaDetail;
  id: Scalars['PropertyEngineUUID'];
  leadId?: Maybe<Scalars['String']>;
  priority?: Maybe<PropertyEngineSearchDemandPriority>;
  state?: Maybe<Array<PropertyEngineMatchingStatusDetail>>;
  status: PropertyEngineSearchDemandStatus;
  validFrom?: Maybe<Scalars['PropertyEngineDate']>;
  validTo?: Maybe<Scalars['PropertyEngineDate']>;
};

/**  --------------------------------------------------------------------------------------------------------------------- */
export type PropertyEngineSearchDemandDetailResponse = {
  __typename?: 'PropertyEngineSearchDemandDetailResponse';
  searchDemandDetails: Array<PropertyEngineSearchDemandDetail>;
  total?: Maybe<Scalars['Int']>;
  totalUniqueContacts?: Maybe<Scalars['Int']>;
};

/**  --------------------------------------------------------------------------------------------------------------------- */
export type PropertyEngineSearchDemandOrder = {
  contactName?: InputMaybe<PropertyEngineOrderBy>;
  maxAskingPrice?: InputMaybe<PropertyEngineOrderBy>;
  /**  deprecated for removal */
  maxListingPrice?: InputMaybe<PropertyEngineOrderBy>;
  maxMonthlyNetRent?: InputMaybe<PropertyEngineOrderBy>;
  maxMonthlyTotalRent?: InputMaybe<PropertyEngineOrderBy>;
  priority?: InputMaybe<PropertyEngineOrderBy>;
};

export type PropertyEngineSearchDemandPriority = 'A' | 'B' | 'C' | 'UNDEFINED';

export type PropertyEngineSearchDemandStatus =
  /** Search-demand is active */
  | 'ACTIVE'
  /** Search-demand has been closed */
  | 'CLOSED'
  /** Search-demand has expired */
  | 'EXPIRED'
  /** Search-demand is inactive */
  | 'INACTIVE'
  /** Search-demand is invalid */
  | 'INVALID';

export type PropertyEngineSearchListingsFilterInput = {
  askingPrice?: InputMaybe<PropertyEngineDecimalRangeInput>;
  assetSubTypes?: InputMaybe<Array<PropertyEngineAssetSubType>>;
  assetType?: InputMaybe<PropertyEngineAssetType>;
  assetVariations?: InputMaybe<Array<PropertyEngineAssetVariation>>;
  assignedAgentId?: InputMaybe<Scalars['PropertyEngineUUID']>;
  assignedAgentKey?: InputMaybe<Scalars['PropertyEngineLong']>;
  assignedTeamIds?: InputMaybe<Array<Scalars['PropertyEngineUUID']>>;
  boundingBox?: InputMaybe<PropertyEngineBoundingBox>;
  cdfs?: InputMaybe<Array<Scalars['PropertyEngineUUID']>>;
  city?: InputMaybe<Scalars['String']>;
  closingDate?: InputMaybe<PropertyEngineDateRangeInput>;
  countryCode?: InputMaybe<PropertyEngineCountryCode>;
  fileAs?: InputMaybe<Scalars['String']>;
  keyInShop?: InputMaybe<Scalars['Boolean']>;
  lastActivationDate?: InputMaybe<PropertyEngineDateRangeInput>;
  lastOwnerChange?: InputMaybe<PropertyEngineDateRangeInput>;
  lastPriceChange?: InputMaybe<PropertyEngineDateRangeInput>;
  lastPriceReduction?: InputMaybe<PropertyEngineDateRangeInput>;
  listingStatus?: InputMaybe<Array<PropertyEngineListingStatus>>;
  listingType?: InputMaybe<PropertyEngineListingType>;
  monthlyNetRent?: InputMaybe<PropertyEngineDecimalRangeInput>;
  monthlyNetRentBySurfSys?: InputMaybe<PropertyEngineDecimalRangeInput>;
  multiPolygon?: InputMaybe<Scalars['PropertyEngineMultiPolygon']>;
  numberOfBathrooms?: InputMaybe<PropertyEngineIntegerRangeInput>;
  numberOfBedrooms?: InputMaybe<PropertyEngineIntegerRangeInput>;
  numberOfRooms?: InputMaybe<PropertyEngineDecimalRangeInput>;
  ownerContactId?: InputMaybe<Scalars['String']>;
  ownerContactKey?: InputMaybe<Scalars['PropertyEngineLong']>;
  /** placeId is deprecated but FE cannot generate their models for deprecated fields... */
  placeId?: InputMaybe<Scalars['String']>;
  placeIds?: InputMaybe<Array<Scalars['String']>>;
  plotSurface?: InputMaybe<PropertyEngineIntegerRangeInput>;
  postalCode?: InputMaybe<Scalars['String']>;
  propertyName?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  signboard?: InputMaybe<Scalars['Boolean']>;
  streetName?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  surfaceLivingArea?: InputMaybe<PropertyEngineDecimalRangeInput>;
  towerName?: InputMaybe<Scalars['String']>;
  yearOfConstruction?: InputMaybe<PropertyEngineIntegerRangeInput>;
};

export type PropertyEngineSearchListingsOrder = {
  askingPrice?: InputMaybe<PropertyEngineOrderBy>;
  city?: InputMaybe<PropertyEngineOrderBy>;
  lastActivation?: InputMaybe<PropertyEngineOrderBy>;
  monthlyNetRent?: InputMaybe<PropertyEngineOrderBy>;
  monthlyTotalRent?: InputMaybe<PropertyEngineOrderBy>;
  numberOfBedrooms?: InputMaybe<PropertyEngineOrderBy>;
  numberOfRooms?: InputMaybe<PropertyEngineOrderBy>;
  plotSurface?: InputMaybe<PropertyEngineOrderBy>;
  postalCode?: InputMaybe<PropertyEngineOrderBy>;
  /**  deprecated for removal */
  price?: InputMaybe<PropertyEngineOrderBy>;
  propertyName?: InputMaybe<PropertyEngineOrderBy>;
  streetName?: InputMaybe<PropertyEngineOrderBy>;
  streetNumber?: InputMaybe<PropertyEngineOrderBy>;
  surfaceLivingArea?: InputMaybe<PropertyEngineOrderBy>;
};

export type PropertyEngineSearchListingsResponse = {
  __typename?: 'PropertyEngineSearchListingsResponse';
  listings?: Maybe<Array<PropertyEngineListing>>;
  placeMultiPolygons?: Maybe<Array<PropertyEnginePlaceMultiPolygon>>;
  total?: Maybe<Scalars['Int']>;
};

export type PropertyEngineSecurityOption =
  /** Alarm-system */
  | 'ALARM_SYSTEM'
  /** Electric fence */
  | 'ELECTRIC_FENCE'
  /** Electronic gate */
  | 'ELECTRONIC_GATE'
  /** Safety door/Triple lock */
  | 'SAFETY_DOOR_OR_TRIPLE_LOCK'
  /** Security 24h */
  | 'SECURITY_24H'
  /** Security estate */
  | 'SECURITY_ESTATE';

export type PropertyEngineShopFeatureResponse = {
  __typename?: 'PropertyEngineShopFeatureResponse';
  automatedExposeEnabled: Scalars['Boolean'];
  energyAdvisoryEnabled: Scalars['Boolean'];
};

export type PropertyEngineShopLevelResult = {
  __typename?: 'PropertyEngineShopLevelResult';
  shopId?: Maybe<Scalars['PropertyEngineUUID']>;
  shopLevel?: Maybe<Scalars['Int']>;
};

export type PropertyEngineStatusTransition = {
  __typename?: 'PropertyEngineStatusTransition';
  requiredFields: Array<PropertyEngineListingRequiredField>;
  status: PropertyEngineListingStatus;
  transitionAllowed: Scalars['Boolean'];
};

export type PropertyEngineSwimmingPoolType =
  /** Heated indoor swimming-pool */
  | 'HEATED_INDOOR'
  /** Heated outdoor swimming-pool */
  | 'HEATED_OUTDOOR'
  /** Others */
  | 'OTHERS'
  /** Unheated indoor swimming-pool */
  | 'UNHEATED_INDOOR'
  /** Unheated outdoor swimming-pool */
  | 'UNHEATED_OUTDOOR';

export type PropertyEngineTableConfiguration = {
  __typename?: 'PropertyEngineTableConfiguration';
  columnOrder?: Maybe<Array<Scalars['String']>>;
  columnPinning?: Maybe<PropertyEngineColumnPinning>;
  /** Data type is Map<String, Integer> but could not be typed with GraphQL. Types are validated on update. */
  columnSizing?: Maybe<Scalars['PropertyEngineJSON']>;
  /** Data type is Map<String, Boolean> but could not be typed with GraphQL. Types are validated on update. */
  columnVisibility?: Maybe<Scalars['PropertyEngineJSON']>;
  pagination?: Maybe<PropertyEnginePagination>;
  sorting?: Maybe<Array<PropertyEngineColumnSorting>>;
};

export type PropertyEngineTableConfigurationInput = {
  columnOrder?: InputMaybe<Array<Scalars['String']>>;
  columnPinning?: InputMaybe<PropertyEngineColumnPinningInput>;
  /** Data type is Map<String, Integer> but could not be typed with GraphQL. Types are validated on update. */
  columnSizing?: InputMaybe<Scalars['PropertyEngineJSON']>;
  /** Data type is Map<String, Boolean> but could not be typed with GraphQL. Types are validated on update. */
  columnVisibility?: InputMaybe<Scalars['PropertyEngineJSON']>;
  pagination?: InputMaybe<PropertyEnginePaginationInput>;
  sorting?: InputMaybe<Array<PropertyEngineColumnSortingInput>>;
};

export type PropertyEngineTeam = {
  __typename?: 'PropertyEngineTeam';
  id: Scalars['PropertyEngineUUID'];
  name: Scalars['String'];
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineTenureCondition =
  /** Assured Shorthold Tenancy */
  | 'ASSURED_SHORTHOLD_TENANCY'
  /** Common Law Tenancy */
  | 'COMMON_LAW_TENANCY'
  /** Sectional Title */
  | 'SECTIONAL_TITLE'
  /** Shareblock */
  | 'SHAREBLOCK'
  /** Syndication/Fract. Ownership */
  | 'SYNDICATION_FRACT_OWNERSHIP';

export type PropertyEngineThreeAnswers =
  /** No */
  | 'NO'
  /** Yes, conform */
  | 'YES_CONFORM'
  /** Yes, not conform */
  | 'YES_NOT_CONFORM';

export type PropertyEngineUpdateContactNameInput = {
  contactId: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type PropertyEngineUpdateContactNameResponse = {
  __typename?: 'PropertyEngineUpdateContactNameResponse';
  success: Scalars['Boolean'];
};

export type PropertyEngineUpdateSearchDemandAssignmentInput = {
  agentId?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['String']>;
};

export type PropertyEngineUpdateSearchDemandInput = {
  contactFirstName?: InputMaybe<Scalars['String']>;
  contactId?: InputMaybe<Scalars['String']>;
  contactLastName?: InputMaybe<Scalars['String']>;
  criteria?: InputMaybe<PropertyEngineSearchDemandCriteriaInput>;
  leadId: Scalars['String'];
  priority?: InputMaybe<PropertyEngineSearchDemandPriority>;
  status?: InputMaybe<PropertyEngineSearchDemandStatus>;
};

/**  --------------------------------------------------------------------------------------------------------------------- */
export type PropertyEngineUpdateSearchDemandStatusInput = {
  searchDemandId: Scalars['PropertyEngineUUID'];
  searchDemandStatus: PropertyEngineSearchDemandStatus;
};

export type PropertyEngineUpdateSearchDemandStatusResponse = {
  __typename?: 'PropertyEngineUpdateSearchDemandStatusResponse';
  success: Scalars['Boolean'];
};

/**  --------------------------------------------------------------------------------------------------------------------- */
export type PropertyEngineUpdateShopGroupRelationsItemInput = {
  groupId: Scalars['PropertyEngineUUID'];
  shopIds: Array<Scalars['PropertyEngineUUID']>;
};

export type PropertyEngineUrbanInformationLatestLandUseDesignation =
  /** Agricultural area (Ag) */
  | 'AG'
  /** Forest area (Bg) */
  | 'BG'
  /** Area for day recreation (Gdr) */
  | 'GDR'
  /** Area for residential recreation (Gvr) */
  | 'GVR'
  /** Industrial area for craft enterprises or areas for small and medium-sized enterprises (Iab) */
  | 'IAB'
  /** Industrial area (Igb) */
  | 'IGB'
  /** Agricultural area of scenic value (Lwag) */
  | 'LWAG'
  /** Natural area (Ng) */
  | 'NG'
  /** Nature Reserve (Nr) */
  | 'NR'
  /** Park area (Pg) */
  | 'PG'
  /** Urban zoning not available (Sbnb) */
  | 'SBNB'
  /** Residential area with cultural, historical and/or aesthetic value (Wche) */
  | 'WCHE'
  /** Residential area (Wg) */
  | 'WG'
  /** Residential park (Wp) */
  | 'WP'
  /** Residential extension area (Wug) */
  | 'WUG';

export type PropertyEngineUrbanInformationPlanningPermissionType =
  /** No permit issued (Gvg) */
  | 'GVG'
  /** Permit issued (Vg) */
  | 'VG'
  /** Permit not available (Vgnb) */
  | 'VGNB';

export type PropertyEngineUrbanInformationPreemptiveRightOption =
  /** No pre-emption right available (Gvkr) */
  | 'GVKR'
  /** Spatial planning pre-emption right present (Vkr) */
  | 'VKR'
  /** Pre-emption right  not available (Vkrnb) */
  | 'VKRNB';

export type PropertyEngineUrbanInformationProceedingsPlanningRegulationsBreach =
  /** Bo */
  | 'BO'
  /** Dv */
  | 'DV'
  /** Gmo */
  | 'GMO'
  /** Ho */
  | 'HO'
  /** Lod */
  | 'LOD'
  /** Ms */
  | 'MS'
  /** Nb */
  | 'NB';

export type PropertyEngineUrbanInformationSubdivisionPermit =
  /** No subdivision permit (Gvv) */
  | 'GVV'
  /** Subdivision permit (Vv) */
  | 'VV'
  /** Subdivision permit not available (Vvnb) */
  | 'VVNB';

export type PropertyEngineUsageType =
  /** Private */
  | 'PRIVATE'
  /** Shared */
  | 'SHARED';

export type PropertyEngineUserDataScope =
  /** Rental scope */
  | 'RENTAL_LIST'
  /** Sale scope */
  | 'SALE_LIST';

export type PropertyEngineUtilizationType =
  /** For own use */
  | 'FOR_OWN_USE'
  /** For third part use */
  | 'FOR_THIRD_PARTY_USE';

export type PropertyEngineViewOption =
  /** Beach front view */
  | 'BEACH_FRONT_VIEW'
  /** Building view */
  | 'BUILDING_VIEW'
  /** Green view */
  | 'GREEN_VIEW'
  /** Harbour view */
  | 'HARBOUR_VIEW'
  /** Lake view */
  | 'LAKE_VIEW'
  /** Mountain view */
  | 'MOUNTAIN_VIEW'
  /** Ocean view */
  | 'OCEAN_VIEW'
  /** Open view */
  | 'OPEN_VIEW'
  /** Racecourse view */
  | 'RACECOURSE_VIEW';

export type PropertyEngineWaterLocationType =
  /** Direct access to a lake */
  | 'DIRECT_ACCESS_TO_LAKE'
  /** Direct access to a river */
  | 'DIRECT_ACCESS_TO_RIVER'
  /** Direct access to a sea */
  | 'DIRECT_ACCESS_TO_SEA'
  /** In front of a lake */
  | 'IN_FRONT_OF_LAKE'
  /** In front of an ocean */
  | 'IN_FRONT_OF_OCEAN'
  /** In front of a river */
  | 'IN_FRONT_OF_RIVER'
  /** Landing stage */
  | 'LANDING_STAGE';

export type PropertyEngineWellnessOption =
  /** Gym */
  | 'GYM'
  /** Jacuzzi */
  | 'JACUZZI'
  /** Sauna */
  | 'SAUNA';

export type PropertyEngineWindowDisplay = {
  __typename?: 'PropertyEngineWindowDisplay';
  downloadUrl?: Maybe<Scalars['PropertyEngineUrl']>;
  id: Scalars['PropertyEngineIdentifier'];
  languages: Array<PropertyEngineLanguage>;
};

export type PropertyEngineService = {
  __typename?: 'PropertyEngine_Service';
  sdl: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringArrayComparisonExp = {
  /** is the array contained in the given array value */
  _containedIn?: InputMaybe<Array<Scalars['String']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']>>;
  _eq?: InputMaybe<Array<Scalars['String']>>;
  _gt?: InputMaybe<Array<Scalars['String']>>;
  _gte?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']>>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Array<Scalars['String']>>;
  _lte?: InputMaybe<Array<Scalars['String']>>;
  _neq?: InputMaybe<Array<Scalars['String']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type TimestampComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type DbAgentAggregateBoolExpBoolAnd = {
  arguments: DbAgentSelectColumnDbAgentAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbAgentBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbAgentAggregateBoolExpBoolOr = {
  arguments: DbAgentSelectColumnDbAgentAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbAgentBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbAgentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbAgentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbAgentBoolExp>;
  predicate: IntComparisonExp;
};

export type DbAgentDefaultEmailSignatureAggregateBoolExpBoolAnd = {
  arguments: DbAgentDefaultEmailSignatureSelectColumnDbAgentDefaultEmailSignatureAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbAgentDefaultEmailSignatureBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbAgentDefaultEmailSignatureAggregateBoolExpBoolOr = {
  arguments: DbAgentDefaultEmailSignatureSelectColumnDbAgentDefaultEmailSignatureAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbAgentDefaultEmailSignatureBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbAgentDefaultEmailSignatureAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbAgentDefaultEmailSignatureSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbAgentDefaultEmailSignatureBoolExp>;
  predicate: IntComparisonExp;
};

export type DbAgentDefaultNylasEmailIntegrationAggregateBoolExpBoolAnd = {
  arguments: DbAgentDefaultNylasEmailIntegrationSelectColumnDbAgentDefaultNylasEmailIntegrationAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbAgentDefaultNylasEmailIntegrationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbAgentDefaultNylasEmailIntegrationAggregateBoolExpBoolOr = {
  arguments: DbAgentDefaultNylasEmailIntegrationSelectColumnDbAgentDefaultNylasEmailIntegrationAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbAgentDefaultNylasEmailIntegrationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbAgentDefaultNylasEmailIntegrationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbAgentDefaultNylasEmailIntegrationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbAgentDefaultNylasEmailIntegrationBoolExp>;
  predicate: IntComparisonExp;
};

export type DbAgentProfileAggregateBoolExpBoolAnd = {
  arguments: DbAgentProfileSelectColumnDbAgentProfileAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbAgentProfileBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbAgentProfileAggregateBoolExpBoolOr = {
  arguments: DbAgentProfileSelectColumnDbAgentProfileAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbAgentProfileBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbAgentProfileAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbAgentProfileSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbAgentProfileBoolExp>;
  predicate: IntComparisonExp;
};

export type DbAgentProfileCityDistrictAggregateBoolExpBoolAnd = {
  arguments: DbAgentProfileCityDistrictSelectColumnDbAgentProfileCityDistrictAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbAgentProfileCityDistrictBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbAgentProfileCityDistrictAggregateBoolExpBoolOr = {
  arguments: DbAgentProfileCityDistrictSelectColumnDbAgentProfileCityDistrictAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbAgentProfileCityDistrictBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbAgentProfileCityDistrictAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbAgentProfileCityDistrictSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbAgentProfileCityDistrictBoolExp>;
  predicate: IntComparisonExp;
};

export type DbAgentProfileDescriptionAggregateBoolExpBoolAnd = {
  arguments: DbAgentProfileDescriptionSelectColumnDbAgentProfileDescriptionAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbAgentProfileDescriptionBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbAgentProfileDescriptionAggregateBoolExpBoolOr = {
  arguments: DbAgentProfileDescriptionSelectColumnDbAgentProfileDescriptionAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbAgentProfileDescriptionBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbAgentProfileDescriptionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbAgentProfileDescriptionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbAgentProfileDescriptionBoolExp>;
  predicate: IntComparisonExp;
};

export type DbAgentProfileExpertiseAggregateBoolExpBoolAnd = {
  arguments: DbAgentProfileExpertiseSelectColumnDbAgentProfileExpertiseAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbAgentProfileExpertiseBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbAgentProfileExpertiseAggregateBoolExpBoolOr = {
  arguments: DbAgentProfileExpertiseSelectColumnDbAgentProfileExpertiseAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbAgentProfileExpertiseBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbAgentProfileExpertiseAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbAgentProfileExpertiseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbAgentProfileExpertiseBoolExp>;
  predicate: IntComparisonExp;
};

export type DbAgentProfileSpokenLanguageAggregateBoolExpBoolAnd = {
  arguments: DbAgentProfileSpokenLanguageSelectColumnDbAgentProfileSpokenLanguageAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbAgentProfileSpokenLanguageBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbAgentProfileSpokenLanguageAggregateBoolExpBoolOr = {
  arguments: DbAgentProfileSpokenLanguageSelectColumnDbAgentProfileSpokenLanguageAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbAgentProfileSpokenLanguageBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbAgentProfileSpokenLanguageAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbAgentProfileSpokenLanguageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbAgentProfileSpokenLanguageBoolExp>;
  predicate: IntComparisonExp;
};

export type DbBaseActivityAggregateBoolExpBoolAnd = {
  arguments: DbBaseActivitySelectColumnDbBaseActivityAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbBaseActivityBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbBaseActivityAggregateBoolExpBoolOr = {
  arguments: DbBaseActivitySelectColumnDbBaseActivityAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbBaseActivityBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbBaseActivityAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbBaseActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbBaseActivityBoolExp>;
  predicate: IntComparisonExp;
};

export type DbCallAggregateBoolExpBoolAnd = {
  arguments: DbCallSelectColumnDbCallAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbCallBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbCallAggregateBoolExpBoolOr = {
  arguments: DbCallSelectColumnDbCallAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbCallBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbCallAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbCallSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbCallBoolExp>;
  predicate: IntComparisonExp;
};

export type DbConsentAggregateBoolExpBoolAnd = {
  arguments: DbConsentSelectColumnDbConsentAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbConsentBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbConsentAggregateBoolExpBoolOr = {
  arguments: DbConsentSelectColumnDbConsentAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbConsentBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbConsentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbConsentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbConsentBoolExp>;
  predicate: IntComparisonExp;
};

export type DbConsentEventAggregateBoolExpBoolAnd = {
  arguments: DbConsentEventSelectColumnDbConsentEventAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbConsentEventBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbConsentEventAggregateBoolExpBoolOr = {
  arguments: DbConsentEventSelectColumnDbConsentEventAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbConsentEventBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbConsentEventAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbConsentEventSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbConsentEventBoolExp>;
  predicate: IntComparisonExp;
};

export type DbContactAddressAggregateBoolExpBoolAnd = {
  arguments: DbContactAddressSelectColumnDbContactAddressAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbContactAddressBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbContactAddressAggregateBoolExpBoolOr = {
  arguments: DbContactAddressSelectColumnDbContactAddressAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbContactAddressBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbContactAddressAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbContactAddressSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbContactAddressBoolExp>;
  predicate: IntComparisonExp;
};

export type DbContactAggregateBoolExpBoolAnd = {
  arguments: DbContactSelectColumnDbContactAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbContactBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbContactAggregateBoolExpBoolOr = {
  arguments: DbContactSelectColumnDbContactAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbContactBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbContactAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbContactSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbContactBoolExp>;
  predicate: IntComparisonExp;
};

export type DbContactEmailAddressAggregateBoolExpBoolAnd = {
  arguments: DbContactEmailAddressSelectColumnDbContactEmailAddressAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbContactEmailAddressBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbContactEmailAddressAggregateBoolExpBoolOr = {
  arguments: DbContactEmailAddressSelectColumnDbContactEmailAddressAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbContactEmailAddressBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbContactEmailAddressAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbContactEmailAddressSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbContactEmailAddressBoolExp>;
  predicate: IntComparisonExp;
};

export type DbContactPhoneNumberAggregateBoolExpBoolAnd = {
  arguments: DbContactPhoneNumberSelectColumnDbContactPhoneNumberAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbContactPhoneNumberBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbContactPhoneNumberAggregateBoolExpBoolOr = {
  arguments: DbContactPhoneNumberSelectColumnDbContactPhoneNumberAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbContactPhoneNumberBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbContactPhoneNumberAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbContactPhoneNumberSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbContactPhoneNumberBoolExp>;
  predicate: IntComparisonExp;
};

export type DbContactPreferredTimeAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbContactPreferredTimeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbContactPreferredTimeBoolExp>;
  predicate: IntComparisonExp;
};

export type DbContactPreferredWayAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbContactPreferredWaySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbContactPreferredWayBoolExp>;
  predicate: IntComparisonExp;
};

export type DbDocumentReferenceAggregateBoolExpBoolAnd = {
  arguments: DbDocumentReferenceSelectColumnDbDocumentReferenceAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbDocumentReferenceBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbDocumentReferenceAggregateBoolExpBoolOr = {
  arguments: DbDocumentReferenceSelectColumnDbDocumentReferenceAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbDocumentReferenceBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbDocumentReferenceAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbDocumentReferenceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbDocumentReferenceBoolExp>;
  predicate: IntComparisonExp;
};

export type DbEmailAttachmentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbEmailAttachmentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbEmailAttachmentBoolExp>;
  predicate: IntComparisonExp;
};

export type DbEmailCallbackEventsAggregateBoolExpBoolAnd = {
  arguments: DbEmailCallbackEventsSelectColumnDbEmailCallbackEventsAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbEmailCallbackEventsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbEmailCallbackEventsAggregateBoolExpBoolOr = {
  arguments: DbEmailCallbackEventsSelectColumnDbEmailCallbackEventsAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbEmailCallbackEventsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbEmailCallbackEventsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbEmailCallbackEventsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbEmailCallbackEventsBoolExp>;
  predicate: IntComparisonExp;
};

export type DbEmailLinkAggregateBoolExpBoolAnd = {
  arguments: DbEmailLinkSelectColumnDbEmailLinkAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbEmailLinkBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbEmailLinkAggregateBoolExpBoolOr = {
  arguments: DbEmailLinkSelectColumnDbEmailLinkAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbEmailLinkBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbEmailLinkAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbEmailLinkSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbEmailLinkBoolExp>;
  predicate: IntComparisonExp;
};

export type DbEmailPlaceholderAggregateBoolExpBoolAnd = {
  arguments: DbEmailPlaceholderSelectColumnDbEmailPlaceholderAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbEmailPlaceholderBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbEmailPlaceholderAggregateBoolExpBoolOr = {
  arguments: DbEmailPlaceholderSelectColumnDbEmailPlaceholderAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbEmailPlaceholderBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbEmailPlaceholderAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbEmailPlaceholderSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbEmailPlaceholderBoolExp>;
  predicate: IntComparisonExp;
};

export type DbEmailSignatureAggregateBoolExpBoolAnd = {
  arguments: DbEmailSignatureSelectColumnDbEmailSignatureAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbEmailSignatureBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbEmailSignatureAggregateBoolExpBoolOr = {
  arguments: DbEmailSignatureSelectColumnDbEmailSignatureAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbEmailSignatureBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbEmailSignatureAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbEmailSignatureSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbEmailSignatureBoolExp>;
  predicate: IntComparisonExp;
};

export type DbEmailTemplateAggregateBoolExpBoolAnd = {
  arguments: DbEmailTemplateSelectColumnDbEmailTemplateAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbEmailTemplateBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbEmailTemplateAggregateBoolExpBoolOr = {
  arguments: DbEmailTemplateSelectColumnDbEmailTemplateAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbEmailTemplateBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbEmailTemplateAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbEmailTemplateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbEmailTemplateBoolExp>;
  predicate: IntComparisonExp;
};

export type DbEmailTemplateContentAggregateBoolExpBoolAnd = {
  arguments: DbEmailTemplateContentSelectColumnDbEmailTemplateContentAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbEmailTemplateContentBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbEmailTemplateContentAggregateBoolExpBoolOr = {
  arguments: DbEmailTemplateContentSelectColumnDbEmailTemplateContentAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbEmailTemplateContentBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbEmailTemplateContentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbEmailTemplateContentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbEmailTemplateContentBoolExp>;
  predicate: IntComparisonExp;
};

export type DbEmailTrackingAggregateBoolExpBoolAnd = {
  arguments: DbEmailTrackingSelectColumnDbEmailTrackingAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbEmailTrackingBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbEmailTrackingAggregateBoolExpBoolOr = {
  arguments: DbEmailTrackingSelectColumnDbEmailTrackingAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbEmailTrackingBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbEmailTrackingAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbEmailTrackingSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbEmailTrackingBoolExp>;
  predicate: IntComparisonExp;
};

export type DbImportLeadRequestAggregateBoolExpBoolAnd = {
  arguments: DbImportLeadRequestSelectColumnDbImportLeadRequestAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbImportLeadRequestBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbImportLeadRequestAggregateBoolExpBoolOr = {
  arguments: DbImportLeadRequestSelectColumnDbImportLeadRequestAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbImportLeadRequestBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbImportLeadRequestAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbImportLeadRequestSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbImportLeadRequestBoolExp>;
  predicate: IntComparisonExp;
};

export type DbLeadAggregateBoolExpBoolAnd = {
  arguments: DbLeadSelectColumnDbLeadAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbLeadBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbLeadAggregateBoolExpBoolOr = {
  arguments: DbLeadSelectColumnDbLeadAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbLeadBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbLeadAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbLeadSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbLeadBoolExp>;
  predicate: IntComparisonExp;
};

export type DbLeadhubGo3ContactLinkAggregateBoolExpBoolAnd = {
  arguments: DbLeadhubGo3ContactLinkSelectColumnDbLeadhubGo3ContactLinkAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbLeadhubGo3ContactLinkAggregateBoolExpBoolOr = {
  arguments: DbLeadhubGo3ContactLinkSelectColumnDbLeadhubGo3ContactLinkAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbLeadhubGo3ContactLinkAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbLeadhubGo3ContactLinkSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
  predicate: IntComparisonExp;
};

export type DbLicensePartnerProfileAggregateBoolExpBoolAnd = {
  arguments: DbLicensePartnerProfileSelectColumnDbLicensePartnerProfileAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbLicensePartnerProfileBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbLicensePartnerProfileAggregateBoolExpBoolOr = {
  arguments: DbLicensePartnerProfileSelectColumnDbLicensePartnerProfileAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbLicensePartnerProfileBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbLicensePartnerProfileAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbLicensePartnerProfileSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbLicensePartnerProfileBoolExp>;
  predicate: IntComparisonExp;
};

export type DbLicensePartnerProfileImageAggregateBoolExpBoolAnd = {
  arguments: DbLicensePartnerProfileImageSelectColumnDbLicensePartnerProfileImageAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbLicensePartnerProfileImageBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbLicensePartnerProfileImageAggregateBoolExpBoolOr = {
  arguments: DbLicensePartnerProfileImageSelectColumnDbLicensePartnerProfileImageAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbLicensePartnerProfileImageBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbLicensePartnerProfileImageAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbLicensePartnerProfileImageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbLicensePartnerProfileImageBoolExp>;
  predicate: IntComparisonExp;
};

export type DbLicensePartnerProfileLanguageVariantAggregateBoolExpBoolAnd = {
  arguments: DbLicensePartnerProfileLanguageVariantSelectColumnDbLicensePartnerProfileLanguageVariantAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbLicensePartnerProfileLanguageVariantBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbLicensePartnerProfileLanguageVariantAggregateBoolExpBoolOr = {
  arguments: DbLicensePartnerProfileLanguageVariantSelectColumnDbLicensePartnerProfileLanguageVariantAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbLicensePartnerProfileLanguageVariantBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbLicensePartnerProfileLanguageVariantAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbLicensePartnerProfileLanguageVariantSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbLicensePartnerProfileLanguageVariantBoolExp>;
  predicate: IntComparisonExp;
};

export type DbMigrationTargetAggregateBoolExpBoolAnd = {
  arguments: DbMigrationTargetSelectColumnDbMigrationTargetAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbMigrationTargetBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbMigrationTargetAggregateBoolExpBoolOr = {
  arguments: DbMigrationTargetSelectColumnDbMigrationTargetAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbMigrationTargetBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbMigrationTargetAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbMigrationTargetSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbMigrationTargetBoolExp>;
  predicate: IntComparisonExp;
};

export type DbNylasEmailIntegrationAggregateBoolExpBoolAnd = {
  arguments: DbNylasEmailIntegrationSelectColumnDbNylasEmailIntegrationAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbNylasEmailIntegrationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbNylasEmailIntegrationAggregateBoolExpBoolOr = {
  arguments: DbNylasEmailIntegrationSelectColumnDbNylasEmailIntegrationAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbNylasEmailIntegrationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbNylasEmailIntegrationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbNylasEmailIntegrationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbNylasEmailIntegrationBoolExp>;
  predicate: IntComparisonExp;
};

export type DbQualificationFormLinkAggregateBoolExpBoolAnd = {
  arguments: DbQualificationFormLinkSelectColumnDbQualificationFormLinkAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbQualificationFormLinkBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbQualificationFormLinkAggregateBoolExpBoolOr = {
  arguments: DbQualificationFormLinkSelectColumnDbQualificationFormLinkAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbQualificationFormLinkBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbQualificationFormLinkAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbQualificationFormLinkSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbQualificationFormLinkBoolExp>;
  predicate: IntComparisonExp;
};

export type DbReferralAggregateBoolExpAvg = {
  arguments: DbReferralSelectColumnDbReferralAggregateBoolExpAvgArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbReferralBoolExp>;
  predicate: Float8ComparisonExp;
};

export type DbReferralAggregateBoolExpBoolAnd = {
  arguments: DbReferralSelectColumnDbReferralAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbReferralBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbReferralAggregateBoolExpBoolOr = {
  arguments: DbReferralSelectColumnDbReferralAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbReferralBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbReferralAggregateBoolExpCorr = {
  arguments: DbReferralAggregateBoolExpCorrArguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbReferralBoolExp>;
  predicate: Float8ComparisonExp;
};

export type DbReferralAggregateBoolExpCorrArguments = {
  X: DbReferralSelectColumnDbReferralAggregateBoolExpCorrArgumentsColumns;
  Y: DbReferralSelectColumnDbReferralAggregateBoolExpCorrArgumentsColumns;
};

export type DbReferralAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbReferralSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbReferralBoolExp>;
  predicate: IntComparisonExp;
};

export type DbReferralAggregateBoolExpCovarSamp = {
  arguments: DbReferralAggregateBoolExpCovarSampArguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbReferralBoolExp>;
  predicate: Float8ComparisonExp;
};

export type DbReferralAggregateBoolExpCovarSampArguments = {
  X: DbReferralSelectColumnDbReferralAggregateBoolExpCovarSampArgumentsColumns;
  Y: DbReferralSelectColumnDbReferralAggregateBoolExpCovarSampArgumentsColumns;
};

export type DbReferralAggregateBoolExpMax = {
  arguments: DbReferralSelectColumnDbReferralAggregateBoolExpMaxArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbReferralBoolExp>;
  predicate: Float8ComparisonExp;
};

export type DbReferralAggregateBoolExpMin = {
  arguments: DbReferralSelectColumnDbReferralAggregateBoolExpMinArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbReferralBoolExp>;
  predicate: Float8ComparisonExp;
};

export type DbReferralAggregateBoolExpStddevSamp = {
  arguments: DbReferralSelectColumnDbReferralAggregateBoolExpStddevSampArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbReferralBoolExp>;
  predicate: Float8ComparisonExp;
};

export type DbReferralAggregateBoolExpSum = {
  arguments: DbReferralSelectColumnDbReferralAggregateBoolExpSumArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbReferralBoolExp>;
  predicate: Float8ComparisonExp;
};

export type DbReferralAggregateBoolExpVarSamp = {
  arguments: DbReferralSelectColumnDbReferralAggregateBoolExpVarSampArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbReferralBoolExp>;
  predicate: Float8ComparisonExp;
};

export type DbSessionAggregateBoolExpBoolAnd = {
  arguments: DbSessionSelectColumnDbSessionAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbSessionBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbSessionAggregateBoolExpBoolOr = {
  arguments: DbSessionSelectColumnDbSessionAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbSessionBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbSessionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbSessionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbSessionBoolExp>;
  predicate: IntComparisonExp;
};

export type DbShopAggregateBoolExpAvg = {
  arguments: DbShopSelectColumnDbShopAggregateBoolExpAvgArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopBoolExp>;
  predicate: Float8ComparisonExp;
};

export type DbShopAggregateBoolExpBoolAnd = {
  arguments: DbShopSelectColumnDbShopAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopAggregateBoolExpBoolOr = {
  arguments: DbShopSelectColumnDbShopAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopAggregateBoolExpCorr = {
  arguments: DbShopAggregateBoolExpCorrArguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopBoolExp>;
  predicate: Float8ComparisonExp;
};

export type DbShopAggregateBoolExpCorrArguments = {
  X: DbShopSelectColumnDbShopAggregateBoolExpCorrArgumentsColumns;
  Y: DbShopSelectColumnDbShopAggregateBoolExpCorrArgumentsColumns;
};

export type DbShopAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbShopSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopBoolExp>;
  predicate: IntComparisonExp;
};

export type DbShopAggregateBoolExpCovarSamp = {
  arguments: DbShopAggregateBoolExpCovarSampArguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopBoolExp>;
  predicate: Float8ComparisonExp;
};

export type DbShopAggregateBoolExpCovarSampArguments = {
  X: DbShopSelectColumnDbShopAggregateBoolExpCovarSampArgumentsColumns;
  Y: DbShopSelectColumnDbShopAggregateBoolExpCovarSampArgumentsColumns;
};

export type DbShopAggregateBoolExpMax = {
  arguments: DbShopSelectColumnDbShopAggregateBoolExpMaxArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopBoolExp>;
  predicate: Float8ComparisonExp;
};

export type DbShopAggregateBoolExpMin = {
  arguments: DbShopSelectColumnDbShopAggregateBoolExpMinArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopBoolExp>;
  predicate: Float8ComparisonExp;
};

export type DbShopAggregateBoolExpStddevSamp = {
  arguments: DbShopSelectColumnDbShopAggregateBoolExpStddevSampArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopBoolExp>;
  predicate: Float8ComparisonExp;
};

export type DbShopAggregateBoolExpSum = {
  arguments: DbShopSelectColumnDbShopAggregateBoolExpSumArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopBoolExp>;
  predicate: Float8ComparisonExp;
};

export type DbShopAggregateBoolExpVarSamp = {
  arguments: DbShopSelectColumnDbShopAggregateBoolExpVarSampArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopBoolExp>;
  predicate: Float8ComparisonExp;
};

export type DbShopProfileAggregateBoolExpBoolAnd = {
  arguments: DbShopProfileSelectColumnDbShopProfileAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopProfileAggregateBoolExpBoolOr = {
  arguments: DbShopProfileSelectColumnDbShopProfileAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopProfileAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbShopProfileSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileBoolExp>;
  predicate: IntComparisonExp;
};

export type DbShopProfileCareerAggregateBoolExpBoolAnd = {
  arguments: DbShopProfileCareerSelectColumnDbShopProfileCareerAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileCareerBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopProfileCareerAggregateBoolExpBoolOr = {
  arguments: DbShopProfileCareerSelectColumnDbShopProfileCareerAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileCareerBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopProfileCareerAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbShopProfileCareerSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileCareerBoolExp>;
  predicate: IntComparisonExp;
};

export type DbShopProfileCityDistrictAggregateBoolExpBoolAnd = {
  arguments: DbShopProfileCityDistrictSelectColumnDbShopProfileCityDistrictAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileCityDistrictBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopProfileCityDistrictAggregateBoolExpBoolOr = {
  arguments: DbShopProfileCityDistrictSelectColumnDbShopProfileCityDistrictAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileCityDistrictBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopProfileCityDistrictAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbShopProfileCityDistrictSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileCityDistrictBoolExp>;
  predicate: IntComparisonExp;
};

export type DbShopProfileDescriptionAggregateBoolExpBoolAnd = {
  arguments: DbShopProfileDescriptionSelectColumnDbShopProfileDescriptionAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileDescriptionBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopProfileDescriptionAggregateBoolExpBoolOr = {
  arguments: DbShopProfileDescriptionSelectColumnDbShopProfileDescriptionAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileDescriptionBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopProfileDescriptionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbShopProfileDescriptionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileDescriptionBoolExp>;
  predicate: IntComparisonExp;
};

export type DbShopProfileExpertiseAggregateBoolExpBoolAnd = {
  arguments: DbShopProfileExpertiseSelectColumnDbShopProfileExpertiseAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileExpertiseBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopProfileExpertiseAggregateBoolExpBoolOr = {
  arguments: DbShopProfileExpertiseSelectColumnDbShopProfileExpertiseAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileExpertiseBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopProfileExpertiseAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbShopProfileExpertiseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileExpertiseBoolExp>;
  predicate: IntComparisonExp;
};

export type DbShopProfileImageAggregateBoolExpBoolAnd = {
  arguments: DbShopProfileImageSelectColumnDbShopProfileImageAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileImageBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopProfileImageAggregateBoolExpBoolOr = {
  arguments: DbShopProfileImageSelectColumnDbShopProfileImageAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileImageBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopProfileImageAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbShopProfileImageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileImageBoolExp>;
  predicate: IntComparisonExp;
};

export type DbShopProfileImprintAggregateBoolExpBoolAnd = {
  arguments: DbShopProfileImprintSelectColumnDbShopProfileImprintAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileImprintBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopProfileImprintAggregateBoolExpBoolOr = {
  arguments: DbShopProfileImprintSelectColumnDbShopProfileImprintAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileImprintBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopProfileImprintAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbShopProfileImprintSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileImprintBoolExp>;
  predicate: IntComparisonExp;
};

export type DbShopProfilePrivacyPolicyAggregateBoolExpBoolAnd = {
  arguments: DbShopProfilePrivacyPolicySelectColumnDbShopProfilePrivacyPolicyAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfilePrivacyPolicyBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopProfilePrivacyPolicyAggregateBoolExpBoolOr = {
  arguments: DbShopProfilePrivacyPolicySelectColumnDbShopProfilePrivacyPolicyAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfilePrivacyPolicyBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopProfilePrivacyPolicyAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbShopProfilePrivacyPolicySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfilePrivacyPolicyBoolExp>;
  predicate: IntComparisonExp;
};

export type DbShopProfilePropertyPreviewAggregateBoolExpBoolAnd = {
  arguments: DbShopProfilePropertyPreviewSelectColumnDbShopProfilePropertyPreviewAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfilePropertyPreviewBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopProfilePropertyPreviewAggregateBoolExpBoolOr = {
  arguments: DbShopProfilePropertyPreviewSelectColumnDbShopProfilePropertyPreviewAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfilePropertyPreviewBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopProfilePropertyPreviewAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbShopProfilePropertyPreviewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfilePropertyPreviewBoolExp>;
  predicate: IntComparisonExp;
};

export type DbShopProfileQuoteAggregateBoolExpBoolAnd = {
  arguments: DbShopProfileQuoteSelectColumnDbShopProfileQuoteAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileQuoteBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopProfileQuoteAggregateBoolExpBoolOr = {
  arguments: DbShopProfileQuoteSelectColumnDbShopProfileQuoteAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileQuoteBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopProfileQuoteAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbShopProfileQuoteSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileQuoteBoolExp>;
  predicate: IntComparisonExp;
};

export type DbShopProfileSpokenLanguageAggregateBoolExpBoolAnd = {
  arguments: DbShopProfileSpokenLanguageSelectColumnDbShopProfileSpokenLanguageAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileSpokenLanguageBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopProfileSpokenLanguageAggregateBoolExpBoolOr = {
  arguments: DbShopProfileSpokenLanguageSelectColumnDbShopProfileSpokenLanguageAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileSpokenLanguageBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopProfileSpokenLanguageAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbShopProfileSpokenLanguageSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopProfileSpokenLanguageBoolExp>;
  predicate: IntComparisonExp;
};

export type DbShopSettingAggregateBoolExpBoolAnd = {
  arguments: DbShopSettingSelectColumnDbShopSettingAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopSettingBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopSettingAggregateBoolExpBoolOr = {
  arguments: DbShopSettingSelectColumnDbShopSettingAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopSettingBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbShopSettingAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbShopSettingSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbShopSettingBoolExp>;
  predicate: IntComparisonExp;
};

export type DbSnapshotLeadAggregateBoolExpBoolAnd = {
  arguments: DbSnapshotLeadSelectColumnDbSnapshotLeadAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbSnapshotLeadBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbSnapshotLeadAggregateBoolExpBoolOr = {
  arguments: DbSnapshotLeadSelectColumnDbSnapshotLeadAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbSnapshotLeadBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbSnapshotLeadAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbSnapshotLeadSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbSnapshotLeadBoolExp>;
  predicate: IntComparisonExp;
};

export type DbSnapshotSellerRentOutSpecificationAggregateBoolExpAvg = {
  arguments: DbSnapshotSellerRentOutSpecificationSelectColumnDbSnapshotSellerRentOutSpecificationAggregateBoolExpAvgArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbSnapshotSellerRentOutSpecificationBoolExp>;
  predicate: Float8ComparisonExp;
};

export type DbSnapshotSellerRentOutSpecificationAggregateBoolExpBoolAnd = {
  arguments: DbSnapshotSellerRentOutSpecificationSelectColumnDbSnapshotSellerRentOutSpecificationAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbSnapshotSellerRentOutSpecificationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbSnapshotSellerRentOutSpecificationAggregateBoolExpBoolOr = {
  arguments: DbSnapshotSellerRentOutSpecificationSelectColumnDbSnapshotSellerRentOutSpecificationAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbSnapshotSellerRentOutSpecificationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbSnapshotSellerRentOutSpecificationAggregateBoolExpCorr = {
  arguments: DbSnapshotSellerRentOutSpecificationAggregateBoolExpCorrArguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbSnapshotSellerRentOutSpecificationBoolExp>;
  predicate: Float8ComparisonExp;
};

export type DbSnapshotSellerRentOutSpecificationAggregateBoolExpCorrArguments = {
  X: DbSnapshotSellerRentOutSpecificationSelectColumnDbSnapshotSellerRentOutSpecificationAggregateBoolExpCorrArgumentsColumns;
  Y: DbSnapshotSellerRentOutSpecificationSelectColumnDbSnapshotSellerRentOutSpecificationAggregateBoolExpCorrArgumentsColumns;
};

export type DbSnapshotSellerRentOutSpecificationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbSnapshotSellerRentOutSpecificationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbSnapshotSellerRentOutSpecificationBoolExp>;
  predicate: IntComparisonExp;
};

export type DbSnapshotSellerRentOutSpecificationAggregateBoolExpCovarSamp = {
  arguments: DbSnapshotSellerRentOutSpecificationAggregateBoolExpCovarSampArguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbSnapshotSellerRentOutSpecificationBoolExp>;
  predicate: Float8ComparisonExp;
};

export type DbSnapshotSellerRentOutSpecificationAggregateBoolExpCovarSampArguments = {
  X: DbSnapshotSellerRentOutSpecificationSelectColumnDbSnapshotSellerRentOutSpecificationAggregateBoolExpCovarSampArgumentsColumns;
  Y: DbSnapshotSellerRentOutSpecificationSelectColumnDbSnapshotSellerRentOutSpecificationAggregateBoolExpCovarSampArgumentsColumns;
};

export type DbSnapshotSellerRentOutSpecificationAggregateBoolExpMax = {
  arguments: DbSnapshotSellerRentOutSpecificationSelectColumnDbSnapshotSellerRentOutSpecificationAggregateBoolExpMaxArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbSnapshotSellerRentOutSpecificationBoolExp>;
  predicate: Float8ComparisonExp;
};

export type DbSnapshotSellerRentOutSpecificationAggregateBoolExpMin = {
  arguments: DbSnapshotSellerRentOutSpecificationSelectColumnDbSnapshotSellerRentOutSpecificationAggregateBoolExpMinArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbSnapshotSellerRentOutSpecificationBoolExp>;
  predicate: Float8ComparisonExp;
};

export type DbSnapshotSellerRentOutSpecificationAggregateBoolExpStddevSamp = {
  arguments: DbSnapshotSellerRentOutSpecificationSelectColumnDbSnapshotSellerRentOutSpecificationAggregateBoolExpStddevSampArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbSnapshotSellerRentOutSpecificationBoolExp>;
  predicate: Float8ComparisonExp;
};

export type DbSnapshotSellerRentOutSpecificationAggregateBoolExpSum = {
  arguments: DbSnapshotSellerRentOutSpecificationSelectColumnDbSnapshotSellerRentOutSpecificationAggregateBoolExpSumArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbSnapshotSellerRentOutSpecificationBoolExp>;
  predicate: Float8ComparisonExp;
};

export type DbSnapshotSellerRentOutSpecificationAggregateBoolExpVarSamp = {
  arguments: DbSnapshotSellerRentOutSpecificationSelectColumnDbSnapshotSellerRentOutSpecificationAggregateBoolExpVarSampArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbSnapshotSellerRentOutSpecificationBoolExp>;
  predicate: Float8ComparisonExp;
};

export type DbTeamAggregateBoolExpBoolAnd = {
  arguments: DbTeamSelectColumnDbTeamAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbTeamBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbTeamAggregateBoolExpBoolOr = {
  arguments: DbTeamSelectColumnDbTeamAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbTeamBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbTeamAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbTeamSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbTeamBoolExp>;
  predicate: IntComparisonExp;
};

export type DbTwilioConfigurationAggregateBoolExpBoolAnd = {
  arguments: DbTwilioConfigurationSelectColumnDbTwilioConfigurationAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbTwilioConfigurationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbTwilioConfigurationAggregateBoolExpBoolOr = {
  arguments: DbTwilioConfigurationSelectColumnDbTwilioConfigurationAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbTwilioConfigurationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbTwilioConfigurationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbTwilioConfigurationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbTwilioConfigurationBoolExp>;
  predicate: IntComparisonExp;
};

export type DbUserAdminAssignmentAggregateBoolExpBoolAnd = {
  arguments: DbUserAdminAssignmentSelectColumnDbUserAdminAssignmentAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbUserAdminAssignmentBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbUserAdminAssignmentAggregateBoolExpBoolOr = {
  arguments: DbUserAdminAssignmentSelectColumnDbUserAdminAssignmentAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbUserAdminAssignmentBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbUserAdminAssignmentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbUserAdminAssignmentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbUserAdminAssignmentBoolExp>;
  predicate: IntComparisonExp;
};

export type DbUserShopAssignmentAggregateBoolExpBoolAnd = {
  arguments: DbUserShopAssignmentSelectColumnDbUserShopAssignmentAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbUserShopAssignmentBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbUserShopAssignmentAggregateBoolExpBoolOr = {
  arguments: DbUserShopAssignmentSelectColumnDbUserShopAssignmentAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbUserShopAssignmentBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbUserShopAssignmentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbUserShopAssignmentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbUserShopAssignmentBoolExp>;
  predicate: IntComparisonExp;
};

export type DbUserTeamAssignmentAggregateBoolExpBoolAnd = {
  arguments: DbUserTeamAssignmentSelectColumnDbUserTeamAssignmentAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbUserTeamAssignmentBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbUserTeamAssignmentAggregateBoolExpBoolOr = {
  arguments: DbUserTeamAssignmentSelectColumnDbUserTeamAssignmentAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbUserTeamAssignmentBoolExp>;
  predicate: BooleanComparisonExp;
};

export type DbUserTeamAssignmentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DbUserTeamAssignmentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<DbUserTeamAssignmentBoolExp>;
  predicate: IntComparisonExp;
};

export type ExtensionsSearchContactsArgs = {
  _limit?: InputMaybe<Scalars['Int']>;
  _offset?: InputMaybe<Scalars['Int']>;
  _searchtext?: InputMaybe<Scalars['String']>;
  _shopid?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type MutationRoot = {
  __typename?: 'mutation_root';
  propertyEngine?: Maybe<PropertyEngineMutation>;
};

export type PropertyEngineMutation = {
  __typename?: 'propertyEngineMutation';
  /** Generate Marketing text using AI */
  aiGenerateMarketingText: PropertyEngineAiMarketingTextDetail;
  /** Batch generate Marketing text using AI */
  aiGenerateMarketingTextBatch: Array<PropertyEngineAiMarketingTextDetail>;
  /** Submit feedback to AI team */
  aiSubmitFeedbacks?: Maybe<Scalars['PropertyEngineVoid']>;
  /** Translate text using AI */
  aiTranslateText: PropertyEngineAiTextDetail;
  /** Batch translate text using AI */
  aiTranslateTextBatch: Array<PropertyEngineAiTextDetail>;
  /**
   * Create an energy advisory check
   * body is passed through to API
   */
  createEnergyAdvisoryAssessment: Scalars['PropertyEngineJSON'];
  createFile: PropertyEngineFile;
  createFolder: PropertyEngineFolder;
  /** Create an offer. */
  createOffer: PropertyEngineOffer;
  /** Creates a search-demand */
  createSearchDemand: PropertyEngineSearchDemandDetail;
  /** Creates search demands in bulk */
  createSearchDemands: Array<PropertyEngineCreateSearchDemandsResponse>;
  /** Deletes a custom view by id */
  deleteCustomView?: Maybe<Scalars['PropertyEngineVoid']>;
  /** Deletes an existing matching status state by its id */
  deleteMatchingStatus: Scalars['Boolean'];
  deleteNode?: Maybe<Scalars['Boolean']>;
  /** Deletes an existing search-demand by its ID */
  deleteSearchDemand: Scalars['Boolean'];
  /** Migrate all custom view data from the GOme database for all users with access to the given shop */
  go3MigrateAllCustomViewDataSetsByShopId: Scalars['Int'];
  /** Migrate all matching status from GO3 to our database using shop id */
  go3MigrateAllMatchedPropertyRecordsByShopId: Scalars['Int'];
  /** Migrate all offers from GO3 to our database using shop id */
  go3MigrateAllOfferRecordsByShopId: Scalars['Int'];
  /**
   * Migrate all rental listings from Go3 to our database using shop id.
   * The list can be filtered by lastChangeTimeAfter and statuses.
   */
  go3MigrateAllPropResLettingRecordsByShopId: Scalars['Int'];
  /**
   * Migrate all listings (sales, rental) from Go3 to our database using shop id.
   * The list can be filtered by lastChangeTimeAfter and statuses.
   */
  go3MigrateAllPropResRecordsByShopId: Scalars['Int'];
  /**
   * Migrate all sale listings from Go3 to our database using shop id.
   * The list can be filtered by lastChangeTimeAfter and statuses.
   */
  go3MigrateAllPropResSalesRecordsByShopId: Scalars['Int'];
  /** Synchronize internet portal from Go3 to our database using utag */
  go3MigrateInternetPortalsByUtags: Scalars['Int'];
  /** Synchronize matched property from Go3 to our database using utag */
  go3MigrateMatchedPropertyByUtags: Scalars['Int'];
  /** Synchronize rental listing from Go3 to our database using utag */
  go3MigratePropResLettingRecordByUtag: Scalars['Int'];
  /** Synchronize sale listing from Go3 to our database using utag */
  go3MigratePropResSalesRecordByUtag: Scalars['Int'];
  /** Synchronize department settings from Go3 to our database using shop id */
  go3SyncAllDepartmentSettingsByShopId: Scalars['Int'];
  /** Synchronize all rental listings from Go3 to our database using shop id */
  go3SyncAllPropResLettingRecordsByShopId: Scalars['Int'];
  /** Synchronize all listings (sales, rental) from Go3 to our database using shop id */
  go3SyncAllPropResRecordsByShopId: Scalars['Int'];
  /** Synchronize all sale listings from Go3 to our database using shop id */
  go3SyncAllPropResSalesRecordsByShopId: Scalars['Int'];
  /**
   * Triggers the asynchronous execution of the following mutations in the specified order:
   * 1. go3SyncAllDepartmentSettingsByShopId
   * 2. go3MigrateAllCustomViewDataSetsByShopId
   * 3. go3SyncAllPropResRecordsByShopId
   * 4. go3MigrateAllOfferRecordsByShopId
   * 5. go3MigrateAllMatchedPropertyRecordsByShopId
   *
   * The call returns immediately without waiting for the asynchronous execution to complete.
   */
  go3TriggerSyncAllByShopId?: Maybe<Scalars['PropertyEngineVoid']>;
  /**
   * Triggers the asynchronous execution of the following mutations in the specified order:
   * 1. go3SyncAllDepartmentSettingsByShopId for all shops
   * 2. go3MigrateAllCustomViewDataSetsByShopId for all shops
   * 3. go3SyncAllPropResRecordsByShopId for all shops
   * 4. go3MigrateAllOfferRecordsByShopId for all shops
   * 5. go3MigrateAllMatchedPropertyRecordsByShopId for all shops
   *
   * The call returns immediately without waiting for the asynchronous execution to complete.
   */
  go3TriggerSyncAllByShopIds?: Maybe<Scalars['PropertyEngineVoid']>;
  /** link a lead to a property */
  lhLinkPropertyToSellerLead?: Maybe<PropertyEngineLeadWithContactIdResponse>;
  /**
   * Assign an address to a given check
   * body is passed through to API
   * response is passed through property engine
   */
  linkEnergyAdvisoryBuildingAddress: Scalars['PropertyEngineJSON'];
  /** Link a building to an agent using refId */
  linkEnergyAdvisoryBuildingToAgent: Scalars['PropertyEngineJSON'];
  /** Merges data of two contacts into one and updating the first name and last name */
  mergeContactInformation?: Maybe<Scalars['PropertyEngineVoid']>;
  /** Order a service */
  orderEnergyAdvisoryService: Scalars['PropertyEngineJSON'];
  ping: Scalars['Boolean'];
  /** Provide the availability of the customer */
  provideEnergyAdvisoryAvailability: Scalars['PropertyEngineJSON'];
  /** Set JSON custom views for current user identified by JWT token. */
  setCustomViews?: Maybe<Scalars['PropertyEngineVoid']>;
  /** Updates contact name with matching ID */
  updateContactName: PropertyEngineUpdateContactNameResponse;
  /**
   * Update an energy advisory check
   * body is passed through to API
   */
  updateEnergyAdvisoryAssessment: Scalars['PropertyEngineJSON'];
  updateFile: PropertyEngineFile;
  updateFolder: PropertyEngineFolder;
  updateInternetPortal?: Maybe<PropertyEngineInternetPortal>;
  updateInternetProfileByUtag: PropertyEngineInternetProfile;
  /** Update an offer. */
  updateOffer: PropertyEngineOffer;
  /** Update marketing detail premium expose email by listing utag and language */
  updatePremiumExposeEmail?: Maybe<PropertyEnginePremiumExposeEmail>;
  /** Updates an existing search-demand by its ID */
  updateSearchDemand: PropertyEngineSearchDemandDetail;
  /** Creates or updates search demand assignments */
  updateSearchDemandAssignment: PropertyEngineSearchDemandAssignmentDetail;
  /** Creates or updates a batch of search demand assignments */
  updateSearchDemandAssignments?: Maybe<Scalars['PropertyEngineVoid']>;
  /** Updates search demand status in bulk */
  updateSearchDemandStatus?: Maybe<PropertyEngineUpdateSearchDemandStatusResponse>;
  /** Update shop group relations */
  updateShopGroupRelations?: Maybe<Scalars['PropertyEngineVoid']>;
  upload: PropertyEngineFile;
  /** Upload a document */
  uploadEnergyAdvisoryDocument: Scalars['PropertyEngineJSON'];
  /** Either newOrder or customView can be set. */
  upsertCustomView: Scalars['PropertyEngineIdentifier'];
  /** Upserts a matching status by applying the given content */
  upsertMatchingStatus: Array<PropertyEngineMatchingStatusDetail>;
};

export type PropertyEngineMutationAiGenerateMarketingTextArgs = {
  input: PropertyEngineAiGenerateMarketingTextInput;
};

export type PropertyEngineMutationAiGenerateMarketingTextBatchArgs = {
  batch: Array<PropertyEngineAiGenerateMarketingTextInput>;
};

export type PropertyEngineMutationAiSubmitFeedbacksArgs = {
  input: Array<PropertyEngineAiFeedbackInput>;
};

export type PropertyEngineMutationAiTranslateTextArgs = {
  input: PropertyEngineAiTranslateTextInput;
};

export type PropertyEngineMutationAiTranslateTextBatchArgs = {
  batch: Array<PropertyEngineAiTranslateTextInput>;
};

export type PropertyEngineMutationCreateEnergyAdvisoryAssessmentArgs = {
  body: Scalars['PropertyEngineJSON'];
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineMutationCreateFileArgs = {
  input: PropertyEngineFileInput;
  parentId: Scalars['PropertyEngineIdentifier'];
};

export type PropertyEngineMutationCreateFolderArgs = {
  input: PropertyEngineFolderInput;
  parentId: Scalars['PropertyEngineIdentifier'];
};

export type PropertyEngineMutationCreateOfferArgs = {
  assignedAgentId: Scalars['PropertyEngineUUID'];
  comment?: InputMaybe<Scalars['String']>;
  contactId: Scalars['String'];
  listingUtag: Scalars['String'];
  offeredAt: Scalars['PropertyEngineDateTime'];
  price: Scalars['Float'];
  status: PropertyEngineOfferStatus;
};

export type PropertyEngineMutationCreateSearchDemandArgs = {
  id?: InputMaybe<Scalars['PropertyEngineUUID']>;
  input: PropertyEngineCreateSearchDemandInput;
};

export type PropertyEngineMutationCreateSearchDemandsArgs = {
  input: Array<PropertyEngineCreateSearchDemandsInput>;
};

export type PropertyEngineMutationDeleteCustomViewArgs = {
  id: Scalars['PropertyEngineIdentifier'];
};

export type PropertyEngineMutationDeleteMatchingStatusArgs = {
  matchingStatusId: Scalars['PropertyEngineIdentifier'];
};

export type PropertyEngineMutationDeleteNodeArgs = {
  id: Scalars['PropertyEngineIdentifier'];
};

export type PropertyEngineMutationDeleteSearchDemandArgs = {
  id: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineMutationGo3MigrateAllCustomViewDataSetsByShopIdArgs = {
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineMutationGo3MigrateAllMatchedPropertyRecordsByShopIdArgs = {
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineMutationGo3MigrateAllOfferRecordsByShopIdArgs = {
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineMutationGo3MigrateAllPropResLettingRecordsByShopIdArgs = {
  filter?: InputMaybe<PropertyEngineGo3MigrationFilter>;
  shopId: Scalars['PropertyEngineUUID'];
  skipPictureMigration?: InputMaybe<Scalars['Boolean']>;
};

export type PropertyEngineMutationGo3MigrateAllPropResRecordsByShopIdArgs = {
  filter?: InputMaybe<PropertyEngineGo3MigrationFilter>;
  shopId: Scalars['PropertyEngineUUID'];
  skipPictureMigration?: InputMaybe<Scalars['Boolean']>;
};

export type PropertyEngineMutationGo3MigrateAllPropResSalesRecordsByShopIdArgs = {
  filter?: InputMaybe<PropertyEngineGo3MigrationFilter>;
  shopId: Scalars['PropertyEngineUUID'];
  skipPictureMigration?: InputMaybe<Scalars['Boolean']>;
};

export type PropertyEngineMutationGo3MigrateInternetPortalsByUtagsArgs = {
  utags: Array<Scalars['String']>;
};

export type PropertyEngineMutationGo3MigrateMatchedPropertyByUtagsArgs = {
  utags: Array<Scalars['String']>;
};

export type PropertyEngineMutationGo3MigratePropResLettingRecordByUtagArgs = {
  skipPictureMigration?: InputMaybe<Scalars['Boolean']>;
  utag: Scalars['String'];
};

export type PropertyEngineMutationGo3MigratePropResSalesRecordByUtagArgs = {
  skipPictureMigration?: InputMaybe<Scalars['Boolean']>;
  utag: Scalars['String'];
};

export type PropertyEngineMutationGo3SyncAllDepartmentSettingsByShopIdArgs = {
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineMutationGo3SyncAllPropResLettingRecordsByShopIdArgs = {
  shopId: Scalars['PropertyEngineUUID'];
  skipPictureMigration?: InputMaybe<Scalars['Boolean']>;
};

export type PropertyEngineMutationGo3SyncAllPropResRecordsByShopIdArgs = {
  shopId: Scalars['PropertyEngineUUID'];
  skipPictureMigration?: InputMaybe<Scalars['Boolean']>;
};

export type PropertyEngineMutationGo3SyncAllPropResSalesRecordsByShopIdArgs = {
  shopId: Scalars['PropertyEngineUUID'];
  skipPictureMigration?: InputMaybe<Scalars['Boolean']>;
};

export type PropertyEngineMutationGo3TriggerSyncAllByShopIdArgs = {
  shopId: Scalars['PropertyEngineUUID'];
  skipPictureMigration?: InputMaybe<Scalars['Boolean']>;
};

export type PropertyEngineMutationGo3TriggerSyncAllByShopIdsArgs = {
  shopIds: Array<Scalars['PropertyEngineUUID']>;
  skipPictureMigration?: InputMaybe<Scalars['Boolean']>;
};

export type PropertyEngineMutationLhLinkPropertyToSellerLeadArgs = {
  leadId: Scalars['String'];
  propertyUtag: Scalars['String'];
};

export type PropertyEngineMutationLinkEnergyAdvisoryBuildingAddressArgs = {
  body: Scalars['PropertyEngineJSON'];
  refId: Scalars['String'];
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineMutationLinkEnergyAdvisoryBuildingToAgentArgs = {
  body: Scalars['PropertyEngineJSON'];
  refId: Scalars['String'];
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineMutationMergeContactInformationArgs = {
  newContactId: Scalars['String'];
  newFirstName?: InputMaybe<Scalars['String']>;
  newLastName?: InputMaybe<Scalars['String']>;
  oldContactId: Scalars['String'];
};

export type PropertyEngineMutationOrderEnergyAdvisoryServiceArgs = {
  buildingId: Scalars['String'];
  serviceId: Scalars['String'];
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineMutationProvideEnergyAdvisoryAvailabilityArgs = {
  body: Scalars['PropertyEngineJSON'];
  partnerOrderId: Scalars['String'];
  serviceId: Scalars['String'];
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineMutationSetCustomViewsArgs = {
  customViews?: InputMaybe<Array<PropertyEngineCustomTableInput>>;
  scope: PropertyEngineUserDataScope;
};

export type PropertyEngineMutationUpdateContactNameArgs = {
  input: PropertyEngineUpdateContactNameInput;
};

export type PropertyEngineMutationUpdateEnergyAdvisoryAssessmentArgs = {
  body: Scalars['PropertyEngineJSON'];
  buildingId: Scalars['String'];
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineMutationUpdateFileArgs = {
  id: Scalars['PropertyEngineIdentifier'];
  update: PropertyEngineFileInput;
};

export type PropertyEngineMutationUpdateFolderArgs = {
  id: Scalars['PropertyEngineIdentifier'];
  update: PropertyEngineFolderInput;
};

export type PropertyEngineMutationUpdateInternetPortalArgs = {
  availableForExport: Scalars['Boolean'];
  portalId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineMutationUpdateInternetProfileByUtagArgs = {
  automaticExposeSendingEnabled: Scalars['Boolean'];
  utag: Scalars['String'];
};

export type PropertyEngineMutationUpdateOfferArgs = {
  id: Scalars['String'];
  status?: InputMaybe<PropertyEngineOfferStatus>;
};

export type PropertyEngineMutationUpdatePremiumExposeEmailArgs = {
  input?: InputMaybe<PropertyEnginePremiumExposeEmailInput>;
  language: PropertyEngineLanguage;
  listingUtag: Scalars['String'];
};

export type PropertyEngineMutationUpdateSearchDemandArgs = {
  id: Scalars['PropertyEngineUUID'];
  update: PropertyEngineUpdateSearchDemandInput;
};

export type PropertyEngineMutationUpdateSearchDemandAssignmentArgs = {
  searchDemandId: Scalars['PropertyEngineUUID'];
  update: PropertyEngineUpdateSearchDemandAssignmentInput;
};

export type PropertyEngineMutationUpdateSearchDemandAssignmentsArgs = {
  searchDemandIds: Array<Scalars['PropertyEngineUUID']>;
  update: PropertyEngineUpdateSearchDemandAssignmentInput;
};

export type PropertyEngineMutationUpdateSearchDemandStatusArgs = {
  input: Array<PropertyEngineUpdateSearchDemandStatusInput>;
};

export type PropertyEngineMutationUpdateShopGroupRelationsArgs = {
  input: Array<PropertyEngineUpdateShopGroupRelationsItemInput>;
};

export type PropertyEngineMutationUploadArgs = {
  fileId: Scalars['PropertyEngineIdentifier'];
  upload: Scalars['PropertyEngineUpload'];
};

export type PropertyEngineMutationUploadEnergyAdvisoryDocumentArgs = {
  body: PropertyEngineEnterUploadInput;
  partnerOrderId: Scalars['String'];
  serviceId: Scalars['String'];
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineMutationUpsertCustomViewArgs = {
  customView?: InputMaybe<PropertyEngineCustomTableInput>;
  id?: InputMaybe<Scalars['PropertyEngineIdentifier']>;
  newOrder?: InputMaybe<Scalars['Int']>;
  scope: PropertyEngineUserDataScope;
};

export type PropertyEngineMutationUpsertMatchingStatusArgs = {
  input: Array<PropertyEngineMatchingStatusInput>;
};

export type PropertyEngineQuery = {
  __typename?: 'propertyEngineQuery';
  _service: PropertyEngineService;
  /**
   * Translate text using AI from source language to target language or fallback if needed
   * Deprecated: Please use the mutation aiTranslateText
   */
  aiTranslateText: Scalars['String'];
  /** Search listings for dashboard feed */
  dashboardFeedListings?: Maybe<PropertyEngineDashboardFeedListingsResponse>;
  /** Returns an actual list of listings with details, which have matched against the given search-demand */
  findMatchingListingsForSearchDemand: PropertyEngineMatchingListings;
  /** Returns an actual list of search-demands with details, which would have matched against the given utag */
  findMatchingSearchDemandsForListing: PropertyEngineSearchDemandDetailResponse;
  /** Finds matching statuses */
  findMatchingStatus?: Maybe<Array<PropertyEngineMatchingStatusDetail>>;
  /** Finds proposed listings of a contact */
  findProposedListings: PropertyEngineProposedListingsResponse;
  /** Fetch available translation languages for AI translation */
  getAvailableAiTranslationLanguages?: Maybe<Array<Scalars['PropertyEngineLocaleScalar']>>;
  /** Get JSON custom views for current user identified by JWT token. */
  getCustomViews?: Maybe<Array<PropertyEngineCustomTable>>;
  /** Fetch the availability of Energy Advisory for a given shop */
  getEnergyAdvisoryAvailability: Scalars['Boolean'];
  /** Query the energy advisory custom package */
  getEnergyAdvisoryCustomPackage: Scalars['PropertyEngineJSON'];
  /**
   * Request to check results
   * refId is used as GET parameter
   * response is passed through property engine
   */
  getEnergyAdvisoryResult?: Maybe<Scalars['PropertyEngineJSON']>;
  /** Query to request the available appointments */
  getEnergyAdvisoryServiceAppointments: Scalars['PropertyEngineJSON'];
  /** Query to request a service state */
  getEnergyAdvisoryServiceState: Scalars['PropertyEngineJSON'];
  /** Query the energy advisory services */
  getEnergyAdvisoryServices: Scalars['PropertyEngineJSON'];
  /** Query the energy advisory services questions */
  getEnergyAdvisoryServicesQuestions: Scalars['PropertyEngineJSON'];
  getInternetPortalsByShopId?: Maybe<Array<PropertyEngineInternetPortal>>;
  /** Get a listing by utag. */
  getListingByUtag: PropertyEngineListing;
  /** Get listings by utags. */
  getListingsByUtags?: Maybe<Array<PropertyEngineListing>>;
  /** Get an offer by its id. */
  getOffer?: Maybe<PropertyEngineOffer>;
  /** Get offers by listing utag */
  getOffersByListingUtag?: Maybe<PropertyEngineOffersResponse>;
  /** Get portal status for a given listing, or for a shop */
  getPortalStatus: Array<Maybe<PropertyEnginePortalStatusDetail>>;
  getPropertyTypeHierarchy: PropertyEnginePropertyTypeHierarchy;
  /** Get required fields for a listing by utag. */
  getRequiredFieldsForListingByUtag: PropertyEngineRequiredFields;
  /** Finds a search-demand by its ID */
  getSearchDemand: PropertyEngineSearchDemandDetail;
  /** Fetch teams by shopId from LeadHub, including the team names */
  getTeamsByShopId?: Maybe<Array<PropertyEngineTeam>>;
  /** Query to get template file document */
  getTemplateFile: Scalars['PropertyEngineJSON'];
  /** Check energy advisory feature status */
  lhCheckEnergyAdvisoryFeatureStatus: Scalars['Boolean'];
  /** Return activities by property id (utag) */
  lhFetchActivitiesByPropertyId: PropertyEngineActivityResponseDetails;
  /** Return activity by its entity id */
  lhFetchActivityByEntityId?: Maybe<PropertyEngineActivityResponse>;
  /** Return agent details for the agent ids */
  lhFetchAgentDetails: Array<PropertyEngineAgentDetail>;
  /** Return all agents of the given shop */
  lhFetchAgentsByShopId: Array<PropertyEngineAgentDetail>;
  /** Fetches all CDF definitions belonging to the given shop-ID from LH */
  lhFetchAllCdfsByShopId: Array<PropertyEngineCdfDefinition>;
  /** Return contact details for the contact ids */
  lhFetchContactDetails: Array<PropertyEngineContactBasicDataResponse>;
  /** Return contact details for search text */
  lhFetchContactsByText: Array<PropertyEngineContactDetailsResult>;
  /** Return shop levels for the given shops */
  lhFetchShopLevel: Array<PropertyEngineShopLevelResult>;
  /** Fetch shop settings from LeadHub. */
  lhGetShopSettingsByShopId: PropertyEngineShopFeatureResponse;
  ping: Scalars['Boolean'];
  /** Search listings for map view. */
  searchListings?: Maybe<PropertyEngineSearchListingsResponse>;
  /** Search listings for autocomplete. */
  searchListingsAutoComplete?: Maybe<Array<PropertyEngineListing>>;
};

export type PropertyEngineQueryAiTranslateTextArgs = {
  input: Scalars['String'];
  sourceLanguage: Scalars['PropertyEngineLocaleScalar'];
  targetLanguage: Scalars['PropertyEngineLocaleScalar'];
};

export type PropertyEngineQueryDashboardFeedListingsArgs = {
  dateTimeFrom: Scalars['PropertyEngineDateTime'];
  dateTimeTo?: InputMaybe<Scalars['PropertyEngineDateTime']>;
  dimensions: Array<PropertyEngineDashboardFeedListingsDimension>;
  listingType?: InputMaybe<PropertyEngineListingType>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineQueryFindMatchingListingsForSearchDemandArgs = {
  addressQuery?: InputMaybe<Scalars['String']>;
  coverLetterAddressQuery?: InputMaybe<Scalars['String']>;
  id: Scalars['PropertyEngineUUID'];
  listingStatus?: InputMaybe<Array<PropertyEngineListingStatus>>;
  order?: InputMaybe<PropertyEngineListingOrder>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type PropertyEngineQueryFindMatchingSearchDemandsForListingArgs = {
  contactNameQuery?: InputMaybe<Scalars['String']>;
  matchingState: PropertyEngineMatchingState;
  onlyOwnSearchDemands?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<PropertyEngineSearchDemandOrder>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<PropertyEngineSearchDemandStatus>>;
  utag: Scalars['String'];
};

export type PropertyEngineQueryFindMatchingStatusArgs = {
  contactId?: InputMaybe<Scalars['String']>;
  listingUtag: Scalars['String'];
  searchDemandId?: InputMaybe<Scalars['PropertyEngineUUID']>;
};

export type PropertyEngineQueryFindProposedListingsArgs = {
  addressQuery?: InputMaybe<Scalars['String']>;
  contactId: Scalars['String'];
  coverLetterAddressQuery?: InputMaybe<Scalars['String']>;
  hasFeedback?: InputMaybe<Scalars['Boolean']>;
  listingStatus?: InputMaybe<Array<PropertyEngineListingStatus>>;
  listingType?: InputMaybe<PropertyEngineListingType>;
  matchingState?: InputMaybe<PropertyEngineMatchingState>;
  matchingStatusOrder?: InputMaybe<PropertyEngineMatchingStatusOrder>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  searchDemandIds?: InputMaybe<Array<Scalars['PropertyEngineUUID']>>;
};

export type PropertyEngineQueryGetCustomViewsArgs = {
  scope: PropertyEngineUserDataScope;
};

export type PropertyEngineQueryGetEnergyAdvisoryAvailabilityArgs = {
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineQueryGetEnergyAdvisoryCustomPackageArgs = {
  refId: Scalars['String'];
  retrofits: Array<Scalars['String']>;
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineQueryGetEnergyAdvisoryResultArgs = {
  refId: Scalars['String'];
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineQueryGetEnergyAdvisoryServiceAppointmentsArgs = {
  partnerOrderId: Scalars['String'];
  serviceId: Scalars['String'];
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineQueryGetEnergyAdvisoryServiceStateArgs = {
  partnerOrderId: Scalars['String'];
  serviceId: Scalars['String'];
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineQueryGetEnergyAdvisoryServicesQuestionsArgs = {
  serviceId: Scalars['String'];
};

export type PropertyEngineQueryGetInternetPortalsByShopIdArgs = {
  availableForExport?: InputMaybe<Scalars['Boolean']>;
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineQueryGetListingByUtagArgs = {
  utag: Scalars['String'];
};

export type PropertyEngineQueryGetListingsByUtagsArgs = {
  utags: Array<Scalars['String']>;
};

export type PropertyEngineQueryGetOfferArgs = {
  id: Scalars['String'];
};

export type PropertyEngineQueryGetOffersByListingUtagArgs = {
  order?: InputMaybe<PropertyEngineOfferOrder>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  utag: Scalars['String'];
};

export type PropertyEngineQueryGetPortalStatusArgs = {
  scope?: InputMaybe<PropertyEnginePortalStatusScope>;
  shopId: Scalars['PropertyEngineUUID'];
  utag?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PropertyEngineQueryGetRequiredFieldsForListingByUtagArgs = {
  utag: Scalars['String'];
};

export type PropertyEngineQueryGetSearchDemandArgs = {
  id: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineQueryGetTeamsByShopIdArgs = {
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineQueryGetTemplateFileArgs = {
  shopId: Scalars['PropertyEngineUUID'];
  type: Scalars['String'];
};

export type PropertyEngineQueryLhCheckEnergyAdvisoryFeatureStatusArgs = {
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineQueryLhFetchActivitiesByPropertyIdArgs = {
  dateFrom?: InputMaybe<Scalars['PropertyEngineDate']>;
  dateTo?: InputMaybe<Scalars['PropertyEngineDate']>;
  order?: InputMaybe<PropertyEngineActivityOrderByInput>;
  ownerAgent?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  propertyId: Scalars['String'];
  size?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<InputMaybe<PropertyEngineActivityStatus>>>;
  type?: InputMaybe<Array<InputMaybe<PropertyEngineActivityTypeEnum>>>;
};

export type PropertyEngineQueryLhFetchActivityByEntityIdArgs = {
  entityId?: InputMaybe<Scalars['String']>;
  utag?: InputMaybe<Scalars['String']>;
};

export type PropertyEngineQueryLhFetchAgentDetailsArgs = {
  agentIds: Array<Scalars['String']>;
};

export type PropertyEngineQueryLhFetchAgentsByShopIdArgs = {
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineQueryLhFetchAllCdfsByShopIdArgs = {
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineQueryLhFetchContactDetailsArgs = {
  contactIds: Array<Scalars['String']>;
};

export type PropertyEngineQueryLhFetchContactsByTextArgs = {
  contactIds?: InputMaybe<Array<Scalars['String']>>;
  go3ContactKeys?: InputMaybe<Array<Scalars['Int']>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineQueryLhFetchShopLevelArgs = {
  shopIds: Array<Scalars['PropertyEngineUUID']>;
};

export type PropertyEngineQueryLhGetShopSettingsByShopIdArgs = {
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineQuerySearchListingsArgs = {
  order?: InputMaybe<PropertyEngineSearchListingsOrder>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  shopId: Scalars['PropertyEngineUUID'];
  where: PropertyEngineSearchListingsFilterInput;
};

export type PropertyEngineQuerySearchListingsAutoCompleteArgs = {
  listingType?: InputMaybe<PropertyEngineListingType>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  shopId: Scalars['PropertyEngineUUID'];
};

export type PropertyEngineSubscription = {
  __typename?: 'propertyEngineSubscription';
  ping: Scalars['Boolean'];
};

export type QueryRoot = {
  __typename?: 'query_root';
  /** fetch data from the table: "db.activity_type_grouping" */
  dbActivityTypeGrouping: Array<DbActivityTypeGrouping>;
  /** fetch data from the table: "db.activity_type_grouping" using primary key columns */
  dbActivityTypeGroupingByPk?: Maybe<DbActivityTypeGrouping>;
  /** fetch data from the table: "db.agent" */
  dbAgent: Array<DbAgent>;
  /** fetch aggregated fields from the table: "db.agent" */
  dbAgentAggregate: DbAgentAggregate;
  /** fetch data from the table: "db.agent" using primary key columns */
  dbAgentByPk?: Maybe<DbAgent>;
  /** fetch data from the table: "db.agent_default_email_signature" */
  dbAgentDefaultEmailSignature: Array<DbAgentDefaultEmailSignature>;
  /** fetch aggregated fields from the table: "db.agent_default_email_signature" */
  dbAgentDefaultEmailSignatureAggregate: DbAgentDefaultEmailSignatureAggregate;
  /** fetch data from the table: "db.agent_default_email_signature" using primary key columns */
  dbAgentDefaultEmailSignatureByPk?: Maybe<DbAgentDefaultEmailSignature>;
  /** fetch data from the table: "db.agent_default_nylas_email_integration" */
  dbAgentDefaultNylasEmailIntegration: Array<DbAgentDefaultNylasEmailIntegration>;
  /** fetch aggregated fields from the table: "db.agent_default_nylas_email_integration" */
  dbAgentDefaultNylasEmailIntegrationAggregate: DbAgentDefaultNylasEmailIntegrationAggregate;
  /** fetch data from the table: "db.agent_default_nylas_email_integration" using primary key columns */
  dbAgentDefaultNylasEmailIntegrationByPk?: Maybe<DbAgentDefaultNylasEmailIntegration>;
  /** fetch data from the table: "db.agent_profile" */
  dbAgentProfile: Array<DbAgentProfile>;
  /** fetch aggregated fields from the table: "db.agent_profile" */
  dbAgentProfileAggregate: DbAgentProfileAggregate;
  /** fetch data from the table: "db.agent_profile" using primary key columns */
  dbAgentProfileByPk?: Maybe<DbAgentProfile>;
  /** fetch data from the table: "db.agent_profile_city_district" */
  dbAgentProfileCityDistrict: Array<DbAgentProfileCityDistrict>;
  /** fetch aggregated fields from the table: "db.agent_profile_city_district" */
  dbAgentProfileCityDistrictAggregate: DbAgentProfileCityDistrictAggregate;
  /** fetch data from the table: "db.agent_profile_city_district" using primary key columns */
  dbAgentProfileCityDistrictByPk?: Maybe<DbAgentProfileCityDistrict>;
  /** fetch data from the table: "db.agent_profile_description" */
  dbAgentProfileDescription: Array<DbAgentProfileDescription>;
  /** fetch aggregated fields from the table: "db.agent_profile_description" */
  dbAgentProfileDescriptionAggregate: DbAgentProfileDescriptionAggregate;
  /** fetch data from the table: "db.agent_profile_description" using primary key columns */
  dbAgentProfileDescriptionByPk?: Maybe<DbAgentProfileDescription>;
  /** fetch data from the table: "db.agent_profile_expertise" */
  dbAgentProfileExpertise: Array<DbAgentProfileExpertise>;
  /** fetch aggregated fields from the table: "db.agent_profile_expertise" */
  dbAgentProfileExpertiseAggregate: DbAgentProfileExpertiseAggregate;
  /** fetch data from the table: "db.agent_profile_expertise" using primary key columns */
  dbAgentProfileExpertiseByPk?: Maybe<DbAgentProfileExpertise>;
  /** fetch data from the table: "db.agent_profile_former_url" */
  dbAgentProfileFormerUrl: Array<DbAgentProfileFormerUrl>;
  /** fetch aggregated fields from the table: "db.agent_profile_former_url" */
  dbAgentProfileFormerUrlAggregate: DbAgentProfileFormerUrlAggregate;
  /** fetch data from the table: "db.agent_profile_former_url" using primary key columns */
  dbAgentProfileFormerUrlByPk?: Maybe<DbAgentProfileFormerUrl>;
  /** fetch data from the table: "db.agent_profile_image" */
  dbAgentProfileImage: Array<DbAgentProfileImage>;
  /** fetch data from the table: "db.agent_profile_image" using primary key columns */
  dbAgentProfileImageByPk?: Maybe<DbAgentProfileImage>;
  /** fetch data from the table: "db.agent_profile_social_media_profiles" */
  dbAgentProfileSocialMediaProfiles: Array<DbAgentProfileSocialMediaProfiles>;
  /** fetch aggregated fields from the table: "db.agent_profile_social_media_profiles" */
  dbAgentProfileSocialMediaProfilesAggregate: DbAgentProfileSocialMediaProfilesAggregate;
  /** fetch data from the table: "db.agent_profile_social_media_profiles" using primary key columns */
  dbAgentProfileSocialMediaProfilesByPk?: Maybe<DbAgentProfileSocialMediaProfiles>;
  /** fetch data from the table: "db.agent_profile_spoken_language" */
  dbAgentProfileSpokenLanguage: Array<DbAgentProfileSpokenLanguage>;
  /** fetch aggregated fields from the table: "db.agent_profile_spoken_language" */
  dbAgentProfileSpokenLanguageAggregate: DbAgentProfileSpokenLanguageAggregate;
  /** fetch data from the table: "db.agent_profile_spoken_language" using primary key columns */
  dbAgentProfileSpokenLanguageByPk?: Maybe<DbAgentProfileSpokenLanguage>;
  /** fetch data from the table: "db.appointment_activity" */
  dbAppointmentActivity: Array<DbAppointmentActivity>;
  /** fetch data from the table: "db.appointment_activity" using primary key columns */
  dbAppointmentActivityByPk?: Maybe<DbAppointmentActivity>;
  /** fetch data from the table: "db.appointment_agent" */
  dbAppointmentAgent: Array<DbAppointmentAgent>;
  /** fetch data from the table: "db.appointment_contact" */
  dbAppointmentContact: Array<DbAppointmentContact>;
  /** fetch data from the table: "db.appointment_lead" */
  dbAppointmentLead: Array<DbAppointmentLead>;
  /** fetch data from the table: "db.audit_activity" */
  dbAuditActivity: Array<DbAuditActivity>;
  /** fetch data from the table: "db.audit_activity" using primary key columns */
  dbAuditActivityByPk?: Maybe<DbAuditActivity>;
  /** fetch data from the table: "db.base_activity" */
  dbBaseActivity: Array<DbBaseActivity>;
  /** fetch aggregated fields from the table: "db.base_activity" */
  dbBaseActivityAggregate: DbBaseActivityAggregate;
  /** fetch data from the table: "db.base_activity" using primary key columns */
  dbBaseActivityByPk?: Maybe<DbBaseActivity>;
  /** fetch data from the table: "db.base_activity_creation" */
  dbBaseActivityCreation: Array<DbBaseActivityCreation>;
  /** fetch aggregated fields from the table: "db.base_activity_creation" */
  dbBaseActivityCreationAggregate: DbBaseActivityCreationAggregate;
  /** fetch data from the table: "db.batch_job_execution" */
  dbBatchJobExecution: Array<DbBatchJobExecution>;
  /** fetch data from the table: "db.batch_job_execution" using primary key columns */
  dbBatchJobExecutionByPk?: Maybe<DbBatchJobExecution>;
  /** fetch data from the table: "db.batch_job_execution_context" */
  dbBatchJobExecutionContext: Array<DbBatchJobExecutionContext>;
  /** fetch data from the table: "db.batch_job_execution_context" using primary key columns */
  dbBatchJobExecutionContextByPk?: Maybe<DbBatchJobExecutionContext>;
  /** fetch data from the table: "db.batch_job_execution_params" */
  dbBatchJobExecutionParams: Array<DbBatchJobExecutionParams>;
  /** fetch data from the table: "db.batch_job_instance" */
  dbBatchJobInstance: Array<DbBatchJobInstance>;
  /** fetch data from the table: "db.batch_job_instance" using primary key columns */
  dbBatchJobInstanceByPk?: Maybe<DbBatchJobInstance>;
  /** fetch data from the table: "db.batch_step_execution" */
  dbBatchStepExecution: Array<DbBatchStepExecution>;
  /** fetch data from the table: "db.batch_step_execution" using primary key columns */
  dbBatchStepExecutionByPk?: Maybe<DbBatchStepExecution>;
  /** fetch data from the table: "db.batch_step_execution_context" */
  dbBatchStepExecutionContext: Array<DbBatchStepExecutionContext>;
  /** fetch data from the table: "db.batch_step_execution_context" using primary key columns */
  dbBatchStepExecutionContextByPk?: Maybe<DbBatchStepExecutionContext>;
  /** fetch data from the table: "db.bulk_email" */
  dbBulkEmail: Array<DbBulkEmail>;
  /** fetch aggregated fields from the table: "db.bulk_email" */
  dbBulkEmailAggregate: DbBulkEmailAggregate;
  /** fetch data from the table: "db.buyer_to_rent_specification" */
  dbBuyerToRentSpecification: Array<DbBuyerToRentSpecification>;
  /** fetch data from the table: "db.buyer_to_rent_specification" using primary key columns */
  dbBuyerToRentSpecificationByPk?: Maybe<DbBuyerToRentSpecification>;
  /** fetch data from the table: "db.buyer_to_rent_specification_cdf" */
  dbBuyerToRentSpecificationCdf: Array<DbBuyerToRentSpecificationCdf>;
  /** fetch data from the table: "db.buyer_to_rent_specification_cdf" using primary key columns */
  dbBuyerToRentSpecificationCdfByPk?: Maybe<DbBuyerToRentSpecificationCdf>;
  /** fetch data from the table: "db.call" */
  dbCall: Array<DbCall>;
  /** fetch data from the table: "db.call_activity" */
  dbCallActivity: Array<DbCallActivity>;
  /** fetch data from the table: "db.call_activity" using primary key columns */
  dbCallActivityByPk?: Maybe<DbCallActivity>;
  /** fetch aggregated fields from the table: "db.call" */
  dbCallAggregate: DbCallAggregate;
  /** fetch data from the table: "db.call" using primary key columns */
  dbCallByPk?: Maybe<DbCall>;
  /** fetch data from the table: "db.cdf" */
  dbCdf: Array<DbCdf>;
  /** fetch data from the table: "db.cdf" using primary key columns */
  dbCdfByPk?: Maybe<DbCdf>;
  /** fetch data from the table: "db.consent" */
  dbConsent: Array<DbConsent>;
  /** fetch data from the table: "db.consent_activity" */
  dbConsentActivity: Array<DbConsentActivity>;
  /** fetch data from the table: "db.consent_activity" using primary key columns */
  dbConsentActivityByPk?: Maybe<DbConsentActivity>;
  /** fetch aggregated fields from the table: "db.consent" */
  dbConsentAggregate: DbConsentAggregate;
  /** fetch data from the table: "db.consent" using primary key columns */
  dbConsentByPk?: Maybe<DbConsent>;
  /** fetch data from the table: "db.consent_event" */
  dbConsentEvent: Array<DbConsentEvent>;
  /** fetch aggregated fields from the table: "db.consent_event" */
  dbConsentEventAggregate: DbConsentEventAggregate;
  /** fetch data from the table: "db.consent_event" using primary key columns */
  dbConsentEventByPk?: Maybe<DbConsentEvent>;
  /** fetch data from the table: "db.consent_template" */
  dbConsentTemplate: Array<DbConsentTemplate>;
  /** fetch data from the table: "db.consent_template" using primary key columns */
  dbConsentTemplateByPk?: Maybe<DbConsentTemplate>;
  /** fetch data from the table: "db.consent_text" */
  dbConsentText: Array<DbConsentText>;
  /** fetch data from the table: "db.consent_text" using primary key columns */
  dbConsentTextByPk?: Maybe<DbConsentText>;
  /** fetch data from the table: "db.contact" */
  dbContact: Array<DbContact>;
  /** fetch aggregated fields from the table: "db.contact" */
  dbContactAggregate: DbContactAggregate;
  /** fetch data from the table: "db.contact_assignment" */
  dbContactAssignment: Array<DbContactAssignment>;
  /** fetch data from the table: "db.contact" using primary key columns */
  dbContactByPk?: Maybe<DbContact>;
  /** fetch data from the table: "db.contact_change_activity" */
  dbContactChangeActivity: Array<DbContactChangeActivity>;
  /** fetch data from the table: "db.contact_change_activity" using primary key columns */
  dbContactChangeActivityByPk?: Maybe<DbContactChangeActivity>;
  /** fetch data from the table: "db.contact_change_log" */
  dbContactChangeLog: Array<DbContactChangeLog>;
  /** fetch data from the table: "db.contact_change_log" using primary key columns */
  dbContactChangeLogByPk?: Maybe<DbContactChangeLog>;
  /** fetch data from the table: "db.contact_details" */
  dbContactDetails: Array<DbContactDetails>;
  /** fetch aggregated fields from the table: "db.contact_details" */
  dbContactDetailsAggregate: DbContactDetailsAggregate;
  /** fetch data from the table: "db.contact_gdpr" */
  dbContactGdpr: Array<DbContactGdpr>;
  /** fetch data from the table: "db.contact_gdpr" using primary key columns */
  dbContactGdprByPk?: Maybe<DbContactGdpr>;
  /** fetch data from the table: "db.contact_preferred_time" */
  dbContactPreferredTime: Array<DbContactPreferredTime>;
  /** fetch aggregated fields from the table: "db.contact_preferred_time" */
  dbContactPreferredTimeAggregate: DbContactPreferredTimeAggregate;
  /** fetch data from the table: "db.contact_preferred_time" using primary key columns */
  dbContactPreferredTimeByPk?: Maybe<DbContactPreferredTime>;
  /** fetch data from the table: "db.contact_preferred_way" */
  dbContactPreferredWay: Array<DbContactPreferredWay>;
  /** fetch aggregated fields from the table: "db.contact_preferred_way" */
  dbContactPreferredWayAggregate: DbContactPreferredWayAggregate;
  /** fetch data from the table: "db.contact_preferred_way" using primary key columns */
  dbContactPreferredWayByPk?: Maybe<DbContactPreferredWay>;
  /** fetch data from the table: "db.contact_relationship" */
  dbContactRelationship: Array<DbContactRelationship>;
  /** fetch data from the table: "db.contact_relationship" using primary key columns */
  dbContactRelationshipByPk?: Maybe<DbContactRelationship>;
  /** fetch data from the table: "db.contact_renter_information" */
  dbContactRenterInformation: Array<DbContactRenterInformation>;
  /** fetch data from the table: "db.contact_renter_information" using primary key columns */
  dbContactRenterInformationByPk?: Maybe<DbContactRenterInformation>;
  /** fetch data from the table: "db.contact_status_info" */
  dbContactStatusInfo: Array<DbContactStatusInfo>;
  /** fetch aggregated fields from the table: "db.contact_status_info" */
  dbContactStatusInfoAggregate: DbContactStatusInfoAggregate;
  /** fetch data from the table: "db.contact_tag" */
  dbContactTag: Array<DbContactTag>;
  /** fetch data from the table: "db.contact_tag" using primary key columns */
  dbContactTagByPk?: Maybe<DbContactTag>;
  /** fetch data from the table: "db.conversion_data" */
  dbConversionData: Array<DbConversionData>;
  /** fetch data from the table: "db.conversion_data" using primary key columns */
  dbConversionDataByPk?: Maybe<DbConversionData>;
  /** fetch data from the table: "db.country_mandatory_language" */
  dbCountryMandatoryLanguage: Array<DbCountryMandatoryLanguage>;
  /** fetch data from the table: "db.country_mandatory_language" using primary key columns */
  dbCountryMandatoryLanguageByPk?: Maybe<DbCountryMandatoryLanguage>;
  /** fetch data from the table: "db.custom_list_view" */
  dbCustomListView: Array<DbCustomListView>;
  /** fetch data from the table: "db.custom_list_view" using primary key columns */
  dbCustomListViewByPk?: Maybe<DbCustomListView>;
  /** fetch data from the table: "db.custom_search_demand_polygon" */
  dbCustomSearchDemandPolygon: Array<DbCustomSearchDemandPolygon>;
  /** fetch data from the table: "db.custom_search_demand_polygon" using primary key columns */
  dbCustomSearchDemandPolygonByPk?: Maybe<DbCustomSearchDemandPolygon>;
  /** fetch data from the table: "db.document" */
  dbDocument: Array<DbDocument>;
  /** fetch data from the table: "db.document_activity" */
  dbDocumentActivity: Array<DbDocumentActivity>;
  /** fetch data from the table: "db.document_activity" using primary key columns */
  dbDocumentActivityByPk?: Maybe<DbDocumentActivity>;
  /** fetch aggregated fields from the table: "db.document" */
  dbDocumentAggregate: DbDocumentAggregate;
  /** fetch data from the table: "db.document" using primary key columns */
  dbDocumentByPk?: Maybe<DbDocument>;
  /** fetch data from the table: "db.document_reference" */
  dbDocumentReference: Array<DbDocumentReference>;
  /** fetch aggregated fields from the table: "db.document_reference" */
  dbDocumentReferenceAggregate: DbDocumentReferenceAggregate;
  /** fetch data from the table: "db.document_reference" using primary key columns */
  dbDocumentReferenceByPk?: Maybe<DbDocumentReference>;
  /** fetch data from the table: "db.email_attachment" */
  dbEmailAttachment: Array<DbEmailAttachment>;
  /** fetch aggregated fields from the table: "db.email_attachment" */
  dbEmailAttachmentAggregate: DbEmailAttachmentAggregate;
  /** fetch data from the table: "db.email_attachment" using primary key columns */
  dbEmailAttachmentByPk?: Maybe<DbEmailAttachment>;
  /** fetch data from the table: "db.email_callback_events" */
  dbEmailCallbackEvents: Array<DbEmailCallbackEvents>;
  /** fetch aggregated fields from the table: "db.email_callback_events" */
  dbEmailCallbackEventsAggregate: DbEmailCallbackEventsAggregate;
  /** fetch data from the table: "db.email_callback_events" using primary key columns */
  dbEmailCallbackEventsByPk?: Maybe<DbEmailCallbackEvents>;
  /** fetch data from the table: "db.email_link" */
  dbEmailLink: Array<DbEmailLink>;
  /** fetch aggregated fields from the table: "db.email_link" */
  dbEmailLinkAggregate: DbEmailLinkAggregate;
  /** fetch data from the table: "db.email_link" using primary key columns */
  dbEmailLinkByPk?: Maybe<DbEmailLink>;
  /** fetch data from the table: "db.email_message_map" */
  dbEmailMessageMap: Array<DbEmailMessageMap>;
  /** fetch data from the table: "db.email_placeholder" */
  dbEmailPlaceholder: Array<DbEmailPlaceholder>;
  /** fetch aggregated fields from the table: "db.email_placeholder" */
  dbEmailPlaceholderAggregate: DbEmailPlaceholderAggregate;
  /** fetch data from the table: "db.email_placeholder" using primary key columns */
  dbEmailPlaceholderByPk?: Maybe<DbEmailPlaceholder>;
  /** fetch data from the table: "db.email_signature" */
  dbEmailSignature: Array<DbEmailSignature>;
  /** fetch aggregated fields from the table: "db.email_signature" */
  dbEmailSignatureAggregate: DbEmailSignatureAggregate;
  /** fetch data from the table: "db.email_signature" using primary key columns */
  dbEmailSignatureByPk?: Maybe<DbEmailSignature>;
  /** fetch data from the table: "db.email_template" */
  dbEmailTemplate: Array<DbEmailTemplate>;
  /** fetch aggregated fields from the table: "db.email_template" */
  dbEmailTemplateAggregate: DbEmailTemplateAggregate;
  /** fetch data from the table: "db.email_template" using primary key columns */
  dbEmailTemplateByPk?: Maybe<DbEmailTemplate>;
  /** fetch data from the table: "db.email_template_content" */
  dbEmailTemplateContent: Array<DbEmailTemplateContent>;
  /** fetch aggregated fields from the table: "db.email_template_content" */
  dbEmailTemplateContentAggregate: DbEmailTemplateContentAggregate;
  /** fetch data from the table: "db.email_template_content" using primary key columns */
  dbEmailTemplateContentByPk?: Maybe<DbEmailTemplateContent>;
  /** fetch data from the table: "db.email_tracking" */
  dbEmailTracking: Array<DbEmailTracking>;
  /** fetch aggregated fields from the table: "db.email_tracking" */
  dbEmailTrackingAggregate: DbEmailTrackingAggregate;
  /** fetch data from the table: "db.email_tracking" using primary key columns */
  dbEmailTrackingByPk?: Maybe<DbEmailTracking>;
  /** fetch data from the table: "db.enabled_feature_flags" */
  dbEnabledFeatureFlags: Array<DbEnabledFeatureFlags>;
  /** fetch data from the table: "db.exposee_shared_activity" */
  dbExposeeSharedActivity: Array<DbExposeeSharedActivity>;
  /** fetch aggregated fields from the table: "db.exposee_shared_activity" */
  dbExposeeSharedActivityAggregate: DbExposeeSharedActivityAggregate;
  /** fetch data from the table: "db.exposee_shared_activity" using primary key columns */
  dbExposeeSharedActivityByPk?: Maybe<DbExposeeSharedActivity>;
  /** fetch data from the table: "db.import_lead_request" */
  dbImportLeadRequest: Array<DbImportLeadRequest>;
  /** fetch aggregated fields from the table: "db.import_lead_request" */
  dbImportLeadRequestAggregate: DbImportLeadRequestAggregate;
  /** fetch data from the table: "db.import_lead_request" using primary key columns */
  dbImportLeadRequestByPk?: Maybe<DbImportLeadRequest>;
  /** fetch data from the table: "db.lead" */
  dbLead: Array<DbLead>;
  /** fetch aggregated fields from the table: "db.lead" */
  dbLeadAggregate: DbLeadAggregate;
  /** fetch data from the table: "db.lead_auto_assignment_intent" */
  dbLeadAutoAssignmentIntent: Array<DbLeadAutoAssignmentIntent>;
  /** fetch data from the table: "db.lead_auto_assignment_intent" using primary key columns */
  dbLeadAutoAssignmentIntentByPk?: Maybe<DbLeadAutoAssignmentIntent>;
  /** fetch data from the table: "db.lead_auto_assignment_rules" */
  dbLeadAutoAssignmentRules: Array<DbLeadAutoAssignmentRules>;
  /** fetch data from the table: "db.lead_auto_assignment_rules" using primary key columns */
  dbLeadAutoAssignmentRulesByPk?: Maybe<DbLeadAutoAssignmentRules>;
  /** fetch data from the table: "db.lead" using primary key columns */
  dbLeadByPk?: Maybe<DbLead>;
  /** fetch data from the table: "db.lead_expiry" */
  dbLeadExpiry: Array<DbLeadExpiry>;
  /** fetch data from the table: "db.lead_extending_activities" */
  dbLeadExtendingActivities: Array<DbLeadExtendingActivities>;
  /** fetch data from the table: "db.lead_extending_activities" using primary key columns */
  dbLeadExtendingActivitiesByPk?: Maybe<DbLeadExtendingActivities>;
  /** fetch data from the table: "db.lead_extending_appointment_types" */
  dbLeadExtendingAppointmentTypes: Array<DbLeadExtendingAppointmentTypes>;
  /** fetch data from the table: "db.lead_extending_appointment_types" using primary key columns */
  dbLeadExtendingAppointmentTypesByPk?: Maybe<DbLeadExtendingAppointmentTypes>;
  /** fetch data from the table: "db.lead_extending_task_types" */
  dbLeadExtendingTaskTypes: Array<DbLeadExtendingTaskTypes>;
  /** fetch data from the table: "db.lead_extending_task_types" using primary key columns */
  dbLeadExtendingTaskTypesByPk?: Maybe<DbLeadExtendingTaskTypes>;
  /** fetch data from the table: "db.lead_statistics" */
  dbLeadStatistics: Array<DbLeadStatistics>;
  /** fetch data from the table: "db.leadhub_go3_contact_link" */
  dbLeadhubGo3ContactLink: Array<DbLeadhubGo3ContactLink>;
  /** fetch aggregated fields from the table: "db.leadhub_go3_contact_link" */
  dbLeadhubGo3ContactLinkAggregate: DbLeadhubGo3ContactLinkAggregate;
  /** fetch data from the table: "db.leadhub_go3_contact_link" using primary key columns */
  dbLeadhubGo3ContactLinkByPk?: Maybe<DbLeadhubGo3ContactLink>;
  /** fetch data from the table: "db.legacy_go3_change_log" */
  dbLegacyGo3ChangeLog: Array<DbLegacyGo3ChangeLog>;
  /** fetch data from the table: "db.legacy_go3_change_log" using primary key columns */
  dbLegacyGo3ChangeLogByPk?: Maybe<DbLegacyGo3ChangeLog>;
  /** fetch data from the table: "db.legal_entity" */
  dbLegalEntity: Array<DbLegalEntity>;
  /** fetch aggregated fields from the table: "db.legal_entity" */
  dbLegalEntityAggregate: DbLegalEntityAggregate;
  /** fetch data from the table: "db.legal_entity" using primary key columns */
  dbLegalEntityByPk?: Maybe<DbLegalEntity>;
  /** fetch data from the table: "db.license_partner" */
  dbLicensePartner: Array<DbLicensePartner>;
  /** fetch aggregated fields from the table: "db.license_partner" */
  dbLicensePartnerAggregate: DbLicensePartnerAggregate;
  /** fetch data from the table: "db.license_partner" using primary key columns */
  dbLicensePartnerByPk?: Maybe<DbLicensePartner>;
  /** fetch data from the table: "db.license_partner_profile" */
  dbLicensePartnerProfile: Array<DbLicensePartnerProfile>;
  /** fetch aggregated fields from the table: "db.license_partner_profile" */
  dbLicensePartnerProfileAggregate: DbLicensePartnerProfileAggregate;
  /** fetch data from the table: "db.license_partner_profile" using primary key columns */
  dbLicensePartnerProfileByPk?: Maybe<DbLicensePartnerProfile>;
  /** fetch data from the table: "db.license_partner_profile_featured_agent" */
  dbLicensePartnerProfileFeaturedAgent: Array<DbLicensePartnerProfileFeaturedAgent>;
  /** fetch data from the table: "db.license_partner_profile_featured_agent" using primary key columns */
  dbLicensePartnerProfileFeaturedAgentByPk?: Maybe<DbLicensePartnerProfileFeaturedAgent>;
  /** fetch data from the table: "db.license_partner_profile_featured_content_teaser" */
  dbLicensePartnerProfileFeaturedContentTeaser: Array<DbLicensePartnerProfileFeaturedContentTeaser>;
  /** fetch data from the table: "db.license_partner_profile_featured_content_teaser" using primary key columns */
  dbLicensePartnerProfileFeaturedContentTeaserByPk?: Maybe<DbLicensePartnerProfileFeaturedContentTeaser>;
  /** fetch data from the table: "db.license_partner_profile_former_url" */
  dbLicensePartnerProfileFormerUrl: Array<DbLicensePartnerProfileFormerUrl>;
  /** fetch aggregated fields from the table: "db.license_partner_profile_former_url" */
  dbLicensePartnerProfileFormerUrlAggregate: DbLicensePartnerProfileFormerUrlAggregate;
  /** fetch data from the table: "db.license_partner_profile_former_url" using primary key columns */
  dbLicensePartnerProfileFormerUrlByPk?: Maybe<DbLicensePartnerProfileFormerUrl>;
  /** fetch data from the table: "db.license_partner_profile_image" */
  dbLicensePartnerProfileImage: Array<DbLicensePartnerProfileImage>;
  /** fetch aggregated fields from the table: "db.license_partner_profile_image" */
  dbLicensePartnerProfileImageAggregate: DbLicensePartnerProfileImageAggregate;
  /** fetch data from the table: "db.license_partner_profile_image" using primary key columns */
  dbLicensePartnerProfileImageByPk?: Maybe<DbLicensePartnerProfileImage>;
  /** fetch data from the table: "db.license_partner_profile_language_variant" */
  dbLicensePartnerProfileLanguageVariant: Array<DbLicensePartnerProfileLanguageVariant>;
  /** fetch aggregated fields from the table: "db.license_partner_profile_language_variant" */
  dbLicensePartnerProfileLanguageVariantAggregate: DbLicensePartnerProfileLanguageVariantAggregate;
  /** fetch data from the table: "db.license_partner_profile_language_variant" using primary key columns */
  dbLicensePartnerProfileLanguageVariantByPk?: Maybe<DbLicensePartnerProfileLanguageVariant>;
  /** fetch data from the table: "db.license_partner_profile_property_preview" */
  dbLicensePartnerProfilePropertyPreview: Array<DbLicensePartnerProfilePropertyPreview>;
  /** fetch data from the table: "db.license_partner_profile_property_preview" using primary key columns */
  dbLicensePartnerProfilePropertyPreviewByPk?: Maybe<DbLicensePartnerProfilePropertyPreview>;
  /** fetch data from the table: "db.migration_source" */
  dbMigrationSource: Array<DbMigrationSource>;
  /** fetch aggregated fields from the table: "db.migration_source" */
  dbMigrationSourceAggregate: DbMigrationSourceAggregate;
  /** fetch data from the table: "db.migration_source" using primary key columns */
  dbMigrationSourceByPk?: Maybe<DbMigrationSource>;
  /** fetch data from the table: "db.migration_source_pk_list" */
  dbMigrationSourcePkList: Array<DbMigrationSourcePkList>;
  /** fetch aggregated fields from the table: "db.migration_source_pk_list" */
  dbMigrationSourcePkListAggregate: DbMigrationSourcePkListAggregate;
  /** fetch data from the table: "db.migration_source_pk_list" using primary key columns */
  dbMigrationSourcePkListByPk?: Maybe<DbMigrationSourcePkList>;
  /** fetch data from the table: "db.migration_target" */
  dbMigrationTarget: Array<DbMigrationTarget>;
  /** fetch aggregated fields from the table: "db.migration_target" */
  dbMigrationTargetAggregate: DbMigrationTargetAggregate;
  /** fetch data from the table: "db.migration_target" using primary key columns */
  dbMigrationTargetByPk?: Maybe<DbMigrationTarget>;
  /** fetch data from the table: "db.nylas_email_integration" */
  dbNylasEmailIntegration: Array<DbNylasEmailIntegration>;
  /** fetch aggregated fields from the table: "db.nylas_email_integration" */
  dbNylasEmailIntegrationAggregate: DbNylasEmailIntegrationAggregate;
  /** fetch data from the table: "db.nylas_email_integration" using primary key columns */
  dbNylasEmailIntegrationByPk?: Maybe<DbNylasEmailIntegration>;
  /** fetch data from the table: "db.one_way_mapping_from_lead_id_to_contact_id" */
  dbOneWayMappingFromLeadIdToContactId: Array<DbOneWayMappingFromLeadIdToContactId>;
  /** fetch data from the table: "db.optimizely_client" */
  dbOptimizelyClient: Array<DbOptimizelyClient>;
  /** fetch data from the table: "db.optimizely_client" using primary key columns */
  dbOptimizelyClientByPk?: Maybe<DbOptimizelyClient>;
  /** fetch data from the table: "db.potential_duplicate_contact" */
  dbPotentialDuplicateContact: Array<DbPotentialDuplicateContact>;
  /** fetch aggregated fields from the table: "db.potential_duplicate_contact" */
  dbPotentialDuplicateContactAggregate: DbPotentialDuplicateContactAggregate;
  /** fetch data from the table: "db.potential_duplicate_contact" using primary key columns */
  dbPotentialDuplicateContactByPk?: Maybe<DbPotentialDuplicateContact>;
  /** fetch data from the table: "db.printed_document_activity" */
  dbPrintedDocumentActivity: Array<DbPrintedDocumentActivity>;
  /** fetch data from the table: "db.printed_document_activity" using primary key columns */
  dbPrintedDocumentActivityByPk?: Maybe<DbPrintedDocumentActivity>;
  /** fetch data from the table: "db.property_lounge" */
  dbPropertyLounge: Array<DbPropertyLounge>;
  /** fetch data from the table: "db.property_lounge" using primary key columns */
  dbPropertyLoungeByPk?: Maybe<DbPropertyLounge>;
  /** fetch data from the table: "db.qualification_form_link" */
  dbQualificationFormLink: Array<DbQualificationFormLink>;
  /** fetch aggregated fields from the table: "db.qualification_form_link" */
  dbQualificationFormLinkAggregate: DbQualificationFormLinkAggregate;
  /** fetch data from the table: "db.qualification_form_link" using primary key columns */
  dbQualificationFormLinkByPk?: Maybe<DbQualificationFormLink>;
  /** fetch data from the table: "db.qualiform_lead_intent" */
  dbQualiformLeadIntent: Array<DbQualiformLeadIntent>;
  /** fetch data from the table: "db.qualiform_lead_intent" using primary key columns */
  dbQualiformLeadIntentByPk?: Maybe<DbQualiformLeadIntent>;
  /** fetch data from the table: "db.qualiform_lead_source" */
  dbQualiformLeadSource: Array<DbQualiformLeadSource>;
  /** fetch data from the table: "db.qualiform_lead_source" using primary key columns */
  dbQualiformLeadSourceByPk?: Maybe<DbQualiformLeadSource>;
  /** fetch data from the table: "db.qualiform_mandatory_field" */
  dbQualiformMandatoryField: Array<DbQualiformMandatoryField>;
  /** fetch data from the table: "db.qualiform_mandatory_field" using primary key columns */
  dbQualiformMandatoryFieldByPk?: Maybe<DbQualiformMandatoryField>;
  /** fetch data from the table: "db.qualiform_shop_setting" */
  dbQualiformShopSetting: Array<DbQualiformShopSetting>;
  /** fetch data from the table: "db.qualiform_shop_setting" using primary key columns */
  dbQualiformShopSettingByPk?: Maybe<DbQualiformShopSetting>;
  /** fetch data from the table: "db.referral" */
  dbReferral: Array<DbReferral>;
  /** fetch data from the table: "db.referral_activity" */
  dbReferralActivity: Array<DbReferralActivity>;
  /** fetch data from the table: "db.referral_activity" using primary key columns */
  dbReferralActivityByPk?: Maybe<DbReferralActivity>;
  /** fetch aggregated fields from the table: "db.referral" */
  dbReferralAggregate: DbReferralAggregate;
  /** fetch data from the table: "db.referral" using primary key columns */
  dbReferralByPk?: Maybe<DbReferral>;
  /** fetch data from the table: "db.seller_rent_out_specification" */
  dbSellerRentOutSpecification: Array<DbSellerRentOutSpecification>;
  /** fetch data from the table: "db.seller_rent_out_specification" using primary key columns */
  dbSellerRentOutSpecificationByPk?: Maybe<DbSellerRentOutSpecification>;
  /** fetch data from the table: "db.seller_rent_out_specification_cdf" */
  dbSellerRentOutSpecificationCdf: Array<DbSellerRentOutSpecificationCdf>;
  /** fetch data from the table: "db.seller_rent_out_specification_cdf" using primary key columns */
  dbSellerRentOutSpecificationCdfByPk?: Maybe<DbSellerRentOutSpecificationCdf>;
  /** fetch data from the table: "db.session" */
  dbSession: Array<DbSession>;
  /** fetch aggregated fields from the table: "db.session" */
  dbSessionAggregate: DbSessionAggregate;
  /** fetch data from the table: "db.session" using primary key columns */
  dbSessionByPk?: Maybe<DbSession>;
  /** fetch data from the table: "db.shop" */
  dbShop: Array<DbShop>;
  /** fetch aggregated fields from the table: "db.shop" */
  dbShopAggregate: DbShopAggregate;
  /** fetch data from the table: "db.shop" using primary key columns */
  dbShopByPk?: Maybe<DbShop>;
  /** fetch data from the table: "db.shop_commission_contract" */
  dbShopCommissionContract: Array<DbShopCommissionContract>;
  /** fetch data from the table: "db.shop_commission_contract" using primary key columns */
  dbShopCommissionContractByPk?: Maybe<DbShopCommissionContract>;
  /** fetch data from the table: "db.shop_group" */
  dbShopGroup: Array<DbShopGroup>;
  /** fetch aggregated fields from the table: "db.shop_group" */
  dbShopGroupAggregate: DbShopGroupAggregate;
  /** fetch data from the table: "db.shop_group" using primary key columns */
  dbShopGroupByPk?: Maybe<DbShopGroup>;
  /** fetch data from the table: "db.shop_managed_consent" */
  dbShopManagedConsent: Array<DbShopManagedConsent>;
  /** fetch data from the table: "db.shop_managed_consent" using primary key columns */
  dbShopManagedConsentByPk?: Maybe<DbShopManagedConsent>;
  /** fetch data from the table: "db.shop_profile" */
  dbShopProfile: Array<DbShopProfile>;
  /** fetch aggregated fields from the table: "db.shop_profile" */
  dbShopProfileAggregate: DbShopProfileAggregate;
  /** fetch data from the table: "db.shop_profile" using primary key columns */
  dbShopProfileByPk?: Maybe<DbShopProfile>;
  /** fetch data from the table: "db.shop_profile_career" */
  dbShopProfileCareer: Array<DbShopProfileCareer>;
  /** fetch aggregated fields from the table: "db.shop_profile_career" */
  dbShopProfileCareerAggregate: DbShopProfileCareerAggregate;
  /** fetch data from the table: "db.shop_profile_career" using primary key columns */
  dbShopProfileCareerByPk?: Maybe<DbShopProfileCareer>;
  /** fetch data from the table: "db.shop_profile_city_district" */
  dbShopProfileCityDistrict: Array<DbShopProfileCityDistrict>;
  /** fetch aggregated fields from the table: "db.shop_profile_city_district" */
  dbShopProfileCityDistrictAggregate: DbShopProfileCityDistrictAggregate;
  /** fetch data from the table: "db.shop_profile_city_district" using primary key columns */
  dbShopProfileCityDistrictByPk?: Maybe<DbShopProfileCityDistrict>;
  /** fetch data from the table: "db.shop_profile_customer_mails" */
  dbShopProfileCustomerMails: Array<DbShopProfileCustomerMails>;
  /** fetch data from the table: "db.shop_profile_customer_mails" using primary key columns */
  dbShopProfileCustomerMailsByPk?: Maybe<DbShopProfileCustomerMails>;
  /** fetch data from the table: "db.shop_profile_description" */
  dbShopProfileDescription: Array<DbShopProfileDescription>;
  /** fetch aggregated fields from the table: "db.shop_profile_description" */
  dbShopProfileDescriptionAggregate: DbShopProfileDescriptionAggregate;
  /** fetch data from the table: "db.shop_profile_description" using primary key columns */
  dbShopProfileDescriptionByPk?: Maybe<DbShopProfileDescription>;
  /** fetch data from the table: "db.shop_profile_expertise" */
  dbShopProfileExpertise: Array<DbShopProfileExpertise>;
  /** fetch aggregated fields from the table: "db.shop_profile_expertise" */
  dbShopProfileExpertiseAggregate: DbShopProfileExpertiseAggregate;
  /** fetch data from the table: "db.shop_profile_expertise" using primary key columns */
  dbShopProfileExpertiseByPk?: Maybe<DbShopProfileExpertise>;
  /** fetch data from the table: "db.shop_profile_featured_agent" */
  dbShopProfileFeaturedAgent: Array<DbShopProfileFeaturedAgent>;
  /** fetch data from the table: "db.shop_profile_featured_agent" using primary key columns */
  dbShopProfileFeaturedAgentByPk?: Maybe<DbShopProfileFeaturedAgent>;
  /** fetch data from the table: "db.shop_profile_featured_content" */
  dbShopProfileFeaturedContent: Array<DbShopProfileFeaturedContent>;
  /** fetch data from the table: "db.shop_profile_featured_content" using primary key columns */
  dbShopProfileFeaturedContentByPk?: Maybe<DbShopProfileFeaturedContent>;
  /** fetch data from the table: "db.shop_profile_featured_content_teaser" */
  dbShopProfileFeaturedContentTeaser: Array<DbShopProfileFeaturedContentTeaser>;
  /** fetch data from the table: "db.shop_profile_featured_content_teaser" using primary key columns */
  dbShopProfileFeaturedContentTeaserByPk?: Maybe<DbShopProfileFeaturedContentTeaser>;
  /** fetch data from the table: "db.shop_profile_former_url" */
  dbShopProfileFormerUrl: Array<DbShopProfileFormerUrl>;
  /** fetch aggregated fields from the table: "db.shop_profile_former_url" */
  dbShopProfileFormerUrlAggregate: DbShopProfileFormerUrlAggregate;
  /** fetch data from the table: "db.shop_profile_former_url" using primary key columns */
  dbShopProfileFormerUrlByPk?: Maybe<DbShopProfileFormerUrl>;
  /** fetch data from the table: "db.shop_profile_image" */
  dbShopProfileImage: Array<DbShopProfileImage>;
  /** fetch aggregated fields from the table: "db.shop_profile_image" */
  dbShopProfileImageAggregate: DbShopProfileImageAggregate;
  /** fetch data from the table: "db.shop_profile_image" using primary key columns */
  dbShopProfileImageByPk?: Maybe<DbShopProfileImage>;
  /** fetch data from the table: "db.shop_profile_imprint" */
  dbShopProfileImprint: Array<DbShopProfileImprint>;
  /** fetch aggregated fields from the table: "db.shop_profile_imprint" */
  dbShopProfileImprintAggregate: DbShopProfileImprintAggregate;
  /** fetch data from the table: "db.shop_profile_imprint" using primary key columns */
  dbShopProfileImprintByPk?: Maybe<DbShopProfileImprint>;
  /** fetch data from the table: "db.shop_profile_privacy_policy" */
  dbShopProfilePrivacyPolicy: Array<DbShopProfilePrivacyPolicy>;
  /** fetch aggregated fields from the table: "db.shop_profile_privacy_policy" */
  dbShopProfilePrivacyPolicyAggregate: DbShopProfilePrivacyPolicyAggregate;
  /** fetch data from the table: "db.shop_profile_privacy_policy" using primary key columns */
  dbShopProfilePrivacyPolicyByPk?: Maybe<DbShopProfilePrivacyPolicy>;
  /** fetch data from the table: "db.shop_profile_property_preview" */
  dbShopProfilePropertyPreview: Array<DbShopProfilePropertyPreview>;
  /** fetch aggregated fields from the table: "db.shop_profile_property_preview" */
  dbShopProfilePropertyPreviewAggregate: DbShopProfilePropertyPreviewAggregate;
  /** fetch data from the table: "db.shop_profile_property_preview" using primary key columns */
  dbShopProfilePropertyPreviewByPk?: Maybe<DbShopProfilePropertyPreview>;
  /** fetch data from the table: "db.shop_profile_quote" */
  dbShopProfileQuote: Array<DbShopProfileQuote>;
  /** fetch aggregated fields from the table: "db.shop_profile_quote" */
  dbShopProfileQuoteAggregate: DbShopProfileQuoteAggregate;
  /** fetch data from the table: "db.shop_profile_quote" using primary key columns */
  dbShopProfileQuoteByPk?: Maybe<DbShopProfileQuote>;
  /** fetch data from the table: "db.shop_profile_seo_tracking" */
  dbShopProfileSeoTracking: Array<DbShopProfileSeoTracking>;
  /** fetch data from the table: "db.shop_profile_seo_tracking" using primary key columns */
  dbShopProfileSeoTrackingByPk?: Maybe<DbShopProfileSeoTracking>;
  /** fetch data from the table: "db.shop_profile_social_media_profiles" */
  dbShopProfileSocialMediaProfiles: Array<DbShopProfileSocialMediaProfiles>;
  /** fetch aggregated fields from the table: "db.shop_profile_social_media_profiles" */
  dbShopProfileSocialMediaProfilesAggregate: DbShopProfileSocialMediaProfilesAggregate;
  /** fetch data from the table: "db.shop_profile_social_media_profiles" using primary key columns */
  dbShopProfileSocialMediaProfilesByPk?: Maybe<DbShopProfileSocialMediaProfiles>;
  /** fetch data from the table: "db.shop_profile_spoken_language" */
  dbShopProfileSpokenLanguage: Array<DbShopProfileSpokenLanguage>;
  /** fetch aggregated fields from the table: "db.shop_profile_spoken_language" */
  dbShopProfileSpokenLanguageAggregate: DbShopProfileSpokenLanguageAggregate;
  /** fetch data from the table: "db.shop_profile_spoken_language" using primary key columns */
  dbShopProfileSpokenLanguageByPk?: Maybe<DbShopProfileSpokenLanguage>;
  /** fetch data from the table: "db.shop_profile_team" */
  dbShopProfileTeam: Array<DbShopProfileTeam>;
  /** fetch data from the table: "db.shop_profile_team" using primary key columns */
  dbShopProfileTeamByPk?: Maybe<DbShopProfileTeam>;
  /** fetch data from the table: "db.shop_profile_terms_and_conditions" */
  dbShopProfileTermsAndConditions: Array<DbShopProfileTermsAndConditions>;
  /** fetch data from the table: "db.shop_profile_terms_and_conditions" using primary key columns */
  dbShopProfileTermsAndConditionsByPk?: Maybe<DbShopProfileTermsAndConditions>;
  /** fetch data from the table: "db.shop_profile_withdrawal_policy" */
  dbShopProfileWithdrawalPolicy: Array<DbShopProfileWithdrawalPolicy>;
  /** fetch data from the table: "db.shop_profile_withdrawal_policy" using primary key columns */
  dbShopProfileWithdrawalPolicyByPk?: Maybe<DbShopProfileWithdrawalPolicy>;
  /** fetch data from the table: "db.shop_right_of_withdrawal" */
  dbShopRightOfWithdrawal: Array<DbShopRightOfWithdrawal>;
  /** fetch data from the table: "db.shop_right_of_withdrawal" using primary key columns */
  dbShopRightOfWithdrawalByPk?: Maybe<DbShopRightOfWithdrawal>;
  /** fetch data from the table: "db.shop_setting" */
  dbShopSetting: Array<DbShopSetting>;
  /** fetch aggregated fields from the table: "db.shop_setting" */
  dbShopSettingAggregate: DbShopSettingAggregate;
  /** fetch data from the table: "db.shop_setting" using primary key columns */
  dbShopSettingByPk?: Maybe<DbShopSetting>;
  /** fetch data from the table: "db.snapshot_buyer_to_rent_specification" */
  dbSnapshotBuyerToRentSpecification: Array<DbSnapshotBuyerToRentSpecification>;
  /** fetch aggregated fields from the table: "db.snapshot_buyer_to_rent_specification" */
  dbSnapshotBuyerToRentSpecificationAggregate: DbSnapshotBuyerToRentSpecificationAggregate;
  /** fetch data from the table: "db.snapshot_buyer_to_rent_specification" using primary key columns */
  dbSnapshotBuyerToRentSpecificationByPk?: Maybe<DbSnapshotBuyerToRentSpecification>;
  /** fetch data from the table: "db.snapshot_custom_search_demand_polygon" */
  dbSnapshotCustomSearchDemandPolygon: Array<DbSnapshotCustomSearchDemandPolygon>;
  /** fetch data from the table: "db.snapshot_custom_search_demand_polygon" using primary key columns */
  dbSnapshotCustomSearchDemandPolygonByPk?: Maybe<DbSnapshotCustomSearchDemandPolygon>;
  /** fetch data from the table: "db.snapshot_seller_rent_out_specification" */
  dbSnapshotSellerRentOutSpecification: Array<DbSnapshotSellerRentOutSpecification>;
  /** fetch aggregated fields from the table: "db.snapshot_seller_rent_out_specification" */
  dbSnapshotSellerRentOutSpecificationAggregate: DbSnapshotSellerRentOutSpecificationAggregate;
  /** fetch data from the table: "db.snapshot_seller_rent_out_specification" using primary key columns */
  dbSnapshotSellerRentOutSpecificationByPk?: Maybe<DbSnapshotSellerRentOutSpecification>;
  /** fetch data from the table: "db.tag" */
  dbTag: Array<DbTag>;
  /** fetch data from the table: "db.tag" using primary key columns */
  dbTagByPk?: Maybe<DbTag>;
  /** fetch data from the table: "db.task_activity" */
  dbTaskActivity: Array<DbTaskActivity>;
  /** fetch data from the table: "db.task_activity" using primary key columns */
  dbTaskActivityByPk?: Maybe<DbTaskActivity>;
  /** fetch data from the table: "db.task_agent" */
  dbTaskAgent: Array<DbTaskAgent>;
  /** fetch data from the table: "db.task_contact" */
  dbTaskContact: Array<DbTaskContact>;
  /** fetch data from the table: "db.task_lead" */
  dbTaskLead: Array<DbTaskLead>;
  /** fetch data from the table: "db.team" */
  dbTeam: Array<DbTeam>;
  /** fetch aggregated fields from the table: "db.team" */
  dbTeamAggregate: DbTeamAggregate;
  /** fetch data from the table: "db.team" using primary key columns */
  dbTeamByPk?: Maybe<DbTeam>;
  /** fetch data from the table: "db.tracking_lead" */
  dbTrackingLead: Array<DbTrackingLead>;
  /** fetch aggregated fields from the table: "db.tracking_lead" */
  dbTrackingLeadAggregate: DbTrackingLeadAggregate;
  /** fetch data from the table: "db.tracking_lead" using primary key columns */
  dbTrackingLeadByPk?: Maybe<DbTrackingLead>;
  /** fetch data from the table: "db.twilio_configuration" */
  dbTwilioConfiguration: Array<DbTwilioConfiguration>;
  /** fetch aggregated fields from the table: "db.twilio_configuration" */
  dbTwilioConfigurationAggregate: DbTwilioConfigurationAggregate;
  /** fetch data from the table: "db.twilio_configuration" using primary key columns */
  dbTwilioConfigurationByPk?: Maybe<DbTwilioConfiguration>;
  /** fetch data from the table: "db.user_admin_assignment" */
  dbUserAdminAssignment: Array<DbUserAdminAssignment>;
  /** fetch aggregated fields from the table: "db.user_admin_assignment" */
  dbUserAdminAssignmentAggregate: DbUserAdminAssignmentAggregate;
  /** fetch data from the table: "db.user_admin_assignment" using primary key columns */
  dbUserAdminAssignmentByPk?: Maybe<DbUserAdminAssignment>;
  /** fetch data from the table: "db.user_shop_assignment" */
  dbUserShopAssignment: Array<DbUserShopAssignment>;
  /** fetch aggregated fields from the table: "db.user_shop_assignment" */
  dbUserShopAssignmentAggregate: DbUserShopAssignmentAggregate;
  /** fetch data from the table: "db.user_shop_assignment" using primary key columns */
  dbUserShopAssignmentByPk?: Maybe<DbUserShopAssignment>;
  /** fetch data from the table: "db.user_team_assignment" */
  dbUserTeamAssignment: Array<DbUserTeamAssignment>;
  /** fetch aggregated fields from the table: "db.user_team_assignment" */
  dbUserTeamAssignmentAggregate: DbUserTeamAssignmentAggregate;
  /** fetch data from the table: "db.user_team_assignment" using primary key columns */
  dbUserTeamAssignmentByPk?: Maybe<DbUserTeamAssignment>;
  /** fetch data from the table: "db.utm_param" */
  dbUtmParam: Array<DbUtmParam>;
  /** fetch data from the table: "db.utm_param" using primary key columns */
  dbUtmParamByPk?: Maybe<DbUtmParam>;
  /** fetch data from the table: "enums.activity_type" */
  enumsActivityType: Array<EnumsActivityType>;
  /** fetch data from the table: "enums.activity_type" using primary key columns */
  enumsActivityTypeByPk?: Maybe<EnumsActivityType>;
  /** fetch data from the table: "enums.address_format" */
  enumsAddressFormat: Array<EnumsAddressFormat>;
  /** fetch data from the table: "enums.address_format" using primary key columns */
  enumsAddressFormatByPk?: Maybe<EnumsAddressFormat>;
  /** fetch data from the table: "enums.address_type" */
  enumsAddressType: Array<EnumsAddressType>;
  /** fetch data from the table: "enums.address_type" using primary key columns */
  enumsAddressTypeByPk?: Maybe<EnumsAddressType>;
  /** fetch data from the table: "enums.appointment_type" */
  enumsAppointmentType: Array<EnumsAppointmentType>;
  /** fetch data from the table: "enums.appointment_type" using primary key columns */
  enumsAppointmentTypeByPk?: Maybe<EnumsAppointmentType>;
  /** fetch data from the table: "enums.asset_condition" */
  enumsAssetCondition: Array<EnumsAssetCondition>;
  /** fetch data from the table: "enums.asset_condition" using primary key columns */
  enumsAssetConditionByPk?: Maybe<EnumsAssetCondition>;
  /** fetch data from the table: "enums.asset_sub_type" */
  enumsAssetSubType: Array<EnumsAssetSubType>;
  /** fetch data from the table: "enums.asset_sub_type" using primary key columns */
  enumsAssetSubTypeByPk?: Maybe<EnumsAssetSubType>;
  /** fetch data from the table: "enums.asset_type" */
  enumsAssetType: Array<EnumsAssetType>;
  /** fetch data from the table: "enums.asset_type" using primary key columns */
  enumsAssetTypeByPk?: Maybe<EnumsAssetType>;
  /** fetch data from the table: "enums.asset_variation" */
  enumsAssetVariation: Array<EnumsAssetVariation>;
  /** fetch data from the table: "enums.asset_variation" using primary key columns */
  enumsAssetVariationByPk?: Maybe<EnumsAssetVariation>;
  /** fetch data from the table: "enums.base_activity_category" */
  enumsBaseActivityCategory: Array<EnumsBaseActivityCategory>;
  /** fetch data from the table: "enums.base_activity_category" using primary key columns */
  enumsBaseActivityCategoryByPk?: Maybe<EnumsBaseActivityCategory>;
  /** fetch data from the table: "enums.call_direction" */
  enumsCallDirection: Array<EnumsCallDirection>;
  /** fetch data from the table: "enums.call_direction" using primary key columns */
  enumsCallDirectionByPk?: Maybe<EnumsCallDirection>;
  /** fetch data from the table: "enums.call_outcome" */
  enumsCallOutcome: Array<EnumsCallOutcome>;
  /** fetch data from the table: "enums.call_outcome" using primary key columns */
  enumsCallOutcomeByPk?: Maybe<EnumsCallOutcome>;
  /** fetch data from the table: "enums.call_status" */
  enumsCallStatus: Array<EnumsCallStatus>;
  /** fetch data from the table: "enums.call_status" using primary key columns */
  enumsCallStatusByPk?: Maybe<EnumsCallStatus>;
  /** fetch data from the table: "enums.consent_event_status" */
  enumsConsentEventStatus: Array<EnumsConsentEventStatus>;
  /** fetch data from the table: "enums.consent_event_status" using primary key columns */
  enumsConsentEventStatusByPk?: Maybe<EnumsConsentEventStatus>;
  /** fetch data from the table: "enums.consent_event_type" */
  enumsConsentEventType: Array<EnumsConsentEventType>;
  /** fetch data from the table: "enums.consent_event_type" using primary key columns */
  enumsConsentEventTypeByPk?: Maybe<EnumsConsentEventType>;
  /** fetch data from the table: "enums.consent_source" */
  enumsConsentSource: Array<EnumsConsentSource>;
  /** fetch data from the table: "enums.consent_source" using primary key columns */
  enumsConsentSourceByPk?: Maybe<EnumsConsentSource>;
  /** fetch data from the table: "enums.consent_status" */
  enumsConsentStatus: Array<EnumsConsentStatus>;
  /** fetch data from the table: "enums.consent_status" using primary key columns */
  enumsConsentStatusByPk?: Maybe<EnumsConsentStatus>;
  /** fetch data from the table: "enums.consent_template_type" */
  enumsConsentTemplateType: Array<EnumsConsentTemplateType>;
  /** fetch data from the table: "enums.consent_template_type" using primary key columns */
  enumsConsentTemplateTypeByPk?: Maybe<EnumsConsentTemplateType>;
  /** fetch data from the table: "enums.consent_type" */
  enumsConsentType: Array<EnumsConsentType>;
  /** fetch data from the table: "enums.consent_type" using primary key columns */
  enumsConsentTypeByPk?: Maybe<EnumsConsentType>;
  /** fetch data from the table: "enums.contact_relation_type" */
  enumsContactRelationType: Array<EnumsContactRelationType>;
  /** fetch data from the table: "enums.contact_relation_type" using primary key columns */
  enumsContactRelationTypeByPk?: Maybe<EnumsContactRelationType>;
  /** fetch data from the table: "enums.contact_status" */
  enumsContactStatus: Array<EnumsContactStatus>;
  /** fetch data from the table: "enums.contact_status" using primary key columns */
  enumsContactStatusByPk?: Maybe<EnumsContactStatus>;
  /** fetch data from the table: "enums.contact_time" */
  enumsContactTime: Array<EnumsContactTime>;
  /** fetch data from the table: "enums.contact_time" using primary key columns */
  enumsContactTimeByPk?: Maybe<EnumsContactTime>;
  /** fetch data from the table: "enums.contact_type" */
  enumsContactType: Array<EnumsContactType>;
  /** fetch data from the table: "enums.contact_type" using primary key columns */
  enumsContactTypeByPk?: Maybe<EnumsContactType>;
  /** fetch data from the table: "enums.contact_way" */
  enumsContactWay: Array<EnumsContactWay>;
  /** fetch data from the table: "enums.contact_way" using primary key columns */
  enumsContactWayByPk?: Maybe<EnumsContactWay>;
  /** fetch data from the table: "enums.country" */
  enumsCountry: Array<EnumsCountry>;
  /** fetch data from the table: "enums.country" using primary key columns */
  enumsCountryByPk?: Maybe<EnumsCountry>;
  /** fetch data from the table: "enums.currency" */
  enumsCurrency: Array<EnumsCurrency>;
  /** fetch data from the table: "enums.currency" using primary key columns */
  enumsCurrencyByPk?: Maybe<EnumsCurrency>;
  /** fetch data from the table: "enums.custom_view_type" */
  enumsCustomViewType: Array<EnumsCustomViewType>;
  /** fetch data from the table: "enums.custom_view_type" using primary key columns */
  enumsCustomViewTypeByPk?: Maybe<EnumsCustomViewType>;
  /** fetch data from the table: "enums.email_address_type" */
  enumsEmailAddressType: Array<EnumsEmailAddressType>;
  /** fetch data from the table: "enums.email_address_type" using primary key columns */
  enumsEmailAddressTypeByPk?: Maybe<EnumsEmailAddressType>;
  /** fetch data from the table: "enums.email_callback_event_source" */
  enumsEmailCallbackEventSource: Array<EnumsEmailCallbackEventSource>;
  /** fetch data from the table: "enums.email_callback_event_source" using primary key columns */
  enumsEmailCallbackEventSourceByPk?: Maybe<EnumsEmailCallbackEventSource>;
  /** fetch data from the table: "enums.email_direction" */
  enumsEmailDirection: Array<EnumsEmailDirection>;
  /** fetch data from the table: "enums.email_direction" using primary key columns */
  enumsEmailDirectionByPk?: Maybe<EnumsEmailDirection>;
  /** fetch data from the table: "enums.email_integration_scope" */
  enumsEmailIntegrationScope: Array<EnumsEmailIntegrationScope>;
  /** fetch data from the table: "enums.email_integration_scope" using primary key columns */
  enumsEmailIntegrationScopeByPk?: Maybe<EnumsEmailIntegrationScope>;
  /** fetch data from the table: "enums.email_placeholder_scope" */
  enumsEmailPlaceholderScope: Array<EnumsEmailPlaceholderScope>;
  /** fetch data from the table: "enums.email_placeholder_scope" using primary key columns */
  enumsEmailPlaceholderScopeByPk?: Maybe<EnumsEmailPlaceholderScope>;
  /** fetch data from the table: "enums.email_placeholder_type" */
  enumsEmailPlaceholderType: Array<EnumsEmailPlaceholderType>;
  /** fetch data from the table: "enums.email_placeholder_type" using primary key columns */
  enumsEmailPlaceholderTypeByPk?: Maybe<EnumsEmailPlaceholderType>;
  /** fetch data from the table: "enums.email_signature_scope" */
  enumsEmailSignatureScope: Array<EnumsEmailSignatureScope>;
  /** fetch data from the table: "enums.email_signature_scope" using primary key columns */
  enumsEmailSignatureScopeByPk?: Maybe<EnumsEmailSignatureScope>;
  /** fetch data from the table: "enums.email_status" */
  enumsEmailStatus: Array<EnumsEmailStatus>;
  /** fetch data from the table: "enums.email_status" using primary key columns */
  enumsEmailStatusByPk?: Maybe<EnumsEmailStatus>;
  /** fetch data from the table: "enums.email_template_scope" */
  enumsEmailTemplateScope: Array<EnumsEmailTemplateScope>;
  /** fetch data from the table: "enums.email_template_scope" using primary key columns */
  enumsEmailTemplateScopeByPk?: Maybe<EnumsEmailTemplateScope>;
  /** fetch data from the table: "enums.email_template_type" */
  enumsEmailTemplateType: Array<EnumsEmailTemplateType>;
  /** fetch data from the table: "enums.email_template_type" using primary key columns */
  enumsEmailTemplateTypeByPk?: Maybe<EnumsEmailTemplateType>;
  /** fetch data from the table: "enums.email_tracking_type" */
  enumsEmailTrackingType: Array<EnumsEmailTrackingType>;
  /** fetch data from the table: "enums.email_tracking_type" using primary key columns */
  enumsEmailTrackingTypeByPk?: Maybe<EnumsEmailTrackingType>;
  /** fetch data from the table: "enums.entity_type" */
  enumsEntityType: Array<EnumsEntityType>;
  /** fetch data from the table: "enums.entity_type" using primary key columns */
  enumsEntityTypeByPk?: Maybe<EnumsEntityType>;
  /** fetch data from the table: "enums.event_status" */
  enumsEventStatus: Array<EnumsEventStatus>;
  /** fetch data from the table: "enums.event_status" using primary key columns */
  enumsEventStatusByPk?: Maybe<EnumsEventStatus>;
  /** fetch data from the table: "enums.feature_flags" */
  enumsFeatureFlags: Array<EnumsFeatureFlags>;
  /** fetch data from the table: "enums.feature_flags" using primary key columns */
  enumsFeatureFlagsByPk?: Maybe<EnumsFeatureFlags>;
  /** fetch data from the table: "enums.gender" */
  enumsGender: Array<EnumsGender>;
  /** fetch data from the table: "enums.gender" using primary key columns */
  enumsGenderByPk?: Maybe<EnumsGender>;
  /** fetch data from the table: "enums.household_type" */
  enumsHouseholdType: Array<EnumsHouseholdType>;
  /** fetch data from the table: "enums.household_type" using primary key columns */
  enumsHouseholdTypeByPk?: Maybe<EnumsHouseholdType>;
  /** fetch data from the table: "enums.import_lead_status" */
  enumsImportLeadStatus: Array<EnumsImportLeadStatus>;
  /** fetch data from the table: "enums.import_lead_status" using primary key columns */
  enumsImportLeadStatusByPk?: Maybe<EnumsImportLeadStatus>;
  /** fetch data from the table: "enums.intent_category" */
  enumsIntentCategory: Array<EnumsIntentCategory>;
  /** fetch data from the table: "enums.intent_category" using primary key columns */
  enumsIntentCategoryByPk?: Maybe<EnumsIntentCategory>;
  /** fetch data from the table: "enums.intent_type" */
  enumsIntentType: Array<EnumsIntentType>;
  /** fetch data from the table: "enums.intent_type" using primary key columns */
  enumsIntentTypeByPk?: Maybe<EnumsIntentType>;
  /** fetch data from the table: "enums.language" */
  enumsLanguage: Array<EnumsLanguage>;
  /** fetch data from the table: "enums.language" using primary key columns */
  enumsLanguageByPk?: Maybe<EnumsLanguage>;
  /** fetch data from the table: "enums.lead_auto_assignment_rules" */
  enumsLeadAutoAssignmentRules: Array<EnumsLeadAutoAssignmentRules>;
  /** fetch data from the table: "enums.lead_auto_assignment_rules" using primary key columns */
  enumsLeadAutoAssignmentRulesByPk?: Maybe<EnumsLeadAutoAssignmentRules>;
  /** fetch data from the table: "enums.lead_source" */
  enumsLeadSource: Array<EnumsLeadSource>;
  /** fetch data from the table: "enums.lead_source" using primary key columns */
  enumsLeadSourceByPk?: Maybe<EnumsLeadSource>;
  /** fetch data from the table: "enums.lead_status" */
  enumsLeadStatus: Array<EnumsLeadStatus>;
  /** fetch data from the table: "enums.lead_status" using primary key columns */
  enumsLeadStatusByPk?: Maybe<EnumsLeadStatus>;
  /** fetch data from the table: "enums.lead_status_reason" */
  enumsLeadStatusReason: Array<EnumsLeadStatusReason>;
  /** fetch data from the table: "enums.lead_status_reason" using primary key columns */
  enumsLeadStatusReasonByPk?: Maybe<EnumsLeadStatusReason>;
  /** fetch data from the table: "enums.legacy_go3_newsletter_type" */
  enumsLegacyGo3NewsletterType: Array<EnumsLegacyGo3NewsletterType>;
  /** fetch data from the table: "enums.legacy_go3_newsletter_type" using primary key columns */
  enumsLegacyGo3NewsletterTypeByPk?: Maybe<EnumsLegacyGo3NewsletterType>;
  /** fetch data from the table: "enums.license_partner_status" */
  enumsLicensePartnerStatus: Array<EnumsLicensePartnerStatus>;
  /** fetch aggregated fields from the table: "enums.license_partner_status" */
  enumsLicensePartnerStatusAggregate: EnumsLicensePartnerStatusAggregate;
  /** fetch data from the table: "enums.license_partner_status" using primary key columns */
  enumsLicensePartnerStatusByPk?: Maybe<EnumsLicensePartnerStatus>;
  /** fetch data from the table: "enums.mandatory_agent_profile_field" */
  enumsMandatoryAgentProfileField: Array<EnumsMandatoryAgentProfileField>;
  /** fetch data from the table: "enums.mandatory_agent_profile_field" using primary key columns */
  enumsMandatoryAgentProfileFieldByPk?: Maybe<EnumsMandatoryAgentProfileField>;
  /** fetch data from the table: "enums.mandatory_shop_profile_field" */
  enumsMandatoryShopProfileField: Array<EnumsMandatoryShopProfileField>;
  /** fetch data from the table: "enums.mandatory_shop_profile_field" using primary key columns */
  enumsMandatoryShopProfileFieldByPk?: Maybe<EnumsMandatoryShopProfileField>;
  /** fetch data from the table: "enums.marketing_type" */
  enumsMarketingType: Array<EnumsMarketingType>;
  /** fetch data from the table: "enums.marketing_type" using primary key columns */
  enumsMarketingTypeByPk?: Maybe<EnumsMarketingType>;
  /** fetch data from the table: "enums.phone_number_type" */
  enumsPhoneNumberType: Array<EnumsPhoneNumberType>;
  /** fetch data from the table: "enums.phone_number_type" using primary key columns */
  enumsPhoneNumberTypeByPk?: Maybe<EnumsPhoneNumberType>;
  /** fetch data from the table: "enums.priority" */
  enumsPriority: Array<EnumsPriority>;
  /** fetch data from the table: "enums.priority" using primary key columns */
  enumsPriorityByPk?: Maybe<EnumsPriority>;
  /** fetch data from the table: "enums.privacy_level" */
  enumsPrivacyLevel: Array<EnumsPrivacyLevel>;
  /** fetch data from the table: "enums.privacy_level" using primary key columns */
  enumsPrivacyLevelByPk?: Maybe<EnumsPrivacyLevel>;
  /** fetch data from the table: "enums.profession_type" */
  enumsProfessionType: Array<EnumsProfessionType>;
  /** fetch data from the table: "enums.profession_type" using primary key columns */
  enumsProfessionTypeByPk?: Maybe<EnumsProfessionType>;
  /** fetch data from the table: "enums.property_occupation_status" */
  enumsPropertyOccupationStatus: Array<EnumsPropertyOccupationStatus>;
  /** fetch data from the table: "enums.property_occupation_status" using primary key columns */
  enumsPropertyOccupationStatusByPk?: Maybe<EnumsPropertyOccupationStatus>;
  /** fetch data from the table: "enums.property_ownership_status" */
  enumsPropertyOwnershipStatus: Array<EnumsPropertyOwnershipStatus>;
  /** fetch data from the table: "enums.property_ownership_status" using primary key columns */
  enumsPropertyOwnershipStatusByPk?: Maybe<EnumsPropertyOwnershipStatus>;
  /** fetch data from the table: "enums.qualification_form_status" */
  enumsQualificationFormStatus: Array<EnumsQualificationFormStatus>;
  /** fetch data from the table: "enums.qualification_form_status" using primary key columns */
  enumsQualificationFormStatusByPk?: Maybe<EnumsQualificationFormStatus>;
  /** fetch data from the table: "enums.qualiform_fields" */
  enumsQualiformFields: Array<EnumsQualiformFields>;
  /** fetch data from the table: "enums.qualiform_fields" using primary key columns */
  enumsQualiformFieldsByPk?: Maybe<EnumsQualiformFields>;
  /** fetch data from the table: "enums.referral_not_possible_reason" */
  enumsReferralNotPossibleReason: Array<EnumsReferralNotPossibleReason>;
  /** fetch data from the table: "enums.referral_not_possible_reason" using primary key columns */
  enumsReferralNotPossibleReasonByPk?: Maybe<EnumsReferralNotPossibleReason>;
  /** fetch data from the table: "enums.salutation" */
  enumsSalutation: Array<EnumsSalutation>;
  /** fetch data from the table: "enums.salutation" using primary key columns */
  enumsSalutationByPk?: Maybe<EnumsSalutation>;
  /** fetch data from the table: "enums.session_status" */
  enumsSessionStatus: Array<EnumsSessionStatus>;
  /** fetch data from the table: "enums.session_status" using primary key columns */
  enumsSessionStatusByPk?: Maybe<EnumsSessionStatus>;
  /** fetch data from the table: "enums.shared_type" */
  enumsSharedType: Array<EnumsSharedType>;
  /** fetch data from the table: "enums.shared_type" using primary key columns */
  enumsSharedTypeByPk?: Maybe<EnumsSharedType>;
  /** fetch data from the table: "enums.shop_expertise" */
  enumsShopExpertise: Array<EnumsShopExpertise>;
  /** fetch data from the table: "enums.shop_expertise" using primary key columns */
  enumsShopExpertiseByPk?: Maybe<EnumsShopExpertise>;
  /** fetch data from the table: "enums.shop_status" */
  enumsShopStatus: Array<EnumsShopStatus>;
  /** fetch data from the table: "enums.shop_status" using primary key columns */
  enumsShopStatusByPk?: Maybe<EnumsShopStatus>;
  /** fetch data from the table: "enums.spoken_language" */
  enumsSpokenLanguage: Array<EnumsSpokenLanguage>;
  /** fetch data from the table: "enums.spoken_language" using primary key columns */
  enumsSpokenLanguageByPk?: Maybe<EnumsSpokenLanguage>;
  /** fetch data from the table: "enums.task_appointment_status" */
  enumsTaskAppointmentStatus: Array<EnumsTaskAppointmentStatus>;
  /** fetch data from the table: "enums.task_appointment_status" using primary key columns */
  enumsTaskAppointmentStatusByPk?: Maybe<EnumsTaskAppointmentStatus>;
  /** fetch data from the table: "enums.task_type" */
  enumsTaskType: Array<EnumsTaskType>;
  /** fetch data from the table: "enums.task_type" using primary key columns */
  enumsTaskTypeByPk?: Maybe<EnumsTaskType>;
  /** fetch data from the table: "enums.team_status" */
  enumsTeamStatus: Array<EnumsTeamStatus>;
  /** fetch data from the table: "enums.team_status" using primary key columns */
  enumsTeamStatusByPk?: Maybe<EnumsTeamStatus>;
  /** fetch data from the table: "enums.time_frame" */
  enumsTimeFrame: Array<EnumsTimeFrame>;
  /** fetch data from the table: "enums.time_frame" using primary key columns */
  enumsTimeFrameByPk?: Maybe<EnumsTimeFrame>;
  /** fetch data from the table: "enums.user_role" */
  enumsUserRole: Array<EnumsUserRole>;
  /** fetch data from the table: "enums.user_role" using primary key columns */
  enumsUserRoleByPk?: Maybe<EnumsUserRole>;
  /** fetch data from the table: "enums.zero_commission_reason" */
  enumsZeroCommissionReason: Array<EnumsZeroCommissionReason>;
  /** fetch data from the table: "enums.zero_commission_reason" using primary key columns */
  enumsZeroCommissionReasonByPk?: Maybe<EnumsZeroCommissionReason>;
  /** execute function "extensions.search_contacts" which returns "db.contact" */
  extensionsSearchContacts: Array<DbContact>;
  /** execute function "extensions.search_contacts" and query aggregates on result of table type "db.contact" */
  extensionsSearchContactsAggregate: DbContactAggregate;
  keycloakUser?: Maybe<Array<Maybe<KeycloakUser>>>;
  propertyEngine?: Maybe<PropertyEngineQuery>;
};

export type QueryRootDbActivityTypeGroupingArgs = {
  distinctOn?: InputMaybe<Array<DbActivityTypeGroupingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbActivityTypeGroupingOrderBy>>;
  where?: InputMaybe<DbActivityTypeGroupingBoolExp>;
};

export type QueryRootDbActivityTypeGroupingByPkArgs = {
  activityType: ActivityTypeEnum;
};

export type QueryRootDbAgentArgs = {
  distinctOn?: InputMaybe<Array<DbAgentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentOrderBy>>;
  where?: InputMaybe<DbAgentBoolExp>;
};

export type QueryRootDbAgentAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentOrderBy>>;
  where?: InputMaybe<DbAgentBoolExp>;
};

export type QueryRootDbAgentByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbAgentDefaultEmailSignatureArgs = {
  distinctOn?: InputMaybe<Array<DbAgentDefaultEmailSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentDefaultEmailSignatureOrderBy>>;
  where?: InputMaybe<DbAgentDefaultEmailSignatureBoolExp>;
};

export type QueryRootDbAgentDefaultEmailSignatureAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentDefaultEmailSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentDefaultEmailSignatureOrderBy>>;
  where?: InputMaybe<DbAgentDefaultEmailSignatureBoolExp>;
};

export type QueryRootDbAgentDefaultEmailSignatureByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbAgentDefaultNylasEmailIntegrationArgs = {
  distinctOn?: InputMaybe<Array<DbAgentDefaultNylasEmailIntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentDefaultNylasEmailIntegrationOrderBy>>;
  where?: InputMaybe<DbAgentDefaultNylasEmailIntegrationBoolExp>;
};

export type QueryRootDbAgentDefaultNylasEmailIntegrationAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentDefaultNylasEmailIntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentDefaultNylasEmailIntegrationOrderBy>>;
  where?: InputMaybe<DbAgentDefaultNylasEmailIntegrationBoolExp>;
};

export type QueryRootDbAgentDefaultNylasEmailIntegrationByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbAgentProfileArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileOrderBy>>;
  where?: InputMaybe<DbAgentProfileBoolExp>;
};

export type QueryRootDbAgentProfileAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileOrderBy>>;
  where?: InputMaybe<DbAgentProfileBoolExp>;
};

export type QueryRootDbAgentProfileByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbAgentProfileCityDistrictArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileCityDistrictSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileCityDistrictOrderBy>>;
  where?: InputMaybe<DbAgentProfileCityDistrictBoolExp>;
};

export type QueryRootDbAgentProfileCityDistrictAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileCityDistrictSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileCityDistrictOrderBy>>;
  where?: InputMaybe<DbAgentProfileCityDistrictBoolExp>;
};

export type QueryRootDbAgentProfileCityDistrictByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbAgentProfileDescriptionArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileDescriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileDescriptionOrderBy>>;
  where?: InputMaybe<DbAgentProfileDescriptionBoolExp>;
};

export type QueryRootDbAgentProfileDescriptionAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileDescriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileDescriptionOrderBy>>;
  where?: InputMaybe<DbAgentProfileDescriptionBoolExp>;
};

export type QueryRootDbAgentProfileDescriptionByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbAgentProfileExpertiseArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileExpertiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileExpertiseOrderBy>>;
  where?: InputMaybe<DbAgentProfileExpertiseBoolExp>;
};

export type QueryRootDbAgentProfileExpertiseAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileExpertiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileExpertiseOrderBy>>;
  where?: InputMaybe<DbAgentProfileExpertiseBoolExp>;
};

export type QueryRootDbAgentProfileExpertiseByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbAgentProfileFormerUrlArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileFormerUrlSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileFormerUrlOrderBy>>;
  where?: InputMaybe<DbAgentProfileFormerUrlBoolExp>;
};

export type QueryRootDbAgentProfileFormerUrlAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileFormerUrlSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileFormerUrlOrderBy>>;
  where?: InputMaybe<DbAgentProfileFormerUrlBoolExp>;
};

export type QueryRootDbAgentProfileFormerUrlByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbAgentProfileImageArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileImageOrderBy>>;
  where?: InputMaybe<DbAgentProfileImageBoolExp>;
};

export type QueryRootDbAgentProfileImageByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbAgentProfileSocialMediaProfilesArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileSocialMediaProfilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileSocialMediaProfilesOrderBy>>;
  where?: InputMaybe<DbAgentProfileSocialMediaProfilesBoolExp>;
};

export type QueryRootDbAgentProfileSocialMediaProfilesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileSocialMediaProfilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileSocialMediaProfilesOrderBy>>;
  where?: InputMaybe<DbAgentProfileSocialMediaProfilesBoolExp>;
};

export type QueryRootDbAgentProfileSocialMediaProfilesByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbAgentProfileSpokenLanguageArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileSpokenLanguageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileSpokenLanguageOrderBy>>;
  where?: InputMaybe<DbAgentProfileSpokenLanguageBoolExp>;
};

export type QueryRootDbAgentProfileSpokenLanguageAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileSpokenLanguageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileSpokenLanguageOrderBy>>;
  where?: InputMaybe<DbAgentProfileSpokenLanguageBoolExp>;
};

export type QueryRootDbAgentProfileSpokenLanguageByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbAppointmentActivityArgs = {
  distinctOn?: InputMaybe<Array<DbAppointmentActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAppointmentActivityOrderBy>>;
  where?: InputMaybe<DbAppointmentActivityBoolExp>;
};

export type QueryRootDbAppointmentActivityByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbAppointmentAgentArgs = {
  distinctOn?: InputMaybe<Array<DbAppointmentAgentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAppointmentAgentOrderBy>>;
  where?: InputMaybe<DbAppointmentAgentBoolExp>;
};

export type QueryRootDbAppointmentContactArgs = {
  distinctOn?: InputMaybe<Array<DbAppointmentContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAppointmentContactOrderBy>>;
  where?: InputMaybe<DbAppointmentContactBoolExp>;
};

export type QueryRootDbAppointmentLeadArgs = {
  distinctOn?: InputMaybe<Array<DbAppointmentLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAppointmentLeadOrderBy>>;
  where?: InputMaybe<DbAppointmentLeadBoolExp>;
};

export type QueryRootDbAuditActivityArgs = {
  distinctOn?: InputMaybe<Array<DbAuditActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAuditActivityOrderBy>>;
  where?: InputMaybe<DbAuditActivityBoolExp>;
};

export type QueryRootDbAuditActivityByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbBaseActivityArgs = {
  distinctOn?: InputMaybe<Array<DbBaseActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBaseActivityOrderBy>>;
  where?: InputMaybe<DbBaseActivityBoolExp>;
};

export type QueryRootDbBaseActivityAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbBaseActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBaseActivityOrderBy>>;
  where?: InputMaybe<DbBaseActivityBoolExp>;
};

export type QueryRootDbBaseActivityByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbBaseActivityCreationArgs = {
  distinctOn?: InputMaybe<Array<DbBaseActivityCreationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBaseActivityCreationOrderBy>>;
  where?: InputMaybe<DbBaseActivityCreationBoolExp>;
};

export type QueryRootDbBaseActivityCreationAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbBaseActivityCreationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBaseActivityCreationOrderBy>>;
  where?: InputMaybe<DbBaseActivityCreationBoolExp>;
};

export type QueryRootDbBatchJobExecutionArgs = {
  distinctOn?: InputMaybe<Array<DbBatchJobExecutionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBatchJobExecutionOrderBy>>;
  where?: InputMaybe<DbBatchJobExecutionBoolExp>;
};

export type QueryRootDbBatchJobExecutionByPkArgs = {
  jobExecutionId: Scalars['bigint'];
};

export type QueryRootDbBatchJobExecutionContextArgs = {
  distinctOn?: InputMaybe<Array<DbBatchJobExecutionContextSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBatchJobExecutionContextOrderBy>>;
  where?: InputMaybe<DbBatchJobExecutionContextBoolExp>;
};

export type QueryRootDbBatchJobExecutionContextByPkArgs = {
  jobExecutionId: Scalars['bigint'];
};

export type QueryRootDbBatchJobExecutionParamsArgs = {
  distinctOn?: InputMaybe<Array<DbBatchJobExecutionParamsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBatchJobExecutionParamsOrderBy>>;
  where?: InputMaybe<DbBatchJobExecutionParamsBoolExp>;
};

export type QueryRootDbBatchJobInstanceArgs = {
  distinctOn?: InputMaybe<Array<DbBatchJobInstanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBatchJobInstanceOrderBy>>;
  where?: InputMaybe<DbBatchJobInstanceBoolExp>;
};

export type QueryRootDbBatchJobInstanceByPkArgs = {
  jobInstanceId: Scalars['bigint'];
};

export type QueryRootDbBatchStepExecutionArgs = {
  distinctOn?: InputMaybe<Array<DbBatchStepExecutionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBatchStepExecutionOrderBy>>;
  where?: InputMaybe<DbBatchStepExecutionBoolExp>;
};

export type QueryRootDbBatchStepExecutionByPkArgs = {
  stepExecutionId: Scalars['bigint'];
};

export type QueryRootDbBatchStepExecutionContextArgs = {
  distinctOn?: InputMaybe<Array<DbBatchStepExecutionContextSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBatchStepExecutionContextOrderBy>>;
  where?: InputMaybe<DbBatchStepExecutionContextBoolExp>;
};

export type QueryRootDbBatchStepExecutionContextByPkArgs = {
  stepExecutionId: Scalars['bigint'];
};

export type QueryRootDbBulkEmailArgs = {
  distinctOn?: InputMaybe<Array<DbBulkEmailSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBulkEmailOrderBy>>;
  where?: InputMaybe<DbBulkEmailBoolExp>;
};

export type QueryRootDbBulkEmailAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbBulkEmailSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBulkEmailOrderBy>>;
  where?: InputMaybe<DbBulkEmailBoolExp>;
};

export type QueryRootDbBuyerToRentSpecificationArgs = {
  distinctOn?: InputMaybe<Array<DbBuyerToRentSpecificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBuyerToRentSpecificationOrderBy>>;
  where?: InputMaybe<DbBuyerToRentSpecificationBoolExp>;
};

export type QueryRootDbBuyerToRentSpecificationByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbBuyerToRentSpecificationCdfArgs = {
  distinctOn?: InputMaybe<Array<DbBuyerToRentSpecificationCdfSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBuyerToRentSpecificationCdfOrderBy>>;
  where?: InputMaybe<DbBuyerToRentSpecificationCdfBoolExp>;
};

export type QueryRootDbBuyerToRentSpecificationCdfByPkArgs = {
  brsId: Scalars['String'];
  cdfId: Scalars['String'];
};

export type QueryRootDbCallArgs = {
  distinctOn?: InputMaybe<Array<DbCallSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCallOrderBy>>;
  where?: InputMaybe<DbCallBoolExp>;
};

export type QueryRootDbCallActivityArgs = {
  distinctOn?: InputMaybe<Array<DbCallActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCallActivityOrderBy>>;
  where?: InputMaybe<DbCallActivityBoolExp>;
};

export type QueryRootDbCallActivityByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbCallAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbCallSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCallOrderBy>>;
  where?: InputMaybe<DbCallBoolExp>;
};

export type QueryRootDbCallByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbCdfArgs = {
  distinctOn?: InputMaybe<Array<DbCdfSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCdfOrderBy>>;
  where?: InputMaybe<DbCdfBoolExp>;
};

export type QueryRootDbCdfByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbConsentArgs = {
  distinctOn?: InputMaybe<Array<DbConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentOrderBy>>;
  where?: InputMaybe<DbConsentBoolExp>;
};

export type QueryRootDbConsentActivityArgs = {
  distinctOn?: InputMaybe<Array<DbConsentActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentActivityOrderBy>>;
  where?: InputMaybe<DbConsentActivityBoolExp>;
};

export type QueryRootDbConsentActivityByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbConsentAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentOrderBy>>;
  where?: InputMaybe<DbConsentBoolExp>;
};

export type QueryRootDbConsentByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbConsentEventArgs = {
  distinctOn?: InputMaybe<Array<DbConsentEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentEventOrderBy>>;
  where?: InputMaybe<DbConsentEventBoolExp>;
};

export type QueryRootDbConsentEventAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbConsentEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentEventOrderBy>>;
  where?: InputMaybe<DbConsentEventBoolExp>;
};

export type QueryRootDbConsentEventByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbConsentTemplateArgs = {
  distinctOn?: InputMaybe<Array<DbConsentTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentTemplateOrderBy>>;
  where?: InputMaybe<DbConsentTemplateBoolExp>;
};

export type QueryRootDbConsentTemplateByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbConsentTextArgs = {
  distinctOn?: InputMaybe<Array<DbConsentTextSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentTextOrderBy>>;
  where?: InputMaybe<DbConsentTextBoolExp>;
};

export type QueryRootDbConsentTextByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbContactArgs = {
  distinctOn?: InputMaybe<Array<DbContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactOrderBy>>;
  where?: InputMaybe<DbContactBoolExp>;
};

export type QueryRootDbContactAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactOrderBy>>;
  where?: InputMaybe<DbContactBoolExp>;
};

export type QueryRootDbContactAssignmentArgs = {
  distinctOn?: InputMaybe<Array<DbContactAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactAssignmentOrderBy>>;
  where?: InputMaybe<DbContactAssignmentBoolExp>;
};

export type QueryRootDbContactByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbContactChangeActivityArgs = {
  distinctOn?: InputMaybe<Array<DbContactChangeActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactChangeActivityOrderBy>>;
  where?: InputMaybe<DbContactChangeActivityBoolExp>;
};

export type QueryRootDbContactChangeActivityByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbContactChangeLogArgs = {
  distinctOn?: InputMaybe<Array<DbContactChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactChangeLogOrderBy>>;
  where?: InputMaybe<DbContactChangeLogBoolExp>;
};

export type QueryRootDbContactChangeLogByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbContactDetailsArgs = {
  distinctOn?: InputMaybe<Array<DbContactDetailsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactDetailsOrderBy>>;
  where?: InputMaybe<DbContactDetailsBoolExp>;
};

export type QueryRootDbContactDetailsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbContactDetailsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactDetailsOrderBy>>;
  where?: InputMaybe<DbContactDetailsBoolExp>;
};

export type QueryRootDbContactGdprArgs = {
  distinctOn?: InputMaybe<Array<DbContactGdprSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactGdprOrderBy>>;
  where?: InputMaybe<DbContactGdprBoolExp>;
};

export type QueryRootDbContactGdprByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbContactPreferredTimeArgs = {
  distinctOn?: InputMaybe<Array<DbContactPreferredTimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactPreferredTimeOrderBy>>;
  where?: InputMaybe<DbContactPreferredTimeBoolExp>;
};

export type QueryRootDbContactPreferredTimeAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbContactPreferredTimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactPreferredTimeOrderBy>>;
  where?: InputMaybe<DbContactPreferredTimeBoolExp>;
};

export type QueryRootDbContactPreferredTimeByPkArgs = {
  contactId: Scalars['String'];
  preferredContactTime: ContactTimeEnum;
};

export type QueryRootDbContactPreferredWayArgs = {
  distinctOn?: InputMaybe<Array<DbContactPreferredWaySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactPreferredWayOrderBy>>;
  where?: InputMaybe<DbContactPreferredWayBoolExp>;
};

export type QueryRootDbContactPreferredWayAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbContactPreferredWaySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactPreferredWayOrderBy>>;
  where?: InputMaybe<DbContactPreferredWayBoolExp>;
};

export type QueryRootDbContactPreferredWayByPkArgs = {
  contactId: Scalars['String'];
  preferredContactWay: ContactWayEnum;
};

export type QueryRootDbContactRelationshipArgs = {
  distinctOn?: InputMaybe<Array<DbContactRelationshipSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactRelationshipOrderBy>>;
  where?: InputMaybe<DbContactRelationshipBoolExp>;
};

export type QueryRootDbContactRelationshipByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbContactRenterInformationArgs = {
  distinctOn?: InputMaybe<Array<DbContactRenterInformationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactRenterInformationOrderBy>>;
  where?: InputMaybe<DbContactRenterInformationBoolExp>;
};

export type QueryRootDbContactRenterInformationByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbContactStatusInfoArgs = {
  distinctOn?: InputMaybe<Array<DbContactStatusInfoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactStatusInfoOrderBy>>;
  where?: InputMaybe<DbContactStatusInfoBoolExp>;
};

export type QueryRootDbContactStatusInfoAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbContactStatusInfoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactStatusInfoOrderBy>>;
  where?: InputMaybe<DbContactStatusInfoBoolExp>;
};

export type QueryRootDbContactTagArgs = {
  distinctOn?: InputMaybe<Array<DbContactTagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactTagOrderBy>>;
  where?: InputMaybe<DbContactTagBoolExp>;
};

export type QueryRootDbContactTagByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbConversionDataArgs = {
  distinctOn?: InputMaybe<Array<DbConversionDataSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConversionDataOrderBy>>;
  where?: InputMaybe<DbConversionDataBoolExp>;
};

export type QueryRootDbConversionDataByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbCountryMandatoryLanguageArgs = {
  distinctOn?: InputMaybe<Array<DbCountryMandatoryLanguageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCountryMandatoryLanguageOrderBy>>;
  where?: InputMaybe<DbCountryMandatoryLanguageBoolExp>;
};

export type QueryRootDbCountryMandatoryLanguageByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbCustomListViewArgs = {
  distinctOn?: InputMaybe<Array<DbCustomListViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCustomListViewOrderBy>>;
  where?: InputMaybe<DbCustomListViewBoolExp>;
};

export type QueryRootDbCustomListViewByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbCustomSearchDemandPolygonArgs = {
  distinctOn?: InputMaybe<Array<DbCustomSearchDemandPolygonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCustomSearchDemandPolygonOrderBy>>;
  where?: InputMaybe<DbCustomSearchDemandPolygonBoolExp>;
};

export type QueryRootDbCustomSearchDemandPolygonByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbDocumentArgs = {
  distinctOn?: InputMaybe<Array<DbDocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbDocumentOrderBy>>;
  where?: InputMaybe<DbDocumentBoolExp>;
};

export type QueryRootDbDocumentActivityArgs = {
  distinctOn?: InputMaybe<Array<DbDocumentActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbDocumentActivityOrderBy>>;
  where?: InputMaybe<DbDocumentActivityBoolExp>;
};

export type QueryRootDbDocumentActivityByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbDocumentAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbDocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbDocumentOrderBy>>;
  where?: InputMaybe<DbDocumentBoolExp>;
};

export type QueryRootDbDocumentByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbDocumentReferenceArgs = {
  distinctOn?: InputMaybe<Array<DbDocumentReferenceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbDocumentReferenceOrderBy>>;
  where?: InputMaybe<DbDocumentReferenceBoolExp>;
};

export type QueryRootDbDocumentReferenceAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbDocumentReferenceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbDocumentReferenceOrderBy>>;
  where?: InputMaybe<DbDocumentReferenceBoolExp>;
};

export type QueryRootDbDocumentReferenceByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbEmailAttachmentArgs = {
  distinctOn?: InputMaybe<Array<DbEmailAttachmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailAttachmentOrderBy>>;
  where?: InputMaybe<DbEmailAttachmentBoolExp>;
};

export type QueryRootDbEmailAttachmentAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailAttachmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailAttachmentOrderBy>>;
  where?: InputMaybe<DbEmailAttachmentBoolExp>;
};

export type QueryRootDbEmailAttachmentByPkArgs = {
  attachmentId: Scalars['String'];
  emailId: Scalars['String'];
};

export type QueryRootDbEmailCallbackEventsArgs = {
  distinctOn?: InputMaybe<Array<DbEmailCallbackEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailCallbackEventsOrderBy>>;
  where?: InputMaybe<DbEmailCallbackEventsBoolExp>;
};

export type QueryRootDbEmailCallbackEventsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailCallbackEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailCallbackEventsOrderBy>>;
  where?: InputMaybe<DbEmailCallbackEventsBoolExp>;
};

export type QueryRootDbEmailCallbackEventsByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbEmailLinkArgs = {
  distinctOn?: InputMaybe<Array<DbEmailLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailLinkOrderBy>>;
  where?: InputMaybe<DbEmailLinkBoolExp>;
};

export type QueryRootDbEmailLinkAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailLinkOrderBy>>;
  where?: InputMaybe<DbEmailLinkBoolExp>;
};

export type QueryRootDbEmailLinkByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbEmailMessageMapArgs = {
  distinctOn?: InputMaybe<Array<DbEmailMessageMapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailMessageMapOrderBy>>;
  where?: InputMaybe<DbEmailMessageMapBoolExp>;
};

export type QueryRootDbEmailPlaceholderArgs = {
  distinctOn?: InputMaybe<Array<DbEmailPlaceholderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailPlaceholderOrderBy>>;
  where?: InputMaybe<DbEmailPlaceholderBoolExp>;
};

export type QueryRootDbEmailPlaceholderAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailPlaceholderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailPlaceholderOrderBy>>;
  where?: InputMaybe<DbEmailPlaceholderBoolExp>;
};

export type QueryRootDbEmailPlaceholderByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbEmailSignatureArgs = {
  distinctOn?: InputMaybe<Array<DbEmailSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailSignatureOrderBy>>;
  where?: InputMaybe<DbEmailSignatureBoolExp>;
};

export type QueryRootDbEmailSignatureAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailSignatureOrderBy>>;
  where?: InputMaybe<DbEmailSignatureBoolExp>;
};

export type QueryRootDbEmailSignatureByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbEmailTemplateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTemplateOrderBy>>;
  where?: InputMaybe<DbEmailTemplateBoolExp>;
};

export type QueryRootDbEmailTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTemplateOrderBy>>;
  where?: InputMaybe<DbEmailTemplateBoolExp>;
};

export type QueryRootDbEmailTemplateByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbEmailTemplateContentArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTemplateContentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTemplateContentOrderBy>>;
  where?: InputMaybe<DbEmailTemplateContentBoolExp>;
};

export type QueryRootDbEmailTemplateContentAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTemplateContentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTemplateContentOrderBy>>;
  where?: InputMaybe<DbEmailTemplateContentBoolExp>;
};

export type QueryRootDbEmailTemplateContentByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbEmailTrackingArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTrackingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTrackingOrderBy>>;
  where?: InputMaybe<DbEmailTrackingBoolExp>;
};

export type QueryRootDbEmailTrackingAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTrackingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTrackingOrderBy>>;
  where?: InputMaybe<DbEmailTrackingBoolExp>;
};

export type QueryRootDbEmailTrackingByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbEnabledFeatureFlagsArgs = {
  distinctOn?: InputMaybe<Array<DbEnabledFeatureFlagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEnabledFeatureFlagsOrderBy>>;
  where?: InputMaybe<DbEnabledFeatureFlagsBoolExp>;
};

export type QueryRootDbExposeeSharedActivityArgs = {
  distinctOn?: InputMaybe<Array<DbExposeeSharedActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbExposeeSharedActivityOrderBy>>;
  where?: InputMaybe<DbExposeeSharedActivityBoolExp>;
};

export type QueryRootDbExposeeSharedActivityAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbExposeeSharedActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbExposeeSharedActivityOrderBy>>;
  where?: InputMaybe<DbExposeeSharedActivityBoolExp>;
};

export type QueryRootDbExposeeSharedActivityByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbImportLeadRequestArgs = {
  distinctOn?: InputMaybe<Array<DbImportLeadRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbImportLeadRequestOrderBy>>;
  where?: InputMaybe<DbImportLeadRequestBoolExp>;
};

export type QueryRootDbImportLeadRequestAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbImportLeadRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbImportLeadRequestOrderBy>>;
  where?: InputMaybe<DbImportLeadRequestBoolExp>;
};

export type QueryRootDbImportLeadRequestByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbLeadArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

export type QueryRootDbLeadAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

export type QueryRootDbLeadAutoAssignmentIntentArgs = {
  distinctOn?: InputMaybe<Array<DbLeadAutoAssignmentIntentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadAutoAssignmentIntentOrderBy>>;
  where?: InputMaybe<DbLeadAutoAssignmentIntentBoolExp>;
};

export type QueryRootDbLeadAutoAssignmentIntentByPkArgs = {
  intentType: IntentTypeEnum;
  shopId: Scalars['String'];
};

export type QueryRootDbLeadAutoAssignmentRulesArgs = {
  distinctOn?: InputMaybe<Array<DbLeadAutoAssignmentRulesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadAutoAssignmentRulesOrderBy>>;
  where?: InputMaybe<DbLeadAutoAssignmentRulesBoolExp>;
};

export type QueryRootDbLeadAutoAssignmentRulesByPkArgs = {
  leadAssignmentRule: LeadAutoAssignmentRulesEnum;
  shopId: Scalars['String'];
};

export type QueryRootDbLeadByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbLeadExpiryArgs = {
  distinctOn?: InputMaybe<Array<DbLeadExpirySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadExpiryOrderBy>>;
  where?: InputMaybe<DbLeadExpiryBoolExp>;
};

export type QueryRootDbLeadExtendingActivitiesArgs = {
  distinctOn?: InputMaybe<Array<DbLeadExtendingActivitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadExtendingActivitiesOrderBy>>;
  where?: InputMaybe<DbLeadExtendingActivitiesBoolExp>;
};

export type QueryRootDbLeadExtendingActivitiesByPkArgs = {
  activityType: ActivityTypeEnum;
  shopId: Scalars['String'];
};

export type QueryRootDbLeadExtendingAppointmentTypesArgs = {
  distinctOn?: InputMaybe<Array<DbLeadExtendingAppointmentTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadExtendingAppointmentTypesOrderBy>>;
  where?: InputMaybe<DbLeadExtendingAppointmentTypesBoolExp>;
};

export type QueryRootDbLeadExtendingAppointmentTypesByPkArgs = {
  appointmentType: AppointmentTypeEnum;
  shopId: Scalars['String'];
};

export type QueryRootDbLeadExtendingTaskTypesArgs = {
  distinctOn?: InputMaybe<Array<DbLeadExtendingTaskTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadExtendingTaskTypesOrderBy>>;
  where?: InputMaybe<DbLeadExtendingTaskTypesBoolExp>;
};

export type QueryRootDbLeadExtendingTaskTypesByPkArgs = {
  shopId: Scalars['String'];
  taskType: TaskTypeEnum;
};

export type QueryRootDbLeadStatisticsArgs = {
  distinctOn?: InputMaybe<Array<DbLeadStatisticsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadStatisticsOrderBy>>;
  where?: InputMaybe<DbLeadStatisticsBoolExp>;
};

export type QueryRootDbLeadhubGo3ContactLinkArgs = {
  distinctOn?: InputMaybe<Array<DbLeadhubGo3ContactLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadhubGo3ContactLinkOrderBy>>;
  where?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
};

export type QueryRootDbLeadhubGo3ContactLinkAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLeadhubGo3ContactLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadhubGo3ContactLinkOrderBy>>;
  where?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
};

export type QueryRootDbLeadhubGo3ContactLinkByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbLegacyGo3ChangeLogArgs = {
  distinctOn?: InputMaybe<Array<DbLegacyGo3ChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLegacyGo3ChangeLogOrderBy>>;
  where?: InputMaybe<DbLegacyGo3ChangeLogBoolExp>;
};

export type QueryRootDbLegacyGo3ChangeLogByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbLegalEntityArgs = {
  distinctOn?: InputMaybe<Array<DbLegalEntitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLegalEntityOrderBy>>;
  where?: InputMaybe<DbLegalEntityBoolExp>;
};

export type QueryRootDbLegalEntityAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLegalEntitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLegalEntityOrderBy>>;
  where?: InputMaybe<DbLegalEntityBoolExp>;
};

export type QueryRootDbLegalEntityByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbLicensePartnerArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerOrderBy>>;
  where?: InputMaybe<DbLicensePartnerBoolExp>;
};

export type QueryRootDbLicensePartnerAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerOrderBy>>;
  where?: InputMaybe<DbLicensePartnerBoolExp>;
};

export type QueryRootDbLicensePartnerByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbLicensePartnerProfileArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileBoolExp>;
};

export type QueryRootDbLicensePartnerProfileAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileBoolExp>;
};

export type QueryRootDbLicensePartnerProfileByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbLicensePartnerProfileFeaturedAgentArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileFeaturedAgentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileFeaturedAgentOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileFeaturedAgentBoolExp>;
};

export type QueryRootDbLicensePartnerProfileFeaturedAgentByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbLicensePartnerProfileFeaturedContentTeaserArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileFeaturedContentTeaserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileFeaturedContentTeaserOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileFeaturedContentTeaserBoolExp>;
};

export type QueryRootDbLicensePartnerProfileFeaturedContentTeaserByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbLicensePartnerProfileFormerUrlArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileFormerUrlSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileFormerUrlOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileFormerUrlBoolExp>;
};

export type QueryRootDbLicensePartnerProfileFormerUrlAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileFormerUrlSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileFormerUrlOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileFormerUrlBoolExp>;
};

export type QueryRootDbLicensePartnerProfileFormerUrlByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbLicensePartnerProfileImageArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileImageOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileImageBoolExp>;
};

export type QueryRootDbLicensePartnerProfileImageAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileImageOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileImageBoolExp>;
};

export type QueryRootDbLicensePartnerProfileImageByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbLicensePartnerProfileLanguageVariantArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileLanguageVariantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileLanguageVariantOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileLanguageVariantBoolExp>;
};

export type QueryRootDbLicensePartnerProfileLanguageVariantAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileLanguageVariantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileLanguageVariantOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileLanguageVariantBoolExp>;
};

export type QueryRootDbLicensePartnerProfileLanguageVariantByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbLicensePartnerProfilePropertyPreviewArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfilePropertyPreviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfilePropertyPreviewOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfilePropertyPreviewBoolExp>;
};

export type QueryRootDbLicensePartnerProfilePropertyPreviewByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbMigrationSourceArgs = {
  distinctOn?: InputMaybe<Array<DbMigrationSourceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbMigrationSourceOrderBy>>;
  where?: InputMaybe<DbMigrationSourceBoolExp>;
};

export type QueryRootDbMigrationSourceAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbMigrationSourceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbMigrationSourceOrderBy>>;
  where?: InputMaybe<DbMigrationSourceBoolExp>;
};

export type QueryRootDbMigrationSourceByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbMigrationSourcePkListArgs = {
  distinctOn?: InputMaybe<Array<DbMigrationSourcePkListSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbMigrationSourcePkListOrderBy>>;
  where?: InputMaybe<DbMigrationSourcePkListBoolExp>;
};

export type QueryRootDbMigrationSourcePkListAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbMigrationSourcePkListSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbMigrationSourcePkListOrderBy>>;
  where?: InputMaybe<DbMigrationSourcePkListBoolExp>;
};

export type QueryRootDbMigrationSourcePkListByPkArgs = {
  sequenceNo: Scalars['Int'];
};

export type QueryRootDbMigrationTargetArgs = {
  distinctOn?: InputMaybe<Array<DbMigrationTargetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbMigrationTargetOrderBy>>;
  where?: InputMaybe<DbMigrationTargetBoolExp>;
};

export type QueryRootDbMigrationTargetAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbMigrationTargetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbMigrationTargetOrderBy>>;
  where?: InputMaybe<DbMigrationTargetBoolExp>;
};

export type QueryRootDbMigrationTargetByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbNylasEmailIntegrationArgs = {
  distinctOn?: InputMaybe<Array<DbNylasEmailIntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbNylasEmailIntegrationOrderBy>>;
  where?: InputMaybe<DbNylasEmailIntegrationBoolExp>;
};

export type QueryRootDbNylasEmailIntegrationAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbNylasEmailIntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbNylasEmailIntegrationOrderBy>>;
  where?: InputMaybe<DbNylasEmailIntegrationBoolExp>;
};

export type QueryRootDbNylasEmailIntegrationByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbOneWayMappingFromLeadIdToContactIdArgs = {
  distinctOn?: InputMaybe<Array<DbOneWayMappingFromLeadIdToContactIdSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbOneWayMappingFromLeadIdToContactIdOrderBy>>;
  where?: InputMaybe<DbOneWayMappingFromLeadIdToContactIdBoolExp>;
};

export type QueryRootDbOptimizelyClientArgs = {
  distinctOn?: InputMaybe<Array<DbOptimizelyClientSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbOptimizelyClientOrderBy>>;
  where?: InputMaybe<DbOptimizelyClientBoolExp>;
};

export type QueryRootDbOptimizelyClientByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbPotentialDuplicateContactArgs = {
  distinctOn?: InputMaybe<Array<DbPotentialDuplicateContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbPotentialDuplicateContactOrderBy>>;
  where?: InputMaybe<DbPotentialDuplicateContactBoolExp>;
};

export type QueryRootDbPotentialDuplicateContactAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbPotentialDuplicateContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbPotentialDuplicateContactOrderBy>>;
  where?: InputMaybe<DbPotentialDuplicateContactBoolExp>;
};

export type QueryRootDbPotentialDuplicateContactByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbPrintedDocumentActivityArgs = {
  distinctOn?: InputMaybe<Array<DbPrintedDocumentActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbPrintedDocumentActivityOrderBy>>;
  where?: InputMaybe<DbPrintedDocumentActivityBoolExp>;
};

export type QueryRootDbPrintedDocumentActivityByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbPropertyLoungeArgs = {
  distinctOn?: InputMaybe<Array<DbPropertyLoungeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbPropertyLoungeOrderBy>>;
  where?: InputMaybe<DbPropertyLoungeBoolExp>;
};

export type QueryRootDbPropertyLoungeByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbQualificationFormLinkArgs = {
  distinctOn?: InputMaybe<Array<DbQualificationFormLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbQualificationFormLinkOrderBy>>;
  where?: InputMaybe<DbQualificationFormLinkBoolExp>;
};

export type QueryRootDbQualificationFormLinkAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbQualificationFormLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbQualificationFormLinkOrderBy>>;
  where?: InputMaybe<DbQualificationFormLinkBoolExp>;
};

export type QueryRootDbQualificationFormLinkByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbQualiformLeadIntentArgs = {
  distinctOn?: InputMaybe<Array<DbQualiformLeadIntentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbQualiformLeadIntentOrderBy>>;
  where?: InputMaybe<DbQualiformLeadIntentBoolExp>;
};

export type QueryRootDbQualiformLeadIntentByPkArgs = {
  intentType: IntentTypeEnum;
  shopId: Scalars['String'];
};

export type QueryRootDbQualiformLeadSourceArgs = {
  distinctOn?: InputMaybe<Array<DbQualiformLeadSourceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbQualiformLeadSourceOrderBy>>;
  where?: InputMaybe<DbQualiformLeadSourceBoolExp>;
};

export type QueryRootDbQualiformLeadSourceByPkArgs = {
  leadSource: LeadSourceEnum;
  shopId: Scalars['String'];
};

export type QueryRootDbQualiformMandatoryFieldArgs = {
  distinctOn?: InputMaybe<Array<DbQualiformMandatoryFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbQualiformMandatoryFieldOrderBy>>;
  where?: InputMaybe<DbQualiformMandatoryFieldBoolExp>;
};

export type QueryRootDbQualiformMandatoryFieldByPkArgs = {
  fieldName: QualiformFieldsEnum;
  shopId: Scalars['String'];
};

export type QueryRootDbQualiformShopSettingArgs = {
  distinctOn?: InputMaybe<Array<DbQualiformShopSettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbQualiformShopSettingOrderBy>>;
  where?: InputMaybe<DbQualiformShopSettingBoolExp>;
};

export type QueryRootDbQualiformShopSettingByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbReferralArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

export type QueryRootDbReferralActivityArgs = {
  distinctOn?: InputMaybe<Array<DbReferralActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralActivityOrderBy>>;
  where?: InputMaybe<DbReferralActivityBoolExp>;
};

export type QueryRootDbReferralActivityByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbReferralAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

export type QueryRootDbReferralByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbSellerRentOutSpecificationArgs = {
  distinctOn?: InputMaybe<Array<DbSellerRentOutSpecificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSellerRentOutSpecificationOrderBy>>;
  where?: InputMaybe<DbSellerRentOutSpecificationBoolExp>;
};

export type QueryRootDbSellerRentOutSpecificationByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbSellerRentOutSpecificationCdfArgs = {
  distinctOn?: InputMaybe<Array<DbSellerRentOutSpecificationCdfSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSellerRentOutSpecificationCdfOrderBy>>;
  where?: InputMaybe<DbSellerRentOutSpecificationCdfBoolExp>;
};

export type QueryRootDbSellerRentOutSpecificationCdfByPkArgs = {
  cdfId: Scalars['String'];
  srsId: Scalars['String'];
};

export type QueryRootDbSessionArgs = {
  distinctOn?: InputMaybe<Array<DbSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSessionOrderBy>>;
  where?: InputMaybe<DbSessionBoolExp>;
};

export type QueryRootDbSessionAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSessionOrderBy>>;
  where?: InputMaybe<DbSessionBoolExp>;
};

export type QueryRootDbSessionByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopArgs = {
  distinctOn?: InputMaybe<Array<DbShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopOrderBy>>;
  where?: InputMaybe<DbShopBoolExp>;
};

export type QueryRootDbShopAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopOrderBy>>;
  where?: InputMaybe<DbShopBoolExp>;
};

export type QueryRootDbShopByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopCommissionContractArgs = {
  distinctOn?: InputMaybe<Array<DbShopCommissionContractSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopCommissionContractOrderBy>>;
  where?: InputMaybe<DbShopCommissionContractBoolExp>;
};

export type QueryRootDbShopCommissionContractByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopGroupArgs = {
  distinctOn?: InputMaybe<Array<DbShopGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopGroupOrderBy>>;
  where?: InputMaybe<DbShopGroupBoolExp>;
};

export type QueryRootDbShopGroupAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopGroupOrderBy>>;
  where?: InputMaybe<DbShopGroupBoolExp>;
};

export type QueryRootDbShopGroupByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopManagedConsentArgs = {
  distinctOn?: InputMaybe<Array<DbShopManagedConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopManagedConsentOrderBy>>;
  where?: InputMaybe<DbShopManagedConsentBoolExp>;
};

export type QueryRootDbShopManagedConsentByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopProfileArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileOrderBy>>;
  where?: InputMaybe<DbShopProfileBoolExp>;
};

export type QueryRootDbShopProfileAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileOrderBy>>;
  where?: InputMaybe<DbShopProfileBoolExp>;
};

export type QueryRootDbShopProfileByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopProfileCareerArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileCareerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileCareerOrderBy>>;
  where?: InputMaybe<DbShopProfileCareerBoolExp>;
};

export type QueryRootDbShopProfileCareerAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileCareerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileCareerOrderBy>>;
  where?: InputMaybe<DbShopProfileCareerBoolExp>;
};

export type QueryRootDbShopProfileCareerByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopProfileCityDistrictArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileCityDistrictSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileCityDistrictOrderBy>>;
  where?: InputMaybe<DbShopProfileCityDistrictBoolExp>;
};

export type QueryRootDbShopProfileCityDistrictAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileCityDistrictSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileCityDistrictOrderBy>>;
  where?: InputMaybe<DbShopProfileCityDistrictBoolExp>;
};

export type QueryRootDbShopProfileCityDistrictByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopProfileCustomerMailsArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileCustomerMailsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileCustomerMailsOrderBy>>;
  where?: InputMaybe<DbShopProfileCustomerMailsBoolExp>;
};

export type QueryRootDbShopProfileCustomerMailsByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopProfileDescriptionArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileDescriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileDescriptionOrderBy>>;
  where?: InputMaybe<DbShopProfileDescriptionBoolExp>;
};

export type QueryRootDbShopProfileDescriptionAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileDescriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileDescriptionOrderBy>>;
  where?: InputMaybe<DbShopProfileDescriptionBoolExp>;
};

export type QueryRootDbShopProfileDescriptionByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopProfileExpertiseArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileExpertiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileExpertiseOrderBy>>;
  where?: InputMaybe<DbShopProfileExpertiseBoolExp>;
};

export type QueryRootDbShopProfileExpertiseAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileExpertiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileExpertiseOrderBy>>;
  where?: InputMaybe<DbShopProfileExpertiseBoolExp>;
};

export type QueryRootDbShopProfileExpertiseByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopProfileFeaturedAgentArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileFeaturedAgentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileFeaturedAgentOrderBy>>;
  where?: InputMaybe<DbShopProfileFeaturedAgentBoolExp>;
};

export type QueryRootDbShopProfileFeaturedAgentByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopProfileFeaturedContentArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileFeaturedContentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileFeaturedContentOrderBy>>;
  where?: InputMaybe<DbShopProfileFeaturedContentBoolExp>;
};

export type QueryRootDbShopProfileFeaturedContentByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopProfileFeaturedContentTeaserArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileFeaturedContentTeaserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileFeaturedContentTeaserOrderBy>>;
  where?: InputMaybe<DbShopProfileFeaturedContentTeaserBoolExp>;
};

export type QueryRootDbShopProfileFeaturedContentTeaserByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopProfileFormerUrlArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileFormerUrlSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileFormerUrlOrderBy>>;
  where?: InputMaybe<DbShopProfileFormerUrlBoolExp>;
};

export type QueryRootDbShopProfileFormerUrlAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileFormerUrlSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileFormerUrlOrderBy>>;
  where?: InputMaybe<DbShopProfileFormerUrlBoolExp>;
};

export type QueryRootDbShopProfileFormerUrlByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopProfileImageArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileImageOrderBy>>;
  where?: InputMaybe<DbShopProfileImageBoolExp>;
};

export type QueryRootDbShopProfileImageAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileImageOrderBy>>;
  where?: InputMaybe<DbShopProfileImageBoolExp>;
};

export type QueryRootDbShopProfileImageByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopProfileImprintArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileImprintSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileImprintOrderBy>>;
  where?: InputMaybe<DbShopProfileImprintBoolExp>;
};

export type QueryRootDbShopProfileImprintAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileImprintSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileImprintOrderBy>>;
  where?: InputMaybe<DbShopProfileImprintBoolExp>;
};

export type QueryRootDbShopProfileImprintByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopProfilePrivacyPolicyArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfilePrivacyPolicySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfilePrivacyPolicyOrderBy>>;
  where?: InputMaybe<DbShopProfilePrivacyPolicyBoolExp>;
};

export type QueryRootDbShopProfilePrivacyPolicyAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfilePrivacyPolicySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfilePrivacyPolicyOrderBy>>;
  where?: InputMaybe<DbShopProfilePrivacyPolicyBoolExp>;
};

export type QueryRootDbShopProfilePrivacyPolicyByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopProfilePropertyPreviewArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfilePropertyPreviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfilePropertyPreviewOrderBy>>;
  where?: InputMaybe<DbShopProfilePropertyPreviewBoolExp>;
};

export type QueryRootDbShopProfilePropertyPreviewAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfilePropertyPreviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfilePropertyPreviewOrderBy>>;
  where?: InputMaybe<DbShopProfilePropertyPreviewBoolExp>;
};

export type QueryRootDbShopProfilePropertyPreviewByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopProfileQuoteArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileQuoteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileQuoteOrderBy>>;
  where?: InputMaybe<DbShopProfileQuoteBoolExp>;
};

export type QueryRootDbShopProfileQuoteAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileQuoteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileQuoteOrderBy>>;
  where?: InputMaybe<DbShopProfileQuoteBoolExp>;
};

export type QueryRootDbShopProfileQuoteByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopProfileSeoTrackingArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileSeoTrackingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileSeoTrackingOrderBy>>;
  where?: InputMaybe<DbShopProfileSeoTrackingBoolExp>;
};

export type QueryRootDbShopProfileSeoTrackingByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopProfileSocialMediaProfilesArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileSocialMediaProfilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileSocialMediaProfilesOrderBy>>;
  where?: InputMaybe<DbShopProfileSocialMediaProfilesBoolExp>;
};

export type QueryRootDbShopProfileSocialMediaProfilesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileSocialMediaProfilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileSocialMediaProfilesOrderBy>>;
  where?: InputMaybe<DbShopProfileSocialMediaProfilesBoolExp>;
};

export type QueryRootDbShopProfileSocialMediaProfilesByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopProfileSpokenLanguageArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileSpokenLanguageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileSpokenLanguageOrderBy>>;
  where?: InputMaybe<DbShopProfileSpokenLanguageBoolExp>;
};

export type QueryRootDbShopProfileSpokenLanguageAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileSpokenLanguageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileSpokenLanguageOrderBy>>;
  where?: InputMaybe<DbShopProfileSpokenLanguageBoolExp>;
};

export type QueryRootDbShopProfileSpokenLanguageByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopProfileTeamArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileTeamOrderBy>>;
  where?: InputMaybe<DbShopProfileTeamBoolExp>;
};

export type QueryRootDbShopProfileTeamByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopProfileTermsAndConditionsArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileTermsAndConditionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileTermsAndConditionsOrderBy>>;
  where?: InputMaybe<DbShopProfileTermsAndConditionsBoolExp>;
};

export type QueryRootDbShopProfileTermsAndConditionsByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopProfileWithdrawalPolicyArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileWithdrawalPolicySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileWithdrawalPolicyOrderBy>>;
  where?: InputMaybe<DbShopProfileWithdrawalPolicyBoolExp>;
};

export type QueryRootDbShopProfileWithdrawalPolicyByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopRightOfWithdrawalArgs = {
  distinctOn?: InputMaybe<Array<DbShopRightOfWithdrawalSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopRightOfWithdrawalOrderBy>>;
  where?: InputMaybe<DbShopRightOfWithdrawalBoolExp>;
};

export type QueryRootDbShopRightOfWithdrawalByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbShopSettingArgs = {
  distinctOn?: InputMaybe<Array<DbShopSettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopSettingOrderBy>>;
  where?: InputMaybe<DbShopSettingBoolExp>;
};

export type QueryRootDbShopSettingAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopSettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopSettingOrderBy>>;
  where?: InputMaybe<DbShopSettingBoolExp>;
};

export type QueryRootDbShopSettingByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbSnapshotBuyerToRentSpecificationArgs = {
  distinctOn?: InputMaybe<Array<DbSnapshotBuyerToRentSpecificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSnapshotBuyerToRentSpecificationOrderBy>>;
  where?: InputMaybe<DbSnapshotBuyerToRentSpecificationBoolExp>;
};

export type QueryRootDbSnapshotBuyerToRentSpecificationAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbSnapshotBuyerToRentSpecificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSnapshotBuyerToRentSpecificationOrderBy>>;
  where?: InputMaybe<DbSnapshotBuyerToRentSpecificationBoolExp>;
};

export type QueryRootDbSnapshotBuyerToRentSpecificationByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbSnapshotCustomSearchDemandPolygonArgs = {
  distinctOn?: InputMaybe<Array<DbSnapshotCustomSearchDemandPolygonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSnapshotCustomSearchDemandPolygonOrderBy>>;
  where?: InputMaybe<DbSnapshotCustomSearchDemandPolygonBoolExp>;
};

export type QueryRootDbSnapshotCustomSearchDemandPolygonByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbSnapshotSellerRentOutSpecificationArgs = {
  distinctOn?: InputMaybe<Array<DbSnapshotSellerRentOutSpecificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSnapshotSellerRentOutSpecificationOrderBy>>;
  where?: InputMaybe<DbSnapshotSellerRentOutSpecificationBoolExp>;
};

export type QueryRootDbSnapshotSellerRentOutSpecificationAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbSnapshotSellerRentOutSpecificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSnapshotSellerRentOutSpecificationOrderBy>>;
  where?: InputMaybe<DbSnapshotSellerRentOutSpecificationBoolExp>;
};

export type QueryRootDbSnapshotSellerRentOutSpecificationByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbTagArgs = {
  distinctOn?: InputMaybe<Array<DbTagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTagOrderBy>>;
  where?: InputMaybe<DbTagBoolExp>;
};

export type QueryRootDbTagByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbTaskActivityArgs = {
  distinctOn?: InputMaybe<Array<DbTaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTaskActivityOrderBy>>;
  where?: InputMaybe<DbTaskActivityBoolExp>;
};

export type QueryRootDbTaskActivityByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbTaskAgentArgs = {
  distinctOn?: InputMaybe<Array<DbTaskAgentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTaskAgentOrderBy>>;
  where?: InputMaybe<DbTaskAgentBoolExp>;
};

export type QueryRootDbTaskContactArgs = {
  distinctOn?: InputMaybe<Array<DbTaskContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTaskContactOrderBy>>;
  where?: InputMaybe<DbTaskContactBoolExp>;
};

export type QueryRootDbTaskLeadArgs = {
  distinctOn?: InputMaybe<Array<DbTaskLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTaskLeadOrderBy>>;
  where?: InputMaybe<DbTaskLeadBoolExp>;
};

export type QueryRootDbTeamArgs = {
  distinctOn?: InputMaybe<Array<DbTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTeamOrderBy>>;
  where?: InputMaybe<DbTeamBoolExp>;
};

export type QueryRootDbTeamAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTeamOrderBy>>;
  where?: InputMaybe<DbTeamBoolExp>;
};

export type QueryRootDbTeamByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbTrackingLeadArgs = {
  distinctOn?: InputMaybe<Array<DbTrackingLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTrackingLeadOrderBy>>;
  where?: InputMaybe<DbTrackingLeadBoolExp>;
};

export type QueryRootDbTrackingLeadAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbTrackingLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTrackingLeadOrderBy>>;
  where?: InputMaybe<DbTrackingLeadBoolExp>;
};

export type QueryRootDbTrackingLeadByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbTwilioConfigurationArgs = {
  distinctOn?: InputMaybe<Array<DbTwilioConfigurationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTwilioConfigurationOrderBy>>;
  where?: InputMaybe<DbTwilioConfigurationBoolExp>;
};

export type QueryRootDbTwilioConfigurationAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbTwilioConfigurationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTwilioConfigurationOrderBy>>;
  where?: InputMaybe<DbTwilioConfigurationBoolExp>;
};

export type QueryRootDbTwilioConfigurationByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbUserAdminAssignmentArgs = {
  distinctOn?: InputMaybe<Array<DbUserAdminAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserAdminAssignmentOrderBy>>;
  where?: InputMaybe<DbUserAdminAssignmentBoolExp>;
};

export type QueryRootDbUserAdminAssignmentAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbUserAdminAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserAdminAssignmentOrderBy>>;
  where?: InputMaybe<DbUserAdminAssignmentBoolExp>;
};

export type QueryRootDbUserAdminAssignmentByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbUserShopAssignmentArgs = {
  distinctOn?: InputMaybe<Array<DbUserShopAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserShopAssignmentOrderBy>>;
  where?: InputMaybe<DbUserShopAssignmentBoolExp>;
};

export type QueryRootDbUserShopAssignmentAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbUserShopAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserShopAssignmentOrderBy>>;
  where?: InputMaybe<DbUserShopAssignmentBoolExp>;
};

export type QueryRootDbUserShopAssignmentByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbUserTeamAssignmentArgs = {
  distinctOn?: InputMaybe<Array<DbUserTeamAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserTeamAssignmentOrderBy>>;
  where?: InputMaybe<DbUserTeamAssignmentBoolExp>;
};

export type QueryRootDbUserTeamAssignmentAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbUserTeamAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserTeamAssignmentOrderBy>>;
  where?: InputMaybe<DbUserTeamAssignmentBoolExp>;
};

export type QueryRootDbUserTeamAssignmentByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootDbUtmParamArgs = {
  distinctOn?: InputMaybe<Array<DbUtmParamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUtmParamOrderBy>>;
  where?: InputMaybe<DbUtmParamBoolExp>;
};

export type QueryRootDbUtmParamByPkArgs = {
  id: Scalars['String'];
};

export type QueryRootEnumsActivityTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsActivityTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsActivityTypeOrderBy>>;
  where?: InputMaybe<EnumsActivityTypeBoolExp>;
};

export type QueryRootEnumsActivityTypeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsAddressFormatArgs = {
  distinctOn?: InputMaybe<Array<EnumsAddressFormatSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsAddressFormatOrderBy>>;
  where?: InputMaybe<EnumsAddressFormatBoolExp>;
};

export type QueryRootEnumsAddressFormatByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsAddressTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsAddressTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsAddressTypeOrderBy>>;
  where?: InputMaybe<EnumsAddressTypeBoolExp>;
};

export type QueryRootEnumsAddressTypeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsAppointmentTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsAppointmentTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsAppointmentTypeOrderBy>>;
  where?: InputMaybe<EnumsAppointmentTypeBoolExp>;
};

export type QueryRootEnumsAppointmentTypeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsAssetConditionArgs = {
  distinctOn?: InputMaybe<Array<EnumsAssetConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsAssetConditionOrderBy>>;
  where?: InputMaybe<EnumsAssetConditionBoolExp>;
};

export type QueryRootEnumsAssetConditionByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsAssetSubTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsAssetSubTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsAssetSubTypeOrderBy>>;
  where?: InputMaybe<EnumsAssetSubTypeBoolExp>;
};

export type QueryRootEnumsAssetSubTypeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsAssetTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsAssetTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsAssetTypeOrderBy>>;
  where?: InputMaybe<EnumsAssetTypeBoolExp>;
};

export type QueryRootEnumsAssetTypeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsAssetVariationArgs = {
  distinctOn?: InputMaybe<Array<EnumsAssetVariationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsAssetVariationOrderBy>>;
  where?: InputMaybe<EnumsAssetVariationBoolExp>;
};

export type QueryRootEnumsAssetVariationByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsBaseActivityCategoryArgs = {
  distinctOn?: InputMaybe<Array<EnumsBaseActivityCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsBaseActivityCategoryOrderBy>>;
  where?: InputMaybe<EnumsBaseActivityCategoryBoolExp>;
};

export type QueryRootEnumsBaseActivityCategoryByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsCallDirectionArgs = {
  distinctOn?: InputMaybe<Array<EnumsCallDirectionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsCallDirectionOrderBy>>;
  where?: InputMaybe<EnumsCallDirectionBoolExp>;
};

export type QueryRootEnumsCallDirectionByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsCallOutcomeArgs = {
  distinctOn?: InputMaybe<Array<EnumsCallOutcomeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsCallOutcomeOrderBy>>;
  where?: InputMaybe<EnumsCallOutcomeBoolExp>;
};

export type QueryRootEnumsCallOutcomeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsCallStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsCallStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsCallStatusOrderBy>>;
  where?: InputMaybe<EnumsCallStatusBoolExp>;
};

export type QueryRootEnumsCallStatusByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsConsentEventStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsConsentEventStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsConsentEventStatusOrderBy>>;
  where?: InputMaybe<EnumsConsentEventStatusBoolExp>;
};

export type QueryRootEnumsConsentEventStatusByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsConsentEventTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsConsentEventTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsConsentEventTypeOrderBy>>;
  where?: InputMaybe<EnumsConsentEventTypeBoolExp>;
};

export type QueryRootEnumsConsentEventTypeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsConsentSourceArgs = {
  distinctOn?: InputMaybe<Array<EnumsConsentSourceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsConsentSourceOrderBy>>;
  where?: InputMaybe<EnumsConsentSourceBoolExp>;
};

export type QueryRootEnumsConsentSourceByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsConsentStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsConsentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsConsentStatusOrderBy>>;
  where?: InputMaybe<EnumsConsentStatusBoolExp>;
};

export type QueryRootEnumsConsentStatusByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsConsentTemplateTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsConsentTemplateTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsConsentTemplateTypeOrderBy>>;
  where?: InputMaybe<EnumsConsentTemplateTypeBoolExp>;
};

export type QueryRootEnumsConsentTemplateTypeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsConsentTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsConsentTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsConsentTypeOrderBy>>;
  where?: InputMaybe<EnumsConsentTypeBoolExp>;
};

export type QueryRootEnumsConsentTypeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsContactRelationTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsContactRelationTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsContactRelationTypeOrderBy>>;
  where?: InputMaybe<EnumsContactRelationTypeBoolExp>;
};

export type QueryRootEnumsContactRelationTypeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsContactStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsContactStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsContactStatusOrderBy>>;
  where?: InputMaybe<EnumsContactStatusBoolExp>;
};

export type QueryRootEnumsContactStatusByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsContactTimeArgs = {
  distinctOn?: InputMaybe<Array<EnumsContactTimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsContactTimeOrderBy>>;
  where?: InputMaybe<EnumsContactTimeBoolExp>;
};

export type QueryRootEnumsContactTimeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsContactTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsContactTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsContactTypeOrderBy>>;
  where?: InputMaybe<EnumsContactTypeBoolExp>;
};

export type QueryRootEnumsContactTypeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsContactWayArgs = {
  distinctOn?: InputMaybe<Array<EnumsContactWaySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsContactWayOrderBy>>;
  where?: InputMaybe<EnumsContactWayBoolExp>;
};

export type QueryRootEnumsContactWayByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsCountryArgs = {
  distinctOn?: InputMaybe<Array<EnumsCountrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsCountryOrderBy>>;
  where?: InputMaybe<EnumsCountryBoolExp>;
};

export type QueryRootEnumsCountryByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsCurrencyArgs = {
  distinctOn?: InputMaybe<Array<EnumsCurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsCurrencyOrderBy>>;
  where?: InputMaybe<EnumsCurrencyBoolExp>;
};

export type QueryRootEnumsCurrencyByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsCustomViewTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsCustomViewTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsCustomViewTypeOrderBy>>;
  where?: InputMaybe<EnumsCustomViewTypeBoolExp>;
};

export type QueryRootEnumsCustomViewTypeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsEmailAddressTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsEmailAddressTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEmailAddressTypeOrderBy>>;
  where?: InputMaybe<EnumsEmailAddressTypeBoolExp>;
};

export type QueryRootEnumsEmailAddressTypeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsEmailCallbackEventSourceArgs = {
  distinctOn?: InputMaybe<Array<EnumsEmailCallbackEventSourceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEmailCallbackEventSourceOrderBy>>;
  where?: InputMaybe<EnumsEmailCallbackEventSourceBoolExp>;
};

export type QueryRootEnumsEmailCallbackEventSourceByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsEmailDirectionArgs = {
  distinctOn?: InputMaybe<Array<EnumsEmailDirectionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEmailDirectionOrderBy>>;
  where?: InputMaybe<EnumsEmailDirectionBoolExp>;
};

export type QueryRootEnumsEmailDirectionByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsEmailIntegrationScopeArgs = {
  distinctOn?: InputMaybe<Array<EnumsEmailIntegrationScopeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEmailIntegrationScopeOrderBy>>;
  where?: InputMaybe<EnumsEmailIntegrationScopeBoolExp>;
};

export type QueryRootEnumsEmailIntegrationScopeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsEmailPlaceholderScopeArgs = {
  distinctOn?: InputMaybe<Array<EnumsEmailPlaceholderScopeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEmailPlaceholderScopeOrderBy>>;
  where?: InputMaybe<EnumsEmailPlaceholderScopeBoolExp>;
};

export type QueryRootEnumsEmailPlaceholderScopeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsEmailPlaceholderTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsEmailPlaceholderTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEmailPlaceholderTypeOrderBy>>;
  where?: InputMaybe<EnumsEmailPlaceholderTypeBoolExp>;
};

export type QueryRootEnumsEmailPlaceholderTypeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsEmailSignatureScopeArgs = {
  distinctOn?: InputMaybe<Array<EnumsEmailSignatureScopeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEmailSignatureScopeOrderBy>>;
  where?: InputMaybe<EnumsEmailSignatureScopeBoolExp>;
};

export type QueryRootEnumsEmailSignatureScopeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsEmailStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsEmailStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEmailStatusOrderBy>>;
  where?: InputMaybe<EnumsEmailStatusBoolExp>;
};

export type QueryRootEnumsEmailStatusByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsEmailTemplateScopeArgs = {
  distinctOn?: InputMaybe<Array<EnumsEmailTemplateScopeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEmailTemplateScopeOrderBy>>;
  where?: InputMaybe<EnumsEmailTemplateScopeBoolExp>;
};

export type QueryRootEnumsEmailTemplateScopeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsEmailTemplateTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsEmailTemplateTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEmailTemplateTypeOrderBy>>;
  where?: InputMaybe<EnumsEmailTemplateTypeBoolExp>;
};

export type QueryRootEnumsEmailTemplateTypeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsEmailTrackingTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsEmailTrackingTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEmailTrackingTypeOrderBy>>;
  where?: InputMaybe<EnumsEmailTrackingTypeBoolExp>;
};

export type QueryRootEnumsEmailTrackingTypeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsEntityTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsEntityTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEntityTypeOrderBy>>;
  where?: InputMaybe<EnumsEntityTypeBoolExp>;
};

export type QueryRootEnumsEntityTypeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsEventStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsEventStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEventStatusOrderBy>>;
  where?: InputMaybe<EnumsEventStatusBoolExp>;
};

export type QueryRootEnumsEventStatusByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsFeatureFlagsArgs = {
  distinctOn?: InputMaybe<Array<EnumsFeatureFlagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsFeatureFlagsOrderBy>>;
  where?: InputMaybe<EnumsFeatureFlagsBoolExp>;
};

export type QueryRootEnumsFeatureFlagsByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsGenderArgs = {
  distinctOn?: InputMaybe<Array<EnumsGenderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsGenderOrderBy>>;
  where?: InputMaybe<EnumsGenderBoolExp>;
};

export type QueryRootEnumsGenderByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsHouseholdTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsHouseholdTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsHouseholdTypeOrderBy>>;
  where?: InputMaybe<EnumsHouseholdTypeBoolExp>;
};

export type QueryRootEnumsHouseholdTypeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsImportLeadStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsImportLeadStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsImportLeadStatusOrderBy>>;
  where?: InputMaybe<EnumsImportLeadStatusBoolExp>;
};

export type QueryRootEnumsImportLeadStatusByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsIntentCategoryArgs = {
  distinctOn?: InputMaybe<Array<EnumsIntentCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsIntentCategoryOrderBy>>;
  where?: InputMaybe<EnumsIntentCategoryBoolExp>;
};

export type QueryRootEnumsIntentCategoryByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsIntentTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsIntentTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsIntentTypeOrderBy>>;
  where?: InputMaybe<EnumsIntentTypeBoolExp>;
};

export type QueryRootEnumsIntentTypeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsLanguageArgs = {
  distinctOn?: InputMaybe<Array<EnumsLanguageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsLanguageOrderBy>>;
  where?: InputMaybe<EnumsLanguageBoolExp>;
};

export type QueryRootEnumsLanguageByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsLeadAutoAssignmentRulesArgs = {
  distinctOn?: InputMaybe<Array<EnumsLeadAutoAssignmentRulesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsLeadAutoAssignmentRulesOrderBy>>;
  where?: InputMaybe<EnumsLeadAutoAssignmentRulesBoolExp>;
};

export type QueryRootEnumsLeadAutoAssignmentRulesByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsLeadSourceArgs = {
  distinctOn?: InputMaybe<Array<EnumsLeadSourceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsLeadSourceOrderBy>>;
  where?: InputMaybe<EnumsLeadSourceBoolExp>;
};

export type QueryRootEnumsLeadSourceByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsLeadStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsLeadStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsLeadStatusOrderBy>>;
  where?: InputMaybe<EnumsLeadStatusBoolExp>;
};

export type QueryRootEnumsLeadStatusByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsLeadStatusReasonArgs = {
  distinctOn?: InputMaybe<Array<EnumsLeadStatusReasonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsLeadStatusReasonOrderBy>>;
  where?: InputMaybe<EnumsLeadStatusReasonBoolExp>;
};

export type QueryRootEnumsLeadStatusReasonByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsLegacyGo3NewsletterTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsLegacyGo3NewsletterTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsLegacyGo3NewsletterTypeOrderBy>>;
  where?: InputMaybe<EnumsLegacyGo3NewsletterTypeBoolExp>;
};

export type QueryRootEnumsLegacyGo3NewsletterTypeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsLicensePartnerStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsLicensePartnerStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsLicensePartnerStatusOrderBy>>;
  where?: InputMaybe<EnumsLicensePartnerStatusBoolExp>;
};

export type QueryRootEnumsLicensePartnerStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<EnumsLicensePartnerStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsLicensePartnerStatusOrderBy>>;
  where?: InputMaybe<EnumsLicensePartnerStatusBoolExp>;
};

export type QueryRootEnumsLicensePartnerStatusByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsMandatoryAgentProfileFieldArgs = {
  distinctOn?: InputMaybe<Array<EnumsMandatoryAgentProfileFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsMandatoryAgentProfileFieldOrderBy>>;
  where?: InputMaybe<EnumsMandatoryAgentProfileFieldBoolExp>;
};

export type QueryRootEnumsMandatoryAgentProfileFieldByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsMandatoryShopProfileFieldArgs = {
  distinctOn?: InputMaybe<Array<EnumsMandatoryShopProfileFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsMandatoryShopProfileFieldOrderBy>>;
  where?: InputMaybe<EnumsMandatoryShopProfileFieldBoolExp>;
};

export type QueryRootEnumsMandatoryShopProfileFieldByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsMarketingTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsMarketingTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsMarketingTypeOrderBy>>;
  where?: InputMaybe<EnumsMarketingTypeBoolExp>;
};

export type QueryRootEnumsMarketingTypeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsPhoneNumberTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsPhoneNumberTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsPhoneNumberTypeOrderBy>>;
  where?: InputMaybe<EnumsPhoneNumberTypeBoolExp>;
};

export type QueryRootEnumsPhoneNumberTypeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsPriorityArgs = {
  distinctOn?: InputMaybe<Array<EnumsPrioritySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsPriorityOrderBy>>;
  where?: InputMaybe<EnumsPriorityBoolExp>;
};

export type QueryRootEnumsPriorityByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsPrivacyLevelArgs = {
  distinctOn?: InputMaybe<Array<EnumsPrivacyLevelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsPrivacyLevelOrderBy>>;
  where?: InputMaybe<EnumsPrivacyLevelBoolExp>;
};

export type QueryRootEnumsPrivacyLevelByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsProfessionTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsProfessionTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsProfessionTypeOrderBy>>;
  where?: InputMaybe<EnumsProfessionTypeBoolExp>;
};

export type QueryRootEnumsProfessionTypeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsPropertyOccupationStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsPropertyOccupationStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsPropertyOccupationStatusOrderBy>>;
  where?: InputMaybe<EnumsPropertyOccupationStatusBoolExp>;
};

export type QueryRootEnumsPropertyOccupationStatusByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsPropertyOwnershipStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsPropertyOwnershipStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsPropertyOwnershipStatusOrderBy>>;
  where?: InputMaybe<EnumsPropertyOwnershipStatusBoolExp>;
};

export type QueryRootEnumsPropertyOwnershipStatusByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsQualificationFormStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsQualificationFormStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsQualificationFormStatusOrderBy>>;
  where?: InputMaybe<EnumsQualificationFormStatusBoolExp>;
};

export type QueryRootEnumsQualificationFormStatusByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsQualiformFieldsArgs = {
  distinctOn?: InputMaybe<Array<EnumsQualiformFieldsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsQualiformFieldsOrderBy>>;
  where?: InputMaybe<EnumsQualiformFieldsBoolExp>;
};

export type QueryRootEnumsQualiformFieldsByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsReferralNotPossibleReasonArgs = {
  distinctOn?: InputMaybe<Array<EnumsReferralNotPossibleReasonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsReferralNotPossibleReasonOrderBy>>;
  where?: InputMaybe<EnumsReferralNotPossibleReasonBoolExp>;
};

export type QueryRootEnumsReferralNotPossibleReasonByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsSalutationArgs = {
  distinctOn?: InputMaybe<Array<EnumsSalutationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsSalutationOrderBy>>;
  where?: InputMaybe<EnumsSalutationBoolExp>;
};

export type QueryRootEnumsSalutationByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsSessionStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsSessionStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsSessionStatusOrderBy>>;
  where?: InputMaybe<EnumsSessionStatusBoolExp>;
};

export type QueryRootEnumsSessionStatusByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsSharedTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsSharedTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsSharedTypeOrderBy>>;
  where?: InputMaybe<EnumsSharedTypeBoolExp>;
};

export type QueryRootEnumsSharedTypeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsShopExpertiseArgs = {
  distinctOn?: InputMaybe<Array<EnumsShopExpertiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsShopExpertiseOrderBy>>;
  where?: InputMaybe<EnumsShopExpertiseBoolExp>;
};

export type QueryRootEnumsShopExpertiseByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsShopStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsShopStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsShopStatusOrderBy>>;
  where?: InputMaybe<EnumsShopStatusBoolExp>;
};

export type QueryRootEnumsShopStatusByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsSpokenLanguageArgs = {
  distinctOn?: InputMaybe<Array<EnumsSpokenLanguageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsSpokenLanguageOrderBy>>;
  where?: InputMaybe<EnumsSpokenLanguageBoolExp>;
};

export type QueryRootEnumsSpokenLanguageByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsTaskAppointmentStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsTaskAppointmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsTaskAppointmentStatusOrderBy>>;
  where?: InputMaybe<EnumsTaskAppointmentStatusBoolExp>;
};

export type QueryRootEnumsTaskAppointmentStatusByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsTaskTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsTaskTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsTaskTypeOrderBy>>;
  where?: InputMaybe<EnumsTaskTypeBoolExp>;
};

export type QueryRootEnumsTaskTypeByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsTeamStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsTeamStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsTeamStatusOrderBy>>;
  where?: InputMaybe<EnumsTeamStatusBoolExp>;
};

export type QueryRootEnumsTeamStatusByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsTimeFrameArgs = {
  distinctOn?: InputMaybe<Array<EnumsTimeFrameSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsTimeFrameOrderBy>>;
  where?: InputMaybe<EnumsTimeFrameBoolExp>;
};

export type QueryRootEnumsTimeFrameByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsUserRoleArgs = {
  distinctOn?: InputMaybe<Array<EnumsUserRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsUserRoleOrderBy>>;
  where?: InputMaybe<EnumsUserRoleBoolExp>;
};

export type QueryRootEnumsUserRoleByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootEnumsZeroCommissionReasonArgs = {
  distinctOn?: InputMaybe<Array<EnumsZeroCommissionReasonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsZeroCommissionReasonOrderBy>>;
  where?: InputMaybe<EnumsZeroCommissionReasonBoolExp>;
};

export type QueryRootEnumsZeroCommissionReasonByPkArgs = {
  name: Scalars['String'];
};

export type QueryRootExtensionsSearchContactsArgs = {
  args: ExtensionsSearchContactsArgs;
  distinctOn?: InputMaybe<Array<DbContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactOrderBy>>;
  where?: InputMaybe<DbContactBoolExp>;
};

export type QueryRootExtensionsSearchContactsAggregateArgs = {
  args: ExtensionsSearchContactsArgs;
  distinctOn?: InputMaybe<Array<DbContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactOrderBy>>;
  where?: InputMaybe<DbContactBoolExp>;
};

export type QueryRootKeycloakUserArgs = {
  limit: Scalars['Int'];
  search: Scalars['String'];
};

export type StDWithinGeographyInput = {
  distance: Scalars['Float'];
  from: Scalars['geography'];
  use_spheroid?: InputMaybe<Scalars['Boolean']>;
};

export type StDWithinInput = {
  distance: Scalars['Float'];
  from: Scalars['geometry'];
};

export type SubscriptionRoot = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "db.activity_type_grouping" */
  dbActivityTypeGrouping: Array<DbActivityTypeGrouping>;
  /** fetch data from the table: "db.activity_type_grouping" using primary key columns */
  dbActivityTypeGroupingByPk?: Maybe<DbActivityTypeGrouping>;
  /** fetch data from the table in a streaming manner: "db.activity_type_grouping" */
  dbActivityTypeGroupingStream: Array<DbActivityTypeGrouping>;
  /** fetch data from the table: "db.agent" */
  dbAgent: Array<DbAgent>;
  /** fetch aggregated fields from the table: "db.agent" */
  dbAgentAggregate: DbAgentAggregate;
  /** fetch data from the table: "db.agent" using primary key columns */
  dbAgentByPk?: Maybe<DbAgent>;
  /** fetch data from the table: "db.agent_default_email_signature" */
  dbAgentDefaultEmailSignature: Array<DbAgentDefaultEmailSignature>;
  /** fetch aggregated fields from the table: "db.agent_default_email_signature" */
  dbAgentDefaultEmailSignatureAggregate: DbAgentDefaultEmailSignatureAggregate;
  /** fetch data from the table: "db.agent_default_email_signature" using primary key columns */
  dbAgentDefaultEmailSignatureByPk?: Maybe<DbAgentDefaultEmailSignature>;
  /** fetch data from the table in a streaming manner: "db.agent_default_email_signature" */
  dbAgentDefaultEmailSignatureStream: Array<DbAgentDefaultEmailSignature>;
  /** fetch data from the table: "db.agent_default_nylas_email_integration" */
  dbAgentDefaultNylasEmailIntegration: Array<DbAgentDefaultNylasEmailIntegration>;
  /** fetch aggregated fields from the table: "db.agent_default_nylas_email_integration" */
  dbAgentDefaultNylasEmailIntegrationAggregate: DbAgentDefaultNylasEmailIntegrationAggregate;
  /** fetch data from the table: "db.agent_default_nylas_email_integration" using primary key columns */
  dbAgentDefaultNylasEmailIntegrationByPk?: Maybe<DbAgentDefaultNylasEmailIntegration>;
  /** fetch data from the table in a streaming manner: "db.agent_default_nylas_email_integration" */
  dbAgentDefaultNylasEmailIntegrationStream: Array<DbAgentDefaultNylasEmailIntegration>;
  /** fetch data from the table: "db.agent_profile" */
  dbAgentProfile: Array<DbAgentProfile>;
  /** fetch aggregated fields from the table: "db.agent_profile" */
  dbAgentProfileAggregate: DbAgentProfileAggregate;
  /** fetch data from the table: "db.agent_profile" using primary key columns */
  dbAgentProfileByPk?: Maybe<DbAgentProfile>;
  /** fetch data from the table: "db.agent_profile_city_district" */
  dbAgentProfileCityDistrict: Array<DbAgentProfileCityDistrict>;
  /** fetch aggregated fields from the table: "db.agent_profile_city_district" */
  dbAgentProfileCityDistrictAggregate: DbAgentProfileCityDistrictAggregate;
  /** fetch data from the table: "db.agent_profile_city_district" using primary key columns */
  dbAgentProfileCityDistrictByPk?: Maybe<DbAgentProfileCityDistrict>;
  /** fetch data from the table in a streaming manner: "db.agent_profile_city_district" */
  dbAgentProfileCityDistrictStream: Array<DbAgentProfileCityDistrict>;
  /** fetch data from the table: "db.agent_profile_description" */
  dbAgentProfileDescription: Array<DbAgentProfileDescription>;
  /** fetch aggregated fields from the table: "db.agent_profile_description" */
  dbAgentProfileDescriptionAggregate: DbAgentProfileDescriptionAggregate;
  /** fetch data from the table: "db.agent_profile_description" using primary key columns */
  dbAgentProfileDescriptionByPk?: Maybe<DbAgentProfileDescription>;
  /** fetch data from the table in a streaming manner: "db.agent_profile_description" */
  dbAgentProfileDescriptionStream: Array<DbAgentProfileDescription>;
  /** fetch data from the table: "db.agent_profile_expertise" */
  dbAgentProfileExpertise: Array<DbAgentProfileExpertise>;
  /** fetch aggregated fields from the table: "db.agent_profile_expertise" */
  dbAgentProfileExpertiseAggregate: DbAgentProfileExpertiseAggregate;
  /** fetch data from the table: "db.agent_profile_expertise" using primary key columns */
  dbAgentProfileExpertiseByPk?: Maybe<DbAgentProfileExpertise>;
  /** fetch data from the table in a streaming manner: "db.agent_profile_expertise" */
  dbAgentProfileExpertiseStream: Array<DbAgentProfileExpertise>;
  /** fetch data from the table: "db.agent_profile_former_url" */
  dbAgentProfileFormerUrl: Array<DbAgentProfileFormerUrl>;
  /** fetch aggregated fields from the table: "db.agent_profile_former_url" */
  dbAgentProfileFormerUrlAggregate: DbAgentProfileFormerUrlAggregate;
  /** fetch data from the table: "db.agent_profile_former_url" using primary key columns */
  dbAgentProfileFormerUrlByPk?: Maybe<DbAgentProfileFormerUrl>;
  /** fetch data from the table in a streaming manner: "db.agent_profile_former_url" */
  dbAgentProfileFormerUrlStream: Array<DbAgentProfileFormerUrl>;
  /** fetch data from the table: "db.agent_profile_image" */
  dbAgentProfileImage: Array<DbAgentProfileImage>;
  /** fetch data from the table: "db.agent_profile_image" using primary key columns */
  dbAgentProfileImageByPk?: Maybe<DbAgentProfileImage>;
  /** fetch data from the table in a streaming manner: "db.agent_profile_image" */
  dbAgentProfileImageStream: Array<DbAgentProfileImage>;
  /** fetch data from the table: "db.agent_profile_social_media_profiles" */
  dbAgentProfileSocialMediaProfiles: Array<DbAgentProfileSocialMediaProfiles>;
  /** fetch aggregated fields from the table: "db.agent_profile_social_media_profiles" */
  dbAgentProfileSocialMediaProfilesAggregate: DbAgentProfileSocialMediaProfilesAggregate;
  /** fetch data from the table: "db.agent_profile_social_media_profiles" using primary key columns */
  dbAgentProfileSocialMediaProfilesByPk?: Maybe<DbAgentProfileSocialMediaProfiles>;
  /** fetch data from the table in a streaming manner: "db.agent_profile_social_media_profiles" */
  dbAgentProfileSocialMediaProfilesStream: Array<DbAgentProfileSocialMediaProfiles>;
  /** fetch data from the table: "db.agent_profile_spoken_language" */
  dbAgentProfileSpokenLanguage: Array<DbAgentProfileSpokenLanguage>;
  /** fetch aggregated fields from the table: "db.agent_profile_spoken_language" */
  dbAgentProfileSpokenLanguageAggregate: DbAgentProfileSpokenLanguageAggregate;
  /** fetch data from the table: "db.agent_profile_spoken_language" using primary key columns */
  dbAgentProfileSpokenLanguageByPk?: Maybe<DbAgentProfileSpokenLanguage>;
  /** fetch data from the table in a streaming manner: "db.agent_profile_spoken_language" */
  dbAgentProfileSpokenLanguageStream: Array<DbAgentProfileSpokenLanguage>;
  /** fetch data from the table in a streaming manner: "db.agent_profile" */
  dbAgentProfileStream: Array<DbAgentProfile>;
  /** fetch data from the table in a streaming manner: "db.agent" */
  dbAgentStream: Array<DbAgent>;
  /** fetch data from the table: "db.appointment_activity" */
  dbAppointmentActivity: Array<DbAppointmentActivity>;
  /** fetch data from the table: "db.appointment_activity" using primary key columns */
  dbAppointmentActivityByPk?: Maybe<DbAppointmentActivity>;
  /** fetch data from the table in a streaming manner: "db.appointment_activity" */
  dbAppointmentActivityStream: Array<DbAppointmentActivity>;
  /** fetch data from the table: "db.appointment_agent" */
  dbAppointmentAgent: Array<DbAppointmentAgent>;
  /** fetch data from the table in a streaming manner: "db.appointment_agent" */
  dbAppointmentAgentStream: Array<DbAppointmentAgent>;
  /** fetch data from the table: "db.appointment_contact" */
  dbAppointmentContact: Array<DbAppointmentContact>;
  /** fetch data from the table in a streaming manner: "db.appointment_contact" */
  dbAppointmentContactStream: Array<DbAppointmentContact>;
  /** fetch data from the table: "db.appointment_lead" */
  dbAppointmentLead: Array<DbAppointmentLead>;
  /** fetch data from the table in a streaming manner: "db.appointment_lead" */
  dbAppointmentLeadStream: Array<DbAppointmentLead>;
  /** fetch data from the table: "db.audit_activity" */
  dbAuditActivity: Array<DbAuditActivity>;
  /** fetch data from the table: "db.audit_activity" using primary key columns */
  dbAuditActivityByPk?: Maybe<DbAuditActivity>;
  /** fetch data from the table in a streaming manner: "db.audit_activity" */
  dbAuditActivityStream: Array<DbAuditActivity>;
  /** fetch data from the table: "db.base_activity" */
  dbBaseActivity: Array<DbBaseActivity>;
  /** fetch aggregated fields from the table: "db.base_activity" */
  dbBaseActivityAggregate: DbBaseActivityAggregate;
  /** fetch data from the table: "db.base_activity" using primary key columns */
  dbBaseActivityByPk?: Maybe<DbBaseActivity>;
  /** fetch data from the table: "db.base_activity_creation" */
  dbBaseActivityCreation: Array<DbBaseActivityCreation>;
  /** fetch aggregated fields from the table: "db.base_activity_creation" */
  dbBaseActivityCreationAggregate: DbBaseActivityCreationAggregate;
  /** fetch data from the table in a streaming manner: "db.base_activity_creation" */
  dbBaseActivityCreationStream: Array<DbBaseActivityCreation>;
  /** fetch data from the table in a streaming manner: "db.base_activity" */
  dbBaseActivityStream: Array<DbBaseActivity>;
  /** fetch data from the table: "db.batch_job_execution" */
  dbBatchJobExecution: Array<DbBatchJobExecution>;
  /** fetch data from the table: "db.batch_job_execution" using primary key columns */
  dbBatchJobExecutionByPk?: Maybe<DbBatchJobExecution>;
  /** fetch data from the table: "db.batch_job_execution_context" */
  dbBatchJobExecutionContext: Array<DbBatchJobExecutionContext>;
  /** fetch data from the table: "db.batch_job_execution_context" using primary key columns */
  dbBatchJobExecutionContextByPk?: Maybe<DbBatchJobExecutionContext>;
  /** fetch data from the table in a streaming manner: "db.batch_job_execution_context" */
  dbBatchJobExecutionContextStream: Array<DbBatchJobExecutionContext>;
  /** fetch data from the table: "db.batch_job_execution_params" */
  dbBatchJobExecutionParams: Array<DbBatchJobExecutionParams>;
  /** fetch data from the table in a streaming manner: "db.batch_job_execution_params" */
  dbBatchJobExecutionParamsStream: Array<DbBatchJobExecutionParams>;
  /** fetch data from the table in a streaming manner: "db.batch_job_execution" */
  dbBatchJobExecutionStream: Array<DbBatchJobExecution>;
  /** fetch data from the table: "db.batch_job_instance" */
  dbBatchJobInstance: Array<DbBatchJobInstance>;
  /** fetch data from the table: "db.batch_job_instance" using primary key columns */
  dbBatchJobInstanceByPk?: Maybe<DbBatchJobInstance>;
  /** fetch data from the table in a streaming manner: "db.batch_job_instance" */
  dbBatchJobInstanceStream: Array<DbBatchJobInstance>;
  /** fetch data from the table: "db.batch_step_execution" */
  dbBatchStepExecution: Array<DbBatchStepExecution>;
  /** fetch data from the table: "db.batch_step_execution" using primary key columns */
  dbBatchStepExecutionByPk?: Maybe<DbBatchStepExecution>;
  /** fetch data from the table: "db.batch_step_execution_context" */
  dbBatchStepExecutionContext: Array<DbBatchStepExecutionContext>;
  /** fetch data from the table: "db.batch_step_execution_context" using primary key columns */
  dbBatchStepExecutionContextByPk?: Maybe<DbBatchStepExecutionContext>;
  /** fetch data from the table in a streaming manner: "db.batch_step_execution_context" */
  dbBatchStepExecutionContextStream: Array<DbBatchStepExecutionContext>;
  /** fetch data from the table in a streaming manner: "db.batch_step_execution" */
  dbBatchStepExecutionStream: Array<DbBatchStepExecution>;
  /** fetch data from the table: "db.bulk_email" */
  dbBulkEmail: Array<DbBulkEmail>;
  /** fetch aggregated fields from the table: "db.bulk_email" */
  dbBulkEmailAggregate: DbBulkEmailAggregate;
  /** fetch data from the table in a streaming manner: "db.bulk_email" */
  dbBulkEmailStream: Array<DbBulkEmail>;
  /** fetch data from the table: "db.buyer_to_rent_specification" */
  dbBuyerToRentSpecification: Array<DbBuyerToRentSpecification>;
  /** fetch data from the table: "db.buyer_to_rent_specification" using primary key columns */
  dbBuyerToRentSpecificationByPk?: Maybe<DbBuyerToRentSpecification>;
  /** fetch data from the table: "db.buyer_to_rent_specification_cdf" */
  dbBuyerToRentSpecificationCdf: Array<DbBuyerToRentSpecificationCdf>;
  /** fetch data from the table: "db.buyer_to_rent_specification_cdf" using primary key columns */
  dbBuyerToRentSpecificationCdfByPk?: Maybe<DbBuyerToRentSpecificationCdf>;
  /** fetch data from the table in a streaming manner: "db.buyer_to_rent_specification_cdf" */
  dbBuyerToRentSpecificationCdfStream: Array<DbBuyerToRentSpecificationCdf>;
  /** fetch data from the table in a streaming manner: "db.buyer_to_rent_specification" */
  dbBuyerToRentSpecificationStream: Array<DbBuyerToRentSpecification>;
  /** fetch data from the table: "db.call" */
  dbCall: Array<DbCall>;
  /** fetch data from the table: "db.call_activity" */
  dbCallActivity: Array<DbCallActivity>;
  /** fetch data from the table: "db.call_activity" using primary key columns */
  dbCallActivityByPk?: Maybe<DbCallActivity>;
  /** fetch data from the table in a streaming manner: "db.call_activity" */
  dbCallActivityStream: Array<DbCallActivity>;
  /** fetch aggregated fields from the table: "db.call" */
  dbCallAggregate: DbCallAggregate;
  /** fetch data from the table: "db.call" using primary key columns */
  dbCallByPk?: Maybe<DbCall>;
  /** fetch data from the table in a streaming manner: "db.call" */
  dbCallStream: Array<DbCall>;
  /** fetch data from the table: "db.cdf" */
  dbCdf: Array<DbCdf>;
  /** fetch data from the table: "db.cdf" using primary key columns */
  dbCdfByPk?: Maybe<DbCdf>;
  /** fetch data from the table in a streaming manner: "db.cdf" */
  dbCdfStream: Array<DbCdf>;
  /** fetch data from the table: "db.consent" */
  dbConsent: Array<DbConsent>;
  /** fetch data from the table: "db.consent_activity" */
  dbConsentActivity: Array<DbConsentActivity>;
  /** fetch data from the table: "db.consent_activity" using primary key columns */
  dbConsentActivityByPk?: Maybe<DbConsentActivity>;
  /** fetch data from the table in a streaming manner: "db.consent_activity" */
  dbConsentActivityStream: Array<DbConsentActivity>;
  /** fetch aggregated fields from the table: "db.consent" */
  dbConsentAggregate: DbConsentAggregate;
  /** fetch data from the table: "db.consent" using primary key columns */
  dbConsentByPk?: Maybe<DbConsent>;
  /** fetch data from the table: "db.consent_event" */
  dbConsentEvent: Array<DbConsentEvent>;
  /** fetch aggregated fields from the table: "db.consent_event" */
  dbConsentEventAggregate: DbConsentEventAggregate;
  /** fetch data from the table: "db.consent_event" using primary key columns */
  dbConsentEventByPk?: Maybe<DbConsentEvent>;
  /** fetch data from the table in a streaming manner: "db.consent_event" */
  dbConsentEventStream: Array<DbConsentEvent>;
  /** fetch data from the table in a streaming manner: "db.consent" */
  dbConsentStream: Array<DbConsent>;
  /** fetch data from the table: "db.consent_template" */
  dbConsentTemplate: Array<DbConsentTemplate>;
  /** fetch data from the table: "db.consent_template" using primary key columns */
  dbConsentTemplateByPk?: Maybe<DbConsentTemplate>;
  /** fetch data from the table in a streaming manner: "db.consent_template" */
  dbConsentTemplateStream: Array<DbConsentTemplate>;
  /** fetch data from the table: "db.consent_text" */
  dbConsentText: Array<DbConsentText>;
  /** fetch data from the table: "db.consent_text" using primary key columns */
  dbConsentTextByPk?: Maybe<DbConsentText>;
  /** fetch data from the table in a streaming manner: "db.consent_text" */
  dbConsentTextStream: Array<DbConsentText>;
  /** fetch data from the table: "db.contact" */
  dbContact: Array<DbContact>;
  /** fetch aggregated fields from the table: "db.contact" */
  dbContactAggregate: DbContactAggregate;
  /** fetch data from the table: "db.contact_assignment" */
  dbContactAssignment: Array<DbContactAssignment>;
  /** fetch data from the table in a streaming manner: "db.contact_assignment" */
  dbContactAssignmentStream: Array<DbContactAssignment>;
  /** fetch data from the table: "db.contact" using primary key columns */
  dbContactByPk?: Maybe<DbContact>;
  /** fetch data from the table: "db.contact_change_activity" */
  dbContactChangeActivity: Array<DbContactChangeActivity>;
  /** fetch data from the table: "db.contact_change_activity" using primary key columns */
  dbContactChangeActivityByPk?: Maybe<DbContactChangeActivity>;
  /** fetch data from the table in a streaming manner: "db.contact_change_activity" */
  dbContactChangeActivityStream: Array<DbContactChangeActivity>;
  /** fetch data from the table: "db.contact_change_log" */
  dbContactChangeLog: Array<DbContactChangeLog>;
  /** fetch data from the table: "db.contact_change_log" using primary key columns */
  dbContactChangeLogByPk?: Maybe<DbContactChangeLog>;
  /** fetch data from the table in a streaming manner: "db.contact_change_log" */
  dbContactChangeLogStream: Array<DbContactChangeLog>;
  /** fetch data from the table: "db.contact_details" */
  dbContactDetails: Array<DbContactDetails>;
  /** fetch aggregated fields from the table: "db.contact_details" */
  dbContactDetailsAggregate: DbContactDetailsAggregate;
  /** fetch data from the table in a streaming manner: "db.contact_details" */
  dbContactDetailsStream: Array<DbContactDetails>;
  /** fetch data from the table: "db.contact_gdpr" */
  dbContactGdpr: Array<DbContactGdpr>;
  /** fetch data from the table: "db.contact_gdpr" using primary key columns */
  dbContactGdprByPk?: Maybe<DbContactGdpr>;
  /** fetch data from the table in a streaming manner: "db.contact_gdpr" */
  dbContactGdprStream: Array<DbContactGdpr>;
  /** fetch data from the table: "db.contact_preferred_time" */
  dbContactPreferredTime: Array<DbContactPreferredTime>;
  /** fetch aggregated fields from the table: "db.contact_preferred_time" */
  dbContactPreferredTimeAggregate: DbContactPreferredTimeAggregate;
  /** fetch data from the table: "db.contact_preferred_time" using primary key columns */
  dbContactPreferredTimeByPk?: Maybe<DbContactPreferredTime>;
  /** fetch data from the table in a streaming manner: "db.contact_preferred_time" */
  dbContactPreferredTimeStream: Array<DbContactPreferredTime>;
  /** fetch data from the table: "db.contact_preferred_way" */
  dbContactPreferredWay: Array<DbContactPreferredWay>;
  /** fetch aggregated fields from the table: "db.contact_preferred_way" */
  dbContactPreferredWayAggregate: DbContactPreferredWayAggregate;
  /** fetch data from the table: "db.contact_preferred_way" using primary key columns */
  dbContactPreferredWayByPk?: Maybe<DbContactPreferredWay>;
  /** fetch data from the table in a streaming manner: "db.contact_preferred_way" */
  dbContactPreferredWayStream: Array<DbContactPreferredWay>;
  /** fetch data from the table: "db.contact_relationship" */
  dbContactRelationship: Array<DbContactRelationship>;
  /** fetch data from the table: "db.contact_relationship" using primary key columns */
  dbContactRelationshipByPk?: Maybe<DbContactRelationship>;
  /** fetch data from the table in a streaming manner: "db.contact_relationship" */
  dbContactRelationshipStream: Array<DbContactRelationship>;
  /** fetch data from the table: "db.contact_renter_information" */
  dbContactRenterInformation: Array<DbContactRenterInformation>;
  /** fetch data from the table: "db.contact_renter_information" using primary key columns */
  dbContactRenterInformationByPk?: Maybe<DbContactRenterInformation>;
  /** fetch data from the table in a streaming manner: "db.contact_renter_information" */
  dbContactRenterInformationStream: Array<DbContactRenterInformation>;
  /** fetch data from the table: "db.contact_status_info" */
  dbContactStatusInfo: Array<DbContactStatusInfo>;
  /** fetch aggregated fields from the table: "db.contact_status_info" */
  dbContactStatusInfoAggregate: DbContactStatusInfoAggregate;
  /** fetch data from the table in a streaming manner: "db.contact_status_info" */
  dbContactStatusInfoStream: Array<DbContactStatusInfo>;
  /** fetch data from the table in a streaming manner: "db.contact" */
  dbContactStream: Array<DbContact>;
  /** fetch data from the table: "db.contact_tag" */
  dbContactTag: Array<DbContactTag>;
  /** fetch data from the table: "db.contact_tag" using primary key columns */
  dbContactTagByPk?: Maybe<DbContactTag>;
  /** fetch data from the table in a streaming manner: "db.contact_tag" */
  dbContactTagStream: Array<DbContactTag>;
  /** fetch data from the table: "db.conversion_data" */
  dbConversionData: Array<DbConversionData>;
  /** fetch data from the table: "db.conversion_data" using primary key columns */
  dbConversionDataByPk?: Maybe<DbConversionData>;
  /** fetch data from the table in a streaming manner: "db.conversion_data" */
  dbConversionDataStream: Array<DbConversionData>;
  /** fetch data from the table: "db.country_mandatory_language" */
  dbCountryMandatoryLanguage: Array<DbCountryMandatoryLanguage>;
  /** fetch data from the table: "db.country_mandatory_language" using primary key columns */
  dbCountryMandatoryLanguageByPk?: Maybe<DbCountryMandatoryLanguage>;
  /** fetch data from the table in a streaming manner: "db.country_mandatory_language" */
  dbCountryMandatoryLanguageStream: Array<DbCountryMandatoryLanguage>;
  /** fetch data from the table: "db.custom_list_view" */
  dbCustomListView: Array<DbCustomListView>;
  /** fetch data from the table: "db.custom_list_view" using primary key columns */
  dbCustomListViewByPk?: Maybe<DbCustomListView>;
  /** fetch data from the table in a streaming manner: "db.custom_list_view" */
  dbCustomListViewStream: Array<DbCustomListView>;
  /** fetch data from the table: "db.custom_search_demand_polygon" */
  dbCustomSearchDemandPolygon: Array<DbCustomSearchDemandPolygon>;
  /** fetch data from the table: "db.custom_search_demand_polygon" using primary key columns */
  dbCustomSearchDemandPolygonByPk?: Maybe<DbCustomSearchDemandPolygon>;
  /** fetch data from the table in a streaming manner: "db.custom_search_demand_polygon" */
  dbCustomSearchDemandPolygonStream: Array<DbCustomSearchDemandPolygon>;
  /** fetch data from the table: "db.document" */
  dbDocument: Array<DbDocument>;
  /** fetch data from the table: "db.document_activity" */
  dbDocumentActivity: Array<DbDocumentActivity>;
  /** fetch data from the table: "db.document_activity" using primary key columns */
  dbDocumentActivityByPk?: Maybe<DbDocumentActivity>;
  /** fetch data from the table in a streaming manner: "db.document_activity" */
  dbDocumentActivityStream: Array<DbDocumentActivity>;
  /** fetch aggregated fields from the table: "db.document" */
  dbDocumentAggregate: DbDocumentAggregate;
  /** fetch data from the table: "db.document" using primary key columns */
  dbDocumentByPk?: Maybe<DbDocument>;
  /** fetch data from the table: "db.document_reference" */
  dbDocumentReference: Array<DbDocumentReference>;
  /** fetch aggregated fields from the table: "db.document_reference" */
  dbDocumentReferenceAggregate: DbDocumentReferenceAggregate;
  /** fetch data from the table: "db.document_reference" using primary key columns */
  dbDocumentReferenceByPk?: Maybe<DbDocumentReference>;
  /** fetch data from the table in a streaming manner: "db.document_reference" */
  dbDocumentReferenceStream: Array<DbDocumentReference>;
  /** fetch data from the table in a streaming manner: "db.document" */
  dbDocumentStream: Array<DbDocument>;
  /** fetch data from the table: "db.email_attachment" */
  dbEmailAttachment: Array<DbEmailAttachment>;
  /** fetch aggregated fields from the table: "db.email_attachment" */
  dbEmailAttachmentAggregate: DbEmailAttachmentAggregate;
  /** fetch data from the table: "db.email_attachment" using primary key columns */
  dbEmailAttachmentByPk?: Maybe<DbEmailAttachment>;
  /** fetch data from the table in a streaming manner: "db.email_attachment" */
  dbEmailAttachmentStream: Array<DbEmailAttachment>;
  /** fetch data from the table: "db.email_callback_events" */
  dbEmailCallbackEvents: Array<DbEmailCallbackEvents>;
  /** fetch aggregated fields from the table: "db.email_callback_events" */
  dbEmailCallbackEventsAggregate: DbEmailCallbackEventsAggregate;
  /** fetch data from the table: "db.email_callback_events" using primary key columns */
  dbEmailCallbackEventsByPk?: Maybe<DbEmailCallbackEvents>;
  /** fetch data from the table in a streaming manner: "db.email_callback_events" */
  dbEmailCallbackEventsStream: Array<DbEmailCallbackEvents>;
  /** fetch data from the table: "db.email_link" */
  dbEmailLink: Array<DbEmailLink>;
  /** fetch aggregated fields from the table: "db.email_link" */
  dbEmailLinkAggregate: DbEmailLinkAggregate;
  /** fetch data from the table: "db.email_link" using primary key columns */
  dbEmailLinkByPk?: Maybe<DbEmailLink>;
  /** fetch data from the table in a streaming manner: "db.email_link" */
  dbEmailLinkStream: Array<DbEmailLink>;
  /** fetch data from the table: "db.email_message_map" */
  dbEmailMessageMap: Array<DbEmailMessageMap>;
  /** fetch data from the table in a streaming manner: "db.email_message_map" */
  dbEmailMessageMapStream: Array<DbEmailMessageMap>;
  /** fetch data from the table: "db.email_placeholder" */
  dbEmailPlaceholder: Array<DbEmailPlaceholder>;
  /** fetch aggregated fields from the table: "db.email_placeholder" */
  dbEmailPlaceholderAggregate: DbEmailPlaceholderAggregate;
  /** fetch data from the table: "db.email_placeholder" using primary key columns */
  dbEmailPlaceholderByPk?: Maybe<DbEmailPlaceholder>;
  /** fetch data from the table in a streaming manner: "db.email_placeholder" */
  dbEmailPlaceholderStream: Array<DbEmailPlaceholder>;
  /** fetch data from the table: "db.email_signature" */
  dbEmailSignature: Array<DbEmailSignature>;
  /** fetch aggregated fields from the table: "db.email_signature" */
  dbEmailSignatureAggregate: DbEmailSignatureAggregate;
  /** fetch data from the table: "db.email_signature" using primary key columns */
  dbEmailSignatureByPk?: Maybe<DbEmailSignature>;
  /** fetch data from the table in a streaming manner: "db.email_signature" */
  dbEmailSignatureStream: Array<DbEmailSignature>;
  /** fetch data from the table: "db.email_template" */
  dbEmailTemplate: Array<DbEmailTemplate>;
  /** fetch aggregated fields from the table: "db.email_template" */
  dbEmailTemplateAggregate: DbEmailTemplateAggregate;
  /** fetch data from the table: "db.email_template" using primary key columns */
  dbEmailTemplateByPk?: Maybe<DbEmailTemplate>;
  /** fetch data from the table: "db.email_template_content" */
  dbEmailTemplateContent: Array<DbEmailTemplateContent>;
  /** fetch aggregated fields from the table: "db.email_template_content" */
  dbEmailTemplateContentAggregate: DbEmailTemplateContentAggregate;
  /** fetch data from the table: "db.email_template_content" using primary key columns */
  dbEmailTemplateContentByPk?: Maybe<DbEmailTemplateContent>;
  /** fetch data from the table in a streaming manner: "db.email_template_content" */
  dbEmailTemplateContentStream: Array<DbEmailTemplateContent>;
  /** fetch data from the table in a streaming manner: "db.email_template" */
  dbEmailTemplateStream: Array<DbEmailTemplate>;
  /** fetch data from the table: "db.email_tracking" */
  dbEmailTracking: Array<DbEmailTracking>;
  /** fetch aggregated fields from the table: "db.email_tracking" */
  dbEmailTrackingAggregate: DbEmailTrackingAggregate;
  /** fetch data from the table: "db.email_tracking" using primary key columns */
  dbEmailTrackingByPk?: Maybe<DbEmailTracking>;
  /** fetch data from the table in a streaming manner: "db.email_tracking" */
  dbEmailTrackingStream: Array<DbEmailTracking>;
  /** fetch data from the table: "db.enabled_feature_flags" */
  dbEnabledFeatureFlags: Array<DbEnabledFeatureFlags>;
  /** fetch data from the table in a streaming manner: "db.enabled_feature_flags" */
  dbEnabledFeatureFlagsStream: Array<DbEnabledFeatureFlags>;
  /** fetch data from the table: "db.exposee_shared_activity" */
  dbExposeeSharedActivity: Array<DbExposeeSharedActivity>;
  /** fetch aggregated fields from the table: "db.exposee_shared_activity" */
  dbExposeeSharedActivityAggregate: DbExposeeSharedActivityAggregate;
  /** fetch data from the table: "db.exposee_shared_activity" using primary key columns */
  dbExposeeSharedActivityByPk?: Maybe<DbExposeeSharedActivity>;
  /** fetch data from the table in a streaming manner: "db.exposee_shared_activity" */
  dbExposeeSharedActivityStream: Array<DbExposeeSharedActivity>;
  /** fetch data from the table: "db.import_lead_request" */
  dbImportLeadRequest: Array<DbImportLeadRequest>;
  /** fetch aggregated fields from the table: "db.import_lead_request" */
  dbImportLeadRequestAggregate: DbImportLeadRequestAggregate;
  /** fetch data from the table: "db.import_lead_request" using primary key columns */
  dbImportLeadRequestByPk?: Maybe<DbImportLeadRequest>;
  /** fetch data from the table in a streaming manner: "db.import_lead_request" */
  dbImportLeadRequestStream: Array<DbImportLeadRequest>;
  /** fetch data from the table: "db.lead" */
  dbLead: Array<DbLead>;
  /** fetch aggregated fields from the table: "db.lead" */
  dbLeadAggregate: DbLeadAggregate;
  /** fetch data from the table: "db.lead_auto_assignment_intent" */
  dbLeadAutoAssignmentIntent: Array<DbLeadAutoAssignmentIntent>;
  /** fetch data from the table: "db.lead_auto_assignment_intent" using primary key columns */
  dbLeadAutoAssignmentIntentByPk?: Maybe<DbLeadAutoAssignmentIntent>;
  /** fetch data from the table in a streaming manner: "db.lead_auto_assignment_intent" */
  dbLeadAutoAssignmentIntentStream: Array<DbLeadAutoAssignmentIntent>;
  /** fetch data from the table: "db.lead_auto_assignment_rules" */
  dbLeadAutoAssignmentRules: Array<DbLeadAutoAssignmentRules>;
  /** fetch data from the table: "db.lead_auto_assignment_rules" using primary key columns */
  dbLeadAutoAssignmentRulesByPk?: Maybe<DbLeadAutoAssignmentRules>;
  /** fetch data from the table in a streaming manner: "db.lead_auto_assignment_rules" */
  dbLeadAutoAssignmentRulesStream: Array<DbLeadAutoAssignmentRules>;
  /** fetch data from the table: "db.lead" using primary key columns */
  dbLeadByPk?: Maybe<DbLead>;
  /** fetch data from the table: "db.lead_expiry" */
  dbLeadExpiry: Array<DbLeadExpiry>;
  /** fetch data from the table in a streaming manner: "db.lead_expiry" */
  dbLeadExpiryStream: Array<DbLeadExpiry>;
  /** fetch data from the table: "db.lead_extending_activities" */
  dbLeadExtendingActivities: Array<DbLeadExtendingActivities>;
  /** fetch data from the table: "db.lead_extending_activities" using primary key columns */
  dbLeadExtendingActivitiesByPk?: Maybe<DbLeadExtendingActivities>;
  /** fetch data from the table in a streaming manner: "db.lead_extending_activities" */
  dbLeadExtendingActivitiesStream: Array<DbLeadExtendingActivities>;
  /** fetch data from the table: "db.lead_extending_appointment_types" */
  dbLeadExtendingAppointmentTypes: Array<DbLeadExtendingAppointmentTypes>;
  /** fetch data from the table: "db.lead_extending_appointment_types" using primary key columns */
  dbLeadExtendingAppointmentTypesByPk?: Maybe<DbLeadExtendingAppointmentTypes>;
  /** fetch data from the table in a streaming manner: "db.lead_extending_appointment_types" */
  dbLeadExtendingAppointmentTypesStream: Array<DbLeadExtendingAppointmentTypes>;
  /** fetch data from the table: "db.lead_extending_task_types" */
  dbLeadExtendingTaskTypes: Array<DbLeadExtendingTaskTypes>;
  /** fetch data from the table: "db.lead_extending_task_types" using primary key columns */
  dbLeadExtendingTaskTypesByPk?: Maybe<DbLeadExtendingTaskTypes>;
  /** fetch data from the table in a streaming manner: "db.lead_extending_task_types" */
  dbLeadExtendingTaskTypesStream: Array<DbLeadExtendingTaskTypes>;
  /** fetch data from the table: "db.lead_statistics" */
  dbLeadStatistics: Array<DbLeadStatistics>;
  /** fetch data from the table in a streaming manner: "db.lead_statistics" */
  dbLeadStatisticsStream: Array<DbLeadStatistics>;
  /** fetch data from the table in a streaming manner: "db.lead" */
  dbLeadStream: Array<DbLead>;
  /** fetch data from the table: "db.leadhub_go3_contact_link" */
  dbLeadhubGo3ContactLink: Array<DbLeadhubGo3ContactLink>;
  /** fetch aggregated fields from the table: "db.leadhub_go3_contact_link" */
  dbLeadhubGo3ContactLinkAggregate: DbLeadhubGo3ContactLinkAggregate;
  /** fetch data from the table: "db.leadhub_go3_contact_link" using primary key columns */
  dbLeadhubGo3ContactLinkByPk?: Maybe<DbLeadhubGo3ContactLink>;
  /** fetch data from the table in a streaming manner: "db.leadhub_go3_contact_link" */
  dbLeadhubGo3ContactLinkStream: Array<DbLeadhubGo3ContactLink>;
  /** fetch data from the table: "db.legacy_go3_change_log" */
  dbLegacyGo3ChangeLog: Array<DbLegacyGo3ChangeLog>;
  /** fetch data from the table: "db.legacy_go3_change_log" using primary key columns */
  dbLegacyGo3ChangeLogByPk?: Maybe<DbLegacyGo3ChangeLog>;
  /** fetch data from the table in a streaming manner: "db.legacy_go3_change_log" */
  dbLegacyGo3ChangeLogStream: Array<DbLegacyGo3ChangeLog>;
  /** fetch data from the table: "db.legal_entity" */
  dbLegalEntity: Array<DbLegalEntity>;
  /** fetch aggregated fields from the table: "db.legal_entity" */
  dbLegalEntityAggregate: DbLegalEntityAggregate;
  /** fetch data from the table: "db.legal_entity" using primary key columns */
  dbLegalEntityByPk?: Maybe<DbLegalEntity>;
  /** fetch data from the table in a streaming manner: "db.legal_entity" */
  dbLegalEntityStream: Array<DbLegalEntity>;
  /** fetch data from the table: "db.license_partner" */
  dbLicensePartner: Array<DbLicensePartner>;
  /** fetch aggregated fields from the table: "db.license_partner" */
  dbLicensePartnerAggregate: DbLicensePartnerAggregate;
  /** fetch data from the table: "db.license_partner" using primary key columns */
  dbLicensePartnerByPk?: Maybe<DbLicensePartner>;
  /** fetch data from the table: "db.license_partner_profile" */
  dbLicensePartnerProfile: Array<DbLicensePartnerProfile>;
  /** fetch aggregated fields from the table: "db.license_partner_profile" */
  dbLicensePartnerProfileAggregate: DbLicensePartnerProfileAggregate;
  /** fetch data from the table: "db.license_partner_profile" using primary key columns */
  dbLicensePartnerProfileByPk?: Maybe<DbLicensePartnerProfile>;
  /** fetch data from the table: "db.license_partner_profile_featured_agent" */
  dbLicensePartnerProfileFeaturedAgent: Array<DbLicensePartnerProfileFeaturedAgent>;
  /** fetch data from the table: "db.license_partner_profile_featured_agent" using primary key columns */
  dbLicensePartnerProfileFeaturedAgentByPk?: Maybe<DbLicensePartnerProfileFeaturedAgent>;
  /** fetch data from the table in a streaming manner: "db.license_partner_profile_featured_agent" */
  dbLicensePartnerProfileFeaturedAgentStream: Array<DbLicensePartnerProfileFeaturedAgent>;
  /** fetch data from the table: "db.license_partner_profile_featured_content_teaser" */
  dbLicensePartnerProfileFeaturedContentTeaser: Array<DbLicensePartnerProfileFeaturedContentTeaser>;
  /** fetch data from the table: "db.license_partner_profile_featured_content_teaser" using primary key columns */
  dbLicensePartnerProfileFeaturedContentTeaserByPk?: Maybe<DbLicensePartnerProfileFeaturedContentTeaser>;
  /** fetch data from the table in a streaming manner: "db.license_partner_profile_featured_content_teaser" */
  dbLicensePartnerProfileFeaturedContentTeaserStream: Array<DbLicensePartnerProfileFeaturedContentTeaser>;
  /** fetch data from the table: "db.license_partner_profile_former_url" */
  dbLicensePartnerProfileFormerUrl: Array<DbLicensePartnerProfileFormerUrl>;
  /** fetch aggregated fields from the table: "db.license_partner_profile_former_url" */
  dbLicensePartnerProfileFormerUrlAggregate: DbLicensePartnerProfileFormerUrlAggregate;
  /** fetch data from the table: "db.license_partner_profile_former_url" using primary key columns */
  dbLicensePartnerProfileFormerUrlByPk?: Maybe<DbLicensePartnerProfileFormerUrl>;
  /** fetch data from the table in a streaming manner: "db.license_partner_profile_former_url" */
  dbLicensePartnerProfileFormerUrlStream: Array<DbLicensePartnerProfileFormerUrl>;
  /** fetch data from the table: "db.license_partner_profile_image" */
  dbLicensePartnerProfileImage: Array<DbLicensePartnerProfileImage>;
  /** fetch aggregated fields from the table: "db.license_partner_profile_image" */
  dbLicensePartnerProfileImageAggregate: DbLicensePartnerProfileImageAggregate;
  /** fetch data from the table: "db.license_partner_profile_image" using primary key columns */
  dbLicensePartnerProfileImageByPk?: Maybe<DbLicensePartnerProfileImage>;
  /** fetch data from the table in a streaming manner: "db.license_partner_profile_image" */
  dbLicensePartnerProfileImageStream: Array<DbLicensePartnerProfileImage>;
  /** fetch data from the table: "db.license_partner_profile_language_variant" */
  dbLicensePartnerProfileLanguageVariant: Array<DbLicensePartnerProfileLanguageVariant>;
  /** fetch aggregated fields from the table: "db.license_partner_profile_language_variant" */
  dbLicensePartnerProfileLanguageVariantAggregate: DbLicensePartnerProfileLanguageVariantAggregate;
  /** fetch data from the table: "db.license_partner_profile_language_variant" using primary key columns */
  dbLicensePartnerProfileLanguageVariantByPk?: Maybe<DbLicensePartnerProfileLanguageVariant>;
  /** fetch data from the table in a streaming manner: "db.license_partner_profile_language_variant" */
  dbLicensePartnerProfileLanguageVariantStream: Array<DbLicensePartnerProfileLanguageVariant>;
  /** fetch data from the table: "db.license_partner_profile_property_preview" */
  dbLicensePartnerProfilePropertyPreview: Array<DbLicensePartnerProfilePropertyPreview>;
  /** fetch data from the table: "db.license_partner_profile_property_preview" using primary key columns */
  dbLicensePartnerProfilePropertyPreviewByPk?: Maybe<DbLicensePartnerProfilePropertyPreview>;
  /** fetch data from the table in a streaming manner: "db.license_partner_profile_property_preview" */
  dbLicensePartnerProfilePropertyPreviewStream: Array<DbLicensePartnerProfilePropertyPreview>;
  /** fetch data from the table in a streaming manner: "db.license_partner_profile" */
  dbLicensePartnerProfileStream: Array<DbLicensePartnerProfile>;
  /** fetch data from the table in a streaming manner: "db.license_partner" */
  dbLicensePartnerStream: Array<DbLicensePartner>;
  /** fetch data from the table: "db.migration_source" */
  dbMigrationSource: Array<DbMigrationSource>;
  /** fetch aggregated fields from the table: "db.migration_source" */
  dbMigrationSourceAggregate: DbMigrationSourceAggregate;
  /** fetch data from the table: "db.migration_source" using primary key columns */
  dbMigrationSourceByPk?: Maybe<DbMigrationSource>;
  /** fetch data from the table: "db.migration_source_pk_list" */
  dbMigrationSourcePkList: Array<DbMigrationSourcePkList>;
  /** fetch aggregated fields from the table: "db.migration_source_pk_list" */
  dbMigrationSourcePkListAggregate: DbMigrationSourcePkListAggregate;
  /** fetch data from the table: "db.migration_source_pk_list" using primary key columns */
  dbMigrationSourcePkListByPk?: Maybe<DbMigrationSourcePkList>;
  /** fetch data from the table in a streaming manner: "db.migration_source_pk_list" */
  dbMigrationSourcePkListStream: Array<DbMigrationSourcePkList>;
  /** fetch data from the table in a streaming manner: "db.migration_source" */
  dbMigrationSourceStream: Array<DbMigrationSource>;
  /** fetch data from the table: "db.migration_target" */
  dbMigrationTarget: Array<DbMigrationTarget>;
  /** fetch aggregated fields from the table: "db.migration_target" */
  dbMigrationTargetAggregate: DbMigrationTargetAggregate;
  /** fetch data from the table: "db.migration_target" using primary key columns */
  dbMigrationTargetByPk?: Maybe<DbMigrationTarget>;
  /** fetch data from the table in a streaming manner: "db.migration_target" */
  dbMigrationTargetStream: Array<DbMigrationTarget>;
  /** fetch data from the table: "db.nylas_email_integration" */
  dbNylasEmailIntegration: Array<DbNylasEmailIntegration>;
  /** fetch aggregated fields from the table: "db.nylas_email_integration" */
  dbNylasEmailIntegrationAggregate: DbNylasEmailIntegrationAggregate;
  /** fetch data from the table: "db.nylas_email_integration" using primary key columns */
  dbNylasEmailIntegrationByPk?: Maybe<DbNylasEmailIntegration>;
  /** fetch data from the table in a streaming manner: "db.nylas_email_integration" */
  dbNylasEmailIntegrationStream: Array<DbNylasEmailIntegration>;
  /** fetch data from the table: "db.one_way_mapping_from_lead_id_to_contact_id" */
  dbOneWayMappingFromLeadIdToContactId: Array<DbOneWayMappingFromLeadIdToContactId>;
  /** fetch data from the table in a streaming manner: "db.one_way_mapping_from_lead_id_to_contact_id" */
  dbOneWayMappingFromLeadIdToContactIdStream: Array<DbOneWayMappingFromLeadIdToContactId>;
  /** fetch data from the table: "db.optimizely_client" */
  dbOptimizelyClient: Array<DbOptimizelyClient>;
  /** fetch data from the table: "db.optimizely_client" using primary key columns */
  dbOptimizelyClientByPk?: Maybe<DbOptimizelyClient>;
  /** fetch data from the table in a streaming manner: "db.optimizely_client" */
  dbOptimizelyClientStream: Array<DbOptimizelyClient>;
  /** fetch data from the table: "db.potential_duplicate_contact" */
  dbPotentialDuplicateContact: Array<DbPotentialDuplicateContact>;
  /** fetch aggregated fields from the table: "db.potential_duplicate_contact" */
  dbPotentialDuplicateContactAggregate: DbPotentialDuplicateContactAggregate;
  /** fetch data from the table: "db.potential_duplicate_contact" using primary key columns */
  dbPotentialDuplicateContactByPk?: Maybe<DbPotentialDuplicateContact>;
  /** fetch data from the table in a streaming manner: "db.potential_duplicate_contact" */
  dbPotentialDuplicateContactStream: Array<DbPotentialDuplicateContact>;
  /** fetch data from the table: "db.printed_document_activity" */
  dbPrintedDocumentActivity: Array<DbPrintedDocumentActivity>;
  /** fetch data from the table: "db.printed_document_activity" using primary key columns */
  dbPrintedDocumentActivityByPk?: Maybe<DbPrintedDocumentActivity>;
  /** fetch data from the table in a streaming manner: "db.printed_document_activity" */
  dbPrintedDocumentActivityStream: Array<DbPrintedDocumentActivity>;
  /** fetch data from the table: "db.property_lounge" */
  dbPropertyLounge: Array<DbPropertyLounge>;
  /** fetch data from the table: "db.property_lounge" using primary key columns */
  dbPropertyLoungeByPk?: Maybe<DbPropertyLounge>;
  /** fetch data from the table in a streaming manner: "db.property_lounge" */
  dbPropertyLoungeStream: Array<DbPropertyLounge>;
  /** fetch data from the table: "db.qualification_form_link" */
  dbQualificationFormLink: Array<DbQualificationFormLink>;
  /** fetch aggregated fields from the table: "db.qualification_form_link" */
  dbQualificationFormLinkAggregate: DbQualificationFormLinkAggregate;
  /** fetch data from the table: "db.qualification_form_link" using primary key columns */
  dbQualificationFormLinkByPk?: Maybe<DbQualificationFormLink>;
  /** fetch data from the table in a streaming manner: "db.qualification_form_link" */
  dbQualificationFormLinkStream: Array<DbQualificationFormLink>;
  /** fetch data from the table: "db.qualiform_lead_intent" */
  dbQualiformLeadIntent: Array<DbQualiformLeadIntent>;
  /** fetch data from the table: "db.qualiform_lead_intent" using primary key columns */
  dbQualiformLeadIntentByPk?: Maybe<DbQualiformLeadIntent>;
  /** fetch data from the table in a streaming manner: "db.qualiform_lead_intent" */
  dbQualiformLeadIntentStream: Array<DbQualiformLeadIntent>;
  /** fetch data from the table: "db.qualiform_lead_source" */
  dbQualiformLeadSource: Array<DbQualiformLeadSource>;
  /** fetch data from the table: "db.qualiform_lead_source" using primary key columns */
  dbQualiformLeadSourceByPk?: Maybe<DbQualiformLeadSource>;
  /** fetch data from the table in a streaming manner: "db.qualiform_lead_source" */
  dbQualiformLeadSourceStream: Array<DbQualiformLeadSource>;
  /** fetch data from the table: "db.qualiform_mandatory_field" */
  dbQualiformMandatoryField: Array<DbQualiformMandatoryField>;
  /** fetch data from the table: "db.qualiform_mandatory_field" using primary key columns */
  dbQualiformMandatoryFieldByPk?: Maybe<DbQualiformMandatoryField>;
  /** fetch data from the table in a streaming manner: "db.qualiform_mandatory_field" */
  dbQualiformMandatoryFieldStream: Array<DbQualiformMandatoryField>;
  /** fetch data from the table: "db.qualiform_shop_setting" */
  dbQualiformShopSetting: Array<DbQualiformShopSetting>;
  /** fetch data from the table: "db.qualiform_shop_setting" using primary key columns */
  dbQualiformShopSettingByPk?: Maybe<DbQualiformShopSetting>;
  /** fetch data from the table in a streaming manner: "db.qualiform_shop_setting" */
  dbQualiformShopSettingStream: Array<DbQualiformShopSetting>;
  /** fetch data from the table: "db.referral" */
  dbReferral: Array<DbReferral>;
  /** fetch data from the table: "db.referral_activity" */
  dbReferralActivity: Array<DbReferralActivity>;
  /** fetch data from the table: "db.referral_activity" using primary key columns */
  dbReferralActivityByPk?: Maybe<DbReferralActivity>;
  /** fetch data from the table in a streaming manner: "db.referral_activity" */
  dbReferralActivityStream: Array<DbReferralActivity>;
  /** fetch aggregated fields from the table: "db.referral" */
  dbReferralAggregate: DbReferralAggregate;
  /** fetch data from the table: "db.referral" using primary key columns */
  dbReferralByPk?: Maybe<DbReferral>;
  /** fetch data from the table in a streaming manner: "db.referral" */
  dbReferralStream: Array<DbReferral>;
  /** fetch data from the table: "db.seller_rent_out_specification" */
  dbSellerRentOutSpecification: Array<DbSellerRentOutSpecification>;
  /** fetch data from the table: "db.seller_rent_out_specification" using primary key columns */
  dbSellerRentOutSpecificationByPk?: Maybe<DbSellerRentOutSpecification>;
  /** fetch data from the table: "db.seller_rent_out_specification_cdf" */
  dbSellerRentOutSpecificationCdf: Array<DbSellerRentOutSpecificationCdf>;
  /** fetch data from the table: "db.seller_rent_out_specification_cdf" using primary key columns */
  dbSellerRentOutSpecificationCdfByPk?: Maybe<DbSellerRentOutSpecificationCdf>;
  /** fetch data from the table in a streaming manner: "db.seller_rent_out_specification_cdf" */
  dbSellerRentOutSpecificationCdfStream: Array<DbSellerRentOutSpecificationCdf>;
  /** fetch data from the table in a streaming manner: "db.seller_rent_out_specification" */
  dbSellerRentOutSpecificationStream: Array<DbSellerRentOutSpecification>;
  /** fetch data from the table: "db.session" */
  dbSession: Array<DbSession>;
  /** fetch aggregated fields from the table: "db.session" */
  dbSessionAggregate: DbSessionAggregate;
  /** fetch data from the table: "db.session" using primary key columns */
  dbSessionByPk?: Maybe<DbSession>;
  /** fetch data from the table in a streaming manner: "db.session" */
  dbSessionStream: Array<DbSession>;
  /** fetch data from the table: "db.shop" */
  dbShop: Array<DbShop>;
  /** fetch aggregated fields from the table: "db.shop" */
  dbShopAggregate: DbShopAggregate;
  /** fetch data from the table: "db.shop" using primary key columns */
  dbShopByPk?: Maybe<DbShop>;
  /** fetch data from the table: "db.shop_commission_contract" */
  dbShopCommissionContract: Array<DbShopCommissionContract>;
  /** fetch data from the table: "db.shop_commission_contract" using primary key columns */
  dbShopCommissionContractByPk?: Maybe<DbShopCommissionContract>;
  /** fetch data from the table in a streaming manner: "db.shop_commission_contract" */
  dbShopCommissionContractStream: Array<DbShopCommissionContract>;
  /** fetch data from the table: "db.shop_group" */
  dbShopGroup: Array<DbShopGroup>;
  /** fetch aggregated fields from the table: "db.shop_group" */
  dbShopGroupAggregate: DbShopGroupAggregate;
  /** fetch data from the table: "db.shop_group" using primary key columns */
  dbShopGroupByPk?: Maybe<DbShopGroup>;
  /** fetch data from the table in a streaming manner: "db.shop_group" */
  dbShopGroupStream: Array<DbShopGroup>;
  /** fetch data from the table: "db.shop_managed_consent" */
  dbShopManagedConsent: Array<DbShopManagedConsent>;
  /** fetch data from the table: "db.shop_managed_consent" using primary key columns */
  dbShopManagedConsentByPk?: Maybe<DbShopManagedConsent>;
  /** fetch data from the table in a streaming manner: "db.shop_managed_consent" */
  dbShopManagedConsentStream: Array<DbShopManagedConsent>;
  /** fetch data from the table: "db.shop_profile" */
  dbShopProfile: Array<DbShopProfile>;
  /** fetch aggregated fields from the table: "db.shop_profile" */
  dbShopProfileAggregate: DbShopProfileAggregate;
  /** fetch data from the table: "db.shop_profile" using primary key columns */
  dbShopProfileByPk?: Maybe<DbShopProfile>;
  /** fetch data from the table: "db.shop_profile_career" */
  dbShopProfileCareer: Array<DbShopProfileCareer>;
  /** fetch aggregated fields from the table: "db.shop_profile_career" */
  dbShopProfileCareerAggregate: DbShopProfileCareerAggregate;
  /** fetch data from the table: "db.shop_profile_career" using primary key columns */
  dbShopProfileCareerByPk?: Maybe<DbShopProfileCareer>;
  /** fetch data from the table in a streaming manner: "db.shop_profile_career" */
  dbShopProfileCareerStream: Array<DbShopProfileCareer>;
  /** fetch data from the table: "db.shop_profile_city_district" */
  dbShopProfileCityDistrict: Array<DbShopProfileCityDistrict>;
  /** fetch aggregated fields from the table: "db.shop_profile_city_district" */
  dbShopProfileCityDistrictAggregate: DbShopProfileCityDistrictAggregate;
  /** fetch data from the table: "db.shop_profile_city_district" using primary key columns */
  dbShopProfileCityDistrictByPk?: Maybe<DbShopProfileCityDistrict>;
  /** fetch data from the table in a streaming manner: "db.shop_profile_city_district" */
  dbShopProfileCityDistrictStream: Array<DbShopProfileCityDistrict>;
  /** fetch data from the table: "db.shop_profile_customer_mails" */
  dbShopProfileCustomerMails: Array<DbShopProfileCustomerMails>;
  /** fetch data from the table: "db.shop_profile_customer_mails" using primary key columns */
  dbShopProfileCustomerMailsByPk?: Maybe<DbShopProfileCustomerMails>;
  /** fetch data from the table in a streaming manner: "db.shop_profile_customer_mails" */
  dbShopProfileCustomerMailsStream: Array<DbShopProfileCustomerMails>;
  /** fetch data from the table: "db.shop_profile_description" */
  dbShopProfileDescription: Array<DbShopProfileDescription>;
  /** fetch aggregated fields from the table: "db.shop_profile_description" */
  dbShopProfileDescriptionAggregate: DbShopProfileDescriptionAggregate;
  /** fetch data from the table: "db.shop_profile_description" using primary key columns */
  dbShopProfileDescriptionByPk?: Maybe<DbShopProfileDescription>;
  /** fetch data from the table in a streaming manner: "db.shop_profile_description" */
  dbShopProfileDescriptionStream: Array<DbShopProfileDescription>;
  /** fetch data from the table: "db.shop_profile_expertise" */
  dbShopProfileExpertise: Array<DbShopProfileExpertise>;
  /** fetch aggregated fields from the table: "db.shop_profile_expertise" */
  dbShopProfileExpertiseAggregate: DbShopProfileExpertiseAggregate;
  /** fetch data from the table: "db.shop_profile_expertise" using primary key columns */
  dbShopProfileExpertiseByPk?: Maybe<DbShopProfileExpertise>;
  /** fetch data from the table in a streaming manner: "db.shop_profile_expertise" */
  dbShopProfileExpertiseStream: Array<DbShopProfileExpertise>;
  /** fetch data from the table: "db.shop_profile_featured_agent" */
  dbShopProfileFeaturedAgent: Array<DbShopProfileFeaturedAgent>;
  /** fetch data from the table: "db.shop_profile_featured_agent" using primary key columns */
  dbShopProfileFeaturedAgentByPk?: Maybe<DbShopProfileFeaturedAgent>;
  /** fetch data from the table in a streaming manner: "db.shop_profile_featured_agent" */
  dbShopProfileFeaturedAgentStream: Array<DbShopProfileFeaturedAgent>;
  /** fetch data from the table: "db.shop_profile_featured_content" */
  dbShopProfileFeaturedContent: Array<DbShopProfileFeaturedContent>;
  /** fetch data from the table: "db.shop_profile_featured_content" using primary key columns */
  dbShopProfileFeaturedContentByPk?: Maybe<DbShopProfileFeaturedContent>;
  /** fetch data from the table in a streaming manner: "db.shop_profile_featured_content" */
  dbShopProfileFeaturedContentStream: Array<DbShopProfileFeaturedContent>;
  /** fetch data from the table: "db.shop_profile_featured_content_teaser" */
  dbShopProfileFeaturedContentTeaser: Array<DbShopProfileFeaturedContentTeaser>;
  /** fetch data from the table: "db.shop_profile_featured_content_teaser" using primary key columns */
  dbShopProfileFeaturedContentTeaserByPk?: Maybe<DbShopProfileFeaturedContentTeaser>;
  /** fetch data from the table in a streaming manner: "db.shop_profile_featured_content_teaser" */
  dbShopProfileFeaturedContentTeaserStream: Array<DbShopProfileFeaturedContentTeaser>;
  /** fetch data from the table: "db.shop_profile_former_url" */
  dbShopProfileFormerUrl: Array<DbShopProfileFormerUrl>;
  /** fetch aggregated fields from the table: "db.shop_profile_former_url" */
  dbShopProfileFormerUrlAggregate: DbShopProfileFormerUrlAggregate;
  /** fetch data from the table: "db.shop_profile_former_url" using primary key columns */
  dbShopProfileFormerUrlByPk?: Maybe<DbShopProfileFormerUrl>;
  /** fetch data from the table in a streaming manner: "db.shop_profile_former_url" */
  dbShopProfileFormerUrlStream: Array<DbShopProfileFormerUrl>;
  /** fetch data from the table: "db.shop_profile_image" */
  dbShopProfileImage: Array<DbShopProfileImage>;
  /** fetch aggregated fields from the table: "db.shop_profile_image" */
  dbShopProfileImageAggregate: DbShopProfileImageAggregate;
  /** fetch data from the table: "db.shop_profile_image" using primary key columns */
  dbShopProfileImageByPk?: Maybe<DbShopProfileImage>;
  /** fetch data from the table in a streaming manner: "db.shop_profile_image" */
  dbShopProfileImageStream: Array<DbShopProfileImage>;
  /** fetch data from the table: "db.shop_profile_imprint" */
  dbShopProfileImprint: Array<DbShopProfileImprint>;
  /** fetch aggregated fields from the table: "db.shop_profile_imprint" */
  dbShopProfileImprintAggregate: DbShopProfileImprintAggregate;
  /** fetch data from the table: "db.shop_profile_imprint" using primary key columns */
  dbShopProfileImprintByPk?: Maybe<DbShopProfileImprint>;
  /** fetch data from the table in a streaming manner: "db.shop_profile_imprint" */
  dbShopProfileImprintStream: Array<DbShopProfileImprint>;
  /** fetch data from the table: "db.shop_profile_privacy_policy" */
  dbShopProfilePrivacyPolicy: Array<DbShopProfilePrivacyPolicy>;
  /** fetch aggregated fields from the table: "db.shop_profile_privacy_policy" */
  dbShopProfilePrivacyPolicyAggregate: DbShopProfilePrivacyPolicyAggregate;
  /** fetch data from the table: "db.shop_profile_privacy_policy" using primary key columns */
  dbShopProfilePrivacyPolicyByPk?: Maybe<DbShopProfilePrivacyPolicy>;
  /** fetch data from the table in a streaming manner: "db.shop_profile_privacy_policy" */
  dbShopProfilePrivacyPolicyStream: Array<DbShopProfilePrivacyPolicy>;
  /** fetch data from the table: "db.shop_profile_property_preview" */
  dbShopProfilePropertyPreview: Array<DbShopProfilePropertyPreview>;
  /** fetch aggregated fields from the table: "db.shop_profile_property_preview" */
  dbShopProfilePropertyPreviewAggregate: DbShopProfilePropertyPreviewAggregate;
  /** fetch data from the table: "db.shop_profile_property_preview" using primary key columns */
  dbShopProfilePropertyPreviewByPk?: Maybe<DbShopProfilePropertyPreview>;
  /** fetch data from the table in a streaming manner: "db.shop_profile_property_preview" */
  dbShopProfilePropertyPreviewStream: Array<DbShopProfilePropertyPreview>;
  /** fetch data from the table: "db.shop_profile_quote" */
  dbShopProfileQuote: Array<DbShopProfileQuote>;
  /** fetch aggregated fields from the table: "db.shop_profile_quote" */
  dbShopProfileQuoteAggregate: DbShopProfileQuoteAggregate;
  /** fetch data from the table: "db.shop_profile_quote" using primary key columns */
  dbShopProfileQuoteByPk?: Maybe<DbShopProfileQuote>;
  /** fetch data from the table in a streaming manner: "db.shop_profile_quote" */
  dbShopProfileQuoteStream: Array<DbShopProfileQuote>;
  /** fetch data from the table: "db.shop_profile_seo_tracking" */
  dbShopProfileSeoTracking: Array<DbShopProfileSeoTracking>;
  /** fetch data from the table: "db.shop_profile_seo_tracking" using primary key columns */
  dbShopProfileSeoTrackingByPk?: Maybe<DbShopProfileSeoTracking>;
  /** fetch data from the table in a streaming manner: "db.shop_profile_seo_tracking" */
  dbShopProfileSeoTrackingStream: Array<DbShopProfileSeoTracking>;
  /** fetch data from the table: "db.shop_profile_social_media_profiles" */
  dbShopProfileSocialMediaProfiles: Array<DbShopProfileSocialMediaProfiles>;
  /** fetch aggregated fields from the table: "db.shop_profile_social_media_profiles" */
  dbShopProfileSocialMediaProfilesAggregate: DbShopProfileSocialMediaProfilesAggregate;
  /** fetch data from the table: "db.shop_profile_social_media_profiles" using primary key columns */
  dbShopProfileSocialMediaProfilesByPk?: Maybe<DbShopProfileSocialMediaProfiles>;
  /** fetch data from the table in a streaming manner: "db.shop_profile_social_media_profiles" */
  dbShopProfileSocialMediaProfilesStream: Array<DbShopProfileSocialMediaProfiles>;
  /** fetch data from the table: "db.shop_profile_spoken_language" */
  dbShopProfileSpokenLanguage: Array<DbShopProfileSpokenLanguage>;
  /** fetch aggregated fields from the table: "db.shop_profile_spoken_language" */
  dbShopProfileSpokenLanguageAggregate: DbShopProfileSpokenLanguageAggregate;
  /** fetch data from the table: "db.shop_profile_spoken_language" using primary key columns */
  dbShopProfileSpokenLanguageByPk?: Maybe<DbShopProfileSpokenLanguage>;
  /** fetch data from the table in a streaming manner: "db.shop_profile_spoken_language" */
  dbShopProfileSpokenLanguageStream: Array<DbShopProfileSpokenLanguage>;
  /** fetch data from the table in a streaming manner: "db.shop_profile" */
  dbShopProfileStream: Array<DbShopProfile>;
  /** fetch data from the table: "db.shop_profile_team" */
  dbShopProfileTeam: Array<DbShopProfileTeam>;
  /** fetch data from the table: "db.shop_profile_team" using primary key columns */
  dbShopProfileTeamByPk?: Maybe<DbShopProfileTeam>;
  /** fetch data from the table in a streaming manner: "db.shop_profile_team" */
  dbShopProfileTeamStream: Array<DbShopProfileTeam>;
  /** fetch data from the table: "db.shop_profile_terms_and_conditions" */
  dbShopProfileTermsAndConditions: Array<DbShopProfileTermsAndConditions>;
  /** fetch data from the table: "db.shop_profile_terms_and_conditions" using primary key columns */
  dbShopProfileTermsAndConditionsByPk?: Maybe<DbShopProfileTermsAndConditions>;
  /** fetch data from the table in a streaming manner: "db.shop_profile_terms_and_conditions" */
  dbShopProfileTermsAndConditionsStream: Array<DbShopProfileTermsAndConditions>;
  /** fetch data from the table: "db.shop_profile_withdrawal_policy" */
  dbShopProfileWithdrawalPolicy: Array<DbShopProfileWithdrawalPolicy>;
  /** fetch data from the table: "db.shop_profile_withdrawal_policy" using primary key columns */
  dbShopProfileWithdrawalPolicyByPk?: Maybe<DbShopProfileWithdrawalPolicy>;
  /** fetch data from the table in a streaming manner: "db.shop_profile_withdrawal_policy" */
  dbShopProfileWithdrawalPolicyStream: Array<DbShopProfileWithdrawalPolicy>;
  /** fetch data from the table: "db.shop_right_of_withdrawal" */
  dbShopRightOfWithdrawal: Array<DbShopRightOfWithdrawal>;
  /** fetch data from the table: "db.shop_right_of_withdrawal" using primary key columns */
  dbShopRightOfWithdrawalByPk?: Maybe<DbShopRightOfWithdrawal>;
  /** fetch data from the table in a streaming manner: "db.shop_right_of_withdrawal" */
  dbShopRightOfWithdrawalStream: Array<DbShopRightOfWithdrawal>;
  /** fetch data from the table: "db.shop_setting" */
  dbShopSetting: Array<DbShopSetting>;
  /** fetch aggregated fields from the table: "db.shop_setting" */
  dbShopSettingAggregate: DbShopSettingAggregate;
  /** fetch data from the table: "db.shop_setting" using primary key columns */
  dbShopSettingByPk?: Maybe<DbShopSetting>;
  /** fetch data from the table in a streaming manner: "db.shop_setting" */
  dbShopSettingStream: Array<DbShopSetting>;
  /** fetch data from the table in a streaming manner: "db.shop" */
  dbShopStream: Array<DbShop>;
  /** fetch data from the table: "db.snapshot_buyer_to_rent_specification" */
  dbSnapshotBuyerToRentSpecification: Array<DbSnapshotBuyerToRentSpecification>;
  /** fetch aggregated fields from the table: "db.snapshot_buyer_to_rent_specification" */
  dbSnapshotBuyerToRentSpecificationAggregate: DbSnapshotBuyerToRentSpecificationAggregate;
  /** fetch data from the table: "db.snapshot_buyer_to_rent_specification" using primary key columns */
  dbSnapshotBuyerToRentSpecificationByPk?: Maybe<DbSnapshotBuyerToRentSpecification>;
  /** fetch data from the table in a streaming manner: "db.snapshot_buyer_to_rent_specification" */
  dbSnapshotBuyerToRentSpecificationStream: Array<DbSnapshotBuyerToRentSpecification>;
  /** fetch data from the table: "db.snapshot_custom_search_demand_polygon" */
  dbSnapshotCustomSearchDemandPolygon: Array<DbSnapshotCustomSearchDemandPolygon>;
  /** fetch data from the table: "db.snapshot_custom_search_demand_polygon" using primary key columns */
  dbSnapshotCustomSearchDemandPolygonByPk?: Maybe<DbSnapshotCustomSearchDemandPolygon>;
  /** fetch data from the table in a streaming manner: "db.snapshot_custom_search_demand_polygon" */
  dbSnapshotCustomSearchDemandPolygonStream: Array<DbSnapshotCustomSearchDemandPolygon>;
  /** fetch data from the table: "db.snapshot_seller_rent_out_specification" */
  dbSnapshotSellerRentOutSpecification: Array<DbSnapshotSellerRentOutSpecification>;
  /** fetch aggregated fields from the table: "db.snapshot_seller_rent_out_specification" */
  dbSnapshotSellerRentOutSpecificationAggregate: DbSnapshotSellerRentOutSpecificationAggregate;
  /** fetch data from the table: "db.snapshot_seller_rent_out_specification" using primary key columns */
  dbSnapshotSellerRentOutSpecificationByPk?: Maybe<DbSnapshotSellerRentOutSpecification>;
  /** fetch data from the table in a streaming manner: "db.snapshot_seller_rent_out_specification" */
  dbSnapshotSellerRentOutSpecificationStream: Array<DbSnapshotSellerRentOutSpecification>;
  /** fetch data from the table: "db.tag" */
  dbTag: Array<DbTag>;
  /** fetch data from the table: "db.tag" using primary key columns */
  dbTagByPk?: Maybe<DbTag>;
  /** fetch data from the table in a streaming manner: "db.tag" */
  dbTagStream: Array<DbTag>;
  /** fetch data from the table: "db.task_activity" */
  dbTaskActivity: Array<DbTaskActivity>;
  /** fetch data from the table: "db.task_activity" using primary key columns */
  dbTaskActivityByPk?: Maybe<DbTaskActivity>;
  /** fetch data from the table in a streaming manner: "db.task_activity" */
  dbTaskActivityStream: Array<DbTaskActivity>;
  /** fetch data from the table: "db.task_agent" */
  dbTaskAgent: Array<DbTaskAgent>;
  /** fetch data from the table in a streaming manner: "db.task_agent" */
  dbTaskAgentStream: Array<DbTaskAgent>;
  /** fetch data from the table: "db.task_contact" */
  dbTaskContact: Array<DbTaskContact>;
  /** fetch data from the table in a streaming manner: "db.task_contact" */
  dbTaskContactStream: Array<DbTaskContact>;
  /** fetch data from the table: "db.task_lead" */
  dbTaskLead: Array<DbTaskLead>;
  /** fetch data from the table in a streaming manner: "db.task_lead" */
  dbTaskLeadStream: Array<DbTaskLead>;
  /** fetch data from the table: "db.team" */
  dbTeam: Array<DbTeam>;
  /** fetch aggregated fields from the table: "db.team" */
  dbTeamAggregate: DbTeamAggregate;
  /** fetch data from the table: "db.team" using primary key columns */
  dbTeamByPk?: Maybe<DbTeam>;
  /** fetch data from the table in a streaming manner: "db.team" */
  dbTeamStream: Array<DbTeam>;
  /** fetch data from the table: "db.tracking_lead" */
  dbTrackingLead: Array<DbTrackingLead>;
  /** fetch aggregated fields from the table: "db.tracking_lead" */
  dbTrackingLeadAggregate: DbTrackingLeadAggregate;
  /** fetch data from the table: "db.tracking_lead" using primary key columns */
  dbTrackingLeadByPk?: Maybe<DbTrackingLead>;
  /** fetch data from the table in a streaming manner: "db.tracking_lead" */
  dbTrackingLeadStream: Array<DbTrackingLead>;
  /** fetch data from the table: "db.twilio_configuration" */
  dbTwilioConfiguration: Array<DbTwilioConfiguration>;
  /** fetch aggregated fields from the table: "db.twilio_configuration" */
  dbTwilioConfigurationAggregate: DbTwilioConfigurationAggregate;
  /** fetch data from the table: "db.twilio_configuration" using primary key columns */
  dbTwilioConfigurationByPk?: Maybe<DbTwilioConfiguration>;
  /** fetch data from the table in a streaming manner: "db.twilio_configuration" */
  dbTwilioConfigurationStream: Array<DbTwilioConfiguration>;
  /** fetch data from the table: "db.user_admin_assignment" */
  dbUserAdminAssignment: Array<DbUserAdminAssignment>;
  /** fetch aggregated fields from the table: "db.user_admin_assignment" */
  dbUserAdminAssignmentAggregate: DbUserAdminAssignmentAggregate;
  /** fetch data from the table: "db.user_admin_assignment" using primary key columns */
  dbUserAdminAssignmentByPk?: Maybe<DbUserAdminAssignment>;
  /** fetch data from the table in a streaming manner: "db.user_admin_assignment" */
  dbUserAdminAssignmentStream: Array<DbUserAdminAssignment>;
  /** fetch data from the table: "db.user_shop_assignment" */
  dbUserShopAssignment: Array<DbUserShopAssignment>;
  /** fetch aggregated fields from the table: "db.user_shop_assignment" */
  dbUserShopAssignmentAggregate: DbUserShopAssignmentAggregate;
  /** fetch data from the table: "db.user_shop_assignment" using primary key columns */
  dbUserShopAssignmentByPk?: Maybe<DbUserShopAssignment>;
  /** fetch data from the table in a streaming manner: "db.user_shop_assignment" */
  dbUserShopAssignmentStream: Array<DbUserShopAssignment>;
  /** fetch data from the table: "db.user_team_assignment" */
  dbUserTeamAssignment: Array<DbUserTeamAssignment>;
  /** fetch aggregated fields from the table: "db.user_team_assignment" */
  dbUserTeamAssignmentAggregate: DbUserTeamAssignmentAggregate;
  /** fetch data from the table: "db.user_team_assignment" using primary key columns */
  dbUserTeamAssignmentByPk?: Maybe<DbUserTeamAssignment>;
  /** fetch data from the table in a streaming manner: "db.user_team_assignment" */
  dbUserTeamAssignmentStream: Array<DbUserTeamAssignment>;
  /** fetch data from the table: "db.utm_param" */
  dbUtmParam: Array<DbUtmParam>;
  /** fetch data from the table: "db.utm_param" using primary key columns */
  dbUtmParamByPk?: Maybe<DbUtmParam>;
  /** fetch data from the table in a streaming manner: "db.utm_param" */
  dbUtmParamStream: Array<DbUtmParam>;
  /** fetch data from the table: "enums.activity_type" */
  enumsActivityType: Array<EnumsActivityType>;
  /** fetch data from the table: "enums.activity_type" using primary key columns */
  enumsActivityTypeByPk?: Maybe<EnumsActivityType>;
  /** fetch data from the table in a streaming manner: "enums.activity_type" */
  enumsActivityTypeStream: Array<EnumsActivityType>;
  /** fetch data from the table: "enums.address_format" */
  enumsAddressFormat: Array<EnumsAddressFormat>;
  /** fetch data from the table: "enums.address_format" using primary key columns */
  enumsAddressFormatByPk?: Maybe<EnumsAddressFormat>;
  /** fetch data from the table in a streaming manner: "enums.address_format" */
  enumsAddressFormatStream: Array<EnumsAddressFormat>;
  /** fetch data from the table: "enums.address_type" */
  enumsAddressType: Array<EnumsAddressType>;
  /** fetch data from the table: "enums.address_type" using primary key columns */
  enumsAddressTypeByPk?: Maybe<EnumsAddressType>;
  /** fetch data from the table in a streaming manner: "enums.address_type" */
  enumsAddressTypeStream: Array<EnumsAddressType>;
  /** fetch data from the table: "enums.appointment_type" */
  enumsAppointmentType: Array<EnumsAppointmentType>;
  /** fetch data from the table: "enums.appointment_type" using primary key columns */
  enumsAppointmentTypeByPk?: Maybe<EnumsAppointmentType>;
  /** fetch data from the table in a streaming manner: "enums.appointment_type" */
  enumsAppointmentTypeStream: Array<EnumsAppointmentType>;
  /** fetch data from the table: "enums.asset_condition" */
  enumsAssetCondition: Array<EnumsAssetCondition>;
  /** fetch data from the table: "enums.asset_condition" using primary key columns */
  enumsAssetConditionByPk?: Maybe<EnumsAssetCondition>;
  /** fetch data from the table in a streaming manner: "enums.asset_condition" */
  enumsAssetConditionStream: Array<EnumsAssetCondition>;
  /** fetch data from the table: "enums.asset_sub_type" */
  enumsAssetSubType: Array<EnumsAssetSubType>;
  /** fetch data from the table: "enums.asset_sub_type" using primary key columns */
  enumsAssetSubTypeByPk?: Maybe<EnumsAssetSubType>;
  /** fetch data from the table in a streaming manner: "enums.asset_sub_type" */
  enumsAssetSubTypeStream: Array<EnumsAssetSubType>;
  /** fetch data from the table: "enums.asset_type" */
  enumsAssetType: Array<EnumsAssetType>;
  /** fetch data from the table: "enums.asset_type" using primary key columns */
  enumsAssetTypeByPk?: Maybe<EnumsAssetType>;
  /** fetch data from the table in a streaming manner: "enums.asset_type" */
  enumsAssetTypeStream: Array<EnumsAssetType>;
  /** fetch data from the table: "enums.asset_variation" */
  enumsAssetVariation: Array<EnumsAssetVariation>;
  /** fetch data from the table: "enums.asset_variation" using primary key columns */
  enumsAssetVariationByPk?: Maybe<EnumsAssetVariation>;
  /** fetch data from the table in a streaming manner: "enums.asset_variation" */
  enumsAssetVariationStream: Array<EnumsAssetVariation>;
  /** fetch data from the table: "enums.base_activity_category" */
  enumsBaseActivityCategory: Array<EnumsBaseActivityCategory>;
  /** fetch data from the table: "enums.base_activity_category" using primary key columns */
  enumsBaseActivityCategoryByPk?: Maybe<EnumsBaseActivityCategory>;
  /** fetch data from the table in a streaming manner: "enums.base_activity_category" */
  enumsBaseActivityCategoryStream: Array<EnumsBaseActivityCategory>;
  /** fetch data from the table: "enums.call_direction" */
  enumsCallDirection: Array<EnumsCallDirection>;
  /** fetch data from the table: "enums.call_direction" using primary key columns */
  enumsCallDirectionByPk?: Maybe<EnumsCallDirection>;
  /** fetch data from the table in a streaming manner: "enums.call_direction" */
  enumsCallDirectionStream: Array<EnumsCallDirection>;
  /** fetch data from the table: "enums.call_outcome" */
  enumsCallOutcome: Array<EnumsCallOutcome>;
  /** fetch data from the table: "enums.call_outcome" using primary key columns */
  enumsCallOutcomeByPk?: Maybe<EnumsCallOutcome>;
  /** fetch data from the table in a streaming manner: "enums.call_outcome" */
  enumsCallOutcomeStream: Array<EnumsCallOutcome>;
  /** fetch data from the table: "enums.call_status" */
  enumsCallStatus: Array<EnumsCallStatus>;
  /** fetch data from the table: "enums.call_status" using primary key columns */
  enumsCallStatusByPk?: Maybe<EnumsCallStatus>;
  /** fetch data from the table in a streaming manner: "enums.call_status" */
  enumsCallStatusStream: Array<EnumsCallStatus>;
  /** fetch data from the table: "enums.consent_event_status" */
  enumsConsentEventStatus: Array<EnumsConsentEventStatus>;
  /** fetch data from the table: "enums.consent_event_status" using primary key columns */
  enumsConsentEventStatusByPk?: Maybe<EnumsConsentEventStatus>;
  /** fetch data from the table in a streaming manner: "enums.consent_event_status" */
  enumsConsentEventStatusStream: Array<EnumsConsentEventStatus>;
  /** fetch data from the table: "enums.consent_event_type" */
  enumsConsentEventType: Array<EnumsConsentEventType>;
  /** fetch data from the table: "enums.consent_event_type" using primary key columns */
  enumsConsentEventTypeByPk?: Maybe<EnumsConsentEventType>;
  /** fetch data from the table in a streaming manner: "enums.consent_event_type" */
  enumsConsentEventTypeStream: Array<EnumsConsentEventType>;
  /** fetch data from the table: "enums.consent_source" */
  enumsConsentSource: Array<EnumsConsentSource>;
  /** fetch data from the table: "enums.consent_source" using primary key columns */
  enumsConsentSourceByPk?: Maybe<EnumsConsentSource>;
  /** fetch data from the table in a streaming manner: "enums.consent_source" */
  enumsConsentSourceStream: Array<EnumsConsentSource>;
  /** fetch data from the table: "enums.consent_status" */
  enumsConsentStatus: Array<EnumsConsentStatus>;
  /** fetch data from the table: "enums.consent_status" using primary key columns */
  enumsConsentStatusByPk?: Maybe<EnumsConsentStatus>;
  /** fetch data from the table in a streaming manner: "enums.consent_status" */
  enumsConsentStatusStream: Array<EnumsConsentStatus>;
  /** fetch data from the table: "enums.consent_template_type" */
  enumsConsentTemplateType: Array<EnumsConsentTemplateType>;
  /** fetch data from the table: "enums.consent_template_type" using primary key columns */
  enumsConsentTemplateTypeByPk?: Maybe<EnumsConsentTemplateType>;
  /** fetch data from the table in a streaming manner: "enums.consent_template_type" */
  enumsConsentTemplateTypeStream: Array<EnumsConsentTemplateType>;
  /** fetch data from the table: "enums.consent_type" */
  enumsConsentType: Array<EnumsConsentType>;
  /** fetch data from the table: "enums.consent_type" using primary key columns */
  enumsConsentTypeByPk?: Maybe<EnumsConsentType>;
  /** fetch data from the table in a streaming manner: "enums.consent_type" */
  enumsConsentTypeStream: Array<EnumsConsentType>;
  /** fetch data from the table: "enums.contact_relation_type" */
  enumsContactRelationType: Array<EnumsContactRelationType>;
  /** fetch data from the table: "enums.contact_relation_type" using primary key columns */
  enumsContactRelationTypeByPk?: Maybe<EnumsContactRelationType>;
  /** fetch data from the table in a streaming manner: "enums.contact_relation_type" */
  enumsContactRelationTypeStream: Array<EnumsContactRelationType>;
  /** fetch data from the table: "enums.contact_status" */
  enumsContactStatus: Array<EnumsContactStatus>;
  /** fetch data from the table: "enums.contact_status" using primary key columns */
  enumsContactStatusByPk?: Maybe<EnumsContactStatus>;
  /** fetch data from the table in a streaming manner: "enums.contact_status" */
  enumsContactStatusStream: Array<EnumsContactStatus>;
  /** fetch data from the table: "enums.contact_time" */
  enumsContactTime: Array<EnumsContactTime>;
  /** fetch data from the table: "enums.contact_time" using primary key columns */
  enumsContactTimeByPk?: Maybe<EnumsContactTime>;
  /** fetch data from the table in a streaming manner: "enums.contact_time" */
  enumsContactTimeStream: Array<EnumsContactTime>;
  /** fetch data from the table: "enums.contact_type" */
  enumsContactType: Array<EnumsContactType>;
  /** fetch data from the table: "enums.contact_type" using primary key columns */
  enumsContactTypeByPk?: Maybe<EnumsContactType>;
  /** fetch data from the table in a streaming manner: "enums.contact_type" */
  enumsContactTypeStream: Array<EnumsContactType>;
  /** fetch data from the table: "enums.contact_way" */
  enumsContactWay: Array<EnumsContactWay>;
  /** fetch data from the table: "enums.contact_way" using primary key columns */
  enumsContactWayByPk?: Maybe<EnumsContactWay>;
  /** fetch data from the table in a streaming manner: "enums.contact_way" */
  enumsContactWayStream: Array<EnumsContactWay>;
  /** fetch data from the table: "enums.country" */
  enumsCountry: Array<EnumsCountry>;
  /** fetch data from the table: "enums.country" using primary key columns */
  enumsCountryByPk?: Maybe<EnumsCountry>;
  /** fetch data from the table in a streaming manner: "enums.country" */
  enumsCountryStream: Array<EnumsCountry>;
  /** fetch data from the table: "enums.currency" */
  enumsCurrency: Array<EnumsCurrency>;
  /** fetch data from the table: "enums.currency" using primary key columns */
  enumsCurrencyByPk?: Maybe<EnumsCurrency>;
  /** fetch data from the table in a streaming manner: "enums.currency" */
  enumsCurrencyStream: Array<EnumsCurrency>;
  /** fetch data from the table: "enums.custom_view_type" */
  enumsCustomViewType: Array<EnumsCustomViewType>;
  /** fetch data from the table: "enums.custom_view_type" using primary key columns */
  enumsCustomViewTypeByPk?: Maybe<EnumsCustomViewType>;
  /** fetch data from the table in a streaming manner: "enums.custom_view_type" */
  enumsCustomViewTypeStream: Array<EnumsCustomViewType>;
  /** fetch data from the table: "enums.email_address_type" */
  enumsEmailAddressType: Array<EnumsEmailAddressType>;
  /** fetch data from the table: "enums.email_address_type" using primary key columns */
  enumsEmailAddressTypeByPk?: Maybe<EnumsEmailAddressType>;
  /** fetch data from the table in a streaming manner: "enums.email_address_type" */
  enumsEmailAddressTypeStream: Array<EnumsEmailAddressType>;
  /** fetch data from the table: "enums.email_callback_event_source" */
  enumsEmailCallbackEventSource: Array<EnumsEmailCallbackEventSource>;
  /** fetch data from the table: "enums.email_callback_event_source" using primary key columns */
  enumsEmailCallbackEventSourceByPk?: Maybe<EnumsEmailCallbackEventSource>;
  /** fetch data from the table in a streaming manner: "enums.email_callback_event_source" */
  enumsEmailCallbackEventSourceStream: Array<EnumsEmailCallbackEventSource>;
  /** fetch data from the table: "enums.email_direction" */
  enumsEmailDirection: Array<EnumsEmailDirection>;
  /** fetch data from the table: "enums.email_direction" using primary key columns */
  enumsEmailDirectionByPk?: Maybe<EnumsEmailDirection>;
  /** fetch data from the table in a streaming manner: "enums.email_direction" */
  enumsEmailDirectionStream: Array<EnumsEmailDirection>;
  /** fetch data from the table: "enums.email_integration_scope" */
  enumsEmailIntegrationScope: Array<EnumsEmailIntegrationScope>;
  /** fetch data from the table: "enums.email_integration_scope" using primary key columns */
  enumsEmailIntegrationScopeByPk?: Maybe<EnumsEmailIntegrationScope>;
  /** fetch data from the table in a streaming manner: "enums.email_integration_scope" */
  enumsEmailIntegrationScopeStream: Array<EnumsEmailIntegrationScope>;
  /** fetch data from the table: "enums.email_placeholder_scope" */
  enumsEmailPlaceholderScope: Array<EnumsEmailPlaceholderScope>;
  /** fetch data from the table: "enums.email_placeholder_scope" using primary key columns */
  enumsEmailPlaceholderScopeByPk?: Maybe<EnumsEmailPlaceholderScope>;
  /** fetch data from the table in a streaming manner: "enums.email_placeholder_scope" */
  enumsEmailPlaceholderScopeStream: Array<EnumsEmailPlaceholderScope>;
  /** fetch data from the table: "enums.email_placeholder_type" */
  enumsEmailPlaceholderType: Array<EnumsEmailPlaceholderType>;
  /** fetch data from the table: "enums.email_placeholder_type" using primary key columns */
  enumsEmailPlaceholderTypeByPk?: Maybe<EnumsEmailPlaceholderType>;
  /** fetch data from the table in a streaming manner: "enums.email_placeholder_type" */
  enumsEmailPlaceholderTypeStream: Array<EnumsEmailPlaceholderType>;
  /** fetch data from the table: "enums.email_signature_scope" */
  enumsEmailSignatureScope: Array<EnumsEmailSignatureScope>;
  /** fetch data from the table: "enums.email_signature_scope" using primary key columns */
  enumsEmailSignatureScopeByPk?: Maybe<EnumsEmailSignatureScope>;
  /** fetch data from the table in a streaming manner: "enums.email_signature_scope" */
  enumsEmailSignatureScopeStream: Array<EnumsEmailSignatureScope>;
  /** fetch data from the table: "enums.email_status" */
  enumsEmailStatus: Array<EnumsEmailStatus>;
  /** fetch data from the table: "enums.email_status" using primary key columns */
  enumsEmailStatusByPk?: Maybe<EnumsEmailStatus>;
  /** fetch data from the table in a streaming manner: "enums.email_status" */
  enumsEmailStatusStream: Array<EnumsEmailStatus>;
  /** fetch data from the table: "enums.email_template_scope" */
  enumsEmailTemplateScope: Array<EnumsEmailTemplateScope>;
  /** fetch data from the table: "enums.email_template_scope" using primary key columns */
  enumsEmailTemplateScopeByPk?: Maybe<EnumsEmailTemplateScope>;
  /** fetch data from the table in a streaming manner: "enums.email_template_scope" */
  enumsEmailTemplateScopeStream: Array<EnumsEmailTemplateScope>;
  /** fetch data from the table: "enums.email_template_type" */
  enumsEmailTemplateType: Array<EnumsEmailTemplateType>;
  /** fetch data from the table: "enums.email_template_type" using primary key columns */
  enumsEmailTemplateTypeByPk?: Maybe<EnumsEmailTemplateType>;
  /** fetch data from the table in a streaming manner: "enums.email_template_type" */
  enumsEmailTemplateTypeStream: Array<EnumsEmailTemplateType>;
  /** fetch data from the table: "enums.email_tracking_type" */
  enumsEmailTrackingType: Array<EnumsEmailTrackingType>;
  /** fetch data from the table: "enums.email_tracking_type" using primary key columns */
  enumsEmailTrackingTypeByPk?: Maybe<EnumsEmailTrackingType>;
  /** fetch data from the table in a streaming manner: "enums.email_tracking_type" */
  enumsEmailTrackingTypeStream: Array<EnumsEmailTrackingType>;
  /** fetch data from the table: "enums.entity_type" */
  enumsEntityType: Array<EnumsEntityType>;
  /** fetch data from the table: "enums.entity_type" using primary key columns */
  enumsEntityTypeByPk?: Maybe<EnumsEntityType>;
  /** fetch data from the table in a streaming manner: "enums.entity_type" */
  enumsEntityTypeStream: Array<EnumsEntityType>;
  /** fetch data from the table: "enums.event_status" */
  enumsEventStatus: Array<EnumsEventStatus>;
  /** fetch data from the table: "enums.event_status" using primary key columns */
  enumsEventStatusByPk?: Maybe<EnumsEventStatus>;
  /** fetch data from the table in a streaming manner: "enums.event_status" */
  enumsEventStatusStream: Array<EnumsEventStatus>;
  /** fetch data from the table: "enums.feature_flags" */
  enumsFeatureFlags: Array<EnumsFeatureFlags>;
  /** fetch data from the table: "enums.feature_flags" using primary key columns */
  enumsFeatureFlagsByPk?: Maybe<EnumsFeatureFlags>;
  /** fetch data from the table in a streaming manner: "enums.feature_flags" */
  enumsFeatureFlagsStream: Array<EnumsFeatureFlags>;
  /** fetch data from the table: "enums.gender" */
  enumsGender: Array<EnumsGender>;
  /** fetch data from the table: "enums.gender" using primary key columns */
  enumsGenderByPk?: Maybe<EnumsGender>;
  /** fetch data from the table in a streaming manner: "enums.gender" */
  enumsGenderStream: Array<EnumsGender>;
  /** fetch data from the table: "enums.household_type" */
  enumsHouseholdType: Array<EnumsHouseholdType>;
  /** fetch data from the table: "enums.household_type" using primary key columns */
  enumsHouseholdTypeByPk?: Maybe<EnumsHouseholdType>;
  /** fetch data from the table in a streaming manner: "enums.household_type" */
  enumsHouseholdTypeStream: Array<EnumsHouseholdType>;
  /** fetch data from the table: "enums.import_lead_status" */
  enumsImportLeadStatus: Array<EnumsImportLeadStatus>;
  /** fetch data from the table: "enums.import_lead_status" using primary key columns */
  enumsImportLeadStatusByPk?: Maybe<EnumsImportLeadStatus>;
  /** fetch data from the table in a streaming manner: "enums.import_lead_status" */
  enumsImportLeadStatusStream: Array<EnumsImportLeadStatus>;
  /** fetch data from the table: "enums.intent_category" */
  enumsIntentCategory: Array<EnumsIntentCategory>;
  /** fetch data from the table: "enums.intent_category" using primary key columns */
  enumsIntentCategoryByPk?: Maybe<EnumsIntentCategory>;
  /** fetch data from the table in a streaming manner: "enums.intent_category" */
  enumsIntentCategoryStream: Array<EnumsIntentCategory>;
  /** fetch data from the table: "enums.intent_type" */
  enumsIntentType: Array<EnumsIntentType>;
  /** fetch data from the table: "enums.intent_type" using primary key columns */
  enumsIntentTypeByPk?: Maybe<EnumsIntentType>;
  /** fetch data from the table in a streaming manner: "enums.intent_type" */
  enumsIntentTypeStream: Array<EnumsIntentType>;
  /** fetch data from the table: "enums.language" */
  enumsLanguage: Array<EnumsLanguage>;
  /** fetch data from the table: "enums.language" using primary key columns */
  enumsLanguageByPk?: Maybe<EnumsLanguage>;
  /** fetch data from the table in a streaming manner: "enums.language" */
  enumsLanguageStream: Array<EnumsLanguage>;
  /** fetch data from the table: "enums.lead_auto_assignment_rules" */
  enumsLeadAutoAssignmentRules: Array<EnumsLeadAutoAssignmentRules>;
  /** fetch data from the table: "enums.lead_auto_assignment_rules" using primary key columns */
  enumsLeadAutoAssignmentRulesByPk?: Maybe<EnumsLeadAutoAssignmentRules>;
  /** fetch data from the table in a streaming manner: "enums.lead_auto_assignment_rules" */
  enumsLeadAutoAssignmentRulesStream: Array<EnumsLeadAutoAssignmentRules>;
  /** fetch data from the table: "enums.lead_source" */
  enumsLeadSource: Array<EnumsLeadSource>;
  /** fetch data from the table: "enums.lead_source" using primary key columns */
  enumsLeadSourceByPk?: Maybe<EnumsLeadSource>;
  /** fetch data from the table in a streaming manner: "enums.lead_source" */
  enumsLeadSourceStream: Array<EnumsLeadSource>;
  /** fetch data from the table: "enums.lead_status" */
  enumsLeadStatus: Array<EnumsLeadStatus>;
  /** fetch data from the table: "enums.lead_status" using primary key columns */
  enumsLeadStatusByPk?: Maybe<EnumsLeadStatus>;
  /** fetch data from the table: "enums.lead_status_reason" */
  enumsLeadStatusReason: Array<EnumsLeadStatusReason>;
  /** fetch data from the table: "enums.lead_status_reason" using primary key columns */
  enumsLeadStatusReasonByPk?: Maybe<EnumsLeadStatusReason>;
  /** fetch data from the table in a streaming manner: "enums.lead_status_reason" */
  enumsLeadStatusReasonStream: Array<EnumsLeadStatusReason>;
  /** fetch data from the table in a streaming manner: "enums.lead_status" */
  enumsLeadStatusStream: Array<EnumsLeadStatus>;
  /** fetch data from the table: "enums.legacy_go3_newsletter_type" */
  enumsLegacyGo3NewsletterType: Array<EnumsLegacyGo3NewsletterType>;
  /** fetch data from the table: "enums.legacy_go3_newsletter_type" using primary key columns */
  enumsLegacyGo3NewsletterTypeByPk?: Maybe<EnumsLegacyGo3NewsletterType>;
  /** fetch data from the table in a streaming manner: "enums.legacy_go3_newsletter_type" */
  enumsLegacyGo3NewsletterTypeStream: Array<EnumsLegacyGo3NewsletterType>;
  /** fetch data from the table: "enums.license_partner_status" */
  enumsLicensePartnerStatus: Array<EnumsLicensePartnerStatus>;
  /** fetch aggregated fields from the table: "enums.license_partner_status" */
  enumsLicensePartnerStatusAggregate: EnumsLicensePartnerStatusAggregate;
  /** fetch data from the table: "enums.license_partner_status" using primary key columns */
  enumsLicensePartnerStatusByPk?: Maybe<EnumsLicensePartnerStatus>;
  /** fetch data from the table in a streaming manner: "enums.license_partner_status" */
  enumsLicensePartnerStatusStream: Array<EnumsLicensePartnerStatus>;
  /** fetch data from the table: "enums.mandatory_agent_profile_field" */
  enumsMandatoryAgentProfileField: Array<EnumsMandatoryAgentProfileField>;
  /** fetch data from the table: "enums.mandatory_agent_profile_field" using primary key columns */
  enumsMandatoryAgentProfileFieldByPk?: Maybe<EnumsMandatoryAgentProfileField>;
  /** fetch data from the table in a streaming manner: "enums.mandatory_agent_profile_field" */
  enumsMandatoryAgentProfileFieldStream: Array<EnumsMandatoryAgentProfileField>;
  /** fetch data from the table: "enums.mandatory_shop_profile_field" */
  enumsMandatoryShopProfileField: Array<EnumsMandatoryShopProfileField>;
  /** fetch data from the table: "enums.mandatory_shop_profile_field" using primary key columns */
  enumsMandatoryShopProfileFieldByPk?: Maybe<EnumsMandatoryShopProfileField>;
  /** fetch data from the table in a streaming manner: "enums.mandatory_shop_profile_field" */
  enumsMandatoryShopProfileFieldStream: Array<EnumsMandatoryShopProfileField>;
  /** fetch data from the table: "enums.marketing_type" */
  enumsMarketingType: Array<EnumsMarketingType>;
  /** fetch data from the table: "enums.marketing_type" using primary key columns */
  enumsMarketingTypeByPk?: Maybe<EnumsMarketingType>;
  /** fetch data from the table in a streaming manner: "enums.marketing_type" */
  enumsMarketingTypeStream: Array<EnumsMarketingType>;
  /** fetch data from the table: "enums.phone_number_type" */
  enumsPhoneNumberType: Array<EnumsPhoneNumberType>;
  /** fetch data from the table: "enums.phone_number_type" using primary key columns */
  enumsPhoneNumberTypeByPk?: Maybe<EnumsPhoneNumberType>;
  /** fetch data from the table in a streaming manner: "enums.phone_number_type" */
  enumsPhoneNumberTypeStream: Array<EnumsPhoneNumberType>;
  /** fetch data from the table: "enums.priority" */
  enumsPriority: Array<EnumsPriority>;
  /** fetch data from the table: "enums.priority" using primary key columns */
  enumsPriorityByPk?: Maybe<EnumsPriority>;
  /** fetch data from the table in a streaming manner: "enums.priority" */
  enumsPriorityStream: Array<EnumsPriority>;
  /** fetch data from the table: "enums.privacy_level" */
  enumsPrivacyLevel: Array<EnumsPrivacyLevel>;
  /** fetch data from the table: "enums.privacy_level" using primary key columns */
  enumsPrivacyLevelByPk?: Maybe<EnumsPrivacyLevel>;
  /** fetch data from the table in a streaming manner: "enums.privacy_level" */
  enumsPrivacyLevelStream: Array<EnumsPrivacyLevel>;
  /** fetch data from the table: "enums.profession_type" */
  enumsProfessionType: Array<EnumsProfessionType>;
  /** fetch data from the table: "enums.profession_type" using primary key columns */
  enumsProfessionTypeByPk?: Maybe<EnumsProfessionType>;
  /** fetch data from the table in a streaming manner: "enums.profession_type" */
  enumsProfessionTypeStream: Array<EnumsProfessionType>;
  /** fetch data from the table: "enums.property_occupation_status" */
  enumsPropertyOccupationStatus: Array<EnumsPropertyOccupationStatus>;
  /** fetch data from the table: "enums.property_occupation_status" using primary key columns */
  enumsPropertyOccupationStatusByPk?: Maybe<EnumsPropertyOccupationStatus>;
  /** fetch data from the table in a streaming manner: "enums.property_occupation_status" */
  enumsPropertyOccupationStatusStream: Array<EnumsPropertyOccupationStatus>;
  /** fetch data from the table: "enums.property_ownership_status" */
  enumsPropertyOwnershipStatus: Array<EnumsPropertyOwnershipStatus>;
  /** fetch data from the table: "enums.property_ownership_status" using primary key columns */
  enumsPropertyOwnershipStatusByPk?: Maybe<EnumsPropertyOwnershipStatus>;
  /** fetch data from the table in a streaming manner: "enums.property_ownership_status" */
  enumsPropertyOwnershipStatusStream: Array<EnumsPropertyOwnershipStatus>;
  /** fetch data from the table: "enums.qualification_form_status" */
  enumsQualificationFormStatus: Array<EnumsQualificationFormStatus>;
  /** fetch data from the table: "enums.qualification_form_status" using primary key columns */
  enumsQualificationFormStatusByPk?: Maybe<EnumsQualificationFormStatus>;
  /** fetch data from the table in a streaming manner: "enums.qualification_form_status" */
  enumsQualificationFormStatusStream: Array<EnumsQualificationFormStatus>;
  /** fetch data from the table: "enums.qualiform_fields" */
  enumsQualiformFields: Array<EnumsQualiformFields>;
  /** fetch data from the table: "enums.qualiform_fields" using primary key columns */
  enumsQualiformFieldsByPk?: Maybe<EnumsQualiformFields>;
  /** fetch data from the table in a streaming manner: "enums.qualiform_fields" */
  enumsQualiformFieldsStream: Array<EnumsQualiformFields>;
  /** fetch data from the table: "enums.referral_not_possible_reason" */
  enumsReferralNotPossibleReason: Array<EnumsReferralNotPossibleReason>;
  /** fetch data from the table: "enums.referral_not_possible_reason" using primary key columns */
  enumsReferralNotPossibleReasonByPk?: Maybe<EnumsReferralNotPossibleReason>;
  /** fetch data from the table in a streaming manner: "enums.referral_not_possible_reason" */
  enumsReferralNotPossibleReasonStream: Array<EnumsReferralNotPossibleReason>;
  /** fetch data from the table: "enums.salutation" */
  enumsSalutation: Array<EnumsSalutation>;
  /** fetch data from the table: "enums.salutation" using primary key columns */
  enumsSalutationByPk?: Maybe<EnumsSalutation>;
  /** fetch data from the table in a streaming manner: "enums.salutation" */
  enumsSalutationStream: Array<EnumsSalutation>;
  /** fetch data from the table: "enums.session_status" */
  enumsSessionStatus: Array<EnumsSessionStatus>;
  /** fetch data from the table: "enums.session_status" using primary key columns */
  enumsSessionStatusByPk?: Maybe<EnumsSessionStatus>;
  /** fetch data from the table in a streaming manner: "enums.session_status" */
  enumsSessionStatusStream: Array<EnumsSessionStatus>;
  /** fetch data from the table: "enums.shared_type" */
  enumsSharedType: Array<EnumsSharedType>;
  /** fetch data from the table: "enums.shared_type" using primary key columns */
  enumsSharedTypeByPk?: Maybe<EnumsSharedType>;
  /** fetch data from the table in a streaming manner: "enums.shared_type" */
  enumsSharedTypeStream: Array<EnumsSharedType>;
  /** fetch data from the table: "enums.shop_expertise" */
  enumsShopExpertise: Array<EnumsShopExpertise>;
  /** fetch data from the table: "enums.shop_expertise" using primary key columns */
  enumsShopExpertiseByPk?: Maybe<EnumsShopExpertise>;
  /** fetch data from the table in a streaming manner: "enums.shop_expertise" */
  enumsShopExpertiseStream: Array<EnumsShopExpertise>;
  /** fetch data from the table: "enums.shop_status" */
  enumsShopStatus: Array<EnumsShopStatus>;
  /** fetch data from the table: "enums.shop_status" using primary key columns */
  enumsShopStatusByPk?: Maybe<EnumsShopStatus>;
  /** fetch data from the table in a streaming manner: "enums.shop_status" */
  enumsShopStatusStream: Array<EnumsShopStatus>;
  /** fetch data from the table: "enums.spoken_language" */
  enumsSpokenLanguage: Array<EnumsSpokenLanguage>;
  /** fetch data from the table: "enums.spoken_language" using primary key columns */
  enumsSpokenLanguageByPk?: Maybe<EnumsSpokenLanguage>;
  /** fetch data from the table in a streaming manner: "enums.spoken_language" */
  enumsSpokenLanguageStream: Array<EnumsSpokenLanguage>;
  /** fetch data from the table: "enums.task_appointment_status" */
  enumsTaskAppointmentStatus: Array<EnumsTaskAppointmentStatus>;
  /** fetch data from the table: "enums.task_appointment_status" using primary key columns */
  enumsTaskAppointmentStatusByPk?: Maybe<EnumsTaskAppointmentStatus>;
  /** fetch data from the table in a streaming manner: "enums.task_appointment_status" */
  enumsTaskAppointmentStatusStream: Array<EnumsTaskAppointmentStatus>;
  /** fetch data from the table: "enums.task_type" */
  enumsTaskType: Array<EnumsTaskType>;
  /** fetch data from the table: "enums.task_type" using primary key columns */
  enumsTaskTypeByPk?: Maybe<EnumsTaskType>;
  /** fetch data from the table in a streaming manner: "enums.task_type" */
  enumsTaskTypeStream: Array<EnumsTaskType>;
  /** fetch data from the table: "enums.team_status" */
  enumsTeamStatus: Array<EnumsTeamStatus>;
  /** fetch data from the table: "enums.team_status" using primary key columns */
  enumsTeamStatusByPk?: Maybe<EnumsTeamStatus>;
  /** fetch data from the table in a streaming manner: "enums.team_status" */
  enumsTeamStatusStream: Array<EnumsTeamStatus>;
  /** fetch data from the table: "enums.time_frame" */
  enumsTimeFrame: Array<EnumsTimeFrame>;
  /** fetch data from the table: "enums.time_frame" using primary key columns */
  enumsTimeFrameByPk?: Maybe<EnumsTimeFrame>;
  /** fetch data from the table in a streaming manner: "enums.time_frame" */
  enumsTimeFrameStream: Array<EnumsTimeFrame>;
  /** fetch data from the table: "enums.user_role" */
  enumsUserRole: Array<EnumsUserRole>;
  /** fetch data from the table: "enums.user_role" using primary key columns */
  enumsUserRoleByPk?: Maybe<EnumsUserRole>;
  /** fetch data from the table in a streaming manner: "enums.user_role" */
  enumsUserRoleStream: Array<EnumsUserRole>;
  /** fetch data from the table: "enums.zero_commission_reason" */
  enumsZeroCommissionReason: Array<EnumsZeroCommissionReason>;
  /** fetch data from the table: "enums.zero_commission_reason" using primary key columns */
  enumsZeroCommissionReasonByPk?: Maybe<EnumsZeroCommissionReason>;
  /** fetch data from the table in a streaming manner: "enums.zero_commission_reason" */
  enumsZeroCommissionReasonStream: Array<EnumsZeroCommissionReason>;
  /** execute function "extensions.search_contacts" which returns "db.contact" */
  extensionsSearchContacts: Array<DbContact>;
  /** execute function "extensions.search_contacts" and query aggregates on result of table type "db.contact" */
  extensionsSearchContactsAggregate: DbContactAggregate;
  propertyEngine?: Maybe<PropertyEngineSubscription>;
};

export type SubscriptionRootDbActivityTypeGroupingArgs = {
  distinctOn?: InputMaybe<Array<DbActivityTypeGroupingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbActivityTypeGroupingOrderBy>>;
  where?: InputMaybe<DbActivityTypeGroupingBoolExp>;
};

export type SubscriptionRootDbActivityTypeGroupingByPkArgs = {
  activityType: ActivityTypeEnum;
};

export type SubscriptionRootDbActivityTypeGroupingStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbActivityTypeGroupingStreamCursorInput>>;
  where?: InputMaybe<DbActivityTypeGroupingBoolExp>;
};

export type SubscriptionRootDbAgentArgs = {
  distinctOn?: InputMaybe<Array<DbAgentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentOrderBy>>;
  where?: InputMaybe<DbAgentBoolExp>;
};

export type SubscriptionRootDbAgentAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentOrderBy>>;
  where?: InputMaybe<DbAgentBoolExp>;
};

export type SubscriptionRootDbAgentByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbAgentDefaultEmailSignatureArgs = {
  distinctOn?: InputMaybe<Array<DbAgentDefaultEmailSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentDefaultEmailSignatureOrderBy>>;
  where?: InputMaybe<DbAgentDefaultEmailSignatureBoolExp>;
};

export type SubscriptionRootDbAgentDefaultEmailSignatureAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentDefaultEmailSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentDefaultEmailSignatureOrderBy>>;
  where?: InputMaybe<DbAgentDefaultEmailSignatureBoolExp>;
};

export type SubscriptionRootDbAgentDefaultEmailSignatureByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbAgentDefaultEmailSignatureStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbAgentDefaultEmailSignatureStreamCursorInput>>;
  where?: InputMaybe<DbAgentDefaultEmailSignatureBoolExp>;
};

export type SubscriptionRootDbAgentDefaultNylasEmailIntegrationArgs = {
  distinctOn?: InputMaybe<Array<DbAgentDefaultNylasEmailIntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentDefaultNylasEmailIntegrationOrderBy>>;
  where?: InputMaybe<DbAgentDefaultNylasEmailIntegrationBoolExp>;
};

export type SubscriptionRootDbAgentDefaultNylasEmailIntegrationAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentDefaultNylasEmailIntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentDefaultNylasEmailIntegrationOrderBy>>;
  where?: InputMaybe<DbAgentDefaultNylasEmailIntegrationBoolExp>;
};

export type SubscriptionRootDbAgentDefaultNylasEmailIntegrationByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbAgentDefaultNylasEmailIntegrationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbAgentDefaultNylasEmailIntegrationStreamCursorInput>>;
  where?: InputMaybe<DbAgentDefaultNylasEmailIntegrationBoolExp>;
};

export type SubscriptionRootDbAgentProfileArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileOrderBy>>;
  where?: InputMaybe<DbAgentProfileBoolExp>;
};

export type SubscriptionRootDbAgentProfileAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileOrderBy>>;
  where?: InputMaybe<DbAgentProfileBoolExp>;
};

export type SubscriptionRootDbAgentProfileByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbAgentProfileCityDistrictArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileCityDistrictSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileCityDistrictOrderBy>>;
  where?: InputMaybe<DbAgentProfileCityDistrictBoolExp>;
};

export type SubscriptionRootDbAgentProfileCityDistrictAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileCityDistrictSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileCityDistrictOrderBy>>;
  where?: InputMaybe<DbAgentProfileCityDistrictBoolExp>;
};

export type SubscriptionRootDbAgentProfileCityDistrictByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbAgentProfileCityDistrictStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbAgentProfileCityDistrictStreamCursorInput>>;
  where?: InputMaybe<DbAgentProfileCityDistrictBoolExp>;
};

export type SubscriptionRootDbAgentProfileDescriptionArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileDescriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileDescriptionOrderBy>>;
  where?: InputMaybe<DbAgentProfileDescriptionBoolExp>;
};

export type SubscriptionRootDbAgentProfileDescriptionAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileDescriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileDescriptionOrderBy>>;
  where?: InputMaybe<DbAgentProfileDescriptionBoolExp>;
};

export type SubscriptionRootDbAgentProfileDescriptionByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbAgentProfileDescriptionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbAgentProfileDescriptionStreamCursorInput>>;
  where?: InputMaybe<DbAgentProfileDescriptionBoolExp>;
};

export type SubscriptionRootDbAgentProfileExpertiseArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileExpertiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileExpertiseOrderBy>>;
  where?: InputMaybe<DbAgentProfileExpertiseBoolExp>;
};

export type SubscriptionRootDbAgentProfileExpertiseAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileExpertiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileExpertiseOrderBy>>;
  where?: InputMaybe<DbAgentProfileExpertiseBoolExp>;
};

export type SubscriptionRootDbAgentProfileExpertiseByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbAgentProfileExpertiseStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbAgentProfileExpertiseStreamCursorInput>>;
  where?: InputMaybe<DbAgentProfileExpertiseBoolExp>;
};

export type SubscriptionRootDbAgentProfileFormerUrlArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileFormerUrlSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileFormerUrlOrderBy>>;
  where?: InputMaybe<DbAgentProfileFormerUrlBoolExp>;
};

export type SubscriptionRootDbAgentProfileFormerUrlAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileFormerUrlSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileFormerUrlOrderBy>>;
  where?: InputMaybe<DbAgentProfileFormerUrlBoolExp>;
};

export type SubscriptionRootDbAgentProfileFormerUrlByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbAgentProfileFormerUrlStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbAgentProfileFormerUrlStreamCursorInput>>;
  where?: InputMaybe<DbAgentProfileFormerUrlBoolExp>;
};

export type SubscriptionRootDbAgentProfileImageArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileImageOrderBy>>;
  where?: InputMaybe<DbAgentProfileImageBoolExp>;
};

export type SubscriptionRootDbAgentProfileImageByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbAgentProfileImageStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbAgentProfileImageStreamCursorInput>>;
  where?: InputMaybe<DbAgentProfileImageBoolExp>;
};

export type SubscriptionRootDbAgentProfileSocialMediaProfilesArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileSocialMediaProfilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileSocialMediaProfilesOrderBy>>;
  where?: InputMaybe<DbAgentProfileSocialMediaProfilesBoolExp>;
};

export type SubscriptionRootDbAgentProfileSocialMediaProfilesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileSocialMediaProfilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileSocialMediaProfilesOrderBy>>;
  where?: InputMaybe<DbAgentProfileSocialMediaProfilesBoolExp>;
};

export type SubscriptionRootDbAgentProfileSocialMediaProfilesByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbAgentProfileSocialMediaProfilesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbAgentProfileSocialMediaProfilesStreamCursorInput>>;
  where?: InputMaybe<DbAgentProfileSocialMediaProfilesBoolExp>;
};

export type SubscriptionRootDbAgentProfileSpokenLanguageArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileSpokenLanguageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileSpokenLanguageOrderBy>>;
  where?: InputMaybe<DbAgentProfileSpokenLanguageBoolExp>;
};

export type SubscriptionRootDbAgentProfileSpokenLanguageAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbAgentProfileSpokenLanguageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentProfileSpokenLanguageOrderBy>>;
  where?: InputMaybe<DbAgentProfileSpokenLanguageBoolExp>;
};

export type SubscriptionRootDbAgentProfileSpokenLanguageByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbAgentProfileSpokenLanguageStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbAgentProfileSpokenLanguageStreamCursorInput>>;
  where?: InputMaybe<DbAgentProfileSpokenLanguageBoolExp>;
};

export type SubscriptionRootDbAgentProfileStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbAgentProfileStreamCursorInput>>;
  where?: InputMaybe<DbAgentProfileBoolExp>;
};

export type SubscriptionRootDbAgentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbAgentStreamCursorInput>>;
  where?: InputMaybe<DbAgentBoolExp>;
};

export type SubscriptionRootDbAppointmentActivityArgs = {
  distinctOn?: InputMaybe<Array<DbAppointmentActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAppointmentActivityOrderBy>>;
  where?: InputMaybe<DbAppointmentActivityBoolExp>;
};

export type SubscriptionRootDbAppointmentActivityByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbAppointmentActivityStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbAppointmentActivityStreamCursorInput>>;
  where?: InputMaybe<DbAppointmentActivityBoolExp>;
};

export type SubscriptionRootDbAppointmentAgentArgs = {
  distinctOn?: InputMaybe<Array<DbAppointmentAgentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAppointmentAgentOrderBy>>;
  where?: InputMaybe<DbAppointmentAgentBoolExp>;
};

export type SubscriptionRootDbAppointmentAgentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbAppointmentAgentStreamCursorInput>>;
  where?: InputMaybe<DbAppointmentAgentBoolExp>;
};

export type SubscriptionRootDbAppointmentContactArgs = {
  distinctOn?: InputMaybe<Array<DbAppointmentContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAppointmentContactOrderBy>>;
  where?: InputMaybe<DbAppointmentContactBoolExp>;
};

export type SubscriptionRootDbAppointmentContactStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbAppointmentContactStreamCursorInput>>;
  where?: InputMaybe<DbAppointmentContactBoolExp>;
};

export type SubscriptionRootDbAppointmentLeadArgs = {
  distinctOn?: InputMaybe<Array<DbAppointmentLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAppointmentLeadOrderBy>>;
  where?: InputMaybe<DbAppointmentLeadBoolExp>;
};

export type SubscriptionRootDbAppointmentLeadStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbAppointmentLeadStreamCursorInput>>;
  where?: InputMaybe<DbAppointmentLeadBoolExp>;
};

export type SubscriptionRootDbAuditActivityArgs = {
  distinctOn?: InputMaybe<Array<DbAuditActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAuditActivityOrderBy>>;
  where?: InputMaybe<DbAuditActivityBoolExp>;
};

export type SubscriptionRootDbAuditActivityByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbAuditActivityStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbAuditActivityStreamCursorInput>>;
  where?: InputMaybe<DbAuditActivityBoolExp>;
};

export type SubscriptionRootDbBaseActivityArgs = {
  distinctOn?: InputMaybe<Array<DbBaseActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBaseActivityOrderBy>>;
  where?: InputMaybe<DbBaseActivityBoolExp>;
};

export type SubscriptionRootDbBaseActivityAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbBaseActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBaseActivityOrderBy>>;
  where?: InputMaybe<DbBaseActivityBoolExp>;
};

export type SubscriptionRootDbBaseActivityByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbBaseActivityCreationArgs = {
  distinctOn?: InputMaybe<Array<DbBaseActivityCreationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBaseActivityCreationOrderBy>>;
  where?: InputMaybe<DbBaseActivityCreationBoolExp>;
};

export type SubscriptionRootDbBaseActivityCreationAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbBaseActivityCreationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBaseActivityCreationOrderBy>>;
  where?: InputMaybe<DbBaseActivityCreationBoolExp>;
};

export type SubscriptionRootDbBaseActivityCreationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbBaseActivityCreationStreamCursorInput>>;
  where?: InputMaybe<DbBaseActivityCreationBoolExp>;
};

export type SubscriptionRootDbBaseActivityStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbBaseActivityStreamCursorInput>>;
  where?: InputMaybe<DbBaseActivityBoolExp>;
};

export type SubscriptionRootDbBatchJobExecutionArgs = {
  distinctOn?: InputMaybe<Array<DbBatchJobExecutionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBatchJobExecutionOrderBy>>;
  where?: InputMaybe<DbBatchJobExecutionBoolExp>;
};

export type SubscriptionRootDbBatchJobExecutionByPkArgs = {
  jobExecutionId: Scalars['bigint'];
};

export type SubscriptionRootDbBatchJobExecutionContextArgs = {
  distinctOn?: InputMaybe<Array<DbBatchJobExecutionContextSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBatchJobExecutionContextOrderBy>>;
  where?: InputMaybe<DbBatchJobExecutionContextBoolExp>;
};

export type SubscriptionRootDbBatchJobExecutionContextByPkArgs = {
  jobExecutionId: Scalars['bigint'];
};

export type SubscriptionRootDbBatchJobExecutionContextStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbBatchJobExecutionContextStreamCursorInput>>;
  where?: InputMaybe<DbBatchJobExecutionContextBoolExp>;
};

export type SubscriptionRootDbBatchJobExecutionParamsArgs = {
  distinctOn?: InputMaybe<Array<DbBatchJobExecutionParamsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBatchJobExecutionParamsOrderBy>>;
  where?: InputMaybe<DbBatchJobExecutionParamsBoolExp>;
};

export type SubscriptionRootDbBatchJobExecutionParamsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbBatchJobExecutionParamsStreamCursorInput>>;
  where?: InputMaybe<DbBatchJobExecutionParamsBoolExp>;
};

export type SubscriptionRootDbBatchJobExecutionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbBatchJobExecutionStreamCursorInput>>;
  where?: InputMaybe<DbBatchJobExecutionBoolExp>;
};

export type SubscriptionRootDbBatchJobInstanceArgs = {
  distinctOn?: InputMaybe<Array<DbBatchJobInstanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBatchJobInstanceOrderBy>>;
  where?: InputMaybe<DbBatchJobInstanceBoolExp>;
};

export type SubscriptionRootDbBatchJobInstanceByPkArgs = {
  jobInstanceId: Scalars['bigint'];
};

export type SubscriptionRootDbBatchJobInstanceStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbBatchJobInstanceStreamCursorInput>>;
  where?: InputMaybe<DbBatchJobInstanceBoolExp>;
};

export type SubscriptionRootDbBatchStepExecutionArgs = {
  distinctOn?: InputMaybe<Array<DbBatchStepExecutionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBatchStepExecutionOrderBy>>;
  where?: InputMaybe<DbBatchStepExecutionBoolExp>;
};

export type SubscriptionRootDbBatchStepExecutionByPkArgs = {
  stepExecutionId: Scalars['bigint'];
};

export type SubscriptionRootDbBatchStepExecutionContextArgs = {
  distinctOn?: InputMaybe<Array<DbBatchStepExecutionContextSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBatchStepExecutionContextOrderBy>>;
  where?: InputMaybe<DbBatchStepExecutionContextBoolExp>;
};

export type SubscriptionRootDbBatchStepExecutionContextByPkArgs = {
  stepExecutionId: Scalars['bigint'];
};

export type SubscriptionRootDbBatchStepExecutionContextStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbBatchStepExecutionContextStreamCursorInput>>;
  where?: InputMaybe<DbBatchStepExecutionContextBoolExp>;
};

export type SubscriptionRootDbBatchStepExecutionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbBatchStepExecutionStreamCursorInput>>;
  where?: InputMaybe<DbBatchStepExecutionBoolExp>;
};

export type SubscriptionRootDbBulkEmailArgs = {
  distinctOn?: InputMaybe<Array<DbBulkEmailSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBulkEmailOrderBy>>;
  where?: InputMaybe<DbBulkEmailBoolExp>;
};

export type SubscriptionRootDbBulkEmailAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbBulkEmailSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBulkEmailOrderBy>>;
  where?: InputMaybe<DbBulkEmailBoolExp>;
};

export type SubscriptionRootDbBulkEmailStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbBulkEmailStreamCursorInput>>;
  where?: InputMaybe<DbBulkEmailBoolExp>;
};

export type SubscriptionRootDbBuyerToRentSpecificationArgs = {
  distinctOn?: InputMaybe<Array<DbBuyerToRentSpecificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBuyerToRentSpecificationOrderBy>>;
  where?: InputMaybe<DbBuyerToRentSpecificationBoolExp>;
};

export type SubscriptionRootDbBuyerToRentSpecificationByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbBuyerToRentSpecificationCdfArgs = {
  distinctOn?: InputMaybe<Array<DbBuyerToRentSpecificationCdfSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBuyerToRentSpecificationCdfOrderBy>>;
  where?: InputMaybe<DbBuyerToRentSpecificationCdfBoolExp>;
};

export type SubscriptionRootDbBuyerToRentSpecificationCdfByPkArgs = {
  brsId: Scalars['String'];
  cdfId: Scalars['String'];
};

export type SubscriptionRootDbBuyerToRentSpecificationCdfStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbBuyerToRentSpecificationCdfStreamCursorInput>>;
  where?: InputMaybe<DbBuyerToRentSpecificationCdfBoolExp>;
};

export type SubscriptionRootDbBuyerToRentSpecificationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbBuyerToRentSpecificationStreamCursorInput>>;
  where?: InputMaybe<DbBuyerToRentSpecificationBoolExp>;
};

export type SubscriptionRootDbCallArgs = {
  distinctOn?: InputMaybe<Array<DbCallSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCallOrderBy>>;
  where?: InputMaybe<DbCallBoolExp>;
};

export type SubscriptionRootDbCallActivityArgs = {
  distinctOn?: InputMaybe<Array<DbCallActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCallActivityOrderBy>>;
  where?: InputMaybe<DbCallActivityBoolExp>;
};

export type SubscriptionRootDbCallActivityByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbCallActivityStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbCallActivityStreamCursorInput>>;
  where?: InputMaybe<DbCallActivityBoolExp>;
};

export type SubscriptionRootDbCallAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbCallSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCallOrderBy>>;
  where?: InputMaybe<DbCallBoolExp>;
};

export type SubscriptionRootDbCallByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbCallStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbCallStreamCursorInput>>;
  where?: InputMaybe<DbCallBoolExp>;
};

export type SubscriptionRootDbCdfArgs = {
  distinctOn?: InputMaybe<Array<DbCdfSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCdfOrderBy>>;
  where?: InputMaybe<DbCdfBoolExp>;
};

export type SubscriptionRootDbCdfByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbCdfStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbCdfStreamCursorInput>>;
  where?: InputMaybe<DbCdfBoolExp>;
};

export type SubscriptionRootDbConsentArgs = {
  distinctOn?: InputMaybe<Array<DbConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentOrderBy>>;
  where?: InputMaybe<DbConsentBoolExp>;
};

export type SubscriptionRootDbConsentActivityArgs = {
  distinctOn?: InputMaybe<Array<DbConsentActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentActivityOrderBy>>;
  where?: InputMaybe<DbConsentActivityBoolExp>;
};

export type SubscriptionRootDbConsentActivityByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbConsentActivityStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbConsentActivityStreamCursorInput>>;
  where?: InputMaybe<DbConsentActivityBoolExp>;
};

export type SubscriptionRootDbConsentAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentOrderBy>>;
  where?: InputMaybe<DbConsentBoolExp>;
};

export type SubscriptionRootDbConsentByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbConsentEventArgs = {
  distinctOn?: InputMaybe<Array<DbConsentEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentEventOrderBy>>;
  where?: InputMaybe<DbConsentEventBoolExp>;
};

export type SubscriptionRootDbConsentEventAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbConsentEventSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentEventOrderBy>>;
  where?: InputMaybe<DbConsentEventBoolExp>;
};

export type SubscriptionRootDbConsentEventByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbConsentEventStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbConsentEventStreamCursorInput>>;
  where?: InputMaybe<DbConsentEventBoolExp>;
};

export type SubscriptionRootDbConsentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbConsentStreamCursorInput>>;
  where?: InputMaybe<DbConsentBoolExp>;
};

export type SubscriptionRootDbConsentTemplateArgs = {
  distinctOn?: InputMaybe<Array<DbConsentTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentTemplateOrderBy>>;
  where?: InputMaybe<DbConsentTemplateBoolExp>;
};

export type SubscriptionRootDbConsentTemplateByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbConsentTemplateStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbConsentTemplateStreamCursorInput>>;
  where?: InputMaybe<DbConsentTemplateBoolExp>;
};

export type SubscriptionRootDbConsentTextArgs = {
  distinctOn?: InputMaybe<Array<DbConsentTextSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConsentTextOrderBy>>;
  where?: InputMaybe<DbConsentTextBoolExp>;
};

export type SubscriptionRootDbConsentTextByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbConsentTextStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbConsentTextStreamCursorInput>>;
  where?: InputMaybe<DbConsentTextBoolExp>;
};

export type SubscriptionRootDbContactArgs = {
  distinctOn?: InputMaybe<Array<DbContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactOrderBy>>;
  where?: InputMaybe<DbContactBoolExp>;
};

export type SubscriptionRootDbContactAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactOrderBy>>;
  where?: InputMaybe<DbContactBoolExp>;
};

export type SubscriptionRootDbContactAssignmentArgs = {
  distinctOn?: InputMaybe<Array<DbContactAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactAssignmentOrderBy>>;
  where?: InputMaybe<DbContactAssignmentBoolExp>;
};

export type SubscriptionRootDbContactAssignmentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbContactAssignmentStreamCursorInput>>;
  where?: InputMaybe<DbContactAssignmentBoolExp>;
};

export type SubscriptionRootDbContactByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbContactChangeActivityArgs = {
  distinctOn?: InputMaybe<Array<DbContactChangeActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactChangeActivityOrderBy>>;
  where?: InputMaybe<DbContactChangeActivityBoolExp>;
};

export type SubscriptionRootDbContactChangeActivityByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbContactChangeActivityStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbContactChangeActivityStreamCursorInput>>;
  where?: InputMaybe<DbContactChangeActivityBoolExp>;
};

export type SubscriptionRootDbContactChangeLogArgs = {
  distinctOn?: InputMaybe<Array<DbContactChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactChangeLogOrderBy>>;
  where?: InputMaybe<DbContactChangeLogBoolExp>;
};

export type SubscriptionRootDbContactChangeLogByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbContactChangeLogStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbContactChangeLogStreamCursorInput>>;
  where?: InputMaybe<DbContactChangeLogBoolExp>;
};

export type SubscriptionRootDbContactDetailsArgs = {
  distinctOn?: InputMaybe<Array<DbContactDetailsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactDetailsOrderBy>>;
  where?: InputMaybe<DbContactDetailsBoolExp>;
};

export type SubscriptionRootDbContactDetailsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbContactDetailsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactDetailsOrderBy>>;
  where?: InputMaybe<DbContactDetailsBoolExp>;
};

export type SubscriptionRootDbContactDetailsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbContactDetailsStreamCursorInput>>;
  where?: InputMaybe<DbContactDetailsBoolExp>;
};

export type SubscriptionRootDbContactGdprArgs = {
  distinctOn?: InputMaybe<Array<DbContactGdprSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactGdprOrderBy>>;
  where?: InputMaybe<DbContactGdprBoolExp>;
};

export type SubscriptionRootDbContactGdprByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbContactGdprStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbContactGdprStreamCursorInput>>;
  where?: InputMaybe<DbContactGdprBoolExp>;
};

export type SubscriptionRootDbContactPreferredTimeArgs = {
  distinctOn?: InputMaybe<Array<DbContactPreferredTimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactPreferredTimeOrderBy>>;
  where?: InputMaybe<DbContactPreferredTimeBoolExp>;
};

export type SubscriptionRootDbContactPreferredTimeAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbContactPreferredTimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactPreferredTimeOrderBy>>;
  where?: InputMaybe<DbContactPreferredTimeBoolExp>;
};

export type SubscriptionRootDbContactPreferredTimeByPkArgs = {
  contactId: Scalars['String'];
  preferredContactTime: ContactTimeEnum;
};

export type SubscriptionRootDbContactPreferredTimeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbContactPreferredTimeStreamCursorInput>>;
  where?: InputMaybe<DbContactPreferredTimeBoolExp>;
};

export type SubscriptionRootDbContactPreferredWayArgs = {
  distinctOn?: InputMaybe<Array<DbContactPreferredWaySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactPreferredWayOrderBy>>;
  where?: InputMaybe<DbContactPreferredWayBoolExp>;
};

export type SubscriptionRootDbContactPreferredWayAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbContactPreferredWaySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactPreferredWayOrderBy>>;
  where?: InputMaybe<DbContactPreferredWayBoolExp>;
};

export type SubscriptionRootDbContactPreferredWayByPkArgs = {
  contactId: Scalars['String'];
  preferredContactWay: ContactWayEnum;
};

export type SubscriptionRootDbContactPreferredWayStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbContactPreferredWayStreamCursorInput>>;
  where?: InputMaybe<DbContactPreferredWayBoolExp>;
};

export type SubscriptionRootDbContactRelationshipArgs = {
  distinctOn?: InputMaybe<Array<DbContactRelationshipSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactRelationshipOrderBy>>;
  where?: InputMaybe<DbContactRelationshipBoolExp>;
};

export type SubscriptionRootDbContactRelationshipByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbContactRelationshipStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbContactRelationshipStreamCursorInput>>;
  where?: InputMaybe<DbContactRelationshipBoolExp>;
};

export type SubscriptionRootDbContactRenterInformationArgs = {
  distinctOn?: InputMaybe<Array<DbContactRenterInformationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactRenterInformationOrderBy>>;
  where?: InputMaybe<DbContactRenterInformationBoolExp>;
};

export type SubscriptionRootDbContactRenterInformationByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbContactRenterInformationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbContactRenterInformationStreamCursorInput>>;
  where?: InputMaybe<DbContactRenterInformationBoolExp>;
};

export type SubscriptionRootDbContactStatusInfoArgs = {
  distinctOn?: InputMaybe<Array<DbContactStatusInfoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactStatusInfoOrderBy>>;
  where?: InputMaybe<DbContactStatusInfoBoolExp>;
};

export type SubscriptionRootDbContactStatusInfoAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbContactStatusInfoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactStatusInfoOrderBy>>;
  where?: InputMaybe<DbContactStatusInfoBoolExp>;
};

export type SubscriptionRootDbContactStatusInfoStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbContactStatusInfoStreamCursorInput>>;
  where?: InputMaybe<DbContactStatusInfoBoolExp>;
};

export type SubscriptionRootDbContactStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbContactStreamCursorInput>>;
  where?: InputMaybe<DbContactBoolExp>;
};

export type SubscriptionRootDbContactTagArgs = {
  distinctOn?: InputMaybe<Array<DbContactTagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactTagOrderBy>>;
  where?: InputMaybe<DbContactTagBoolExp>;
};

export type SubscriptionRootDbContactTagByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbContactTagStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbContactTagStreamCursorInput>>;
  where?: InputMaybe<DbContactTagBoolExp>;
};

export type SubscriptionRootDbConversionDataArgs = {
  distinctOn?: InputMaybe<Array<DbConversionDataSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbConversionDataOrderBy>>;
  where?: InputMaybe<DbConversionDataBoolExp>;
};

export type SubscriptionRootDbConversionDataByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbConversionDataStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbConversionDataStreamCursorInput>>;
  where?: InputMaybe<DbConversionDataBoolExp>;
};

export type SubscriptionRootDbCountryMandatoryLanguageArgs = {
  distinctOn?: InputMaybe<Array<DbCountryMandatoryLanguageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCountryMandatoryLanguageOrderBy>>;
  where?: InputMaybe<DbCountryMandatoryLanguageBoolExp>;
};

export type SubscriptionRootDbCountryMandatoryLanguageByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbCountryMandatoryLanguageStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbCountryMandatoryLanguageStreamCursorInput>>;
  where?: InputMaybe<DbCountryMandatoryLanguageBoolExp>;
};

export type SubscriptionRootDbCustomListViewArgs = {
  distinctOn?: InputMaybe<Array<DbCustomListViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCustomListViewOrderBy>>;
  where?: InputMaybe<DbCustomListViewBoolExp>;
};

export type SubscriptionRootDbCustomListViewByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbCustomListViewStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbCustomListViewStreamCursorInput>>;
  where?: InputMaybe<DbCustomListViewBoolExp>;
};

export type SubscriptionRootDbCustomSearchDemandPolygonArgs = {
  distinctOn?: InputMaybe<Array<DbCustomSearchDemandPolygonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbCustomSearchDemandPolygonOrderBy>>;
  where?: InputMaybe<DbCustomSearchDemandPolygonBoolExp>;
};

export type SubscriptionRootDbCustomSearchDemandPolygonByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbCustomSearchDemandPolygonStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbCustomSearchDemandPolygonStreamCursorInput>>;
  where?: InputMaybe<DbCustomSearchDemandPolygonBoolExp>;
};

export type SubscriptionRootDbDocumentArgs = {
  distinctOn?: InputMaybe<Array<DbDocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbDocumentOrderBy>>;
  where?: InputMaybe<DbDocumentBoolExp>;
};

export type SubscriptionRootDbDocumentActivityArgs = {
  distinctOn?: InputMaybe<Array<DbDocumentActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbDocumentActivityOrderBy>>;
  where?: InputMaybe<DbDocumentActivityBoolExp>;
};

export type SubscriptionRootDbDocumentActivityByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbDocumentActivityStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbDocumentActivityStreamCursorInput>>;
  where?: InputMaybe<DbDocumentActivityBoolExp>;
};

export type SubscriptionRootDbDocumentAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbDocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbDocumentOrderBy>>;
  where?: InputMaybe<DbDocumentBoolExp>;
};

export type SubscriptionRootDbDocumentByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbDocumentReferenceArgs = {
  distinctOn?: InputMaybe<Array<DbDocumentReferenceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbDocumentReferenceOrderBy>>;
  where?: InputMaybe<DbDocumentReferenceBoolExp>;
};

export type SubscriptionRootDbDocumentReferenceAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbDocumentReferenceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbDocumentReferenceOrderBy>>;
  where?: InputMaybe<DbDocumentReferenceBoolExp>;
};

export type SubscriptionRootDbDocumentReferenceByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbDocumentReferenceStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbDocumentReferenceStreamCursorInput>>;
  where?: InputMaybe<DbDocumentReferenceBoolExp>;
};

export type SubscriptionRootDbDocumentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbDocumentStreamCursorInput>>;
  where?: InputMaybe<DbDocumentBoolExp>;
};

export type SubscriptionRootDbEmailAttachmentArgs = {
  distinctOn?: InputMaybe<Array<DbEmailAttachmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailAttachmentOrderBy>>;
  where?: InputMaybe<DbEmailAttachmentBoolExp>;
};

export type SubscriptionRootDbEmailAttachmentAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailAttachmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailAttachmentOrderBy>>;
  where?: InputMaybe<DbEmailAttachmentBoolExp>;
};

export type SubscriptionRootDbEmailAttachmentByPkArgs = {
  attachmentId: Scalars['String'];
  emailId: Scalars['String'];
};

export type SubscriptionRootDbEmailAttachmentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbEmailAttachmentStreamCursorInput>>;
  where?: InputMaybe<DbEmailAttachmentBoolExp>;
};

export type SubscriptionRootDbEmailCallbackEventsArgs = {
  distinctOn?: InputMaybe<Array<DbEmailCallbackEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailCallbackEventsOrderBy>>;
  where?: InputMaybe<DbEmailCallbackEventsBoolExp>;
};

export type SubscriptionRootDbEmailCallbackEventsAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailCallbackEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailCallbackEventsOrderBy>>;
  where?: InputMaybe<DbEmailCallbackEventsBoolExp>;
};

export type SubscriptionRootDbEmailCallbackEventsByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbEmailCallbackEventsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbEmailCallbackEventsStreamCursorInput>>;
  where?: InputMaybe<DbEmailCallbackEventsBoolExp>;
};

export type SubscriptionRootDbEmailLinkArgs = {
  distinctOn?: InputMaybe<Array<DbEmailLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailLinkOrderBy>>;
  where?: InputMaybe<DbEmailLinkBoolExp>;
};

export type SubscriptionRootDbEmailLinkAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailLinkOrderBy>>;
  where?: InputMaybe<DbEmailLinkBoolExp>;
};

export type SubscriptionRootDbEmailLinkByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbEmailLinkStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbEmailLinkStreamCursorInput>>;
  where?: InputMaybe<DbEmailLinkBoolExp>;
};

export type SubscriptionRootDbEmailMessageMapArgs = {
  distinctOn?: InputMaybe<Array<DbEmailMessageMapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailMessageMapOrderBy>>;
  where?: InputMaybe<DbEmailMessageMapBoolExp>;
};

export type SubscriptionRootDbEmailMessageMapStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbEmailMessageMapStreamCursorInput>>;
  where?: InputMaybe<DbEmailMessageMapBoolExp>;
};

export type SubscriptionRootDbEmailPlaceholderArgs = {
  distinctOn?: InputMaybe<Array<DbEmailPlaceholderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailPlaceholderOrderBy>>;
  where?: InputMaybe<DbEmailPlaceholderBoolExp>;
};

export type SubscriptionRootDbEmailPlaceholderAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailPlaceholderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailPlaceholderOrderBy>>;
  where?: InputMaybe<DbEmailPlaceholderBoolExp>;
};

export type SubscriptionRootDbEmailPlaceholderByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbEmailPlaceholderStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbEmailPlaceholderStreamCursorInput>>;
  where?: InputMaybe<DbEmailPlaceholderBoolExp>;
};

export type SubscriptionRootDbEmailSignatureArgs = {
  distinctOn?: InputMaybe<Array<DbEmailSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailSignatureOrderBy>>;
  where?: InputMaybe<DbEmailSignatureBoolExp>;
};

export type SubscriptionRootDbEmailSignatureAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailSignatureSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailSignatureOrderBy>>;
  where?: InputMaybe<DbEmailSignatureBoolExp>;
};

export type SubscriptionRootDbEmailSignatureByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbEmailSignatureStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbEmailSignatureStreamCursorInput>>;
  where?: InputMaybe<DbEmailSignatureBoolExp>;
};

export type SubscriptionRootDbEmailTemplateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTemplateOrderBy>>;
  where?: InputMaybe<DbEmailTemplateBoolExp>;
};

export type SubscriptionRootDbEmailTemplateAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTemplateOrderBy>>;
  where?: InputMaybe<DbEmailTemplateBoolExp>;
};

export type SubscriptionRootDbEmailTemplateByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbEmailTemplateContentArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTemplateContentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTemplateContentOrderBy>>;
  where?: InputMaybe<DbEmailTemplateContentBoolExp>;
};

export type SubscriptionRootDbEmailTemplateContentAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTemplateContentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTemplateContentOrderBy>>;
  where?: InputMaybe<DbEmailTemplateContentBoolExp>;
};

export type SubscriptionRootDbEmailTemplateContentByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbEmailTemplateContentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbEmailTemplateContentStreamCursorInput>>;
  where?: InputMaybe<DbEmailTemplateContentBoolExp>;
};

export type SubscriptionRootDbEmailTemplateStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbEmailTemplateStreamCursorInput>>;
  where?: InputMaybe<DbEmailTemplateBoolExp>;
};

export type SubscriptionRootDbEmailTrackingArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTrackingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTrackingOrderBy>>;
  where?: InputMaybe<DbEmailTrackingBoolExp>;
};

export type SubscriptionRootDbEmailTrackingAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbEmailTrackingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEmailTrackingOrderBy>>;
  where?: InputMaybe<DbEmailTrackingBoolExp>;
};

export type SubscriptionRootDbEmailTrackingByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbEmailTrackingStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbEmailTrackingStreamCursorInput>>;
  where?: InputMaybe<DbEmailTrackingBoolExp>;
};

export type SubscriptionRootDbEnabledFeatureFlagsArgs = {
  distinctOn?: InputMaybe<Array<DbEnabledFeatureFlagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbEnabledFeatureFlagsOrderBy>>;
  where?: InputMaybe<DbEnabledFeatureFlagsBoolExp>;
};

export type SubscriptionRootDbEnabledFeatureFlagsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbEnabledFeatureFlagsStreamCursorInput>>;
  where?: InputMaybe<DbEnabledFeatureFlagsBoolExp>;
};

export type SubscriptionRootDbExposeeSharedActivityArgs = {
  distinctOn?: InputMaybe<Array<DbExposeeSharedActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbExposeeSharedActivityOrderBy>>;
  where?: InputMaybe<DbExposeeSharedActivityBoolExp>;
};

export type SubscriptionRootDbExposeeSharedActivityAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbExposeeSharedActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbExposeeSharedActivityOrderBy>>;
  where?: InputMaybe<DbExposeeSharedActivityBoolExp>;
};

export type SubscriptionRootDbExposeeSharedActivityByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbExposeeSharedActivityStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbExposeeSharedActivityStreamCursorInput>>;
  where?: InputMaybe<DbExposeeSharedActivityBoolExp>;
};

export type SubscriptionRootDbImportLeadRequestArgs = {
  distinctOn?: InputMaybe<Array<DbImportLeadRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbImportLeadRequestOrderBy>>;
  where?: InputMaybe<DbImportLeadRequestBoolExp>;
};

export type SubscriptionRootDbImportLeadRequestAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbImportLeadRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbImportLeadRequestOrderBy>>;
  where?: InputMaybe<DbImportLeadRequestBoolExp>;
};

export type SubscriptionRootDbImportLeadRequestByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbImportLeadRequestStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbImportLeadRequestStreamCursorInput>>;
  where?: InputMaybe<DbImportLeadRequestBoolExp>;
};

export type SubscriptionRootDbLeadArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

export type SubscriptionRootDbLeadAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadOrderBy>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

export type SubscriptionRootDbLeadAutoAssignmentIntentArgs = {
  distinctOn?: InputMaybe<Array<DbLeadAutoAssignmentIntentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadAutoAssignmentIntentOrderBy>>;
  where?: InputMaybe<DbLeadAutoAssignmentIntentBoolExp>;
};

export type SubscriptionRootDbLeadAutoAssignmentIntentByPkArgs = {
  intentType: IntentTypeEnum;
  shopId: Scalars['String'];
};

export type SubscriptionRootDbLeadAutoAssignmentIntentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbLeadAutoAssignmentIntentStreamCursorInput>>;
  where?: InputMaybe<DbLeadAutoAssignmentIntentBoolExp>;
};

export type SubscriptionRootDbLeadAutoAssignmentRulesArgs = {
  distinctOn?: InputMaybe<Array<DbLeadAutoAssignmentRulesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadAutoAssignmentRulesOrderBy>>;
  where?: InputMaybe<DbLeadAutoAssignmentRulesBoolExp>;
};

export type SubscriptionRootDbLeadAutoAssignmentRulesByPkArgs = {
  leadAssignmentRule: LeadAutoAssignmentRulesEnum;
  shopId: Scalars['String'];
};

export type SubscriptionRootDbLeadAutoAssignmentRulesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbLeadAutoAssignmentRulesStreamCursorInput>>;
  where?: InputMaybe<DbLeadAutoAssignmentRulesBoolExp>;
};

export type SubscriptionRootDbLeadByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbLeadExpiryArgs = {
  distinctOn?: InputMaybe<Array<DbLeadExpirySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadExpiryOrderBy>>;
  where?: InputMaybe<DbLeadExpiryBoolExp>;
};

export type SubscriptionRootDbLeadExpiryStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbLeadExpiryStreamCursorInput>>;
  where?: InputMaybe<DbLeadExpiryBoolExp>;
};

export type SubscriptionRootDbLeadExtendingActivitiesArgs = {
  distinctOn?: InputMaybe<Array<DbLeadExtendingActivitiesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadExtendingActivitiesOrderBy>>;
  where?: InputMaybe<DbLeadExtendingActivitiesBoolExp>;
};

export type SubscriptionRootDbLeadExtendingActivitiesByPkArgs = {
  activityType: ActivityTypeEnum;
  shopId: Scalars['String'];
};

export type SubscriptionRootDbLeadExtendingActivitiesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbLeadExtendingActivitiesStreamCursorInput>>;
  where?: InputMaybe<DbLeadExtendingActivitiesBoolExp>;
};

export type SubscriptionRootDbLeadExtendingAppointmentTypesArgs = {
  distinctOn?: InputMaybe<Array<DbLeadExtendingAppointmentTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadExtendingAppointmentTypesOrderBy>>;
  where?: InputMaybe<DbLeadExtendingAppointmentTypesBoolExp>;
};

export type SubscriptionRootDbLeadExtendingAppointmentTypesByPkArgs = {
  appointmentType: AppointmentTypeEnum;
  shopId: Scalars['String'];
};

export type SubscriptionRootDbLeadExtendingAppointmentTypesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbLeadExtendingAppointmentTypesStreamCursorInput>>;
  where?: InputMaybe<DbLeadExtendingAppointmentTypesBoolExp>;
};

export type SubscriptionRootDbLeadExtendingTaskTypesArgs = {
  distinctOn?: InputMaybe<Array<DbLeadExtendingTaskTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadExtendingTaskTypesOrderBy>>;
  where?: InputMaybe<DbLeadExtendingTaskTypesBoolExp>;
};

export type SubscriptionRootDbLeadExtendingTaskTypesByPkArgs = {
  shopId: Scalars['String'];
  taskType: TaskTypeEnum;
};

export type SubscriptionRootDbLeadExtendingTaskTypesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbLeadExtendingTaskTypesStreamCursorInput>>;
  where?: InputMaybe<DbLeadExtendingTaskTypesBoolExp>;
};

export type SubscriptionRootDbLeadStatisticsArgs = {
  distinctOn?: InputMaybe<Array<DbLeadStatisticsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadStatisticsOrderBy>>;
  where?: InputMaybe<DbLeadStatisticsBoolExp>;
};

export type SubscriptionRootDbLeadStatisticsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbLeadStatisticsStreamCursorInput>>;
  where?: InputMaybe<DbLeadStatisticsBoolExp>;
};

export type SubscriptionRootDbLeadStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbLeadStreamCursorInput>>;
  where?: InputMaybe<DbLeadBoolExp>;
};

export type SubscriptionRootDbLeadhubGo3ContactLinkArgs = {
  distinctOn?: InputMaybe<Array<DbLeadhubGo3ContactLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadhubGo3ContactLinkOrderBy>>;
  where?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
};

export type SubscriptionRootDbLeadhubGo3ContactLinkAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLeadhubGo3ContactLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLeadhubGo3ContactLinkOrderBy>>;
  where?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
};

export type SubscriptionRootDbLeadhubGo3ContactLinkByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbLeadhubGo3ContactLinkStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbLeadhubGo3ContactLinkStreamCursorInput>>;
  where?: InputMaybe<DbLeadhubGo3ContactLinkBoolExp>;
};

export type SubscriptionRootDbLegacyGo3ChangeLogArgs = {
  distinctOn?: InputMaybe<Array<DbLegacyGo3ChangeLogSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLegacyGo3ChangeLogOrderBy>>;
  where?: InputMaybe<DbLegacyGo3ChangeLogBoolExp>;
};

export type SubscriptionRootDbLegacyGo3ChangeLogByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbLegacyGo3ChangeLogStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbLegacyGo3ChangeLogStreamCursorInput>>;
  where?: InputMaybe<DbLegacyGo3ChangeLogBoolExp>;
};

export type SubscriptionRootDbLegalEntityArgs = {
  distinctOn?: InputMaybe<Array<DbLegalEntitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLegalEntityOrderBy>>;
  where?: InputMaybe<DbLegalEntityBoolExp>;
};

export type SubscriptionRootDbLegalEntityAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLegalEntitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLegalEntityOrderBy>>;
  where?: InputMaybe<DbLegalEntityBoolExp>;
};

export type SubscriptionRootDbLegalEntityByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbLegalEntityStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbLegalEntityStreamCursorInput>>;
  where?: InputMaybe<DbLegalEntityBoolExp>;
};

export type SubscriptionRootDbLicensePartnerArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerOrderBy>>;
  where?: InputMaybe<DbLicensePartnerBoolExp>;
};

export type SubscriptionRootDbLicensePartnerAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerOrderBy>>;
  where?: InputMaybe<DbLicensePartnerBoolExp>;
};

export type SubscriptionRootDbLicensePartnerByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbLicensePartnerProfileArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileBoolExp>;
};

export type SubscriptionRootDbLicensePartnerProfileAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileBoolExp>;
};

export type SubscriptionRootDbLicensePartnerProfileByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbLicensePartnerProfileFeaturedAgentArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileFeaturedAgentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileFeaturedAgentOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileFeaturedAgentBoolExp>;
};

export type SubscriptionRootDbLicensePartnerProfileFeaturedAgentByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbLicensePartnerProfileFeaturedAgentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbLicensePartnerProfileFeaturedAgentStreamCursorInput>>;
  where?: InputMaybe<DbLicensePartnerProfileFeaturedAgentBoolExp>;
};

export type SubscriptionRootDbLicensePartnerProfileFeaturedContentTeaserArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileFeaturedContentTeaserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileFeaturedContentTeaserOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileFeaturedContentTeaserBoolExp>;
};

export type SubscriptionRootDbLicensePartnerProfileFeaturedContentTeaserByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbLicensePartnerProfileFeaturedContentTeaserStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbLicensePartnerProfileFeaturedContentTeaserStreamCursorInput>>;
  where?: InputMaybe<DbLicensePartnerProfileFeaturedContentTeaserBoolExp>;
};

export type SubscriptionRootDbLicensePartnerProfileFormerUrlArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileFormerUrlSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileFormerUrlOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileFormerUrlBoolExp>;
};

export type SubscriptionRootDbLicensePartnerProfileFormerUrlAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileFormerUrlSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileFormerUrlOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileFormerUrlBoolExp>;
};

export type SubscriptionRootDbLicensePartnerProfileFormerUrlByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbLicensePartnerProfileFormerUrlStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbLicensePartnerProfileFormerUrlStreamCursorInput>>;
  where?: InputMaybe<DbLicensePartnerProfileFormerUrlBoolExp>;
};

export type SubscriptionRootDbLicensePartnerProfileImageArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileImageOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileImageBoolExp>;
};

export type SubscriptionRootDbLicensePartnerProfileImageAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileImageOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileImageBoolExp>;
};

export type SubscriptionRootDbLicensePartnerProfileImageByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbLicensePartnerProfileImageStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbLicensePartnerProfileImageStreamCursorInput>>;
  where?: InputMaybe<DbLicensePartnerProfileImageBoolExp>;
};

export type SubscriptionRootDbLicensePartnerProfileLanguageVariantArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileLanguageVariantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileLanguageVariantOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileLanguageVariantBoolExp>;
};

export type SubscriptionRootDbLicensePartnerProfileLanguageVariantAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfileLanguageVariantSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfileLanguageVariantOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfileLanguageVariantBoolExp>;
};

export type SubscriptionRootDbLicensePartnerProfileLanguageVariantByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbLicensePartnerProfileLanguageVariantStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbLicensePartnerProfileLanguageVariantStreamCursorInput>>;
  where?: InputMaybe<DbLicensePartnerProfileLanguageVariantBoolExp>;
};

export type SubscriptionRootDbLicensePartnerProfilePropertyPreviewArgs = {
  distinctOn?: InputMaybe<Array<DbLicensePartnerProfilePropertyPreviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbLicensePartnerProfilePropertyPreviewOrderBy>>;
  where?: InputMaybe<DbLicensePartnerProfilePropertyPreviewBoolExp>;
};

export type SubscriptionRootDbLicensePartnerProfilePropertyPreviewByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbLicensePartnerProfilePropertyPreviewStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbLicensePartnerProfilePropertyPreviewStreamCursorInput>>;
  where?: InputMaybe<DbLicensePartnerProfilePropertyPreviewBoolExp>;
};

export type SubscriptionRootDbLicensePartnerProfileStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbLicensePartnerProfileStreamCursorInput>>;
  where?: InputMaybe<DbLicensePartnerProfileBoolExp>;
};

export type SubscriptionRootDbLicensePartnerStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbLicensePartnerStreamCursorInput>>;
  where?: InputMaybe<DbLicensePartnerBoolExp>;
};

export type SubscriptionRootDbMigrationSourceArgs = {
  distinctOn?: InputMaybe<Array<DbMigrationSourceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbMigrationSourceOrderBy>>;
  where?: InputMaybe<DbMigrationSourceBoolExp>;
};

export type SubscriptionRootDbMigrationSourceAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbMigrationSourceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbMigrationSourceOrderBy>>;
  where?: InputMaybe<DbMigrationSourceBoolExp>;
};

export type SubscriptionRootDbMigrationSourceByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbMigrationSourcePkListArgs = {
  distinctOn?: InputMaybe<Array<DbMigrationSourcePkListSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbMigrationSourcePkListOrderBy>>;
  where?: InputMaybe<DbMigrationSourcePkListBoolExp>;
};

export type SubscriptionRootDbMigrationSourcePkListAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbMigrationSourcePkListSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbMigrationSourcePkListOrderBy>>;
  where?: InputMaybe<DbMigrationSourcePkListBoolExp>;
};

export type SubscriptionRootDbMigrationSourcePkListByPkArgs = {
  sequenceNo: Scalars['Int'];
};

export type SubscriptionRootDbMigrationSourcePkListStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbMigrationSourcePkListStreamCursorInput>>;
  where?: InputMaybe<DbMigrationSourcePkListBoolExp>;
};

export type SubscriptionRootDbMigrationSourceStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbMigrationSourceStreamCursorInput>>;
  where?: InputMaybe<DbMigrationSourceBoolExp>;
};

export type SubscriptionRootDbMigrationTargetArgs = {
  distinctOn?: InputMaybe<Array<DbMigrationTargetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbMigrationTargetOrderBy>>;
  where?: InputMaybe<DbMigrationTargetBoolExp>;
};

export type SubscriptionRootDbMigrationTargetAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbMigrationTargetSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbMigrationTargetOrderBy>>;
  where?: InputMaybe<DbMigrationTargetBoolExp>;
};

export type SubscriptionRootDbMigrationTargetByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbMigrationTargetStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbMigrationTargetStreamCursorInput>>;
  where?: InputMaybe<DbMigrationTargetBoolExp>;
};

export type SubscriptionRootDbNylasEmailIntegrationArgs = {
  distinctOn?: InputMaybe<Array<DbNylasEmailIntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbNylasEmailIntegrationOrderBy>>;
  where?: InputMaybe<DbNylasEmailIntegrationBoolExp>;
};

export type SubscriptionRootDbNylasEmailIntegrationAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbNylasEmailIntegrationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbNylasEmailIntegrationOrderBy>>;
  where?: InputMaybe<DbNylasEmailIntegrationBoolExp>;
};

export type SubscriptionRootDbNylasEmailIntegrationByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbNylasEmailIntegrationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbNylasEmailIntegrationStreamCursorInput>>;
  where?: InputMaybe<DbNylasEmailIntegrationBoolExp>;
};

export type SubscriptionRootDbOneWayMappingFromLeadIdToContactIdArgs = {
  distinctOn?: InputMaybe<Array<DbOneWayMappingFromLeadIdToContactIdSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbOneWayMappingFromLeadIdToContactIdOrderBy>>;
  where?: InputMaybe<DbOneWayMappingFromLeadIdToContactIdBoolExp>;
};

export type SubscriptionRootDbOneWayMappingFromLeadIdToContactIdStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbOneWayMappingFromLeadIdToContactIdStreamCursorInput>>;
  where?: InputMaybe<DbOneWayMappingFromLeadIdToContactIdBoolExp>;
};

export type SubscriptionRootDbOptimizelyClientArgs = {
  distinctOn?: InputMaybe<Array<DbOptimizelyClientSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbOptimizelyClientOrderBy>>;
  where?: InputMaybe<DbOptimizelyClientBoolExp>;
};

export type SubscriptionRootDbOptimizelyClientByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbOptimizelyClientStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbOptimizelyClientStreamCursorInput>>;
  where?: InputMaybe<DbOptimizelyClientBoolExp>;
};

export type SubscriptionRootDbPotentialDuplicateContactArgs = {
  distinctOn?: InputMaybe<Array<DbPotentialDuplicateContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbPotentialDuplicateContactOrderBy>>;
  where?: InputMaybe<DbPotentialDuplicateContactBoolExp>;
};

export type SubscriptionRootDbPotentialDuplicateContactAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbPotentialDuplicateContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbPotentialDuplicateContactOrderBy>>;
  where?: InputMaybe<DbPotentialDuplicateContactBoolExp>;
};

export type SubscriptionRootDbPotentialDuplicateContactByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbPotentialDuplicateContactStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbPotentialDuplicateContactStreamCursorInput>>;
  where?: InputMaybe<DbPotentialDuplicateContactBoolExp>;
};

export type SubscriptionRootDbPrintedDocumentActivityArgs = {
  distinctOn?: InputMaybe<Array<DbPrintedDocumentActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbPrintedDocumentActivityOrderBy>>;
  where?: InputMaybe<DbPrintedDocumentActivityBoolExp>;
};

export type SubscriptionRootDbPrintedDocumentActivityByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbPrintedDocumentActivityStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbPrintedDocumentActivityStreamCursorInput>>;
  where?: InputMaybe<DbPrintedDocumentActivityBoolExp>;
};

export type SubscriptionRootDbPropertyLoungeArgs = {
  distinctOn?: InputMaybe<Array<DbPropertyLoungeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbPropertyLoungeOrderBy>>;
  where?: InputMaybe<DbPropertyLoungeBoolExp>;
};

export type SubscriptionRootDbPropertyLoungeByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbPropertyLoungeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbPropertyLoungeStreamCursorInput>>;
  where?: InputMaybe<DbPropertyLoungeBoolExp>;
};

export type SubscriptionRootDbQualificationFormLinkArgs = {
  distinctOn?: InputMaybe<Array<DbQualificationFormLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbQualificationFormLinkOrderBy>>;
  where?: InputMaybe<DbQualificationFormLinkBoolExp>;
};

export type SubscriptionRootDbQualificationFormLinkAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbQualificationFormLinkSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbQualificationFormLinkOrderBy>>;
  where?: InputMaybe<DbQualificationFormLinkBoolExp>;
};

export type SubscriptionRootDbQualificationFormLinkByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbQualificationFormLinkStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbQualificationFormLinkStreamCursorInput>>;
  where?: InputMaybe<DbQualificationFormLinkBoolExp>;
};

export type SubscriptionRootDbQualiformLeadIntentArgs = {
  distinctOn?: InputMaybe<Array<DbQualiformLeadIntentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbQualiformLeadIntentOrderBy>>;
  where?: InputMaybe<DbQualiformLeadIntentBoolExp>;
};

export type SubscriptionRootDbQualiformLeadIntentByPkArgs = {
  intentType: IntentTypeEnum;
  shopId: Scalars['String'];
};

export type SubscriptionRootDbQualiformLeadIntentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbQualiformLeadIntentStreamCursorInput>>;
  where?: InputMaybe<DbQualiformLeadIntentBoolExp>;
};

export type SubscriptionRootDbQualiformLeadSourceArgs = {
  distinctOn?: InputMaybe<Array<DbQualiformLeadSourceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbQualiformLeadSourceOrderBy>>;
  where?: InputMaybe<DbQualiformLeadSourceBoolExp>;
};

export type SubscriptionRootDbQualiformLeadSourceByPkArgs = {
  leadSource: LeadSourceEnum;
  shopId: Scalars['String'];
};

export type SubscriptionRootDbQualiformLeadSourceStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbQualiformLeadSourceStreamCursorInput>>;
  where?: InputMaybe<DbQualiformLeadSourceBoolExp>;
};

export type SubscriptionRootDbQualiformMandatoryFieldArgs = {
  distinctOn?: InputMaybe<Array<DbQualiformMandatoryFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbQualiformMandatoryFieldOrderBy>>;
  where?: InputMaybe<DbQualiformMandatoryFieldBoolExp>;
};

export type SubscriptionRootDbQualiformMandatoryFieldByPkArgs = {
  fieldName: QualiformFieldsEnum;
  shopId: Scalars['String'];
};

export type SubscriptionRootDbQualiformMandatoryFieldStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbQualiformMandatoryFieldStreamCursorInput>>;
  where?: InputMaybe<DbQualiformMandatoryFieldBoolExp>;
};

export type SubscriptionRootDbQualiformShopSettingArgs = {
  distinctOn?: InputMaybe<Array<DbQualiformShopSettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbQualiformShopSettingOrderBy>>;
  where?: InputMaybe<DbQualiformShopSettingBoolExp>;
};

export type SubscriptionRootDbQualiformShopSettingByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbQualiformShopSettingStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbQualiformShopSettingStreamCursorInput>>;
  where?: InputMaybe<DbQualiformShopSettingBoolExp>;
};

export type SubscriptionRootDbReferralArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

export type SubscriptionRootDbReferralActivityArgs = {
  distinctOn?: InputMaybe<Array<DbReferralActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralActivityOrderBy>>;
  where?: InputMaybe<DbReferralActivityBoolExp>;
};

export type SubscriptionRootDbReferralActivityByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbReferralActivityStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbReferralActivityStreamCursorInput>>;
  where?: InputMaybe<DbReferralActivityBoolExp>;
};

export type SubscriptionRootDbReferralAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbReferralSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbReferralOrderBy>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

export type SubscriptionRootDbReferralByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbReferralStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbReferralStreamCursorInput>>;
  where?: InputMaybe<DbReferralBoolExp>;
};

export type SubscriptionRootDbSellerRentOutSpecificationArgs = {
  distinctOn?: InputMaybe<Array<DbSellerRentOutSpecificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSellerRentOutSpecificationOrderBy>>;
  where?: InputMaybe<DbSellerRentOutSpecificationBoolExp>;
};

export type SubscriptionRootDbSellerRentOutSpecificationByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbSellerRentOutSpecificationCdfArgs = {
  distinctOn?: InputMaybe<Array<DbSellerRentOutSpecificationCdfSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSellerRentOutSpecificationCdfOrderBy>>;
  where?: InputMaybe<DbSellerRentOutSpecificationCdfBoolExp>;
};

export type SubscriptionRootDbSellerRentOutSpecificationCdfByPkArgs = {
  cdfId: Scalars['String'];
  srsId: Scalars['String'];
};

export type SubscriptionRootDbSellerRentOutSpecificationCdfStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbSellerRentOutSpecificationCdfStreamCursorInput>>;
  where?: InputMaybe<DbSellerRentOutSpecificationCdfBoolExp>;
};

export type SubscriptionRootDbSellerRentOutSpecificationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbSellerRentOutSpecificationStreamCursorInput>>;
  where?: InputMaybe<DbSellerRentOutSpecificationBoolExp>;
};

export type SubscriptionRootDbSessionArgs = {
  distinctOn?: InputMaybe<Array<DbSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSessionOrderBy>>;
  where?: InputMaybe<DbSessionBoolExp>;
};

export type SubscriptionRootDbSessionAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbSessionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSessionOrderBy>>;
  where?: InputMaybe<DbSessionBoolExp>;
};

export type SubscriptionRootDbSessionByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbSessionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbSessionStreamCursorInput>>;
  where?: InputMaybe<DbSessionBoolExp>;
};

export type SubscriptionRootDbShopArgs = {
  distinctOn?: InputMaybe<Array<DbShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopOrderBy>>;
  where?: InputMaybe<DbShopBoolExp>;
};

export type SubscriptionRootDbShopAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopOrderBy>>;
  where?: InputMaybe<DbShopBoolExp>;
};

export type SubscriptionRootDbShopByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopCommissionContractArgs = {
  distinctOn?: InputMaybe<Array<DbShopCommissionContractSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopCommissionContractOrderBy>>;
  where?: InputMaybe<DbShopCommissionContractBoolExp>;
};

export type SubscriptionRootDbShopCommissionContractByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopCommissionContractStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopCommissionContractStreamCursorInput>>;
  where?: InputMaybe<DbShopCommissionContractBoolExp>;
};

export type SubscriptionRootDbShopGroupArgs = {
  distinctOn?: InputMaybe<Array<DbShopGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopGroupOrderBy>>;
  where?: InputMaybe<DbShopGroupBoolExp>;
};

export type SubscriptionRootDbShopGroupAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopGroupOrderBy>>;
  where?: InputMaybe<DbShopGroupBoolExp>;
};

export type SubscriptionRootDbShopGroupByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopGroupStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopGroupStreamCursorInput>>;
  where?: InputMaybe<DbShopGroupBoolExp>;
};

export type SubscriptionRootDbShopManagedConsentArgs = {
  distinctOn?: InputMaybe<Array<DbShopManagedConsentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopManagedConsentOrderBy>>;
  where?: InputMaybe<DbShopManagedConsentBoolExp>;
};

export type SubscriptionRootDbShopManagedConsentByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopManagedConsentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopManagedConsentStreamCursorInput>>;
  where?: InputMaybe<DbShopManagedConsentBoolExp>;
};

export type SubscriptionRootDbShopProfileArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileOrderBy>>;
  where?: InputMaybe<DbShopProfileBoolExp>;
};

export type SubscriptionRootDbShopProfileAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileOrderBy>>;
  where?: InputMaybe<DbShopProfileBoolExp>;
};

export type SubscriptionRootDbShopProfileByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopProfileCareerArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileCareerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileCareerOrderBy>>;
  where?: InputMaybe<DbShopProfileCareerBoolExp>;
};

export type SubscriptionRootDbShopProfileCareerAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileCareerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileCareerOrderBy>>;
  where?: InputMaybe<DbShopProfileCareerBoolExp>;
};

export type SubscriptionRootDbShopProfileCareerByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopProfileCareerStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopProfileCareerStreamCursorInput>>;
  where?: InputMaybe<DbShopProfileCareerBoolExp>;
};

export type SubscriptionRootDbShopProfileCityDistrictArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileCityDistrictSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileCityDistrictOrderBy>>;
  where?: InputMaybe<DbShopProfileCityDistrictBoolExp>;
};

export type SubscriptionRootDbShopProfileCityDistrictAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileCityDistrictSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileCityDistrictOrderBy>>;
  where?: InputMaybe<DbShopProfileCityDistrictBoolExp>;
};

export type SubscriptionRootDbShopProfileCityDistrictByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopProfileCityDistrictStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopProfileCityDistrictStreamCursorInput>>;
  where?: InputMaybe<DbShopProfileCityDistrictBoolExp>;
};

export type SubscriptionRootDbShopProfileCustomerMailsArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileCustomerMailsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileCustomerMailsOrderBy>>;
  where?: InputMaybe<DbShopProfileCustomerMailsBoolExp>;
};

export type SubscriptionRootDbShopProfileCustomerMailsByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopProfileCustomerMailsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopProfileCustomerMailsStreamCursorInput>>;
  where?: InputMaybe<DbShopProfileCustomerMailsBoolExp>;
};

export type SubscriptionRootDbShopProfileDescriptionArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileDescriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileDescriptionOrderBy>>;
  where?: InputMaybe<DbShopProfileDescriptionBoolExp>;
};

export type SubscriptionRootDbShopProfileDescriptionAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileDescriptionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileDescriptionOrderBy>>;
  where?: InputMaybe<DbShopProfileDescriptionBoolExp>;
};

export type SubscriptionRootDbShopProfileDescriptionByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopProfileDescriptionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopProfileDescriptionStreamCursorInput>>;
  where?: InputMaybe<DbShopProfileDescriptionBoolExp>;
};

export type SubscriptionRootDbShopProfileExpertiseArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileExpertiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileExpertiseOrderBy>>;
  where?: InputMaybe<DbShopProfileExpertiseBoolExp>;
};

export type SubscriptionRootDbShopProfileExpertiseAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileExpertiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileExpertiseOrderBy>>;
  where?: InputMaybe<DbShopProfileExpertiseBoolExp>;
};

export type SubscriptionRootDbShopProfileExpertiseByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopProfileExpertiseStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopProfileExpertiseStreamCursorInput>>;
  where?: InputMaybe<DbShopProfileExpertiseBoolExp>;
};

export type SubscriptionRootDbShopProfileFeaturedAgentArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileFeaturedAgentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileFeaturedAgentOrderBy>>;
  where?: InputMaybe<DbShopProfileFeaturedAgentBoolExp>;
};

export type SubscriptionRootDbShopProfileFeaturedAgentByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopProfileFeaturedAgentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopProfileFeaturedAgentStreamCursorInput>>;
  where?: InputMaybe<DbShopProfileFeaturedAgentBoolExp>;
};

export type SubscriptionRootDbShopProfileFeaturedContentArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileFeaturedContentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileFeaturedContentOrderBy>>;
  where?: InputMaybe<DbShopProfileFeaturedContentBoolExp>;
};

export type SubscriptionRootDbShopProfileFeaturedContentByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopProfileFeaturedContentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopProfileFeaturedContentStreamCursorInput>>;
  where?: InputMaybe<DbShopProfileFeaturedContentBoolExp>;
};

export type SubscriptionRootDbShopProfileFeaturedContentTeaserArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileFeaturedContentTeaserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileFeaturedContentTeaserOrderBy>>;
  where?: InputMaybe<DbShopProfileFeaturedContentTeaserBoolExp>;
};

export type SubscriptionRootDbShopProfileFeaturedContentTeaserByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopProfileFeaturedContentTeaserStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopProfileFeaturedContentTeaserStreamCursorInput>>;
  where?: InputMaybe<DbShopProfileFeaturedContentTeaserBoolExp>;
};

export type SubscriptionRootDbShopProfileFormerUrlArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileFormerUrlSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileFormerUrlOrderBy>>;
  where?: InputMaybe<DbShopProfileFormerUrlBoolExp>;
};

export type SubscriptionRootDbShopProfileFormerUrlAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileFormerUrlSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileFormerUrlOrderBy>>;
  where?: InputMaybe<DbShopProfileFormerUrlBoolExp>;
};

export type SubscriptionRootDbShopProfileFormerUrlByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopProfileFormerUrlStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopProfileFormerUrlStreamCursorInput>>;
  where?: InputMaybe<DbShopProfileFormerUrlBoolExp>;
};

export type SubscriptionRootDbShopProfileImageArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileImageOrderBy>>;
  where?: InputMaybe<DbShopProfileImageBoolExp>;
};

export type SubscriptionRootDbShopProfileImageAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileImageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileImageOrderBy>>;
  where?: InputMaybe<DbShopProfileImageBoolExp>;
};

export type SubscriptionRootDbShopProfileImageByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopProfileImageStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopProfileImageStreamCursorInput>>;
  where?: InputMaybe<DbShopProfileImageBoolExp>;
};

export type SubscriptionRootDbShopProfileImprintArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileImprintSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileImprintOrderBy>>;
  where?: InputMaybe<DbShopProfileImprintBoolExp>;
};

export type SubscriptionRootDbShopProfileImprintAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileImprintSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileImprintOrderBy>>;
  where?: InputMaybe<DbShopProfileImprintBoolExp>;
};

export type SubscriptionRootDbShopProfileImprintByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopProfileImprintStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopProfileImprintStreamCursorInput>>;
  where?: InputMaybe<DbShopProfileImprintBoolExp>;
};

export type SubscriptionRootDbShopProfilePrivacyPolicyArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfilePrivacyPolicySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfilePrivacyPolicyOrderBy>>;
  where?: InputMaybe<DbShopProfilePrivacyPolicyBoolExp>;
};

export type SubscriptionRootDbShopProfilePrivacyPolicyAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfilePrivacyPolicySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfilePrivacyPolicyOrderBy>>;
  where?: InputMaybe<DbShopProfilePrivacyPolicyBoolExp>;
};

export type SubscriptionRootDbShopProfilePrivacyPolicyByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopProfilePrivacyPolicyStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopProfilePrivacyPolicyStreamCursorInput>>;
  where?: InputMaybe<DbShopProfilePrivacyPolicyBoolExp>;
};

export type SubscriptionRootDbShopProfilePropertyPreviewArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfilePropertyPreviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfilePropertyPreviewOrderBy>>;
  where?: InputMaybe<DbShopProfilePropertyPreviewBoolExp>;
};

export type SubscriptionRootDbShopProfilePropertyPreviewAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfilePropertyPreviewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfilePropertyPreviewOrderBy>>;
  where?: InputMaybe<DbShopProfilePropertyPreviewBoolExp>;
};

export type SubscriptionRootDbShopProfilePropertyPreviewByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopProfilePropertyPreviewStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopProfilePropertyPreviewStreamCursorInput>>;
  where?: InputMaybe<DbShopProfilePropertyPreviewBoolExp>;
};

export type SubscriptionRootDbShopProfileQuoteArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileQuoteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileQuoteOrderBy>>;
  where?: InputMaybe<DbShopProfileQuoteBoolExp>;
};

export type SubscriptionRootDbShopProfileQuoteAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileQuoteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileQuoteOrderBy>>;
  where?: InputMaybe<DbShopProfileQuoteBoolExp>;
};

export type SubscriptionRootDbShopProfileQuoteByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopProfileQuoteStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopProfileQuoteStreamCursorInput>>;
  where?: InputMaybe<DbShopProfileQuoteBoolExp>;
};

export type SubscriptionRootDbShopProfileSeoTrackingArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileSeoTrackingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileSeoTrackingOrderBy>>;
  where?: InputMaybe<DbShopProfileSeoTrackingBoolExp>;
};

export type SubscriptionRootDbShopProfileSeoTrackingByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopProfileSeoTrackingStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopProfileSeoTrackingStreamCursorInput>>;
  where?: InputMaybe<DbShopProfileSeoTrackingBoolExp>;
};

export type SubscriptionRootDbShopProfileSocialMediaProfilesArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileSocialMediaProfilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileSocialMediaProfilesOrderBy>>;
  where?: InputMaybe<DbShopProfileSocialMediaProfilesBoolExp>;
};

export type SubscriptionRootDbShopProfileSocialMediaProfilesAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileSocialMediaProfilesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileSocialMediaProfilesOrderBy>>;
  where?: InputMaybe<DbShopProfileSocialMediaProfilesBoolExp>;
};

export type SubscriptionRootDbShopProfileSocialMediaProfilesByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopProfileSocialMediaProfilesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopProfileSocialMediaProfilesStreamCursorInput>>;
  where?: InputMaybe<DbShopProfileSocialMediaProfilesBoolExp>;
};

export type SubscriptionRootDbShopProfileSpokenLanguageArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileSpokenLanguageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileSpokenLanguageOrderBy>>;
  where?: InputMaybe<DbShopProfileSpokenLanguageBoolExp>;
};

export type SubscriptionRootDbShopProfileSpokenLanguageAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileSpokenLanguageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileSpokenLanguageOrderBy>>;
  where?: InputMaybe<DbShopProfileSpokenLanguageBoolExp>;
};

export type SubscriptionRootDbShopProfileSpokenLanguageByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopProfileSpokenLanguageStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopProfileSpokenLanguageStreamCursorInput>>;
  where?: InputMaybe<DbShopProfileSpokenLanguageBoolExp>;
};

export type SubscriptionRootDbShopProfileStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopProfileStreamCursorInput>>;
  where?: InputMaybe<DbShopProfileBoolExp>;
};

export type SubscriptionRootDbShopProfileTeamArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileTeamOrderBy>>;
  where?: InputMaybe<DbShopProfileTeamBoolExp>;
};

export type SubscriptionRootDbShopProfileTeamByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopProfileTeamStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopProfileTeamStreamCursorInput>>;
  where?: InputMaybe<DbShopProfileTeamBoolExp>;
};

export type SubscriptionRootDbShopProfileTermsAndConditionsArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileTermsAndConditionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileTermsAndConditionsOrderBy>>;
  where?: InputMaybe<DbShopProfileTermsAndConditionsBoolExp>;
};

export type SubscriptionRootDbShopProfileTermsAndConditionsByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopProfileTermsAndConditionsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopProfileTermsAndConditionsStreamCursorInput>>;
  where?: InputMaybe<DbShopProfileTermsAndConditionsBoolExp>;
};

export type SubscriptionRootDbShopProfileWithdrawalPolicyArgs = {
  distinctOn?: InputMaybe<Array<DbShopProfileWithdrawalPolicySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopProfileWithdrawalPolicyOrderBy>>;
  where?: InputMaybe<DbShopProfileWithdrawalPolicyBoolExp>;
};

export type SubscriptionRootDbShopProfileWithdrawalPolicyByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopProfileWithdrawalPolicyStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopProfileWithdrawalPolicyStreamCursorInput>>;
  where?: InputMaybe<DbShopProfileWithdrawalPolicyBoolExp>;
};

export type SubscriptionRootDbShopRightOfWithdrawalArgs = {
  distinctOn?: InputMaybe<Array<DbShopRightOfWithdrawalSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopRightOfWithdrawalOrderBy>>;
  where?: InputMaybe<DbShopRightOfWithdrawalBoolExp>;
};

export type SubscriptionRootDbShopRightOfWithdrawalByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopRightOfWithdrawalStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopRightOfWithdrawalStreamCursorInput>>;
  where?: InputMaybe<DbShopRightOfWithdrawalBoolExp>;
};

export type SubscriptionRootDbShopSettingArgs = {
  distinctOn?: InputMaybe<Array<DbShopSettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopSettingOrderBy>>;
  where?: InputMaybe<DbShopSettingBoolExp>;
};

export type SubscriptionRootDbShopSettingAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbShopSettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbShopSettingOrderBy>>;
  where?: InputMaybe<DbShopSettingBoolExp>;
};

export type SubscriptionRootDbShopSettingByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbShopSettingStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopSettingStreamCursorInput>>;
  where?: InputMaybe<DbShopSettingBoolExp>;
};

export type SubscriptionRootDbShopStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbShopStreamCursorInput>>;
  where?: InputMaybe<DbShopBoolExp>;
};

export type SubscriptionRootDbSnapshotBuyerToRentSpecificationArgs = {
  distinctOn?: InputMaybe<Array<DbSnapshotBuyerToRentSpecificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSnapshotBuyerToRentSpecificationOrderBy>>;
  where?: InputMaybe<DbSnapshotBuyerToRentSpecificationBoolExp>;
};

export type SubscriptionRootDbSnapshotBuyerToRentSpecificationAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbSnapshotBuyerToRentSpecificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSnapshotBuyerToRentSpecificationOrderBy>>;
  where?: InputMaybe<DbSnapshotBuyerToRentSpecificationBoolExp>;
};

export type SubscriptionRootDbSnapshotBuyerToRentSpecificationByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbSnapshotBuyerToRentSpecificationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbSnapshotBuyerToRentSpecificationStreamCursorInput>>;
  where?: InputMaybe<DbSnapshotBuyerToRentSpecificationBoolExp>;
};

export type SubscriptionRootDbSnapshotCustomSearchDemandPolygonArgs = {
  distinctOn?: InputMaybe<Array<DbSnapshotCustomSearchDemandPolygonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSnapshotCustomSearchDemandPolygonOrderBy>>;
  where?: InputMaybe<DbSnapshotCustomSearchDemandPolygonBoolExp>;
};

export type SubscriptionRootDbSnapshotCustomSearchDemandPolygonByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbSnapshotCustomSearchDemandPolygonStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbSnapshotCustomSearchDemandPolygonStreamCursorInput>>;
  where?: InputMaybe<DbSnapshotCustomSearchDemandPolygonBoolExp>;
};

export type SubscriptionRootDbSnapshotSellerRentOutSpecificationArgs = {
  distinctOn?: InputMaybe<Array<DbSnapshotSellerRentOutSpecificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSnapshotSellerRentOutSpecificationOrderBy>>;
  where?: InputMaybe<DbSnapshotSellerRentOutSpecificationBoolExp>;
};

export type SubscriptionRootDbSnapshotSellerRentOutSpecificationAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbSnapshotSellerRentOutSpecificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbSnapshotSellerRentOutSpecificationOrderBy>>;
  where?: InputMaybe<DbSnapshotSellerRentOutSpecificationBoolExp>;
};

export type SubscriptionRootDbSnapshotSellerRentOutSpecificationByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbSnapshotSellerRentOutSpecificationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbSnapshotSellerRentOutSpecificationStreamCursorInput>>;
  where?: InputMaybe<DbSnapshotSellerRentOutSpecificationBoolExp>;
};

export type SubscriptionRootDbTagArgs = {
  distinctOn?: InputMaybe<Array<DbTagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTagOrderBy>>;
  where?: InputMaybe<DbTagBoolExp>;
};

export type SubscriptionRootDbTagByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbTagStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbTagStreamCursorInput>>;
  where?: InputMaybe<DbTagBoolExp>;
};

export type SubscriptionRootDbTaskActivityArgs = {
  distinctOn?: InputMaybe<Array<DbTaskActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTaskActivityOrderBy>>;
  where?: InputMaybe<DbTaskActivityBoolExp>;
};

export type SubscriptionRootDbTaskActivityByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbTaskActivityStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbTaskActivityStreamCursorInput>>;
  where?: InputMaybe<DbTaskActivityBoolExp>;
};

export type SubscriptionRootDbTaskAgentArgs = {
  distinctOn?: InputMaybe<Array<DbTaskAgentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTaskAgentOrderBy>>;
  where?: InputMaybe<DbTaskAgentBoolExp>;
};

export type SubscriptionRootDbTaskAgentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbTaskAgentStreamCursorInput>>;
  where?: InputMaybe<DbTaskAgentBoolExp>;
};

export type SubscriptionRootDbTaskContactArgs = {
  distinctOn?: InputMaybe<Array<DbTaskContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTaskContactOrderBy>>;
  where?: InputMaybe<DbTaskContactBoolExp>;
};

export type SubscriptionRootDbTaskContactStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbTaskContactStreamCursorInput>>;
  where?: InputMaybe<DbTaskContactBoolExp>;
};

export type SubscriptionRootDbTaskLeadArgs = {
  distinctOn?: InputMaybe<Array<DbTaskLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTaskLeadOrderBy>>;
  where?: InputMaybe<DbTaskLeadBoolExp>;
};

export type SubscriptionRootDbTaskLeadStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbTaskLeadStreamCursorInput>>;
  where?: InputMaybe<DbTaskLeadBoolExp>;
};

export type SubscriptionRootDbTeamArgs = {
  distinctOn?: InputMaybe<Array<DbTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTeamOrderBy>>;
  where?: InputMaybe<DbTeamBoolExp>;
};

export type SubscriptionRootDbTeamAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbTeamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTeamOrderBy>>;
  where?: InputMaybe<DbTeamBoolExp>;
};

export type SubscriptionRootDbTeamByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbTeamStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbTeamStreamCursorInput>>;
  where?: InputMaybe<DbTeamBoolExp>;
};

export type SubscriptionRootDbTrackingLeadArgs = {
  distinctOn?: InputMaybe<Array<DbTrackingLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTrackingLeadOrderBy>>;
  where?: InputMaybe<DbTrackingLeadBoolExp>;
};

export type SubscriptionRootDbTrackingLeadAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbTrackingLeadSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTrackingLeadOrderBy>>;
  where?: InputMaybe<DbTrackingLeadBoolExp>;
};

export type SubscriptionRootDbTrackingLeadByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbTrackingLeadStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbTrackingLeadStreamCursorInput>>;
  where?: InputMaybe<DbTrackingLeadBoolExp>;
};

export type SubscriptionRootDbTwilioConfigurationArgs = {
  distinctOn?: InputMaybe<Array<DbTwilioConfigurationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTwilioConfigurationOrderBy>>;
  where?: InputMaybe<DbTwilioConfigurationBoolExp>;
};

export type SubscriptionRootDbTwilioConfigurationAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbTwilioConfigurationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTwilioConfigurationOrderBy>>;
  where?: InputMaybe<DbTwilioConfigurationBoolExp>;
};

export type SubscriptionRootDbTwilioConfigurationByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbTwilioConfigurationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbTwilioConfigurationStreamCursorInput>>;
  where?: InputMaybe<DbTwilioConfigurationBoolExp>;
};

export type SubscriptionRootDbUserAdminAssignmentArgs = {
  distinctOn?: InputMaybe<Array<DbUserAdminAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserAdminAssignmentOrderBy>>;
  where?: InputMaybe<DbUserAdminAssignmentBoolExp>;
};

export type SubscriptionRootDbUserAdminAssignmentAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbUserAdminAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserAdminAssignmentOrderBy>>;
  where?: InputMaybe<DbUserAdminAssignmentBoolExp>;
};

export type SubscriptionRootDbUserAdminAssignmentByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbUserAdminAssignmentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbUserAdminAssignmentStreamCursorInput>>;
  where?: InputMaybe<DbUserAdminAssignmentBoolExp>;
};

export type SubscriptionRootDbUserShopAssignmentArgs = {
  distinctOn?: InputMaybe<Array<DbUserShopAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserShopAssignmentOrderBy>>;
  where?: InputMaybe<DbUserShopAssignmentBoolExp>;
};

export type SubscriptionRootDbUserShopAssignmentAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbUserShopAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserShopAssignmentOrderBy>>;
  where?: InputMaybe<DbUserShopAssignmentBoolExp>;
};

export type SubscriptionRootDbUserShopAssignmentByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbUserShopAssignmentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbUserShopAssignmentStreamCursorInput>>;
  where?: InputMaybe<DbUserShopAssignmentBoolExp>;
};

export type SubscriptionRootDbUserTeamAssignmentArgs = {
  distinctOn?: InputMaybe<Array<DbUserTeamAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserTeamAssignmentOrderBy>>;
  where?: InputMaybe<DbUserTeamAssignmentBoolExp>;
};

export type SubscriptionRootDbUserTeamAssignmentAggregateArgs = {
  distinctOn?: InputMaybe<Array<DbUserTeamAssignmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUserTeamAssignmentOrderBy>>;
  where?: InputMaybe<DbUserTeamAssignmentBoolExp>;
};

export type SubscriptionRootDbUserTeamAssignmentByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbUserTeamAssignmentStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbUserTeamAssignmentStreamCursorInput>>;
  where?: InputMaybe<DbUserTeamAssignmentBoolExp>;
};

export type SubscriptionRootDbUtmParamArgs = {
  distinctOn?: InputMaybe<Array<DbUtmParamSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbUtmParamOrderBy>>;
  where?: InputMaybe<DbUtmParamBoolExp>;
};

export type SubscriptionRootDbUtmParamByPkArgs = {
  id: Scalars['String'];
};

export type SubscriptionRootDbUtmParamStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<DbUtmParamStreamCursorInput>>;
  where?: InputMaybe<DbUtmParamBoolExp>;
};

export type SubscriptionRootEnumsActivityTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsActivityTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsActivityTypeOrderBy>>;
  where?: InputMaybe<EnumsActivityTypeBoolExp>;
};

export type SubscriptionRootEnumsActivityTypeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsActivityTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsActivityTypeStreamCursorInput>>;
  where?: InputMaybe<EnumsActivityTypeBoolExp>;
};

export type SubscriptionRootEnumsAddressFormatArgs = {
  distinctOn?: InputMaybe<Array<EnumsAddressFormatSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsAddressFormatOrderBy>>;
  where?: InputMaybe<EnumsAddressFormatBoolExp>;
};

export type SubscriptionRootEnumsAddressFormatByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsAddressFormatStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsAddressFormatStreamCursorInput>>;
  where?: InputMaybe<EnumsAddressFormatBoolExp>;
};

export type SubscriptionRootEnumsAddressTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsAddressTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsAddressTypeOrderBy>>;
  where?: InputMaybe<EnumsAddressTypeBoolExp>;
};

export type SubscriptionRootEnumsAddressTypeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsAddressTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsAddressTypeStreamCursorInput>>;
  where?: InputMaybe<EnumsAddressTypeBoolExp>;
};

export type SubscriptionRootEnumsAppointmentTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsAppointmentTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsAppointmentTypeOrderBy>>;
  where?: InputMaybe<EnumsAppointmentTypeBoolExp>;
};

export type SubscriptionRootEnumsAppointmentTypeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsAppointmentTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsAppointmentTypeStreamCursorInput>>;
  where?: InputMaybe<EnumsAppointmentTypeBoolExp>;
};

export type SubscriptionRootEnumsAssetConditionArgs = {
  distinctOn?: InputMaybe<Array<EnumsAssetConditionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsAssetConditionOrderBy>>;
  where?: InputMaybe<EnumsAssetConditionBoolExp>;
};

export type SubscriptionRootEnumsAssetConditionByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsAssetConditionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsAssetConditionStreamCursorInput>>;
  where?: InputMaybe<EnumsAssetConditionBoolExp>;
};

export type SubscriptionRootEnumsAssetSubTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsAssetSubTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsAssetSubTypeOrderBy>>;
  where?: InputMaybe<EnumsAssetSubTypeBoolExp>;
};

export type SubscriptionRootEnumsAssetSubTypeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsAssetSubTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsAssetSubTypeStreamCursorInput>>;
  where?: InputMaybe<EnumsAssetSubTypeBoolExp>;
};

export type SubscriptionRootEnumsAssetTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsAssetTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsAssetTypeOrderBy>>;
  where?: InputMaybe<EnumsAssetTypeBoolExp>;
};

export type SubscriptionRootEnumsAssetTypeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsAssetTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsAssetTypeStreamCursorInput>>;
  where?: InputMaybe<EnumsAssetTypeBoolExp>;
};

export type SubscriptionRootEnumsAssetVariationArgs = {
  distinctOn?: InputMaybe<Array<EnumsAssetVariationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsAssetVariationOrderBy>>;
  where?: InputMaybe<EnumsAssetVariationBoolExp>;
};

export type SubscriptionRootEnumsAssetVariationByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsAssetVariationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsAssetVariationStreamCursorInput>>;
  where?: InputMaybe<EnumsAssetVariationBoolExp>;
};

export type SubscriptionRootEnumsBaseActivityCategoryArgs = {
  distinctOn?: InputMaybe<Array<EnumsBaseActivityCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsBaseActivityCategoryOrderBy>>;
  where?: InputMaybe<EnumsBaseActivityCategoryBoolExp>;
};

export type SubscriptionRootEnumsBaseActivityCategoryByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsBaseActivityCategoryStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsBaseActivityCategoryStreamCursorInput>>;
  where?: InputMaybe<EnumsBaseActivityCategoryBoolExp>;
};

export type SubscriptionRootEnumsCallDirectionArgs = {
  distinctOn?: InputMaybe<Array<EnumsCallDirectionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsCallDirectionOrderBy>>;
  where?: InputMaybe<EnumsCallDirectionBoolExp>;
};

export type SubscriptionRootEnumsCallDirectionByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsCallDirectionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsCallDirectionStreamCursorInput>>;
  where?: InputMaybe<EnumsCallDirectionBoolExp>;
};

export type SubscriptionRootEnumsCallOutcomeArgs = {
  distinctOn?: InputMaybe<Array<EnumsCallOutcomeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsCallOutcomeOrderBy>>;
  where?: InputMaybe<EnumsCallOutcomeBoolExp>;
};

export type SubscriptionRootEnumsCallOutcomeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsCallOutcomeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsCallOutcomeStreamCursorInput>>;
  where?: InputMaybe<EnumsCallOutcomeBoolExp>;
};

export type SubscriptionRootEnumsCallStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsCallStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsCallStatusOrderBy>>;
  where?: InputMaybe<EnumsCallStatusBoolExp>;
};

export type SubscriptionRootEnumsCallStatusByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsCallStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsCallStatusStreamCursorInput>>;
  where?: InputMaybe<EnumsCallStatusBoolExp>;
};

export type SubscriptionRootEnumsConsentEventStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsConsentEventStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsConsentEventStatusOrderBy>>;
  where?: InputMaybe<EnumsConsentEventStatusBoolExp>;
};

export type SubscriptionRootEnumsConsentEventStatusByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsConsentEventStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsConsentEventStatusStreamCursorInput>>;
  where?: InputMaybe<EnumsConsentEventStatusBoolExp>;
};

export type SubscriptionRootEnumsConsentEventTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsConsentEventTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsConsentEventTypeOrderBy>>;
  where?: InputMaybe<EnumsConsentEventTypeBoolExp>;
};

export type SubscriptionRootEnumsConsentEventTypeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsConsentEventTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsConsentEventTypeStreamCursorInput>>;
  where?: InputMaybe<EnumsConsentEventTypeBoolExp>;
};

export type SubscriptionRootEnumsConsentSourceArgs = {
  distinctOn?: InputMaybe<Array<EnumsConsentSourceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsConsentSourceOrderBy>>;
  where?: InputMaybe<EnumsConsentSourceBoolExp>;
};

export type SubscriptionRootEnumsConsentSourceByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsConsentSourceStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsConsentSourceStreamCursorInput>>;
  where?: InputMaybe<EnumsConsentSourceBoolExp>;
};

export type SubscriptionRootEnumsConsentStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsConsentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsConsentStatusOrderBy>>;
  where?: InputMaybe<EnumsConsentStatusBoolExp>;
};

export type SubscriptionRootEnumsConsentStatusByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsConsentStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsConsentStatusStreamCursorInput>>;
  where?: InputMaybe<EnumsConsentStatusBoolExp>;
};

export type SubscriptionRootEnumsConsentTemplateTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsConsentTemplateTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsConsentTemplateTypeOrderBy>>;
  where?: InputMaybe<EnumsConsentTemplateTypeBoolExp>;
};

export type SubscriptionRootEnumsConsentTemplateTypeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsConsentTemplateTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsConsentTemplateTypeStreamCursorInput>>;
  where?: InputMaybe<EnumsConsentTemplateTypeBoolExp>;
};

export type SubscriptionRootEnumsConsentTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsConsentTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsConsentTypeOrderBy>>;
  where?: InputMaybe<EnumsConsentTypeBoolExp>;
};

export type SubscriptionRootEnumsConsentTypeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsConsentTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsConsentTypeStreamCursorInput>>;
  where?: InputMaybe<EnumsConsentTypeBoolExp>;
};

export type SubscriptionRootEnumsContactRelationTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsContactRelationTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsContactRelationTypeOrderBy>>;
  where?: InputMaybe<EnumsContactRelationTypeBoolExp>;
};

export type SubscriptionRootEnumsContactRelationTypeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsContactRelationTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsContactRelationTypeStreamCursorInput>>;
  where?: InputMaybe<EnumsContactRelationTypeBoolExp>;
};

export type SubscriptionRootEnumsContactStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsContactStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsContactStatusOrderBy>>;
  where?: InputMaybe<EnumsContactStatusBoolExp>;
};

export type SubscriptionRootEnumsContactStatusByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsContactStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsContactStatusStreamCursorInput>>;
  where?: InputMaybe<EnumsContactStatusBoolExp>;
};

export type SubscriptionRootEnumsContactTimeArgs = {
  distinctOn?: InputMaybe<Array<EnumsContactTimeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsContactTimeOrderBy>>;
  where?: InputMaybe<EnumsContactTimeBoolExp>;
};

export type SubscriptionRootEnumsContactTimeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsContactTimeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsContactTimeStreamCursorInput>>;
  where?: InputMaybe<EnumsContactTimeBoolExp>;
};

export type SubscriptionRootEnumsContactTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsContactTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsContactTypeOrderBy>>;
  where?: InputMaybe<EnumsContactTypeBoolExp>;
};

export type SubscriptionRootEnumsContactTypeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsContactTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsContactTypeStreamCursorInput>>;
  where?: InputMaybe<EnumsContactTypeBoolExp>;
};

export type SubscriptionRootEnumsContactWayArgs = {
  distinctOn?: InputMaybe<Array<EnumsContactWaySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsContactWayOrderBy>>;
  where?: InputMaybe<EnumsContactWayBoolExp>;
};

export type SubscriptionRootEnumsContactWayByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsContactWayStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsContactWayStreamCursorInput>>;
  where?: InputMaybe<EnumsContactWayBoolExp>;
};

export type SubscriptionRootEnumsCountryArgs = {
  distinctOn?: InputMaybe<Array<EnumsCountrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsCountryOrderBy>>;
  where?: InputMaybe<EnumsCountryBoolExp>;
};

export type SubscriptionRootEnumsCountryByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsCountryStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsCountryStreamCursorInput>>;
  where?: InputMaybe<EnumsCountryBoolExp>;
};

export type SubscriptionRootEnumsCurrencyArgs = {
  distinctOn?: InputMaybe<Array<EnumsCurrencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsCurrencyOrderBy>>;
  where?: InputMaybe<EnumsCurrencyBoolExp>;
};

export type SubscriptionRootEnumsCurrencyByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsCurrencyStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsCurrencyStreamCursorInput>>;
  where?: InputMaybe<EnumsCurrencyBoolExp>;
};

export type SubscriptionRootEnumsCustomViewTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsCustomViewTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsCustomViewTypeOrderBy>>;
  where?: InputMaybe<EnumsCustomViewTypeBoolExp>;
};

export type SubscriptionRootEnumsCustomViewTypeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsCustomViewTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsCustomViewTypeStreamCursorInput>>;
  where?: InputMaybe<EnumsCustomViewTypeBoolExp>;
};

export type SubscriptionRootEnumsEmailAddressTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsEmailAddressTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEmailAddressTypeOrderBy>>;
  where?: InputMaybe<EnumsEmailAddressTypeBoolExp>;
};

export type SubscriptionRootEnumsEmailAddressTypeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsEmailAddressTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsEmailAddressTypeStreamCursorInput>>;
  where?: InputMaybe<EnumsEmailAddressTypeBoolExp>;
};

export type SubscriptionRootEnumsEmailCallbackEventSourceArgs = {
  distinctOn?: InputMaybe<Array<EnumsEmailCallbackEventSourceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEmailCallbackEventSourceOrderBy>>;
  where?: InputMaybe<EnumsEmailCallbackEventSourceBoolExp>;
};

export type SubscriptionRootEnumsEmailCallbackEventSourceByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsEmailCallbackEventSourceStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsEmailCallbackEventSourceStreamCursorInput>>;
  where?: InputMaybe<EnumsEmailCallbackEventSourceBoolExp>;
};

export type SubscriptionRootEnumsEmailDirectionArgs = {
  distinctOn?: InputMaybe<Array<EnumsEmailDirectionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEmailDirectionOrderBy>>;
  where?: InputMaybe<EnumsEmailDirectionBoolExp>;
};

export type SubscriptionRootEnumsEmailDirectionByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsEmailDirectionStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsEmailDirectionStreamCursorInput>>;
  where?: InputMaybe<EnumsEmailDirectionBoolExp>;
};

export type SubscriptionRootEnumsEmailIntegrationScopeArgs = {
  distinctOn?: InputMaybe<Array<EnumsEmailIntegrationScopeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEmailIntegrationScopeOrderBy>>;
  where?: InputMaybe<EnumsEmailIntegrationScopeBoolExp>;
};

export type SubscriptionRootEnumsEmailIntegrationScopeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsEmailIntegrationScopeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsEmailIntegrationScopeStreamCursorInput>>;
  where?: InputMaybe<EnumsEmailIntegrationScopeBoolExp>;
};

export type SubscriptionRootEnumsEmailPlaceholderScopeArgs = {
  distinctOn?: InputMaybe<Array<EnumsEmailPlaceholderScopeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEmailPlaceholderScopeOrderBy>>;
  where?: InputMaybe<EnumsEmailPlaceholderScopeBoolExp>;
};

export type SubscriptionRootEnumsEmailPlaceholderScopeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsEmailPlaceholderScopeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsEmailPlaceholderScopeStreamCursorInput>>;
  where?: InputMaybe<EnumsEmailPlaceholderScopeBoolExp>;
};

export type SubscriptionRootEnumsEmailPlaceholderTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsEmailPlaceholderTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEmailPlaceholderTypeOrderBy>>;
  where?: InputMaybe<EnumsEmailPlaceholderTypeBoolExp>;
};

export type SubscriptionRootEnumsEmailPlaceholderTypeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsEmailPlaceholderTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsEmailPlaceholderTypeStreamCursorInput>>;
  where?: InputMaybe<EnumsEmailPlaceholderTypeBoolExp>;
};

export type SubscriptionRootEnumsEmailSignatureScopeArgs = {
  distinctOn?: InputMaybe<Array<EnumsEmailSignatureScopeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEmailSignatureScopeOrderBy>>;
  where?: InputMaybe<EnumsEmailSignatureScopeBoolExp>;
};

export type SubscriptionRootEnumsEmailSignatureScopeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsEmailSignatureScopeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsEmailSignatureScopeStreamCursorInput>>;
  where?: InputMaybe<EnumsEmailSignatureScopeBoolExp>;
};

export type SubscriptionRootEnumsEmailStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsEmailStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEmailStatusOrderBy>>;
  where?: InputMaybe<EnumsEmailStatusBoolExp>;
};

export type SubscriptionRootEnumsEmailStatusByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsEmailStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsEmailStatusStreamCursorInput>>;
  where?: InputMaybe<EnumsEmailStatusBoolExp>;
};

export type SubscriptionRootEnumsEmailTemplateScopeArgs = {
  distinctOn?: InputMaybe<Array<EnumsEmailTemplateScopeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEmailTemplateScopeOrderBy>>;
  where?: InputMaybe<EnumsEmailTemplateScopeBoolExp>;
};

export type SubscriptionRootEnumsEmailTemplateScopeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsEmailTemplateScopeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsEmailTemplateScopeStreamCursorInput>>;
  where?: InputMaybe<EnumsEmailTemplateScopeBoolExp>;
};

export type SubscriptionRootEnumsEmailTemplateTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsEmailTemplateTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEmailTemplateTypeOrderBy>>;
  where?: InputMaybe<EnumsEmailTemplateTypeBoolExp>;
};

export type SubscriptionRootEnumsEmailTemplateTypeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsEmailTemplateTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsEmailTemplateTypeStreamCursorInput>>;
  where?: InputMaybe<EnumsEmailTemplateTypeBoolExp>;
};

export type SubscriptionRootEnumsEmailTrackingTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsEmailTrackingTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEmailTrackingTypeOrderBy>>;
  where?: InputMaybe<EnumsEmailTrackingTypeBoolExp>;
};

export type SubscriptionRootEnumsEmailTrackingTypeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsEmailTrackingTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsEmailTrackingTypeStreamCursorInput>>;
  where?: InputMaybe<EnumsEmailTrackingTypeBoolExp>;
};

export type SubscriptionRootEnumsEntityTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsEntityTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEntityTypeOrderBy>>;
  where?: InputMaybe<EnumsEntityTypeBoolExp>;
};

export type SubscriptionRootEnumsEntityTypeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsEntityTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsEntityTypeStreamCursorInput>>;
  where?: InputMaybe<EnumsEntityTypeBoolExp>;
};

export type SubscriptionRootEnumsEventStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsEventStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsEventStatusOrderBy>>;
  where?: InputMaybe<EnumsEventStatusBoolExp>;
};

export type SubscriptionRootEnumsEventStatusByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsEventStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsEventStatusStreamCursorInput>>;
  where?: InputMaybe<EnumsEventStatusBoolExp>;
};

export type SubscriptionRootEnumsFeatureFlagsArgs = {
  distinctOn?: InputMaybe<Array<EnumsFeatureFlagsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsFeatureFlagsOrderBy>>;
  where?: InputMaybe<EnumsFeatureFlagsBoolExp>;
};

export type SubscriptionRootEnumsFeatureFlagsByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsFeatureFlagsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsFeatureFlagsStreamCursorInput>>;
  where?: InputMaybe<EnumsFeatureFlagsBoolExp>;
};

export type SubscriptionRootEnumsGenderArgs = {
  distinctOn?: InputMaybe<Array<EnumsGenderSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsGenderOrderBy>>;
  where?: InputMaybe<EnumsGenderBoolExp>;
};

export type SubscriptionRootEnumsGenderByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsGenderStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsGenderStreamCursorInput>>;
  where?: InputMaybe<EnumsGenderBoolExp>;
};

export type SubscriptionRootEnumsHouseholdTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsHouseholdTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsHouseholdTypeOrderBy>>;
  where?: InputMaybe<EnumsHouseholdTypeBoolExp>;
};

export type SubscriptionRootEnumsHouseholdTypeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsHouseholdTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsHouseholdTypeStreamCursorInput>>;
  where?: InputMaybe<EnumsHouseholdTypeBoolExp>;
};

export type SubscriptionRootEnumsImportLeadStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsImportLeadStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsImportLeadStatusOrderBy>>;
  where?: InputMaybe<EnumsImportLeadStatusBoolExp>;
};

export type SubscriptionRootEnumsImportLeadStatusByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsImportLeadStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsImportLeadStatusStreamCursorInput>>;
  where?: InputMaybe<EnumsImportLeadStatusBoolExp>;
};

export type SubscriptionRootEnumsIntentCategoryArgs = {
  distinctOn?: InputMaybe<Array<EnumsIntentCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsIntentCategoryOrderBy>>;
  where?: InputMaybe<EnumsIntentCategoryBoolExp>;
};

export type SubscriptionRootEnumsIntentCategoryByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsIntentCategoryStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsIntentCategoryStreamCursorInput>>;
  where?: InputMaybe<EnumsIntentCategoryBoolExp>;
};

export type SubscriptionRootEnumsIntentTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsIntentTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsIntentTypeOrderBy>>;
  where?: InputMaybe<EnumsIntentTypeBoolExp>;
};

export type SubscriptionRootEnumsIntentTypeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsIntentTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsIntentTypeStreamCursorInput>>;
  where?: InputMaybe<EnumsIntentTypeBoolExp>;
};

export type SubscriptionRootEnumsLanguageArgs = {
  distinctOn?: InputMaybe<Array<EnumsLanguageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsLanguageOrderBy>>;
  where?: InputMaybe<EnumsLanguageBoolExp>;
};

export type SubscriptionRootEnumsLanguageByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsLanguageStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsLanguageStreamCursorInput>>;
  where?: InputMaybe<EnumsLanguageBoolExp>;
};

export type SubscriptionRootEnumsLeadAutoAssignmentRulesArgs = {
  distinctOn?: InputMaybe<Array<EnumsLeadAutoAssignmentRulesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsLeadAutoAssignmentRulesOrderBy>>;
  where?: InputMaybe<EnumsLeadAutoAssignmentRulesBoolExp>;
};

export type SubscriptionRootEnumsLeadAutoAssignmentRulesByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsLeadAutoAssignmentRulesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsLeadAutoAssignmentRulesStreamCursorInput>>;
  where?: InputMaybe<EnumsLeadAutoAssignmentRulesBoolExp>;
};

export type SubscriptionRootEnumsLeadSourceArgs = {
  distinctOn?: InputMaybe<Array<EnumsLeadSourceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsLeadSourceOrderBy>>;
  where?: InputMaybe<EnumsLeadSourceBoolExp>;
};

export type SubscriptionRootEnumsLeadSourceByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsLeadSourceStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsLeadSourceStreamCursorInput>>;
  where?: InputMaybe<EnumsLeadSourceBoolExp>;
};

export type SubscriptionRootEnumsLeadStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsLeadStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsLeadStatusOrderBy>>;
  where?: InputMaybe<EnumsLeadStatusBoolExp>;
};

export type SubscriptionRootEnumsLeadStatusByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsLeadStatusReasonArgs = {
  distinctOn?: InputMaybe<Array<EnumsLeadStatusReasonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsLeadStatusReasonOrderBy>>;
  where?: InputMaybe<EnumsLeadStatusReasonBoolExp>;
};

export type SubscriptionRootEnumsLeadStatusReasonByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsLeadStatusReasonStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsLeadStatusReasonStreamCursorInput>>;
  where?: InputMaybe<EnumsLeadStatusReasonBoolExp>;
};

export type SubscriptionRootEnumsLeadStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsLeadStatusStreamCursorInput>>;
  where?: InputMaybe<EnumsLeadStatusBoolExp>;
};

export type SubscriptionRootEnumsLegacyGo3NewsletterTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsLegacyGo3NewsletterTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsLegacyGo3NewsletterTypeOrderBy>>;
  where?: InputMaybe<EnumsLegacyGo3NewsletterTypeBoolExp>;
};

export type SubscriptionRootEnumsLegacyGo3NewsletterTypeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsLegacyGo3NewsletterTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsLegacyGo3NewsletterTypeStreamCursorInput>>;
  where?: InputMaybe<EnumsLegacyGo3NewsletterTypeBoolExp>;
};

export type SubscriptionRootEnumsLicensePartnerStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsLicensePartnerStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsLicensePartnerStatusOrderBy>>;
  where?: InputMaybe<EnumsLicensePartnerStatusBoolExp>;
};

export type SubscriptionRootEnumsLicensePartnerStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<EnumsLicensePartnerStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsLicensePartnerStatusOrderBy>>;
  where?: InputMaybe<EnumsLicensePartnerStatusBoolExp>;
};

export type SubscriptionRootEnumsLicensePartnerStatusByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsLicensePartnerStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsLicensePartnerStatusStreamCursorInput>>;
  where?: InputMaybe<EnumsLicensePartnerStatusBoolExp>;
};

export type SubscriptionRootEnumsMandatoryAgentProfileFieldArgs = {
  distinctOn?: InputMaybe<Array<EnumsMandatoryAgentProfileFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsMandatoryAgentProfileFieldOrderBy>>;
  where?: InputMaybe<EnumsMandatoryAgentProfileFieldBoolExp>;
};

export type SubscriptionRootEnumsMandatoryAgentProfileFieldByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsMandatoryAgentProfileFieldStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsMandatoryAgentProfileFieldStreamCursorInput>>;
  where?: InputMaybe<EnumsMandatoryAgentProfileFieldBoolExp>;
};

export type SubscriptionRootEnumsMandatoryShopProfileFieldArgs = {
  distinctOn?: InputMaybe<Array<EnumsMandatoryShopProfileFieldSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsMandatoryShopProfileFieldOrderBy>>;
  where?: InputMaybe<EnumsMandatoryShopProfileFieldBoolExp>;
};

export type SubscriptionRootEnumsMandatoryShopProfileFieldByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsMandatoryShopProfileFieldStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsMandatoryShopProfileFieldStreamCursorInput>>;
  where?: InputMaybe<EnumsMandatoryShopProfileFieldBoolExp>;
};

export type SubscriptionRootEnumsMarketingTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsMarketingTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsMarketingTypeOrderBy>>;
  where?: InputMaybe<EnumsMarketingTypeBoolExp>;
};

export type SubscriptionRootEnumsMarketingTypeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsMarketingTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsMarketingTypeStreamCursorInput>>;
  where?: InputMaybe<EnumsMarketingTypeBoolExp>;
};

export type SubscriptionRootEnumsPhoneNumberTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsPhoneNumberTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsPhoneNumberTypeOrderBy>>;
  where?: InputMaybe<EnumsPhoneNumberTypeBoolExp>;
};

export type SubscriptionRootEnumsPhoneNumberTypeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsPhoneNumberTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsPhoneNumberTypeStreamCursorInput>>;
  where?: InputMaybe<EnumsPhoneNumberTypeBoolExp>;
};

export type SubscriptionRootEnumsPriorityArgs = {
  distinctOn?: InputMaybe<Array<EnumsPrioritySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsPriorityOrderBy>>;
  where?: InputMaybe<EnumsPriorityBoolExp>;
};

export type SubscriptionRootEnumsPriorityByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsPriorityStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsPriorityStreamCursorInput>>;
  where?: InputMaybe<EnumsPriorityBoolExp>;
};

export type SubscriptionRootEnumsPrivacyLevelArgs = {
  distinctOn?: InputMaybe<Array<EnumsPrivacyLevelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsPrivacyLevelOrderBy>>;
  where?: InputMaybe<EnumsPrivacyLevelBoolExp>;
};

export type SubscriptionRootEnumsPrivacyLevelByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsPrivacyLevelStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsPrivacyLevelStreamCursorInput>>;
  where?: InputMaybe<EnumsPrivacyLevelBoolExp>;
};

export type SubscriptionRootEnumsProfessionTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsProfessionTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsProfessionTypeOrderBy>>;
  where?: InputMaybe<EnumsProfessionTypeBoolExp>;
};

export type SubscriptionRootEnumsProfessionTypeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsProfessionTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsProfessionTypeStreamCursorInput>>;
  where?: InputMaybe<EnumsProfessionTypeBoolExp>;
};

export type SubscriptionRootEnumsPropertyOccupationStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsPropertyOccupationStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsPropertyOccupationStatusOrderBy>>;
  where?: InputMaybe<EnumsPropertyOccupationStatusBoolExp>;
};

export type SubscriptionRootEnumsPropertyOccupationStatusByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsPropertyOccupationStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsPropertyOccupationStatusStreamCursorInput>>;
  where?: InputMaybe<EnumsPropertyOccupationStatusBoolExp>;
};

export type SubscriptionRootEnumsPropertyOwnershipStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsPropertyOwnershipStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsPropertyOwnershipStatusOrderBy>>;
  where?: InputMaybe<EnumsPropertyOwnershipStatusBoolExp>;
};

export type SubscriptionRootEnumsPropertyOwnershipStatusByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsPropertyOwnershipStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsPropertyOwnershipStatusStreamCursorInput>>;
  where?: InputMaybe<EnumsPropertyOwnershipStatusBoolExp>;
};

export type SubscriptionRootEnumsQualificationFormStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsQualificationFormStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsQualificationFormStatusOrderBy>>;
  where?: InputMaybe<EnumsQualificationFormStatusBoolExp>;
};

export type SubscriptionRootEnumsQualificationFormStatusByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsQualificationFormStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsQualificationFormStatusStreamCursorInput>>;
  where?: InputMaybe<EnumsQualificationFormStatusBoolExp>;
};

export type SubscriptionRootEnumsQualiformFieldsArgs = {
  distinctOn?: InputMaybe<Array<EnumsQualiformFieldsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsQualiformFieldsOrderBy>>;
  where?: InputMaybe<EnumsQualiformFieldsBoolExp>;
};

export type SubscriptionRootEnumsQualiformFieldsByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsQualiformFieldsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsQualiformFieldsStreamCursorInput>>;
  where?: InputMaybe<EnumsQualiformFieldsBoolExp>;
};

export type SubscriptionRootEnumsReferralNotPossibleReasonArgs = {
  distinctOn?: InputMaybe<Array<EnumsReferralNotPossibleReasonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsReferralNotPossibleReasonOrderBy>>;
  where?: InputMaybe<EnumsReferralNotPossibleReasonBoolExp>;
};

export type SubscriptionRootEnumsReferralNotPossibleReasonByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsReferralNotPossibleReasonStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsReferralNotPossibleReasonStreamCursorInput>>;
  where?: InputMaybe<EnumsReferralNotPossibleReasonBoolExp>;
};

export type SubscriptionRootEnumsSalutationArgs = {
  distinctOn?: InputMaybe<Array<EnumsSalutationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsSalutationOrderBy>>;
  where?: InputMaybe<EnumsSalutationBoolExp>;
};

export type SubscriptionRootEnumsSalutationByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsSalutationStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsSalutationStreamCursorInput>>;
  where?: InputMaybe<EnumsSalutationBoolExp>;
};

export type SubscriptionRootEnumsSessionStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsSessionStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsSessionStatusOrderBy>>;
  where?: InputMaybe<EnumsSessionStatusBoolExp>;
};

export type SubscriptionRootEnumsSessionStatusByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsSessionStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsSessionStatusStreamCursorInput>>;
  where?: InputMaybe<EnumsSessionStatusBoolExp>;
};

export type SubscriptionRootEnumsSharedTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsSharedTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsSharedTypeOrderBy>>;
  where?: InputMaybe<EnumsSharedTypeBoolExp>;
};

export type SubscriptionRootEnumsSharedTypeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsSharedTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsSharedTypeStreamCursorInput>>;
  where?: InputMaybe<EnumsSharedTypeBoolExp>;
};

export type SubscriptionRootEnumsShopExpertiseArgs = {
  distinctOn?: InputMaybe<Array<EnumsShopExpertiseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsShopExpertiseOrderBy>>;
  where?: InputMaybe<EnumsShopExpertiseBoolExp>;
};

export type SubscriptionRootEnumsShopExpertiseByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsShopExpertiseStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsShopExpertiseStreamCursorInput>>;
  where?: InputMaybe<EnumsShopExpertiseBoolExp>;
};

export type SubscriptionRootEnumsShopStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsShopStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsShopStatusOrderBy>>;
  where?: InputMaybe<EnumsShopStatusBoolExp>;
};

export type SubscriptionRootEnumsShopStatusByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsShopStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsShopStatusStreamCursorInput>>;
  where?: InputMaybe<EnumsShopStatusBoolExp>;
};

export type SubscriptionRootEnumsSpokenLanguageArgs = {
  distinctOn?: InputMaybe<Array<EnumsSpokenLanguageSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsSpokenLanguageOrderBy>>;
  where?: InputMaybe<EnumsSpokenLanguageBoolExp>;
};

export type SubscriptionRootEnumsSpokenLanguageByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsSpokenLanguageStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsSpokenLanguageStreamCursorInput>>;
  where?: InputMaybe<EnumsSpokenLanguageBoolExp>;
};

export type SubscriptionRootEnumsTaskAppointmentStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsTaskAppointmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsTaskAppointmentStatusOrderBy>>;
  where?: InputMaybe<EnumsTaskAppointmentStatusBoolExp>;
};

export type SubscriptionRootEnumsTaskAppointmentStatusByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsTaskAppointmentStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsTaskAppointmentStatusStreamCursorInput>>;
  where?: InputMaybe<EnumsTaskAppointmentStatusBoolExp>;
};

export type SubscriptionRootEnumsTaskTypeArgs = {
  distinctOn?: InputMaybe<Array<EnumsTaskTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsTaskTypeOrderBy>>;
  where?: InputMaybe<EnumsTaskTypeBoolExp>;
};

export type SubscriptionRootEnumsTaskTypeByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsTaskTypeStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsTaskTypeStreamCursorInput>>;
  where?: InputMaybe<EnumsTaskTypeBoolExp>;
};

export type SubscriptionRootEnumsTeamStatusArgs = {
  distinctOn?: InputMaybe<Array<EnumsTeamStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsTeamStatusOrderBy>>;
  where?: InputMaybe<EnumsTeamStatusBoolExp>;
};

export type SubscriptionRootEnumsTeamStatusByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsTeamStatusStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsTeamStatusStreamCursorInput>>;
  where?: InputMaybe<EnumsTeamStatusBoolExp>;
};

export type SubscriptionRootEnumsTimeFrameArgs = {
  distinctOn?: InputMaybe<Array<EnumsTimeFrameSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsTimeFrameOrderBy>>;
  where?: InputMaybe<EnumsTimeFrameBoolExp>;
};

export type SubscriptionRootEnumsTimeFrameByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsTimeFrameStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsTimeFrameStreamCursorInput>>;
  where?: InputMaybe<EnumsTimeFrameBoolExp>;
};

export type SubscriptionRootEnumsUserRoleArgs = {
  distinctOn?: InputMaybe<Array<EnumsUserRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsUserRoleOrderBy>>;
  where?: InputMaybe<EnumsUserRoleBoolExp>;
};

export type SubscriptionRootEnumsUserRoleByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsUserRoleStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsUserRoleStreamCursorInput>>;
  where?: InputMaybe<EnumsUserRoleBoolExp>;
};

export type SubscriptionRootEnumsZeroCommissionReasonArgs = {
  distinctOn?: InputMaybe<Array<EnumsZeroCommissionReasonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EnumsZeroCommissionReasonOrderBy>>;
  where?: InputMaybe<EnumsZeroCommissionReasonBoolExp>;
};

export type SubscriptionRootEnumsZeroCommissionReasonByPkArgs = {
  name: Scalars['String'];
};

export type SubscriptionRootEnumsZeroCommissionReasonStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<EnumsZeroCommissionReasonStreamCursorInput>>;
  where?: InputMaybe<EnumsZeroCommissionReasonBoolExp>;
};

export type SubscriptionRootExtensionsSearchContactsArgs = {
  args: ExtensionsSearchContactsArgs;
  distinctOn?: InputMaybe<Array<DbContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactOrderBy>>;
  where?: InputMaybe<DbContactBoolExp>;
};

export type SubscriptionRootExtensionsSearchContactsAggregateArgs = {
  args: ExtensionsSearchContactsArgs;
  distinctOn?: InputMaybe<Array<DbContactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbContactOrderBy>>;
  where?: InputMaybe<DbContactBoolExp>;
};

export type ReferralActivity = {
  __typename?: 'DbReferralActivity';
  id: string;
  referral: {
    __typename?: 'DbReferral';
    id: string;
    createdAt: any;
    acceptedRefusedDate?: any | null;
    referralStatus: ReferralStatusEnum;
    commission: any;
    note?: string | null;
    sendingContactId?: string | null;
    leadId?: string | null;
    receivingLeadId?: string | null;
    receivingGo3ContactUtag?: string | null;
    type: ReferralTypeEnum;
    referralAgentNote?: string | null;
    referralNotPossibleReason?: ReferralNotPossibleReasonEnum | null;
    sendingShopId: string;
    receivingShopId?: string | null;
    receivingTeamId?: string | null;
    sendingTeamId?: string | null;
    snapshotId: string;
    leadByReceivingLeadId?: {
      __typename?: 'DbLead';
      contact: {
        __typename?: 'DbContact';
        id: string;
        blocked: boolean;
        emailAddresses: Array<{ __typename?: 'DbContactEmailAddress'; address: string; preferred: boolean }>;
        phoneNumbers: Array<{ __typename?: 'DbContactPhoneNumber'; number: string; preferred: boolean }>;
      };
      agent?: {
        __typename: 'DbAgent';
        createdAt: any;
        primaryShopId?: string | null;
        userName: string;
        salutation?: SalutationEnum | null;
        betaTester: boolean;
        energyAdvisoryEnabled: boolean;
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      } | null;
      team?: {
        __typename?: 'DbTeam';
        normalizedName?: string | null;
        description?: string | null;
        status?: TeamStatusEnum | null;
        id: string;
        name: string;
        shopId: string;
        email?: string | null;
        teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
      } | null;
      baseActivities: Array<{
        __typename?: 'DbBaseActivity';
        callActivity?: {
          __typename?: 'DbCallActivity';
          call?: {
            __typename?: 'DbCall';
            id: string;
            callSid: string;
            createdAt: any;
            duration?: any | null;
            outcome?: CallOutcomeEnum | null;
            note?: string | null;
            status: CallStatusEnum;
            shopId: string;
            recipient: string;
            caller: string;
            callDirection: { __typename?: 'EnumsCallDirection'; name: string };
            agent?: {
              __typename: 'DbAgent';
              createdAt: any;
              primaryShopId?: string | null;
              userName: string;
              salutation?: SalutationEnum | null;
              betaTester: boolean;
              energyAdvisoryEnabled: boolean;
              id: string;
              firstName: string;
              lastName: string;
              email: string;
              status: AgentStatusEnum;
              mobilePhoneNumber?: string | null;
              officePhoneNumber?: string | null;
              nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
              profiles: Array<{
                __typename?: 'DbAgentProfile';
                images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
              }>;
            } | null;
          } | null;
        } | null;
        emailActivity?: {
          __typename?: 'DbEmailActivity';
          id: string;
          email: {
            __typename?: 'DbEmail';
            id: string;
            status?: EmailStatusEnum | null;
            from: string;
            to: Array<string>;
            cc?: Array<string> | null;
            bcc?: Array<string> | null;
            subject: string;
            createdAt: any;
            direction?: EmailDirectionEnum | null;
            body: string;
            propertyIds: Array<string>;
            trackings: Array<{
              __typename?: 'DbEmailTracking';
              id: string;
              count: number;
              type: EmailTrackingTypeEnum;
              recipient?: string | null;
              updatedAt: any;
              linkUrl?: string | null;
            }>;
            attachments: Array<{
              __typename?: 'DbEmailAttachment';
              document: { __typename?: 'DbDocument'; id: string; contentType: string; name: string };
            }>;
          };
        } | null;
        appointmentActivity?: {
          __typename?: 'DbAppointmentActivity';
          createdAt: any;
          appointment: {
            __typename?: 'DbAppointment';
            id: string;
            type: AppointmentTypeEnum;
            startTime: any;
            endTime?: any | null;
            location?: string | null;
            subject?: string | null;
            status?: TaskAppointmentStatusEnum | null;
            agentId: string;
            teamId?: string | null;
            comment?: string | null;
            feedback?: string | null;
            cost?: any | null;
            propertyIds: Array<string>;
            go3AppointmentKey?: number | null;
            agent: { __typename?: 'DbAgent'; firstName: string; lastName: string };
            appointmentContacts: Array<{ __typename?: 'DbAppointmentContact'; contactId: string }>;
            appointmentLeads: Array<{ __typename?: 'DbAppointmentLead'; leadId: string }>;
            appointmentAgents: Array<{
              __typename?: 'DbAppointmentAgent';
              agent: {
                __typename: 'DbAgent';
                id: string;
                firstName: string;
                lastName: string;
                email: string;
                status: AgentStatusEnum;
                mobilePhoneNumber?: string | null;
                officePhoneNumber?: string | null;
                profiles: Array<{
                  __typename?: 'DbAgentProfile';
                  images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
                }>;
              };
            }>;
          };
        } | null;
      }>;
    } | null;
    agent: {
      __typename: 'DbAgent';
      createdAt: any;
      primaryShopId?: string | null;
      userName: string;
      salutation?: SalutationEnum | null;
      betaTester: boolean;
      energyAdvisoryEnabled: boolean;
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    };
    agentByReceivingAgentId?: {
      __typename: 'DbAgent';
      createdAt: any;
      primaryShopId?: string | null;
      userName: string;
      salutation?: SalutationEnum | null;
      betaTester: boolean;
      energyAdvisoryEnabled: boolean;
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
    contactByReceivingLeadIdWrapper?: {
      __typename?: 'DbOneWayMappingFromLeadIdToContactId';
      contact?: { __typename?: 'DbContact'; id: string } | null;
    } | null;
    receivingShop?: {
      __typename?: 'DbShop';
      id: string;
      shopName: string;
      city?: string | null;
      countryCode: CountryEnum;
      postalCode?: string | null;
      streetName?: string | null;
      phone?: string | null;
      email?: string | null;
      status?: ShopStatusEnum | null;
      streetNumber?: string | null;
      onboardingLevel?: number | null;
      groupId?: string | null;
      latitude?: any | null;
      longitude?: any | null;
      shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
      licensePartner: {
        __typename?: 'DbLicensePartner';
        name: string;
        isCompanyOwnedOperation?: boolean | null;
        id: string;
      };
      profiles: Array<{
        __typename?: 'DbShopProfile';
        images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
    sendingShop?: {
      __typename?: 'DbShop';
      id: string;
      shopName: string;
      city?: string | null;
      countryCode: CountryEnum;
      postalCode?: string | null;
      streetName?: string | null;
      phone?: string | null;
      email?: string | null;
      status?: ShopStatusEnum | null;
      streetNumber?: string | null;
      onboardingLevel?: number | null;
      groupId?: string | null;
      latitude?: any | null;
      longitude?: any | null;
      shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
      licensePartner: {
        __typename?: 'DbLicensePartner';
        name: string;
        isCompanyOwnedOperation?: boolean | null;
        id: string;
      };
      profiles: Array<{
        __typename?: 'DbShopProfile';
        images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
    snapshotLead: {
      __typename?: 'DbSnapshotLead';
      contactFirstName?: string | null;
      contactLastName?: string | null;
      intentType: IntentTypeEnum;
    };
    contact?: { __typename?: 'DbContact'; id: string } | null;
    teamByReceivingTeamId?: { __typename?: 'DbTeam'; id: string; name: string; shopId: string } | null;
    teamBySendingTeamId?: { __typename?: 'DbTeam'; id: string; name: string; shopId: string } | null;
  };
};

export type ActivityTypeGrouping = {
  __typename?: 'DbActivityTypeGrouping';
  activityCategory: BaseActivityCategoryEnum;
  activityType: ActivityTypeEnum;
  typeLocalizeKey: string;
};

export type Address = {
  __typename?: 'DbAddress';
  id: string;
  city?: string | null;
  countryCode: CountryEnum;
  postalCode?: string | null;
  secondAddressLine?: string | null;
  streetName?: string | null;
  streetNumber?: string | null;
  fullAddress?: string | null;
  placeId?: string | null;
  country: { __typename?: 'EnumsCountry'; description?: string | null; name: string };
};

export type AgentInfo = {
  __typename: 'DbAgent';
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  status: AgentStatusEnum;
  mobilePhoneNumber?: string | null;
  officePhoneNumber?: string | null;
  profiles: Array<{
    __typename?: 'DbAgentProfile';
    images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
  }>;
};

export type Agent = {
  __typename: 'DbAgent';
  createdAt: any;
  primaryShopId?: string | null;
  userName: string;
  salutation?: SalutationEnum | null;
  betaTester: boolean;
  energyAdvisoryEnabled: boolean;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  status: AgentStatusEnum;
  mobilePhoneNumber?: string | null;
  officePhoneNumber?: string | null;
  nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
  profiles: Array<{
    __typename?: 'DbAgentProfile';
    images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
  }>;
};

export type AgentWithTeamAssignments = {
  __typename: 'DbAgent';
  createdAt: any;
  primaryShopId?: string | null;
  userName: string;
  salutation?: SalutationEnum | null;
  betaTester: boolean;
  energyAdvisoryEnabled: boolean;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  status: AgentStatusEnum;
  mobilePhoneNumber?: string | null;
  officePhoneNumber?: string | null;
  userTeamAssignments: Array<{
    __typename?: 'DbUserTeamAssignment';
    role: UserRoleEnum;
    team: {
      __typename?: 'DbTeam';
      normalizedName?: string | null;
      description?: string | null;
      status?: TeamStatusEnum | null;
      id: string;
      name: string;
      shopId: string;
      email?: string | null;
      teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
    };
  }>;
  nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
  profiles: Array<{
    __typename?: 'DbAgentProfile';
    images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
  }>;
};

export type AgentWithShopMembership = {
  __typename: 'DbAgent';
  createdAt: any;
  primaryShopId?: string | null;
  userName: string;
  salutation?: SalutationEnum | null;
  betaTester: boolean;
  energyAdvisoryEnabled: boolean;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  status: AgentStatusEnum;
  mobilePhoneNumber?: string | null;
  officePhoneNumber?: string | null;
  userTeamAssignments: Array<{
    __typename?: 'DbUserTeamAssignment';
    role: UserRoleEnum;
    team: {
      __typename?: 'DbTeam';
      normalizedName?: string | null;
      description?: string | null;
      status?: TeamStatusEnum | null;
      id: string;
      name: string;
      shopId: string;
      email?: string | null;
      shop: {
        __typename?: 'DbShop';
        id: string;
        shopName: string;
        city?: string | null;
        countryCode: CountryEnum;
        postalCode?: string | null;
        streetName?: string | null;
        phone?: string | null;
        email?: string | null;
        status?: ShopStatusEnum | null;
        streetNumber?: string | null;
        onboardingLevel?: number | null;
        groupId?: string | null;
        latitude?: any | null;
        longitude?: any | null;
        shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
        licensePartner: {
          __typename?: 'DbLicensePartner';
          name: string;
          isCompanyOwnedOperation?: boolean | null;
          id: string;
        };
        profiles: Array<{
          __typename?: 'DbShopProfile';
          images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
        }>;
      };
      teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
    };
  }>;
  userShopAssignments: Array<{
    __typename?: 'DbUserShopAssignment';
    role: UserRoleEnum;
    shop: {
      __typename?: 'DbShop';
      id: string;
      shopName: string;
      city?: string | null;
      countryCode: CountryEnum;
      postalCode?: string | null;
      streetName?: string | null;
      phone?: string | null;
      email?: string | null;
      status?: ShopStatusEnum | null;
      streetNumber?: string | null;
      onboardingLevel?: number | null;
      groupId?: string | null;
      latitude?: any | null;
      longitude?: any | null;
      shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
      licensePartner: {
        __typename?: 'DbLicensePartner';
        name: string;
        isCompanyOwnedOperation?: boolean | null;
        id: string;
      };
      profiles: Array<{
        __typename?: 'DbShopProfile';
        images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
      }>;
    };
  }>;
  userAdminAssignments: Array<{ __typename?: 'DbUserAdminAssignment'; role: UserRoleEnum }>;
  nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
  profiles: Array<{
    __typename?: 'DbAgentProfile';
    images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
  }>;
};

export type AgentProfileImage = { __typename?: 'DbAgentProfileImage'; id: string; title: string; gcpFileName: string };

export type AgentProfile = {
  __typename?: 'DbAgentProfile';
  id: string;
  published?: string | null;
  cityDistricts: Array<{ __typename?: 'DbAgentProfileCityDistrict'; city: string; district?: string | null }>;
  expertises: Array<{ __typename?: 'DbAgentProfileExpertise'; expertise: ShopExpertiseEnum }>;
  spokenLanguages: Array<{ __typename?: 'DbAgentProfileSpokenLanguage'; spokenLanguage: SpokenLanguageEnum }>;
  descriptions: Array<{
    __typename?: 'DbAgentProfileDescription';
    jobTitle?: string | null;
    academicTitle?: string | null;
    text?: string | null;
    lang: LanguageEnum;
    url?: string | null;
    isAiTranslated?: boolean | null;
  }>;
  images: Array<{ __typename?: 'DbAgentProfileImage'; id: string; title: string; gcpFileName: string }>;
  socialMediaProfiles?: {
    __typename?: 'DbAgentProfileSocialMediaProfiles';
    facebookUrl?: string | null;
    instagramUrl?: string | null;
    twitterUrl?: string | null;
    xingUrl?: string | null;
    linkedInUrl?: string | null;
    tiktokUrl?: string | null;
  } | null;
};

export type Appointment = {
  __typename?: 'DbAppointment';
  id: string;
  type: AppointmentTypeEnum;
  startTime: any;
  endTime?: any | null;
  location?: string | null;
  subject?: string | null;
  status?: TaskAppointmentStatusEnum | null;
  agentId: string;
  teamId?: string | null;
  comment?: string | null;
  feedback?: string | null;
  cost?: any | null;
  propertyIds: Array<string>;
  go3AppointmentKey?: number | null;
  agent: { __typename?: 'DbAgent'; firstName: string; lastName: string };
  appointmentContacts: Array<{ __typename?: 'DbAppointmentContact'; contactId: string }>;
  appointmentLeads: Array<{ __typename?: 'DbAppointmentLead'; leadId: string }>;
  appointmentAgents: Array<{
    __typename?: 'DbAppointmentAgent';
    agent: {
      __typename: 'DbAgent';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    };
  }>;
};

export type AppointmentActivity = {
  __typename?: 'DbAppointmentActivity';
  createdAt: any;
  appointment: {
    __typename?: 'DbAppointment';
    id: string;
    type: AppointmentTypeEnum;
    startTime: any;
    endTime?: any | null;
    location?: string | null;
    subject?: string | null;
    status?: TaskAppointmentStatusEnum | null;
    agentId: string;
    teamId?: string | null;
    comment?: string | null;
    feedback?: string | null;
    cost?: any | null;
    propertyIds: Array<string>;
    go3AppointmentKey?: number | null;
    agent: { __typename?: 'DbAgent'; firstName: string; lastName: string };
    appointmentContacts: Array<{ __typename?: 'DbAppointmentContact'; contactId: string }>;
    appointmentLeads: Array<{ __typename?: 'DbAppointmentLead'; leadId: string }>;
    appointmentAgents: Array<{
      __typename?: 'DbAppointmentAgent';
      agent: {
        __typename: 'DbAgent';
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      };
    }>;
  };
};

export type AuditActivity = { __typename?: 'DbAuditActivity'; content?: any | null; id: string };

export type BaseActivityInfo = {
  __typename?: 'DbBaseActivity';
  type: ActivityTypeEnum;
  createdBy: string;
  createdAt: any;
  shopId?: string | null;
};

export type ActionActivity = {
  __typename?: 'DbBaseActivity';
  id: string;
  createdAt: any;
  category: BaseActivityCategoryEnum;
  creator?: { __typename?: 'DbAgent'; firstName: string; lastName: string } | null;
  taskActivity?: {
    __typename?: 'DbTaskActivity';
    task: { __typename?: 'DbTask'; taskType: { __typename?: 'EnumsTaskType'; name: string } };
  } | null;
};

export type ActivityContactFragment = {
  __typename?: 'DbContact';
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  salutation?: SalutationEnum | null;
  title?: string | null;
  birthdate?: any | null;
  contactStatus: ContactStatusEnum;
  blocked: boolean;
  personalNote?: string | null;
  preferredLanguage?: LanguageEnum | null;
  preferredTimes: Array<{ __typename?: 'DbContactPreferredTime'; preferredContactTime: ContactTimeEnum }>;
  emailAddresses: Array<{ __typename?: 'DbContactEmailAddress'; address: string; preferred: boolean }>;
  phoneNumbers: Array<{
    __typename?: 'DbContactPhoneNumber';
    number: string;
    preferred: boolean;
    type: PhoneNumberTypeEnum;
  }>;
};

export type ActivityLeadFragment = {
  __typename?: 'DbLead';
  contactId: string;
  id: string;
  intentType: IntentTypeEnum;
  status: LeadStatusEnum;
  expiredAt?: any | null;
  go3PropertyId?: string | null;
  peLinkedPropertyId?: string | null;
  sellerRentOutSpecification?: {
    __typename?: 'DbSellerRentOutSpecification';
    type?: AssetTypeEnum | null;
    livingArea?: any | null;
    plotArea?: any | null;
    address?: {
      __typename?: 'DbAddress';
      city?: string | null;
      postalCode?: string | null;
      countryCode: CountryEnum;
      streetName?: string | null;
      streetNumber?: string | null;
      secondAddressLine?: string | null;
    } | null;
  } | null;
  buyerToRentSpecification?: {
    __typename?: 'DbBuyerToRentSpecification';
    type?: AssetTypeEnum | null;
    city?: string | null;
    livingAreaMax?: number | null;
    livingAreaMin?: number | null;
    totalSurfaceMax?: number | null;
    totalSurfaceMin?: number | null;
    plotSurfaceMax?: number | null;
    plotSurfaceMin?: number | null;
  } | null;
};

export type CreatedAndReferralActivityLeadFragment = {
  __typename?: 'DbLead';
  personalNote?: string | null;
  timeFrame?: TimeFrameEnum | null;
  customerMessage?: string | null;
  intentType: IntentTypeEnum;
  referrals: Array<{ __typename?: 'DbReferral'; receivingShop?: { __typename?: 'DbShop'; id: string } | null }>;
  sellerRentOutSpecification?: {
    __typename?: 'DbSellerRentOutSpecification';
    constructionYear?: number | null;
    condition?: AssetConditionEnum | null;
    priceRangeTo?: any | null;
    priceRangeFrom?: any | null;
    rentRangeFrom?: any | null;
    rentRangeTo?: any | null;
    priceRangeUnit: CurrencyEnum;
    rentPriceUnit: CurrencyEnum;
    occupationStatus?: PropertyOccupationStatusEnum | null;
    livingArea?: any | null;
    numberOfBathrooms?: number | null;
    numberOfBedrooms?: number | null;
    floor?: number | null;
    numberOfMasterBathrooms?: number | null;
    numberOfRooms?: number | null;
    totalFloors?: number | null;
    surfaceTotal?: any | null;
    plotArea?: any | null;
    surfaceUsable?: any | null;
    balcony?: boolean | null;
    garden?: boolean | null;
    parking?: boolean | null;
    swimmingPool?: boolean | null;
    elevator?: boolean | null;
    garage?: boolean | null;
    guestToilet?: boolean | null;
    type?: AssetTypeEnum | null;
    subType?: AssetSubTypeEnum | null;
    variation?: AssetVariationEnum | null;
    id: string;
    ownershipStatus?: PropertyOwnershipStatusEnum | null;
    ownerDescription?: string | null;
    owner?: {
      __typename?: 'DbContact';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      fullName?: string | null;
    } | null;
    address?: {
      __typename?: 'DbAddress';
      id: string;
      city?: string | null;
      countryCode: CountryEnum;
      postalCode?: string | null;
      secondAddressLine?: string | null;
      streetName?: string | null;
      streetNumber?: string | null;
      fullAddress?: string | null;
      placeId?: string | null;
      country: { __typename?: 'EnumsCountry'; description?: string | null; name: string };
    } | null;
    sellerRentOutSpecificationCdfs: Array<{
      __typename?: 'DbSellerRentOutSpecificationCdf';
      cdf: { __typename?: 'DbCdf'; id: string; label: string };
    }>;
  } | null;
  buyerToRentSpecification?: {
    __typename?: 'DbBuyerToRentSpecification';
    area?: string | null;
    bedroomsMax?: number | null;
    bedroomsMin?: number | null;
    city?: string | null;
    country?: string | null;
    currency: CurrencyEnum;
    district?: string | null;
    id: string;
    legacyAreaNames: Array<string>;
    legacyDistrictNames: Array<string>;
    livingAreaMax?: number | null;
    livingAreaMin?: number | null;
    monthlyNetRentMax?: number | null;
    monthlyNetRentMin?: number | null;
    monthlyTotalRentMax?: number | null;
    monthlyTotalRentMin?: number | null;
    plotSurfaceMax?: number | null;
    plotSurfaceMin?: number | null;
    priceMax?: number | null;
    priceMin?: number | null;
    roomsMax?: number | null;
    roomsMin?: number | null;
    subTypes?: Array<string> | null;
    variations?: Array<string> | null;
    totalSurfaceMax?: number | null;
    totalSurfaceMin?: number | null;
    type?: AssetTypeEnum | null;
    placeIds: Array<string>;
    searchDemandId?: string | null;
    buyerToRentSpecificationCdfs: Array<{
      __typename?: 'DbBuyerToRentSpecificationCdf';
      cdf: { __typename?: 'DbCdf'; id: string; label: string };
    }>;
    customSearchDemandPolygons: Array<{
      __typename?: 'DbCustomSearchDemandPolygon';
      id: string;
      name?: string | null;
      polygon: any;
    }>;
  } | null;
};

export type BaseActivity = {
  __typename?: 'DbBaseActivity';
  id: string;
  category: BaseActivityCategoryEnum;
  type: ActivityTypeEnum;
  createdAt: any;
  createdBy: string;
  contactId?: string | null;
  leadId?: string | null;
  shopId?: string | null;
  creator?: { __typename?: 'DbAgent'; userName: string; firstName: string; lastName: string } | null;
  lead?: {
    __typename?: 'DbLead';
    contactId: string;
    id: string;
    intentType: IntentTypeEnum;
    status: LeadStatusEnum;
    expiredAt?: any | null;
    go3PropertyId?: string | null;
    peLinkedPropertyId?: string | null;
    personalNote?: string | null;
    timeFrame?: TimeFrameEnum | null;
    customerMessage?: string | null;
    sellerRentOutSpecification?: {
      __typename?: 'DbSellerRentOutSpecification';
      type?: AssetTypeEnum | null;
      livingArea?: any | null;
      plotArea?: any | null;
      constructionYear?: number | null;
      condition?: AssetConditionEnum | null;
      priceRangeTo?: any | null;
      priceRangeFrom?: any | null;
      rentRangeFrom?: any | null;
      rentRangeTo?: any | null;
      priceRangeUnit: CurrencyEnum;
      rentPriceUnit: CurrencyEnum;
      occupationStatus?: PropertyOccupationStatusEnum | null;
      numberOfBathrooms?: number | null;
      numberOfBedrooms?: number | null;
      floor?: number | null;
      numberOfMasterBathrooms?: number | null;
      numberOfRooms?: number | null;
      totalFloors?: number | null;
      surfaceTotal?: any | null;
      surfaceUsable?: any | null;
      balcony?: boolean | null;
      garden?: boolean | null;
      parking?: boolean | null;
      swimmingPool?: boolean | null;
      elevator?: boolean | null;
      garage?: boolean | null;
      guestToilet?: boolean | null;
      subType?: AssetSubTypeEnum | null;
      variation?: AssetVariationEnum | null;
      id: string;
      ownershipStatus?: PropertyOwnershipStatusEnum | null;
      ownerDescription?: string | null;
      address?: {
        __typename?: 'DbAddress';
        city?: string | null;
        postalCode?: string | null;
        countryCode: CountryEnum;
        streetName?: string | null;
        streetNumber?: string | null;
        secondAddressLine?: string | null;
        id: string;
        fullAddress?: string | null;
        placeId?: string | null;
        country: { __typename?: 'EnumsCountry'; description?: string | null; name: string };
      } | null;
      owner?: {
        __typename?: 'DbContact';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        fullName?: string | null;
      } | null;
      sellerRentOutSpecificationCdfs: Array<{
        __typename?: 'DbSellerRentOutSpecificationCdf';
        cdf: { __typename?: 'DbCdf'; id: string; label: string };
      }>;
    } | null;
    buyerToRentSpecification?: {
      __typename?: 'DbBuyerToRentSpecification';
      type?: AssetTypeEnum | null;
      city?: string | null;
      livingAreaMax?: number | null;
      livingAreaMin?: number | null;
      totalSurfaceMax?: number | null;
      totalSurfaceMin?: number | null;
      plotSurfaceMax?: number | null;
      plotSurfaceMin?: number | null;
      area?: string | null;
      bedroomsMax?: number | null;
      bedroomsMin?: number | null;
      country?: string | null;
      currency: CurrencyEnum;
      district?: string | null;
      id: string;
      legacyAreaNames: Array<string>;
      legacyDistrictNames: Array<string>;
      monthlyNetRentMax?: number | null;
      monthlyNetRentMin?: number | null;
      monthlyTotalRentMax?: number | null;
      monthlyTotalRentMin?: number | null;
      priceMax?: number | null;
      priceMin?: number | null;
      roomsMax?: number | null;
      roomsMin?: number | null;
      subTypes?: Array<string> | null;
      variations?: Array<string> | null;
      placeIds: Array<string>;
      searchDemandId?: string | null;
      buyerToRentSpecificationCdfs: Array<{
        __typename?: 'DbBuyerToRentSpecificationCdf';
        cdf: { __typename?: 'DbCdf'; id: string; label: string };
      }>;
      customSearchDemandPolygons: Array<{
        __typename?: 'DbCustomSearchDemandPolygon';
        id: string;
        name?: string | null;
        polygon: any;
      }>;
    } | null;
    referrals: Array<{ __typename?: 'DbReferral'; receivingShop?: { __typename?: 'DbShop'; id: string } | null }>;
  } | null;
  contact?: {
    __typename?: 'DbContact';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    salutation?: SalutationEnum | null;
    title?: string | null;
    birthdate?: any | null;
    contactStatus: ContactStatusEnum;
    blocked: boolean;
    personalNote?: string | null;
    preferredLanguage?: LanguageEnum | null;
    preferredTimes: Array<{ __typename?: 'DbContactPreferredTime'; preferredContactTime: ContactTimeEnum }>;
    emailAddresses: Array<{ __typename?: 'DbContactEmailAddress'; address: string; preferred: boolean }>;
    phoneNumbers: Array<{
      __typename?: 'DbContactPhoneNumber';
      number: string;
      preferred: boolean;
      type: PhoneNumberTypeEnum;
    }>;
  } | null;
  callActivity?: {
    __typename?: 'DbCallActivity';
    call?: {
      __typename?: 'DbCall';
      id: string;
      callSid: string;
      createdAt: any;
      duration?: any | null;
      outcome?: CallOutcomeEnum | null;
      note?: string | null;
      status: CallStatusEnum;
      shopId: string;
      recipient: string;
      caller: string;
      callDirection: { __typename?: 'EnumsCallDirection'; name: string };
      agent?: {
        __typename: 'DbAgent';
        createdAt: any;
        primaryShopId?: string | null;
        userName: string;
        salutation?: SalutationEnum | null;
        betaTester: boolean;
        energyAdvisoryEnabled: boolean;
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      } | null;
    } | null;
  } | null;
  auditActivity?: { __typename?: 'DbAuditActivity'; content?: any | null; id: string } | null;
  consentActivity?: {
    __typename?: 'DbConsentActivity';
    id: string;
    consentId: string;
    content?: any | null;
    consent: {
      __typename?: 'DbConsent';
      createdAt: any;
      status: ConsentStatusEnum;
      licenseId?: string | null;
      expiredAt?: any | null;
      customerAccountId: string;
      contactForm: string;
      ipAddress?: string | null;
      consentType: ConsentTypeEnum;
      consentText?: string | null;
      url?: string | null;
      customerAccount: { __typename?: 'DbCustomerAccount'; emailAddress: string };
    };
  } | null;
  emailActivity?: {
    __typename?: 'DbEmailActivity';
    id: string;
    email: {
      __typename?: 'DbEmail';
      id: string;
      status?: EmailStatusEnum | null;
      from: string;
      to: Array<string>;
      cc?: Array<string> | null;
      bcc?: Array<string> | null;
      subject: string;
      createdAt: any;
      direction?: EmailDirectionEnum | null;
      body: string;
      propertyIds: Array<string>;
      trackings: Array<{
        __typename?: 'DbEmailTracking';
        id: string;
        count: number;
        type: EmailTrackingTypeEnum;
        recipient?: string | null;
        updatedAt: any;
        linkUrl?: string | null;
      }>;
      attachments: Array<{
        __typename?: 'DbEmailAttachment';
        document: { __typename?: 'DbDocument'; id: string; contentType: string; name: string };
      }>;
    };
  } | null;
  exposeSharedActivity?: {
    __typename?: 'DbExposeeSharedActivity';
    id: string;
    propertyIds: Array<string>;
    status: string;
    title?: string | null;
    updatedAt: any;
    createdAt: any;
    comment?: string | null;
  } | null;
  taskActivity?: {
    __typename?: 'DbTaskActivity';
    task: {
      __typename?: 'DbTask';
      id: string;
      type: TaskTypeEnum;
      dueDatetime?: any | null;
      title?: string | null;
      status?: TaskAppointmentStatusEnum | null;
      agentId: string;
      teamId?: string | null;
      comment?: string | null;
      propertyIds: Array<string>;
      priority?: PriorityEnum | null;
      go3TaskKey?: number | null;
      taskContacts: Array<{ __typename?: 'DbTaskContact'; contactId: string }>;
      taskLeads: Array<{ __typename?: 'DbTaskLead'; leadId: string }>;
      taskAgents: Array<{
        __typename?: 'DbTaskAgent';
        agent: {
          __typename: 'DbAgent';
          id: string;
          firstName: string;
          lastName: string;
          email: string;
          status: AgentStatusEnum;
          mobilePhoneNumber?: string | null;
          officePhoneNumber?: string | null;
          profiles: Array<{
            __typename?: 'DbAgentProfile';
            images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
          }>;
        };
      }>;
    };
  } | null;
  appointmentActivity?: {
    __typename?: 'DbAppointmentActivity';
    createdAt: any;
    appointment: {
      __typename?: 'DbAppointment';
      id: string;
      type: AppointmentTypeEnum;
      startTime: any;
      endTime?: any | null;
      location?: string | null;
      subject?: string | null;
      status?: TaskAppointmentStatusEnum | null;
      agentId: string;
      teamId?: string | null;
      comment?: string | null;
      feedback?: string | null;
      cost?: any | null;
      propertyIds: Array<string>;
      go3AppointmentKey?: number | null;
      agent: { __typename?: 'DbAgent'; firstName: string; lastName: string };
      appointmentContacts: Array<{ __typename?: 'DbAppointmentContact'; contactId: string }>;
      appointmentLeads: Array<{ __typename?: 'DbAppointmentLead'; leadId: string }>;
      appointmentAgents: Array<{
        __typename?: 'DbAppointmentAgent';
        agent: {
          __typename: 'DbAgent';
          id: string;
          firstName: string;
          lastName: string;
          email: string;
          status: AgentStatusEnum;
          mobilePhoneNumber?: string | null;
          officePhoneNumber?: string | null;
          profiles: Array<{
            __typename?: 'DbAgentProfile';
            images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
          }>;
        };
      }>;
    };
  } | null;
  referralActivity?: {
    __typename?: 'DbReferralActivity';
    id: string;
    referral: {
      __typename?: 'DbReferral';
      id: string;
      createdAt: any;
      acceptedRefusedDate?: any | null;
      referralStatus: ReferralStatusEnum;
      commission: any;
      note?: string | null;
      sendingContactId?: string | null;
      leadId?: string | null;
      receivingLeadId?: string | null;
      receivingGo3ContactUtag?: string | null;
      type: ReferralTypeEnum;
      referralAgentNote?: string | null;
      referralNotPossibleReason?: ReferralNotPossibleReasonEnum | null;
      sendingShopId: string;
      receivingShopId?: string | null;
      receivingTeamId?: string | null;
      sendingTeamId?: string | null;
      snapshotId: string;
      leadByReceivingLeadId?: {
        __typename?: 'DbLead';
        contact: {
          __typename?: 'DbContact';
          id: string;
          blocked: boolean;
          emailAddresses: Array<{ __typename?: 'DbContactEmailAddress'; address: string; preferred: boolean }>;
          phoneNumbers: Array<{ __typename?: 'DbContactPhoneNumber'; number: string; preferred: boolean }>;
        };
        agent?: {
          __typename: 'DbAgent';
          createdAt: any;
          primaryShopId?: string | null;
          userName: string;
          salutation?: SalutationEnum | null;
          betaTester: boolean;
          energyAdvisoryEnabled: boolean;
          id: string;
          firstName: string;
          lastName: string;
          email: string;
          status: AgentStatusEnum;
          mobilePhoneNumber?: string | null;
          officePhoneNumber?: string | null;
          nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
          profiles: Array<{
            __typename?: 'DbAgentProfile';
            images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
          }>;
        } | null;
        team?: {
          __typename?: 'DbTeam';
          normalizedName?: string | null;
          description?: string | null;
          status?: TeamStatusEnum | null;
          id: string;
          name: string;
          shopId: string;
          email?: string | null;
          teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
        } | null;
        baseActivities: Array<{
          __typename?: 'DbBaseActivity';
          callActivity?: {
            __typename?: 'DbCallActivity';
            call?: {
              __typename?: 'DbCall';
              id: string;
              callSid: string;
              createdAt: any;
              duration?: any | null;
              outcome?: CallOutcomeEnum | null;
              note?: string | null;
              status: CallStatusEnum;
              shopId: string;
              recipient: string;
              caller: string;
              callDirection: { __typename?: 'EnumsCallDirection'; name: string };
              agent?: {
                __typename: 'DbAgent';
                createdAt: any;
                primaryShopId?: string | null;
                userName: string;
                salutation?: SalutationEnum | null;
                betaTester: boolean;
                energyAdvisoryEnabled: boolean;
                id: string;
                firstName: string;
                lastName: string;
                email: string;
                status: AgentStatusEnum;
                mobilePhoneNumber?: string | null;
                officePhoneNumber?: string | null;
                nylasEmailIntegrations: Array<{
                  __typename?: 'DbNylasEmailIntegration';
                  email: string;
                  active: boolean;
                }>;
                profiles: Array<{
                  __typename?: 'DbAgentProfile';
                  images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
                }>;
              } | null;
            } | null;
          } | null;
          emailActivity?: {
            __typename?: 'DbEmailActivity';
            id: string;
            email: {
              __typename?: 'DbEmail';
              id: string;
              status?: EmailStatusEnum | null;
              from: string;
              to: Array<string>;
              cc?: Array<string> | null;
              bcc?: Array<string> | null;
              subject: string;
              createdAt: any;
              direction?: EmailDirectionEnum | null;
              body: string;
              propertyIds: Array<string>;
              trackings: Array<{
                __typename?: 'DbEmailTracking';
                id: string;
                count: number;
                type: EmailTrackingTypeEnum;
                recipient?: string | null;
                updatedAt: any;
                linkUrl?: string | null;
              }>;
              attachments: Array<{
                __typename?: 'DbEmailAttachment';
                document: { __typename?: 'DbDocument'; id: string; contentType: string; name: string };
              }>;
            };
          } | null;
          appointmentActivity?: {
            __typename?: 'DbAppointmentActivity';
            createdAt: any;
            appointment: {
              __typename?: 'DbAppointment';
              id: string;
              type: AppointmentTypeEnum;
              startTime: any;
              endTime?: any | null;
              location?: string | null;
              subject?: string | null;
              status?: TaskAppointmentStatusEnum | null;
              agentId: string;
              teamId?: string | null;
              comment?: string | null;
              feedback?: string | null;
              cost?: any | null;
              propertyIds: Array<string>;
              go3AppointmentKey?: number | null;
              agent: { __typename?: 'DbAgent'; firstName: string; lastName: string };
              appointmentContacts: Array<{ __typename?: 'DbAppointmentContact'; contactId: string }>;
              appointmentLeads: Array<{ __typename?: 'DbAppointmentLead'; leadId: string }>;
              appointmentAgents: Array<{
                __typename?: 'DbAppointmentAgent';
                agent: {
                  __typename: 'DbAgent';
                  id: string;
                  firstName: string;
                  lastName: string;
                  email: string;
                  status: AgentStatusEnum;
                  mobilePhoneNumber?: string | null;
                  officePhoneNumber?: string | null;
                  profiles: Array<{
                    __typename?: 'DbAgentProfile';
                    images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
                  }>;
                };
              }>;
            };
          } | null;
        }>;
      } | null;
      agent: {
        __typename: 'DbAgent';
        createdAt: any;
        primaryShopId?: string | null;
        userName: string;
        salutation?: SalutationEnum | null;
        betaTester: boolean;
        energyAdvisoryEnabled: boolean;
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      };
      agentByReceivingAgentId?: {
        __typename: 'DbAgent';
        createdAt: any;
        primaryShopId?: string | null;
        userName: string;
        salutation?: SalutationEnum | null;
        betaTester: boolean;
        energyAdvisoryEnabled: boolean;
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      } | null;
      contactByReceivingLeadIdWrapper?: {
        __typename?: 'DbOneWayMappingFromLeadIdToContactId';
        contact?: { __typename?: 'DbContact'; id: string } | null;
      } | null;
      receivingShop?: {
        __typename?: 'DbShop';
        id: string;
        shopName: string;
        city?: string | null;
        countryCode: CountryEnum;
        postalCode?: string | null;
        streetName?: string | null;
        phone?: string | null;
        email?: string | null;
        status?: ShopStatusEnum | null;
        streetNumber?: string | null;
        onboardingLevel?: number | null;
        groupId?: string | null;
        latitude?: any | null;
        longitude?: any | null;
        shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
        licensePartner: {
          __typename?: 'DbLicensePartner';
          name: string;
          isCompanyOwnedOperation?: boolean | null;
          id: string;
        };
        profiles: Array<{
          __typename?: 'DbShopProfile';
          images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
        }>;
      } | null;
      sendingShop?: {
        __typename?: 'DbShop';
        id: string;
        shopName: string;
        city?: string | null;
        countryCode: CountryEnum;
        postalCode?: string | null;
        streetName?: string | null;
        phone?: string | null;
        email?: string | null;
        status?: ShopStatusEnum | null;
        streetNumber?: string | null;
        onboardingLevel?: number | null;
        groupId?: string | null;
        latitude?: any | null;
        longitude?: any | null;
        shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
        licensePartner: {
          __typename?: 'DbLicensePartner';
          name: string;
          isCompanyOwnedOperation?: boolean | null;
          id: string;
        };
        profiles: Array<{
          __typename?: 'DbShopProfile';
          images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
        }>;
      } | null;
      snapshotLead: {
        __typename?: 'DbSnapshotLead';
        contactFirstName?: string | null;
        contactLastName?: string | null;
        intentType: IntentTypeEnum;
      };
      contact?: { __typename?: 'DbContact'; id: string } | null;
      teamByReceivingTeamId?: { __typename?: 'DbTeam'; id: string; name: string; shopId: string } | null;
      teamBySendingTeamId?: { __typename?: 'DbTeam'; id: string; name: string; shopId: string } | null;
    };
  } | null;
  documentActivity?: {
    __typename?: 'DbDocumentActivity';
    id: string;
    documentReference?: {
      __typename?: 'DbDocumentReference';
      contactId?: string | null;
      contentType: string;
      createdAt: any;
      createdBy: string;
      description?: string | null;
      fileName: string;
      teamId?: string | null;
      privacyLevel: PrivacyLevelEnum;
      referenceId: string;
      referenceType: EntityTypeEnum;
      sizeKb?: number | null;
      id: string;
      displayName: string;
      createdByAgent?: {
        __typename: 'DbAgent';
        createdAt: any;
        primaryShopId?: string | null;
        userName: string;
        salutation?: SalutationEnum | null;
        betaTester: boolean;
        energyAdvisoryEnabled: boolean;
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      } | null;
      lead?: {
        __typename?: 'DbLead';
        id: string;
        contactId: string;
        teamId?: string | null;
        intentType: IntentTypeEnum;
        createdAt: any;
        expiredAt?: any | null;
        status: LeadStatusEnum;
        timeFrame?: TimeFrameEnum | null;
        agentId?: string | null;
        shopId: string;
        personalNote?: string | null;
        go3PropertyId?: string | null;
        peLinkedPropertyId?: string | null;
        sellerRentOutSpecification?: {
          __typename?: 'DbSellerRentOutSpecification';
          type?: AssetTypeEnum | null;
          livingArea?: any | null;
          priceRangeTo?: any | null;
          priceRangeFrom?: any | null;
          priceRangeUnit: CurrencyEnum;
          rentPriceUnit: CurrencyEnum;
          plotArea?: any | null;
          address?: {
            __typename?: 'DbAddress';
            city?: string | null;
            postalCode?: string | null;
            fullAddress?: string | null;
          } | null;
        } | null;
        buyerToRentSpecification?: {
          __typename?: 'DbBuyerToRentSpecification';
          searchDemandId?: string | null;
          type?: AssetTypeEnum | null;
          city?: string | null;
          livingAreaMax?: number | null;
          livingAreaMin?: number | null;
          totalSurfaceMax?: number | null;
          totalSurfaceMin?: number | null;
          plotSurfaceMax?: number | null;
          plotSurfaceMin?: number | null;
          priceMax?: number | null;
          priceMin?: number | null;
          currency: CurrencyEnum;
          area?: string | null;
          country?: string | null;
        } | null;
        emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
        leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
      } | null;
    } | null;
  } | null;
  contactChangeLogs: Array<{
    __typename?: 'DbContactChangeLog';
    id: string;
    oldValue?: string | null;
    newValue?: string | null;
    fieldName?: string | null;
  }>;
  base_activity_legacy_go3_change_logs: Array<{
    __typename?: 'DbLegacyGo3ChangeLog';
    id: string;
    oldValue?: string | null;
    newValue?: string | null;
    fieldName?: string | null;
  }>;
};

export type DashboardActivity = {
  __typename?: 'DbBaseActivity';
  id: string;
  category: BaseActivityCategoryEnum;
  leadId?: string | null;
  contactId?: string | null;
  type: ActivityTypeEnum;
  createdBy: string;
  createdAt: any;
  shopId?: string | null;
  contact?: { __typename?: 'DbContact'; firstName?: string | null; lastName?: string | null } | null;
  taskActivity?: {
    __typename?: 'DbTaskActivity';
    task: {
      __typename?: 'DbTask';
      id: string;
      type: TaskTypeEnum;
      dueDatetime?: any | null;
      title?: string | null;
      status?: TaskAppointmentStatusEnum | null;
      agentId: string;
      teamId?: string | null;
      comment?: string | null;
      propertyIds: Array<string>;
      priority?: PriorityEnum | null;
      go3TaskKey?: number | null;
      taskContacts: Array<{ __typename?: 'DbTaskContact'; contactId: string }>;
      taskLeads: Array<{ __typename?: 'DbTaskLead'; leadId: string }>;
      taskAgents: Array<{
        __typename?: 'DbTaskAgent';
        agent: {
          __typename: 'DbAgent';
          id: string;
          firstName: string;
          lastName: string;
          email: string;
          status: AgentStatusEnum;
          mobilePhoneNumber?: string | null;
          officePhoneNumber?: string | null;
          profiles: Array<{
            __typename?: 'DbAgentProfile';
            images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
          }>;
        };
      }>;
    };
  } | null;
  appointmentActivity?: {
    __typename?: 'DbAppointmentActivity';
    createdAt: any;
    appointment: {
      __typename?: 'DbAppointment';
      id: string;
      type: AppointmentTypeEnum;
      startTime: any;
      endTime?: any | null;
      location?: string | null;
      subject?: string | null;
      status?: TaskAppointmentStatusEnum | null;
      agentId: string;
      teamId?: string | null;
      comment?: string | null;
      feedback?: string | null;
      cost?: any | null;
      propertyIds: Array<string>;
      go3AppointmentKey?: number | null;
      agent: { __typename?: 'DbAgent'; firstName: string; lastName: string };
      appointmentContacts: Array<{ __typename?: 'DbAppointmentContact'; contactId: string }>;
      appointmentLeads: Array<{ __typename?: 'DbAppointmentLead'; leadId: string }>;
      appointmentAgents: Array<{
        __typename?: 'DbAppointmentAgent';
        agent: {
          __typename: 'DbAgent';
          id: string;
          firstName: string;
          lastName: string;
          email: string;
          status: AgentStatusEnum;
          mobilePhoneNumber?: string | null;
          officePhoneNumber?: string | null;
          profiles: Array<{
            __typename?: 'DbAgentProfile';
            images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
          }>;
        };
      }>;
    };
  } | null;
};

export type ExpandedActivityListItem = {
  __typename?: 'DbBaseActivity';
  id: string;
  category: BaseActivityCategoryEnum;
  contactId?: string | null;
  shopId?: string | null;
  leadId?: string | null;
  type: ActivityTypeEnum;
  createdBy: string;
  createdAt: any;
  creator?: { __typename?: 'DbAgent'; id: string; userName: string; firstName: string; lastName: string } | null;
  contact?: { __typename?: 'DbContact'; id: string; firstName?: string | null; lastName?: string | null } | null;
  callActivity?: {
    __typename?: 'DbCallActivity';
    id: string;
    call?: {
      __typename?: 'DbCall';
      direction: CallDirectionEnum;
      status: CallStatusEnum;
      duration?: any | null;
      createdAt: any;
      note?: string | null;
      outcome?: CallOutcomeEnum | null;
      agent?: { __typename?: 'DbAgent'; firstName: string; lastName: string } | null;
    } | null;
  } | null;
  emailActivity?: {
    __typename?: 'DbEmailActivity';
    id: string;
    email: {
      __typename?: 'DbEmail';
      direction?: EmailDirectionEnum | null;
      subject: string;
      propertyIds: Array<string>;
      createdAt: any;
      from: string;
      to: Array<string>;
      cc?: Array<string> | null;
      body: string;
    };
  } | null;
  taskActivity?: {
    __typename?: 'DbTaskActivity';
    id: string;
    task: {
      __typename?: 'DbTask';
      id: string;
      agentId: string;
      title?: string | null;
      type: TaskTypeEnum;
      propertyIds: Array<string>;
      dueDatetime?: any | null;
      status?: TaskAppointmentStatusEnum | null;
      updatedAt: any;
      comment?: string | null;
    };
  } | null;
  appointmentActivity?: {
    __typename?: 'DbAppointmentActivity';
    id: string;
    appointment: {
      __typename?: 'DbAppointment';
      id: string;
      agentId: string;
      subject?: string | null;
      type: AppointmentTypeEnum;
      propertyIds: Array<string>;
      startTime: any;
      endTime?: any | null;
      status?: TaskAppointmentStatusEnum | null;
      updatedAt: any;
      comment?: string | null;
    };
  } | null;
  documentActivity?: {
    __typename?: 'DbDocumentActivity';
    id: string;
    documentReference?: {
      __typename?: 'DbDocumentReference';
      id: string;
      fileName: string;
      contentType: string;
      createdAt: any;
      sizeKb?: number | null;
    } | null;
  } | null;
  exposeSharedActivity?: {
    __typename?: 'DbExposeeSharedActivity';
    id: string;
    propertyIds: Array<string>;
    status: string;
    title?: string | null;
    createdAt: any;
    comment?: string | null;
  } | null;
};

export type ActivityListItem = {
  __typename?: 'DbBaseActivity';
  id: string;
  category: BaseActivityCategoryEnum;
  contactId?: string | null;
  shopId?: string | null;
  leadId?: string | null;
  type: ActivityTypeEnum;
  createdBy: string;
  createdAt: any;
  creator?: { __typename?: 'DbAgent'; id: string; userName: string } | null;
  callActivity?: {
    __typename?: 'DbCallActivity';
    call?: {
      __typename?: 'DbCall';
      createdAt: any;
      direction: CallDirectionEnum;
      status: CallStatusEnum;
      duration?: any | null;
    } | null;
  } | null;
  emailActivity?: {
    __typename?: 'DbEmailActivity';
    email: {
      __typename?: 'DbEmail';
      subject: string;
      propertyIds: Array<string>;
      createdAt: any;
      direction?: EmailDirectionEnum | null;
    };
  } | null;
  taskActivity?: {
    __typename?: 'DbTaskActivity';
    task: {
      __typename?: 'DbTask';
      agentId: string;
      title?: string | null;
      type: TaskTypeEnum;
      dueDatetime?: any | null;
      propertyIds: Array<string>;
    };
  } | null;
  appointmentActivity?: {
    __typename?: 'DbAppointmentActivity';
    appointment: {
      __typename?: 'DbAppointment';
      agentId: string;
      subject?: string | null;
      type: AppointmentTypeEnum;
      propertyIds: Array<string>;
      startTime: any;
      endTime?: any | null;
    };
  } | null;
  documentActivity?: {
    __typename?: 'DbDocumentActivity';
    documentReference?: {
      __typename?: 'DbDocumentReference';
      fileName: string;
      contentType: string;
      createdAt: any;
    } | null;
  } | null;
  exposeSharedActivity?: {
    __typename?: 'DbExposeeSharedActivity';
    propertyIds: Array<string>;
    title?: string | null;
    createdAt: any;
  } | null;
};

export type BatchJobExecution = {
  __typename?: 'DbBatchJobExecution';
  startTime?: string | null;
  status?: string | null;
  jobExecutionId: any;
  jobInstanceId: any;
  endTime?: string | null;
  exitCode?: string | null;
  exitMessage?: string | null;
  batchJobExecutionParams: Array<{
    __typename?: 'DbBatchJobExecutionParams';
    parameterName: string;
    parameterValue?: string | null;
    parameterType: string;
  }>;
  batchStepExecutions: Array<{
    __typename?: 'DbBatchStepExecution';
    exitCode?: string | null;
    exitMessage?: string | null;
    endTime?: string | null;
    readCount?: any | null;
    status?: string | null;
    startTime?: string | null;
    stepName: string;
    writeCount?: any | null;
    stepExecutionId: any;
  }>;
};

export type BuyerToRentSpecification = {
  __typename?: 'DbBuyerToRentSpecification';
  area?: string | null;
  bedroomsMax?: number | null;
  bedroomsMin?: number | null;
  city?: string | null;
  country?: string | null;
  currency: CurrencyEnum;
  district?: string | null;
  id: string;
  legacyAreaNames: Array<string>;
  legacyDistrictNames: Array<string>;
  livingAreaMax?: number | null;
  livingAreaMin?: number | null;
  monthlyNetRentMax?: number | null;
  monthlyNetRentMin?: number | null;
  monthlyTotalRentMax?: number | null;
  monthlyTotalRentMin?: number | null;
  plotSurfaceMax?: number | null;
  plotSurfaceMin?: number | null;
  priceMax?: number | null;
  priceMin?: number | null;
  roomsMax?: number | null;
  roomsMin?: number | null;
  subTypes?: Array<string> | null;
  variations?: Array<string> | null;
  totalSurfaceMax?: number | null;
  totalSurfaceMin?: number | null;
  type?: AssetTypeEnum | null;
  placeIds: Array<string>;
  searchDemandId?: string | null;
  buyerToRentSpecificationCdfs: Array<{
    __typename?: 'DbBuyerToRentSpecificationCdf';
    cdf: { __typename?: 'DbCdf'; id: string; label: string };
  }>;
  customSearchDemandPolygons: Array<{
    __typename?: 'DbCustomSearchDemandPolygon';
    id: string;
    name?: string | null;
    polygon: any;
  }>;
};

export type CallInfo = {
  __typename?: 'DbCall';
  id: string;
  callSid: string;
  createdAt: any;
  duration?: any | null;
  outcome?: CallOutcomeEnum | null;
  note?: string | null;
  status: CallStatusEnum;
  shopId: string;
  recipient: string;
  caller: string;
  callDirection: { __typename?: 'EnumsCallDirection'; name: string };
  agent?: {
    __typename: 'DbAgent';
    createdAt: any;
    primaryShopId?: string | null;
    userName: string;
    salutation?: SalutationEnum | null;
    betaTester: boolean;
    energyAdvisoryEnabled: boolean;
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    status: AgentStatusEnum;
    mobilePhoneNumber?: string | null;
    officePhoneNumber?: string | null;
    nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
    profiles: Array<{
      __typename?: 'DbAgentProfile';
      images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
    }>;
  } | null;
};

export type Call = {
  __typename?: 'DbCall';
  id: string;
  callSid: string;
  createdAt: any;
  duration?: any | null;
  outcome?: CallOutcomeEnum | null;
  note?: string | null;
  status: CallStatusEnum;
  shopId: string;
  recipient: string;
  caller: string;
  call_activities: Array<{
    __typename?: 'DbCallActivity';
    baseActivity: {
      __typename?: 'DbBaseActivity';
      id: string;
      category: BaseActivityCategoryEnum;
      type: ActivityTypeEnum;
      createdAt: any;
      createdBy: string;
      contactId?: string | null;
      leadId?: string | null;
      shopId?: string | null;
      lead?: {
        __typename?: 'DbLead';
        contactId: string;
        id: string;
        intentType: IntentTypeEnum;
        status: LeadStatusEnum;
        expiredAt?: any | null;
        go3PropertyId?: string | null;
        peLinkedPropertyId?: string | null;
        personalNote?: string | null;
        timeFrame?: TimeFrameEnum | null;
        customerMessage?: string | null;
        sellerRentOutSpecification?: {
          __typename?: 'DbSellerRentOutSpecification';
          type?: AssetTypeEnum | null;
          livingArea?: any | null;
          plotArea?: any | null;
          constructionYear?: number | null;
          condition?: AssetConditionEnum | null;
          priceRangeTo?: any | null;
          priceRangeFrom?: any | null;
          rentRangeFrom?: any | null;
          rentRangeTo?: any | null;
          priceRangeUnit: CurrencyEnum;
          rentPriceUnit: CurrencyEnum;
          occupationStatus?: PropertyOccupationStatusEnum | null;
          numberOfBathrooms?: number | null;
          numberOfBedrooms?: number | null;
          floor?: number | null;
          numberOfMasterBathrooms?: number | null;
          numberOfRooms?: number | null;
          totalFloors?: number | null;
          surfaceTotal?: any | null;
          surfaceUsable?: any | null;
          balcony?: boolean | null;
          garden?: boolean | null;
          parking?: boolean | null;
          swimmingPool?: boolean | null;
          elevator?: boolean | null;
          garage?: boolean | null;
          guestToilet?: boolean | null;
          subType?: AssetSubTypeEnum | null;
          variation?: AssetVariationEnum | null;
          id: string;
          ownershipStatus?: PropertyOwnershipStatusEnum | null;
          ownerDescription?: string | null;
          address?: {
            __typename?: 'DbAddress';
            city?: string | null;
            postalCode?: string | null;
            countryCode: CountryEnum;
            streetName?: string | null;
            streetNumber?: string | null;
            secondAddressLine?: string | null;
            id: string;
            fullAddress?: string | null;
            placeId?: string | null;
            country: { __typename?: 'EnumsCountry'; description?: string | null; name: string };
          } | null;
          owner?: {
            __typename?: 'DbContact';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            fullName?: string | null;
          } | null;
          sellerRentOutSpecificationCdfs: Array<{
            __typename?: 'DbSellerRentOutSpecificationCdf';
            cdf: { __typename?: 'DbCdf'; id: string; label: string };
          }>;
        } | null;
        buyerToRentSpecification?: {
          __typename?: 'DbBuyerToRentSpecification';
          type?: AssetTypeEnum | null;
          city?: string | null;
          livingAreaMax?: number | null;
          livingAreaMin?: number | null;
          totalSurfaceMax?: number | null;
          totalSurfaceMin?: number | null;
          plotSurfaceMax?: number | null;
          plotSurfaceMin?: number | null;
          area?: string | null;
          bedroomsMax?: number | null;
          bedroomsMin?: number | null;
          country?: string | null;
          currency: CurrencyEnum;
          district?: string | null;
          id: string;
          legacyAreaNames: Array<string>;
          legacyDistrictNames: Array<string>;
          monthlyNetRentMax?: number | null;
          monthlyNetRentMin?: number | null;
          monthlyTotalRentMax?: number | null;
          monthlyTotalRentMin?: number | null;
          priceMax?: number | null;
          priceMin?: number | null;
          roomsMax?: number | null;
          roomsMin?: number | null;
          subTypes?: Array<string> | null;
          variations?: Array<string> | null;
          placeIds: Array<string>;
          searchDemandId?: string | null;
          buyerToRentSpecificationCdfs: Array<{
            __typename?: 'DbBuyerToRentSpecificationCdf';
            cdf: { __typename?: 'DbCdf'; id: string; label: string };
          }>;
          customSearchDemandPolygons: Array<{
            __typename?: 'DbCustomSearchDemandPolygon';
            id: string;
            name?: string | null;
            polygon: any;
          }>;
        } | null;
        referrals: Array<{ __typename?: 'DbReferral'; receivingShop?: { __typename?: 'DbShop'; id: string } | null }>;
      } | null;
      contact?: {
        __typename?: 'DbContact';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        salutation?: SalutationEnum | null;
        title?: string | null;
        birthdate?: any | null;
        contactStatus: ContactStatusEnum;
        blocked: boolean;
        personalNote?: string | null;
        preferredLanguage?: LanguageEnum | null;
        preferredTimes: Array<{ __typename?: 'DbContactPreferredTime'; preferredContactTime: ContactTimeEnum }>;
        emailAddresses: Array<{ __typename?: 'DbContactEmailAddress'; address: string; preferred: boolean }>;
        phoneNumbers: Array<{
          __typename?: 'DbContactPhoneNumber';
          number: string;
          preferred: boolean;
          type: PhoneNumberTypeEnum;
        }>;
      } | null;
    };
  }>;
  callDirection: { __typename?: 'EnumsCallDirection'; name: string };
  agent?: {
    __typename: 'DbAgent';
    createdAt: any;
    primaryShopId?: string | null;
    userName: string;
    salutation?: SalutationEnum | null;
    betaTester: boolean;
    energyAdvisoryEnabled: boolean;
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    status: AgentStatusEnum;
    mobilePhoneNumber?: string | null;
    officePhoneNumber?: string | null;
    nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
    profiles: Array<{
      __typename?: 'DbAgentProfile';
      images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
    }>;
  } | null;
};

export type CallActivity = {
  __typename?: 'DbCallActivity';
  call?: {
    __typename?: 'DbCall';
    id: string;
    callSid: string;
    createdAt: any;
    duration?: any | null;
    outcome?: CallOutcomeEnum | null;
    note?: string | null;
    status: CallStatusEnum;
    shopId: string;
    recipient: string;
    caller: string;
    callDirection: { __typename?: 'EnumsCallDirection'; name: string };
    agent?: {
      __typename: 'DbAgent';
      createdAt: any;
      primaryShopId?: string | null;
      userName: string;
      salutation?: SalutationEnum | null;
      betaTester: boolean;
      energyAdvisoryEnabled: boolean;
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
  } | null;
};

export type Consent = {
  __typename?: 'DbConsent';
  id: string;
  licenseId?: string | null;
  status: ConsentStatusEnum;
  consentType: ConsentTypeEnum;
  consentedOn?: any | null;
  updatedAt: any;
  revokedAt?: any | null;
  expiredAt?: any | null;
  consentSource: ConsentSourceEnum;
  contactForm: string;
  createdBy: string;
  creator: { __typename?: 'DbAgent'; firstName: string; lastName: string };
  document?: {
    __typename?: 'DbDocumentReference';
    contactId?: string | null;
    contentType: string;
    createdAt: any;
    createdBy: string;
    description?: string | null;
    fileName: string;
    teamId?: string | null;
    privacyLevel: PrivacyLevelEnum;
    referenceId: string;
    referenceType: EntityTypeEnum;
    sizeKb?: number | null;
    id: string;
    displayName: string;
    createdByAgent?: {
      __typename: 'DbAgent';
      createdAt: any;
      primaryShopId?: string | null;
      userName: string;
      salutation?: SalutationEnum | null;
      betaTester: boolean;
      energyAdvisoryEnabled: boolean;
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
    lead?: {
      __typename?: 'DbLead';
      id: string;
      contactId: string;
      teamId?: string | null;
      intentType: IntentTypeEnum;
      createdAt: any;
      expiredAt?: any | null;
      status: LeadStatusEnum;
      timeFrame?: TimeFrameEnum | null;
      agentId?: string | null;
      shopId: string;
      personalNote?: string | null;
      go3PropertyId?: string | null;
      peLinkedPropertyId?: string | null;
      sellerRentOutSpecification?: {
        __typename?: 'DbSellerRentOutSpecification';
        type?: AssetTypeEnum | null;
        livingArea?: any | null;
        priceRangeTo?: any | null;
        priceRangeFrom?: any | null;
        priceRangeUnit: CurrencyEnum;
        rentPriceUnit: CurrencyEnum;
        plotArea?: any | null;
        address?: {
          __typename?: 'DbAddress';
          city?: string | null;
          postalCode?: string | null;
          fullAddress?: string | null;
        } | null;
      } | null;
      buyerToRentSpecification?: {
        __typename?: 'DbBuyerToRentSpecification';
        searchDemandId?: string | null;
        type?: AssetTypeEnum | null;
        city?: string | null;
        livingAreaMax?: number | null;
        livingAreaMin?: number | null;
        totalSurfaceMax?: number | null;
        totalSurfaceMin?: number | null;
        plotSurfaceMax?: number | null;
        plotSurfaceMin?: number | null;
        priceMax?: number | null;
        priceMin?: number | null;
        currency: CurrencyEnum;
        area?: string | null;
        country?: string | null;
      } | null;
      emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
      leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
    } | null;
  } | null;
};

export type ConsentActivity = {
  __typename?: 'DbConsentActivity';
  id: string;
  consentId: string;
  content?: any | null;
  consent: {
    __typename?: 'DbConsent';
    createdAt: any;
    status: ConsentStatusEnum;
    licenseId?: string | null;
    expiredAt?: any | null;
    customerAccountId: string;
    contactForm: string;
    ipAddress?: string | null;
    consentType: ConsentTypeEnum;
    consentText?: string | null;
    url?: string | null;
    customerAccount: { __typename?: 'DbCustomerAccount'; emailAddress: string };
  };
};

export type ContactInfo = {
  __typename?: 'DbContact';
  id: string;
  go3Utag?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  preferredLanguage?: LanguageEnum | null;
  blocked: boolean;
  phoneNumbers: Array<{ __typename?: 'DbContactPhoneNumber'; number: string; preferred: boolean }>;
  emailAddresses: Array<{ __typename?: 'DbContactEmailAddress'; address: string; preferred: boolean }>;
};

export type Contact = {
  __typename?: 'DbContact';
  fullName?: string | null;
  salutation?: SalutationEnum | null;
  formattedSalutation?: string | null;
  title?: string | null;
  contactStatus: ContactStatusEnum;
  birthdate?: any | null;
  placeOfBirth?: string | null;
  nationality: CountryEnum;
  idNumber?: string | null;
  kycSearchId?: string | null;
  personalNote?: string | null;
  partnerFirstName?: string | null;
  partnerLastName?: string | null;
  deleted: boolean;
  createdAt: any;
  type: ContactTypeEnum;
  autoCc?: boolean | null;
  customerAccountId?: string | null;
  blocked: boolean;
  preferredLanguage?: LanguageEnum | null;
  shopId: string;
  id: string;
  go3Utag?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  addresses: Array<{
    __typename?: 'DbContactAddress';
    preferred: boolean;
    type: AddressTypeEnum;
    address: {
      __typename?: 'DbAddress';
      id: string;
      city?: string | null;
      countryCode: CountryEnum;
      postalCode?: string | null;
      secondAddressLine?: string | null;
      streetName?: string | null;
      streetNumber?: string | null;
      fullAddress?: string | null;
      placeId?: string | null;
      country: { __typename?: 'EnumsCountry'; description?: string | null; name: string };
    };
  }>;
  emailAddresses: Array<{
    __typename?: 'DbContactEmailAddress';
    address: string;
    preferred: boolean;
    type: EmailAddressTypeEnum;
  }>;
  legalEntity?: { __typename?: 'DbLegalEntity'; department?: string | null; id: string; name: string } | null;
  customerAccount?: {
    __typename?: 'DbCustomerAccount';
    emailAddress: string;
    consents: Array<{
      __typename?: 'DbConsent';
      id: string;
      licenseId?: string | null;
      status: ConsentStatusEnum;
      consentType: ConsentTypeEnum;
      consentedOn?: any | null;
      updatedAt: any;
      revokedAt?: any | null;
      expiredAt?: any | null;
      consentSource: ConsentSourceEnum;
      contactForm: string;
      createdBy: string;
      creator: { __typename?: 'DbAgent'; firstName: string; lastName: string };
      document?: {
        __typename?: 'DbDocumentReference';
        contactId?: string | null;
        contentType: string;
        createdAt: any;
        createdBy: string;
        description?: string | null;
        fileName: string;
        teamId?: string | null;
        privacyLevel: PrivacyLevelEnum;
        referenceId: string;
        referenceType: EntityTypeEnum;
        sizeKb?: number | null;
        id: string;
        displayName: string;
        createdByAgent?: {
          __typename: 'DbAgent';
          createdAt: any;
          primaryShopId?: string | null;
          userName: string;
          salutation?: SalutationEnum | null;
          betaTester: boolean;
          energyAdvisoryEnabled: boolean;
          id: string;
          firstName: string;
          lastName: string;
          email: string;
          status: AgentStatusEnum;
          mobilePhoneNumber?: string | null;
          officePhoneNumber?: string | null;
          nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
          profiles: Array<{
            __typename?: 'DbAgentProfile';
            images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
          }>;
        } | null;
        lead?: {
          __typename?: 'DbLead';
          id: string;
          contactId: string;
          teamId?: string | null;
          intentType: IntentTypeEnum;
          createdAt: any;
          expiredAt?: any | null;
          status: LeadStatusEnum;
          timeFrame?: TimeFrameEnum | null;
          agentId?: string | null;
          shopId: string;
          personalNote?: string | null;
          go3PropertyId?: string | null;
          peLinkedPropertyId?: string | null;
          sellerRentOutSpecification?: {
            __typename?: 'DbSellerRentOutSpecification';
            type?: AssetTypeEnum | null;
            livingArea?: any | null;
            priceRangeTo?: any | null;
            priceRangeFrom?: any | null;
            priceRangeUnit: CurrencyEnum;
            rentPriceUnit: CurrencyEnum;
            plotArea?: any | null;
            address?: {
              __typename?: 'DbAddress';
              city?: string | null;
              postalCode?: string | null;
              fullAddress?: string | null;
            } | null;
          } | null;
          buyerToRentSpecification?: {
            __typename?: 'DbBuyerToRentSpecification';
            searchDemandId?: string | null;
            type?: AssetTypeEnum | null;
            city?: string | null;
            livingAreaMax?: number | null;
            livingAreaMin?: number | null;
            totalSurfaceMax?: number | null;
            totalSurfaceMin?: number | null;
            plotSurfaceMax?: number | null;
            plotSurfaceMin?: number | null;
            priceMax?: number | null;
            priceMin?: number | null;
            currency: CurrencyEnum;
            area?: string | null;
            country?: string | null;
          } | null;
          emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
          leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
        } | null;
      } | null;
    }>;
  } | null;
  phoneNumbers: Array<{
    __typename?: 'DbContactPhoneNumber';
    number: string;
    internationalNumber?: string | null;
    preferred: boolean;
    type: PhoneNumberTypeEnum;
  }>;
  preferredTimes: Array<{ __typename?: 'DbContactPreferredTime'; preferredContactTime: ContactTimeEnum }>;
  contactRelationships: Array<{
    __typename?: 'DbContactRelationship';
    relatedContactId: string;
    autoCc?: boolean | null;
    type: ContactRelationTypeEnum;
    relatedContact: {
      __typename?: 'DbContact';
      id: string;
      go3Utag?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      preferredLanguage?: LanguageEnum | null;
      blocked: boolean;
      phoneNumbers: Array<{ __typename?: 'DbContactPhoneNumber'; number: string; preferred: boolean }>;
      emailAddresses: Array<{ __typename?: 'DbContactEmailAddress'; address: string; preferred: boolean }>;
    };
  }>;
  contactGdpr?: { __typename?: 'DbContactGdpr'; agreedToGdpr?: boolean | null } | null;
  contactRenterInformations: Array<{
    __typename?: 'DbContactRenterInformation';
    contactId: string;
    householdType?: HouseholdTypeEnum | null;
    professionType?: ProfessionTypeEnum | null;
    petOwner?: boolean | null;
    monthlyIncomeMin?: number | null;
    monthlyIncomeMax?: number | null;
    currency: CurrencyEnum;
  }>;
  tags: Array<{ __typename?: 'DbContactTag'; tag: { __typename?: 'DbTag'; id: string; name: string } }>;
  preferredPhone: Array<{ __typename?: 'DbContactPhoneNumber'; number: string }>;
  preferredEmail: Array<{ __typename?: 'DbContactEmailAddress'; address: string }>;
  leadhubGo3ContactLinks: Array<{
    __typename?: 'DbLeadhubGo3ContactLink';
    firstIntentCategory: IntentCategoryEnum;
    secondIntentCategory?: IntentCategoryEnum | null;
    thirdIntentCategory?: IntentCategoryEnum | null;
    firstAgent: {
      __typename: 'DbAgent';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    };
    secondAgent?: {
      __typename: 'DbAgent';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
    thirdAgent?: {
      __typename: 'DbAgent';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
  }>;
  emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
  shop: { __typename?: 'DbShop'; shopName: string };
  creator?: { __typename?: 'DbAgent'; firstName: string; lastName: string } | null;
};

export type ContactAndLeads = {
  __typename?: 'DbContact';
  fullName?: string | null;
  salutation?: SalutationEnum | null;
  formattedSalutation?: string | null;
  title?: string | null;
  contactStatus: ContactStatusEnum;
  birthdate?: any | null;
  placeOfBirth?: string | null;
  nationality: CountryEnum;
  idNumber?: string | null;
  kycSearchId?: string | null;
  personalNote?: string | null;
  partnerFirstName?: string | null;
  partnerLastName?: string | null;
  deleted: boolean;
  createdAt: any;
  type: ContactTypeEnum;
  autoCc?: boolean | null;
  customerAccountId?: string | null;
  blocked: boolean;
  preferredLanguage?: LanguageEnum | null;
  shopId: string;
  id: string;
  go3Utag?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  leads: Array<{
    __typename?: 'DbLead';
    id: string;
    contactId: string;
    teamId?: string | null;
    intentType: IntentTypeEnum;
    createdAt: any;
    expiredAt?: any | null;
    status: LeadStatusEnum;
    timeFrame?: TimeFrameEnum | null;
    agentId?: string | null;
    shopId: string;
    personalNote?: string | null;
    go3PropertyId?: string | null;
    peLinkedPropertyId?: string | null;
    lastInteractionActivity?: {
      __typename?: 'DbBaseActivity';
      orderDate: any;
      category: BaseActivityCategoryEnum;
      type: ActivityTypeEnum;
      createdBy: string;
      createdAt: any;
      shopId?: string | null;
      taskActivity?: { __typename?: 'DbTaskActivity'; task: { __typename?: 'DbTask'; type: TaskTypeEnum } } | null;
      appointmentActivity?: {
        __typename?: 'DbAppointmentActivity';
        appointment: { __typename?: 'DbAppointment'; type: AppointmentTypeEnum };
      } | null;
    } | null;
    sellerRentOutSpecification?: {
      __typename?: 'DbSellerRentOutSpecification';
      type?: AssetTypeEnum | null;
      livingArea?: any | null;
      priceRangeTo?: any | null;
      priceRangeFrom?: any | null;
      priceRangeUnit: CurrencyEnum;
      rentPriceUnit: CurrencyEnum;
      plotArea?: any | null;
      address?: {
        __typename?: 'DbAddress';
        city?: string | null;
        postalCode?: string | null;
        fullAddress?: string | null;
      } | null;
    } | null;
    buyerToRentSpecification?: {
      __typename?: 'DbBuyerToRentSpecification';
      searchDemandId?: string | null;
      type?: AssetTypeEnum | null;
      city?: string | null;
      livingAreaMax?: number | null;
      livingAreaMin?: number | null;
      totalSurfaceMax?: number | null;
      totalSurfaceMin?: number | null;
      plotSurfaceMax?: number | null;
      plotSurfaceMin?: number | null;
      priceMax?: number | null;
      priceMin?: number | null;
      currency: CurrencyEnum;
      area?: string | null;
      country?: string | null;
    } | null;
    emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
    leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
    lastActivity: Array<{
      __typename?: 'DbBaseActivity';
      type: ActivityTypeEnum;
      createdBy: string;
      createdAt: any;
      shopId?: string | null;
    }>;
  }>;
  addresses: Array<{
    __typename?: 'DbContactAddress';
    preferred: boolean;
    type: AddressTypeEnum;
    address: {
      __typename?: 'DbAddress';
      id: string;
      city?: string | null;
      countryCode: CountryEnum;
      postalCode?: string | null;
      secondAddressLine?: string | null;
      streetName?: string | null;
      streetNumber?: string | null;
      fullAddress?: string | null;
      placeId?: string | null;
      country: { __typename?: 'EnumsCountry'; description?: string | null; name: string };
    };
  }>;
  emailAddresses: Array<{
    __typename?: 'DbContactEmailAddress';
    address: string;
    preferred: boolean;
    type: EmailAddressTypeEnum;
  }>;
  legalEntity?: { __typename?: 'DbLegalEntity'; department?: string | null; id: string; name: string } | null;
  customerAccount?: {
    __typename?: 'DbCustomerAccount';
    emailAddress: string;
    consents: Array<{
      __typename?: 'DbConsent';
      id: string;
      licenseId?: string | null;
      status: ConsentStatusEnum;
      consentType: ConsentTypeEnum;
      consentedOn?: any | null;
      updatedAt: any;
      revokedAt?: any | null;
      expiredAt?: any | null;
      consentSource: ConsentSourceEnum;
      contactForm: string;
      createdBy: string;
      creator: { __typename?: 'DbAgent'; firstName: string; lastName: string };
      document?: {
        __typename?: 'DbDocumentReference';
        contactId?: string | null;
        contentType: string;
        createdAt: any;
        createdBy: string;
        description?: string | null;
        fileName: string;
        teamId?: string | null;
        privacyLevel: PrivacyLevelEnum;
        referenceId: string;
        referenceType: EntityTypeEnum;
        sizeKb?: number | null;
        id: string;
        displayName: string;
        createdByAgent?: {
          __typename: 'DbAgent';
          createdAt: any;
          primaryShopId?: string | null;
          userName: string;
          salutation?: SalutationEnum | null;
          betaTester: boolean;
          energyAdvisoryEnabled: boolean;
          id: string;
          firstName: string;
          lastName: string;
          email: string;
          status: AgentStatusEnum;
          mobilePhoneNumber?: string | null;
          officePhoneNumber?: string | null;
          nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
          profiles: Array<{
            __typename?: 'DbAgentProfile';
            images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
          }>;
        } | null;
        lead?: {
          __typename?: 'DbLead';
          id: string;
          contactId: string;
          teamId?: string | null;
          intentType: IntentTypeEnum;
          createdAt: any;
          expiredAt?: any | null;
          status: LeadStatusEnum;
          timeFrame?: TimeFrameEnum | null;
          agentId?: string | null;
          shopId: string;
          personalNote?: string | null;
          go3PropertyId?: string | null;
          peLinkedPropertyId?: string | null;
          sellerRentOutSpecification?: {
            __typename?: 'DbSellerRentOutSpecification';
            type?: AssetTypeEnum | null;
            livingArea?: any | null;
            priceRangeTo?: any | null;
            priceRangeFrom?: any | null;
            priceRangeUnit: CurrencyEnum;
            rentPriceUnit: CurrencyEnum;
            plotArea?: any | null;
            address?: {
              __typename?: 'DbAddress';
              city?: string | null;
              postalCode?: string | null;
              fullAddress?: string | null;
            } | null;
          } | null;
          buyerToRentSpecification?: {
            __typename?: 'DbBuyerToRentSpecification';
            searchDemandId?: string | null;
            type?: AssetTypeEnum | null;
            city?: string | null;
            livingAreaMax?: number | null;
            livingAreaMin?: number | null;
            totalSurfaceMax?: number | null;
            totalSurfaceMin?: number | null;
            plotSurfaceMax?: number | null;
            plotSurfaceMin?: number | null;
            priceMax?: number | null;
            priceMin?: number | null;
            currency: CurrencyEnum;
            area?: string | null;
            country?: string | null;
          } | null;
          emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
          leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
        } | null;
      } | null;
    }>;
  } | null;
  phoneNumbers: Array<{
    __typename?: 'DbContactPhoneNumber';
    number: string;
    internationalNumber?: string | null;
    preferred: boolean;
    type: PhoneNumberTypeEnum;
  }>;
  preferredTimes: Array<{ __typename?: 'DbContactPreferredTime'; preferredContactTime: ContactTimeEnum }>;
  contactRelationships: Array<{
    __typename?: 'DbContactRelationship';
    relatedContactId: string;
    autoCc?: boolean | null;
    type: ContactRelationTypeEnum;
    relatedContact: {
      __typename?: 'DbContact';
      id: string;
      go3Utag?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      preferredLanguage?: LanguageEnum | null;
      blocked: boolean;
      phoneNumbers: Array<{ __typename?: 'DbContactPhoneNumber'; number: string; preferred: boolean }>;
      emailAddresses: Array<{ __typename?: 'DbContactEmailAddress'; address: string; preferred: boolean }>;
    };
  }>;
  contactGdpr?: { __typename?: 'DbContactGdpr'; agreedToGdpr?: boolean | null } | null;
  contactRenterInformations: Array<{
    __typename?: 'DbContactRenterInformation';
    contactId: string;
    householdType?: HouseholdTypeEnum | null;
    professionType?: ProfessionTypeEnum | null;
    petOwner?: boolean | null;
    monthlyIncomeMin?: number | null;
    monthlyIncomeMax?: number | null;
    currency: CurrencyEnum;
  }>;
  tags: Array<{ __typename?: 'DbContactTag'; tag: { __typename?: 'DbTag'; id: string; name: string } }>;
  preferredPhone: Array<{ __typename?: 'DbContactPhoneNumber'; number: string }>;
  preferredEmail: Array<{ __typename?: 'DbContactEmailAddress'; address: string }>;
  leadhubGo3ContactLinks: Array<{
    __typename?: 'DbLeadhubGo3ContactLink';
    firstIntentCategory: IntentCategoryEnum;
    secondIntentCategory?: IntentCategoryEnum | null;
    thirdIntentCategory?: IntentCategoryEnum | null;
    firstAgent: {
      __typename: 'DbAgent';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    };
    secondAgent?: {
      __typename: 'DbAgent';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
    thirdAgent?: {
      __typename: 'DbAgent';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
  }>;
  emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
  shop: { __typename?: 'DbShop'; shopName: string };
  creator?: { __typename?: 'DbAgent'; firstName: string; lastName: string } | null;
};

export type ContactAddress = {
  __typename?: 'DbContactAddress';
  preferred: boolean;
  type: AddressTypeEnum;
  address: {
    __typename?: 'DbAddress';
    id: string;
    city?: string | null;
    countryCode: CountryEnum;
    postalCode?: string | null;
    secondAddressLine?: string | null;
    streetName?: string | null;
    streetNumber?: string | null;
    fullAddress?: string | null;
    placeId?: string | null;
    country: { __typename?: 'EnumsCountry'; description?: string | null; name: string };
  };
};

export type ContactChangeActivity = {
  __typename?: 'DbContactChangeLog';
  id: string;
  oldValue?: string | null;
  newValue?: string | null;
  fieldName?: string | null;
};

export type ContactConnectedPropertiesListing = {
  __typename?: 'PropertyEngineListing';
  assignedAgentId?: string | null;
  assignedTeamId?: string | null;
  id: string;
  lastActivation?: any | null;
  listingOwnerContactId?: string | null;
  status: PropertyEngineListingStatus;
  type: PropertyEngineListingType;
  utag: string;
  address?: { __typename?: 'PropertyEngineAddress'; streetName?: string | null; streetNumber?: string | null } | null;
};

export type ContactEmailAddress = {
  __typename?: 'DbContactEmailAddress';
  address: string;
  preferred: boolean;
  type: EmailAddressTypeEnum;
};

export type ContactLastActivity = {
  __typename?: 'DbContact';
  lastActivity: Array<{
    __typename?: 'DbBaseActivity';
    type: ActivityTypeEnum;
    createdBy: string;
    createdAt: any;
    shopId?: string | null;
  }>;
};

export type ContactListEntry = {
  __typename?: 'DbContact';
  createdAt: any;
  firstName?: string | null;
  id: string;
  lastName?: string | null;
  type: ContactTypeEnum;
  blocked: boolean;
  mostRecentActivityCreatedAt?: any | null;
  shopId: string;
  addresses: Array<{
    __typename?: 'DbContactAddress';
    preferred: boolean;
    type: AddressTypeEnum;
    address: {
      __typename?: 'DbAddress';
      id: string;
      city?: string | null;
      countryCode: CountryEnum;
      postalCode?: string | null;
      secondAddressLine?: string | null;
      streetName?: string | null;
      streetNumber?: string | null;
      fullAddress?: string | null;
      placeId?: string | null;
      country: { __typename?: 'EnumsCountry'; description?: string | null; name: string };
    };
  }>;
  contactGdpr?: {
    __typename?: 'DbContactGdpr';
    agreedToGdpr?: boolean | null;
    blockedDueToGdpr?: boolean | null;
  } | null;
  emailAddresses: Array<{
    __typename?: 'DbContactEmailAddress';
    address: string;
    preferred: boolean;
    type: EmailAddressTypeEnum;
  }>;
  lastActivity: Array<{
    __typename?: 'DbBaseActivity';
    type: ActivityTypeEnum;
    createdBy: string;
    createdAt: any;
    shopId?: string | null;
  }>;
  tags: Array<{ __typename?: 'DbContactTag'; tagId: string }>;
  leads: Array<{
    __typename?: 'DbLead';
    id: string;
    intentType: IntentTypeEnum;
    agentId?: string | null;
    agent?: { __typename?: 'DbAgent'; firstName: string; lastName: string } | null;
  }>;
  phoneNumbers: Array<{
    __typename?: 'DbContactPhoneNumber';
    number: string;
    preferred: boolean;
    internationalNumber?: string | null;
  }>;
};

export type ConversionData = {
  __typename?: 'DbConversionData';
  referrer?: string | null;
  landingPageUrlWithUtm?: string | null;
  landingPageOwnerShopId?: string | null;
  campaignOwnerShopId?: string | null;
};

export type CustomView = {
  __typename?: 'DbCustomListView';
  id: string;
  name: string;
  shownByDefault?: boolean | null;
  type: CustomViewTypeEnum;
  viewData: any;
  viewIndex: number;
};

export type DocumentInfo = { __typename?: 'DbDocumentReference'; id: string; displayName: string };

export type Document = {
  __typename?: 'DbDocumentReference';
  contactId?: string | null;
  contentType: string;
  createdAt: any;
  createdBy: string;
  description?: string | null;
  fileName: string;
  teamId?: string | null;
  privacyLevel: PrivacyLevelEnum;
  referenceId: string;
  referenceType: EntityTypeEnum;
  sizeKb?: number | null;
  id: string;
  displayName: string;
  createdByAgent?: {
    __typename: 'DbAgent';
    createdAt: any;
    primaryShopId?: string | null;
    userName: string;
    salutation?: SalutationEnum | null;
    betaTester: boolean;
    energyAdvisoryEnabled: boolean;
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    status: AgentStatusEnum;
    mobilePhoneNumber?: string | null;
    officePhoneNumber?: string | null;
    nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
    profiles: Array<{
      __typename?: 'DbAgentProfile';
      images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
    }>;
  } | null;
  lead?: {
    __typename?: 'DbLead';
    id: string;
    contactId: string;
    teamId?: string | null;
    intentType: IntentTypeEnum;
    createdAt: any;
    expiredAt?: any | null;
    status: LeadStatusEnum;
    timeFrame?: TimeFrameEnum | null;
    agentId?: string | null;
    shopId: string;
    personalNote?: string | null;
    go3PropertyId?: string | null;
    peLinkedPropertyId?: string | null;
    sellerRentOutSpecification?: {
      __typename?: 'DbSellerRentOutSpecification';
      type?: AssetTypeEnum | null;
      livingArea?: any | null;
      priceRangeTo?: any | null;
      priceRangeFrom?: any | null;
      priceRangeUnit: CurrencyEnum;
      rentPriceUnit: CurrencyEnum;
      plotArea?: any | null;
      address?: {
        __typename?: 'DbAddress';
        city?: string | null;
        postalCode?: string | null;
        fullAddress?: string | null;
      } | null;
    } | null;
    buyerToRentSpecification?: {
      __typename?: 'DbBuyerToRentSpecification';
      searchDemandId?: string | null;
      type?: AssetTypeEnum | null;
      city?: string | null;
      livingAreaMax?: number | null;
      livingAreaMin?: number | null;
      totalSurfaceMax?: number | null;
      totalSurfaceMin?: number | null;
      plotSurfaceMax?: number | null;
      plotSurfaceMin?: number | null;
      priceMax?: number | null;
      priceMin?: number | null;
      currency: CurrencyEnum;
      area?: string | null;
      country?: string | null;
    } | null;
    emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
    leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
  } | null;
};

export type DocumentActivity = {
  __typename?: 'DbDocumentActivity';
  id: string;
  documentReference?: {
    __typename?: 'DbDocumentReference';
    contactId?: string | null;
    contentType: string;
    createdAt: any;
    createdBy: string;
    description?: string | null;
    fileName: string;
    teamId?: string | null;
    privacyLevel: PrivacyLevelEnum;
    referenceId: string;
    referenceType: EntityTypeEnum;
    sizeKb?: number | null;
    id: string;
    displayName: string;
    createdByAgent?: {
      __typename: 'DbAgent';
      createdAt: any;
      primaryShopId?: string | null;
      userName: string;
      salutation?: SalutationEnum | null;
      betaTester: boolean;
      energyAdvisoryEnabled: boolean;
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
    lead?: {
      __typename?: 'DbLead';
      id: string;
      contactId: string;
      teamId?: string | null;
      intentType: IntentTypeEnum;
      createdAt: any;
      expiredAt?: any | null;
      status: LeadStatusEnum;
      timeFrame?: TimeFrameEnum | null;
      agentId?: string | null;
      shopId: string;
      personalNote?: string | null;
      go3PropertyId?: string | null;
      peLinkedPropertyId?: string | null;
      sellerRentOutSpecification?: {
        __typename?: 'DbSellerRentOutSpecification';
        type?: AssetTypeEnum | null;
        livingArea?: any | null;
        priceRangeTo?: any | null;
        priceRangeFrom?: any | null;
        priceRangeUnit: CurrencyEnum;
        rentPriceUnit: CurrencyEnum;
        plotArea?: any | null;
        address?: {
          __typename?: 'DbAddress';
          city?: string | null;
          postalCode?: string | null;
          fullAddress?: string | null;
        } | null;
      } | null;
      buyerToRentSpecification?: {
        __typename?: 'DbBuyerToRentSpecification';
        searchDemandId?: string | null;
        type?: AssetTypeEnum | null;
        city?: string | null;
        livingAreaMax?: number | null;
        livingAreaMin?: number | null;
        totalSurfaceMax?: number | null;
        totalSurfaceMin?: number | null;
        plotSurfaceMax?: number | null;
        plotSurfaceMin?: number | null;
        priceMax?: number | null;
        priceMin?: number | null;
        currency: CurrencyEnum;
        area?: string | null;
        country?: string | null;
      } | null;
      emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
      leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
    } | null;
  } | null;
};

export type DuplicateContact = {
  __typename?: 'DbPotentialDuplicateContact';
  createdAt: any;
  groupId: string;
  matchedEmail?: Array<string> | null;
  matchedPhone?: Array<string> | null;
  contact: {
    __typename?: 'DbContact';
    contactStatus: ContactStatusEnum;
    createdAt: any;
    fullName?: string | null;
    id: string;
    blocked: boolean;
    leads: Array<{
      __typename?: 'DbLead';
      id: string;
      contactId: string;
      teamId?: string | null;
      intentType: IntentTypeEnum;
      createdAt: any;
      expiredAt?: any | null;
      status: LeadStatusEnum;
      timeFrame?: TimeFrameEnum | null;
      agentId?: string | null;
      shopId: string;
      personalNote?: string | null;
      go3PropertyId?: string | null;
      peLinkedPropertyId?: string | null;
      sellerRentOutSpecification?: {
        __typename?: 'DbSellerRentOutSpecification';
        type?: AssetTypeEnum | null;
        livingArea?: any | null;
        priceRangeTo?: any | null;
        priceRangeFrom?: any | null;
        priceRangeUnit: CurrencyEnum;
        rentPriceUnit: CurrencyEnum;
        plotArea?: any | null;
        address?: {
          __typename?: 'DbAddress';
          city?: string | null;
          postalCode?: string | null;
          fullAddress?: string | null;
        } | null;
      } | null;
      buyerToRentSpecification?: {
        __typename?: 'DbBuyerToRentSpecification';
        searchDemandId?: string | null;
        type?: AssetTypeEnum | null;
        city?: string | null;
        livingAreaMax?: number | null;
        livingAreaMin?: number | null;
        totalSurfaceMax?: number | null;
        totalSurfaceMin?: number | null;
        plotSurfaceMax?: number | null;
        plotSurfaceMin?: number | null;
        priceMax?: number | null;
        priceMin?: number | null;
        currency: CurrencyEnum;
        area?: string | null;
        country?: string | null;
      } | null;
      emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
      leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
      lastActivity: Array<{
        __typename?: 'DbBaseActivity';
        type: ActivityTypeEnum;
        createdBy: string;
        createdAt: any;
        shopId?: string | null;
      }>;
    }>;
    emailAddresses: Array<{
      __typename?: 'DbContactEmailAddress';
      address: string;
      normalizedAddress?: string | null;
      preferred: boolean;
      type: EmailAddressTypeEnum;
    }>;
    phoneNumbers: Array<{
      __typename?: 'DbContactPhoneNumber';
      number: string;
      internationalNumber?: string | null;
      normalizedLocalNumber?: string | null;
      preferred: boolean;
      type: PhoneNumberTypeEnum;
    }>;
    lastInteractionActivity?: {
      __typename?: 'DbBaseActivity';
      orderDate: any;
      category: BaseActivityCategoryEnum;
      type: ActivityTypeEnum;
      createdBy: string;
      createdAt: any;
      shopId?: string | null;
      taskActivity?: { __typename?: 'DbTaskActivity'; task: { __typename?: 'DbTask'; type: TaskTypeEnum } } | null;
      appointmentActivity?: {
        __typename?: 'DbAppointmentActivity';
        appointment: { __typename?: 'DbAppointment'; type: AppointmentTypeEnum };
      } | null;
    } | null;
    lastActivity: Array<{
      __typename?: 'DbBaseActivity';
      type: ActivityTypeEnum;
      createdBy: string;
      createdAt: any;
      shopId?: string | null;
    }>;
  };
};

export type DuplicateContactDetails = {
  __typename?: 'DbContact';
  contactStatus: ContactStatusEnum;
  createdAt: any;
  fullName?: string | null;
  id: string;
  blocked: boolean;
  leads: Array<{
    __typename?: 'DbLead';
    id: string;
    contactId: string;
    teamId?: string | null;
    intentType: IntentTypeEnum;
    createdAt: any;
    expiredAt?: any | null;
    status: LeadStatusEnum;
    timeFrame?: TimeFrameEnum | null;
    agentId?: string | null;
    shopId: string;
    personalNote?: string | null;
    go3PropertyId?: string | null;
    peLinkedPropertyId?: string | null;
    sellerRentOutSpecification?: {
      __typename?: 'DbSellerRentOutSpecification';
      type?: AssetTypeEnum | null;
      livingArea?: any | null;
      priceRangeTo?: any | null;
      priceRangeFrom?: any | null;
      priceRangeUnit: CurrencyEnum;
      rentPriceUnit: CurrencyEnum;
      plotArea?: any | null;
      address?: {
        __typename?: 'DbAddress';
        city?: string | null;
        postalCode?: string | null;
        fullAddress?: string | null;
      } | null;
    } | null;
    buyerToRentSpecification?: {
      __typename?: 'DbBuyerToRentSpecification';
      searchDemandId?: string | null;
      type?: AssetTypeEnum | null;
      city?: string | null;
      livingAreaMax?: number | null;
      livingAreaMin?: number | null;
      totalSurfaceMax?: number | null;
      totalSurfaceMin?: number | null;
      plotSurfaceMax?: number | null;
      plotSurfaceMin?: number | null;
      priceMax?: number | null;
      priceMin?: number | null;
      currency: CurrencyEnum;
      area?: string | null;
      country?: string | null;
    } | null;
    emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
    leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
    lastActivity: Array<{
      __typename?: 'DbBaseActivity';
      type: ActivityTypeEnum;
      createdBy: string;
      createdAt: any;
      shopId?: string | null;
    }>;
  }>;
  emailAddresses: Array<{
    __typename?: 'DbContactEmailAddress';
    address: string;
    normalizedAddress?: string | null;
    preferred: boolean;
    type: EmailAddressTypeEnum;
  }>;
  phoneNumbers: Array<{
    __typename?: 'DbContactPhoneNumber';
    number: string;
    internationalNumber?: string | null;
    normalizedLocalNumber?: string | null;
    preferred: boolean;
    type: PhoneNumberTypeEnum;
  }>;
  lastInteractionActivity?: {
    __typename?: 'DbBaseActivity';
    orderDate: any;
    category: BaseActivityCategoryEnum;
    type: ActivityTypeEnum;
    createdBy: string;
    createdAt: any;
    shopId?: string | null;
    taskActivity?: { __typename?: 'DbTaskActivity'; task: { __typename?: 'DbTask'; type: TaskTypeEnum } } | null;
    appointmentActivity?: {
      __typename?: 'DbAppointmentActivity';
      appointment: { __typename?: 'DbAppointment'; type: AppointmentTypeEnum };
    } | null;
  } | null;
  lastActivity: Array<{
    __typename?: 'DbBaseActivity';
    type: ActivityTypeEnum;
    createdBy: string;
    createdAt: any;
    shopId?: string | null;
  }>;
};

export type BulkEmailFragment = {
  __typename?: 'DbBulkEmail';
  id?: string | null;
  from?: string | null;
  to?: Array<string> | null;
  cc?: Array<string> | null;
  bcc?: Array<string> | null;
  subject?: string | null;
  body?: string | null;
  status?: string | null;
  createdAt?: any | null;
  attachments: Array<{
    __typename?: 'DbEmailAttachment';
    document: { __typename?: 'DbDocument'; id: string; contentType: string; name: string };
  }>;
  documentReferences: Array<{
    __typename?: 'DbDocumentReference';
    id: string;
    fileName: string;
    sizeKb?: number | null;
  }>;
  trackings: Array<{
    __typename?: 'DbEmailTracking';
    id: string;
    count: number;
    type: EmailTrackingTypeEnum;
    updatedAt: any;
    linkUrl?: string | null;
    recipient?: string | null;
  }>;
  emailActivities: Array<{
    __typename?: 'DbEmailActivity';
    baseActivity: { __typename?: 'DbBaseActivity'; contactId?: string | null };
  }>;
  template?: { __typename?: 'DbEmailTemplate'; name: string } | null;
};

export type EmailActivity = {
  __typename?: 'DbEmailActivity';
  id: string;
  email: {
    __typename?: 'DbEmail';
    id: string;
    status?: EmailStatusEnum | null;
    from: string;
    to: Array<string>;
    cc?: Array<string> | null;
    bcc?: Array<string> | null;
    subject: string;
    createdAt: any;
    direction?: EmailDirectionEnum | null;
    body: string;
    propertyIds: Array<string>;
    trackings: Array<{
      __typename?: 'DbEmailTracking';
      id: string;
      count: number;
      type: EmailTrackingTypeEnum;
      recipient?: string | null;
      updatedAt: any;
      linkUrl?: string | null;
    }>;
    attachments: Array<{
      __typename?: 'DbEmailAttachment';
      document: { __typename?: 'DbDocument'; id: string; contentType: string; name: string };
    }>;
  };
};

export type NylasEmailIntegration = {
  __typename?: 'DbNylasEmailIntegration';
  id: string;
  email: string;
  agentId?: string | null;
  shopId?: string | null;
  createdAt: any;
  updatedAt: any;
  scope: EmailIntegrationScopeEnum;
  active: boolean;
  isDefault: {
    __typename?: 'DbAgentDefaultNylasEmailIntegrationAggregate';
    aggregate?: { __typename?: 'DbAgentDefaultNylasEmailIntegrationAggregateFields'; count: number } | null;
  };
};

export type EmailPlaceholder = {
  __typename?: 'DbEmailPlaceholder';
  name: string;
  mapping: string;
  scope: EmailPlaceholderScopeEnum;
  type: EmailPlaceholderTypeEnum;
};

export type EmailSignature = {
  __typename?: 'DbEmailSignature';
  id: string;
  scope: EmailSignatureScopeEnum;
  name: string;
  signature: string;
  agentId?: string | null;
  shopId?: string | null;
  teamId?: string | null;
  createdAt: any;
  updatedAt: any;
  isDefault: {
    __typename?: 'DbAgentDefaultEmailSignatureAggregate';
    aggregate?: { __typename?: 'DbAgentDefaultEmailSignatureAggregateFields'; count: number } | null;
  };
};

export type EmailTemplate = {
  __typename?: 'DbEmailTemplate';
  id: string;
  scope: EmailTemplateScopeEnum;
  name: string;
  type: EmailTemplateTypeEnum;
  agentId?: string | null;
  shopId?: string | null;
  teamId?: string | null;
  createdAt: any;
  updatedAt: any;
  contents: Array<{ __typename?: 'DbEmailTemplateContent'; language: LanguageEnum; subject: string; body: string }>;
};

export type EmailTracking = {
  __typename?: 'DbEmailTracking';
  id: string;
  emailId: string;
  type: EmailTrackingTypeEnum;
  linkUrl?: string | null;
  count: number;
  updatedAt: any;
  email?: { __typename?: 'DbEmailMessageMap'; messageId?: string | null } | null;
};

export type ExposeSharedActivity = {
  __typename?: 'DbExposeeSharedActivity';
  id: string;
  propertyIds: Array<string>;
  status: string;
  title?: string | null;
  updatedAt: any;
  createdAt: any;
  comment?: string | null;
};

export type KeycloakUserData = {
  __typename?: 'KeycloakUser';
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  ldapId?: string | null;
};

export type LastInteraction = {
  __typename?: 'DbBaseActivity';
  orderDate: any;
  category: BaseActivityCategoryEnum;
  type: ActivityTypeEnum;
  createdBy: string;
  createdAt: any;
  shopId?: string | null;
  taskActivity?: { __typename?: 'DbTaskActivity'; task: { __typename?: 'DbTask'; type: TaskTypeEnum } } | null;
  appointmentActivity?: {
    __typename?: 'DbAppointmentActivity';
    appointment: { __typename?: 'DbAppointment'; type: AppointmentTypeEnum };
  } | null;
};

export type LeadInfo = {
  __typename?: 'DbLead';
  id: string;
  contactId: string;
  teamId?: string | null;
  intentType: IntentTypeEnum;
  createdAt: any;
  expiredAt?: any | null;
  status: LeadStatusEnum;
  timeFrame?: TimeFrameEnum | null;
  agentId?: string | null;
  shopId: string;
  personalNote?: string | null;
  go3PropertyId?: string | null;
  peLinkedPropertyId?: string | null;
  sellerRentOutSpecification?: {
    __typename?: 'DbSellerRentOutSpecification';
    type?: AssetTypeEnum | null;
    livingArea?: any | null;
    priceRangeTo?: any | null;
    priceRangeFrom?: any | null;
    priceRangeUnit: CurrencyEnum;
    rentPriceUnit: CurrencyEnum;
    plotArea?: any | null;
    address?: {
      __typename?: 'DbAddress';
      city?: string | null;
      postalCode?: string | null;
      fullAddress?: string | null;
    } | null;
  } | null;
  buyerToRentSpecification?: {
    __typename?: 'DbBuyerToRentSpecification';
    searchDemandId?: string | null;
    type?: AssetTypeEnum | null;
    city?: string | null;
    livingAreaMax?: number | null;
    livingAreaMin?: number | null;
    totalSurfaceMax?: number | null;
    totalSurfaceMin?: number | null;
    plotSurfaceMax?: number | null;
    plotSurfaceMin?: number | null;
    priceMax?: number | null;
    priceMin?: number | null;
    currency: CurrencyEnum;
    area?: string | null;
    country?: string | null;
  } | null;
  emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
  leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
};

export type Lead = {
  __typename?: 'DbLead';
  contactId: string;
  status: LeadStatusEnum;
  source: LeadSourceEnum;
  priority?: PriorityEnum | null;
  customerMessage?: string | null;
  statusReason?: LeadStatusReasonEnum | null;
  statusNote?: string | null;
  agentId?: string | null;
  shopId: string;
  teamId?: string | null;
  id: string;
  intentType: IntentTypeEnum;
  createdAt: any;
  expiredAt?: any | null;
  timeFrame?: TimeFrameEnum | null;
  personalNote?: string | null;
  go3PropertyId?: string | null;
  peLinkedPropertyId?: string | null;
  utmParams?: {
    __typename?: 'DbUtmParam';
    autaclid?: string | null;
    msclkid?: string | null;
    pmclid?: string | null;
    campaign?: string | null;
    content?: string | null;
    gclid?: string | null;
    medium?: string | null;
    source?: string | null;
    url?: string | null;
    gbraid?: string | null;
    wbraid?: string | null;
  } | null;
  conversionData: Array<{
    __typename?: 'DbConversionData';
    referrer?: string | null;
    landingPageUrlWithUtm?: string | null;
    landingPageOwnerShopId?: string | null;
    campaignOwnerShopId?: string | null;
  }>;
  activities: Array<{
    __typename?: 'DbBaseActivity';
    type: ActivityTypeEnum;
    createdBy: string;
    createdAt: any;
    shopId?: string | null;
  }>;
  leadExpiryByLead?: {
    __typename?: 'DbLeadExpiry';
    expirationDate?: any | null;
    id?: string | null;
    status?: string | null;
  } | null;
  contact: { __typename?: 'DbContact'; go3ContactKey?: number | null };
  agent?: {
    __typename: 'DbAgent';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    status: AgentStatusEnum;
    mobilePhoneNumber?: string | null;
    officePhoneNumber?: string | null;
    profiles: Array<{
      __typename?: 'DbAgentProfile';
      images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
    }>;
  } | null;
  shop: {
    __typename?: 'DbShop';
    id: string;
    shopName: string;
    city?: string | null;
    countryCode: CountryEnum;
    postalCode?: string | null;
    streetName?: string | null;
    phone?: string | null;
    email?: string | null;
    status?: ShopStatusEnum | null;
    streetNumber?: string | null;
    onboardingLevel?: number | null;
    groupId?: string | null;
    latitude?: any | null;
    longitude?: any | null;
    shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
    licensePartner: {
      __typename?: 'DbLicensePartner';
      name: string;
      isCompanyOwnedOperation?: boolean | null;
      id: string;
    };
    profiles: Array<{
      __typename?: 'DbShopProfile';
      images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
    }>;
  };
  referrals: Array<{
    __typename?: 'DbReferral';
    id: string;
    go3ReferralId?: number | null;
    referralNotPossibleReason?: ReferralNotPossibleReasonEnum | null;
    referralAgentNote?: string | null;
    receivingShop?: {
      __typename?: 'DbShop';
      id: string;
      shopName: string;
      city?: string | null;
      countryCode: CountryEnum;
      postalCode?: string | null;
      streetName?: string | null;
      phone?: string | null;
      email?: string | null;
      status?: ShopStatusEnum | null;
      streetNumber?: string | null;
      onboardingLevel?: number | null;
      groupId?: string | null;
      latitude?: any | null;
      longitude?: any | null;
      shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
      licensePartner: {
        __typename?: 'DbLicensePartner';
        name: string;
        isCompanyOwnedOperation?: boolean | null;
        id: string;
      };
      profiles: Array<{
        __typename?: 'DbShopProfile';
        images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
  }>;
  sellerRentOutSpecification?: {
    __typename?: 'DbSellerRentOutSpecification';
    type?: AssetTypeEnum | null;
    livingArea?: any | null;
    priceRangeTo?: any | null;
    priceRangeFrom?: any | null;
    priceRangeUnit: CurrencyEnum;
    rentPriceUnit: CurrencyEnum;
    plotArea?: any | null;
    constructionYear?: number | null;
    condition?: AssetConditionEnum | null;
    rentRangeFrom?: any | null;
    rentRangeTo?: any | null;
    occupationStatus?: PropertyOccupationStatusEnum | null;
    numberOfBathrooms?: number | null;
    numberOfBedrooms?: number | null;
    floor?: number | null;
    numberOfMasterBathrooms?: number | null;
    numberOfRooms?: number | null;
    totalFloors?: number | null;
    surfaceTotal?: any | null;
    surfaceUsable?: any | null;
    balcony?: boolean | null;
    garden?: boolean | null;
    parking?: boolean | null;
    swimmingPool?: boolean | null;
    elevator?: boolean | null;
    garage?: boolean | null;
    guestToilet?: boolean | null;
    subType?: AssetSubTypeEnum | null;
    variation?: AssetVariationEnum | null;
    id: string;
    ownershipStatus?: PropertyOwnershipStatusEnum | null;
    ownerDescription?: string | null;
    address?: {
      __typename?: 'DbAddress';
      city?: string | null;
      postalCode?: string | null;
      fullAddress?: string | null;
      id: string;
      countryCode: CountryEnum;
      secondAddressLine?: string | null;
      streetName?: string | null;
      streetNumber?: string | null;
      placeId?: string | null;
      country: { __typename?: 'EnumsCountry'; description?: string | null; name: string };
    } | null;
    owner?: {
      __typename?: 'DbContact';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      fullName?: string | null;
    } | null;
    sellerRentOutSpecificationCdfs: Array<{
      __typename?: 'DbSellerRentOutSpecificationCdf';
      cdf: { __typename?: 'DbCdf'; id: string; label: string };
    }>;
  } | null;
  buyerToRentSpecification?: {
    __typename?: 'DbBuyerToRentSpecification';
    searchDemandId?: string | null;
    type?: AssetTypeEnum | null;
    city?: string | null;
    livingAreaMax?: number | null;
    livingAreaMin?: number | null;
    totalSurfaceMax?: number | null;
    totalSurfaceMin?: number | null;
    plotSurfaceMax?: number | null;
    plotSurfaceMin?: number | null;
    priceMax?: number | null;
    priceMin?: number | null;
    currency: CurrencyEnum;
    area?: string | null;
    country?: string | null;
    bedroomsMax?: number | null;
    bedroomsMin?: number | null;
    district?: string | null;
    id: string;
    legacyAreaNames: Array<string>;
    legacyDistrictNames: Array<string>;
    monthlyNetRentMax?: number | null;
    monthlyNetRentMin?: number | null;
    monthlyTotalRentMax?: number | null;
    monthlyTotalRentMin?: number | null;
    roomsMax?: number | null;
    roomsMin?: number | null;
    subTypes?: Array<string> | null;
    variations?: Array<string> | null;
    placeIds: Array<string>;
    buyerToRentSpecificationCdfs: Array<{
      __typename?: 'DbBuyerToRentSpecificationCdf';
      cdf: { __typename?: 'DbCdf'; id: string; label: string };
    }>;
    customSearchDemandPolygons: Array<{
      __typename?: 'DbCustomSearchDemandPolygon';
      id: string;
      name?: string | null;
      polygon: any;
    }>;
  } | null;
  leadBundlingByLeadWithPropertyId?: {
    __typename?: 'DbLeadBundling';
    id: string;
    leadWithSearchDemand: { __typename?: 'DbLead'; id: string };
  } | null;
  leadBundlingsByLeadWithSearchDemandId: Array<{
    __typename?: 'DbLeadBundling';
    id: string;
    leadWithProperty: {
      __typename?: 'DbLead';
      id: string;
      contactId: string;
      teamId?: string | null;
      intentType: IntentTypeEnum;
      createdAt: any;
      expiredAt?: any | null;
      status: LeadStatusEnum;
      timeFrame?: TimeFrameEnum | null;
      agentId?: string | null;
      shopId: string;
      personalNote?: string | null;
      go3PropertyId?: string | null;
      peLinkedPropertyId?: string | null;
      sellerRentOutSpecification?: {
        __typename?: 'DbSellerRentOutSpecification';
        type?: AssetTypeEnum | null;
        livingArea?: any | null;
        priceRangeTo?: any | null;
        priceRangeFrom?: any | null;
        priceRangeUnit: CurrencyEnum;
        rentPriceUnit: CurrencyEnum;
        plotArea?: any | null;
        address?: {
          __typename?: 'DbAddress';
          city?: string | null;
          postalCode?: string | null;
          fullAddress?: string | null;
        } | null;
      } | null;
      buyerToRentSpecification?: {
        __typename?: 'DbBuyerToRentSpecification';
        searchDemandId?: string | null;
        type?: AssetTypeEnum | null;
        city?: string | null;
        livingAreaMax?: number | null;
        livingAreaMin?: number | null;
        totalSurfaceMax?: number | null;
        totalSurfaceMin?: number | null;
        plotSurfaceMax?: number | null;
        plotSurfaceMin?: number | null;
        priceMax?: number | null;
        priceMin?: number | null;
        currency: CurrencyEnum;
        area?: string | null;
        country?: string | null;
      } | null;
      emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
      leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
    };
  }>;
  emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
  lastActivity: Array<{
    __typename?: 'DbBaseActivity';
    type: ActivityTypeEnum;
    createdBy: string;
    createdAt: any;
    shopId?: string | null;
  }>;
};

export type LeadListEntry = {
  __typename?: 'DbLead';
  id: string;
  status: LeadStatusEnum;
  source: LeadSourceEnum;
  createdAt: any;
  priority?: PriorityEnum | null;
  statusReason?: LeadStatusReasonEnum | null;
  statusNote?: string | null;
  contactId: string;
  intentType: IntentTypeEnum;
  timeFrame?: TimeFrameEnum | null;
  go3PropertyId?: string | null;
  peLinkedPropertyId?: string | null;
  agentId?: string | null;
  shopId: string;
  teamId?: string | null;
  buyerToRentSpecification?: { __typename?: 'DbBuyerToRentSpecification'; searchDemandId?: string | null } | null;
  contact: {
    __typename?: 'DbContact';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    contactStatus: ContactStatusEnum;
    blocked: boolean;
    phoneNumbers: Array<{
      __typename?: 'DbContactPhoneNumber';
      preferred: boolean;
      number: string;
      internationalNumber?: string | null;
      type: PhoneNumberTypeEnum;
    }>;
    emailAddresses: Array<{ __typename?: 'DbContactEmailAddress'; address: string; preferred: boolean }>;
    preferredTimes: Array<{ __typename?: 'DbContactPreferredTime'; preferredContactTime: ContactTimeEnum }>;
  };
  agent?: {
    __typename: 'DbAgent';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    status: AgentStatusEnum;
    mobilePhoneNumber?: string | null;
    officePhoneNumber?: string | null;
    profiles: Array<{
      __typename?: 'DbAgentProfile';
      images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
    }>;
  } | null;
  shop: {
    __typename?: 'DbShop';
    id: string;
    shopGroup?: {
      __typename?: 'DbShopGroup';
      name: string;
      shops: Array<{ __typename?: 'DbShop'; id: string; status?: ShopStatusEnum | null }>;
    } | null;
  };
  emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
  lastActivity: Array<{
    __typename?: 'DbBaseActivity';
    type: ActivityTypeEnum;
    createdBy: string;
    createdAt: any;
    shopId?: string | null;
  }>;
};

export type LeadForContactList = {
  __typename?: 'DbLead';
  id: string;
  intentType: IntentTypeEnum;
  agentId?: string | null;
  agent?: { __typename?: 'DbAgent'; firstName: string; lastName: string } | null;
};

export type LeadForAddingProperty = {
  __typename?: 'DbLead';
  id: string;
  intentType: IntentTypeEnum;
  contact: { __typename?: 'DbContact'; go3ContactKey?: number | null };
  sellerRentOutSpecification?: {
    __typename?: 'DbSellerRentOutSpecification';
    type?: AssetTypeEnum | null;
    subType?: AssetSubTypeEnum | null;
    livingArea?: any | null;
    plotArea?: any | null;
    surfaceTotal?: any | null;
    numberOfRooms?: number | null;
    numberOfBathrooms?: number | null;
    address?: {
      __typename?: 'DbAddress';
      streetName?: string | null;
      streetNumber?: string | null;
      city?: string | null;
      postalCode?: string | null;
    } | null;
  } | null;
};

export type LeadExpiryByLead = {
  __typename?: 'DbLeadExpiry';
  expirationDate?: any | null;
  id?: string | null;
  status?: string | null;
};

export type ImportLeadRequest = {
  __typename?: 'DbImportLeadRequest';
  id: string;
  createdAt: any;
  payloadType: string;
  requestPayload: string;
  importLeadStatus: ImportLeadStatusEnum;
  errorMessage?: string | null;
  stackTrace?: string | null;
  importedLeadId?: string | null;
};

export type LeadLastActivity = {
  __typename?: 'DbLead';
  lastActivity: Array<{
    __typename?: 'DbBaseActivity';
    type: ActivityTypeEnum;
    createdBy: string;
    createdAt: any;
    shopId?: string | null;
  }>;
};

export type LegacyGo3ChangeActivity = {
  __typename?: 'DbLegacyGo3ChangeLog';
  id: string;
  oldValue?: string | null;
  newValue?: string | null;
  fieldName?: string | null;
};

export type LicensePartner = {
  __typename?: 'DbLicensePartner';
  name: string;
  id: string;
  isCompanyOwnedOperation?: boolean | null;
  status?: LicensePartnerStatusEnum | null;
  debtorNumber?: string | null;
  primaryShopId?: string | null;
  shops: Array<{ __typename?: 'DbShop'; id: string; status?: ShopStatusEnum | null }>;
  profiles: Array<{
    __typename?: 'DbLicensePartnerProfile';
    licensePartnerProfileImages: Array<{ __typename?: 'DbLicensePartnerProfileImage'; gcpFileName: string }>;
  }>;
};

export type LipaProfile = {
  __typename?: 'DbLicensePartnerProfile';
  id: string;
  licensePartnerId: string;
  emailRecruiting?: string | null;
  emailOther?: string | null;
  facebookUrl?: string | null;
  instagramUrl?: string | null;
  twitterUrl?: string | null;
  xingUrl?: string | null;
  linkedInUrl?: string | null;
  youtubeUrl?: string | null;
  tiktokUrl?: string | null;
  googleAdsId?: string | null;
  googlePlaceId?: string | null;
  googleAnalyticsAccountId?: string | null;
  microsoftId?: string | null;
  facebookId?: string | null;
  criteoId?: string | null;
  linkedinId?: string | null;
  imprintUrl?: string | null;
  privacyPolicyUrl?: string | null;
  clientId?: string | null;
  optin?: string | null;
  recipientId?: string | null;
  activated?: boolean | null;
  featuredAgents: Array<{
    __typename?: 'DbLicensePartnerProfileFeaturedAgent';
    agentId: string;
    index: number;
    agent: {
      __typename: 'DbAgent';
      createdAt: any;
      primaryShopId?: string | null;
      userName: string;
      salutation?: SalutationEnum | null;
      betaTester: boolean;
      energyAdvisoryEnabled: boolean;
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      userTeamAssignments: Array<{
        __typename?: 'DbUserTeamAssignment';
        role: UserRoleEnum;
        team: {
          __typename?: 'DbTeam';
          normalizedName?: string | null;
          description?: string | null;
          status?: TeamStatusEnum | null;
          id: string;
          name: string;
          shopId: string;
          email?: string | null;
          shop: {
            __typename?: 'DbShop';
            id: string;
            shopName: string;
            city?: string | null;
            countryCode: CountryEnum;
            postalCode?: string | null;
            streetName?: string | null;
            phone?: string | null;
            email?: string | null;
            status?: ShopStatusEnum | null;
            streetNumber?: string | null;
            onboardingLevel?: number | null;
            groupId?: string | null;
            latitude?: any | null;
            longitude?: any | null;
            shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
            licensePartner: {
              __typename?: 'DbLicensePartner';
              name: string;
              isCompanyOwnedOperation?: boolean | null;
              id: string;
            };
            profiles: Array<{
              __typename?: 'DbShopProfile';
              images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
            }>;
          };
          teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
        };
      }>;
      userShopAssignments: Array<{
        __typename?: 'DbUserShopAssignment';
        role: UserRoleEnum;
        shop: { __typename?: 'DbShop'; shopName: string; id: string };
      }>;
      nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    };
  }>;
  propertyPreviews: Array<{
    __typename?: 'DbLicensePartnerProfilePropertyPreview';
    utag: string;
    type: MarketingTypeEnum;
    index: number;
  }>;
  heroPhoto: Array<{ __typename?: 'DbLicensePartnerProfileImage'; id: string; title: string; gcpFileName: string }>;
  featuredContentPhotos: Array<{
    __typename?: 'DbLicensePartnerProfileImage';
    id: string;
    title: string;
    gcpFileName: string;
  }>;
  licensePartnerProfileLanguageVariants: Array<{
    __typename?: 'DbLicensePartnerProfileLanguageVariant';
    displayName?: string | null;
    slugOverride?: string | null;
    lang: LanguageEnum;
    heroDesc?: string | null;
    heroHeadline?: string | null;
    heroIsAiTranslated?: boolean | null;
    url?: string | null;
    imprint?: string | null;
    imprintIsAiTranslated?: boolean | null;
    privacyPolicy?: string | null;
    privacyPolicyIsAiTranslated?: boolean | null;
    introHeadline?: string | null;
    introDescription?: string | null;
    introIsAiTranslated?: boolean | null;
    shopOverviewHeadline?: string | null;
    shopOverviewDescription?: string | null;
    shopOverviewIsAiTranslated?: boolean | null;
    careerDesc?: string | null;
    careerHeadline?: string | null;
    careerLink?: string | null;
    careerIsAiTranslated?: boolean | null;
    featuredContentDescription?: string | null;
    featuredContentHeadline?: string | null;
    featuredContentIsAiTranslated?: boolean | null;
    featuredContentTeasers: Array<{
      __typename?: 'DbLicensePartnerProfileFeaturedContentTeaser';
      headline?: string | null;
      description?: string | null;
      link?: string | null;
    }>;
  }>;
  careerImages: Array<{ __typename?: 'DbLicensePartnerProfileImage'; id: string; title: string; gcpFileName: string }>;
};

export type LipaProfileImage = {
  __typename?: 'DbLicensePartnerProfileImage';
  id: string;
  title: string;
  gcpFileName: string;
};

export type LipaProfileFeaturedAgent = {
  __typename?: 'DbLicensePartnerProfileFeaturedAgent';
  agentId: string;
  index: number;
  agent: {
    __typename: 'DbAgent';
    createdAt: any;
    primaryShopId?: string | null;
    userName: string;
    salutation?: SalutationEnum | null;
    betaTester: boolean;
    energyAdvisoryEnabled: boolean;
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    status: AgentStatusEnum;
    mobilePhoneNumber?: string | null;
    officePhoneNumber?: string | null;
    userTeamAssignments: Array<{
      __typename?: 'DbUserTeamAssignment';
      role: UserRoleEnum;
      team: {
        __typename?: 'DbTeam';
        normalizedName?: string | null;
        description?: string | null;
        status?: TeamStatusEnum | null;
        id: string;
        name: string;
        shopId: string;
        email?: string | null;
        shop: {
          __typename?: 'DbShop';
          id: string;
          shopName: string;
          city?: string | null;
          countryCode: CountryEnum;
          postalCode?: string | null;
          streetName?: string | null;
          phone?: string | null;
          email?: string | null;
          status?: ShopStatusEnum | null;
          streetNumber?: string | null;
          onboardingLevel?: number | null;
          groupId?: string | null;
          latitude?: any | null;
          longitude?: any | null;
          shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
          licensePartner: {
            __typename?: 'DbLicensePartner';
            name: string;
            isCompanyOwnedOperation?: boolean | null;
            id: string;
          };
          profiles: Array<{
            __typename?: 'DbShopProfile';
            images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
          }>;
        };
        teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
      };
    }>;
    userShopAssignments: Array<{
      __typename?: 'DbUserShopAssignment';
      role: UserRoleEnum;
      shop: { __typename?: 'DbShop'; shopName: string; id: string };
    }>;
    nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
    profiles: Array<{
      __typename?: 'DbAgentProfile';
      images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
    }>;
  };
};

export type Listing = {
  __typename?: 'PropertyEngineListing';
  utag: string;
  type: PropertyEngineListingType;
  status: PropertyEngineListingStatus;
  cdfs?: Array<string | null> | null;
  assignedAgentId?: string | null;
  assignedTeamId?: string | null;
  currency?: string | null;
  district?: string | null;
  area?: string | null;
  fileAs?: string | null;
  monthlyNetRent?: number | null;
  monthlyTotalRent?: number | null;
  monthlyUtilities?: number | null;
  askingPrice?: number | null;
  lastActivation?: any | null;
  listingOwnerContactId?: string | null;
  asset:
    | {
        __typename?: 'PropertyEngineApartment';
        type?: PropertyEngineAssetType | null;
        subType?: PropertyEngineAssetSubType | null;
        variation?: PropertyEngineAssetVariation | null;
        numberOfRooms?: number | null;
        numberOfBedrooms?: number | null;
        numberOfBathrooms?: number | null;
        totalSurface?: number | null;
        surfaceLivingArea?: number | null;
      }
    | {
        __typename?: 'PropertyEngineHouse';
        type?: PropertyEngineAssetType | null;
        subType?: PropertyEngineAssetSubType | null;
        variation?: PropertyEngineAssetVariation | null;
        numberOfRooms?: number | null;
        numberOfBedrooms?: number | null;
        numberOfBathrooms?: number | null;
        totalSurface?: number | null;
        surfaceLivingArea?: number | null;
      }
    | {
        __typename?: 'PropertyEngineLand';
        type?: PropertyEngineAssetType | null;
        subType?: PropertyEngineAssetSubType | null;
        variation?: PropertyEngineAssetVariation | null;
        plotSurface?: number | null;
      };
  address?: {
    __typename?: 'PropertyEngineAddress';
    city?: string | null;
    postalCode?: string | null;
    coverLetterAddress?: string | null;
    streetName?: string | null;
    streetNumber?: string | null;
    countryCode?: PropertyEngineCountryCode | null;
    latitude?: number | null;
    longitude?: number | null;
  } | null;
  previewImage?: { __typename?: 'PropertyEngineProxyImageDetail'; imageUrl: string } | null;
  marketingDetails: Array<{
    __typename?: 'PropertyEngineMarketingDetail';
    language: PropertyEngineLanguage;
    title: string;
    premiumExposeEmail?: {
      __typename?: 'PropertyEnginePremiumExposeEmail';
      subject?: string | null;
      description?: string | null;
      bulletPoints?: Array<string | null> | null;
      bulletPointsTitle?: string | null;
    } | null;
  }>;
  internetProfile: {
    __typename?: 'PropertyEngineInternetProfile';
    priceOnRequest?: boolean | null;
    portals?: Array<{ __typename?: 'PropertyEngineListingInternetPortal'; includeInExport?: boolean | null }> | null;
  };
};

export type MatchingStatus = {
  __typename?: 'PropertyEngineMatchingStatusDetail';
  contactId: string;
  searchDemandId?: string | null;
  matchingState: PropertyEngineMatchingState;
  manuallyProposed?: boolean | null;
  feedback?: string | null;
  createdAt: string;
  buyerToRentSpecification?: {
    __typename?: 'DbBuyerToRentSpecification';
    area?: string | null;
    bedroomsMax?: number | null;
    bedroomsMin?: number | null;
    city?: string | null;
    country?: string | null;
    currency: CurrencyEnum;
    district?: string | null;
    id: string;
    legacyAreaNames: Array<string>;
    legacyDistrictNames: Array<string>;
    livingAreaMax?: number | null;
    livingAreaMin?: number | null;
    monthlyNetRentMax?: number | null;
    monthlyNetRentMin?: number | null;
    monthlyTotalRentMax?: number | null;
    monthlyTotalRentMin?: number | null;
    plotSurfaceMax?: number | null;
    plotSurfaceMin?: number | null;
    priceMax?: number | null;
    priceMin?: number | null;
    roomsMax?: number | null;
    roomsMin?: number | null;
    subTypes?: Array<string> | null;
    variations?: Array<string> | null;
    totalSurfaceMax?: number | null;
    totalSurfaceMin?: number | null;
    type?: AssetTypeEnum | null;
    placeIds: Array<string>;
    searchDemandId?: string | null;
    lead?: { __typename?: 'DbLead'; id: string } | null;
    buyerToRentSpecificationCdfs: Array<{
      __typename?: 'DbBuyerToRentSpecificationCdf';
      cdf: { __typename?: 'DbCdf'; id: string; label: string };
    }>;
    customSearchDemandPolygons: Array<{
      __typename?: 'DbCustomSearchDemandPolygon';
      id: string;
      name?: string | null;
      polygon: any;
    }>;
  } | null;
};

export type ProposedListing = {
  __typename?: 'PropertyEngineProposedListing';
  matchingState: PropertyEngineMatchingState;
  manuallyProposed: boolean;
  feedback?: string | null;
  createdAt: string;
  listing: {
    __typename?: 'PropertyEngineListing';
    utag: string;
    type: PropertyEngineListingType;
    status: PropertyEngineListingStatus;
    cdfs?: Array<string | null> | null;
    assignedAgentId?: string | null;
    assignedTeamId?: string | null;
    currency?: string | null;
    district?: string | null;
    area?: string | null;
    fileAs?: string | null;
    monthlyNetRent?: number | null;
    monthlyTotalRent?: number | null;
    monthlyUtilities?: number | null;
    askingPrice?: number | null;
    lastActivation?: any | null;
    listingOwnerContactId?: string | null;
    asset:
      | {
          __typename?: 'PropertyEngineApartment';
          type?: PropertyEngineAssetType | null;
          subType?: PropertyEngineAssetSubType | null;
          variation?: PropertyEngineAssetVariation | null;
          numberOfRooms?: number | null;
          numberOfBedrooms?: number | null;
          numberOfBathrooms?: number | null;
          totalSurface?: number | null;
          surfaceLivingArea?: number | null;
        }
      | {
          __typename?: 'PropertyEngineHouse';
          type?: PropertyEngineAssetType | null;
          subType?: PropertyEngineAssetSubType | null;
          variation?: PropertyEngineAssetVariation | null;
          numberOfRooms?: number | null;
          numberOfBedrooms?: number | null;
          numberOfBathrooms?: number | null;
          totalSurface?: number | null;
          surfaceLivingArea?: number | null;
        }
      | {
          __typename?: 'PropertyEngineLand';
          type?: PropertyEngineAssetType | null;
          subType?: PropertyEngineAssetSubType | null;
          variation?: PropertyEngineAssetVariation | null;
          plotSurface?: number | null;
        };
    address?: {
      __typename?: 'PropertyEngineAddress';
      city?: string | null;
      postalCode?: string | null;
      coverLetterAddress?: string | null;
      streetName?: string | null;
      streetNumber?: string | null;
      countryCode?: PropertyEngineCountryCode | null;
      latitude?: number | null;
      longitude?: number | null;
    } | null;
    previewImage?: { __typename?: 'PropertyEngineProxyImageDetail'; imageUrl: string } | null;
    marketingDetails: Array<{
      __typename?: 'PropertyEngineMarketingDetail';
      language: PropertyEngineLanguage;
      title: string;
      premiumExposeEmail?: {
        __typename?: 'PropertyEnginePremiumExposeEmail';
        subject?: string | null;
        description?: string | null;
        bulletPoints?: Array<string | null> | null;
        bulletPointsTitle?: string | null;
      } | null;
    }>;
    internetProfile: {
      __typename?: 'PropertyEngineInternetProfile';
      priceOnRequest?: boolean | null;
      portals?: Array<{ __typename?: 'PropertyEngineListingInternetPortal'; includeInExport?: boolean | null }> | null;
    };
  };
};

export type Referral = {
  __typename?: 'DbReferral';
  id: string;
  go3ReferralId?: number | null;
  referralNotPossibleReason?: ReferralNotPossibleReasonEnum | null;
  referralAgentNote?: string | null;
  receivingShop?: {
    __typename?: 'DbShop';
    id: string;
    shopName: string;
    city?: string | null;
    countryCode: CountryEnum;
    postalCode?: string | null;
    streetName?: string | null;
    phone?: string | null;
    email?: string | null;
    status?: ShopStatusEnum | null;
    streetNumber?: string | null;
    onboardingLevel?: number | null;
    groupId?: string | null;
    latitude?: any | null;
    longitude?: any | null;
    shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
    licensePartner: {
      __typename?: 'DbLicensePartner';
      name: string;
      isCompanyOwnedOperation?: boolean | null;
      id: string;
    };
    profiles: Array<{
      __typename?: 'DbShopProfile';
      images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
    }>;
  } | null;
};

export type ReferralDetailsFragment = {
  __typename?: 'DbReferral';
  id: string;
  createdAt: any;
  acceptedRefusedDate?: any | null;
  referralStatus: ReferralStatusEnum;
  commission: any;
  note?: string | null;
  sendingContactId?: string | null;
  leadId?: string | null;
  receivingLeadId?: string | null;
  receivingGo3ContactUtag?: string | null;
  type: ReferralTypeEnum;
  referralAgentNote?: string | null;
  referralNotPossibleReason?: ReferralNotPossibleReasonEnum | null;
  sendingShopId: string;
  receivingShopId?: string | null;
  receivingTeamId?: string | null;
  sendingTeamId?: string | null;
  snapshotId: string;
  leadByReceivingLeadId?: {
    __typename?: 'DbLead';
    contact: {
      __typename?: 'DbContact';
      id: string;
      blocked: boolean;
      emailAddresses: Array<{ __typename?: 'DbContactEmailAddress'; address: string; preferred: boolean }>;
      phoneNumbers: Array<{ __typename?: 'DbContactPhoneNumber'; number: string; preferred: boolean }>;
    };
    agent?: {
      __typename: 'DbAgent';
      createdAt: any;
      primaryShopId?: string | null;
      userName: string;
      salutation?: SalutationEnum | null;
      betaTester: boolean;
      energyAdvisoryEnabled: boolean;
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
    team?: {
      __typename?: 'DbTeam';
      normalizedName?: string | null;
      description?: string | null;
      status?: TeamStatusEnum | null;
      id: string;
      name: string;
      shopId: string;
      email?: string | null;
      teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
    } | null;
    baseActivities: Array<{
      __typename?: 'DbBaseActivity';
      callActivity?: {
        __typename?: 'DbCallActivity';
        call?: {
          __typename?: 'DbCall';
          id: string;
          callSid: string;
          createdAt: any;
          duration?: any | null;
          outcome?: CallOutcomeEnum | null;
          note?: string | null;
          status: CallStatusEnum;
          shopId: string;
          recipient: string;
          caller: string;
          callDirection: { __typename?: 'EnumsCallDirection'; name: string };
          agent?: {
            __typename: 'DbAgent';
            createdAt: any;
            primaryShopId?: string | null;
            userName: string;
            salutation?: SalutationEnum | null;
            betaTester: boolean;
            energyAdvisoryEnabled: boolean;
            id: string;
            firstName: string;
            lastName: string;
            email: string;
            status: AgentStatusEnum;
            mobilePhoneNumber?: string | null;
            officePhoneNumber?: string | null;
            nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
            profiles: Array<{
              __typename?: 'DbAgentProfile';
              images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
            }>;
          } | null;
        } | null;
      } | null;
      emailActivity?: {
        __typename?: 'DbEmailActivity';
        id: string;
        email: {
          __typename?: 'DbEmail';
          id: string;
          status?: EmailStatusEnum | null;
          from: string;
          to: Array<string>;
          cc?: Array<string> | null;
          bcc?: Array<string> | null;
          subject: string;
          createdAt: any;
          direction?: EmailDirectionEnum | null;
          body: string;
          propertyIds: Array<string>;
          trackings: Array<{
            __typename?: 'DbEmailTracking';
            id: string;
            count: number;
            type: EmailTrackingTypeEnum;
            recipient?: string | null;
            updatedAt: any;
            linkUrl?: string | null;
          }>;
          attachments: Array<{
            __typename?: 'DbEmailAttachment';
            document: { __typename?: 'DbDocument'; id: string; contentType: string; name: string };
          }>;
        };
      } | null;
      appointmentActivity?: {
        __typename?: 'DbAppointmentActivity';
        createdAt: any;
        appointment: {
          __typename?: 'DbAppointment';
          id: string;
          type: AppointmentTypeEnum;
          startTime: any;
          endTime?: any | null;
          location?: string | null;
          subject?: string | null;
          status?: TaskAppointmentStatusEnum | null;
          agentId: string;
          teamId?: string | null;
          comment?: string | null;
          feedback?: string | null;
          cost?: any | null;
          propertyIds: Array<string>;
          go3AppointmentKey?: number | null;
          agent: { __typename?: 'DbAgent'; firstName: string; lastName: string };
          appointmentContacts: Array<{ __typename?: 'DbAppointmentContact'; contactId: string }>;
          appointmentLeads: Array<{ __typename?: 'DbAppointmentLead'; leadId: string }>;
          appointmentAgents: Array<{
            __typename?: 'DbAppointmentAgent';
            agent: {
              __typename: 'DbAgent';
              id: string;
              firstName: string;
              lastName: string;
              email: string;
              status: AgentStatusEnum;
              mobilePhoneNumber?: string | null;
              officePhoneNumber?: string | null;
              profiles: Array<{
                __typename?: 'DbAgentProfile';
                images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
              }>;
            };
          }>;
        };
      } | null;
    }>;
  } | null;
  agent: {
    __typename: 'DbAgent';
    createdAt: any;
    primaryShopId?: string | null;
    userName: string;
    salutation?: SalutationEnum | null;
    betaTester: boolean;
    energyAdvisoryEnabled: boolean;
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    status: AgentStatusEnum;
    mobilePhoneNumber?: string | null;
    officePhoneNumber?: string | null;
    nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
    profiles: Array<{
      __typename?: 'DbAgentProfile';
      images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
    }>;
  };
  agentByReceivingAgentId?: {
    __typename: 'DbAgent';
    createdAt: any;
    primaryShopId?: string | null;
    userName: string;
    salutation?: SalutationEnum | null;
    betaTester: boolean;
    energyAdvisoryEnabled: boolean;
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    status: AgentStatusEnum;
    mobilePhoneNumber?: string | null;
    officePhoneNumber?: string | null;
    nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
    profiles: Array<{
      __typename?: 'DbAgentProfile';
      images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
    }>;
  } | null;
  contactByReceivingLeadIdWrapper?: {
    __typename?: 'DbOneWayMappingFromLeadIdToContactId';
    contact?: { __typename?: 'DbContact'; id: string } | null;
  } | null;
  receivingShop?: {
    __typename?: 'DbShop';
    id: string;
    shopName: string;
    city?: string | null;
    countryCode: CountryEnum;
    postalCode?: string | null;
    streetName?: string | null;
    phone?: string | null;
    email?: string | null;
    status?: ShopStatusEnum | null;
    streetNumber?: string | null;
    onboardingLevel?: number | null;
    groupId?: string | null;
    latitude?: any | null;
    longitude?: any | null;
    shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
    licensePartner: {
      __typename?: 'DbLicensePartner';
      name: string;
      isCompanyOwnedOperation?: boolean | null;
      id: string;
    };
    profiles: Array<{
      __typename?: 'DbShopProfile';
      images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
    }>;
  } | null;
  sendingShop?: {
    __typename?: 'DbShop';
    id: string;
    shopName: string;
    city?: string | null;
    countryCode: CountryEnum;
    postalCode?: string | null;
    streetName?: string | null;
    phone?: string | null;
    email?: string | null;
    status?: ShopStatusEnum | null;
    streetNumber?: string | null;
    onboardingLevel?: number | null;
    groupId?: string | null;
    latitude?: any | null;
    longitude?: any | null;
    shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
    licensePartner: {
      __typename?: 'DbLicensePartner';
      name: string;
      isCompanyOwnedOperation?: boolean | null;
      id: string;
    };
    profiles: Array<{
      __typename?: 'DbShopProfile';
      images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
    }>;
  } | null;
  snapshotLead: {
    __typename?: 'DbSnapshotLead';
    contactFirstName?: string | null;
    contactLastName?: string | null;
    intentType: IntentTypeEnum;
  };
  contact?: { __typename?: 'DbContact'; id: string } | null;
  teamByReceivingTeamId?: { __typename?: 'DbTeam'; id: string; name: string; shopId: string } | null;
  teamBySendingTeamId?: { __typename?: 'DbTeam'; id: string; name: string; shopId: string } | null;
};

export type SellerRentOutSpecification = {
  __typename?: 'DbSellerRentOutSpecification';
  constructionYear?: number | null;
  condition?: AssetConditionEnum | null;
  priceRangeTo?: any | null;
  priceRangeFrom?: any | null;
  rentRangeFrom?: any | null;
  rentRangeTo?: any | null;
  priceRangeUnit: CurrencyEnum;
  rentPriceUnit: CurrencyEnum;
  occupationStatus?: PropertyOccupationStatusEnum | null;
  livingArea?: any | null;
  numberOfBathrooms?: number | null;
  numberOfBedrooms?: number | null;
  floor?: number | null;
  numberOfMasterBathrooms?: number | null;
  numberOfRooms?: number | null;
  totalFloors?: number | null;
  surfaceTotal?: any | null;
  plotArea?: any | null;
  surfaceUsable?: any | null;
  balcony?: boolean | null;
  garden?: boolean | null;
  parking?: boolean | null;
  swimmingPool?: boolean | null;
  elevator?: boolean | null;
  garage?: boolean | null;
  guestToilet?: boolean | null;
  type?: AssetTypeEnum | null;
  subType?: AssetSubTypeEnum | null;
  variation?: AssetVariationEnum | null;
  id: string;
  ownershipStatus?: PropertyOwnershipStatusEnum | null;
  ownerDescription?: string | null;
  owner?: {
    __typename?: 'DbContact';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    fullName?: string | null;
  } | null;
  address?: {
    __typename?: 'DbAddress';
    id: string;
    city?: string | null;
    countryCode: CountryEnum;
    postalCode?: string | null;
    secondAddressLine?: string | null;
    streetName?: string | null;
    streetNumber?: string | null;
    fullAddress?: string | null;
    placeId?: string | null;
    country: { __typename?: 'EnumsCountry'; description?: string | null; name: string };
  } | null;
  sellerRentOutSpecificationCdfs: Array<{
    __typename?: 'DbSellerRentOutSpecificationCdf';
    cdf: { __typename?: 'DbCdf'; id: string; label: string };
  }>;
};

export type ShopInfo = {
  __typename?: 'DbShop';
  id: string;
  shopName: string;
  city?: string | null;
  countryCode: CountryEnum;
  postalCode?: string | null;
  streetName?: string | null;
  phone?: string | null;
  email?: string | null;
  status?: ShopStatusEnum | null;
  streetNumber?: string | null;
  onboardingLevel?: number | null;
  groupId?: string | null;
  latitude?: any | null;
  longitude?: any | null;
  shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
  licensePartner: {
    __typename?: 'DbLicensePartner';
    name: string;
    isCompanyOwnedOperation?: boolean | null;
    id: string;
  };
  profiles: Array<{
    __typename?: 'DbShopProfile';
    images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
  }>;
};

export type Shop = {
  __typename?: 'DbShop';
  currency: CurrencyEnum;
  website?: string | null;
  energyAdvisoryEnabled: boolean;
  businessUnit?: string | null;
  id: string;
  shopName: string;
  city?: string | null;
  countryCode: CountryEnum;
  postalCode?: string | null;
  streetName?: string | null;
  phone?: string | null;
  email?: string | null;
  status?: ShopStatusEnum | null;
  streetNumber?: string | null;
  onboardingLevel?: number | null;
  groupId?: string | null;
  latitude?: any | null;
  longitude?: any | null;
  twilioConfigurations: Array<{ __typename?: 'DbTwilioConfiguration'; id: string; twilioNumber: string }>;
  shopGroup?: {
    __typename?: 'DbShopGroup';
    name: string;
    shops: Array<{ __typename?: 'DbShop'; id: string; status?: ShopStatusEnum | null }>;
  } | null;
  licensePartner: {
    __typename?: 'DbLicensePartner';
    name: string;
    isCompanyOwnedOperation?: boolean | null;
    id: string;
    shops: Array<{ __typename?: 'DbShop'; id: string }>;
  };
  shopStatus?: { __typename?: 'EnumsShopStatus'; description?: string | null } | null;
  teams: Array<{
    __typename?: 'DbTeam';
    normalizedName?: string | null;
    description?: string | null;
    status?: TeamStatusEnum | null;
    id: string;
    name: string;
    shopId: string;
    email?: string | null;
    teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
  }>;
  profiles: Array<{
    __typename?: 'DbShopProfile';
    images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
  }>;
};

export type ShopWithMembers = {
  __typename?: 'DbShop';
  currency: CurrencyEnum;
  website?: string | null;
  energyAdvisoryEnabled: boolean;
  businessUnit?: string | null;
  id: string;
  shopName: string;
  city?: string | null;
  countryCode: CountryEnum;
  postalCode?: string | null;
  streetName?: string | null;
  phone?: string | null;
  email?: string | null;
  status?: ShopStatusEnum | null;
  streetNumber?: string | null;
  onboardingLevel?: number | null;
  groupId?: string | null;
  latitude?: any | null;
  longitude?: any | null;
  teams: Array<{
    __typename?: 'DbTeam';
    normalizedName?: string | null;
    description?: string | null;
    status?: TeamStatusEnum | null;
    id: string;
    name: string;
    shopId: string;
    email?: string | null;
    userTeamAssignments: Array<{
      __typename?: 'DbUserTeamAssignment';
      role: UserRoleEnum;
      agent: {
        __typename: 'DbAgent';
        createdAt: any;
        primaryShopId?: string | null;
        userName: string;
        salutation?: SalutationEnum | null;
        betaTester: boolean;
        energyAdvisoryEnabled: boolean;
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      };
    }>;
    teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
  }>;
  userShopAssignments: Array<{
    __typename?: 'DbUserShopAssignment';
    role: UserRoleEnum;
    agent: {
      __typename: 'DbAgent';
      createdAt: any;
      primaryShopId?: string | null;
      userName: string;
      salutation?: SalutationEnum | null;
      betaTester: boolean;
      energyAdvisoryEnabled: boolean;
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    };
  }>;
  twilioConfigurations: Array<{ __typename?: 'DbTwilioConfiguration'; id: string; twilioNumber: string }>;
  shopGroup?: {
    __typename?: 'DbShopGroup';
    name: string;
    shops: Array<{ __typename?: 'DbShop'; id: string; status?: ShopStatusEnum | null }>;
  } | null;
  licensePartner: {
    __typename?: 'DbLicensePartner';
    name: string;
    isCompanyOwnedOperation?: boolean | null;
    id: string;
    shops: Array<{ __typename?: 'DbShop'; id: string }>;
  };
  shopStatus?: { __typename?: 'EnumsShopStatus'; description?: string | null } | null;
  profiles: Array<{
    __typename?: 'DbShopProfile';
    images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
  }>;
};

export type ShopNameById = { __typename?: 'DbShop'; countryCode: CountryEnum; shopName: string; id: string };

export type ShopGroup = {
  __typename?: 'DbShopGroup';
  id: string;
  name: string;
  description?: string | null;
  shops: Array<{
    __typename?: 'DbShop';
    id: string;
    shopName: string;
    city?: string | null;
    countryCode: CountryEnum;
    postalCode?: string | null;
    streetName?: string | null;
    phone?: string | null;
    email?: string | null;
    status?: ShopStatusEnum | null;
    streetNumber?: string | null;
    onboardingLevel?: number | null;
    groupId?: string | null;
    latitude?: any | null;
    longitude?: any | null;
    shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
    licensePartner: {
      __typename?: 'DbLicensePartner';
      name: string;
      isCompanyOwnedOperation?: boolean | null;
      id: string;
    };
    profiles: Array<{
      __typename?: 'DbShopProfile';
      images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
    }>;
  }>;
};

export type ShopProfileImage = { __typename?: 'DbShopProfileImage'; id: string; title: string; gcpFileName: string };

export type ShopProfile = {
  __typename?: 'DbShopProfile';
  id: string;
  published?: string | null;
  activated?: boolean | null;
  clientId?: string | null;
  optin?: string | null;
  recipientId?: string | null;
  placeId?: string | null;
  shopProfileFeaturedAgents: Array<{
    __typename?: 'DbShopProfileFeaturedAgent';
    agentId: string;
    index: number;
    agent: {
      __typename: 'DbAgent';
      createdAt: any;
      primaryShopId?: string | null;
      userName: string;
      salutation?: SalutationEnum | null;
      betaTester: boolean;
      energyAdvisoryEnabled: boolean;
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      userTeamAssignments: Array<{
        __typename?: 'DbUserTeamAssignment';
        role: UserRoleEnum;
        team: {
          __typename?: 'DbTeam';
          normalizedName?: string | null;
          description?: string | null;
          status?: TeamStatusEnum | null;
          id: string;
          name: string;
          shopId: string;
          email?: string | null;
          shop: {
            __typename?: 'DbShop';
            id: string;
            shopName: string;
            city?: string | null;
            countryCode: CountryEnum;
            postalCode?: string | null;
            streetName?: string | null;
            phone?: string | null;
            email?: string | null;
            status?: ShopStatusEnum | null;
            streetNumber?: string | null;
            onboardingLevel?: number | null;
            groupId?: string | null;
            latitude?: any | null;
            longitude?: any | null;
            shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
            licensePartner: {
              __typename?: 'DbLicensePartner';
              name: string;
              isCompanyOwnedOperation?: boolean | null;
              id: string;
            };
            profiles: Array<{
              __typename?: 'DbShopProfile';
              images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
            }>;
          };
          teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
        };
      }>;
      userShopAssignments: Array<{
        __typename?: 'DbUserShopAssignment';
        role: UserRoleEnum;
        shop: { __typename?: 'DbShop'; shopName: string; id: string };
      }>;
      nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    };
  }>;
  descriptions: Array<{
    __typename?: 'DbShopProfileDescription';
    displayName?: string | null;
    slugOverride?: string | null;
    text?: string | null;
    lang: LanguageEnum;
    url?: string | null;
    isAiTranslated?: boolean | null;
  }>;
  cityDistricts: Array<{
    __typename?: 'DbShopProfileCityDistrict';
    city: string;
    district?: string | null;
    language: LanguageEnum;
  }>;
  expertises: Array<{ __typename?: 'DbShopProfileExpertise'; expertise: ShopExpertiseEnum }>;
  spokenLanguages: Array<{ __typename?: 'DbShopProfileSpokenLanguage'; spokenLanguage: SpokenLanguageEnum }>;
  propertyPreviews: Array<{
    __typename?: 'DbShopProfilePropertyPreview';
    utag: string;
    type: MarketingTypeEnum;
    index: number;
  }>;
  shopProfileFeaturedContents: Array<{
    __typename?: 'DbShopProfileFeaturedContent';
    headline?: string | null;
    description?: string | null;
    isAiTranslated?: boolean | null;
    lang: LanguageEnum;
    shopProfileFeaturedContentsTeasers: Array<{
      __typename?: 'DbShopProfileFeaturedContentTeaser';
      headline?: string | null;
      description?: string | null;
      link?: string | null;
    }>;
  }>;
  shopProfileFeaturedContentPhotos: Array<{
    __typename?: 'DbShopProfileImage';
    id: string;
    title: string;
    gcpFileName: string;
  }>;
  quotes: Array<{
    __typename?: 'DbShopProfileQuote';
    quote: string;
    authorName: string;
    authorTitle?: string | null;
    lang: LanguageEnum;
    isAiTranslated?: boolean | null;
  }>;
  teams: Array<{
    __typename?: 'DbShopProfileTeam';
    headline?: string | null;
    description?: string | null;
    lang: LanguageEnum;
    isAiTranslated: boolean;
  }>;
  teamPhoto: Array<{ __typename?: 'DbShopProfileImage'; id: string; title: string; gcpFileName: string }>;
  images: Array<{ __typename?: 'DbShopProfileImage'; id: string; title: string; gcpFileName: string }>;
  managerPhoto: Array<{ __typename?: 'DbShopProfileImage'; id: string; title: string; gcpFileName: string }>;
  seoTrackings: Array<{
    __typename?: 'DbShopProfileSeoTracking';
    googleAdsId?: string | null;
    googlePlaceId?: string | null;
    googleAnalyticsAccountId?: string | null;
    microsoftId?: string | null;
    facebookId?: string | null;
    criteoId?: string | null;
    linkedinId?: string | null;
    imprintUrl?: string | null;
    privacyPolicyUrl?: string | null;
  }>;
  imprints: Array<{
    __typename?: 'DbShopProfileImprint';
    isAiTranslated?: boolean | null;
    imprint: string;
    lang: LanguageEnum;
  }>;
  careers: Array<{
    __typename?: 'DbShopProfileCareer';
    description?: string | null;
    link?: string | null;
    headline?: string | null;
    lang: LanguageEnum;
    isAiTranslated?: boolean | null;
  }>;
  privacyPolicies: Array<{
    __typename?: 'DbShopProfilePrivacyPolicy';
    isAiTranslated?: boolean | null;
    privacyPolicy: string;
    lang: LanguageEnum;
  }>;
  termsAndConditions: Array<{
    __typename?: 'DbShopProfileTermsAndConditions';
    termsAndConditions: string;
    lang: LanguageEnum;
  }>;
  withdrawalPolicies: Array<{
    __typename?: 'DbShopProfileWithdrawalPolicy';
    withdrawalPolicy: string;
    lang: LanguageEnum;
  }>;
  customerMails: Array<{ __typename?: 'DbShopProfileCustomerMails'; footer: string; lang: LanguageEnum }>;
  socialMediaProfiles?: {
    __typename?: 'DbShopProfileSocialMediaProfiles';
    facebookUrl?: string | null;
    instagramUrl?: string | null;
    twitterUrl?: string | null;
    xingUrl?: string | null;
    linkedInUrl?: string | null;
    youtubeUrl?: string | null;
    tiktokUrl?: string | null;
  } | null;
  propertyLounge?: {
    __typename?: 'DbPropertyLounge';
    id: string;
    city?: string | null;
    countryCode?: CountryEnum | null;
    streetName?: string | null;
    streetNumber?: string | null;
    postalCode?: string | null;
    name: string;
    phone?: string | null;
    email?: string | null;
    status?: string | null;
  } | null;
};

export type PropertyLounge = {
  __typename?: 'DbPropertyLounge';
  id: string;
  city?: string | null;
  countryCode?: CountryEnum | null;
  streetName?: string | null;
  streetNumber?: string | null;
  postalCode?: string | null;
  name: string;
  phone?: string | null;
  email?: string | null;
  status?: string | null;
};

export type ShopProfileFeaturedAgent = {
  __typename?: 'DbShopProfileFeaturedAgent';
  agentId: string;
  index: number;
  agent: {
    __typename: 'DbAgent';
    createdAt: any;
    primaryShopId?: string | null;
    userName: string;
    salutation?: SalutationEnum | null;
    betaTester: boolean;
    energyAdvisoryEnabled: boolean;
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    status: AgentStatusEnum;
    mobilePhoneNumber?: string | null;
    officePhoneNumber?: string | null;
    userTeamAssignments: Array<{
      __typename?: 'DbUserTeamAssignment';
      role: UserRoleEnum;
      team: {
        __typename?: 'DbTeam';
        normalizedName?: string | null;
        description?: string | null;
        status?: TeamStatusEnum | null;
        id: string;
        name: string;
        shopId: string;
        email?: string | null;
        shop: {
          __typename?: 'DbShop';
          id: string;
          shopName: string;
          city?: string | null;
          countryCode: CountryEnum;
          postalCode?: string | null;
          streetName?: string | null;
          phone?: string | null;
          email?: string | null;
          status?: ShopStatusEnum | null;
          streetNumber?: string | null;
          onboardingLevel?: number | null;
          groupId?: string | null;
          latitude?: any | null;
          longitude?: any | null;
          shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
          licensePartner: {
            __typename?: 'DbLicensePartner';
            name: string;
            isCompanyOwnedOperation?: boolean | null;
            id: string;
          };
          profiles: Array<{
            __typename?: 'DbShopProfile';
            images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
          }>;
        };
        teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
      };
    }>;
    userShopAssignments: Array<{
      __typename?: 'DbUserShopAssignment';
      role: UserRoleEnum;
      shop: { __typename?: 'DbShop'; shopName: string; id: string };
    }>;
    nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
    profiles: Array<{
      __typename?: 'DbAgentProfile';
      images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
    }>;
  };
};

export type ShopSettings = {
  __typename?: 'DbShopSetting';
  applyAutomatedContactSettings: boolean;
  ccUsersInAssignmentEmails: boolean;
  consentManagementEnabled: boolean;
  defaultCity?: string | null;
  defaultCountryCode: CountryEnum;
  defaultPreferredLanguage: LanguageEnum;
  shopId: string;
  sendQualificationLink: boolean;
  leadAutoAssignmentEnabled: boolean;
  leadExpiryEnabled: boolean;
  leadExpiryDaysBuyer?: number | null;
  leadExpiryDaysSeller?: number | null;
  leadExpiryDaysToRent?: number | null;
  leadExpiryDaysRentOut?: number | null;
  leadExtendingInteractionsEnabled: boolean;
  lastInteractionRecalculatedAt: any;
  activeToRecalculateLastInteraction?: boolean | null;
};

export type SnapshotLeadFragment = {
  __typename?: 'DbSnapshotLead';
  id: string;
  contactFirstName?: string | null;
  contactLastName?: string | null;
  createdAt: any;
  intentType: IntentTypeEnum;
  priority?: PriorityEnum | null;
  personalNote?: string | null;
  propertyId?: string | null;
  preferredEmailAddress?: { __typename?: 'DbSnapshotEmailAddress'; address: string } | null;
  preferredPhoneNumber?: { __typename?: 'DbSnapshotPhoneNumber'; number: string } | null;
  snapshotBuyerToRentSpecification?: {
    __typename?: 'DbSnapshotBuyerToRentSpecification';
    id: string;
    area?: string | null;
    livingAreaMax?: number | null;
    livingAreaMin?: number | null;
    monthlyNetRentMax?: number | null;
    monthlyNetRentMin?: number | null;
    monthlyTotalRentMax?: number | null;
    monthlyTotalRentMin?: number | null;
    plotSurfaceMax?: number | null;
    plotSurfaceMin?: number | null;
    currency: CurrencyEnum;
    priceMax?: number | null;
    priceMin?: number | null;
    roomsMin?: number | null;
    roomsMax?: number | null;
    type?: AssetTypeEnum | null;
    subTypes?: Array<string> | null;
    variations?: Array<string> | null;
    placeIds: Array<string>;
    snapshotCustomSearchDemandPolygons: Array<{
      __typename?: 'DbSnapshotCustomSearchDemandPolygon';
      id: string;
      name?: string | null;
      polygon: any;
    }>;
  } | null;
  snapshotSellerRentOutSpecification?: {
    __typename?: 'DbSnapshotSellerRentOutSpecification';
    id: string;
    condition?: AssetConditionEnum | null;
    floor?: number | null;
    totalFloors?: number | null;
    constructionYear?: number | null;
    plotArea?: any | null;
    livingArea?: any | null;
    numberOfRooms?: number | null;
    numberOfBathrooms?: number | null;
    variation?: AssetVariationEnum | null;
    priceRangeFrom?: any | null;
    priceRangeTo?: any | null;
    priceRangeUnit: CurrencyEnum;
    rentPriceUnit: CurrencyEnum;
    rentRangeFrom?: any | null;
    rentRangeTo?: any | null;
    city?: string | null;
    postalCode?: string | null;
    countryCode?: CountryEnum | null;
    streetName?: string | null;
    streetNumber?: string | null;
    balcony?: boolean | null;
    garden?: boolean | null;
    parking?: boolean | null;
    swimmingPool?: boolean | null;
    elevator?: boolean | null;
    garage?: boolean | null;
    guestToilet?: boolean | null;
    assetSubType?: { __typename?: 'EnumsAssetSubType'; name: string } | null;
    assetType?: { __typename?: 'EnumsAssetType'; name: string } | null;
    country?: { __typename?: 'EnumsCountry'; name: string } | null;
  } | null;
};

export type Task = {
  __typename?: 'DbTask';
  id: string;
  type: TaskTypeEnum;
  dueDatetime?: any | null;
  title?: string | null;
  status?: TaskAppointmentStatusEnum | null;
  agentId: string;
  teamId?: string | null;
  comment?: string | null;
  propertyIds: Array<string>;
  priority?: PriorityEnum | null;
  go3TaskKey?: number | null;
  taskContacts: Array<{ __typename?: 'DbTaskContact'; contactId: string }>;
  taskLeads: Array<{ __typename?: 'DbTaskLead'; leadId: string }>;
  taskAgents: Array<{
    __typename?: 'DbTaskAgent';
    agent: {
      __typename: 'DbAgent';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    };
  }>;
};

export type TaskActivity = {
  __typename?: 'DbTaskActivity';
  task: {
    __typename?: 'DbTask';
    id: string;
    type: TaskTypeEnum;
    dueDatetime?: any | null;
    title?: string | null;
    status?: TaskAppointmentStatusEnum | null;
    agentId: string;
    teamId?: string | null;
    comment?: string | null;
    propertyIds: Array<string>;
    priority?: PriorityEnum | null;
    go3TaskKey?: number | null;
    taskContacts: Array<{ __typename?: 'DbTaskContact'; contactId: string }>;
    taskLeads: Array<{ __typename?: 'DbTaskLead'; leadId: string }>;
    taskAgents: Array<{
      __typename?: 'DbTaskAgent';
      agent: {
        __typename: 'DbAgent';
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      };
    }>;
  };
};

export type TeamInfo = { __typename?: 'DbTeam'; id: string; name: string; shopId: string; email?: string | null };

export type Team = {
  __typename?: 'DbTeam';
  normalizedName?: string | null;
  description?: string | null;
  status?: TeamStatusEnum | null;
  id: string;
  name: string;
  shopId: string;
  email?: string | null;
  teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
};

export type TeamWithMembers = {
  __typename?: 'DbTeam';
  normalizedName?: string | null;
  description?: string | null;
  status?: TeamStatusEnum | null;
  id: string;
  name: string;
  shopId: string;
  email?: string | null;
  userTeamAssignments: Array<{
    __typename?: 'DbUserTeamAssignment';
    role: UserRoleEnum;
    agent: {
      __typename: 'DbAgent';
      createdAt: any;
      primaryShopId?: string | null;
      userName: string;
      salutation?: SalutationEnum | null;
      betaTester: boolean;
      energyAdvisoryEnabled: boolean;
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    };
  }>;
  teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
};

export type TeamWithMembersAndShop = {
  __typename?: 'DbTeam';
  normalizedName?: string | null;
  description?: string | null;
  status?: TeamStatusEnum | null;
  id: string;
  name: string;
  shopId: string;
  email?: string | null;
  shop: {
    __typename?: 'DbShop';
    id: string;
    shopName: string;
    city?: string | null;
    countryCode: CountryEnum;
    postalCode?: string | null;
    streetName?: string | null;
    phone?: string | null;
    email?: string | null;
    status?: ShopStatusEnum | null;
    streetNumber?: string | null;
    onboardingLevel?: number | null;
    groupId?: string | null;
    latitude?: any | null;
    longitude?: any | null;
    shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
    licensePartner: {
      __typename?: 'DbLicensePartner';
      name: string;
      isCompanyOwnedOperation?: boolean | null;
      id: string;
    };
    profiles: Array<{
      __typename?: 'DbShopProfile';
      images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
    }>;
  };
  userTeamAssignments: Array<{
    __typename?: 'DbUserTeamAssignment';
    role: UserRoleEnum;
    agent: {
      __typename: 'DbAgent';
      createdAt: any;
      primaryShopId?: string | null;
      userName: string;
      salutation?: SalutationEnum | null;
      betaTester: boolean;
      energyAdvisoryEnabled: boolean;
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    };
  }>;
  teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
};

export type UtmParam = {
  __typename?: 'DbUtmParam';
  autaclid?: string | null;
  msclkid?: string | null;
  pmclid?: string | null;
  campaign?: string | null;
  content?: string | null;
  gclid?: string | null;
  medium?: string | null;
  source?: string | null;
  url?: string | null;
  gbraid?: string | null;
  wbraid?: string | null;
};

export type GetLastInteractionActivitiesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBaseActivityOrderBy> | DbBaseActivityOrderBy>;
  where?: InputMaybe<DbBaseActivityBoolExp>;
}>;

export type GetLastInteractionActivitiesQuery = {
  __typename?: 'query_root';
  dbBaseActivity: Array<{
    __typename?: 'DbBaseActivity';
    orderDate: any;
    category: BaseActivityCategoryEnum;
    type: ActivityTypeEnum;
    createdBy: string;
    createdAt: any;
    shopId?: string | null;
    taskActivity?: { __typename?: 'DbTaskActivity'; task: { __typename?: 'DbTask'; type: TaskTypeEnum } } | null;
    appointmentActivity?: {
      __typename?: 'DbAppointmentActivity';
      appointment: { __typename?: 'DbAppointment'; type: AppointmentTypeEnum };
    } | null;
  }>;
};

export type GetActivitiesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBaseActivityOrderBy> | DbBaseActivityOrderBy>;
  where?: InputMaybe<DbBaseActivityBoolExp>;
}>;

export type GetActivitiesQuery = {
  __typename?: 'query_root';
  dbBaseActivity: Array<{
    __typename?: 'DbBaseActivity';
    id: string;
    createdAt: any;
    category: BaseActivityCategoryEnum;
    creator?: { __typename?: 'DbAgent'; firstName: string; lastName: string } | null;
    taskActivity?: {
      __typename?: 'DbTaskActivity';
      task: { __typename?: 'DbTask'; taskType: { __typename?: 'EnumsTaskType'; name: string } };
    } | null;
  }>;
};

export type GetActivitiesListQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBaseActivityOrderBy> | DbBaseActivityOrderBy>;
  where?: InputMaybe<DbBaseActivityBoolExp>;
}>;

export type GetActivitiesListQuery = {
  __typename?: 'query_root';
  dbBaseActivity: Array<{
    __typename?: 'DbBaseActivity';
    id: string;
    category: BaseActivityCategoryEnum;
    contactId?: string | null;
    shopId?: string | null;
    leadId?: string | null;
    type: ActivityTypeEnum;
    createdBy: string;
    createdAt: any;
    creator?: { __typename?: 'DbAgent'; id: string; userName: string } | null;
    callActivity?: {
      __typename?: 'DbCallActivity';
      call?: {
        __typename?: 'DbCall';
        createdAt: any;
        direction: CallDirectionEnum;
        status: CallStatusEnum;
        duration?: any | null;
      } | null;
    } | null;
    emailActivity?: {
      __typename?: 'DbEmailActivity';
      email: {
        __typename?: 'DbEmail';
        subject: string;
        propertyIds: Array<string>;
        createdAt: any;
        direction?: EmailDirectionEnum | null;
      };
    } | null;
    taskActivity?: {
      __typename?: 'DbTaskActivity';
      task: {
        __typename?: 'DbTask';
        agentId: string;
        title?: string | null;
        type: TaskTypeEnum;
        dueDatetime?: any | null;
        propertyIds: Array<string>;
      };
    } | null;
    appointmentActivity?: {
      __typename?: 'DbAppointmentActivity';
      appointment: {
        __typename?: 'DbAppointment';
        agentId: string;
        subject?: string | null;
        type: AppointmentTypeEnum;
        propertyIds: Array<string>;
        startTime: any;
        endTime?: any | null;
      };
    } | null;
    documentActivity?: {
      __typename?: 'DbDocumentActivity';
      documentReference?: {
        __typename?: 'DbDocumentReference';
        fileName: string;
        contentType: string;
        createdAt: any;
      } | null;
    } | null;
    exposeSharedActivity?: {
      __typename?: 'DbExposeeSharedActivity';
      propertyIds: Array<string>;
      title?: string | null;
      createdAt: any;
    } | null;
  }>;
};

export type GetExpandedActivityQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbBaseActivityOrderBy> | DbBaseActivityOrderBy>;
  where?: InputMaybe<DbBaseActivityBoolExp>;
}>;

export type GetExpandedActivityQuery = {
  __typename?: 'query_root';
  dbBaseActivity: Array<{
    __typename?: 'DbBaseActivity';
    id: string;
    category: BaseActivityCategoryEnum;
    contactId?: string | null;
    shopId?: string | null;
    leadId?: string | null;
    type: ActivityTypeEnum;
    createdBy: string;
    createdAt: any;
    creator?: { __typename?: 'DbAgent'; id: string; userName: string; firstName: string; lastName: string } | null;
    contact?: { __typename?: 'DbContact'; id: string; firstName?: string | null; lastName?: string | null } | null;
    callActivity?: {
      __typename?: 'DbCallActivity';
      id: string;
      call?: {
        __typename?: 'DbCall';
        direction: CallDirectionEnum;
        status: CallStatusEnum;
        duration?: any | null;
        createdAt: any;
        note?: string | null;
        outcome?: CallOutcomeEnum | null;
        agent?: { __typename?: 'DbAgent'; firstName: string; lastName: string } | null;
      } | null;
    } | null;
    emailActivity?: {
      __typename?: 'DbEmailActivity';
      id: string;
      email: {
        __typename?: 'DbEmail';
        direction?: EmailDirectionEnum | null;
        subject: string;
        propertyIds: Array<string>;
        createdAt: any;
        from: string;
        to: Array<string>;
        cc?: Array<string> | null;
        body: string;
      };
    } | null;
    taskActivity?: {
      __typename?: 'DbTaskActivity';
      id: string;
      task: {
        __typename?: 'DbTask';
        id: string;
        agentId: string;
        title?: string | null;
        type: TaskTypeEnum;
        propertyIds: Array<string>;
        dueDatetime?: any | null;
        status?: TaskAppointmentStatusEnum | null;
        updatedAt: any;
        comment?: string | null;
      };
    } | null;
    appointmentActivity?: {
      __typename?: 'DbAppointmentActivity';
      id: string;
      appointment: {
        __typename?: 'DbAppointment';
        id: string;
        agentId: string;
        subject?: string | null;
        type: AppointmentTypeEnum;
        propertyIds: Array<string>;
        startTime: any;
        endTime?: any | null;
        status?: TaskAppointmentStatusEnum | null;
        updatedAt: any;
        comment?: string | null;
      };
    } | null;
    documentActivity?: {
      __typename?: 'DbDocumentActivity';
      id: string;
      documentReference?: {
        __typename?: 'DbDocumentReference';
        id: string;
        fileName: string;
        contentType: string;
        createdAt: any;
        sizeKb?: number | null;
      } | null;
    } | null;
    exposeSharedActivity?: {
      __typename?: 'DbExposeeSharedActivity';
      id: string;
      propertyIds: Array<string>;
      status: string;
      title?: string | null;
      createdAt: any;
      comment?: string | null;
    } | null;
  }>;
};

export type TotalActivitiesCountQueryQueryVariables = Exact<{
  where?: InputMaybe<DbBaseActivityBoolExp>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type TotalActivitiesCountQueryQuery = {
  __typename?: 'query_root';
  dbBaseActivityAggregate: {
    __typename?: 'DbBaseActivityAggregate';
    aggregate?: { __typename?: 'DbBaseActivityAggregateFields'; totalActivityCount: number } | null;
  };
};

export type ActivityTypeGroupingQueryVariables = Exact<{ [key: string]: never }>;

export type ActivityTypeGroupingQuery = {
  __typename?: 'query_root';
  dbActivityTypeGrouping: Array<{
    __typename?: 'DbActivityTypeGrouping';
    activityCategory: BaseActivityCategoryEnum;
    activityType: ActivityTypeEnum;
    typeLocalizeKey: string;
  }>;
};

export type AgentProfileQueryVariables = Exact<{
  where: DbAgentProfileBoolExp;
}>;

export type AgentProfileQuery = {
  __typename?: 'query_root';
  dbAgentProfile: Array<{
    __typename?: 'DbAgentProfile';
    id: string;
    published?: string | null;
    cityDistricts: Array<{ __typename?: 'DbAgentProfileCityDistrict'; city: string; district?: string | null }>;
    expertises: Array<{ __typename?: 'DbAgentProfileExpertise'; expertise: ShopExpertiseEnum }>;
    spokenLanguages: Array<{ __typename?: 'DbAgentProfileSpokenLanguage'; spokenLanguage: SpokenLanguageEnum }>;
    descriptions: Array<{
      __typename?: 'DbAgentProfileDescription';
      jobTitle?: string | null;
      academicTitle?: string | null;
      text?: string | null;
      lang: LanguageEnum;
      url?: string | null;
      isAiTranslated?: boolean | null;
    }>;
    images: Array<{ __typename?: 'DbAgentProfileImage'; id: string; title: string; gcpFileName: string }>;
    socialMediaProfiles?: {
      __typename?: 'DbAgentProfileSocialMediaProfiles';
      facebookUrl?: string | null;
      instagramUrl?: string | null;
      twitterUrl?: string | null;
      xingUrl?: string | null;
      linkedInUrl?: string | null;
      tiktokUrl?: string | null;
    } | null;
  }>;
  publishedProfile: Array<{ __typename?: 'DbAgentProfile'; published?: string | null }>;
};

export type AgentQueryQueryVariables = Exact<{
  where?: InputMaybe<DbAgentBoolExp>;
  orderBy?: InputMaybe<Array<DbAgentOrderBy> | DbAgentOrderBy>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  distinctOn?: InputMaybe<Array<DbAgentSelectColumn> | DbAgentSelectColumn>;
}>;

export type AgentQueryQuery = {
  __typename?: 'query_root';
  dbAgent: Array<{
    __typename: 'DbAgent';
    createdAt: any;
    primaryShopId?: string | null;
    userName: string;
    salutation?: SalutationEnum | null;
    betaTester: boolean;
    energyAdvisoryEnabled: boolean;
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    status: AgentStatusEnum;
    mobilePhoneNumber?: string | null;
    officePhoneNumber?: string | null;
    nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
    profiles: Array<{
      __typename?: 'DbAgentProfile';
      images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
    }>;
  }>;
};

export type AgentWithTeamAssignmentsQueryVariables = Exact<{
  where: DbAgentBoolExp;
}>;

export type AgentWithTeamAssignmentsQuery = {
  __typename?: 'query_root';
  dbAgent: Array<{
    __typename: 'DbAgent';
    createdAt: any;
    primaryShopId?: string | null;
    userName: string;
    salutation?: SalutationEnum | null;
    betaTester: boolean;
    energyAdvisoryEnabled: boolean;
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    status: AgentStatusEnum;
    mobilePhoneNumber?: string | null;
    officePhoneNumber?: string | null;
    userTeamAssignments: Array<{
      __typename?: 'DbUserTeamAssignment';
      role: UserRoleEnum;
      team: {
        __typename?: 'DbTeam';
        normalizedName?: string | null;
        description?: string | null;
        status?: TeamStatusEnum | null;
        id: string;
        name: string;
        shopId: string;
        email?: string | null;
        teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
      };
    }>;
    nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
    profiles: Array<{
      __typename?: 'DbAgentProfile';
      images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
    }>;
  }>;
};

export type LeadsResponsibleAgentsQueryVariables = Exact<{
  contactId?: InputMaybe<Scalars['String']>;
}>;

export type LeadsResponsibleAgentsQuery = {
  __typename?: 'query_root';
  dbContact: Array<{
    __typename?: 'DbContact';
    agentsAssignedToLeadsOfContact: Array<{
      __typename?: 'DbAgentsAssignedToLeadsOfContact';
      intentType?: string | null;
      agent?: {
        __typename: 'DbAgent';
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      } | null;
    }>;
  }>;
};

export type AgentsWithAllMembershipsQueryVariables = Exact<{
  where: DbAgentBoolExp;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentOrderBy> | DbAgentOrderBy>;
}>;

export type AgentsWithAllMembershipsQuery = {
  __typename?: 'query_root';
  dbAgent: Array<{
    __typename: 'DbAgent';
    createdAt: any;
    primaryShopId?: string | null;
    userName: string;
    salutation?: SalutationEnum | null;
    betaTester: boolean;
    energyAdvisoryEnabled: boolean;
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    status: AgentStatusEnum;
    mobilePhoneNumber?: string | null;
    officePhoneNumber?: string | null;
    userTeamAssignments: Array<{
      __typename?: 'DbUserTeamAssignment';
      role: UserRoleEnum;
      team: {
        __typename?: 'DbTeam';
        normalizedName?: string | null;
        description?: string | null;
        status?: TeamStatusEnum | null;
        id: string;
        name: string;
        shopId: string;
        email?: string | null;
        shop: {
          __typename?: 'DbShop';
          id: string;
          shopName: string;
          city?: string | null;
          countryCode: CountryEnum;
          postalCode?: string | null;
          streetName?: string | null;
          phone?: string | null;
          email?: string | null;
          status?: ShopStatusEnum | null;
          streetNumber?: string | null;
          onboardingLevel?: number | null;
          groupId?: string | null;
          latitude?: any | null;
          longitude?: any | null;
          shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
          licensePartner: {
            __typename?: 'DbLicensePartner';
            name: string;
            isCompanyOwnedOperation?: boolean | null;
            id: string;
          };
          profiles: Array<{
            __typename?: 'DbShopProfile';
            images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
          }>;
        };
        teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
      };
    }>;
    userShopAssignments: Array<{
      __typename?: 'DbUserShopAssignment';
      role: UserRoleEnum;
      shop: {
        __typename?: 'DbShop';
        id: string;
        shopName: string;
        city?: string | null;
        countryCode: CountryEnum;
        postalCode?: string | null;
        streetName?: string | null;
        phone?: string | null;
        email?: string | null;
        status?: ShopStatusEnum | null;
        streetNumber?: string | null;
        onboardingLevel?: number | null;
        groupId?: string | null;
        latitude?: any | null;
        longitude?: any | null;
        shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
        licensePartner: {
          __typename?: 'DbLicensePartner';
          name: string;
          isCompanyOwnedOperation?: boolean | null;
          id: string;
        };
        profiles: Array<{
          __typename?: 'DbShopProfile';
          images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
        }>;
      };
    }>;
    userAdminAssignments: Array<{ __typename?: 'DbUserAdminAssignment'; role: UserRoleEnum }>;
    nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
    profiles: Array<{
      __typename?: 'DbAgentProfile';
      images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
    }>;
  }>;
  dbAgentAggregate: {
    __typename?: 'DbAgentAggregate';
    aggregate?: { __typename?: 'DbAgentAggregateFields'; totalCount: number } | null;
  };
};

export type BulkQueryQueryVariables = Exact<{
  order_by?: InputMaybe<Array<DbBulkEmailOrderBy> | DbBulkEmailOrderBy>;
  where?: InputMaybe<DbBulkEmailBoolExp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type BulkQueryQuery = {
  __typename?: 'query_root';
  dbBulkEmail: Array<{
    __typename?: 'DbBulkEmail';
    id?: string | null;
    from?: string | null;
    to?: Array<string> | null;
    cc?: Array<string> | null;
    bcc?: Array<string> | null;
    subject?: string | null;
    body?: string | null;
    status?: string | null;
    createdAt?: any | null;
    attachments: Array<{
      __typename?: 'DbEmailAttachment';
      document: { __typename?: 'DbDocument'; id: string; contentType: string; name: string };
    }>;
    documentReferences: Array<{
      __typename?: 'DbDocumentReference';
      id: string;
      fileName: string;
      sizeKb?: number | null;
    }>;
    trackings: Array<{
      __typename?: 'DbEmailTracking';
      id: string;
      count: number;
      type: EmailTrackingTypeEnum;
      updatedAt: any;
      linkUrl?: string | null;
      recipient?: string | null;
    }>;
    emailActivities: Array<{
      __typename?: 'DbEmailActivity';
      baseActivity: { __typename?: 'DbBaseActivity'; contactId?: string | null };
    }>;
    template?: { __typename?: 'DbEmailTemplate'; name: string } | null;
  }>;
};

export type TotalBulkEmailCountQueryQueryVariables = Exact<{
  where?: InputMaybe<DbBulkEmailBoolExp>;
}>;

export type TotalBulkEmailCountQueryQuery = {
  __typename?: 'query_root';
  dbBulkEmailAggregate: {
    __typename?: 'DbBulkEmailAggregate';
    aggregate?: { __typename?: 'DbBulkEmailAggregateFields'; totalBulkEmails: number } | null;
  };
};

export type CallListQueryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DbCallOrderBy> | DbCallOrderBy>;
  where?: InputMaybe<DbCallBoolExp>;
}>;

export type CallListQueryQuery = {
  __typename?: 'query_root';
  dbCall: Array<{
    __typename?: 'DbCall';
    id: string;
    callSid: string;
    createdAt: any;
    duration?: any | null;
    outcome?: CallOutcomeEnum | null;
    note?: string | null;
    status: CallStatusEnum;
    shopId: string;
    recipient: string;
    caller: string;
    call_activities: Array<{
      __typename?: 'DbCallActivity';
      baseActivity: {
        __typename?: 'DbBaseActivity';
        id: string;
        category: BaseActivityCategoryEnum;
        type: ActivityTypeEnum;
        createdAt: any;
        createdBy: string;
        contactId?: string | null;
        leadId?: string | null;
        shopId?: string | null;
        lead?: {
          __typename?: 'DbLead';
          contactId: string;
          id: string;
          intentType: IntentTypeEnum;
          status: LeadStatusEnum;
          expiredAt?: any | null;
          go3PropertyId?: string | null;
          peLinkedPropertyId?: string | null;
          personalNote?: string | null;
          timeFrame?: TimeFrameEnum | null;
          customerMessage?: string | null;
          sellerRentOutSpecification?: {
            __typename?: 'DbSellerRentOutSpecification';
            type?: AssetTypeEnum | null;
            livingArea?: any | null;
            plotArea?: any | null;
            constructionYear?: number | null;
            condition?: AssetConditionEnum | null;
            priceRangeTo?: any | null;
            priceRangeFrom?: any | null;
            rentRangeFrom?: any | null;
            rentRangeTo?: any | null;
            priceRangeUnit: CurrencyEnum;
            rentPriceUnit: CurrencyEnum;
            occupationStatus?: PropertyOccupationStatusEnum | null;
            numberOfBathrooms?: number | null;
            numberOfBedrooms?: number | null;
            floor?: number | null;
            numberOfMasterBathrooms?: number | null;
            numberOfRooms?: number | null;
            totalFloors?: number | null;
            surfaceTotal?: any | null;
            surfaceUsable?: any | null;
            balcony?: boolean | null;
            garden?: boolean | null;
            parking?: boolean | null;
            swimmingPool?: boolean | null;
            elevator?: boolean | null;
            garage?: boolean | null;
            guestToilet?: boolean | null;
            subType?: AssetSubTypeEnum | null;
            variation?: AssetVariationEnum | null;
            id: string;
            ownershipStatus?: PropertyOwnershipStatusEnum | null;
            ownerDescription?: string | null;
            address?: {
              __typename?: 'DbAddress';
              city?: string | null;
              postalCode?: string | null;
              countryCode: CountryEnum;
              streetName?: string | null;
              streetNumber?: string | null;
              secondAddressLine?: string | null;
              id: string;
              fullAddress?: string | null;
              placeId?: string | null;
              country: { __typename?: 'EnumsCountry'; description?: string | null; name: string };
            } | null;
            owner?: {
              __typename?: 'DbContact';
              id: string;
              firstName?: string | null;
              lastName?: string | null;
              fullName?: string | null;
            } | null;
            sellerRentOutSpecificationCdfs: Array<{
              __typename?: 'DbSellerRentOutSpecificationCdf';
              cdf: { __typename?: 'DbCdf'; id: string; label: string };
            }>;
          } | null;
          buyerToRentSpecification?: {
            __typename?: 'DbBuyerToRentSpecification';
            type?: AssetTypeEnum | null;
            city?: string | null;
            livingAreaMax?: number | null;
            livingAreaMin?: number | null;
            totalSurfaceMax?: number | null;
            totalSurfaceMin?: number | null;
            plotSurfaceMax?: number | null;
            plotSurfaceMin?: number | null;
            area?: string | null;
            bedroomsMax?: number | null;
            bedroomsMin?: number | null;
            country?: string | null;
            currency: CurrencyEnum;
            district?: string | null;
            id: string;
            legacyAreaNames: Array<string>;
            legacyDistrictNames: Array<string>;
            monthlyNetRentMax?: number | null;
            monthlyNetRentMin?: number | null;
            monthlyTotalRentMax?: number | null;
            monthlyTotalRentMin?: number | null;
            priceMax?: number | null;
            priceMin?: number | null;
            roomsMax?: number | null;
            roomsMin?: number | null;
            subTypes?: Array<string> | null;
            variations?: Array<string> | null;
            placeIds: Array<string>;
            searchDemandId?: string | null;
            buyerToRentSpecificationCdfs: Array<{
              __typename?: 'DbBuyerToRentSpecificationCdf';
              cdf: { __typename?: 'DbCdf'; id: string; label: string };
            }>;
            customSearchDemandPolygons: Array<{
              __typename?: 'DbCustomSearchDemandPolygon';
              id: string;
              name?: string | null;
              polygon: any;
            }>;
          } | null;
          referrals: Array<{ __typename?: 'DbReferral'; receivingShop?: { __typename?: 'DbShop'; id: string } | null }>;
        } | null;
        contact?: {
          __typename?: 'DbContact';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          salutation?: SalutationEnum | null;
          title?: string | null;
          birthdate?: any | null;
          contactStatus: ContactStatusEnum;
          blocked: boolean;
          personalNote?: string | null;
          preferredLanguage?: LanguageEnum | null;
          preferredTimes: Array<{ __typename?: 'DbContactPreferredTime'; preferredContactTime: ContactTimeEnum }>;
          emailAddresses: Array<{ __typename?: 'DbContactEmailAddress'; address: string; preferred: boolean }>;
          phoneNumbers: Array<{
            __typename?: 'DbContactPhoneNumber';
            number: string;
            preferred: boolean;
            type: PhoneNumberTypeEnum;
          }>;
        } | null;
      };
    }>;
    callDirection: { __typename?: 'EnumsCallDirection'; name: string };
    agent?: {
      __typename: 'DbAgent';
      createdAt: any;
      primaryShopId?: string | null;
      userName: string;
      salutation?: SalutationEnum | null;
      betaTester: boolean;
      energyAdvisoryEnabled: boolean;
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
  }>;
};

export type CallQueueQueryVariables = Exact<{
  shops?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type CallQueueQuery = {
  __typename?: 'query_root';
  dbCallAggregate: {
    __typename?: 'DbCallAggregate';
    aggregate?: { __typename?: 'DbCallAggregateFields'; count: number } | null;
  };
};

export type CommentQueryVariables = Exact<{
  contactId: Scalars['String'];
}>;

export type CommentQuery = {
  __typename?: 'query_root';
  dbContact: Array<{ __typename?: 'DbContact'; personalNote?: string | null }>;
};

export type ConsentByIdQueryVariables = Exact<{
  where?: InputMaybe<DbConsentBoolExp>;
}>;

export type ConsentByIdQuery = {
  __typename?: 'query_root';
  dbConsent: Array<{
    __typename?: 'DbConsent';
    id: string;
    licenseId?: string | null;
    status: ConsentStatusEnum;
    consentType: ConsentTypeEnum;
    consentedOn?: any | null;
    updatedAt: any;
    revokedAt?: any | null;
    expiredAt?: any | null;
    consentSource: ConsentSourceEnum;
    contactForm: string;
    createdBy: string;
    creator: { __typename?: 'DbAgent'; firstName: string; lastName: string };
    document?: {
      __typename?: 'DbDocumentReference';
      contactId?: string | null;
      contentType: string;
      createdAt: any;
      createdBy: string;
      description?: string | null;
      fileName: string;
      teamId?: string | null;
      privacyLevel: PrivacyLevelEnum;
      referenceId: string;
      referenceType: EntityTypeEnum;
      sizeKb?: number | null;
      id: string;
      displayName: string;
      createdByAgent?: {
        __typename: 'DbAgent';
        createdAt: any;
        primaryShopId?: string | null;
        userName: string;
        salutation?: SalutationEnum | null;
        betaTester: boolean;
        energyAdvisoryEnabled: boolean;
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      } | null;
      lead?: {
        __typename?: 'DbLead';
        id: string;
        contactId: string;
        teamId?: string | null;
        intentType: IntentTypeEnum;
        createdAt: any;
        expiredAt?: any | null;
        status: LeadStatusEnum;
        timeFrame?: TimeFrameEnum | null;
        agentId?: string | null;
        shopId: string;
        personalNote?: string | null;
        go3PropertyId?: string | null;
        peLinkedPropertyId?: string | null;
        sellerRentOutSpecification?: {
          __typename?: 'DbSellerRentOutSpecification';
          type?: AssetTypeEnum | null;
          livingArea?: any | null;
          priceRangeTo?: any | null;
          priceRangeFrom?: any | null;
          priceRangeUnit: CurrencyEnum;
          rentPriceUnit: CurrencyEnum;
          plotArea?: any | null;
          address?: {
            __typename?: 'DbAddress';
            city?: string | null;
            postalCode?: string | null;
            fullAddress?: string | null;
          } | null;
        } | null;
        buyerToRentSpecification?: {
          __typename?: 'DbBuyerToRentSpecification';
          searchDemandId?: string | null;
          type?: AssetTypeEnum | null;
          city?: string | null;
          livingAreaMax?: number | null;
          livingAreaMin?: number | null;
          totalSurfaceMax?: number | null;
          totalSurfaceMin?: number | null;
          plotSurfaceMax?: number | null;
          plotSurfaceMin?: number | null;
          priceMax?: number | null;
          priceMin?: number | null;
          currency: CurrencyEnum;
          area?: string | null;
          country?: string | null;
        } | null;
        emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
        leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
      } | null;
    } | null;
  }>;
};

export type ContactAndLeadsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
  shopIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type ContactAndLeadsQuery = {
  __typename?: 'query_root';
  dbContact: Array<{
    __typename?: 'DbContact';
    fullName?: string | null;
    salutation?: SalutationEnum | null;
    formattedSalutation?: string | null;
    title?: string | null;
    contactStatus: ContactStatusEnum;
    birthdate?: any | null;
    placeOfBirth?: string | null;
    nationality: CountryEnum;
    idNumber?: string | null;
    kycSearchId?: string | null;
    personalNote?: string | null;
    partnerFirstName?: string | null;
    partnerLastName?: string | null;
    deleted: boolean;
    createdAt: any;
    type: ContactTypeEnum;
    autoCc?: boolean | null;
    customerAccountId?: string | null;
    blocked: boolean;
    preferredLanguage?: LanguageEnum | null;
    shopId: string;
    id: string;
    go3Utag?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    lastInteractionActivity?: {
      __typename?: 'DbBaseActivity';
      orderDate: any;
      category: BaseActivityCategoryEnum;
      type: ActivityTypeEnum;
      createdBy: string;
      createdAt: any;
      shopId?: string | null;
      taskActivity?: { __typename?: 'DbTaskActivity'; task: { __typename?: 'DbTask'; type: TaskTypeEnum } } | null;
      appointmentActivity?: {
        __typename?: 'DbAppointmentActivity';
        appointment: { __typename?: 'DbAppointment'; type: AppointmentTypeEnum };
      } | null;
    } | null;
    leads: Array<{
      __typename?: 'DbLead';
      id: string;
      contactId: string;
      teamId?: string | null;
      intentType: IntentTypeEnum;
      createdAt: any;
      expiredAt?: any | null;
      status: LeadStatusEnum;
      timeFrame?: TimeFrameEnum | null;
      agentId?: string | null;
      shopId: string;
      personalNote?: string | null;
      go3PropertyId?: string | null;
      peLinkedPropertyId?: string | null;
      lastInteractionActivity?: {
        __typename?: 'DbBaseActivity';
        orderDate: any;
        category: BaseActivityCategoryEnum;
        type: ActivityTypeEnum;
        createdBy: string;
        createdAt: any;
        shopId?: string | null;
        taskActivity?: { __typename?: 'DbTaskActivity'; task: { __typename?: 'DbTask'; type: TaskTypeEnum } } | null;
        appointmentActivity?: {
          __typename?: 'DbAppointmentActivity';
          appointment: { __typename?: 'DbAppointment'; type: AppointmentTypeEnum };
        } | null;
      } | null;
      sellerRentOutSpecification?: {
        __typename?: 'DbSellerRentOutSpecification';
        type?: AssetTypeEnum | null;
        livingArea?: any | null;
        priceRangeTo?: any | null;
        priceRangeFrom?: any | null;
        priceRangeUnit: CurrencyEnum;
        rentPriceUnit: CurrencyEnum;
        plotArea?: any | null;
        address?: {
          __typename?: 'DbAddress';
          city?: string | null;
          postalCode?: string | null;
          fullAddress?: string | null;
        } | null;
      } | null;
      buyerToRentSpecification?: {
        __typename?: 'DbBuyerToRentSpecification';
        searchDemandId?: string | null;
        type?: AssetTypeEnum | null;
        city?: string | null;
        livingAreaMax?: number | null;
        livingAreaMin?: number | null;
        totalSurfaceMax?: number | null;
        totalSurfaceMin?: number | null;
        plotSurfaceMax?: number | null;
        plotSurfaceMin?: number | null;
        priceMax?: number | null;
        priceMin?: number | null;
        currency: CurrencyEnum;
        area?: string | null;
        country?: string | null;
      } | null;
      emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
      leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
      lastActivity: Array<{
        __typename?: 'DbBaseActivity';
        type: ActivityTypeEnum;
        createdBy: string;
        createdAt: any;
        shopId?: string | null;
      }>;
    }>;
    lastActivity: Array<{
      __typename?: 'DbBaseActivity';
      type: ActivityTypeEnum;
      createdBy: string;
      createdAt: any;
      shopId?: string | null;
    }>;
    addresses: Array<{
      __typename?: 'DbContactAddress';
      preferred: boolean;
      type: AddressTypeEnum;
      address: {
        __typename?: 'DbAddress';
        id: string;
        city?: string | null;
        countryCode: CountryEnum;
        postalCode?: string | null;
        secondAddressLine?: string | null;
        streetName?: string | null;
        streetNumber?: string | null;
        fullAddress?: string | null;
        placeId?: string | null;
        country: { __typename?: 'EnumsCountry'; description?: string | null; name: string };
      };
    }>;
    emailAddresses: Array<{
      __typename?: 'DbContactEmailAddress';
      address: string;
      preferred: boolean;
      type: EmailAddressTypeEnum;
    }>;
    legalEntity?: { __typename?: 'DbLegalEntity'; department?: string | null; id: string; name: string } | null;
    customerAccount?: {
      __typename?: 'DbCustomerAccount';
      emailAddress: string;
      consents: Array<{
        __typename?: 'DbConsent';
        id: string;
        licenseId?: string | null;
        status: ConsentStatusEnum;
        consentType: ConsentTypeEnum;
        consentedOn?: any | null;
        updatedAt: any;
        revokedAt?: any | null;
        expiredAt?: any | null;
        consentSource: ConsentSourceEnum;
        contactForm: string;
        createdBy: string;
        creator: { __typename?: 'DbAgent'; firstName: string; lastName: string };
        document?: {
          __typename?: 'DbDocumentReference';
          contactId?: string | null;
          contentType: string;
          createdAt: any;
          createdBy: string;
          description?: string | null;
          fileName: string;
          teamId?: string | null;
          privacyLevel: PrivacyLevelEnum;
          referenceId: string;
          referenceType: EntityTypeEnum;
          sizeKb?: number | null;
          id: string;
          displayName: string;
          createdByAgent?: {
            __typename: 'DbAgent';
            createdAt: any;
            primaryShopId?: string | null;
            userName: string;
            salutation?: SalutationEnum | null;
            betaTester: boolean;
            energyAdvisoryEnabled: boolean;
            id: string;
            firstName: string;
            lastName: string;
            email: string;
            status: AgentStatusEnum;
            mobilePhoneNumber?: string | null;
            officePhoneNumber?: string | null;
            nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
            profiles: Array<{
              __typename?: 'DbAgentProfile';
              images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
            }>;
          } | null;
          lead?: {
            __typename?: 'DbLead';
            id: string;
            contactId: string;
            teamId?: string | null;
            intentType: IntentTypeEnum;
            createdAt: any;
            expiredAt?: any | null;
            status: LeadStatusEnum;
            timeFrame?: TimeFrameEnum | null;
            agentId?: string | null;
            shopId: string;
            personalNote?: string | null;
            go3PropertyId?: string | null;
            peLinkedPropertyId?: string | null;
            sellerRentOutSpecification?: {
              __typename?: 'DbSellerRentOutSpecification';
              type?: AssetTypeEnum | null;
              livingArea?: any | null;
              priceRangeTo?: any | null;
              priceRangeFrom?: any | null;
              priceRangeUnit: CurrencyEnum;
              rentPriceUnit: CurrencyEnum;
              plotArea?: any | null;
              address?: {
                __typename?: 'DbAddress';
                city?: string | null;
                postalCode?: string | null;
                fullAddress?: string | null;
              } | null;
            } | null;
            buyerToRentSpecification?: {
              __typename?: 'DbBuyerToRentSpecification';
              searchDemandId?: string | null;
              type?: AssetTypeEnum | null;
              city?: string | null;
              livingAreaMax?: number | null;
              livingAreaMin?: number | null;
              totalSurfaceMax?: number | null;
              totalSurfaceMin?: number | null;
              plotSurfaceMax?: number | null;
              plotSurfaceMin?: number | null;
              priceMax?: number | null;
              priceMin?: number | null;
              currency: CurrencyEnum;
              area?: string | null;
              country?: string | null;
            } | null;
            emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
            leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
          } | null;
        } | null;
      }>;
    } | null;
    phoneNumbers: Array<{
      __typename?: 'DbContactPhoneNumber';
      number: string;
      internationalNumber?: string | null;
      preferred: boolean;
      type: PhoneNumberTypeEnum;
    }>;
    preferredTimes: Array<{ __typename?: 'DbContactPreferredTime'; preferredContactTime: ContactTimeEnum }>;
    contactRelationships: Array<{
      __typename?: 'DbContactRelationship';
      relatedContactId: string;
      autoCc?: boolean | null;
      type: ContactRelationTypeEnum;
      relatedContact: {
        __typename?: 'DbContact';
        id: string;
        go3Utag?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        preferredLanguage?: LanguageEnum | null;
        blocked: boolean;
        phoneNumbers: Array<{ __typename?: 'DbContactPhoneNumber'; number: string; preferred: boolean }>;
        emailAddresses: Array<{ __typename?: 'DbContactEmailAddress'; address: string; preferred: boolean }>;
      };
    }>;
    contactGdpr?: { __typename?: 'DbContactGdpr'; agreedToGdpr?: boolean | null } | null;
    contactRenterInformations: Array<{
      __typename?: 'DbContactRenterInformation';
      contactId: string;
      householdType?: HouseholdTypeEnum | null;
      professionType?: ProfessionTypeEnum | null;
      petOwner?: boolean | null;
      monthlyIncomeMin?: number | null;
      monthlyIncomeMax?: number | null;
      currency: CurrencyEnum;
    }>;
    tags: Array<{ __typename?: 'DbContactTag'; tag: { __typename?: 'DbTag'; id: string; name: string } }>;
    preferredPhone: Array<{ __typename?: 'DbContactPhoneNumber'; number: string }>;
    preferredEmail: Array<{ __typename?: 'DbContactEmailAddress'; address: string }>;
    leadhubGo3ContactLinks: Array<{
      __typename?: 'DbLeadhubGo3ContactLink';
      firstIntentCategory: IntentCategoryEnum;
      secondIntentCategory?: IntentCategoryEnum | null;
      thirdIntentCategory?: IntentCategoryEnum | null;
      firstAgent: {
        __typename: 'DbAgent';
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      };
      secondAgent?: {
        __typename: 'DbAgent';
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      } | null;
      thirdAgent?: {
        __typename: 'DbAgent';
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      } | null;
    }>;
    emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
    shop: { __typename?: 'DbShop'; shopName: string };
    creator?: { __typename?: 'DbAgent'; firstName: string; lastName: string } | null;
  }>;
};

export type ContactQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;

export type ContactQuery = {
  __typename?: 'query_root';
  dbContact: Array<{
    __typename?: 'DbContact';
    fullName?: string | null;
    salutation?: SalutationEnum | null;
    formattedSalutation?: string | null;
    title?: string | null;
    contactStatus: ContactStatusEnum;
    birthdate?: any | null;
    placeOfBirth?: string | null;
    nationality: CountryEnum;
    idNumber?: string | null;
    kycSearchId?: string | null;
    personalNote?: string | null;
    partnerFirstName?: string | null;
    partnerLastName?: string | null;
    deleted: boolean;
    createdAt: any;
    type: ContactTypeEnum;
    autoCc?: boolean | null;
    customerAccountId?: string | null;
    blocked: boolean;
    preferredLanguage?: LanguageEnum | null;
    shopId: string;
    id: string;
    go3Utag?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    addresses: Array<{
      __typename?: 'DbContactAddress';
      preferred: boolean;
      type: AddressTypeEnum;
      address: {
        __typename?: 'DbAddress';
        id: string;
        city?: string | null;
        countryCode: CountryEnum;
        postalCode?: string | null;
        secondAddressLine?: string | null;
        streetName?: string | null;
        streetNumber?: string | null;
        fullAddress?: string | null;
        placeId?: string | null;
        country: { __typename?: 'EnumsCountry'; description?: string | null; name: string };
      };
    }>;
    emailAddresses: Array<{
      __typename?: 'DbContactEmailAddress';
      address: string;
      preferred: boolean;
      type: EmailAddressTypeEnum;
    }>;
    legalEntity?: { __typename?: 'DbLegalEntity'; department?: string | null; id: string; name: string } | null;
    customerAccount?: {
      __typename?: 'DbCustomerAccount';
      emailAddress: string;
      consents: Array<{
        __typename?: 'DbConsent';
        id: string;
        licenseId?: string | null;
        status: ConsentStatusEnum;
        consentType: ConsentTypeEnum;
        consentedOn?: any | null;
        updatedAt: any;
        revokedAt?: any | null;
        expiredAt?: any | null;
        consentSource: ConsentSourceEnum;
        contactForm: string;
        createdBy: string;
        creator: { __typename?: 'DbAgent'; firstName: string; lastName: string };
        document?: {
          __typename?: 'DbDocumentReference';
          contactId?: string | null;
          contentType: string;
          createdAt: any;
          createdBy: string;
          description?: string | null;
          fileName: string;
          teamId?: string | null;
          privacyLevel: PrivacyLevelEnum;
          referenceId: string;
          referenceType: EntityTypeEnum;
          sizeKb?: number | null;
          id: string;
          displayName: string;
          createdByAgent?: {
            __typename: 'DbAgent';
            createdAt: any;
            primaryShopId?: string | null;
            userName: string;
            salutation?: SalutationEnum | null;
            betaTester: boolean;
            energyAdvisoryEnabled: boolean;
            id: string;
            firstName: string;
            lastName: string;
            email: string;
            status: AgentStatusEnum;
            mobilePhoneNumber?: string | null;
            officePhoneNumber?: string | null;
            nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
            profiles: Array<{
              __typename?: 'DbAgentProfile';
              images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
            }>;
          } | null;
          lead?: {
            __typename?: 'DbLead';
            id: string;
            contactId: string;
            teamId?: string | null;
            intentType: IntentTypeEnum;
            createdAt: any;
            expiredAt?: any | null;
            status: LeadStatusEnum;
            timeFrame?: TimeFrameEnum | null;
            agentId?: string | null;
            shopId: string;
            personalNote?: string | null;
            go3PropertyId?: string | null;
            peLinkedPropertyId?: string | null;
            sellerRentOutSpecification?: {
              __typename?: 'DbSellerRentOutSpecification';
              type?: AssetTypeEnum | null;
              livingArea?: any | null;
              priceRangeTo?: any | null;
              priceRangeFrom?: any | null;
              priceRangeUnit: CurrencyEnum;
              rentPriceUnit: CurrencyEnum;
              plotArea?: any | null;
              address?: {
                __typename?: 'DbAddress';
                city?: string | null;
                postalCode?: string | null;
                fullAddress?: string | null;
              } | null;
            } | null;
            buyerToRentSpecification?: {
              __typename?: 'DbBuyerToRentSpecification';
              searchDemandId?: string | null;
              type?: AssetTypeEnum | null;
              city?: string | null;
              livingAreaMax?: number | null;
              livingAreaMin?: number | null;
              totalSurfaceMax?: number | null;
              totalSurfaceMin?: number | null;
              plotSurfaceMax?: number | null;
              plotSurfaceMin?: number | null;
              priceMax?: number | null;
              priceMin?: number | null;
              currency: CurrencyEnum;
              area?: string | null;
              country?: string | null;
            } | null;
            emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
            leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
          } | null;
        } | null;
      }>;
    } | null;
    phoneNumbers: Array<{
      __typename?: 'DbContactPhoneNumber';
      number: string;
      internationalNumber?: string | null;
      preferred: boolean;
      type: PhoneNumberTypeEnum;
    }>;
    preferredTimes: Array<{ __typename?: 'DbContactPreferredTime'; preferredContactTime: ContactTimeEnum }>;
    contactRelationships: Array<{
      __typename?: 'DbContactRelationship';
      relatedContactId: string;
      autoCc?: boolean | null;
      type: ContactRelationTypeEnum;
      relatedContact: {
        __typename?: 'DbContact';
        id: string;
        go3Utag?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        preferredLanguage?: LanguageEnum | null;
        blocked: boolean;
        phoneNumbers: Array<{ __typename?: 'DbContactPhoneNumber'; number: string; preferred: boolean }>;
        emailAddresses: Array<{ __typename?: 'DbContactEmailAddress'; address: string; preferred: boolean }>;
      };
    }>;
    contactGdpr?: { __typename?: 'DbContactGdpr'; agreedToGdpr?: boolean | null } | null;
    contactRenterInformations: Array<{
      __typename?: 'DbContactRenterInformation';
      contactId: string;
      householdType?: HouseholdTypeEnum | null;
      professionType?: ProfessionTypeEnum | null;
      petOwner?: boolean | null;
      monthlyIncomeMin?: number | null;
      monthlyIncomeMax?: number | null;
      currency: CurrencyEnum;
    }>;
    tags: Array<{ __typename?: 'DbContactTag'; tag: { __typename?: 'DbTag'; id: string; name: string } }>;
    preferredPhone: Array<{ __typename?: 'DbContactPhoneNumber'; number: string }>;
    preferredEmail: Array<{ __typename?: 'DbContactEmailAddress'; address: string }>;
    leadhubGo3ContactLinks: Array<{
      __typename?: 'DbLeadhubGo3ContactLink';
      firstIntentCategory: IntentCategoryEnum;
      secondIntentCategory?: IntentCategoryEnum | null;
      thirdIntentCategory?: IntentCategoryEnum | null;
      firstAgent: {
        __typename: 'DbAgent';
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      };
      secondAgent?: {
        __typename: 'DbAgent';
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      } | null;
      thirdAgent?: {
        __typename: 'DbAgent';
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      } | null;
    }>;
    emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
    shop: { __typename?: 'DbShop'; shopName: string };
    creator?: { __typename?: 'DbAgent'; firstName: string; lastName: string } | null;
  }>;
};

export type ContactsAndLeadsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DbContactOrderBy> | DbContactOrderBy>;
  where?: InputMaybe<DbContactBoolExp>;
}>;

export type ContactsAndLeadsQuery = {
  __typename?: 'query_root';
  dbContact: Array<{
    __typename?: 'DbContact';
    fullName?: string | null;
    salutation?: SalutationEnum | null;
    formattedSalutation?: string | null;
    title?: string | null;
    contactStatus: ContactStatusEnum;
    birthdate?: any | null;
    placeOfBirth?: string | null;
    nationality: CountryEnum;
    idNumber?: string | null;
    kycSearchId?: string | null;
    personalNote?: string | null;
    partnerFirstName?: string | null;
    partnerLastName?: string | null;
    deleted: boolean;
    createdAt: any;
    type: ContactTypeEnum;
    autoCc?: boolean | null;
    customerAccountId?: string | null;
    blocked: boolean;
    preferredLanguage?: LanguageEnum | null;
    shopId: string;
    id: string;
    go3Utag?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    leads: Array<{
      __typename?: 'DbLead';
      id: string;
      contactId: string;
      teamId?: string | null;
      intentType: IntentTypeEnum;
      createdAt: any;
      expiredAt?: any | null;
      status: LeadStatusEnum;
      timeFrame?: TimeFrameEnum | null;
      agentId?: string | null;
      shopId: string;
      personalNote?: string | null;
      go3PropertyId?: string | null;
      peLinkedPropertyId?: string | null;
      lastInteractionActivity?: {
        __typename?: 'DbBaseActivity';
        orderDate: any;
        category: BaseActivityCategoryEnum;
        type: ActivityTypeEnum;
        createdBy: string;
        createdAt: any;
        shopId?: string | null;
        taskActivity?: { __typename?: 'DbTaskActivity'; task: { __typename?: 'DbTask'; type: TaskTypeEnum } } | null;
        appointmentActivity?: {
          __typename?: 'DbAppointmentActivity';
          appointment: { __typename?: 'DbAppointment'; type: AppointmentTypeEnum };
        } | null;
      } | null;
      sellerRentOutSpecification?: {
        __typename?: 'DbSellerRentOutSpecification';
        type?: AssetTypeEnum | null;
        livingArea?: any | null;
        priceRangeTo?: any | null;
        priceRangeFrom?: any | null;
        priceRangeUnit: CurrencyEnum;
        rentPriceUnit: CurrencyEnum;
        plotArea?: any | null;
        address?: {
          __typename?: 'DbAddress';
          city?: string | null;
          postalCode?: string | null;
          fullAddress?: string | null;
        } | null;
      } | null;
      buyerToRentSpecification?: {
        __typename?: 'DbBuyerToRentSpecification';
        searchDemandId?: string | null;
        type?: AssetTypeEnum | null;
        city?: string | null;
        livingAreaMax?: number | null;
        livingAreaMin?: number | null;
        totalSurfaceMax?: number | null;
        totalSurfaceMin?: number | null;
        plotSurfaceMax?: number | null;
        plotSurfaceMin?: number | null;
        priceMax?: number | null;
        priceMin?: number | null;
        currency: CurrencyEnum;
        area?: string | null;
        country?: string | null;
      } | null;
      emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
      leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
      lastActivity: Array<{
        __typename?: 'DbBaseActivity';
        type: ActivityTypeEnum;
        createdBy: string;
        createdAt: any;
        shopId?: string | null;
      }>;
    }>;
    addresses: Array<{
      __typename?: 'DbContactAddress';
      preferred: boolean;
      type: AddressTypeEnum;
      address: {
        __typename?: 'DbAddress';
        id: string;
        city?: string | null;
        countryCode: CountryEnum;
        postalCode?: string | null;
        secondAddressLine?: string | null;
        streetName?: string | null;
        streetNumber?: string | null;
        fullAddress?: string | null;
        placeId?: string | null;
        country: { __typename?: 'EnumsCountry'; description?: string | null; name: string };
      };
    }>;
    emailAddresses: Array<{
      __typename?: 'DbContactEmailAddress';
      address: string;
      preferred: boolean;
      type: EmailAddressTypeEnum;
    }>;
    legalEntity?: { __typename?: 'DbLegalEntity'; department?: string | null; id: string; name: string } | null;
    customerAccount?: {
      __typename?: 'DbCustomerAccount';
      emailAddress: string;
      consents: Array<{
        __typename?: 'DbConsent';
        id: string;
        licenseId?: string | null;
        status: ConsentStatusEnum;
        consentType: ConsentTypeEnum;
        consentedOn?: any | null;
        updatedAt: any;
        revokedAt?: any | null;
        expiredAt?: any | null;
        consentSource: ConsentSourceEnum;
        contactForm: string;
        createdBy: string;
        creator: { __typename?: 'DbAgent'; firstName: string; lastName: string };
        document?: {
          __typename?: 'DbDocumentReference';
          contactId?: string | null;
          contentType: string;
          createdAt: any;
          createdBy: string;
          description?: string | null;
          fileName: string;
          teamId?: string | null;
          privacyLevel: PrivacyLevelEnum;
          referenceId: string;
          referenceType: EntityTypeEnum;
          sizeKb?: number | null;
          id: string;
          displayName: string;
          createdByAgent?: {
            __typename: 'DbAgent';
            createdAt: any;
            primaryShopId?: string | null;
            userName: string;
            salutation?: SalutationEnum | null;
            betaTester: boolean;
            energyAdvisoryEnabled: boolean;
            id: string;
            firstName: string;
            lastName: string;
            email: string;
            status: AgentStatusEnum;
            mobilePhoneNumber?: string | null;
            officePhoneNumber?: string | null;
            nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
            profiles: Array<{
              __typename?: 'DbAgentProfile';
              images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
            }>;
          } | null;
          lead?: {
            __typename?: 'DbLead';
            id: string;
            contactId: string;
            teamId?: string | null;
            intentType: IntentTypeEnum;
            createdAt: any;
            expiredAt?: any | null;
            status: LeadStatusEnum;
            timeFrame?: TimeFrameEnum | null;
            agentId?: string | null;
            shopId: string;
            personalNote?: string | null;
            go3PropertyId?: string | null;
            peLinkedPropertyId?: string | null;
            sellerRentOutSpecification?: {
              __typename?: 'DbSellerRentOutSpecification';
              type?: AssetTypeEnum | null;
              livingArea?: any | null;
              priceRangeTo?: any | null;
              priceRangeFrom?: any | null;
              priceRangeUnit: CurrencyEnum;
              rentPriceUnit: CurrencyEnum;
              plotArea?: any | null;
              address?: {
                __typename?: 'DbAddress';
                city?: string | null;
                postalCode?: string | null;
                fullAddress?: string | null;
              } | null;
            } | null;
            buyerToRentSpecification?: {
              __typename?: 'DbBuyerToRentSpecification';
              searchDemandId?: string | null;
              type?: AssetTypeEnum | null;
              city?: string | null;
              livingAreaMax?: number | null;
              livingAreaMin?: number | null;
              totalSurfaceMax?: number | null;
              totalSurfaceMin?: number | null;
              plotSurfaceMax?: number | null;
              plotSurfaceMin?: number | null;
              priceMax?: number | null;
              priceMin?: number | null;
              currency: CurrencyEnum;
              area?: string | null;
              country?: string | null;
            } | null;
            emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
            leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
          } | null;
        } | null;
      }>;
    } | null;
    phoneNumbers: Array<{
      __typename?: 'DbContactPhoneNumber';
      number: string;
      internationalNumber?: string | null;
      preferred: boolean;
      type: PhoneNumberTypeEnum;
    }>;
    preferredTimes: Array<{ __typename?: 'DbContactPreferredTime'; preferredContactTime: ContactTimeEnum }>;
    contactRelationships: Array<{
      __typename?: 'DbContactRelationship';
      relatedContactId: string;
      autoCc?: boolean | null;
      type: ContactRelationTypeEnum;
      relatedContact: {
        __typename?: 'DbContact';
        id: string;
        go3Utag?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        preferredLanguage?: LanguageEnum | null;
        blocked: boolean;
        phoneNumbers: Array<{ __typename?: 'DbContactPhoneNumber'; number: string; preferred: boolean }>;
        emailAddresses: Array<{ __typename?: 'DbContactEmailAddress'; address: string; preferred: boolean }>;
      };
    }>;
    contactGdpr?: { __typename?: 'DbContactGdpr'; agreedToGdpr?: boolean | null } | null;
    contactRenterInformations: Array<{
      __typename?: 'DbContactRenterInformation';
      contactId: string;
      householdType?: HouseholdTypeEnum | null;
      professionType?: ProfessionTypeEnum | null;
      petOwner?: boolean | null;
      monthlyIncomeMin?: number | null;
      monthlyIncomeMax?: number | null;
      currency: CurrencyEnum;
    }>;
    tags: Array<{ __typename?: 'DbContactTag'; tag: { __typename?: 'DbTag'; id: string; name: string } }>;
    preferredPhone: Array<{ __typename?: 'DbContactPhoneNumber'; number: string }>;
    preferredEmail: Array<{ __typename?: 'DbContactEmailAddress'; address: string }>;
    leadhubGo3ContactLinks: Array<{
      __typename?: 'DbLeadhubGo3ContactLink';
      firstIntentCategory: IntentCategoryEnum;
      secondIntentCategory?: IntentCategoryEnum | null;
      thirdIntentCategory?: IntentCategoryEnum | null;
      firstAgent: {
        __typename: 'DbAgent';
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      };
      secondAgent?: {
        __typename: 'DbAgent';
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      } | null;
      thirdAgent?: {
        __typename: 'DbAgent';
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      } | null;
    }>;
    emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
    shop: { __typename?: 'DbShop'; shopName: string };
    creator?: { __typename?: 'DbAgent'; firstName: string; lastName: string } | null;
  }>;
};

export type ContactsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DbContactOrderBy> | DbContactOrderBy>;
  where?: InputMaybe<DbContactBoolExp>;
}>;

export type ContactsQuery = {
  __typename?: 'query_root';
  dbContact: Array<{
    __typename?: 'DbContact';
    fullName?: string | null;
    salutation?: SalutationEnum | null;
    formattedSalutation?: string | null;
    title?: string | null;
    contactStatus: ContactStatusEnum;
    birthdate?: any | null;
    placeOfBirth?: string | null;
    nationality: CountryEnum;
    idNumber?: string | null;
    kycSearchId?: string | null;
    personalNote?: string | null;
    partnerFirstName?: string | null;
    partnerLastName?: string | null;
    deleted: boolean;
    createdAt: any;
    type: ContactTypeEnum;
    autoCc?: boolean | null;
    customerAccountId?: string | null;
    blocked: boolean;
    preferredLanguage?: LanguageEnum | null;
    shopId: string;
    id: string;
    go3Utag?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    addresses: Array<{
      __typename?: 'DbContactAddress';
      preferred: boolean;
      type: AddressTypeEnum;
      address: {
        __typename?: 'DbAddress';
        id: string;
        city?: string | null;
        countryCode: CountryEnum;
        postalCode?: string | null;
        secondAddressLine?: string | null;
        streetName?: string | null;
        streetNumber?: string | null;
        fullAddress?: string | null;
        placeId?: string | null;
        country: { __typename?: 'EnumsCountry'; description?: string | null; name: string };
      };
    }>;
    emailAddresses: Array<{
      __typename?: 'DbContactEmailAddress';
      address: string;
      preferred: boolean;
      type: EmailAddressTypeEnum;
    }>;
    legalEntity?: { __typename?: 'DbLegalEntity'; department?: string | null; id: string; name: string } | null;
    customerAccount?: {
      __typename?: 'DbCustomerAccount';
      emailAddress: string;
      consents: Array<{
        __typename?: 'DbConsent';
        id: string;
        licenseId?: string | null;
        status: ConsentStatusEnum;
        consentType: ConsentTypeEnum;
        consentedOn?: any | null;
        updatedAt: any;
        revokedAt?: any | null;
        expiredAt?: any | null;
        consentSource: ConsentSourceEnum;
        contactForm: string;
        createdBy: string;
        creator: { __typename?: 'DbAgent'; firstName: string; lastName: string };
        document?: {
          __typename?: 'DbDocumentReference';
          contactId?: string | null;
          contentType: string;
          createdAt: any;
          createdBy: string;
          description?: string | null;
          fileName: string;
          teamId?: string | null;
          privacyLevel: PrivacyLevelEnum;
          referenceId: string;
          referenceType: EntityTypeEnum;
          sizeKb?: number | null;
          id: string;
          displayName: string;
          createdByAgent?: {
            __typename: 'DbAgent';
            createdAt: any;
            primaryShopId?: string | null;
            userName: string;
            salutation?: SalutationEnum | null;
            betaTester: boolean;
            energyAdvisoryEnabled: boolean;
            id: string;
            firstName: string;
            lastName: string;
            email: string;
            status: AgentStatusEnum;
            mobilePhoneNumber?: string | null;
            officePhoneNumber?: string | null;
            nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
            profiles: Array<{
              __typename?: 'DbAgentProfile';
              images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
            }>;
          } | null;
          lead?: {
            __typename?: 'DbLead';
            id: string;
            contactId: string;
            teamId?: string | null;
            intentType: IntentTypeEnum;
            createdAt: any;
            expiredAt?: any | null;
            status: LeadStatusEnum;
            timeFrame?: TimeFrameEnum | null;
            agentId?: string | null;
            shopId: string;
            personalNote?: string | null;
            go3PropertyId?: string | null;
            peLinkedPropertyId?: string | null;
            sellerRentOutSpecification?: {
              __typename?: 'DbSellerRentOutSpecification';
              type?: AssetTypeEnum | null;
              livingArea?: any | null;
              priceRangeTo?: any | null;
              priceRangeFrom?: any | null;
              priceRangeUnit: CurrencyEnum;
              rentPriceUnit: CurrencyEnum;
              plotArea?: any | null;
              address?: {
                __typename?: 'DbAddress';
                city?: string | null;
                postalCode?: string | null;
                fullAddress?: string | null;
              } | null;
            } | null;
            buyerToRentSpecification?: {
              __typename?: 'DbBuyerToRentSpecification';
              searchDemandId?: string | null;
              type?: AssetTypeEnum | null;
              city?: string | null;
              livingAreaMax?: number | null;
              livingAreaMin?: number | null;
              totalSurfaceMax?: number | null;
              totalSurfaceMin?: number | null;
              plotSurfaceMax?: number | null;
              plotSurfaceMin?: number | null;
              priceMax?: number | null;
              priceMin?: number | null;
              currency: CurrencyEnum;
              area?: string | null;
              country?: string | null;
            } | null;
            emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
            leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
          } | null;
        } | null;
      }>;
    } | null;
    phoneNumbers: Array<{
      __typename?: 'DbContactPhoneNumber';
      number: string;
      internationalNumber?: string | null;
      preferred: boolean;
      type: PhoneNumberTypeEnum;
    }>;
    preferredTimes: Array<{ __typename?: 'DbContactPreferredTime'; preferredContactTime: ContactTimeEnum }>;
    contactRelationships: Array<{
      __typename?: 'DbContactRelationship';
      relatedContactId: string;
      autoCc?: boolean | null;
      type: ContactRelationTypeEnum;
      relatedContact: {
        __typename?: 'DbContact';
        id: string;
        go3Utag?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        preferredLanguage?: LanguageEnum | null;
        blocked: boolean;
        phoneNumbers: Array<{ __typename?: 'DbContactPhoneNumber'; number: string; preferred: boolean }>;
        emailAddresses: Array<{ __typename?: 'DbContactEmailAddress'; address: string; preferred: boolean }>;
      };
    }>;
    contactGdpr?: { __typename?: 'DbContactGdpr'; agreedToGdpr?: boolean | null } | null;
    contactRenterInformations: Array<{
      __typename?: 'DbContactRenterInformation';
      contactId: string;
      householdType?: HouseholdTypeEnum | null;
      professionType?: ProfessionTypeEnum | null;
      petOwner?: boolean | null;
      monthlyIncomeMin?: number | null;
      monthlyIncomeMax?: number | null;
      currency: CurrencyEnum;
    }>;
    tags: Array<{ __typename?: 'DbContactTag'; tag: { __typename?: 'DbTag'; id: string; name: string } }>;
    preferredPhone: Array<{ __typename?: 'DbContactPhoneNumber'; number: string }>;
    preferredEmail: Array<{ __typename?: 'DbContactEmailAddress'; address: string }>;
    leadhubGo3ContactLinks: Array<{
      __typename?: 'DbLeadhubGo3ContactLink';
      firstIntentCategory: IntentCategoryEnum;
      secondIntentCategory?: IntentCategoryEnum | null;
      thirdIntentCategory?: IntentCategoryEnum | null;
      firstAgent: {
        __typename: 'DbAgent';
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      };
      secondAgent?: {
        __typename: 'DbAgent';
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      } | null;
      thirdAgent?: {
        __typename: 'DbAgent';
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      } | null;
    }>;
    emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
    shop: { __typename?: 'DbShop'; shopName: string };
    creator?: { __typename?: 'DbAgent'; firstName: string; lastName: string } | null;
  }>;
};

export type ContactIdsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DbContactOrderBy> | DbContactOrderBy>;
  where?: InputMaybe<DbContactBoolExp>;
}>;

export type ContactIdsQuery = { __typename?: 'query_root'; dbContact: Array<{ __typename?: 'DbContact'; id: string }> };

export type ContactsListQueryVariables = Exact<{
  order_by?: InputMaybe<Array<DbContactOrderBy> | DbContactOrderBy>;
  where?: InputMaybe<DbContactBoolExp>;
}>;

export type ContactsListQuery = {
  __typename?: 'query_root';
  dbContact: Array<{
    __typename?: 'DbContact';
    createdAt: any;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    type: ContactTypeEnum;
    blocked: boolean;
    mostRecentActivityCreatedAt?: any | null;
    shopId: string;
    lastInteractionActivity?: {
      __typename?: 'DbBaseActivity';
      orderDate: any;
      category: BaseActivityCategoryEnum;
      type: ActivityTypeEnum;
      createdBy: string;
      createdAt: any;
      shopId?: string | null;
      taskActivity?: { __typename?: 'DbTaskActivity'; task: { __typename?: 'DbTask'; type: TaskTypeEnum } } | null;
      appointmentActivity?: {
        __typename?: 'DbAppointmentActivity';
        appointment: { __typename?: 'DbAppointment'; type: AppointmentTypeEnum };
      } | null;
    } | null;
    addresses: Array<{
      __typename?: 'DbContactAddress';
      preferred: boolean;
      type: AddressTypeEnum;
      address: {
        __typename?: 'DbAddress';
        id: string;
        city?: string | null;
        countryCode: CountryEnum;
        postalCode?: string | null;
        secondAddressLine?: string | null;
        streetName?: string | null;
        streetNumber?: string | null;
        fullAddress?: string | null;
        placeId?: string | null;
        country: { __typename?: 'EnumsCountry'; description?: string | null; name: string };
      };
    }>;
    contactGdpr?: {
      __typename?: 'DbContactGdpr';
      agreedToGdpr?: boolean | null;
      blockedDueToGdpr?: boolean | null;
    } | null;
    emailAddresses: Array<{
      __typename?: 'DbContactEmailAddress';
      address: string;
      preferred: boolean;
      type: EmailAddressTypeEnum;
    }>;
    lastActivity: Array<{
      __typename?: 'DbBaseActivity';
      type: ActivityTypeEnum;
      createdBy: string;
      createdAt: any;
      shopId?: string | null;
    }>;
    tags: Array<{ __typename?: 'DbContactTag'; tagId: string }>;
    leads: Array<{
      __typename?: 'DbLead';
      id: string;
      intentType: IntentTypeEnum;
      agentId?: string | null;
      agent?: { __typename?: 'DbAgent'; firstName: string; lastName: string } | null;
    }>;
    phoneNumbers: Array<{
      __typename?: 'DbContactPhoneNumber';
      number: string;
      preferred: boolean;
      internationalNumber?: string | null;
    }>;
  }>;
};

export type ContactsListCountQueryVariables = Exact<{
  where?: InputMaybe<DbContactBoolExp>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type ContactsListCountQuery = {
  __typename?: 'query_root';
  dbContactAggregate: {
    __typename?: 'DbContactAggregate';
    aggregate?: { __typename?: 'DbContactAggregateFields'; totalCount: number } | null;
  };
};

export type PublicContactQueryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DbContactDetailsOrderBy> | DbContactDetailsOrderBy>;
  where?: InputMaybe<DbContactDetailsBoolExp>;
}>;

export type PublicContactQueryQuery = {
  __typename?: 'query_root';
  dbContactDetails: Array<{
    __typename?: 'DbContactDetails';
    firstName?: string | null;
    lastName?: string | null;
    id?: string | null;
    contact?: {
      __typename?: 'DbContact';
      id: string;
      go3Utag?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      preferredLanguage?: LanguageEnum | null;
      blocked: boolean;
      phoneNumbers: Array<{ __typename?: 'DbContactPhoneNumber'; number: string; preferred: boolean }>;
      emailAddresses: Array<{ __typename?: 'DbContactEmailAddress'; address: string; preferred: boolean }>;
    } | null;
  }>;
};

export type ContactInfosQueryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DbContactOrderBy> | DbContactOrderBy>;
  where?: InputMaybe<DbContactBoolExp>;
}>;

export type ContactInfosQueryQuery = {
  __typename?: 'query_root';
  dbContact: Array<{
    __typename?: 'DbContact';
    id: string;
    go3Utag?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    preferredLanguage?: LanguageEnum | null;
    blocked: boolean;
    phoneNumbers: Array<{ __typename?: 'DbContactPhoneNumber'; number: string; preferred: boolean }>;
    emailAddresses: Array<{ __typename?: 'DbContactEmailAddress'; address: string; preferred: boolean }>;
  }>;
};

export type ContactInfoWithRelationshipsQueryQueryVariables = Exact<{
  contactId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<DbContactBoolExp>;
}>;

export type ContactInfoWithRelationshipsQueryQuery = {
  __typename?: 'query_root';
  dbContact: Array<{
    __typename?: 'DbContact';
    contactRelationships: Array<{
      __typename?: 'DbContactRelationship';
      type: ContactRelationTypeEnum;
      relatedContact: {
        __typename?: 'DbContact';
        id: string;
        go3Utag?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        preferredLanguage?: LanguageEnum | null;
        blocked: boolean;
        phoneNumbers: Array<{ __typename?: 'DbContactPhoneNumber'; number: string; preferred: boolean }>;
        emailAddresses: Array<{ __typename?: 'DbContactEmailAddress'; address: string; preferred: boolean }>;
      };
    }>;
  }>;
};

export type ContactTagsQueryVariables = Exact<{
  contactId: Scalars['String'];
}>;

export type ContactTagsQuery = {
  __typename?: 'query_root';
  dbContactTag: Array<{ __typename?: 'DbContactTag'; tag: { __typename?: 'DbTag'; name: string } }>;
};

export type ContactAllTagsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DbContactTagOrderBy> | DbContactTagOrderBy>;
  where?: InputMaybe<DbContactTagBoolExp>;
}>;

export type ContactAllTagsQuery = {
  __typename?: 'query_root';
  dbContactTag: Array<{ __typename?: 'DbContactTag'; tag: { __typename?: 'DbTag'; id: string; name: string } }>;
};

export type ContactsConnectedPropertiesQueryVariables = Exact<{
  ownerContactId: Scalars['String'];
  shopId: Scalars['PropertyEngineUUID'];
}>;

export type ContactsConnectedPropertiesQuery = {
  __typename?: 'query_root';
  propertyEngine?: {
    __typename?: 'propertyEngineQuery';
    searchListings?: {
      __typename?: 'PropertyEngineSearchListingsResponse';
      total?: number | null;
      listings?: Array<{
        __typename?: 'PropertyEngineListing';
        assignedAgentId?: string | null;
        assignedTeamId?: string | null;
        id: string;
        lastActivation?: any | null;
        listingOwnerContactId?: string | null;
        status: PropertyEngineListingStatus;
        type: PropertyEngineListingType;
        utag: string;
        address?: {
          __typename?: 'PropertyEngineAddress';
          streetName?: string | null;
          streetNumber?: string | null;
        } | null;
      }> | null;
    } | null;
  } | null;
};

export type CustomDemandFieldsQueryVariables = Exact<{
  where?: InputMaybe<DbCdfBoolExp>;
}>;

export type CustomDemandFieldsQuery = {
  __typename?: 'query_root';
  dbCdf: Array<{ __typename?: 'DbCdf'; id: string; label: string; shopId: string }>;
};

export type CustomViewsListQueryVariables = Exact<{
  activeShopId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CustomViewTypeEnum>;
}>;

export type CustomViewsListQuery = {
  __typename?: 'query_root';
  dbCustomListView: Array<{
    __typename?: 'DbCustomListView';
    id: string;
    name: string;
    shownByDefault?: boolean | null;
    type: CustomViewTypeEnum;
    viewData: any;
    viewIndex: number;
  }>;
};

export type AgentDashboardActivityQueryVariables = Exact<{
  order_by?: InputMaybe<Array<DbBaseActivityOrderBy> | DbBaseActivityOrderBy>;
  where?: InputMaybe<DbBaseActivityBoolExp>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type AgentDashboardActivityQuery = {
  __typename?: 'query_root';
  dbBaseActivity: Array<{
    __typename?: 'DbBaseActivity';
    id: string;
    category: BaseActivityCategoryEnum;
    leadId?: string | null;
    contactId?: string | null;
    type: ActivityTypeEnum;
    createdBy: string;
    createdAt: any;
    shopId?: string | null;
    contact?: { __typename?: 'DbContact'; firstName?: string | null; lastName?: string | null } | null;
    taskActivity?: {
      __typename?: 'DbTaskActivity';
      task: {
        __typename?: 'DbTask';
        id: string;
        type: TaskTypeEnum;
        dueDatetime?: any | null;
        title?: string | null;
        status?: TaskAppointmentStatusEnum | null;
        agentId: string;
        teamId?: string | null;
        comment?: string | null;
        propertyIds: Array<string>;
        priority?: PriorityEnum | null;
        go3TaskKey?: number | null;
        taskContacts: Array<{ __typename?: 'DbTaskContact'; contactId: string }>;
        taskLeads: Array<{ __typename?: 'DbTaskLead'; leadId: string }>;
        taskAgents: Array<{
          __typename?: 'DbTaskAgent';
          agent: {
            __typename: 'DbAgent';
            id: string;
            firstName: string;
            lastName: string;
            email: string;
            status: AgentStatusEnum;
            mobilePhoneNumber?: string | null;
            officePhoneNumber?: string | null;
            profiles: Array<{
              __typename?: 'DbAgentProfile';
              images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
            }>;
          };
        }>;
      };
    } | null;
    appointmentActivity?: {
      __typename?: 'DbAppointmentActivity';
      createdAt: any;
      appointment: {
        __typename?: 'DbAppointment';
        id: string;
        type: AppointmentTypeEnum;
        startTime: any;
        endTime?: any | null;
        location?: string | null;
        subject?: string | null;
        status?: TaskAppointmentStatusEnum | null;
        agentId: string;
        teamId?: string | null;
        comment?: string | null;
        feedback?: string | null;
        cost?: any | null;
        propertyIds: Array<string>;
        go3AppointmentKey?: number | null;
        agent: { __typename?: 'DbAgent'; firstName: string; lastName: string };
        appointmentContacts: Array<{ __typename?: 'DbAppointmentContact'; contactId: string }>;
        appointmentLeads: Array<{ __typename?: 'DbAppointmentLead'; leadId: string }>;
        appointmentAgents: Array<{
          __typename?: 'DbAppointmentAgent';
          agent: {
            __typename: 'DbAgent';
            id: string;
            firstName: string;
            lastName: string;
            email: string;
            status: AgentStatusEnum;
            mobilePhoneNumber?: string | null;
            officePhoneNumber?: string | null;
            profiles: Array<{
              __typename?: 'DbAgentProfile';
              images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
            }>;
          };
        }>;
      };
    } | null;
  }>;
};

export type DocumentsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DbDocumentReferenceOrderBy> | DbDocumentReferenceOrderBy>;
  where?: InputMaybe<DbDocumentReferenceBoolExp>;
}>;

export type DocumentsQuery = {
  __typename?: 'query_root';
  dbDocumentReference: Array<{
    __typename?: 'DbDocumentReference';
    contactId?: string | null;
    contentType: string;
    createdAt: any;
    createdBy: string;
    description?: string | null;
    fileName: string;
    teamId?: string | null;
    privacyLevel: PrivacyLevelEnum;
    referenceId: string;
    referenceType: EntityTypeEnum;
    sizeKb?: number | null;
    id: string;
    displayName: string;
    createdByAgent?: {
      __typename: 'DbAgent';
      createdAt: any;
      primaryShopId?: string | null;
      userName: string;
      salutation?: SalutationEnum | null;
      betaTester: boolean;
      energyAdvisoryEnabled: boolean;
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
    lead?: {
      __typename?: 'DbLead';
      id: string;
      contactId: string;
      teamId?: string | null;
      intentType: IntentTypeEnum;
      createdAt: any;
      expiredAt?: any | null;
      status: LeadStatusEnum;
      timeFrame?: TimeFrameEnum | null;
      agentId?: string | null;
      shopId: string;
      personalNote?: string | null;
      go3PropertyId?: string | null;
      peLinkedPropertyId?: string | null;
      sellerRentOutSpecification?: {
        __typename?: 'DbSellerRentOutSpecification';
        type?: AssetTypeEnum | null;
        livingArea?: any | null;
        priceRangeTo?: any | null;
        priceRangeFrom?: any | null;
        priceRangeUnit: CurrencyEnum;
        rentPriceUnit: CurrencyEnum;
        plotArea?: any | null;
        address?: {
          __typename?: 'DbAddress';
          city?: string | null;
          postalCode?: string | null;
          fullAddress?: string | null;
        } | null;
      } | null;
      buyerToRentSpecification?: {
        __typename?: 'DbBuyerToRentSpecification';
        searchDemandId?: string | null;
        type?: AssetTypeEnum | null;
        city?: string | null;
        livingAreaMax?: number | null;
        livingAreaMin?: number | null;
        totalSurfaceMax?: number | null;
        totalSurfaceMin?: number | null;
        plotSurfaceMax?: number | null;
        plotSurfaceMin?: number | null;
        priceMax?: number | null;
        priceMin?: number | null;
        currency: CurrencyEnum;
        area?: string | null;
        country?: string | null;
      } | null;
      emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
      leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
    } | null;
  }>;
};

export type DocumentsCountQueryVariables = Exact<{
  where?: InputMaybe<DbDocumentReferenceBoolExp>;
}>;

export type DocumentsCountQuery = {
  __typename?: 'query_root';
  dbDocumentReferenceAggregate: {
    __typename?: 'DbDocumentReferenceAggregate';
    aggregate?: { __typename?: 'DbDocumentReferenceAggregateFields'; totalCount: number } | null;
  };
};

export type DocumentsInfoQueryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DbDocumentReferenceOrderBy> | DbDocumentReferenceOrderBy>;
  where?: InputMaybe<DbDocumentReferenceBoolExp>;
}>;

export type DocumentsInfoQueryQuery = {
  __typename?: 'query_root';
  dbDocumentReference: Array<{ __typename?: 'DbDocumentReference'; id: string; displayName: string }>;
};

export type DocumentContactIdsQueryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DbBaseActivityOrderBy> | DbBaseActivityOrderBy>;
  where?: InputMaybe<DbBaseActivityBoolExp>;
}>;

export type DocumentContactIdsQueryQuery = {
  __typename?: 'query_root';
  dbBaseActivity: Array<{
    __typename?: 'DbBaseActivity';
    emailActivity?: { __typename?: 'DbEmailActivity'; emailId: string } | null;
    printedDocumentActivity?: { __typename?: 'DbPrintedDocumentActivity'; printedDocumentId?: string | null } | null;
    appointmentActivity?: { __typename?: 'DbAppointmentActivity'; appointmentId: string } | null;
  }>;
};

export type DuplicateContactGroupIdsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DbPotentialDuplicateContactOrderBy> | DbPotentialDuplicateContactOrderBy>;
  where?: InputMaybe<DbPotentialDuplicateContactBoolExp>;
  distinctOn?: InputMaybe<Array<DbPotentialDuplicateContactSelectColumn> | DbPotentialDuplicateContactSelectColumn>;
}>;

export type DuplicateContactGroupIdsQuery = {
  __typename?: 'query_root';
  dbPotentialDuplicateContact: Array<{ __typename?: 'DbPotentialDuplicateContact'; groupId: string }>;
};

export type DuplicateContactQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DbPotentialDuplicateContactOrderBy> | DbPotentialDuplicateContactOrderBy>;
  where?: InputMaybe<DbPotentialDuplicateContactBoolExp>;
  distinctOn?: InputMaybe<Array<DbPotentialDuplicateContactSelectColumn> | DbPotentialDuplicateContactSelectColumn>;
}>;

export type DuplicateContactQuery = {
  __typename?: 'query_root';
  dbPotentialDuplicateContact: Array<{
    __typename?: 'DbPotentialDuplicateContact';
    createdAt: any;
    groupId: string;
    matchedEmail?: Array<string> | null;
    matchedPhone?: Array<string> | null;
    contact: {
      __typename?: 'DbContact';
      contactStatus: ContactStatusEnum;
      createdAt: any;
      fullName?: string | null;
      id: string;
      blocked: boolean;
      lastInteractionActivity?: {
        __typename?: 'DbBaseActivity';
        orderDate: any;
        category: BaseActivityCategoryEnum;
        type: ActivityTypeEnum;
        createdBy: string;
        createdAt: any;
        shopId?: string | null;
        taskActivity?: { __typename?: 'DbTaskActivity'; task: { __typename?: 'DbTask'; type: TaskTypeEnum } } | null;
        appointmentActivity?: {
          __typename?: 'DbAppointmentActivity';
          appointment: { __typename?: 'DbAppointment'; type: AppointmentTypeEnum };
        } | null;
      } | null;
      leads: Array<{
        __typename?: 'DbLead';
        id: string;
        contactId: string;
        teamId?: string | null;
        intentType: IntentTypeEnum;
        createdAt: any;
        expiredAt?: any | null;
        status: LeadStatusEnum;
        timeFrame?: TimeFrameEnum | null;
        agentId?: string | null;
        shopId: string;
        personalNote?: string | null;
        go3PropertyId?: string | null;
        peLinkedPropertyId?: string | null;
        sellerRentOutSpecification?: {
          __typename?: 'DbSellerRentOutSpecification';
          type?: AssetTypeEnum | null;
          livingArea?: any | null;
          priceRangeTo?: any | null;
          priceRangeFrom?: any | null;
          priceRangeUnit: CurrencyEnum;
          rentPriceUnit: CurrencyEnum;
          plotArea?: any | null;
          address?: {
            __typename?: 'DbAddress';
            city?: string | null;
            postalCode?: string | null;
            fullAddress?: string | null;
          } | null;
        } | null;
        buyerToRentSpecification?: {
          __typename?: 'DbBuyerToRentSpecification';
          searchDemandId?: string | null;
          type?: AssetTypeEnum | null;
          city?: string | null;
          livingAreaMax?: number | null;
          livingAreaMin?: number | null;
          totalSurfaceMax?: number | null;
          totalSurfaceMin?: number | null;
          plotSurfaceMax?: number | null;
          plotSurfaceMin?: number | null;
          priceMax?: number | null;
          priceMin?: number | null;
          currency: CurrencyEnum;
          area?: string | null;
          country?: string | null;
        } | null;
        emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
        leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
        lastActivity: Array<{
          __typename?: 'DbBaseActivity';
          type: ActivityTypeEnum;
          createdBy: string;
          createdAt: any;
          shopId?: string | null;
        }>;
      }>;
      emailAddresses: Array<{
        __typename?: 'DbContactEmailAddress';
        address: string;
        normalizedAddress?: string | null;
        preferred: boolean;
        type: EmailAddressTypeEnum;
      }>;
      phoneNumbers: Array<{
        __typename?: 'DbContactPhoneNumber';
        number: string;
        internationalNumber?: string | null;
        normalizedLocalNumber?: string | null;
        preferred: boolean;
        type: PhoneNumberTypeEnum;
      }>;
      lastActivity: Array<{
        __typename?: 'DbBaseActivity';
        type: ActivityTypeEnum;
        createdBy: string;
        createdAt: any;
        shopId?: string | null;
      }>;
    };
  }>;
};

export type DuplicateContactCountQueryVariables = Exact<{
  where?: InputMaybe<DbPotentialDuplicateContactBoolExp>;
  distinctOn?: InputMaybe<Array<DbPotentialDuplicateContactSelectColumn> | DbPotentialDuplicateContactSelectColumn>;
}>;

export type DuplicateContactCountQuery = {
  __typename?: 'query_root';
  dbPotentialDuplicateContactAggregate: {
    __typename?: 'DbPotentialDuplicateContactAggregate';
    aggregate?: { __typename?: 'DbPotentialDuplicateContactAggregateFields'; totalCount: number } | null;
  };
};

export type EmailLinksQueryQueryVariables = Exact<{
  where?: InputMaybe<DbEmailLinkBoolExp>;
  contactRelationshipWhere?: InputMaybe<DbContactRelationshipBoolExp>;
}>;

export type EmailLinksQueryQuery = {
  __typename?: 'query_root';
  dbEmailLink: Array<{
    __typename?: 'DbEmailLink';
    threadId?: string | null;
    contactId: string;
    leadId?: string | null;
    contact: {
      __typename?: 'DbContact';
      fullName?: string | null;
      salutation?: SalutationEnum | null;
      formattedSalutation?: string | null;
      title?: string | null;
      contactStatus: ContactStatusEnum;
      birthdate?: any | null;
      placeOfBirth?: string | null;
      nationality: CountryEnum;
      idNumber?: string | null;
      kycSearchId?: string | null;
      personalNote?: string | null;
      partnerFirstName?: string | null;
      partnerLastName?: string | null;
      deleted: boolean;
      createdAt: any;
      type: ContactTypeEnum;
      autoCc?: boolean | null;
      customerAccountId?: string | null;
      blocked: boolean;
      preferredLanguage?: LanguageEnum | null;
      shopId: string;
      id: string;
      go3Utag?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      leads: Array<{
        __typename?: 'DbLead';
        id: string;
        contactId: string;
        teamId?: string | null;
        intentType: IntentTypeEnum;
        createdAt: any;
        expiredAt?: any | null;
        status: LeadStatusEnum;
        timeFrame?: TimeFrameEnum | null;
        agentId?: string | null;
        shopId: string;
        personalNote?: string | null;
        go3PropertyId?: string | null;
        peLinkedPropertyId?: string | null;
        lastInteractionActivity?: {
          __typename?: 'DbBaseActivity';
          orderDate: any;
          category: BaseActivityCategoryEnum;
          type: ActivityTypeEnum;
          createdBy: string;
          createdAt: any;
          shopId?: string | null;
          taskActivity?: { __typename?: 'DbTaskActivity'; task: { __typename?: 'DbTask'; type: TaskTypeEnum } } | null;
          appointmentActivity?: {
            __typename?: 'DbAppointmentActivity';
            appointment: { __typename?: 'DbAppointment'; type: AppointmentTypeEnum };
          } | null;
        } | null;
        sellerRentOutSpecification?: {
          __typename?: 'DbSellerRentOutSpecification';
          type?: AssetTypeEnum | null;
          livingArea?: any | null;
          priceRangeTo?: any | null;
          priceRangeFrom?: any | null;
          priceRangeUnit: CurrencyEnum;
          rentPriceUnit: CurrencyEnum;
          plotArea?: any | null;
          address?: {
            __typename?: 'DbAddress';
            city?: string | null;
            postalCode?: string | null;
            fullAddress?: string | null;
          } | null;
        } | null;
        buyerToRentSpecification?: {
          __typename?: 'DbBuyerToRentSpecification';
          searchDemandId?: string | null;
          type?: AssetTypeEnum | null;
          city?: string | null;
          livingAreaMax?: number | null;
          livingAreaMin?: number | null;
          totalSurfaceMax?: number | null;
          totalSurfaceMin?: number | null;
          plotSurfaceMax?: number | null;
          plotSurfaceMin?: number | null;
          priceMax?: number | null;
          priceMin?: number | null;
          currency: CurrencyEnum;
          area?: string | null;
          country?: string | null;
        } | null;
        emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
        leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
        lastActivity: Array<{
          __typename?: 'DbBaseActivity';
          type: ActivityTypeEnum;
          createdBy: string;
          createdAt: any;
          shopId?: string | null;
        }>;
      }>;
      addresses: Array<{
        __typename?: 'DbContactAddress';
        preferred: boolean;
        type: AddressTypeEnum;
        address: {
          __typename?: 'DbAddress';
          id: string;
          city?: string | null;
          countryCode: CountryEnum;
          postalCode?: string | null;
          secondAddressLine?: string | null;
          streetName?: string | null;
          streetNumber?: string | null;
          fullAddress?: string | null;
          placeId?: string | null;
          country: { __typename?: 'EnumsCountry'; description?: string | null; name: string };
        };
      }>;
      emailAddresses: Array<{
        __typename?: 'DbContactEmailAddress';
        address: string;
        preferred: boolean;
        type: EmailAddressTypeEnum;
      }>;
      legalEntity?: { __typename?: 'DbLegalEntity'; department?: string | null; id: string; name: string } | null;
      customerAccount?: {
        __typename?: 'DbCustomerAccount';
        emailAddress: string;
        consents: Array<{
          __typename?: 'DbConsent';
          id: string;
          licenseId?: string | null;
          status: ConsentStatusEnum;
          consentType: ConsentTypeEnum;
          consentedOn?: any | null;
          updatedAt: any;
          revokedAt?: any | null;
          expiredAt?: any | null;
          consentSource: ConsentSourceEnum;
          contactForm: string;
          createdBy: string;
          creator: { __typename?: 'DbAgent'; firstName: string; lastName: string };
          document?: {
            __typename?: 'DbDocumentReference';
            contactId?: string | null;
            contentType: string;
            createdAt: any;
            createdBy: string;
            description?: string | null;
            fileName: string;
            teamId?: string | null;
            privacyLevel: PrivacyLevelEnum;
            referenceId: string;
            referenceType: EntityTypeEnum;
            sizeKb?: number | null;
            id: string;
            displayName: string;
            createdByAgent?: {
              __typename: 'DbAgent';
              createdAt: any;
              primaryShopId?: string | null;
              userName: string;
              salutation?: SalutationEnum | null;
              betaTester: boolean;
              energyAdvisoryEnabled: boolean;
              id: string;
              firstName: string;
              lastName: string;
              email: string;
              status: AgentStatusEnum;
              mobilePhoneNumber?: string | null;
              officePhoneNumber?: string | null;
              nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
              profiles: Array<{
                __typename?: 'DbAgentProfile';
                images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
              }>;
            } | null;
            lead?: {
              __typename?: 'DbLead';
              id: string;
              contactId: string;
              teamId?: string | null;
              intentType: IntentTypeEnum;
              createdAt: any;
              expiredAt?: any | null;
              status: LeadStatusEnum;
              timeFrame?: TimeFrameEnum | null;
              agentId?: string | null;
              shopId: string;
              personalNote?: string | null;
              go3PropertyId?: string | null;
              peLinkedPropertyId?: string | null;
              sellerRentOutSpecification?: {
                __typename?: 'DbSellerRentOutSpecification';
                type?: AssetTypeEnum | null;
                livingArea?: any | null;
                priceRangeTo?: any | null;
                priceRangeFrom?: any | null;
                priceRangeUnit: CurrencyEnum;
                rentPriceUnit: CurrencyEnum;
                plotArea?: any | null;
                address?: {
                  __typename?: 'DbAddress';
                  city?: string | null;
                  postalCode?: string | null;
                  fullAddress?: string | null;
                } | null;
              } | null;
              buyerToRentSpecification?: {
                __typename?: 'DbBuyerToRentSpecification';
                searchDemandId?: string | null;
                type?: AssetTypeEnum | null;
                city?: string | null;
                livingAreaMax?: number | null;
                livingAreaMin?: number | null;
                totalSurfaceMax?: number | null;
                totalSurfaceMin?: number | null;
                plotSurfaceMax?: number | null;
                plotSurfaceMin?: number | null;
                priceMax?: number | null;
                priceMin?: number | null;
                currency: CurrencyEnum;
                area?: string | null;
                country?: string | null;
              } | null;
              emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
              leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
            } | null;
          } | null;
        }>;
      } | null;
      phoneNumbers: Array<{
        __typename?: 'DbContactPhoneNumber';
        number: string;
        internationalNumber?: string | null;
        preferred: boolean;
        type: PhoneNumberTypeEnum;
      }>;
      preferredTimes: Array<{ __typename?: 'DbContactPreferredTime'; preferredContactTime: ContactTimeEnum }>;
      contactRelationships: Array<{
        __typename?: 'DbContactRelationship';
        relatedContactId: string;
        autoCc?: boolean | null;
        type: ContactRelationTypeEnum;
        relatedContact: {
          __typename?: 'DbContact';
          id: string;
          go3Utag?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          preferredLanguage?: LanguageEnum | null;
          blocked: boolean;
          phoneNumbers: Array<{ __typename?: 'DbContactPhoneNumber'; number: string; preferred: boolean }>;
          emailAddresses: Array<{ __typename?: 'DbContactEmailAddress'; address: string; preferred: boolean }>;
        };
      }>;
      contactGdpr?: { __typename?: 'DbContactGdpr'; agreedToGdpr?: boolean | null } | null;
      contactRenterInformations: Array<{
        __typename?: 'DbContactRenterInformation';
        contactId: string;
        householdType?: HouseholdTypeEnum | null;
        professionType?: ProfessionTypeEnum | null;
        petOwner?: boolean | null;
        monthlyIncomeMin?: number | null;
        monthlyIncomeMax?: number | null;
        currency: CurrencyEnum;
      }>;
      tags: Array<{ __typename?: 'DbContactTag'; tag: { __typename?: 'DbTag'; id: string; name: string } }>;
      preferredPhone: Array<{ __typename?: 'DbContactPhoneNumber'; number: string }>;
      preferredEmail: Array<{ __typename?: 'DbContactEmailAddress'; address: string }>;
      leadhubGo3ContactLinks: Array<{
        __typename?: 'DbLeadhubGo3ContactLink';
        firstIntentCategory: IntentCategoryEnum;
        secondIntentCategory?: IntentCategoryEnum | null;
        thirdIntentCategory?: IntentCategoryEnum | null;
        firstAgent: {
          __typename: 'DbAgent';
          id: string;
          firstName: string;
          lastName: string;
          email: string;
          status: AgentStatusEnum;
          mobilePhoneNumber?: string | null;
          officePhoneNumber?: string | null;
          profiles: Array<{
            __typename?: 'DbAgentProfile';
            images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
          }>;
        };
        secondAgent?: {
          __typename: 'DbAgent';
          id: string;
          firstName: string;
          lastName: string;
          email: string;
          status: AgentStatusEnum;
          mobilePhoneNumber?: string | null;
          officePhoneNumber?: string | null;
          profiles: Array<{
            __typename?: 'DbAgentProfile';
            images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
          }>;
        } | null;
        thirdAgent?: {
          __typename: 'DbAgent';
          id: string;
          firstName: string;
          lastName: string;
          email: string;
          status: AgentStatusEnum;
          mobilePhoneNumber?: string | null;
          officePhoneNumber?: string | null;
          profiles: Array<{
            __typename?: 'DbAgentProfile';
            images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
          }>;
        } | null;
      }>;
      emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
      shop: { __typename?: 'DbShop'; shopName: string };
      creator?: { __typename?: 'DbAgent'; firstName: string; lastName: string } | null;
    };
  }>;
};

export type EmailLinkThreadIdQueryQueryVariables = Exact<{
  where?: InputMaybe<DbEmailLinkBoolExp>;
}>;

export type EmailLinkThreadIdQueryQuery = {
  __typename?: 'query_root';
  dbEmailLink: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
};

export type EmailPlaceholderQueryQueryVariables = Exact<{
  where?: InputMaybe<DbEmailPlaceholderBoolExp>;
}>;

export type EmailPlaceholderQueryQuery = {
  __typename?: 'query_root';
  dbEmailPlaceholder: Array<{
    __typename?: 'DbEmailPlaceholder';
    name: string;
    mapping: string;
    scope: EmailPlaceholderScopeEnum;
    type: EmailPlaceholderTypeEnum;
  }>;
};

export type EmailSignaturesQueryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DbEmailSignatureOrderBy> | DbEmailSignatureOrderBy>;
  where?: InputMaybe<DbEmailSignatureBoolExp>;
  agent_id?: InputMaybe<Scalars['String']>;
  shop_id?: InputMaybe<Scalars['String']>;
}>;

export type EmailSignaturesQueryQuery = {
  __typename?: 'query_root';
  dbEmailSignature: Array<{
    __typename?: 'DbEmailSignature';
    id: string;
    scope: EmailSignatureScopeEnum;
    name: string;
    signature: string;
    agentId?: string | null;
    shopId?: string | null;
    teamId?: string | null;
    createdAt: any;
    updatedAt: any;
    isDefault: {
      __typename?: 'DbAgentDefaultEmailSignatureAggregate';
      aggregate?: { __typename?: 'DbAgentDefaultEmailSignatureAggregateFields'; count: number } | null;
    };
  }>;
};

export type EmailTemplatesQueryQueryVariables = Exact<{
  order_by?: InputMaybe<Array<DbEmailTemplateOrderBy> | DbEmailTemplateOrderBy>;
  where?: InputMaybe<DbEmailTemplateBoolExp>;
}>;

export type EmailTemplatesQueryQuery = {
  __typename?: 'query_root';
  dbEmailTemplate: Array<{
    __typename?: 'DbEmailTemplate';
    id: string;
    scope: EmailTemplateScopeEnum;
    name: string;
    type: EmailTemplateTypeEnum;
    agentId?: string | null;
    shopId?: string | null;
    teamId?: string | null;
    createdAt: any;
    updatedAt: any;
    contents: Array<{ __typename?: 'DbEmailTemplateContent'; language: LanguageEnum; subject: string; body: string }>;
  }>;
};

export type EmailTrackingQueryQueryVariables = Exact<{
  where?: InputMaybe<DbEmailTrackingBoolExp>;
}>;

export type EmailTrackingQueryQuery = {
  __typename?: 'query_root';
  dbEmailTracking: Array<{
    __typename?: 'DbEmailTracking';
    id: string;
    emailId: string;
    type: EmailTrackingTypeEnum;
    linkUrl?: string | null;
    count: number;
    updatedAt: any;
    email?: { __typename?: 'DbEmailMessageMap'; messageId?: string | null } | null;
  }>;
};

export type GetEnableFeaturesQueryVariables = Exact<{ [key: string]: never }>;

export type GetEnableFeaturesQuery = {
  __typename?: 'query_root';
  dbEnabledFeatureFlags: Array<{ __typename?: 'DbEnabledFeatureFlags'; enabledFeature: FeatureFlagsEnum }>;
};

export type FailedLeadImportsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type FailedLeadImportsQuery = {
  __typename?: 'query_root';
  dbImportLeadRequest: Array<{
    __typename?: 'DbImportLeadRequest';
    id: string;
    createdAt: any;
    payloadType: string;
    requestPayload: string;
    importLeadStatus: ImportLeadStatusEnum;
    errorMessage?: string | null;
    stackTrace?: string | null;
    importedLeadId?: string | null;
  }>;
};

export type FailedLeadImportsTotalCountQueryVariables = Exact<{ [key: string]: never }>;

export type FailedLeadImportsTotalCountQuery = {
  __typename?: 'query_root';
  dbImportLeadRequestAggregate: {
    __typename?: 'DbImportLeadRequestAggregate';
    aggregate?: { __typename?: 'DbImportLeadRequestAggregateFields'; totalCount: number } | null;
  };
};

export type FindProposedListingsQueryQueryVariables = Exact<{
  contactId: Scalars['String'];
  matchingState?: InputMaybe<PropertyEngineMatchingState>;
  searchDemandIds?: InputMaybe<Array<Scalars['PropertyEngineUUID']> | Scalars['PropertyEngineUUID']>;
  addressQuery?: InputMaybe<Scalars['String']>;
  listingType?: InputMaybe<PropertyEngineListingType>;
  matchingStatusOrder?: InputMaybe<PropertyEngineMatchingStatusOrder>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
}>;

export type FindProposedListingsQueryQuery = {
  __typename?: 'query_root';
  propertyEngine?: {
    __typename?: 'propertyEngineQuery';
    findProposedListings: {
      __typename?: 'PropertyEngineProposedListingsResponse';
      total: number;
      proposedListings: Array<{
        __typename?: 'PropertyEngineProposedListing';
        matchingState: PropertyEngineMatchingState;
        manuallyProposed: boolean;
        feedback?: string | null;
        createdAt: string;
        listing: {
          __typename?: 'PropertyEngineListing';
          utag: string;
          type: PropertyEngineListingType;
          status: PropertyEngineListingStatus;
          cdfs?: Array<string | null> | null;
          assignedAgentId?: string | null;
          assignedTeamId?: string | null;
          currency?: string | null;
          district?: string | null;
          area?: string | null;
          fileAs?: string | null;
          monthlyNetRent?: number | null;
          monthlyTotalRent?: number | null;
          monthlyUtilities?: number | null;
          askingPrice?: number | null;
          lastActivation?: any | null;
          listingOwnerContactId?: string | null;
          asset:
            | {
                __typename?: 'PropertyEngineApartment';
                type?: PropertyEngineAssetType | null;
                subType?: PropertyEngineAssetSubType | null;
                variation?: PropertyEngineAssetVariation | null;
                numberOfRooms?: number | null;
                numberOfBedrooms?: number | null;
                numberOfBathrooms?: number | null;
                totalSurface?: number | null;
                surfaceLivingArea?: number | null;
              }
            | {
                __typename?: 'PropertyEngineHouse';
                type?: PropertyEngineAssetType | null;
                subType?: PropertyEngineAssetSubType | null;
                variation?: PropertyEngineAssetVariation | null;
                numberOfRooms?: number | null;
                numberOfBedrooms?: number | null;
                numberOfBathrooms?: number | null;
                totalSurface?: number | null;
                surfaceLivingArea?: number | null;
              }
            | {
                __typename?: 'PropertyEngineLand';
                type?: PropertyEngineAssetType | null;
                subType?: PropertyEngineAssetSubType | null;
                variation?: PropertyEngineAssetVariation | null;
                plotSurface?: number | null;
              };
          address?: {
            __typename?: 'PropertyEngineAddress';
            city?: string | null;
            postalCode?: string | null;
            coverLetterAddress?: string | null;
            streetName?: string | null;
            streetNumber?: string | null;
            countryCode?: PropertyEngineCountryCode | null;
            latitude?: number | null;
            longitude?: number | null;
          } | null;
          previewImage?: { __typename?: 'PropertyEngineProxyImageDetail'; imageUrl: string } | null;
          marketingDetails: Array<{
            __typename?: 'PropertyEngineMarketingDetail';
            language: PropertyEngineLanguage;
            title: string;
            premiumExposeEmail?: {
              __typename?: 'PropertyEnginePremiumExposeEmail';
              subject?: string | null;
              description?: string | null;
              bulletPoints?: Array<string | null> | null;
              bulletPointsTitle?: string | null;
            } | null;
          }>;
          internetProfile: {
            __typename?: 'PropertyEngineInternetProfile';
            priceOnRequest?: boolean | null;
            portals?: Array<{
              __typename?: 'PropertyEngineListingInternetPortal';
              includeInExport?: boolean | null;
            }> | null;
          };
        };
      }>;
    };
  } | null;
};

export type FindProposedListingsCountQueryQueryVariables = Exact<{
  contactId: Scalars['String'];
}>;

export type FindProposedListingsCountQueryQuery = {
  __typename?: 'query_root';
  propertyEngine?: {
    __typename?: 'propertyEngineQuery';
    findProposedListings: { __typename?: 'PropertyEngineProposedListingsResponse'; total: number };
  } | null;
};

export type FullLeadsQueryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DbLeadOrderBy> | DbLeadOrderBy>;
  where?: InputMaybe<DbLeadBoolExp>;
}>;

export type FullLeadsQueryQuery = {
  __typename?: 'query_root';
  dbLead: Array<{
    __typename?: 'DbLead';
    contactId: string;
    status: LeadStatusEnum;
    source: LeadSourceEnum;
    priority?: PriorityEnum | null;
    customerMessage?: string | null;
    statusReason?: LeadStatusReasonEnum | null;
    statusNote?: string | null;
    agentId?: string | null;
    shopId: string;
    teamId?: string | null;
    id: string;
    intentType: IntentTypeEnum;
    createdAt: any;
    expiredAt?: any | null;
    timeFrame?: TimeFrameEnum | null;
    personalNote?: string | null;
    go3PropertyId?: string | null;
    peLinkedPropertyId?: string | null;
    utmParams?: {
      __typename?: 'DbUtmParam';
      autaclid?: string | null;
      msclkid?: string | null;
      pmclid?: string | null;
      campaign?: string | null;
      content?: string | null;
      gclid?: string | null;
      medium?: string | null;
      source?: string | null;
      url?: string | null;
      gbraid?: string | null;
      wbraid?: string | null;
    } | null;
    conversionData: Array<{
      __typename?: 'DbConversionData';
      referrer?: string | null;
      landingPageUrlWithUtm?: string | null;
      landingPageOwnerShopId?: string | null;
      campaignOwnerShopId?: string | null;
    }>;
    activities: Array<{
      __typename?: 'DbBaseActivity';
      type: ActivityTypeEnum;
      createdBy: string;
      createdAt: any;
      shopId?: string | null;
    }>;
    leadExpiryByLead?: {
      __typename?: 'DbLeadExpiry';
      expirationDate?: any | null;
      id?: string | null;
      status?: string | null;
    } | null;
    contact: { __typename?: 'DbContact'; go3ContactKey?: number | null };
    agent?: {
      __typename: 'DbAgent';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
    shop: {
      __typename?: 'DbShop';
      id: string;
      shopName: string;
      city?: string | null;
      countryCode: CountryEnum;
      postalCode?: string | null;
      streetName?: string | null;
      phone?: string | null;
      email?: string | null;
      status?: ShopStatusEnum | null;
      streetNumber?: string | null;
      onboardingLevel?: number | null;
      groupId?: string | null;
      latitude?: any | null;
      longitude?: any | null;
      shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
      licensePartner: {
        __typename?: 'DbLicensePartner';
        name: string;
        isCompanyOwnedOperation?: boolean | null;
        id: string;
      };
      profiles: Array<{
        __typename?: 'DbShopProfile';
        images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
      }>;
    };
    referrals: Array<{
      __typename?: 'DbReferral';
      id: string;
      go3ReferralId?: number | null;
      referralNotPossibleReason?: ReferralNotPossibleReasonEnum | null;
      referralAgentNote?: string | null;
      receivingShop?: {
        __typename?: 'DbShop';
        id: string;
        shopName: string;
        city?: string | null;
        countryCode: CountryEnum;
        postalCode?: string | null;
        streetName?: string | null;
        phone?: string | null;
        email?: string | null;
        status?: ShopStatusEnum | null;
        streetNumber?: string | null;
        onboardingLevel?: number | null;
        groupId?: string | null;
        latitude?: any | null;
        longitude?: any | null;
        shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
        licensePartner: {
          __typename?: 'DbLicensePartner';
          name: string;
          isCompanyOwnedOperation?: boolean | null;
          id: string;
        };
        profiles: Array<{
          __typename?: 'DbShopProfile';
          images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
        }>;
      } | null;
    }>;
    sellerRentOutSpecification?: {
      __typename?: 'DbSellerRentOutSpecification';
      type?: AssetTypeEnum | null;
      livingArea?: any | null;
      priceRangeTo?: any | null;
      priceRangeFrom?: any | null;
      priceRangeUnit: CurrencyEnum;
      rentPriceUnit: CurrencyEnum;
      plotArea?: any | null;
      constructionYear?: number | null;
      condition?: AssetConditionEnum | null;
      rentRangeFrom?: any | null;
      rentRangeTo?: any | null;
      occupationStatus?: PropertyOccupationStatusEnum | null;
      numberOfBathrooms?: number | null;
      numberOfBedrooms?: number | null;
      floor?: number | null;
      numberOfMasterBathrooms?: number | null;
      numberOfRooms?: number | null;
      totalFloors?: number | null;
      surfaceTotal?: any | null;
      surfaceUsable?: any | null;
      balcony?: boolean | null;
      garden?: boolean | null;
      parking?: boolean | null;
      swimmingPool?: boolean | null;
      elevator?: boolean | null;
      garage?: boolean | null;
      guestToilet?: boolean | null;
      subType?: AssetSubTypeEnum | null;
      variation?: AssetVariationEnum | null;
      id: string;
      ownershipStatus?: PropertyOwnershipStatusEnum | null;
      ownerDescription?: string | null;
      address?: {
        __typename?: 'DbAddress';
        city?: string | null;
        postalCode?: string | null;
        fullAddress?: string | null;
        id: string;
        countryCode: CountryEnum;
        secondAddressLine?: string | null;
        streetName?: string | null;
        streetNumber?: string | null;
        placeId?: string | null;
        country: { __typename?: 'EnumsCountry'; description?: string | null; name: string };
      } | null;
      owner?: {
        __typename?: 'DbContact';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        fullName?: string | null;
      } | null;
      sellerRentOutSpecificationCdfs: Array<{
        __typename?: 'DbSellerRentOutSpecificationCdf';
        cdf: { __typename?: 'DbCdf'; id: string; label: string };
      }>;
    } | null;
    buyerToRentSpecification?: {
      __typename?: 'DbBuyerToRentSpecification';
      searchDemandId?: string | null;
      type?: AssetTypeEnum | null;
      city?: string | null;
      livingAreaMax?: number | null;
      livingAreaMin?: number | null;
      totalSurfaceMax?: number | null;
      totalSurfaceMin?: number | null;
      plotSurfaceMax?: number | null;
      plotSurfaceMin?: number | null;
      priceMax?: number | null;
      priceMin?: number | null;
      currency: CurrencyEnum;
      area?: string | null;
      country?: string | null;
      bedroomsMax?: number | null;
      bedroomsMin?: number | null;
      district?: string | null;
      id: string;
      legacyAreaNames: Array<string>;
      legacyDistrictNames: Array<string>;
      monthlyNetRentMax?: number | null;
      monthlyNetRentMin?: number | null;
      monthlyTotalRentMax?: number | null;
      monthlyTotalRentMin?: number | null;
      roomsMax?: number | null;
      roomsMin?: number | null;
      subTypes?: Array<string> | null;
      variations?: Array<string> | null;
      placeIds: Array<string>;
      buyerToRentSpecificationCdfs: Array<{
        __typename?: 'DbBuyerToRentSpecificationCdf';
        cdf: { __typename?: 'DbCdf'; id: string; label: string };
      }>;
      customSearchDemandPolygons: Array<{
        __typename?: 'DbCustomSearchDemandPolygon';
        id: string;
        name?: string | null;
        polygon: any;
      }>;
    } | null;
    leadBundlingByLeadWithPropertyId?: {
      __typename?: 'DbLeadBundling';
      id: string;
      leadWithSearchDemand: { __typename?: 'DbLead'; id: string };
    } | null;
    leadBundlingsByLeadWithSearchDemandId: Array<{
      __typename?: 'DbLeadBundling';
      id: string;
      leadWithProperty: {
        __typename?: 'DbLead';
        id: string;
        contactId: string;
        teamId?: string | null;
        intentType: IntentTypeEnum;
        createdAt: any;
        expiredAt?: any | null;
        status: LeadStatusEnum;
        timeFrame?: TimeFrameEnum | null;
        agentId?: string | null;
        shopId: string;
        personalNote?: string | null;
        go3PropertyId?: string | null;
        peLinkedPropertyId?: string | null;
        sellerRentOutSpecification?: {
          __typename?: 'DbSellerRentOutSpecification';
          type?: AssetTypeEnum | null;
          livingArea?: any | null;
          priceRangeTo?: any | null;
          priceRangeFrom?: any | null;
          priceRangeUnit: CurrencyEnum;
          rentPriceUnit: CurrencyEnum;
          plotArea?: any | null;
          address?: {
            __typename?: 'DbAddress';
            city?: string | null;
            postalCode?: string | null;
            fullAddress?: string | null;
          } | null;
        } | null;
        buyerToRentSpecification?: {
          __typename?: 'DbBuyerToRentSpecification';
          searchDemandId?: string | null;
          type?: AssetTypeEnum | null;
          city?: string | null;
          livingAreaMax?: number | null;
          livingAreaMin?: number | null;
          totalSurfaceMax?: number | null;
          totalSurfaceMin?: number | null;
          plotSurfaceMax?: number | null;
          plotSurfaceMin?: number | null;
          priceMax?: number | null;
          priceMin?: number | null;
          currency: CurrencyEnum;
          area?: string | null;
          country?: string | null;
        } | null;
        emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
        leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
      };
    }>;
    emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
    lastActivity: Array<{
      __typename?: 'DbBaseActivity';
      type: ActivityTypeEnum;
      createdBy: string;
      createdAt: any;
      shopId?: string | null;
    }>;
  }>;
};

export type GetAllEnumsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllEnumsQuery = {
  __typename?: 'query_root';
  enumsBaseActivityCategory: Array<{ __typename?: 'EnumsBaseActivityCategory'; name: string }>;
  enumsActivityType: Array<{ __typename?: 'EnumsActivityType'; name: string }>;
  enumsAddressType: Array<{ __typename?: 'EnumsAddressType'; name: string }>;
  enumsContactTime: Array<{ __typename?: 'EnumsContactTime'; name: string }>;
  enumsCountry: Array<{ __typename?: 'EnumsCountry'; name: string }>;
  enumsLanguage: Array<{ __typename?: 'EnumsLanguage'; name: string }>;
  enumsLeadSource: Array<{ __typename?: 'EnumsLeadSource'; name: string }>;
  enumsLeadStatus: Array<{ __typename?: 'EnumsLeadStatus'; name: string }>;
  enumsPhoneNumberType: Array<{ __typename?: 'EnumsPhoneNumberType'; name: string }>;
  enumsEmailAddressType: Array<{ __typename?: 'EnumsEmailAddressType'; name: string }>;
  enumsSalutation: Array<{ __typename?: 'EnumsSalutation'; name: string }>;
  enumsGender: Array<{ __typename?: 'EnumsGender'; name: string }>;
  enumsAssetType: Array<{ __typename?: 'EnumsAssetType'; name: string }>;
  enumsAssetSubType: Array<{ __typename?: 'EnumsAssetSubType'; name: string }>;
  enumsPriority: Array<{ __typename?: 'EnumsPriority'; name: string }>;
  enumsIntentType: Array<{ __typename?: 'EnumsIntentType'; name: string }>;
  enumsAssetCondition: Array<{ __typename?: 'EnumsAssetCondition'; name: string }>;
  enumsReferralNotPossibleReason: Array<{ __typename?: 'EnumsReferralNotPossibleReason'; name: string }>;
  enumsZeroCommissionReason: Array<{ __typename?: 'EnumsZeroCommissionReason'; name: string }>;
  enumsEmailSignatureScope: Array<{ __typename?: 'EnumsEmailSignatureScope'; name: string }>;
  enumsEmailTemplateScope: Array<{ __typename?: 'EnumsEmailTemplateScope'; name: string }>;
  enumsUserRole: Array<{ __typename?: 'EnumsUserRole'; name: string }>;
  enumsTimeFrame: Array<{ __typename?: 'EnumsTimeFrame'; name: string }>;
  enumsLeadStatusReason: Array<{ __typename?: 'EnumsLeadStatusReason'; name: string }>;
  enumsShopExpertise: Array<{ __typename?: 'EnumsShopExpertise'; name: string }>;
  enumsIntentCategory: Array<{ __typename?: 'EnumsIntentCategory'; name: string }>;
  enumsContactRelationType: Array<{ __typename?: 'EnumsContactRelationType'; name: string }>;
  enumsCallStatus: Array<{ __typename?: 'EnumsCallStatus'; name: string }>;
  enumsCallOutcome: Array<{ __typename?: 'EnumsCallOutcome'; name: string }>;
  enumsTaskAppointmentStatus: Array<{ __typename?: 'EnumsTaskAppointmentStatus'; name: string }>;
  enumsTaskType: Array<{ __typename?: 'EnumsTaskType'; name: string }>;
  enumsAppointmentType: Array<{ __typename?: 'EnumsAppointmentType'; name: string }>;
  enumsSharedType: Array<{ __typename?: 'EnumsSharedType'; name: string }>;
  enumsPropertyOwnershipStatus: Array<{ __typename?: 'EnumsPropertyOwnershipStatus'; name: string }>;
  enumsPropertyOccupationStatus: Array<{ __typename?: 'EnumsPropertyOccupationStatus'; name: string }>;
  enumsEntityType: Array<{ __typename?: 'EnumsEntityType'; name: string }>;
  enumsPrivacyLevel: Array<{ __typename?: 'EnumsPrivacyLevel'; name: string }>;
  enumsMandatoryShopProfileField: Array<{ __typename?: 'EnumsMandatoryShopProfileField'; name: string }>;
  enumsConsentSource: Array<{ __typename?: 'EnumsConsentSource'; name: string }>;
  enumsHouseholdType: Array<{ __typename?: 'EnumsHouseholdType'; name: string }>;
  enumsProfessionType: Array<{ __typename?: 'EnumsProfessionType'; name: string }>;
  enumsSpokenLanguage: Array<{ __typename?: 'EnumsSpokenLanguage'; name: string }>;
};

export type GoogleIntegrationsQueryVariables = Exact<{
  order_by?: InputMaybe<Array<DbNylasEmailIntegrationOrderBy> | DbNylasEmailIntegrationOrderBy>;
  where?: InputMaybe<DbNylasEmailIntegrationBoolExp>;
  agent_id?: InputMaybe<Scalars['String']>;
  shop_id?: InputMaybe<Scalars['String']>;
}>;

export type GoogleIntegrationsQuery = {
  __typename?: 'query_root';
  dbNylasEmailIntegration: Array<{
    __typename?: 'DbNylasEmailIntegration';
    id: string;
    email: string;
    agentId?: string | null;
    shopId?: string | null;
    createdAt: any;
    updatedAt: any;
    scope: EmailIntegrationScopeEnum;
    active: boolean;
    isDefault: {
      __typename?: 'DbAgentDefaultNylasEmailIntegrationAggregate';
      aggregate?: { __typename?: 'DbAgentDefaultNylasEmailIntegrationAggregateFields'; count: number } | null;
    };
  }>;
};

export type LeadByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;

export type LeadByIdQuery = {
  __typename?: 'query_root';
  dbLead: Array<{
    __typename?: 'DbLead';
    contactId: string;
    status: LeadStatusEnum;
    source: LeadSourceEnum;
    priority?: PriorityEnum | null;
    customerMessage?: string | null;
    statusReason?: LeadStatusReasonEnum | null;
    statusNote?: string | null;
    agentId?: string | null;
    shopId: string;
    teamId?: string | null;
    id: string;
    intentType: IntentTypeEnum;
    createdAt: any;
    expiredAt?: any | null;
    timeFrame?: TimeFrameEnum | null;
    personalNote?: string | null;
    go3PropertyId?: string | null;
    peLinkedPropertyId?: string | null;
    lastInteractionActivity?: {
      __typename?: 'DbBaseActivity';
      orderDate: any;
      category: BaseActivityCategoryEnum;
      type: ActivityTypeEnum;
      createdBy: string;
      createdAt: any;
      shopId?: string | null;
      taskActivity?: { __typename?: 'DbTaskActivity'; task: { __typename?: 'DbTask'; type: TaskTypeEnum } } | null;
      appointmentActivity?: {
        __typename?: 'DbAppointmentActivity';
        appointment: { __typename?: 'DbAppointment'; type: AppointmentTypeEnum };
      } | null;
    } | null;
    utmParams?: {
      __typename?: 'DbUtmParam';
      autaclid?: string | null;
      msclkid?: string | null;
      pmclid?: string | null;
      campaign?: string | null;
      content?: string | null;
      gclid?: string | null;
      medium?: string | null;
      source?: string | null;
      url?: string | null;
      gbraid?: string | null;
      wbraid?: string | null;
    } | null;
    conversionData: Array<{
      __typename?: 'DbConversionData';
      referrer?: string | null;
      landingPageUrlWithUtm?: string | null;
      landingPageOwnerShopId?: string | null;
      campaignOwnerShopId?: string | null;
    }>;
    activities: Array<{
      __typename?: 'DbBaseActivity';
      type: ActivityTypeEnum;
      createdBy: string;
      createdAt: any;
      shopId?: string | null;
    }>;
    leadExpiryByLead?: {
      __typename?: 'DbLeadExpiry';
      expirationDate?: any | null;
      id?: string | null;
      status?: string | null;
    } | null;
    contact: { __typename?: 'DbContact'; go3ContactKey?: number | null };
    agent?: {
      __typename: 'DbAgent';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
    shop: {
      __typename?: 'DbShop';
      id: string;
      shopName: string;
      city?: string | null;
      countryCode: CountryEnum;
      postalCode?: string | null;
      streetName?: string | null;
      phone?: string | null;
      email?: string | null;
      status?: ShopStatusEnum | null;
      streetNumber?: string | null;
      onboardingLevel?: number | null;
      groupId?: string | null;
      latitude?: any | null;
      longitude?: any | null;
      shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
      licensePartner: {
        __typename?: 'DbLicensePartner';
        name: string;
        isCompanyOwnedOperation?: boolean | null;
        id: string;
      };
      profiles: Array<{
        __typename?: 'DbShopProfile';
        images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
      }>;
    };
    referrals: Array<{
      __typename?: 'DbReferral';
      id: string;
      go3ReferralId?: number | null;
      referralNotPossibleReason?: ReferralNotPossibleReasonEnum | null;
      referralAgentNote?: string | null;
      receivingShop?: {
        __typename?: 'DbShop';
        id: string;
        shopName: string;
        city?: string | null;
        countryCode: CountryEnum;
        postalCode?: string | null;
        streetName?: string | null;
        phone?: string | null;
        email?: string | null;
        status?: ShopStatusEnum | null;
        streetNumber?: string | null;
        onboardingLevel?: number | null;
        groupId?: string | null;
        latitude?: any | null;
        longitude?: any | null;
        shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
        licensePartner: {
          __typename?: 'DbLicensePartner';
          name: string;
          isCompanyOwnedOperation?: boolean | null;
          id: string;
        };
        profiles: Array<{
          __typename?: 'DbShopProfile';
          images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
        }>;
      } | null;
    }>;
    sellerRentOutSpecification?: {
      __typename?: 'DbSellerRentOutSpecification';
      type?: AssetTypeEnum | null;
      livingArea?: any | null;
      priceRangeTo?: any | null;
      priceRangeFrom?: any | null;
      priceRangeUnit: CurrencyEnum;
      rentPriceUnit: CurrencyEnum;
      plotArea?: any | null;
      constructionYear?: number | null;
      condition?: AssetConditionEnum | null;
      rentRangeFrom?: any | null;
      rentRangeTo?: any | null;
      occupationStatus?: PropertyOccupationStatusEnum | null;
      numberOfBathrooms?: number | null;
      numberOfBedrooms?: number | null;
      floor?: number | null;
      numberOfMasterBathrooms?: number | null;
      numberOfRooms?: number | null;
      totalFloors?: number | null;
      surfaceTotal?: any | null;
      surfaceUsable?: any | null;
      balcony?: boolean | null;
      garden?: boolean | null;
      parking?: boolean | null;
      swimmingPool?: boolean | null;
      elevator?: boolean | null;
      garage?: boolean | null;
      guestToilet?: boolean | null;
      subType?: AssetSubTypeEnum | null;
      variation?: AssetVariationEnum | null;
      id: string;
      ownershipStatus?: PropertyOwnershipStatusEnum | null;
      ownerDescription?: string | null;
      address?: {
        __typename?: 'DbAddress';
        city?: string | null;
        postalCode?: string | null;
        fullAddress?: string | null;
        id: string;
        countryCode: CountryEnum;
        secondAddressLine?: string | null;
        streetName?: string | null;
        streetNumber?: string | null;
        placeId?: string | null;
        country: { __typename?: 'EnumsCountry'; description?: string | null; name: string };
      } | null;
      owner?: {
        __typename?: 'DbContact';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        fullName?: string | null;
      } | null;
      sellerRentOutSpecificationCdfs: Array<{
        __typename?: 'DbSellerRentOutSpecificationCdf';
        cdf: { __typename?: 'DbCdf'; id: string; label: string };
      }>;
    } | null;
    buyerToRentSpecification?: {
      __typename?: 'DbBuyerToRentSpecification';
      searchDemandId?: string | null;
      type?: AssetTypeEnum | null;
      city?: string | null;
      livingAreaMax?: number | null;
      livingAreaMin?: number | null;
      totalSurfaceMax?: number | null;
      totalSurfaceMin?: number | null;
      plotSurfaceMax?: number | null;
      plotSurfaceMin?: number | null;
      priceMax?: number | null;
      priceMin?: number | null;
      currency: CurrencyEnum;
      area?: string | null;
      country?: string | null;
      bedroomsMax?: number | null;
      bedroomsMin?: number | null;
      district?: string | null;
      id: string;
      legacyAreaNames: Array<string>;
      legacyDistrictNames: Array<string>;
      monthlyNetRentMax?: number | null;
      monthlyNetRentMin?: number | null;
      monthlyTotalRentMax?: number | null;
      monthlyTotalRentMin?: number | null;
      roomsMax?: number | null;
      roomsMin?: number | null;
      subTypes?: Array<string> | null;
      variations?: Array<string> | null;
      placeIds: Array<string>;
      buyerToRentSpecificationCdfs: Array<{
        __typename?: 'DbBuyerToRentSpecificationCdf';
        cdf: { __typename?: 'DbCdf'; id: string; label: string };
      }>;
      customSearchDemandPolygons: Array<{
        __typename?: 'DbCustomSearchDemandPolygon';
        id: string;
        name?: string | null;
        polygon: any;
      }>;
    } | null;
    leadBundlingByLeadWithPropertyId?: {
      __typename?: 'DbLeadBundling';
      id: string;
      leadWithSearchDemand: { __typename?: 'DbLead'; id: string };
    } | null;
    leadBundlingsByLeadWithSearchDemandId: Array<{
      __typename?: 'DbLeadBundling';
      id: string;
      leadWithProperty: {
        __typename?: 'DbLead';
        id: string;
        contactId: string;
        teamId?: string | null;
        intentType: IntentTypeEnum;
        createdAt: any;
        expiredAt?: any | null;
        status: LeadStatusEnum;
        timeFrame?: TimeFrameEnum | null;
        agentId?: string | null;
        shopId: string;
        personalNote?: string | null;
        go3PropertyId?: string | null;
        peLinkedPropertyId?: string | null;
        sellerRentOutSpecification?: {
          __typename?: 'DbSellerRentOutSpecification';
          type?: AssetTypeEnum | null;
          livingArea?: any | null;
          priceRangeTo?: any | null;
          priceRangeFrom?: any | null;
          priceRangeUnit: CurrencyEnum;
          rentPriceUnit: CurrencyEnum;
          plotArea?: any | null;
          address?: {
            __typename?: 'DbAddress';
            city?: string | null;
            postalCode?: string | null;
            fullAddress?: string | null;
          } | null;
        } | null;
        buyerToRentSpecification?: {
          __typename?: 'DbBuyerToRentSpecification';
          searchDemandId?: string | null;
          type?: AssetTypeEnum | null;
          city?: string | null;
          livingAreaMax?: number | null;
          livingAreaMin?: number | null;
          totalSurfaceMax?: number | null;
          totalSurfaceMin?: number | null;
          plotSurfaceMax?: number | null;
          plotSurfaceMin?: number | null;
          priceMax?: number | null;
          priceMin?: number | null;
          currency: CurrencyEnum;
          area?: string | null;
          country?: string | null;
        } | null;
        emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
        leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
      };
    }>;
    emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
    lastActivity: Array<{
      __typename?: 'DbBaseActivity';
      type: ActivityTypeEnum;
      createdBy: string;
      createdAt: any;
      shopId?: string | null;
    }>;
  }>;
};

export type LeadAndContactByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;

export type LeadAndContactByIdQuery = {
  __typename?: 'query_root';
  dbLead: Array<{
    __typename?: 'DbLead';
    id: string;
    contactId: string;
    teamId?: string | null;
    intentType: IntentTypeEnum;
    createdAt: any;
    expiredAt?: any | null;
    status: LeadStatusEnum;
    timeFrame?: TimeFrameEnum | null;
    agentId?: string | null;
    shopId: string;
    personalNote?: string | null;
    go3PropertyId?: string | null;
    peLinkedPropertyId?: string | null;
    contact: {
      __typename?: 'DbContact';
      id: string;
      go3Utag?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      preferredLanguage?: LanguageEnum | null;
      blocked: boolean;
      phoneNumbers: Array<{ __typename?: 'DbContactPhoneNumber'; number: string; preferred: boolean }>;
      emailAddresses: Array<{ __typename?: 'DbContactEmailAddress'; address: string; preferred: boolean }>;
    };
    sellerRentOutSpecification?: {
      __typename?: 'DbSellerRentOutSpecification';
      type?: AssetTypeEnum | null;
      livingArea?: any | null;
      priceRangeTo?: any | null;
      priceRangeFrom?: any | null;
      priceRangeUnit: CurrencyEnum;
      rentPriceUnit: CurrencyEnum;
      plotArea?: any | null;
      address?: {
        __typename?: 'DbAddress';
        city?: string | null;
        postalCode?: string | null;
        fullAddress?: string | null;
      } | null;
    } | null;
    buyerToRentSpecification?: {
      __typename?: 'DbBuyerToRentSpecification';
      searchDemandId?: string | null;
      type?: AssetTypeEnum | null;
      city?: string | null;
      livingAreaMax?: number | null;
      livingAreaMin?: number | null;
      totalSurfaceMax?: number | null;
      totalSurfaceMin?: number | null;
      plotSurfaceMax?: number | null;
      plotSurfaceMin?: number | null;
      priceMax?: number | null;
      priceMin?: number | null;
      currency: CurrencyEnum;
      area?: string | null;
      country?: string | null;
    } | null;
    emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
    leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
  }>;
};

export type LeadBaseInfoByIdQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;

export type LeadBaseInfoByIdQuery = {
  __typename?: 'query_root';
  dbLead: Array<{
    __typename?: 'DbLead';
    id: string;
    contactId: string;
    teamId?: string | null;
    intentType: IntentTypeEnum;
    createdAt: any;
    expiredAt?: any | null;
    status: LeadStatusEnum;
    timeFrame?: TimeFrameEnum | null;
    agentId?: string | null;
    shopId: string;
    personalNote?: string | null;
    go3PropertyId?: string | null;
    peLinkedPropertyId?: string | null;
    sellerRentOutSpecification?: {
      __typename?: 'DbSellerRentOutSpecification';
      type?: AssetTypeEnum | null;
      livingArea?: any | null;
      priceRangeTo?: any | null;
      priceRangeFrom?: any | null;
      priceRangeUnit: CurrencyEnum;
      rentPriceUnit: CurrencyEnum;
      plotArea?: any | null;
      address?: {
        __typename?: 'DbAddress';
        city?: string | null;
        postalCode?: string | null;
        fullAddress?: string | null;
      } | null;
    } | null;
    buyerToRentSpecification?: {
      __typename?: 'DbBuyerToRentSpecification';
      searchDemandId?: string | null;
      type?: AssetTypeEnum | null;
      city?: string | null;
      livingAreaMax?: number | null;
      livingAreaMin?: number | null;
      totalSurfaceMax?: number | null;
      totalSurfaceMin?: number | null;
      plotSurfaceMax?: number | null;
      plotSurfaceMin?: number | null;
      priceMax?: number | null;
      priceMin?: number | null;
      currency: CurrencyEnum;
      area?: string | null;
      country?: string | null;
    } | null;
    emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
    leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
  }>;
};

export type LeadInfoByIdForAddingPropertyQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;

export type LeadInfoByIdForAddingPropertyQuery = {
  __typename?: 'query_root';
  dbLead: Array<{
    __typename?: 'DbLead';
    id: string;
    intentType: IntentTypeEnum;
    contact: { __typename?: 'DbContact'; go3ContactKey?: number | null };
    sellerRentOutSpecification?: {
      __typename?: 'DbSellerRentOutSpecification';
      type?: AssetTypeEnum | null;
      subType?: AssetSubTypeEnum | null;
      livingArea?: any | null;
      plotArea?: any | null;
      surfaceTotal?: any | null;
      numberOfRooms?: number | null;
      numberOfBathrooms?: number | null;
      address?: {
        __typename?: 'DbAddress';
        streetName?: string | null;
        streetNumber?: string | null;
        city?: string | null;
        postalCode?: string | null;
      } | null;
    } | null;
  }>;
};

export type LeadsQueryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DbLeadBoolExp>;
  lastInteractionWhere?: InputMaybe<DbBaseActivityBoolExp>;
}>;

export type LeadsQueryQuery = {
  __typename?: 'query_root';
  dbLead: Array<{
    __typename?: 'DbLead';
    id: string;
    contactId: string;
    teamId?: string | null;
    intentType: IntentTypeEnum;
    createdAt: any;
    expiredAt?: any | null;
    status: LeadStatusEnum;
    timeFrame?: TimeFrameEnum | null;
    agentId?: string | null;
    shopId: string;
    personalNote?: string | null;
    go3PropertyId?: string | null;
    peLinkedPropertyId?: string | null;
    sellerRentOutSpecification?: {
      __typename?: 'DbSellerRentOutSpecification';
      type?: AssetTypeEnum | null;
      livingArea?: any | null;
      priceRangeTo?: any | null;
      priceRangeFrom?: any | null;
      priceRangeUnit: CurrencyEnum;
      rentPriceUnit: CurrencyEnum;
      plotArea?: any | null;
      address?: {
        __typename?: 'DbAddress';
        city?: string | null;
        postalCode?: string | null;
        fullAddress?: string | null;
      } | null;
    } | null;
    buyerToRentSpecification?: {
      __typename?: 'DbBuyerToRentSpecification';
      searchDemandId?: string | null;
      type?: AssetTypeEnum | null;
      city?: string | null;
      livingAreaMax?: number | null;
      livingAreaMin?: number | null;
      totalSurfaceMax?: number | null;
      totalSurfaceMin?: number | null;
      plotSurfaceMax?: number | null;
      plotSurfaceMin?: number | null;
      priceMax?: number | null;
      priceMin?: number | null;
      currency: CurrencyEnum;
      area?: string | null;
      country?: string | null;
    } | null;
    emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
    leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
  }>;
};

export type LeadsOfContactQueryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DbLeadBoolExp>;
}>;

export type LeadsOfContactQueryQuery = {
  __typename?: 'query_root';
  dbLead: Array<{
    __typename?: 'DbLead';
    contactId: string;
    status: LeadStatusEnum;
    source: LeadSourceEnum;
    priority?: PriorityEnum | null;
    customerMessage?: string | null;
    statusReason?: LeadStatusReasonEnum | null;
    statusNote?: string | null;
    agentId?: string | null;
    shopId: string;
    teamId?: string | null;
    id: string;
    intentType: IntentTypeEnum;
    createdAt: any;
    expiredAt?: any | null;
    timeFrame?: TimeFrameEnum | null;
    personalNote?: string | null;
    go3PropertyId?: string | null;
    peLinkedPropertyId?: string | null;
    utmParams?: {
      __typename?: 'DbUtmParam';
      autaclid?: string | null;
      msclkid?: string | null;
      pmclid?: string | null;
      campaign?: string | null;
      content?: string | null;
      gclid?: string | null;
      medium?: string | null;
      source?: string | null;
      url?: string | null;
      gbraid?: string | null;
      wbraid?: string | null;
    } | null;
    conversionData: Array<{
      __typename?: 'DbConversionData';
      referrer?: string | null;
      landingPageUrlWithUtm?: string | null;
      landingPageOwnerShopId?: string | null;
      campaignOwnerShopId?: string | null;
    }>;
    activities: Array<{
      __typename?: 'DbBaseActivity';
      type: ActivityTypeEnum;
      createdBy: string;
      createdAt: any;
      shopId?: string | null;
    }>;
    leadExpiryByLead?: {
      __typename?: 'DbLeadExpiry';
      expirationDate?: any | null;
      id?: string | null;
      status?: string | null;
    } | null;
    contact: { __typename?: 'DbContact'; go3ContactKey?: number | null };
    agent?: {
      __typename: 'DbAgent';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
    shop: {
      __typename?: 'DbShop';
      id: string;
      shopName: string;
      city?: string | null;
      countryCode: CountryEnum;
      postalCode?: string | null;
      streetName?: string | null;
      phone?: string | null;
      email?: string | null;
      status?: ShopStatusEnum | null;
      streetNumber?: string | null;
      onboardingLevel?: number | null;
      groupId?: string | null;
      latitude?: any | null;
      longitude?: any | null;
      shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
      licensePartner: {
        __typename?: 'DbLicensePartner';
        name: string;
        isCompanyOwnedOperation?: boolean | null;
        id: string;
      };
      profiles: Array<{
        __typename?: 'DbShopProfile';
        images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
      }>;
    };
    referrals: Array<{
      __typename?: 'DbReferral';
      id: string;
      go3ReferralId?: number | null;
      referralNotPossibleReason?: ReferralNotPossibleReasonEnum | null;
      referralAgentNote?: string | null;
      receivingShop?: {
        __typename?: 'DbShop';
        id: string;
        shopName: string;
        city?: string | null;
        countryCode: CountryEnum;
        postalCode?: string | null;
        streetName?: string | null;
        phone?: string | null;
        email?: string | null;
        status?: ShopStatusEnum | null;
        streetNumber?: string | null;
        onboardingLevel?: number | null;
        groupId?: string | null;
        latitude?: any | null;
        longitude?: any | null;
        shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
        licensePartner: {
          __typename?: 'DbLicensePartner';
          name: string;
          isCompanyOwnedOperation?: boolean | null;
          id: string;
        };
        profiles: Array<{
          __typename?: 'DbShopProfile';
          images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
        }>;
      } | null;
    }>;
    sellerRentOutSpecification?: {
      __typename?: 'DbSellerRentOutSpecification';
      type?: AssetTypeEnum | null;
      livingArea?: any | null;
      priceRangeTo?: any | null;
      priceRangeFrom?: any | null;
      priceRangeUnit: CurrencyEnum;
      rentPriceUnit: CurrencyEnum;
      plotArea?: any | null;
      constructionYear?: number | null;
      condition?: AssetConditionEnum | null;
      rentRangeFrom?: any | null;
      rentRangeTo?: any | null;
      occupationStatus?: PropertyOccupationStatusEnum | null;
      numberOfBathrooms?: number | null;
      numberOfBedrooms?: number | null;
      floor?: number | null;
      numberOfMasterBathrooms?: number | null;
      numberOfRooms?: number | null;
      totalFloors?: number | null;
      surfaceTotal?: any | null;
      surfaceUsable?: any | null;
      balcony?: boolean | null;
      garden?: boolean | null;
      parking?: boolean | null;
      swimmingPool?: boolean | null;
      elevator?: boolean | null;
      garage?: boolean | null;
      guestToilet?: boolean | null;
      subType?: AssetSubTypeEnum | null;
      variation?: AssetVariationEnum | null;
      id: string;
      ownershipStatus?: PropertyOwnershipStatusEnum | null;
      ownerDescription?: string | null;
      address?: {
        __typename?: 'DbAddress';
        city?: string | null;
        postalCode?: string | null;
        fullAddress?: string | null;
        id: string;
        countryCode: CountryEnum;
        secondAddressLine?: string | null;
        streetName?: string | null;
        streetNumber?: string | null;
        placeId?: string | null;
        country: { __typename?: 'EnumsCountry'; description?: string | null; name: string };
      } | null;
      owner?: {
        __typename?: 'DbContact';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        fullName?: string | null;
      } | null;
      sellerRentOutSpecificationCdfs: Array<{
        __typename?: 'DbSellerRentOutSpecificationCdf';
        cdf: { __typename?: 'DbCdf'; id: string; label: string };
      }>;
    } | null;
    buyerToRentSpecification?: {
      __typename?: 'DbBuyerToRentSpecification';
      searchDemandId?: string | null;
      type?: AssetTypeEnum | null;
      city?: string | null;
      livingAreaMax?: number | null;
      livingAreaMin?: number | null;
      totalSurfaceMax?: number | null;
      totalSurfaceMin?: number | null;
      plotSurfaceMax?: number | null;
      plotSurfaceMin?: number | null;
      priceMax?: number | null;
      priceMin?: number | null;
      currency: CurrencyEnum;
      area?: string | null;
      country?: string | null;
      bedroomsMax?: number | null;
      bedroomsMin?: number | null;
      district?: string | null;
      id: string;
      legacyAreaNames: Array<string>;
      legacyDistrictNames: Array<string>;
      monthlyNetRentMax?: number | null;
      monthlyNetRentMin?: number | null;
      monthlyTotalRentMax?: number | null;
      monthlyTotalRentMin?: number | null;
      roomsMax?: number | null;
      roomsMin?: number | null;
      subTypes?: Array<string> | null;
      variations?: Array<string> | null;
      placeIds: Array<string>;
      buyerToRentSpecificationCdfs: Array<{
        __typename?: 'DbBuyerToRentSpecificationCdf';
        cdf: { __typename?: 'DbCdf'; id: string; label: string };
      }>;
      customSearchDemandPolygons: Array<{
        __typename?: 'DbCustomSearchDemandPolygon';
        id: string;
        name?: string | null;
        polygon: any;
      }>;
    } | null;
    leadBundlingByLeadWithPropertyId?: {
      __typename?: 'DbLeadBundling';
      id: string;
      leadWithSearchDemand: { __typename?: 'DbLead'; id: string };
    } | null;
    leadBundlingsByLeadWithSearchDemandId: Array<{
      __typename?: 'DbLeadBundling';
      id: string;
      leadWithProperty: {
        __typename?: 'DbLead';
        id: string;
        contactId: string;
        teamId?: string | null;
        intentType: IntentTypeEnum;
        createdAt: any;
        expiredAt?: any | null;
        status: LeadStatusEnum;
        timeFrame?: TimeFrameEnum | null;
        agentId?: string | null;
        shopId: string;
        personalNote?: string | null;
        go3PropertyId?: string | null;
        peLinkedPropertyId?: string | null;
        sellerRentOutSpecification?: {
          __typename?: 'DbSellerRentOutSpecification';
          type?: AssetTypeEnum | null;
          livingArea?: any | null;
          priceRangeTo?: any | null;
          priceRangeFrom?: any | null;
          priceRangeUnit: CurrencyEnum;
          rentPriceUnit: CurrencyEnum;
          plotArea?: any | null;
          address?: {
            __typename?: 'DbAddress';
            city?: string | null;
            postalCode?: string | null;
            fullAddress?: string | null;
          } | null;
        } | null;
        buyerToRentSpecification?: {
          __typename?: 'DbBuyerToRentSpecification';
          searchDemandId?: string | null;
          type?: AssetTypeEnum | null;
          city?: string | null;
          livingAreaMax?: number | null;
          livingAreaMin?: number | null;
          totalSurfaceMax?: number | null;
          totalSurfaceMin?: number | null;
          plotSurfaceMax?: number | null;
          plotSurfaceMin?: number | null;
          priceMax?: number | null;
          priceMin?: number | null;
          currency: CurrencyEnum;
          area?: string | null;
          country?: string | null;
        } | null;
        emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
        leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
      };
    }>;
    emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
    lastActivity: Array<{
      __typename?: 'DbBaseActivity';
      type: ActivityTypeEnum;
      createdBy: string;
      createdAt: any;
      shopId?: string | null;
    }>;
  }>;
};

export type LeadBaseActivityQueryVariables = Exact<{
  where?: InputMaybe<DbBaseActivityBoolExp>;
  orderBy?: InputMaybe<Array<DbBaseActivityOrderBy> | DbBaseActivityOrderBy>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type LeadBaseActivityQuery = {
  __typename?: 'query_root';
  dbBaseActivity: Array<{
    __typename?: 'DbBaseActivity';
    id: string;
    category: BaseActivityCategoryEnum;
    type: ActivityTypeEnum;
    createdAt: any;
    createdBy: string;
    contactId?: string | null;
    leadId?: string | null;
    shopId?: string | null;
    creator?: { __typename?: 'DbAgent'; userName: string; firstName: string; lastName: string } | null;
    lead?: {
      __typename?: 'DbLead';
      contactId: string;
      id: string;
      intentType: IntentTypeEnum;
      status: LeadStatusEnum;
      expiredAt?: any | null;
      go3PropertyId?: string | null;
      peLinkedPropertyId?: string | null;
      personalNote?: string | null;
      timeFrame?: TimeFrameEnum | null;
      customerMessage?: string | null;
      sellerRentOutSpecification?: {
        __typename?: 'DbSellerRentOutSpecification';
        type?: AssetTypeEnum | null;
        livingArea?: any | null;
        plotArea?: any | null;
        constructionYear?: number | null;
        condition?: AssetConditionEnum | null;
        priceRangeTo?: any | null;
        priceRangeFrom?: any | null;
        rentRangeFrom?: any | null;
        rentRangeTo?: any | null;
        priceRangeUnit: CurrencyEnum;
        rentPriceUnit: CurrencyEnum;
        occupationStatus?: PropertyOccupationStatusEnum | null;
        numberOfBathrooms?: number | null;
        numberOfBedrooms?: number | null;
        floor?: number | null;
        numberOfMasterBathrooms?: number | null;
        numberOfRooms?: number | null;
        totalFloors?: number | null;
        surfaceTotal?: any | null;
        surfaceUsable?: any | null;
        balcony?: boolean | null;
        garden?: boolean | null;
        parking?: boolean | null;
        swimmingPool?: boolean | null;
        elevator?: boolean | null;
        garage?: boolean | null;
        guestToilet?: boolean | null;
        subType?: AssetSubTypeEnum | null;
        variation?: AssetVariationEnum | null;
        id: string;
        ownershipStatus?: PropertyOwnershipStatusEnum | null;
        ownerDescription?: string | null;
        address?: {
          __typename?: 'DbAddress';
          city?: string | null;
          postalCode?: string | null;
          countryCode: CountryEnum;
          streetName?: string | null;
          streetNumber?: string | null;
          secondAddressLine?: string | null;
          id: string;
          fullAddress?: string | null;
          placeId?: string | null;
          country: { __typename?: 'EnumsCountry'; description?: string | null; name: string };
        } | null;
        owner?: {
          __typename?: 'DbContact';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          fullName?: string | null;
        } | null;
        sellerRentOutSpecificationCdfs: Array<{
          __typename?: 'DbSellerRentOutSpecificationCdf';
          cdf: { __typename?: 'DbCdf'; id: string; label: string };
        }>;
      } | null;
      buyerToRentSpecification?: {
        __typename?: 'DbBuyerToRentSpecification';
        type?: AssetTypeEnum | null;
        city?: string | null;
        livingAreaMax?: number | null;
        livingAreaMin?: number | null;
        totalSurfaceMax?: number | null;
        totalSurfaceMin?: number | null;
        plotSurfaceMax?: number | null;
        plotSurfaceMin?: number | null;
        area?: string | null;
        bedroomsMax?: number | null;
        bedroomsMin?: number | null;
        country?: string | null;
        currency: CurrencyEnum;
        district?: string | null;
        id: string;
        legacyAreaNames: Array<string>;
        legacyDistrictNames: Array<string>;
        monthlyNetRentMax?: number | null;
        monthlyNetRentMin?: number | null;
        monthlyTotalRentMax?: number | null;
        monthlyTotalRentMin?: number | null;
        priceMax?: number | null;
        priceMin?: number | null;
        roomsMax?: number | null;
        roomsMin?: number | null;
        subTypes?: Array<string> | null;
        variations?: Array<string> | null;
        placeIds: Array<string>;
        searchDemandId?: string | null;
        buyerToRentSpecificationCdfs: Array<{
          __typename?: 'DbBuyerToRentSpecificationCdf';
          cdf: { __typename?: 'DbCdf'; id: string; label: string };
        }>;
        customSearchDemandPolygons: Array<{
          __typename?: 'DbCustomSearchDemandPolygon';
          id: string;
          name?: string | null;
          polygon: any;
        }>;
      } | null;
      referrals: Array<{ __typename?: 'DbReferral'; receivingShop?: { __typename?: 'DbShop'; id: string } | null }>;
    } | null;
    contact?: {
      __typename?: 'DbContact';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      salutation?: SalutationEnum | null;
      title?: string | null;
      birthdate?: any | null;
      contactStatus: ContactStatusEnum;
      blocked: boolean;
      personalNote?: string | null;
      preferredLanguage?: LanguageEnum | null;
      preferredTimes: Array<{ __typename?: 'DbContactPreferredTime'; preferredContactTime: ContactTimeEnum }>;
      emailAddresses: Array<{ __typename?: 'DbContactEmailAddress'; address: string; preferred: boolean }>;
      phoneNumbers: Array<{
        __typename?: 'DbContactPhoneNumber';
        number: string;
        preferred: boolean;
        type: PhoneNumberTypeEnum;
      }>;
    } | null;
    callActivity?: {
      __typename?: 'DbCallActivity';
      call?: {
        __typename?: 'DbCall';
        id: string;
        callSid: string;
        createdAt: any;
        duration?: any | null;
        outcome?: CallOutcomeEnum | null;
        note?: string | null;
        status: CallStatusEnum;
        shopId: string;
        recipient: string;
        caller: string;
        callDirection: { __typename?: 'EnumsCallDirection'; name: string };
        agent?: {
          __typename: 'DbAgent';
          createdAt: any;
          primaryShopId?: string | null;
          userName: string;
          salutation?: SalutationEnum | null;
          betaTester: boolean;
          energyAdvisoryEnabled: boolean;
          id: string;
          firstName: string;
          lastName: string;
          email: string;
          status: AgentStatusEnum;
          mobilePhoneNumber?: string | null;
          officePhoneNumber?: string | null;
          nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
          profiles: Array<{
            __typename?: 'DbAgentProfile';
            images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
          }>;
        } | null;
      } | null;
    } | null;
    auditActivity?: { __typename?: 'DbAuditActivity'; content?: any | null; id: string } | null;
    consentActivity?: {
      __typename?: 'DbConsentActivity';
      id: string;
      consentId: string;
      content?: any | null;
      consent: {
        __typename?: 'DbConsent';
        createdAt: any;
        status: ConsentStatusEnum;
        licenseId?: string | null;
        expiredAt?: any | null;
        customerAccountId: string;
        contactForm: string;
        ipAddress?: string | null;
        consentType: ConsentTypeEnum;
        consentText?: string | null;
        url?: string | null;
        customerAccount: { __typename?: 'DbCustomerAccount'; emailAddress: string };
      };
    } | null;
    emailActivity?: {
      __typename?: 'DbEmailActivity';
      id: string;
      email: {
        __typename?: 'DbEmail';
        id: string;
        status?: EmailStatusEnum | null;
        from: string;
        to: Array<string>;
        cc?: Array<string> | null;
        bcc?: Array<string> | null;
        subject: string;
        createdAt: any;
        direction?: EmailDirectionEnum | null;
        body: string;
        propertyIds: Array<string>;
        trackings: Array<{
          __typename?: 'DbEmailTracking';
          id: string;
          count: number;
          type: EmailTrackingTypeEnum;
          recipient?: string | null;
          updatedAt: any;
          linkUrl?: string | null;
        }>;
        attachments: Array<{
          __typename?: 'DbEmailAttachment';
          document: { __typename?: 'DbDocument'; id: string; contentType: string; name: string };
        }>;
      };
    } | null;
    exposeSharedActivity?: {
      __typename?: 'DbExposeeSharedActivity';
      id: string;
      propertyIds: Array<string>;
      status: string;
      title?: string | null;
      updatedAt: any;
      createdAt: any;
      comment?: string | null;
    } | null;
    taskActivity?: {
      __typename?: 'DbTaskActivity';
      task: {
        __typename?: 'DbTask';
        id: string;
        type: TaskTypeEnum;
        dueDatetime?: any | null;
        title?: string | null;
        status?: TaskAppointmentStatusEnum | null;
        agentId: string;
        teamId?: string | null;
        comment?: string | null;
        propertyIds: Array<string>;
        priority?: PriorityEnum | null;
        go3TaskKey?: number | null;
        taskContacts: Array<{ __typename?: 'DbTaskContact'; contactId: string }>;
        taskLeads: Array<{ __typename?: 'DbTaskLead'; leadId: string }>;
        taskAgents: Array<{
          __typename?: 'DbTaskAgent';
          agent: {
            __typename: 'DbAgent';
            id: string;
            firstName: string;
            lastName: string;
            email: string;
            status: AgentStatusEnum;
            mobilePhoneNumber?: string | null;
            officePhoneNumber?: string | null;
            profiles: Array<{
              __typename?: 'DbAgentProfile';
              images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
            }>;
          };
        }>;
      };
    } | null;
    appointmentActivity?: {
      __typename?: 'DbAppointmentActivity';
      createdAt: any;
      appointment: {
        __typename?: 'DbAppointment';
        id: string;
        type: AppointmentTypeEnum;
        startTime: any;
        endTime?: any | null;
        location?: string | null;
        subject?: string | null;
        status?: TaskAppointmentStatusEnum | null;
        agentId: string;
        teamId?: string | null;
        comment?: string | null;
        feedback?: string | null;
        cost?: any | null;
        propertyIds: Array<string>;
        go3AppointmentKey?: number | null;
        agent: { __typename?: 'DbAgent'; firstName: string; lastName: string };
        appointmentContacts: Array<{ __typename?: 'DbAppointmentContact'; contactId: string }>;
        appointmentLeads: Array<{ __typename?: 'DbAppointmentLead'; leadId: string }>;
        appointmentAgents: Array<{
          __typename?: 'DbAppointmentAgent';
          agent: {
            __typename: 'DbAgent';
            id: string;
            firstName: string;
            lastName: string;
            email: string;
            status: AgentStatusEnum;
            mobilePhoneNumber?: string | null;
            officePhoneNumber?: string | null;
            profiles: Array<{
              __typename?: 'DbAgentProfile';
              images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
            }>;
          };
        }>;
      };
    } | null;
    referralActivity?: {
      __typename?: 'DbReferralActivity';
      id: string;
      referral: {
        __typename?: 'DbReferral';
        id: string;
        createdAt: any;
        acceptedRefusedDate?: any | null;
        referralStatus: ReferralStatusEnum;
        commission: any;
        note?: string | null;
        sendingContactId?: string | null;
        leadId?: string | null;
        receivingLeadId?: string | null;
        receivingGo3ContactUtag?: string | null;
        type: ReferralTypeEnum;
        referralAgentNote?: string | null;
        referralNotPossibleReason?: ReferralNotPossibleReasonEnum | null;
        sendingShopId: string;
        receivingShopId?: string | null;
        receivingTeamId?: string | null;
        sendingTeamId?: string | null;
        snapshotId: string;
        leadByReceivingLeadId?: {
          __typename?: 'DbLead';
          contact: {
            __typename?: 'DbContact';
            id: string;
            blocked: boolean;
            emailAddresses: Array<{ __typename?: 'DbContactEmailAddress'; address: string; preferred: boolean }>;
            phoneNumbers: Array<{ __typename?: 'DbContactPhoneNumber'; number: string; preferred: boolean }>;
          };
          agent?: {
            __typename: 'DbAgent';
            createdAt: any;
            primaryShopId?: string | null;
            userName: string;
            salutation?: SalutationEnum | null;
            betaTester: boolean;
            energyAdvisoryEnabled: boolean;
            id: string;
            firstName: string;
            lastName: string;
            email: string;
            status: AgentStatusEnum;
            mobilePhoneNumber?: string | null;
            officePhoneNumber?: string | null;
            nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
            profiles: Array<{
              __typename?: 'DbAgentProfile';
              images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
            }>;
          } | null;
          team?: {
            __typename?: 'DbTeam';
            normalizedName?: string | null;
            description?: string | null;
            status?: TeamStatusEnum | null;
            id: string;
            name: string;
            shopId: string;
            email?: string | null;
            teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
          } | null;
          baseActivities: Array<{
            __typename?: 'DbBaseActivity';
            callActivity?: {
              __typename?: 'DbCallActivity';
              call?: {
                __typename?: 'DbCall';
                id: string;
                callSid: string;
                createdAt: any;
                duration?: any | null;
                outcome?: CallOutcomeEnum | null;
                note?: string | null;
                status: CallStatusEnum;
                shopId: string;
                recipient: string;
                caller: string;
                callDirection: { __typename?: 'EnumsCallDirection'; name: string };
                agent?: {
                  __typename: 'DbAgent';
                  createdAt: any;
                  primaryShopId?: string | null;
                  userName: string;
                  salutation?: SalutationEnum | null;
                  betaTester: boolean;
                  energyAdvisoryEnabled: boolean;
                  id: string;
                  firstName: string;
                  lastName: string;
                  email: string;
                  status: AgentStatusEnum;
                  mobilePhoneNumber?: string | null;
                  officePhoneNumber?: string | null;
                  nylasEmailIntegrations: Array<{
                    __typename?: 'DbNylasEmailIntegration';
                    email: string;
                    active: boolean;
                  }>;
                  profiles: Array<{
                    __typename?: 'DbAgentProfile';
                    images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
                  }>;
                } | null;
              } | null;
            } | null;
            emailActivity?: {
              __typename?: 'DbEmailActivity';
              id: string;
              email: {
                __typename?: 'DbEmail';
                id: string;
                status?: EmailStatusEnum | null;
                from: string;
                to: Array<string>;
                cc?: Array<string> | null;
                bcc?: Array<string> | null;
                subject: string;
                createdAt: any;
                direction?: EmailDirectionEnum | null;
                body: string;
                propertyIds: Array<string>;
                trackings: Array<{
                  __typename?: 'DbEmailTracking';
                  id: string;
                  count: number;
                  type: EmailTrackingTypeEnum;
                  recipient?: string | null;
                  updatedAt: any;
                  linkUrl?: string | null;
                }>;
                attachments: Array<{
                  __typename?: 'DbEmailAttachment';
                  document: { __typename?: 'DbDocument'; id: string; contentType: string; name: string };
                }>;
              };
            } | null;
            appointmentActivity?: {
              __typename?: 'DbAppointmentActivity';
              createdAt: any;
              appointment: {
                __typename?: 'DbAppointment';
                id: string;
                type: AppointmentTypeEnum;
                startTime: any;
                endTime?: any | null;
                location?: string | null;
                subject?: string | null;
                status?: TaskAppointmentStatusEnum | null;
                agentId: string;
                teamId?: string | null;
                comment?: string | null;
                feedback?: string | null;
                cost?: any | null;
                propertyIds: Array<string>;
                go3AppointmentKey?: number | null;
                agent: { __typename?: 'DbAgent'; firstName: string; lastName: string };
                appointmentContacts: Array<{ __typename?: 'DbAppointmentContact'; contactId: string }>;
                appointmentLeads: Array<{ __typename?: 'DbAppointmentLead'; leadId: string }>;
                appointmentAgents: Array<{
                  __typename?: 'DbAppointmentAgent';
                  agent: {
                    __typename: 'DbAgent';
                    id: string;
                    firstName: string;
                    lastName: string;
                    email: string;
                    status: AgentStatusEnum;
                    mobilePhoneNumber?: string | null;
                    officePhoneNumber?: string | null;
                    profiles: Array<{
                      __typename?: 'DbAgentProfile';
                      images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
                    }>;
                  };
                }>;
              };
            } | null;
          }>;
        } | null;
        agent: {
          __typename: 'DbAgent';
          createdAt: any;
          primaryShopId?: string | null;
          userName: string;
          salutation?: SalutationEnum | null;
          betaTester: boolean;
          energyAdvisoryEnabled: boolean;
          id: string;
          firstName: string;
          lastName: string;
          email: string;
          status: AgentStatusEnum;
          mobilePhoneNumber?: string | null;
          officePhoneNumber?: string | null;
          nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
          profiles: Array<{
            __typename?: 'DbAgentProfile';
            images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
          }>;
        };
        agentByReceivingAgentId?: {
          __typename: 'DbAgent';
          createdAt: any;
          primaryShopId?: string | null;
          userName: string;
          salutation?: SalutationEnum | null;
          betaTester: boolean;
          energyAdvisoryEnabled: boolean;
          id: string;
          firstName: string;
          lastName: string;
          email: string;
          status: AgentStatusEnum;
          mobilePhoneNumber?: string | null;
          officePhoneNumber?: string | null;
          nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
          profiles: Array<{
            __typename?: 'DbAgentProfile';
            images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
          }>;
        } | null;
        contactByReceivingLeadIdWrapper?: {
          __typename?: 'DbOneWayMappingFromLeadIdToContactId';
          contact?: { __typename?: 'DbContact'; id: string } | null;
        } | null;
        receivingShop?: {
          __typename?: 'DbShop';
          id: string;
          shopName: string;
          city?: string | null;
          countryCode: CountryEnum;
          postalCode?: string | null;
          streetName?: string | null;
          phone?: string | null;
          email?: string | null;
          status?: ShopStatusEnum | null;
          streetNumber?: string | null;
          onboardingLevel?: number | null;
          groupId?: string | null;
          latitude?: any | null;
          longitude?: any | null;
          shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
          licensePartner: {
            __typename?: 'DbLicensePartner';
            name: string;
            isCompanyOwnedOperation?: boolean | null;
            id: string;
          };
          profiles: Array<{
            __typename?: 'DbShopProfile';
            images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
          }>;
        } | null;
        sendingShop?: {
          __typename?: 'DbShop';
          id: string;
          shopName: string;
          city?: string | null;
          countryCode: CountryEnum;
          postalCode?: string | null;
          streetName?: string | null;
          phone?: string | null;
          email?: string | null;
          status?: ShopStatusEnum | null;
          streetNumber?: string | null;
          onboardingLevel?: number | null;
          groupId?: string | null;
          latitude?: any | null;
          longitude?: any | null;
          shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
          licensePartner: {
            __typename?: 'DbLicensePartner';
            name: string;
            isCompanyOwnedOperation?: boolean | null;
            id: string;
          };
          profiles: Array<{
            __typename?: 'DbShopProfile';
            images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
          }>;
        } | null;
        snapshotLead: {
          __typename?: 'DbSnapshotLead';
          contactFirstName?: string | null;
          contactLastName?: string | null;
          intentType: IntentTypeEnum;
        };
        contact?: { __typename?: 'DbContact'; id: string } | null;
        teamByReceivingTeamId?: { __typename?: 'DbTeam'; id: string; name: string; shopId: string } | null;
        teamBySendingTeamId?: { __typename?: 'DbTeam'; id: string; name: string; shopId: string } | null;
      };
    } | null;
    documentActivity?: {
      __typename?: 'DbDocumentActivity';
      id: string;
      documentReference?: {
        __typename?: 'DbDocumentReference';
        contactId?: string | null;
        contentType: string;
        createdAt: any;
        createdBy: string;
        description?: string | null;
        fileName: string;
        teamId?: string | null;
        privacyLevel: PrivacyLevelEnum;
        referenceId: string;
        referenceType: EntityTypeEnum;
        sizeKb?: number | null;
        id: string;
        displayName: string;
        createdByAgent?: {
          __typename: 'DbAgent';
          createdAt: any;
          primaryShopId?: string | null;
          userName: string;
          salutation?: SalutationEnum | null;
          betaTester: boolean;
          energyAdvisoryEnabled: boolean;
          id: string;
          firstName: string;
          lastName: string;
          email: string;
          status: AgentStatusEnum;
          mobilePhoneNumber?: string | null;
          officePhoneNumber?: string | null;
          nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
          profiles: Array<{
            __typename?: 'DbAgentProfile';
            images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
          }>;
        } | null;
        lead?: {
          __typename?: 'DbLead';
          id: string;
          contactId: string;
          teamId?: string | null;
          intentType: IntentTypeEnum;
          createdAt: any;
          expiredAt?: any | null;
          status: LeadStatusEnum;
          timeFrame?: TimeFrameEnum | null;
          agentId?: string | null;
          shopId: string;
          personalNote?: string | null;
          go3PropertyId?: string | null;
          peLinkedPropertyId?: string | null;
          sellerRentOutSpecification?: {
            __typename?: 'DbSellerRentOutSpecification';
            type?: AssetTypeEnum | null;
            livingArea?: any | null;
            priceRangeTo?: any | null;
            priceRangeFrom?: any | null;
            priceRangeUnit: CurrencyEnum;
            rentPriceUnit: CurrencyEnum;
            plotArea?: any | null;
            address?: {
              __typename?: 'DbAddress';
              city?: string | null;
              postalCode?: string | null;
              fullAddress?: string | null;
            } | null;
          } | null;
          buyerToRentSpecification?: {
            __typename?: 'DbBuyerToRentSpecification';
            searchDemandId?: string | null;
            type?: AssetTypeEnum | null;
            city?: string | null;
            livingAreaMax?: number | null;
            livingAreaMin?: number | null;
            totalSurfaceMax?: number | null;
            totalSurfaceMin?: number | null;
            plotSurfaceMax?: number | null;
            plotSurfaceMin?: number | null;
            priceMax?: number | null;
            priceMin?: number | null;
            currency: CurrencyEnum;
            area?: string | null;
            country?: string | null;
          } | null;
          emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
          leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
        } | null;
      } | null;
    } | null;
    contactChangeLogs: Array<{
      __typename?: 'DbContactChangeLog';
      id: string;
      oldValue?: string | null;
      newValue?: string | null;
      fieldName?: string | null;
    }>;
    base_activity_legacy_go3_change_logs: Array<{
      __typename?: 'DbLegacyGo3ChangeLog';
      id: string;
      oldValue?: string | null;
      newValue?: string | null;
      fieldName?: string | null;
    }>;
  }>;
};

export type LeadListIdsQueryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DbLeadOrderBy> | DbLeadOrderBy>;
  where?: InputMaybe<DbLeadBoolExp>;
}>;

export type LeadListIdsQueryQuery = { __typename?: 'query_root'; dbLead: Array<{ __typename?: 'DbLead'; id: string }> };

export type LeadListQueryQueryVariables = Exact<{
  order_by?: InputMaybe<Array<DbLeadOrderBy> | DbLeadOrderBy>;
  where?: InputMaybe<DbLeadBoolExp>;
}>;

export type LeadListQueryQuery = {
  __typename?: 'query_root';
  dbLead: Array<{
    __typename?: 'DbLead';
    id: string;
    status: LeadStatusEnum;
    source: LeadSourceEnum;
    createdAt: any;
    priority?: PriorityEnum | null;
    statusReason?: LeadStatusReasonEnum | null;
    statusNote?: string | null;
    contactId: string;
    intentType: IntentTypeEnum;
    timeFrame?: TimeFrameEnum | null;
    go3PropertyId?: string | null;
    peLinkedPropertyId?: string | null;
    agentId?: string | null;
    shopId: string;
    teamId?: string | null;
    lastInteractionActivity?: {
      __typename?: 'DbBaseActivity';
      orderDate: any;
      category: BaseActivityCategoryEnum;
      type: ActivityTypeEnum;
      createdBy: string;
      createdAt: any;
      shopId?: string | null;
      taskActivity?: { __typename?: 'DbTaskActivity'; task: { __typename?: 'DbTask'; type: TaskTypeEnum } } | null;
      appointmentActivity?: {
        __typename?: 'DbAppointmentActivity';
        appointment: { __typename?: 'DbAppointment'; type: AppointmentTypeEnum };
      } | null;
    } | null;
    buyerToRentSpecification?: { __typename?: 'DbBuyerToRentSpecification'; searchDemandId?: string | null } | null;
    contact: {
      __typename?: 'DbContact';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      contactStatus: ContactStatusEnum;
      blocked: boolean;
      phoneNumbers: Array<{
        __typename?: 'DbContactPhoneNumber';
        preferred: boolean;
        number: string;
        internationalNumber?: string | null;
        type: PhoneNumberTypeEnum;
      }>;
      emailAddresses: Array<{ __typename?: 'DbContactEmailAddress'; address: string; preferred: boolean }>;
      preferredTimes: Array<{ __typename?: 'DbContactPreferredTime'; preferredContactTime: ContactTimeEnum }>;
    };
    agent?: {
      __typename: 'DbAgent';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
    shop: {
      __typename?: 'DbShop';
      id: string;
      shopGroup?: {
        __typename?: 'DbShopGroup';
        name: string;
        shops: Array<{ __typename?: 'DbShop'; id: string; status?: ShopStatusEnum | null }>;
      } | null;
    };
    emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
    lastActivity: Array<{
      __typename?: 'DbBaseActivity';
      type: ActivityTypeEnum;
      createdBy: string;
      createdAt: any;
      shopId?: string | null;
    }>;
  }>;
};

export type LeadInfoListQueryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DbLeadOrderBy> | DbLeadOrderBy>;
  where?: InputMaybe<DbLeadBoolExp>;
}>;

export type LeadInfoListQueryQuery = {
  __typename?: 'query_root';
  dbLead: Array<{
    __typename?: 'DbLead';
    id: string;
    contactId: string;
    teamId?: string | null;
    intentType: IntentTypeEnum;
    createdAt: any;
    expiredAt?: any | null;
    status: LeadStatusEnum;
    timeFrame?: TimeFrameEnum | null;
    agentId?: string | null;
    shopId: string;
    personalNote?: string | null;
    go3PropertyId?: string | null;
    peLinkedPropertyId?: string | null;
    sellerRentOutSpecification?: {
      __typename?: 'DbSellerRentOutSpecification';
      type?: AssetTypeEnum | null;
      livingArea?: any | null;
      priceRangeTo?: any | null;
      priceRangeFrom?: any | null;
      priceRangeUnit: CurrencyEnum;
      rentPriceUnit: CurrencyEnum;
      plotArea?: any | null;
      address?: {
        __typename?: 'DbAddress';
        city?: string | null;
        postalCode?: string | null;
        fullAddress?: string | null;
      } | null;
    } | null;
    buyerToRentSpecification?: {
      __typename?: 'DbBuyerToRentSpecification';
      searchDemandId?: string | null;
      type?: AssetTypeEnum | null;
      city?: string | null;
      livingAreaMax?: number | null;
      livingAreaMin?: number | null;
      totalSurfaceMax?: number | null;
      totalSurfaceMin?: number | null;
      plotSurfaceMax?: number | null;
      plotSurfaceMin?: number | null;
      priceMax?: number | null;
      priceMin?: number | null;
      currency: CurrencyEnum;
      area?: string | null;
      country?: string | null;
    } | null;
    emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
    leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
  }>;
};

export type LeadSourceOptionsQueryVariables = Exact<{
  shopIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type LeadSourceOptionsQuery = {
  __typename?: 'query_root';
  dbLead: Array<{ __typename?: 'DbLead'; source: LeadSourceEnum }>;
};

export type LeadStatisticsQueryVariables = Exact<{
  where?: InputMaybe<DbLeadStatisticsBoolExp>;
}>;

export type LeadStatisticsQuery = {
  __typename?: 'query_root';
  dbLeadStatistics: Array<{
    __typename?: 'DbLeadStatistics';
    count?: any | null;
    originalLeadSource?: string | null;
    createdDate?: any | null;
  }>;
};

export type LicensePartnersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DbLicensePartnerOrderBy> | DbLicensePartnerOrderBy>;
  where?: InputMaybe<DbLicensePartnerBoolExp>;
}>;

export type LicensePartnersQuery = {
  __typename?: 'query_root';
  dbLicensePartner: Array<{
    __typename?: 'DbLicensePartner';
    name: string;
    id: string;
    isCompanyOwnedOperation?: boolean | null;
    status?: LicensePartnerStatusEnum | null;
    debtorNumber?: string | null;
    primaryShopId?: string | null;
    shops: Array<{ __typename?: 'DbShop'; id: string; status?: ShopStatusEnum | null }>;
    profiles: Array<{
      __typename?: 'DbLicensePartnerProfile';
      licensePartnerProfileImages: Array<{ __typename?: 'DbLicensePartnerProfileImage'; gcpFileName: string }>;
    }>;
  }>;
};

export type LipaProfileQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;

export type LipaProfileQuery = {
  __typename?: 'query_root';
  dbLicensePartnerProfile: Array<{
    __typename?: 'DbLicensePartnerProfile';
    id: string;
    licensePartnerId: string;
    emailRecruiting?: string | null;
    emailOther?: string | null;
    facebookUrl?: string | null;
    instagramUrl?: string | null;
    twitterUrl?: string | null;
    xingUrl?: string | null;
    linkedInUrl?: string | null;
    youtubeUrl?: string | null;
    tiktokUrl?: string | null;
    googleAdsId?: string | null;
    googlePlaceId?: string | null;
    googleAnalyticsAccountId?: string | null;
    microsoftId?: string | null;
    facebookId?: string | null;
    criteoId?: string | null;
    linkedinId?: string | null;
    imprintUrl?: string | null;
    privacyPolicyUrl?: string | null;
    clientId?: string | null;
    optin?: string | null;
    recipientId?: string | null;
    activated?: boolean | null;
    featuredAgents: Array<{
      __typename?: 'DbLicensePartnerProfileFeaturedAgent';
      agentId: string;
      index: number;
      agent: {
        __typename: 'DbAgent';
        createdAt: any;
        primaryShopId?: string | null;
        userName: string;
        salutation?: SalutationEnum | null;
        betaTester: boolean;
        energyAdvisoryEnabled: boolean;
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        userTeamAssignments: Array<{
          __typename?: 'DbUserTeamAssignment';
          role: UserRoleEnum;
          team: {
            __typename?: 'DbTeam';
            normalizedName?: string | null;
            description?: string | null;
            status?: TeamStatusEnum | null;
            id: string;
            name: string;
            shopId: string;
            email?: string | null;
            shop: {
              __typename?: 'DbShop';
              id: string;
              shopName: string;
              city?: string | null;
              countryCode: CountryEnum;
              postalCode?: string | null;
              streetName?: string | null;
              phone?: string | null;
              email?: string | null;
              status?: ShopStatusEnum | null;
              streetNumber?: string | null;
              onboardingLevel?: number | null;
              groupId?: string | null;
              latitude?: any | null;
              longitude?: any | null;
              shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
              licensePartner: {
                __typename?: 'DbLicensePartner';
                name: string;
                isCompanyOwnedOperation?: boolean | null;
                id: string;
              };
              profiles: Array<{
                __typename?: 'DbShopProfile';
                images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
              }>;
            };
            teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
          };
        }>;
        userShopAssignments: Array<{
          __typename?: 'DbUserShopAssignment';
          role: UserRoleEnum;
          shop: { __typename?: 'DbShop'; shopName: string; id: string };
        }>;
        nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      };
    }>;
    propertyPreviews: Array<{
      __typename?: 'DbLicensePartnerProfilePropertyPreview';
      utag: string;
      type: MarketingTypeEnum;
      index: number;
    }>;
    heroPhoto: Array<{ __typename?: 'DbLicensePartnerProfileImage'; id: string; title: string; gcpFileName: string }>;
    featuredContentPhotos: Array<{
      __typename?: 'DbLicensePartnerProfileImage';
      id: string;
      title: string;
      gcpFileName: string;
    }>;
    licensePartnerProfileLanguageVariants: Array<{
      __typename?: 'DbLicensePartnerProfileLanguageVariant';
      displayName?: string | null;
      slugOverride?: string | null;
      lang: LanguageEnum;
      heroDesc?: string | null;
      heroHeadline?: string | null;
      heroIsAiTranslated?: boolean | null;
      url?: string | null;
      imprint?: string | null;
      imprintIsAiTranslated?: boolean | null;
      privacyPolicy?: string | null;
      privacyPolicyIsAiTranslated?: boolean | null;
      introHeadline?: string | null;
      introDescription?: string | null;
      introIsAiTranslated?: boolean | null;
      shopOverviewHeadline?: string | null;
      shopOverviewDescription?: string | null;
      shopOverviewIsAiTranslated?: boolean | null;
      careerDesc?: string | null;
      careerHeadline?: string | null;
      careerLink?: string | null;
      careerIsAiTranslated?: boolean | null;
      featuredContentDescription?: string | null;
      featuredContentHeadline?: string | null;
      featuredContentIsAiTranslated?: boolean | null;
      featuredContentTeasers: Array<{
        __typename?: 'DbLicensePartnerProfileFeaturedContentTeaser';
        headline?: string | null;
        description?: string | null;
        link?: string | null;
      }>;
    }>;
    careerImages: Array<{
      __typename?: 'DbLicensePartnerProfileImage';
      id: string;
      title: string;
      gcpFileName: string;
    }>;
  }>;
  publishedProfile: Array<{ __typename?: 'DbLicensePartnerProfile'; published?: string | null }>;
};

export type GetPropertyDetailQueryQueryVariables = Exact<{
  utag: Scalars['String'];
}>;

export type GetPropertyDetailQueryQuery = {
  __typename?: 'query_root';
  propertyEngine?: {
    __typename?: 'propertyEngineQuery';
    getListingByUtag: {
      __typename?: 'PropertyEngineListing';
      utag: string;
      type: PropertyEngineListingType;
      status: PropertyEngineListingStatus;
      cdfs?: Array<string | null> | null;
      assignedAgentId?: string | null;
      assignedTeamId?: string | null;
      currency?: string | null;
      district?: string | null;
      area?: string | null;
      fileAs?: string | null;
      monthlyNetRent?: number | null;
      monthlyTotalRent?: number | null;
      monthlyUtilities?: number | null;
      askingPrice?: number | null;
      lastActivation?: any | null;
      listingOwnerContactId?: string | null;
      asset:
        | {
            __typename?: 'PropertyEngineApartment';
            type?: PropertyEngineAssetType | null;
            subType?: PropertyEngineAssetSubType | null;
            variation?: PropertyEngineAssetVariation | null;
            numberOfRooms?: number | null;
            numberOfBedrooms?: number | null;
            numberOfBathrooms?: number | null;
            totalSurface?: number | null;
            surfaceLivingArea?: number | null;
          }
        | {
            __typename?: 'PropertyEngineHouse';
            type?: PropertyEngineAssetType | null;
            subType?: PropertyEngineAssetSubType | null;
            variation?: PropertyEngineAssetVariation | null;
            numberOfRooms?: number | null;
            numberOfBedrooms?: number | null;
            numberOfBathrooms?: number | null;
            totalSurface?: number | null;
            surfaceLivingArea?: number | null;
          }
        | {
            __typename?: 'PropertyEngineLand';
            type?: PropertyEngineAssetType | null;
            subType?: PropertyEngineAssetSubType | null;
            variation?: PropertyEngineAssetVariation | null;
            plotSurface?: number | null;
          };
      address?: {
        __typename?: 'PropertyEngineAddress';
        city?: string | null;
        postalCode?: string | null;
        coverLetterAddress?: string | null;
        streetName?: string | null;
        streetNumber?: string | null;
        countryCode?: PropertyEngineCountryCode | null;
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      previewImage?: { __typename?: 'PropertyEngineProxyImageDetail'; imageUrl: string } | null;
      marketingDetails: Array<{
        __typename?: 'PropertyEngineMarketingDetail';
        language: PropertyEngineLanguage;
        title: string;
        premiumExposeEmail?: {
          __typename?: 'PropertyEnginePremiumExposeEmail';
          subject?: string | null;
          description?: string | null;
          bulletPoints?: Array<string | null> | null;
          bulletPointsTitle?: string | null;
        } | null;
      }>;
      internetProfile: {
        __typename?: 'PropertyEngineInternetProfile';
        priceOnRequest?: boolean | null;
        portals?: Array<{
          __typename?: 'PropertyEngineListingInternetPortal';
          includeInExport?: boolean | null;
        }> | null;
      };
    };
  } | null;
};

export type ListingsQueryQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where: PropertyEngineSearchListingsFilterInput;
  shopId: Scalars['PropertyEngineUUID'];
}>;

export type ListingsQueryQuery = {
  __typename?: 'query_root';
  propertyEngine?: {
    __typename?: 'propertyEngineQuery';
    searchListings?: {
      __typename?: 'PropertyEngineSearchListingsResponse';
      listings?: Array<{
        __typename?: 'PropertyEngineListing';
        utag: string;
        type: PropertyEngineListingType;
        status: PropertyEngineListingStatus;
        cdfs?: Array<string | null> | null;
        assignedAgentId?: string | null;
        assignedTeamId?: string | null;
        currency?: string | null;
        district?: string | null;
        area?: string | null;
        fileAs?: string | null;
        monthlyNetRent?: number | null;
        monthlyTotalRent?: number | null;
        monthlyUtilities?: number | null;
        askingPrice?: number | null;
        lastActivation?: any | null;
        listingOwnerContactId?: string | null;
        asset:
          | {
              __typename?: 'PropertyEngineApartment';
              type?: PropertyEngineAssetType | null;
              subType?: PropertyEngineAssetSubType | null;
              variation?: PropertyEngineAssetVariation | null;
              numberOfRooms?: number | null;
              numberOfBedrooms?: number | null;
              numberOfBathrooms?: number | null;
              totalSurface?: number | null;
              surfaceLivingArea?: number | null;
            }
          | {
              __typename?: 'PropertyEngineHouse';
              type?: PropertyEngineAssetType | null;
              subType?: PropertyEngineAssetSubType | null;
              variation?: PropertyEngineAssetVariation | null;
              numberOfRooms?: number | null;
              numberOfBedrooms?: number | null;
              numberOfBathrooms?: number | null;
              totalSurface?: number | null;
              surfaceLivingArea?: number | null;
            }
          | {
              __typename?: 'PropertyEngineLand';
              type?: PropertyEngineAssetType | null;
              subType?: PropertyEngineAssetSubType | null;
              variation?: PropertyEngineAssetVariation | null;
              plotSurface?: number | null;
            };
        address?: {
          __typename?: 'PropertyEngineAddress';
          city?: string | null;
          postalCode?: string | null;
          coverLetterAddress?: string | null;
          streetName?: string | null;
          streetNumber?: string | null;
          countryCode?: PropertyEngineCountryCode | null;
          latitude?: number | null;
          longitude?: number | null;
        } | null;
        previewImage?: { __typename?: 'PropertyEngineProxyImageDetail'; imageUrl: string } | null;
        marketingDetails: Array<{
          __typename?: 'PropertyEngineMarketingDetail';
          language: PropertyEngineLanguage;
          title: string;
          premiumExposeEmail?: {
            __typename?: 'PropertyEnginePremiumExposeEmail';
            subject?: string | null;
            description?: string | null;
            bulletPoints?: Array<string | null> | null;
            bulletPointsTitle?: string | null;
          } | null;
        }>;
        internetProfile: {
          __typename?: 'PropertyEngineInternetProfile';
          priceOnRequest?: boolean | null;
          portals?: Array<{
            __typename?: 'PropertyEngineListingInternetPortal';
            includeInExport?: boolean | null;
          }> | null;
        };
      }> | null;
    } | null;
  } | null;
};

export type MatchingCountQueryQueryVariables = Exact<{
  searchDemandId: Scalars['PropertyEngineUUID'];
}>;

export type MatchingCountQueryQuery = {
  __typename?: 'query_root';
  propertyEngine?: {
    __typename?: 'propertyEngineQuery';
    findMatchingListingsForSearchDemand: { __typename?: 'PropertyEngineMatchingListings'; total?: number | null };
  } | null;
};

export type MatchingsQueryQueryVariables = Exact<{
  searchDemandId: Scalars['PropertyEngineUUID'];
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<PropertyEngineListingOrder>;
  addressQuery?: InputMaybe<Scalars['String']>;
  listingStatus?: InputMaybe<Array<PropertyEngineListingStatus> | PropertyEngineListingStatus>;
}>;

export type MatchingsQueryQuery = {
  __typename?: 'query_root';
  propertyEngine?: {
    __typename?: 'propertyEngineQuery';
    findMatchingListingsForSearchDemand: {
      __typename?: 'PropertyEngineMatchingListings';
      total?: number | null;
      listings?: Array<{
        __typename?: 'PropertyEngineListing';
        utag: string;
        type: PropertyEngineListingType;
        status: PropertyEngineListingStatus;
        cdfs?: Array<string | null> | null;
        assignedAgentId?: string | null;
        assignedTeamId?: string | null;
        currency?: string | null;
        district?: string | null;
        area?: string | null;
        fileAs?: string | null;
        monthlyNetRent?: number | null;
        monthlyTotalRent?: number | null;
        monthlyUtilities?: number | null;
        askingPrice?: number | null;
        lastActivation?: any | null;
        listingOwnerContactId?: string | null;
        asset:
          | {
              __typename?: 'PropertyEngineApartment';
              type?: PropertyEngineAssetType | null;
              subType?: PropertyEngineAssetSubType | null;
              variation?: PropertyEngineAssetVariation | null;
              numberOfRooms?: number | null;
              numberOfBedrooms?: number | null;
              numberOfBathrooms?: number | null;
              totalSurface?: number | null;
              surfaceLivingArea?: number | null;
            }
          | {
              __typename?: 'PropertyEngineHouse';
              type?: PropertyEngineAssetType | null;
              subType?: PropertyEngineAssetSubType | null;
              variation?: PropertyEngineAssetVariation | null;
              numberOfRooms?: number | null;
              numberOfBedrooms?: number | null;
              numberOfBathrooms?: number | null;
              totalSurface?: number | null;
              surfaceLivingArea?: number | null;
            }
          | {
              __typename?: 'PropertyEngineLand';
              type?: PropertyEngineAssetType | null;
              subType?: PropertyEngineAssetSubType | null;
              variation?: PropertyEngineAssetVariation | null;
              plotSurface?: number | null;
            };
        address?: {
          __typename?: 'PropertyEngineAddress';
          city?: string | null;
          postalCode?: string | null;
          coverLetterAddress?: string | null;
          streetName?: string | null;
          streetNumber?: string | null;
          countryCode?: PropertyEngineCountryCode | null;
          latitude?: number | null;
          longitude?: number | null;
        } | null;
        previewImage?: { __typename?: 'PropertyEngineProxyImageDetail'; imageUrl: string } | null;
        marketingDetails: Array<{
          __typename?: 'PropertyEngineMarketingDetail';
          language: PropertyEngineLanguage;
          title: string;
          premiumExposeEmail?: {
            __typename?: 'PropertyEnginePremiumExposeEmail';
            subject?: string | null;
            description?: string | null;
            bulletPoints?: Array<string | null> | null;
            bulletPointsTitle?: string | null;
          } | null;
        }>;
        internetProfile: {
          __typename?: 'PropertyEngineInternetProfile';
          priceOnRequest?: boolean | null;
          portals?: Array<{
            __typename?: 'PropertyEngineListingInternetPortal';
            includeInExport?: boolean | null;
          }> | null;
        };
      }> | null;
    };
  } | null;
};

export type UpsertMatchingStatusMutationVariables = Exact<{
  input: Array<PropertyEngineMatchingStatusInput> | PropertyEngineMatchingStatusInput;
}>;

export type UpsertMatchingStatusMutation = {
  __typename?: 'mutation_root';
  propertyEngine?: {
    __typename?: 'propertyEngineMutation';
    upsertMatchingStatus: Array<{ __typename?: 'PropertyEngineMatchingStatusDetail'; id: string }>;
  } | null;
};

export type FindMatchingStatusQueryVariables = Exact<{
  listingUtag: Scalars['String'];
  contactId: Scalars['String'];
}>;

export type FindMatchingStatusQuery = {
  __typename?: 'query_root';
  propertyEngine?: {
    __typename?: 'propertyEngineQuery';
    findMatchingStatus?: Array<{
      __typename?: 'PropertyEngineMatchingStatusDetail';
      contactId: string;
      searchDemandId?: string | null;
      matchingState: PropertyEngineMatchingState;
      manuallyProposed?: boolean | null;
      feedback?: string | null;
      createdAt: string;
      buyerToRentSpecification?: {
        __typename?: 'DbBuyerToRentSpecification';
        area?: string | null;
        bedroomsMax?: number | null;
        bedroomsMin?: number | null;
        city?: string | null;
        country?: string | null;
        currency: CurrencyEnum;
        district?: string | null;
        id: string;
        legacyAreaNames: Array<string>;
        legacyDistrictNames: Array<string>;
        livingAreaMax?: number | null;
        livingAreaMin?: number | null;
        monthlyNetRentMax?: number | null;
        monthlyNetRentMin?: number | null;
        monthlyTotalRentMax?: number | null;
        monthlyTotalRentMin?: number | null;
        plotSurfaceMax?: number | null;
        plotSurfaceMin?: number | null;
        priceMax?: number | null;
        priceMin?: number | null;
        roomsMax?: number | null;
        roomsMin?: number | null;
        subTypes?: Array<string> | null;
        variations?: Array<string> | null;
        totalSurfaceMax?: number | null;
        totalSurfaceMin?: number | null;
        type?: AssetTypeEnum | null;
        placeIds: Array<string>;
        searchDemandId?: string | null;
        lead?: { __typename?: 'DbLead'; id: string } | null;
        buyerToRentSpecificationCdfs: Array<{
          __typename?: 'DbBuyerToRentSpecificationCdf';
          cdf: { __typename?: 'DbCdf'; id: string; label: string };
        }>;
        customSearchDemandPolygons: Array<{
          __typename?: 'DbCustomSearchDemandPolygon';
          id: string;
          name?: string | null;
          polygon: any;
        }>;
      } | null;
    }> | null;
  } | null;
};

export type BatchJobExecutionQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type BatchJobExecutionQuery = {
  __typename?: 'query_root';
  dbBatchJobExecution: Array<{
    __typename?: 'DbBatchJobExecution';
    startTime?: string | null;
    status?: string | null;
    jobExecutionId: any;
    jobInstanceId: any;
    endTime?: string | null;
    exitCode?: string | null;
    exitMessage?: string | null;
    batchJobExecutionParams: Array<{
      __typename?: 'DbBatchJobExecutionParams';
      parameterName: string;
      parameterValue?: string | null;
      parameterType: string;
    }>;
    batchStepExecutions: Array<{
      __typename?: 'DbBatchStepExecution';
      exitCode?: string | null;
      exitMessage?: string | null;
      endTime?: string | null;
      readCount?: any | null;
      status?: string | null;
      startTime?: string | null;
      stepName: string;
      writeCount?: any | null;
      stepExecutionId: any;
    }>;
  }>;
};

export type NewsQueryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DbBaseActivityOrderBy> | DbBaseActivityOrderBy>;
  where?: InputMaybe<DbBaseActivityBoolExp>;
}>;

export type NewsQueryQuery = {
  __typename?: 'query_root';
  dbBaseActivity: Array<{
    __typename?: 'DbBaseActivity';
    id: string;
    createdAt: any;
    type: ActivityTypeEnum;
    auditActivity?: { __typename?: 'DbAuditActivity'; content?: any | null } | null;
    contact?: { __typename?: 'DbContact'; firstName?: string | null; lastName?: string | null; id: string } | null;
    lead?: {
      __typename?: 'DbLead';
      status: LeadStatusEnum;
      id: string;
      contactId: string;
      teamId?: string | null;
      intentType: IntentTypeEnum;
      createdAt: any;
      expiredAt?: any | null;
      timeFrame?: TimeFrameEnum | null;
      agentId?: string | null;
      shopId: string;
      personalNote?: string | null;
      go3PropertyId?: string | null;
      peLinkedPropertyId?: string | null;
      sellerRentOutSpecification?: {
        __typename?: 'DbSellerRentOutSpecification';
        type?: AssetTypeEnum | null;
        livingArea?: any | null;
        priceRangeTo?: any | null;
        priceRangeFrom?: any | null;
        priceRangeUnit: CurrencyEnum;
        rentPriceUnit: CurrencyEnum;
        plotArea?: any | null;
        address?: {
          __typename?: 'DbAddress';
          city?: string | null;
          postalCode?: string | null;
          fullAddress?: string | null;
        } | null;
      } | null;
      buyerToRentSpecification?: {
        __typename?: 'DbBuyerToRentSpecification';
        searchDemandId?: string | null;
        type?: AssetTypeEnum | null;
        city?: string | null;
        livingAreaMax?: number | null;
        livingAreaMin?: number | null;
        totalSurfaceMax?: number | null;
        totalSurfaceMin?: number | null;
        plotSurfaceMax?: number | null;
        plotSurfaceMin?: number | null;
        priceMax?: number | null;
        priceMin?: number | null;
        currency: CurrencyEnum;
        area?: string | null;
        country?: string | null;
      } | null;
      emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
      leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
    } | null;
  }>;
};

export type CountryMandatoryLanguageQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DbCountryMandatoryLanguageOrderBy> | DbCountryMandatoryLanguageOrderBy>;
  where?: InputMaybe<DbCountryMandatoryLanguageBoolExp>;
}>;

export type CountryMandatoryLanguageQuery = {
  __typename?: 'query_root';
  dbCountryMandatoryLanguage: Array<{
    __typename?: 'DbCountryMandatoryLanguage';
    id: string;
    countryCode: CountryEnum;
    lang: LanguageEnum;
  }>;
};

export type MyQueryQueryVariables = Exact<{
  dimensions: Array<PropertyEngineDashboardFeedListingsDimension> | PropertyEngineDashboardFeedListingsDimension;
  shopId: Scalars['PropertyEngineUUID'];
  dateFrom: Scalars['PropertyEngineDateTime'];
  perPage?: InputMaybe<Scalars['Int']>;
}>;

export type MyQueryQuery = {
  __typename?: 'query_root';
  propertyEngine?: {
    __typename?: 'propertyEngineQuery';
    dashboardFeedListings?: {
      __typename?: 'PropertyEngineDashboardFeedListingsResponse';
      total?: number | null;
      results?: Array<{
        __typename?: 'PropertyEngineDashboardFeedResult';
        listing: {
          __typename?: 'PropertyEngineListing';
          id: string;
          type: PropertyEngineListingType;
          status: PropertyEngineListingStatus;
          utag: string;
          fileAs?: string | null;
          lastActivation?: any | null;
          lastAssignedAgentChange?: any | null;
          lastPriceReduction?: any | null;
          previewImage?: { __typename?: 'PropertyEngineProxyImageDetail'; imageUrl: string } | null;
          pictures?: Array<{ __typename?: 'PropertyEngineProxyImageDetail'; imageUrl: string }> | null;
        };
      }> | null;
    } | null;
  } | null;
};

export type ReferralQueryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DbReferralOrderBy> | DbReferralOrderBy>;
  where?: InputMaybe<DbReferralBoolExp>;
}>;

export type ReferralQueryQuery = {
  __typename?: 'query_root';
  dbReferral: Array<{
    __typename?: 'DbReferral';
    id: string;
    createdAt: any;
    acceptedRefusedDate?: any | null;
    referralStatus: ReferralStatusEnum;
    commission: any;
    note?: string | null;
    sendingContactId?: string | null;
    leadId?: string | null;
    receivingLeadId?: string | null;
    receivingGo3ContactUtag?: string | null;
    type: ReferralTypeEnum;
    referralAgentNote?: string | null;
    referralNotPossibleReason?: ReferralNotPossibleReasonEnum | null;
    sendingShopId: string;
    receivingShopId?: string | null;
    receivingTeamId?: string | null;
    sendingTeamId?: string | null;
    snapshotId: string;
    leadByReceivingLeadId?: {
      __typename?: 'DbLead';
      contact: {
        __typename?: 'DbContact';
        id: string;
        blocked: boolean;
        emailAddresses: Array<{ __typename?: 'DbContactEmailAddress'; address: string; preferred: boolean }>;
        phoneNumbers: Array<{ __typename?: 'DbContactPhoneNumber'; number: string; preferred: boolean }>;
      };
      agent?: {
        __typename: 'DbAgent';
        createdAt: any;
        primaryShopId?: string | null;
        userName: string;
        salutation?: SalutationEnum | null;
        betaTester: boolean;
        energyAdvisoryEnabled: boolean;
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      } | null;
      team?: {
        __typename?: 'DbTeam';
        normalizedName?: string | null;
        description?: string | null;
        status?: TeamStatusEnum | null;
        id: string;
        name: string;
        shopId: string;
        email?: string | null;
        teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
      } | null;
      baseActivities: Array<{
        __typename?: 'DbBaseActivity';
        callActivity?: {
          __typename?: 'DbCallActivity';
          call?: {
            __typename?: 'DbCall';
            id: string;
            callSid: string;
            createdAt: any;
            duration?: any | null;
            outcome?: CallOutcomeEnum | null;
            note?: string | null;
            status: CallStatusEnum;
            shopId: string;
            recipient: string;
            caller: string;
            callDirection: { __typename?: 'EnumsCallDirection'; name: string };
            agent?: {
              __typename: 'DbAgent';
              createdAt: any;
              primaryShopId?: string | null;
              userName: string;
              salutation?: SalutationEnum | null;
              betaTester: boolean;
              energyAdvisoryEnabled: boolean;
              id: string;
              firstName: string;
              lastName: string;
              email: string;
              status: AgentStatusEnum;
              mobilePhoneNumber?: string | null;
              officePhoneNumber?: string | null;
              nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
              profiles: Array<{
                __typename?: 'DbAgentProfile';
                images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
              }>;
            } | null;
          } | null;
        } | null;
        emailActivity?: {
          __typename?: 'DbEmailActivity';
          id: string;
          email: {
            __typename?: 'DbEmail';
            id: string;
            status?: EmailStatusEnum | null;
            from: string;
            to: Array<string>;
            cc?: Array<string> | null;
            bcc?: Array<string> | null;
            subject: string;
            createdAt: any;
            direction?: EmailDirectionEnum | null;
            body: string;
            propertyIds: Array<string>;
            trackings: Array<{
              __typename?: 'DbEmailTracking';
              id: string;
              count: number;
              type: EmailTrackingTypeEnum;
              recipient?: string | null;
              updatedAt: any;
              linkUrl?: string | null;
            }>;
            attachments: Array<{
              __typename?: 'DbEmailAttachment';
              document: { __typename?: 'DbDocument'; id: string; contentType: string; name: string };
            }>;
          };
        } | null;
        appointmentActivity?: {
          __typename?: 'DbAppointmentActivity';
          createdAt: any;
          appointment: {
            __typename?: 'DbAppointment';
            id: string;
            type: AppointmentTypeEnum;
            startTime: any;
            endTime?: any | null;
            location?: string | null;
            subject?: string | null;
            status?: TaskAppointmentStatusEnum | null;
            agentId: string;
            teamId?: string | null;
            comment?: string | null;
            feedback?: string | null;
            cost?: any | null;
            propertyIds: Array<string>;
            go3AppointmentKey?: number | null;
            agent: { __typename?: 'DbAgent'; firstName: string; lastName: string };
            appointmentContacts: Array<{ __typename?: 'DbAppointmentContact'; contactId: string }>;
            appointmentLeads: Array<{ __typename?: 'DbAppointmentLead'; leadId: string }>;
            appointmentAgents: Array<{
              __typename?: 'DbAppointmentAgent';
              agent: {
                __typename: 'DbAgent';
                id: string;
                firstName: string;
                lastName: string;
                email: string;
                status: AgentStatusEnum;
                mobilePhoneNumber?: string | null;
                officePhoneNumber?: string | null;
                profiles: Array<{
                  __typename?: 'DbAgentProfile';
                  images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
                }>;
              };
            }>;
          };
        } | null;
      }>;
    } | null;
    agent: {
      __typename: 'DbAgent';
      createdAt: any;
      primaryShopId?: string | null;
      userName: string;
      salutation?: SalutationEnum | null;
      betaTester: boolean;
      energyAdvisoryEnabled: boolean;
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    };
    agentByReceivingAgentId?: {
      __typename: 'DbAgent';
      createdAt: any;
      primaryShopId?: string | null;
      userName: string;
      salutation?: SalutationEnum | null;
      betaTester: boolean;
      energyAdvisoryEnabled: boolean;
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
    contactByReceivingLeadIdWrapper?: {
      __typename?: 'DbOneWayMappingFromLeadIdToContactId';
      contact?: { __typename?: 'DbContact'; id: string } | null;
    } | null;
    receivingShop?: {
      __typename?: 'DbShop';
      id: string;
      shopName: string;
      city?: string | null;
      countryCode: CountryEnum;
      postalCode?: string | null;
      streetName?: string | null;
      phone?: string | null;
      email?: string | null;
      status?: ShopStatusEnum | null;
      streetNumber?: string | null;
      onboardingLevel?: number | null;
      groupId?: string | null;
      latitude?: any | null;
      longitude?: any | null;
      shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
      licensePartner: {
        __typename?: 'DbLicensePartner';
        name: string;
        isCompanyOwnedOperation?: boolean | null;
        id: string;
      };
      profiles: Array<{
        __typename?: 'DbShopProfile';
        images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
    sendingShop?: {
      __typename?: 'DbShop';
      id: string;
      shopName: string;
      city?: string | null;
      countryCode: CountryEnum;
      postalCode?: string | null;
      streetName?: string | null;
      phone?: string | null;
      email?: string | null;
      status?: ShopStatusEnum | null;
      streetNumber?: string | null;
      onboardingLevel?: number | null;
      groupId?: string | null;
      latitude?: any | null;
      longitude?: any | null;
      shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
      licensePartner: {
        __typename?: 'DbLicensePartner';
        name: string;
        isCompanyOwnedOperation?: boolean | null;
        id: string;
      };
      profiles: Array<{
        __typename?: 'DbShopProfile';
        images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
    snapshotLead: {
      __typename?: 'DbSnapshotLead';
      contactFirstName?: string | null;
      contactLastName?: string | null;
      intentType: IntentTypeEnum;
    };
    contact?: { __typename?: 'DbContact'; id: string } | null;
    teamByReceivingTeamId?: { __typename?: 'DbTeam'; id: string; name: string; shopId: string } | null;
    teamBySendingTeamId?: { __typename?: 'DbTeam'; id: string; name: string; shopId: string } | null;
  }>;
};

export type TotalReferralCountQueryVariables = Exact<{
  where?: InputMaybe<DbReferralBoolExp>;
}>;

export type TotalReferralCountQuery = {
  __typename?: 'query_root';
  dbReferralAggregate: {
    __typename?: 'DbReferralAggregate';
    aggregate?: { __typename?: 'DbReferralAggregateFields'; count: number } | null;
  };
};

export type ReferralSendingShopsQueryQueryVariables = Exact<{
  where?: InputMaybe<DbReferralBoolExp>;
}>;

export type ReferralSendingShopsQueryQuery = {
  __typename?: 'query_root';
  dbReferral: Array<{
    __typename?: 'DbReferral';
    sendingShop?: { __typename?: 'DbShop'; id: string; shopName: string } | null;
  }>;
};

export type ReferralSendingAgentsQueryQueryVariables = Exact<{
  where?: InputMaybe<DbReferralBoolExp>;
}>;

export type ReferralSendingAgentsQueryQuery = {
  __typename?: 'query_root';
  dbReferral: Array<{
    __typename?: 'DbReferral';
    agent: { __typename?: 'DbAgent'; id: string; firstName: string; lastName: string };
  }>;
};

export type ReferralReceivingShopsQueryQueryVariables = Exact<{
  where?: InputMaybe<DbReferralBoolExp>;
}>;

export type ReferralReceivingShopsQueryQuery = {
  __typename?: 'query_root';
  dbReferral: Array<{
    __typename?: 'DbReferral';
    receivingShop?: { __typename?: 'DbShop'; id: string; shopName: string } | null;
  }>;
};

export type ReferralReceivingTeamsQueryQueryVariables = Exact<{
  where?: InputMaybe<DbReferralBoolExp>;
}>;

export type ReferralReceivingTeamsQueryQuery = {
  __typename?: 'query_root';
  dbReferral: Array<{
    __typename?: 'DbReferral';
    teamByReceivingTeamId?: { __typename?: 'DbTeam'; id: string; name: string } | null;
  }>;
};

export type ReferralSendingTeamsQueryQueryVariables = Exact<{
  where?: InputMaybe<DbReferralBoolExp>;
}>;

export type ReferralSendingTeamsQueryQuery = {
  __typename?: 'query_root';
  dbReferral: Array<{
    __typename?: 'DbReferral';
    teamBySendingTeamId?: { __typename?: 'DbTeam'; id: string; name: string } | null;
  }>;
};

export type ReferralReceivingAgentsQueryQueryVariables = Exact<{
  where?: InputMaybe<DbReferralBoolExp>;
}>;

export type ReferralReceivingAgentsQueryQuery = {
  __typename?: 'query_root';
  dbReferral: Array<{
    __typename?: 'DbReferral';
    agentByReceivingAgentId?: { __typename?: 'DbAgent'; id: string; firstName: string; lastName: string } | null;
  }>;
};

export type ReferralWithSnapshotLeadQueryQueryVariables = Exact<{
  referralId?: InputMaybe<Scalars['String']>;
}>;

export type ReferralWithSnapshotLeadQueryQuery = {
  __typename?: 'query_root';
  dbReferral: Array<{
    __typename?: 'DbReferral';
    id: string;
    createdAt: any;
    acceptedRefusedDate?: any | null;
    referralStatus: ReferralStatusEnum;
    commission: any;
    note?: string | null;
    sendingContactId?: string | null;
    leadId?: string | null;
    receivingLeadId?: string | null;
    receivingGo3ContactUtag?: string | null;
    type: ReferralTypeEnum;
    referralAgentNote?: string | null;
    referralNotPossibleReason?: ReferralNotPossibleReasonEnum | null;
    sendingShopId: string;
    receivingShopId?: string | null;
    receivingTeamId?: string | null;
    sendingTeamId?: string | null;
    snapshotId: string;
    snapshotLead: {
      __typename?: 'DbSnapshotLead';
      contactFirstName?: string | null;
      contactLastName?: string | null;
      intentType: IntentTypeEnum;
      id: string;
      createdAt: any;
      priority?: PriorityEnum | null;
      personalNote?: string | null;
      propertyId?: string | null;
      preferredEmailAddress?: { __typename?: 'DbSnapshotEmailAddress'; address: string } | null;
      preferredPhoneNumber?: { __typename?: 'DbSnapshotPhoneNumber'; number: string } | null;
      snapshotBuyerToRentSpecification?: {
        __typename?: 'DbSnapshotBuyerToRentSpecification';
        id: string;
        area?: string | null;
        livingAreaMax?: number | null;
        livingAreaMin?: number | null;
        monthlyNetRentMax?: number | null;
        monthlyNetRentMin?: number | null;
        monthlyTotalRentMax?: number | null;
        monthlyTotalRentMin?: number | null;
        plotSurfaceMax?: number | null;
        plotSurfaceMin?: number | null;
        currency: CurrencyEnum;
        priceMax?: number | null;
        priceMin?: number | null;
        roomsMin?: number | null;
        roomsMax?: number | null;
        type?: AssetTypeEnum | null;
        subTypes?: Array<string> | null;
        variations?: Array<string> | null;
        placeIds: Array<string>;
        snapshotCustomSearchDemandPolygons: Array<{
          __typename?: 'DbSnapshotCustomSearchDemandPolygon';
          id: string;
          name?: string | null;
          polygon: any;
        }>;
      } | null;
      snapshotSellerRentOutSpecification?: {
        __typename?: 'DbSnapshotSellerRentOutSpecification';
        id: string;
        condition?: AssetConditionEnum | null;
        floor?: number | null;
        totalFloors?: number | null;
        constructionYear?: number | null;
        plotArea?: any | null;
        livingArea?: any | null;
        numberOfRooms?: number | null;
        numberOfBathrooms?: number | null;
        variation?: AssetVariationEnum | null;
        priceRangeFrom?: any | null;
        priceRangeTo?: any | null;
        priceRangeUnit: CurrencyEnum;
        rentPriceUnit: CurrencyEnum;
        rentRangeFrom?: any | null;
        rentRangeTo?: any | null;
        city?: string | null;
        postalCode?: string | null;
        countryCode?: CountryEnum | null;
        streetName?: string | null;
        streetNumber?: string | null;
        balcony?: boolean | null;
        garden?: boolean | null;
        parking?: boolean | null;
        swimmingPool?: boolean | null;
        elevator?: boolean | null;
        garage?: boolean | null;
        guestToilet?: boolean | null;
        assetSubType?: { __typename?: 'EnumsAssetSubType'; name: string } | null;
        assetType?: { __typename?: 'EnumsAssetType'; name: string } | null;
        country?: { __typename?: 'EnumsCountry'; name: string } | null;
      } | null;
    };
    leadByReceivingLeadId?: {
      __typename?: 'DbLead';
      contact: {
        __typename?: 'DbContact';
        id: string;
        blocked: boolean;
        emailAddresses: Array<{ __typename?: 'DbContactEmailAddress'; address: string; preferred: boolean }>;
        phoneNumbers: Array<{ __typename?: 'DbContactPhoneNumber'; number: string; preferred: boolean }>;
      };
      agent?: {
        __typename: 'DbAgent';
        createdAt: any;
        primaryShopId?: string | null;
        userName: string;
        salutation?: SalutationEnum | null;
        betaTester: boolean;
        energyAdvisoryEnabled: boolean;
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      } | null;
      team?: {
        __typename?: 'DbTeam';
        normalizedName?: string | null;
        description?: string | null;
        status?: TeamStatusEnum | null;
        id: string;
        name: string;
        shopId: string;
        email?: string | null;
        teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
      } | null;
      baseActivities: Array<{
        __typename?: 'DbBaseActivity';
        callActivity?: {
          __typename?: 'DbCallActivity';
          call?: {
            __typename?: 'DbCall';
            id: string;
            callSid: string;
            createdAt: any;
            duration?: any | null;
            outcome?: CallOutcomeEnum | null;
            note?: string | null;
            status: CallStatusEnum;
            shopId: string;
            recipient: string;
            caller: string;
            callDirection: { __typename?: 'EnumsCallDirection'; name: string };
            agent?: {
              __typename: 'DbAgent';
              createdAt: any;
              primaryShopId?: string | null;
              userName: string;
              salutation?: SalutationEnum | null;
              betaTester: boolean;
              energyAdvisoryEnabled: boolean;
              id: string;
              firstName: string;
              lastName: string;
              email: string;
              status: AgentStatusEnum;
              mobilePhoneNumber?: string | null;
              officePhoneNumber?: string | null;
              nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
              profiles: Array<{
                __typename?: 'DbAgentProfile';
                images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
              }>;
            } | null;
          } | null;
        } | null;
        emailActivity?: {
          __typename?: 'DbEmailActivity';
          id: string;
          email: {
            __typename?: 'DbEmail';
            id: string;
            status?: EmailStatusEnum | null;
            from: string;
            to: Array<string>;
            cc?: Array<string> | null;
            bcc?: Array<string> | null;
            subject: string;
            createdAt: any;
            direction?: EmailDirectionEnum | null;
            body: string;
            propertyIds: Array<string>;
            trackings: Array<{
              __typename?: 'DbEmailTracking';
              id: string;
              count: number;
              type: EmailTrackingTypeEnum;
              recipient?: string | null;
              updatedAt: any;
              linkUrl?: string | null;
            }>;
            attachments: Array<{
              __typename?: 'DbEmailAttachment';
              document: { __typename?: 'DbDocument'; id: string; contentType: string; name: string };
            }>;
          };
        } | null;
        appointmentActivity?: {
          __typename?: 'DbAppointmentActivity';
          createdAt: any;
          appointment: {
            __typename?: 'DbAppointment';
            id: string;
            type: AppointmentTypeEnum;
            startTime: any;
            endTime?: any | null;
            location?: string | null;
            subject?: string | null;
            status?: TaskAppointmentStatusEnum | null;
            agentId: string;
            teamId?: string | null;
            comment?: string | null;
            feedback?: string | null;
            cost?: any | null;
            propertyIds: Array<string>;
            go3AppointmentKey?: number | null;
            agent: { __typename?: 'DbAgent'; firstName: string; lastName: string };
            appointmentContacts: Array<{ __typename?: 'DbAppointmentContact'; contactId: string }>;
            appointmentLeads: Array<{ __typename?: 'DbAppointmentLead'; leadId: string }>;
            appointmentAgents: Array<{
              __typename?: 'DbAppointmentAgent';
              agent: {
                __typename: 'DbAgent';
                id: string;
                firstName: string;
                lastName: string;
                email: string;
                status: AgentStatusEnum;
                mobilePhoneNumber?: string | null;
                officePhoneNumber?: string | null;
                profiles: Array<{
                  __typename?: 'DbAgentProfile';
                  images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
                }>;
              };
            }>;
          };
        } | null;
      }>;
    } | null;
    agent: {
      __typename: 'DbAgent';
      createdAt: any;
      primaryShopId?: string | null;
      userName: string;
      salutation?: SalutationEnum | null;
      betaTester: boolean;
      energyAdvisoryEnabled: boolean;
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    };
    agentByReceivingAgentId?: {
      __typename: 'DbAgent';
      createdAt: any;
      primaryShopId?: string | null;
      userName: string;
      salutation?: SalutationEnum | null;
      betaTester: boolean;
      energyAdvisoryEnabled: boolean;
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
    contactByReceivingLeadIdWrapper?: {
      __typename?: 'DbOneWayMappingFromLeadIdToContactId';
      contact?: { __typename?: 'DbContact'; id: string } | null;
    } | null;
    receivingShop?: {
      __typename?: 'DbShop';
      id: string;
      shopName: string;
      city?: string | null;
      countryCode: CountryEnum;
      postalCode?: string | null;
      streetName?: string | null;
      phone?: string | null;
      email?: string | null;
      status?: ShopStatusEnum | null;
      streetNumber?: string | null;
      onboardingLevel?: number | null;
      groupId?: string | null;
      latitude?: any | null;
      longitude?: any | null;
      shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
      licensePartner: {
        __typename?: 'DbLicensePartner';
        name: string;
        isCompanyOwnedOperation?: boolean | null;
        id: string;
      };
      profiles: Array<{
        __typename?: 'DbShopProfile';
        images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
    sendingShop?: {
      __typename?: 'DbShop';
      id: string;
      shopName: string;
      city?: string | null;
      countryCode: CountryEnum;
      postalCode?: string | null;
      streetName?: string | null;
      phone?: string | null;
      email?: string | null;
      status?: ShopStatusEnum | null;
      streetNumber?: string | null;
      onboardingLevel?: number | null;
      groupId?: string | null;
      latitude?: any | null;
      longitude?: any | null;
      shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
      licensePartner: {
        __typename?: 'DbLicensePartner';
        name: string;
        isCompanyOwnedOperation?: boolean | null;
        id: string;
      };
      profiles: Array<{
        __typename?: 'DbShopProfile';
        images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
    contact?: { __typename?: 'DbContact'; id: string } | null;
    teamByReceivingTeamId?: { __typename?: 'DbTeam'; id: string; name: string; shopId: string } | null;
    teamBySendingTeamId?: { __typename?: 'DbTeam'; id: string; name: string; shopId: string } | null;
  }>;
};

export type ReferredShopsQueryVariables = Exact<{
  shopId: Scalars['String'];
}>;

export type ReferredShopsQuery = {
  __typename?: 'query_root';
  dbReferral: Array<{
    __typename?: 'DbReferral';
    receivingShop?: {
      __typename?: 'DbShop';
      id: string;
      shopName: string;
      city?: string | null;
      countryCode: CountryEnum;
      postalCode?: string | null;
      streetName?: string | null;
      phone?: string | null;
      email?: string | null;
      status?: ShopStatusEnum | null;
      streetNumber?: string | null;
      onboardingLevel?: number | null;
      groupId?: string | null;
      latitude?: any | null;
      longitude?: any | null;
      shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
      licensePartner: {
        __typename?: 'DbLicensePartner';
        name: string;
        isCompanyOwnedOperation?: boolean | null;
        id: string;
      };
      profiles: Array<{
        __typename?: 'DbShopProfile';
        images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
  }>;
};

export type LeadQueryVariables = Exact<{
  where?: InputMaybe<DbLeadBoolExp>;
}>;

export type LeadQuery = {
  __typename?: 'query_root';
  dbLead: Array<{
    __typename?: 'DbLead';
    contactId: string;
    status: LeadStatusEnum;
    source: LeadSourceEnum;
    priority?: PriorityEnum | null;
    customerMessage?: string | null;
    statusReason?: LeadStatusReasonEnum | null;
    statusNote?: string | null;
    agentId?: string | null;
    shopId: string;
    teamId?: string | null;
    id: string;
    intentType: IntentTypeEnum;
    createdAt: any;
    expiredAt?: any | null;
    timeFrame?: TimeFrameEnum | null;
    personalNote?: string | null;
    go3PropertyId?: string | null;
    peLinkedPropertyId?: string | null;
    utmParams?: {
      __typename?: 'DbUtmParam';
      autaclid?: string | null;
      msclkid?: string | null;
      pmclid?: string | null;
      campaign?: string | null;
      content?: string | null;
      gclid?: string | null;
      medium?: string | null;
      source?: string | null;
      url?: string | null;
      gbraid?: string | null;
      wbraid?: string | null;
    } | null;
    conversionData: Array<{
      __typename?: 'DbConversionData';
      referrer?: string | null;
      landingPageUrlWithUtm?: string | null;
      landingPageOwnerShopId?: string | null;
      campaignOwnerShopId?: string | null;
    }>;
    activities: Array<{
      __typename?: 'DbBaseActivity';
      type: ActivityTypeEnum;
      createdBy: string;
      createdAt: any;
      shopId?: string | null;
    }>;
    leadExpiryByLead?: {
      __typename?: 'DbLeadExpiry';
      expirationDate?: any | null;
      id?: string | null;
      status?: string | null;
    } | null;
    contact: { __typename?: 'DbContact'; go3ContactKey?: number | null };
    agent?: {
      __typename: 'DbAgent';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      status: AgentStatusEnum;
      mobilePhoneNumber?: string | null;
      officePhoneNumber?: string | null;
      profiles: Array<{
        __typename?: 'DbAgentProfile';
        images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
      }>;
    } | null;
    shop: {
      __typename?: 'DbShop';
      id: string;
      shopName: string;
      city?: string | null;
      countryCode: CountryEnum;
      postalCode?: string | null;
      streetName?: string | null;
      phone?: string | null;
      email?: string | null;
      status?: ShopStatusEnum | null;
      streetNumber?: string | null;
      onboardingLevel?: number | null;
      groupId?: string | null;
      latitude?: any | null;
      longitude?: any | null;
      shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
      licensePartner: {
        __typename?: 'DbLicensePartner';
        name: string;
        isCompanyOwnedOperation?: boolean | null;
        id: string;
      };
      profiles: Array<{
        __typename?: 'DbShopProfile';
        images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
      }>;
    };
    referrals: Array<{
      __typename?: 'DbReferral';
      id: string;
      go3ReferralId?: number | null;
      referralNotPossibleReason?: ReferralNotPossibleReasonEnum | null;
      referralAgentNote?: string | null;
      receivingShop?: {
        __typename?: 'DbShop';
        id: string;
        shopName: string;
        city?: string | null;
        countryCode: CountryEnum;
        postalCode?: string | null;
        streetName?: string | null;
        phone?: string | null;
        email?: string | null;
        status?: ShopStatusEnum | null;
        streetNumber?: string | null;
        onboardingLevel?: number | null;
        groupId?: string | null;
        latitude?: any | null;
        longitude?: any | null;
        shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
        licensePartner: {
          __typename?: 'DbLicensePartner';
          name: string;
          isCompanyOwnedOperation?: boolean | null;
          id: string;
        };
        profiles: Array<{
          __typename?: 'DbShopProfile';
          images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
        }>;
      } | null;
    }>;
    sellerRentOutSpecification?: {
      __typename?: 'DbSellerRentOutSpecification';
      type?: AssetTypeEnum | null;
      livingArea?: any | null;
      priceRangeTo?: any | null;
      priceRangeFrom?: any | null;
      priceRangeUnit: CurrencyEnum;
      rentPriceUnit: CurrencyEnum;
      plotArea?: any | null;
      constructionYear?: number | null;
      condition?: AssetConditionEnum | null;
      rentRangeFrom?: any | null;
      rentRangeTo?: any | null;
      occupationStatus?: PropertyOccupationStatusEnum | null;
      numberOfBathrooms?: number | null;
      numberOfBedrooms?: number | null;
      floor?: number | null;
      numberOfMasterBathrooms?: number | null;
      numberOfRooms?: number | null;
      totalFloors?: number | null;
      surfaceTotal?: any | null;
      surfaceUsable?: any | null;
      balcony?: boolean | null;
      garden?: boolean | null;
      parking?: boolean | null;
      swimmingPool?: boolean | null;
      elevator?: boolean | null;
      garage?: boolean | null;
      guestToilet?: boolean | null;
      subType?: AssetSubTypeEnum | null;
      variation?: AssetVariationEnum | null;
      id: string;
      ownershipStatus?: PropertyOwnershipStatusEnum | null;
      ownerDescription?: string | null;
      address?: {
        __typename?: 'DbAddress';
        city?: string | null;
        postalCode?: string | null;
        fullAddress?: string | null;
        id: string;
        countryCode: CountryEnum;
        secondAddressLine?: string | null;
        streetName?: string | null;
        streetNumber?: string | null;
        placeId?: string | null;
        country: { __typename?: 'EnumsCountry'; description?: string | null; name: string };
      } | null;
      owner?: {
        __typename?: 'DbContact';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        fullName?: string | null;
      } | null;
      sellerRentOutSpecificationCdfs: Array<{
        __typename?: 'DbSellerRentOutSpecificationCdf';
        cdf: { __typename?: 'DbCdf'; id: string; label: string };
      }>;
    } | null;
    buyerToRentSpecification?: {
      __typename?: 'DbBuyerToRentSpecification';
      searchDemandId?: string | null;
      type?: AssetTypeEnum | null;
      city?: string | null;
      livingAreaMax?: number | null;
      livingAreaMin?: number | null;
      totalSurfaceMax?: number | null;
      totalSurfaceMin?: number | null;
      plotSurfaceMax?: number | null;
      plotSurfaceMin?: number | null;
      priceMax?: number | null;
      priceMin?: number | null;
      currency: CurrencyEnum;
      area?: string | null;
      country?: string | null;
      bedroomsMax?: number | null;
      bedroomsMin?: number | null;
      district?: string | null;
      id: string;
      legacyAreaNames: Array<string>;
      legacyDistrictNames: Array<string>;
      monthlyNetRentMax?: number | null;
      monthlyNetRentMin?: number | null;
      monthlyTotalRentMax?: number | null;
      monthlyTotalRentMin?: number | null;
      roomsMax?: number | null;
      roomsMin?: number | null;
      subTypes?: Array<string> | null;
      variations?: Array<string> | null;
      placeIds: Array<string>;
      buyerToRentSpecificationCdfs: Array<{
        __typename?: 'DbBuyerToRentSpecificationCdf';
        cdf: { __typename?: 'DbCdf'; id: string; label: string };
      }>;
      customSearchDemandPolygons: Array<{
        __typename?: 'DbCustomSearchDemandPolygon';
        id: string;
        name?: string | null;
        polygon: any;
      }>;
    } | null;
    leadBundlingByLeadWithPropertyId?: {
      __typename?: 'DbLeadBundling';
      id: string;
      leadWithSearchDemand: { __typename?: 'DbLead'; id: string };
    } | null;
    leadBundlingsByLeadWithSearchDemandId: Array<{
      __typename?: 'DbLeadBundling';
      id: string;
      leadWithProperty: {
        __typename?: 'DbLead';
        id: string;
        contactId: string;
        teamId?: string | null;
        intentType: IntentTypeEnum;
        createdAt: any;
        expiredAt?: any | null;
        status: LeadStatusEnum;
        timeFrame?: TimeFrameEnum | null;
        agentId?: string | null;
        shopId: string;
        personalNote?: string | null;
        go3PropertyId?: string | null;
        peLinkedPropertyId?: string | null;
        sellerRentOutSpecification?: {
          __typename?: 'DbSellerRentOutSpecification';
          type?: AssetTypeEnum | null;
          livingArea?: any | null;
          priceRangeTo?: any | null;
          priceRangeFrom?: any | null;
          priceRangeUnit: CurrencyEnum;
          rentPriceUnit: CurrencyEnum;
          plotArea?: any | null;
          address?: {
            __typename?: 'DbAddress';
            city?: string | null;
            postalCode?: string | null;
            fullAddress?: string | null;
          } | null;
        } | null;
        buyerToRentSpecification?: {
          __typename?: 'DbBuyerToRentSpecification';
          searchDemandId?: string | null;
          type?: AssetTypeEnum | null;
          city?: string | null;
          livingAreaMax?: number | null;
          livingAreaMin?: number | null;
          totalSurfaceMax?: number | null;
          totalSurfaceMin?: number | null;
          plotSurfaceMax?: number | null;
          plotSurfaceMin?: number | null;
          priceMax?: number | null;
          priceMin?: number | null;
          currency: CurrencyEnum;
          area?: string | null;
          country?: string | null;
        } | null;
        emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
        leadBundlingByLeadWithPropertyId?: { __typename?: 'DbLeadBundling'; id: string } | null;
      };
    }>;
    emailLinks: Array<{ __typename?: 'DbEmailLink'; threadId?: string | null }>;
    lastActivity: Array<{
      __typename?: 'DbBaseActivity';
      type: ActivityTypeEnum;
      createdBy: string;
      createdAt: any;
      shopId?: string | null;
    }>;
  }>;
};

export type SearchKeycloakUsersQueryVariables = Exact<{
  search: Scalars['String'];
  limit: Scalars['Int'];
}>;

export type SearchKeycloakUsersQuery = {
  __typename?: 'query_root';
  keycloakUser?: Array<{
    __typename?: 'KeycloakUser';
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    ldapId?: string | null;
  } | null> | null;
};

export type ShopGroupsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DbShopGroupOrderBy> | DbShopGroupOrderBy>;
  where?: InputMaybe<DbShopGroupBoolExp>;
}>;

export type ShopGroupsQuery = {
  __typename?: 'query_root';
  dbShopGroup: Array<{
    __typename?: 'DbShopGroup';
    id: string;
    name: string;
    description?: string | null;
    shops: Array<{
      __typename?: 'DbShop';
      id: string;
      shopName: string;
      city?: string | null;
      countryCode: CountryEnum;
      postalCode?: string | null;
      streetName?: string | null;
      phone?: string | null;
      email?: string | null;
      status?: ShopStatusEnum | null;
      streetNumber?: string | null;
      onboardingLevel?: number | null;
      groupId?: string | null;
      latitude?: any | null;
      longitude?: any | null;
      shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
      licensePartner: {
        __typename?: 'DbLicensePartner';
        name: string;
        isCompanyOwnedOperation?: boolean | null;
        id: string;
      };
      profiles: Array<{
        __typename?: 'DbShopProfile';
        images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
      }>;
    }>;
  }>;
};

export type TotalShopGroupCountQueryVariables = Exact<{ [key: string]: never }>;

export type TotalShopGroupCountQuery = {
  __typename?: 'query_root';
  dbShopGroupAggregate: {
    __typename?: 'DbShopGroupAggregate';
    aggregate?: { __typename?: 'DbShopGroupAggregateFields'; count: number } | null;
  };
};

export type AgentsInShopQueryVariables = Exact<{
  shopId?: InputMaybe<Scalars['String']>;
  where: DbAgentBoolExp;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentOrderBy> | DbAgentOrderBy>;
}>;

export type AgentsInShopQuery = {
  __typename?: 'query_root';
  dbAgent: Array<{
    __typename: 'DbAgent';
    createdAt: any;
    primaryShopId?: string | null;
    userName: string;
    salutation?: SalutationEnum | null;
    betaTester: boolean;
    energyAdvisoryEnabled: boolean;
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    status: AgentStatusEnum;
    mobilePhoneNumber?: string | null;
    officePhoneNumber?: string | null;
    userTeamAssignments: Array<{
      __typename?: 'DbUserTeamAssignment';
      role: UserRoleEnum;
      team: {
        __typename?: 'DbTeam';
        normalizedName?: string | null;
        description?: string | null;
        status?: TeamStatusEnum | null;
        id: string;
        name: string;
        shopId: string;
        email?: string | null;
        shop: {
          __typename?: 'DbShop';
          id: string;
          shopName: string;
          city?: string | null;
          countryCode: CountryEnum;
          postalCode?: string | null;
          streetName?: string | null;
          phone?: string | null;
          email?: string | null;
          status?: ShopStatusEnum | null;
          streetNumber?: string | null;
          onboardingLevel?: number | null;
          groupId?: string | null;
          latitude?: any | null;
          longitude?: any | null;
          shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
          licensePartner: {
            __typename?: 'DbLicensePartner';
            name: string;
            isCompanyOwnedOperation?: boolean | null;
            id: string;
          };
          profiles: Array<{
            __typename?: 'DbShopProfile';
            images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
          }>;
        };
        teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
      };
    }>;
    userShopAssignments: Array<{
      __typename?: 'DbUserShopAssignment';
      role: UserRoleEnum;
      shop: {
        __typename?: 'DbShop';
        id: string;
        shopName: string;
        city?: string | null;
        countryCode: CountryEnum;
        postalCode?: string | null;
        streetName?: string | null;
        phone?: string | null;
        email?: string | null;
        status?: ShopStatusEnum | null;
        streetNumber?: string | null;
        onboardingLevel?: number | null;
        groupId?: string | null;
        latitude?: any | null;
        longitude?: any | null;
        shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
        licensePartner: {
          __typename?: 'DbLicensePartner';
          name: string;
          isCompanyOwnedOperation?: boolean | null;
          id: string;
        };
        profiles: Array<{
          __typename?: 'DbShopProfile';
          images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
        }>;
      };
    }>;
    userAdminAssignments: Array<{ __typename?: 'DbUserAdminAssignment'; role: UserRoleEnum }>;
    nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
    profiles: Array<{
      __typename?: 'DbAgentProfile';
      images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
    }>;
  }>;
};

export type TotalAgentsInShopCountQueryVariables = Exact<{
  shopId?: InputMaybe<Scalars['String']>;
  where: DbAgentBoolExp;
}>;

export type TotalAgentsInShopCountQuery = {
  __typename?: 'query_root';
  dbAgentAggregate: {
    __typename?: 'DbAgentAggregate';
    aggregate?: { __typename?: 'DbAgentAggregateFields'; totalCount: number } | null;
  };
};

export type AgentsInLipaQueryVariables = Exact<{
  lipaId?: InputMaybe<Scalars['String']>;
  where: DbAgentBoolExp;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbAgentOrderBy> | DbAgentOrderBy>;
}>;

export type AgentsInLipaQuery = {
  __typename?: 'query_root';
  dbAgent: Array<{
    __typename: 'DbAgent';
    createdAt: any;
    primaryShopId?: string | null;
    userName: string;
    salutation?: SalutationEnum | null;
    betaTester: boolean;
    energyAdvisoryEnabled: boolean;
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    status: AgentStatusEnum;
    mobilePhoneNumber?: string | null;
    officePhoneNumber?: string | null;
    userTeamAssignments: Array<{
      __typename?: 'DbUserTeamAssignment';
      role: UserRoleEnum;
      team: {
        __typename?: 'DbTeam';
        normalizedName?: string | null;
        description?: string | null;
        status?: TeamStatusEnum | null;
        id: string;
        name: string;
        shopId: string;
        email?: string | null;
        shop: {
          __typename?: 'DbShop';
          id: string;
          shopName: string;
          city?: string | null;
          countryCode: CountryEnum;
          postalCode?: string | null;
          streetName?: string | null;
          phone?: string | null;
          email?: string | null;
          status?: ShopStatusEnum | null;
          streetNumber?: string | null;
          onboardingLevel?: number | null;
          groupId?: string | null;
          latitude?: any | null;
          longitude?: any | null;
          shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
          licensePartner: {
            __typename?: 'DbLicensePartner';
            name: string;
            isCompanyOwnedOperation?: boolean | null;
            id: string;
          };
          profiles: Array<{
            __typename?: 'DbShopProfile';
            images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
          }>;
        };
        teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
      };
    }>;
    userShopAssignments: Array<{
      __typename?: 'DbUserShopAssignment';
      role: UserRoleEnum;
      shop: {
        __typename?: 'DbShop';
        id: string;
        shopName: string;
        city?: string | null;
        countryCode: CountryEnum;
        postalCode?: string | null;
        streetName?: string | null;
        phone?: string | null;
        email?: string | null;
        status?: ShopStatusEnum | null;
        streetNumber?: string | null;
        onboardingLevel?: number | null;
        groupId?: string | null;
        latitude?: any | null;
        longitude?: any | null;
        shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
        licensePartner: {
          __typename?: 'DbLicensePartner';
          name: string;
          isCompanyOwnedOperation?: boolean | null;
          id: string;
        };
        profiles: Array<{
          __typename?: 'DbShopProfile';
          images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
        }>;
      };
    }>;
    userAdminAssignments: Array<{ __typename?: 'DbUserAdminAssignment'; role: UserRoleEnum }>;
    nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
    profiles: Array<{
      __typename?: 'DbAgentProfile';
      images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
    }>;
  }>;
};

export type ShopProfileQueryVariables = Exact<{
  shopId?: InputMaybe<Scalars['String']>;
  where: DbShopProfileBoolExp;
}>;

export type ShopProfileQuery = {
  __typename?: 'query_root';
  dbShopProfile: Array<{
    __typename?: 'DbShopProfile';
    id: string;
    published?: string | null;
    activated?: boolean | null;
    clientId?: string | null;
    optin?: string | null;
    recipientId?: string | null;
    placeId?: string | null;
    shopProfileFeaturedAgents: Array<{
      __typename?: 'DbShopProfileFeaturedAgent';
      agentId: string;
      index: number;
      agent: {
        __typename: 'DbAgent';
        createdAt: any;
        primaryShopId?: string | null;
        userName: string;
        salutation?: SalutationEnum | null;
        betaTester: boolean;
        energyAdvisoryEnabled: boolean;
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        userTeamAssignments: Array<{
          __typename?: 'DbUserTeamAssignment';
          role: UserRoleEnum;
          team: {
            __typename?: 'DbTeam';
            normalizedName?: string | null;
            description?: string | null;
            status?: TeamStatusEnum | null;
            id: string;
            name: string;
            shopId: string;
            email?: string | null;
            shop: {
              __typename?: 'DbShop';
              id: string;
              shopName: string;
              city?: string | null;
              countryCode: CountryEnum;
              postalCode?: string | null;
              streetName?: string | null;
              phone?: string | null;
              email?: string | null;
              status?: ShopStatusEnum | null;
              streetNumber?: string | null;
              onboardingLevel?: number | null;
              groupId?: string | null;
              latitude?: any | null;
              longitude?: any | null;
              shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
              licensePartner: {
                __typename?: 'DbLicensePartner';
                name: string;
                isCompanyOwnedOperation?: boolean | null;
                id: string;
              };
              profiles: Array<{
                __typename?: 'DbShopProfile';
                images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
              }>;
            };
            teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
          };
        }>;
        userShopAssignments: Array<{
          __typename?: 'DbUserShopAssignment';
          role: UserRoleEnum;
          shop: { __typename?: 'DbShop'; shopName: string; id: string };
        }>;
        nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      };
    }>;
    descriptions: Array<{
      __typename?: 'DbShopProfileDescription';
      displayName?: string | null;
      slugOverride?: string | null;
      text?: string | null;
      lang: LanguageEnum;
      url?: string | null;
      isAiTranslated?: boolean | null;
    }>;
    cityDistricts: Array<{
      __typename?: 'DbShopProfileCityDistrict';
      city: string;
      district?: string | null;
      language: LanguageEnum;
    }>;
    expertises: Array<{ __typename?: 'DbShopProfileExpertise'; expertise: ShopExpertiseEnum }>;
    spokenLanguages: Array<{ __typename?: 'DbShopProfileSpokenLanguage'; spokenLanguage: SpokenLanguageEnum }>;
    propertyPreviews: Array<{
      __typename?: 'DbShopProfilePropertyPreview';
      utag: string;
      type: MarketingTypeEnum;
      index: number;
    }>;
    shopProfileFeaturedContents: Array<{
      __typename?: 'DbShopProfileFeaturedContent';
      headline?: string | null;
      description?: string | null;
      isAiTranslated?: boolean | null;
      lang: LanguageEnum;
      shopProfileFeaturedContentsTeasers: Array<{
        __typename?: 'DbShopProfileFeaturedContentTeaser';
        headline?: string | null;
        description?: string | null;
        link?: string | null;
      }>;
    }>;
    shopProfileFeaturedContentPhotos: Array<{
      __typename?: 'DbShopProfileImage';
      id: string;
      title: string;
      gcpFileName: string;
    }>;
    quotes: Array<{
      __typename?: 'DbShopProfileQuote';
      quote: string;
      authorName: string;
      authorTitle?: string | null;
      lang: LanguageEnum;
      isAiTranslated?: boolean | null;
    }>;
    teams: Array<{
      __typename?: 'DbShopProfileTeam';
      headline?: string | null;
      description?: string | null;
      lang: LanguageEnum;
      isAiTranslated: boolean;
    }>;
    teamPhoto: Array<{ __typename?: 'DbShopProfileImage'; id: string; title: string; gcpFileName: string }>;
    images: Array<{ __typename?: 'DbShopProfileImage'; id: string; title: string; gcpFileName: string }>;
    managerPhoto: Array<{ __typename?: 'DbShopProfileImage'; id: string; title: string; gcpFileName: string }>;
    seoTrackings: Array<{
      __typename?: 'DbShopProfileSeoTracking';
      googleAdsId?: string | null;
      googlePlaceId?: string | null;
      googleAnalyticsAccountId?: string | null;
      microsoftId?: string | null;
      facebookId?: string | null;
      criteoId?: string | null;
      linkedinId?: string | null;
      imprintUrl?: string | null;
      privacyPolicyUrl?: string | null;
    }>;
    imprints: Array<{
      __typename?: 'DbShopProfileImprint';
      isAiTranslated?: boolean | null;
      imprint: string;
      lang: LanguageEnum;
    }>;
    careers: Array<{
      __typename?: 'DbShopProfileCareer';
      description?: string | null;
      link?: string | null;
      headline?: string | null;
      lang: LanguageEnum;
      isAiTranslated?: boolean | null;
    }>;
    privacyPolicies: Array<{
      __typename?: 'DbShopProfilePrivacyPolicy';
      isAiTranslated?: boolean | null;
      privacyPolicy: string;
      lang: LanguageEnum;
    }>;
    termsAndConditions: Array<{
      __typename?: 'DbShopProfileTermsAndConditions';
      termsAndConditions: string;
      lang: LanguageEnum;
    }>;
    withdrawalPolicies: Array<{
      __typename?: 'DbShopProfileWithdrawalPolicy';
      withdrawalPolicy: string;
      lang: LanguageEnum;
    }>;
    customerMails: Array<{ __typename?: 'DbShopProfileCustomerMails'; footer: string; lang: LanguageEnum }>;
    socialMediaProfiles?: {
      __typename?: 'DbShopProfileSocialMediaProfiles';
      facebookUrl?: string | null;
      instagramUrl?: string | null;
      twitterUrl?: string | null;
      xingUrl?: string | null;
      linkedInUrl?: string | null;
      youtubeUrl?: string | null;
      tiktokUrl?: string | null;
    } | null;
    propertyLounge?: {
      __typename?: 'DbPropertyLounge';
      id: string;
      city?: string | null;
      countryCode?: CountryEnum | null;
      streetName?: string | null;
      streetNumber?: string | null;
      postalCode?: string | null;
      name: string;
      phone?: string | null;
      email?: string | null;
      status?: string | null;
    } | null;
  }>;
  publishedProfile: Array<{ __typename?: 'DbShopProfile'; published?: string | null }>;
};

export type PropertyLoungesQueryVariables = Exact<{
  shopId?: InputMaybe<Scalars['String']>;
}>;

export type PropertyLoungesQuery = {
  __typename?: 'query_root';
  dbPropertyLounge: Array<{
    __typename?: 'DbPropertyLounge';
    id: string;
    city?: string | null;
    countryCode?: CountryEnum | null;
    streetName?: string | null;
    streetNumber?: string | null;
    postalCode?: string | null;
    name: string;
    phone?: string | null;
    email?: string | null;
    status?: string | null;
  }>;
};

export type ShopSettingsQueryVariables = Exact<{
  where?: InputMaybe<DbShopSettingBoolExp>;
}>;

export type ShopSettingsQuery = {
  __typename?: 'query_root';
  dbShopSetting: Array<{
    __typename?: 'DbShopSetting';
    applyAutomatedContactSettings: boolean;
    ccUsersInAssignmentEmails: boolean;
    consentManagementEnabled: boolean;
    defaultCity?: string | null;
    defaultCountryCode: CountryEnum;
    defaultPreferredLanguage: LanguageEnum;
    shopId: string;
    sendQualificationLink: boolean;
    leadAutoAssignmentEnabled: boolean;
    leadExpiryEnabled: boolean;
    leadExpiryDaysBuyer?: number | null;
    leadExpiryDaysSeller?: number | null;
    leadExpiryDaysToRent?: number | null;
    leadExpiryDaysRentOut?: number | null;
    leadExtendingInteractionsEnabled: boolean;
    lastInteractionRecalculatedAt: any;
    activeToRecalculateLastInteraction?: boolean | null;
  }>;
};

export type AutoExposeShopSettingsQueryVariables = Exact<{
  where?: InputMaybe<DbShopSettingBoolExp>;
}>;

export type AutoExposeShopSettingsQuery = {
  __typename?: 'query_root';
  dbShopSetting: Array<{
    __typename?: 'DbShopSetting';
    shop: {
      __typename?: 'DbShop';
      qualiformMandatoryFields: Array<{ __typename?: 'DbQualiformMandatoryField'; fieldName: QualiformFieldsEnum }>;
      qualiformLeadIntents: Array<{ __typename?: 'DbQualiformLeadIntent'; intentType: IntentTypeEnum }>;
      qualiformLeadSources: Array<{ __typename?: 'DbQualiformLeadSource'; leadSource: LeadSourceEnum }>;
      qualiformShopSettings: Array<{ __typename?: 'DbQualiformShopSetting'; secondaryLanguageEnglishEnabled: boolean }>;
    };
  }>;
};

export type AutoLeadAssignmentShopSettingsQueryVariables = Exact<{
  where?: InputMaybe<DbShopSettingBoolExp>;
}>;

export type AutoLeadAssignmentShopSettingsQuery = {
  __typename?: 'query_root';
  dbShopSetting: Array<{
    __typename?: 'DbShopSetting';
    shop: {
      __typename?: 'DbShop';
      leadAutoAssignmentIntents: Array<{ __typename?: 'DbLeadAutoAssignmentIntent'; intentType: IntentTypeEnum }>;
      leadAutoAssignmentRules: Array<{
        __typename?: 'DbLeadAutoAssignmentRules';
        leadAssignmentRule: LeadAutoAssignmentRulesEnum;
      }>;
    };
  }>;
};

export type ConsentManagementShopSettingsQueryVariables = Exact<{
  shopId: Scalars['String'];
}>;

export type ConsentManagementShopSettingsQuery = {
  __typename?: 'query_root';
  dbShopManagedConsent: Array<{ __typename?: 'DbShopManagedConsent'; consentType: ConsentTypeEnum }>;
};

export type LastInteractionSettingsQueryVariables = Exact<{
  shopId: Scalars['String'];
}>;

export type LastInteractionSettingsQuery = {
  __typename?: 'query_root';
  dbShopSetting: Array<{ __typename?: 'DbShopSetting'; leadExtendingInteractionsEnabled: boolean }>;
};

export type ShopLastInteractionActivitiesQueryVariables = Exact<{
  shopId: Scalars['String'];
}>;

export type ShopLastInteractionActivitiesQuery = {
  __typename?: 'query_root';
  dbLeadExtendingActivities: Array<{ __typename?: 'DbLeadExtendingActivities'; activityType: ActivityTypeEnum }>;
  dbLeadExtendingTaskTypes: Array<{ __typename?: 'DbLeadExtendingTaskTypes'; taskType: TaskTypeEnum }>;
  dbLeadExtendingAppointmentTypes: Array<{
    __typename?: 'DbLeadExtendingAppointmentTypes';
    appointmentType: AppointmentTypeEnum;
  }>;
};

export type ShopTagsQueryVariables = Exact<{
  shopIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type ShopTagsQuery = {
  __typename?: 'query_root';
  dbTag: Array<{ __typename?: 'DbTag'; id: string; name: string }>;
};

export type ShopsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DbShopOrderBy> | DbShopOrderBy>;
  where?: InputMaybe<DbShopBoolExp>;
}>;

export type ShopsQuery = {
  __typename?: 'query_root';
  dbShop: Array<{
    __typename?: 'DbShop';
    currency: CurrencyEnum;
    website?: string | null;
    energyAdvisoryEnabled: boolean;
    businessUnit?: string | null;
    id: string;
    shopName: string;
    city?: string | null;
    countryCode: CountryEnum;
    postalCode?: string | null;
    streetName?: string | null;
    phone?: string | null;
    email?: string | null;
    status?: ShopStatusEnum | null;
    streetNumber?: string | null;
    onboardingLevel?: number | null;
    groupId?: string | null;
    latitude?: any | null;
    longitude?: any | null;
    twilioConfigurations: Array<{ __typename?: 'DbTwilioConfiguration'; id: string; twilioNumber: string }>;
    shopGroup?: {
      __typename?: 'DbShopGroup';
      name: string;
      shops: Array<{ __typename?: 'DbShop'; id: string; status?: ShopStatusEnum | null }>;
    } | null;
    licensePartner: {
      __typename?: 'DbLicensePartner';
      name: string;
      isCompanyOwnedOperation?: boolean | null;
      id: string;
      shops: Array<{ __typename?: 'DbShop'; id: string }>;
    };
    shopStatus?: { __typename?: 'EnumsShopStatus'; description?: string | null } | null;
    teams: Array<{
      __typename?: 'DbTeam';
      normalizedName?: string | null;
      description?: string | null;
      status?: TeamStatusEnum | null;
      id: string;
      name: string;
      shopId: string;
      email?: string | null;
      teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
    }>;
    profiles: Array<{
      __typename?: 'DbShopProfile';
      images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
    }>;
  }>;
};

export type ShopInfoQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DbShopOrderBy> | DbShopOrderBy>;
  where?: InputMaybe<DbShopBoolExp>;
}>;

export type ShopInfoQuery = {
  __typename?: 'query_root';
  dbShop: Array<{
    __typename?: 'DbShop';
    id: string;
    shopName: string;
    city?: string | null;
    countryCode: CountryEnum;
    postalCode?: string | null;
    streetName?: string | null;
    phone?: string | null;
    email?: string | null;
    status?: ShopStatusEnum | null;
    streetNumber?: string | null;
    onboardingLevel?: number | null;
    groupId?: string | null;
    latitude?: any | null;
    longitude?: any | null;
    shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
    licensePartner: {
      __typename?: 'DbLicensePartner';
      name: string;
      isCompanyOwnedOperation?: boolean | null;
      id: string;
    };
    profiles: Array<{
      __typename?: 'DbShopProfile';
      images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
    }>;
  }>;
};

export type ShopsWithMembersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<DbShopOrderBy> | DbShopOrderBy>;
  where?: InputMaybe<DbShopBoolExp>;
}>;

export type ShopsWithMembersQuery = {
  __typename?: 'query_root';
  dbShop: Array<{
    __typename?: 'DbShop';
    currency: CurrencyEnum;
    website?: string | null;
    energyAdvisoryEnabled: boolean;
    businessUnit?: string | null;
    id: string;
    shopName: string;
    city?: string | null;
    countryCode: CountryEnum;
    postalCode?: string | null;
    streetName?: string | null;
    phone?: string | null;
    email?: string | null;
    status?: ShopStatusEnum | null;
    streetNumber?: string | null;
    onboardingLevel?: number | null;
    groupId?: string | null;
    latitude?: any | null;
    longitude?: any | null;
    teams: Array<{
      __typename?: 'DbTeam';
      normalizedName?: string | null;
      description?: string | null;
      status?: TeamStatusEnum | null;
      id: string;
      name: string;
      shopId: string;
      email?: string | null;
      userTeamAssignments: Array<{
        __typename?: 'DbUserTeamAssignment';
        role: UserRoleEnum;
        agent: {
          __typename: 'DbAgent';
          createdAt: any;
          primaryShopId?: string | null;
          userName: string;
          salutation?: SalutationEnum | null;
          betaTester: boolean;
          energyAdvisoryEnabled: boolean;
          id: string;
          firstName: string;
          lastName: string;
          email: string;
          status: AgentStatusEnum;
          mobilePhoneNumber?: string | null;
          officePhoneNumber?: string | null;
          nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
          profiles: Array<{
            __typename?: 'DbAgentProfile';
            images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
          }>;
        };
      }>;
      teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
    }>;
    userShopAssignments: Array<{
      __typename?: 'DbUserShopAssignment';
      role: UserRoleEnum;
      agent: {
        __typename: 'DbAgent';
        createdAt: any;
        primaryShopId?: string | null;
        userName: string;
        salutation?: SalutationEnum | null;
        betaTester: boolean;
        energyAdvisoryEnabled: boolean;
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      };
    }>;
    twilioConfigurations: Array<{ __typename?: 'DbTwilioConfiguration'; id: string; twilioNumber: string }>;
    shopGroup?: {
      __typename?: 'DbShopGroup';
      name: string;
      shops: Array<{ __typename?: 'DbShop'; id: string; status?: ShopStatusEnum | null }>;
    } | null;
    licensePartner: {
      __typename?: 'DbLicensePartner';
      name: string;
      isCompanyOwnedOperation?: boolean | null;
      id: string;
      shops: Array<{ __typename?: 'DbShop'; id: string }>;
    };
    shopStatus?: { __typename?: 'EnumsShopStatus'; description?: string | null } | null;
    profiles: Array<{
      __typename?: 'DbShopProfile';
      images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
    }>;
  }>;
};

export type TotalShopCountQueryVariables = Exact<{
  where?: InputMaybe<DbShopBoolExp>;
}>;

export type TotalShopCountQuery = {
  __typename?: 'query_root';
  dbShopAggregate: {
    __typename?: 'DbShopAggregate';
    aggregate?: { __typename?: 'DbShopAggregateFields'; totalShopCount: number } | null;
  };
};

export type ShopNameByIDsQueryVariables = Exact<{
  shopIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type ShopNameByIDsQuery = {
  __typename?: 'query_root';
  dbShop: Array<{ __typename?: 'DbShop'; countryCode: CountryEnum; shopName: string; id: string }>;
};

export type LeadExtendingActivitiesQueryVariables = Exact<{
  shopId: Scalars['String'];
}>;

export type LeadExtendingActivitiesQuery = {
  __typename?: 'query_root';
  dbLeadExtendingActivities: Array<{ __typename?: 'DbLeadExtendingActivities'; activityType: ActivityTypeEnum }>;
  dbLeadExtendingTaskTypes: Array<{ __typename?: 'DbLeadExtendingTaskTypes'; taskType: TaskTypeEnum }>;
  dbLeadExtendingAppointmentTypes: Array<{
    __typename?: 'DbLeadExtendingAppointmentTypes';
    appointmentType: AppointmentTypeEnum;
  }>;
};

export type SystemUserIdsQueryQueryVariables = Exact<{
  systemUsers?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type SystemUserIdsQueryQuery = {
  __typename?: 'query_root';
  dbAgent: Array<{ __typename?: 'DbAgent'; id: string }>;
};

export type TagsQueryVariables = Exact<{
  where?: InputMaybe<DbTagBoolExp>;
}>;

export type TagsQuery = {
  __typename?: 'query_root';
  dbTag: Array<{ __typename?: 'DbTag'; id: string; name: string; description?: string | null }>;
};

export type TeamsWithMembersQueryVariables = Exact<{
  where: DbTeamBoolExp;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbTeamOrderBy> | DbTeamOrderBy>;
}>;

export type TeamsWithMembersQuery = {
  __typename?: 'query_root';
  dbTeam: Array<{
    __typename?: 'DbTeam';
    normalizedName?: string | null;
    description?: string | null;
    status?: TeamStatusEnum | null;
    id: string;
    name: string;
    shopId: string;
    email?: string | null;
    shop: {
      __typename?: 'DbShop';
      id: string;
      shopName: string;
      city?: string | null;
      countryCode: CountryEnum;
      postalCode?: string | null;
      streetName?: string | null;
      phone?: string | null;
      email?: string | null;
      status?: ShopStatusEnum | null;
      streetNumber?: string | null;
      onboardingLevel?: number | null;
      groupId?: string | null;
      latitude?: any | null;
      longitude?: any | null;
      shopGroup?: { __typename?: 'DbShopGroup'; shops: Array<{ __typename?: 'DbShop'; id: string }> } | null;
      licensePartner: {
        __typename?: 'DbLicensePartner';
        name: string;
        isCompanyOwnedOperation?: boolean | null;
        id: string;
      };
      profiles: Array<{
        __typename?: 'DbShopProfile';
        images: Array<{ __typename?: 'DbShopProfileImage'; gcpFileName: string }>;
      }>;
    };
    userTeamAssignments: Array<{
      __typename?: 'DbUserTeamAssignment';
      role: UserRoleEnum;
      agent: {
        __typename: 'DbAgent';
        createdAt: any;
        primaryShopId?: string | null;
        userName: string;
        salutation?: SalutationEnum | null;
        betaTester: boolean;
        energyAdvisoryEnabled: boolean;
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        status: AgentStatusEnum;
        mobilePhoneNumber?: string | null;
        officePhoneNumber?: string | null;
        nylasEmailIntegrations: Array<{ __typename?: 'DbNylasEmailIntegration'; email: string; active: boolean }>;
        profiles: Array<{
          __typename?: 'DbAgentProfile';
          images: Array<{ __typename?: 'DbAgentProfileImage'; gcpFileName: string }>;
        }>;
      };
    }>;
    teamStatus?: { __typename?: 'EnumsTeamStatus'; description?: string | null } | null;
  }>;
  dbTeamAggregate: {
    __typename?: 'DbTeamAggregate';
    aggregate?: { __typename?: 'DbTeamAggregateFields'; totalCount: number } | null;
  };
};

export type TeamInfoQueryVariables = Exact<{
  where: DbTeamBoolExp;
  orderBy?: InputMaybe<Array<DbTeamOrderBy> | DbTeamOrderBy>;
}>;

export type TeamInfoQuery = {
  __typename?: 'query_root';
  dbTeam: Array<{ __typename?: 'DbTeam'; id: string; name: string; shopId: string; email?: string | null }>;
};

export type TeamsAssignedToAgentInShopQueryVariables = Exact<{
  where: DbAgentBoolExp;
  shopId: Scalars['String'];
}>;

export type TeamsAssignedToAgentInShopQuery = {
  __typename?: 'query_root';
  dbAgent: Array<{
    __typename?: 'DbAgent';
    userTeamAssignments: Array<{
      __typename?: 'DbUserTeamAssignment';
      team: { __typename?: 'DbTeam'; name: string; id: string };
    }>;
  }>;
  dbTeam: Array<{ __typename?: 'DbTeam'; id: string; name: string }>;
};

export type TotalCallCountQueryQueryVariables = Exact<{
  where?: InputMaybe<DbCallBoolExp>;
}>;

export type TotalCallCountQueryQuery = {
  __typename?: 'query_root';
  dbCallAggregate: {
    __typename?: 'DbCallAggregate';
    aggregate?: { __typename?: 'DbCallAggregateFields'; totalCallCount: number } | null;
  };
};

export type TotalEmailSignaturesQueryQueryVariables = Exact<{
  where?: InputMaybe<DbEmailSignatureBoolExp>;
}>;

export type TotalEmailSignaturesQueryQuery = {
  __typename?: 'query_root';
  dbEmailSignatureAggregate: {
    __typename?: 'DbEmailSignatureAggregate';
    aggregate?: { __typename?: 'DbEmailSignatureAggregateFields'; totalEmailSignatures: number } | null;
  };
};

export type TotalLeadCountQueryQueryVariables = Exact<{
  where?: InputMaybe<DbLeadBoolExp>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type TotalLeadCountQueryQuery = {
  __typename?: 'query_root';
  dbLeadAggregate: {
    __typename?: 'DbLeadAggregate';
    aggregate?: { __typename?: 'DbLeadAggregateFields'; totalLeadCount: number } | null;
  };
};

export type VerifyNumberQueryQueryVariables = Exact<{
  shopId: Scalars['String'];
}>;

export type VerifyNumberQueryQuery = {
  __typename?: 'query_root';
  dbTwilioConfiguration: Array<{ __typename?: 'DbTwilioConfiguration'; displayNumber: string; twilioNumber: string }>;
};

export const ActivityTypeGroupingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityTypeGrouping' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbActivityTypeGrouping' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'activityCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'typeLocalizeKey' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActivityTypeGrouping, unknown>;
export const AgentInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgentInfo, unknown>;
export const AgentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Agent, unknown>;
export const TeamInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TeamInfo, unknown>;
export const TeamFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Team, unknown>;
export const AgentWithTeamAssignmentsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentWithTeamAssignments' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userTeamAssignments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgentWithTeamAssignments, unknown>;
export const ShopInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopInfo, unknown>;
export const AgentWithShopMembershipFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentWithShopMembership' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userTeamAssignments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shop' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userShopAssignments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shop' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAdminAssignments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'role' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgentWithShopMembership, unknown>;
export const AgentProfileImageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentProfileImage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgentProfileImage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgentProfileImage, unknown>;
export const AgentProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgentProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'published' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cityDistricts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'district' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expertises' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expertise' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'spokenLanguages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'spokenLanguage' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'descriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'academicTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAiTranslated' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentProfileImage' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'socialMediaProfiles' },
            name: { kind: 'Name', value: 'agentProfileSocialMediaProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'facebookUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'instagramUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'twitterUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xingUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkedInUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tiktokUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentProfileImage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgentProfileImage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgentProfile, unknown>;
export const ActionActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taskType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActionActivity, unknown>;
export const BaseActivityInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseActivityInfo, unknown>;
export const ActivityLeadFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityLeadFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActivityLeadFragment, unknown>;
export const AddressFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Address, unknown>;
export const SellerRentOutSpecificationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SellerRentOutSpecification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbSellerRentOutSpecification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'constructionYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'condition' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentRangeFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentRangeTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfBedrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfMasterBathrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalFloors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surfaceTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surfaceUsable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'balcony' } },
          { kind: 'Field', name: { kind: 'Name', value: 'garden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parking' } },
          { kind: 'Field', name: { kind: 'Name', value: 'swimmingPool' } },
          { kind: 'Field', name: { kind: 'Name', value: 'elevator' } },
          { kind: 'Field', name: { kind: 'Name', value: 'garage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guestToilet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ownershipStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecificationCdfs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cdf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SellerRentOutSpecification, unknown>;
export const BuyerToRentSpecificationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BuyerToRentSpecification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBuyerToRentSpecification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'district' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyAreaNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyDistrictNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subTypes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecificationCdfs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cdf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customSearchDemandPolygons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'polygon' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BuyerToRentSpecification, unknown>;
export const CreatedAndReferralActivityLeadFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreatedAndReferralActivityLeadFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referrals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'receivingShop' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerMessage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SellerRentOutSpecification' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BuyerToRentSpecification' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SellerRentOutSpecification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbSellerRentOutSpecification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'constructionYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'condition' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentRangeFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentRangeTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfBedrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfMasterBathrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalFloors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surfaceTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surfaceUsable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'balcony' } },
          { kind: 'Field', name: { kind: 'Name', value: 'garden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parking' } },
          { kind: 'Field', name: { kind: 'Name', value: 'swimmingPool' } },
          { kind: 'Field', name: { kind: 'Name', value: 'elevator' } },
          { kind: 'Field', name: { kind: 'Name', value: 'garage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guestToilet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ownershipStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecificationCdfs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cdf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BuyerToRentSpecification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBuyerToRentSpecification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'district' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyAreaNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyDistrictNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subTypes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecificationCdfs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cdf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customSearchDemandPolygons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'polygon' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatedAndReferralActivityLeadFragment, unknown>;
export const ActivityContactFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityContactFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'birthdate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredTimes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'preferredContactTime' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredLanguage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActivityContactFragment, unknown>;
export const CallInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CallInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCall' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callSid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'outcome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callDirection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caller' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CallInfo, unknown>;
export const CallActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CallActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCallActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'call' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CallInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CallInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCall' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callSid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'outcome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callDirection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caller' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CallActivity, unknown>;
export const AuditActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAuditActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuditActivity, unknown>;
export const ConsentActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConsentActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbConsentActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'licenseId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customerAccountId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customerAccount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'contactForm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'consentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'consentText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConsentActivity, unknown>;
export const EmailActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmailActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbEmailActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'email' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                { kind: 'Field', name: { kind: 'Name', value: 'to' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bcc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'document' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmailActivity, unknown>;
export const ExposeSharedActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExposeSharedActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbExposeeSharedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExposeSharedActivity, unknown>;
export const TaskFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Task' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTask' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDatetime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3TaskKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'contactId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskLeads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'leadId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskAgents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Task, unknown>;
export const TaskActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TaskActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTaskActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Task' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Task' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTask' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDatetime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3TaskKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'contactId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskLeads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'leadId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskAgents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TaskActivity, unknown>;
export const AppointmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Appointment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAppointment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3AppointmentKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'contactId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentLeads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'leadId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentAgents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Appointment, unknown>;
export const AppointmentActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AppointmentActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAppointmentActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Appointment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Appointment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAppointment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3AppointmentKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'contactId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentLeads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'leadId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentAgents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AppointmentActivity, unknown>;
export const ReferralDetailsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReferralDetailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbReferral' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptedRefusedDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendingContactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivingLeadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivingGo3ContactUtag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadByReceivingLeadId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailAddresses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumbers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'referralAgentNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralNotPossibleReason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentByReceivingAgentId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sendingShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivingShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivingTeamId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactByReceivingLeadIdWrapper' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sendingTeamId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receivingShop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendingShop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'snapshotId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshotLead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactFirstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactLastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamByReceivingTeamId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamBySendingTeamId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadByReceivingLeadId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'baseActivities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'callActivity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CallActivity' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailActivity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailActivity' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'appointmentActivity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppointmentActivity' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CallInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCall' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callSid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'outcome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callDirection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caller' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Appointment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAppointment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3AppointmentKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'contactId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentLeads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'leadId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentAgents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CallActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCallActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'call' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CallInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmailActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbEmailActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'email' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                { kind: 'Field', name: { kind: 'Name', value: 'to' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bcc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'document' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AppointmentActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAppointmentActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Appointment' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReferralDetailsFragment, unknown>;
export const ReferralActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReferralActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbReferralActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referral' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReferralDetailsFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CallInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCall' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callSid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'outcome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callDirection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caller' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CallActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCallActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'call' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CallInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmailActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbEmailActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'email' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                { kind: 'Field', name: { kind: 'Name', value: 'to' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bcc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'document' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Appointment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAppointment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3AppointmentKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'contactId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentLeads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'leadId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentAgents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AppointmentActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAppointmentActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Appointment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReferralDetailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbReferral' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptedRefusedDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendingContactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivingLeadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivingGo3ContactUtag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadByReceivingLeadId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailAddresses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumbers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'referralAgentNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralNotPossibleReason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentByReceivingAgentId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sendingShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivingShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivingTeamId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactByReceivingLeadIdWrapper' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sendingTeamId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receivingShop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendingShop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'snapshotId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshotLead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactFirstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactLastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamByReceivingTeamId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamBySendingTeamId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadByReceivingLeadId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'baseActivities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'callActivity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CallActivity' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailActivity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailActivity' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'appointmentActivity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppointmentActivity' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReferralActivity, unknown>;
export const DocumentInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DocumentInfo, unknown>;
export const LeadInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeadInfo, unknown>;
export const DocumentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Document' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeKb' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByAgent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Document, unknown>;
export const DocumentActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documentReference' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Document' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Document' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeKb' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByAgent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DocumentActivity, unknown>;
export const ContactChangeActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactChangeActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactChangeLog' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'oldValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fieldName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactChangeActivity, unknown>;
export const LegacyGo3ChangeActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LegacyGo3ChangeActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLegacyGo3ChangeLog' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'oldValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fieldName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LegacyGo3ChangeActivity, unknown>;
export const BaseActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActivityLeadFragment' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CreatedAndReferralActivityLeadFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActivityContactFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CallActivity' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuditActivity' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ConsentActivity' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailActivity' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exposeSharedActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ExposeSharedActivity' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TaskActivity' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppointmentActivity' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referralActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReferralActivity' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentActivity' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactChangeLogs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactChangeActivity' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'base_activity_legacy_go3_change_logs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LegacyGo3ChangeActivity' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SellerRentOutSpecification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbSellerRentOutSpecification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'constructionYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'condition' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentRangeFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentRangeTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfBedrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfMasterBathrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalFloors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surfaceTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surfaceUsable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'balcony' } },
          { kind: 'Field', name: { kind: 'Name', value: 'garden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parking' } },
          { kind: 'Field', name: { kind: 'Name', value: 'swimmingPool' } },
          { kind: 'Field', name: { kind: 'Name', value: 'elevator' } },
          { kind: 'Field', name: { kind: 'Name', value: 'garage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guestToilet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ownershipStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecificationCdfs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cdf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BuyerToRentSpecification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBuyerToRentSpecification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'district' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyAreaNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyDistrictNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subTypes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecificationCdfs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cdf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customSearchDemandPolygons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'polygon' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CallInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCall' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callSid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'outcome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callDirection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caller' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Task' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTask' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDatetime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3TaskKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'contactId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskLeads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'leadId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskAgents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Appointment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAppointment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3AppointmentKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'contactId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentLeads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'leadId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentAgents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CallActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCallActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'call' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CallInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmailActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbEmailActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'email' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                { kind: 'Field', name: { kind: 'Name', value: 'to' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bcc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'document' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AppointmentActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAppointmentActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Appointment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReferralDetailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbReferral' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptedRefusedDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendingContactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivingLeadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivingGo3ContactUtag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadByReceivingLeadId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailAddresses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumbers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'referralAgentNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralNotPossibleReason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentByReceivingAgentId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sendingShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivingShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivingTeamId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactByReceivingLeadIdWrapper' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sendingTeamId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receivingShop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendingShop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'snapshotId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshotLead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactFirstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactLastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamByReceivingTeamId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamBySendingTeamId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadByReceivingLeadId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'baseActivities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'callActivity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CallActivity' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailActivity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailActivity' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'appointmentActivity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppointmentActivity' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Document' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeKb' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByAgent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityLeadFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreatedAndReferralActivityLeadFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referrals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'receivingShop' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerMessage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SellerRentOutSpecification' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BuyerToRentSpecification' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityContactFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'birthdate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredTimes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'preferredContactTime' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredLanguage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAuditActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConsentActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbConsentActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'licenseId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customerAccountId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customerAccount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'contactForm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'consentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'consentText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExposeSharedActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbExposeeSharedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TaskActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTaskActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Task' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReferralActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbReferralActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referral' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReferralDetailsFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documentReference' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Document' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactChangeActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactChangeLog' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'oldValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fieldName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LegacyGo3ChangeActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLegacyGo3ChangeLog' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'oldValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fieldName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseActivity, unknown>;
export const DashboardActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TaskActivity' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppointmentActivity' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Task' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTask' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDatetime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3TaskKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'contactId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskLeads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'leadId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskAgents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Appointment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAppointment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3AppointmentKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'contactId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentLeads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'leadId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentAgents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TaskActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTaskActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Task' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AppointmentActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAppointmentActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Appointment' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardActivity, unknown>;
export const ExpandedActivityListItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExpandedActivityListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'leadId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'call' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'agent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'outcome' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'email' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'to' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cc' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dueDatetime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appointment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'documentReference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sizeKb' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exposeSharedActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExpandedActivityListItem, unknown>;
export const ActivityListItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'call' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'email' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dueDatetime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appointment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'documentReference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exposeSharedActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActivityListItem, unknown>;
export const BatchJobExecutionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BatchJobExecution' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBatchJobExecution' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobExecutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobInstanceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exitCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exitMessage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'batchJobExecutionParams' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parameterName' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'ListValue',
                              values: [{ kind: 'StringValue', value: 'shop_id, migration_id', block: false }],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'parameterName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parameterValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parameterType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'batchStepExecutions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'stepName' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_nlike' },
                            value: { kind: 'StringValue', value: '%PARTITION%', block: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'exitCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exitMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'readCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stepName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'writeCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stepExecutionId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BatchJobExecution, unknown>;
export const CallFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Call' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCall' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'call_activities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'baseActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'leadId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lead' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActivityLeadFragment' } },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CreatedAndReferralActivityLeadFragment' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActivityContactFragment' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CallInfo' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SellerRentOutSpecification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbSellerRentOutSpecification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'constructionYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'condition' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentRangeFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentRangeTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfBedrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfMasterBathrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalFloors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surfaceTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surfaceUsable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'balcony' } },
          { kind: 'Field', name: { kind: 'Name', value: 'garden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parking' } },
          { kind: 'Field', name: { kind: 'Name', value: 'swimmingPool' } },
          { kind: 'Field', name: { kind: 'Name', value: 'elevator' } },
          { kind: 'Field', name: { kind: 'Name', value: 'garage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guestToilet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ownershipStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecificationCdfs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cdf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BuyerToRentSpecification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBuyerToRentSpecification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'district' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyAreaNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyDistrictNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subTypes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecificationCdfs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cdf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customSearchDemandPolygons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'polygon' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityLeadFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreatedAndReferralActivityLeadFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referrals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'receivingShop' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerMessage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SellerRentOutSpecification' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BuyerToRentSpecification' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityContactFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'birthdate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredTimes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'preferredContactTime' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredLanguage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CallInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCall' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callSid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'outcome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callDirection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caller' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Call, unknown>;
export const ContactInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3Utag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredLanguage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactInfo, unknown>;
export const ContactAddressFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactAddress, unknown>;
export const ConsentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Consent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbConsent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'licenseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revokedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactForm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'document' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Document' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Document' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeKb' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByAgent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Consent, unknown>;
export const ContactFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactInfo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactAddress' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'legalEntity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'department' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formattedSalutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'birthdate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nationality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'idNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kycSearchId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerFirstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerLastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'autoCc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'consents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Consent' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internationalNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredLanguage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredTimes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'preferredContactTime' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactRelationships' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'relatedContactId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoCc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'relatedContact' },
                  name: { kind: 'Name', value: 'related_contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactInfo' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactGdpr' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'agreedToGdpr' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactRenterInformations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'householdType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professionType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'petOwner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyIncomeMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyIncomeMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tag' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'preferredPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'preferred' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'number' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'preferredEmail' },
            name: { kind: 'Name', value: 'emailAddresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'preferred' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'address' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadhubGo3ContactLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'firstAgent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'secondAgent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thirdAgent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'firstIntentCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secondIntentCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thirdIntentCategory' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'shopName' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'customerAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Document' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeKb' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByAgent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3Utag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredLanguage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Consent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbConsent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'licenseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revokedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactForm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'document' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Document' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Contact, unknown>;
export const LeadLastActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadLastActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastActivity' },
            name: { kind: 'Name', value: 'baseActivities' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeadLastActivity, unknown>;
export const LastInteractionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastInteraction' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appointment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LastInteraction, unknown>;
export const ContactAndLeadsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactAndLeads' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Contact' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadLastActivity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastInteractionActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastInteraction' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3Utag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredLanguage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Document' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeKb' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByAgent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Consent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbConsent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'licenseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revokedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactForm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'document' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Document' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactInfo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactAddress' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'legalEntity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'department' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formattedSalutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'birthdate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nationality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'idNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kycSearchId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerFirstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerLastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'autoCc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'consents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Consent' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internationalNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredLanguage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredTimes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'preferredContactTime' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactRelationships' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'relatedContactId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoCc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'relatedContact' },
                  name: { kind: 'Name', value: 'related_contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactInfo' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactGdpr' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'agreedToGdpr' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactRenterInformations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'householdType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professionType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'petOwner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyIncomeMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyIncomeMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tag' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'preferredPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'preferred' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'number' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'preferredEmail' },
            name: { kind: 'Name', value: 'emailAddresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'preferred' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'address' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadhubGo3ContactLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'firstAgent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'secondAgent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thirdAgent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'firstIntentCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secondIntentCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thirdIntentCategory' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'shopName' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'customerAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadLastActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastActivity' },
            name: { kind: 'Name', value: 'baseActivities' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastInteraction' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appointment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactAndLeads, unknown>;
export const ContactConnectedPropertiesListingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactConnectedPropertiesListing' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineListing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assignedAgentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assignedTeamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastActivation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listingOwnerContactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'utag' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactConnectedPropertiesListing, unknown>;
export const ContactEmailAddressFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactEmailAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactEmailAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactEmailAddress, unknown>;
export const LeadForContactListFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadForContactList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeadForContactList, unknown>;
export const ContactListEntryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactListEntry' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactAddress' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactGdpr' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'agreedToGdpr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'blockedDueToGdpr' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastActivity' },
            name: { kind: 'Name', value: 'baseActivities' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mostRecentActivityCreatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'tagId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadForContactList' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internationalNumber' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadForContactList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactListEntry, unknown>;
export const CustomViewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomView' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCustomListView' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shownByDefault' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewData' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewIndex' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomView, unknown>;
export const ContactLastActivityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactLastActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastActivity' },
            name: { kind: 'Name', value: 'baseActivities' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'orderDate' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactLastActivity, unknown>;
export const DuplicateContactDetailsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DuplicateContactDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'contactStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadLastActivity' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'normalizedAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internationalNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'normalizedLocalNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactLastActivity' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastInteractionActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastInteraction' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadLastActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastActivity' },
            name: { kind: 'Name', value: 'baseActivities' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactLastActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastActivity' },
            name: { kind: 'Name', value: 'baseActivities' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'orderDate' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastInteraction' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appointment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DuplicateContactDetails, unknown>;
export const DuplicateContactFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DuplicateContact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbPotentialDuplicateContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DuplicateContactDetails' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matchedEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matchedPhone' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadLastActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastActivity' },
            name: { kind: 'Name', value: 'baseActivities' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactLastActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastActivity' },
            name: { kind: 'Name', value: 'baseActivities' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'orderDate' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastInteraction' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appointment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DuplicateContactDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'contactStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadLastActivity' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'normalizedAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internationalNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'normalizedLocalNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactLastActivity' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastInteractionActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastInteraction' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DuplicateContact, unknown>;
export const BulkEmailFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BulkEmailFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBulkEmail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bcc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'document' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documentReferences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sizeKb' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trackings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'baseActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'contactId' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkEmailFragment, unknown>;
export const NylasEmailIntegrationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NylasEmailIntegration' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbNylasEmailIntegration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'isDefault' },
            name: { kind: 'Name', value: 'agentDefaultsAggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'agentId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'agent_id' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shopId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'shop_id' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NylasEmailIntegration, unknown>;
export const EmailPlaceholderFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmailPlaceholder' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbEmailPlaceholder' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mapping' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmailPlaceholder, unknown>;
export const EmailSignatureFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmailSignature' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbEmailSignature' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signature' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'isDefault' },
            name: { kind: 'Name', value: 'agentDefaultsAggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'agentId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'agent_id' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shopId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'shop_id' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmailSignature, unknown>;
export const EmailTemplateFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmailTemplate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbEmailTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                { kind: 'Field', name: { kind: 'Name', value: 'body' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmailTemplate, unknown>;
export const EmailTrackingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmailTracking' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbEmailTracking' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'email' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'messageId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmailTracking, unknown>;
export const KeycloakUserDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KeycloakUserData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'KeycloakUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ldapId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<KeycloakUserData, unknown>;
export const UtmParamFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UtmParam' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbUtmParam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'autaclid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'msclkid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pmclid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'campaign' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gclid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'medium' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gbraid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wbraid' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UtmParam, unknown>;
export const ConversionDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConversionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbConversionData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'referrer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landingPageUrlWithUtm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landingPageOwnerShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'campaignOwnerShopId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConversionData, unknown>;
export const LeadExpiryByLeadFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadExpiryByLead' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLeadExpiry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeadExpiryByLead, unknown>;
export const ReferralFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Referral' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbReferral' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3ReferralId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralNotPossibleReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralAgentNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receivingShop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Referral, unknown>;
export const LeadFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Lead' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'utmParams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UtmParam' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversionData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ConversionData' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'activities' },
            name: { kind: 'Name', value: 'baseActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadExpiryByLead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadExpiryByLead' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'go3ContactKey' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referrals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Referral' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SellerRentOutSpecification' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BuyerToRentSpecification' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leadWithSearchDemand' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingsByLeadWithSearchDemandId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leadWithProperty' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } }],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadLastActivity' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UtmParam' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbUtmParam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'autaclid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'msclkid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pmclid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'campaign' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gclid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'medium' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gbraid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wbraid' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConversionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbConversionData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'referrer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landingPageUrlWithUtm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landingPageOwnerShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'campaignOwnerShopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadExpiryByLead' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLeadExpiry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Referral' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbReferral' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3ReferralId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralNotPossibleReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralAgentNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receivingShop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SellerRentOutSpecification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbSellerRentOutSpecification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'constructionYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'condition' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentRangeFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentRangeTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfBedrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfMasterBathrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalFloors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surfaceTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surfaceUsable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'balcony' } },
          { kind: 'Field', name: { kind: 'Name', value: 'garden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parking' } },
          { kind: 'Field', name: { kind: 'Name', value: 'swimmingPool' } },
          { kind: 'Field', name: { kind: 'Name', value: 'elevator' } },
          { kind: 'Field', name: { kind: 'Name', value: 'garage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guestToilet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ownershipStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecificationCdfs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cdf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BuyerToRentSpecification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBuyerToRentSpecification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'district' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyAreaNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyDistrictNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subTypes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecificationCdfs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cdf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customSearchDemandPolygons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'polygon' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadLastActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastActivity' },
            name: { kind: 'Name', value: 'baseActivities' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Lead, unknown>;
export const LeadListEntryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadListEntry' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phoneNumbers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'internationalNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailAddresses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'preferredTimes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'preferredContactTime' } }],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadLastActivity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shopGroup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shops' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadLastActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastActivity' },
            name: { kind: 'Name', value: 'baseActivities' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeadListEntry, unknown>;
export const LeadForAddingPropertyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadForAddingProperty' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'go3ContactKey' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'surfaceTotal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeadForAddingProperty, unknown>;
export const ImportLeadRequestFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImportLeadRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbImportLeadRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payloadType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestPayload' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importLeadStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stackTrace' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importedLeadId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ImportLeadRequest, unknown>;
export const LicensePartnerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LicensePartner' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLicensePartner' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'debtorNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shops' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'licensePartnerProfileImages' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'StringValue', value: 'HERO_TEASER', block: false },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LicensePartner, unknown>;
export const LipaProfileFeaturedAgentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LipaProfileFeaturedAgent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLicensePartnerProfileFeaturedAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userTeamAssignments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'team' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'shop' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'licensePartnerId' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: '_eq' },
                                              value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'team' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shop' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userShopAssignments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'shop' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'licensePartnerId' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shop' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LipaProfileFeaturedAgent, unknown>;
export const LipaProfileImageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LipaProfileImage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLicensePartnerProfileImage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LipaProfileImage, unknown>;
export const LipaProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LipaProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLicensePartnerProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'licensePartnerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailRecruiting' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailOther' } },
          { kind: 'Field', name: { kind: 'Name', value: 'facebookUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instagramUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'twitterUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'xingUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedInUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'youtubeUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tiktokUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'googleAdsId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'googlePlaceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'googleAnalyticsAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'microsoftId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'facebookId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'criteoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imprintUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featuredAgents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LipaProfileFeaturedAgent' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyPreviews' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'utag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'heroPhoto' },
            name: { kind: 'Name', value: 'licensePartnerProfileImages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'StringValue', value: 'HERO_TEASER', block: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LipaProfileImage' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'featuredContentPhotos' },
            name: { kind: 'Name', value: 'licensePartnerProfileImages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'StringValue', value: 'FEATURED_CONTENT_TEASER', block: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LipaProfileImage' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartnerProfileLanguageVariants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slugOverride' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
                { kind: 'Field', name: { kind: 'Name', value: 'heroDesc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'heroHeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'heroIsAiTranslated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imprint' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imprintIsAiTranslated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyIsAiTranslated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'introHeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'introDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'introIsAiTranslated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopOverviewHeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopOverviewDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopOverviewIsAiTranslated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'careerDesc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'careerHeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'careerLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'careerIsAiTranslated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
                { kind: 'Field', name: { kind: 'Name', value: 'featuredContentDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'featuredContentHeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'featuredContentIsAiTranslated' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'featuredContentTeasers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: { kind: 'EnumValue', value: 'ASC' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'careerImages' },
            name: { kind: 'Name', value: 'licensePartnerProfileImages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'StringValue', value: 'CAREER', block: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '3' } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LipaProfileImage' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LipaProfileFeaturedAgent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLicensePartnerProfileFeaturedAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userTeamAssignments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'team' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'shop' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'licensePartnerId' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: '_eq' },
                                              value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'team' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shop' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userShopAssignments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'shop' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'licensePartnerId' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shop' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LipaProfileImage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLicensePartnerProfileImage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LipaProfile, unknown>;
export const MatchingStatusFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatchingStatus' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineMatchingStatusDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matchingState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manuallyProposed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BuyerToRentSpecification' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lead' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BuyerToRentSpecification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBuyerToRentSpecification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'district' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyAreaNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyDistrictNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subTypes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecificationCdfs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cdf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customSearchDemandPolygons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'polygon' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MatchingStatus, unknown>;
export const ListingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Listing' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineListing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'utag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cdfs' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineApartment' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfBedrooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalSurface' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'surfaceLivingArea' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineHouse' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfBedrooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalSurface' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'surfaceLivingArea' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineLand' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plotSurface' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assignedAgentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assignedTeamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'district' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileAs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyUtilities' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askingPrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coverLetterAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketingDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'premiumExposeEmail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bulletPoints' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bulletPointsTitle' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'priceOnRequest' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portals' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'includeInExport' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastActivation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listingOwnerContactId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Listing, unknown>;
export const ProposedListingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProposedListing' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineProposedListing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'matchingState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manuallyProposed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Listing' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Listing' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineListing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'utag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cdfs' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineApartment' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfBedrooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalSurface' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'surfaceLivingArea' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineHouse' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfBedrooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalSurface' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'surfaceLivingArea' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineLand' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plotSurface' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assignedAgentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assignedTeamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'district' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileAs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyUtilities' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askingPrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coverLetterAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketingDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'premiumExposeEmail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bulletPoints' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bulletPointsTitle' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'priceOnRequest' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portals' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'includeInExport' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastActivation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listingOwnerContactId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProposedListing, unknown>;
export const ShopFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Shop' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'twilioConfigurations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'twilioNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'businessUnit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Shop, unknown>;
export const TeamWithMembersFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamWithMembers' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userTeamAssignments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TeamWithMembers, unknown>;
export const ShopWithMembersFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopWithMembers' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Shop' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamWithMembers' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userShopAssignments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Shop' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'twilioConfigurations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'twilioNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'businessUnit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamWithMembers' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userTeamAssignments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopWithMembers, unknown>;
export const ShopNameByIdFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopNameByID' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopNameById, unknown>;
export const ShopGroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopGroup' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShopGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shops' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopGroup, unknown>;
export const ShopProfileFeaturedAgentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopProfileFeaturedAgent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShopProfileFeaturedAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userTeamAssignments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'team' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'shopId' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'team' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shop' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userShopAssignments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'shopId' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shop' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopProfileFeaturedAgent, unknown>;
export const ShopProfileImageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopProfileImage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShopProfileImage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopProfileImage, unknown>;
export const PropertyLoungeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyLounge' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbPropertyLounge' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PropertyLounge, unknown>;
export const ShopProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShopProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'published' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopProfileFeaturedAgents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopProfileFeaturedAgent' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'descriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slugOverride' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAiTranslated' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cityDistricts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'district' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expertises' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expertise' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'spokenLanguages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'spokenLanguage' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyPreviews' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'utag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopProfileFeaturedContents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAiTranslated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shopProfileFeaturedContentsTeasers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: { kind: 'EnumValue', value: 'ASC' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shopProfileFeaturedContentPhotos' },
            name: { kind: 'Name', value: 'images' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'FEATURED_CONTENT_TEASER' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopProfileImage' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'quote' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authorName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authorTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAiTranslated' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAiTranslated' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'teamPhoto' },
            name: { kind: 'Name', value: 'images' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'TEAM' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopProfileImage' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'SHOP' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopProfileImage' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'managerPhoto' },
            name: { kind: 'Name', value: 'images' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'MANAGER' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopProfileImage' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'seoTrackings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'googleAdsId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'googlePlaceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'googleAnalyticsAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'microsoftId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'facebookId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'criteoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkedinId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imprintUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imprints' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isAiTranslated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imprint' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'careers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAiTranslated' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'privacyPolicies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isAiTranslated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'termsAndConditions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'termsAndConditions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'withdrawalPolicies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'withdrawalPolicy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerMails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'footer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialMediaProfiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'facebookUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'instagramUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'twitterUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xingUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkedInUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'youtubeUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tiktokUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyLounge' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PropertyLounge' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopProfileFeaturedAgent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShopProfileFeaturedAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userTeamAssignments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'team' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'shopId' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'team' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shop' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userShopAssignments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'shopId' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shop' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopProfileImage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShopProfileImage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyLounge' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbPropertyLounge' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopProfile, unknown>;
export const ShopSettingsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopSettings' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShopSetting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'applyAutomatedContactSettings' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ccUsersInAssignmentEmails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentManagementEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultCity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultPreferredLanguage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendQualificationLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadAutoAssignmentEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadExpiryEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadExpiryDaysBuyer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadExpiryDaysSeller' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadExpiryDaysToRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadExpiryDaysRentOut' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadExtendingInteractionsEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastInteractionRecalculatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activeToRecalculateLastInteraction' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopSettings, unknown>;
export const SnapshotLeadFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotLeadFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbSnapshotLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactFirstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactLastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredEmailAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'address' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredPhoneNumber' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'number' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshotBuyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roomsMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roomsMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subTypes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variations' } },
                { kind: 'Field', name: { kind: 'Name', value: 'placeIds' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'snapshotCustomSearchDemandPolygons' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'polygon' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshotSellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assetSubType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assetType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'condition' } },
                { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalFloors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'constructionYear' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balcony' } },
                { kind: 'Field', name: { kind: 'Name', value: 'garden' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parking' } },
                { kind: 'Field', name: { kind: 'Name', value: 'swimmingPool' } },
                { kind: 'Field', name: { kind: 'Name', value: 'elevator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'garage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guestToilet' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SnapshotLeadFragment, unknown>;
export const TeamWithMembersAndShopFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamWithMembersAndShop' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamWithMembers' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamWithMembers' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userTeamAssignments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TeamWithMembersAndShop, unknown>;
export const GetLastInteractionActivitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLastInteractionActivities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivityOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivityBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbBaseActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastInteraction' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastInteraction' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appointment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLastInteractionActivitiesQuery, GetLastInteractionActivitiesQueryVariables>;
export const GetActivitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getActivities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivityOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivityBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbBaseActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActionActivity' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActionActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'taskType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetActivitiesQuery, GetActivitiesQueryVariables>;
export const GetActivitiesListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getActivitiesList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivityOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivityBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbBaseActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActivityListItem' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'call' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'email' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dueDatetime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appointment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'documentReference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exposeSharedActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetActivitiesListQuery, GetActivitiesListQueryVariables>;
export const GetExpandedActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getExpandedActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivityOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivityBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbBaseActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ExpandedActivityListItem' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExpandedActivityListItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'leadId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'call' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'agent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'outcome' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'email' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'to' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cc' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dueDatetime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appointment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'documentReference' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sizeKb' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exposeSharedActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetExpandedActivityQuery, GetExpandedActivityQueryVariables>;
export const TotalActivitiesCountQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'totalActivitiesCountQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivityBoolExp' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbBaseActivityAggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'totalActivityCount' },
                        name: { kind: 'Name', value: 'count' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TotalActivitiesCountQueryQuery, TotalActivitiesCountQueryQueryVariables>;
export const ActivityTypeGroupingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'activityTypeGrouping' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbActivityTypeGrouping' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActivityTypeGrouping' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityTypeGrouping' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbActivityTypeGrouping' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'activityCategory' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activityType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'typeLocalizeKey' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActivityTypeGroupingQuery, ActivityTypeGroupingQueryVariables>;
export const AgentProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AgentProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgentProfileBoolExp' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbAgentProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'published' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_isNull' },
                                      value: { kind: 'BooleanValue', value: true },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentProfile' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'publishedProfile' },
            name: { kind: 'Name', value: 'dbAgentProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'published' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_isNull' },
                                      value: { kind: 'BooleanValue', value: false },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'published' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentProfileImage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgentProfileImage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgentProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'published' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cityDistricts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'district' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expertises' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expertise' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'spokenLanguages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'spokenLanguage' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'descriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'academicTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAiTranslated' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentProfileImage' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'socialMediaProfiles' },
            name: { kind: 'Name', value: 'agentProfileSocialMediaProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'facebookUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'instagramUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'twitterUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xingUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkedInUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tiktokUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgentProfileQuery, AgentProfileQueryVariables>;
export const AgentQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AgentQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgentBoolExp' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgentOrderBy' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'distinctOn' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgentSelectColumn' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinctOn' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'distinctOn' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgentQueryQuery, AgentQueryQueryVariables>;
export const AgentWithTeamAssignmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AgentWithTeamAssignments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgentBoolExp' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentWithTeamAssignments' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentWithTeamAssignments' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userTeamAssignments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgentWithTeamAssignmentsQuery, AgentWithTeamAssignmentsQueryVariables>;
export const LeadsResponsibleAgentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LeadsResponsibleAgents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agentsAssignedToLeadsOfContact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'agent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeadsResponsibleAgentsQuery, LeadsResponsibleAgentsQueryVariables>;
export const AgentsWithAllMembershipsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AgentsWithAllMemberships' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgentBoolExp' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgentOrderBy' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentWithShopMembership' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbAgentAggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'totalCount' },
                        name: { kind: 'Name', value: 'count' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentWithShopMembership' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userTeamAssignments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shop' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userShopAssignments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shop' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAdminAssignments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'role' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgentsWithAllMembershipsQuery, AgentsWithAllMembershipsQueryVariables>;
export const BulkQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BulkQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBulkEmailOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBulkEmailBoolExp' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbBulkEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BulkEmailFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BulkEmailFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBulkEmail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'from' } },
          { kind: 'Field', name: { kind: 'Name', value: 'to' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bcc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attachments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'document' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documentReferences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sizeKb' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trackings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'baseActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'contactId' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'template' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BulkQueryQuery, BulkQueryQueryVariables>;
export const TotalBulkEmailCountQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TotalBulkEmailCountQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBulkEmailBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbBulkEmailAggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'totalBulkEmails' },
                        name: { kind: 'Name', value: 'count' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TotalBulkEmailCountQueryQuery, TotalBulkEmailCountQueryQueryVariables>;
export const CallListQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'callListQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCallOrderBy' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCallBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbCall' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Call' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityLeadFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SellerRentOutSpecification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbSellerRentOutSpecification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'constructionYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'condition' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentRangeFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentRangeTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfBedrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfMasterBathrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalFloors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surfaceTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surfaceUsable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'balcony' } },
          { kind: 'Field', name: { kind: 'Name', value: 'garden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parking' } },
          { kind: 'Field', name: { kind: 'Name', value: 'swimmingPool' } },
          { kind: 'Field', name: { kind: 'Name', value: 'elevator' } },
          { kind: 'Field', name: { kind: 'Name', value: 'garage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guestToilet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ownershipStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecificationCdfs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cdf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BuyerToRentSpecification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBuyerToRentSpecification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'district' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyAreaNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyDistrictNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subTypes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecificationCdfs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cdf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customSearchDemandPolygons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'polygon' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreatedAndReferralActivityLeadFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referrals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'receivingShop' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerMessage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SellerRentOutSpecification' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BuyerToRentSpecification' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityContactFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'birthdate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredTimes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'preferredContactTime' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredLanguage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CallInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCall' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callSid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'outcome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callDirection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caller' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Call' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCall' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'call_activities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'baseActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'category' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'leadId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lead' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActivityLeadFragment' } },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'CreatedAndReferralActivityLeadFragment' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActivityContactFragment' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CallInfo' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CallListQueryQuery, CallListQueryQueryVariables>;
export const CallQueueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CallQueue' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shops' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbCallAggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'QUEUED' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shopId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'shops' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CallQueueQuery, CallQueueQueryVariables>;
export const CommentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Comment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'personalNote' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CommentQuery, CommentQueryVariables>;
export const ConsentByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ConsentById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbConsentBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbConsent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Consent' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Document' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeKb' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByAgent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Consent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbConsent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'licenseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revokedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactForm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'document' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Document' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConsentByIdQuery, ConsentByIdQueryVariables>;
export const ContactAndLeadsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ContactAndLeads' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopIds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shopId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'shopIds' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactAndLeads' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastInteractionActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastInteraction' } }],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactLastActivity' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3Utag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredLanguage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Document' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeKb' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByAgent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Consent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbConsent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'licenseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revokedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactForm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'document' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Document' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactInfo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactAddress' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'legalEntity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'department' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formattedSalutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'birthdate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nationality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'idNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kycSearchId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerFirstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerLastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'autoCc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'consents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Consent' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internationalNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredLanguage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredTimes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'preferredContactTime' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactRelationships' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'relatedContactId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoCc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'relatedContact' },
                  name: { kind: 'Name', value: 'related_contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactInfo' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactGdpr' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'agreedToGdpr' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactRenterInformations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'householdType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professionType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'petOwner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyIncomeMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyIncomeMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tag' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'preferredPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'preferred' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'number' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'preferredEmail' },
            name: { kind: 'Name', value: 'emailAddresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'preferred' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'address' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadhubGo3ContactLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'firstAgent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'secondAgent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thirdAgent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'firstIntentCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secondIntentCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thirdIntentCategory' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'shopName' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'customerAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadLastActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastActivity' },
            name: { kind: 'Name', value: 'baseActivities' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastInteraction' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appointment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactAndLeads' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Contact' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadLastActivity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastInteractionActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastInteraction' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactLastActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastActivity' },
            name: { kind: 'Name', value: 'baseActivities' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'orderDate' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactAndLeadsQuery, ContactAndLeadsQueryVariables>;
export const ContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Contact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Contact' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3Utag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredLanguage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Document' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeKb' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByAgent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Consent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbConsent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'licenseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revokedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactForm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'document' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Document' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactInfo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactAddress' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'legalEntity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'department' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formattedSalutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'birthdate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nationality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'idNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kycSearchId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerFirstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerLastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'autoCc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'consents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Consent' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internationalNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredLanguage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredTimes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'preferredContactTime' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactRelationships' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'relatedContactId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoCc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'relatedContact' },
                  name: { kind: 'Name', value: 'related_contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactInfo' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactGdpr' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'agreedToGdpr' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactRenterInformations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'householdType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professionType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'petOwner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyIncomeMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyIncomeMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tag' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'preferredPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'preferred' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'number' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'preferredEmail' },
            name: { kind: 'Name', value: 'emailAddresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'preferred' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'address' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadhubGo3ContactLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'firstAgent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'secondAgent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thirdAgent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'firstIntentCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secondIntentCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thirdIntentCategory' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'shopName' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'customerAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactQuery, ContactQueryVariables>;
export const ContactsAndLeadsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ContactsAndLeads' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactAndLeads' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3Utag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredLanguage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Document' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeKb' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByAgent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Consent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbConsent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'licenseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revokedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactForm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'document' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Document' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactInfo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactAddress' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'legalEntity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'department' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formattedSalutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'birthdate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nationality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'idNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kycSearchId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerFirstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerLastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'autoCc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'consents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Consent' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internationalNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredLanguage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredTimes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'preferredContactTime' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactRelationships' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'relatedContactId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoCc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'relatedContact' },
                  name: { kind: 'Name', value: 'related_contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactInfo' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactGdpr' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'agreedToGdpr' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactRenterInformations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'householdType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professionType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'petOwner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyIncomeMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyIncomeMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tag' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'preferredPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'preferred' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'number' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'preferredEmail' },
            name: { kind: 'Name', value: 'emailAddresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'preferred' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'address' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadhubGo3ContactLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'firstAgent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'secondAgent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thirdAgent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'firstIntentCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secondIntentCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thirdIntentCategory' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'shopName' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'customerAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadLastActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastActivity' },
            name: { kind: 'Name', value: 'baseActivities' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastInteraction' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appointment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactAndLeads' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Contact' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadLastActivity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastInteractionActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastInteraction' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactsAndLeadsQuery, ContactsAndLeadsQueryVariables>;
export const ContactsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Contacts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Contact' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3Utag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredLanguage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Document' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeKb' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByAgent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Consent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbConsent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'licenseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revokedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactForm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'document' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Document' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactInfo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactAddress' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'legalEntity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'department' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formattedSalutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'birthdate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nationality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'idNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kycSearchId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerFirstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerLastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'autoCc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'consents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Consent' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internationalNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredLanguage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredTimes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'preferredContactTime' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactRelationships' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'relatedContactId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoCc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'relatedContact' },
                  name: { kind: 'Name', value: 'related_contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactInfo' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactGdpr' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'agreedToGdpr' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactRenterInformations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'householdType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professionType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'petOwner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyIncomeMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyIncomeMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tag' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'preferredPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'preferred' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'number' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'preferredEmail' },
            name: { kind: 'Name', value: 'emailAddresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'preferred' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'address' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadhubGo3ContactLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'firstAgent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'secondAgent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thirdAgent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'firstIntentCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secondIntentCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thirdIntentCategory' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'shopName' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'customerAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactsQuery, ContactsQueryVariables>;
export const ContactIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ContactIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactIdsQuery, ContactIdsQueryVariables>;
export const ContactsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ContactsList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactListEntry' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastInteractionActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastInteraction' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadForContactList' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactListEntry' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactAddress' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactGdpr' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'agreedToGdpr' } },
                { kind: 'Field', name: { kind: 'Name', value: 'blockedDueToGdpr' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastActivity' },
            name: { kind: 'Name', value: 'baseActivities' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mostRecentActivityCreatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'tagId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadForContactList' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internationalNumber' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastInteraction' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appointment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactsListQuery, ContactsListQueryVariables>;
export const ContactsListCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ContactsListCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactBoolExp' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbContactAggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'totalCount' },
                        name: { kind: 'Name', value: 'count' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactsListCountQuery, ContactsListCountQueryVariables>;
export const PublicContactQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'publicContactQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactDetailsOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactDetailsBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbContactDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactInfo' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3Utag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredLanguage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PublicContactQueryQuery, PublicContactQueryQueryVariables>;
export const ContactInfosQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'contactInfosQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3Utag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredLanguage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactInfosQueryQuery, ContactInfosQueryQueryVariables>;
export const ContactInfoWithRelationshipsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'contactInfoWithRelationshipsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contactRelationships' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'related_contact' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'relatedContact' },
                        name: { kind: 'Name', value: 'related_contact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactInfo' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3Utag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredLanguage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactInfoWithRelationshipsQueryQuery, ContactInfoWithRelationshipsQueryQueryVariables>;
export const ContactTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ContactTags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbContactTag' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contactId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tag' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactTagsQuery, ContactTagsQueryVariables>;
export const ContactAllTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ContactAllTags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactTagOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactTagBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbContactTag' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinctOn' },
                value: { kind: 'EnumValue', value: 'tagId' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tag' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactAllTagsQuery, ContactAllTagsQueryVariables>;
export const ContactsConnectedPropertiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ContactsConnectedProperties' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ownerContactId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineUUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyEngine' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchListings' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'shopId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'ownerContactId' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'ownerContactId' } },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'listings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ContactConnectedPropertiesListing' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactConnectedPropertiesListing' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineListing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assignedAgentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assignedTeamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastActivation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listingOwnerContactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'utag' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ContactsConnectedPropertiesQuery, ContactsConnectedPropertiesQueryVariables>;
export const CustomDemandFieldsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomDemandFields' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCdfBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbCdf' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomDemandFieldsQuery, CustomDemandFieldsQueryVariables>;
export const CustomViewsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomViewsList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'activeShopId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'EnumsCustomViewTypeEnum' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbCustomListView' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'viewIndex' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shopId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'activeShopId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CustomView' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CustomView' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCustomListView' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shownByDefault' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewData' } },
          { kind: 'Field', name: { kind: 'Name', value: 'viewIndex' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomViewsListQuery, CustomViewsListQueryVariables>;
export const AgentDashboardActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AgentDashboardActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivityOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivityBoolExp' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbBaseActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DashboardActivity' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Task' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTask' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDatetime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3TaskKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'contactId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskLeads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'leadId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskAgents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TaskActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTaskActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Task' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Appointment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAppointment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3AppointmentKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'contactId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentLeads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'leadId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentAgents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AppointmentActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAppointmentActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Appointment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DashboardActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TaskActivity' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppointmentActivity' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgentDashboardActivityQuery, AgentDashboardActivityQueryVariables>;
export const DocumentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Documents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReferenceOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReferenceBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbDocumentReference' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Document' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Document' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeKb' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByAgent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DocumentsQuery, DocumentsQueryVariables>;
export const DocumentsCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DocumentsCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReferenceBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbDocumentReferenceAggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'totalCount' },
                        name: { kind: 'Name', value: 'count' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DocumentsCountQuery, DocumentsCountQueryVariables>;
export const DocumentsInfoQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DocumentsInfoQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReferenceOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReferenceBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbDocumentReference' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DocumentsInfoQueryQuery, DocumentsInfoQueryQueryVariables>;
export const DocumentContactIdsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DocumentContactIdsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivityOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivityBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbBaseActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'emailId' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'printedDocumentActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'printedDocumentId' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appointmentActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'appointmentId' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DocumentContactIdsQueryQuery, DocumentContactIdsQueryQueryVariables>;
export const DuplicateContactGroupIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DuplicateContactGroupIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbPotentialDuplicateContactOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbPotentialDuplicateContactBoolExp' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'distinctOn' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbPotentialDuplicateContactSelectColumn' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbPotentialDuplicateContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinctOn' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'distinctOn' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'groupId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DuplicateContactGroupIdsQuery, DuplicateContactGroupIdsQueryVariables>;
export const DuplicateContactDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DuplicateContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbPotentialDuplicateContactOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbPotentialDuplicateContactBoolExp' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'distinctOn' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbPotentialDuplicateContactSelectColumn' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbPotentialDuplicateContact' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinctOn' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'distinctOn' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DuplicateContact' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastInteractionActivity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastInteraction' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadLastActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastActivity' },
            name: { kind: 'Name', value: 'baseActivities' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactLastActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastActivity' },
            name: { kind: 'Name', value: 'baseActivities' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'orderDate' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastInteraction' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appointment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DuplicateContactDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'contactStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadLastActivity' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'normalizedAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internationalNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'normalizedLocalNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactLastActivity' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lastInteractionActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastInteraction' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DuplicateContact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbPotentialDuplicateContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DuplicateContactDetails' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matchedEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matchedPhone' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DuplicateContactQuery, DuplicateContactQueryVariables>;
export const DuplicateContactCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DuplicateContactCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbPotentialDuplicateContactBoolExp' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'distinctOn' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbPotentialDuplicateContactSelectColumn' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbPotentialDuplicateContactAggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinctOn' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'distinctOn' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'totalCount' },
                        name: { kind: 'Name', value: 'count' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DuplicateContactCountQuery, DuplicateContactCountQueryVariables>;
export const EmailLinksQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EmailLinksQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbEmailLinkBoolExp' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contactRelationshipWhere' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactRelationshipBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbEmailLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'leadId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactAndLeads' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3Utag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredLanguage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Document' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeKb' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByAgent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Consent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbConsent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'licenseId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentedOn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'revokedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentSource' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactForm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'document' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Document' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Contact' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactInfo' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactAddress' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'legalEntity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'department' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'formattedSalutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'birthdate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeOfBirth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nationality' } },
          { kind: 'Field', name: { kind: 'Name', value: 'idNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kycSearchId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerFirstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'partnerLastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'autoCc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'consents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Consent' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internationalNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredLanguage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredTimes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'preferredContactTime' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactRelationships' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'relatedContactId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'autoCc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'relatedContact' },
                  name: { kind: 'Name', value: 'related_contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactInfo' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactGdpr' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'agreedToGdpr' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactRenterInformations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'householdType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'professionType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'petOwner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyIncomeMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyIncomeMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tag' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'preferredPhone' },
            name: { kind: 'Name', value: 'phoneNumbers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'preferred' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'number' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'preferredEmail' },
            name: { kind: 'Name', value: 'emailAddresses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'preferred' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'address' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadhubGo3ContactLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'firstAgent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'secondAgent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thirdAgent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'firstIntentCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secondIntentCategory' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thirdIntentCategory' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'shopName' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'customerAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadLastActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastActivity' },
            name: { kind: 'Name', value: 'baseActivities' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastInteraction' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appointment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactAndLeads' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Contact' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadLastActivity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastInteractionActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastInteraction' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmailLinksQueryQuery, EmailLinksQueryQueryVariables>;
export const EmailLinkThreadIdQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EmailLinkThreadIdQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbEmailLinkBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbEmailLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmailLinkThreadIdQueryQuery, EmailLinkThreadIdQueryQueryVariables>;
export const EmailPlaceholderQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EmailPlaceholderQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbEmailPlaceholderBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbEmailPlaceholder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailPlaceholder' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmailPlaceholder' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbEmailPlaceholder' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mapping' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmailPlaceholderQueryQuery, EmailPlaceholderQueryQueryVariables>;
export const EmailSignaturesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EmailSignaturesQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbEmailSignatureOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbEmailSignatureBoolExp' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'agent_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shop_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbEmailSignature' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailSignature' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmailSignature' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbEmailSignature' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signature' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'isDefault' },
            name: { kind: 'Name', value: 'agentDefaultsAggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'agentId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'agent_id' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shopId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'shop_id' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmailSignaturesQueryQuery, EmailSignaturesQueryQueryVariables>;
export const EmailTemplatesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EmailTemplatesQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbEmailTemplateOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbEmailTemplateBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbEmailTemplate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailTemplate' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmailTemplate' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbEmailTemplate' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                { kind: 'Field', name: { kind: 'Name', value: 'body' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmailTemplatesQueryQuery, EmailTemplatesQueryQueryVariables>;
export const EmailTrackingQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EmailTrackingQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbEmailTrackingBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbEmailTracking' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailTracking' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmailTracking' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbEmailTracking' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'count' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'email' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'messageId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmailTrackingQueryQuery, EmailTrackingQueryQueryVariables>;
export const GetEnableFeaturesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEnableFeatures' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbEnabledFeatureFlags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'enabledFeature' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetEnableFeaturesQuery, GetEnableFeaturesQueryVariables>;
export const FailedLeadImportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FailedLeadImports' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbImportLeadRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'importLeadStatus' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'FAILED' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ImportLeadRequest' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ImportLeadRequest' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbImportLeadRequest' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'payloadType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'requestPayload' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importLeadStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stackTrace' } },
          { kind: 'Field', name: { kind: 'Name', value: 'importedLeadId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FailedLeadImportsQuery, FailedLeadImportsQueryVariables>;
export const FailedLeadImportsTotalCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FailedLeadImportsTotalCount' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbImportLeadRequestAggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'importLeadStatus' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'FAILED' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'totalCount' },
                        name: { kind: 'Name', value: 'count' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FailedLeadImportsTotalCountQuery, FailedLeadImportsTotalCountQueryVariables>;
export const FindProposedListingsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindProposedListingsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'matchingState' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineMatchingState' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchDemandIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineUUID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'addressQuery' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'listingType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineListingType' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'matchingStatusOrder' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineMatchingStatusOrder' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '0' },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '100' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyEngine' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'findProposedListings' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'contactId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'matchingState' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'matchingState' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'searchDemandIds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'searchDemandIds' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'addressQuery' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'addressQuery' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'listingType' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'listingType' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'matchingStatusOrder' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'matchingStatusOrder' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'perPage' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'proposedListings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProposedListing' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Listing' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineListing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'utag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cdfs' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineApartment' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfBedrooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalSurface' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'surfaceLivingArea' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineHouse' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfBedrooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalSurface' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'surfaceLivingArea' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineLand' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plotSurface' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assignedAgentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assignedTeamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'district' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileAs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyUtilities' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askingPrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coverLetterAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketingDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'premiumExposeEmail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bulletPoints' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bulletPointsTitle' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'priceOnRequest' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portals' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'includeInExport' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastActivation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listingOwnerContactId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProposedListing' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineProposedListing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'matchingState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manuallyProposed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Listing' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindProposedListingsQueryQuery, FindProposedListingsQueryQueryVariables>;
export const FindProposedListingsCountQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindProposedListingsCountQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyEngine' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'findProposedListings' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'contactId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindProposedListingsCountQueryQuery, FindProposedListingsCountQueryQueryVariables>;
export const FullLeadsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FullLeadsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLeadOrderBy' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLeadBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbLead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Lead' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UtmParam' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbUtmParam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'autaclid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'msclkid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pmclid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'campaign' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gclid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'medium' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gbraid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wbraid' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConversionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbConversionData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'referrer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landingPageUrlWithUtm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landingPageOwnerShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'campaignOwnerShopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadExpiryByLead' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLeadExpiry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Referral' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbReferral' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3ReferralId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralNotPossibleReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralAgentNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receivingShop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SellerRentOutSpecification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbSellerRentOutSpecification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'constructionYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'condition' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentRangeFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentRangeTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfBedrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfMasterBathrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalFloors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surfaceTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surfaceUsable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'balcony' } },
          { kind: 'Field', name: { kind: 'Name', value: 'garden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parking' } },
          { kind: 'Field', name: { kind: 'Name', value: 'swimmingPool' } },
          { kind: 'Field', name: { kind: 'Name', value: 'elevator' } },
          { kind: 'Field', name: { kind: 'Name', value: 'garage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guestToilet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ownershipStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecificationCdfs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cdf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BuyerToRentSpecification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBuyerToRentSpecification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'district' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyAreaNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyDistrictNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subTypes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecificationCdfs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cdf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customSearchDemandPolygons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'polygon' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadLastActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastActivity' },
            name: { kind: 'Name', value: 'baseActivities' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Lead' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'utmParams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UtmParam' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversionData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ConversionData' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'activities' },
            name: { kind: 'Name', value: 'baseActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadExpiryByLead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadExpiryByLead' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'go3ContactKey' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referrals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Referral' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SellerRentOutSpecification' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BuyerToRentSpecification' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leadWithSearchDemand' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingsByLeadWithSearchDemandId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leadWithProperty' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } }],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadLastActivity' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FullLeadsQueryQuery, FullLeadsQueryQueryVariables>;
export const GetAllEnumsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllEnums' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsBaseActivityCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsActivityType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsAddressType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsContactTime' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsCountry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsLanguage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsLeadSource' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsLeadStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsPhoneNumberType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsEmailAddressType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsSalutation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsGender' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsAssetType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsAssetSubType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsPriority' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsIntentType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsAssetCondition' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsReferralNotPossibleReason' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsZeroCommissionReason' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsEmailSignatureScope' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsEmailTemplateScope' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsUserRole' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsTimeFrame' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsLeadStatusReason' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsShopExpertise' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsIntentCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsContactRelationType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsCallStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsCallOutcome' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsTaskAppointmentStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsTaskType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsAppointmentType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsSharedType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsPropertyOwnershipStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsPropertyOccupationStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsEntityType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsPrivacyLevel' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsMandatoryShopProfileField' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsConsentSource' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsHouseholdType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsProfessionType' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enumsSpokenLanguage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllEnumsQuery, GetAllEnumsQueryVariables>;
export const GoogleIntegrationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GoogleIntegrations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbNylasEmailIntegrationOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbNylasEmailIntegrationBoolExp' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'agent_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shop_id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbNylasEmailIntegration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NylasEmailIntegration' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NylasEmailIntegration' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbNylasEmailIntegration' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scope' } },
          { kind: 'Field', name: { kind: 'Name', value: 'active' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'isDefault' },
            name: { kind: 'Name', value: 'agentDefaultsAggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'agentId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'agent_id' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shopId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'shop_id' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GoogleIntegrationsQuery, GoogleIntegrationsQueryVariables>;
export const LeadByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LeadById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbLead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Lead' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastInteractionActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastInteraction' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UtmParam' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbUtmParam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'autaclid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'msclkid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pmclid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'campaign' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gclid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'medium' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gbraid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wbraid' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConversionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbConversionData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'referrer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landingPageUrlWithUtm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landingPageOwnerShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'campaignOwnerShopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadExpiryByLead' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLeadExpiry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Referral' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbReferral' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3ReferralId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralNotPossibleReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralAgentNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receivingShop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SellerRentOutSpecification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbSellerRentOutSpecification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'constructionYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'condition' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentRangeFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentRangeTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfBedrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfMasterBathrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalFloors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surfaceTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surfaceUsable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'balcony' } },
          { kind: 'Field', name: { kind: 'Name', value: 'garden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parking' } },
          { kind: 'Field', name: { kind: 'Name', value: 'swimmingPool' } },
          { kind: 'Field', name: { kind: 'Name', value: 'elevator' } },
          { kind: 'Field', name: { kind: 'Name', value: 'garage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guestToilet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ownershipStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecificationCdfs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cdf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BuyerToRentSpecification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBuyerToRentSpecification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'district' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyAreaNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyDistrictNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subTypes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecificationCdfs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cdf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customSearchDemandPolygons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'polygon' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadLastActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastActivity' },
            name: { kind: 'Name', value: 'baseActivities' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Lead' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'utmParams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UtmParam' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversionData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ConversionData' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'activities' },
            name: { kind: 'Name', value: 'baseActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadExpiryByLead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadExpiryByLead' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'go3ContactKey' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referrals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Referral' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SellerRentOutSpecification' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BuyerToRentSpecification' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leadWithSearchDemand' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingsByLeadWithSearchDemandId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leadWithProperty' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } }],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadLastActivity' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastInteraction' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appointment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeadByIdQuery, LeadByIdQueryVariables>;
export const LeadAndContactByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'leadAndContactById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbLead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactInfo' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3Utag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredLanguage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeadAndContactByIdQuery, LeadAndContactByIdQueryVariables>;
export const LeadBaseInfoByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LeadBaseInfoById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbLead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeadBaseInfoByIdQuery, LeadBaseInfoByIdQueryVariables>;
export const LeadInfoByIdForAddingPropertyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LeadInfoByIdForAddingProperty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbLead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadForAddingProperty' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadForAddingProperty' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'go3ContactKey' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'surfaceTotal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeadInfoByIdForAddingPropertyQuery, LeadInfoByIdForAddingPropertyQueryVariables>;
export const LeadsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LeadsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLeadBoolExp' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastInteractionWhere' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivityBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbLead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeadsQueryQuery, LeadsQueryQueryVariables>;
export const LeadsOfContactQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LeadsOfContactQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLeadBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbLead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Lead' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UtmParam' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbUtmParam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'autaclid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'msclkid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pmclid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'campaign' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gclid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'medium' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gbraid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wbraid' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConversionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbConversionData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'referrer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landingPageUrlWithUtm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landingPageOwnerShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'campaignOwnerShopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadExpiryByLead' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLeadExpiry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Referral' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbReferral' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3ReferralId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralNotPossibleReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralAgentNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receivingShop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SellerRentOutSpecification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbSellerRentOutSpecification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'constructionYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'condition' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentRangeFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentRangeTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfBedrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfMasterBathrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalFloors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surfaceTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surfaceUsable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'balcony' } },
          { kind: 'Field', name: { kind: 'Name', value: 'garden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parking' } },
          { kind: 'Field', name: { kind: 'Name', value: 'swimmingPool' } },
          { kind: 'Field', name: { kind: 'Name', value: 'elevator' } },
          { kind: 'Field', name: { kind: 'Name', value: 'garage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guestToilet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ownershipStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecificationCdfs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cdf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BuyerToRentSpecification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBuyerToRentSpecification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'district' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyAreaNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyDistrictNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subTypes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecificationCdfs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cdf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customSearchDemandPolygons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'polygon' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadLastActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastActivity' },
            name: { kind: 'Name', value: 'baseActivities' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Lead' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'utmParams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UtmParam' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversionData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ConversionData' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'activities' },
            name: { kind: 'Name', value: 'baseActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadExpiryByLead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadExpiryByLead' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'go3ContactKey' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referrals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Referral' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SellerRentOutSpecification' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BuyerToRentSpecification' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leadWithSearchDemand' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingsByLeadWithSearchDemandId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leadWithProperty' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } }],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadLastActivity' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeadsOfContactQueryQuery, LeadsOfContactQueryQueryVariables>;
export const LeadBaseActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LeadBaseActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivityBoolExp' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivityOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbBaseActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivity' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityLeadFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SellerRentOutSpecification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbSellerRentOutSpecification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'constructionYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'condition' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentRangeFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentRangeTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfBedrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfMasterBathrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalFloors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surfaceTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surfaceUsable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'balcony' } },
          { kind: 'Field', name: { kind: 'Name', value: 'garden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parking' } },
          { kind: 'Field', name: { kind: 'Name', value: 'swimmingPool' } },
          { kind: 'Field', name: { kind: 'Name', value: 'elevator' } },
          { kind: 'Field', name: { kind: 'Name', value: 'garage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guestToilet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ownershipStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecificationCdfs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cdf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BuyerToRentSpecification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBuyerToRentSpecification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'district' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyAreaNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyDistrictNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subTypes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecificationCdfs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cdf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customSearchDemandPolygons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'polygon' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreatedAndReferralActivityLeadFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referrals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'receivingShop' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerMessage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SellerRentOutSpecification' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BuyerToRentSpecification' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityContactFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContact' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'birthdate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredTimes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'preferredContactTime' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAddresses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'preferredLanguage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'phoneNumbers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CallInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCall' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callSid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'outcome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callDirection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caller' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CallActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCallActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'call' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CallInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuditActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAuditActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConsentActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbConsentActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'licenseId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customerAccountId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customerAccount' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'emailAddress' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'contactForm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'consentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'consentText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmailActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbEmailActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'email' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                { kind: 'Field', name: { kind: 'Name', value: 'to' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bcc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'document' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExposeSharedActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbExposeeSharedActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Task' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTask' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dueDatetime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3TaskKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'contactId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskLeads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'leadId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskAgents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TaskActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTaskActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'task' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Task' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Appointment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAppointment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3AppointmentKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'contactId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentLeads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'leadId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentAgents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AppointmentActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAppointmentActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Appointment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReferralDetailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbReferral' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptedRefusedDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendingContactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivingLeadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivingGo3ContactUtag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadByReceivingLeadId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailAddresses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumbers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'referralAgentNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralNotPossibleReason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentByReceivingAgentId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sendingShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivingShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivingTeamId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactByReceivingLeadIdWrapper' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sendingTeamId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receivingShop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendingShop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'snapshotId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshotLead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactFirstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactLastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamByReceivingTeamId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamBySendingTeamId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadByReceivingLeadId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'baseActivities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'callActivity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CallActivity' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailActivity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailActivity' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'appointmentActivity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppointmentActivity' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReferralActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbReferralActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referral' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReferralDetailsFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Document' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentReference' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referenceType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sizeKb' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdByAgent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbDocumentActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documentReference' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Document' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContactChangeActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbContactChangeLog' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'oldValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fieldName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LegacyGo3ChangeActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLegacyGo3ChangeLog' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'oldValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'newValue' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fieldName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActivityLeadFragment' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CreatedAndReferralActivityLeadFragment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ActivityContactFragment' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CallActivity' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuditActivity' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'consentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ConsentActivity' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailActivity' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exposeSharedActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ExposeSharedActivity' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TaskActivity' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppointmentActivity' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referralActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReferralActivity' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentActivity' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactChangeLogs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContactChangeActivity' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'base_activity_legacy_go3_change_logs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LegacyGo3ChangeActivity' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeadBaseActivityQuery, LeadBaseActivityQueryVariables>;
export const LeadListIdsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LeadListIdsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLeadOrderBy' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLeadBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbLead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeadListIdsQueryQuery, LeadListIdsQueryQueryVariables>;
export const LeadListQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LeadListQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLeadOrderBy' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLeadBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbLead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadListEntry' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastInteractionActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LastInteraction' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadLastActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastActivity' },
            name: { kind: 'Name', value: 'baseActivities' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadListEntry' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phoneNumbers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'internationalNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailAddresses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'preferredTimes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'preferredContactTime' } }],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadLastActivity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shopGroup' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shops' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LastInteraction' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'taskActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentActivity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appointment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'type' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeadListQueryQuery, LeadListQueryQueryVariables>;
export const LeadInfoListQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LeadInfoListQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLeadOrderBy' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLeadBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbLead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeadInfoListQueryQuery, LeadInfoListQueryQueryVariables>;
export const LeadSourceOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'leadSourceOptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopIds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbLead' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '250' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinctOn' },
                value: { kind: 'EnumValue', value: 'source' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shopId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'shopIds' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'source' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeadSourceOptionsQuery, LeadSourceOptionsQueryVariables>;
export const LeadStatisticsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'leadStatistics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLeadStatisticsBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbLeadStatistics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                { kind: 'Field', name: { kind: 'Name', value: 'originalLeadSource' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdDate' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeadStatisticsQuery, LeadStatisticsQueryVariables>;
export const LicensePartnersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'licensePartners' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLicensePartnerOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLicensePartnerBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbLicensePartner' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LicensePartner' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LicensePartner' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLicensePartner' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'debtorNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shops' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'licensePartnerProfileImages' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'StringValue', value: 'HERO_TEASER', block: false },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LicensePartnersQuery, LicensePartnersQueryVariables>;
export const LipaProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'lipaProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbLicensePartnerProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'licensePartnerId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LipaProfile' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'publishedProfile' },
            name: { kind: 'Name', value: 'dbLicensePartnerProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'licensePartnerId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'published' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LipaProfileFeaturedAgent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLicensePartnerProfileFeaturedAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userTeamAssignments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'team' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'shop' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'licensePartnerId' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: '_eq' },
                                              value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'team' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shop' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userShopAssignments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'shop' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'licensePartnerId' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shop' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LipaProfileImage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLicensePartnerProfileImage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LipaProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLicensePartnerProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'licensePartnerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailRecruiting' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailOther' } },
          { kind: 'Field', name: { kind: 'Name', value: 'facebookUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'instagramUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'twitterUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'xingUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedInUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'youtubeUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tiktokUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'googleAdsId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'googlePlaceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'googleAnalyticsAccountId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'microsoftId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'facebookId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'criteoId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'linkedinId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imprintUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featuredAgents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LipaProfileFeaturedAgent' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyPreviews' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'utag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'heroPhoto' },
            name: { kind: 'Name', value: 'licensePartnerProfileImages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'StringValue', value: 'HERO_TEASER', block: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LipaProfileImage' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'featuredContentPhotos' },
            name: { kind: 'Name', value: 'licensePartnerProfileImages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'StringValue', value: 'FEATURED_CONTENT_TEASER', block: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LipaProfileImage' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartnerProfileLanguageVariants' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slugOverride' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
                { kind: 'Field', name: { kind: 'Name', value: 'heroDesc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'heroHeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'heroIsAiTranslated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imprint' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imprintIsAiTranslated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyIsAiTranslated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'introHeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'introDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'introIsAiTranslated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopOverviewHeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopOverviewDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopOverviewIsAiTranslated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'careerDesc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'careerHeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'careerLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'careerIsAiTranslated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
                { kind: 'Field', name: { kind: 'Name', value: 'featuredContentDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'featuredContentHeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'featuredContentIsAiTranslated' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'featuredContentTeasers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: { kind: 'EnumValue', value: 'ASC' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'careerImages' },
            name: { kind: 'Name', value: 'licensePartnerProfileImages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'StringValue', value: 'CAREER', block: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '3' } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LipaProfileImage' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LipaProfileQuery, LipaProfileQueryVariables>;
export const GetPropertyDetailQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPropertyDetailQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'utag' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyEngine' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getListingByUtag' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'utag' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'utag' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Listing' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Listing' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineListing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'utag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cdfs' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineApartment' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfBedrooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalSurface' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'surfaceLivingArea' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineHouse' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfBedrooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalSurface' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'surfaceLivingArea' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineLand' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plotSurface' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assignedAgentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assignedTeamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'district' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileAs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyUtilities' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askingPrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coverLetterAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketingDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'premiumExposeEmail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bulletPoints' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bulletPointsTitle' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'priceOnRequest' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portals' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'includeInExport' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastActivation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listingOwnerContactId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPropertyDetailQueryQuery, GetPropertyDetailQueryQueryVariables>;
export const ListingsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListingsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineSearchListingsFilterInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineUUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyEngine' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchListings' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'perPage' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'shopId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'listings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Listing' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Listing' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineListing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'utag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cdfs' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineApartment' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfBedrooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalSurface' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'surfaceLivingArea' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineHouse' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfBedrooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalSurface' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'surfaceLivingArea' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineLand' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plotSurface' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assignedAgentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assignedTeamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'district' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileAs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyUtilities' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askingPrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coverLetterAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketingDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'premiumExposeEmail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bulletPoints' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bulletPointsTitle' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'priceOnRequest' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portals' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'includeInExport' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastActivation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listingOwnerContactId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ListingsQueryQuery, ListingsQueryQueryVariables>;
export const MatchingCountQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MatchingCountQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchDemandId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineUUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyEngine' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'findMatchingListingsForSearchDemand' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'searchDemandId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'total' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MatchingCountQueryQuery, MatchingCountQueryQueryVariables>;
export const MatchingsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MatchingsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchDemandId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineUUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineListingOrder' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'addressQuery' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'listingStatus' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineListingStatus' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyEngine' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'findMatchingListingsForSearchDemand' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'searchDemandId' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'perPage' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'addressQuery' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'addressQuery' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'listingStatus' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'listingStatus' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'listings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Listing' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Listing' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineListing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'utag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cdfs' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'asset' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineApartment' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfBedrooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalSurface' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'surfaceLivingArea' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineHouse' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfBedrooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalSurface' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'surfaceLivingArea' } },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineLand' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'plotSurface' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assignedAgentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assignedTeamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'district' } },
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileAs' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyUtilities' } },
          { kind: 'Field', name: { kind: 'Name', value: 'askingPrice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coverLetterAddress' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketingDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'premiumExposeEmail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bulletPoints' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bulletPointsTitle' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internetProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'priceOnRequest' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'portals' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'includeInExport' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'lastActivation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'listingOwnerContactId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MatchingsQueryQuery, MatchingsQueryQueryVariables>;
export const UpsertMatchingStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpsertMatchingStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineMatchingStatusInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyEngine' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'upsertMatchingStatus' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpsertMatchingStatusMutation, UpsertMatchingStatusMutationVariables>;
export const FindMatchingStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'findMatchingStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'listingUtag' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyEngine' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'findMatchingStatus' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'listingUtag' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'listingUtag' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'contactId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'contactId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MatchingStatus' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BuyerToRentSpecification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBuyerToRentSpecification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'district' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyAreaNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyDistrictNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subTypes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecificationCdfs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cdf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customSearchDemandPolygons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'polygon' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MatchingStatus' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineMatchingStatusDetail' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'matchingState' } },
          { kind: 'Field', name: { kind: 'Name', value: 'manuallyProposed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BuyerToRentSpecification' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lead' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindMatchingStatusQuery, FindMatchingStatusQueryVariables>;
export const BatchJobExecutionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'batchJobExecution' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbBatchJobExecution' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'jobExecutionId' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BatchJobExecution' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BatchJobExecution' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBatchJobExecution' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobExecutionId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'jobInstanceId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exitCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exitMessage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'batchJobExecutionParams' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parameterName' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'ListValue',
                              values: [{ kind: 'StringValue', value: 'shop_id, migration_id', block: false }],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'parameterName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parameterValue' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parameterType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'batchStepExecutions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'stepName' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_nlike' },
                            value: { kind: 'StringValue', value: '%PARTITION%', block: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'exitCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'exitMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'readCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stepName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'writeCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stepExecutionId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BatchJobExecutionQuery, BatchJobExecutionQueryVariables>;
export const NewsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'NewsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivityOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivityBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbBaseActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'auditActivity' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'content' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lead' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NewsQueryQuery, NewsQueryQueryVariables>;
export const CountryMandatoryLanguageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CountryMandatoryLanguage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCountryMandatoryLanguageOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCountryMandatoryLanguageBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbCountryMandatoryLanguage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CountryMandatoryLanguageQuery, CountryMandatoryLanguageQueryVariables>;
export const MyQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MyQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dimensions' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'PropertyEngineDashboardFeedListingsDimension' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineUUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dateFrom' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PropertyEngineDateTime' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyEngine' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dashboardFeedListings' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'dimensions' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'dimensions' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'shopId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'dateTimeFrom' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'dateFrom' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'perPage' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'results' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'listing' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'utag' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileAs' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastActivation' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastAssignedAgentChange' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastPriceReduction' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'previewImage' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } }],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pictures' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } }],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MyQueryQuery, MyQueryQueryVariables>;
export const ReferralQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReferralQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbReferralOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbReferralBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbReferral' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReferralDetailsFragment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CallInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCall' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callSid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'outcome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callDirection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caller' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CallActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCallActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'call' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CallInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmailActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbEmailActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'email' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                { kind: 'Field', name: { kind: 'Name', value: 'to' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bcc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'document' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Appointment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAppointment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3AppointmentKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'contactId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentLeads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'leadId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentAgents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AppointmentActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAppointmentActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Appointment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReferralDetailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbReferral' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptedRefusedDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendingContactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivingLeadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivingGo3ContactUtag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadByReceivingLeadId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailAddresses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumbers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'referralAgentNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralNotPossibleReason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentByReceivingAgentId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sendingShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivingShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivingTeamId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactByReceivingLeadIdWrapper' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sendingTeamId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receivingShop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendingShop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'snapshotId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshotLead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactFirstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactLastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamByReceivingTeamId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamBySendingTeamId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadByReceivingLeadId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'baseActivities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'callActivity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CallActivity' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailActivity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailActivity' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'appointmentActivity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppointmentActivity' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReferralQueryQuery, ReferralQueryQueryVariables>;
export const TotalReferralCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TotalReferralCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbReferralBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbReferralAggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TotalReferralCountQuery, TotalReferralCountQueryVariables>;
export const ReferralSendingShopsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReferralSendingShopsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbReferralBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbReferral' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinctOn' },
                value: { kind: 'EnumValue', value: 'sendingShopId' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendingShop' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReferralSendingShopsQueryQuery, ReferralSendingShopsQueryQueryVariables>;
export const ReferralSendingAgentsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReferralSendingAgentsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbReferralBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbReferral' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinctOn' },
                value: { kind: 'EnumValue', value: 'agentId' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReferralSendingAgentsQueryQuery, ReferralSendingAgentsQueryQueryVariables>;
export const ReferralReceivingShopsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReferralReceivingShopsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbReferralBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbReferral' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinctOn' },
                value: { kind: 'EnumValue', value: 'receivingShopId' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'receivingShop' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReferralReceivingShopsQueryQuery, ReferralReceivingShopsQueryQueryVariables>;
export const ReferralReceivingTeamsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReferralReceivingTeamsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbReferralBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbReferral' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinctOn' },
                value: { kind: 'EnumValue', value: 'receivingTeamId' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'teamByReceivingTeamId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReferralReceivingTeamsQueryQuery, ReferralReceivingTeamsQueryQueryVariables>;
export const ReferralSendingTeamsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReferralSendingTeamsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbReferralBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbReferral' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinctOn' },
                value: { kind: 'EnumValue', value: 'sendingTeamId' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'teamBySendingTeamId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReferralSendingTeamsQueryQuery, ReferralSendingTeamsQueryQueryVariables>;
export const ReferralReceivingAgentsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReferralReceivingAgentsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbReferralBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbReferral' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinctOn' },
                value: { kind: 'EnumValue', value: 'receivingAgentId' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agentByReceivingAgentId' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReferralReceivingAgentsQueryQuery, ReferralReceivingAgentsQueryQueryVariables>;
export const ReferralWithSnapshotLeadQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReferralWithSnapshotLeadQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'referralId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbReferral' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'referralId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReferralDetailsFragment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'snapshotLead' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotLeadFragment' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CallInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCall' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'callSid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'outcome' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'callDirection' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
          { kind: 'Field', name: { kind: 'Name', value: 'caller' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CallActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCallActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'call' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CallInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmailActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbEmailActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'email' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                { kind: 'Field', name: { kind: 'Name', value: 'to' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bcc' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
                { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'linkUrl' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'document' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Appointment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAppointment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'feedback' } },
          { kind: 'Field', name: { kind: 'Name', value: 'cost' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3AppointmentKey' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentContacts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'contactId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentLeads' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'leadId' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentAgents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AppointmentActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAppointmentActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Appointment' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReferralDetailsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbReferral' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'acceptedRefusedDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'commission' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendingContactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivingLeadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivingGo3ContactUtag' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadByReceivingLeadId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailAddresses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumbers' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferred' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'team' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'referralAgentNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralNotPossibleReason' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agentByReceivingAgentId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sendingShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivingShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivingTeamId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactByReceivingLeadIdWrapper' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sendingTeamId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receivingShop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendingShop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'snapshotId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshotLead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contactFirstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contactLastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamByReceivingTeamId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamBySendingTeamId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadByReceivingLeadId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'baseActivities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'callActivity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'CallActivity' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailActivity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'EmailActivity' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'appointmentActivity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppointmentActivity' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotLeadFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbSnapshotLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactFirstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactLastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'propertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredEmailAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'address' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preferredPhoneNumber' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'number' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshotBuyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roomsMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roomsMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subTypes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variations' } },
                { kind: 'Field', name: { kind: 'Name', value: 'placeIds' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'snapshotCustomSearchDemandPolygons' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'polygon' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshotSellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assetSubType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assetType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'condition' } },
                { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalFloors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'constructionYear' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'balcony' } },
                { kind: 'Field', name: { kind: 'Name', value: 'garden' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parking' } },
                { kind: 'Field', name: { kind: 'Name', value: 'swimmingPool' } },
                { kind: 'Field', name: { kind: 'Name', value: 'elevator' } },
                { kind: 'Field', name: { kind: 'Name', value: 'garage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'guestToilet' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReferralWithSnapshotLeadQueryQuery, ReferralWithSnapshotLeadQueryQueryVariables>;
export const ReferredShopsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ReferredShops' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbReferral' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'sendingShopId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'distinctOn' },
                value: { kind: 'EnumValue', value: 'receivingShopId' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'receivingShop' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReferredShopsQuery, ReferredShopsQueryVariables>;
export const LeadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Lead' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLeadBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbLead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Lead' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'intentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expiredAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeFrame' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'area' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'threadId' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'personalNote' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3PropertyId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'peLinkedPropertyId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UtmParam' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbUtmParam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'autaclid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'msclkid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pmclid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'campaign' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gclid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'medium' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gbraid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'wbraid' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ConversionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbConversionData' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'referrer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landingPageUrlWithUtm' } },
          { kind: 'Field', name: { kind: 'Name', value: 'landingPageOwnerShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'campaignOwnerShopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseActivityInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBaseActivity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadExpiryByLead' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLeadExpiry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Referral' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbReferral' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'go3ReferralId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralNotPossibleReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'referralAgentNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'receivingShop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Address' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'secondAddressLine' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullAddress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SellerRentOutSpecification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbSellerRentOutSpecification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'constructionYear' } },
          { kind: 'Field', name: { kind: 'Name', value: 'condition' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentRangeFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentRangeTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceRangeUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'rentPriceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'occupationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingArea' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfBathrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfBedrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfMasterBathrooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numberOfRooms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalFloors' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surfaceTotal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotArea' } },
          { kind: 'Field', name: { kind: 'Name', value: 'surfaceUsable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'balcony' } },
          { kind: 'Field', name: { kind: 'Name', value: 'garden' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parking' } },
          { kind: 'Field', name: { kind: 'Name', value: 'swimmingPool' } },
          { kind: 'Field', name: { kind: 'Name', value: 'elevator' } },
          { kind: 'Field', name: { kind: 'Name', value: 'garage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'guestToilet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ownershipStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Address' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecificationCdfs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cdf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BuyerToRentSpecification' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbBuyerToRentSpecification' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'area' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bedroomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'district' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyAreaNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyDistrictNames' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'livingAreaMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyNetRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'monthlyTotalRentMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plotSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'roomsMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subTypes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'variations' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMax' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalSurfaceMin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placeIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'searchDemandId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecificationCdfs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cdf' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customSearchDemandPolygons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'polygon' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LeadLastActivity' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastActivity' },
            name: { kind: 'Name', value: 'baseActivities' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'DESC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Lead' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLead' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contactId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'source' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priority' } },
          { kind: 'Field', name: { kind: 'Name', value: 'customerMessage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusReason' } },
          { kind: 'Field', name: { kind: 'Name', value: 'statusNote' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'utmParams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UtmParam' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversionData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ConversionData' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'activities' },
            name: { kind: 'Name', value: 'baseActivities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseActivityInfo' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadExpiryByLead' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadExpiryByLead' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contact' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'go3ContactKey' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referrals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Referral' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sellerRentOutSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SellerRentOutSpecification' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buyerToRentSpecification' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BuyerToRentSpecification' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingByLeadWithPropertyId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leadWithSearchDemand' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leadBundlingsByLeadWithSearchDemandId' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leadWithProperty' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadInfo' } }],
                  },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LeadLastActivity' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeadQuery, LeadQueryVariables>;
export const SearchKeycloakUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchKeycloakUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'keycloakUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'KeycloakUserData' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KeycloakUserData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'KeycloakUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ldapId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchKeycloakUsersQuery, SearchKeycloakUsersQueryVariables>;
export const ShopGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShopGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShopGroupOrderBy' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShopGroupBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbShopGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopGroup' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopGroup' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShopGroup' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shops' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopGroupsQuery, ShopGroupsQueryVariables>;
export const TotalShopGroupCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TotalShopGroupCount' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbShopGroupAggregate' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TotalShopGroupCountQuery, TotalShopGroupCountQueryVariables>;
export const AgentsInShopDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AgentsInShop' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgentBoolExp' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgentOrderBy' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: '_or' },
                                value: {
                                  kind: 'ListValue',
                                  values: [
                                    {
                                      kind: 'ObjectValue',
                                      fields: [
                                        {
                                          kind: 'ObjectField',
                                          name: { kind: 'Name', value: 'userShopAssignments' },
                                          value: {
                                            kind: 'ObjectValue',
                                            fields: [
                                              {
                                                kind: 'ObjectField',
                                                name: { kind: 'Name', value: 'shopId' },
                                                value: {
                                                  kind: 'ObjectValue',
                                                  fields: [
                                                    {
                                                      kind: 'ObjectField',
                                                      name: { kind: 'Name', value: '_eq' },
                                                      value: {
                                                        kind: 'Variable',
                                                        name: { kind: 'Name', value: 'shopId' },
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                    {
                                      kind: 'ObjectValue',
                                      fields: [
                                        {
                                          kind: 'ObjectField',
                                          name: { kind: 'Name', value: 'userTeamAssignments' },
                                          value: {
                                            kind: 'ObjectValue',
                                            fields: [
                                              {
                                                kind: 'ObjectField',
                                                name: { kind: 'Name', value: 'team' },
                                                value: {
                                                  kind: 'ObjectValue',
                                                  fields: [
                                                    {
                                                      kind: 'ObjectField',
                                                      name: { kind: 'Name', value: 'shopId' },
                                                      value: {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                          {
                                                            kind: 'ObjectField',
                                                            name: { kind: 'Name', value: '_eq' },
                                                            value: {
                                                              kind: 'Variable',
                                                              name: { kind: 'Name', value: 'shopId' },
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userTeamAssignments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'team' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'shopId' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'team' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shop' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userShopAssignments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'shopId' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shop' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userAdminAssignments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'role' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgentsInShopQuery, AgentsInShopQueryVariables>;
export const TotalAgentsInShopCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TotalAgentsInShopCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgentBoolExp' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbAgentAggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: '_or' },
                                value: {
                                  kind: 'ListValue',
                                  values: [
                                    {
                                      kind: 'ObjectValue',
                                      fields: [
                                        {
                                          kind: 'ObjectField',
                                          name: { kind: 'Name', value: 'userShopAssignments' },
                                          value: {
                                            kind: 'ObjectValue',
                                            fields: [
                                              {
                                                kind: 'ObjectField',
                                                name: { kind: 'Name', value: 'shopId' },
                                                value: {
                                                  kind: 'ObjectValue',
                                                  fields: [
                                                    {
                                                      kind: 'ObjectField',
                                                      name: { kind: 'Name', value: '_eq' },
                                                      value: {
                                                        kind: 'Variable',
                                                        name: { kind: 'Name', value: 'shopId' },
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                    {
                                      kind: 'ObjectValue',
                                      fields: [
                                        {
                                          kind: 'ObjectField',
                                          name: { kind: 'Name', value: 'userTeamAssignments' },
                                          value: {
                                            kind: 'ObjectValue',
                                            fields: [
                                              {
                                                kind: 'ObjectField',
                                                name: { kind: 'Name', value: 'team' },
                                                value: {
                                                  kind: 'ObjectValue',
                                                  fields: [
                                                    {
                                                      kind: 'ObjectField',
                                                      name: { kind: 'Name', value: 'shopId' },
                                                      value: {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                          {
                                                            kind: 'ObjectField',
                                                            name: { kind: 'Name', value: '_eq' },
                                                            value: {
                                                              kind: 'Variable',
                                                              name: { kind: 'Name', value: 'shopId' },
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'totalCount' },
                        name: { kind: 'Name', value: 'count' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TotalAgentsInShopCountQuery, TotalAgentsInShopCountQueryVariables>;
export const AgentsInLipaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AgentsInLipa' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lipaId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgentBoolExp' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgentOrderBy' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: '_or' },
                                value: {
                                  kind: 'ListValue',
                                  values: [
                                    {
                                      kind: 'ObjectValue',
                                      fields: [
                                        {
                                          kind: 'ObjectField',
                                          name: { kind: 'Name', value: 'userShopAssignments' },
                                          value: {
                                            kind: 'ObjectValue',
                                            fields: [
                                              {
                                                kind: 'ObjectField',
                                                name: { kind: 'Name', value: 'shop' },
                                                value: {
                                                  kind: 'ObjectValue',
                                                  fields: [
                                                    {
                                                      kind: 'ObjectField',
                                                      name: { kind: 'Name', value: 'licensePartnerId' },
                                                      value: {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                          {
                                                            kind: 'ObjectField',
                                                            name: { kind: 'Name', value: '_eq' },
                                                            value: {
                                                              kind: 'Variable',
                                                              name: { kind: 'Name', value: 'lipaId' },
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                    {
                                      kind: 'ObjectValue',
                                      fields: [
                                        {
                                          kind: 'ObjectField',
                                          name: { kind: 'Name', value: 'userTeamAssignments' },
                                          value: {
                                            kind: 'ObjectValue',
                                            fields: [
                                              {
                                                kind: 'ObjectField',
                                                name: { kind: 'Name', value: 'team' },
                                                value: {
                                                  kind: 'ObjectValue',
                                                  fields: [
                                                    {
                                                      kind: 'ObjectField',
                                                      name: { kind: 'Name', value: 'shop' },
                                                      value: {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                          {
                                                            kind: 'ObjectField',
                                                            name: { kind: 'Name', value: 'licensePartnerId' },
                                                            value: {
                                                              kind: 'ObjectValue',
                                                              fields: [
                                                                {
                                                                  kind: 'ObjectField',
                                                                  name: { kind: 'Name', value: '_eq' },
                                                                  value: {
                                                                    kind: 'Variable',
                                                                    name: { kind: 'Name', value: 'lipaId' },
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userTeamAssignments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'team' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'shop' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'licensePartnerId' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: '_eq' },
                                              value: { kind: 'Variable', name: { kind: 'Name', value: 'lipaId' } },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'team' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shop' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userShopAssignments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'shop' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'licensePartnerId' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'lipaId' } },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shop' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userAdminAssignments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'role' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AgentsInLipaQuery, AgentsInLipaQueryVariables>;
export const ShopProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShopProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShopProfileBoolExp' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbShopProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'shopId' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                                    },
                                  ],
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'published' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_isNull' },
                                      value: { kind: 'BooleanValue', value: true },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopProfile' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'publishedProfile' },
            name: { kind: 'Name', value: 'dbShopProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_and' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'shopId' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_eq' },
                                      value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                                    },
                                  ],
                                },
                              },
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'published' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: '_isNull' },
                                      value: { kind: 'BooleanValue', value: false },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'published' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopProfileFeaturedAgent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShopProfileFeaturedAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'agentId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'index' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userTeamAssignments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'team' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'shopId' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'team' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shop' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userShopAssignments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'shopId' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shop' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopProfileImage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShopProfileImage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyLounge' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbPropertyLounge' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopProfile' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShopProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'published' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'recipientId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopProfileFeaturedAgents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopProfileFeaturedAgent' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'descriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slugOverride' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAiTranslated' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cityDistricts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'district' } },
                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expertises' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'expertise' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'spokenLanguages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'spokenLanguage' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyPreviews' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'utag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopProfileFeaturedContents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAiTranslated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shopProfileFeaturedContentsTeasers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: { kind: 'EnumValue', value: 'ASC' },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'shopProfileFeaturedContentPhotos' },
            name: { kind: 'Name', value: 'images' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'FEATURED_CONTENT_TEASER' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopProfileImage' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'quote' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authorName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authorTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAiTranslated' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAiTranslated' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'teamPhoto' },
            name: { kind: 'Name', value: 'images' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'TEAM' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopProfileImage' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'SHOP' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'index' },
                      value: { kind: 'EnumValue', value: 'ASC' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopProfileImage' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'managerPhoto' },
            name: { kind: 'Name', value: 'images' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'MANAGER' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopProfileImage' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'seoTrackings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'googleAdsId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'googlePlaceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'googleAnalyticsAccountId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'microsoftId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'facebookId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'criteoId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkedinId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imprintUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imprints' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isAiTranslated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imprint' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'careers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                { kind: 'Field', name: { kind: 'Name', value: 'headline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAiTranslated' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'privacyPolicies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isAiTranslated' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'termsAndConditions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'termsAndConditions' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'withdrawalPolicies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'withdrawalPolicy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerMails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'footer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialMediaProfiles' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'facebookUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'instagramUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'twitterUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xingUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkedInUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'youtubeUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tiktokUrl' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'propertyLounge' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PropertyLounge' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'placeId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopProfileQuery, ShopProfileQueryVariables>;
export const PropertyLoungesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PropertyLounges' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbPropertyLounge' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shopId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'PropertyLounge' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PropertyLounge' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbPropertyLounge' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PropertyLoungesQuery, PropertyLoungesQueryVariables>;
export const ShopSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShopSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShopSettingBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbShopSetting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopSettings' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopSettings' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShopSetting' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'applyAutomatedContactSettings' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ccUsersInAssignmentEmails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consentManagementEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultCity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultCountryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultPreferredLanguage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sendQualificationLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadAutoAssignmentEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadExpiryEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadExpiryDaysBuyer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadExpiryDaysSeller' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadExpiryDaysToRent' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadExpiryDaysRentOut' } },
          { kind: 'Field', name: { kind: 'Name', value: 'leadExtendingInteractionsEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastInteractionRecalculatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'activeToRecalculateLastInteraction' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopSettingsQuery, ShopSettingsQueryVariables>;
export const AutoExposeShopSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AutoExposeShopSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShopSettingBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbShopSetting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shop' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'qualiformMandatoryFields' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fieldName' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'qualiformLeadIntents' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'intentType' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'qualiformLeadSources' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'leadSource' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'qualiformShopSettings' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'secondaryLanguageEnglishEnabled' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AutoExposeShopSettingsQuery, AutoExposeShopSettingsQueryVariables>;
export const AutoLeadAssignmentShopSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AutoLeadAssignmentShopSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShopSettingBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbShopSetting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shop' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leadAutoAssignmentIntents' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'intentType' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leadAutoAssignmentRules' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'leadAssignmentRule' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AutoLeadAssignmentShopSettingsQuery, AutoLeadAssignmentShopSettingsQueryVariables>;
export const ConsentManagementShopSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ConsentManagementShopSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbShopManagedConsent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shopId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'consentType' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConsentManagementShopSettingsQuery, ConsentManagementShopSettingsQueryVariables>;
export const LastInteractionSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LastInteractionSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbShopSetting' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shopId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'leadExtendingInteractionsEnabled' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LastInteractionSettingsQuery, LastInteractionSettingsQueryVariables>;
export const ShopLastInteractionActivitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShopLastInteractionActivities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbLeadExtendingActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shopId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'activityType' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbLeadExtendingTaskTypes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shopId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'taskType' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbLeadExtendingAppointmentTypes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shopId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'appointmentType' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopLastInteractionActivitiesQuery, ShopLastInteractionActivitiesQueryVariables>;
export const ShopTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShopTags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbTag' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shopId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'shopIds' } },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'deleted' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopTagsQuery, ShopTagsQueryVariables>;
export const ShopsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Shops' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShopOrderBy' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShopBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbShop' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Shop' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Shop' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'twilioConfigurations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'twilioNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'businessUnit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopsQuery, ShopsQueryVariables>;
export const ShopInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShopInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShopOrderBy' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShopBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbShop' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopInfoQuery, ShopInfoQueryVariables>;
export const ShopsWithMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShopsWithMembers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShopOrderBy' } } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShopBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbShop' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order_by' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopWithMembers' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Shop' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'website' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'twilioConfigurations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'twilioNumber' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'businessUnit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamWithMembers' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userTeamAssignments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopWithMembers' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Shop' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamWithMembers' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userShopAssignments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopsWithMembersQuery, ShopsWithMembersQueryVariables>;
export const TotalShopCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TotalShopCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShopBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbShopAggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'totalShopCount' },
                        name: { kind: 'Name', value: 'count' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TotalShopCountQuery, TotalShopCountQueryVariables>;
export const ShopNameByIDsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'shopNameByIDs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbShop' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'shopIds' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopNameByID' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopNameByID' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopNameByIDsQuery, ShopNameByIDsQueryVariables>;
export const LeadExtendingActivitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LeadExtendingActivities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbLeadExtendingActivities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shopId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'activityType' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbLeadExtendingTaskTypes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shopId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'taskType' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbLeadExtendingAppointmentTypes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shopId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'appointmentType' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LeadExtendingActivitiesQuery, LeadExtendingActivitiesQueryVariables>;
export const SystemUserIdsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'systemUserIdsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'systemUsers' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'userName' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'systemUsers' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SystemUserIdsQueryQuery, SystemUserIdsQueryQueryVariables>;
export const TagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Tags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTagBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbTag' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TagsQuery, TagsQueryVariables>;
export const TeamsWithMembersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'teamsWithMembers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeamBoolExp' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeamOrderBy' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamWithMembersAndShop' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbTeamAggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'totalCount' },
                        name: { kind: 'Name', value: 'count' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Team' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'normalizedName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'description' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AgentInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobilePhoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'officePhoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Agent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgent' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AgentInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'primaryShopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'salutation' } },
          { kind: 'Field', name: { kind: 'Name', value: 'betaTester' } },
          { kind: 'Field', name: { kind: 'Name', value: 'energyAdvisoryEnabled' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nylasEmailIntegrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamWithMembers' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Team' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userTeamAssignments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'agent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Agent' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ShopInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbShop' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'streetNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onboardingLevel' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shopGroup' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shops' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
          { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'licensePartner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCompanyOwnedOperation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profiles' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_isNull' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'type' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'EnumValue', value: 'SHOP' },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'index' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: { kind: 'IntValue', value: '0' },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'gcpFileName' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamWithMembersAndShop' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamWithMembers' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shop' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ShopInfo' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TeamsWithMembersQuery, TeamsWithMembersQueryVariables>;
export const TeamInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'teamInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeamBoolExp' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeamOrderBy' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'TeamInfo' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TeamInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DbTeam' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shopId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TeamInfoQuery, TeamInfoQueryVariables>;
export const TeamsAssignedToAgentInShopDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TeamsAssignedToAgentInShop' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbAgentBoolExp' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userTeamAssignments' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'team' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'shopId' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: '_eq' },
                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'team' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shopId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TeamsAssignedToAgentInShopQuery, TeamsAssignedToAgentInShopQueryVariables>;
export const TotalCallCountQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TotalCallCountQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbCallBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbCallAggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'totalCallCount' },
                        name: { kind: 'Name', value: 'count' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TotalCallCountQueryQuery, TotalCallCountQueryQueryVariables>;
export const TotalEmailSignaturesQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TotalEmailSignaturesQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbEmailSignatureBoolExp' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbEmailSignatureAggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'totalEmailSignatures' },
                        name: { kind: 'Name', value: 'count' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TotalEmailSignaturesQueryQuery, TotalEmailSignaturesQueryQueryVariables>;
export const TotalLeadCountQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TotalLeadCountQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DbLeadBoolExp' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbLeadAggregate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'aggregate' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'totalLeadCount' },
                        name: { kind: 'Name', value: 'count' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TotalLeadCountQueryQuery, TotalLeadCountQueryQueryVariables>;
export const VerifyNumberQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VerifyNumberQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dbTwilioConfiguration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'shopId' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'shopId' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'displayNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'twilioNumber' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VerifyNumberQueryQuery, VerifyNumberQueryQueryVariables>;
